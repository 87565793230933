import { Modal, Button, Message } from 'semantic-ui-react';
import { Form } from "~/components/form/Form"
import { TextField } from '~/components/form/TextField';
import { TextAreaField } from '~/components/form/TextAreaField';
import { DropdownField } from '~/components/form/DropdownField';
import { useAppState } from '~/hooks/useAppState';
import { maxCharacters } from '~/components/form/validators';
import { NewBugReport, createBugReport } from '~/services/bugsReports';

const bugReportFormId = "bug-report-modal";

export function BugReportModal() {
  const [open, setOpen] = useAppState("reportBugModalOpen")
  const close = () => setOpen(false)

  const onSubmit = async (fields:NewBugReport) => {
    // I don't like specifying impact.value here, but there is an incompatibility between semantic dropdowns and final form dropdowns that makes this necessary. There's probably a better way to do this, but I can't think of it now.
	//@ts-ignore
    fields.impact = fields.impact.value;
    await createBugReport(fields)
    close();
  }

  return <Modal open={open} onClose={close}>
      {/* <Modal.Header>Report A Bug</Modal.Header>
      <Modal.Content scrolling>
        <Message>
          <Message.Content>Please provide details about your issue below.</Message.Content>
        </Message>

		<Form<NewBugReport> id={bugReportFormId} onSubmit={onSubmit}>
				<TextField
					name="description"
					label="Short Description"
					required
					validate={maxCharacters(200)} //this needs to be 200 for service now table
				/>
          <DropdownField
            name="impact"
            label="Who Does This Impact"
            textgetter={(val:any) => {if (val.text) return val.text}}
            required
            options={[
              {key: 3, value: '3', text: 'This only affects me'},
              {key: 2, value: '2', text: 'This affects me and a few other users'},
              {key: 1, value: '1', text: 'This is blocking ASD from doing work'}
            ]}
          />
          <TextAreaField
            name="details"
            label="Details"
            placeholder='Please describe what happened, versus what you expected to happen.'
            validate={maxCharacters(4000)} //this needs to be 4000 for service now table
            required
          />
        </Form>
      </Modal.Content>
      <Modal.Actions> */}
        <Button basic onClick={close}>
          Cancel
        </Button>
        {/* <Button form={bugReportFormId} type="submit" color="blue">
          Submit
        </Button>
      </Modal.Actions> */}
    </Modal>
}

/*
=============
	the following code enables attachment functionality; commented out because there is a 128kb limit on async lambda request bodies
	if screenshot functionality becomes important in the future this would be a good place to start
=============
	hideAttachmentMessage(timeout) {
		const messageTimeoutFunction = setTimeout(
			() => this.setMessage(ATTACHMENT_MESSAGE, MESSAGE_STATE.none, ''),
			timeout
		);

		this.setState({messageTimeoutFunction});
	}

	async processAttachment(e) {
		const {attachedScreenshots, messageTimeoutFunction} = this.state;

		// this resets the timeout function that hides the attachment message
		// e.g. if a user uploads a screenshot, waits 4 seconds, then uploads another screenshot, message isn't immediately hidden
		if (messageTimeoutFunction) {
			clearTimeout(messageTimeoutFunction);
		}

		// display loading indicator
		this.setMessage(ATTACHMENT_MESSAGE, MESSAGE_STATE.loading, 'Uploading attachment...');

		// get attached file
		const screenshotFile = e.target.files[0];

		// double-check that screenshotFile is of an acceptable type, display an error if it isn't
		if (!ACCEPTED_ATTACHMENT_TYPES.includes(screenshotFile.type) || screenshotFile.type === '') {
			this.setMessage(ATTACHMENT_MESSAGE, MESSAGE_STATE.error, 'Attachment must be of type .png, .jpg, or .jpeg');
			this.hideAttachmentMessage(5000);
			return;
		}

		try {
			const dataUrl = await navBarService.processAttachment(screenshotFile);

			console.log(new Blob([dataUrl]).size);

			this.setState({
				screenshot: {
					name: screenshotFile.name,
					type: screenshotFile.type,
					data: dataUrl
				}
			});

			this.setMessage(ATTACHMENT_MESSAGE, MESSAGE_STATE.success, 'Attachment uploaded successfully');
		} catch (error) {
			console.error(error);
			this.setMessage(ATTACHMENT_MESSAGE, MESSAGE_STATE.error, 'Error uploading attachment');
		}

		// hide message after 5 seconds regardless of success/failure
		this.hideAttachmentMessage(5000);
	}

	removeAttachment(name) {
		this.setState({
			screenshot: null
		});
	}

	***JSX***

<DialogMessage message={this.state[ATTACHMENT_MESSAGE]} />

<Form.Field>
	<label>Attach Screenshot (limit 1MB)</label>
	<input
		type="file"
		accept={ACCEPTED_ATTACHMENT_TYPES}
		onChange={(e) => this.processAttachment(e)}
	/>
</Form.Field>


<List celled>
	{screenshot ? (
	<List.Item className="navbar bug-modal attachment">
		<List.Content className="list-content">
			<Image
				className="thumbnail"
				size="tiny"
				bordered
				inline
				src={screenshot.data}
			/>
			<List.Header>{screenshot.name}</List.Header>
		</List.Content>

		<Button onClick={() => this.removeAttachment(screenshot.name)} icon type="button">
			<Icon name="times" size="large" />
		</Button>
	</List.Item>
	) : (
		''
	)}
</List>
*/
