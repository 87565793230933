import React from 'react';
import {connect} from 'react-redux';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {Segment, Label} from 'semantic-ui-react';
import BillingDetailsGrid from '../../../../../../../../components/billingDetailsGrid/billingDetailsGrid.tsx';

import {resetBillingDetailsRowOnToggle, updateCreateProjectModalGrid} from '../../services/createProjectActions.js';

// makes cells uneditable when tech is set to inactive
function editableActive(params) {
	let active = params.data.toggled;
	return active;
}

class AGGridSection extends React.Component {
	constructor() {
		super(...arguments);
	}

	render() {
		return (
			<Segment basic>
				<div
					style={{
						border: '1px solid rgba(34,36,38,.15)'
					}}
				>
					<Label attached="top" icon="search" color="blue" style={{position: 'relative'}}>
						{'Billing Details'}
					</Label>
					{this.props.rowData.length > 0 ? (
						<BillingDetailsGrid
							rowData={this.props.rowData}
							slim={false}
							updateRecord={this.props.updateCreateProjectModalGrid}
							resetRecord={this.props.resetBillingDetailsRowOnToggle}
						/>
					) : (
						<></>
					)}
				</div>
			</Segment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		rowData: state.crm.createProject.createProjectModal.sections[1].rows
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateProjectModalGrid: (id, field, value) => {
			dispatch(updateCreateProjectModalGrid(id, field, value));
		},
		resetBillingDetailsRowOnToggle: (id) => {
			dispatch(resetBillingDetailsRowOnToggle(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AGGridSection);
