// Modules
import React from 'react';
import {Button, Modal, Icon, Header, Input, Label, Dropdown, Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import aasdiSearchExpiredRenderer from './aasdiSearchExpiredRenderer.js';
import * as actions from '../../../../../services/createWorkOrderActions.js';

import AutoFill from './autoFill.js';

class AASDIModal extends React.Component {
	render = () => {
		const {props} = this;
		return (
			<Modal
				open={props.modal.toggled}
				style={{
					marginTop: '0'
				}}
				size="fullscreen"
				onMount={() => {
					if (props.modal.dataList.length < 1 && props.modal.selectedZip) {
						props.getWorkOrderAASDI(
							props.sectionIndex,
							props.modal.selectedNameSearch,
							props.modal.selectedZip,
							props.modal.selectedRadius,
							this.gridApi
						);
					}
				}}
			>
				<Header>
					<Icon name="plus" color="green" />
					<div className="content">Add AASDI</div>
				</Header>
				<Modal.Content
					scrolling
					style={{
						marginTop: '0',
						height: '65rem'
					}}
				>
					<div>
						<Grid>
							<Grid.Row>
								<Grid.Column width={4}>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Zip
										</Label>
									</div>
									<DebounceInput
										element={Input}
										debounceTimeout={600}
										value={props.modal.selectedZip}
										onChange={(e) => {
											// if (e.target.value.length === 5) {
											props.setWorkOrderModalAASDIZipSearch(
												'createWorkOrderModal',
												props.sectionIndex,
												e.target.value.trim()
											);
											// }
										}}
										onKeyPress={(e) => {
											if (
												// e.target.value.length === 5 &&
												e.charCode === 13
											) {
												if (e.target.value.length >= 3) {
													props.getWorkOrderAASDI(
														props.sectionIndex,
														props.modal.selectedNameSearch,
														props.modal.selectedZip.trim(),
														props.modal.selectedRadius,
														this.gridApi
													);
												} else {
													props.workOrderModalZipErrorToggle(props.sectionIndex, true);
												}
											}
										}}
										style={{width: '100%'}}
									/>
									{props.modal.zipError ? (
										<Label color="red" pointing="above">
											Invalid zip code
										</Label>
									) : null}
								</Grid.Column>
								<Grid.Column width={4}>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Radius
										</Label>
									</div>
									<Dropdown
										search
										selection
										scrolling
										compact
										options={props.modal.radiusOptions}
										text={props.modal.selectedRadius}
										onChange={(e, {value}) => {
											props.setWorkOrderModalAASDISearchRadius(
												'createWorkOrderModal',
												props.sectionIndex,
												value
											);
										}}
										style={{width: '100%'}}
									/>
								</Grid.Column>
								<Grid.Column width={4}>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Name
										</Label>
									</div>
									<Input
										onChange={(e) => {
											props.setWorkOrderModalAASDINameSearch(
												'createWorkOrderModal',
												props.sectionIndex,
												e.target.value
											);
										}}
										onKeyPress={(e) => {
											if (e.charCode === 13) {
												props.getWorkOrderAASDI(
													props.sectionIndex,
													props.modal.selectedNameSearch,
													props.modal.selectedZip,
													props.modal.selectedRadius,
													this.gridApi
												);
											}
										}}
										style={{width: '100%'}}
									/>
									<AutoFill field={props.modal} />
								</Grid.Column>
								<Grid.Column width={4}>
									<Button
										fluid
										color="blue"
										style={{
											marginTop: '3rem'
										}}
										onClick={() => {
											if (props.modal.selectedZip >= 3) {
												props.getWorkOrderAASDI(
													props.sectionIndex,
													props.modal.selectedNameSearch,
													props.modal.selectedZip,
													props.modal.selectedRadius,
													this.gridApi
												);
											} else {
												props.workOrderModalZipErrorToggle(props.sectionIndex, true);
											}
										}}
									>
										<Icon
											name="search"
											style={{
												marginRight: '1rem'
											}}
										/>
										Search
									</Button>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<div
							className="ag-theme-balham"
							style={{
								margin: '2% 0 0 0',
								height: '38rem',
								width: '100%'
							}}
						>
							<AgGridReact
								defaultColDef={{
									sortable: true,
									filter: true,
									resizable: true
								}}
								columnDefs={[
									{
										headerName: 'Distance',
										field: 'distance',
										sort: 'asc'
									},
									{
										headerName: 'Name',
										field: 'name',
										cellRendererFramework: aasdiSearchExpiredRenderer
									},
									{
										headerName: 'Address',
										field: 'address'
									},
									{
										headerName: 'Address2',
										field: 'address2'
									},
									{
										headerName: 'City',
										field: 'city'
									},
									{
										headerName: 'State',
										field: 'state'
									},
									{
										headerName: 'Zip',
										field: 'zip'
									},
									{
										headerName: 'Country',
										field: 'countryName'
									}
								]}
								modules={AllModules}
								onGridReady={(params) => {
									this.gridApi = params.api;
									this.columnApi = params.columnApi;
									this.gridApi.sizeColumnsToFit();
								}}
								rowSelection="multiple"
								rowData={props.modal.dataList}
							/>
						</div>
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							color="red"
							onClick={() =>
								props.modal.toggled
									? props.toggleAASDIModal(props.sectionIndex)
									: (props.toggleAASDIModal(props.sectionIndex),
									  props.workOrderModalRemoveAllAASDI(props.sectionIndex))
							}
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							color="green"
							onClick={() => {
								let selectedArray = this.gridApi.getSelectedRows();

								selectedArray.forEach((aasdi, index) => {
									// removes any expired aasdis from array
									if (aasdi.expired) {
										selectedArray.splice(index, 1);
									}
								});

								if (selectedArray.length > 0) {
									props.workOrderModalSelectAASDI(
										props.sectionIndex,
										selectedArray,
										props.AASDIArray
									);
									props.toggleAASDIModal(props.sectionIndex);
								}
							}}
						>
							<Icon name="checkmark" /> Select
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	};
}

AASDIModal.propTypes = {
	AASDIArray: PropTypes.array,
	workOrderModalSelectAASDI: PropTypes.func,
	toggleAASDIModal: PropTypes.func,
	workOrderModalRemoveAllAASDI: PropTypes.func,
	setWorkOrderModalAASDINameSearch: PropTypes.func,
	setWorkOrderModalAASDISearchRadius: PropTypes.func,
	workOrderModalZipErrorToggle: PropTypes.func,
	setWorkOrderModalAASDIZipSearch: PropTypes.func,
	getWorkOrderAASDI: PropTypes.func,
	modal: PropTypes.object,
	sectionIndex: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		AASDIArray:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'AASDISection'
				)
			].AASDI,
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'AASDISection'
		),
		modal:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'AASDISection'
				)
			].modal
	};
};

export default connect(mapStateToProps, actions)(AASDIModal);
