import React from 'react';
import {Button, Modal, Icon, Header, Dimmer, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';

import * as actions from '../../../../../services/workRecordActions.js';

class ActionBillModal extends React.Component {
	render() {
		return (
			<Modal open={this.props.actionBillModalToggleValue} size="large">
				<Header>
					{this.props.actionBillModalPlace === 'deleteBill' ? (
						<Icon name="remove" color={'red'} />
					) : (
						<Icon name="check" color={'green'} />
					)}
					<div className="content">
						{this.props.actionBillModalPlace === 'deleteBill' ? 'Delete Bill' : 'Submit Bill'}
					</div>
				</Header>
				<Modal.Content scrolling={true}>
					{this.props.actionBillModalModalLoading ? (
						<Dimmer inverted active className="centerDimmer">
							{this.props.actionBillModalPlace === 'deleteBill' ? (
								<Loader size="large">{'Deleting Bill'}</Loader>
							) : this.props.actionBillModalPlace === 'submitBill' ? (
								<Loader size="large">{'Submitting Bill'}</Loader>
							) : (
								// Error handling
								<Header as="h4" icon>
									<Icon name="cancel" color="red" />
									<div style={{paddingBottom: '.5em', color: 'black'}}>
										{this.props.actionBillModalPlace === 'deleteBill'
											? 'Deleting Bill Failed'
											: 'Submitting Bill Failed'}
									</div>
									<div style={{color: 'black'}}>Please try again.</div>
									<Button
										onClick={() => {
											this.props.closeDimmer('actionBillModal');
										}}
										style={{marginTop: '1.5em'}}
									>
										Close Message
									</Button>
								</Header>
							)}
						</Dimmer>
					) : null}
					{this.props.actionBillModalPlace === 'deleteBill'
						? 'Are you sure you want to delete this bill?'
						: 'Are you sure you want to submit this bill for approval?'}
				</Modal.Content>
				<Modal.Actions>
					<Button
						color="red"
						onClick={() => {
							this.props.actionBillModalToggle(null, null);
						}}
					>
						<Icon name="remove" /> No
					</Button>
					<Button
						color="green"
						onClick={() => {
							this.props.actionBillModalPlace === 'deleteBill'
								? this.props.removeInvoiceRowSpreadsheet(
										this.props.actionBillModalData,
										this.props.actionBillModalRowIndex
								  )
								: this.props.submitWOBill(
										this.props.actionBillModalData,
										this.props.actionBillModalRowIndex
								  );
						}}
					>
						<Icon name="checkmark" /> Yes
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		actionBillModalToggleValue: state.work.workRecord.view.BillingCenter.actionBillModal.actionBillModalToggle,
		actionBillModalData: state.work.workRecord.view.BillingCenter.actionBillModal.data,
		actionBillModalModalLoading: state.work.workRecord.view.BillingCenter.actionBillModal.loading,
		actionBillModalPlace: state.work.workRecord.view.BillingCenter.actionBillModal.place,
		actionBillModalRowIndex: state.work.workRecord.view.BillingCenter.actionBillModal.rowIndex
	};
};

export default connect(mapStateToProps, actions)(ActionBillModal);
