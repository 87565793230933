import React from 'react';
import {connect} from 'react-redux';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {Button, Icon} from 'semantic-ui-react';
import WorkOrderPDF from '../../../WorkOrder-Project/scenes/workRecordView/scenes/overview/components/WorkOrderPDF.js';

class WorkOrderPDFButton extends React.Component {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps) {
		const {specInst, requestDate, loading} = this.props;
		if (
			loading === nextProps.loading &&
			this.isEmpty(specInst) === this.isEmpty(nextProps.specInst) &&
			this.isEmpty(requestDate) === this.isEmpty(nextProps.requestDate)
		)
			return false;
		return true;
	}

	isEmpty = (str) => {
		return !str || 0 === str.length;
	};

	render() {
		const {loading, workOverview} = this.props;
		if (!loading) {
			return (
				<PDFDownloadLink
					document={<WorkOrderPDF {...this.props} />}
					fileName={`${workOverview.workNumber}.pdf`}
				>
					<Button color="blue">
						<Icon name="file pdf outline" />
						{'Download'}
					</Button>
				</PDFDownloadLink>
			);
		} else {
			return (
				<Button color="blue">
					<Icon name="file pdf outline" />
					{'Loading...'}
				</Button>
			);
		}
	}
}

export default connect(null, null)(WorkOrderPDFButton);
