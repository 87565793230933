import React from 'react';
import {Icon} from 'semantic-ui-react';

const DocumentSectionRemoveButton = (props) => (
	<div style={{paddingTop: '.15em'}}>
		<Icon
			name="cancel"
			color="red"
			size="large"
			style={{cursor: 'pointer'}}
			onClick={() => {
				console.log(props);
				console.log(props.rowIndex);
				console.log(props.agGridReact.props.activeStep);
				props.agGridReact.props.removeCreateAASDIDocumentGrid(
					props.rowIndex,
					props.agGridReact.props.activeStep
				);
			}}
		/>
	</div>
);

export default DocumentSectionRemoveButton;
