import React from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../services/workRecordActions.js';

const OrderGroupGridActions = (props) => (
	<div>
		<Popup
			position="top center"
			content="Edit Order Group"
			trigger={
				<Icon
					name="edit"
					color="blue"
					size="large"
					style={{
						cursor: 'pointer',
						fontSize: '1.3em',
						margin: '0 1.5em 0 0'
					}}
					onClick={() => {
						if (props.data.status_Name === 'Open') {
							props.toggleAddOrderGroupModal('edit', props.data, props.rowIndex);
							if (props.type !== 'create') {
								props.saveOldGroup();
							}
						}
					}}
					disabled={props.data.status_Name !== 'Open'}
				/>
			}
		/>
		<Popup
			position="top center"
			content="Submit Order Group"
			trigger={
				<Icon
					name="check"
					color="green"
					size="large"
					style={{
						cursor: 'pointer',
						fontSize: '1.3em',
						margin: '0 1.5em 0 0'
					}}
					onClick={() => {
						if (props.data.status_Name === 'Open') {
							props.toggleSubmitOrderGroupModal(props.data, props.rowIndex);
						}
					}}
					disabled={props.data.status_Name !== 'Open'}
				/>
			}
		/>
		<Popup
			position="top center"
			content="Remove Order Group"
			trigger={
				<Icon
					name="cancel"
					color="red"
					size="large"
					style={{cursor: 'pointer', margin: '0 1.5em 0 0'}}
					onClick={() => {
						if (props.data.status_Name === 'Open') {
							props.toggleRemoveGroupConfirmationModel(props.data, props.rowIndex);
						}
					}}
					disabled={props.data.status_Name !== 'Open'}
				/>
			}
		/>
	</div>
);

OrderGroupGridActions.propTypes = {
	data: PropTypes.object,
	rowIndex: PropTypes.number,
	saveOldGroup: PropTypes.func,
	toggleAddOrderGroupModal: PropTypes.func,
	toggleRemoveGroupConfirmationModel: PropTypes.func,
	toggleSubmitOrderGroupModal: PropTypes.func,
	type: PropTypes.string
};

export default connect(null, actions)(OrderGroupGridActions);
