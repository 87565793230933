import React from 'react';
import {Icon} from 'semantic-ui-react';

const RemoveRowButton = (props) => (
	<div>
		<Icon
			name="cancel"
			color="red"
			size="large"
			style={{cursor: 'pointer'}}
			onClick={() => {
				props.agGridReact.props.removeWO(props.rowIndex, props.data);
			}}
		/>
	</div>
);

export default RemoveRowButton;
