import React from 'react';
import moment from 'moment';
import {Grid, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {updateCreateWorkOrderModal} from '../../services/createWorkOrderActions.js';
import {MomentDatePicker} from '../../../../../../components/inputs/DatePicker.jsx';

const DropdownField = (props) => (
	<div
		style={{
			paddingTop: '1.1em'
		}}
	>
		<Grid.Row
			inline
			style={{
				height: '2rem',
				padding: 0,
				marginBottom: '1rem'
			}}
		>
			<Label
				style={{
					backgroundColor: 'transparent',
					fontSize: '1rem',
					color: 'rgba(0,0,0,.8)'
				}}
			>
				{props.field.name}
			</Label>
			<MomentDatePicker
				className={props.field.error ? 'workOrderDatePicker sendToModalDatePickerError' : 'workOrderDatePicker'}
				showTimeSelect={props.field.time}
				timeFormat="hh:mm a"
				timeIntervals={15}
				dateFormat={props.field.time ? 'MM-dd-yyyy hh:mm a' : 'MM-dd-yyyy'}
				timeCaption="time"
				selected={moment(props.field.value)}
				onChange={(e) => {
					props.updateCreateWorkOrderModal(
						'createWorkOrderModal',
						props.sectionIndex,
						props.fieldIndex,
						e,
						false
					);
				}}
			/>
		</Grid.Row>
	</div>
);

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateWorkOrderModal: (modal, sectionIndex, fieldIndex, value, error) => {
			dispatch(updateCreateWorkOrderModal(modal, sectionIndex, fieldIndex, value, error));
		}
	};
};

export default connect(null, mapDispatchToProps)(DropdownField);
