// Todo: use constants defined in UserCreationModal, or better yet create a shared constants file
import React from 'react';
import {Button, Radio, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import api from '../../../../../../../../../lib/api.ts';

import {
	toggleUserCreationModal,
	toggleUserCreationModalType,
	updateUserForm,
	switchModalRequestType,
	setSearchOptions,
	toggleDeactivateUserModal,
	setDeactivateUserData,
	updateAccountingConsoleUsers
} from '../../../../../../../services/accountingConsoleActions.js';

const changeUserActivation = async (updateAccountingConsoleUsers, userData, isActive) => {
	const user = {...userData};
	let param = '';
	if (user.aasdiUserId) {
		param = 'aasdi';
		user.roleType = isActive ? 3 : 5;
	} else if (user.clientUserId) {
		param = 'client';
		user.roleType = isActive ? 3 : 4;
	} else {
		param = 'employee';
		user.isActive = !isActive;
	}

	try {
		const result = await api(`legacy/employee?type=${param}`, 'put', user);

		if (result.error) {
			throw new Error(result.error);
		}

		updateAccountingConsoleUsers(user);
	} catch (error) {
		console.error(error);
	}
};

const ActivationButton = (props) => {
	// employee users have a "isActive" flag in DB, while aasdi/client users are set to roleId 3(inactive) -> to aid future development
	// an isActive flag should be added to aasdi/client users
	const isNotActive = !props.data.isActive || props.data.roleId === 3;
	const isActive = !isNotActive;
	return (
		<Popup
			trigger={
				<Radio
					toggle
					checked={isActive}
					onClick={() => changeUserActivation(props.updateAccountingConsoleUsers, props.data, isActive)}
					style={{marginRight: '1em'}}
				/>
			}
			content={isActive ? 'Deactivate' : 'Activate'}
			on={['hover', 'click']}
			flowing
			hoverable
			position="right center"
			style={{zIndex: 1}}
		/>
	);
};

const userActionsButtons = (props) => {
	const {
		data,
		toggleUserCreationModal,
		toggleUserCreationModalType,
		updateUserForm,
		switchModalRequestType,
		setSearchOptions,
		updateAccountingConsoleUsers
	} = props;
	return (
		<div>
			<ActivationButton data={data} updateAccountingConsoleUsers={updateAccountingConsoleUsers} />

			<Popup
				trigger={
					<Button
						icon={'edit'}
						style={{
							backgroundColor: '#e8e8e8',
							padding: '.5em .5em .5em',
							position: 'relative',
							bottom: '4px'
						}}
						onClick={() => {
							// data.branchId =
							// 	data.branchId //&& !isNaN(data.branchId) ? data.branchId.toString() : data.branchId;
							// data.roleId = data.roleId && !isNaN(data.roleId) ? data.roleId.toString() : data.roleId;
							let modalType;
							if (data.aasdiUserId) {
								modalType = 'AASDI';
								const option = [
									{
										value: data.aasdiId,
										text: data.aasdiName
									}
								];
								setSearchOptions(option);
								// action com a option
							} else if (data.clientUserId) {
								modalType = 'Client';
								const option = [
									{
										value: data.clientId,
										text: data.clientName
									}
								];
								setSearchOptions(option);
							} else {
								modalType = 'Employee';
							}
							updateUserForm(data);
							switchModalRequestType('put');
							toggleUserCreationModalType(modalType);
							toggleUserCreationModal(true);
						}}
					/>
				}
				content={'Edit User'}
				on={['hover', 'click']}
				flowing
				hoverable
				position="right center"
				style={{zIndex: 1}}
			/>
		</div>
	);
};

userActionsButtons.propTypes = {
	data: PropTypes.object,
	toggleUserCreationModal: PropTypes.func,
	toggleUserCreationModalType: PropTypes.func,
	toggleDeactivateUserModal: PropTypes.func,
	updateUserForm: PropTypes.func,
	switchModalRequestType: PropTypes.func,
	setSearchOptions: PropTypes.func,
	setDeactivateUserData: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleUserCreationModal: (status) => dispatch(toggleUserCreationModal(status)),
		toggleUserCreationModalType: (status) => dispatch(toggleUserCreationModalType(status)),
		updateUserForm: (form) => dispatch(updateUserForm(form)),
		switchModalRequestType: (type) => dispatch(switchModalRequestType(type)),
		setSearchOptions: (options) => dispatch(setSearchOptions(options)),
		toggleDeactivateUserModal: (status) => dispatch(toggleDeactivateUserModal(status)),
		setDeactivateUserData: (status) => dispatch(setDeactivateUserData(status)),
		updateAccountingConsoleUsers: (user) => dispatch(updateAccountingConsoleUsers(user))
	};
};

export default connect(null, mapDispatchToProps)(userActionsButtons);
