import {rowIndex} from '../../../scenes/crm/scenes/dashboard/scenes/pipeline/services/pipelineActions.js';
import customFetch from '../../../lib/old/customFetch.js';

export const toggleCreateModal = (id, place) => {
	return (dispatch, getState) => {
		const state = getState();
		dispatch({
			type: 'CREATE_MODAL_TOGGLE',
			payload: {
				id,
				place
			}
		});

		if (!state.global.createCustomerModal.createModal.toggleCreateModal) {
			let rowData = dispatch(rowIndex(id, place));
			let clientId = state.crm[place].data.opportunities[rowData].clientId;

			// Call getClient action
			dispatch(getClientInfo(clientId));
		}
	};
};

export const setActiveStep = (currentStep, targetStep) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_MODAL_SET_ACTIVE_STEP',
			payload: {
				currentStep,
				targetStep
			}
		});
	};
};

export const updateCreateModal = (modal, step, column, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_MODAL_UPDATE',
			payload: {
				modal,
				step,
				column,
				value,
				error
			}
		});
	};
};

// export const updateCreateModalPlaceholder = (modal, step, column, value) => {
// 	return dispatch => {
// 		dispatch({
// 			type: 'CREATE_MODAL_UPDATE_PLACEHOLDER',
// 			payload: {
// 				modal,
// 				step,
// 				column,
// 				value
// 			}
// 		});
// 	};
// };

export const getClientInfo = (clientId) => {
	return (dispatch) => {
		console.log(clientId);
		dispatch({
			type: 'CREATE_MODAL_LOADING',
			payload: {
				state: 'start'
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/client/${clientId}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				// This is a quick fix because we're live and I don't have time to properly fix this.
				// For some (probably dumb) reason I changed the way we return data from getClientInfo()
				// on the backend from recordset to recordsets
				const prospectData = json[0][0];

				dispatch({
					type: 'CREATE_MODAL_AUTOFILL',
					payload: {
						json: prospectData
					}
				});
				dispatch({
					type: 'CREATE_MODAL_LOADING',
					payload: {
						state: null
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'CREATE_MODAL_LOADING',
					payload: {
						state: 'errorFetchClient'
					}
				});
			});
	};
};

export const getAccountExecs = (step, column, value) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_MODAL_LOADING_FIELD',
			payload: {
				step,
				column
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((accountExec) => ({
					key: accountExec.id,
					text: accountExec.fullName,
					value: accountExec.id
				}));
				dispatch({
					type: 'CREATE_MODAL_GET_DROPDOWN_OPTIONS',
					payload: {
						step: step,
						column: column,
						options: options
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const getMainContacts = (step, column, value, oppId, place) => {
	return (dispatch, getState) => {
		const state = getState();
		// Dispatch Loader
		dispatch({
			type: 'CREATE_MODAL_LOADING_FIELD',
			payload: {
				step,
				column
			}
		});

		let rowData = dispatch(rowIndex(oppId, place));
		let clientId = state.crm[place].data.opportunities[rowData].clientId;
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}&name=${value}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((mainContact) => ({
					key: mainContact.id,
					text: mainContact.fullName,
					value: mainContact.id
				}));
				dispatch({
					type: 'CREATE_MODAL_GET_DROPDOWN_OPTIONS',
					payload: {
						step: step,
						column: column,
						options: options
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const createCustomer = (oppId, place) => {
	return (dispatch, getState) => {
		const state = getState();

		// PUT variables
		let createModalFields = state.global.createCustomerModal.createModal.modalSteps;
		let payload = {
			name: createModalFields[0].modalFields[0].value,
			clientType: createModalFields[0].modalFields[1].value,
			industryId: createModalFields[0].modalFields[2].value,
			website: createModalFields[0].modalFields[3].value,
			address: createModalFields[1].modalFields[0].value,
			address2: createModalFields[1].modalFields[1].value,
			city: createModalFields[1].modalFields[2].value,
			zip: createModalFields[1].modalFields[3].value,
			state: createModalFields[1].modalFields[4].value,
			countryId: createModalFields[1].modalFields[5].value,
			accountExecutiveId: createModalFields[2].modalFields[1].value,
			leadSourceId: createModalFields[2].modalFields[2].value,
			mainContactId: createModalFields[2].modalFields[0].value,
			transition: true
		};
		let rowData = dispatch(rowIndex(oppId, place));
		let clientId = state.crm[place].data.opportunities[rowData].clientId;
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/client/${clientId}`;

		// Check for required fields
		let emptyRequiredFields = state.global.createCustomerModal.createModal.modalSteps.map((modalStep, StepIndex) =>
			modalStep.modalFields
				.map((field, fieldIndex) => {
					field.FieldIndex = fieldIndex;
					field.StepIndex = StepIndex;
					return field;
				})
				.filter((modalField) => modalField.required)
				.filter((modalField) => !modalField.value)
		);
		let checkFields = emptyRequiredFields.map((step) => step.length).filter((length) => length > 0)[0];

		// Run check on required fields
		if (checkFields > 0) {
			dispatch({
				type: 'CREATE_MODAL_CHECK_REQUIRED_FIELDS',
				payload: {
					emptyRequiredFields
				}
			});
			return;
		}
		console.log('check passed, run PUT');
		dispatch({
			type: 'CREATE_MODAL_LOADING',
			payload: {
				state: 'progress'
			}
		});
		customFetch(endpoint, {
			method: 'Put',
			body: JSON.stringify(payload)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				console.log('PUT SUCCESSSFUL');
				// Update frontend opportunities with this client attached
				let updateArray = state.crm[place].data.opportunities.filter(
					(opportunity) => opportunity.clientId === clientId
				);
				// See if pipeline or leads
				switch (place) {
					case 'pipeline':
						updateArray.forEach((update) => {
							let rowData = dispatch(rowIndex(update.id, place));
							dispatch({
								type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_UPDATE_COLUMN',
								payload: {
									value: 'Waiting For Approval',
									updateIndex: rowData,
									column: 'clientStatusName'
								}
							});
							dispatch({
								type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_UPDATE_COLUMN',
								payload: {
									value: 4,
									updateIndex: rowData,
									column: 'clientStatus'
								}
							});
						});
						dispatch(toggleCreateModal(null, null));
						break;
					case 'leads':
						updateArray.forEach((update) => {
							let rowData = dispatch(rowIndex(update.id, place));
							dispatch({
								type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_UPDATE_COLUMN',
								payload: {
									value: 'Waiting For Approval',
									updateIndex: rowData,
									column: 'clientStatusName'
								}
							});
							dispatch({
								type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_UPDATE_COLUMN',
								payload: {
									value: 4,
									updateIndex: rowData,
									column: 'clientStatus'
								}
							});
						});
						dispatch(toggleCreateModal(null, null));
						break;
				}
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				console.log('PUT ERROR');
				dispatch({
					type: 'CREATE_MODAL_LOADING',
					payload: {
						state: 'error'
					}
				});
			});
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_MODAL_LOADING',
			payload: {
				state: value
			}
		});
	};
};
