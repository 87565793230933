// Modules
import React from 'react';

// Components
import IdentifiedNeed from './components/IdentifiedNeed.js';
import Estimating from './components/Estimating.js';
import SubmittedQuote from './components/SubmittedQuote.js';
import HighProbability from './components/HighProbability.js';
import VerbalAward from './components/VerbalAward.js';
import '../../style.css';

let CardGroup = () => <div>Select a group to call (ie. CardGroup.IdentifiedNeed)</div>;

CardGroup.IdentifiedNeed = () => [<IdentifiedNeed key={'IdentifiedNeed'} />];

CardGroup.Estimating = () => [<Estimating key={'Estimating'} />];

CardGroup.SubmittedQuote = () => [<SubmittedQuote key={'SubmittedQuote'} />];

CardGroup.HighProbability = () => [<HighProbability key={'HighProbability'} />];

CardGroup.VerbalAward = () => [<VerbalAward key={'VerbalAward'} />];

export default CardGroup;
