import update from 'react-addons-update';

/**
 * The Leads state object
 * @namespace leads
 * @memberof App.Store.Reducers
 * @since 3.0.0
 */
export default (
	state = {
		opportunities: [],
		fetched: false
	},
	action
) => {
	switch (action.type) {
		case 'CRM_LEADS_UPDATE_OPPORTUNITIES':
			state = update(state, {
				opportunities: {
					$set: action.payload
				}
			});
			break;

		case 'CRM_LEADS_UPDATE_OPPORTUNITIES_FETCHED':
			state = update(state, {
				fetched: {
					$set: action.payload
				}
			});
			break;

		case 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_UPDATE_COLUMN':
			state = update(state, {
				opportunities: {
					[action.payload.updateIndex]: {
						[action.payload.column]: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_DEACTIVATE':
			state = update(state, {
				opportunities: {
					$splice: [[action.payload, 1]]
				}
			});
			break;
	}

	return state;
};
