const industryOptions = [
	{key: 1, value: 1, text: 'Agriculture'},
	{key: 2, value: 2, text: 'Architecture'},
	{key: 3, value: 3, text: 'Broadcasting'},
	{key: 4, value: 4, text: 'Cabling'},
	{key: 5, value: 5, text: 'Commerical Real Estate'},
	{key: 6, value: 6, text: 'Corrections'},
	{key: 7, value: 7, text: 'Education'},
	{key: 8, value: 8, text: 'Electrical Contractors'},
	{key: 9, value: 9, text: 'Finance/Banking'},
	{key: 10, value: 10, text: 'General Contractor'},
	{key: 11, value: 11, text: 'Government'},
	{key: 12, value: 12, text: 'Health Care'},
	{key: 13, value: 13, text: 'Hospitality'},
	{key: 14, value: 14, text: 'Insurance'},
	{key: 15, value: 15, text: 'Manufacturing'},
	{key: 16, value: 16, text: 'Modular Furniture'},
	{key: 17, value: 17, text: 'Other'},
	{key: 18, value: 18, text: 'Professional Services'},
	{key: 19, value: 19, text: 'Retail'},
	{key: 20, value: 20, text: 'Systems Integrator'},
	{key: 21, value: 21, text: 'Telecom'},
	{key: 22, value: 22, text: 'Utilities'},
	{key: 23, value: 23, text: 'Vendor'}
];

export default industryOptions;
