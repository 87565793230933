import React from 'react';
import {Grid, Segment, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ContactsSection from '../../../../../../../../components/contactsSection/contactsSection.js';
import WorkOverviewProgress from './components/workOverviewProgress.js';
import OrderDetailsSection from './components/orderDetailsSection/orderDetailsSection.js';
import SOWSection from './components/sowSection/sow.js';
import ActivityStream from '../../../../../../../../components/activityStream/ActivityStream.js';
import Tags from '../../../../../../../../components/tags/tags.tsx';
import ClosureComments from './components/closureComments.js';

class Overview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			recordId: 0
		};
	}

	componentDidMount() {
		this.setState({recordId: parseInt(this.props.workId)});
	}

	componentDidUpdate() {
		if (this.state.recordId !== parseInt(this.props.workId)) {
			this.setState({recordId: parseInt(this.props.workId)});
		}
	}

	render() {
		return (
			<Grid columns={2} style={{marginTop: '2.5em'}}>
				<Grid.Row style={{margin: '0', padding: '0'}}>
					<Grid.Column width={16}>
						<WorkOverviewProgress />
					</Grid.Column>
				</Grid.Row>
				{this.props.workId ? (
					<Grid.Row>
						<Grid.Column width={6}>
							{this.props.closureComments ? (
								<ClosureComments comment={this.props.closureComments} stage={this.props.stage} />
							) : null}
							{this.state.recordId ? (
								<>
									<ActivityStream
										type="work"
										id={this.props.workId}
										options={[
											{
												key: 'note',
												text: 'Note',
												value: 'Note'
											},
											{
												key: 'phoneCall',
												text: 'Phone Call',
												value: 'Phone Call'
											},
											{
												key: 'quote',
												text: 'Quote',
												value: 'Quote'
											},
											{
												key: 'voicemail',
												text: 'Voicemail',
												value: 'Voicemail'
											},
											{
												key: 'update',
												text: 'Update',
												value: 'Update'
											}
										]}
									/>
								</>
							) : null}
							<Tags workId={this.props.workId} />
						</Grid.Column>
						<Grid.Column width={10}>
							<OrderDetailsSection orderDetails={this.props.orderDetails} />
							<SOWSection id={this.props.workId} />
							<ContactsSection type={'work'} typeId={this.props.workId} />
						</Grid.Column>
					</Grid.Row>
				) : null}
			</Grid>
		);
	}
}

Overview.propTypes = {
	getWork: PropTypes.func,
	orderDetails: PropTypes.object,
	workId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const mapStateToProps = (state) => {
	return {
		orderDetails: state.work.workRecord.view.overview.orderDetails,
		workId: state.work.workRecord.view.id,
		closureComments: state.work.workRecord.view.record.closureComments,
		isDesynced: state.work.workRecord.view.record.desynced,
		stage: state.work.workRecord.view.overview.progressStep.activeStep
	};
};

export default connect(mapStateToProps, null)(Overview);
