import moment from 'moment';
import {v4 as uuid} from 'uuid';
import customFetch from '../../../../../lib/old/customFetch.js';
import {addSearchContact, createContact} from '../../../../../components/contactModal/services/contactModalActions.js';
import {validateGoogleDriveLink, hasName} from '../../../../../lib/old/validation.js';
import branchOptions from '../../../../../constants/branches.js';
import {createTrip} from '../../WorkOrder-Project/scenes/workRecordView/services/workRecordActions.js';

export const toggleCreateWorkOrderModal = () => {
	return (dispatch, getState) => {
		const value = !getState().work.createWorkOrder.createWorkOrderModal.toggleCreateWorkOrderModal;
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_TOGGLE',
			payload: {
				value
			}
		});
		dispatch({
			type: 'CREATE_WORK_CLEAR_TEMP_CONTACT'
		});

		// Autopopulate create work fields
		dispatch(autopopulateNDSCreateWorkOrder());

		if (value) {
			dispatch({
				type: 'CREATE_WORK_UPDATE_TEMP_AASDI_TRIP',
				payload: {
					value: []
				}
			});
			dispatch({
				type: 'CREATE_WORK_UPDATE_TEMP_WORK_ORDER',
				payload: {
					value: []
				}
			});
		}
	};
};

export const toggleAfterCreateModal = (value) => (dispatch) => {
	dispatch({
		type: 'AFTER_CREATE_WORK_MODAL_TOGGLE',
		payload: {
			value
		}
	});
};

export const validateAndSetPONumber =
	(modal, sectionIndex, fieldIndex, value, error, inputType, object, quotedJobNumber) => (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				loading: true,
				value
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?PONumber=${value}`;
		if (quotedJobNumber) {
			endpoint += `&quotedJobNumber=${quotedJobNumber}`;
		}

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_UPDATE',
					payload: {
						modal,
						sectionIndex,
						fieldIndex,
						value,
						error: json[0].amount > 0,
						inputType,
						object
					}
				});

				dispatch({
					type: 'CREATE_WORK_ORDER_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false,
						value
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false,
						value
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};

export const updateCreateWorkOrderModal = (modal, sectionIndex, fieldIndex, value, error, inputType, object) => {
	return (dispatch, getState) => {
		const fieldName = getState().work.createWorkOrder.createWorkOrderModal.sections[sectionIndex].fields[fieldIndex]
			? getState().work.createWorkOrder.createWorkOrderModal.sections[sectionIndex].fields[fieldIndex].name
			: null;

		if (inputType === 'Location') {
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_LOCATION_UPDATE',
				payload: {
					modal,
					sectionIndex,
					fieldIndex,
					value,
					error,
					inputType,
					locationObject: object
				}
			});
		} else if (inputType === 'BillingContact') {
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_BILLING_CONTACT_UPDATE',
				payload: {
					modal,
					sectionIndex,
					fieldIndex,
					value,
					error,
					inputType,
					contactObject: object
				}
			});
		} else {
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_UPDATE',
				payload: {
					modal,
					sectionIndex,
					fieldIndex,
					value,
					error,
					inputType,
					object
				}
			});

			if (inputType === 'Client') {
				dispatch(getAutoFillData(value));
			}
			// if (fieldName === 'Sales Representative') {
			// 	dispatch(getSalesRepresentativeData(value));
			// }
		}
	};
};

export const getAutoFillData = (clientId) => (dispatch, getState) => {
	console.log(`hit inside getAutoFillData`);

	// sets master client
	let sections = getState().work.createWorkOrder.createWorkOrderModal.sections;
	const sectionIndex = sections.findIndex(hasName('workOrderOverviewSection'));

	const clientOptions = sections.find(hasName('workOrderOverviewSection')).fields.find(hasName('Client')).options;

	const selectedClientOption = clientOptions.find((elm) => elm.value === clientId);

	console.log(`selectedClientOption - ${JSON.stringify(selectedClientOption)}`);

	const masterClientIndex = sections[sectionIndex].fields.findIndex(hasName('Master Client'));

	dispatch({
		type: 'CREATE_WORK_ORDER_GET_CLIENT_DROPDOWN_OPTIONS',
		payload: {
			sectionIndex,
			fieldIndex: masterClientIndex,
			value: clientId,
			options: [selectedClientOption]
		}
	});

	// sets account manager and sales rep
	let accountManagerIndex = sections[sectionIndex].fields.findIndex(hasName('Account Manager (Sales Rep)'));
	let salesRepIndex = sections[sectionIndex].fields.findIndex(hasName('Sales Representative'));

	dispatch({
		type: 'CREATE_WORK_ORDER_LOADING_FIELD',
		payload: {
			sectionIndex,
			fieldIndex: accountManagerIndex,
			loading: true
		}
	});

	dispatch({
		type: 'CREATE_WORK_ORDER_LOADING_FIELD',
		payload: {
			sectionIndex,
			fieldIndex: salesRepIndex,
			loading: true
		}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/client/${clientId}`;

	customFetch(endpoint)
		.then((response) => {
			if (response.status !== 200) {
				return Promise.reject(response);
			}

			return response.json();
		})
		.then((json) => {
			let clientInfo = json[0][0];
			let accountManagerOptions = [
				{
					key: clientInfo.accountManagerId,
					text: clientInfo.accountManagerName,
					value: clientInfo.accountManagerId
				}
			];

			let accountExecutiveOptions = [
				{
					key: clientInfo.accountExecutiveId,
					text: clientInfo.accountExecutiveName,
					value: clientInfo.accountExecutiveId
				}
			];

			dispatch(autopopulateWorkOrderBranch(clientInfo.accountExecutiveBranchId));

			dispatch({
				type: 'LOAD_ACCOUNT_MANAGER_FIELD',
				payload: {
					sectionIndex: sectionIndex,
					fieldIndex: accountManagerIndex,
					loading: false,
					value: accountManagerOptions[0].value,
					options: accountManagerOptions
				}
			});

			dispatch({
				type: 'LOAD_SALES_REPRESENTATIVE_FIELD',
				payload: {
					sectionIndex: sectionIndex,
					fieldIndex: salesRepIndex,
					loading: false,
					value: accountExecutiveOptions[0].value,
					options: accountExecutiveOptions
				}
			});

			dispatch({
				type: 'CREATE_WORK_ORDER_LOADING_FIELD',
				payload: {
					sectionIndex,
					fieldIndex: accountManagerIndex,
					loading: false,
					value: accountManagerOptions[0].value
				}
			});

			dispatch({
				type: 'CREATE_WORK_ORDER_LOADING_FIELD',
				payload: {
					sectionIndex,
					fieldIndex: salesRepIndex,
					loading: false,
					value: accountExecutiveOptions[0].value
				}
			});
		})
		.catch((err) => {
			dispatch({
				type: 'ERROR',
				payload: err.stack
			});
			dispatch({
				type: 'CREATE_WORK_ORDER_LOADING_FIELD',
				payload: {
					sectionIndex,
					fieldIndex: accountManagerIndex,
					loading: false
				}
			});
			dispatch({
				type: 'CREATE_WORK_ORDER_LOADING_FIELD',
				payload: {
					sectionIndex,
					fieldIndex: salesRepIndex,
					loading: false
				}
			});
		});
};

export const getWorkOrderEmployees = (sectionIndex, fieldIndex, search) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_WORK_ORDER_SEARCH_LOADING',
			payload: {
				sectionIndex,
				fieldIndex,
				search
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${search}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((employees) => {
				let options = employees.map((employee) => ({
					key: employee.id,
					text: employee.fullName,
					value: employee.id,
					branchId: employee.branchId
				}));

				dispatch({
					type: 'CREATE_WORK_ORDER_SEARCH_FINISH',
					payload: {
						sectionIndex,
						fieldIndex,
						options
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_SEARCH_FINISH',
					payload: {
						sectionIndex,
						fieldIndex,
						options: []
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const getWorkOrderClients = (sectionIndex, fieldIndex, search) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_WORK_ORDER_SEARCH_LOADING',
			payload: {
				sectionIndex,
				fieldIndex,
				search
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/client?clientSearch=${encodeURIComponent(search)}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				console.log(response);
				return response.json();
			})
			.then((json) => {
				let options = json.map((workOrderLocations) => ({
					key: workOrderLocations.id,
					text: `${workOrderLocations.name}, ${workOrderLocations.spectrumCode}`,
					value: workOrderLocations.id,
					spectrumCode: workOrderLocations.spectrumCode
				}));

				dispatch({
					type: 'CREATE_WORK_ORDER_SEARCH_FINISH',
					payload: {
						sectionIndex,
						fieldIndex,
						options
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_SEARCH_FINISH',
					payload: {
						sectionIndex,
						fieldIndex,
						options: []
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const getQuotedJobNumber = (sectionIndex, fieldIndex, search) => {
	return (dispatch) => {
		console.log(`hit inside getQuotedJobNumber`);
		// Dispatch Loader
		dispatch({
			type: 'CREATE_WORK_ORDER_SEARCH_LOADING',
			payload: {
				sectionIndex,
				fieldIndex,
				search
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=search-quoted-job-number&value=${search}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				console.log(response);
				return response.json();
			})
			.then((json) => {
				let options = json.map((result, index) => ({
					key: index,
					text: result.workNumber,
					value: result.workNumber
				}));

				dispatch({
					type: 'CREATE_WORK_ORDER_SEARCH_FINISH',
					payload: {
						sectionIndex,
						fieldIndex,
						options
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_SEARCH_FINISH',
					payload: {
						sectionIndex,
						fieldIndex,
						options: []
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const autopopulateNDSCreateWorkOrder = () => (dispatch, getState) => {
	const user = getState().auth.account;
	const createWorkOrderModal = getState().work.createWorkOrder.createWorkOrderModal;
	const sectionIndex = createWorkOrderModal.sections.findIndex(hasName('workOrderOverviewSection'));
	const fieldIndex = createWorkOrderModal.sections[sectionIndex].fields.findIndex(hasName('Project Administrator'));

	const userData = {
		options: [
			{
				key: user.accountExecutiveId,
				value: user.accountExecutiveId,
				text: `${user.firstName} ${user.lastName}`
			}
		]
	};
	dispatch({
		type: 'CREATE_WORK_ORDER_GET_EMPLOYEE_DROPDOWN_OPTIONS',
		payload: {
			sectionIndex,
			fieldIndex,
			value: userData.options[0].value,
			options: userData.options
		}
	});

	dispatch({
		type: 'CREATE_WORK_ORDER_MODAL_UPDATE',
		payload: {
			modal: 'createWorkOrderModal',
			sectionIndex,
			fieldIndex,
			value: userData.options[0].value,
			error: false
		}
	});
};

export const autopopulateWorkOrderBranch = (branchId) => (dispatch, getState) => {
	const createWorkOrderModal = getState().work.createWorkOrder.createWorkOrderModal;
	const sectionIndex = createWorkOrderModal.sections.findIndex(hasName('workOrderOverviewSection'));
	const fieldIndex = createWorkOrderModal.sections[sectionIndex].fields.findIndex(hasName('Branch'));

	dispatch({
		type: 'CREATE_WORK_ORDER_MODAL_UPDATE',
		payload: {
			modal: 'createWorkOrderModal',
			sectionIndex,
			fieldIndex,
			value: branchId,
			error: false
		}
	});
};

export const getSalesRepresentativeData = (accountExecutiveId) => (dispatch, getState) => {
	let sections = getState().work.createWorkOrder.createWorkOrderModal.sections;
	const sectionIndex = sections.findIndex(hasName('workOrderOverviewSection'));
	const salesRepresentativeIndex = sections[sectionIndex].fields.findIndex(hasName('Sales Representative'));

	dispatch({
		type: 'CREATE_WORK_ORDER_LOADING_FIELD',
		payload: {
			sectionIndex: sectionIndex,
			fieldIndex: salesRepresentativeIndex,
			loading: true
		}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?employeeId=${accountExecutiveId}`;

	customFetch(endpoint)
		.then((response) => {
			if (response.status !== 200) {
				return Promise.reject(response);
			}

			return response.json();
		})
		.then((json) => {
			dispatch({
				type: 'CREATE_WORK_ORDER_LOADING_FIELD',
				payload: {
					sectionIndex: sectionIndex,
					fieldIndex: salesRepresentativeIndex,
					loading: false,
					value: json.branchId
				}
			});
		})
		.catch((err) => {
			dispatch({
				type: 'ERROR',
				payload: err.stack
			});
			dispatch({
				type: 'CREATE_WORK_ORDER_LOADING_FIELD',
				payload: {
					sectionIndex: sectionIndex,
					fieldIndex: salesRepresentativeIndex,
					loading: false
				}
			});
		});
};

export const getWorkOrderLocations = (sectionIndex, fieldIndex, value, clientId) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_WORK_ORDER_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				loading: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations?limit=8&clientId=${clientId}&search=${value}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_GET_LOCATIONS_DROPDOWN_OPTIONS',
					payload: {
						value,
						sectionIndex,
						fieldIndex,
						json
					}
				});
				dispatch({
					type: 'CREATE_WORK_ORDER_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const getWorkOrderBillingContacts = (sectionIndex, fieldIndex, value, clientId) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_WORK_ORDER_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				value,
				loading: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}&name=${value}&type=1`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_GET_BILLING_CONTACTS_DROPDOWN_OPTIONS',
					payload: {
						value,
						sectionIndex,
						fieldIndex,
						json
					}
				});
				dispatch({
					type: 'CREATE_WORK_ORDER_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const toggleProjectCostingSpreadsheetModal = (sectionIndex, spreadSheetData) => {
	return (dispatch, getState) => {
		const value =
			!getState().work.createWorkOrder.createWorkOrderModal.sections[sectionIndex].projectCostingSpreadsheetModal
				.toggle;
		dispatch({
			type: 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_TOGGLE',
			payload: {
				sectionIndex,
				spreadSheetData,
				value
			}
		});
	};
};

export const addDocument = (fileName, googleDrive, customerApproval, comments, sectionIndex) => {
	return (dispatch) => {
		if (!fileName.value || !googleDrive.value || !validateGoogleDriveLink(googleDrive.value)) {
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_UPDATE',
				payload: {
					modal: 'documentSection',
					sectionIndex,
					fieldIndex: fileName.value ? 1 : 0,
					error: true
				}
			});
		} else {
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_ADD_DOCUMENT',
				payload: {
					sectionIndex,
					object: {
						fileName: fileName.value,
						googleDrive: googleDrive.value,
						customerApproval: customerApproval.value,
						comments: comments.value
					}
				}
			});
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_CHECK_REQUIRED_DOCUMENTS',
				payload: {sectionIndex: 4, hasError: false}
			});
		}
	};
};

export const updateCreateWorkOrderModalDocumentGrid = (rowIndex, sectionIndex, value) => {
	return (dispatch) =>
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_UPDATE_DOC_GRID',
			payload: {
				sectionIndex,
				rowIndex,
				value
			}
		});
};

export const removeCreateWorkOrderModalDocumentGrid = (rowIndex, sectionIndex) => {
	return (dispatch) =>
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_REMOVE_DOC_GRID',
			payload: {
				sectionIndex,
				rowIndex
			}
		});
};

export const getWorkOrderAASDI = (sectionIndex, name, zip, radius, gridApi) => async (dispatch) => {
	// Loader
	if (gridApi) {
		gridApi.showLoadingOverlay();
	}

	if (radius === '>200') {
		radius = 'ANY';
	}

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/locations/${zip}?radius=ANY`;
	if (name) {
		endpoint += `&name=${name}`;
	}

	dispatch({
		type: 'CREATE_WORK_ORDER_MODAL_ZIP_ERROR_TOGGLE',
		payload: {
			sectionIndex,
			value: false
		}
	});

	const searchAASDI = await customFetch(endpoint);
	let searchAASDIjson = await searchAASDI.json();

	if (searchAASDI.status !== 200) {
		if (searchAASDIjson.error === 'No results') {
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_ZIP_ERROR_TOGGLE',
				payload: {
					sectionIndex,
					value: false
				}
			});
			dispatch({
				type: 'CREATE_WORK_ORDER_GET_AASDI_DROPDOWN_OPTIONS',
				payload: {
					sectionIndex,
					json: []
				}
			});
			// gridApi.hideOverlay();
		} else {
			dispatch({
				type: 'CREATE_WORK_ORDER_GET_AASDI_DROPDOWN_OPTIONS',
				payload: {
					sectionIndex,
					json: []
				}
			});
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_ZIP_ERROR_TOGGLE',
				payload: {
					sectionIndex,
					value: true
				}
			});
			dispatch({
				type: 'CREATE_WORK_ORDER_LOADING_AASDI_MODAL',
				payload: {
					sectionIndex,
					loading: false
				}
			});
			// gridApi.hideOverlay();
		}

		return Promise.reject(searchAASDI.response);
	}

	if (searchAASDIjson[1].length > 0) {
		if (gridApi) {
			gridApi.hideOverlay();
		}
		dispatch({
			type: 'CREATE_WORK_ORDER_LOADING_AASDI_MODAL',
			payload: {
				sectionIndex,
				loading: false
			}
		});
		dispatch({
			type: 'CREATE_WORK_ORDER_GET_AASDI_DROPDOWN_OPTIONS',
			payload: {
				sectionIndex,
				json:
					radius === 'ANY'
						? searchAASDIjson[1]
						: searchAASDIjson[1].slice(
								0,
								searchAASDIjson[0][
									searchAASDIjson[0].findIndex((distance) => distance.radius === radius)
								].index
						  )
			}
		});
		if (gridApi) {
			gridApi.hideOverlay();
		}
	}
};

export const workOrderModalZipErrorToggle = (sectionIndex, value) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_ZIP_ERROR_TOGGLE',
			payload: {
				sectionIndex,
				value
			}
		});
	};
};

export const workOrderModalSelectAASDI = (sectionIndex, selectedAASDIArray, AASDIArray) => {
	return (dispatch) => {
		// Check for duplicates on selected aasdis and existing aasdis
		const checkDuplicates = selectedAASDIArray
			.map((selected) => AASDIArray.filter((aasdi) => aasdi.id === selected.id))
			.filter((checked) => checked.length > 0)
			.map((array) => array[0]);
		// Value already exists on aasdis, remove from selected AASDIs, push the rest
		if (checkDuplicates.length) {
			selectedAASDIArray = selectedAASDIArray.filter((val) => !checkDuplicates.includes(val));
		}
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_ADD_AASDI',
			payload: {
				sectionIndex,
				selectedAASDIArray
			}
		});
	};
};

export const workOrderModalRemoveSelectAASDI = (index, sectionIndex) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_REMOVE_AASDI',
			payload: {
				index,
				sectionIndex
			}
		});
	};
};

export const workOrderModalRemoveAllAASDI = (sectionIndex) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_REMOVE_ALL_AASDI',
			payload: {
				sectionIndex
			}
		});
	};
};

export const setWorkOrderModalAASDINameSearch = (modal, sectionIndex, search) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_SELECT_SEARCH',
			payload: {
				modal,
				sectionIndex,
				search
			}
		});
	};
};

export const setWorkOrderModalAASDIZipSearch = (modal, sectionIndex, zip) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_SELECT_ZIP',
			payload: {
				modal,
				sectionIndex,
				zip
			}
		});
	};
};

export const setWorkOrderModalAASDISearchRadius = (modal, sectionIndex, radius) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_SELECT_RADIUS',
			payload: {
				modal,
				sectionIndex,
				radius
			}
		});
	};
};

export const updateAASDIGridColumn = (sectionIndex, rowIndex, column, value) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_AASDI_GRID_UPDATE',
			payload: {
				sectionIndex,
				rowIndex,
				column,
				value
			}
		});
	};
};

export const removeAASDI = (rowIndex, sectionIndex) => {
	return (dispatch) =>
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_REMOVE_AASDI',
			payload: {
				sectionIndex,
				rowIndex
			}
		});
};

export const updateProjectCostingModalGrid = (rowIndex, changes) => ({
	type: 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_UPDATE',
	payload: {
		rowIndex,
		value: changes
	}
});

export const getRowNodeData = (sectionIndex, rowIndex) => {
	return (dispatch, getState) => {
		const rowData =
			getState().work.createWorkOrder.createWorkOrderModal.sections[sectionIndex].projectCostingSpreadsheetModal
				.spreadSheetData[rowIndex];
		return rowData;
	};
};

export const addRowSpreadsheet = () => ({
	type: 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_ADD_ROW',
	payload: {
		object: {
			id: uuid(),
			isFixedRevenue: true,
			description: '',
			manufacturer: '',
			partNumber: '',
			quantity: 1,
			unit: 'EA',
			unitMaterialCost: 0,
			materialResell: 0,
			unitLaborCost: 0,
			laborResell: 0
		}
	}
});

export const removeRowSpreadsheet = (rowIndex) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_REMOVE_ROW',
			payload: rowIndex
		});
	};
};

export const selectSKUModalToggle = (sectionIndex) => {
	return (dispatch, getState) => {
		const toggle =
			!getState().work.createWorkOrder.createWorkOrderModal.sections[sectionIndex].projectCostingSpreadsheetModal
				.selectSKUModal.toggle;
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_SELECT_SKU_MODAL_TOGGLE',
			payload: {
				sectionIndex,
				toggle
			}
		});
	};
};

export const selectSKUModalAddItems = (skus) => ({
	type: 'CREATE_WORK_ORDER_MODAL_SELECT_SKU_MODAL_ADD_ITEMS',
	payload: skus
});

export const completeProjectCostingSpreadsheet = (sectionIndex, rowData) => {
	return (dispatch) => {
		const filteredRowData = rowData.filter((item) => item.quantity);

		/// CALCULATE SUMMARY DATA
		let summary = {};

		const totals = filteredRowData.reduce(
			(accum, val) => {
				accum.cost += val.unitMaterialCost * val.quantity + val.unitLaborCost * val.quantity;
				accum.resell += val.materialResell + val.laborResell;
				return accum;
			},
			{
				resell: 0,
				cost: 0
			}
		);

		/// TOTAL RESELL
		summary.totalResell = '$' + totals.resell.toFixed(2);

		/// TOTAL COST
		summary.totalCost = '$' + totals.cost.toFixed(2);

		/// TOTAL GROSS PROFIT
		summary.totalGrossProfit = '$' + (totals.resell - totals.cost).toFixed(2);

		dispatch({
			type: 'CREATE_WORK_ORDER_COMPLETE_PROJECT_COSTING_SPREADSHEET',
			payload: {
				sectionIndex,
				rowData: filteredRowData,
				summary
			}
		});

		filteredRowData.forEach(function (SKU) {
			if (SKU.materialResell > 0 || SKU.laborResell > 0) {
				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_CHECK_RESELL',
					payload: {
						sectionIndex: 2,
						error: false
					}
				});
			} else {
				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_CHECK_RESELL',
					payload: {
						sectionIndex: 2,
						error: true
					}
				});
			}
		});

		dispatch(toggleProjectCostingSpreadsheetModal(sectionIndex, filteredRowData));
	};
};

export const cancelProjectCostingSpreadsheet = (sectionIndex) => (dispatch, getState) => {
	const useSavedRowData = getState().work.createWorkOrder.createWorkOrderModal.sections[sectionIndex].rowData;
	console.log(useSavedRowData);
	dispatch(toggleProjectCostingSpreadsheetModal(sectionIndex, useSavedRowData));
};

export const updateScopeOfWork = (sectionIndex, value) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_UPDATE_SCOPE_OF_WORK',
			payload: {
				sectionIndex,
				value
			}
		});
	};
};

/// WORK CONTACT
export const getContacts = (id) => {
	return (dispatch, getState) => {
		const sectionIndex = getState().work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'contactSection'
		);
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_UPDATE_CONTACTS',
			payload: {
				value: true,
				column: 'loading',
				sectionIndex
			}
		});

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/contacts?contactType=client&clientId=${id}&type=1`)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_UPDATE_CONTACTS',
					payload: {
						column: 'contacts',
						value: json,
						sectionIndex
					}
				});

				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_UPDATE_CONTACTS',
					payload: {
						value: false,
						column: 'loading',
						sectionIndex
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_UPDATE_CONTACTS',
					payload: {
						value: err,
						column: 'error',
						sectionIndex
					}
				});
				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_UPDATE_CONTACTS',
					payload: {
						value: false,
						column: 'loading',
						sectionIndex
					}
				});
			});
	};
};

export const deleteContact = (oppId, contactId, index) => {
	return (dispatch, getState) => {
		const sectionIndex = getState().work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'contactSection'
		);
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_CONTACTS_DELETE_ITEM',
			payload: {index, sectionIndex}
		});
	};
};

export const addContactWorkOrder = (data) => {
	return (dispatch, getState) => {
		let sectionIndex = getState().work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'contactSection'
		);
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_CONTACTS_ADD_ITEM',
			payload: {
				data,
				sectionIndex
			}
		});
	};
};

export const addContactWorkOrderAASDI = (data) => {
	return (dispatch, getState) => {
		let sectionIndex = getState().work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'AASDISection'
		);
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_AASDI_ADD_CONTACT',
			payload: {
				value: data.value,
				column: 'contact',
				sectionIndex,
				rowIndex: data.rowIndex
			}
		});
	};
};

export const updateContactItemWorkOrder = (payload) => {
	return (dispatch, getState) => {
		const sectionIndex = getState().work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'contactSection'
		);
		const contactIndex = getState().work.createWorkOrder.createWorkOrderModal.sections[
			sectionIndex
		].contacts.findIndex((contact) => contact.id === payload.id);
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_CONTACTS_ITEM_UPDATE',
			payload: {
				contactItem: payload,
				sectionIndex,
				contactIndex
			}
		});
	};
};

export const getSKUs =
	(clientId = 0, sectionIndex) =>
	async (dispatch) => {
		// dispatch loading
		dispatch({
			type: 'CREATE_WORK_ORDER_GET_SKU_LOADED',
			payload: {sectionIndex, skuLoaded: false}
		});

		// get GlobalSKUs
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/sku?clientId=${clientId}&includeGlobal=true&isActive=true`;
		const callEndPoint = await customFetch(endpoint);
		const response = await callEndPoint.json();

		dispatch({
			type: 'CREATE_WORK_ORDER_GET_SKUS',
			payload: {
				sectionIndex,
				skuArray: response,
				skuLoaded: true
			}
		});
	};

export const getWorkOrderTemplates = (sectionIndex, fieldIndex, value) => {
	return async (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_WORK_ORDER_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				value,
				loading: true
			}
		});

		////////////////
		///// HERE /////
		////////////////

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=work-templates&search=${value}`;

		const call = await customFetch(endpoint);
		const response = await call.json();

		let options = [];

		if (response.length > 0) {
			options = response.map((template, index) => {
				template.template = JSON.parse(template.template);
				return {
					key: index,
					text: template.name,
					value: {
						// template.template
						accountExecutiveId:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Sales Representative'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Sales Representative'
								)
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Sales Representative'
										)
								  ].value
								: null,
						accountExecutive_Name:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Sales Representative'
								)
							].options &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Sales Representative'
								)
							].options[
								template.template.overviewSection[
									template.template.overviewSection.findIndex(
										(field) => field.name === 'Sales Representative'
									)
								].options.findIndex(
									(option) =>
										option.key ===
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Sales Representative'
											)
										].value
								)
							]
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Sales Representative'
										)
								  ].options[
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Sales Representative'
											)
										].options.findIndex(
											(option) =>
												option.key ===
												template.template.overviewSection[
													template.template.overviewSection.findIndex(
														(field) => field.name === 'Sales Representative'
													)
												].value
										)
								  ].text
								: null,
						billingContactId:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							].billingContactSelected
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Billing Contact'
										)
								  ].selectedBillingContact.id
								: null,
						billingContact_Email:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							].billingContactSelected
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Billing Contact'
										)
								  ].selectedBillingContact.email
								: null,
						billingContact_Name:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							].billingContactSelected
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Billing Contact'
										)
								  ].selectedBillingContact.fullName
								: null,
						billingContact_Phone:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							].billingContactSelected
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Billing Contact'
										)
								  ].selectedBillingContact.phone
								: null,
						billingContact_Title:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Billing Contact')
							].billingContactSelected
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Billing Contact'
										)
								  ].selectedBillingContact.Title
								: null,
						clientId:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Client')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Client')
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex((field) => field.name === 'Client')
								  ].value
								: null,
						client_Name:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Client')
							].options &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Client')
							].options[
								template.template.overviewSection[
									template.template.overviewSection.findIndex((field) => field.name === 'Client')
								].options.findIndex(
									(option) =>
										option.key ===
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Client'
											)
										].value
								)
							]
								? template.template.overviewSection[
										template.template.overviewSection.findIndex((field) => field.name === 'Client')
								  ].options[
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Client'
											)
										].options.findIndex(
											(option) =>
												option.key ===
												template.template.overviewSection[
													template.template.overviewSection.findIndex(
														(field) => field.name === 'Client'
													)
												].value
										)
								  ].text
								: null,
						googleDriveLink:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Google Drive Path'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Google Drive Path'
								)
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Google Drive Path'
										)
								  ].value
								: null,
						isDraft: false,
						//   isGroupBillable
						isNewConstruction:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'New Construction'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'New Construction'
								)
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'New Construction'
										)
								  ].value === 1
								: false,
						//  isProject ?
						locationId:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							].selectedLocation
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Location'
										)
								  ].selectedLocation.id
								: null,
						location_Address:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							].selectedLocation
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Location'
										)
								  ].selectedLocation.address
								: null,
						location_Address2:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							].selectedLocation
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Location'
										)
								  ].selectedLocation.address2
								: null,
						location_City:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							].selectedLocation
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Location'
										)
								  ].selectedLocation.city
								: null,
						location_Name:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							].selectedLocation
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Location'
										)
								  ].selectedLocation.name
								: null,
						location_State:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							].selectedLocation
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Location'
										)
								  ].selectedLocation.state
								: null,
						location_StoreNumber:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							].selectedLocation
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Location'
										)
								  ].selectedLocation.storeNumber
								: null,
						location_Zip:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Location')
							].selectedLocation
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Location'
										)
								  ].selectedLocation.zip
								: null,
						ndsId:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Project Administrator'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Project Administrator'
								)
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Project Administrator'
										)
								  ].value
								: null,
						nds_Name:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Project Administrator'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Project Administrator'
								)
							].value &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Project Administrator'
								)
							].options[
								template.template.overviewSection[
									template.template.overviewSection.findIndex(
										(field) => field.name === 'Project Administrator'
									)
								].options.findIndex(
									(option) =>
										option.key ===
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Project Administrator'
											)
										].value
								)
							]
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Project Administrator'
										)
								  ].options[
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Project Administrator'
											)
										].options.findIndex(
											(option) =>
												option.key ===
												template.template.overviewSection[
													template.template.overviewSection.findIndex(
														(field) => field.name === 'Project Administrator'
													)
												].value
										)
								  ].text
								: null,
						projectManagerId:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Project Manager')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Project Manager')
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Project Manager'
										)
								  ].value
								: null,
						projectManager_Name:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Project Manager')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Project Manager')
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Project Manager'
										)
								  ].options[
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Project Manager'
											)
										].options.findIndex(
											(option) =>
												option.key ===
												template.template.overviewSection[
													template.template.overviewSection.findIndex(
														(field) => field.name === 'Project Manager'
													)
												].value
										)
								  ].text
								: null,
						purchaseOrderNumber:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Purchase Order Number'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Purchase Order Number'
								)
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Purchase Order Number'
										)
								  ].value
								: null,
						quotedJobNumber:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Quoted Job Number (Optional)'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Quoted Job Number (Optional)'
								)
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Quoted Job Number (Optional)'
										)
								  ].value
								: null,
						scopeOfWork: template.template.scopeOfWorkSection ? template.template.scopeOfWorkSection : null,
						serviceName:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Service Type')
							].options &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Service Type')
							].options[
								template.template.overviewSection[
									template.template.overviewSection.findIndex(
										(field) => field.name === 'Service Type'
									)
								].options.findIndex(
									(option) =>
										option.key ===
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Service Type'
											)
										].value
								)
							]
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Service Type'
										)
								  ].options[
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Service Type'
											)
										].options.findIndex(
											(option) =>
												option.key ===
												template.template.overviewSection[
													template.template.overviewSection.findIndex(
														(field) => field.name === 'Service Type'
													)
												].value
										)
								  ].text
								: null,
						serviceType:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Service Type')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Service Type')
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Service Type'
										)
								  ].value
								: null,
						spectrumCode:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Client')
							].options &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Client')
							].options[
								template.template.overviewSection[
									template.template.overviewSection.findIndex((field) => field.name === 'Client')
								].options.findIndex(
									(option) =>
										option.key ===
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Client'
											)
										].value
								)
							]
								? template.template.overviewSection[
										template.template.overviewSection.findIndex((field) => field.name === 'Client')
								  ].options[
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) => field.name === 'Client'
											)
										].options.findIndex(
											(option) =>
												option.key ===
												template.template.overviewSection[
													template.template.overviewSection.findIndex(
														(field) => field.name === 'Client'
													)
												].value
										)
								  ].spectrumCode
								: null,
						workCategory:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Select Work Category'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Select Work Category'
								)
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Select Work Category'
										)
								  ].value
								: null,
						accountManagerId:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
								)
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
								)
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
										)
								  ].value
								: null,
						accountManager_Name:
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
								)
							].options &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex(
									(field) => field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
								)
							].options[
								template.template.overviewSection[
									template.template.overviewSection.findIndex(
										(field) => field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
									)
								].options.findIndex(
									(option) =>
										option.key ===
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) =>
													field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
											)
										].value
								)
							]
								? template.template.overviewSection[
										template.template.overviewSection.findIndex(
											(field) => field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
										)
								  ].options[
										template.template.overviewSection[
											template.template.overviewSection.findIndex(
												(field) =>
													field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
											)
										].options.findIndex(
											(option) =>
												option.key ===
												template.template.overviewSection[
													template.template.overviewSection.findIndex(
														(field) =>
															field.name === 'Account Manager' ||
															'Account Manager (Sales Rep)'
													)
												].value
										)
								  ].text
								: null,
						branch:
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Branch')
							] &&
							template.template.overviewSection[
								template.template.overviewSection.findIndex((field) => field.name === 'Branch')
							].value
								? template.template.overviewSection[
										template.template.overviewSection.findIndex((field) => field.name === 'Branch')
								  ].value
								: null,
						projectCosting: template.template.projectCostingSection
							? template.template.projectCostingSection
							: null
					}
				};
			});
		}

		//Yes i know this is really jank im sorry.
		//Need to correct the sku items, they arent right on the back end and fixing them in stringified json is a nightmare
		options.forEach((opt) => {
			const skus = opt.value.projectCosting.rowData;
			opt.value.projectCosting.rowData = skus.map((sku) => ({
				id: uuid(),
				isFixedRevenue: sku.isFixedRevenue || true,
				description: sku.SKU //stuff used to be stored in a sku object. Eventually we need to do some kind of transform on all the json in our database
					? sku.SKU.description || ''
					: sku.description || '',
				manufacturer: sku.manufacturer || '',
				partNumber: sku.partNumber || '',
				quantity: sku.quantity || 1,
				unit: sku.unit || 'EA',
				unitMaterialCost: sku.unitMaterialCost || 0,
				materialResell: sku.materialResell || 0,
				unitLaborCost: sku.unitLaborCost || 0,
				laborResell: sku.laborResell || 0,
				totalResell: sku.totalResell || 0
			}));
			opt.value.projectCosting.projectCostingSpreadsheetModal.SKUs = [];
			opt.value.projectCosting.projectCostingSpreadsheetModal.spreadSheetData = [];
		});

		dispatch({
			type: 'CREATE_WORK_ORDER_GET_DROPDOWN_OPTIONS',
			payload: {
				sectionIndex,
				fieldIndex,
				value,
				options
			}
		});
		dispatch({
			type: 'CREATE_WORK_ORDER_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				loading: false
			}
		});
	};
};

export const getWorkOrderDrafts = (sectionIndex, fieldIndex, value) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_WORK_ORDER_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				value,
				loading: true
			}
		});

		let options = [
			{
				key: 1,
				text: 'Draft 1',
				value: {
					name: 'Draft 1',
					clientId: 1
				}
			},
			{
				key: 2,
				text: 'Draft 2',
				value: {
					name: 'Draft 2',
					clientId: 2
				}
			},
			{
				key: 3,
				text: 'Draft 3',
				value: {
					name: 'Draft 3',
					clientId: 3
				}
			}
		];
		dispatch({
			type: 'CREATE_WORK_ORDER_GET_DROPDOWN_OPTIONS',
			payload: {
				sectionIndex,
				fieldIndex,
				value,
				options
			}
		});
		dispatch({
			type: 'CREATE_WORK_ORDER_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				loading: false
			}
		});
	};
};

export const applyTemplateOrDraft = (templateObject) => {
	return (dispatch, getState) => {
		const state = getState().work.createWorkOrder.createWorkOrderModal;

		//// GET ALL MDOAL FIELDS IN ARRAY ////
		let fieldMapping = state.sections
			.map((section, sectionIndex) => ({
				fields: section.fields,
				sectionIndex: sectionIndex
			}))
			.filter((object) => object.fields)
			.map((object) =>
				object.fields.map((field, fieldIndex) => ({
					field: field,
					fieldIndex: fieldIndex,
					sectionIndex: object.sectionIndex
				}))
			)
			.reduce((a, b) => a.concat(b), []);
		///////////////////////////////////////

		// Refactor keys to match create work order field names
		// Only made for some parts of create workorder, eventually
		// should work for project costing spreadsheet, aasdis, contacts, and more
		let refactoredTemplateObject = {
			Client: {
				value: templateObject.clientId ? templateObject.clientId : null,
				options: [
					{
						key: templateObject.clientId ? templateObject.clientId : null,
						text: templateObject.client_Name ? templateObject.client_Name : null,
						value: templateObject.clientId ? templateObject.clientId : null,
						spectrumCode: templateObject.spectrumCode ? templateObject.spectrumCode : null
					}
				]
			},
			// See text param in option below
			'Project Administrator': {
				value: templateObject.ndsId ? templateObject.ndsId : null,
				options: [
					{
						key: templateObject.ndsId ? templateObject.ndsId : null,
						/// Change to NDS name when that is in workOrder object
						text: templateObject.nds_Name ? templateObject.nds_Name : null,
						value: templateObject.ndsId ? templateObject.ndsId : null
					}
				]
			},
			'Purchase Order Number': {
				value: templateObject.purchaseOrderNumber ? templateObject.purchaseOrderNumber : null
			},
			'Quoted Job Number (Optional)': {
				value: templateObject.quotedJobNumber ? templateObject.quotedJobNumber : null
			},
			'Service Type': {
				value: templateObject.serviceType ? templateObject.serviceType : null,
				// Set options from reducer here otherwise it will be null
				options: state.sections[1].fields[
					state.sections[1].fields.findIndex((section) => section.name === 'Service Type')
				].options
					? state.sections[1].fields[
							state.sections[1].fields.findIndex((section) => section.name === 'Service Type')
					  ].options
					: null
			},
			// Not in work order object yet
			'Select Work Category': {
				value: templateObject.workCategory ? templateObject.workCategory : null,
				// Set options from reducer here otherwise it will be null
				options: state.sections[1].fields[
					state.sections[1].fields.findIndex((section) => section.name === 'Select Work Category')
				].options
					? state.sections[1].fields[
							state.sections[1].fields.findIndex((section) => section.name === 'Select Work Category')
					  ].options
					: null
			},
			'Google Drive Path': {
				value: templateObject.googleDriveLink ? templateObject.googleDriveLink : null
			},
			'New Construction': {
				value: templateObject.isNewConstruction,
				options: state.sections[1].fields[
					state.sections[1].fields.findIndex((section) => section.name === 'New Construction')
				].options
					? state.sections[1].fields[
							state.sections[1].fields.findIndex((section) => section.name === 'New Construction')
					  ].options
					: null
			},
			Location: {
				value: templateObject.locationId ? templateObject.locationId : null,
				dataList: [
					{
						id: templateObject.locationId ? templateObject.locationId : null,
						name: templateObject.location_Name ? templateObject.location_Name : null,
						storeNumber: templateObject.location_StoreNumber ? templateObject.location_StoreNumber : null,
						address: `${templateObject.location_Address ? templateObject.location_Address : null} ${
							templateObject.location_Address2 ? templateObject.location_Address2 : null
						}`,
						city: templateObject.location_City ? templateObject.location_City : null,
						state: templateObject.location_State ? templateObject.location_State : null,
						zip: templateObject.location_Zip ? templateObject.location_Zip : null
					}
				]
			},
			'Billing Contact': {
				value: templateObject.billingContactId ? templateObject.billingContactId : null,
				dataList: [
					{
						id: templateObject.billingContactId ? templateObject.billingContactId : null,
						fullName: templateObject.billingContact_Name ? templateObject.billingContact_Name : null,
						title: templateObject.billingContact_Title || '',
						email: templateObject.billingContact_Email ? templateObject.billingContact_Email : null,
						phone: templateObject.billingContact_Phone ? templateObject.billingContact_Phone : null,
						// Fix for displaying correct name in selected contact
						firstName: templateObject.billingContact_Name ? templateObject.billingContact_Name : null,
						lastName: ''
					}
				]
			},
			'Project Manager': {
				value: templateObject.projectManagerId ? templateObject.projectManagerId : null,
				options: [
					{
						key: templateObject.projectManagerId ? templateObject.projectManagerId : null,
						text: templateObject.projectManager_Name ? templateObject.projectManager_Name : null,
						value: templateObject.projectManagerId ? templateObject.projectManagerId : null
					}
				]
			},
			'Account Manager (Sales Rep)': {
				value: templateObject.accountExecutiveId ? templateObject.accountExecutiveId : null,
				options: [
					{
						key: templateObject.accountExecutiveId ? templateObject.accountExecutiveId : null,
						text: templateObject.accountExecutive_Name ? templateObject.accountExecutive_Name : null,
						value: templateObject.accountExecutiveId ? templateObject.accountExecutiveId : null
					}
				]
			},
			'Sales Representative': {
				value: templateObject.accountExecutiveId ? templateObject.accountExecutiveId : null,
				options: [
					{
						key: templateObject.accountExecutiveId ? templateObject.accountExecutiveId : null,
						text: templateObject.accountExecutive_Name ? templateObject.accountExecutive_Name : null,
						value: templateObject.accountExecutiveId ? templateObject.accountExecutiveId : null
					}
				]
			}
		};
		let refactoredTemplateObjectKeys = Object.keys(refactoredTemplateObject);

		/// Match template object keys to create work order modal field's names
		let template = fieldMapping
			.filter(
				(field) =>
					field.field.name ===
					refactoredTemplateObjectKeys.filter((objectKey) => objectKey === field.field.name)[0]
			)
			// Create items that mimic structure of modal fields with template data
			.map((item) => ({
				...item.field,
				fieldIndex: item.fieldIndex,
				sectionIndex: item.sectionIndex,
				value: refactoredTemplateObject[item.field.name].value,
				options: refactoredTemplateObject[item.field.name].options || null,
				dataList: refactoredTemplateObject[item.field.name].dataList || null
			}));

		dispatch({
			type: 'CREATE_WORK_MODAL_APPLY_TEMPL_OR_DRAFT',
			payload: template
		});
		// had to put this here because of time, ideally it would be done in the getWorkOrderTemplates
		templateObject.projectCosting.skuExists = true;

		dispatch({
			type: 'CREATE_WORK_MODAL_APPLY_TEMPL_OR_DRAFT_COST_SOW',
			payload: {
				projectCosting: templateObject.projectCosting ? templateObject.projectCosting : null,
				SOW: templateObject.scopeOfWork ? templateObject.scopeOfWork : null
			}
		});

		// Fix for locations and billing contacts because I got crap logics with dataLists
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_LOCATION_UPDATE',
			payload: {
				sectionIndex: 1,
				value: refactoredTemplateObject['Location'].dataList[0].name,
				error: false,
				locationObject: refactoredTemplateObject['Location'].dataList[0]
			}
		});
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_BILLING_CONTACT_UPDATE',
			payload: {
				sectionIndex: 1,
				value: refactoredTemplateObject['Billing Contact'].dataList[0].fullName,
				error: false,
				contactObject: refactoredTemplateObject['Billing Contact'].dataList[0]
			}
		});
	};
};

export const toggleAASDIModal = (sectionIndex) => {
	return (dispatch, getState) => {
		const toggleValue = getState().work.createWorkOrder.createWorkOrderModal.sections[sectionIndex].modal.toggled;
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_TOGGLE_AASDI_MODAL',
			payload: {
				sectionIndex,
				value: !toggleValue
			}
		});
	};
};

export const closeDimmer = (value) => {
	return (dispatch, getState) => {
		const sectionIndex = getState().work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'contactSection'
		);
		dispatch({
			type: 'CREATE_WORK_MODAL_CONTACTS_DIMMER',
			payload: {
				sectionIndex,
				value: value,
				column: 'editError'
			}
		});
	};
};

export const closeDimmerMain = (value) => {
	return (dispatch) => {
		if (value === 'spectrum') {
			dispatch({
				type: 'CREATE_WORK_ORDER_SPECTRUM_ERROR_TOGGLE',
				payload: {value: false}
			});
		}
		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_LOADING',
			payload: {
				state: value
			}
		});
	};
};

export const applyMaterialMargin = () => ({
	type: 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_APPLY_MATERIAL_MARGIN'
});
export const applyLaborMargin = () => ({
	type: 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_APPLY_LABOR_MARGIN'
});
export const setMaterialMargin = (val) => ({
	type: 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_SET_MATERIAL_MARGIN',
	payload: val
});
export const setLaborMargin = (val) => ({
	type: 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_SET_LABOR_MARGIN',
	payload: val
});

export const createWorkOrder = () => {
	return (dispatch, getState) => {
		const state = getState().work.createWorkOrder.createWorkOrderModal;

		// VALIDATION ////
		let requiredFields = state.sections
			.map((section, sectionIndex) => ({
				fields: section.fields,
				sectionIndex: sectionIndex
			}))
			.filter((section) => section.fields)
			.map((object) =>
				object.fields
					.map((field, fieldIndex) => ({
						field: field,
						fieldIndex: fieldIndex,
						sectionIndex: object.sectionIndex
					}))
					.filter((field) => field.field.required && !field.field.value)
			)
			.filter((object) => object.length > 0)
			.reduce((a, b) => a.concat(b), []);

		const checkServiceType = state.sections[1].fields[4];
		const checkServiceTypeOption = state.sections[1].fields[state.sections[1].fields.length - 1];

		if (checkServiceType.value === 4 && !checkServiceTypeOption.value) {
			// Support Service Type Selected, But No Service Type Option Selected
			const addRequired = {
				field: checkServiceTypeOption,
				fieldIndex: state.sections[1].fields.length - 1,
				sectionIndex: 1
			};
			requiredFields.push(addRequired);
		}

		const projectCostingSection = state.sections[2];
		const documentsSection = state.sections[4];
		let resellMissing = false;

		// Check each SKU for labor and material resell
		// SKU should include at a minimum one or the other
		if (projectCostingSection.rowData.length > 0) {
			projectCostingSection.rowData.forEach(function (SKU) {
				if (SKU.laborResell <= 0 && SKU.materialResell <= 0) {
					resellMissing = true;
				}
			});
		}

		// Check if dates are valid
		const startDate =
			state.sections[1].fields[state.sections[1].fields.findIndex((field) => field.name === 'Start Date')];
		const endDate =
			state.sections[1].fields[state.sections[1].fields.findIndex((field) => field.name === 'End Date')];

		const isStartDateValid = moment(startDate.value).isSameOrBefore(endDate.value);
		const isEndDateValid = moment(endDate.value).isSameOrAfter(startDate.value);

		if (!isStartDateValid) {
			const addRequired = {
				field: startDate,
				fieldIndex: state.sections[1].fields.findIndex((field) => field.name === 'Start Date'),
				sectionIndex: 1
			};
			requiredFields.push(addRequired);
		}

		if (!isEndDateValid) {
			const addRequired = {
				field: endDate,
				fieldIndex: state.sections[1].fields.findIndex((field) => field.name === 'End Date'),
				sectionIndex: 1
			};
			requiredFields.push(addRequired);
		}

		const skuExist = state.sections[2].rowData.length > 0;

		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_CHECK_SKU',
			payload: {
				sectionIndex: 2,
				skuExists: skuExist
			}
		});

		if (
			requiredFields.length > 0 ||
			resellMissing ||
			!documentsSection.documents.length ||
			!skuExist ||
			!documentsSection.documents.some((doc) => doc.customerApproval)
		) {
			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_CHECK_REQUIRED_FIELDS',
				payload: requiredFields
			});

			dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_CHECK_REQUIRED_DOCUMENTS',
				payload: {
					sectionIndex: 4,
					hasError:
						!documentsSection.documents.length ||
						!documentsSection.documents.some((doc) => doc.customerApproval)
				}
			});

			if (resellMissing) {
				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_CHECK_RESELL',
					payload: {
						sectionIndex: 2,
						error: true
					}
				});
			}
			return;
		}

		// Total Revenue
		const checkTotalRevenue =
			state.sections[state.sections.findIndex((section) => section.name === 'workOrderProjectCostingSection')]
				.summary.totalResell;
		const fixNumber = checkTotalRevenue ? checkTotalRevenue.replace('$', '') : null;
		if (fixNumber < 0 || fixNumber === null) {
			return dispatch({
				type: 'CREATE_WORK_ORDER_MODAL_LOADING',
				payload: {state: 'totalRevenue'}
			});
		}
		/// READY TO POST ///

		let billableItemsArray = state.sections[2].rowData.map((row) => ({
			isFixedRevenue: row.isFixedRevenue,
			description: row.description ? row.description : row.SKU ? row.SKU.description : null,
			quantity: row.quantity ? row.quantity.toFixed(2) : null,
			skuId: row.skuId,
			partNumber: row.partNumber ? row.partNumber : null,
			unitMaterialCost: row.unitMaterialCost ? row.unitMaterialCost.toFixed(2) : null,
			manufacturer: row.manufacturer ? row.manufacturer : null,
			unit: row.unit ? row.unit : null,
			unitLaborCost: row.unitLaborCost ? row.unitLaborCost.toFixed(2) : null,
			markupLabor: row.markupLabor ? parseInt(row.markupLabor.toFixed(2)) : null,
			markupMaterial: row.markupMaterial ? parseInt(row.markupMaterial.toFixed(2)) : null,
			materialResell: row.materialResell ? row.materialResell.toFixed(2) : null,
			laborResell: row.laborResell ? row.laborResell.toFixed(2) : null,
			totalResell: row.totalResell ? row.totalResell.toFixed(2) : null
		}));

		let aasdiArray = state.sections[6].AASDI;
		let tagsArray = state.sections[7].tags;

		dispatch({
			type: 'CREATE_WORK_UPDATE_TEMP_AASDI_TRIP',
			payload: {
				value: aasdiArray
			}
		});

		dispatch({
			type: 'CREATE_WORK_ORDER_MODAL_LOADING',
			payload: {state: 'progress'}
		});

		let workPayload = {
			// these field numbers could be off because of 'Service Type Options'
			clientId:
				state.sections[1].fields[state.sections[1].fields.findIndex((field) => field.name === 'Client')].value,
			spectrumCode:
				state.sections[1].fields[state.sections[1].fields.findIndex((field) => field.name === 'Client')]
					.options[0].spectrumCode,
			ndsId: state.sections[1].fields[
				state.sections[1].fields.findIndex((field) => field.name === 'Project Administrator')
			].value,
			purchaseOrderNumber:
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'Purchase Order Number')
				].value,
			quotedJobNumber:
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'Quoted Job Number (Optional)')
				].value,
			serviceType:
				state.sections[1].fields[state.sections[1].fields.findIndex((field) => field.name === 'Service Type')]
					.value,
			workCategory:
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'Select Work Category')
				].options[
					state.sections[1].fields[
						state.sections[1].fields.findIndex((field) => field.name === 'Select Work Category')
					].options.findIndex(
						(option) =>
							option.value ===
							state.sections[1].fields[
								state.sections[1].fields.findIndex((field) => field.name === 'Select Work Category')
							].value
					)
				].text,
			googleDriveLink:
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'Google Drive Path')
				].value,
			isNewConstruction:
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'New Construction')
				].value,
			locationId:
				state.sections[1].fields[state.sections[1].fields.findIndex((field) => field.name === 'Location')]
					.selectedLocation.id,
			billingContactId:
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'Billing Contact')
				].selectedBillingContact.id,
			projectManagerId:
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'Project Manager')
				].value,
			accountManagerId:
				state.sections[1].fields[
					state.sections[1].fields.findIndex(
						(field) => field.name === 'Account Manager' || 'Account Manager (Sales Rep)'
					)
				].value,
			accountExecutiveId:
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'Sales Representative')
				].value,
			branch: branchOptions.find(
				(op) =>
					op.value ===
					state.sections[1].fields[state.sections[1].fields.findIndex((field) => field.name === 'Branch')]
						.value
			).code,
			isProject: false,
			isDraft: false,
			isGroupBillable: false,
			masterClientId: state.sections[1].fields.find(hasName('Master Client')).value,
			startDate: moment(startDate.value, 'MM-DD-YYYY').format('MM-DD-YYYY').toString(),
			endDate: moment(endDate.value, 'MM-DD-YYYY').format('MM-DD-YYYY').toString(),
			dateReceived: moment(
				state.sections[1].fields[state.sections[1].fields.findIndex((field) => field.name === 'Date Received')]
					.value,
				'MM-DD-YYYY HH:mm'
			)
				.format('MM-DD-YYYY HH:mm')
				.toString(),
			billableItems: billableItemsArray,
			totalResell: Number(fixNumber),
			scopeOfWork: state.sections[3].scopeOfWorkValue,
			aasdis: aasdiArray,
			tags: tagsArray
		};
		if (workPayload.serviceType === 4) {
			workPayload.serviceSubType =
				state.sections[1].fields[
					state.sections[1].fields.findIndex((field) => field.name === 'Service Type Options')
				].value;
		}

		let workEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work`;

		customFetch(workEndpoint, {
			method: 'POST',
			body: JSON.stringify(workPayload)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject({passed: false});
				}
				return response.json();
			})
			.then((json) => {
				if (json[0].desynced) {
					dispatch({
						type: 'CREATE_WORK_ORDER_DESYNCED',
						payload: {value: json[0].desynced}
					});
				}

				if (typeof json === 'string') {
					console.log('FAILED AT SPECTRUM');
					dispatch({
						type: 'CREATE_WORK_ORDER_SPECTRUM_ERROR_TOGGLE',
						payload: {value: true}
					});
					dispatch({
						type: 'CREATE_WORK_ORDER_SPECTRUM_MESSAGE',
						payload: {value: json}
					});
					return Promise.reject({passed: false});
				}
				dispatch({
					type: 'CREATE_WORK_UPDATE_TEMP_WORK_ORDER',
					payload: {
						value: json[0]
					}
				});
				let workId = json[0].id;
				dispatch({
					type: 'SET_REDIRECT_WORK_ID',
					payload: {
						workId,
						workNumber: json[0].workNumber
					}
				});
				// POST CONTACT SECTION

				let contacts = getState().work.createWorkOrder.createWorkOrderModal.tempContacts;
				contacts.forEach((contact) => {
					contact.type = 'work';
					contact.typeId = workId;
					if (contact.contactId) {
						dispatch(
							addSearchContact(contact.type, contact.typeId, contact.contactId, contact.modalFields)
						);
					} else {
						dispatch(createContact(contact.type, contact.typeId, contact.modalFields));
					}
				});

				// SET WORK ID TO USE IN NEXT POSTS
				dispatch({
					type: 'CREATE_WORK_ORDER_UPDATE_WORK_ID',
					payload: workId
				});
				// Add WorkOrder to Work Grid List
				if (json.length > 0) {
					//format date
					if (json[0].endDate && json[0].startDate) {
						json[0].endDate = moment.utc(json[0].endDate).format('MM-DD-YYYY');
						json[0].startDate = moment.utc(json[0].startDate).format('MM-DD-YYYY');
					}

					dispatch({
						type: 'WORK_GRID_ADD_WORK_ITEM',
						payload: json
					});
				}
			})
			.then(() => {
				const newState = getState().work.createWorkOrder.createWorkOrderModal;

				/// DOCUMENTS POST
				let workId = newState.workId;
				let clientId = newState.sections[1].fields[0].value;

				// POST DOCUMENTS DETAILS
				let documentArray = newState.sections[4].documents;
				if (documentArray.length < 1) {
					// No Docs found stop posts
					dispatch(toggleCreateWorkOrderModal());
					dispatch(toggleAfterCreateModal(true));
					if (aasdiArray.length === 0) {
						dispatch({
							type: 'CREATE_WORK_ORDER_MODAL_LOADING',
							payload: {state: null}
						});
					}
					return;
				}
				let docMapped = documentArray.map((document) => ({
					comments: document.comments,
					workId: workId,
					clientId: clientId,
					googleDriveLink: document.googleDrive,
					fileName: document.fileName,
					customerApproval: document.customerApproval,
					s3Url: null
				}));

				let documentEndpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

				customFetch(documentEndpoint, {
					method: 'POST',
					body: JSON.stringify(docMapped)
				})
					.then((response) => {
						if (response.status !== 200) {
							return Promise.reject(response);
						}
						return response.json();
					})
					.then(() => {
						dispatch(toggleCreateWorkOrderModal());
						dispatch(toggleAfterCreateModal(true));
						if (aasdiArray.length === 0) {
							dispatch({
								type: 'CREATE_WORK_ORDER_MODAL_LOADING',
								payload: {state: null}
							});
						}
					})
					.catch((err) => {
						dispatch({
							type: 'ERROR',
							payload: err.stack
						});
						dispatch({
							type: 'CREATE_WORK_ORDER_MODAL_LOADING',
							payload: {state: 'error'}
						});
					});

				// Create default trip 1
				if (aasdiArray.length > 0) {
					const workAasdiEndpoint = `${process.env.REACT_APP_API_URL}/work/${workId}/aasdi`;
					customFetch(workAasdiEndpoint)
						.then((response) => {
							if (response.status !== 200) {
								return Promise.reject(response);
							}
							return response.json();
						})
						.then((workAasdiRecords) => {
							workAasdiRecords.forEach((workAasdi) => {
								const aasdiId = workAasdi.id;
								const aasdi = aasdiArray.find((aasdi) => aasdi.aasdiId == aasdiId);
								dispatch(
									createTrip(
										workAasdi.workAasdiId,
										'Trip 1',
										aasdi.siteContactId,
										aasdi.requestedTime,
										aasdi.actualTime,
										workPayload.scopeOfWork,
										aasdi.specialInstructions,
										aasdi.id
									)
								);
							});
							dispatch({
								type: 'CREATE_WORK_ORDER_MODAL_LOADING',
								payload: {state: null}
							});
						})
						.catch((err) => {
							dispatch({
								type: 'ERROR',
								payload: err.stack
							});
							dispatch({
								type: 'CREATE_WORK_ORDER_MODAL_LOADING',
								payload: {state: 'error'}
							});
						});
				}
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'CREATE_WORK_ORDER_MODAL_LOADING',
					payload: {state: 'error'}
				});
			});
	};
};

export const addToTempWorkOrderContacts = (contact) => {
	return (dispatch) => {
		let displayContact;
		if (contact.contactId) {
			let contactObj = contact.modalFields[0].value;
			displayContact = {
				firstName: contactObj.firstName,
				lastName: contactObj.lastName,
				title: contactObj.title,
				email: contactObj.email,
				phone: contactObj.phone,
				extension: contactObj.extension,
				isPrimary: contactObj.isPrimary,
				fullName: `${contactObj.firstName} ${contactObj.lastName}`,
				existing: true
			};
		} else {
			displayContact = {
				firstName: contact.modalFields[1].value,
				lastName: contact.modalFields[2].value,
				title: contact.modalFields[3].value,
				email: contact.modalFields[4].value,
				phone: contact.modalFields[5].value,
				extension: contact.modalFields[6].value,
				isPrimary: contact.modalFields[7].value,
				fullName: `${contact.modalFields[1].value} ${contact.modalFields[2].value}`
			};
		}
		dispatch({
			type: 'CREATE_WORK_ADD_TEMP_CONTACT',
			payload: {
				contact,
				displayContact
			}
		});
	};
};

export const removeFromTempWorkOrderContacts = (index) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_REMOVE_TEMP_CONTACT',
			payload: {
				index
			}
		});
	};
};

export const updateTempWorkOrderContacts = (index, contact) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_UPDATE_TEMP_CONTACT',
			payload: {
				index,
				contact
			}
		});
	};
};

export const toggleCreateWorkOrderAasdiContactModal = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_TOGGLE',
			payload: {
				value
			}
		});

		if (value === false) {
			dispatch({
				type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_CLEAR_CONTACT'
			});
		}
	};
};

export const getAasdiContacts = (value, aasdiId) => async (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_SEARCH_LOADING',
		payload: {value: true}
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/contacts?name=${value}&aasdiId=${aasdiId}`;
	const getContactOptions = await customFetch(endpoint);
	const response = await getContactOptions.json();

	let contactOptions = [];
	response.forEach((contact, index) => {
		contactOptions.push({
			key: index,
			text: contact.fullName,
			value: contact
		});
	});

	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_DROPDOWN_OPTIONS',
		payload: {json: contactOptions}
	});

	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_SEARCH_LOADING',
		payload: {value: false}
	});
};

export const setTempContact = (value, index) => async (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_ERROR',
		payload: {value: false}
	});
	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_SELECTED_CONTACT',
		payload: {value, index}
	});
};

export const setIsPrimary = (value) => async (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_IS_PRIMARY',
		payload: {value}
	});
};

export const setSelectedAasdiContacts = (contact, index) => async (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_ADD_CONTACT',
		payload: {contact, index}
	});

	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_TOGGLE',
		payload: {
			value: false
		}
	});

	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_CLEAR_CONTACT'
	});
};

export const aasdiContactsValidationError = () => async (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_ERROR',
		payload: {value: true}
	});
};

export const saveWorkTemplates = (name, isProject) => async (dispatch, getState) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_TEMPLATE_MODAL_LOADER',
		payload: {value: true}
	});

	const state = getState().work.createWorkOrder.createWorkOrderModal;

	let postBody = {
		name,
		isProject,
		userId: 1,
		clientId: state.sections[1].fields[0].value,
		template: {
			overviewSection: state.sections[1].fields,
			projectCostingSection: state.sections[2],
			scopeOfWorkSection: state.sections[3].scopeOfWorkValue
		}
	};

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=work-templates`;

	// const call =
	await customFetch(endpoint, {
		method: 'POST',
		body: JSON.stringify({
			postBody
		})
	});
	// const response = await getContactOptions.json();
	dispatch({
		type: 'CREATE_WORK_ORDER_TOGGLE_WORK_TEMPLATE_MODAL',
		payload: {value: false}
	});
	dispatch({
		type: 'CREATE_WORK_ORDER_TEMPLATE_MODAL_LOADER',
		payload: {value: false}
	});
};

export const openWorkTemplateModal = (value) => async (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_TOGGLE_WORK_TEMPLATE_MODAL',
		payload: {value}
	});
};

export const clearDesynced = () => async (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_DESYNCED',
		payload: {value: false}
	});
};

export const addTag = (tag) => (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_ADD_TAG',
		payload: {
			tag
		}
	});
};

export const removeTag = (removeIndex) => (dispatch) => {
	dispatch({
		type: 'CREATE_WORK_ORDER_REMOVE_TAG',
		payload: {
			removeIndex
		}
	});
};
