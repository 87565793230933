// TODO: update certs to get rid of this file
// I don't think the way I set up certs before makes sense.
// It should be a get from the certs table instead of manually writing
// out the info below. Don't have time now because deadlines.

export const certifications = [
	{
		name: 'Structured Cabling',
		id: 2,
		cert: [
			{
				name: 'Products',
				id: 7,
				subCert: [
					{name: 'Belden', id: 19},
					{name: 'Berk-Tek', id: 20},
					{name: 'Commscope - Systimax', id: 21},
					{name: 'Commscope - Uniprise', id: 22},
					{name: 'Corning', id: 23},
					{name: 'Corning NPI Partner', id: 163},
					{name: 'Corning SD LAN', id: 165},
					{name: 'Corning Spider Cloud', id: 164},
					{name: 'Hubbell Premise Wiring', id: 28},
					{name: 'iCAT-ITS', id: 24},
					{name: 'iGLO-ITS', id: 26},
					{name: 'Leviton', id: 29},
					{name: 'Ortonics', id: 32},
					{name: 'Panduit', id: 33},
					{name: 'Siemon', id: 34},
					{name: 'Telabs OLAN', id: 166},
					{name: 'Zhone OLAN', id: 167}
				]
			},
			{
				name: 'Industry Certs BICSI',
				id: 8,
				subCert: [
					{name: 'ITS Installer 1', id: 44},
					{name: 'ITS Installer 2', id: 45},
					{name: 'ITS Installer 2, Copper', id: 46},
					{name: 'ITS Installer 2, Optical Fiber', id: 47},
					{name: 'ITS Technician', id: 48}
				]
			}
		]
	},

	{
		name: 'Voice',
		id: 3,
		cert: [
			{
				name: 'Products',
				id: 9,
				subCert: [
					{id: 49, name: 'Avaya Definity'},
					{id: 50, name: 'Avaya ECG Com. Manager'},
					{id: 51, name: 'Avaya IP Office'},
					{id: 53, name: 'Avaya legend'},
					{id: 54, name: 'Avaya Magix'},
					{id: 52, name: 'Avaya Partner'},
					{id: 56, name: 'Cisco Call Manager'},
					{id: 57, name: 'Cisco Call Manager Express'},
					{id: 55, name: 'Comdial'},
					{id: 77, name: 'DBS 576'},
					{id: 65, name: 'Iwatsu Omega'},
					{id: 66, name: 'Mitel SX-200'},
					{id: 69, name: 'NEC Pro II'},
					{id: 71, name: 'Nortel BCM'},
					{id: 72, name: 'Nortel Norstar (MiCS, CiCS)'},
					{id: 73, name: 'Nortel Option 11'},
					{id: 76, name: 'Panasonic DBS'},
					{id: 78, name: 'Panasonic KX-T'},
					{id: 81, name: 'Panasonic NCP'},
					{id: 79, name: 'Panasonic TDA'},
					{id: 80, name: 'Panasonic TDE'},
					{id: 82, name: 'Shoretel'},
					{id: 87, name: 'Toshiba Strata DK 280'},
					{id: 88, name: 'Toshiba Strata DK 424'}
				]
			},
			{
				name: 'Industry Certs',
				id: 10,
				subCert: [
					{id: 91, name: 'Avaya ACA'},
					{id: 94, name: 'Avaya ACE'},
					{id: 92, name: 'Avaya ACIS'},
					{id: 93, name: 'Avaya ACSS'},
					{id: 95, name: 'Avaya SME Expert'},
					{id: 98, name: 'Cisco CCIE Voice'},
					{id: 96, name: 'Cisco CCNA Voice'},
					{id: 97, name: 'Cisco CCNP Voice'}
				]
			}
		]
	},
	{
		name: 'AV & Networking',
		id: 4,
		cert: [
			{
				name: 'Products',
				id: 11,
				subCert: [
					{id: 173, name: 'AMX'},
					{id: 100, name: 'Aruba Networks'},
					{id: 168, name: 'Biamp'},
					{id: 101, name: 'Cisco Wireless'},
					{id: 172, name: 'Crestron'},
					{id: 102, name: 'Dell'},
					{id: 171, name: 'Extron'},
					{id: 103, name: 'HP Wireless'},
					{id: 106, name: 'Meru Wireless'},
					{id: 107, name: 'Nortel/Avaya Data'},
					{id: 169, name: 'QSC'},
					{id: 170, name: 'Xirrus'}
				]
			},
			{
				name: 'Industry Certs',
				id: 12,
				subCert: [
					{id: 177, name: 'AV Acoustial Expert'},
					{id: 175, name: 'AV Control Programming'},
					{id: 176, name: 'AV DSP Programming'},
					{id: 174, name: 'CTS-D'},
					{id: 110, name: 'Microsoft MCP'},
					{id: 111, name: 'Microsoft MSCE'}
				]
			}
		]
	},
	{
		name: 'Building System (Security, A/V, Fire Alarm)',
		id: 5,
		cert: [
			{
				name: 'Products',
				id: 13,
				subCert: [
					{id: 116, name: 'ADT'},
					{id: 117, name: 'Axis'},
					{id: 118, name: 'Bosch'},
					{id: 119, name: 'Bluewave'},
					{id: 179, name: 'Honeywell Prowatch'},
					{id: 183, name: 'Isonas'},
					{id: 123, name: 'Keyscan'},
					{id: 178, name: 'Lenel'},
					{id: 184, name: 'Milestone'},
					{id: 185, name: 'ONSSI'},
					{id: 180, name: 'Open Path'},
					{id: 124, name: 'Panasonic'},
					{id: 182, name: 'S2'},
					{id: 181, name: 'Software House'}
				]
			}
		]
	},
	{
		name: 'Other',
		id: 6,
		cert: [
			{
				name: 'Disadvantaged Status',
				id: 14,
				subCert: [
					{id: 126, name: 'DVBE'},
					{id: 125, name: 'MBE'},
					{id: 128, name: 'Veteran Owned'},
					{id: 127, name: 'WBE'}
				]
			},
			{
				name: 'Prehire Tests',
				id: 15,
				subCert: [
					{id: 129, name: 'Backround'},
					{id: 131, name: 'Driver'},
					{id: 130, name: 'Drug'},
					{id: 189, name: 'OSHA 10'},
					{id: 190, name: 'OSHA 30'},
					{id: 132, name: 'Technical'}
				]
			},
			{
				name: 'Government',
				id: 16,
				subCert: [
					{id: 188, name: 'GSA Contract'},
					{id: 187, name: 'US Govt Green Badge Security Clearance'},
					{id: 186, name: 'US Govt Red Badge Security Clearance'}
				]
			},
			{
				name: 'Equipment',
				id: 17,
				subCert: [
					{id: 193, name: 'Air Magnet Wifi Tool'},
					{id: 136, name: 'Certification Tester (Cat5e, Cat6)'},
					{id: 194, name: 'Eases Modeling Audio Tool'},
					{id: 191, name: 'Ekahau Wifi Tool'},
					{id: 192, name: 'IBWAVE Tool'},
					{id: 137, name: 'OTDR Tester'}
				]
			},
			{
				name: 'Work Preformed',
				id: 18,
				subCert: [
					{id: 142, name: 'CATV'},
					{id: 195, name: 'DAS'},
					{id: 143, name: 'DirecTV'},
					{id: 150, name: 'Electrical'},
					{id: 198, name: 'IGOR'},
					{id: 201, name: 'Locksmith'},
					{id: 196, name: 'OLAN / GPON'},
					{id: 151, name: 'Outside Plant Directional Boring'},
					{id: 152, name: 'Outside Plant Trenching'},
					{id: 154, name: 'Paging systems'},
					{id: 153, name: 'Phone Systems'},
					{id: 199, name: 'PoE Lighting'},
					{id: 197, name: 'Private LTE'},
					{id: 155, name: 'Raised Floor Install'},
					{id: 156, name: 'Residential Work'},
					{id: 158, name: 'Sound Masking Atlas Sound'},
					{id: 157, name: 'Sound Masking Lencore'},
					{id: 200, name: 'Video Walls'}
				]
			},
			{
				name: 'Union & licensing',
				id: 159,
				subCert: [
					{id: 161, name: 'CWA'},
					{id: 203, name: 'Holds Electrical State License'},
					{id: 202, name: 'Holds Low Voltage State License'},
					{id: 204, name: 'Holds Security State License'},
					{id: 160, name: 'IBEW'},
					{id: 162, name: 'Other'}
				]
			}
		]
	}
];

export const catMap = [
	{name: 'Products', id: 7, parentId: 2},
	{name: 'Industry Certs BICSI', id: 8, parentId: 2},
	{name: 'Products', id: 9, parentId: 3},
	{name: 'Industry Certs', id: 10, parentId: 3},
	{name: 'Products', id: 11, parentId: 4},
	{name: 'Industry Certs', id: 12, parentId: 4},
	{name: 'Products', id: 13, parentId: 5},
	{name: 'Disadvantaged Status', id: 14, parentId: 6},
	{name: 'Prehire Tests', id: 15, parentId: 6},
	{name: 'Industry Association', id: 16, parentId: 6},
	{name: 'Equipment', id: 17, parentId: 6},
	{name: 'Work Preformed', id: 18, parentId: 6},
	{name: 'Union & licensing', id: 159, parentId: 6}
];

export const certIds = [
	{id: 19, name: 'Belden'},
	{id: 20, name: 'Berk-Tek'},
	{id: 21, name: 'Commscope - Systimax'},
	{id: 22, name: 'Commscope - Uniprise'},
	{id: 23, name: 'Corning'},
	{id: 24, name: 'iCAT-ITS'},
	{id: 26, name: 'iGLO-ITS'},
	{id: 28, name: 'Hubbell Premise Wiring'},
	{id: 29, name: 'Leviton'},
	{id: 32, name: 'Ortonics'},
	{id: 33, name: 'Panduit'},
	{id: 34, name: 'Siemon'},
	{id: 44, name: 'ITS Installer 1'},
	{id: 45, name: 'ITS Installer 2'},
	{id: 46, name: 'ITS Installer 2, Copper'},
	{id: 47, name: 'ITS Installer 2, Optical Fiber'},
	{id: 48, name: 'ITS Technician'},
	{id: 49, name: 'Avaya Definity'},
	{id: 50, name: 'Avaya  ECG Com. Manager'},
	{id: 51, name: 'Avaya IP Office'},
	{id: 52, name: 'Avaya Partner'},
	{id: 53, name: 'Avaya legend'},
	{id: 54, name: 'Avaya Magix'},
	{id: 55, name: 'Comdial'},
	{id: 56, name: 'Cisco Call Manager'},
	{id: 57, name: 'Cisco Call Manager Express'},
	{id: 65, name: 'Iwatsu Omega'},
	{id: 66, name: 'Mitel  SX-200'},
	{id: 69, name: 'NEC Pro II'},
	{id: 71, name: 'Nortel BCM'},
	{id: 72, name: 'Nortel Norstar (MiCS, CiCS)'},
	{id: 73, name: 'Nortel Option 11'},
	{id: 76, name: 'Panasonic DBS'},
	{id: 77, name: 'Panasonic DBS 576'},
	{id: 78, name: 'Panasonic KX-T'},
	{id: 79, name: 'Panasonic TDA'},
	{id: 80, name: 'Panasonic TDE'},
	{id: 81, name: 'Panasonic NCP'},
	{id: 82, name: 'Shoretel'},
	{id: 87, name: 'Toshiba Strata DK 280'},
	{id: 88, name: 'Toshiba  Strata DK 424'},
	{id: 91, name: 'Avaya ACA'},
	{id: 92, name: 'Avaya ACIS'},
	{id: 93, name: 'Avaya ACSS'},
	{id: 94, name: 'Avaya ACE'},
	{id: 95, name: 'Avaya SME Expert'},
	{id: 96, name: 'Cisco CCNA Voice'},
	{id: 97, name: 'Cisco CCNP Voice'},
	{id: 98, name: 'Cisco CCIE Voice'},
	{id: 100, name: 'Aruba Networks'},
	{id: 101, name: 'Cisco Wireless'},
	{id: 102, name: 'Dell'},
	{id: 103, name: 'HP Wireless'},
	{id: 106, name: 'Meru Wireless'},
	{id: 107, name: 'Nortel/Avaya Data'},
	{id: 110, name: 'Microsoft MCP'},
	{id: 111, name: 'Microsoft MSCE'},
	{id: 116, name: 'ADT'},
	{id: 117, name: 'Axis'},
	{id: 118, name: 'Bosch'},
	{id: 119, name: 'Bluewave'},
	{id: 123, name: 'Keyscan'},
	{id: 124, name: 'Panasonic'},
	{id: 125, name: 'MBE'},
	{id: 126, name: 'DVBE'},
	{id: 127, name: 'WBE'},
	{id: 128, name: 'Veteran Owned'},
	{id: 129, name: 'Backround'},
	{id: 130, name: 'Drug'},
	{id: 131, name: 'Driver'},
	{id: 132, name: 'Technical'},
	{id: 136, name: 'Certification Tester (Cat5e, Cat6)'},
	{id: 137, name: 'OTDR Tester'},
	{id: 142, name: 'CATV'},
	{id: 143, name: 'DirecTV'},
	{id: 150, name: 'Electrical'},
	{id: 151, name: 'Outside Plant  Directional Boring'},
	{id: 152, name: 'Outside Plant Trenching'},
	{id: 153, name: 'Phone Systems'},
	{id: 154, name: 'Paging systems'},
	{id: 155, name: 'Raised Floor Install'},
	{id: 156, name: 'Residential Work'},
	{id: 157, name: 'Sound Masking Lencore'},
	{id: 158, name: 'Sound Masking Atlas Sound'},
	{id: 160, name: 'IBEW'},
	{id: 161, name: 'CWA'},
	{id: 162, name: 'Other'},
	{id: 163, name: 'Corning NPI Partner'},
	{id: 164, name: 'Corning Spider Cloud'},
	{id: 165, name: 'Corning SD LAN'},
	{id: 166, name: 'Telabs OLAN'},
	{id: 167, name: 'Zhone OLAN'},
	{id: 168, name: 'Biamp'},
	{id: 169, name: 'QSC'},
	{id: 170, name: 'Xirrus'},
	{id: 171, name: 'Extron'},
	{id: 172, name: 'Crestron'},
	{id: 173, name: 'AMX'},
	{id: 174, name: 'CTS-D'},
	{id: 175, name: 'AV Control Programming'},
	{id: 176, name: 'AV DSP Programming'},
	{id: 177, name: 'AV Acoustial Expert'},
	{id: 178, name: 'Lenel'},
	{id: 179, name: 'Honeywell Prowatch'},
	{id: 180, name: 'Open Path'},
	{id: 181, name: 'Software House'},
	{id: 182, name: 'S2'},
	{id: 183, name: 'Isonas'},
	{id: 184, name: 'Milestone'},
	{id: 185, name: 'ONSSI'},
	{id: 186, name: 'US Govt Red Badge Security Clearance'},
	{id: 187, name: 'US Govt Green Badge Security Clearance'},
	{id: 188, name: 'GSA Contract'},
	{id: 189, name: 'OSHA 10'},
	{id: 190, name: 'OSHA 30'},
	{id: 191, name: 'Ekahau Wifi Tool'},
	{id: 192, name: 'IBWAVE Tool'},
	{id: 193, name: 'Air Magnet Wifi Tool'},
	{id: 194, name: 'Eases Modeling Audio Tool'},
	{id: 195, name: 'DAS'},
	{id: 196, name: 'OLAN / GPON'},
	{id: 197, name: 'Private LTE'},
	{id: 198, name: 'IGOR'},
	{id: 199, name: 'PoE Lighting'},
	{id: 200, name: 'Video Walls'},
	{id: 201, name: 'Locksmith'},
	{id: 202, name: 'Holds Low Voltage State License'},
	{id: 203, name: 'Holds Electrical State License'},
	{id: 204, name: 'Holds Security State License'}
];

export default {certifications, catMap, certIds};
