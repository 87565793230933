import {submitLostJobPipeline} from '../../../scenes/crm/scenes/dashboard/scenes/pipeline/services/pipelineActions.js';
import {submitLostJobLeads} from '../../../scenes/crm/scenes/dashboard/scenes/leads/services/leadsActions.js';

export const submitLostJob = (id, place, reason, comments) => {
	return (dispatch) => {
		console.log(place);
		switch (place) {
			case 'pipeline':
				dispatch(submitLostJobPipeline(id, place, reason, comments));
				break;
			case 'leads':
				dispatch(submitLostJobLeads(id, place, reason, comments));
				break;
		}
	};
};

export const toggleLostJobModal = (id, place) => {
	return (dispatch) => {
		dispatch({
			type: 'LOSTJOB_OPPORTUNITY_MODAL_UPDATE_MODAL_ID',
			payload: {
				id,
				place
			}
		});
		return dispatch({
			type: 'LOSTJOB_OPPORTUNITY_MODAL_TOGGLE'
		});
	};
};

export const updateModal = (column, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'LOSTJOB_OPPORTUNITY_MODAL_UPDATE',
			payload: {
				column,
				value,
				error
			}
		});
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'LOSTJOB_OPPORTUNITY_MODAL_LOADING',
			payload: value
		});
	};
};
