import React from 'react';
import {Label, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';

import * as actions from '../../../../../services/workRecordActions.js';

import {debounce, debounceEventHandler} from '../../../../../../../../../../../lib/old/debounce.js';
import {Column} from '../../../../../../../../../../../components/layout/Flex.jsx';

const CreateBillModalDropdownField = (props) => (
	<Column>
		<label>{props.field.name}</label>
		<Dropdown
			className="createWorkOrderClientDropdown"
			placeholder={props.field.placeholder}
			fluid
			search
			selection
			options={props.field.options}
			onChange={(e, {value}) => {
				props.createModalUpdateColumn(props.fieldIndex, value, false);
			}}
			onSearchChange={debounceEventHandler(
				debounce((e) => {
					if (e.target.value) {
						if (props.field.searchBillingContact) {
							props.createBillUpdateColumnDropdown(
								'billingContact',
								props.clientId,
								props.fieldIndex,
								e.target.value
							);
						}
						if (props.field.searchPO) {
							props.createBillUpdateColumnDropdown(
								'purchaseOrder',
								props.clientId,
								props.fieldIndex,
								e.target.value
							);
						}
					}
				}, 600)
			)}
			icon={props.field.icon}
			value={props.field.value}
			error={Boolean(props.field.error)}
			disabled={props.field.disabled}
			upward={props.field.upward}
			loading={props.field.loading}
			defaultSearchQuery={props.field.searchQuery}
		/>
	</Column>
);

const mapStateToProps = (state) => {
	return {
		clientId: state.work.workRecord.view.record.clientId
	};
};

export default connect(mapStateToProps, actions)(CreateBillModalDropdownField);
