import React from 'react';
import {Segment, Grid, Label, TextArea, Button, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import InputField from '../components/inputField.js';
import DropdownField from '../components/dropdownField.js';
import DocumentSectionCheckbox from './components/documentSectionCheckbox.js';
import DocumentSectionRemoveButton from './components/documentSectionRemoveButton.js';
import DebounceInput from 'react-debounce-input';
import {
	updateCreateProjectModal,
	addDocument,
	updateCreateProjectModalDocumentGrid,
	removeCreateProjectModalDocumentGrid
} from '../../services/createProjectActions.js';

const DocumentsSection = (props) => (
	<Segment>
		<Label attached="top" color="blue">
			{'Documents'}
		</Label>
		<Grid>
			<Grid.Row>
				{props.sectionFields.map((field, fieldIndex) =>
					field.input ? (
						<Grid.Column width={field.columnWidth} key={fieldIndex}>
							<InputField field={field} sectionIndex={props.sectionIndex} fieldIndex={fieldIndex} />
						</Grid.Column>
					) : field.dropdown ? (
						<Grid.Column width={field.columnWidth} key={fieldIndex}>
							<DropdownField field={field} sectionIndex={props.sectionIndex} fieldIndex={fieldIndex} />
						</Grid.Column>
					) : null
				)}
				<Grid.Column width={props.commentsField.columnWidth}>
					<div>
						<div
							style={{
								paddingTop: '0.8em'
							}}
						>
							<Label
								style={{
									backgroundColor: 'transparent',
									fontSize: '1rem',
									color: 'rgba(0,0,0,.8)',
									paddingLeft: '0'
								}}
							>
								{props.commentsField.name}
							</Label>
						</div>
						<DebounceInput
							element={TextArea}
							onChange={(e) => {
								props.updateCreateProjectModal(
									'createProjectModal',
									props.sectionIndex,
									props.commentsFieldIndex,
									e.target.value,
									false
								);
							}}
							debounceTimeout={600}
							value={props.commentsField.value}
							style={{
								width: '100%',
								border: '1px solid rgba(34,36,38,.15)',
								color: 'rgba(0,0,0,.87)',
								borderRadius: '.28571429rem'
							}}
							error={props.commentsField.required ? Boolean(props.commentsField.error) : false}
							disabled={props.commentsField.disabled}
							placeholder={props.commentsField.placeholder}
						/>
					</div>
				</Grid.Column>
				<Grid.Column
					width={15}
					style={{
						paddingTop: '0.8em'
					}}
				>
					<Button
						primary
						onClick={() =>
							props.addDocument(
								props.sectionFields[0],
								props.sectionFields[1],
								props.sectionFields[2],
								props.sectionFields[3],
								props.sectionIndex
							)
						}
					>
						<Icon name="plus" />
						Add Document
					</Button>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={15}>
					<div
						className="ag-theme-balham"
						style={{
							height: '20vh',
							width: '100%',
							textAlign: 'left',
							boxSizing: 'border-box'
						}}
					>
						<AgGridReact
							suppressHorizontalScroll={true}
							columnDefs={columnDefs}
							defaultColDef={defaultColDef}
							rowHeight={40}
							id="documentGrid"
							rowData={props.documentsData ? props.documentsData : null}
							enableStatusBar={true}
							alwaysShowStatusBar={false}
							onGridReady={onGridReady}
							enableRangeSelection={true}
							modules={AllModules}
							// enable delta updates
							// deltaRowDataMode={true}
							// return id required for delta updates
							// getRowNodeId={data => data.id}
							{...props}
						/>
					</div>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</Segment>
);

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.crm.createProject.createProjectModal.sections.findIndex(
			(section) => section.name === 'documentsSection'
		),
		sectionFields: state.crm.createProject.createProjectModal.sections.filter(
			(section) => section.name === 'documentsSection'
		)[0].fields,
		commentsField: state.crm.createProject.createProjectModal.sections
			.filter((section) => section.name === 'documentsSection')[0]
			.fields.filter((field) => field.name === 'Comments')[0],
		commentsFieldIndex: state.crm.createProject.createProjectModal.sections
			.filter((section) => section.name === 'documentsSection')[0]
			.fields.findIndex((field) => field.name === 'Comments'),
		documentsData: state.crm.createProject.createProjectModal.sections.filter(
			(section) => section.name === 'documentsSection'
		)[0].documents
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateProjectModal: (modal, sectionIndex, fieldIndex, value, error) => {
			dispatch(updateCreateProjectModal(modal, sectionIndex, fieldIndex, value, error));
		},
		addDocument: (fileName, googleDrive, customerApproval, comments, sectionIndex) => {
			dispatch(addDocument(fileName, googleDrive, customerApproval, comments, sectionIndex));
		},
		updateCreateProjectModalDocumentGrid: (rowIndex, sectionIndex, value) => {
			dispatch(updateCreateProjectModalDocumentGrid(rowIndex, sectionIndex, value));
		},
		removeCreateProjectModalDocumentGrid: (rowIndex, sectionIndex) => {
			dispatch(removeCreateProjectModalDocumentGrid(rowIndex, sectionIndex));
		}
	};
};

const columnDefs = [
	{
		headerName: 'File Name',
		field: 'fileName',
		cellStyle: {paddingTop: '.4em'}
		// cellRendererFramework: ActiveToggleCellRenderer
	},
	{
		headerName: 'Google Drive',
		field: 'googleDrive',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function(params) {
			return `<a href="${params.value}" target="_blank">${params.value}</a>`;
		}
	},
	{
		headerName: 'Comments',
		field: 'comments',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Customer Approval ',
		field: 'customerApproval',
		cellStyle: {paddingTop: '.4em'},
		cellRendererFramework: DocumentSectionCheckbox
	},
	{
		headerName: 'Remove',
		cellStyle: {paddingTop: '.4em'},
		cellRendererFramework: DocumentSectionRemoveButton
	}
];

const defaultColDef = {
	sortable: true,
	filter: true,
	resizable: true
};

const onGridReady = (params) => {
	params.api.sizeColumnsToFit();
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsSection);
