// Modules
import React from 'react';
import {Segment, Label, Icon, Menu, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Charts from '../../../../../components/charts/Charts.js';

// Services
import {selectChartType, refreshChart} from '../services/analyticsActions.js';

let ChartWrapper = ({name, chartType, chart, selectChartType, refreshChart}) => (
	<Segment>
		<Label attached="top" color="blue" content={name} />
		{chart.fetched ? (
			chart.error ? (
				<Segment basic>
					Hmmm, it looks like something went wrong when I was trying to pull this data. Try again, or contact
					the Dev team if it keeps happening
					<Segment basic style={{paddingBottom: '0'}}>
						<Button icon onClick={() => refreshChart(chartType)}>
							<Icon name="refresh" />
						</Button>
					</Segment>
				</Segment>
			) : (
				[
					<Charts
						key={`chart-${name}`}
						type={chart.fetched ? chart.type : null}
						data={chart.fetched ? chart.data : null}
					/>,
					<Menu key={`chartMenu-${name}`} icon="labeled" size="mini" widths="6">
						<Menu.Item
							name="bar-chart"
							active={chart.type === 'bar'}
							onClick={() => selectChartType(chartType, 'bar')}
						>
							<Icon name="chart bar" />
							Bar
						</Menu.Item>

						<Menu.Item
							name="pie-chart"
							active={chart.type === 'pie'}
							onClick={() => selectChartType(chartType, 'pie')}
						>
							<Icon name="chart pie" />
							Pie
						</Menu.Item>

						<Menu.Item
							name="column-chart"
							active={chart.type === 'column'}
							onClick={() => selectChartType(chartType, 'column')}
						>
							<Icon name="chart bar" />
							Column
						</Menu.Item>

						<Menu.Item
							name="line-chart"
							active={chart.type === 'line'}
							onClick={() => selectChartType(chartType, 'line')}
						>
							<Icon name="chart line" />
							Line
						</Menu.Item>

						<Menu.Item
							name="area-chart"
							active={chart.type === 'area'}
							onClick={() => selectChartType(chartType, 'area')}
						>
							<Icon name="chart area" />
							Area
						</Menu.Item>
					</Menu>
				]
			)
		) : (
			<Segment basic loading={!chart.fetched} style={{height: '20rem'}} />
		)}
	</Segment>
);

ChartWrapper.propTypes = {
	selectChartType: PropTypes.func.isRequired,
	refreshChart: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	chartType: PropTypes.string.isRequired,
	chart: PropTypes.object.isRequired
};

const mapStateToProps = (state, {chartType}) => {
	return {
		chart: state.crm.pipeline.analytics.charts[chartType]
			? state.crm.pipeline.analytics.charts[chartType]
			: {fetched: false}
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		selectChartType: (name, type) => {
			dispatch(selectChartType(name, type));
		},
		refreshChart: (name) => {
			dispatch(refreshChart(name));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartWrapper);
