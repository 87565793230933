import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Modal, Icon, Header} from 'semantic-ui-react';
import {toggleDeactivateUserModal, setDeactivateUserData} from '../../../../../services/accountingConsoleActions.js';
import api from '../../../../../../../lib/api.ts';

class DeactivateUserModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmitting: false
		};
		this.closeModal = this.closeModal.bind(this);
		this.deactiveUser = this.deactiveUser.bind(this);
	}

	closeModal() {
		const {setDeactivateUserData, toggleDeactivateUserModal} = this.props;
		setDeactivateUserData({});
		toggleDeactivateUserModal(false);
		this.setState((prevState) => ({...prevState, isSubmitting: false}));
	}

	async deactiveUser() {
		const {userToBeDeactivated} = this.props;
		const user = Object.assign({}, userToBeDeactivated);
		let param = '';
		if (userToBeDeactivated.aasdiUserId) {
			user.roleType = 3;
			param = 'aasdi';
		} else if (userToBeDeactivated.clientUserId) {
			user.roleType = 3;
			param = 'client';
		} else {
			user.isActive = false;
			param = 'employee';
		}
		const endpoint = `legacy/employee?type=${param}`;
		this.setState((prevState) => ({...prevState, isSubmitting: true}));
		try {
			await api(endpoint, 'put', user);
			this.closeModal();
		} catch (error) {
			console.error(error);
			this.closeModal();
		}
	}

	render() {
		const {isSubmitting} = this.state;
		const {isOpen, userToBeDeactivated} = this.props;
		return (
			<Modal open={isOpen} size="small" onUnmount={this.onUnmount}>
				<Header>
					<div>Deactivate User</div>
				</Header>
				<Modal.Content>
					<p>{`Are you sure you want to deactivate user ${userToBeDeactivated.firstName}?`}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button color="red" onClick={() => this.closeModal()} disabled={isSubmitting}>
							<Icon name="remove" />
							No
						</Button>
						<Button
							color="green"
							onClick={() => this.deactiveUser()}
							loading={isSubmitting}
							disabled={isSubmitting}
						>
							<Icon name="checkmark" />
							Yes
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

DeactivateUserModal.propTypes = {
	toggleDeactivateUserModal: PropTypes.func,
	setDeactivateUserData: PropTypes.func,
	isOpen: PropTypes.bool,
	userToBeDeactivated: PropTypes.object
};

const mapStateToProps = (state) => ({
	isOpen: state.accountingConsoleReducer.accountingConsole.isDeactivateUserModalOpen,
	userToBeDeactivated: state.accountingConsoleReducer.accountingConsole.userToBeDeactivated
});

const mapDispatchToProps = (dispatch) => ({
	toggleDeactivateUserModal: (status) => dispatch(toggleDeactivateUserModal(status)),
	setDeactivateUserData: (user) => dispatch(setDeactivateUserData(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateUserModal);
