import update from 'react-addons-update';
import moment from 'moment';

export default (
	state = {
		startDate: moment().startOf('month').toISOString(),
		endDate: moment().endOf('month').toISOString(),
		branch: null,
		accountExecutiveId: null,
		serviceType: null,
		technology: null,
		employees: [],
		getAccountExecLoading: false
	},
	action
) => {
	switch (action.type) {
		case 'MONTHLY_COMMIT_UPDATE_START_DATE':
			state = update(state, {
				startDate: {
					$set: action.payload.value._d.toISOString()
				}
			});
			break;
		case 'MONTHLY_COMMIT_UPDATE_END_DATE':
			state = update(state, {
				endDate: {
					$set: action.payload.value._d.toISOString()
				}
			});
			break;
		case 'MONTHLY_COMMIT_UPDATE_SELECTED_BRANCH':
			state = update(state, {
				branch: {
					$set: action.payload.value
				}
			});
			break;
		case 'MONTHLY_COMMIT_UPDATE_SELECTED_ACCOUNT_EXECUTIVE':
			state = update(state, {
				accountExecutiveId: {
					$set: action.payload.value
				},
				getAccountExecLoading: {
					$set: false
				}
			});
			break;
		case 'MONTHLY_COMMIT_UPDATE_SELECTED_SERVICE_TYPE':
			state = update(state, {
				serviceType: {
					$set: action.payload.value
				}
			});
			break;
		case 'MONTHLY_COMMIT_UPDATE_SELECTED_TECHNOLOGY':
			state = update(state, {
				technology: {
					$set: action.payload.value
				}
			});
			break;
		case 'MONTHLY_COMMIT_GET_EMPLOYEE_DROPDOWN_OPTIONS':
			state = update(state, {
				getAccountExecLoading: {
					$set: false
				},
				employees: {
					$set: action.payload.employees
				}
			});
			break;
		case 'MONTHLY_COMMIT_GET_EMPLOYEE_DROPDOWN_LOADING':
			state = update(state, {
				getAccountExecLoading: {
					$set: action.payload
				}
			});
			break;
		default:
	}
	return state;
};
