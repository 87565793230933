import React from 'react';
import {connect} from 'react-redux';
import {selectSnackbar} from '../services/TaskTemplates.selectors.js';
import {resetSnackbarValues} from '../services/TaskTemplates.actions.js';
import {Message, Icon} from 'semantic-ui-react';
import {Redirect} from 'react-router-dom';

function Snackbar(props) {
	const {snackbar} = props;

	if (snackbar.open) {
		return [
			<Message
				key="task-template-snackbar"
				icon
				onDismiss={() => props.resetSnackbarValues()}
				color={snackbar.color}
				style={{
					position: 'fixed',
					width: 400,
					right: 10,
					top: 60
				}}
			>
				{snackbar.icon ? <Icon name={snackbar.icon} loading={snackbar.loading} /> : null}
				{snackbar.message}
			</Message>,
			snackbar.redirect ? <Redirect to={snackbar.redirect} /> : null
		];
	}

	return null;
}

export const mapStateToProps = (state) => ({
	snackbar: selectSnackbar(state)
});

export const mapDispatchToProps = (dispatch) => ({
	resetSnackbarValues: () => dispatch(resetSnackbarValues())
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
