import {Label, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const InputMaskLabels = (props) => [
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={
			<Label
				key={uuidv4()}
				color={`blue`}
				icon={`text cursor`}
				content={props.task.taskTypeId === 3 ? ` Form Input` : ` Free Form Text Area`}
			/>
		}
		content="A free form text area task."
		on="hover"
	/>,
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={
			<Label
				key={uuidv4()}
				color={(() => {
					if (!props.task.maskName) return 'red';
				})()}
				icon={(() => {
					switch (props.task.maskName) {
						case null:
							return 'bug';
						case 'Phone Number':
							return 'phone';
						case 'Number':
							return 'hashtag';
						case 'Text':
							return 'text cursor';
						case 'Website URL':
							return 'linkify';
						case 'Email Address':
							return 'mail';
						case 'Date':
							return 'calendar';
						case 'Time':
							return 'clock';
						default:
							return 'question circle';
					}
				})()}
				content={` ${props.task.maskName ? props.task.maskName : 'ERROR'}`}
			/>
		}
		content="Mask type."
		on="hover"
	/>
];
export default InputMaskLabels;

InputMaskLabels.propTypes = {
	task: PropTypes.object.isRequired
};
