import React from 'react';

import {Message, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const MESSAGE_STATE = {
	none: 'none',
	loading: 'loading',
	success: 'success',
	error: 'error'
};

function GenericMessage(props) {
	const {type, content} = props;

	if (type === MESSAGE_STATE.none) {
		return null;
	}

	const loading = type === MESSAGE_STATE.loading;
	const positive = type === MESSAGE_STATE.success;
	const negative = type === MESSAGE_STATE.error;

	let icon;
	if (loading) {
		icon = <Icon name="circle notched" loading />;
	}
	if (positive) {
		icon = <Icon name="check" />;
	}
	if (negative) {
		icon = <Icon name="times" />;
	}

	return (
		<Message positive={positive} negative={negative} icon floating>
			{icon}
			<Message.Header>{content}</Message.Header>
		</Message>
	);
}

GenericMessage.propTypes = {
	message: PropTypes.shape({
		type: PropTypes.oneOf(Object.values(MESSAGE_STATE)),
		content: PropTypes.string
	})
};

export default GenericMessage;
