import update from 'react-addons-update';
import countryOptions from '../../../constants/countries.js';
import stateOptions from '../../../constants/states.js';

export default (
	state = {
		createLocationModal: {
			id: null,
			loading: null,
			clientId: null,
			toggleCreateLocationModal: false,
			radioGroup: {
				existing: true,
				newContact: false
			},
			modalSteps: [
				{
					name: 'Location',
					active: true,
					icon: {
						name: 'map marker alternate',
						color: 'black'
					},
					description: 'location description',
					modalFields: [
						{
							name: 'Location Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Store Number',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Address',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Address 2',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false
						},
						{
							name: 'City',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Zip Code',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'State',
							value: null,
							placeholder: 'Choose state',
							options: stateOptions,
							error: false,
							disabled: false,
							upward: true,
							required: true
						},
						{
							name: 'Country',
							value: 218,
							placeholder: 'Choose country',
							options: countryOptions,
							error: false,
							disabled: false,
							upward: true,
							required: true
						},
						{
							name: 'Union Labor Site',
							value: false,
							options: [
								{
									key: 0,
									text: 'False',
									value: false
								},
								{
									key: 1,
									text: 'True',
									value: true
								}
							],
							error: false,
							disabled: false,
							upward: true,
							required: false
						}
					]
				},
				{
					name: 'Site Contact',
					description: 'contact description',
					active: false,
					icon: {
						name: 'phone',
						color: 'black'
					},
					modalFields: [
						{
							name: 'Search for Contact',
							value: null,
							input: false,
							error: false,
							disabled: false,
							searchContact: true,
							options: null,
							loading: false,
							required: false
						},
						{
							name: 'First Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Last Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Email',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Extension',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false
						},
						{
							name: 'Phone',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						}
					]
				}
			]
		}
	},
	action
) => {
	switch (action.type) {
		case 'CREATE_LOCATION_MODAL_LOADING':
			state = update(state, {
				createLocationModal: {
					loading: {
						$set: action.payload.state
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_UPDATE':
			state = update(state, {
				[action.payload.modal]: {
					modalSteps: {
						[action.payload.step]: {
							icon: {
								color: {
									$set: 'black'
								}
							},
							modalFields: {
								[action.payload.column]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_TOGGLE':
			state = update(state, {
				createLocationModal: {
					toggleCreateLocationModal: {
						$set: !state.createLocationModal.toggleCreateLocationModal
					},
					id: {
						$set: action.payload.id
					},
					clientId: {
						$set: action.payload.clientId
					},
					modalSteps: {
						$set: baseModalSteps
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_SET_ACTIVE_STEP':
			state = update(state, {
				createLocationModal: {
					modalSteps: {
						[action.payload.currentStep]: {
							active: {
								$set: false
							}
						},
						[action.payload.targetStep]: {
							active: {
								$set: true
							}
						}
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_GET_DROPDOWN_OPTIONS':
			state = update(state, {
				createLocationModal: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								[action.payload.column]: {
									options: {
										$set: action.payload.options
									},
									loading: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_LOADING_FIELD':
			state = update(state, {
				createLocationModal: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								[action.payload.column]: {
									loading: {
										$set: true
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_AUTO_FILL':
			state = update(state, {
				[action.payload.modal]: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								1: {
									value: {
										$set: action.payload.firstName
									},
									error: {
										$set: false
									}
								},
								2: {
									value: {
										$set: action.payload.lastName
									},
									error: {
										$set: false
									}
								},
								3: {
									value: {
										$set: action.payload.email
									},
									error: {
										$set: false
									}
								},
								4: {
									value: {
										$set: action.payload.extension
									},
									error: {
										$set: false
									}
								},
								5: {
									value: {
										$set: action.payload.phone
									},
									error: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_UPDATE_RADIO':
			state = update(state, {
				createLocationModal: {
					radioGroup: {
						[action.payload.previous]: {
							$set: !state.createLocationModal.radioGroup[action.payload.previous]
						},
						[action.payload.target]: {
							$set: !state.createLocationModal.radioGroup[action.payload.target]
						}
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_UPDATE_RADIO_CLEAR':
			state = update(state, {
				createLocationModal: {
					modalSteps: {
						1: {
							modalFields: {
								0: {
									value: {
										$set: null
									}
								},
								1: {
									value: {
										$set: ''
									},
									error: {
										$set: false
									}
								},
								2: {
									value: {
										$set: ''
									},
									error: {
										$set: false
									}
								},
								3: {
									value: {
										$set: ''
									},
									error: {
										$set: false
									}
								},
								4: {
									value: {
										$set: ''
									},
									error: {
										$set: false
									}
								},
								5: {
									value: {
										$set: ''
									},
									error: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_LOCATION_MODAL_CHECK_REQUIRED_FIELDS':
			state = update(state, {
				createLocationModal: {
					modalSteps: {
						[action.payload.stepIndex]: {
							icon: {
								color: {
									$set: 'red'
								}
							},
							modalFields: {
								[action.payload.fieldIndex]: {
									error: {
										$set: true
									}
								}
							}
						}
					}
				}
			});

			break;
	}

	return state;
};

const baseModalSteps = [
	{
		name: 'Location',
		active: true,
		icon: {
			name: 'map marker alternate',
			color: 'black'
		},
		description: 'location description',
		modalFields: [
			{
				name: 'Location Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Store Number',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Address',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Address 2',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false
			},
			{
				name: 'City',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Zip Code',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'State',
				value: null,
				placeholder: 'Choose state',
				options: stateOptions,
				error: false,
				disabled: false,
				upward: true,
				required: true
			},
			{
				name: 'Country',
				value: 218,
				placeholder: 'Choose country',
				options: countryOptions,
				error: false,
				disabled: false,
				upward: true,
				required: true
			},
			{
				name: 'Union Labor Site',
				value: false,
				options: [
					{
						key: 0,
						text: 'No',
						value: false
					},
					{
						key: 1,
						text: 'Yes',
						value: true
					}
				],
				error: false,
				disabled: false,
				upward: true,
				required: false
			}
		]
	},
	{
		name: 'Site Contact',
		description: 'contact description',
		active: false,
		icon: {
			name: 'phone',
			color: 'black'
		},
		modalFields: [
			{
				name: 'Search for Contact',
				value: null,
				input: false,
				error: false,
				disabled: false,
				searchContact: true,
				options: null,
				loading: false,
				required: false
			},
			{
				name: 'First Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Last Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Email',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Extension',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false
			},
			{
				name: 'Phone',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			}
		]
	}
];
