import React from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {Button, Grid, Icon, Label, Input} from 'semantic-ui-react';
import ActiveToggleCellRenderer from './components/activeToggleCellRenderer.js';
import {roundFloat} from '~/util/numbers';
import {fmtMoney} from '../../util/formatting.js';
import {calcMargin} from '../../util/moneymath';

interface BillingDetailsGridProps {
	updateRecord?: (id: number, name: string, value: number) => void;
	resetRecord?: () => void;
	slim: boolean;
	rowData: {
		value: string,
		revenueLabor: number | null,
		revenueMaterial: number | null,
		estimatedLaborCost: number | null,
		estimatedMaterialCost: number | null,
		totalRevenue: number | null,
		totalCost: number | null,
		toggled: boolean
	}[];
}

const BillingDetailsGrid = (props: BillingDetailsGridProps) => {
	// makes cells uneditable when tech is set to inactive
	function editableActive(params: any) {
		let active = params.data.toggled;
		return active;
	}

	const updateMoneyField = (params:any) => {
		const newNumber = roundFloat(toNumber(params.newValue), 2);
		if (props.updateRecord) props.updateRecord(params.data.id, params.colDef.field, newNumber);
		//return true telling ag-grid needs to know data updated successfully
		return true;
	};

	const defaultColDef = {
		editable: false,
		sortable: true,
		filter: true,
		resizable: true,
		menuTabs: ['generalMenuTab'],
		suppressPaste: true
	};

	const slimDefs = [
		{
			headerName: 'Technologies',
			filter: 'agTextColumnFilter',
			field: 'value',
			cellStyle: {paddingTop: '.4em'},
			minWidth: 100
		},
		{
			headerName: 'Labor Revenue',
			field: 'revenueLabor',
			editable: props.slim ? false : editableActive,
			cellStyle: {paddingTop: '.4em'},
			filter: 'agNumberColumnFilter',
			valueSetter: updateMoneyField,
			valueFormatter: (obj: {value:number}) => fmtMoney(obj.value),
			type: 'rightAligned',
			minWidth: 100
		},
		{
			headerName: 'Material Revenue',
			field: 'revenueMaterial',
			filter: 'agTextColumnFilter',
			editable: props.slim ? false : editableActive,
			cellStyle: {paddingTop: '.4em'},
			valueSetter: updateMoneyField,
			valueFormatter: (obj: {value:number}) => fmtMoney(obj.value),
			type: 'rightAligned',
			minWidth: 100
		},
		{
			headerName: 'Estimate Labor Cost',
			field: 'estimatedLaborCost',
			filter: 'agTextColumnFilter',
			editable: props.slim ? false : editableActive,
			cellStyle: {paddingTop: '.4em'},
			valueSetter: updateMoneyField,
			valueFormatter: (obj: {value:number}) => fmtMoney(obj.value),
			type: 'rightAligned',
			minWidth: 100
		},
		{
			headerName: 'Estimated Material Cost',
			field: 'estimatedMaterialCost',
			filter: 'agTextColumnFilter',
			editable: props.slim ? false : editableActive,
			cellStyle: {paddingTop: '.4em'},
			valueSetter: updateMoneyField,
			valueFormatter: (obj: {value:number}) => fmtMoney(obj.value),
			type: 'rightAligned',
			minWidth: 100
		},
		{
			headerName: 'Total Revenue',
			field: 'totalRevenue',
			filter: 'agTextColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em'},
			valueGetter: (params:any) => params.getValue('revenueLabor') + params.getValue('revenueMaterial'),
			valueFormatter: (obj: {value:number}) => fmtMoney(obj.value),
			type: 'rightAligned',
			minWidth: 100
		},
		{
			headerName: 'Total Cost',
			field: 'totalCost',
			filter: 'agTextColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em'},
			valueGetter: (params:any) => params.getValue('estimatedLaborCost') + params.getValue('estimatedMaterialCost'),
			valueFormatter: (obj: {value:number}) => fmtMoney(obj.value),
			type: 'rightAligned',
			minWidth: 100
		},
		{
			headerName: 'GP %',
			field: 'gp',
			filter: 'agTextColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em'},
			width: 100,
			valueGetter: (params: any) => {
				const totalCost = params.data.estimatedLaborCost + params.data.estimatedMaterialCost;
				const totalRevenue = params.data.revenueLabor + params.data.revenueMaterial;
				return calcMargin(totalCost, totalRevenue);
			},
			valueFormatter: (params: any) => {
				if (Number.isNaN(params.value)) {
					return '';
				}
				return `${(params.value * 100).toFixed(1)}%`;
			},
			type: 'rightAligned',
			minWidth: 70
		}
	];

	const columnDefs = [
		{
			headerName: 'Active',
			field: 'toggled',
			cellRendererFramework: ActiveToggleCellRenderer,
			cellRendererParams: {
				update: props.updateRecord,
				reset: props.resetRecord
			},
			cellStyle: {paddingTop: '.4em'},
			minWidth: 80,
			maxWidth: 80
		},
		...slimDefs
	];

	const sideBar = {
		position: 'right',
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true,
					suppressPivots: true,
					suppressPivotMode: true
				}
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		]
	};

	function onQuickFilterChanged(value: string) {
		//@ts-ignore - built into ag grid
		setQuickFilter(value);
	}

	// in onGridReady, store the api for later use
	const onGridReady = (params:any) => {
		const gridApi = params.api;
		gridApi.sizeColumnsToFit();
	};

	return (
		<div
			style={{
				padding: '1em'
			}}
		>
			<Grid style={{padding: '0 0 1.5em 0'}}>
				{props.slim ? (
					<></>
				) : (
					<>
						<Grid.Column width={8}>
							<Label
								style={{
									float: 'left',
									backgroundColor: 'transparent',
									fontSize: '.92rem',
									paddingTop: '1em'
								}}
							>
								Filter Grid
							</Label>
							<Input
								type="text"
								onChange={(e, {value}) => onQuickFilterChanged(value)}
								id="quickFilterCreateProjectModal"
								icon="search"
							/>
						</Grid.Column>
						<Grid.Column width={8}>
							<Input
								style={{float: 'right'}}
								type="text"
								id="fileNameCreateProjectModal"
								placeholder="Name file for export"
								action
							>
								<input />
								<Button
									type="submit"
									onClick={() => {
										//@ts-ignore - this comes from ag grid
										exportHandler(
											//@ts-ignore - this will always exist
											document.getElementById('fileNameCreateProjectModal').value
										);
									}}
									style={{fontSize: '.95rem'}}
								>
									Export
									<Icon name="arrow right" />
								</Button>
							</Input>
						</Grid.Column>
					</>
				)}
				<Grid.Column width={16}>
					<div
						className="ag-theme-balham"
						style={{
							height: props.slim ? undefined : '41vh',
							width: props.slim ? '65em' : '100%',
							textAlign: 'left',
							boxSizing: 'border-box'
						}}
					>
						<AgGridReact
							columnDefs={props.slim ? slimDefs : columnDefs}
							id="myGrid"
							rowData={props.rowData || []}
							pinnedBottomRowData={calculateTotals(props.rowData)}
							onGridReady={onGridReady}
							defaultColDef={defaultColDef}
							modules={AllModules}
							suppressRowClickSelection={false}
							// @ts-ignore - false is a valid input but ts doesn't think it is
							sideBar={props.slim ? false : sideBar}
							suppressDragLeaveHidesColumns={true}
							suppressContextMenu={true}
							suppressMultiRangeSelection={true}
							rowHeight={40}
							immutableData={true}
							// return id required for delta updates
							getRowNodeId={(data) => data.id}
							domLayout="autoHeight"
							isExternalFilterPresent={() => {
								return true;
							}}
							doesExternalFilterPass={(node) => {
								if (props.slim) {
									return node.data.toggled;
								} else {
									return true;
								}
							}}
						/>
					</div>
				</Grid.Column>
			</Grid>
		</div>
	);
}

function toNumber(numstr: string) {
	const num = Number.parseFloat(numstr);
	if (Number.isNaN(num)) {
		return 0;
	}
	return num;
}

function calculateTotals(rowData: BillingDetailsGridProps["rowData"]) { // use type imported
	let pinnedRow = [{revenueLabor: 0, revenueMaterial: 0, estimatedLaborCost: 0, estimatedMaterialCost: 0}];

	rowData.forEach((row: any) => {
		pinnedRow[0].revenueLabor += row.revenueLabor
		pinnedRow[0].revenueMaterial += row.revenueMaterial
		pinnedRow[0].estimatedLaborCost += row.estimatedLaborCost
		pinnedRow[0].estimatedMaterialCost += row.estimatedMaterialCost
	})
	return pinnedRow;
}

export default BillingDetailsGrid;
