import customFetch from '../../../../../../../../../lib/old/customFetch.js';

export let selectCurrentStage = (stageName) => {
	return (dispatch) => {
		dispatch({
			type: 'CRM_PIPELINE_ANALYTICS_UPDATE_STAGE',
			payload: stageName
		});
		return;
	};
};

export let selectChartType = (name, type) => {
	return (dispatch) => {
		dispatch({
			type: 'CRM_PIPELINE_UPDATE_CHART_TYPE_ANALYTICS',
			name,
			payload: type
		});
		return;
	};
};

export const refreshChart = (name) => {
	return (dispatch) => {
		dispatch({
			type: 'CRM_PIPELINE_UPDATE_PIPELINE_CHART_ANALYTICS',
			name,
			payload: {
				data: null,
				fetched: false
			}
		});

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/analytics/chart-data/${name}`)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_CHART_ANALYTICS',
					name,
					payload: {
						data: json,
						fetched: true,
						type: 'bar'
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_CHART_ANALYTICS',
					name,
					payload: {
						fetched: true,
						error: true,
						errorMessage: err
					}
				});
			});
	};
};
