import customFetch from '../../lib/old/customFetch.js';
import React from 'react';
import {Popup, Button} from 'semantic-ui-react';
async function genericRequest(endpoint, requestOptions) {
	const request = await customFetch(endpoint, requestOptions);
	const response = await request.json();

	if (request.status !== 200) {
		throw new Error(JSON.stringify(response));
	}

	return response;
}

export async function getDesyncedJobs() {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=desync-failed`;
	return genericRequest(endpoint);
}

export async function deleteDesyncedFailureRecord(recordId) {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=desync-failed&desyncedFailureId=${recordId}`;
	return genericRequest(endpoint, {method: 'DELETE'});
}

export async function updateDesyncedFailureRecord(updatedRecord) {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=desync-failed`;
	return genericRequest(endpoint, {
		method: 'PUT',
		body: JSON.stringify(updatedRecord)
	});
}

export async function setPendingOpsToZero(entityPath) {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=desync-pending-ops`;
	return genericRequest(endpoint, {method: 'PUT', body: JSON.stringify({entityPath})});
}

const popupPositioning = {position: 'bottom left', basic: true};

export function renderGridActionButtons(params, editAction, resyncAction, deleteAction, pendingOpsAction) {
	return [
		<Popup
			{...popupPositioning}
			key="edit"
			content="Edit record body"
			trigger={<Button icon="edit" className="grid-button" onClick={() => editAction()} />}
		/>,
		<Popup
			{...popupPositioning}
			key="zero"
			content="Set pending ops to zero"
			trigger={<Button icon="circle" className="grid-button" onClick={() => pendingOpsAction()} />}
		/>,
		<Popup
			{...popupPositioning}
			key="resync"
			content="Attempt to resync"
			trigger={<Button icon="redo" className="grid-button" onClick={() => resyncAction()} />}
		/>,
		<Popup
			{...popupPositioning}
			key="delete"
			content="Delete record"
			trigger={
				<Button icon="trash" className="grid-button" style={{color: 'red'}} onClick={() => deleteAction()} />
			}
		/>
	];
}

export const sidebar = {
	toolPanels: [
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel'
		}
	],
	position: 'right'
};

export const defaultColDef = {
	sortable: true,
	filter: true,
	resizable: true
};
