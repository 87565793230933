import moment from 'moment';
import {getList} from '../../../../../lib/old/paginationGet.js';
import customFetch from '../../../../../lib/old/customFetch.js';

import {pagingGet} from '~/services/pagingGet';

const formatWorkOrder = (order, category) => {
	if (order.oppStartDate) {
		order.startDate = moment.utc(order.oppStartDate).format('MM-DD-YYYY');
	} else {
		if (order.startDate === null) {
			order.startDate = moment(new Date()).format('MM-DD-YYYY');
		} else {
			order.startDate = moment.utc(order.startDate).format('MM-DD-YYYY');
		}
	}

	if (order.oppEndDate) {
		order.endDate = moment.utc(order.oppEndDate).format('MM-DD-YYYY');
	} else {
		if (order.endDate === null) {
			order.endDate = moment(new Date()).format('MM-DD-YYYY');
		} else {
			order.endDate = moment.utc(order.endDate).format('MM-DD-YYYY');
		}
	}

	if (order.spectrumInvoiceDate) {
		order.invoiceDate = order.spectrumInvoiceDate;
	}

	order.spectrumEstimatedRevenue = order.spectrumEstimatedRevenue ? order.spectrumEstimatedRevenue : 0;
	order.spectrumEstimatedChangeOrderRevenue = order.spectrumEstimatedChangeOrderRevenue
		? order.spectrumEstimatedChangeOrderRevenue
		: 0;
	order.spectrumActualRevenue = order.spectrumActualRevenue ? order.spectrumActualRevenue : 0;
	order.spectrumActualChangeOrderRevenue = order.spectrumActualChangeOrderRevenue
		? order.spectrumActualChangeOrderRevenue
		: 0;
	order.spectrumActualCost = order.spectrumActualCost ? order.spectrumActualCost : 0;
	order.spectrumActualChangeOrderCost = order.spectrumActualChangeOrderCost ? order.spectrumActualChangeOrderCost : 0;
	order.spectrumOpenCommit = order.spectrumOpenCommit ? order.spectrumOpenCommit : 0;

	if (category === 'Support') {
		let createdOn = moment(order.createdOn);
		let scheduledDate = moment(order.actualDate);

		order.dateReceived = order.dateReceived || order.createdOn;

		order.daysToSchedule = {};
		order.daysToSchedule.number = scheduledDate.diff(createdOn, 'days');
		order.daysToSchedule.color = order.daysToSchedule.number > 1 ? 'salmon' : 'yellowgreen';

		order.daysToComplete = {};
		let completedOn = moment(order.workCompletedOn);
		order.daysToComplete.number = completedOn.diff(scheduledDate, 'days');

		order.daysToInvoice = {};
		let invoicedDate = moment(order.invoiceDate);
		order.daysToInvoice.number = invoicedDate.diff(completedOn, 'days');
		order.daysToInvoice.color = order.daysToInvoice.number > 2 ? 'salmon' : 'yellowgreen';

		order.daysToClose = {};
		let requestedToCloseDate = moment(order.requestedToCloseDate);
		order.daysToClose.number = requestedToCloseDate.diff(invoicedDate, 'days');
		order.daysToClose.color = order.daysToClose.number > 30 ? 'salmon' : 'yellowgreen';

		if (order.serviceSubName === 'Small Project') {
			order.daysToComplete.color = order.daysToComplete.number > 5 ? 'salmon' : 'yellowgreen';
		} else if (order.serviceSubName === 'Move, Add, Change') {
			order.daysToComplete.color = order.daysToComplete.number > 10 ? 'salmon' : 'yellowgreen';
		} else {
			order.daysToComplete.color = order.daysToComplete.number > 7 ? 'salmon' : 'yellowgreen';
		}
	}
	return order;
};

const appendWorkRows = (newRows, passThroughObj) => {
	return (dispatch, getState) => {
		const state = getState();
		const selectedCategory = state.work.workGrid.rowMenu[state.work.workGrid.activeTabIndex].name;
		if (passThroughObj.category === selectedCategory) {
			if (newRows) {
				newRows.map((order) => {
					return formatWorkOrder(order, passThroughObj.category);
				});
			} else {
				newRows = [];
			}
			dispatch({
				type: 'WORK_FETCH_WORK_GRID_REPORTS',
				payload: newRows
			});

			dispatch({
				type: 'WORK_GRID_LOADER',
				payload: {
					value: false
				}
			});
			return true;
		} else {
			return false;
		}
	};
};

export const getWorkGrid = (categoryObj) => {
	return async (dispatch, getState) => {
		dispatch({
			type: 'WORK_GRID_LOADER',
			payload: {
				value: true
			}
		});
		dispatch({
			type: 'WORK_FETCH_WORK_GRID',
			payload: []
		});

		const state = getState();
		const workGridToggled = state.work.workGrid.toggled;
		const userRole = state.auth.account.roleTypeName;
		let endpoint = `/legacy/work?`;

		if (categoryObj.statusId) {
			endpoint += `gridSearch=status&statusId=${categoryObj.statusId}`;
		} else {
			endpoint += `gridSearch=${categoryObj.name.toLowerCase().replace(/\s+/g, '')}`;
		}

		if (userRole === 'Standard' && !workGridToggled) {
			endpoint += `&currentUserDataOnly=true`;
		}

		await dispatch(pagingGet(endpoint, 'last', 2000, appendWorkRows, {category: categoryObj.name}));
	};
};

export const updateActiveMenuItem = (index) => {
	return (dispatch, getState) => {
		const previousActiveItemIndex = getState().work.workGrid.rowMenu.findIndex((item) => item.active);
		// Active Item was clicked, do nothing
		if (previousActiveItemIndex === index) {
			return;
		}

		dispatch({
			type: 'WORK_MENU_ITEM_ACTIVE_WORK_GRID',
			payload: {
				previousActiveItemIndex: previousActiveItemIndex,
				newActiveItemIndex: index
			}
		});

		dispatch({
			type: 'STORE_ACTIVE_TAB_INDEX',
			payload: index
		});
	};
};

export const updateGridItem = (index, data, field) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_GRID_UPDATE_ITEM',
			payload: {
				index,
				data,
				field
			}
		});
	};
};

export const updateProjectionData = (data, rowIndex) => (dispatch) => {
	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${data.commitBillingId}?mode=commits&commitType=CommitBilling`;

	customFetch(endpoint, {
		method: 'PUT',
		body: JSON.stringify({
			projected: data.projectedBilling
		})
	})
		.then((response) => {
			if (response.status !== 200) {
				return Promise.reject(response);
			}
			return response.json();
		})
		.then((json) => {
			dispatch({
				type: 'WORK_GRID_UPDATE_ITEM',
				payload: {
					rowIndex,
					value: data.projectedBilling,
					column: 'projectedBilling'
				}
			});
		})
		.catch((err) => {
			dispatch({
				type: 'ERROR',
				payload: err.stack
			});
		});
};

export const updateWorkGridToggle = () => (dispatch) => {
	dispatch({
		type: 'UPDATE_WORK_GRID_TOGGLE'
	});
};
