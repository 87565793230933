import React from 'react';
import Logo from '../img/asd-logo.png';

// utc epoch began on a Thursday, ASD wants values to rotate on Sunday
let daysElapsed = Date.now() / 1000 / 60 / 60 / 24;
daysElapsed += 4;

// "Follow up on everything" was the value when this feature was approved
let weeksElapsed = daysElapsed / 7;
weeksElapsed += 4;

const quotes = [
	{
		title: 'Own The Process',
		description:
			'The ASD® project management team runs the project. We communicate to the stakeholders in a consistent and efficient manner. We manage the AASDI. We control the scope, schedule and the budget. We do right by our customers and by our company by managing the entire process.'
	},
	{
		title: 'Have Fun - Enjoy The Journey',
		description:
			'Remember that there are a lot of problems in the world and the daily challenges will happen. Embrace the challenges and make it fun to overcome them. Keep things in perspective and have fun on your journey.'
	},
	{
		title: 'Trust',
		bold: 'We are known for our candor and integrity, give our best effort, and expect the best in others.',
		description:
			'You share information openly and proactively. You are quick to admit mistakes and quicker to remedy them.'
	},
	{
		title: 'Respect',
		bold: 'We treat people with high regard, independent of their status or stance, to build enduring and mutually beneficial relationships.',
		description:
			'You listen well instead of reacting fast, so you can better understand. You consider how your actions impact others.'
	},
	{
		title: 'Attitude',
		bold: 'We inspire others with our positive perspective and have an unwavering thirst for excellence.',
		description:
			'You maintain calm composure in stressful situations. You turn challenges and failures into opportunities.'
	},
	{
		title: 'Commitment',
		bold: 'We care intensely about the success of our clients and ASD, and exhibit a bias towards action.',
		description:
			'You accomplish amazing amounts of important work and focus on results. You make wise decisions with the end in mind.'
	},
	{
		title: 'Build Win / Win Scenarios',
		description:
			"It’s always a 2-way street, listen and learn objectives from all parties involved. Uncover the goals and objectives of the people you're dealing with and work to find a solution where everyone wins. Win / Win scenarios will help create long term business relationships that will follow you throughout your whole career."
	},
	{
		title: 'Trust But Verify',
		description:
			'When you deal with our AASDIs, customers, vendors and associates always believe that they are honest and trustworthy, but always question to verify for accuracy and ensure you are both on the same page.'
	},
	{
		title: 'Give',
		description:
			'From a humble beginning, ASD® enjoys success and is committed to supporting the communities where we all live and work.'
	},
	{
		title: 'Always Anchor',
		description:
			'When communicating with AASDIs, Vendors, Customers, etc. always state your position and anchor the conversation, price and position you take.'
	},
	{
		title: 'Deliver Red Carpet Service',
		description:
			'Our clients depend on us and will always be treated with the utmost respect. We will run the extra mile, show our appreciation and possess unquestionable integrity. We will strive to deliver the "best" experience and "wow" them on each and every transaction.'
	},
	{
		title: 'Always Do Right',
		description:
			"Always demonstrate an unwavering commitment to doing what's right for the client and ASD® on each and every transaction. To ensure you're making the right choices ask yourself these questions: Is the decision I'm making right for the customer? Is it right for ASD®? Is it ethical? Is it something I’m willing to be held accountable for? Is it consistent with ASD®’s basic beliefs?"
	},
	{
		title: 'Safety First',
		description:
			"At ASD® our goal on each and every project is to complete the job on time, on budget and on schedule. However, we will never compromise safety as nothing is more important than the health and life of our associates, our AASDIs and customers. We will follow all job site safety guidelines. We will not take short cuts that could put us or our associates in harm's way. We will strive for zero accidents in all engagements."
	},
	{
		title: 'Know Your Scope',
		description:
			'To ensure "red carpet service" on every project we must know the scope of services on the project. We understand that it\'s the little things that sets us apart from our competitors. As professionals, we will clearly communicate to our clients and our AASDIs to ensure we meet and beat the expectation on each and every engagement.'
	},
	{
		title: 'Do Whatever It Takes',
		description:
			'We seek responsibility and accountability for our results. We will do whatever it takes to get the job done, and as a result, set the industry standard for excellence.'
	},
	{
		title: 'Take Extreme Ownership',
		description:
			'When we take on a task we will take it on with purpose and accountability. Take personal responsibility for making things happen. We will always look for ways to get this done. Respond to every situation with a can-do attitude rather than explaining why it can’t be done. Be resourceful and show gumption.'
	},
	{
		title: 'Swim Upstream',
		description:
			'We are innovators and leaders that are passionate about our business. This entrepreneurial spirit is not only our legacy, but more importantly, our future!'
	},
	{
		title: 'Be Cool - Be The Duck!',
		description:
			'No matter the intensity of our business, we will remain a steady force for our clients and teammates. As we all share common goals, we will enjoy the journey and have fun!'
	},
	{
		title: 'Pursue Knowledge',
		description:
			'Grow. Learn. Always. We are constantly seeking knowledge in order to better ourselves and better service our clients'
	},
	{
		title: 'Self Discipline',
		description:
			'Discipline is the bridge between goals and accomplishment. We realize that if we are disciplined in our daily activities we will create unparalleled success for our company, our clients and ourselves.'
	},
	{
		title: 'Relentless Pursuit',
		description:
			'We will regularly reevaluate every aspect of our job to find better ways to service our clients. We will never be satisfied with status quo and we will always look to improve in every aspect of life. We will be Relentless in our pursuit to be the best in the industries we serve!'
	},
	{
		title: 'Shared Fate',
		description:
			'Our future will only be as bright as each and every one of our associates. We will not tolerate "elephants in the rooms" and will communicate openly and respectfully so that we develop the trust that we all desire.'
	},
	{
		title: 'Follow Up On Everything',
		description:
			'We must take responsibility to see that each and every task assigned to us gets completed. We get paid to complete things, not simply to put them in motion. If we take extreme ownership to follow up on the big and little things we will set our organization apart from the competition. This will give us the desired results we are looking for... "Recurring Client Revenue"'
	},
	{
		title: 'Look Ahead And Anticipate',
		description:
			'Solve problems before they happen by anticipating and planning activities with all stakeholders. We don’t have to simply react to a situation, we can anticipate future needs and address them in advance. We must be courageous and diligent in this manner. Don’t sit back idly when you see a customer headed down the wrong path. Speak up and help prevent issues before they happen. Preventing a problem is always more productive than fixing one.'
	},
	{
		title: 'Don’t Get Stuck',
		description: '...and when you do, confide in your colleagues to help you through. Together we are strong.'
	},
	{
		title: 'Be Process Driven',
		description:
			'All great organizations are built on a foundation of repeatable and duplicable systems and processes. Alway look for ways to create a process for every aspect of your work, then turn those processes into habits to achieve consistent results.'
	},
	{
		title: 'Never Take The Easy Way Out',
		description:
			'There are no shortcuts to success. This is true in every aspect of life. If there was an easy way out everyone would be doing it. We will look at the most efficient ways to complete tasks and ensure our customers that we have their back and will go above and beyond to earn their business from project to project. '
	},
	{
		title: 'Deliver Extreme Results',
		description:
			'This is what we do. This is why our clients put trust in us. We work hard to be the best, we care about our clients and the success of our organization. We are relentless in our pursuit to deliver projects that make us stand above the rest. We set the standards and once we hit a milestone we look to set new ones. We are the best at what we do….Period.'
	},
	{
		title: 'Set No Limits',
		description:
			'There are no limits to what we can achieve other than the ones we impose on ourselves. So we will "THINK BIG" and achieve great things.'
	},
	{
		title: 'Be A Rapid Responder',
		description:
			'In today’s world everyone wants things now. The faster the better. Our prospects and clients expect us to respond to their questions and concerns quickly, whether by phone, email or in person. This includes simply acknowledging that we received the questions and we are "on it". We must also keep those involved continuously updated on the status of outstanding issues. You should always strive to give information rather than to receive it from our customers. Rapid response is one of the easiest ways to stand out from the crowd.'
	},
	{
		title: 'Set Clear Expectations',
		description:
			'Establish mutually understood objectives and deadlines on a project upfront. This will help create clarity and avoid misunderstandings that lead to unhappy customers. To ensure everyone is on the same page ask questions and listen. Effectively communicate to ensure total clarity and agreement. Do this on the front end of a project and your customers will be happy and excited to give you deal after deal.'
	},
	{
		title: 'Do What You Say',
		description:
			'Your word matters. The best way to build trust and long-term relationships with your customers and colleagues is to do what you say and follow through on your commitments. Always under-promise and over-deliver. '
	},
	{
		title: 'Pay Attention To The Details',
		description:
			"It’s the little things that matter so get the details right. From the spelling of a client's name to the color of a logo, details matter. Be a fanatic about accuracy and precision. Double check your work and get the details right..."
	},
	{
		title: 'Sell Once Service Forever',
		description:
			'We work hard to sell our services. The sales team is selling the results that our associates deliver. If we do our jobs we will only have to sell once. Our project team will deliver the results that “wow” the client and therefore earn repeat business and relationships that will last our entire careers.'
	}
];

const index = Math.floor(weeksElapsed % quotes.length);
class LandingPage extends React.Component {
	render() {
		return (
			<div className="omni-landing-page-container">
				<div className="omni-landing-page-item-logo">
					<img src={Logo} />
					<h3>Be Confident in Your Workplace Technology</h3>
				</div>
				<div className="omni-landing-page-item-quote">
					<h1>{quotes[index].title}</h1>
					<p>
						{quotes[index].bold ? <b>{quotes[index].bold} </b> : <></>}
						{quotes[index].description}
					</p>
				</div>
			</div>
		);
	}
}

export default LandingPage;
