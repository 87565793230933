// Modules
import React from 'react';
import {Grid, Segment, Label, Statistic, Icon, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Services
import {getPipelineAnalytics} from '../../../services/pipelineActions.js';

let PipelineOverview = ({data, fetched, error, getPipelineAnalytics}) => (
	<Segment>
		<Label attached="top" color="blue" content="Pipeline Overview" />
		{fetched ? (
			error ? (
				<Segment basic>
					Hmmm, it looks like something went wrong when I was trying to pull this data. Try again, or contact
					the Dev team if it keeps happening
					<Segment basic className="error-segment">
						<Button icon onClick={() => getPipelineAnalytics()}>
							<Icon name="refresh" />
						</Button>
					</Segment>
				</Segment>
			) : (
				<div className="analytics-pipeline-overview">
					<Statistic size="mini" className="pipeline-statistic">
						<Statistic.Value>
							<Icon className="pipeline-statistic-icon" name="folder open outline" />
							{data.count}
						</Statistic.Value>
						<Statistic.Label>Open Deals</Statistic.Label>
					</Statistic>
					<Statistic size="mini" className="pipeline-statistic">
						<Statistic.Value>
							<Icon className="pipeline-statistic-icon" name="bar chart" />
							{data.revenue}
						</Statistic.Value>
						<Statistic.Label>Total Deals In Pipeline</Statistic.Label>
					</Statistic>
					<Statistic size="mini" className="pipeline-statistic">
						<Statistic.Value>
							<Icon className="pipeline-statistic-icon" name="area chart" />
							{data.average}
						</Statistic.Value>
						<Statistic.Label>Avg Size Deal</Statistic.Label>
					</Statistic>
					<Statistic size="mini" className="pipeline-statistic">
						<Statistic.Value>
							<Icon className="pipeline-statistic-icon" name="clock outline" />
							{`${data.averageTime}D`}
						</Statistic.Value>
						<Statistic.Label>Avg time in Pipeline</Statistic.Label>
					</Statistic>
				</div>
			)
		) : (
			<Segment basic loading={!fetched} className="loading-segment" />
		)}
	</Segment>
);

PipelineOverview.propTypes = {
	data: PropTypes.object.isRequired,
	fetched: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	getPipelineAnalytics: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		data: state.crm.pipeline.analytics.pipeline.data,
		fetched: state.crm.pipeline.analytics.pipeline.fetched,
		error: state.crm.pipeline.analytics.pipeline.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPipelineAnalytics: () => {
			dispatch(getPipelineAnalytics());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PipelineOverview);
