import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Icon, Popup} from 'semantic-ui-react';
import * as actions from '../../../../../services/workRecordActions.js';
import BillPDFButton from './BillPDFButton.js';

let OpenSheetIcon = (props) =>
	props.data.status === 'Awaiting Submission' ? (
		<Popup
			trigger={
				<Icon
					color="blue"
					name="table"
					style={{
						backgroundColor: 'transparent',
						fontSize: '1.6em',
						cursor: 'pointer'
					}}
					onClick={() => {
						props.toggleInvoiceSpreadsheet(
							props.rowIndex,
							props.invoiceRowData[props.rowIndex].items
								? props.invoiceRowData[props.rowIndex].items.length > 0
									? props.invoiceRowData[props.rowIndex].items
									: []
								: []
						);
					}}
				/>
			}
			position="right center"
			content="Open Spreadsheet"
		/>
	) : (
		<Popup
			trigger={<BillPDFButton invoiceRowData={props.invoiceRowData[props.rowIndex]} loading={false} />}
			position="right center"
			content="Download BIll PDF"
		/>
	);

OpenSheetIcon.propTypes = {
	data: PropTypes.array,
	toggleInvoiceSpreadsheet: PropTypes.func,
	rowIndex: PropTypes.number,
	invoiceRowData: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		invoiceRowData: state.work.workRecord.view.BillingCenter.invoiceRowData
	};
};

export default connect(mapStateToProps, actions)(OpenSheetIcon);
