import {Popup, Icon, Menu} from 'semantic-ui-react';
import React from 'react';
//import PropTypes from 'prop-types';
import optionFactory from './optionFactory.js';

const toggleSequentialButton = (props) => (
	<Popup
		size={'mini'}
		trigger={
			<Menu.Item
				position={props.position}
				className="pseudoDragHandler"
				// className={'right floated'}
				onClick={() => {
					if (props.isSection) {
						props.toggleSectionSequential(
							!props.section.isSequential,
							props.section.id,
							optionFactory(props)
						);
					} else {
						props.toggleOrdered(
							props.aasdiIndex,
							props.tasks.selectedTaskGroupIndex,
							props.groups[props.tasks.selectedTaskGroupIndex].sequential !== true,
							props.groups[props.tasks.selectedTaskGroupIndex]
						);
					}
				}}
			>
				<Icon
					className="pseudoDragHandler"
					name={
						props.isSection
							? props.section.isSequential
								? 'toggle on'
								: 'toggle off'
							: props.groups[props.tasks.selectedTaskGroupIndex].sequential
							? 'toggle on'
							: 'toggle off'
					}
				/>
				{props.isSection
					? null
					: props.groups[props.tasks.selectedTaskGroupIndex].sequential
					? 'Ordered Task List'
					: 'Unordered Task List'}
			</Menu.Item>
		}
		content={
			props.mode == 'templateManger'
				? props.isSection
					? props.tasks.currentTemplate.sequential
						? 'Click to allow the Tech to complete this sections tasks in any order.'
						: 'Click to force the Tech to complete this sections tasks in order.'
					: props.tasks.currentTemplate.sequential
					? 'Click to allow the Tech to complete the tasks in any order.'
					: 'Click to force the Tech to complete the tasks in order.'
				: props.isSection
				? props.section.isSequential
					? 'Click to allow the Tech to complete this sections tasks in any order.'
					: 'Click to force the Tech to complete this sections tasks in order.'
				: props.groups[props.tasks.selectedTaskGroupIndex].sequential
				? 'Click to allow the Tech to complete the tasks in any order.'
				: 'Click to force the Tech to complete the tasks in order.'
		}
	/>
);
export default toggleSequentialButton;
