import {Card, Form, Header, Icon, Label, Popup, Input, Button} from 'semantic-ui-react';
import React from 'react';
// import PropTypes from 'prop-types';
import ClosePanel from './Panel_Close.js';
import SaveTemplateButton from './Button_SaveTemplate.js';

const TemplateInfoPanel = (props) => {
	return [
		<ClosePanel key={'closeCompletedPanel'} {...props} />,
		<Card.Content key={'header-templateInfo'}>
			<Header as="h3" key={'header-templateInfo'}>
				<Icon name="edit" />
				{props.tasks.currentTemplate.id ? (
					<Header.Content>Edit Template Info</Header.Content>
				) : (
					<Header.Content>Add Template Info</Header.Content>
				)}
			</Header>
		</Card.Content>,
		<Card.Content key={'form-templateInfo'}>
			<Form>
				<Form.Input
					error={props.tasks.ui.errors.templateName}
					fluid
					label="Template Name"
					value={props.tasks.currentTemplate.name}
					placeholder="A name for the template."
					onChange={(e, {value}) => {
						props.updateTemplateInfo('name', value);
					}}
				/>
				{props.tasks.ui.errors.templateName ? (
					<Label style={{margin: 0}} pointing basic color="red" size="mini">
						Please enter a template name
					</Label>
				) : null}

				<Form.Input
					error={props.tasks.ui.errors.templateDescription}
					fluid
					label="Description"
					value={props.tasks.currentTemplate.description}
					placeholder="A description of the template."
					onChange={(e, {value}) => {
						props.updateTemplateInfo('description', value);
					}}
				/>
				{props.tasks.ui.errors.templateDescription ? (
					<Label style={{margin: 0}} pointing basic color="red" size="mini">
						Please enter a template description
					</Label>
				) : null}
				<Form.Group widths="equal">
					<Form.Field>
						<label>
							Client Name{' '}
							<Popup
								trigger={<Icon name="question circle outline" />}
								content={'Use the Client List search to assign this template to a specific customer.'}
							/>
						</label>
						<Input
							disabled={true}
							value={
								props.tasks.currentTemplate.clientName
									? props.tasks.currentTemplate.clientName
									: 'This is currently a global template.'
							}
						/>
					</Form.Field>

					<Form.Dropdown
						placeholder={'Begin typing to Search for clients'}
						label="Client List"
						fluid
						search
						selection
						loading={props.tasks.ui.template.dropdownLoading}
						onSearchChange={(e, {searchQuery}) => {
							props.updateClientSearchResults(searchQuery);
						}}
						onClick={(e, {value}) => {
							if (props.tasks.ui.template.searchChoices.length < 2) {
								props.updateClientSearchResults('a');
							}
						}}
						onChange={(e, {value}) => {
							if (value !== 'default') {
								props.updateTemplateInfo('clientName', value.clientName);
								props.updateTemplateInfo('clientId', value.clientId);
							}
						}}
						options={props.tasks.ui.template.searchChoices}
					/>
				</Form.Group>
			</Form>
		</Card.Content>,
		<Card.Content key={'PanelButtonGroup'}>
			<SaveTemplateButton {...props} isPanel />
		</Card.Content>
	];
};

export default TemplateInfoPanel;
