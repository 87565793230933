/*
TODO: This is way overloaded. This component should not be path aware
*/

import React, {Component} from 'react';
import {matchPath} from 'react-router';
import {connect} from 'react-redux';
import moment from 'moment';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {Button, Grid, Icon, Segment, Label, Input, Radio} from 'semantic-ui-react';
import {toggleCreateModal} from '../../../../../../components/createCustomerModal/services/createCustomerModalActions.js';
import {toggleSendToModal} from '../../../../../../components/sendToModal/services/sendToModalActions.js';
import {toggleDeactivateModal} from '../../../../../../components/deactivateOpportunityModal/services/deactivateOpportunityModalActions.js';
import {toggleLostJobModal} from '../../../../../../components/lostJobOpportunityModal/services/lostJobOpportunityModalActions.js';
import {
	toggleCommitModal,
	setOpportunityData
} from '../../../../../../components/commitModal/services/commitModalActions.js';
import {toggleCreateProjectModal} from '../../scenes/CreateProject/services/createProjectActions.js';
import AddButton from './components/AddButton.js';
import {updateOpportunityColumn} from './services/AGGridActions.js';
import ActionCellRenderer from './components/actionCellRenderer.js';
import DatePickerButton from './components/datePicker.js'; //this should be dateCellEditor
import EstimatorsCellRenderer from './components/estimatorsCellRenderer.js';
import TechnologiesCellRenderer from './components/technologiesCellRenderer.js';
import ClientCellRenderer from './components/clientCellRenderer.js';
import LossReasonCellRenderer from './components/lossReasonCellRenderer.js';
import LossCommentsCellRenderer from './components/lossCommentsCellRenderer.js';
import DescriptionCellRenderer from './components/descriptionCellRenderer.js';
import CommitCellRenderer from './components/commitCellRenderer.js';
import TagCellRenderer from '~/components/ag-grid/renderers/tagCellRenderer';
import CreateCustomerModal from '../../../../../../components/createCustomerModal/createCustomerModal.js';
import CreateProjectModal from '../../scenes/CreateProject/createProject.js';
import CommitModal from '../../../../../../components/commitModal/commitModal.js';
import SendToModal from '../../../../../../components/sendToModal/sendToModal.js';
import DeactivateOpportunityModal from '../../../../../../components/deactivateOpportunityModal/deactivateOpportunityModal.js';
import LostJobOpportunityModal from '../../../../../../components/lostJobOpportunityModal/lostJobOpportunityModal.js';
import {getPipelineOpportunities, updateGridType} from '../../scenes/pipeline/services/pipelineActions.js';
import RevenueTotals from './components/RevenueTotals.jsx';
import {fmtMoney} from '../../../../../../util/formatting.js';

class AGGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultGrid: true,
			estimateRequestLink: 'https://forms.monday.com/forms/78069a970e7e2b219b963bd8dfad6360'
		};
		this.switchGridMode = this.switchGridMode.bind(this);
	}

	switchGridMode() {
		const {defaultGrid} = this.state;
		this.props.updateGridType(defaultGrid ? 'jobLog' : 'pipeline');
		this.props.getPipelineOpportunities();
		this.setState({defaultGrid: !defaultGrid});
	}

	requestEstimate(link) {
		window.open(link);
	}

	defaultColDef = {
		editable: false,
		menuTabs: ['generalMenuTab'],
		suppressPaste: true
	};

	jobLogColumns = [
		{
			headerName: 'Actions',
			field: 'action',
			cellStyle: {paddingTop: '.4em'},
			cellRenderer: 'actionCellRenderer',
			colId: 'actionColumn',
			minWidth: 125,
			maxWidth: 125
		},
		{
			headerName: 'Commit',
			field: 'isCommit',
			cellRenderer: 'commitCellRenderer',
			cellStyle: {paddingTop: '.4em'},
			hide: true
		},
		{
			headerName: 'Project',
			field: 'name',
			filter: 'agTextColumnFilter',
			editable: true,
			cellStyle: {paddingTop: '.4em'},
			onCellValueChanged: (params) => {
				return this.props.updateOpportunityColumn(
					params.newValue,
					params.data.id,
					this.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0 ? 'pipeline' : 'leads',
					params.colDef.field
				);
			}
		},
		{
			headerName: 'Created On',
			field: 'projectCreatedOnDate',
			filter: 'agTextColumnFilter',
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (data) => moment(data.value).format('MM-DD-YYYY')
		},
		{
			headerName: 'Client',
			field: 'clientName',
			filter: 'agTextColumnFilter',
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'Work Number',
			field: 'workNumber',
			filter: 'agTextColumnFilter',
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'Tags',
			field: 'tags',
			cellRenderer: 'tagCellRenderer',
			width: 100
		},
		{
			headerName: 'Revenue',
			field: 'revenue',
			filter: 'agNumberColumnFilter',
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (data) => (data.value ? fmtMoney(data.value) : fmtMoney(0))
		},
		{
			headerName: 'Projected Order Date',
			field: 'projectedOrderDate',
			filter: 'agDateColumnFilter',
			cellEditor: 'datePickerButton',
			cellEditorParams: {
				column: 'projectedOrderDate'
			},
			editable: true,
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (data) => moment(data.value).format('MM-DD-YYYY'),
			hide: true
		},
		{
			headerName: 'Bid Due Date',
			field: 'bidDueDate',
			filter: 'agDateColumnFilter',
			cellEditor: 'datePickerButton',
			cellEditorParams: {
				column: 'bidDueDate'
			},
			editable: true,
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (data) => (data.value ? moment(data.value).format('MM-DD-YYYY') : null),
			hide: true
		},
		{
			headerName: 'Stage',
			field: 'stageName',
			cellRenderer: 'statusCellRenderer',
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				cellRenderer: 'statusCellRenderer',
				values: [
					{name: 'Identified Need', id: '1'},
					{name: 'Estimating', id: '2'},
					{name: 'Submitted Quote', id: '3'},
					{name: 'High Probability', id: '4'},
					{name: 'Verbal Award', id: '5'}
				]
			},
			editable: true,
			onCellValueChanged: (params) => {
				if (params.newValue.name) {
					return this.props.updateOpportunityColumn(
						params.newValue.name.toString(),
						params.data.id,
						this.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0 ? 'pipeline' : 'leads',
						params.colDef.field
					);
				}
			},
			cellStyle: {paddingTop: '.4em'},
			hide: true
		},
		{
			headerName: 'Account Exec',
			field: 'accountExecutiveName',
			editable: false,
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (params) => (params.data.accountExecutiveName ? params.data.accountExecutiveName : '')
		},
		{
			headerName: 'Engineers',
			field: 'estimators',
			cellRenderer: 'estimatorsCellRenderer',
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'Technologies',
			field: 'technologies',
			cellRenderer: 'technologiesCellRenderer',
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'Loss Reason',
			field: 'lossReason',
			cellRenderer: 'lossReasonCellRenderer',
			cellStyle: {paddingTop: '.4em'},
			hide: true
		},
		{
			headerName: 'Comments',
			field: 'lossComments',
			cellRenderer: 'lossCommentsCellRenderer',
			cellStyle: {paddingTop: '.4em'},
			hide: true
		},
		{
			headerName: 'Type',
			field: 'type',
			filter: 'agTextColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'Estimated GP',
			field: 'estimatedGP',
			filter: 'agTextColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'City',
			field: 'workCity',
			filter: 'agTextColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (params) => (params.data.workCity ? params.data.workCity : '')
		},
		{
			headerName: 'State',
			field: 'workState',
			filter: 'agTextColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'ZIP',
			field: 'workZip',
			filter: 'agTextColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'Project Manager',
			field: 'projectManager',
			editable: false,
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'ID',
			field: 'id',
			cellStyle: {paddingTop: '.4em'}
		}
	];
	pipelineColumns = [
		{
			headerName: 'Actions',
			field: 'action',
			cellStyle: {paddingTop: '.4em'},
			cellRenderer: 'actionCellRenderer',
			colId: 'actionColumn',
			minWidth: 125,
			maxWidth: 125
		},
		{
			headerName: 'Commit',
			field: 'isCommit',
			cellRenderer: 'commitCellRenderer',
			cellStyle: {paddingTop: '.4em'},
			hide: false
		},
		{
			headerName: 'Project',
			field: 'name',
			filter: 'agTextColumnFilter',
			editable: true,
			cellStyle: {paddingTop: '.4em'},
			onCellValueChanged: (params) => {
				return this.props.updateOpportunityColumn(
					params.newValue,
					params.data.id,
					this.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0 ? 'pipeline' : 'leads',
					params.colDef.field
				);
			}
		},
		{
			headerName: 'Client',
			field: 'clientName',
			filter: 'agTextColumnFilter',
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (params) => (params.data.clientName ? params.data.clientName : '')
		},
		{
			headerName: 'Tags',
			field: 'tags',
			cellRenderer: 'tagCellRenderer',
			width: 100
		},
		{
			headerName: 'Revenue',
			field: 'revenue',
			filter: 'agNumberColumnFilter',
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (data) => (data.value ? fmtMoney(data.value) : fmtMoney(0))
		},
		{
			headerName: 'Projected Order Date',
			field: 'projectedOrderDate',
			filter: 'agDateColumnFilter',
			cellEditor: 'datePickerButton',
			cellEditorParams: {
				column: 'projectedOrderDate'
			},
			editable: true,
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (data) => moment(data.value).format('MM-DD-YYYY'),
			hide: false
		},
		{
			headerName: 'Bid Due Date',
			field: 'bidDueDate',
			filter: 'agDateColumnFilter',
			cellEditor: 'datePickerButton',
			cellEditorParams: {
				column: 'bidDueDate'
			},
			editable: true,
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (data) => (data.value ? moment(data.value).format('MM-DD-YYYY') : null),
			hide: false
		},
		{
			headerName: 'Stage',
			field: 'stageName',
			cellRenderer: 'statusCellRenderer',
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: {
				cellRenderer: 'statusCellRenderer',
				values: [
					{name: 'Identified Need', id: '1'},
					{name: 'Estimating', id: '2'},
					{name: 'Submitted Quote', id: '3'},
					{name: 'High Probability', id: '4'},
					{name: 'Verbal Award', id: '5'}
				]
			},
			editable: true,
			onCellValueChanged: (params) => {
				if (params.newValue.name) {
					return this.props.updateOpportunityColumn(
						params.newValue.name.toString(),
						params.data.id,
						this.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0 ? 'pipeline' : 'leads',
						params.colDef.field
					);
				}
			},
			cellStyle: {paddingTop: '.4em'},
			hide: false
		},
		{
			headerName: 'Account Exec',
			field: 'accountExecutiveName',
			editable: false,
			cellStyle: {paddingTop: '.4em'},
			valueFormatter: (params) => (params.data.accountExecutiveName ? params.data.accountExecutiveName : ''),
			hide: false
		},
		{
			headerName: 'Engineers',
			field: 'estimators',
			cellRenderer: 'estimatorsCellRenderer',
			cellStyle: {paddingTop: '.4em'},
			hide: false
		},
		{
			headerName: 'Technologies',
			field: 'technologies',
			cellRenderer: 'technologiesCellRenderer',
			cellStyle: {paddingTop: '.4em'},
			hide: false
		},
		{
			headerName: 'Conversion Type',
			field: 'conversionType',
			cellStyle: {paddingTop: '.4em'},
			hide: false
		},
		{
			headerName: 'Loss Reason',
			field: 'lossReason',
			cellRenderer: 'lossReasonCellRenderer',
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'Comments',
			field: 'lossComments',
			cellRenderer: 'lossCommentsCellRenderer',
			cellStyle: {paddingTop: '.4em'}
		},
		{
			headerName: 'ID',
			field: 'id',
			cellStyle: {paddingTop: '.4em'},
			hide: false
		}
	];
	frameworkComponents = {
		actionCellRenderer: ActionCellRenderer,
		datePickerButton: DatePickerButton,
		estimatorsCellRenderer: EstimatorsCellRenderer,
		clientCellRenderer: ClientCellRenderer,
		technologiesCellRenderer: TechnologiesCellRenderer,
		lossReasonCellRenderer: LossReasonCellRenderer,
		lossCommentsCellRenderer: LossCommentsCellRenderer,
		statusCellRenderer: StatusCellRenderer,
		descriptionCellRenderer: DescriptionCellRenderer,
		commitCellRenderer: CommitCellRenderer,
		tagCellRenderer: TagCellRenderer
	};

	context = {componentParent: this};

	getRowStyle = (params) => {
		return params.data.pastDue && this.state.defaultGrid ? {color: '#D0212D'} : {color: '#000'};
	};

	onQuickFilterChanged() {
		this.gridApi.setQuickFilter(document.getElementById('quickFilter').value);
	}

	exportHandler() {
		let fileName = document.querySelector('#fileName').value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				if (
					(params.value && params.column.colId === 'projectedOrderDate') ||
					(params.value && params.column.colId === 'bidDueDate') ||
					(params.value && params.column.colId === 'projectCreatedOnDate')
				) {
					return moment(params.value).format('MM-DD-YYYY').toString();
				}
				return params.value;
			}
		};
		this.gridApi.exportDataAsExcel(params);
	}

	// in onGridReady, store the api for later use
	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;

		const isGridView = matchPath(this.props.location, {
			path: '/crm/dashboard/pipeline/grid-view'
		});

		if (isGridView) {
			this.columnApi.setColumnVisible('lossReason', false);
			this.columnApi.setColumnVisible('lossComments', false);
		}
		this.gridApi.sizeColumnsToFit();
	};

	render() {
		const {defaultGrid, estimateRequestLink} = this.state;
		const isGridView = matchPath(this.props.location, {
			path: '/crm/dashboard/pipeline/grid-view'
		});
		const isLeadsView = matchPath(this.props.location, {
			path: '/crm/dashboard/leads'
		});
		const defaultColDef = {
			sortable: true,
			filter: true,
			resizable: true
		};
		const sideBar = {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true
					}
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel'
				}
			],
			position: 'right'
		};

		let rowData = null;
		let fetched = false;
		if (isGridView) {
			fetched = this.props.crm.pipeline.data.fetched;
			rowData = this.state.defaultGrid
				? this.props.crm.pipeline.data.opportunities
				: this.props.crm.pipeline.data.jobLog;
		} else if (isLeadsView) {
			fetched = this.props.crm.leads.data.fetched;
			rowData = this.props.crm.leads.data.opportunities;
		} else {
			console.error(new Error('Unknown view for AgGridComponent'));
		}

		return (
			<Segment loading={!fetched}>
				<Label
					attached="top"
					color="blue"
					style={{
						position: 'relative',
						padding: '0px 0px 0px 10px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					{isGridView ? 'Pipeline Grid' : 'Leads Grid'}

					<AddButton
						style={addButtonStyle}
						content={isGridView ? 'Create new opportunity' : 'Create new lead'}
						key="new-opportunity"
					/>
				</Label>
				<Grid style={{padding: '0 0 1em 0'}}>
					<Grid.Column width={4}>
						<Label
							style={{
								float: 'left',
								backgroundColor: 'transparent',
								fontSize: '.92rem',
								paddingTop: '1em'
							}}
						>
							Filter Grid
						</Label>
						<Input
							type="text"
							onInput={this.onQuickFilterChanged.bind(this)}
							id="quickFilter"
							placeholder="Project name"
							icon="search"
						/>
					</Grid.Column>
					{isGridView ? (
						<Grid.Column width={2} style={{paddingTop: '20px'}}>
							<span
								style={{
									position: 'relative',
									top: '-5px',
									left: '-10px',
									fontWeight: defaultGrid ? 800 : 300
								}}
							>
								Pipeline
							</span>
							<Radio toggle onChange={() => this.switchGridMode()} />
							<span
								style={{
									position: 'relative',
									top: '-5px',
									left: '10px',
									fontWeight: !defaultGrid ? 800 : 300
								}}
							>
								Job Log
							</span>
						</Grid.Column>
					) : null}
					<Grid.Column width={10}>
						<Button
							style={{
								backgroundColor: '#0f4b90',
								color: 'white'
							}}
							onClick={() => this.requestEstimate(estimateRequestLink)}
						>
							<Icon name="plus" /> Request Estimate
						</Button>
						<Input
							style={{float: 'right'}}
							type="text"
							id="fileName"
							placeholder="Name file for export"
							action
						>
							<input />
							<Button type="submit" style={{fontSize: '.95rem'}} onClick={this.exportHandler.bind(this)}>
								Export <Icon name="arrow right" />
							</Button>
						</Input>
					</Grid.Column>
				</Grid>
				<div
					className="ag-theme-balham gridPagniationFix"
					style={{
						height: isGridView ? '45vh' : '70vh',
						width: '100%',
						textAlign: 'left',
						boxSizing: 'border-box'
					}}
				>
					<AgGridReact
						getRowStyle={this.getRowStyle}
						columnDefs={defaultGrid ? this.pipelineColumns : this.jobLogColumns}
						animateRows={true}
						rowData={rowData}
						onGridReady={this.onGridReady}
						onGridColumnsChanged={(ev) => ev.api.sizeColumnsToFit()}
						paginationAutoPageSize={true}
						pagination={true}
						defaultColDef={defaultColDef}
						modules={AllModules}
						suppressRowClickSelection={false}
						sideBar={sideBar}
						suppressDragLeaveHidesColumns={true}
						suppressContextMenu={true}
						suppressMultiRangeSelection={true}
						rowHeight={40}
						frameworkComponents={this.frameworkComponents}
						// filter data doesn't get tracked in state; hence the need to force a rerender so RevenueTotals updates
						// RevenueTotals row data must be in sync with grid data
						onFilterChanged={() => this.forceUpdate()}
						// used to prevent the weird rearranging of columns when switching between pipeline and job log
						applyColumnDefOrder={true}
						{...this.props}
					/>
				</div>
				<RevenueTotals isUsingPipelineData={defaultGrid} gridApi={this.gridApi} />

				<CreateCustomerModal {...this.props} />
				<CreateProjectModal {...this.props} />
				<SendToModal {...this.props} />
				<DeactivateOpportunityModal {...this.props} />
				<LostJobOpportunityModal {...this.props} />
				<CommitModal isGrid />
			</Segment>
		);
	}
}

const addButtonStyle = {
	icon: 'plus',
	color: 'white',
	size: 'large',
	borderRadius: 0,
	margin: 0,
	position: 'relative'
};

const mapStateToProps = (state) => {
	return {
		location: state.router.location.pathname,
		crm: state.crm
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateOpportunityColumn: (value, id, place, column) => {
			dispatch(updateOpportunityColumn(value, id, place, column));
		},
		getPipelineOpportunities: () => {
			dispatch(getPipelineOpportunities());
		},
		updateGridType: (type) => {
			dispatch(updateGridType(type));
		},
		// Modal Actions
		toggleDeactivateModal: (id, place) => {
			dispatch(toggleDeactivateModal(id, place));
		},
		toggleLostJobModal: (id, place) => {
			dispatch(toggleLostJobModal(id, place));
		},
		toggleCreateModal: (id, place) => {
			dispatch(toggleCreateModal(id, place));
		},
		toggleCommitModal: () => {
			dispatch(toggleCommitModal());
		},
		setOpportunityData: (data) => {
			dispatch(setOpportunityData(data));
		},
		toggleCreateProjectModal: (
			id,
			place,
			oppName,
			clientName,
			clientId,
			accountExecId,
			accountExecName,
			poAmount,
			serviceType,
			branchName
		) => {
			dispatch(
				toggleCreateProjectModal(
					id,
					place,
					oppName,
					clientName,
					clientId,
					accountExecId,
					accountExecName,
					poAmount,
					serviceType,
					branchName
				)
			);
		},
		toggleSendToModal: (id, place, acctExecId, projOrderDate) => {
			dispatch(toggleSendToModal(id, place, acctExecId, projOrderDate));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AGGrid);

function StatusCellRenderer(params) {
	return params.value ? (params.value.name ? params.value.name : params.value) : null;
}
