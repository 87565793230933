import {Popup, Icon, Button, Menu} from 'semantic-ui-react';
import React from 'react';

const DragHandle = (props) =>
	props.isSection ? (
		<Popup
			size="mini"
			trigger={
				<Menu.Item
					// className="taskCardRightButton"
					icon
					style={{cursor: 'move'}}
				>
					<Icon name="sort" />
				</Menu.Item>
			}
			content={'Click and drag to re-order task.'}
		/>
	) : (
		<Popup
			size="mini"
			trigger={
				<Button
					// className="taskCardRightButton"
					floated="right"
					size="mini"
					icon
					style={{cursor: 'move'}}
					type="button"
				>
					<Icon name="sort" />
				</Button>
			}
			content={'Click and drag to re-order task.'}
		/>
	);
export default DragHandle;
