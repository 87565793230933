import React from 'react';
import {Icon} from 'semantic-ui-react';

const AASDIActionCellRenderer = (props) => (
	<div>
		{console.log(props)}
		<Icon
			name="cancel"
			color="red"
			size="large"
			style={{cursor: 'pointer'}}
			onClick={() => {
				props.agGridReact.props.workOrderModalRemoveSelectAASDI(
					props.rowIndex,
					props.agGridReact.props.sectionIndex
				);
			}}
		/>
	</div>
);

export default AASDIActionCellRenderer;
