import React from 'react';
import {Label, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';

import * as actions from '../../services/createProjectActions.js';

import {debounce, debounceEventHandler} from '../../../../../../../../lib/old/debounce.js';

const DropdownField = (props) => (
	<div
		style={{
			paddingTop: '.8em'
		}}
	>
		<Label
			style={{
				backgroundColor: 'transparent',
				fontSize: '1rem',
				color: 'rgba(0,0,0,.8)',
				paddingLeft: '0'
			}}
		>
			{props.field.name}
		</Label>
		<Dropdown
			className="createModalDropdown"
			placeholder={props.field.placeholder}
			fluid
			search
			selection
			options={props.field.options}
			onChange={(e, {value}) => {
				props.updateCreateProjectModal(
					'createProjectModal',
					props.sectionIndex,
					props.fieldIndex,
					value,
					false
				);
				if (props.field.groupBilling) {
					props.updateGroupBilling(value);
				}
			}}
			onSearchChange={debounceEventHandler(
				debounce((e) => {
					if (e.target.value) {
						if (props.field.searchAE) {
							props.getAccountExecs(props.sectionIndex, props.fieldIndex, e.target.value);
						}
						//search pm?
					}
				}, 600)
			)}
			icon={props.field.icon}
			value={props.field.value}
			error={props.field.required ? Boolean(props.field.error) : false}
			disabled={props.field.disabled}
			upward={props.field.upward}
			loading={props.field.loading}
			defaultSearchQuery={props.field.searchQuery}
		/>
		{props.field.serviceTypeField && props.field.value === 4 ? (
			<Dropdown
				className="createWorkOrderClientDropdown"
				placeholder={props.serviceTypeOptions.placeholder}
				fluid
				search
				selection
				options={props.serviceTypeOptions.options}
				onChange={(e, {value}) => {
					props.updateCreateProjectModal(
						'createProjectModal',
						props.sectionIndex,
						props.serviceTypeIndex,
						value,
						false,
						null
					);
				}}
				icon={props.serviceTypeOptions.icon}
				value={props.serviceTypeOptions.value}
				error={Boolean(props.serviceTypeOptions.error)}
				disabled={props.serviceTypeOptions.disabled}
				upward={props.serviceTypeOptions.upward}
				loading={props.serviceTypeOptions.loading}
				defaultSearchQuery={props.serviceTypeOptions.searchQuery}
			/>
		) : null}
	</div>
);

export default connect(null, actions)(DropdownField);
