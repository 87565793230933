import React from 'react';
import {connect} from 'react-redux';
import {Segment, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import * as actions from '../../../services/workRecordActions.js';
import NonGroupBillableGrid from './components/nonGroupBillableGrid.js';
import OrderGroupGrid from './components/orderGroupGrid.js';
import AddOrderGroupButton from './components/addOrderGroupButton.js';
import AddOrderGroupModal from './components/addOrderGroupModal/addOrderGroupModal.js';
import RemoveOrderGroupModal from './components/removeOrderGroupModal/removeOrderGroupModal.js';
import SubmitOrderGroupModal from './components/submitOrderGroupModal/submitOrderGroupModal.js';

class BillingCenterPJ extends React.Component {
	render() {
		return (
			<Segment basic style={{marginTop: '0', paddingTop: 0}}>
				<Segment style={{padding: 0}}>
					<Label attached="top" icon="search" color="blue" style={{position: 'relative', padding: 0}}>
						<div
							style={{
								display: 'inline-block',
								padding: '1.2em'
							}}
						>
							{this.props.isGroupBillable ? 'Order Group' : 'Invoicing'}
						</div>
						{this.props.isGroupBillable ? <AddOrderGroupButton /> : null}
					</Label>
					{this.props.isGroupBillable ? (
						<div className="fixGridMargin">
							<OrderGroupGrid />
						</div>
					) : (
						<div className="fixGridMargin">
							<NonGroupBillableGrid />
						</div>
					)}
				</Segment>
				<AddOrderGroupModal />
				<RemoveOrderGroupModal />
				<SubmitOrderGroupModal />
			</Segment>
		);
	}
}

BillingCenterPJ.propTypes = {
	workNumber: PropTypes.string,
	workId: PropTypes.number,
	isGroupBillable: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		workId: state.work.workRecord.view.record.id,
		workNumber: state.work.workRecord.view.record.workNumber,
		isGroupBillable: state.work.workRecord.view.record.isGroupBillable,
		quotedJobNumber: state.work.workRecord.view.record.quotedJobNumber
	};
};
export default connect(mapStateToProps, actions)(BillingCenterPJ);
