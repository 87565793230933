import React from 'react';
import {Button, Modal, Icon, Header, Grid, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
	toggleCreateClientModal,
	toggleCustomerCreationModal,
	toggleProspectCreationModal
} from '../services/clientGridActions.js';
import CustomerCreationModal from './CustomerCreationModal.js';
import ProspectCreationModal from './ProspectCreationModal.js';

class CreateClientModal extends React.Component {
	constructor(props) {
		super(props);
	}

	closeModal() {
		this.props.toggleCreateClientModal(false);
	}

	render() {
		const {
			modalTitle,
			toggleCreateClientModal,
			toggleCustomerCreationModal,
			toggleProspectCreationModal,
			isOpen
		} = this.props;
		return (
			<div>
				<Modal size="small" open={isOpen} onUnmount={() => this.closeModal()}>
					<Header>
						<Icon name="plus" color="green" />
						<div className="content">{modalTitle}</div>
					</Header>
					<Modal.Content>
						<Grid centered>
							<Grid.Row>
								<Button
									basic
									size="huge"
									color="blue"
									content="Create customer"
									icon="search"
									onClick={() => {
										this.closeModal();
										toggleCustomerCreationModal(true);
									}}
								/>
							</Grid.Row>
							<Divider />
							<Grid.Row>
								<Button
									basic
									size="huge"
									color="green"
									content="Create prospect"
									icon="add"
									onClick={() => {
										this.closeModal();
										toggleProspectCreationModal(true);
									}}
								/>
							</Grid.Row>
						</Grid>
					</Modal.Content>
					<Modal.Actions>
						<Button.Group>
							<Button
								onClick={() => {
									toggleCreateClientModal(false);
								}}
								color="red"
							>
								<Icon name="remove" /> Cancel
							</Button>
						</Button.Group>
					</Modal.Actions>
				</Modal>
				<CustomerCreationModal />
				<ProspectCreationModal />
			</div>
		);
	}
}

CreateClientModal.propTypes = {
	isOpen: PropTypes.bool,
	toggleCreateClientModal: PropTypes.func,
	modalTitle: PropTypes.string,
	toggleCustomerCreationModal: PropTypes.func,
	toggleProspectCreationModal: PropTypes.func
};

const mapStateToProps = (state) => ({
	isOpen: state.work.clientGrid.showCreateClientModal,
	modalTitle: state.work.clientGrid.modalTitle
});

const mapDispatchToProps = (dispatch) => ({
	toggleCreateClientModal: (status) => dispatch(toggleCreateClientModal(status)),
	toggleCustomerCreationModal: (status) => dispatch(toggleCustomerCreationModal(status)),
	toggleProspectCreationModal: (status) => dispatch(toggleProspectCreationModal(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientModal);
