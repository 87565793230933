// Modules
import React from 'react';
import {Button, Modal, Icon, Header, Grid} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {toggleLocationOrderHistModal} from './services/globalActions.js';
import AgGrid from './AgGrid.js';
import ViewWorkDetailCellRenderer from './ViewWorkDetailCellRenderer.js';

const frameworkComponents = {
	viewWorkDetailCellRenderer: ViewWorkDetailCellRenderer
};

class LocationOrderHistoryModal extends React.Component {
	constructor(props) {
		super(props);
		this.closeModal = this.closeModal.bind(this);
		this.onUnmount = this.onUnmount.bind(this);
	}

	closeModal() {
		this.props.toggleLocationOrderHistModal(false);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	onUnmount() {
		this.props.toggleLocationOrderHistModal(false);
	}

	render() {
		const {historyData, type} = this.props;
		return (
			<Modal size="large" open={this.props.isOpen} style={{justfyContent: 'center'}} onUnmount={this.onUnmount}>
				<Header>
					<Grid className="templateDraftSection">
						<Grid.Row>
							<Grid.Column width={16} style={{margin: 'auto 0'}}>
								<div className="content">Location Order History</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Header>
				<Modal.Content>
					<AgGrid
						columnDefs={
							type === 'aasdi' ? LocationOrderHistoryAasdiHeader : LocationOrderHistoryClientHeader
						}
						rowData={historyData}
						onGridReady={this.onGridReady}
						frameworkComponents={frameworkComponents}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							color="red"
							onClick={() => {
								this.closeModal(false);
							}}
						>
							<Icon name="remove" /> Close
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

const LocationOrderHistoryAasdiHeader = [
	{
		headerName: 'Detail',
		field: '',
		filter: 'agTextColumnFilter',
		cellRenderer: 'viewWorkDetailCellRenderer',
		editable: false
	},
	{
		headerName: 'Work Number',
		field: 'workNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Purchase Order Number',
		field: 'purchaseOrderNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Work Category',
		field: 'workCategory',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Account Exec',
		field: 'accountExecutive_Name',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'NDS',
		field: 'workManager_Name',
		filter: 'agTextColumnFilter',
		editable: false
	}
];

const LocationOrderHistoryClientHeader = [
	{
		headerName: 'Detail',
		field: '',
		filter: 'agTextColumnFilter',
		cellRenderer: 'viewWorkDetailCellRenderer',
		editable: false
	},
	{
		headerName: 'Work Number',
		field: 'workNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'AASDI',
		field: 'aasdi',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Purchase Order Number',
		field: 'purchaseOrderNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Work Category',
		field: 'workCategory',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Account Exec',
		field: 'accountExecutive_Name',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'NDS',
		field: 'workManager_Name',
		filter: 'agTextColumnFilter',
		editable: false
	}
];

LocationOrderHistoryModal.propTypes = {
	isOpen: PropTypes.bool,
	toggleLocationOrderHistModal: PropTypes.func,
	historyData: PropTypes.array,
	type: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
	isOpen: state.global.global.showLocationOrderHistModal
});

const mapDispatchToProps = (dispatch) => ({
	toggleLocationOrderHistModal: (status) => dispatch(toggleLocationOrderHistModal(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationOrderHistoryModal);
