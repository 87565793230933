import moment from 'moment-timezone';
import naturalSort from 'object-property-natural-sort';
import customFetch from '../../../lib/old/customFetch.js';

export const getUsers = (token, search, refresh) => async (dispatch, getState) => {
	const state = getState().global;

	if (refresh && !search) {
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_REFRESH_LOADER',
			payload: {
				loading: true,
				icon: ''
			}
		});

		dispatch({
			type: 'TECH_MANAGEMENT_UPDATE_SEARCH',
			payload: {
				column: 'Email',
				text: ''
			}
		});
	}

	if (search) {
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_SEARCH_LOADER',
			payload: true
		});
	}

	const pagingToken = token === undefined || token === null ? null : token;

	const filterColumn = !state.techManagement.search.column
		? null
		: !refresh
		? state.techManagement.search.column
		: null;

	const filterValue = !state.techManagement.search.text ? null : !refresh ? state.techManagement.search.text : null;

	if (token) {
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_MORE_USER_LOADER',
			payload: true
		});
	}

	if (!search && !state.techManagement.users.token && !state.techManagement.users.new && !refresh) {
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'All users retrieved. ',
				icon: 'checkmark',
				warning: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		setTimeout(() => {
			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: true
			});
		}, 4000);
	}

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=tech-management&paginationToken=${pagingToken}&filterColumn=${filterColumn}&filterValue=${filterValue}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		if (response.statusCode) {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: response.message,
					icon: 'exclamation circle',
					error: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});
		} else {
			dispatch({
				type: 'GLOBAL_UPDATE_PAGE_LOADER',
				payload: {
					loading: false
				}
			});
			response.users.forEach((row) => {
				row.Checked = false;
				row.UserCreateDateHidden = moment(row.UserCreateDate).format();
				row.UserLastModifiedDateHidden = moment(row.UserLastModifiedDate).format();
			});

			response.users.sort(naturalSort('Email'));

			if (state.techManagement.users.new === true) {
				dispatch({
					type: 'TECH_MANAGEMENT_UPDATE_USER_NEW',
					payload: false
				});
			}

			if (token) {
				response.users = state.techManagement.users.users.concat(response.users);

				dispatch({
					type: 'TECH_MANAGEMENT_TOGGLE_MORE_USER_LOADER',
					payload: false
				});
			}

			dispatch({
				type: 'TECH_MANAGEMENT_GET_USERS',
				payload: response
			});

			if (refresh) {
				dispatch({
					type: 'TECH_MANAGEMENT_TOGGLE_REFRESH_LOADER',
					payload: {
						loading: false,
						icon: 'refresh'
					}
				});
			}

			if (search) {
				dispatch({
					type: 'TECH_MANAGEMENT_TOGGLE_SEARCH_LOADER',
					payload: false
				});
			}

			return dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_GET_USER_LOADER',
				payload: false
			});
		}
	} catch (err) {
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'An error occurred while trying to talk to the database, please refresh the page. If the issue persists please contact the dev team.',
				icon: 'exclamation circle',
				error: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		dispatch({
			type: 'GLOBAL_UPDATE_PAGE_LOADER',
			payload: {
				loadingError: true
			}
		});
	}
};

export const getApprovals = (token, search, refresh) => async (dispatch, getState) => {
	const state = getState().global;

	if (refresh && !search) {
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_REFRESH_LOADER',
			payload: {
				loading: true,
				icon: ''
			}
		});
	}

	if (token) {
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_MORE_APPROVALS_LOADER',
			payload: true
		});
	}

	if (!state.techManagement.approvals.token && !state.techManagement.approvals.new && !refresh) {
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'All approvals retrieved. ',
				icon: 'checkmark',
				warning: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		setTimeout(() => {
			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',

				payload: true
			});
		}, 4000);
	}

	const pagingToken = token === undefined || token === null ? null : token;
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=tech-management&paginationToken=${pagingToken}&filterColumn=Approved&filterValue=Not Approved`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		if (response.statusCode) {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: response.message,
					icon: 'exclamation circle',
					error: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);
		} else {
			response.users.forEach((row) => {
				row.Checked = false;
			});

			response.users.sort(naturalSort('Email'));

			if (state.techManagement.approvals.new === true) {
				dispatch({
					type: 'TECH_MANAGEMENT_UPDATE_APPROVALS_NEW',
					payload: false
				});
			}

			if (token) {
				response.users = state.techManagement.approvals.approvals.concat(response.users);

				dispatch({
					type: 'TECH_MANAGEMENT_TOGGLE_MORE_APPROVALS_LOADER',
					payload: false
				});
			}

			dispatch({
				type: 'TECH_MANAGEMENT_UPDATE_APPROVALS_COUNT',
				payload: response.users.length
			});

			if (refresh && !search) {
				dispatch({
					type: 'TECH_MANAGEMENT_TOGGLE_REFRESH_LOADER',
					payload: {
						loading: false,
						icon: 'refresh'
					}
				});
			}

			dispatch({
				type: 'TECH_MANAGEMENT_GET_APPROVALS',
				payload: response
			});
		}
	} catch (err) {
		console.log(`error 1 === ${JSON.stringify(err)}`);
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'An error occurred while trying to talk to the database, please refresh the page. If the issue persists please contact the dev team.',
				icon: 'exclamation circle',
				error: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		dispatch({
			type: 'GLOBAL_UPDATE_PAGE_LOADER',
			payload: {
				loadingError: true
			}
		});
	}
};

export const createUser = () => async (dispatch, getState) => {
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_CREATE_USER_LOADER',
		payload: true
	});

	const state = getState().global;
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=tech-management`;

	try {
		const call = await customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(state.techManagement.createUser.user)
		});
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		if (response.statusCode) {
			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_CREATE_USER_LOADER',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_HIDE_CREATE_USER_MESSAGE',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_UPDATE_CREATE_USER_MESSAGE',
				payload: response.statusCode
			});
		} else {
			let user = {
				...state.techManagement.createUser.user,
				Approved: 'Not Approved',
				EmailVerified: 'Yes',
				Enabled: 'Yes',
				UserCreateDate: 'Now',
				UserLastModifiedDate: 'Now',
				UserStatus: 'Password Change In Process'
			};

			dispatch({
				type: 'TECH_MANAGEMENT_HIDE_CREATE_USER_MESSAGE',
				payload: true
			});

			dispatch({
				type: 'TECH_MANAGEMENT_CREATE_USER',
				payload: user
			});

			dispatch({
				type: 'TECH_MANAGEMENT_CREATE_APPROVAL',
				payload: user
			});

			let userToggle = [];
			state.techManagement.users.users.forEach((user) => {
				userToggle.push({
					...user,
					Checked: false
				});
			});

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ALL',
				payload: {
					users: userToggle
				}
			});

			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'New user created. ',
					icon: 'checkmark',
					success: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_CREATE_USER_MODAL',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_CREATE_USER_LOADER',
				payload: false
			});
		}
	} catch (e) {
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_CREATE_USER_LOADER',
			payload: false
		});

		dispatch({
			type: 'TECH_MANAGEMENT_HIDE_CREATE_USER_MESSAGE',
			payload: false
		});

		dispatch({
			type: 'TECH_MANAGEMENT_UPDATE_CREATE_USER_MESSAGE',
			payload: 'Creating a user failed. Please verify that the correct AASDI code is being used.'
		});
	}
};

export const toggleCreateUserModal = (toggle) => (dispatch) => {
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_CREATE_USER_MODAL',
		payload: toggle !== true
	});
};

export const updateCreateUserInfo = (payload) => (dispatch) => {
	dispatch({
		type: 'TECH_MANAGEMENT_UPDATE_CREATE_USER_OBJECT',
		payload: payload
	});
};

export const editUser = () => async (dispatch, getState) => {
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_EDIT_USER_LOADER',
		payload: true
	});

	const state = getState().global;
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=tech-management&editUser=true`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(state.techManagement.editUser.user)
		});
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		if (response.statusCode) {
			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_EDIT_USER_LOADER',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_HIDE_EDIT_USER_MESSAGE',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_UPDATE_EDIT_USER_MESSAGE',
				payload: response.message
			});
		} else {
			dispatch({
				type: 'TECH_MANAGEMENT_HIDE_EDIT_USER_MESSAGE',
				payload: true
			});

			let index = state.techManagement.users.users.findIndex(
				(element) => element.Email === state.techManagement.editUser.user.Email
			);

			state.techManagement.editUser.user.Checked = false;

			dispatch({
				type: 'TECH_MANAGEMENT_EDIT_USER',
				payload: {
					user: state.techManagement.editUser.user,
					index
				}
			});

			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'User updated. ',
					icon: 'checkmark',
					success: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_EDIT_USER_MODAL',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_EDIT_USER_LOADER',
				payload: false
			});
		}
	} catch (err) {
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_EDIT_USER_LOADER',
			payload: false
		});

		dispatch({
			type: 'TECH_MANAGEMENT_HIDE_EDIT_USER_MESSAGE',
			payload: false
		});

		dispatch({
			type: 'TECH_MANAGEMENT_UPDATE_EDIT_USER_MESSAGE',
			payload: err.message
		});
	}
};

export const toggleEditUserModal = (toggle) => async (dispatch, getState) => {
	const state = getState().global;

	const getChecked = (row) => {
		return row.Checked === true;
	};

	const user = state.techManagement.users.users.filter(getChecked);

	if (user.length === 0 || user.length > 1) {
		if (user.length === 0) {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'At least one user must be selected to edit',
					icon: 'warning sign',
					warning: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);
		} else {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'Only one user can be edited at a time.',
					icon: 'warning sign',
					warning: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);
		}
	} else {
		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: true
		});

		dispatch({
			type: 'TECH_MANAGEMENT_EDIT_USER_OBJECT',
			payload: user[0]
		});

		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_EDIT_USER_MODAL',
			payload: toggle !== true
		});
	}
};

export const updateEditUserInfo = (payload) => (dispatch) => {
	dispatch({
		type: 'TECH_MANAGEMENT_UPDATE_EDIT_USER_OBJECT',
		payload: payload
	});
};

export const updateUserFilter = (payload) => async (dispatch, getState) => {
	const state = getState().global;
	let users;
	dispatch({
		type: 'TECH_MANAGEMENT_UPDATE_FILTER',
		payload: payload
	});
	switch (payload.type) {
		case 'users':
			for (let property in state.techManagement.filtering.users) {
				if (state.techManagement.filtering.users.hasOwnProperty(property)) {
					if (state.techManagement.filtering.users[property] === null) {
						delete state.techManagement.filtering.users[property];
					}
				}
			}
			state.techManagement.users.users.forEach((user) => {
				for (let property in state.techManagement.filtering.users) {
					if (
						new RegExp(state.techManagement.filtering.users[property].toLowerCase()).test(
							user[property].toLowerCase()
						)
					) {
						users.push(user);
					}
					return users;
				}
			});
			dispatch({
				type: 'TECH_MANAGEMENT_FILTER_USERS',
				payload: users
			});
			break;
		case 'approvals':
			break;
		default:
			break;
	}
};

export const toggleFilter = (toggle) => (dispatch) => {
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_FILTER',
		payload: toggle !== true
	});
};

export const clearFilter = () => (dispatch) => {
	dispatch({
		type: 'TECH_MANAGEMENT_CLEAR_FILTER'
	});
};

export const toggleUser = (email, approval) => async (dispatch, getState) => {
	const state = getState().global;
	if (approval) {
		let index = state.techManagement.approvals.approvals.findIndex((element) => element.Email === email);
		let approvals = {
			...state.techManagement.approvals.approvals[index],
			Checked: state.techManagement.approvals.approvals[index].Checked !== true
		};
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_APPROVALS',
			payload: {
				index,
				approvals
			}
		});
	} else {
		let index = state.techManagement.users.users.findIndex((element) => element.Email === email);
		let user = {
			...state.techManagement.users.users[index],
			Checked: state.techManagement.users.users[index].Checked !== true
		};
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_USER',
			payload: {
				index,
				user
			}
		});
	}
};

export const toggleAll = (approvals) => async (dispatch, getState) => {
	const state = getState().global;

	if (approvals) {
		let approvalsCount = 0;
		let approvals = [];
		let resetApprovals = [];

		state.techManagement.approvals.approvals.forEach((row) => {
			if (row.Checked === true) {
				return approvalsCount++;
			}
		});

		if (approvalsCount !== 0 && approvalsCount !== state.techManagement.approvals.approvals.length) {
			if (approvalsCount > state.techManagement.approvals.approvals.length / 2) {
				state.techManagement.approvals.approvals.forEach((user) => {
					resetApprovals.push({
						...user,
						Checked: true
					});
				});
			}
			if (
				approvalsCount < state.techManagement.approvals.approvals.length / 2 ||
				approvalsCount === state.techManagement.approvals.approvals.length
			) {
				state.techManagement.approvals.approvals.forEach((user) => {
					resetApprovals.push({
						...user,
						Checked: false
					});
				});
			}
		}

		resetApprovals = resetApprovals.length === 0 ? state.techManagement.approvals.approvals : resetApprovals;

		resetApprovals.forEach((user) => {
			approvals.push({
				...user,
				Checked: user.Checked !== true
			});
		});

		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_ALL',
			payload: {
				approvals
			}
		});
	}

	let usersCount = 0;
	let users = [];
	let resetUsers = [];

	state.techManagement.users.users.forEach((row) => {
		if (row.Checked === true) {
			return usersCount++;
		}
	});

	if (usersCount !== 0 && usersCount !== state.techManagement.users.users.length) {
		if (usersCount > state.techManagement.users.users.length / 2) {
			state.techManagement.users.users.forEach((user) => {
				resetUsers.push({
					...user,
					Checked: true
				});
			});
		}
		if (
			usersCount < state.techManagement.users.users.length / 2 ||
			usersCount === state.techManagement.users.users.length
		) {
			state.techManagement.users.users.forEach((user) => {
				resetUsers.push({
					...user,
					Checked: false
				});
			});
		}
	}

	resetUsers = resetUsers.length === 0 ? state.techManagement.users.users : resetUsers;

	resetUsers.forEach((user) => {
		users.push({
			...user,
			Checked: user.Checked !== true
		});
	});

	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_ALL',
		payload: {
			users
		}
	});
};

export const activateUsers = () => async (dispatch, getState) => {
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_ACTIVATE_USERS_LOADER',
		payload: true
	});

	const state = getState().global;

	const getChecked = (row) => {
		return row.Checked === true;
	};

	let users = state.techManagement.users.users.filter(getChecked);

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=tech-management&activateUser=true`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(users)
		});
		const response = call.json();

		if (call.status !== 200) {
			throw response;
		}

		if (response.statusCode) {
			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ACTIVATE_USER_LOADER',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_HIDE_ACTIVATE_USER_MESSAGE',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_UPDATE_ACTIVATE_USER_MESSAGE',
				payload: response.message
			});
		} else {
			users = users.map((row) => {
				const index = state.techManagement.users.users.findIndex((element) => element.Email === row.Email);
				return [
					...state.techManagement.users.users.slice(0, index),
					{...row, Enabled: 'Yes'},
					...state.techManagement.users.users.slice(index + 1)
				];
			});

			dispatch({
				type: 'TECH_MANAGEMENT_ACTIVATE_USERS',
				payload: users
			});

			let userToggle = users.map((user) => ({
				...user,
				Checked: false
			}));

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ALL',
				payload: {
					users: userToggle
				}
			});

			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'User(s) activated. ',
					icon: 'checkmark',
					success: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ACTIVATE_USERS_MODAL',
				payload: state.techManagement.activateUsers.toggleActivateUsersModal !== true
			});

			dispatch(getUsers());

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ACTIVATE_USERS_LOADER',
				payload: false
			});
		}
	} catch (err) {
		console.log(`error 2 === ${JSON.stringify(err)}`);
		dispatch({
			type: 'ERROR',
			payload: `THERE WAS AN ERROR DURING GET USERS: ${err}`
		});
	}
};

export const toggleActivateUsersModal = (toggle) => async (dispatch, getState) => {
	const state = getState().global;

	let getChecked = (row) => {
		return row.Checked === true;
	};

	let users = state.techManagement.users.users.filter(getChecked);

	if (users.length === 0) {
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'At least one user must be selected to use the activate feature.',
				icon: 'warning sign',
				warning: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		setTimeout(() => {
			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: true
			});
		}, 4000);
	}

	dispatch({
		type: 'TECH_MANAGEMENT_UPDATE_ACTIVATE_USERS',
		payload: users
	});

	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_ACTIVATE_USERS_MODAL',
		payload: toggle !== true
	});
};

export const deactivateUsers = () => async (dispatch, getState) => {
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_DEACTIVATE_USERS_LOADER',
		payload: true
	});

	const state = getState().global;

	const getChecked = (row) => {
		return row.Checked === true;
	};

	let users = state.techManagement.users.users.filter(getChecked);

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=tech-management&deactivateUser=true`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(users)
		});
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		if (response.statusCode) {
			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ACTIVATE_USER_LOADER',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_HIDE_ACTIVATE_USER_MESSAGE',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_UPDATE_ACTIVATE_USER_MESSAGE',
				payload: response.message
			});
		} else {
			users = users.map((row) => {
				let index = state.techManagement.users.users.findIndex((element) => element.Email === row.Email);
				return [
					...state.techManagement.users.users.slice(0, index),
					{...row, Enabled: 'No'},
					...state.techManagement.users.users.slice(index + 1)
				];
			});

			const userToggle = users.map((user) => ({
				...user,
				Checked: false
			}));

			dispatch({
				type: 'TECH_MANAGEMENT_DEACTIVATE_USERS',
				payload: users
			});

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ALL',
				payload: {
					users: userToggle
				}
			});

			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'User(s) deactivated. ',
					icon: 'checkmark',
					success: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_DEACTIVATE_USERS_MODAL',
				payload: state.techManagement.deactivateUsers.toggleDeactivateUsersModal !== true
			});

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_DEACTIVATE_USERS_LOADER',
				payload: false
			});
		}
	} catch (err) {
		dispatch({
			type: 'ERROR',
			payload: err
		});
	}
};

export const toggleDeactivateUsersModal = (toggle) => async (dispatch, getState) => {
	const state = getState().global;

	const getChecked = (row) => {
		return row.Checked === true;
	};

	const users = state.techManagement.users.users.filter(getChecked);

	if (users.length === 0) {
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'At least one user must be selected to use the deactivate feature.',
				icon: 'warning sign',
				warning: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		setTimeout(() => {
			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: true
			});
		}, 4000);
	}

	dispatch({
		type: 'TECH_MANAGEMENT_UPDATE_DEACTIVATE_USERS',
		payload: users
	});

	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_DEACTIVATE_USERS_MODAL',
		payload: toggle !== true
	});
};

export const sortUsers = (payload) => async (dispatch, getState) => {
	const state = getState().global;
	let users;
	let approvals;
	if (
		(state.techManagement.sorting.columns.users !== payload.clickedColumn &&
			state.techManagement.sorting.columns.users !== null) ||
		(state.techManagement.sorting.columns.approvals !== payload.clickedColumn &&
			state.techManagement.sorting.columns.approvals !== null)
	) {
		switch (payload.type) {
			case 'users':
				users = state.techManagement.users.users.sort(naturalSort(payload.clickedColumn));
				return dispatch({
					type: 'TECH_MANAGEMENT_SORT_USERS',
					payload: {
						sorting: {
							...state.sorting,
							columns: {
								...state.techManagement.columns,
								users: payload.clickedColumn,
								approvals: null
							},
							directions: {
								...state.techManagement.directions,
								users: 'descending',
								approvals: null
							}
						},
						users: {
							...state.techManagement.users,
							users
						}
					}
				});
			case 'approvals':
				approvals = state.techManagement.approvals.approvals.sort(naturalSort(payload.clickedColumn));

				return dispatch({
					type: 'TECH_MANAGEMENT_SORT_USERS',
					payload: {
						sorting: {
							...state.sorting,
							columns: {
								...state.techManagement.columns,
								approvals: payload.clickedColumn,
								users: null
							},
							directions: {
								...state.techManagement.directions,
								approvals: 'descending',
								users: null
							}
						},
						approvals: {
							...state.techManagement.approvals,
							approvals: approvals
						}
					}
				});
			default:
				break;
		}
	}
	switch (payload.type) {
		case 'users':
			users = state.techManagement.users.users.reverse(naturalSort(payload.clickedColumn));

			dispatch({
				type: 'TECH_MANAGEMENT_SORT_USERS',
				payload: {
					sorting: {
						...state.sorting,
						columns: {
							...state.techManagement.columns,
							users: payload.clickedColumn,
							approvals: null
						},
						directions: {
							...state.techManagement.directions,
							users:
								state.techManagement.sorting.directions.users === 'ascending'
									? 'descending'
									: 'ascending',
							approvals: null
						}
					},
					users: {
						...state.techManagement.users,
						users
					}
				}
			});
			break;

		case 'approvals':
			approvals = state.techManagement.approvals.approvals.reverse(naturalSort(payload.clickedColumn));

			dispatch({
				type: 'TECH_MANAGEMENT_SORT_USERS',
				payload: {
					sorting: {
						...state.sorting,
						columns: {
							...state.techManagement.columns,
							approvals: payload.clickedColumn,
							users: null
						},
						directions: {
							...state.techManagement.directions,
							approvals:
								state.techManagement.sorting.directions.approvals === 'ascending'
									? 'descending'
									: 'ascending',
							users: null
						}
					},
					approvals: {
						...state.techManagement.approvals,
						approvals: approvals
					}
				}
			});
			break;
		default:
			break;
	}
};

export const resetUsersPassword = () => async (dispatch, getState) => {
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_RESET_USERS_PASSWORD_LOADER',
		payload: true
	});

	const state = getState().global;

	const getChecked = (row) => {
		return row.Checked === true;
	};

	let users = state.techManagement.users.users.filter(getChecked);

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=tech-management&resetPassword=true`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(users)
		});
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		if (response.statusCode) {
			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_RESET_USERS_PASSWORD_LOADER',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_HIDE_RESET_USERS_PASSWORD_MESSAGE',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_UPDATE_RESET_USERS_PASSWORD_MESSAGE',
				payload: response.message
			});
		} else {
			users = users.map((row) => {
				let index = state.techManagement.users.users.findIndex((element) => element.Email === row.Email);
				return [
					...state.techManagement.users.users.slice(0, index),
					{...row, UserStatus: 'Password Reset Required'},
					...state.techManagement.users.users.slice(index + 1)
				];
			});

			dispatch({
				type: 'TECH_MANAGEMENT_RESET_USERS_PASSWORD',
				payload: users
			});

			const userToggle = users.map((user) => ({
				...user,
				Checked: false
			}));

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ALL',
				payload: {
					users: userToggle
				}
			});

			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'User(s) password reset. ',
					icon: 'checkmark',
					success: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_RESET_USERS_PASSWORD_MODAL',
				payload: state.techManagement.resetUsersPassword.toggleResetUsersPasswordModal !== true
			});

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_RESET_USERS_PASSWORD_LOADER',
				payload: false
			});
		}
	} catch (err) {
		console.log(`error 4 === ${JSON.stringify(err)}`);
		dispatch({
			type: 'ERROR',
			payload: `THERE WAS AN ERROR DURING GET USERS: ${err}`
		});
	}
};

export const toggleResetUsersPasswordModal = () => async (dispatch, getState) => {
	const state = getState().global;

	dispatch({
		type: 'TECH_MANAGEMENT_HIDE_RESET_USERS_PASSWORD_MESSAGE',
		payload: true
	});

	const getChecked = (row) => {
		return row.Checked === true;
	};

	const users = state.techManagement.users.users.filter(getChecked);

	if (users.length === 0) {
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'At least one user must be selected to use the reset feature.',
				icon: 'warning sign',
				warning: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		setTimeout(() => {
			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: true
			});
		}, 4000);
	}

	dispatch({
		type: 'TECH_MANAGEMENT_UPDATE_RESET_USERS_PASSWORD',
		payload: users
	});

	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_RESET_USERS_PASSWORD_MODAL',
		payload: state.techManagement.resetUsersPassword.toggleResetUsersPasswordModal !== true
	});
};

export const approveUsers = () => async (dispatch, getState) => {
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_APPROVE_USERS_LOADER',
		payload: true
	});

	const state = getState().global;

	const getChecked = (row) => {
		return row.Checked === true && row.Approved === 'Not Approved';
	};

	const approvals = state.techManagement.approvals.approvals.filter(getChecked);

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?mode=tech-management&approveUser=true`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(approvals)
		});
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		if (response.statusCode) {
			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_APPROVE_USERS_LOADER',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_HIDE_APPROVE_USERS_MESSAGE',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_UPDATE_APPROVE_USERS_MESSAGE',
				payload: response.message
			});
		} else {
			approvals.forEach((row) => {
				row.Approved = 'Approved';

				let index = state.techManagement.approvals.approvals.findIndex(
					(element) => element.Email === row.Email
				);
				state.techManagement.approvals.approvals.splice(index, 1);
			});

			dispatch({
				type: 'TECH_MANAGEMENT_APPROVE_USERS',
				payload: state.techManagement.approvals.approvals
			});

			let userToggle = [];

			state.techManagement.approvals.approvals.forEach((user) => {
				userToggle.push({
					...user,
					Checked: false
				});
			});

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_ALL',
				payload: userToggle
			});

			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'User(s) approved. ',
					icon: 'checkmark',
					success: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_APPROVE_USERS_MODAL',
				payload: false
			});

			dispatch({
				type: 'TECH_MANAGEMENT_TOGGLE_APPROVE_USERS_LOADER',
				payload: false
			});
		}
	} catch (err) {
		console.log('error 5 === ', err);
		dispatch({
			type: 'ERROR',
			payload: `THERE WAS AN ERROR DURING GET USERS: ${err}`
		});
		dispatch({
			type: 'TECH_MANAGEMENT_TOGGLE_APPROVE_USERS_LOADER',
			payload: false
		});
	}
};

export const toggleApproveUsersModal = (toggle) => async (dispatch, getState) => {
	const state = getState().global;

	dispatch({
		type: 'TECH_MANAGEMENT_HIDE_APPROVE_USERS_MESSAGE',
		payload: true
	});

	const getChecked = (row) => {
		return row.Checked === true && row.Approved === 'Not Approved';
	};

	const users = state.techManagement.approvals.approvals.filter(getChecked);

	if (users.length === 0) {
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'At least one user must be selected to use the activate feature.',
				icon: 'warning sign',
				warning: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		setTimeout(() => {
			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: true
			});
		}, 4000);
	}

	dispatch({
		type: 'TECH_MANAGEMENT_UPDATE_APPROVE_USERS',
		payload: users
	});

	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_APPROVE_USERS_MODAL',
		payload: toggle !== true
	});
};

export const updateSearch = (text, column) => (dispatch) => {
	dispatch({
		type: 'TECH_MANAGEMENT_UPDATE_SEARCH',
		payload: {
			text,
			column
		}
	});
};

export const toggleToTop = (toggle, approvals) => async (dispatch, getState) => {
	const state = getState().global;
	dispatch({
		type: 'TECH_MANAGEMENT_TOGGLE_TO_TOP',
		payload: {
			...state.toggleToTop,
			users: !approvals ? toggle : false,
			approvals: !approvals ? false : toggle
		}
	});
};

export const toTop = () => {
	window.scrollTo(0, 0);
};
