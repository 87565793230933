import React from 'react';
import {Button, Modal, Icon, Header, Grid, Segment, Label, TextArea, Divider, Loader, Dimmer} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';
import {
	toggleRejectModal,
	acceptReject,
	updateRejectCommentValue
} from '../../../../../services/accountingConsoleActions.js';

class RejectModal extends React.Component {
	render() {
		return (
			<Modal open={this.props.rejectModal}>
				<Header>
					<Grid className="templateDraftSection">
						<Grid.Row>
							<Grid.Column width={3} style={{margin: 'auto 0'}}>
								<Icon name="minus circle" color="red" />
								<div className="content">Reject</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Header>
				{this.props.modalLoading === true ? (
					<Dimmer inverted active>
						<Loader size="big" />
					</Dimmer>
				) : null}
				<Modal.Content scrolling>
					<Segment basic textAlign="center" size="massive">
						Why are you rejecting this?
					</Segment>
					<Divider />
					<Segment>
						<Label color="red" ribbon>
							Comments
						</Label>
						<DebounceInput
							element={TextArea}
							className="scrollingTextArea"
							style={{marginTop: '1rem', minHeight: '10rem'}}
							onChange={(e) => {
								this.props.updateRejectCommentValue(e.target.value);
							}}
							debounceTimeout={600}
						/>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group floated="left">
						<Button
							basic
							color="red"
							onClick={() => {
								this.props.toggleRejectModal(false);
							}}
						>
							<Icon name="remove" /> Cancel
						</Button>
					</Button.Group>
					<Button.Group>
						<Button
							color="red"
							onClick={() => {
								this.props.acceptReject(
									this.props.selectedCategory,
									this.props.selectedRecord,
									false,
									this.props.rejectCommentValue,
									null
								);
							}}
						>
							<Icon name="remove" />
							Add Note and Reject
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

RejectModal.propTypes = {
	rejectModal: PropTypes.bool,
	modalLoading: PropTypes.bool,
	selectedRecord: PropTypes.object,
	toggleRejectModal: PropTypes.func,
	acceptReject: PropTypes.func,
	selectedCategory: PropTypes.string,
	updateRejectCommentValue: PropTypes.func,
	rejectCommentValue: PropTypes.string
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleRejectModal: (value) => {
			dispatch(toggleRejectModal(value));
		},
		acceptReject: (category, record, approve, comment, code) => {
			dispatch(acceptReject(category, record, approve, comment, code));
		},
		updateRejectCommentValue: (value) => {
			dispatch(updateRejectCommentValue(value));
		}
	};
};

const mapStateToProps = (state) => {
	return {
		rejectModal: state.accountingConsoleReducer.accountingConsole.rejectModalToggle,
		selectedRecord: state.accountingConsoleReducer.accountingConsole.selectedRecord,
		selectedCategory: state.accountingConsoleReducer.accountingConsole.selectedCategory.name,
		rejectCommentValue: state.accountingConsoleReducer.accountingConsole.rejectCommentValue,
		modalLoading: state.accountingConsoleReducer.accountingConsole.modalLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectModal);
