import React from 'react';
import {Grid, Header, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';

import InputField from './components/inputField.js';
import DropdownField from './components/dropdownField.js';

const Company = (props) => (
	<Grid>
		<Header as="h3">
			<Icon name="building" />
			<Header.Content>Company</Header.Content>
		</Header>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="companyName" field={props.companyName} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="companyAddress" field={props.companyAddress} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="companyAddress2" field={props.companyAddress2} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="companyCity" field={props.companyCity} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<DropdownField fieldLabel="companyCountry" field={props.companyCountry} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				{props.companyCountry.value === 218 ? (
					<DropdownField fieldLabel="companyState" field={props.companyState} stepIndex={props.stepIndex} />
				) : (
					<InputField
						fieldLabel="companyState"
						field={props.companyState}
						stepIndex={props.stepIndex}
						nonUSAState={true}
					/>
				)}
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="companyZipCode" field={props.companyZipCode} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="companyWebsite" field={props.companyWebsite} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="companyFederal" field={props.companyFederal} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="companyYearFounded"
					field={props.companyYearFounded}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="companyAnnualRevenue"
					field={props.companyAnnualRevenue}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="companyGeographicCoverage"
					field={props.companyGeographicCoverage}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="companyLicensedMunicipalities"
					field={props.companyLicensedMunicipalities}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<DropdownField fieldLabel="companyWarranty" field={props.companyWarranty} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<DropdownField fieldLabel="companyUnion" field={props.companyUnion} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<DropdownField
					fieldLabel="companyWorkNonUnionJobs"
					field={props.companyWorkNonUnionJobs}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

const mapStateToProps = (state) => {
	return {
		stepIndex: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Company')[0].index,
		companyName: state.registrationForm.registrationForm.fields.companyName,
		companyAddress: state.registrationForm.registrationForm.fields.companyAddress,
		companyAddress2: state.registrationForm.registrationForm.fields.companyAddress2,
		companyCity: state.registrationForm.registrationForm.fields.companyCity,
		companyState: state.registrationForm.registrationForm.fields.companyState,
		companyZipCode: state.registrationForm.registrationForm.fields.companyZipCode,
		companyCountry: state.registrationForm.registrationForm.fields.companyCountry,
		companyWebsite: state.registrationForm.registrationForm.fields.companyWebsite,
		companyFederal: state.registrationForm.registrationForm.fields.companyFederal,
		companyYearFounded: state.registrationForm.registrationForm.fields.companyYearFounded,
		companyAnnualRevenue: state.registrationForm.registrationForm.fields.companyAnnualRevenue,
		companyGeographicCoverage: state.registrationForm.registrationForm.fields.companyGeographicCoverage,
		companyLicensedMunicipalities: state.registrationForm.registrationForm.fields.companyLicensedMunicipalities,
		companyWarranty: state.registrationForm.registrationForm.fields.companyWarranty,
		companyUnion: state.registrationForm.registrationForm.fields.companyUnion,
		companyWorkNonUnionJobs: state.registrationForm.registrationForm.fields.companyWorkNonUnionJobs
	};
};

export default connect(mapStateToProps, null)(Company);
