import React from 'react';
import {Radio} from 'semantic-ui-react';
import FilterGrid from '../../../../../components/Global/FilterGrid.js';
import ExportGrid from '../../../../../components/Global/ExportGrid.js';

function FilterGridRow(props) {
	return (
		<div className="aasdi-filter-grid-row">
			<div className="left">
				<FilterGrid cert={true} gridApi={() => props.gridApi} />

				<div className="radio-wrapper">
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Show non-active AASDIs
					</span>
					<Radio className="active-toggle" toggle onChange={() => props.switchGridMode()} />
				</div>
			</div>

			<ExportGrid gridApi={() => props.gridApi} />
		</div>
	);
}

export default FilterGridRow;
