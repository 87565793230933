/**
 * Components let you split the UI into independent, reusable pieces, and think about each piece in isolation. Conceptually, components are like JavaScript functions. They accept arbitrary inputs (called “props”) and return React elements describing what should appear on the screen.
 * @memberof App.Store.Reducers
 * @namespace tasks
 * @since 2.2.0
 */
import update from 'immutability-helper';

const taskReducer = (
	state = {
		contextRef: null,
		workId: null,
		aasdis: [],
		taskList: [],
		templates: [],
		templateLoader: false,
		currentTemplate: {
			tasks: {pinned: [], general: []},
			name: '',
			description: ''
		},
		edit: {
			data: {
				index: null,
				taskTypeId: null,
				dropdownTasks: [],
				title: null,
				pendingChanges: false
			}
		},
		ui: {
			modal: {
				delete: {
					toggle: false,
					header: null,
					message: null
				}
			},
			template: {
				timeout: null,
				dropdownLoading: false,
				searchChoices: [
					{
						text: 'Make template global',
						value: {
							clientName: null,
							clientId: null
						}
					}
				]
			},
			sorting: {
				column: null,
				direction: null
			},
			errors: {
				directions: false,
				url: false,
				inputType: false,
				choices: false,
				templateName: false,
				templateDescription: false,
				taskTypeId: false
			},
			panel: {
				toggle: false,
				type: null,
				aasdiIndex: null,
				groupIndex: null,
				category: null,
				sectionIndex: null,
				isSection: false
			},
			toggleTaskPanel: false,
			toggleDeleteTaskModal: false,
			toggleDeleteTaskLoading: false,
			toggleTemplatePanel: false,
			toggleDeleteTemplateModal: false,
			toggleDeleteTemplateLoading: false,
			toggleDiscardChangesModal: false,
			loading: false,
			grid: {
				left: 8,
				right: 8
			},
			itemsPerRow: 1
		}
	},
	action
) => {
	switch (action.type) {
		case 'TASK_SET_SELECTED_TASK_GROUP':
			state = update(state, {
				selectedTaskGroupIndex: {
					$set: action.payload.groupIndex
				}
			});
			break;

		case 'TASKS_UPDATE_CONTEXTREF':
			state = {
				...state,
				contextRef: action.payload
			};
			break;

		case 'TASK_TEMPLATES_UPDATE_CLIENT_SEARCH_RESULTS':
			state = update(state, {
				ui: {
					template: {
						searchChoices: {
							$set: action.payload
						}
					}
				}
			});
			break;
		case 'TASK_TEMPLATES_UPDATE_DROPDOWN_LOADER':
			state = update(state, {
				ui: {
					template: {
						dropdownLoading: {
							$set: action.payload
						}
					}
				}
			});
			break;
		case 'TASK_TEMPLATES_UPDATE_TIMEOUT':
			state = update(state, {
				ui: {
					template: {
						timeout: {
							$set: action.payload
						}
					}
				}
			});
			break;

		// /**
		//  * Adds a new task into the task list.
		//  * @memberof App.Store.Reducers.tasks
		//  * @typedef {Reducer}
		//  * @param {object} action The object that was passed to the reducer.
		//  * @param {string} action.type Needs to === 'TASKS_UPDATE_PANEL_DATA'.
		//  * @param {object} action.payload The data you would like to update.
		//  * @param {object} action.payload.aasdiIndex The index of the aasdi assigned to the task.
		//  * @param {object} action.payload.groupIndex The index of the task group.
		//  * @param {object} action.payload.task The task data to be added.
		//  * @throws {Logger} If the reducer does not receive the correct payload it will bomb.
		//  * @since 2.2.0
		//  * @example <caption>Example usage of the TASKS_ADD_TASK reducer.</caption>
		//  * dispatch({
		//  * 		type: 'TASKS_ADD_TASK',
		//  * 			payload: {
		//  * 				task,
		//  * 				aasdiIndex: options.aasdiIndex,
		//  * 				groupIndex: options.groupIndex
		//  * 			}
		//  * 		});
		//  */
		case 'TASKS_ADD_TASK':
			switch (action.payload.mode) {
				case 'templateManager':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.sectionIndex]: {
												tasks: {
													[action.payload.options.sectionCategory]: {
														$push: action.payload.task
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											$push: action.payload.task
										}
									}
								}
							});
							break;
					}
					break;
				case 'workOrder':
					// here is the reducer comment
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[state.aasdis[action.payload.options.aasdiIndex].taskGroups[
															action.payload.options.groupIndex
														].tasks[action.payload.options.category].findIndex(
															(task) => task.id === action.payload.task[0].sectionId
														)]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	$push: action.payload.task
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						default:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														$push: action.payload.task
													}
												}
											}
										}
									}
								}
							});
							break;
					}
					break;
			}

			break;

		/**
		 * Scrolls to the last item in the task list.
		 * @memberof App.Store.Reducers.tasks
		 * @typedef {Reducer}
		 * @param {object} action The object that was passed to the reducer.
		 * @param {string} action.type Needs to === 'TASKS_SCROLL_TO_LAST_TASK'.
		 * @throws {Logger} If the reducer does not receive the correct payload it will bomb.
		 * @since 2.2.0
		 * @example <caption>Example usage of the TASKS_SCROLL_TO_LAST_TASK reducer.</caption>
		 * dispatch({
		 * 		type: 'TASKS_SCROLL_TO_LAST_TASK'
		 * 		});
		 */
		case 'TASKS_SCROLL_TO_TASK':
			state.contextRef.childNodes[0].childNodes[1] !== (null || undefined)
				? state.contextRef.childNodes[0].childNodes[1].childNodes[1].childNodes[0].childNodes[
						action.payload.index
				  ].scrollIntoView({
						behavior: 'smooth',
						block: action.payload.block || 'center'
				  })
				: state.contextRef.childNodes[0].childNodes[0].childNodes[1].childNodes[0].childNodes[
						action.payload.index
				  ].scrollIntoView({
						behavior: 'smooth',
						block: action.payload.block || 'center'
				  });
			break;
		case 'TASKS_ADD_DROPDOWN_TASK':
			switch (action.payload.mode) {
				case 'templateManager':
					switch (action.payload.options.isSection) {
						case true:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.sectionIndex]: {
												tasks: {
													[action.payload.options.sectionCategory]: {
														[action.payload.options.index]: {
															choices: {
																$push: [action.payload.value]
															},
															choiceName: {
																$set: ''
															},
															dropdown: {
																$set: action.payload.value
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.index]: {
												choices: {
													$push: [action.payload.value]
												},
												choiceName: {
													$set: ''
												},
												dropdown: {
													$set: action.payload.value
												}
											}
										}
									}
								}
							});
							break;
					}
					break;

				case 'workOrder':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.sectionIndex]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	[action.payload.options.index]: {
																		choices: {
																			$push: [action.payload.value]
																		},
																		choiceName: {
																			$set: ''
																		},
																		dropdown: {
																			$set: action.payload.value
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.index]: {
															choices: {
																$push: [action.payload.value]
															},
															choiceName: {
																$set: ''
															},
															dropdown: {
																$set: action.payload.value
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;
					}
			}
			break;
		case 'TASKS_REMOVE_DROPDOWN_TASK':
			switch (action.payload.mode) {
				case 'templateManager':
					switch (action.payload.options.isSection) {
						case true:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.sectionIndex]: {
												tasks: {
													[action.payload.options.sectionCategory]: {
														[action.payload.options.index]: {
															choices: {
																$splice: [[action.payload.selectedIndex, 1]]
															},
															editName: {
																$set: false
															},
															choiceName: {
																$set: ''
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.index]: {
												choices: {
													$splice: [[action.payload.selectedIndex, 1]]
												},
												editName: {
													$set: false
												},
												choiceName: {
													$set: ''
												}
											}
										}
									}
								}
							});
							break;
					}
					break;

				case 'workOrder':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.sectionIndex]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	[action.payload.options.index]: {
																		choices: {
																			$splice: [[action.payload.selectedIndex, 1]]
																		},
																		editName: {
																			$set: false
																		},
																		choiceName: {
																			$set: ''
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.index]: {
															choices: {
																$splice: [[action.payload.selectedIndex, 1]]
															},
															editName: {
																$set: false
															},
															choiceName: {
																$set: ''
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;
					}
			}
			break;
		case 'TASKS_TOGGLE_EDIT_DROPDOWN_TASK':
			switch (action.payload.mode) {
				case 'templateManager':
					switch (action.payload.options.isSection) {
						case true:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.sectionIndex]: {
												tasks: {
													[action.payload.options.sectionCategory]: {
														[action.payload.options.index]: {
															choiceName: {
																$set: action.payload.dropdown
															},
															editName: {
																$set: true
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.index]: {
												choiceName: {
													$set: action.payload.dropdown
												},
												editName: {
													$set: true
												}
											}
										}
									}
								}
							});
							break;
					}
					break;

				case 'workOrder':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.sectionIndex]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	[action.payload.options.index]: {
																		choiceName: {
																			$set: action.payload.dropdown
																		},
																		editName: {
																			$set: true
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.index]: {
															choiceName: {
																$set: action.payload.dropdown
															},
															editName: {
																$set: true
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;
					}
			}
			break;

		case 'TASKS_SAVE_EDIT_DROPDOWN_TASK':
			switch (action.payload.mode) {
				case 'templateManager':
					switch (action.payload.options.isSection) {
						case true:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.sectionIndex]: {
												tasks: {
													[action.payload.options.sectionCategory]: {
														[action.payload.options.index]: {
															choices: {
																$splice: [
																	[
																		action.payload.selectedIndex,
																		1,
																		action.payload.value
																	]
																]
															},
															editName: {
																$set: false
															},
															dropdown: {
																$set: action.payload.value
															},
															choiceName: {
																$set: ''
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.index]: {
												choices: {
													$splice: [[action.payload.selectedIndex, 1, action.payload.value]]
												},
												editName: {
													$set: false
												},
												dropdown: {
													$set: action.payload.value
												},
												choiceName: {
													$set: ''
												}
											}
										}
									}
								}
							});
							break;
					}
					break;

				case 'workOrder':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.sectionIndex]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	[action.payload.options.index]: {
																		choices: {
																			$splice: [
																				[
																					action.payload.selectedIndex,
																					1,
																					action.payload.value
																				]
																			]
																		},
																		editName: {
																			$set: false
																		},
																		dropdown: {
																			$set: action.payload.value
																		},
																		choiceName: {
																			$set: ''
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.index]: {
															choices: {
																$splice: [
																	[
																		action.payload.selectedIndex,
																		1,
																		action.payload.value
																	]
																]
															},
															editName: {
																$set: false
															},
															dropdown: {
																$set: action.payload.value
															},
															choiceName: {
																$set: ''
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;
					}
			}
			break;

		case 'TASKS_CHECKOUT_CODE':
			switch (action.payload.mode) {
				case 'templateManager':
					switch (action.payload.options.isSection) {
						case true:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.sectionIndex]: {
												tasks: {
													[action.payload.options.sectionCategory]: {
														[action.payload.options.index]: {
															checkoutCode: {
																$set: action.payload.newCode
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.index]: {
												checkoutCode: {
													$set: action.payload.newCode
												}
											}
										}
									}
								}
							});
							break;
					}
					break;

				case 'workOrder':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.sectionIndex]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	[action.payload.options.index]: {
																		checkoutCode: {
																			$set: action.payload.newCode
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.index]: {
															checkoutCode: {
																$set: action.payload.newCode
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;
					}
			}
			break;

		case 'TASKS_SET_APPROVED':
			switch (action.payload.mode) {
				case 'templateManager':
					switch (action.payload.options.isSection) {
						case true:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.sectionIndex]: {
												tasks: {
													[action.payload.options.sectionCategory]: {
														[action.payload.options.index]: {
															approved: {
																$set: action.payload.approved
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.index]: {
												approved: {
													$set: action.payload.approved
												}
											}
										}
									}
								}
							});
							break;
					}
					break;

				case 'workOrder':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.sectionIndex]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	[action.payload.options.index]: {
																		approved: {
																			$set: action.payload.approved
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.index]: {
															approved: {
																$set: action.payload.approved
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;
					}
			}
			break;

		//eslint-disable-next-line
		case 'TASKS_TOGGLE_EDIT_PANEL':
			state = update(state, {
				ui: {
					toggleTaskPanel: {
						$set: state.ui.toggleTaskPanel
							? state.edit.data.index === action.payload.index &&
							  state.edit.data.pinned === action.payload.pinned
								? false
								: state.edit.data.index !== null
							: true
					},
					grid: {
						//Use for changing the layout of the task list and edit panel
						left: state.ui.toggleTaskPanel
							? state.edit.data.index === action.payload
								? {$set: 8}
								: {$set: 8}
							: {$set: 8},
						right: state.ui.toggleTaskPanel
							? state.edit.data.index === action.payload
								? {$set: 8}
								: {$set: 8}
							: {$set: 8}
					},
					itemsPerRow: state.ui.toggleTaskPanel
						? state.edit.data.index === action.payload
							? {$set: 1}
							: {$set: 1}
						: {$set: 1}
				}
			});
			break;

		case 'TASKS_TOGGLE_PANEL':
			state = update(state, {
				ui: {
					panel: {
						toggle: {
							$set: action.payload
						}
					}
				}
			});
			break;

		case 'TASKS_UPDATE_PANEL_DATA':
			state = update(state, {
				ui: {
					panel: {
						$set: action.payload
					}
				}
			});
			break;

		case 'TASKS_RESET_PANEL_DATA':
			state = update(state, {
				ui: {
					panel: {
						$set: {
							toggle: false,
							type: null,
							aasdiIndex: null,
							groupIndex: null,
							category: null,
							sectionIndex: null,
							isSection: false
						}
					}
				}
			});
			break;

		case 'TASKS_UPDATE_ON_LOAD':
			state = update(state, {
				aasdis: {$set: action.payload.aasdis},
				taskList: {$set: action.payload.taskList},
				inputMask: {$set: action.payload.inputMask}
			});
			break;

		case 'TASKS_UPDATE_TASK_ORDER': {
			let mode = action.payload.mode || null;
			if (mode === 'templateManager') {
				// Template Management - Section Task
				if (action.payload.options.isSectionTask) {
					state = update(state, {
						currentTemplate: {
							tasks: {
								general: {
									[action.payload.options.sectionIndex]: {
										tasks: {
											general: {
												$set: action.payload.tasks
											}
										}
									}
								}
							}
						}
					});
				} else {
					// Template Management - Regular Task
					state = update(state, {
						currentTemplate: {
							tasks: {
								general: {
									$set: action.payload.tasks
								}
							}
						}
					});
				}
			} else {
				switch (action.payload.options.isSectionTask) {
					// Task List - Section Task
					case true:
						state = update(state, {
							aasdis: {
								[action.payload.options.aasdiIndex]: {
									taskGroups: {
										[action.payload.options.groupIndex]: {
											tasks: {
												general: {
													[action.payload.options.sectionIndex]: {
														tasks: {
															general: {
																$set: action.payload.tasks
															}
														}
													}
												}
											}
										}
									}
								}
							}
						});
						break;

					// Task List - Regular Task
					default:
						state = update(state, {
							aasdis: {
								[action.payload.options.aasdiIndex]: {
									taskGroups: {
										[action.payload.options.groupIndex]: {
											tasks: {
												general: {
													$set: action.payload.tasks
												}
											}
										}
									}
								}
							}
						});
				}
			}
			break;
		}

		case 'TASKS_SECTION_TOGGLE_SEQUENTIAL':
			state = update(state, {
				aasdis: {
					[action.payload.aasdiIndex]: {
						taskGroups: {
							[action.payload.groupIndex]: {
								tasks: {
									general: {
										[action.payload.taskIndex]: {
											isSequential: {
												$set: action.payload.toggleValue
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'TASKS_TOGGLE_DELETE_MODAL':
			state = update(state, {
				ui: {
					modal: {
						delete: {
							$set: action.payload.delete
						}
					}
				}
			});
			break;

		case 'TASKS_TOGGLE_DISCARD_CHANGES_MODAL':
			state = update(state, {
				ui: {
					toggleDiscardChangesModal: {
						$set: !state.ui.toggleDiscardChangesModal
					}
				}
			});
			break;

		case 'TASKS_TEMPLATES_TOGGLE_DELETE_MODAL':
			state = update(state, {
				ui: {
					toggleDeleteTemplateModal: {
						$set: !state.ui.toggleDeleteTemplateModal
					}
				}
			});
			break;

		case 'TASKS_UPDATE_TASK':
			switch (action.payload.mode) {
				case 'templateManager':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.sectionIndex]: {
												tasks: {
													[action.payload.options.sectionCategory]: {
														[action.payload.options.index]: {
															[action.payload.column]: {
																$set: action.payload.value
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								currentTemplate: {
									tasks: {
										[action.payload.options.category]: {
											[action.payload.options.index]: {
												[action.payload.column]: {
													$set: action.payload.value
												}
											}
										}
									}
								}
							});
							break;
					}
					break;
				case 'workOrder':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.sectionIndex]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	[action.payload.options.index]: {
																		[action.payload.column]: {
																			$set: action.payload.value
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						default:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.index]: {
															[action.payload.column]: {
																$set: action.payload.value
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;
					}
					break;
			}
			break;

		case 'TASKS_DELETE_TASK':
			switch (action.payload.mode) {
				case 'templateManager':
					// Task inside a section
					if (action.payload.options.isSection && action.payload.options.isSectionTask) {
						state = update(state, {
							currentTemplate: {
								tasks: {
									[action.payload.options.category]: {
										[action.payload.options.sectionIndex]: {
											tasks: {
												[action.payload.options.sectionCategory]: {
													$splice: [[action.payload.options.index, 1]]
												}
											}
										}
									}
								}
							}
						});
						// Section
					} else if (action.payload.options.isSection && !action.payload.options.isSectionTask) {
						state = update(state, {
							currentTemplate: {
								tasks: {
									[action.payload.options.category]: {
										$splice: [[action.payload.options.index, 1]]
									}
								}
							}
						});
						// Non section task
					} else {
						state = update(state, {
							currentTemplate: {
								tasks: {
									[action.payload.options.category]: {
										$splice: [[action.payload.options.index, 1]]
									}
								}
							}
						});
					}
					break;

				case 'workOrder':
					switch (action.payload.options.isSectionTask) {
						case true:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														[action.payload.options.sectionIndex]: {
															tasks: {
																[action.payload.options.sectionCategory]: {
																	$splice: [[action.payload.options.index, 1]]
																}
															}
														}
													}
												}
											}
										}
									}
								}
							});
							break;

						case false:
							state = update(state, {
								aasdis: {
									[action.payload.options.aasdiIndex]: {
										taskGroups: {
											[action.payload.options.groupIndex]: {
												tasks: {
													[action.payload.options.category]: {
														$splice: [[action.payload.options.index, 1]]
													}
												}
											}
										}
									}
								}
							});
							break;
					}
			}
			break;

		case 'TASKS_UPDATE_EDIT_LOADER':
			state = update(state, {
				ui: {
					loading: {
						$set: action.payload.loading
					}
				}
			});
			break;

		case 'TASKS_TOGGLE_ORDERED':
			state = update(state, {
				aasdis: {
					[action.payload.aasdiIndex]: {
						taskGroups: {
							[action.payload.groupIndex]: {
								sequential: {
									$set: action.payload.toggleValue
								}
							}
						}
					}
				}
			});
			break;
		case 'TASKS_TOGGLE_ADD_TEMPLATE':
			state = update(state, {
				aasdis: {
					[action.payload.aasdiIndex]: {
						taskGroups: {
							[action.payload.groupIndex]: {
								toggleAddTemplateModal: {
									$set: !state.aasdis[action.payload.aasdiIndex].taskGroups[action.payload.groupIndex]
										.toggleAddTemplateModal
								}
							}
						}
					}
				}
			});
			break;
		case 'TASKS_UPDATE_TEMPLATE_ARRAY':
			state = update(state, {
				aasdis: {
					[action.payload.aasdiIndex]: {
						taskGroups: {
							[action.payload.groupIndex]: {
								templateArray: {
									$set: action.payload.templateArray
								}
							}
						}
					}
				}
			});
			break;
		case 'TASKS_UPDATE_TEMPLATE_OPTIONS':
			state = update(state, {
				aasdis: {
					[action.payload.aasdiIndex]: {
						taskGroups: {
							[action.payload.groupIndex]: {
								templateOptionList: {
									$set: action.payload.optionList
								}
							}
						}
					}
				}
			});
			break;
		case 'TASKS_UPDATE_CURRENT_TEMPLATE':
			state = update(state, {
				aasdis: {
					[action.payload.aasdiIndex]: {
						taskGroups: {
							[action.payload.groupIndex]: {
								currentTemplateTasks: {
									$set: action.payload.currentTemplateTasks
								}
							}
						}
					}
				}
			});
			break;

		//////////////////
		// Templates ////
		////////////////
		case 'TASK_TEMPLATES_LOADER':
			state = update(state, {
				templateLoader: {$set: action.payload.value}
			});
			break;

		case 'TASK_TEMPLATES_UPDATE_TEMPLATES':
			state = update(state, {
				templates: {$set: action.payload}
			});
			break;

		case 'TASK_TEMPLATES_UPDATE_TASKLIST':
			state = update(state, {
				taskList: {$set: action.payload}
			});
			break;

		case 'TASK_TEMPLATES_UPDATE_INPUTMASKLIST':
			state = update(state, {
				inputMask: {$set: action.payload}
			});
			break;

		case 'TASK_TEMPLATES_ADD_TEMPLATE_TO_ARRAY':
			state = update(state, {
				templates: {$unshift: [action.payload]}
			});
			break;

		case 'TASK_TEMPLATES_UPDATE_LIST_LOADER':
			state = update(state, {
				loading: {$set: action.payload}
			});
			break;

		case 'TASK_TEMPLATES_UPDATE_MODE':
			state = update(state, {
				mode:
					action.payload === null || action.payload === undefined
						? {
								$set: 'list'
						  }
						: {
								$set: action.payload
						  }
			});
			break;

		case 'TASK_TEMPLATES_UPDATE_SORTING':
			state = update(state, {
				templates: {$set: action.payload.data},
				ui: {
					sorting: {
						column: {$set: action.payload.column},
						direction: {$set: action.payload.direction}
					}
				}
			});
			break;

		case 'TASK_TEMPLATES_UPDATE_TEMPLATE_TO_EDIT':
			state = update(state, {
				currentTemplate: {$set: action.payload}
			});
			break;

		case 'TASK_TEMPLATES_TOGGLE_EDIT_TEMPLATE':
			state = update(state, {
				ui: {
					toggleTemplatePanel: {
						$set: !state.ui.toggleTemplatePanel
					}
				}
			});
			break;

		case 'TASK_TEMPLATES_UPDATE_TEMPLATE_EDIT_HISTORY':
			if (!state.edit.data.pendingChanges) {
				state = update(state, {
					currentTemplate: {
						history: {
							$set: {
								data: {
									...state.currentTemplate.name,
									...state.currentTemplate.description
								}
							}
						}
					}
				});
			}
			break;

		case 'TASK_TEMPLATES_UPDATE_TEMPLATE_INFO':
			state = update(state, {
				currentTemplate: {
					[action.payload.column]: {
						$set: action.payload.value
					}
				}
			});
			break;
		case 'TASKS_TOGGLE_COMPLETE_MODAL':
			state = update(state, {
				ui: {
					toggleCompleteTaskModal: {
						$set: !state.ui.toggleCompleteTaskModal
					}
				}
			});
			break;
		case 'TASKS_COMPLETE_TASK':
			switch (action.payload.sectionIndex) {
				case -1:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											[action.payload.pinned ? 'pinned' : 'general']: {
												[action.payload.taskIndex]: {
													completed: {
														$set: action.payload.completed
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
				default:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											general: {
												[action.payload.sectionIndex]: {
													tasks: {
														[action.payload.pinned ? 'pinned' : 'general']: {
															[action.payload.taskIndex]: {
																completed: {
																	$set: action.payload.completed
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
			}
			break;
		case 'TASKS_UPDATE_DROPDOWN_USER_CHOICE':
			switch (action.payload.sectionIndex) {
				case -1:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											[action.payload.pinned ? 'pinned' : 'general']: {
												[action.payload.taskIndex]: {
													userChoice: {
														choiceId: {
															$set: action.payload.choiceId
														},
														choice: {
															$set: action.payload.userChoice
														}
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
				default:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											general: {
												[action.payload.sectionIndex]: {
													tasks: {
														[action.payload.pinned ? 'pinned' : 'general']: {
															[action.payload.taskIndex]: {
																userChoice: {
																	choiceId: {
																		$set: action.payload.choiceId
																	},
																	choice: {
																		$set: action.payload.userChoice
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
			}
			break;
		case 'TASKS_UPDATE_FORM_USER_INPUT':
			switch (action.payload.sectionIndex) {
				case -1:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											[action.payload.pinned ? 'pinned' : 'general']: {
												[action.payload.taskIndex]: {
													formUserInput: {
														$set: action.payload.formUserInput
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
				default:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											general: {
												[action.payload.sectionIndex]: {
													tasks: {
														[action.payload.pinned ? 'pinned' : 'general']: {
															[action.payload.taskIndex]: {
																formUserInput: {
																	$set: action.payload.formUserInput
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
			}
			break;

		case 'TASKS_UPDATE_FREE_FORM_USER_INPUT':
			switch (action.payload.sectionIndex) {
				case -1:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											[action.payload.pinned ? 'pinned' : 'general']: {
												[action.payload.taskIndex]: {
													freeFormUserInput: {
														$set: action.payload.freeFormUserInput
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
				default:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											general: {
												[action.payload.sectionIndex]: {
													tasks: {
														[action.payload.pinned ? 'pinned' : 'general']: {
															[action.payload.taskIndex]: {
																freeFormUserInput: {
																	$set: action.payload.freeFormUserInput
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
			}
			break;
		case 'TASKS_UPDATE_TEXT_AREA_INPUT':
			switch (action.payload.sectionIndex) {
				case -1:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											[action.payload.pinned ? 'pinned' : 'general']: {
												[action.payload.taskIndex]: {
													textBoxInput: {
														$set: action.payload.textBoxInput
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
				default:
					state = update(state, {
						aasdis: {
							[action.payload.aasdiIndex]: {
								taskGroups: {
									[action.payload.groupIndex]: {
										tasks: {
											general: {
												[action.payload.sectionIndex]: {
													tasks: {
														[action.payload.pinned ? 'pinned' : 'general']: {
															[action.payload.taskIndex]: {
																textBoxInput: {
																	$set: action.payload.textBoxInput
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					});
					break;
			}
			break;
		case 'TASKS_UPDATE_VALIDATION':
			state = update(state, {
				ui: {
					errors: {
						[action.payload.column]: {
							$set: action.payload.error
						}
					}
				}
			});
			break;

		case 'TASKS_UPDATE_WORK_ID':
			state = update(state, {
				workId: {
					$set: action.payload
				}
			});
			break;

		default:
			break;
	}

	return state;
};

export default taskReducer;
