import React, {Component} from 'react';
import {Popup, Label, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class EstimatorsCellRenderer extends Component {
	render() {
		const estimators = this.props.value ? this.props.value.split(',') : '';
		return estimators ? (
			<Popup
				trigger={
					<div
						style={{
							width: '100%',
							textAlign: 'left',
							paddingLeft: '.8em'
						}}
					>
						<Icon name="user" style={{paddingRight: '1.5em'}} />
						{estimators.length}
					</div>
				}
				on={['hover', 'click']}
				flowing
				hoverable
				position="left center"
			>
				{(() => {
					// console.log('ESTIMATORS', this.props.value);
					// let technologies = this.props.value;
					if (estimators) {
						let list = estimators.map((value, index) => {
							return <Label key={`${value}-${index}`}>{value}</Label>;
						});
						return <div>{list}</div>;
					}
				})()}
			</Popup>
		) : (
			<div style={{width: '100%', textAlign: 'left', paddingLeft: '.8em'}}>
				<Icon name="user" style={{paddingRight: '1.5em'}} />
				{0}
			</div>
		);
	}
}

EstimatorsCellRenderer.propTypes = {
	value: PropTypes.string
};
