let dropdownFactory = (dropdownTasks) => {
	if (dropdownTasks === undefined || dropdownTasks === null) {
		return;
	}
	return dropdownTasks.map((tasks) => {
		return tasks.choiceId
			? {
					key: tasks.choice,
					value: tasks.choice,
					text: tasks.choice
			  }
			: {
					key: tasks,
					value: tasks,
					text: tasks
			  };
	});
};
export default dropdownFactory;
