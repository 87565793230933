import update from 'react-addons-update';

export default (
	state = {
		lostJobOpportunityModal: {
			loading: null,
			toggleLostJobModal: false,
			place: null,
			options: [
				{
					key: '1',
					text: 'Price',
					value: 'Price'
				},
				{
					key: '2',
					text: 'Value',
					value: 'Value'
				},
				{
					key: '3',
					text: 'Products',
					value: 'Projects'
				}
			],
			reason: {
				value: null,
				error: false
			},
			comments: {
				value: null,
				error: false
			},
			id: null,
			error: false
		},
		id: null
	},
	action
) => {
	switch (action.type) {
		case 'LOSTJOB_OPPORTUNITY_MODAL_TOGGLE':
			state = update(state, {
				lostJobOpportunityModal: {
					toggleLostJobModal: {
						$set: !state.lostJobOpportunityModal.toggleLostJobModal
					},
					reason: {
						value: {
							$set: null
						},
						error: {
							$set: false
						}
					},
					comments: {
						value: {
							$set: ''
						},
						error: {
							$set: false
						}
					}
				}
			});
			break;

		case 'LOSTJOB_OPPORTUNITY_MODAL_UPDATE_MODAL_ID':
			state = update(state, {
				lostJobOpportunityModal: {
					id: {
						$set: action.payload.id
					},
					place: {
						$set: action.payload.place
					}
				}
			});
			break;

		case 'LOSTJOB_OPPORTUNITY_MODAL_UPDATE':
			state = update(state, {
				lostJobOpportunityModal: {
					[action.payload.column]: {
						value: {
							$set: action.payload.value
						},
						error: {
							$set: action.payload.error
						}
					}
				}
			});
			break;

		case 'LOSTJOB_OPPORTUNITY_MODAL_LOADING':
			state = update(state, {
				lostJobOpportunityModal: {
					loading: {
						$set: action.payload
					}
				}
			});
			break;
	}

	return state;
};
