import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Dimmer, Loader} from 'semantic-ui-react';
import * as actions from './services/tasksActions.js';
import * as globalActions from '../../../../../../../../components/Global/services/globalActions.js';
import Messages from '../../../../../../../../components/Global/Messages.js';
import ProgressLoader from '../../../../../../../../components/Global/Progress_Loader.js';
import DiscardChangeModal from '../../../../../../../../components/Global/Modal_DiscardChanges.js';
import AASDIs from './components/AASDIs.js';

class Task extends Component {
	componentDidMount() {
		this.props.getTasks(this.props.workId);
		this.props.setSelectedTaskGroup(this.props.aasdiIndex, 0);
		window.onbeforeunload = () => {
			if (this.props.tasks.edit.data.pendingChanges) {
				return true;
			}
		};
	}

	render() {
		return (
			<div>
				{this.props.pageLoading ? (
					<Dimmer inverted active className="centerDimmer" style={{height: '85vh'}}>
						<Loader size="huge" disabled={this.props.pageLoadingError}>
							{this.props.pageLoading === 'newTasks' ? 'Loading Added Tasks' : 'Loading Tasks'}
						</Loader>
						<Messages />
					</Dimmer>
				) : null}
				<DiscardChangeModal {...this.props} />
				{this.props.percent ? <ProgressLoader /> : null}
				<AASDIs mode={'workOrder'} aasdis={this.props.tasks.aasdis} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		// PASSING ALL OF TASKS AND GLOBAL REDUCER IS NO GOOD BUT TIME
		tasks: state.work.tasks,
		// global: state.global,
		pageLoading: state.global.global.pageLoading,
		pageLoadingError: state.global.global.pageLoadingError,
		percent: state.global.global.ui.progressLoader.percent,
		workId: state.work.workRecord.view.id
	};
};

Task.propTypes = {
	aasdiIndex: PropTypes.number,
	location: PropTypes.shape({
		search: PropTypes.string
	}),
	global: PropTypes.shape({
		pageLoading: PropTypes.bool,
		pageLoadingError: PropTypes.bool
	}),
	tasks: PropTypes.shape({
		aasdis: PropTypes.array
	}),
	getTasks: PropTypes.func,
	setSelectedTaskGroup: PropTypes.func,
	toggleSectionSequential: PropTypes.func
};

export default connect(mapStateToProps, {...actions, ...globalActions})(Task);
