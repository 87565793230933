import update from 'react-addons-update';

export default (
	state = {
		contactModal: {
			id: null,
			type: null,
			typeId: null,
			loading: null,
			place: null,
			option: null,
			error: false,
			contactData: null,
			toggleContactModal: false,
			rowIndex: null,
			modalFields: [
				{
					name: 'Search for Contact',
					value: null,
					input: false,
					error: false,
					disabled: false,
					searchAE: true,
					options: null,
					loading: false,
					required: true,
					skip: true
				},
				{
					name: 'First Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: true,
					split: true
				},
				{
					name: 'Last Name',
					input: true,
					value: '',
					error: false,
					disabled: false,
					required: true,
					split: true
				},
				{
					name: 'Title',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false
				},
				{
					name: 'Email',
					input: true,
					value: null,
					error: false,
					disabled: false,
					required: false
				},
				{
					name: 'Phone',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false
				},
				{
					name: 'Extension',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false
				},
				{
					name: 'Primary Contact',
					value: false,
					input: false,
					options: [
						{
							key: 'Yes',
							value: true,
							text: 'Yes'
						},
						{
							key: 'No',
							value: false,
							text: 'No'
						}
					],
					error: false,
					disabled: false,
					required: false,
					upward: true
				}
			]
		}
	},
	action
) => {
	switch (action.type) {
		case 'CONTACT_MODAL_LOADING':
			state = update(state, {
				contactModal: {
					loading: {
						$set: action.payload.state
					}
				}
			});
			break;

		case 'CONTACT_MODAL_UPDATE':
			state = update(state, {
				contactModal: {
					error: {
						$set: false
					},
					modalFields: {
						[action.payload.index]: {
							value: {
								$set: action.payload.value
							},
							error: {
								$set: false
							}
						}
					}
				}
			});
			break;

		case 'CONTACT_MODAL_TOGGLE':
			state = update(state, {
				contactModal: {
					toggleContactModal: {
						$set: !state.contactModal.toggleContactModal
					},
					loading: {
						$set: null
					},
					option: {
						$set: action.payload.option
					},
					contactData: {
						$set: action.payload.contactData
					},
					error: {
						$set: false
					},
					id: {
						$set: action.payload.id
					},
					type: {
						$set: action.payload.type
					},
					typeId: {
						$set: action.payload.typeId
					},
					place: {
						$set: action.payload.place
					},
					rowIndex: {
						$set: action.payload.rowIndex
					}
				}
			});
			break;

		case 'CONTACT_MODAL_TOGGLE_OPTION':
			state = update(state, {
				contactModal: {
					option: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CONTACT_MODAL_AUTOFILL':
			state = update(state, {
				contactModal: {
					modalFields: {
						1: {
							value: {
								$set: action.payload.contactData.firstName
							}
						},
						2: {
							value: {
								$set: action.payload.contactData.lastName
							}
						},
						3: {
							value: {
								$set: action.payload.contactData.title
							}
						},
						4: {
							value: {
								$set: action.payload.contactData.email
							}
						},
						5: {
							value: {
								$set: action.payload.contactData.phone
							}
						},
						6: {
							value: {
								$set: action.payload.contactData.extension
							}
						},
						7: {
							value: {
								$set: action.payload.contactData.isPrimary
							}
						}
					}
				}
			});
			break;

		case 'CONTACT_MODAL_GET_DROPDOWN_OPTIONS':
			state = update(state, {
				contactModal: {
					loading: {
						$set: null
					},
					modalFields: {
						[action.payload.column]: {
							options: {
								$set: action.payload.options
							},
							loading: {
								$set: false
							}
						}
					}
				}
			});
			break;

		case 'CONTACT_MODAL_LOADING_FIELD':
			state = update(state, {
				contactModal: {
					modalFields: {
						[action.payload.column]: {
							loading: {
								$set: true
							}
						}
					}
				}
			});
			break;

		case 'CONTACT_MODAL_CHECK_REQUIRED_FIELDS':
			action.payload.emptyRequiredFields.forEach((field) => {
				state = update(state, {
					contactModal: {
						error: {
							$set: true
						},
						modalFields: {
							[field.FieldIndex]: {
								error: {
									$set: true
								}
							}
						}
					}
				});
			});
			break;

		case 'CONTACT_MODAL_CLOSE':
			state = update(state, {
				contactModal: {
					toggleContactModal: {
						$set: false
					}
				}
			});
			break;

		case 'CONTACT_MODAL_ERROR':
			state = update(state, {
				contactModal: {
					error: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CONTACT_MODAL_CLEAR_FIELDS':
			state = update(state, {
				contactModal: {
					error: {
						$set: false
					},
					modalFields: {
						1: {
							value: {
								$set: ''
							},
							error: {
								$set: false
							}
						},
						2: {
							value: {
								$set: ''
							},
							error: {
								$set: false
							}
						},
						3: {
							value: {
								$set: ''
							},
							error: {
								$set: false
							}
						},
						4: {
							value: {
								$set: ''
							},
							error: {
								$set: false
							}
						},
						5: {
							value: {
								$set: ''
							},
							error: {
								$set: false
							}
						},
						6: {
							value: {
								$set: ''
							},
							error: {
								$set: false
							}
						},
						7: {
							value: {
								$set: ''
							},
							error: {
								$set: false
							}
						}
					}
				}
			});
			break;
	}

	return state;
};
