import update from 'immutability-helper';

const analytics = (
	state = {
		stages: {
			loading: false,
			currentStage: '',
			data: {
				identifiedNeed: {
					count: 0,
					revenue: '',
					average: '',
					averageTime: 0
				},
				estimating: {
					count: 0,
					revenue: '',
					average: '',
					averageTime: 0
				},
				submittedQuote: {
					count: 0,
					revenue: '',
					average: '',
					averageTime: 0
				},
				highProbability: {
					count: 0,
					revenue: '',
					average: '',
					averageTime: 0
				},
				verbalAward: {
					count: 0,
					revenue: '',
					average: '',
					averageTime: 0
				},
				closedBusiness: {
					count: 0,
					revenue: '',
					average: '',
					averageTime: 0
				},
				inactive: {
					count: 0,
					revenue: '',
					average: '',
					averageTime: 0
				},
				lostJob: {
					count: 0,
					revenue: '',
					average: '',
					averageTime: 0
				}
			}
		},
		pipeline: {},
		charts: {}
	},
	action
) => {
	switch (action.type) {
		case 'CRM_PIPELINE_ANALYTICS_UPDATE_STAGE':
			state = update(state, {
				stages: {
					currentStage: {
						$set: action.payload
					}
				}
			});
			break;

		case 'CRM_PIPELINE_UPDATE_STAGE_ANALYTICS_BY_KEY':
			state = update(state, {
				stages: {
					[action.key]: {
						$set: action.payload
					}
				}
			});
			break;

		case 'CRM_PIPELINE_UPDATE_PIPELINE_OVERVIEW_ANALYTICS':
			state = update(state, {
				pipeline: {
					$set: action.payload
				}
			});
			break;

		case 'CRM_PIPELINE_UPDATE_PIPELINE_CHART_ANALYTICS':
			state = update(state, {
				charts: {
					[action.name]: {
						$set: action.payload
					}
				}
			});
			break;

		case 'CRM_PIPELINE_UPDATE_CHART_TYPE_ANALYTICS':
			state = update(state, {
				charts: {
					[action.name]: {
						type: {
							$set: action.payload
						}
					}
				}
			});
			break;

		default:
			break;
	}
	return state;
};

export default analytics;
