import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

import { Provider } from 'react-redux';
import store, {history, persistor} from './store.js';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { Router } from 'react-router-dom';
import { App } from './App.jsx';
import { AppStateProvider } from './hooks/useAppState';
import {PersistGate} from 'redux-persist/integration/react';


LicenseManager.setLicenseKey(
	// AG-grid license
	'CompanyName=Automated Systems Design, Inc.,LicensedApplication=OMNI,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-036458,SupportServicesEnd=25_February_2024_[v2]_MTcwODgxOTIwMDAwMA==f33a418f37a0b38a8cb6a92f2a20dce5'
);

ReactDOM.render(
	<AppStateProvider>
		<Provider store={store}>
			<Router history={history}>
				<PersistGate persistor={persistor}>
					<App/>
				</PersistGate>
			</Router>
		</Provider>
	</AppStateProvider>,
	document.getElementById('root')
);