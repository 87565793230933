import React from 'react';
import {Menu} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {changeViewStep} from '../services/workRecordActions.js';
import AddTemplateTasksModal from '../../../../../../TaskTemplates/components/AddTemplateTasksModal.jsx';

class workViewNavBar extends React.Component {
	render() {
		if ((this.props.workRecord.id || this.props.profile) && !this.props.navbarLoading) {
			if (this.props.workRecord.isProject && this.props.workRecord.isGroupBillable) {
				this.stageNames = ['Overview', 'Orders', 'Financials', 'Documents', 'Event Log'];
			} else if (this.props.workRecord.isProject) {
				this.stageNames = ['Overview', 'Manage AASDI', 'Tasks', 'Financials', 'Documents', 'Event Log'];
			} else if (this.props.profile === true) {
				this.stageNames = [
					'Overview',
					'Opportunities',
					'Work',
					'Locations',
					'Order Groups',
					'Documents',
					'Pricing'
				];
			} else {
				this.stageNames = [
					'Overview',
					'Manage AASDI',
					'Tasks',
					'Billing Center',
					'Project Cost',
					'Documents',
					'Event Log'
				];
			}
		} else {
			this.stageNames = ['Work Record Loading'];
		}
		return (
			<Menu
				pointing
				secondary
				size="huge"
				widths={this.stageNames.length}
				style={{
					margin: '1.5em 1em 2em 1em',
					position: 'sticky',
					top: '60px',
					zIndex: '99999',
					backgroundColor: 'white',
					border: 'solid white 15px'
				}}
			>
				{/* <AddTemplateTasksModal /> */}
				{this.stageNames.map((stage, index) => {
					return (
						<Menu.Item
							disabled={!(this.props.workRecord.id && !this.props.navbarLoading)}
							name={stage}
							key={index}
							className={[
								'workView',
								this.stageNames.indexOf(this.props.activeStep) < index ? 'toBeCompleted' : null
							]}
							active={this.props.activeStep === stage}
							link={true}
							color={'blue'}
							onClick={() => {
								if (this.stageNames.indexOf(this.props.activeStep) !== index) {
									this.props.changeViewStep(stage);
								}
								if (this.props.onClick) {
									this.props.onClick(stage);
								}
								return;
							}}
						/>
					);
				})}
			</Menu>
		);
	}
}

workViewNavBar.propTypes = {
	activeStep: PropTypes.string,
	changeViewStep: PropTypes.func,
	navbarLoading: PropTypes.bool,
	onClick: PropTypes.func,
	profile: PropTypes.bool,
	workRecord: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		activeStep: state.work.workRecord.view.activeStep,
		workRecord: state.work.workRecord.view.record,
		navbarLoading: state.work.workRecord.view.navbarLoading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeViewStep: (value) => {
			dispatch(changeViewStep(value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(workViewNavBar);
