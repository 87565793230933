import React from 'react';
import {Button, Modal, Icon, Header, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import * as actions from '../../../../../../../../services/workRecordActions.js';

import OrderLinkRenderer from './orderLinkRenderer.js';

class SelectWOModal extends React.Component {
	componentDidMount() {
		if (this.props.type === 'create') {
			this.props.getAddOrderGroupWorkOrders(
				// this.props.quotedJobNumber,
				this.props.workNumber,
				'selectWO'
			);
		}
	}

	defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true
	};

	columnDefs = [
		{
			headerName: 'Link',
			field: 'action',
			cellRendererFramework: OrderLinkRenderer,
			maxWidth: 75,
			filter: false,
			getQuickFilterText: (params) => {
				// Filter based on project or work order
				return params.data.isProject ? 'Project' : 'Work Order';
			}
		},
		{
			headerName: 'Work #',
			field: 'workNumber',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Client',
			field: 'client_Name',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Issue Date',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Time',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Store #',
			field: 'location_StoreNumber',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'City',
			field: 'location_City',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'State',
			field: 'location_State',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Site Contact',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Site Contact Phone',
			field: '',
			filter: 'agTextColumnFilter'
		}
	];

	render() {
		return (
			<Modal open={this.props.selectWOModalToggleValue} size="large">
				<Header>
					<Icon name="plus" color={this.props.iconError ? 'red' : 'green'} />
					<div className="content">Select WOs</div>
				</Header>
				<Modal.Content scrolling={true}>
					<div style={{padding: '1em 0'}}>
						Select a row by clicking on it. Selecting a row will remove previous selection unless you hold
						down
						<span style={{color: 'blue'}}> ctrl / cmd</span> while clicking. Selecting a row and then
						holding down
						<span style={{color: 'blue'}}> shift</span> while clicking a second row will select the range.
					</div>
					<Segment style={{paddingBottom: '2em'}}>
						<div
							className="ag-theme-balham"
							style={{
								height: '40vh',
								width: '100%',
								textAlign: 'left',
								boxSizing: 'border-box'
							}}
						>
							<AgGridReact
								onGridReady={(params) => {
									this.gridApi = params.api;
									this.columnApi = params.columnApi;
									this.gridApi.sizeColumnsToFit();
								}}
								suppressHorizontalScroll={true}
								modules={AllModules}
								rowSelection="multiple"
								suppressCopyRowsToClipboard={true}
								// enable delta updates
								deltaRowDataMode={true}
								// return id required for delta updates
								getRowNodeId={(data) => data.id}
								{...this.props}
								suppressContextMenu={true}
								rowData={this.props.workOrderData}
								columnDefs={this.columnDefs}
								defaultColDef={this.defaultColDef}
							/>
						</div>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" onClick={() => this.props.selectWOModalToggle([])}>
						<Icon name="remove" />
						Cancel
					</Button>
					<Button
						color="blue"
						onClick={() => {
							this.props.selectWOModalAddItems(this.gridApi.getSelectedRows());
							this.props.selectWOModalToggle([]);
						}}
					>
						<Icon name="checkmark" />
						Add Selected WOs
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		selectWOModalToggleValue:
			state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.selectWOModal.toggle,
		workOrderData: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.selectWOModal.rowData,
		// quotedJobNumber: state.work.workRecord.view.record.quotedJobNumber
		workNumber: state.work.workRecord.view.record.workNumber,
		type: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.type
	};
};

export default connect(mapStateToProps, actions)(SelectWOModal);
