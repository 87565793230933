import React from 'react';
import {Button, Popup} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {toggleLocationOrderHistModal} from '../../../../../../../components/Global/services/globalActions.js';
import {
	getLocationWorks,
	clearLocationWorks,
	setLocationToDeactivate,
	toggleConfirmDeactivateLocationModal
} from '../services/profileGridActions.js';

const LocationGridActionsCellRenderer = (props) => {
	const openModal = () => {
		props.clearLocationWorks();
		props.getLocationWorks(props.data.id);
		props.toggleLocationOrderHistModal(true);
	};

	const openConfirmModal = () => {
		props.toggleConfirmDeactivateLocationModal(true);
		props.setLocationToDeactivate(props.rowIndex, props.data);
	};

	return (
		<div style={{marginTop: '.15rem'}}>
			<Popup
				trigger={
					<Button
						icon="ban"
						style={{
							color: 'red',
							backgroundColor: '#e8e8e8',
							padding: '.5em .5em .5em',
							marginLeft: '.5em'
						}}
						onClick={() => openConfirmModal()}
					/>
				}
				content={'Disable Location'}
				on={['hover', 'click']}
				flowing
				hoverable
				style={{zIndex: 1}}
				position="top center"
				basic
			/>
			<Popup
				trigger={
					<Button
						icon="linkify"
						style={{
							backgroundColor: '#e8e8e8',
							padding: '.5em .5em .5em',
							marginLeft: '.5em'
						}}
						onClick={() => openModal()}
					/>
				}
				content={'View Order History'}
				on={['hover', 'click']}
				flowing
				hoverable
				position="right center"
				style={{zIndex: 1}}
				basic
			/>
		</div>
	);
};

LocationGridActionsCellRenderer.propTypes = {
	data: PropTypes.object,
	toggleLocationOrderHistModal: PropTypes.func,
	getLocationWorks: PropTypes.func,
	clearLocationWorks: PropTypes.func,
	rowIndex: PropTypes.number,
	setLocationToDeactivate: PropTypes.func,
	toggleConfirmDeactivateLocationModal: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	toggleLocationOrderHistModal: (status) => dispatch(toggleLocationOrderHistModal(status)),
	getLocationWorks: (status) => dispatch(getLocationWorks(status)),
	clearLocationWorks: () => dispatch(clearLocationWorks()),
	setLocationToDeactivate: (index, location) => dispatch(setLocationToDeactivate(index, location)),
	toggleConfirmDeactivateLocationModal: (status) => dispatch(toggleConfirmDeactivateLocationModal(status))
});

export default connect(null, mapDispatchToProps)(LocationGridActionsCellRenderer);
