import {Button, Icon, Modal, Header, Loader, Dimmer, Message} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const resetPassword = (props) => (
	<div>
		<Button
			{...props}
			type="button"
			icon
			size="medium"
			toggle
			onClick={() =>
				props.toggleResetUsersPasswordModal(
					props.techManagement.resetUsersPassword.toggleResetUsersPasswordModal
				)
			}
		>
			<Icon color="blue" name="repeat" />
		</Button>
		<Modal
			closeIcon
			size="tiny"
			basic
			open={props.techManagement.resetUsersPassword.toggleResetUsersPasswordModal}
			onClose={() =>
				props.toggleResetUsersPasswordModal(
					props.techManagement.resetUsersPassword.toggleResetUsersPasswordModal
				)
			}
		>
			<Dimmer active={props.techManagement.resetUsersPassword.loading} />
			<Header icon="x" content="Reset user(s) password" />
			<Modal.Content>
				<Loader disabled={props.techManagement.resetUsersPassword.loading !== true} />
				<h3>
					Are you sure you want to reset {_.size(props.techManagement.resetUsersPassword.users)} selected
					users passwords?
				</h3>
				<Message
					attached="bottom"
					error
					hidden={props.techManagement.resetUsersPassword.message.hideResetUsersPasswordMessage}
				>
					<Icon name="help" />
					<span>{props.techManagement.resetUsersPassword.message.text}</span>
				</Message>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="red"
					onClick={() =>
						props.toggleResetUsersPasswordModal(
							props.techManagement.resetUsersPassword.toggleResetUsersPasswordModal
						)
					}
					inverted
				>
					<Icon name="remove" /> No
				</Button>
				<Button color="green" onClick={() => props.resetUsersPassword()} inverted>
					<Icon name="checkmark" /> Yes
				</Button>
			</Modal.Actions>{' '}
		</Modal>
	</div>
);

resetPassword.propTypes = {
	techManagement: PropTypes.shape({
		resetUsersPassword: PropTypes.shape({
			toggleResetUsersPasswordModal: PropTypes.bool,
			loading: PropTypes.bool,
			users: PropTypes.array,
			message: PropTypes.shape({
				hideResetUsersPasswordMessage: PropTypes.bool,
				text: PropTypes.string
			})
		})
	}),
	toggleResetUsersPasswordModal: PropTypes.func,
	resetUsersPassword: PropTypes.func
};

export default resetPassword;
