import {Button, Icon, Item} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {SortableElement} from 'react-sortable-hoc';
import EditPanelButton from './Button_EditPanel.js';
import PinButton from './Button_Pin.js';
import DragHandle from './Button_DragHandle.js';
import PictureLabels from './Labels_Picture.js';
import SwipeLabels from './Labels_Swipe.js';
import InputMaskLabels from './Labels_InputMask.js';
import URLLabels from './Labels_URL.js';
import CheckOutLabels from './Labels_CheckOut.js';
import ParagraphLabels from './Labels_Paragraph.js';
import DropDownLabels from './Labels_DropDown.js';
import SignatureLabels from './Labels_Signature.js';
import CompletedLabels from './Labels_Completed.js';

export const Task = (props) => (
	<Item>
		{!props.group ? console.log(props) : null}
		<Item.Content>
			<Button.Group floated="right" style={{paddingLeft: '.8rem'}}>
				<EditPanelButton {...props} />
				<PinButton {...props} />
				{props.group.sequential || !props.task.id ? (
					props.task.completed || props.task.id === null || props.task.pinned ? null : (
						<DragHandle />
					)
				) : props.task.pinned ? null : (
					<DragHandle />
				)}
			</Button.Group>
			<Item.Header
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					maxWidth: '70%'
				}}
			>
				{props.task.pinned ? <Icon name="pin" /> : <span>{`${props.task.taskOrder + 1}. `}</span>}
				{props.task.title ? props.task.title : props.task.name}
			</Item.Header>
			<Item.Meta />
			<Item.Description>{props.task.directions}</Item.Description>
			<Item.Extra>
				<CompletedLabels {...props} />

				{(() => {
					switch (props.task.taskTypeId) {
						case 1:
							return <PictureLabels {...props} />;

						case 2:
							return <SwipeLabels {...props} />;
						case 3:
						case 7:
							return <InputMaskLabels {...props} />;
						case 4:
							return <SignatureLabels {...props} />;
						case 5:
							return <URLLabels {...props} />;
						case 6:
							return <DropDownLabels {...props} />;
						case 8:
							return <CheckOutLabels {...props} />;
						case 9:
							return <ParagraphLabels {...props} />;
					}
				})()}
			</Item.Extra>
		</Item.Content>
	</Item>
);

export const SortableTask = SortableElement(({props}) => <Task {...props} disabled={Boolean(props.task.completed)} />);

Task.propTypes = {
	task: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired
};
