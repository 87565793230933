import React from 'react';
import {Label, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {updateRegistrationForm, stepComplete} from '../../services/registrationActions.js';

const DropdownField = (props) => (
	<div>
		<Label
			style={{
				backgroundColor: 'transparent',
				fontSize: '1rem',
				color: 'rgba(0,0,0,.8)',
				paddingLeft: '0'
			}}
		>
			{props.field.name}
		</Label>
		<Dropdown
			className="createModalDropdown"
			placeholder={props.field.placeholder}
			fluid
			search
			selection
			options={props.field.options}
			onChange={(e, {value}) => {
				props.updateRegistrationForm('registrationForm', props.stepIndex, props.fieldLabel, value, false);
				props.stepComplete(props.stepIndex);
			}}
			icon={props.field.icon}
			value={props.field.value}
			error={props.field.required ? Boolean(props.field.error) : false}
			disabled={props.field.disabled}
			upward={props.field.upward}
			loading={props.field.loading}
			defaultSearchQuery={props.field.searchQuery}
		/>
	</div>
);

const mapDispatchToProps = (dispatch) => {
	return {
		updateRegistrationForm: (modal, step, column, value, error) => {
			dispatch(updateRegistrationForm(modal, step, column, value, error));
		},
		stepComplete: (stepIndex) => {
			dispatch(stepComplete(stepIndex));
		}
	};
};

export default connect(null, mapDispatchToProps)(DropdownField);
