// Modules
import React from 'react';
import {Grid} from 'semantic-ui-react';
import {connect} from 'react-redux';
// import PropTypes from 'prop-types';

import {updateCreateWorkOrderModal} from '../../services/createWorkOrderActions.js';

// Component
import WorkOrderDropDownField from '../components/workOrderDropDownField.js';

const TemplateDraftSection = (props) => (
	<Grid className="templateDraftSection">
		<Grid.Row style={{paddingTop: 0, paddingBottom: '1.2em'}}>
			{props.sectionFields.map((field, fieldIndex) =>
				field.toggle ? null : field.input ? null : (
					<Grid.Column width={field.columnWidth} key={fieldIndex}>
						<WorkOrderDropDownField
							field={field}
							sectionIndex={props.sectionIndex}
							fieldIndex={fieldIndex}
							templateSection={true}
						/>
					</Grid.Column>
				)
			)}
		</Grid.Row>
	</Grid>
);

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'workOrderTemplateDraftSection'
		),
		sectionFields: state.work.createWorkOrder.createWorkOrderModal.sections.filter(
			(section) => section.name === 'workOrderTemplateDraftSection'
		)[0].fields
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateWorkOrderModal: (modal, sectionIndex, fieldIndex, value, error) => {
			dispatch(updateCreateWorkOrderModal(modal, sectionIndex, fieldIndex, value, error));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDraftSection);
