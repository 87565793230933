import React from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const RemoveRowInvoiceButton = (props) =>
	props.data.status === 'Awaiting Submission' ? (
		<Popup
			trigger={
				<Icon
					name="cancel"
					color="red"
					size="large"
					disabled={Boolean(props.isDesynced)}
					style={props.data.status === 'Awaiting Submission' ? {cursor: 'pointer'} : null}
					onClick={() => {
						if (!props.isDesynced && props.data.status === 'Awaiting Submission') {
							props.agGridReact.props.actionBillModalToggle(props.data, 'deleteBill', props.rowIndex);
						}
					}}
				/>
			}
			position="left center"
			content={props.isDesynced ? 'Desynced' : 'Delete Bill'}
		/>
	) : null;

RemoveRowInvoiceButton.propTypes = {
	agGridReact: PropTypes.object,
	isDesynced: PropTypes.bool,
	rowIndex: PropTypes.number,
	data: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		isDesynced: state.work.workRecord.view.record.desynced
	};
};

export default connect(mapStateToProps, null)(RemoveRowInvoiceButton);
