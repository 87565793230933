import {Button, Icon, Modal, Header, Loader, Dimmer} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import optionFactory from './optionFactory.js';

const DeleteModal = (props) => (
	<Modal
		closeIcon
		size="tiny"
		basic
		open={props.tasks.ui.modal.delete.toggle}
		onClose={() => props.toggleDeleteModal('close')}
	>
		<Header icon="trash" content={props.tasks.ui.modal.delete.header} />
		<Modal.Content>
			<p>{props.tasks.ui.modal.delete.message}</p>
		</Modal.Content>
		<Modal.Actions>
			<Button color="red" onClick={() => props.toggleDeleteModal('close')} inverted>
				<Icon name="remove" /> No
			</Button>
			<Button
				{...props}
				color="green"
				onClick={() => {
					props.deleteTask(
						props.tasks.ui.modal.delete.mode,
						props.tasks.ui.modal.delete.options,
						props.tasks.ui.modal.delete.task
					);
				}}
				inverted
			>
				<Icon name="checkmark" /> Yes
			</Button>
		</Modal.Actions>
	</Modal>
);

export default DeleteModal;
