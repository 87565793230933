import {Progress} from 'semantic-ui-react';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from './services/globalActions.js';

/**
 * This component is used to prompt the user with a modal asking if they would like to discard changes.
 * @memberof App.Components.Presentational.global#
 * @param {object} props The state object needs to include the global reducer.
 * @param {object} props.global The global object.
 * @param {object} props.global.ui The UI object.
 * @param {object} props.global.progressLoader Hold the state data for the progressLoader component.
 * @param {boolean} props.global.progressLoader.error Either true of false.
 * @param {string} props.global.progressLoader.message What you would like to display to the user.
 * @param {Int} props.global.progressLoader.percent 0-100.
 * @param {boolean} props.global.progressLoader.success Either true of false.
 */
const ProgressLoader = (props) => (
	<Progress
		active
		size={props.success || props.error ? 'medium' : 'tiny'}
		percent={props.percent}
		indicating
		key={'progressLoader'}
		success={props.success}
		error={props.error}
		className={'progressLoader'}
		style={{margin: '2em 0'}}
	>
		{props.message}
	</Progress>
);

const mapStateToProps = (state) => {
	return {
		success: state.global.global.ui.progressLoader.success,
		error: state.global.global.ui.progressLoader.error,
		percent: state.global.global.ui.progressLoader.percent,
		message: state.global.global.ui.progressLoader.message
	};
};

export default connect(mapStateToProps, actions)(ProgressLoader);

ProgressLoader.propTypes = {
	size: PropTypes.string,
	global: PropTypes.shape({
		ui: PropTypes.shape({
			progressLoader: PropTypes.shape({
				error: PropTypes.bool.isRequired,
				message: PropTypes.string,
				percent: PropTypes.number,
				success: PropTypes.bool.isRequired
			}).isRequired
		}).isRequired
	}).isRequired
};
