// Modules
import React from 'react';
import {Label, Icon, Popup} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Service
import {updateFilter} from '../services/activityStreamActions.js';

let FilterLabel = ({logType, count, filter, updateFilter}) => (
	<Popup
		trigger={
			<Label
				as="a"
				onClick={() => {
					updateFilter(logType);
				}}
			>
				{filter === logType ? <Icon name="table" /> : null}
				{logType}
				<Label.Detail>{count}</Label.Detail>
			</Label>
		}
		header="Select an event filter."
		content={`Click on this filter to only show records for ${logType}.`}
	/>
);

FilterLabel.propTypes = {
	logType: PropTypes.string.isRequired,
	count: PropTypes.string.isRequired,
	filter: PropTypes.string.isRequired,
	updateFilter: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateFilter: (logType) => {
			dispatch(updateFilter(logType));
		}
	};
};

export default connect(null, mapDispatchToProps)(FilterLabel);
