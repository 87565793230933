import React from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {Grid, Segment, Label, Message, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class eventLog extends React.Component {
	render() {
		const defaultColDef = {
			resizable: true
		};
		return (
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<Segment
							style={{
								padding: '1rem',
								height: '75vh'
							}}
							loading={this.props.eventLog.loading}
						>
							<Label attached="top" color="blue">
								Event Log
							</Label>
							<div
								className="ag-theme-balham"
								style={{
									height: '70vh',
									width: '100%',
									textAlign: 'left',
									boxSizing: 'border-box'
								}}
							>
								<AgGridReact
									defaultColDef={defaultColDef}
									columnDefs={this.props.eventLog.colDefs}
									modules={AllModules}
									rowData={this.props.eventLog.eventLogs}
									enableStatusBar={true}
									alwaysShowStatusBar={false}
									enableRangeSelection={true}
								/>
							</div>
						</Segment>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column>
						{this.props.eventLog.aasdiStatusError ? (
							<Message icon error>
								<Icon name="x" />
								<Message.Content>
									<Message.Header>
										An error occured while loading AASDI statuses. Try refreshing the page, or
										report a bug if the problem the persists.
									</Message.Header>
								</Message.Content>
							</Message>
						) : null}
						<Segment
							style={{
								padding: '1rem',
								height: '75vh'
							}}
							loading={this.props.eventLog.aasdiStatusLoading}
						>
							<Label attached="top" color="blue">
								AASDI Status Log
							</Label>
							<div
								className="ag-theme-balham"
								style={{
									width: '100%',
									textAlign: 'left',
									height: '70vh',
									boxSizing: 'border-box'
								}}
							>
								<AgGridReact
									columnDefs={this.props.eventLog.aasdiStatusColDefs}
									modules={AllModules}
									rowData={this.props.eventLog.aasdiStatusRecords}
									enableStatusBar={true}
									alwaysShowStatusBar={false}
									enableRangeSelection={true}
								/>
							</div>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

eventLog.propTypes = {
	workId: PropTypes.number,
	eventLog: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		workId: state.work.workRecord.view.id,
		eventLog: state.work.workRecord.view.eventLog
	};
};

export default connect(mapStateToProps, null)(eventLog);
