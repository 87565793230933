import React, {Component} from 'react';
import {Button, Popup, Label, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

//Whoever wrote this is going to cause us so much fucking headache when ag grid is updated.
//its relying on this.ptops.agGridReact, which is an undocumented api that goes away.
//not to mention the absolute fucking absurdity of grabbing props off the parent ag grid instance like this.

class ActionCellRenderer extends Component {
	render() {
		return this.props.data.clientStatusName ===
			('Waiting For Approval' || 'Inactive' || 'Closed Business' || 'Lost Job') &&
			this.props.agGridReact.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0 ? (
			this.props.data.clientStatusName === 'Waiting For Approval' ? (
				<div>
					<Popup
						trigger={
							<Button
								icon={
									this.props.data.workNumber
										? 'circle'
										: this.props.data.pastDue
										? 'time'
										: 'angle right'
								}
								style={{
									backgroundColor: this.props.data.workNumber
										? '#e8e8e8'
										: this.props.data.pastDue
										? 'salmon'
										: 'lightblue',
									padding: '.5em .5em .5em'
								}}
							/>
						}
						content={'This client is waiting for approval.'}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						style={{zIndex: 1}}
					/>
					<Popup
						trigger={
							<Link to={`/crm/dashboard/opportunity/${this.props.data.id}`}>
								<Button
									icon="linkify"
									style={{
										backgroundColor: '#e8e8e8',
										padding: '.5em .5em .5em',
										marginLeft: '.5em'
									}}
								/>
							</Link>
						}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						content="View Details"
					/>
				</div>
			) : (
				<div>
					<Popup
						trigger={
							<Button
								icon={this.props.data.pastDue ? 'time' : 'angle right'}
								style={{
									backgroundColor: '#e8e8e8',
									padding: '.5em .5em .5em'
								}}
							/>
						}
						content={'This record is inactive.'}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						style={{zIndex: 1}}
					/>
					<Popup
						trigger={
							<Link to={`/crm/dashboard/opportunity/${this.props.data.id}`}>
								<Button
									icon="linkify"
									style={{
										backgroundColor: '#e8e8e8',
										padding: '.5em .5em .5em',
										marginLeft: '.5em'
									}}
								/>
							</Link>
						}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						content="View Details"
					/>
				</div>
			)
		) : (
			<div>
				{/* First  */}
				<Popup
					trigger={
						<Button
							icon={
								this.props.data.workNumber || this.props.data.workStatusName === 'Needs Approval'
									? 'circle'
									: this.props.data.pastDue
									? 'time'
									: 'angle right'
							}
							style={{
								backgroundColor:
									this.props.data.workNumber || this.props.data.workStatusName === 'Needs Approval'
										? '#e8e8e8'
										: this.props.data.pastDue
										? 'salmon'
										: 'lightblue',
								padding: '.5em .5em .5em'
							}}
						/>
					}
					on={['hover', 'click']}
					flowing
					hoverable
					position="right center"
					style={{zIndex: 1}}
				>
					{this.props.agGridReact.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0 ? (
						this.props.data.workNumber || this.props.data.workStatusName === 'Needs Approval' ? (
							<p style={{margin: 0}}>No actions available</p>
						) : (
							<Popup
								trigger={
									<Label
										as={Button}
										style={{
											textAlign: 'center',
											fontSize: '1em'
										}}
										onClick={() => {
											this.props.data.clientStatusName === 'Customer'
												? this.props.agGridReact.props.toggleCreateProjectModal(
														this.props.data.id,
														this.props.agGridReact.props.location.indexOf(
															'/crm/dashboard/pipeline/grid-view'
														) >= 0
															? 'pipeline'
															: 'leads',
														this.props.data.name,
														this.props.data.clientName,
														this.props.data.clientId,
														this.props.data.accountExecutiveId,
														this.props.data.accountExecutiveName,
														this.props.data.revenue,
														this.props.data.serviceType,
														this.props.data.branchName
												  )
												: this.props.agGridReact.props.toggleCreateModal(
														this.props.data.id,
														this.props.agGridReact.props.location.indexOf(
															'/crm/dashboard/pipeline/grid-view'
														) >= 0
															? 'pipeline'
															: 'leads'
												  );
										}}
									>
										<Icon name="plus" color="green" style={{marginLeft: '0.5em'}} />
									</Label>
								}
								on={['hover', 'click']}
								flowing
								hoverable
								position="top center"
								content={
									this.props.data.clientStatusName === 'Prospect'
										? 'Create Customer'
										: this.props.data.clientStatusName === 'Customer'
										? 'Create Project'
										: null
								}
							/>
						)
					) : null}
					{this.props.agGridReact.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0 ? (
						this.props.data.workNumber || this.props.data.workStatusName === 'Needs Approval' ? null : (
							<Popup
								trigger={
									<Label
										as={Button}
										style={{
											textAlign: 'center',
											fontSize: '1em'
										}}
										onClick={() =>
											this.props.agGridReact.props.toggleSendToModal(
												this.props.data.id,
												this.props.agGridReact.props.location.indexOf(
													'/crm/dashboard/pipeline/grid-view'
												) >= 0
													? 'pipeline'
													: 'leads',
												this.props.data.accountExecutiveId,
												this.props.data.projectedOrderDate
											)
										}
									>
										<Icon name="share" color="blue" style={{marginLeft: '0.5em'}} />
									</Label>
								}
								on={['hover', 'click']}
								flowing
								hoverable
								position="top center"
								content="Send Back to Leads"
							/>
						)
					) : (
						<Popup
							trigger={
								<Label
									as={Button}
									style={{
										textAlign: 'center',
										fontSize: '1em'
									}}
									onClick={() =>
										this.props.agGridReact.props.toggleSendToModal(
											this.props.data.id,
											this.props.agGridReact.props.location.indexOf(
												'/crm/dashboard/pipeline/grid-view'
											) >= 0
												? 'pipeline'
												: 'leads',
											this.props.data.accountExecutiveId,
											this.props.data.projectedOrderDate
										)
									}
								>
									<Icon name="share" color="blue" style={{marginLeft: '0.5em'}} />
								</Label>
							}
							on={['hover', 'click']}
							flowing
							hoverable
							position="top center"
							content="Send To Pipeline"
						/>
					)}
					{this.props.data.workNumber || this.props.data.workStatusName === 'Needs Approval' ? null : (
						<Popup
							trigger={
								<Label
									as={Button}
									style={{
										textAlign: 'center',
										fontSize: '1em'
									}}
									onClick={() => {
										this.props.agGridReact.props.toggleDeactivateModal(
											this.props.data.id,
											this.props.agGridReact.props.location.indexOf(
												'/crm/dashboard/pipeline/grid-view'
											) >= 0
												? 'pipeline'
												: 'leads'
										);
									}}
								>
									<Icon name="ban" color="red" style={{marginLeft: '0.5em'}} />
								</Label>
							}
							on={['hover', 'click']}
							flowing
							hoverable
							position="top center"
							content="Deactivate"
						/>
					)}
					{this.props.data.workNumber || this.props.data.workStatusName === 'Needs Approval' ? null : (
						<Popup
							trigger={
								<Label
									as={Button}
									onClick={() => {
										this.props.agGridReact.props.toggleLostJobModal(
											this.props.data.id,
											this.props.agGridReact.props.location.indexOf(
												'/crm/dashboard/pipeline/grid-view'
											) >= 0
												? 'pipeline'
												: 'leads'
										);
									}}
									style={{
										textAlign: 'center',
										fontSize: '1em'
									}}
								>
									<Icon color="orange" name="warning" style={{marginLeft: '0.5em'}} />
								</Label>
							}
							on={['hover', 'click']}
							flowing
							hoverable
							position="top center"
							content="Lost Job"
						/>
					)}
					{this.props.data.workNumber || this.props.data.workStatusName === 'Needs Approval' ? null : this
							.props.data.isCommit ? (
						<Popup
							trigger={
								<Label
									as={Button}
									onClick={() => {
										this.props.agGridReact.props.toggleCommitModal();
										this.props.agGridReact.props.setOpportunityData(this.props.data);
									}}
									style={{
										textAlign: 'center',
										fontSize: '1em'
									}}
								>
									<Icon color="red" name="history" style={{marginLeft: '0.5em'}} />
								</Label>
							}
							on={['hover', 'click']}
							flowing
							hoverable
							position="top center"
							content="Decommit"
						/>
					) : (
						<Popup
							trigger={
								<Label
									as={Button}
									onClick={() => {
										this.props.agGridReact.props.toggleCommitModal();
										this.props.agGridReact.props.setOpportunityData(this.props.data);
									}}
									style={{
										textAlign: 'center',
										fontSize: '1em'
									}}
								>
									<Icon color="purple" name="history" style={{marginLeft: '0.5em'}} />
								</Label>
							}
							on={['hover', 'click']}
							flowing
							hoverable
							position="top center"
							content="Commit"
						/>
					)}
				</Popup>
				<Popup
					trigger={
						<Link to={`/crm/dashboard/opportunity/${this.props.data.id}`}>
							<Button
								icon="linkify"
								style={{
									backgroundColor: '#e8e8e8',
									padding: '.5em .5em .5em',
									marginLeft: '.5em'
								}}
							/>
						</Link>
					}
					on={['hover', 'click']}
					flowing
					hoverable
					position="right center"
					content="View Details"
				/>
				{this.props.data.clientStatusName === 'Customer' ? (
					<Popup
						trigger={
							<Icon
								name="address card"
								color={'blue'}
								size="big"
								style={{
									// padding: '.5em .5em .5em',
									marginBottom: '.5vh'
								}}
							/>
						}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						content="Customer"
					/>
				) : this.props.data.clientStatusName === 'Prospect' ? (
					<Popup
						trigger={
							<Icon
								name="address book"
								color={'teal'}
								size="big"
								style={{
									// padding: '.5em .5em .5em',
									margin: '0 0 .5vh 0'
								}}
							/>
						}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						content="Prospect"
					/>
				) : null}
			</div>
		);
	}
}

ActionCellRenderer.propTypes = {
	agGridReact: PropTypes.object,
	data: PropTypes.object
};

export default ActionCellRenderer;
