import React, {Component} from 'react';
import {Label, Segment, Grid, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../../../../components/Global/AgGrid.js';
import FilterGrid from '../../../../../../components/Global/FilterGrid.js';
import ExportGrid from '../../../../../../components/Global/ExportGrid.js';
import CreateRatingModal from '../../../../../../components/Global/CreateRatingModal.js';

import {
	toggleCreateRatingModal,
	toggleFetchingOnTabsNavbar
} from '../../../../../../components/Global/services/globalActions.js';
import {checkIdenticalsArrays} from '../../../../../../lib/old/validation.js';
import {RatingsTabHeader} from '../services/profileAasdiGridReducer.js';
import {getTabRequestItems, clearGrid} from '../services/profileAasdiGridActions.js';

class Ratings extends Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps) {
		const {profileAasdiGridData, profileAasdiGridHeader, toggleFetchingOnTabsNavbar} = this.props;
		if (prevProps.profileAasdiGridData === null && profileAasdiGridData) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.profileAasdiGridHeader, profileAasdiGridHeader)) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.rowData, profileAasdiGridData)) {
			toggleFetchingOnTabsNavbar(false);
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();

		const {aasdiId} = this.props;

		if (
			this.props.profileAasdiGridData &&
			this.props.profileAasdiGridData.length &&
			Number(aasdiId) === this.props.profileAasdiGridData[0].aasdiId &&
			checkIdenticalsArrays(this.props.profileAasdiGridHeader, RatingsTabHeader)
		) {
			return;
		}

		this.props.clearGrid('Ratings');
		this.gridApi.showLoadingOverlay();
		this.props.getTabRequestItems(aasdiId, 'Ratings');
		this.props.toggleFetchingOnTabsNavbar(true);
	};

	render() {
		const {profileAasdiGridData, profileAasdiGridHeader, aasdiId} = this.props;
		return (
			<div>
				<Segment basic style={{marginTop: '1.5rem', border: '1px solid #DEDEDE'}} loading={this.props.loading}>
					<Label color="blue" ribbon>
						Ratings
					</Label>
					<Button
						basic
						color="blue"
						content="Add Rating"
						floated="right"
						icon="add"
						size="tiny"
						disabled={!profileAasdiGridData || (profileAasdiGridData && profileAasdiGridData.length < 1)}
						onClick={() => this.props.toggleCreateRatingModal(true)}
					/>
					<Grid style={{padding: '2em 0'}}>
						<FilterGrid gridApi={() => this.gridApi} />
						<ExportGrid gridApi={() => this.gridApi} />
					</Grid>
					<AgGrid
						columnDefs={profileAasdiGridHeader}
						rowData={profileAasdiGridData}
						onGridReady={this.onGridReady}
					/>
				</Segment>
				<CreateRatingModal id={aasdiId} />
			</div>
		);
	}
}

Ratings.propTypes = {
	getTabRequestItems: PropTypes.func.isRequired,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	profileAasdiGridData: PropTypes.array.isRequired,
	profileAasdiGridHeader: PropTypes.array.isRequired,
	aasdiId: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	toggleCreateRatingModal: PropTypes.func,
	clearGrid: PropTypes.func
};

const mapStateToProps = (state) => ({
	profileAasdiGridData: state.work.aasdiProfileGrid.rowData,
	profileAasdiGridHeader: state.work.aasdiProfileGrid.headerData,
	loading: state.work.aasdiProfileGrid.loading
});

const mapDispatchToProps = (dispatch) => ({
	toggleCreateRatingModal: (status) => dispatch(toggleCreateRatingModal(status)),
	getTabRequestItems: (aasdiId, item) => dispatch(getTabRequestItems(aasdiId, item)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	clearGrid: (item) => dispatch(clearGrid(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(Ratings);
