// Modules
import React from 'react';
import {Header, Icon, Input, Grid, Dropdown, Label, Popup, Button} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {updateOpportunityColumn, getAccountExecs, getClients} from '../services/opportunityActions.js';
import {
	toggleCreateClientModal,
	resetClientUpdate
} from '../../../../../../work/scenes/client/services/clientGridActions.js';
import CreateClientModal from '../../../../../../work/scenes/client/components/CreateClientModal.js';
import {debounce, debounceEventHandler} from '../../../../../../../lib/old/debounce.js';
import conversionTypeOptions from '../../../../../../../constants/conversionTypes.js';
import {MomentDatePicker} from '../../../../../../../components/inputs/DatePicker.jsx';

class OppHeader extends React.Component {
	componentWillUnmount() {
		const {id, updateOpportunityColumn} = this.props;
		updateOpportunityColumn(id, 'accountExecutiveId', '', '');
	}

	componentDidUpdate(prevProps) {
		const {createClientModalUpdate, updateOpportunityColumn, id, clientUpdated, resetClientUpdate} = this.props;
		if (!prevProps.createClientModalUpdate && createClientModalUpdate) {
			clientUpdated(createClientModalUpdate, id);
			updateOpportunityColumn(id, 'clientId', createClientModalUpdate.id);
			resetClientUpdate();
		}
	}

	render() {
		const {
			id,
			name,
			clientId,
			accountExecutiveId,
			accountExecutiveName,
			updateOpportunityColumn,
			isLead,
			validation,
			getAccountExecs,
			accountExecutiveLoading,
			accountExecutiveOptions,
			clientName,
			clientOptions,
			clientLoading,
			getClients,
			toggleCreateClientModal,
			startDate,
			endDate,
			projectedOrderDate,
			bidDueDate,
			conversionTypeId
		} = this.props;
		return (
			<Grid centered columns={15}>
				<Grid.Column floated="left" width={4}>
					<Header as="h3">
						<Icon name={isLead ? 'road' : 'folder open outline'} />
						<Header.Content style={{width: '100%'}}>
							{isLead ? 'Lead' : 'Opportunity'}
							<Header.Subheader>
								<DebounceInput
									element={Input}
									fluid
									className="hiddenInput"
									placeholder={`Give the ${isLead ? 'lead' : 'opportunity'} a name.`}
									debounceTimeout={600}
									value={name}
									onChange={(event) => {
										if (id === 'newOpp' || id === 'newLead') {
											updateOpportunityColumn('update', 'name', event.target.value);
										} else {
											updateOpportunityColumn(id, 'name', event.target.value);
										}
									}}
									onKeyPress={(event) => {
										if (event.key === 'Enter') {
											updateOpportunityColumn(id, 'name', event.target.value);
										}
									}}
								/>
								{validation.name ? (
									<Label basic color="red" pointing className="crm">
										Enter a name!
									</Label>
								) : null}
							</Header.Subheader>
						</Header.Content>
					</Header>
				</Grid.Column>
				<Grid.Column width={2} style={{paddingTop: '20px'}}>
					<Header size="tiny">
						Conversion Type
						<Dropdown
							fluid
							className="hiddenInput"
							placeholder="Conversion"
							options={conversionTypeOptions}
							value={conversionTypeId}
							onChange={(e, {value, options}) => {
								if (e.type === 'blur') {
									return;
								}
								updateOpportunityColumn(id, 'conversionTypeId', value);
							}}
						/>
					</Header>
				</Grid.Column>

				<Grid.Column width={2}>
					<Header size="tiny">
						Start Date
						<Input fluid className="hiddenInput">
							<MomentDatePicker
								// not sure why it's required to add one day here. The correct date is being saved to the db, but the wrong date is being displayed without adding the extra day here.
								selected={startDate ? moment(startDate).add(1, 'days') : null}
								placeholderText="Select a date"
								onChange={(date) =>
									updateOpportunityColumn(
										id,
										'startDate',
										date ? date.format('MM-DD-YYYY').toString() : null
									)
								}
							/>
						</Input>
					</Header>
				</Grid.Column>
				<Grid.Column width={2}>
					<Header size="tiny">
						End Date
						<Input fluid className="hiddenInput">
							<MomentDatePicker
								selected={endDate ? moment(endDate).add(12, 'hours') : null}
								placeholderText="Select a date"
								onChange={(date) =>
									updateOpportunityColumn(
										id,
										'endDate',
										date ? date.format('MM-DD-YYYY').toString() : null
									)
								}
								minDate={startDate ? moment(startDate).utc() : null}
							/>
						</Input>
						{validation.endDate ? (
							<Label basic color="red" pointing className="crm">
								Enter end date greater or equal start date!
							</Label>
						) : null}
					</Header>
				</Grid.Column>
				<Grid.Column floated="right" width={2}>
					<Header size="tiny">
						Client Name
						<Dropdown
							fluid
							className="hiddenInput"
							placeholder="Search for a client"
							search
							loading={clientLoading}
							options={
								clientOptions.length > 0
									? clientOptions
									: [
											{
												key: clientId,
												text: clientName,
												value: clientId
											}
									  ]
							}
							value={clientId}
							onChange={(e, {value, options}) => {
								if (clientId !== value) {
									updateOpportunityColumn(
										id,
										'clientId',
										value,
										options[options.findIndex((option) => option.key === value)].text
									);
								}
							}}
							onSearchChange={debounceEventHandler(
								debounce((e) => {
									if (e.target.value) {
										getClients('clientSearch', e.target.value);
									}
								}, 600)
							)}
						/>
						{validation.clientId ? (
							<Label basic color="red" pointing className="crm">
								Select a client!
							</Label>
						) : null}
					</Header>
				</Grid.Column>
				<Grid.Column floated="right" width={1}>
					<Popup
						content="Add Client"
						trigger={
							<Button
								style={{
									display: 'inline-block',
									marginLeft: '-15px',
									width: '3rem',
									textAlign: 'center',
									lineHeight: '1.1'
								}}
								attached="right"
								color="green"
								icon="plus"
								onClick={() => toggleCreateClientModal(true)}
							/>
						}
					/>
					<CreateClientModal />
				</Grid.Column>
				<Grid.Column floated="right" width={3}>
					<Header size="tiny">
						<Icon name="user circle" />
						<Header.Content>
							Account Executive
							<Dropdown
								className="hiddenInput"
								placeholder="Account Exec"
								search
								fluid
								loading={accountExecutiveLoading}
								options={
									accountExecutiveOptions.length > 0
										? accountExecutiveOptions
										: [
												{
													key: accountExecutiveId,
													text: accountExecutiveName,
													value: accountExecutiveId
												}
										  ]
								}
								value={accountExecutiveId}
								onChange={(e, {value, options}) => {
									if (accountExecutiveId !== value) {
										updateOpportunityColumn(
											id,
											'accountExecutiveId',
											value,
											options[options.findIndex((option) => option.key === value)].text
										);
									}
								}}
								onSearchChange={debounceEventHandler(
									debounce((e) => {
										if (e.target.value) {
											getAccountExecs('accountExecutiveSearch', e.target.value);
										}
									}, 600)
								)}
							/>
							{validation.accountExecutiveId ? (
								<Label basic color="red" className="crm" pointing>
									Select an AE!
								</Label>
							) : null}
						</Header.Content>
					</Header>
				</Grid.Column>
			</Grid>
		);
	}
}

OppHeader.propTypes = {
	id: PropTypes.string,
	isLead: PropTypes.bool,
	name: PropTypes.string,
	validation: PropTypes.object,
	accountExecutiveName: PropTypes.string,
	accountExecutiveId: PropTypes.number,
	updateOpportunityColumn: PropTypes.func,
	clientName: PropTypes.string,
	accountExecutiveLoading: PropTypes.bool,
	accountExecutiveOptions: PropTypes.array,
	clientId: PropTypes.number,
	clientLoading: PropTypes.bool,
	createClientModalUpdate: PropTypes.func,
	clientOptions: PropTypes.array,
	clientUpdated: PropTypes.func,
	endDate: PropTypes.string,
	getAccountExecs: PropTypes.func,
	getClients: PropTypes.func,
	resetClientUpdate: PropTypes.func,
	startDate: PropTypes.string,
	toggleCreateClientModal: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		id: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.id : null,
		clientId: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.clientId : null,
		clientName: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.clientName : null,
		clientOptions: state.crm.opportunity.data.clientSearch ? state.crm.opportunity.data.clientSearch.options : null,
		isLead: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.isLead : null,
		name: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.name : null,
		accountExecutiveName: state.crm.opportunity.data.fetched
			? state.crm.opportunity.data.accountExecutiveName
			: null,
		accountExecutiveId: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.accountExecutiveId : null,
		validation: state.crm.opportunity.data.validation,
		accountExecutiveOptions: state.crm.opportunity.data.accountExecutiveSearch
			? state.crm.opportunity.data.accountExecutiveSearch.options
			: null,
		accountExecutiveLoading: state.crm.opportunity.data.accountExecutiveSearch
			? state.crm.opportunity.data.accountExecutiveSearch.loading
			: null,
		clientLoading: state.crm.opportunity.data.clientSearch ? state.crm.opportunity.data.clientSearch.loading : null,
		startDate: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.startDate : null,
		endDate: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.endDate : null,
		bidDueDate: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.bidDueDate : null,
		conversionTypeId: state.crm.opportunity.data.conversionTypeId
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateOpportunityColumn: (id, column, value, name) => {
			dispatch(updateOpportunityColumn(id, column, value, name));
		},
		getAccountExecs: (column, value) => {
			dispatch(getAccountExecs(column, value));
		},
		getClients: (column, value) => {
			dispatch(getClients(column, value));
		},
		toggleCreateClientModal: (status) => {
			dispatch(toggleCreateClientModal(status));
		},
		resetClientUpdate: () => {
			dispatch(resetClientUpdate());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OppHeader);
