import React from 'react';
import {Segment, Grid, Header, Input, Icon, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../../../../../services/workRecordActions.js';

import {debounce, debounceEventHandler} from '../../../../../../../../../../../lib/old/debounce.js';

class infoSection extends React.Component {
	render() {
		let selectedLocation = this.props.location.options.filter(
			(location) => location.name === this.props.location.value.name
		)[0];

		let selectedBillingContact = this.props.billingContact.options.filter(
			(contact) => contact.fullName === this.props.billingContact.value.name
		)[0];

		return (
			<Grid.Column style={{margin: '1vh'}} width={this.props.item.columnWidth}>
				<Segment basic loading={this.props.item.loading}>
					<Grid>
						<Grid.Row>
							<Grid.Column width={3}>
								<Header as="h3">
									<Icon
										name={this.props.item.name === 'Address' ? 'map pin' : 'user circle outline'}
									/>
									<Header.Content>
										{this.props.item.name === 'Address' ? 'Location' : this.props.item.name}
									</Header.Content>
								</Header>
							</Grid.Column>
							<Grid.Column width={11}>
								<Dropdown
									fluid
									search
									loading={this.props.item.inputLoading}
									className="hiddenInput"
									placeholder={'Search by name...'}
									style={{
										marginTop: '.75vh',
										paddingBottom: '0'
									}}
									options={this.props.item.options}
									onSearchChange={debounceEventHandler(
										debounce((e) => {
											if (e.target.value) {
												this.props.getDropdownOptions(
													this.props.item.name,
													this.props.record.clientId,
													e.target.value
												);
											}
										}, 600)
									)}
									onChange={(e, data) => {
										if (this.props.item.name === 'Address' && selectedLocation) {
											let findOption = data.options.filter(
												(option) =>
													option.name === selectedLocation.name &&
													option.storeNumber === selectedLocation.storeNumber
											);
											if (findOption.id === data.value) {
												console.log(`Option aleady selected: ${selectedLocation.name}`);
												// Don't fire PUT
												return;
											}
										}
										if (this.props.item.name === 'Contact' && selectedBillingContact) {
											let findOption = data.options.filter(
												(option) => option.fullName === selectedBillingContact.fullName
											)[0];
											if (findOption.id === data.value) {
												console.log(`Option already selected: ${selectedBillingContact.name}`);
												// Don't fire PUT
												return;
											}
										}
										this.props.setOption(
											this.props.item.name,
											this.props.record,
											data.options,
											data.value
										);
									}}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{this.props.item.name === 'Address' ? (
						<Segment>
							<Grid>
								<Grid.Column width={4}>
									<Header as="h5">
										<Header.Content>Name</Header.Content>
									</Header>
									<Input fluid disabled transparent value={this.props.item.value.name} />
								</Grid.Column>
								<Grid.Column width={6}>
									<Header as="h5">
										<Header.Content>Address</Header.Content>
									</Header>
									<Input fluid disabled transparent value={this.props.item.value.address} />
								</Grid.Column>
								<Grid.Column width={6}>
									<Header as="h5">
										<Header.Content>City</Header.Content>
									</Header>
									<Input fluid disabled transparent value={this.props.item.value.city} />
								</Grid.Column>
							</Grid>
						</Segment>
					) : (
						<Segment>
							<Grid>
								<Grid.Column width={6}>
									<Header as="h5">
										<Header.Content>Name</Header.Content>
									</Header>
									<Input
										fluid
										disabled
										transparent
										value={this.props.item.value ? this.props.item.value.name : null}
									/>
								</Grid.Column>
								<Grid.Column width={3}>
									<Header as="h5">
										<Header.Content>Phone</Header.Content>
									</Header>
									<Input
										fluid
										disabled
										transparent
										value={this.props.item.value ? this.props.item.value.phone : null}
									/>
								</Grid.Column>
								<Grid.Column width={7}>
									<Header as="h5">
										<Header.Content>email</Header.Content>
									</Header>
									<Input
										fluid
										disabled
										transparent
										value={this.props.item.value ? this.props.item.value.email : null}
									/>
								</Grid.Column>
							</Grid>
						</Segment>
					)}
				</Segment>
			</Grid.Column>
		);
	}
}

infoSection.propTypes = {
	item: PropTypes.object,
	record: PropTypes.object,
	setOption: PropTypes.func,
	getDropdownOptions: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		record: state.work.workRecord.view.record,
		location: state.work.workRecord.view.overview.orderDetails.fields.filter(
			(field) => field.name === 'Address'
		)[0],
		billingContact: state.work.workRecord.view.overview.orderDetails.fields.filter(
			(field) => field.name === 'Contact'
		)[0]
	};
};

export default connect(mapStateToProps, actions)(infoSection);
