import React from 'react';
import {Grid, Label, Divider, Step, Dropdown, Header, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {
	updateRegistrationForm,
	setActiveSplitStep,
	contactsAutoFillData,
	stepComplete
} from '../services/registrationActions.js';

import InputField from './components/inputField.js';

const Contacts = (props) => (
	<div>
		<Header as="h3">
			<Icon name="phone" />
			<Header.Content>Contacts</Header.Content>
		</Header>
		<div style={{paddingBottom: '3.5em'}}>
			<Step.Group widths={6} size="small">
				<Step
					title="President Info"
					active={props.activeSplitStep === 0}
					link={true}
					onClick={() => props.setActiveSplitStep(props.stepIndex, 0)}
				/>
				<Step
					title="Operations Manager Info"
					active={props.activeSplitStep === 1}
					link={true}
					onClick={() => props.setActiveSplitStep(props.stepIndex, 1)}
				/>
			</Step.Group>
			<div
				style={{
					textAlign: 'center',
					padding: '.3em 0em'
				}}
			>
				{props.stepDescription}
			</div>
		</div>
		{props.activeSplitStep === 0 ? (
			<Grid>
				<Grid.Row>
					<Grid.Column width={16}>
						<Label
							style={{
								backgroundColor: 'transparent',
								fontSize: '1rem',
								color: 'rgba(0,0,0,.8)',
								paddingLeft: '0'
							}}
						>
							{props.contactsPresidentAutoFill.name}
						</Label>
						<Dropdown
							className="createModalDropdown"
							placeholder={props.contactsPresidentAutoFill.placeholder}
							fluid
							search
							selection
							options={props.contactsPresidentAutoFill.options}
							onChange={(e, {value}) => {
								props.updateRegistrationForm(
									'registrationForm',
									props.stepIndex,
									'contactsPresidentAutoFill',
									value,
									false
								);
								props.contactsAutoFillData(
									'registrationForm',
									'president',
									'contactsPresidentAutoFill',
									value
								);
								props.stepComplete(props.stepIndex);
							}}
							icon={props.contactsPresidentAutoFill.icon}
							value={props.contactsPresidentAutoFill.value}
							error={
								props.contactsPresidentAutoFill.required
									? Boolean(props.contactsPresidentAutoFill.error)
									: false
							}
							disabled={props.contactsPresidentAutoFill.disabled}
							upward={props.contactsPresidentAutoFill.upward}
							loading={props.contactsPresidentAutoFill.loading}
							defaultSearchQuery={props.contactsPresidentAutoFill.searchQuery}
						/>
					</Grid.Column>
					<Grid.Column width={16} style={{paddingTop: '.7em'}}>
						{props.contactsAutoFillErrorMessage.error ? props.contactsAutoFillErrorMessage.value : null}
					</Grid.Column>
				</Grid.Row>
				{props.contactsPresidentAutoFill.value === 1 ? null : (
					<Grid.Row>
						<Grid.Column
							width={8}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
						>
							<InputField
								fieldLabel="contactsPresidentFirstName"
								field={props.contactsPresidentFirstName}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>
						<Grid.Column
							width={8}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
						>
							<InputField
								fieldLabel="contactsPresidentLastName"
								field={props.contactsPresidentLastName}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>
						<Grid.Column
							width={16}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
						>
							<InputField
								fieldLabel="contactsPresidentEmail"
								field={props.contactsPresidentEmail}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>
						<Divider horizontal style={{padding: '2em 0em 3em 0em'}}>
							Optional
						</Divider>
						<Grid.Column width={16}>
							<InputField
								fieldLabel="contactsPresidentPhone"
								field={props.contactsPresidentPhone}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>
					</Grid.Row>
				)}
			</Grid>
		) : (
			<Grid>
				<Grid.Row>
					<Grid.Column width={16}>
						<Label
							style={{
								backgroundColor: 'transparent',
								fontSize: '1rem',
								color: 'rgba(0,0,0,.8)',
								paddingLeft: '0'
							}}
						>
							{props.contactsOperationsManagerAutoFill.name}
						</Label>
						<Dropdown
							className="createModalDropdown"
							placeholder={props.contactsOperationsManagerAutoFill.placeholder}
							fluid
							search
							selection
							options={props.contactsOperationsManagerAutoFill.options}
							onChange={(e, {value}) => {
								props.updateRegistrationForm(
									'registrationForm',
									props.stepIndex,
									'contactsOperationsManagerAutoFill',
									value,
									false
								);
								props.contactsAutoFillData(
									'registrationForm',
									'operations manager',
									'contactsOperationsManagerAutoFill',
									value
								);
								props.stepComplete(props.stepIndex);
							}}
							icon={props.contactsOperationsManagerAutoFill.icon}
							value={props.contactsOperationsManagerAutoFill.value}
							error={
								props.contactsOperationsManagerAutoFill.required
									? Boolean(props.contactsOperationsManagerAutoFill.error)
									: false
							}
							disabled={props.contactsOperationsManagerAutoFill.disabled}
							upward={props.contactsOperationsManagerAutoFill.upward}
							loading={props.contactsOperationsManagerAutoFill.loading}
							defaultSearchQuery={props.contactsOperationsManagerAutoFill.searchQuery}
						/>
					</Grid.Column>
					<Grid.Column width={16} style={{paddingTop: '.7em'}}>
						{props.contactsAutoFillErrorMessage.error ? props.contactsAutoFillErrorMessage.value : null}
					</Grid.Column>
				</Grid.Row>
				{props.contactsOperationsManagerAutoFill.value === 1 ? null : (
					<Grid.Row>
						<Grid.Column
							width={8}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
						>
							<InputField
								fieldLabel="contactsOperationsManagerFirstName"
								field={props.contactsOperationsManagerFirstName}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>
						<Grid.Column
							width={8}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
						>
							<InputField
								fieldLabel="contactsOperationsManagerLastName"
								field={props.contactsOperationsManagerLastName}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>
						<Grid.Column
							width={16}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
						>
							<InputField
								fieldLabel="contactsOperationsManagerEmail"
								field={props.contactsOperationsManagerEmail}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>
						<Divider horizontal style={{padding: '2em 0em 3em 0em'}}>
							Optional
						</Divider>
						<Grid.Column width={16}>
							<InputField
								fieldLabel="contactsOperationsManagerPhone"
								field={props.contactsOperationsManagerPhone}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>
					</Grid.Row>
				)}
			</Grid>
		)}
	</div>
);

const mapStateToProps = (state) => {
	return {
		stepIndex: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Contacts')[0].index,
		stepDescription: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Contacts')[0].step.description,
		activeSplitStep: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Contacts')[0].step.activeSplitStep,
		contactsAutoFillErrorMessage: state.registrationForm.registrationForm.fields.contactsAutoFillErrorMessage,
		contactsPresidentAutoFill: state.registrationForm.registrationForm.fields.contactsPresidentAutoFill,
		contactsPresidentFirstName: state.registrationForm.registrationForm.fields.contactsPresidentFirstName,
		contactsPresidentLastName: state.registrationForm.registrationForm.fields.contactsPresidentLastName,
		contactsPresidentEmail: state.registrationForm.registrationForm.fields.contactsPresidentEmail,
		contactsPresidentPhone: state.registrationForm.registrationForm.fields.contactsPresidentPhone,
		contactsOperationsManagerAutoFill:
			state.registrationForm.registrationForm.fields.contactsOperationsManagerAutoFill,
		contactsOperationsManagerFirstName:
			state.registrationForm.registrationForm.fields.contactsOperationsManagerFirstName,
		contactsOperationsManagerLastName:
			state.registrationForm.registrationForm.fields.contactsOperationsManagerLastName,
		contactsOperationsManagerEmail: state.registrationForm.registrationForm.fields.contactsOperationsManagerEmail,
		contactsOperationsManagerPhone: state.registrationForm.registrationForm.fields.contactsOperationsManagerPhone
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateRegistrationForm: (modal, step, column, value, error) => {
			dispatch(updateRegistrationForm(modal, step, column, value, error));
		},
		setActiveSplitStep: (stepIndex, targetStep) => {
			dispatch(setActiveSplitStep(stepIndex, targetStep));
		},
		contactsAutoFillData: (modal, type, column, value) => {
			dispatch(contactsAutoFillData(modal, type, column, value));
		},
		stepComplete: (stepIndex) => {
			dispatch(stepComplete(stepIndex));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
