import customFetch from '../../../lib/old/customFetch.js';
import {getBillingContacts} from '../../../scenes/crm/scenes/dashboard/scenes/CreateProject/services/createProjectActions.js';
// import {openContactConfirmationModal} from '../../contactConfirmationModal/services/contactConfirmationModalActions.js';

export const toggleBillingContactModal = (id, clientId) => {
	return (dispatch) => {
		dispatch({
			type: 'BILLING_CONTACT_MODAL_TOGGLE',
			payload: {
				id,
				clientId
			}
		});
	};
};

export const updateBillingContactModal = (modal, column, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'BILLING_CONTACT_MODAL_UPDATE',
			payload: {
				modal,
				column,
				value,
				error
			}
		});
	};
};

export const postContact = (clientId, type, payload) => {
	console.log('hit inside postContact');
	return async (dispatch, getState) => {
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}`;

		customFetch(endpoint, {
			method: 'Post',
			body: JSON.stringify(payload)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				// Fill Search Value on Create Project Modal
				let modal = 'createProjectModal';
				let sectionIndex = getState().crm.createProject.createProjectModal.sections.findIndex(
					(section) => section.name === 'billingDetailsSection'
				);
				let fieldIndex = getState().crm.createProject.createProjectModal.sections[
					sectionIndex
				].fields.findIndex((field) => field.name === 'Billing Contact');
				let error = false;
				let stringValue = `${payload.firstName} ${payload.lastName}`;

				payload.id = json.id;

				if (type === 'project') {
					dispatch({
						type: 'CREATE_PROJECT_MODAL_UPDATE',
						payload: {
							modal,
							sectionIndex,
							fieldIndex,
							value: stringValue,
							error
						}
					});

					json.fullName = stringValue;

					dispatch({
						type: 'CREATE_PROJECT_MODAL_GET_DATALIST_OPTIONS',
						payload: {
							value: stringValue,
							sectionIndex,
							fieldIndex,
							json: [json]
						}
					});
				}
				if (type === 'workOrder') {
					// Call Get & Fill Search Value
					dispatch(
						getBillingContacts(
							sectionIndex,
							fieldIndex,
							clientId,
							`${payload.firstName} ${payload.lastName}`,
							error,
							'newContact'
						)
					);

					let sectionIndexWorkOrder = getState().work.createWorkOrder.createWorkOrderModal.sections.findIndex(
						(section) => section.name === 'workOrderOverviewSection'
					);
					let fieldIndexWorkOrder = getState().work.createWorkOrder.createWorkOrderModal.sections[
						sectionIndexWorkOrder
					].fields.findIndex((field) => field.name === 'Billing Contact');

					dispatch({
						type: 'CREATE_WORK_ORDER_GET_BILLING_CONTACTS_DROPDOWN_OPTIONS',
						payload: {
							sectionIndex: sectionIndexWorkOrder,
							fieldIndex: fieldIndexWorkOrder,
							value: json.id,
							json: [payload]
						}
					});
					dispatch({
						type: 'CREATE_WORK_ORDER_MODAL_BILLING_CONTACT_UPDATE',
						payload: {
							sectionIndex: sectionIndexWorkOrder,
							fieldIndex: fieldIndexWorkOrder,
							value: json.id,
							contactObject: payload,
							error
						}
					});
				}
				dispatch(toggleBillingContactModal(null, null));
				dispatch({
					type: 'BILLING_CONTACT_MODAL_LOADING',
					payload: {
						state: null
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'BILLING_CONTACT_MODAL_LOADING',
					payload: {
						state: 'error'
					}
				});
			});
	};
};

export const createBillingContact = (oppId, clientId, modalFields, type, dupCheckRequired) => {
	return async (dispatch, getState) => {
		const state = getState().global.billingContactModal.billingContactModal;

		// // POST variables
		let payload = {
			clientId: clientId,
			firstName: modalFields[modalFields.findIndex((section) => section.name === 'First Name')].value,
			lastName: modalFields[modalFields.findIndex((section) => section.name === 'Last Name')].value,
			title: modalFields[modalFields.findIndex((section) => section.name === 'Title')].value,
			email: modalFields[modalFields.findIndex((section) => section.name === 'Email')].value,
			extension: modalFields[modalFields.findIndex((section) => section.name === 'Ext')].value,
			phone: modalFields[modalFields.findIndex((section) => section.name === 'Phone')].value,
			isBillingContact: true
		};
		// // Check for required fields
		let emptyRequiredFields;
		let checkFields;
		emptyRequiredFields = state.modalFields
			.map((field, fieldIndex) => {
				field.FieldIndex = fieldIndex;
				return field;
			})
			.filter((modalField) => modalField.required && !modalField.value);
		checkFields = emptyRequiredFields.length > 0;
		// Run check on required fields
		if (checkFields) {
			dispatch({
				type: 'BILLING_CONTACT_MODAL_CHECK_REQUIRED_FIELDS',
				payload: {
					emptyRequiredFields
				}
			});
			return;
		}
		dispatch({
			type: 'BILLING_CONTACT_MODAL_LOADING',
			payload: {
				state: 'progress'
			}
		});

		// runs check if similiar contacts already exists
		// if (dupCheckRequired) {
		// 	try {
		// 		const call = await customFetch(
		// 			`${process.env.REACT_APP_API_URL}/legacy/contacts?validate=1&clientId=${clientId}&contact=${JSON.stringify(
		// 				payload
		// 			)}`
		// 		);
		// 		const fuzzyMatch = await call.json();
		// 		console.log('Fuzzy Match = ', JSON.stringify(fuzzyMatch));

		// 		// if there are no matches then create the contact
		// 		if (fuzzyMatch.length < 1) {
		// 			dispatch(postContact(clientId, type, payload));
		// 		} else {
		// 			// else open contact confirmation modal
		// 			dispatch(openContactConfirmationModal(payload, fuzzyMatch, clientId, type));
		// 		}
		// 	} catch (err) {
		// 		return Promise.reject(err);
		// 	}
		// } else {
		dispatch(postContact(clientId, type, payload));
		// }
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'BILLING_CONTACT_MODAL_LOADING',
			payload: {
				state: value
			}
		});
	};
};
