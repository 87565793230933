import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Segment, Icon, Button, Menu, Popup, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
	aasdiZipSearch,
	clearTripOptions,
	deletedSelectedTrip,
	getTripOptions,
	getWorkViewTrip,
	markTripAsComplete,
	removeAASDI,
	selectAASDI,
	setTripIdCheck,
	setWorkAasdiIdCheck,
	toggleAASDIModal,
	toggleNoTrips
} from './../../services/workRecordActions.js';
import {selectAasdiStatusDescription} from '../../services/workRecordSelectors.js';
import AasdiInfoSection from './component/aasdiInfoSection.js';
import AASDIModal from './modals/addAASDIModal.js';
import DetailAASDIModal from './modals/aasdiDetailModal.js';
import TripsDropdown from './component/tripsDropdown.js';
import CreateTripModal from './modals/createTripModal.js';

class ManageAASDI extends React.Component {
	updateName = (name) => {
		return null;
	};

	async componentDidMount() {
		this.props.aasdiZipSearch(this.props.zip);
		this.props.clearTripOptions();
		if (this.props.aasdiExists) {
			await this.props.getTripOptions(this.props.selectedAASDI.workAasdiId, 'firstMount');
			await this.props.setWorkAasdiIdCheck(this.props.selectedAASDI.workAasdiId);
			await this.props.getWorkViewTrip(this.props.selectedTripId, 'manageAasdiMount');
			await this.props.setTripIdCheck(this.props.selectedTripId);
		}
	}

	async componentDidUpdate() {
		// TODO: Fix bug that causes the trip to fail to load. The reason it fails to load is because
		// of the if statement below. Occasionally it will pass the first test but contactLoading will be true
		if (this.props.aasdiExists) {
			if (this.props.workAasdiIdCheck !== this.props.selectedWorkAasdiId && this.props.contactLoading === false) {
				this.props.setWorkAasdiIdCheck(this.props.selectedAASDI.workAasdiId);
				this.props.getTripOptions(this.props.selectedAASDI.workAasdiId);
				await this.props.setTripIdCheck(this.props.selectedTripId);
			}
		}
	}

	render() {
		let trip =
			this.props.manageAASDI.tripOptions.length > 0
				? this.props.manageAASDI.tripOptions.find((trip) => trip.id === this.props.selectedTripId)
				: null;
		let isCompleted = trip ? trip.isCompleted : null;

		return (
			<Segment basic loading={this.props.manageAASDI.loading}>
				<Menu tabular attached="top">
					{this.props.aasdiArray.map((aasdi, index) => {
						return (
							<Menu.Item
								key={index}
								className="workView"
								active={aasdi.id === this.props.selectedAASDI.id}
								color={'blue'}
								onClick={async () => {
									this.props.selectAASDI(index, this.props.aasdiArray);
									if (this.props.noTrip) {
										this.props.toggleNoTrips();
									}
								}}
								style={{padding: '0', margin: 0}}
							>
								<Button
									basic={(aasdi.id !== this.props.selectedAASDI.id).toString()}
									color="blue"
									fluid
									style={{
										margin: 0,
										padding: '1vh .25vh 1vh 1vh'
									}}
									attached="top"
								>
									{aasdi.id === this.props.selectedAASDI.id ? (
										<div
											style={{
												marginLeft: '.5vw',
												minWidth: '8vw'
											}}
										>
											{aasdi.name}
											<Popup
												trigger={
													<Link
														to={`/work/dashboard/aasdi/profile/${aasdi.id}`}
														target="_blank"
													>
														<Button
															icon="linkify"
															style={{
																marginLeft: '1vw',
																padding: '.4vh'
															}}
														/>
													</Link>
												}
												content="View AASDI Info"
											/>
											<Popup
												trigger={
													<Button
														// floated="right"
														color="red"
														size="small"
														onClick={() => {
															this.props.removeAASDI(
																this.props.id,
																this.props.selectedAASDI.id
															);
														}}
														style={{
															padding: '.4vh'
														}}
													>
														<Icon
															name="trash alternate"
															style={{
																margin: '0'
															}}
														/>
													</Button>
												}
												content="Remove this AASDI"
											/>
										</div>
									) : (
										<div style={{width: '6.5vw'}}>
											{aasdi.name.length >= 15 ? `${aasdi.name.substr(0, 12)}...` : aasdi.name}
										</div>
									)}
								</Button>
							</Menu.Item>
						);
					})}

					<Menu.Item position="right" style={{padding: 0, marginBottom: '1vh'}}>
						<Button
							color="green"
							floated="right"
							onClick={() => {
								this.props.toggleAASDIModal(true);
							}}
						>
							<Icon name="plus" />
							Add AASDI
						</Button>
					</Menu.Item>
				</Menu>
				<TripsDropdown />
				{this.props.selectedAASDI && this.props.selectedTripId && this.props.manageAASDI.aasdis.length > 0 ? (
					<React.Fragment>
						<Popup
							style={{margin: '10px'}}
							content="Delete Trip"
							trigger={
								<Button
									color="red"
									size="small"
									icon="trash alternate"
									onClick={() => {
										this.props.deletedSelectedTrip();
									}}
								/>
							}
						/>
						<Popup
							style={{margin: '10px'}}
							content="Mark Trip As Complete"
							trigger={
								<Button
									color={isCompleted ? 'green' : 'white'}
									disabled={Boolean(isCompleted)}
									size="small"
									icon="check circle outline"
									onClick={() =>
										this.props.markTripAsComplete(this.props.id, this.props.selectedTripId)
									}
								/>
							}
						/>
						{this.props.aasdiStatusDescription ? (
							<p>
								<Label color="blue">AASDI Status: {this.props.aasdiStatusDescription}</Label>
							</p>
						) : null}
					</React.Fragment>
				) : null}
				{this.props.isDesynced ? (
					<Segment inverted color="yellow" style={{margin: '1vh'}}>
						<Segment style={{textAlign: 'center'}}>
							<p>
								This section will work properly, but the information will not be in Spectrum until the
								desynchronization is resolved.
							</p>
						</Segment>
					</Segment>
				) : null}
				{this.props.selectedAASDI ? (
					<div>
						<DetailAASDIModal
							id={this.props.selectedAASDI.id}
							name={this.props.selectedAASDI.name}
							updateName={this.updateName}
						/>
						{this.props.selectedTripId ? (
							<AasdiInfoSection selectedAASDI={this.props.selectedAASDI} />
						) : (
							<Segment style={{margin: '0'}}>
								<Segment>
									<p>No Trips Created</p>
								</Segment>
							</Segment>
						)}
					</div>
				) : (
					<Segment style={{margin: '0'}}>
						<Segment>
							<p>No AASDI Selected</p>
						</Segment>
					</Segment>
				)}
				<AASDIModal zip={this.props.zip} />
				<CreateTripModal />
			</Segment>
		);
	}
}

ManageAASDI.propTypes = {
	aasdiArray: PropTypes.array,
	aasdiZipSearch: PropTypes.func,
	selectedAASDI: PropTypes.object,
	selectAASDI: PropTypes.func,
	toggleAASDIModal: PropTypes.func,
	manageAASDI: PropTypes.object,
	removeAASDI: PropTypes.func,
	id: PropTypes.string,
	zip: PropTypes.string,
	noTrip: PropTypes.bool,
	deletedSelectedTrip: PropTypes.func,
	markTripAsComplete: PropTypes.func,
	selectedTripId: PropTypes.number,
	toggleNoTrips: PropTypes.func,
	clearTripOptions: PropTypes.func,
	getTripOptions: PropTypes.func,
	setWorkAasdiIdCheck: PropTypes.func,
	getWorkViewTrip: PropTypes.func,
	setTripIdCheck: PropTypes.number,
	workAasdiIdCheck: PropTypes.number,
	selectedWorkAasdiId: PropTypes.number,
	contactLoading: PropTypes.bool,
	aasdiExists: PropTypes.func,
	isDesynced: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => {
	return {
		aasdiZipSearch: (zip) => dispatch(aasdiZipSearch(zip)),
		clearTripOptions: () => dispatch(clearTripOptions()),
		deletedSelectedTrip: () => dispatch(deletedSelectedTrip()),
		getTripOptions: (workAasdiId, type) => dispatch(getTripOptions(workAasdiId, type)),
		getWorkViewTrip: (tripId, type) => dispatch(getWorkViewTrip(tripId, type)),
		markTripAsComplete: (workId, workAasdiTripId) => dispatch(markTripAsComplete(workId, workAasdiTripId)),
		removeAASDI: (workId, aasdiId) => dispatch(removeAASDI(workId, aasdiId)),
		selectAASDI: (index, array) => dispatch(selectAASDI(index, array)),
		setTripIdCheck: (id) => dispatch(setTripIdCheck(id)),
		setWorkAasdiIdCheck: (id) => dispatch(setWorkAasdiIdCheck(id)),
		toggleAASDIModal: (bool) => dispatch(toggleAASDIModal(bool)),
		toggleNoTrips: () => dispatch(toggleNoTrips())
	};
};

const mapStateToProps = (state) => {
	let viewState = state.work.workRecord.view.manageAASDI;

	let props = {
		aasdiExists: viewState.aasdis.length > 0,
		selectedAASDI:
			viewState.aasdis.length > 0
				? state.work.workRecord.view.manageAASDI.aasdis[
						state.work.workRecord.view.manageAASDI.aasdis.findIndex((aasdi) => aasdi.selected === true)
				  ]
				: null,
		selectedWorkAasdiId:
			viewState.aasdis.length > 0
				? state.work.workRecord.view.manageAASDI.aasdis[
						state.work.workRecord.view.manageAASDI.aasdis.findIndex((aasdi) => aasdi.selected === true)
				  ].workAasdiId
				: null,
		aasdiArray: viewState.aasdis,
		manageAASDI: viewState,
		id: state.work.workRecord.view.id,
		zip: state.work.workRecord.view.record.location_Zip ? state.work.workRecord.view.record.location_Zip : null,
		noTrip: viewState.noTrip,
		contactLoading: viewState.infoSection[0].sections[0].loading,
		selectedTripId: viewState.selectedTripId,
		workAasdiIdCheck: viewState.workAasdiIdCheck,
		isDesynced: state.work.workRecord.view.record.desynced
	};

	if (props.aasdiExists) {
		props.selectedAASDI = viewState.aasdis.find((aasdi) => aasdi.selected === true);
	}

	if (props.selectedWorkAasdiId) {
		props.selectedWorkAasdiId = props.selectedAASDI.workAasdiId;
		props.aasdiStatusDescription = selectAasdiStatusDescription(state, props.selectedAASDI.workAasdiId);
	}

	return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAASDI);
