import React from 'react';
import {Segment, Label} from 'semantic-ui-react';
import ChangeOrdersGrid from './components/changeOrdersGrid.js';

class changeOrders extends React.Component {
	render() {
		return (
			<Segment basic style={{marginTop: '0', paddingTop: 0}}>
				<Segment style={{padding: 0}}>
					<Label attached="top" icon="search" color="blue" style={{position: 'relative', padding: 0}}>
						<div
							style={{
								display: 'inline-block',
								padding: '1.2em'
							}}
						>
							{'Change Orders'}
						</div>
					</Label>
					<ChangeOrdersGrid />
				</Segment>
			</Segment>
		);
	}
}

export default changeOrders;
