import update from 'react-addons-update';

export default (
	state = {
		deactivateOpportunityModal: {
			toggleDeactivateModal: false,
			id: null,
			place: null,
			loading: null
		}
	},
	action
) => {
	switch (action.type) {
		case 'DEACTIVATE_OPPORTUNITY_MODAL_TOGGLE':
			state = update(state, {
				deactivateOpportunityModal: {
					toggleDeactivateModal: {
						$set: !state.deactivateOpportunityModal.toggleDeactivateModal
					}
				}
			});
			break;

		case 'DEACTIVATE_OPPORTUNITY_MODAL_LOADING':
			state = update(state, {
				deactivateOpportunityModal: {
					loading: {
						$set: action.payload
					}
				}
			});
			break;

		case 'DEACTIVATE_OPPORTUNITY_MODAL_UPDATE_MODAL_ID':
			state = update(state, {
				deactivateOpportunityModal: {
					id: {
						$set: action.payload.id
					},
					place: {
						$set: action.payload.place
					}
				}
			});
			break;
	}

	return state;
};
