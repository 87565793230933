import {Button, Icon, Item, Popup, Label} from 'semantic-ui-react';
import React from 'react';
import {connect} from 'react-redux';

import TaskLabel from './TaskLabel.jsx';
import {editTask, setTaskPinnedValue} from '../services/TaskTemplates.actions.js';

import {SortableElement} from 'react-sortable-hoc';

import {v4 as uuidv4} from 'uuid';

const Task = SortableElement((props) => {
	const {task} = props;

	let headerContent;
	let togglePinnedStatus;
	let pinMessage;
	let reorderButton;

	if (task.pinned) {
		togglePinnedStatus = (task) => props.unpinTask(task);
		pinMessage = 'Un-pin this task';
		headerContent = (
			<span>
				<Icon name="pin" />
				{task.title ? task.title : task.name}
			</span>
		);
		reorderButton = null;
	} else {
		togglePinnedStatus = (task) => props.pinTask(task);
		pinMessage = 'Pin this task';
		headerContent = (
			<span>
				<span>{`${task.taskOrder + 1}. `}</span>
				{task.title ? task.title : task.name}
			</span>
		);
		reorderButton = (
			<Popup
				size="mini"
				trigger={
					<Button floated="right" size="mini" icon style={{cursor: 'move'}} type="button">
						<Icon name="sort" />
					</Button>
				}
				content="Click and drag to re-order task."
			/>
		);
	}

	let label = {};

	if (task.pendingChanges) {
		label.color = 'yellow';
		label.icon = 'warning sign';
		label.content = ' Pending Changes';
	} else if (task.completed) {
		label.color = 'green';
		label.icon = 'check';
		label.content = ' Completed';
	} else {
		label.color = 'red';
		label.icon = 'x';
		label.content = ' Not Completed';
	}

	return (
		<Item key={uuidv4()}>
			<Item.Content>
				<Button.Group floated="right" style={{paddingLeft: '.8rem'}}>
					<Popup
						size="mini"
						trigger={
							<Button
								className="pseudoDragHandler"
								size="mini"
								floated="right"
								icon
								type="button"
								onClick={() => props.editTask(task)}
							>
								<Icon name="edit" className="pseudoDragHandler" />
							</Button>
						}
						content="Edit task"
					/>

					<Popup
						size="mini"
						trigger={
							<Button
								className="pseudoDragHandler"
								size="mini"
								floated="right"
								icon
								type="button"
								onClick={() => togglePinnedStatus(task)}
							>
								<Icon name="pin" className="pseudoDragHandler" />
							</Button>
						}
						content={pinMessage}
					/>

					{reorderButton}
				</Button.Group>
				<Item.Header
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						maxWidth: '70%'
					}}
				>
					{headerContent}
				</Item.Header>
				<Item.Meta />
				<Item.Description>{task.directions}</Item.Description>
				<Item.Extra>
					<Label color={label.color} icon={label.icon} content={label.content} />
					<TaskLabel task={task} />
				</Item.Extra>
			</Item.Content>
		</Item>
	);
});

const mapDispatchToProps = (dispatch) => ({
	editTask: (task) => dispatch(editTask(task)),
	pinTask: (task) => dispatch(setTaskPinnedValue(task, true)),
	unpinTask: (task) => dispatch(setTaskPinnedValue(task, false))
});

export default connect(null, mapDispatchToProps)(Task);
