import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {Segment, Button, Icon, Label} from 'semantic-ui-react';
import OrderLinkRenderer from './components/orderLinkRenderer.js';
import RemoveWOButton from './components/removeWOButton.js';
import * as actions from '../../../../../../../services/workRecordActions.js';

function cell(text, styleId) {
	return {
		styleId: styleId,
		data: {
			type: /^\d+$/.test(text) ? 'Number' : 'String',
			value: String(text)
		}
	};
}

function formatNumber(number) {
	number = number ? number : 0;
	return `$ ${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}
class AddOrderGroupGrid extends React.Component {
	columnDefs = [
		{
			headerName: 'Link',
			cellRendererFramework: OrderLinkRenderer,
			maxWidth: 75,
			filter: false
		},
		{
			headerName: 'Work #',
			field: 'workNumber',
			filter: 'agTextColumnFilter',
			cellRenderer: 'agGroupCellRenderer'
		},
		{
			headerName: 'Client',
			field: 'client_Name',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'PO #',
			field: 'purchaseOrderNumber',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Total Revenue',
			field: 'totalRev',
			filter: 'agTextColumnFilter',
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => formatNumber(data.value)
		},
		{
			headerName: 'Store #',
			field: 'location_StoreNumber',
			filter: 'agTextColumnFilter',
			cellStyle: {textAlign: 'right'}
		},
		{
			headerName: 'City',
			field: 'location_City',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'State',
			field: 'location_State',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Remove',
			cellRendererFramework: RemoveWOButton
		}
	];

	columnDetailDefs = [
		{
			headerName: 'Description',
			filter: 'agTextColumnFilter',
			field: 'description',
			editable: false
		},
		{
			headerName: 'Manufacturer',
			field: 'manufacturer',
			editable: false
		},
		{
			headerName: 'Part Number',
			field: 'partNumber',
			editable: false
		},
		{
			headerName: 'Unit',
			field: 'unit',
			editable: false,
			cellStyle: {textAlign: 'right'}
		},
		{
			headerName: 'Quantity',
			field: 'quantity',
			editable: false,
			cellStyle: {textAlign: 'right'}
		},
		{
			headerName: 'Unit Material Cost',
			field: 'unitMaterialCost',
			editable: false,
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => formatNumber(data.value)
		},
		{
			headerName: 'Material Markup',
			field: 'markupMaterial',
			editable: false,
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => (data.value ? `% ${data.value}` : `% 0`)
		},
		{
			headerName: 'Material Revenue',
			field: 'materialResell',
			editable: false,
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => formatNumber(data.value)
		},
		{
			headerName: 'Unit Labor Cost',
			field: 'unitLaborCost',
			editable: false,
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => formatNumber(data.value)
		},
		{
			headerName: 'Labor Markup',
			field: 'markupLabor',
			editable: false,
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => (data.value ? `% ${data.value}` : `% 0`)
		},
		{
			headerName: 'Labor Revenue',
			field: 'laborResell',
			editable: false,
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => formatNumber(data.value)
		},
		{
			headerName: 'Material Markup',
			field: 'markupMaterial',
			editable: false,
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => formatNumber(data.value)
		},
		{
			headerName: 'Total Revenue',
			field: 'totalResell',
			editable: false,
			cellStyle: {textAlign: 'right'},
			valueFormatter: (data) => formatNumber(data.value)
		}
	];

	sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true,
					suppressPivots: true,
					suppressPivotMode: true
				}
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		],
		position: 'right'
	};

	detailCellRendererParams = () => ({
		detailGridOptions: {
			columnDefs: this.columDetailDefs,
			onFirstDataRendered(params) {
				this.gridApi = params.api;
				params.api.sizeColumnsToFit();
			}
		},
		getDetailRowData: function (params) {
			params.successCallback(params.data.bills);
		}
	});

	onQuickFilterChanged(value) {
		this.gridApi.setQuickFilter(value);
	}

	exportHandler(value) {
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				return params.value;
			},
			columnKeys: ['workNumber', 'client_Name', 'purchaseOrderNumber']
		};
		this.gridApi.exportDataAsExcel(params);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	defaultExportParams = (params) => {
		return {
			getCustomContentBelowRow: function (params) {
				return [
					[
						cell(''),
						cell('Description', 'subHeader'),
						cell('Manufacturer', 'subHeader'),
						cell('Part Number', 'subHeader'),
						cell('Quantity', 'subHeader'),
						cell('Unit Material Cost', 'subHeader'),
						cell('Material Markup', 'subHeader'),
						cell('Material Revenue', 'subHeader'),
						cell('Unit Labor Cost', 'subHeader'),
						cell('Labor Markup', 'subHeader'),
						cell('Labor Revenue', 'subHeader'),
						cell('Total Revenue', 'subHeader')
					]
				].concat(
					params.node.data.bills.map(function (record) {
						return [
							cell(''),
							cell(record.description, 'body'),
							cell(record.manufacturer, 'body'),
							cell(record.partNumber, 'body'),
							cell(record.quantity, 'body'),
							cell(formatNumber(record.unitMaterialCost), 'body'),
							cell(record.markupMaterial ? `% ${record.markupMaterial}` : '% 0', 'body'),
							cell(formatNumber(record.materialResell), 'body'),
							cell(formatNumber(record.unitLaborCost), 'body'),
							cell(record.markupLabor ? `% ${record.markupLabor}` : '% 0', 'body'),
							cell(formatNumber(record.laborResell), 'body'),
							cell(formatNumber(record.totalResell), 'body')
						];
					}),
					[[]]
				);
			},
			columnWidth: 120
		};
	};

	excelStyles = [
		{
			id: 'header',
			interior: {
				color: '#aaaaaa',
				pattern: 'Solid'
			}
		},
		{
			id: 'subHeader',
			interior: {
				color: '#dddddd',
				pattern: 'Solid'
			}
		},
		{
			id: 'body',
			interior: {
				color: '#ffffff'
			}
		}
	];

	render() {
		const defaultColDef = {
			sortable: true,
			filter: true,
			resizable: true
		};
		return (
			<Segment style={{marginTop: '3rem'}}>
				<Label attached="top" icon="search" color="blue">
					{'Work Orders'}
				</Label>
				<div
					className="ag-theme-balham"
					style={{
						height: '25vh',
						width: '100%'
					}}
				>
					<AgGridReact
						animateRows={true}
						modules={AllModules}
						suppressRowClickSelection={false}
						sideBar={this.sideBar}
						suppressDragLeaveHidesColumns={true}
						suppressContextMenu={true}
						suppressMultiRangeSelection={true}
						onGridReady={this.onGridReady}
						defaultColDef={defaultColDef}
						columnDefs={this.columnDefs}
						rowData={this.props.rowData}
						masterDetail={true}
						detailCellRendererParams={this.detailCellRendererParams(this.props)}
						defaultExportParams={this.defaultExportParams()}
						excelStyles={this.excelStyles}
						{...this.props}
					/>
				</div>
				<Button
					style={{marginTop: '1rem'}}
					color="grey"
					onClick={() => this.props.selectWOModalToggle(this.props.rowData)}
				>
					<Icon name="plus" /> Add WOs
				</Button>
				<Button
					floated="right"
					style={{marginTop: '1rem'}}
					color="grey"
					onClick={() => this.exportHandler('orderGroupWO')}
				>
					<Icon name="share" /> Export WOs
				</Button>
			</Segment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		rowData: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.rowData,
		quotedJobNumber: state.work.workRecord.view.record.quotedJobNumber
	};
};

export default connect(mapStateToProps, actions)(AddOrderGroupGrid);
