import update from 'react-addons-update';
import moment from 'moment';

export default (
	state = {
		startDate: moment()
			.subtract(1, 'year')
			.format(),
		endDate: moment()
			.add(1, 'year')
			.format(),
		branch: null,
		accountExecutiveId: null,
		serviceType: null,
		technology: null,
		employees: [],
		getAccountExecLoading: false
	},
	action
) => {
	switch (action.type) {
		case 'UPDATE_START_DATE':
			state = update(state, {
				startDate: {
					$set: action.payload.value._d.toISOString()
				}
			});
			break;
		case 'UPDATE_END_DATE':
			state = update(state, {
				endDate: {
					$set: action.payload.value._d.toISOString()
				}
			});
			break;
		case 'UPDATE_SELECTED_BRANCH':
			state = update(state, {
				branch: {
					$set: action.payload.value
				}
			});
			break;
		case 'UPDATE_SELECTED_ACCOUNT_EXECUTIVE':
			state = update(state, {
				accountExecutiveId: {
					$set: action.payload.value
				},
				getAccountExecLoading: {
					$set: false
				}
			});
			break;
		case 'UPDATE_SELECTED_SERVICE_TYPE':
			state = update(state, {
				serviceType: {
					$set: action.payload.value
				}
			});
			break;
		case 'UPDATE_SELECTED_TECHNOLOGY':
			state = update(state, {
				technology: {
					$set: action.payload.value
				}
			});
			break;
		case 'GET_EMPLOYEE_DROPDOWN_OPTIONS_LOADING':
			state = update(state, {
				getAccountExecLoading: {
					$set: action.payload
				}
			});
			break;
		case 'GET_EMPLOYEE_DROPDOWN_OPTIONS':
			state = update(state, {
				employees: {
					$set: action.payload.employees
				}
			});
			break;
	}
	return state;
};
