// Modules
import React from 'react';
import {Button, Modal, Icon, Header, Grid, Input, Form, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {toggleCreateContactModal} from './services/globalActions.js';
import stateOptions from '../../constants/states.js';
import countryOptions from '../../constants/countries.js';
import api from '../../lib/api.ts';
import {contactAdded as clientContactAdded} from '../../../src/scenes/work/scenes/client/scenes/profile/services/profileGridActions.js';
import {contactAdded as aasdiContactAdded} from '../../../src/scenes/work/scenes/aasdi/profile/services/profileAasdiGridActions.js';

const formFields = [
	{
		label: 'First Name',
		required: true,
		type: 'text',
		value: '',
		key: 'firstName'
	},
	{
		label: 'Last Name',
		required: false,
		type: 'text',
		value: '',
		key: 'lastName'
	},
	{
		label: 'Email',
		required: false,
		type: 'text',
		value: '',
		key: 'email'
	},
	{
		label: 'Office Phone',
		required: false,
		type: 'number',
		value: '',
		key: 'phone'
	},
	{
		label: 'Extension',
		required: false,
		type: 'number',
		value: '',
		key: 'extension'
	},
	{
		label: 'Mobile Phone',
		required: false,
		type: 'number',
		value: '',
		key: 'mobilePhone'
	},
	{
		label: 'Title',
		required: false,
		type: 'text',
		value: '',
		key: 'title'
	},
	{
		label: 'Address',
		required: false,
		type: 'text',
		value: '',
		key: 'address'
	},
	{
		label: 'Address 2',
		required: false,
		type: 'text',
		value: '',
		key: 'address2'
	},
	{
		label: 'City',
		required: false,
		type: 'text',
		value: '',
		key: 'city'
	},
	{
		label: 'State',
		error: false,
		required: false,
		type: 'dropdown',
		value: '',
		options: stateOptions,
		key: 'state'
	},
	{
		label: 'Country',
		error: false,
		required: false,
		type: 'dropdown',
		value: 218,
		options: countryOptions,
		key: 'country'
	},
	{
		label: 'ZIP Code',
		required: false,
		type: 'number',
		value: '',
		key: 'zip'
	},
	{
		label: 'Billing Contact',
		required: false,
		type: 'dropdown',
		value: false,
		options: [
			{value: true, text: 'Yes'},
			{value: false, text: 'No'}
		],
		key: 'isBillingContact'
	}
];

class CreateContactModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isCreatingContact: false,
			fields: formFields
		};
		this.updateField = this.updateField.bind(this);
		this.submitContact = this.submitContact.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.validateForm = this.validateForm.bind(this);
	}

	validateForm() {
		const {fields} = this.state;
		let answer = false;
		fields.forEach((field) => {
			if (field.required && !field.value) {
				answer = true;
			}
		});
		return answer;
	}

	submitContact() {
		const {fields} = this.state;
		const {id, typeOfId, clientContactAdded, aasdiContactAdded} = this.props;
		const body = {};
		fields.forEach((field) => {
			body[field.key] = field.value;
		});
		body[typeOfId] = id;
		this.setState((prevState) => ({...prevState, isCreatingContact: true}));
		api('legacy/contacts', 'POST', body)
			.then((response) => {
				this.setState((prevState) => ({
					...prevState,
					isCreatingContact: false,
					fields: formFields
				}));
				if (typeOfId === 'clientId') {
					clientContactAdded(response);
				} else {
					aasdiContactAdded(response);
				}
				this.closeModal();
			})
			.catch((error) => {
				alert('Error while creating contact!');
				console.error(error);
				this.setState((prevState) => ({
					...prevState,
					isCreatingContact: false,
					fields: formFields
				}));
				this.closeModal();
			});
	}

	closeModal() {
		this.setState({
			firstName: '',
			lastName: '',
			firstNameError: false,
			lastNameError: false,
			isCreatingContact: false
		});
		this.props.toggleCreateContactModal(false);
	}

	updateField(value, index) {
		const fields = JSON.parse(JSON.stringify(this.state.fields));
		fields[index].value = value;
		this.setState((prevState) => ({...prevState, fields}));
	}

	render() {
		const {isCreatingContact, fields} = this.state;
		return (
			<Modal size="small" open={this.props.isOpen}>
				<Header>
					<Grid>
						<Grid.Row>
							<Grid.Column width={16} style={{margin: 'auto 0'}}>
								<Icon name="plus" color="green" />
								<div className="content">Add New Contact</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Header>
				<Modal.Content>
					<Form>
						{fields.map((field, index) => (
							<Form.Field required={field.required} key={`create_contact_modal_field${index}`}>
								<label>{field.label}</label>
								{field.type === 'dropdown' ? (
									<Dropdown
										className="createModalDropdown"
										fluid
										selection
										value={field.value}
										type={field.type}
										options={field.options}
										onChange={(e, data) => this.updateField(data.value, index)}
									/>
								) : (
									<Input
										style={{width: '100%'}}
										value={field.value}
										type={field.type}
										onChange={(e) => this.updateField(e.target.value, index)}
									/>
								)}
							</Form.Field>
						))}
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							color="red"
							disabled={isCreatingContact}
							onClick={() => {
								this.closeModal(false);
							}}
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							color="green"
							loading={isCreatingContact}
							disabled={this.validateForm() || isCreatingContact}
							onClick={this.submitContact}
						>
							{isCreatingContact ? <Icon name="spinner" loading={true} /> : <Icon name="checkmark" />}
							Create
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

CreateContactModal.propTypes = {
	isOpen: PropTypes.bool,
	id: PropTypes.number,
	toggleCreateContactModal: PropTypes.func,
	typeOfId: PropTypes.string.isRequired,
	clientContactAdded: PropTypes.func,
	aasdiContactAdded: PropTypes.func
};

const mapStateToProps = (state) => ({
	isOpen: state.global.global.showCreateContactModal
});

const mapDispatchToProps = (dispatch) => ({
	toggleCreateContactModal: (status) => dispatch(toggleCreateContactModal(status)),
	clientContactAdded: (data) => dispatch(clientContactAdded(data)),
	aasdiContactAdded: (data) => dispatch(aasdiContactAdded(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateContactModal);
