import update from 'react-addons-update';
import {fmtMoney} from '../../../util/formatting.js';
import moment from 'moment';
import ActionButtons from '../scenes/acCategoryScreen/acCategory/components/actionButtons/actionButtons.js';
import {calcMargin, calcRemainingToBill} from '../../../util/moneymath.ts';
import {fmtPercent} from '../../../util/formatting.js';
import {roundFloat} from '~/util/numbers.ts';
import {UPDATE_ACCOUNTING_CONSOLE_USERS} from './accountingConsoleActions.js';

export default (
	state = {
		accountingConsole: {
			modalType: 'Client',
			modalRequestType: 'post',
			initialSearchOptions: [],
			userForm: {},
			changedArray: [],
			usersGrid: [],
			loading: true,
			selectedRecord: {},
			selectedItemsForApproval: [],
			invalidSelectedItemsForApproval: [],
			buttonId: '',
			userToBeDeactivated: {},
			acceptModalToggle: false,
			acceptModal: {
				id: '',
				symbolError: false,
				existsError: false
			},
			previouslyApprovedModalToggle: false,
			previouslyApprovedModal: {
				records: [],
				category: ''
			},
			rejectModalToggle: false,
			rejectCommentValue: '',
			modalLoading: false,
			isDeactivateUserModalOpen: false,
			isChooseUserModalOpen: false,
			isUserCreationModalOpen: false,
			usersGridColumns: [
				{
					// We need to flip the activate/deactivate icons on the grid. It looks like the active users aren't active and the deactivated users are active. Should talk to ASD about it first.
					headerName: 'Action Bar',
					field: 'userActionsButtons',
					cellStyle: {paddingTop: '.4em'},
					cellRenderer: 'userActionsButtons',
					width: 125,
					getQuickFilterText: () => ''
				},
				{
					headerName: 'Status',
					field: 'isActive',
					valueGetter: function (params) {
						return params.data.isActive ? 'Active' : 'Inactive';
					},
					filter: 'agSetColumnFilter',
					cellStyle: function (params) {
						return params.data.isActive ? null : {color: 'red'};
					},
					editable: false,
					maxWidth: 100
				},
				{
					headerName: 'First Name',
					field: 'firstName',
					filter: 'agTextColumnFilter',
					editable: false,
					width: 125
				},
				{
					headerName: 'Last Name',
					field: 'lastName',
					filter: 'agTextColumnFilter',
					editable: false,
					width: 125
				},
				{
					headerName: 'Email',
					field: 'email',
					filter: 'agTextColumnFilter',
					editable: false,
					width: 225
				},
				{
					headerName: 'Phone',
					field: 'phone',
					filter: 'agTextColumnFilter',
					editable: false,
					width: 125,
					getQuickFilterText: () => ''
				},
				{
					headerName: 'Phone Ext',
					field: 'phoneExt',
					filter: 'agTextColumnFilter',
					editable: false,
					width: 100,
					getQuickFilterText: () => ''
				},
				{
					headerName: 'Title',
					field: 'title',
					filter: 'agTextColumnFilter',
					editable: false,
					width: 150,
					getQuickFilterText: () => ''
				},
				{
					headerName: 'Role',
					field: 'role',
					filter: 'agTextColumnFilter',
					editable: false,
					width: 125,
					getQuickFilterText: () => ''
				},
				{
					headerName: 'Branch',
					field: 'branch',
					filter: 'agTextColumnFilter',
					editable: false,
					width: 125,
					getQuickFilterText: () => ''
				}
			],
			category: [
				{
					color: 'purple',
					count: '?',
					description: 'Show and Create New Users',
					icon: 'users',
					name: 'Users'
				},
				{
					color: 'yellow',
					count: '?',
					description: 'Approve Clients',
					icon: 'thumbtack',
					name: 'Client'
				},
				{
					color: 'teal',
					count: '?',
					description: 'Approve AASDI',
					icon: 'user',
					name: 'AASDI'
				},
				{
					color: 'olive',
					count: '?',
					description: 'Approve Project',
					icon: 'money bill alternate',
					name: 'Project'
				},
				{
					color: 'blue',
					count: '?',
					description: 'Approve Invoices',
					icon: 'envelope open',
					name: 'Invoice'
				},
				{
					color: 'black',
					count: '?',
					description: 'Close Orders and Projects',
					icon: 'check square',
					name: 'Closure'
				},
				{
					color: 'red',
					count: '?',
					description: 'View Rejections',
					icon: 'thumbs down',
					name: 'Rejections'
				},
				{
					color: 'green',
					count: '?',
					description: 'View Accepted',
					icon: 'thumbs up',
					name: 'Accepted'
				}
			],
			selectedCategory: {
				name: '',
				loading: true,
				isDesynced: false,
				data: [
					{
						name: 'Users',
						rowData: [],
						loading: true,
						colDefs: [
							{
								headerName: 'Actions',
								cellRendererFramework: ActionButtons,
								width: 150
							},
							{
								headerName: 'Last Name',
								field: 'lastName',
								width: 100
							},
							{
								headerName: 'First Name',
								field: 'firstName',
								width: 200
							},
							{
								headerName: 'Title',
								field: 'title',
								width: 175
							},
							{
								headerName: 'Office Phone',
								field: 'lorem ipsum',
								width: 600
							},
							{
								headerName: 'Cell Phone',
								field: 'lorem ipsum',
								width: 200
							},
							{
								headerName: 'Email',
								field: 'email',
								width: 150
							},
							{
								headerName: 'Branch',
								field: 'lorem ipsum',
								width: 200
							},
							{
								headerName: 'Sales Number',
								field: 'lorem ipsum',
								width: 175
							},
							{
								headerName: 'Status',
								field: 'lorem ipsum',
								width: 200
							}
						]
					},
					{
						name: 'Client',
						rowData: [],
						loading: true,
						colDefs: [
							{
								headerName: 'Actions',
								cellRendererFramework: ActionButtons,
								width: 150
							},
							{
								headerName: 'Name',
								field: 'name',
								width: 200
							},
							{
								headerName: 'Created Date',
								field: 'updatedOn',
								valueFormatter: (data) =>
									moment(data.value ? data.value : null).format('M/D/YYYY h:mm A'),
								width: 175
							},
							{
								headerName: 'Type',
								field: 'clientType',
								width: 100
							},
							{
								headerName: 'Status',
								field: 'clientStatusName',
								width: 175
							},
							{
								headerName: 'Address',
								field: 'fullAddress',
								width: 400
							},
							{
								headerName: 'Website',
								field: 'website',
								width: 200,
								cellRenderer: function (params) {
									let link;
									if (params.value.startsWith('http') === false) {
										link = `http://${params.value.toLowerCase()}`;
									} else {
										link = params.value.toLowerCase();
									}

									return `<a href="${link}" target="_blank">${link}</a>`;
								}
							},
							{
								headerName: 'Account Executive',
								field: 'accountExecutiveName',
								width: 200
							},
							{
								headerName: 'Industry',
								field: 'industryName',
								width: 175
							},
							{
								headerName: 'Main Contact',
								field: 'mainContactName',
								width: 175
							},
							{
								headerName: 'Source',
								field: 'leadSourceName',
								width: 175
							}
						]
					},
					{
						name: 'AASDI',
						rowData: [],
						loading: true,
						colDefs: [
							{
								headerName: 'Actions',
								cellRendererFramework: ActionButtons,
								width: 150
							},
							{
								headerName: 'Name',
								field: 'name',
								width: 250
							},
							{
								headerName: 'Created Date',
								field: 'createdOn',
								valueFormatter: (data) =>
									moment(data.value ? data.value : null).format('M/D/YYYY h:mm A'),
								width: 175
							},
							{
								headerName: 'Address',
								field: 'fullAddress',
								width: 500
							},
							{
								headerName: 'Coverage',
								field: 'geographicCoverage',
								width: 150
							},
							{
								headerName: 'Website',
								field: 'website',
								width: 200,
								cellRenderer: function (params) {
									let link;
									if (params.value.startsWith('http') === false) {
										link = `http://${params.value.toLowerCase()}`;
									} else {
										link = params.value.toLowerCase();
									}

									return `<a href="${link}" target="_blank">${link}</a>`;
								}
							},
							{
								headerName: 'Annual Revenue',
								field: 'annualRevenue',
								width: 150
							},
							{
								headerName: 'Tax ID',
								field: 'federalTaxId',
								width: 100
							},
							{
								headerName: 'Municipalities',
								field: 'licensedMunicipalities',
								width: 200
							},
							{
								headerName: 'Liability Company Name',
								field: 'liabilityCompanyName',
								width: 200
							},
							{
								headerName: 'Liability Exp.',
								field: 'liabilityExpirationDate',
								width: 250
							},
							{
								headerName: 'Liability Policy #',
								field: 'liabilityPolicyNumber',
								width: 150
							},
							{
								headerName: 'Best Rating',
								field: 'workersBestRating',
								width: 125
							},
							{
								headerName: 'Workers Comp. Company Name',
								field: 'workersCompanyName',
								width: 225
							},
							{
								headerName: 'Workers Comp. Exp.',
								field: 'workersExpirationDate',
								width: 225
							},
							{
								headerName: 'Workers Comp. Policy #',
								field: 'workersPolicyNumber',
								width: 175
							}
						]
					},
					{
						name: 'Project',
						rowData: [],
						loading: true,
						colDefs: [
							{
								headerName: '',
								width: 55,
								headerCheckboxSelection: true,
								headerTooltip: 'Select all items on all pages of the grid',
								checkboxSelection: true
							},
							{
								headerName: 'Actions',
								cellRendererFramework: ActionButtons,
								width: 150
							},
							{
								headerName: 'Client',
								field: 'clientName',
								width: 200
							},
							{
								headerName: 'Branch',
								field: 'branchName',
								width: 125
							},
							{
								headerName: 'Created Date',
								field: 'workCreatedOn',
								valueFormatter: (data) =>
									moment(data.value ? data.value : null).format('M/D/YYYY h:mm A'),
								width: 175
							},
							{
								headerName: 'Sales Person',
								field: 'accountExecutiveName',
								width: 200
							},
							{
								headerName: 'Billing',
								field: 'billing',
								cellRenderer: 'BillingBreakdownCellRenderer',
								width: 100
							},
							{
								headerName: 'Name',
								field: 'name',
								width: 200
							},
							{
								headerName: 'Description',
								field: 'description',
								width: 500
							},
							{
								headerName: 'Service Type',
								field: 'serviceTypeName',
								width: 100
							},
							{
								headerName: 'Stage',
								field: 'stageName',
								width: 150
							},
							{
								headerName: 'Work Status Name',
								field: 'workStatusName',
								width: 200
							},
							{
								headerName: 'Client Status',
								field: 'clientStatusName',
								width: 100
							},
							{
								headerName: 'Engineers',
								field: 'estimators',
								width: 200
							},
							{
								headerName: 'Lead Name',
								field: 'leadSourceName',
								width: 200
							},
							{
								headerName: 'Loss Comments',
								field: 'lossComments',
								width: 500
							},
							{
								headerName: 'Loss Reason',
								field: 'lossReason',
								width: 500
							},
							{
								headerName: 'Bid Due Date',
								field: 'bidDueDate',
								width: 250
							},
							{
								headerName: 'Branch Name',
								field: 'branchName',
								width: 250
							},
							{
								headerName: 'Projected Order Date',
								field: 'projectedOrderDate',
								width: 250
							},
							{
								headerName: 'Stage Updated On',
								field: 'stageUpdatedOn',
								valueFormatter: (params) => {
									return moment(params.value).format('MM-DD-YYYY h:mm');
								},
								width: 250
							}
						]
					},
					{
						name: 'Invoice',
						rowData: [],
						loading: true,
						colDefs: [
							{
								headerName: '',
								width: 55,
								headerCheckboxSelection: true,
								headerTooltip: 'Select all items on all pages of the grid',
								checkboxSelection: true
							},
							{
								headerName: 'Actions',
								cellRendererFramework: ActionButtons,
								width: 150
							},
							{
								headerName: 'Group Number',
								field: 'groupWorkNumber',
								width: 150,
								valueFormatter: (params) => {
									return params.value ? params.value : null;
								}
							},
							{
								headerName: 'Work Number',
								field: 'workNumber',
								width: 150
							},
							{
								headerName: 'Purchase Order Number',
								field: 'purchaseOrderNumber',
								width: 190
							},
							{
								headerName: 'Client Name',
								field: 'clientName',
								width: 200
							},
							{
								headerName: 'Created Date',
								field: 'createdOn',
								valueFormatter: (data) =>
									moment(data.value ? data.value : null).format('M/D/YYYY h:mm A'),
								width: 175
							},
							{
								headerName: 'Client Code',
								field: 'spectrumCode',
								width: 120
							},
							{
								headerName: 'Amount',
								field: 'amount',
								width: 100,
								valueFormatter: ({value}) => fmtMoney(value)
							},
							{
								headerName: 'Freight',
								field: 'freight',
								width: 100,
								valueFormatter: ({value}) => fmtMoney(value)
							},
							{
								headerName: 'Open Commitment',
								field: 'spectrumOpenCommit',
								width: 170,
								valueFormatter: ({value}) => fmtMoney(value)
							},
							{
								headerName: 'Remaining To Bill',
								field: 'remainingToBill',
								width: 170,
								valueGetter: (params) =>
									calcRemainingToBill(
										params.data.spectrumEstimatedRevenue,
										params.data.spectrumActualRevenue
									),
								valueFormatter: ({value}) => fmtMoney(value)
							},
							{
								headerName: 'Issue Date',
								field: 'invoiceDate',
								width: 130
							},
							{
								headerName: 'Quoted Job #',
								field: 'quotedJobNumber',
								width: 140
							},
							{
								headerName: 'NDS',
								field: 'nds',
								width: 100
							},
							{
								headerName: 'Work Completed Date',
								field: 'invoiceDate',
								width: 170
							}
						]
					},
					{
						name: 'Closure',
						rowData: [],
						loading: true,
						colDefs: [
							{
								headerName: '',
								width: 55,
								headerCheckboxSelection: true,
								headerTooltip: 'Select all items on all pages of the grid',
								checkboxSelection: true
							},
							{
								headerName: 'Actions',
								cellRendererFramework: ActionButtons,
								width: 150
							},
							{
								headerName: 'Work Number',
								field: 'workNumber',
								width: 125
							},
							{
								headerName: 'Status',
								field: 'statusId',
								valueFormatter: (params) => (params.value === 18 ? 'Warranty' : 'Closure'),
								width: 150
							},
							{
								headerName: 'Client',
								field: 'client_Name',
								width: 250
							},
							{
								headerName: 'Created Date',
								field: 'requestedToCloseDate',
								valueFormatter: (data) =>
									moment(data.value ? data.value : null).format('M/D/YYYY h:mm A'),
								width: 175
							},
							{
								headerName: 'Project Manager',
								field: 'projectManager_Name',
								width: 180
							},
							{
								headerName: 'Account Executive',
								field: 'accountExecutive_Name',
								width: 180
							},
							{
								headerName: 'Spectrum Code',
								field: 'spectrumCode',
								width: 125
							},
							{
								headerName: 'NDS',
								field: 'workManager_Name',
								width: 200
							},
							{
								headerName: 'Actual Revenue',
								field: 'spectrumActualRevenue',
								width: 150,
								valueFormatter: ({value}) => fmtMoney(value)
							},
							{
								headerName: 'Actual Cost',
								field: 'spectrumActualCost',
								width: 150,
								valueGetter: (params) =>
									params.data.spectrumActualCost + params.data.spectrumActualChangeOrderCost,
								valueFormatter: ({value}) => fmtMoney(value)
							},
							{
								headerName: 'Margin',
								field: 'margin',
								width: 150,
								valueGetter: (params) =>
									roundFloat(
										calcMargin(
											params.data.spectrumActualCost + params.data.spectrumActualChangeOrderCost,
											params.data.spectrumActualRevenue
										) * 100,
										2
									),
								valueFormatter: (params) => fmtPercent(params.value)
							},
							{
								headerName: 'Open Commitment',
								field: 'spectrumOpenCommit',
								width: 170,
								valueFormatter: ({value}) => fmtMoney(value)
							},
							{
								headerName: 'Remaining To Bill',
								field: 'remainingToBill',
								width: 170,
								valueGetter: (params) =>
									calcRemainingToBill(
										params.data.spectrumEstimatedRevenue,
										params.data.spectrumActualRevenue
									),
								valueFormatter: ({value}) => fmtMoney(value)
							},
							{
								headerName: 'Closure Comments',
								field: 'closureComments',
								width: 250
							}
						]
					},
					// TODO: Refactor the Rejected and Accepted grids. Possibly switch to a master/detail grid.
					// Then each type of record can have it's own grid and there won't be so many types of data
					// all jammed together.
					{
						name: 'Rejections',
						rowData: [],
						loading: true,
						colDefs: [
							{
								headerName: '',
								cellRendererFramework: ActionButtons,
								width: 100
							},
							{
								headerName: 'Type',
								field: 'rejectionType',
								width: 100
							},
							{
								headerName: 'Record',
								valueGetter: (params) => {
									const {data} = params;
									if (data.aasdiId) {
										return data.aasdiName;
									} else if (data.clientId) {
										return data.clientName;
									} else if (data.opportunityId) {
										return data.opportunityName;
									} else if (data.workId) {
										return data.workNumber;
									} else if (data.workId && data.billName) {
										return `${data.workNumber}: ${data.billName}`;
									}
								},
								width: 300
							},
							{
								headerName: 'Project Manager',
								field: 'projectManager'
							},
							{
								headerName: 'NDS',
								field: 'NDS'
							},
							{
								headerName: 'Sales Representative',
								field: 'salesRep'
							},
							{
								headerName: 'Comments',
								field: 'comment',
								width: 400
							},
							{
								headerName: 'Rejected On',
								field: 'createdOn',
								width: 200
							},
							{
								headerName: 'Rejected By',
								field: 'fullName',
								width: 150
							}
						]
					},
					{
						name: 'Accepted',
						rowData: [],
						loading: true,
						pdfModalOpen: false,
						pdfModalLoading: false,
						pdfInfo: {},
						colDefs: [
							{
								headerName: '',
								width: 55,
								headerTooltip: 'Select all invoices on all pages of the grid',
								headerCheckboxSelection: true,
								checkboxSelection: true
							},
							{
								headerName: '',
								cellRendererFramework: ActionButtons,
								width: 50
							},
							{
								headerName: 'Type',
								field: 'type',
								width: 100
							},
							{
								headerName: 'Record',
								valueGetter: (params) => {
									const {data} = params;
									if (data.aasdiName) {
										return data.aasdiName;
									} else if (data.clientName) {
										return data.clientName;
									} else if (data.opportunityName) {
										return data.opportunityName;
									} else if (data.workNumber) {
										return data.workNumber;
									} else if (data.workNumber && data.billName) {
										return `${data.workNumber}: ${data.billName}`;
									}
								},
								width: 200
							},
							{
								headerName: 'Project Manager',
								field: 'projectManager'
							},
							{
								headerName: 'NDS',
								field: 'NDS'
							},
							{
								headerName: 'Sales Representative',
								field: 'salesRep'
							},
							{
								headerName: 'Description',
								field: 'description',
								width: 350
							},
							{
								headerName: 'Accepted On',
								field: 'createdOn',
								width: 300
							},
							{
								headerName: 'Accepted By',
								field: 'fullName',
								width: 200
							}
						]
					}
				]
			}
		}
	},
	action
) => {
	switch (action.type) {
		case 'UPDATE_USER_FORM':
			state = update(state, {
				accountingConsole: {
					userForm: {
						$set: action.payload.form
					}
				}
			});
			break;
		case 'SET_DEACTIVATE_USER_DATA':
			state = update(state, {
				accountingConsole: {
					userToBeDeactivated: {
						$set: action.payload.user
					}
				}
			});
			break;
		case 'ACCOUNTING_CONSOLE_LOADER':
			state = update(state, {
				accountingConsole: {
					loading: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'TOGGLE_OPTIONS_MODAL':
			state = update(state, {
				accountingConsole: {
					isChooseUserModalOpen: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'TOGGLE_DEACTIVATE_USER_MODAL':
			state = update(state, {
				accountingConsole: {
					isDeactivateUserModalOpen: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'SWITCH_MODAL_REQUEST_TYPE':
			state = update(state, {
				accountingConsole: {
					modalRequestType: {
						$set: action.payload.type
					}
				}
			});
			break;

		case 'TOGGLE_USER_CREATION_MODAL':
			state = update(state, {
				accountingConsole: {
					isUserCreationModalOpen: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'SET_SEARCH_OPTIONS_USER_MODAL':
			state = update(state, {
				accountingConsole: {
					initialSearchOptions: {
						$set: action.payload.options
					}
				}
			});
			break;

		case 'TOGGLE_USER_CREATION_MODAL_TYPE':
			state = update(state, {
				accountingConsole: {
					modalType: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'GET_ACCOUNTING_CONSOLE':
			action.payload.json.forEach((object) => {
				if (state.accountingConsole.category.findIndex((category) => category.name === object.name) !== -1) {
					state = update(state, {
						accountingConsole: {
							category: {
								[state.accountingConsole.category.findIndex(
									(category) => category.name === object.name
								)]: {
									count: {
										$set: object.count
									}
								}
							}
						}
					});
				}
			});
			break;

		case 'GET_ACCOUNTING_CONSOLE_CATEGORY_INFO':
			state = update(state, {
				accountingConsole: {
					selectedCategory: {
						// HERE HERE HERE CHANGE THIS BACK LATER
						data: {
							[state.accountingConsole.selectedCategory.data.findIndex(
								(category) => category.name === action.payload.name
							)]: {
								loading: {
									$set: false
								},
								rowData: {
									$set: action.payload.json
								}
							}
						}
					}
				}
			});
			break;

		case 'GET_ACCOUNTING_CONSOLE_USERS':
			state = update(state, {
				accountingConsole: {
					usersGrid: {
						$set: action.payload.json
					}
				}
			});
			break;

		case 'INSERT_ACCOUNTING_CONSOLE_USERS':
			state = update(state, {
				accountingConsole: {
					usersGrid: {
						$push: [action.payload.user]
					}
				}
			});
			break;

		case UPDATE_ACCOUNTING_CONSOLE_USERS:
			const updatedAccountingConsoleUsers = state.accountingConsole.usersGrid.map((user) => {
				if (user.id === action.payload.user.id) {
					return action.payload.user;
				}
				return user;
			});
			state = update(state, {
				accountingConsole: {
					usersGrid: {
						$set: updatedAccountingConsoleUsers
					}
				}
			});
			break;

		case 'SET_SELECTED_ACCOUNTING_CONSOLE':
			state = update(state, {
				accountingConsole: {
					selectedCategory: {
						name: {
							$set: action.payload.name
						}
					}
				}
			});
			break;

		case 'SET_SELECTED_RECORD':
			state = update(state, {
				accountingConsole: {
					selectedRecord: {
						$set: action.payload.record
					}
				}
			});
			break;

		case 'SELECT_ITEM_FOR_APPROVAL':
			state = update(state, {
				accountingConsole: {
					selectedItemsForApproval: {
						$set: action.payload
					}
				}
			});
			break;

		case 'SET_INVALID':
			state = update(state, {
				accountingConsole: {
					invalidSelectedItemsForApproval: {
						$set: action.payload
					}
				}
			});
			break;

		case 'TOGGLE_REJECT_MODAL':
			state = update(state, {
				accountingConsole: {
					rejectModalToggle: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'TOGGLE_ACCEPT_MODAL':
			state = update(state, {
				accountingConsole: {
					acceptModalToggle: {
						$set: action.payload.value
					},
					buttonId: {
						$set: action.payload.buttonId
					}
				}
			});
			break;

		case 'REMOVE_FROM_GRID':
			state = update(state, {
				accountingConsole: {
					selectedCategory: {
						data: {
							[state.accountingConsole.selectedCategory.data.findIndex(
								(category) => category.name === action.payload.category
							)]: {
								rowData: {
									$splice: [
										[
											state.accountingConsole.selectedCategory.data[
												state.accountingConsole.selectedCategory.data.findIndex(
													(category) => category.name === action.payload.category
												)
											].rowData.findIndex(
												(record) =>
													action.payload.id === (record.workId ? record.workId : record.id)
											),
											1
										]
									]
								}
							}
						}
					},
					category: {
						[state.accountingConsole.category.findIndex(
							(category) => category.name === action.payload.category
						)]: {
							count: {
								$set:
									state.accountingConsole.category[
										state.accountingConsole.category.findIndex(
											(category) => category.name === action.payload.category
										)
									].count - 1
							}
						}
					},
					changedArray: {
						$unshift: [action.payload.record]
					}
				}
			});
			break;

		case 'UPDATE_REJECT_COMMENT_VALUE':
			state = update(state, {
				accountingConsole: {
					rejectCommentValue: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'UPDATE_ACCEPT_MODAL_ID':
			state = update(state, {
				accountingConsole: {
					acceptModal: {
						id: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'TOGGLE_SYMBOL_ERROR':
			state = update(state, {
				accountingConsole: {
					acceptModal: {
						symbolError: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'TOGGLE_EXISTS_ERROR':
			state = update(state, {
				accountingConsole: {
					acceptModal: {
						existsError: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'TOGGLE_PREVIOUSLY_APPROVED_RECORDS':
			state = update(state, {
				accountingConsole: {
					previouslyApprovedModalToggle: {
						$set: !state.accountingConsole.previouslyApprovedModalToggle
					},
					previouslyApprovedModal: {
						records: {
							$set: action.payload.records
						},
						category: {
							$set: action.payload.category
						}
					}
				}
			});
			break;

		case 'ACCOUNTING_CONSOLE_MODAL_LOADER':
			state = update(state, {
				accountingConsole: {
					modalLoading: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'ACCOUNTING_CONSOLE_CATEGORY_LOADER':
			state = update(state, {
				accountingConsole: {
					selectedCategory: {
						loading: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'ACCOUNTING_CONSOLE_CATEGORY_DESYNCED':
			state = update(state, {
				accountingConsole: {
					selectedCategory: {
						isDesynced: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'SET_CATEGORIES':
			state = update(state, {
				accountingConsole: {
					category: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'TOGGLE_ACCEPTED_PDF_MODAL':
			state = update(state, {
				accountingConsole: {
					selectedCategory: {
						data: {
							[state.accountingConsole.selectedCategory.data.findIndex(
								(category) => category.name === 'Accepted'
							)]: {
								pdfModalOpen: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'SET_INFO_PDF_MODAL':
			state = update(state, {
				accountingConsole: {
					selectedCategory: {
						data: {
							[state.accountingConsole.selectedCategory.data.findIndex(
								(category) => category.name === 'Accepted'
							)]: {
								pdfInfo: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'CLEAR_INFO_PDF_MODAL':
			state = update(state, {
				accountingConsole: {
					selectedCategory: {
						data: {
							[state.accountingConsole.selectedCategory.data.findIndex(
								(category) => category.name === 'Accepted'
							)]: {
								pdfInfo: {
									$set: {}
								}
							}
						}
					}
				}
			});
			break;
		default:
			break;
	}

	return state;
};
