import customFetch from '../../../lib/old/customFetch.js';

import {addContact, updateContactItem} from '../../contactsSection/services/contactsSectionActions.js';

import {
	addToTempWorkOrderContacts,
	updateTempWorkOrderContacts
} from '../../../scenes/work/scenes/CreateWorkOrder/services/createWorkOrderActions.js';

import {
	addToTempCreateProjectContacts,
	updateTempCreateProjectContacts
} from '../../../scenes/crm/scenes/dashboard/scenes/CreateProject/services/createProjectActions.js';

export const toggleContactModal = (id, type, typeId, place, option, contactData, rowIndex) => {
	return (dispatch) => {
		dispatch({
			type: 'CONTACT_MODAL_CLEAR_FIELDS'
		});
		dispatch({
			type: 'CONTACT_MODAL_TOGGLE',
			payload: {
				id,
				type,
				typeId,
				place,
				option,
				contactData,
				rowIndex
			}
		});
		// Autofill on Edit option

		if (option === 'edit') {
			dispatch({
				type: 'CONTACT_MODAL_AUTOFILL',
				payload: {
					place,
					contactData
				}
			});
		}
	};
};

export const updateContactModal = (index, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'CONTACT_MODAL_UPDATE',
			payload: {
				index,
				value,
				error
			}
		});
	};
};

export const getContacts = (type, typeId, value, clientId) => async (dispatch) => {
	// Dispatch Loader
	dispatch({
		type: 'CONTACT_MODAL_LOADING_FIELD',
		payload: {
			column: 0
		}
	});

	let endpoint;
	if (type !== 'newWork' && type !== 'newProject') {
		endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?name=${value}`;
	} else {
		endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?name=${value}&clientId=${clientId}`;
	}

	console.log(endpoint);

	const contactsSearch = await customFetch(endpoint);
	let contactsSearchjson = await contactsSearch.json();

	if (contactsSearch.status !== 200) {
		return Promise.reject(contactsSearchjson);
	} else {
		let options = contactsSearchjson.map((contact) => ({
			key: contact.id,
			text: contact.fullName,
			value: contact
		}));
		dispatch({
			type: 'CONTACT_MODAL_GET_DROPDOWN_OPTIONS',
			payload: {
				column: 0,
				options: options
			}
		});
	}
};

export const createContact = (type, typeId, modalFields) => async (dispatch) => {
	let body;

	if (type !== 'newWork' && type !== 'newProject') {
		body = {
			typeId,
			type,
			firstName: modalFields[1].value,
			lastName: modalFields[2].value,
			title: modalFields[3].value,
			email: modalFields[4].value,
			phone: modalFields[5].value,
			extension: modalFields[6].value,
			isPrimary: modalFields[7].value,
			fullName: `${modalFields[1].value} ${modalFields[2].value}`
		};

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts`;
		let recordEndpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts/section/${type}/${typeId}`;

		console.log(endpoint);

		// Check for required fields
		let emptyRequiredFields;
		let checkFields;
		emptyRequiredFields = modalFields
			.map((field, fieldIndex) => {
				field.FieldIndex = fieldIndex;
				return field;
			})
			.filter((modalField) => modalField.required)
			.filter((modalField) => !modalField.skip)
			.filter((modalField) => !modalField.value);

		checkFields = emptyRequiredFields.length > 0;

		// Run check on required fields
		if (!checkFields) {
			dispatch({
				type: 'CONTACT_MODAL_CLOSE'
			});

			const contactsPost = await customFetch(endpoint, {
				method: 'POST',
				body: JSON.stringify({
					typeId,
					type,
					firstName: modalFields[1].value,
					lastName: modalFields[2].value,
					title: modalFields[3].value,
					email: modalFields[4].value,
					phone: modalFields[5].value,
					extension: modalFields[6].value,
					isPrimary: modalFields[7].value,
					fullName: `${modalFields[1].value} ${modalFields[2].value}`
				})
			});
			let contactsPostjson = await contactsPost.json();

			if (contactsPost.status !== 200) {
				dispatch({
					type: 'ERROR'
				});
			} else {
				contactsPostjson.contactId = contactsPostjson.id;
				let contactId = contactsPostjson.id;

				const recordContactsPost = await customFetch(recordEndpoint, {
					method: 'POST',
					body: JSON.stringify({
						contactId,
						isPrimary: modalFields[7].value
					})
				});
				let recordContactsPostjson = await recordContactsPost.json();

				contactsPostjson.fullName = `${contactsPostjson.firstName} ${contactsPostjson.lastName}`;
				contactsPostjson.recordContactsId = recordContactsPostjson[0].id;

				dispatch(addContact(contactsPostjson));

				if (recordContactsPost.status !== 200) {
					dispatch({
						type: 'CONTACT_MODAL_ERROR',
						payload: {
							value: true
						}
					});
				} else {
					dispatch({
						type: 'CONTACT_MODAL_CLEAR_FIELDS'
					});
				}
			}
		} else {
			dispatch({
				type: 'CONTACT_MODAL_CHECK_REQUIRED_FIELDS',
				payload: {
					emptyRequiredFields
				}
			});
		}
	} else {
		body = {
			typeId,
			type,
			modalFields
		};
		if (type === 'newWork') {
			dispatch(addToTempWorkOrderContacts(body));
		} else if (type === 'newProject') {
			dispatch(addToTempCreateProjectContacts(body));
		}

		dispatch({
			type: 'CONTACT_MODAL_CLOSE'
		});
		dispatch({
			type: 'CONTACT_MODAL_CLEAR_FIELDS'
		});
	}
};

export const addSearchContact = (type, typeId, contactId, modalFields) => async (dispatch) => {
	let recordEndpoint;

	if (type !== 'newWork' && type !== 'newProject') {
		dispatch({
			type: 'CONTACT_MODAL_CLOSE'
		});

		recordEndpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts/section/${type}/${typeId}`;

		const recordContactsPost = await customFetch(recordEndpoint, {
			method: 'POST',
			body: JSON.stringify({
				contactId,
				isPrimary: modalFields[7].value
			})
		});

		if (!modalFields[0].value.contactId) {
			modalFields[0].value.contactId = modalFields[0].value.id;
		}

		if (recordContactsPost.status !== 200) {
			dispatch({
				type: 'CONTACT_MODAL_ERROR',
				payload: {
					value: true
				}
			});
		} else {
			const response = await recordContactsPost.json();
			modalFields[0].value.recordContactsId = response[0].id;
			dispatch(addContact(modalFields[0].value));
			dispatch({
				type: 'CONTACT_MODAL_CLEAR_FIELDS'
			});
		}
	} else {
		if (!modalFields[0].value.contactId) {
			modalFields[0].value.contactId = modalFields[0].value.id;
		}
		modalFields[0].value.type = 'work';
		modalFields[0].value.typeId = typeId;

		let body = {
			typeId,
			type,
			contactId,
			modalFields
		};

		if (type === 'newWork') {
			dispatch(addToTempWorkOrderContacts(body));
		} else if (type === 'newProject') {
			dispatch(addToTempCreateProjectContacts(body));
		}
		dispatch({
			type: 'CONTACT_MODAL_CLOSE'
		});
		dispatch({
			type: 'CONTACT_MODAL_CLEAR_FIELDS'
		});
	}
};

export const updateContact = (type, typeId, contactId, modalFields, index) => async (dispatch, getState) => {
	let payload = {
		type,
		typeId,
		firstName: modalFields[1].value,
		lastName: modalFields[2].value,
		title: modalFields[3].value,
		email: modalFields[4].value,
		phone: modalFields[5].value,
		extension: modalFields[6].value,
		isBillingContact: modalFields[7].value,
		fullName: `${modalFields[1].value} ${modalFields[2].value}`
	};

	if (type !== 'newWork' && type !== 'newProject') {
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts/${contactId}`;

		console.log(endpoint);

		// Check for required fields
		const state = getState().global.contactModal.contactModal;
		let emptyRequiredFields;
		let checkFields;
		emptyRequiredFields = state.modalFields
			.map((field, fieldIndex) => {
				field.FieldIndex = fieldIndex;
				return field;
			})
			.filter((modalField) => modalField.required)
			.filter((modalField) => !modalField.skip)
			.filter((modalField) => !modalField.value);

		checkFields = emptyRequiredFields.length > 0;

		// Run check on required fields
		if (!checkFields) {
			dispatch({
				type: 'CONTACT_MODAL_CLOSE'
			});

			const contactsUpdate = await customFetch(endpoint, {
				method: 'PUT',
				body: JSON.stringify(payload)
			});
			let contactsUpdatejson = await contactsUpdate.json();

			contactsUpdatejson.fullName = `${contactsUpdatejson.firstName} ${contactsUpdatejson.lastName}`;

			dispatch(updateContactItem(contactsUpdatejson, contactId));

			dispatch({
				type: 'CONTACT_MODAL_CLEAR_FIELDS'
			});
		} else {
			dispatch({
				type: 'CONTACT_MODAL_CHECK_REQUIRED_FIELDS',
				payload: {
					emptyRequiredFields
				}
			});
		}
	} else {
		payload.type = 'work';
		if (type === 'newWork') {
			dispatch(updateTempWorkOrderContacts(index, payload));
		} else if (type === 'newProject') {
			dispatch(updateTempCreateProjectContacts(index, payload));
		}
		dispatch({
			type: 'CONTACT_MODAL_CLOSE'
		});
		dispatch({
			type: 'CONTACT_MODAL_CLEAR_FIELDS'
		});
	}
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'CONTACT_MODAL_LOADING',
			payload: {
				state: value
			}
		});
	};
};

export const contactToggleOption = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'CONTACT_MODAL_TOGGLE_OPTION',
			payload: {
				value
			}
		});
	};
};
