import React, {Component} from 'react';
import {Label, Segment, Grid, Divider, Dropdown, Button, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {certifications} from '../../../../../../constants/certifications.js';
import {workPerformed} from '../../../../../../constants/workPerformed.js';

import {
	workPerformedOptions,
	equipmentOptions,
	updateAasdiCertifications,
	getCertifications,
	addCert,
	closeError,
	addWP,
	updateAasdiWP
} from '../services/profileAasdiGridActions.js';
import {toggleFetchingOnTabsNavbar} from '../../../../../../components/Global/services/globalActions.js';

const labelStyle = {
	backgroundColor: 'transparent',
	fontSize: '1rem',
	color: 'rgba(0,0,0,.8)',
	paddingLeft: '0'
};

class CertificationsTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			workPerformedValues: [],
			equipmentValues: []
		};
	}

	componentDidMount() {
		this.props.getWorkPerformedOptions();
		this.props.getEquipmentOptions();
		this.props.toggleFetchingOnTabsNavbar(false);
		this.props.getCertifications(this.props.aasdiId);
	}

	render() {
		const {workPerformedOptions, equipmentOptions} = this.props;
		const {workPerformedValues, equipmentValues} = this.state;
		return (
			<div>
				<Segment>
					<Label color="blue" ribbon>
						Work
					</Label>
					<Divider />
					<Grid columns="equal">
						{this.props.wpError ? (
							<Segment
								style={{
									width: '80vw',
									margin: '4vh'
								}}
								fluid
								textAlign="center"
							>
								<h2 style={{color: 'black'}}>An error occurred with work performed</h2>
								<Icon
									name="x"
									color="red"
									size="massive"
									style={{
										margin: '4vh'
									}}
								/>
								<br />
								<Button
									onClick={() => {
										this.props.closeError();
									}}
								>
									close
								</Button>
							</Segment>
						) : (
							<Grid.Row as={Segment} basic loading={this.props.wpLoading}>
								<Grid.Column>
									<Label style={labelStyle}>Work Performed</Label>
									<br />
									<Dropdown
										fluid
										multiple
										selection
										options={workPerformed.map((wp) => {
											return {
												key: wp.id,
												value: wp.id,
												text: wp.name
											};
										})}
										value={this.props.workPerformed}
										onChange={(e, {value}) => {
											this.props.addWP(value);
										}}
									/>
								</Grid.Column>
							</Grid.Row>
						)}
						<Grid.Row>
							<Grid.Column>
								<Label style={labelStyle}>{`Equipment(not functional)`}</Label>
								<br />
								<Dropdown fluid multiple disabled selection />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<Button
									disabled={this.props.wpLoading}
									style={{
										backgroundColor: '#0f4b90',
										color: 'white'
									}}
									onClick={() => {
										this.props.updateAasdiWP(
											this.props.oldWorkPerformed,
											this.props.workPerformed,
											this.props.aasdiId
										);
									}}
								>
									Save
								</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
				<Segment>
					<Label color="blue" ribbon>
						Certifications
					</Label>
					<Divider />
					<Grid columns="equal">
						{this.props.certError ? (
							<Segment
								style={{
									width: '80vw',
									margin: '4vh'
								}}
								fluid
								textAlign="center"
							>
								<h2 style={{color: 'black'}}>An error occurred with certifications</h2>
								<Icon
									name="x"
									color="red"
									size="massive"
									style={{
										margin: '4vh'
									}}
								/>
								<br />
								<Button
									onClick={() => {
										this.props.closeError();
									}}
								>
									close
								</Button>
							</Segment>
						) : (
							certifications.map((category, index) => {
								return (
									<Grid.Row key={index}>
										<Grid.Column
											as={Segment}
											style={{
												margin: '2vh'
											}}
											loading={this.props.certLoading}
										>
											<Label attached="top left">{category.name}</Label>
											<br />
											{category.cert.map((cert) => {
												return (
													<Segment
														basic
														key={cert.id}
														style={{
															paddingBottom: '0',
															paddingTop: '0',
															marginBottom: '0.5vh',
															marginTop: '0.5vh'
														}}
													>
														{cert.name}
														<Dropdown
															fluid
															multiple
															selection
															options={cert.subCert.map((sub) => {
																return {
																	key: sub.id,
																	value: sub.id,
																	text: sub.name
																};
															})}
															value={this.props.certifications}
															onChange={(e, {value}) => {
																this.props.addCert(value);
															}}
														/>
													</Segment>
												);
											})}
										</Grid.Column>
									</Grid.Row>
								);
							})
						)}
						<Grid.Row>
							<Grid.Column>
								<Button
									disabled={this.props.certLoading}
									style={{
										backgroundColor: '#0f4b90',
										color: 'white'
									}}
									onClick={() => {
										this.props.updateAasdiCertifications(
											this.props.oldCertifications,
											this.props.certifications,
											this.props.aasdiId
										);
									}}
								>
									Save
								</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</div>
		);
	}
}

CertificationsTab.propTypes = {
	workPerformedOptions: PropTypes.array,
	equipmentOptions: PropTypes.array,
	getWorkPerformedOptions: PropTypes.func,
	getEquipmentOptions: PropTypes.func,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	certifications: PropTypes.array,
	oldCertifications: PropTypes.array,
	certLoading: PropTypes.bool,
	certError: PropTypes.bool,
	closeError: PropTypes.func,
	getCertifications: PropTypes.func,
	aasdiId: PropTypes.number,
	updateAasdiCertifications: PropTypes.func,
	addCert: PropTypes.func,
	wpLoading: PropTypes.bool,
	wpError: PropTypes.bool
};

const mapStateToProps = (state) => ({
	workPerformedOptions: state.work.aasdiProfileGrid.workPerformedOptions,
	equipmentOptions: state.work.aasdiProfileGrid.equipmentOptions,
	certifications: state.work.aasdiProfileGrid.certifications,
	oldCertifications: state.work.aasdiProfileGrid.orgCertifications,
	certLoading: state.work.aasdiProfileGrid.certificationsLoading,
	certError: state.work.aasdiProfileGrid.certificationsError,
	workPerformed: state.work.aasdiProfileGrid.wpArray,
	oldWorkPerformed: state.work.aasdiProfileGrid.orgWpArray,
	wpLoading: state.work.aasdiProfileGrid.wpLoading,
	wpError: state.work.aasdiProfileGrid.wpError
});

const mapDispatchToProps = (dispatch) => ({
	getWorkPerformedOptions: () => dispatch(workPerformedOptions()),
	getEquipmentOptions: () => dispatch(equipmentOptions()),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	updateAasdiCertifications: (oldCerts, newCarts, aasdiId) =>
		dispatch(updateAasdiCertifications(oldCerts, newCarts, aasdiId)),
	getCertifications: (aasdiId) => dispatch(getCertifications(aasdiId)),
	addCert: (id) => dispatch(addCert(id)),
	addWP: (id) => dispatch(addWP(id)),
	closeError: () => dispatch(closeError()),
	updateAasdiWP: (oldWP, newWP, aasdiId) => dispatch(updateAasdiWP(oldWP, newWP, aasdiId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationsTab);
