import React from 'react';
import {Segment, Grid, Label, Button, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import DocumentSectionCheckbox from './components/workOrderDocumentsCheckbox.js';
import DocumentSectionRemoveButton from './components/workOrderDocumentsRemoveButton.js';
import InputField from '../../components/components/workOrderInputField.js';
import DropdownField from '../../components/components/workOrderDropDownField.js';
import TextAreaField from '../../components/components/workOrderTextAreaField.js';
import {
	updateCreateWorkOrderModal,
	addDocument,
	updateCreateWorkOrderModalDocumentGrid,
	removeCreateWorkOrderModalDocumentGrid
} from '../../services/createWorkOrderActions.js';

const DocumentsSection = (props) => (
	<Segment>
		<Label color="blue" ribbon>
			Attachments
		</Label>
		<Grid>
			<Grid.Row>
				{props.sectionFields.map((field, fieldIndex) =>
					field.input ? (
						<Grid.Column width={field.columnWidth} key={fieldIndex}>
							<InputField field={field} sectionIndex={props.sectionIndex} fieldIndex={fieldIndex} />
						</Grid.Column>
					) : field.dropdown ? (
						<Grid.Column width={field.columnWidth} key={fieldIndex}>
							<DropdownField field={field} sectionIndex={props.sectionIndex} fieldIndex={fieldIndex} />
						</Grid.Column>
					) : field.textArea ? (
						<Grid.Column width={field.columnWidth} key={fieldIndex}>
							<TextAreaField field={field} sectionIndex={props.sectionIndex} fieldIndex={fieldIndex} />
						</Grid.Column>
					) : null
				)}
				<Grid.Column
					width={15}
					style={{
						paddingTop: '0.8em'
					}}
				>
					<div>
						<Button
							primary
							onClick={() =>
								props.addDocument(
									props.sectionFields[0],
									props.sectionFields[1],
									props.sectionFields[2],
									props.sectionFields[3],
									props.sectionIndex
								)
							}
						>
							<Icon name="plus" />
							Add Document
						</Button>
						{props.sectionError ? (
							<Label color="red" style={{position: 'relative'}} pointing="left">
								{'Please add a customer PO before creating'}
							</Label>
						) : null}
					</div>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={15}>
					<div
						className="ag-theme-balham"
						style={{
							height: '20vh',
							width: '100%',
							textAlign: 'left',
							boxSizing: 'border-box',
							backgroundColor: props.sectionError ? '#fff6f6' : null
						}}
					>
						<AgGridReact
							suppressHorizontalScroll={true}
							columnDefs={columnDefs}
							defaultColDef={{
								sortable: true,
								filter: true,
								resizable: true
							}}
							rowHeight={40}
							id="documentGrid"
							rowData={props.documentsData ? props.documentsData : null}
							enableStatusBar={true}
							alwaysShowStatusBar={false}
							modules={AllModules}
							onGridReady={onGridReady}
							enableRangeSelection={true}
							// enable delta updates
							// deltaRowDataMode={true}
							// return id required for delta updates
							// getRowNodeId={data => data.id}
							{...props}
						/>
					</div>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</Segment>
);

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'documentsSection'
		),
		sectionFields: state.work.createWorkOrder.createWorkOrderModal.sections.filter(
			(section) => section.name === 'documentsSection'
		)[0].fields,
		documentsData: state.work.createWorkOrder.createWorkOrderModal.sections.filter(
			(section) => section.name === 'documentsSection'
		)[0].documents,
		sectionError: state.work.createWorkOrder.createWorkOrderModal.sections.filter(
			(section) => section.name === 'documentsSection'
		)[0].error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateWorkOrderModal: (modal, sectionIndex, fieldIndex, value, error) => {
			dispatch(updateCreateWorkOrderModal(modal, sectionIndex, fieldIndex, value, error));
		},
		addDocument: (fileName, googleDrive, customerApproval, comments, sectionIndex) => {
			dispatch(addDocument(fileName, googleDrive, customerApproval, comments, sectionIndex));
		},
		updateCreateWorkOrderModalDocumentGrid: (rowIndex, sectionIndex, value) => {
			dispatch(updateCreateWorkOrderModalDocumentGrid(rowIndex, sectionIndex, value));
		},
		removeCreateWorkOrderModalDocumentGrid: (rowIndex, sectionIndex) => {
			dispatch(removeCreateWorkOrderModalDocumentGrid(rowIndex, sectionIndex));
		}
	};
};

const columnDefs = [
	{
		headerName: 'File Name',
		field: 'fileName',
		cellStyle: {paddingTop: '.4em'}
		// cellRendererFramework: ActiveToggleCellRenderer
	},
	{
		headerName: 'Google Drive',
		field: 'googleDrive',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function(params) {
			return `<a href="${params.value}" target="_blank">${params.value}</a>`;
		}
	},
	{
		headerName: 'Comments',
		field: 'comments',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Customer Approval',
		field: 'customerApproval',
		cellStyle: {paddingTop: '.4em'},
		cellRendererFramework: DocumentSectionCheckbox
	},
	{
		headerName: 'Remove',
		cellStyle: {paddingTop: '.4em'},
		cellRendererFramework: DocumentSectionRemoveButton
	}
];

const onGridReady = (params) => {
	params.api.sizeColumnsToFit();
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsSection);
