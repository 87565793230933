import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Overview from './overview/overview.js';
import ManageAASDI from './manageAASDI/manageAASDI.js';
import Orders from './orders/Orders.js';
import Tasks from './tasks/tasks.js';
import BillingCenterWO from './billingCenter/billingCenterWO/billingCenterWO.js';
import BillingCenterPJ from './billingCenter/billingCenterPJ/billingCenterPJ.js';
import ProjectCostPJ from './projectCost/projectCostPJ/projectCostPJ.js';
import ProjectCostWO from './projectCost/projectCostWO/projectCostWO.js';
import EventLog from './eventLog/eventlog.js';
import Documents from './documents/documents.js';
import ChangeOrders from './changeOrders/changeOrders.js';

import {selectActiveStep, selectWorkRecord} from '../services/workRecordSelectors.js';
import WorkRecordErrorMessage from '../components/WorkRecordErrorMessage.js';

const activeStepHashmap = (props) => ({
	Overview: <Overview />,
	'Manage AASDI': <ManageAASDI />,
	Orders: <Orders />,
	'Change Orders': <ChangeOrders />,
	Tasks: <Tasks {...props} />,
	'Billing Center': <BillingCenterWO />,
	'Project Cost': (
		<div>
			<ProjectCostWO />
			<ProjectCostPJ />
		</div>
	),
	'Event Log': <EventLog />,
	Documents: <Documents />,
	Financials:
		props.workRecord.isProject && props.workRecord.isGroupBillable ? (
			// Project and Group Billable
			<div>
				<BillingCenterPJ />
				<ProjectCostPJ />
			</div>
		) : (
			// Project
			<div>
				<ChangeOrders />
				<BillingCenterPJ />
				<ProjectCostWO />
				<ProjectCostPJ />
			</div>
		)
});

let Step = (props) => {
	return (
		<div style={{marginTop: 40}}>
			<WorkRecordErrorMessage />
			{activeStepHashmap(props)[props.activeStep]}
		</div>
	);
};

Step.propTypes = {
	activeStep: PropTypes.string,
	workRecord: PropTypes.object
};

const mapStateToProps = (state) => ({
	activeStep: selectActiveStep(state),
	workRecord: selectWorkRecord(state)
});

export default connect(mapStateToProps, null)(Step);
