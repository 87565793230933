import {Card, Form} from 'semantic-ui-react';
import React from 'react';
// import PropTypes from 'prop-types';
import CompletedHeader from './PanelHeader_Completed.js';
import EditHeader from './PanelHeader_Edit.js';
import TaskType from './PanelContent_TaskType.js';
import DropdownTaskType from './PanelContent_Dropdown.js';
import DefaultTaskType from './PanelContent_Default.js';
import DirectionsTaskType from './PanelContent_Directions.js';
import TextBoxInputTaskType from './PanelContent_TextBoxInput.js';
import FreeFormUserInputTaskType from './PanelContent_FreeFormUserInput.js';
import FormUserInputTaskType from './PanelContent_FormUserInput.js';
import SelectedChoiceTaskType from './PanelContent_SelectedChoice.js';
import DriveLinksTaskType from './PanelContent_DriveLinks.js';
import CheckOutTaskType from './PanelContent_CheckOut.js';
import InputMaskTaskType from './PanelContent_InputMask.js';
import ChoiceNameTaskType from './PanelContent_ChoiceName.js';
import ClosePanel from './Panel_Close.js';
import CompleteModal from './Modal_CompleteTask.js';
import PanelButtonGroup from './Panel_ButtonGroup.js';

const TaskPanel = (props) => {
	return [
		props.task.completed
			? [<ClosePanel key={'closeCompletedPanel'} {...props} />, <CompletedHeader key={'completedTaskHeader'} />]
			: [
					<ClosePanel key={'closeEditPanel'} {...props} />,
					<EditHeader key={'editTaskHeader'} id={props.task.id} />
			  ],
		<Card.Content key={'inputFields'}>
			<Form>
				<TaskType {...props} />
				{(() => {
					if (props.tasks.taskList.some((type) => type.taskName === props.task.name)) {
						return props.tasks.taskList
							.filter((type) => type.taskName === props.task.name)[0]
							.columnList.split(',')
							.map((column) => {
								switch (column) {
									case 'dropdown':
										return <DropdownTaskType {...props} key={column} column={column} />;
									case 'choiceName':
										return <ChoiceNameTaskType {...props} key={column} column={column} />;
									case 'inputMask':
										return <InputMaskTaskType {...props} key={column} column={column} />;
									case 'customerVerification':
									case 'requiresOmniApproval':
									case 'approved':
									case 'code':
										return null;
									case 'asdVerification':
										return <CheckOutTaskType {...props} key={column} column={column} />;
									case 'pictureDriveLinks':
									case 'signatureDriveLinks':
										return <DriveLinksTaskType {...props} key={column} column={column} />;
									case 'selectedChoice':
										return <SelectedChoiceTaskType {...props} key={column} column={column} />;
									case 'formUserInput':
										return <FormUserInputTaskType {...props} key={column} column={column} />;
									case 'freeFormUserInput':
										return <FreeFormUserInputTaskType {...props} key={column} column={column} />;
									case 'directions':
										return <DirectionsTaskType {...props} key={column} column={column} />;
									case 'textBoxInput':
										return <TextBoxInputTaskType {...props} key={column} column={column} />;
									default:
										return <DefaultTaskType {...props} key={column} column={column} />;
								}
							});
					}
				})()}
			</Form>
		</Card.Content>,
		<Card.Content key={'PanelButtonGroup'}>
			<PanelButtonGroup {...props} />
		</Card.Content>,
		<CompleteModal key={'PanelCompleteModal'} {...props} />
	];
};

export default TaskPanel;
