import React from 'react';
import {Segment} from 'semantic-ui-react';
import {Route} from 'react-router-dom';

// Component
import ACCategory from './scenes/acCategoryScreen/acCategoryScreen.js';
import ACHomeScreen from './scenes/acHomeScreen/acHomeScreen.js';

class AccountingConsole extends React.Component {
	render() {
		return (
			<Segment
				key={'work-dashboard'}
				style={{
					left: '15rem',
					right: '0',
					top: '2rem',
					overflowY: 'auto',
					height: '94.5vh',
					position: 'fixed'
				}}
				basic
			>
				<Route path={`/accountingConsole/view`} component={ACHomeScreen} />
				<Route path={`/accountingConsole/category`} component={ACCategory} />
			</Segment>
		);
	}
}

export default AccountingConsole;
