import React from 'react';
import {Label, Grid} from 'semantic-ui-react';

function GridHeader(props) {
	const {message, addButton} = props;
	return (
		<Label attached="top" color="blue" style={{padding: 0}}>
			<Grid>
				<Grid.Column width={14} verticalAlign={'middle'} style={{marginLeft: 14}}>
					{message}
				</Grid.Column>
				<Grid.Column floated={'right'}>{addButton}</Grid.Column>
			</Grid>
		</Label>
	);
}

export default GridHeader;
