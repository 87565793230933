import React from 'react';
import {
	Button,
	Modal,
	Label,
	Icon,
	Header,
	Grid,
	Dropdown,
	Input,
	Dimmer,
	Loader,
	Step,
	Radio
} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';

import {
	toggleCreateLocationModal,
	updateCreateLocationModal,
	setActiveStep,
	getContacts,
	fillContactFields,
	updateRadio,
	createLocation,
	closeDimmer
} from './services/createLocationModalActions.js';

import {debounce, debounceEventHandler} from '../../lib/old/debounce.js';

const CreateLocationModal = (props) => (
	<Modal open={props.toggle} size={'small'}>
		<Header>
			<Icon name="plus" color="green" />
			<div className="content">Create Location</div>
			<Step.Group widths={3} size="small">
				{props.modalSteps.map((modalStep, index) => (
					<Step
						key={modalStep.name}
						icon={modalStep.icon}
						// description: modal.description,
						title={modalStep.name}
						active={modalStep.active}
						link={true}
						onClick={() => props.setActiveStep(props.activeStep, index)}
					/>
				))}
			</Step.Group>
		</Header>
		<Modal.Content scrolling={true} style={{paddingBottom: '3rem'}} className="createModalContent">
			{props.loading ? (
				<Dimmer inverted active className="centerDimmer">
					{props.loading === 'start' ? (
						<Loader size="big">Loading</Loader>
					) : props.loading === 'progress' ? (
						<Loader size="big">Creating Location</Loader>
					) : (
						// Error handling
						<Header as="h3" icon>
							<Icon name="cancel" color="red" />
							<div style={{paddingBottom: '.5em', color: 'black'}}>Location Creation Failed</div>
							<div style={{color: 'black'}}>Please try again.</div>
							<Button
								onClick={() => {
									props.closeDimmer(null);
								}}
								style={{marginTop: '1.5em'}}
							>
								Close Message
							</Button>
						</Header>
					)}
				</Dimmer>
			) : null}
			{props.modalSteps.map((modal, stepIndex) =>
				modal.active ? (
					<Grid key={stepIndex}>
						{stepIndex === 1 ? (
							<Grid.Row
								style={{
									padding: '1.4em 0'
								}}
							>
								<Grid.Column width={4}>
									<Radio
										label="Existing Contact"
										name="radioGroup"
										checked={props.existingRadio}
										onClick={() => props.updateRadio('existing', 'newContact')}
									/>
								</Grid.Column>
								<Grid.Column width={4}>
									<Radio
										label="New Contact"
										name="radioGroup"
										checked={props.newContactRadio}
										onClick={() => props.updateRadio('newContact', 'existing')}
									/>
								</Grid.Column>
							</Grid.Row>
						) : null}
						{modal.modalFields.map((field, fieldIndex) =>
							field.input || (field.name === 'State' && props.countryField !== 218) ? (
								<Grid.Row key={fieldIndex}>
									<Grid.Column width={16}>
										<div
											style={{
												paddingTop: '0.5em'
											}}
										>
											<Label
												style={{
													backgroundColor: 'transparent',
													fontSize: '1rem',
													color: 'rgba(0,0,0,.8)',
													paddingLeft: '0'
												}}
											>
												{field.name}
											</Label>
										</div>
										<DebounceInput
											element={Input}
											onChange={(e) => {
												props.updateCreateLocationModal(
													'createLocationModal',
													stepIndex,
													fieldIndex,
													e.target.value,
													false
												);
											}}
											debounceTimeout={600}
											value={field.value}
											style={{
												width: '100%'
											}}
											error={field.required || field.checkGroup ? Boolean(field.error) : false}
											disabled={Boolean(props.existingRadio && props.activeStep === 1)}
											placeholder={field.placeholder}
										/>
									</Grid.Column>
								</Grid.Row>
							) : props.newContactRadio && props.activeStep === 1 ? null : (
								<Grid.Row key={fieldIndex}>
									<Grid.Column width={16}>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											{field.name}
										</Label>
										<Dropdown
											className="createModalDropdown"
											placeholder={field.placeholder}
											fluid
											search
											selection
											options={field.options}
											onChange={(e, {value}) => {
												props.updateCreateLocationModal(
													'createLocationModal',
													stepIndex,
													fieldIndex,
													value,
													false
												);
												if (field.name === 'Search for Contact') {
													props.fillContactFields(stepIndex, fieldIndex, value);
												}
											}}
											onSearchChange={debounceEventHandler(
												debounce((e) => {
													if (e.target.value) {
														if (field.searchContact) {
															props.getContacts(
																stepIndex,
																fieldIndex,
																e.target.value,
																props.clientId
															);
														}
													}
												}, 600)
											)}
											icon={field.icon}
											value={field.value}
											error={field.required || field.checkGroup ? Boolean(field.error) : false}
											disabled={field.disabled}
											upward={field.upward}
											loading={field.loading}
											defaultSearchQuery={field.searchQuery}
										/>
									</Grid.Column>
								</Grid.Row>
							)
						)}
					</Grid>
				) : null
			)}
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleCreateLocationModal(null, null);
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					// disabled
					onClick={() => {
						props.createLocation(props.clientId, props.type);
					}}
					color="green"
				>
					<Icon name="checkmark" /> Create
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

const mapStateToProps = (state) => {
	return {
		toggle: state.global.createLocationModal.createLocationModal.toggleCreateLocationModal,
		loading: state.global.createLocationModal.createLocationModal.loading,
		clientId: state.global.createLocationModal.createLocationModal.clientId,
		modalSteps: state.global.createLocationModal.createLocationModal.modalSteps,
		activeStep: state.global.createLocationModal.createLocationModal.modalSteps
			.map((modalStep, index) => ({modalStep: modalStep, index: index}))
			.filter((modalStep) => modalStep.modalStep.active)[0].index,
		existingRadio: state.global.createLocationModal.createLocationModal.radioGroup.existing,
		newContactRadio: state.global.createLocationModal.createLocationModal.radioGroup.newContact,
		countryField: state.global.createLocationModal.createLocationModal.modalSteps[0].modalFields.filter(
			(field) => field.name === 'Country'
		)[0].value
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleCreateLocationModal: (id, clientId) => {
			dispatch(toggleCreateLocationModal(id, clientId));
		},
		updateCreateLocationModal: (modal, step, column, value, error) => {
			dispatch(updateCreateLocationModal(modal, step, column, value, error));
		},
		setActiveStep: (currentStep, targetStep) => {
			dispatch(setActiveStep(currentStep, targetStep));
		},
		createLocation: (clientId, type) => {
			dispatch(createLocation(clientId, type));
		},
		getContacts: (step, column, value, clientId) => {
			dispatch(getContacts(step, column, value, clientId));
		},
		fillContactFields: (step, column, value) => {
			dispatch(fillContactFields(step, column, value));
		},
		updateRadio: (previous, target) => {
			dispatch(updateRadio(previous, target));
		},
		closeDimmer: (value) => {
			dispatch(closeDimmer(value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocationModal);
