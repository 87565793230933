import React, {Component} from 'react';
import {Label, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import TabsNavbar from '../../../../../components/Global/TabsNavbar.js';
import Overview from './components/Overview.js';
import Documents from './components/Documents.js';
import Work from './components/Work.js';
import Ratings from './components/Ratings.js';
import LocationsTab from './components/LocationsTab.js';
import CertificationsTab from './components/CertificationsTab.js';
import CompanyProfile from './components/CompanyProfileTab.js';

import {toggleFetchingOnTabsNavbar} from '../../../../../components/Global/services/globalActions.js';

class ProfileAasdiGridView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			tab: 'Overview',
			isLoading: false,
			aasdiId: this.getAasdiId()
		};
		this.tabClick = this.tabClick.bind(this);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	getAasdiId = () => {
		const url = window.location.href;
		return url.substring(url.indexOf('/profile') + 9, url.length);
	};

	tabClick(item) {
		const {tab} = this.state;
		if (tab === item) {
			return;
		}
		this.setState({...this.state, tab: item});
		this.props.toggleFetchingOnTabsNavbar(true);
	}

	render() {
		const {stageNames, aasdiProfileName} = this.props;
		const {isLoading, tab, aasdiId} = this.state;
		return (
			<Segment basic style={{marginTop: '1.5rem'}}>
				<Label attached="top" color="blue" style={{position: 'relative'}}>
					{aasdiProfileName}
				</Label>
				<TabsNavbar stageNames={stageNames} onClick={this.tabClick} disabled={isLoading} />
				{tab === 'Overview' ? <Overview aasdiId={aasdiId} /> : null}
				{tab === 'Work' ? <Work aasdiId={aasdiId} /> : null}
				{tab === 'Documents' ? <Documents aasdiId={aasdiId} /> : null}
				{tab === 'Ratings' ? <Ratings aasdiId={aasdiId} /> : null}
				{tab === 'Certifications' ? <CertificationsTab aasdiId={aasdiId} /> : null}
				{tab === 'Locations' ? <LocationsTab aasdiId={aasdiId} /> : null}
				{tab === 'Company Profile' ? <CompanyProfile aasdiId={aasdiId} /> : null}
			</Segment>
		);
	}
}

ProfileAasdiGridView.propTypes = {
	aasdiProfileName: PropTypes.string,
	getAasdiProfileGrid: PropTypes.func,
	stageNames: PropTypes.array,
	toggleFetchingOnTabsNavbar: PropTypes.func
};

const mapStateToProps = (state) => ({
	aasdiProfileName: state.work.aasdiProfileGrid.profileName,
	stageNames: state.work.aasdiProfileGrid.stageNames
});

const mapDispatchToProps = (dispatch) => ({
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAasdiGridView);
