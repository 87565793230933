// Modules
import React from 'react';
import {Grid, Segment, Label, Statistic, Icon, Menu, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Services
import {selectCurrentStage} from '../services/analyticsActions.js';
import {getStageAnalytics} from '../../../services/pipelineActions.js';

let Stage = ({stage, currentStage, loading, error, selectCurrentStage, getStageAnalytics}) => (
	<Segment>
		<Label attached="top" color="blue" content={`Stage Analytics`} />
		{!loading ? (
			error ? (
				<Segment basic>
					Hmmm, it looks like something went wrong when I was trying to pull this data. Try again, or contact
					the Dev team if it keeps happening
					<Segment basic className="error-segment">
						<Button icon onClick={() => getStageAnalytics()}>
							<Icon name="refresh" />
						</Button>
					</Segment>
				</Segment>
			) : (
				[
					<div className="analytics-pipeline-overview">
						<Statistic size="mini" className="pipeline-statistic">
							<Statistic.Value>
								<Icon className="pipeline-statistic-icon" name="folder open outline" />
								{stage.count}
							</Statistic.Value>
							<Statistic.Label>Open Deals</Statistic.Label>
						</Statistic>

						<Statistic size="mini" className="pipeline-statistic">
							<Statistic.Value>
								<Icon className="pipeline-statistic-icon" name="bar chart" />
								{stage.revenue}
							</Statistic.Value>
							<Statistic.Label>Total Deals in Stage</Statistic.Label>
						</Statistic>

						<Statistic size="mini" className="pipeline-statistic">
							<Statistic.Value>
								<Icon className="pipeline-statistic-icon" name="area chart" />
								{stage.average}
							</Statistic.Value>
							<Statistic.Label>Avg Size Deal</Statistic.Label>
						</Statistic>

						<Statistic size="mini" className="pipeline-statistic">
							<Statistic.Value>
								<Icon className="pipeline-statistic-icon" name="clock outline" />
								{stage.averageTime}D
							</Statistic.Value>
							<Statistic.Label>Avg Time in Stage</Statistic.Label>
						</Statistic>
					</div>,
					<Menu icon="labeled" size="mini" className="analytics-pipeline-flex-menu" key="stage-menu">
						<Menu.Item
							as={'a'}
							name="Identified Need"
							active={currentStage === 'identifiedNeed'}
							onClick={() => selectCurrentStage('identifiedNeed')}
							className="flexible-menu-item"
						>
							Identified Need
						</Menu.Item>

						<Menu.Item
							as={'a'}
							name="Estimating"
							active={currentStage === 'estimating'}
							onClick={() => selectCurrentStage('estimating')}
							className="flexible-menu-item"
						>
							Estimating
						</Menu.Item>

						<Menu.Item
							as={'a'}
							name="Submitted Quote"
							active={currentStage === 'submittedQuote'}
							onClick={() => selectCurrentStage('submittedQuote')}
							className="flexible-menu-item"
						>
							Submitted Quote
						</Menu.Item>

						<Menu.Item
							as={'a'}
							name="High Probability"
							active={currentStage === 'highProbability'}
							onClick={() => selectCurrentStage('highProbability')}
							className="flexible-menu-item"
						>
							High Probability
						</Menu.Item>

						<Menu.Item
							as={'a'}
							name="Verbal Award"
							active={currentStage === 'verbalAward'}
							onClick={() => selectCurrentStage('verbalAward')}
							className="flexible-menu-item"
						>
							Verbal Award
						</Menu.Item>

						<Menu.Item
							as={'a'}
							name="Closed Business"
							active={currentStage === 'closedBusiness'}
							onClick={() => selectCurrentStage('closedBusiness')}
							className="flexible-menu-item"
						>
							Closed Business
						</Menu.Item>

						<Menu.Item
							as={'a'}
							name="Lost Business"
							active={currentStage === 'lostJob'}
							onClick={() => selectCurrentStage('lostJob')}
							className="flexible-menu-item"
						>
							Lost Business
						</Menu.Item>
					</Menu>
				]
			)
		) : (
			<Segment basic loading={loading} className="loading-segment" />
		)}
	</Segment>
);

Stage.propTypes = {
	selectCurrentStage: PropTypes.func.isRequired,
	getStageAnalytics: PropTypes.func.isRequired,
	stage: PropTypes.object.isRequired,
	loading: PropTypes.string.isRequired,
	currentStage: PropTypes.string.isRequired,
	error: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	return {
		stage:
			state.crm.pipeline.analytics.stages.currentStage !== ''
				? state.crm.pipeline.analytics.stages.data[state.crm.pipeline.analytics.stages.currentStage]
				: {},
		loading: state.crm.pipeline.analytics.stages.loading,
		currentStage: state.crm.pipeline.analytics.stages.currentStage,
		error: state.crm.pipeline.analytics.stages.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		selectCurrentStage: (stageName) => {
			dispatch(selectCurrentStage(stageName));
		},
		getStageAnalytics: () => {
			dispatch(getStageAnalytics());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Stage);
