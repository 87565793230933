import React from 'react';
import PropTypes from 'prop-types';
import {Popup, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const DetailsButton = ({category, record}) => {
	let linkPath;

	switch (category) {
		case 'Project': {
			linkPath = `/crm/dashboard/opportunity/${record.id}`;
			break;
		}

		case 'Client': {
			linkPath = `/work/dashboard/client/profile/${record.id}`;
			break;
		}

		case 'AASDI': {
			linkPath = `/work/dashboard/aasdi/profile/${record.id}`;
			break;
		}

		case 'Invoice': {
			if (record.groupNumber !== null) {
				linkPath = `/work/view/${record.parentWorkId}`;
			} else {
				linkPath = `/work/view/${record.workId}`;
			}
			break;
		}

		case 'Closure': {
			linkPath = `/work/view/${record.id}`;
			break;
		}

		case 'Rejections': {
			if (record.clientId) {
				linkPath = `/work/dashboard/client/profile/${record.clientId}`;
			}

			if (record.workId) {
				linkPath = `/work/view/${record.workId}`;
			}

			if (record.aasdiId) {
				linkPath = `/work/dashboard/aasdi/profile/${record.aasdiId}`;
			}

			if (record.opportunityId) {
				linkPath = `/crm/dashboard/opportunity/${record.opportunityId}`;
			}
			break;
		}

		default: {
			linkPath = '';
			break;
		}
	}

	return linkPath ? (
		<Popup
			trigger={
				<Link to={linkPath} target="_blank">
					<Button
						icon="linkify"
						style={{
							backgroundColor: '#e8e8e8',
							padding: '.5em .5em .5em',
							marginLeft: '.5em'
						}}
					/>
				</Link>
			}
			content="Details"
		/>
	) : null;
};

DetailsButton.propTypes = {
	record: PropTypes.object,
	category: PropTypes.string
};

export default DetailsButton;
