import update from 'immutability-helper';
import countryOptions from '~/constants/countries.js';
import stateOptions from '~/constants/states.js';
import industryOptions from '~/constants/industries.js';
import leadSourceOptions from '~/constants/leadTypes.js';
import {clientDocTypeOptions} from '~/constants/docTypes.js';

export default (
	state = {
		loading: false,
		rowData: null,
		modalTitle: 'Add Client',
		isActivateModalOpen: false,
		isActivateModalLoading: false,
		isActivateClientErrorHidden: true,
		activateClientErrorMessage:
			'There was a problem activating the client. Please try again. If the problem persists, please contact the dev team.',
		isDeactivateModalOpen: false,
		isDeactivateModalLoading: false,
		isDeactivateClientErrorHidden: true,
		deactivateClientErrorMessage:
			'There was a problem deactivating the client. Please try again. If the problem persists, please contact the dev team.',
		showCreateClientModal: false,
		showCustomerCreationModal: false,
		showProspectCreationModal: false,
		headerClient,
		AccExecDropDownSearchOptions: [],
		AccExecDropDownSearchLoader: false,
		AccManDropDownSearchOptions: [],
		AccManDropDownSearchLoader: false,
		ContactDropDownSearchOptions: [],
		ContactDropDownSearchLoader: false,
		dropDownSearchOptions: [],
		dropDownSearchLoader: false,
		createClientModalUpdate: null,
		stepsClient: {
			loading: false,
			error: false,
			errorMessage: 'There was a problem creating this Prospect',
			modalTitle: 'Create Prospect',
			modalSteps: [
				{
					name: 'Prospect',
					active: true,
					description: 'client description',
					icon: {name: 'user', color: 'black'},
					modalFields: [
						{
							name: 'Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true,
							split: true,
							key: 'name'
						},
						{
							name: 'Contact First Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'contactFirstName'
						},
						{
							name: 'Contact Last Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'contactLastName'
						},
						{
							name: 'Contact Email',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'contactEmail'
						},
						{
							name: 'Contact Phone',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'contactPhone'
						},
						{
							name: 'Lead Source',
							value: null,
							dropdown: true,
							options: leadSourceOptions,
							error: false,
							disabled: false,
							upward: true,
							required: false,
							split: true,
							key: 'leadSourceId'
						},
						{
							name: 'Address',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							key: 'address',
							split: true
						},
						{
							name: 'Address 2',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							key: 'address2',
							split: true
						},
						{
							name: 'City',
							input: true,
							value: null,
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'city'
						},
						{
							name: 'ZIP Code',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'zip'
						},
						{
							name: 'Country',
							value: 218,
							placeholder: 'Choose country',
							dropdown: true,
							options: countryOptions,
							error: false,
							disabled: false,
							upward: true,
							required: false,
							split: true,
							key: 'countryId'
						},
						{
							name: 'State',
							value: null,
							dropdown: true,
							options: stateOptions,
							error: false,
							disabled: false,
							upward: true,
							required: false,
							split: true,
							key: 'state'
						},
						{
							name: 'Website',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'website'
						},
						{
							name: 'Industry',
							value: null,
							placeholder: 'Choose client industry',
							dropdown: true,
							options: industryOptions,
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'industryId'
						},
						{
							name: 'Client Type',
							value: null,
							placeholder: 'Choose client industry',
							dropdown: true,
							options: [
								{
									key: 'Re-Seller',
									value: 'Re-Seller',
									text: 'Re-Seller'
								},
								{
									key: 'End User',
									value: 'End User',
									text: 'End User'
								}
							],
							error: false,
							disabled: false,
							required: false,
							split: true,
							key: 'clientType'
						}
					]
				},
				{
					name: 'Documents',
					active: false,
					description: 'Client Documents',
					icon: {name: 'file alternate', color: 'black'},
					documents: [],
					modalFields: [
						{
							name: 'File Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true,
							checkForm: true,
							columnWidth: 5,
							key: 'fileName'
						},
						{
							name: 'Google Drive Link',
							value: '',
							input: true,
							error: false,
							disabled: false,
							checkForm: true,
							required: true,
							columnWidth: 5,
							key: 'googleLink'
						},
						{
							name: 'Document Type',
							value: '',
							input: false,
							dropdown: true,
							error: false,
							checkForm: true,
							disabled: false,
							required: true,
							columnWidth: 5,
							options: clientDocTypeOptions,
							key: 'docType'
						},
						{
							name: 'Comments',
							value: '',
							input: false,
							textArea: true,
							error: false,
							disabled: false,
							required: false,
							columnWidth: 15,
							key: 'docComments'
						}
					]
				}
			]
		},
		stepsCustomer: {
			loading: false,
			error: false,
			errorMessage: 'There was a problem creating this Customer',
			modalTitle: 'Create Customer',
			hasFetchedDocuments: false,
			isSubmitting: false,
			modalSteps: [
				{
					name: 'Prospect',
					active: true,
					description: 'client description',
					icon: {name: 'user', color: 'black'},
					modalFields: createClientModalFields
				},
				{
					name: 'Documents',
					active: false,
					description: 'Client Documents',
					icon: {name: 'file alternate', color: 'black'},
					documents: [],
					documentsToDelete: [],
					modalFields: [
						{
							name: 'File Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true,
							checkForm: true,
							columnWidth: 5,
							key: 'fileName'
						},
						{
							name: 'Google Drive Link',
							value: '',
							input: true,
							error: false,
							disabled: false,
							checkForm: true,
							required: true,
							columnWidth: 5,
							key: 'googleLink'
						},
						{
							name: 'Document Type',
							value: '',
							input: false,
							dropdown: true,
							error: false,
							checkForm: true,
							disabled: false,
							required: true,
							columnWidth: 5,
							options: clientDocTypeOptions,
							key: 'docType'
						},
						{
							name: 'Comments',
							value: '',
							input: false,
							textArea: true,
							error: false,
							disabled: false,
							required: false,
							columnWidth: 15,
							key: 'docComments'
						}
					]
				}
			]
		},
		prospectFields: [
			{
				name: 'Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true,
				split: true,
				key: 'name'
			},
			{
				name: 'Contact First Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'contactFirstName'
			},
			{
				name: 'Contact Last Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'contactLastName'
			},
			{
				name: 'Contact Email',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'contactEmail'
			},
			{
				name: 'Contact Phone',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'contactPhone'
			},
			{
				name: 'Lead Source',
				value: null,
				options: leadSourceOptions,
				error: false,
				disabled: false,
				upward: true,
				required: false,
				split: true,
				key: 'leadSourceId'
			},
			{
				name: 'Address',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				key: 'address',
				split: true
			},
			{
				name: 'Address 2',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				key: 'address2',
				split: true
			},
			{
				name: 'City',
				input: true,
				value: null,
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'city'
			},
			{
				name: 'ZIP Code',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'zip'
			},
			{
				name: 'Country',
				value: 218,
				placeholder: 'Choose country',
				options: countryOptions,
				error: false,
				disabled: false,
				upward: true,
				required: false,
				split: true,
				key: 'countryId'
			},
			{
				name: 'State',
				value: null,
				options: stateOptions,
				error: false,
				disabled: false,
				upward: true,
				required: false,
				split: true,
				key: 'state'
			},
			{
				name: 'Website',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'website'
			},
			{
				name: 'Industry',
				value: null,
				placeholder: 'Choose client industry',
				options: industryOptions,
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'industryId'
			},
			{
				name: 'Client Type',
				value: null,
				placeholder: 'Choose client industry',
				dropdown: true,
				options: [
					{
						key: 'Re-Seller',
						value: 'Re-Seller',
						text: 'Re-Seller'
					},
					{
						key: 'End User',
						value: 'End User',
						text: 'End User'
					}
				],
				error: false,
				disabled: false,
				required: false,
				split: true,
				key: 'clientType'
			}
		]
	},
	action
) => {
	switch (action.type) {
		case 'UPDATE_DROP_DOWN_OPTIONS_ACC_EXECS':
			state = update(state, {
				AccExecDropDownSearchOptions: {
					$set: action.payload.json
				}
			});
			break;
		case 'UPDATE_DROP_DOWN_OPTIONS_ACC_MAN':
			state = update(state, {
				AccManDropDownSearchOptions: {
					$set: action.payload.json
				}
			});
			break;
		case 'UPDATE_DROP_DOWN_LOADER_ACC_EXECS':
			state = update(state, {
				AccExecDropDownSearchLoader: {
					$set: action.payload.value
				}
			});
			break;
		case 'UPDATE_DROP_DOWN_LOADER_ACC_MAN':
			state = update(state, {
				AccManDropDownSearchLoader: {
					$set: action.payload.value
				}
			});
			break;
		case 'UPDATE_DROP_DOWN_OPTIONS_CONTACTS':
			state = update(state, {
				ContactsDropDownSearchOptions: {
					$set: action.payload.json
				}
			});
			break;
		case 'UPDATE_DROP_DOWN_LOADER_CONTACTS':
			state = update(state, {
				ContactsDropDownSearchLoader: {
					$set: action.payload.value
				}
			});
			break;
		case 'WORK_FETCH_CLIENT_GRID':
			state = update(state, {
				rowData: {
					$set: action.payload
				}
			});
			break;

		case 'TOGGLE_CREATE_CLIENT_MODAL':
			state = update(state, {
				showCreateClientModal: {
					$set: action.payload
				}
			});
			break;

		case 'TOGGLE_ACTIVATE_CLIENT_MODAL':
			state = update(state, {
				isActivateModalOpen: {
					$set: !state.isActivateModalOpen
				}
			});
			break;

		case 'ACTIVATE_CLIENT_MODAL_LOADING':
			state = update(state, {
				isActivateModalLoading: {
					$set: !state.isActivateModalLoading
				}
			});
			break;

		case 'TOGGLE_ACTIVATE_CLIENT_ERROR':
			state = update(state, {
				isActivateClientErrorHidden: {
					$set: action.payload.hidden
				}
			});
			break;

		case 'TOGGLE_DEACTIVATE_CLIENT_MODAL':
			state = update(state, {
				isDeactivateModalOpen: {
					$set: !state.isDeactivateModalOpen
				}
			});
			break;

		case 'DEACTIVATE_CLIENT_MODAL_LOADING':
			state = update(state, {
				isDeactivateModalLoading: {
					$set: !state.isDeactivateModalLoading
				}
			});
			break;

		case 'TOGGLE_DEACTIVATE_CLIENT_ERROR':
			state = update(state, {
				isDeactivateClientErrorHidden: {
					$set: action.payload.hidden
				}
			});
			break;

		case 'UPDATE_CLIENT_STATUS':
			state = update(state, {
				rowData: {
					[action.payload.i]: {
						statusId: {
							$set: action.payload.statusId
						}
					}
				}
			});
			break;

		case 'TOGGLE_CUSTOMER_CREATION_MODAL':
			state = update(state, {
				showCustomerCreationModal: {
					$set: action.payload
				}
			});
			break;
		case 'CUSTOMER_CREATION_MODAL_RESET':
			state = update(state, {
				stepsClient: {
					$set: baseClient
				},
				stepsCustomer: {
					$set: baseCustomer
				},
				prospectFields: {
					$set: baseProspectFields
				}
			});
			break;
		case 'TOGGLE_PROSPECT_CREATION_MODAL':
			state = update(state, {
				showProspectCreationModal: {
					$set: action.payload
				}
			});
			break;
		case 'TOGGLE_PROSPECT_CREATION_MODAL_RESET':
			state = update(state, {
				showProspectCreationModal: {
					$set: action.payload
				},
				stepsClient: {
					$set: baseClient
				},
				stepsCustomer: {
					$set: baseCustomer
				},
				prospectFields: {
					$set: baseProspectFields
				}
			});
			break;
		case 'RESET_CLIENT_UPDATE':
			state = update(state, {
				createClientModalUpdate: {
					$set: null
				}
			});
			break;
		case 'NEW_PROSPECT_ADDED': {
			const {rowData} = state;
			if (rowData) {
				state = update(state, {
					rowData: {
						$push: action.payload
					}
				});
			} else {
				state = update(state, {
					createClientModalUpdate: {
						$set: action.payload[0]
					}
				});
			}
			break;
		}
		case 'UPDATE_CUSTOMER': {
			const {rowData} = state;
			if (rowData) {
				const copyOfRowData = [...rowData];
				const index = rowData.findIndex((item) => item.id === action.payload[0].id);
				if (index !== -1) {
					copyOfRowData[index] = action.payload[0];
				}
				state = update(state, {
					rowData: {
						$set: copyOfRowData
					}
				});
			} else {
				state = update(state, {
					createClientModalUpdate: {
						$set: action.payload[0]
					}
				});
			}
			break;
		}

		case 'CREATE_PROSPECT_MODAL_SET_ACTIVE_STEP':
			state = update(state, {
				[action.payload.stepType]: {
					modalSteps: {
						[action.payload.currentStep]: {
							active: {
								$set: false
							}
						},
						[action.payload.targetStep]: {
							active: {
								$set: true
							}
						}
					}
				}
			});

			break;
		case 'CREATE_CLIENT_REMOVE_DOC_GRID':
			state = update(state, {
				[action.payload.modalName]: {
					modalSteps: {
						[action.payload.sectionIndex]: {
							documents: {
								$splice: [[action.payload.rowIndex, 1]]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_CLIENT_MODAL_ADD_DOCUMENT':
			state = update(state, {
				[action.payload.stepType]: {
					modalSteps: {
						[action.payload.sectionIndex]: {
							documents: {
								$push: [action.payload.object]
							}
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_MODAL_UPDATE':
			state = update(state, {
				[action.payload.modal]: {
					modalSteps: {
						[action.payload.step]: {
							icon: {
								color: {
									$set: 'black'
								}
							},
							modalFields: {
								[action.payload.column]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									}
								}
							}
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_MODAL_SHOW_REQUIRED':
			state = update(state, {
				stepsClient: {
					modalSteps: {
						[action.payload.step]: {
							icon: {
								color: {
									$set: 'red'
								}
							},
							modalFields: {
								[action.payload.field]: {
									error: {
										$set: true
									}
								}
							}
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_MODAL_ERROR':
			state = update(state, {
				stepsClient: {
					error: {
						$set: action.payload.value
					},
					errorMessage: {
						$set: action.payload.message
					}
				}
			});
			break;
		case 'CREATE_CUSTOMER_MODAL_ERROR':
			state = update(state, {
				stepsCustomer: {
					error: {
						$set: action.payload.value
					},
					errorMessage: {
						$set: action.payload.message
					}
				}
			});
			break;
		case 'CREATE_CLIENT_MODAL_LOADING':
			state = update(state, {
				stepsClient: {
					loading: {
						$set: action.payload.value
					}
				}
			});
			break;
		case 'CREATE_CUSTOMER_MODAL_LOADING':
			state = update(state, {
				stepsCustomer: {
					loading: {
						$set: action.payload.value
					}
				}
			});
			break;
		case 'CREATE_CLIENT_MODAL_SET_DOCUMENTS':
			state = update(state, {
				[action.payload.stepType]: {
					modalSteps: {
						[action.payload.sectionIndex]: {
							documents: {
								$set: action.payload.object
							}
						}
					}
				}
			});
			break;
		case 'CREATE_CUSTOMER_SET_DOC_TO_DELETE':
			state = update(state, {
				[action.payload.modalName]: {
					modalSteps: {
						[action.payload.sectionIndex]: {
							documentsToDelete: {
								$push: [action.payload.document]
							}
						}
					}
				}
			});
			break;
		case 'SET_CUSTOMER_HAS_FETCHED_DOCUMENTS':
			state = update(state, {
				stepsCustomer: {
					hasFetchedDocuments: {
						$set: action.payload.value
					}
				}
			});
			break;
		case 'CREATE_CLIENT_MODAL_SET_DOCUMENTS_TO_DELETE':
			state = update(state, {
				[action.payload.stepType]: {
					modalSteps: {
						[action.payload.sectionIndex]: {
							documentsToDelete: {
								$set: action.payload.object
							}
						}
					}
				}
			});
			break;
		case 'SET_CUSTOMER_IS_SUBMITTING':
			state = update(state, {
				stepsCustomer: {
					isSubmitting: {
						$set: action.payload.value
					}
				}
			});
			break;
		default:
	}
	return state;
};

const statusValueGetter = (params) => {
	if (Number(params.data.statusId) === 3) {
		return 'Inactive';
	} else {
		return 'Active';
	}
};

const headerClient = [
	{
		headerName: 'Action bar',
		field: 'actionBar',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'clientActionCellRenderer',
		colId: 'actionColumn',
		minWidth: 100,
		maxWidth: 130
	},
	{
		headerName: 'Type',
		field: 'clientStatusName',
		cellRenderer: 'clientTypeCellRenderer',
		filter: 'agTextColumnFilter',
		editable: false,
		maxWidth: 80
	},
	{
		headerName: 'Status',
		field: 'statusId',
		valueGetter: statusValueGetter,
		filter: 'agSetColumnFilter',
		cellStyle: function (params) {
			if (params.data.statusId === 3) {
				return {color: 'red'};
			} else {
				return null;
			}
		},
		editable: false,
		maxWidth: 200
	},
	{
		headerName: 'Name',
		field: 'name',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'City',
		field: 'city',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'State',
		field: 'state',
		filter: 'agTextColumnFilter',
		cellEditor: 'descriptionCellRenderer',
		editable: false
	},
	{
		headerName: 'Website',
		field: 'website',
		filter: 'agTextColumnFilter',
		cellEditor: 'descriptionCellRenderer',
		editable: false,
		cellRenderer: function (params) {
			let url = params.value ? params.value.trim() : '';
			if (url && !url.includes('https://') && !url.includes('http://')) {
				url = 'http://' + url;
			}
			return `<a href="${url}" target="_blank">${url}</a>`;
		}
	},
	{
		headerName: 'Account Executive',
		field: 'accountExecutiveName',
		filter: 'agTextColumnFilter',
		cellEditor: 'descriptionCellRenderer',
		editable: false
	},
	{
		headerName: 'Account Manager',
		field: 'accountManagerName',
		filter: 'agTextColumnFilter',
		cellEditor: 'descriptionCellRenderer',
		editable: false
	},
	{
		headerName: 'YTD Revenue',
		field: 'ytdRevenue',
		filter: 'agTextColumnFilter',
		cellEditor: 'descriptionCellRenderer',
		editable: false
	},
	{
		headerName: 'Spectrum Code',
		field: 'spectrumCode',
		filter: 'agTextColumnFilter',
		cellEditor: 'descriptionCellRenderer',
		editable: false
	}
];

// key is the JSON key returned by the backend for client infos
export const createClientModalFields = (clientInfos) => [
	{
		label: 'Name',
		key: 'name',
		value: clientInfos.name,
		isDropdown: false,
		options: null,
		disabled: true,
		required: true
	},
	{
		label: 'Address',
		key: 'address',
		value: clientInfos.address,
		isDropdown: false,
		options: null,
		required: true
	},
	{
		label: 'Address 2',
		key: 'address2',
		value: clientInfos.address2,
		isDropdown: false,
		options: null,
		required: false
	},
	{
		label: 'Country',
		key: 'countryId',
		value: clientInfos.countryId,
		isDropdown: true,
		options: countryOptions,
		required: true
	},
	{
		label: 'State',
		key: 'state',
		value: clientInfos.state,
		isDropdown: clientInfos.countryId === 218,
		options: clientInfos.countryId === 218 ? stateOptions : null,
		required: true
	},
	{
		label: 'City',
		key: 'city',
		value: clientInfos.city,
		isDropdown: false,
		options: null,
		required: true
	},
	{
		label: 'Zip Code',
		key: 'zip',
		value: clientInfos.zip,
		isDropdown: false,
		options: null,
		required: true
	},
	{
		label: 'Website',
		key: 'website',
		value: clientInfos.website,
		isDropdown: false,
		options: null,
		required: true
	},
	{
		label: 'Lead Source',
		key: 'leadSourceId',
		value: clientInfos.leadSourceId,
		isDropdown: true,
		options: leadSourceOptions,
		required: true
	},
	{
		label: 'Client Type',
		key: 'clientType',
		value: clientInfos.clientType,
		isDropdown: true,
		required: true,
		options: [
			{
				key: 'End User',
				text: 'End User',
				value: 'End User'
			},
			{
				key: 'Re-Seller',
				text: 'Re-Seller',
				value: 'Re-Seller'
			}
		]
	},
	{
		label: 'Client Industry',
		key: 'industryId',
		value: clientInfos.industryId,
		isDropdown: true,
		options: industryOptions,
		required: true
	},
	{
		label: 'Account Executive',
		key: 'accountExecutiveId',
		type: 'AccExec',
		value: clientInfos.accountExecutiveId,
		isDropdown: false,
		dropDownSearch: true,
		employee: true,
		AccountExec: true,
		options: null,
		required: true
	},
	{
		label: 'Account Manager',
		key: 'accountManagerId',
		type: 'AccMan',
		value: clientInfos.accountManagerId,
		isDropdown: false,
		dropDownSearch: true,
		employee: true,
		options: null,
		required: true
	}
];

export const createClientDocumentsHeader = [
	{
		headerName: 'File Name',
		field: 'fileName',
		cellStyle: {paddingTop: '.4em'}
		// cellRendererFramework: ActiveToggleCellRenderer
	},
	{
		headerName: 'Google Drive',
		field: 'googleDriveLink',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function (params) {
			return `<a href="${params.value}" target="_blank">${params.value}</a>`;
		}
	},
	{
		headerName: 'Comments',
		field: 'comments',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Document Type',
		field: 'docType',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function (params) {
			const docId = parseInt(params.value);
			if (clientDocTypeOptions.map((value) => value.key).indexOf(docId) === -1) {
				return 'Invalid Type';
			}
			return clientDocTypeOptions.filter((opt) => opt.key === parseInt(docId))[0].text;
		}
	},
	{
		headerName: 'Remove',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'documentSectionRemoveButton'
	}
];

const baseClient = {
	loading: false,
	error: false,
	errorMessage: 'There was a problem creating this Prospect',
	modalTitle: 'Create Prospect',
	modalSteps: [
		{
			name: 'Prospect',
			active: true,
			description: 'client description',
			icon: {name: 'user', color: 'black'},
			modalFields: [
				{
					name: 'Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: true,
					split: true,
					key: 'name'
				},
				{
					name: 'Contact First Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'contactFirstName'
				},
				{
					name: 'Contact Last Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'contactLastName'
				},
				{
					name: 'Contact Email',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'contactEmail'
				},
				{
					name: 'Contact Phone',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'contactPhone'
				},
				{
					name: 'Lead Source',
					value: null,
					dropdown: true,
					options: leadSourceOptions,
					error: false,
					disabled: false,
					upward: true,
					required: false,
					split: true,
					key: 'leadSourceId'
				},
				{
					name: 'Address',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false,
					key: 'address',
					split: true
				},
				{
					name: 'Address 2',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false,
					key: 'address2',
					split: true
				},
				{
					name: 'City',
					input: true,
					value: null,
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'city'
				},
				{
					name: 'ZIP Code',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'zip'
				},
				{
					name: 'Country',
					value: 218,
					placeholder: 'Choose country',
					dropdown: true,
					options: countryOptions,
					error: false,
					disabled: false,
					upward: true,
					required: false,
					split: true,
					key: 'countryId'
				},
				{
					name: 'State',
					value: null,
					dropdown: true,
					options: stateOptions,
					error: false,
					disabled: false,
					upward: true,
					required: false,
					split: true,
					key: 'state'
				},
				{
					name: 'Website',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'website'
				},
				{
					name: 'Industry',
					value: null,
					placeholder: 'Choose client industry',
					dropdown: true,
					options: industryOptions,
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'industryId'
				},
				{
					name: 'Client Type',
					value: null,
					placeholder: 'Choose client industry',
					dropdown: true,
					options: [
						{
							key: 'Re-Seller',
							value: 'Re-Seller',
							text: 'Re-Seller'
						},
						{
							key: 'End User',
							value: 'End User',
							text: 'End User'
						}
					],
					error: false,
					disabled: false,
					required: false,
					split: true,
					key: 'clientType'
				}
			]
		},
		{
			name: 'Documents',
			active: false,
			description: 'Client Documents',
			icon: {name: 'file alternate', color: 'black'},
			documents: [],
			modalFields: [
				{
					name: 'File Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: true,
					checkForm: true,
					columnWidth: 5,
					key: 'fileName'
				},
				{
					name: 'Google Drive Link',
					value: '',
					input: true,
					error: false,
					disabled: false,
					checkForm: true,
					required: true,
					columnWidth: 5,
					key: 'googleLink'
				},
				{
					name: 'Document Type',
					value: '',
					input: false,
					dropdown: true,
					error: false,
					checkForm: true,
					disabled: false,
					required: true,
					columnWidth: 5,
					options: clientDocTypeOptions,
					key: 'docType'
				},
				{
					name: 'Comments',
					value: '',
					input: false,
					textArea: true,
					error: false,
					disabled: false,
					required: false,
					columnWidth: 15,
					key: 'docComments'
				}
			]
		}
	]
};

const baseCustomer = {
	loading: false,
	error: false,
	errorMessage: 'There was a problem creating this Customer',
	modalTitle: 'Create Customer',
	hasFetchedDocuments: false,
	isSubmitting: false,
	modalSteps: [
		{
			name: 'Prospect',
			active: true,
			description: 'client description',
			icon: {name: 'user', color: 'black'},
			modalFields: createClientModalFields
		},
		{
			name: 'Documents',
			active: false,
			description: 'Client Documents',
			icon: {name: 'file alternate', color: 'black'},
			documents: [],
			documentsToDelete: [],
			modalFields: [
				{
					name: 'File Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: true,
					checkForm: true,
					columnWidth: 5,
					key: 'fileName'
				},
				{
					name: 'Google Drive Link',
					value: '',
					input: true,
					error: false,
					disabled: false,
					checkForm: true,
					required: true,
					columnWidth: 5,
					key: 'googleLink'
				},
				{
					name: 'Document Type',
					value: '',
					input: false,
					dropdown: true,
					error: false,
					checkForm: true,
					disabled: false,
					required: true,
					columnWidth: 5,
					options: clientDocTypeOptions,
					key: 'docType'
				},
				{
					name: 'Comments',
					value: '',
					input: false,
					textArea: true,
					error: false,
					disabled: false,
					required: false,
					columnWidth: 15,
					key: 'docComments'
				}
			]
		}
	]
};

const baseProspectFields = [
	{
		name: 'Name',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: true,
		split: true,
		key: 'name'
	},
	{
		name: 'Contact First Name',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'contactFirstName'
	},
	{
		name: 'Contact Last Name',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'contactLastName'
	},
	{
		name: 'Contact Email',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'contactEmail'
	},
	{
		name: 'Contact Phone',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'contactPhone'
	},
	{
		name: 'Lead Source',
		value: null,
		options: leadSourceOptions,
		error: false,
		disabled: false,
		upward: true,
		required: false,
		split: true,
		key: 'leadSourceId'
	},
	{
		name: 'Address',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false,
		key: 'address',
		split: true
	},
	{
		name: 'Address 2',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false,
		key: 'address2',
		split: true
	},
	{
		name: 'City',
		input: true,
		value: null,
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'city'
	},
	{
		name: 'ZIP Code',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'zip'
	},
	{
		name: 'Country',
		value: 218,
		placeholder: 'Choose country',
		options: countryOptions,
		error: false,
		disabled: false,
		upward: true,
		required: false,
		split: true,
		key: 'countryId'
	},
	{
		name: 'State',
		value: null,
		options: stateOptions,
		error: false,
		disabled: false,
		upward: true,
		required: false,
		split: true,
		key: 'state'
	},
	{
		name: 'Website',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'website'
	},
	{
		name: 'Industry',
		value: null,
		placeholder: 'Choose client industry',
		options: industryOptions,
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'industryId'
	},
	{
		name: 'Client Type',
		value: null,
		placeholder: 'Choose client industry',
		dropdown: true,
		options: [
			{
				key: 'Re-Seller',
				value: 'Re-Seller',
				text: 'Re-Seller'
			},
			{
				key: 'End User',
				value: 'End User',
				text: 'End User'
			}
		],
		error: false,
		disabled: false,
		required: false,
		split: true,
		key: 'clientType'
	}
];
