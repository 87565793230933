import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';

class ProjectCostWOVendorGrid extends React.Component {
	defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true
	};

	columnDefs = [
		{
			headerName: 'Vendor',
			field: 'vendor',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'PO#',
			field: 'poNumber',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Status',
			field: 'poStatus',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'PO Amount',
			field: 'poAmount',
			filter: 'agTextColumnFilter',
			valueFormatter: (params) => {
				return typeof params.value === 'number' ? `$${params.value.toFixed(2)}` : '';
			}
		},
		{
			headerName: 'Amount Received',
			field: 'poReceived',
			filter: 'agTextColumnFilter',
			valueFormatter: (params) => {
				return typeof params.value === 'number' ? `$${params.value.toFixed(2)}` : '';
			}
		},
		{
			headerName: 'Amount Remaining',
			field: '',
			filter: 'agTextColumnFilter',
			valueFormatter: (params) => {
				const remaining = params.data ? params.data.poAmount - params.data.poReceived : 0.0;
				return `$${remaining.toFixed(2)}`;
			}
		}
	];

	sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true,
					suppressPivots: true,
					suppressPivotMode: true
				}
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		],
		position: 'right'
	};

	onQuickFilterChanged(value) {
		this.gridApi.setQuickFilter(value);
	}

	exportHandler(value) {
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				return params.value;
			}
		};
		this.gridApi.exportDataAsExcel(params);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	render() {
		return (
			<div className="fixGridMargin">
				<div
					className="ag-theme-balham"
					style={{
						height: '20vh',
						width: '100%'
					}}
				>
					<AgGridReact
						animateRows={true}
						modules={AllModules}
						suppressRowClickSelection={false}
						suppressDragLeaveHidesColumns={true}
						suppressContextMenu={true}
						suppressMultiRangeSelection={true}
						onGridReady={this.onGridReady}
						columnDefs={this.columnDefs}
						defaultColDef={this.defaultColDef}
						sideBar={this.sideBar}
						rowData={this.props.vendorRowData}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		vendorRowData: state.work.workRecord.view.ProjectCosting.projectCostingWO.purchaseOrdersGrid.rowData
	};
};
export default connect(mapStateToProps, null)(ProjectCostWOVendorGrid);
