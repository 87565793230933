import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Modal, Icon, Header, Step} from 'semantic-ui-react';

import {
	toggleOptionSelectionModal,
	toggleUserCreationModal,
	toggleUserCreationModalType
} from '../../../../../services/accountingConsoleActions.js';

class OptionSelectionModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {type: 'Client'};
		this.closeModal = this.closeModal.bind(this);
		this.activateStep = this.activateStep.bind(this);
	}

	closeModal() {
		const {toggleOptionSelectionModal} = this.props;
		toggleOptionSelectionModal(false);
	}

	activateStep(step) {
		const {toggleOptionSelectionModal, toggleUserCreationModal, toggleUserCreationModalType} = this.props;
		toggleOptionSelectionModal(false);
		toggleUserCreationModalType(step);
		toggleUserCreationModal(true);
	}

	modalSteps = [
		{
			name: 'Employee',
			icon: 'user outline',
			active: true
		},
		{
			name: 'AASDI',
			icon: 'user circle',
			active: true
		},
		{
			name: 'Client',
			icon: 'user',
			active: true
		}
	];

	render() {
		const {isOpen} = this.props;
		return (
			<Modal size="small" open={isOpen}>
				<Header>
					<Icon name="plus" color="green" />
					<div className="content">Create User</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '120px'
						}}
					>
						{this.modalSteps.map((modalStep) => (
							<Button
								key={modalStep.name}
								icon={modalStep.icon}
								// description: modal.description,
								basic
								content={modalStep.name}
								active={modalStep.active}
								// added this because we don't have AASDI or Client users in place
								disabled={modalStep.name !== 'Employee'}
								size="huge"
								onClick={() => this.activateStep(modalStep.name)}
							/>
						))}
					</div>
				</Header>
				<Modal.Actions>
					<Button.Group>
						<Button onClick={() => this.closeModal()} color="red">
							<Icon name="remove" /> Cancel
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

OptionSelectionModal.propTypes = {
	toggleOptionSelectionModal: PropTypes.func,
	toggleUserCreationModal: PropTypes.func,
	toggleUserCreationModalType: PropTypes.func,
	isOpen: PropTypes.bool
};

const mapStateToProps = (state) => ({
	isOpen: state.accountingConsoleReducer.accountingConsole.isChooseUserModalOpen
});

const mapDispatchToProps = (dispatch) => ({
	toggleOptionSelectionModal: (status) => dispatch(toggleOptionSelectionModal(status)),
	toggleUserCreationModal: (status) => dispatch(toggleUserCreationModal(status)),
	toggleUserCreationModalType: (status) => dispatch(toggleUserCreationModalType(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionSelectionModal);
