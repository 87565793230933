import update from 'react-addons-update';

export default (
	state = {
		loading: null,
		fetched: null,
		error: null,
		editError: null,
		contacts: []
	},
	action
) => {
	switch (action.type) {
		case 'CRM_UPDATE_OPPORTUNITY_CONTACTS':
			state = update(state, {
				[action.payload.column]: {
					$set: action.payload.value
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY_CONTACTS_ADD_ITEM':
			state = update(state, {
				contacts: {
					$push: [action.payload.data]
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY_CONTACTS_DELETE_ITEM':
			state = update(state, {
				contacts: {
					$splice: [[action.payload, 1]]
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY_CONTACTS_ITEM':
			state = update(state, {
				contacts: {
					[state.contacts.findIndex((contact) => contact.id === action.payload.id)]: {
						$set: action.payload
					}
				}
			});
			break;
	}

	return state;
};
