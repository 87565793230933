// Modules
import React from 'react';
import PropTypes from 'prop-types';

// Components
import CardPlaceholder from './CardPlaceHolder.js';
import CardMain from './CardMain.js';

let BuildGroup = (opportunities, fetched) => {
	let group = [];
	if (fetched === false) {
		for (let i = 0; i <= 11; i++) {
			group.push(<CardPlaceholder />);
		}
		return group;
	}

	return opportunities.map((opportunity) => <CardMain key={opportunity.id} opportunity={opportunity} />);
};

BuildGroup.propTypes = {
	opportunities: PropTypes.string.isRequired,
	fetched: PropTypes.bool.isRequired
};

export default BuildGroup;
