import {Button, Icon, Modal, Header} from 'semantic-ui-react';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from './services/globalActions.js';

/**
 * This component is used to prompt the user with a modal asking if they would like to discard changes.
 * @memberof App.Components.Presentational.global#
 * @param {object} props The state object needs to include the global reducer.
 * @param {object} props.global The global object.
 * @param {object} props.global.ui UI object.
 * @param {object} props.global.discardChanges UI object.
 * @param {boolean} props.global.discardChanges.toggleModal UI object.
 * @param {function} props.global.discardChanges.onConfirm UI object.
 * @param {array} props.global.discardChanges.params UI object.
 * @param {string} props.global.discardChanges.message UI object.
 * @param {function} props.toggleDiscardChangesModal
 * {@link globalReducer}
 */

const DiscardChanges = (props) => (
	<Modal closeIcon size="tiny" basic open={props.toggleModal} onClose={() => props.toggleDiscardChangesModal()}>
		<Header icon="trash" content="Discard Changes" />
		<Modal.Content>
			{props.message
				? props.message
				: 'This is a destructive action, are you sure you want to discard your changes'}
		</Modal.Content>
		<Modal.Actions>
			<Button color="red" onClick={() => props.toggleDiscardChangesModal()} inverted>
				<Icon name="remove" /> No
			</Button>

			<Button
				color="green"
				onClick={() => {
					props.onConfirm(props.params);
				}}
				inverted
			>
				<Icon name="checkmark" /> Yes
			</Button>
		</Modal.Actions>
	</Modal>
);

const mapStateToProps = (state) => {
	return {
		toggleModal: state.global.global.ui.discardChanges.toggleModal,
		message: state.global.global.ui.discardChanges.message,
		params: state.global.global.ui.discardChanges.params
	};
};

DiscardChanges.propTypes = {
	// global: PropTypes.shape({
	// 	ui: PropTypes.shape({
	// 		discardChanges: PropTypes.shape({
	// 			toggleModal: PropTypes.bool.isRequired,
	// 			onConfirm: PropTypes.func.isRequired,
	// 			params: PropTypes.array.isRequired,
	// 			message: PropTypes.string
	// 		}).isRequired
	// 	}).isRequired
	// }),
	toggleDiscardChangesModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, actions)(DiscardChanges);
