import React from 'react';
import {Icon, Popup, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const projectRecord = {
	trigger: <Icon name="briefcase" color="green" style={{fontSize: '1.2em'}} />,
	content: 'Project Record'
};

const workOrder = {
	trigger: <Icon name="clipboard" color="blue" style={{fontSize: '1.2em'}} />,
	content: 'Work Order Record'
};

const popupPositioning = {position: 'bottom left', basic: true};

const OrderTypeRenderer = (props) => {
	const {isProject, id} = props.data;
	return (
		<div style={{textAlign: 'center'}}>
			<Popup {...(isProject ? projectRecord : workOrder)} {...popupPositioning} />
			<Popup
				{...popupPositioning}
				content="View Work Details"
				trigger={
					<Link to={`/work/view/${id}`}>
						<Button
							icon="linkify"
							style={{
								backgroundColor: 'transparent',
								padding: '.5em .5em .5em',
								marginLeft: '.5em'
							}}
						/>
					</Link>
				}
			/>
		</div>
	);
};

export default OrderTypeRenderer;
