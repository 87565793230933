import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Label, Icon} from 'semantic-ui-react';

class RatingStarsField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rating: 0,
			icon0: {
				name: 'star outline',
				color: ''
			},
			icon1: {
				name: 'star outline',
				color: ''
			},
			icon2: {
				name: 'star outline',
				color: ''
			},
			icon3: {
				name: 'star outline',
				color: ''
			},
			icon4: {
				name: 'star outline',
				color: ''
			}
		};
		this.enterIcon = this.enterIcon.bind(this);
		this.leaveIcon = this.leaveIcon.bind(this);
		this.clickIcon = this.clickIcon.bind(this);
	}

	enterIcon(index) {
		let tempState = Object.assign({}, this.state);
		for (let i = 0; i <= index; i++) {
			if (i < tempState.rating) {
				continue;
			}
			tempState[`icon${i}`].name = 'star outline';
			if (i <= index) {
				tempState[`icon${i}`].color = 'yellow';
			} else {
				tempState[`icon${i}`].color = '';
			}
		}
		this.setState({...tempState});
	}

	leaveIcon(index) {
		let tempState = Object.assign({}, this.state);
		for (let i = 0; i <= index; i++) {
			if (i >= tempState.rating) {
				tempState[`icon${i}`].name = 'star outline';
				tempState[`icon${i}`].color = '';
			}
		}
		this.setState({...tempState});
	}

	clickIcon(index) {
		let nextState = Object.assign({}, this.state);
		for (let i = 0; i <= 4; i++) {
			if (i <= index) {
				nextState[`icon${i}`].name = 'star';
				nextState[`icon${i}`].color = 'yellow';
			} else {
				nextState[`icon${i}`].name = 'star outline';
				nextState[`icon${i}`].color = '';
			}
		}
		nextState.rating = index + 1;
		this.setState({...nextState});
		this.props.onClick(index + 1);
	}

	render() {
		const {label} = this.props;
		const {icon0, icon1, icon2, icon3, icon4} = this.state;
		return (
			<div>
				<Label
					style={{
						backgroundColor: 'transparent',
						fontSize: '1rem',
						color: 'rgba(0,0,0,.8)',
						paddingLeft: '0'
					}}
				>
					{label}
				</Label>
				<br />
				<Icon
					className="cursorPointer"
					name={icon0.name}
					color={icon0.color}
					onMouseEnter={() => this.enterIcon(0)}
					onMouseLeave={() => this.leaveIcon(0)}
					onClick={() => this.clickIcon(0)}
				/>
				<Icon
					className="cursorPointer"
					name={icon1.name}
					color={icon1.color}
					onMouseEnter={() => this.enterIcon(1)}
					onMouseLeave={() => this.leaveIcon(1)}
					onClick={() => this.clickIcon(1)}
				/>
				<Icon
					className="cursorPointer"
					name={icon2.name}
					color={icon2.color}
					onMouseEnter={() => this.enterIcon(2)}
					onMouseLeave={() => this.leaveIcon(2)}
					onClick={() => this.clickIcon(2)}
				/>
				<Icon
					className="cursorPointer"
					name={icon3.name}
					color={icon3.color}
					onMouseEnter={() => this.enterIcon(3)}
					onMouseLeave={() => this.leaveIcon(3)}
					onClick={() => this.clickIcon(3)}
				/>
				<Icon
					className="cursorPointer"
					name={icon4.name}
					color={icon4.color}
					onMouseEnter={() => this.enterIcon(4)}
					onMouseLeave={() => this.leaveIcon(4)}
					onClick={() => this.clickIcon(4)}
				/>
			</div>
		);
	}
}

RatingStarsField.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func
};

export default RatingStarsField;
