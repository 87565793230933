import {sendPipelineBackToLeads} from '../../../scenes/crm/scenes/dashboard/scenes/pipeline/services/pipelineActions.js';
import {sendToPipeline} from '../../../scenes/crm/scenes/dashboard/scenes/leads/services/leadsActions.js';
import customFetch from '../../../lib/old/customFetch.js';

export const sendTo = (id, place, acctExecId, projOrderDate) => {
	return (dispatch) => {
		let array = [
			{
				name: 'accountExec',
				value: acctExecId
			},
			{
				name: 'projectedOrderDate',
				value: projOrderDate
			}
		];

		switch (place) {
			case 'pipeline':
				dispatch(sendPipelineBackToLeads(id, place));
				break;
			case 'leads':
				acctExecId && projOrderDate
					? dispatch(sendToPipeline(id, place, acctExecId, projOrderDate))
					: console.log(array);
				array.forEach((column) => {
					if (column.value) {
						dispatch({
							type: 'SEND_TO_MODAL_FIELD_ERROR',
							payload: {
								column: column.name,
								error: true
							}
						});
					}
				});
				break;
		}
	};
};

export const toggleSendToModal = (id, place, acctExecId, projOrderDate) => {
	return (dispatch) => {
		dispatch({
			type: 'SEND_TO_MODAL_UPDATE_MODAL_ID',
			payload: {
				id,
				place,
				acctExecId,
				projOrderDate
			}
		});
		return dispatch({
			type: 'SEND_TO_MODAL_TOGGLE'
		});
	};
};

export const updateSendToModal = (column, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'SEND_TO_MODAL_UPDATE',
			payload: {
				column,
				value,
				error
			}
		});
	};
};

export const getAccountExecs = (column, value) => {
	return (dispatch) => {
		// Dispatch Loader
		console.log(column);
		dispatch({
			type: 'SEND_TO_MODAL_LOADING_FIELD',
			payload: {
				column
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((accountExec) => ({
					key: accountExec.id,
					text: accountExec.fullName,
					value: accountExec.id
				}));
				dispatch({
					type: 'SEND_TO_MODAL_GET_DROPDOWN_OPTIONS',
					payload: {
						column: column,
						options: options
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'SEND_TO_MODAL_LOADING',
			payload: value
		});
	};
};
