import React, {Component} from 'react';
import {Label, Segment, Grid} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../../../../components/Global/AgGrid.js';
import FilterGrid from '../../../../../../components/Global/FilterGrid.js';
import ExportGrid from '../../../../../../components/Global/ExportGrid.js';

import {toggleFetchingOnTabsNavbar} from '../../../../../../components/Global/services/globalActions.js';
import {checkIdenticalsArrays} from '../../../../../../lib/old/validation.js';
import {WorkTabHeader} from '../services/profileAasdiGridReducer.js';
import {getTabRequestItems, clearGrid} from '../services/profileAasdiGridActions.js';
import AasdiWorkTabCellRenderer from './AasdiWorkTabCellRenderer.js';

const frameworkComponents = {
	aasdiWorkTabCellRenderer: AasdiWorkTabCellRenderer
};

class Work extends Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps) {
		const {profileAasdiGridData, profileAasdiGridHeader, toggleFetchingOnTabsNavbar} = this.props;
		if (prevProps.profileAasdiGridData === null && profileAasdiGridData) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.profileAasdiGridHeader, profileAasdiGridHeader)) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.rowData, profileAasdiGridData)) {
			toggleFetchingOnTabsNavbar(false);
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();

		const {aasdiId} = this.props;

		if (
			this.props.profileAasdiGridData &&
			this.props.profileAasdiGridData.length &&
			Number(aasdiId) === this.props.profileAasdiGridData[0].aasdiId &&
			checkIdenticalsArrays(this.props.profileAasdiGridHeader, WorkTabHeader)
		) {
			return;
		}
		this.props.clearGrid('Work');
		this.gridApi.showLoadingOverlay();
		this.props.getTabRequestItems(aasdiId, 'Work');
		this.props.toggleFetchingOnTabsNavbar(true);
	};

	render() {
		const {profileAasdiGridData, profileAasdiGridHeader} = this.props;
		return (
			<Segment basic style={{marginTop: '1.5rem', border: '1px solid #DEDEDE'}} loading={this.props.loading}>
				<Label color="blue" ribbon>
					Work
				</Label>
				<Grid style={{padding: '2em 0'}}>
					<FilterGrid gridApi={() => this.gridApi} />
					<ExportGrid gridApi={() => this.gridApi} />
				</Grid>
				<AgGrid
					columnDefs={profileAasdiGridHeader}
					rowData={profileAasdiGridData}
					onGridReady={this.onGridReady}
					frameworkComponents={frameworkComponents}
				/>
			</Segment>
		);
	}
}

Work.propTypes = {
	getTabRequestItems: PropTypes.func.isRequired,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	profileAasdiGridData: PropTypes.array.isRequired,
	profileAasdiGridHeader: PropTypes.array.isRequired,
	aasdiId: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	clearGrid: PropTypes.func
};

const mapStateToProps = (state) => ({
	profileAasdiGridData: state.work.aasdiProfileGrid.rowData,
	profileAasdiGridHeader: state.work.aasdiProfileGrid.headerData,
	loading: state.work.aasdiProfileGrid.loading
});

const mapDispatchToProps = (dispatch) => ({
	getTabRequestItems: (aasdiId, item) => dispatch(getTabRequestItems(aasdiId, item)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	clearGrid: (item) => dispatch(clearGrid(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(Work);
