const selectWorkRecordState = (state) => state.work.workRecord;

export const selectActiveStep = (appState) => selectWorkRecordState(appState).view.activeStep;
export const selectWorkRecord = (appState) => selectWorkRecordState(appState).view.record;

export const selectWorkRecordErrorState = (state) => state.error.workRecord;

export const selectAasdiStatusColDefs = (state) => selectWorkRecordState(state).view.eventLog.aasdiStatusRecords;

// aasdi status column definitions come in ordered by date on the query, hence selecting the first entry will be the most recent
export const selectAasdiStatusForSelectedTrip = (state, workAasdiId) =>
	selectAasdiStatusColDefs(state).filter((aasdiStatus) => aasdiStatus.workAasdiId === workAasdiId)[0];
export const selectAasdiStatusDescription = (state, workAasdiId) => {
	const selectedAasdi = selectAasdiStatusForSelectedTrip(state, workAasdiId);
	if (selectedAasdi) {
		return selectedAasdi.description;
	}
	return null;
};
