import React from 'react';
import {connect} from 'react-redux';
import {Popup, Label} from 'semantic-ui-react';
import moment from 'moment';

import {updateAASDIGridColumn} from '../../../services/createWorkOrderActions.js';
import {MomentDatePicker} from '../../../../../../../components/inputs/DatePicker.jsx';

const AASDIDatePicker = (props) => (
	<div>
		<Popup
			trigger={
				<Label
					style={{
						width: '100%',
						backgroundColor: 'transparent'
					}}
				>
					{props.value ? moment.utc(props.value).local().format('MM-DD-YYYY HH:mm') : 'Pick Date'}
				</Label>
			}
			on={['hover', 'click']}
			flowing
			hoverable
		>
			<MomentDatePicker
				inline
				className={'workOrderDatePicker'}
				showTimeSelect
				timeFormat="hh:mm a"
				timeIntervals={15}
				dateFormat="LLL"
				timeCaption="time"
				selected={props.value ? moment.utc(props.value).local() : null}
				/// SAVES AS UTC IN REDUCER, FORMATTED TO SHOW LOCAL ON FROTNEND
				onChange={(date) => {
					props.updateAASDIGridColumn(
						props.sectionIndex,
						props.rowIndex,
						props.colDef.field,
						date.utc().format()
					);
				}}
			/>
		</Popup>
	</div>
);

const mapDispatchToProps = (dispatch) => {
	return {
		updateAASDIGridColumn: (sectionIndex, rowIndex, column, value) => {
			dispatch(updateAASDIGridColumn(sectionIndex, rowIndex, column, value));
		}
	};
};

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'AASDISection'
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AASDIDatePicker);
