import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Table} from 'semantic-ui-react';
import {calcMargin} from '../../util/moneymath.ts';
import {fmtMoney, fmtPercent} from '../../util/formatting.js';

export const SKUTotals = (props) => {
	const accumulated = props.data.reduce(
		(accum, val) => {
			const materialCost = val.unitMaterialCost * val.quantity;
			const laborCost = val.unitLaborCost * val.quantity;

			accum.materialCost += materialCost;
			accum.laborCost += laborCost;

			accum.materialRevenue += val.materialResell;
			accum.laborRevenue += val.laborResell;
			return accum;
		},
		{
			materialCost: 0,
			materialRevenue: 0,
			laborCost: 0,
			laborRevenue: 0
		}
	);
	const totalCost = accumulated.laborCost + accumulated.materialCost;
	const totalRevenue = accumulated.materialRevenue + accumulated.laborRevenue;
	const profit = totalRevenue - totalCost;
	const margin = calcMargin(totalCost, totalRevenue) * 100;

	return (
		<Grid columns="equal">
			<Grid.Row columns={3}>
				<Grid.Column>
					<Table definition>
						<Table.Header>
							<Table.HeaderCell />
							<Table.HeaderCell style={{width: '100%'}}>Revenue</Table.HeaderCell>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>Material</Table.Cell>
								<Table.Cell>{fmtMoney(accumulated.materialRevenue)}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Labor</Table.Cell>
								<Table.Cell>{fmtMoney(accumulated.laborRevenue)}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Total</Table.Cell>
								<Table.Cell>{fmtMoney(totalRevenue)}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Grid.Column>
				<Grid.Column>
					<Table definition>
						<Table.Header>
							<Table.HeaderCell />
							<Table.HeaderCell style={{width: '100%'}}>Estimated Cost</Table.HeaderCell>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>Material</Table.Cell>
								<Table.Cell>{fmtMoney(accumulated.materialCost)}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Labor</Table.Cell>
								<Table.Cell>{fmtMoney(accumulated.laborCost)}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Total</Table.Cell>
								<Table.Cell>{fmtMoney(totalCost)}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Grid.Column>
				<Grid.Column>
					<Table definition>
						<Table.Header>
							<Table.HeaderCell />
							<Table.HeaderCell style={{width: '100%'}}>Profit</Table.HeaderCell>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell style={{width: '30%'}}>Gross Profit</Table.Cell>
								<Table.Cell>{fmtMoney(profit)}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell style={{width: '30%'}}>Margin</Table.Cell>
								<Table.Cell>{Number.isNaN(margin) ? '' : fmtPercent(margin)}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

SKUTotals.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			quantity: PropTypes.number.isRequired,
			unitMaterialCost: PropTypes.number.isRequired,
			materialResell: PropTypes.number.isRequired,
			unitLaborCost: PropTypes.number.isRequired,
			materialResell: PropTypes.number.isRequired
		})
	)
};

export default SKUTotals;
