import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AGGrid from '../../components/aggrid/AGGrid.js';
//import NewOppButton from '../../components/newOppButton/newOppButton.js';
import {getLeadsOpportunities} from './services/leadsActions.js';

class Leads extends React.Component {
	componentDidMount() {
		this.props.getLeadsOpportunities();
	}
	render() {
		return <AGGrid key="grid" />;
	}
}

Leads.propTypes = {
	getLeadsOpportunities: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		getLeadsOpportunities: () => {
			dispatch(getLeadsOpportunities());
		}
	};
};

export default connect(null, mapDispatchToProps)(Leads);
