import moment from 'moment';
import customFetch from '../../../../../../../lib/old/customFetch.js';

export const getOpportunity = (id) => {
	return async (dispatch) => {
		if (id === 'newLead' || id === 'newOpp') {
			let json = {
				name: '',
				description: '',
				accountExecutiveId: null,
				bidDueDate: moment().add(1, 'day').format('MM-DD-YYYY'),
				clientId: 0,
				clientName: null,
				accountExecutiveSearch: {
					options: [],
					loading: false
				},
				clientSearch: {
					options: [],
					loading: false
				},
				clientStatus: null,
				clientStatusName: null,
				endDate: moment().format('MM-DD-YYYY'),
				error: false,
				fetched: false,
				id,
				isLead: id === 'newLead',
				industryId: null,
				leadSourceId: null,
				leadSourceName: null,
				loading: false,
				projectedOrderDate: moment().add(2, 'day').format('MM-DD-YYYY'),
				revenue: '0',
				serviceType: 0,
				stageId: 0,
				stageName: 'Lead',
				startDate: moment().format('MM-DD-YYYY'),
				isCommit: false,
				newOpp: true
			};
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY',
				payload: {
					...json,
					fetched: true,
					loading: false
				}
			});
			return;
		}

		try {
			const response = await customFetch(
				`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}`
			);

			if (response.status !== 200) {
				throw new Error(response);
			}

			const documentsCall = await customFetch(
				`${process.env.REACT_APP_API_URL}/legacy/documents?opportunityId=${id}`
			);

			const documents = await documentsCall.json();

			console.log(documents);

			dispatch({
				type: 'GET_OPP_DOCUMENTS',
				payload: documents
			});

			dispatch({
				type: 'OPP_DOCUMENTS_LOADING',
				payload: false
			});

			const json = await response.json();

			json.accountExecutiveSearch = {
				options: [],
				loading: false
			};

			json.clientSearch = {
				options: [],
				loading: false
			};

			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY',
				payload: {
					...json,
					fetched: true,
					loading: false
				}
			});
		} catch (err) {
			console.log(`err === ${err}`);
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					column: 'error',
					value: err
				}
			});
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					column: 'fetched',
					value: true
				}
			});
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					column: 'loading',
					value: false
				}
			});
		}
	};
};

export const togglePipelineModal = (status) => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_PIPELINE_MODAL',
			payload: status
		});
	};
};

export const clientUpdated = (clientInfo, operationId) => {
	return (dispatch) => {
		dispatch({
			type: 'CRM_UPDATED_CLIENT_PROSPECT_MODAL',
			payload: {
				clientId: clientInfo.id,
				clientOption: {
					key: clientInfo.id,
					text: clientInfo.name,
					value: clientInfo.id
				},
				operationId
			}
		});
	};
};

function dateOrderValidation(firstDate, secondDate) {
	firstDate = moment(firstDate).format('MM-DD-YYYY');
	secondDate = moment(secondDate).format('MM-DD-YYYY');
	if (firstDate === secondDate) {
		return false;
	} else {
		if (!firstDate || !secondDate) {
			return false;
		} else {
			return !moment(secondDate).isAfter(firstDate, 'day');
		}
	}
}

export const updateOpportunityColumn = (id, column, value, name) => {
	return (dispatch, getState) => {
		let state = getState();
		if (column === 'accountExecutiveId') {
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					value,
					column: 'accountExecutiveName'
				}
			});
		}
		if (column === 'clientName') {
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					value,
					column: 'clientName'
				}
			});

			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					value,
					column: 'clientId'
				}
			});
		} else {
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					value,
					column
				}
			});
		}

		dispatch({
			type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
			payload: {
				column: 'validation',
				value: {
					name: false,
					description: false,
					clientId: false,
					accountExecutiveId: false,
					projectedOrderDate: false,
					endDate: false,
					serviceType: false
				}
			}
		});

		if (id === 'newOpp' || id === 'newLead' || id === 'update') {
			return;
		} // If new, do not update the db one column at a time.

		const body = state.crm.opportunity.data;
		//Date validations
		// Validation projectedOrderDate > bidDueDate
		const projectedOrderDateValidation =
			column === 'bidDueDate'
				? dateOrderValidation(value, body.projectedOrderDate)
				: column === 'projectedOrderDate'
				? dateOrderValidation(body.bidDueDate, value)
				: dateOrderValidation(body.bidDueDate, body.projectedOrderDate);

		const endDateValidation =
			column === 'startDate'
				? dateOrderValidation(value, body.endDate)
				: column === 'endDate'
				? dateOrderValidation(body.startDate, value)
				: dateOrderValidation(body.startDate, body.endDate);

		if (projectedOrderDateValidation || endDateValidation) {
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					column: 'validation',
					value: {
						name: false,
						description: false,
						clientId: false,
						accountExecutiveId: false,
						projectedOrderDate: projectedOrderDateValidation,
						endDate: endDateValidation,
						serviceType: false
					}
				}
			});
			return;
		}

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}`, {
			method: 'PUT',
			body: JSON.stringify({
				[column]: value
			})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						value,
						column
					}
				});
			})
			.catch(() => {
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						value: state.crm.opportunity.data[column],
						column
					}
				});
			});
	};
};

export const getAccountExecs = (column, value) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CRM_SEARCH_FIELD_LOADING',
			payload: {
				column,
				value: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((accountExec) => ({
					key: accountExec.id,
					text: accountExec.fullName,
					value: accountExec.id
				}));
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN_OPTIONS',
					payload: {
						column,
						value: options
					}
				});
				dispatch({
					type: 'CRM_SEARCH_FIELD_LOADING',
					payload: {
						column,
						value: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CRM_SEARCH_FIELD_LOADING',
					payload: {
						column,
						value: false
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const getClients = (column, value) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CRM_SEARCH_FIELD_LOADING',
			payload: {
				column,
				value: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/client?name=${encodeURIComponent(value)}&oppsearch=1`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((client) => ({
					key: client.id,
					text: client.name,
					value: client.id
				}));
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN_OPTIONS',
					payload: {
						column,
						value: options
					}
				});
				dispatch({
					type: 'CRM_SEARCH_FIELD_LOADING',
					payload: {
						column,
						value: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CRM_SEARCH_FIELD_LOADING',
					payload: {
						column,
						value: false
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const createOpportunity = () => {
	return (dispatch, getState) => {
		console.log('hit inside createOpportunity');
		let state = getState();

		dispatch({
			type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
			payload: {
				column: 'loading',
				value: true
			}
		});

		let body = state.crm.opportunity.data;
		delete body.accountExecutiveSearch;
		delete body.clientSearch;

		// Validation
		if (
			!body.name ||
			!body.description ||
			!body.clientId ||
			body.accountExecutiveId === null ||
			!(moment(body.projectedOrderDate) > moment(body.bidDueDate)) ||
			body.serviceType < 1
		) {
			dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					column: 'validation',
					value: {
						name: !body.name,
						description: !body.description,
						clientId: !body.clientId,
						accountExecutiveId: !body.accountExecutiveId,
						projectedOrderDate: !(body.projectedOrderDate > body.bidDueDate),
						serviceType: !body.serviceType
					}
				}
			});

			return dispatch({
				type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
				payload: {
					column: 'loading',
					value: false
				}
			});
		}
		customFetch(`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity`, {
			method: 'POST',
			body: JSON.stringify(body)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				console.log(json);

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						value: json.id,
						column: 'id'
					}
				});
				window.location.href = `/crm/dashboard/opportunity/${json.id}`;

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						column: 'fetched',
						value: true
					}
				});

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						column: 'loading',
						value: false
					}
				});
			})
			.catch((error) => {
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						value: error,
						column: 'error'
					}
				});
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						column: 'fetched',
						value: true
					}
				});

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						column: 'loading',
						value: false
					}
				});
			});
	};
};

export const updateDocumentValue = (index, value) => {
	return (dispatch) => {
		dispatch({
			type: 'OPP_UPDATE_DOCUMENTS_VALUE',
			payload: {
				index,
				value
			}
		});
	};
};

export const addDocument = (fileName, googleDriveLink, customerApproval, comments, id) => {
	return (dispatch) => {
		dispatch({
			type: 'OPP_DOCUMENTS_LOADING',
			payload: true
		});
		let payloadObj = {
			comments: comments.value,
			opportunityId: id,
			isClientVisible: false,
			isAasdiVisible: false,
			googleDriveLink: googleDriveLink.value,
			fileName: fileName.value,
			customerApproval: customerApproval.value
		};

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

		customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify([payloadObj])
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((results) => {
				payloadObj.id = results[0].id;

				dispatch({
					type: 'OPP_DOCUMENTS_LOADING',
					payload: false
				});

				dispatch({
					type: 'OPP_ADD_DOCUMENT',
					payload: {
						object: payloadObj
					}
				});

				dispatch({
					type: 'OPP_CLEAR_DOC_FIELDS'
				});
			})
			.catch((err) => {
				dispatch({
					type: 'OPP_DOCUMENTS_LOADING',
					payload: false
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const removeDocument = (index, documentId, opportunityId) => async (dispatch) => {
	dispatch({
		type: 'OPP_DOCUMENTS_LOADING',
		payload: true
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;
	const call = await customFetch(endpoint, {
		method: 'DELETE',
		body: JSON.stringify({documentId, opportunityId})
	});

	if (call.status === 200) {
		dispatch({
			type: 'OPP_REMOVE_DOCUMENT',
			payload: {
				index
			}
		});
	}

	dispatch({
		type: 'OPP_DOCUMENTS_LOADING',
		payload: false
	});
};

export const updateDocument = (index, value) => async (dispatch) => {
	value.customerApproval === true ? (value.customerApproval = false) : (value.customerApproval = true);

	let putBody = {
		documentId: value.id,
		opportunityId: value.opportunityId,
		customerApproval: value.customerApproval
	};

	console.log('1');

	dispatch({
		type: 'OPP_UPDATE_DOCUMENT',
		payload: {
			index,
			value: value.customerApproval
		}
	});
	console.log('2');

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

	const call = await customFetch(endpoint, {
		method: 'PUT',
		body: JSON.stringify(putBody)
	});
	console.log('3');

	if (call.status !== 200) {
		console.log(`ERROR - not 200`);
	}
};

export const addTag = (tag) => (dispatch) => {
	dispatch({
		type: 'CREATE_OPPORTUNITY_ADD_TAG',
		payload: {
			tag
		}
	});
};

export const removeTag = (removeIndex) => (dispatch) => {
	dispatch({
		type: 'CREATE_OPPORTUNITY_REMOVE_TAG',
		payload: {
			removeIndex
		}
	});
};
