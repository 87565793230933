import React from 'react';
import {Divider, Segment, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Steps from '../../components/steps/Steps.js';
import OppHeader from './components/OppHeader.js';
import OppInfo from './components/OppInfo.js';
import OppDesc from './components/OppDesc.js';
import OppContent from './components/oppContent/OppContent.js';
import CommitModal from '../../../../../../components/commitModal/commitModal.js';
import Tags from '../../../../../../components/tags/tags.tsx';
import * as actions from './services/opportunityActions.js';

class Opportunity extends React.Component {
	componentDidMount() {
		this.props.getOpportunity(this.props.match.params.id);
	}

	updateTags = (type, tag) => {
		if (type === 'add') {
			this.props.addTag(tag);
		} else {
			const removeIndex = this.props.tags.findIndex((t) => t.id === tag.id);
			this.props.removeTag(removeIndex);
		}
	};

	render() {
		const creation =
			this.props.match.params.id.toLowerCase() === 'newlead' ||
			this.props.match.params.id.toLowerCase() === 'newopp';
		const {isLead} = this.props;
		return [
			<Segment loading={this.props.loading} key="opportunity-main">
				{this.props.error ? (
					<Segment textAlign="center" basic>
						Hmmm, it looks like something went wrong when I was trying to pull this data. Try again, or
						contact the Dev team if it keeps happening.
						<Segment basic style={{paddingBottom: '0'}}>
							{/* <Button
								icon
								onClick={() => refreshChart(chartType)}
							>
								<Icon name="refresh" />
							</Button> */}
						</Segment>
					</Segment>
				) : (
					[
						<OppHeader key={'opp-header'} />,
						<Divider key={'opp-divider-1'} />,
						<OppDesc key={'opp-desc'} />,
						<Divider key={'opp-divider-2'} />,
						<OppInfo key={'opp-info'} creation={creation} />,
						<CommitModal />
					]
				)}
				<div style={{margin: '15px'}}>
					{creation && <Tags tags={this.props.tags} parentUpdateTags={this.updateTags} />}
				</div>
			</Segment>,
			creation ? (
				<Button
					fluid
					content={this.props.isLead ? 'Create Lead' : 'Create Opportunity'}
					onClick={() => this.props.createOpportunity()}
					primary
				/>
			) : (
				[
					<Divider key="data-divider-1" />,
					<Steps
						key="opportunity-steps"
						isOpportunityView
						isOpportunity={!isLead}
						toggleModalPipeline={this.props.togglePipelineModal}
					/>,
					<Divider key="data-divider-2" />,
					<OppContent key="opportunity-content" id={this.props.match.params.id} />
				]
			)
		];
	}
}

Opportunity.propTypes = {
	match: PropTypes.object,
	error: PropTypes.bool,
	getOpportunity: PropTypes.func,
	fetched: PropTypes.bool,
	loading: PropTypes.bool,
	clientUpdated: PropTypes.func,
	createOpportunity: PropTypes.func,
	getAccountExecs: PropTypes.func,
	getClients: PropTypes.func,
	history: PropTypes.object,
	isLead: PropTypes.bool,
	togglePipelineModal: PropTypes.func,
	updateOpportunityColumn: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		tags: state.crm.opportunity.data.tags,
		fetched: state.crm.opportunity.data.fetched,
		loading: state.crm.opportunity.data.loading,
		error: state.crm.opportunity.data.error,
		isLead: state.crm.opportunity.data.isLead
	};
};

export default connect(
	mapStateToProps,
	// mapDispatchToProps
	actions
)(Opportunity);
