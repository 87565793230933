import React, {Component} from 'react';
import {Modal, Icon, ButtonGroup, Button} from 'semantic-ui-react';
import SKUGrid from '../sku/SKUGrid.jsx';
import PropTypes from 'prop-types';

export class SelectSKUsModal extends Component {
	constructor() {
		super(...arguments);
		this.state = {
			selectedSkus: []
		};
	}

	static propTypes = {
		title: PropTypes.string,

		open: PropTypes.bool.isRequired,
		skus: PropTypes.array.isRequired,
		loading: PropTypes.bool,
		onClose: PropTypes.func //(skus :[]skuObject?)
	};

	render() {
		return (
			<Modal style={{marginTop: 0}} open={this.props.open} size="fullscreen">
				<Modal.Header>
					<div
						style={{
							display: 'flex',
							flexFlow: 'row wrap',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<div>
							<Icon name="barcode" />
							{this.props.title || 'Select SKUs'}
						</div>
						<ButtonGroup>
							<Button color="red" onClick={() => this.props.onClose()}>
								Cancel
							</Button>
							<Button color="green" onClick={() => this.props.onClose(this.state.selectedSkus)}>
								Add Selected
							</Button>
						</ButtonGroup>
					</div>
				</Modal.Header>
				<Modal.Content>
					<div style={{height: '60vh'}}>
						<SKUGrid
							data={this.props.skus}
							loading={this.props.loading}
							selectable
							onSelectionChange={(selectedSkus) => this.setState({selectedSkus})}
						/>
					</div>
				</Modal.Content>
			</Modal>
		);
	}
}

export default SelectSKUsModal;
