import React from 'react';
import {Header, TextArea, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';
import {updateOpportunityColumn} from '../services/opportunityActions.js';

let OppDesc = ({id, description, updateOpportunityColumn, isLead, validation}) => (
	<Header size="tiny" key="opp-desc">
		Description
		<DebounceInput
			element={TextArea}
			key="opp-desc-textarea"
			fluid
			className="hiddenInput"
			placeholder={`Give the ${isLead ? 'lead' : 'opportunity'} a description.`}
			debounceTimeout={600}
			style={{width: '100%', height: '5rem', border: 'none'}}
			value={description}
			onChange={(event) => {
				updateOpportunityColumn(id, 'description', event.target.value);
			}}
			onKeyPress={(event) => {
				if (event.key === 'Enter') {
					updateOpportunityColumn(id, 'description', event.target.value);
				}
			}}
		/>
		{validation.description ? (
			<Label basic color="red" className="crm" pointing>
				Enter a description!
			</Label>
		) : null}
	</Header>
);

OppDesc.propTypes = {
	description: PropTypes.string,
	id: PropTypes.string,
	updateOpportunityColumn: PropTypes.func,
	isLead: PropTypes.bool,
	validation: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		description: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.description : null,
		id: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.id : null,
		isLead: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.isLead : null,
		validation: state.crm.opportunity.data.validation
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateOpportunityColumn: (id, column, value) => {
			dispatch(updateOpportunityColumn(id, column, value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OppDesc);
