import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Segment, Label} from 'semantic-ui-react';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {getSalesRepresentative, updateCommitsItem} from '../services/commitsActions.js';
import {billingColumnMasterDefs, billingColumnDetailDefs} from '../services/commitsReducer.js';

class Billings extends React.Component {
	constructor() {
		super();
		this.state = {
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true
			},
			sideBar: {
				toolPanels: [
					{
						id: 'columns',
						labelDefault: 'Columns',
						labelKey: 'columns',
						iconKey: 'columns',
						toolPanel: 'agColumnsToolPanel',
						toolPanelParams: {
							suppressRowGroups: true,
							suppressValues: true,
							suppressPivots: true,
							suppressPivotMode: true
						}
					},
					{
						id: 'filters',
						labelDefault: 'Filters',
						labelKey: 'filters',
						iconKey: 'filter',
						toolPanel: 'agFiltersToolPanel'
					}
				],
				position: 'right'
			}
		};
	}

	onQuickFilterChanged(value) {
		this.gridApi.setQuickFilter(value);
	}

	exportHandler(value) {
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				return params.value;
			}
		};
		this.gridApi.exportDataAsExcel(params);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	editField = (params) => {
		const {rowIndex, data, newValue, colDef} = params;
		this.props.updateCommitsItem(data, colDef.field, 'master', 'CommitBilling');
	};

	detailCellRendererParams = (props) => (parentProps) => ({
		detailGridOptions: {
			columnDefs: billingColumnDetailDefs,
			onFirstDataRendered(params) {
				this.gridApi = params.api;
				params.api.sizeColumnsToFit();
			},
			onCellValueChanged(params) {
				const parentData = parentProps.data;
				const {data, colDef, oldValue} = params;
				this.gridApi = parentProps.api;
				props.updateCommitsItem(data, colDef.field, 'detail', 'CommitBilling', parentData, oldValue);
				this.gridApi.refreshView();
			}
		},
		getDetailRowData: function (params) {
			params.successCallback(params.data.commitBilling);
		}
	});

	render() {
		const {isLoading} = this.props;
		return (
			<Segment basic style={{marginTop: '0', paddingTop: 0}}>
				<Segment style={{padding: 0}} loading={isLoading}>
					<Label attached="top" icon="search" color="blue" style={{position: 'relative', padding: 0}}>
						<div
							style={{
								display: 'inline-block',
								padding: '1.2em'
							}}
						>
							{'Billings'}
						</div>
					</Label>
					<div className="fixGridMargin">
						<div
							className="ag-theme-balham"
							style={{
								height: '45vh',
								width: '100%'
							}}
						>
							<AgGridReact
								getRowStyle={this.getRowStyle}
								animateRows={true}
								defaultColDef={this.state.defaultColDef}
								modules={AllModules}
								suppressRowClickSelection={false}
								sideBar={this.state.sideBar}
								suppressDragLeaveHidesColumns={true}
								suppressContextMenu={true}
								suppressMultiRangeSelection={true}
								onGridReady={this.onGridReady}
								columnDefs={billingColumnMasterDefs}
								rowData={this.props.rowData}
								masterDetail={true}
								detailCellRendererParams={this.detailCellRendererParams(this.props)}
								onCellValueChanged={this.editField}
								pinnedBottomRowData={this.props.total}
								{...this.props}
							/>
						</div>
					</div>
				</Segment>
			</Segment>
		);
	}
}

Billings.propTypes = {
	isLoading: PropTypes.bool,
	rowData: PropTypes.array
};

const mapStateToProps = (state) => ({
	rowData: state.crm.commits.data.salesRepresentative,
	isLoading: state.crm.commits.data.loading,
	total: state.crm.commits.data.bookingTotals
});

const mapDispatchToProps = (dispatch) => ({
	getSalesRepresentative: () => dispatch(getSalesRepresentative()),
	updateCommitsItem: (data, field, gridLevel, grid, parentData, oldValue) =>
		dispatch(updateCommitsItem(data, field, gridLevel, grid, parentData, oldValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(Billings);
