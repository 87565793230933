import React from 'react';
import {Form, Input} from 'semantic-ui-react';
import {debounceEventHandler, debounce} from '../../../../../../../lib/old/debounce.js';
import MaskedInput from 'react-text-mask';

// Creating a standardized form solution would save a lot of work in the long run

function UserCreationDefaultField(props) {
	let {fields, field, labelStyle, fieldUpdated} = props;
	let value = fields[field.key];

	let maskedInput = null;
	if (field.mask) {
		maskedInput = (
			<MaskedInput
				mask={field.mask}
				guide={false}
				placeholder={field.placeholder}
				// value={field.value}
				onChange={debounceEventHandler(debounce((e) => fieldUpdated(e.target.value, field.key), 600))}
			/>
		);
	}

	// dateOfHire is stored in the DB as a datetime field; meaning it appends hours, minutes, and seconds to the end of the db value
	// in order to display the value in the date field, we need to trim that excess content
	if (field.type === 'date' && value && value.includes('T')) {
		value = value.split('T')[0];
	}

	return (
		<Form.Field required={field.isRequired}>
			<label key={`label_${field.key}`} style={labelStyle}>
				{field.label}
			</label>
			<Input
				key={`input_${field.key}`}
				defaultValue={value}
				type={field.type}
				onChange={debounceEventHandler(
					debounce((e) => {
						if (field.key === 'state') {
							fieldUpdated(e.target.value.toUpperCase(), field.key);
						} else {
							fieldUpdated(e.target.value, field.key);
						}
					}, 600)
				)}
				maxLength={field.maxLength}
				children={maskedInput}
			/>
		</Form.Field>
	);
}

export default UserCreationDefaultField;
