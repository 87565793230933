import {Label, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const SwipeLabels = () => (
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={<Label key={uuidv4()} color={`blue`} icon={`resize horizontal`} content={` Swipe to Complete`} />}
		content="Swipe to complete task."
		on="hover"
	/>
);
export default SwipeLabels;

SwipeLabels.propTypes = {
	task: PropTypes.object.isRequired
};
