import {Label, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const CheckOutLabels = (props) => [
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={<Label key={uuidv4()} color={`blue`} icon={`check square`} content={` Checkout`} />}
		content="A checkout task."
		on="hover"
	/>,
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={
			<Label
				key={uuidv4()}
				icon={'write'}
				content={
					props.task.asdVerification ? ` Requires ASD Verification` : ` Does not require ASD verification`
				}
			/>
		}
		content="Displays if the task requires ASD verification or not."
		on="hover"
	/>,
	props.task.asdVerification ? (
		<Popup
			key={uuidv4()}
			size={'tiny'}
			trigger={
				<Label
					key={uuidv4()}
					icon={'key'}
					content={` ${props.task.checkoutCode || 'No checkout code found'}`}
				/>
			}
			content="The checkout code for ASD verification."
			on="hover"
		/>
	) : null,
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={
			<Label
				key={uuidv4()}
				icon={'user'}
				content={
					props.task.customerVerification
						? ` Requires customer verification`
						: ` Does not require customer verification`
				}
			/>
		}
		content="Displays if the task requires customer verification or not."
		on="hover"
	/>,
	props.task.customerVerification ? (
		props.task.signatureDriveLinks ? (
			props.task.signatureDriveLinks.length > 0 ? (
				props.task.signatureDriveLinks.map((link) => (
					<Popup
						key={uuidv4()}
						size={'tiny'}
						trigger={
							<Label
								key={uuidv4()}
								color={`green`}
								icon={'linkify'}
								content={<a href={link.signatureDriveLink}> {link.signatureDriveLink}</a>}
							/>
						}
						content="Click to see signature."
						on="hover"
					/>
				))
			) : (
				<Popup
					key={uuidv4()}
					size={'tiny'}
					trigger={<Label key={uuidv4()} color={`red`} icon={`x`} content={` Not signed by customer`} />}
					content="Displays if the customer has not signed off."
					on="hover"
				/>
			)
		) : (
			<Popup
				key={uuidv4()}
				size={'tiny'}
				trigger={<Label key={uuidv4()} color={`red`} icon={`x`} content={` Not signed by customer`} />}
				content="Displays if the customer has not signed off."
				on="hover"
			/>
		)
	) : null,
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={
			<Label
				key={uuidv4()}
				icon={'user'}
				content={
					props.task.requiresOmniApproval
						? ` Requires OMNI Verification`
						: `Does Not Require OMNI Verification`
				}
			/>
		}
		content="Displays if OMNI verification is required or not."
		on="hover"
	/>,
	props.task.requiresOmniApproval ? (
		<Popup
			key={uuidv4()}
			size={'tiny'}
			trigger={
				<Label
					key={uuidv4()}
					color={props.task.approved ? 'green' : 'red'}
					icon={props.task.approved ? 'check' : 'x'}
					content={props.task.approved ? ' Approved by OMNI' : ' Not approved by OMNI'}
				/>
			}
			content="Displays if OMNI verification is required or not."
			on="hover"
		/>
	) : null
];
export default CheckOutLabels;

CheckOutLabels.propTypes = {
	task: PropTypes.object.isRequired
};
