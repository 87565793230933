import React, {Component} from 'react';
import {Label} from 'semantic-ui-react';

export default class daysToCellRenderer extends Component {
	render() {
		return this.props.value && (this.props.value.number || this.props.value.number === 0) ? (
			<Label color={this.props.value.color} className="daysToCellRenderer">
				{this.props.value.number}
				{this.props.value.number === 1 ? ' day' : ' days'}
			</Label>
		) : null;
	}
}
