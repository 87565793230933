import React from 'react';
import {Label, Input} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';

import * as actions from '../../../../../../services/workRecordActions.js';

const AddOrderGroupModalInputField = (props) => (
	<div style={{flexGrow: '1', margin: '0 1rem'}}>
		<div style={{paddingTop: '0em'}}>
			<Label
				style={{
					backgroundColor: 'transparent',
					fontSize: '1rem',
					color: 'rgba(0,0,0,.8)',
					paddingLeft: '0'
				}}
			>
				{props.field.name}
			</Label>
		</div>
		<DebounceInput
			element={Input}
			onChange={(e) => {
				props.addOrderGroupModalUpdateColumn(props.fieldIndex, e.target.value, false);
			}}
			debounceTimeout={600}
			value={props.quotedJobNumber ? props.quotedJobNumber : props.field.value}
			style={{
				width: '100%'
			}}
			error={Boolean(props.field.error)}
			disabled={props.field.disabled}
			placeholder={props.field.placeholder}
		/>
	</div>
);

export default connect(null, actions)(AddOrderGroupModalInputField);
