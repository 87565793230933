export function retrieveTemplatesIfNeeded(areTemplatesBeingRetrieved, retrieveTemplates) {
	if (!areTemplatesBeingRetrieved) {
		retrieveTemplates();
	}
}

export function createTaskListDropdownActions(taskList) {
	if (taskList) {
		return taskList
			.filter((type) => type.active === true)
			.map((taskType) => {
				return {
					key: taskType.id,
					value: taskType.taskName,
					text: taskType.taskName
				};
			});
	}

	return [];
}

export function createInputMaskDropdownActions(inputMaskList) {
	if (inputMaskList) {
		return inputMaskList.map((inputMask) => {
			return {
				key: inputMask.maskId,
				value: inputMask.maskId,
				text: inputMask.maskName
			};
		});
	}
	return [];
}

export function createDropdownChoice(choices) {
	if (choices) {
		return choices.map((choice) => {
			// freshly created choices are different from choices returned by db and thus need to be handled differently
			if (typeof choice === 'string') {
				return {
					key: choice,
					value: choice,
					text: choice
				};
			} else {
				return {
					key: choice.choiceId,
					value: choice.choiceId,
					text: choice.choice
				};
			}
		});
	}
	return [];
}

export function createTaskObject(taskObjectOptions) {
	// workId, isSection, isSectionTask, tasksLength
	const {workId, isSection, isSectionTask, tasksLength, sectionId} = taskObjectOptions;
	let task = {
		name: 'New Task',
		taskTypeId: null,
		title: 'New Task',
		completed: null,
		directions: 'Describe what you would like the AASDI to do.',
		id: null,
		index: tasksLength, //options.length,
		taskOrder: tasksLength, //options.length,
		maskName: null,
		approved: false,
		asdVerification: null,
		customerVerification: null,
		requiresOmniApproval: null,
		choices: [],
		userChoice: {
			choice: null
		},
		maskId: null,
		url: null,
		pictureDriveLinks: [],
		signatureDriveLinks: [],
		pendingChanges: true,
		pinned: false,
		isSection: false,
		workId
	};

	if (isSection) {
		task = {
			...task,
			name: 'Section',
			title: 'New Section',
			directions: null,
			isSection: true,
			isSequential: true,
			taskTypeId: 10,
			tasks: {
				pinned: [],
				general: []
			}
		};
	}

	if (isSectionTask) {
		task = {
			...task,
			sectionId, //options.sectionId,
			isSectionTask //options.isSectionTask
		};
	}

	return task;
}
