import {validatePayload, validateGoogleDriveLink, hasName} from '../../../../../lib/old/validation.js';
import customFetch from '../../../../../lib/old/customFetch.js';

export const getAasdiGrid = () => {
	console.log('hit inside getAasdiGrid');
	return async (dispatch) => {
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi`;
		const fetchData = await customFetch(endpoint);
		const jsonData = await fetchData.json();

		dispatch({
			type: 'WORK_FETCH_AASDI_GRID',
			payload: validatePayload(jsonData)
		});
	};
};

export function toggleCreateAasdiModal(status) {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_CREATE_AASDI_MODAL',
			payload: status
		});
		if (status === false) {
			dispatch({type: 'CLEAR_AASDI_MODAL'});
		}
	};
}

export const setActiveStep = (currentStep, targetStep) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_AASDI_MODAL_SET_ACTIVE_STEP',
			payload: {
				currentStep,
				targetStep
			}
		});
	};
};

export const updateCreateModal = (modal, step, column, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_AASDI_MODAL_UPDATE',
			payload: {
				modal,
				step,
				column,
				value,
				error
			}
		});
	};
};

export const getAASDIByZip = (name, zip, radius, gridApi) => async (dispatch, getState) => {
	// let state = getState().work.aasdiGrid.rowData;
	// Loader
	if (gridApi) {
		gridApi.showLoadingOverlay();
	}

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/locations/${zip}?radius=${radius}`;
	if (name) {
		endpoint += `&name=${name}`;
	}

	dispatch(setZipErrorToggle(false));

	const searchAASDI = await customFetch(endpoint);
	let searchAASDIjson = await searchAASDI.json();

	if (searchAASDI.status !== 200) {
		if (searchAASDIjson.error === 'No results') {
			dispatch(setZipErrorToggle(false));

			dispatch({
				type: 'AASDI_ZIP_SEARCH_DATA',
				payload: []
			});
			gridApi.hideOverlay();
		} else {
			dispatch({
				type: 'AASDI_ZIP_SEARCH_DATA',
				payload: []
			});

			dispatch(setZipErrorToggle(true));

			dispatch({
				type: 'SET_LOADING',
				payload: {
					loading: false
				}
			});
			gridApi.hideOverlay();
		}

		return Promise.reject(searchAASDI.response);
	}

	if (searchAASDIjson.length > 0) {
		if (gridApi) {
			gridApi.hideOverlay();
		}
		dispatch({
			type: 'SET_LOADING',
			payload: {
				loading: false
			}
		});

		dispatch({
			type: 'AASDI_ZIP_SEARCH_DATA',
			payload: searchAASDIjson
		});
		if (gridApi) {
			gridApi.hideOverlay();
		}
	}
};

export function setZipErrorToggle(status) {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_ZIP_ERROR_TOGGLE',
			payload: status
		});
	};
}

export const addDocument = (fileName, googleDrive, docType, comments, sectionIndex) => {
	return (dispatch, getState) => {
		console.log('adddocuments', fileName, googleDrive, docType, comments, sectionIndex);
		const state = getState().work.aasdiGrid;
		const isValidGoogleDriveLink = validateGoogleDriveLink(googleDrive.value);
		if (!fileName.value || !isValidGoogleDriveLink || !docType.value) {
			const emptyFields = state.stepsAasdi.modalSteps[sectionIndex].modalFields
				.map((field, fieldIndex) => ({
					field: field,
					fieldIndex: fieldIndex
				}))
				.filter(
					(field) =>
						(field.field.checkForm && !field.field.value) ||
						(field.field.name === 'Google Drive Link' && !isValidGoogleDriveLink)
				);
			emptyFields.map((field) =>
				dispatch(updateCreateModal('stepsAasdi', sectionIndex, field.fieldIndex, field.field.value, true))
			);
		} else {
			dispatch({
				type: 'CREATE_AASDI_MODAL_ADD_DOCUMENT',
				payload: {
					sectionIndex,
					object: {
						fileName: fileName.value,
						googleDriveLink: googleDrive.value,
						docType: docType.value,
						comments: comments.value
					}
				}
			});
		}
	};
};

export const removeCreateAASDIDocumentGrid = (rowIndex, sectionIndex) => {
	return (dispatch) =>
		dispatch({
			type: 'CREATE_AASDI_REMOVE_DOC_GRID',
			payload: {
				sectionIndex,
				rowIndex
			}
		});
};

export const createNewAasdi = () => async (dispatch, getState) => {
	let fieldData = getState().work.aasdiGrid.stepsAasdi.modalSteps;
	// START VALIDATION
	let emptyArray = [];
	fieldData.forEach((step, stepIndex) => {
		step.modalFields.forEach((field, fieldIndex) => {
			if (field.required && !field.checkForm) {
				if (!field.value || field.value < 1 || field.value.length < 1) {
					emptyArray.push({step: stepIndex, field: fieldIndex});
				}
			}
		});
	});
	if (emptyArray.length > 0) {
		emptyArray.forEach((object) => {
			dispatch({
				type: 'CREATE_AASDI_MODAL_SHOW_REQUIRED',
				payload: {
					step: object.step,
					field: object.field
				}
			});
		});
		return;
	}
	const documentsTypes = fieldData.find(hasName('Documents')).documents;
	const requiredTypes = ['COI/WC', 'Signed Agreement', 'W9'];
	let missingDoc = false;

	if (documentsTypes) {
		if (documentsTypes.every((doc) => doc.docType in requiredTypes)) {
			missingDoc = true;
		}
	} else {
		missingDoc = true;
	}

	if (missingDoc) {
		dispatch({
			type: 'CREATE_AASDI_MODAL_ERROR',
			payload: {
				value: true,
				message: 'Required documents: COI/WC, Signed Agreement, W9.'
			}
		});
		return;
	}
	// END VALIDATION

	let aasdiId;
	// start loader
	dispatch({
		type: 'CREATE_AASDI_MODAL_LOADING',
		payload: {
			value: true
		}
	});

	let body = {
		name: fieldData[0].modalFields[0].value,
		website: fieldData[0].modalFields[1].value,
		statusId: 2,
		address: fieldData[0].modalFields[2].value,
		address2: fieldData[0].modalFields[3].value ? fieldData[0].modalFields[3].value : null,
		city: fieldData[0].modalFields[4].value,
		zip: fieldData[0].modalFields[5].value,
		state: fieldData[0].modalFields[6].value,
		countryId: fieldData[0].modalFields[7].value,
		documents: fieldData.find(hasName('Documents')).documents
	};

	try {
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi?shortAasdi=true `;
		const call = await customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(body)
		});
		let response = await call.json();

		if (call.status !== 200) {
			throw response;
		}
		aasdiId = response.id;
		dispatch({
			type: 'CREATE_AASDI_MODAL_ADD_AASDI_TO_GRID',
			payload: {
				response
			}
		});
	} catch (err) {
		dispatch({
			type: 'CREATE_AASDI_MODAL_ERROR',
			payload: {
				value: true,
				message: 'There was a problem creating this AASDI'
			}
		});
		dispatch({
			type: 'CREATE_AASDI_MODAL_LOADING',
			payload: {
				value: false
			}
		});
		console.log(`ERROR == ${JSON.stringify(err)}`);
	}

	let contactBody = {
		firstName: fieldData[1].modalFields[0].value,
		lastName: fieldData[1].modalFields[1].value,
		title: fieldData[1].modalFields[2].value ? fieldData[1].modalFields[2].value : null,
		email: fieldData[1].modalFields[3].value ? fieldData[1].modalFields[3].value : null,
		phone: fieldData[1].modalFields[4].value ? fieldData[1].modalFields[4].value : null,
		extension: fieldData[1].modalFields[5].value ? fieldData[1].modalFields[5].value : null,
		aasdiId: aasdiId
	};

	try {
		let contactEndpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts `;
		const call = await customFetch(contactEndpoint, {
			method: 'POST',
			body: JSON.stringify(contactBody)
		});
		let response = await call.json();
		if (call.status !== 200) {
			throw response;
		}
		dispatch({
			type: 'TOGGLE_CREATE_AASDI_MODAL',
			payload: false
		});
		dispatch({
			type: 'CREATE_AASDI_MODAL_LOADING',
			payload: {
				value: false
			}
		});
		dispatch({type: 'CLEAR_AASDI_MODAL'});
	} catch (err) {
		dispatch({
			type: 'CREATE_AASDI_MODAL_ERROR',
			payload: {
				value: true,
				message: 'There was a problem creating this AASDI'
			}
		});
		dispatch({
			type: 'CREATE_AASDI_MODAL_LOADING',
			payload: {
				value: false
			}
		});
		dispatch({type: 'CLEAR_AASDI_MODAL'});
		console.log(`ERROR == ${JSON.stringify(err)}`);
	}
};

export const toggleDeactivateAasdiModal = () => {
	return (dispatch) => {
		dispatch({
			type: 'DEACTIVATE_AASDI_MODAL_TOGGLE'
		});
	};
};

export const deactivateAasdiModalLoading = () => {
	return (dispatch) => {
		dispatch({
			type: 'DEACTIVATE_AASDI_MODAL_LOADING'
		});
	};
};

export const toggleDeactivateAasdiErrorHidden = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_DEACTIVATE_AASDI_ERROR',
			payload: {
				hidden: value
			}
		});
	};
};

export const deactivateAasdi = (id, rowIndex) => async (dispatch) => {
	try {
		dispatch(deactivateAasdiModalLoading());
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/${id}`;
		const body = {statusId: 4};
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(body)
		});
		const response = await call.json();
		console.log(response);

		if (call.status === 200) {
			dispatch({
				type: 'UPDATE_AASDI_STATUS',
				payload: {
					aasdiId: id,
					statusId: body.statusId
				}
			});
			dispatch(toggleDeactivateAasdiModal());
		} else {
			dispatch(toggleDeactivateAasdiErrorHidden(false));
		}
	} catch (err) {
		console.error(err);
	} finally {
		dispatch(deactivateAasdiModalLoading());
	}
};

export const toggleActivateAasdiModal = () => {
	return (dispatch) => {
		dispatch({
			type: 'ACTIVATE_AASDI_MODAL_TOGGLE'
		});
	};
};

export const activateAasdiModalLoading = () => {
	return (dispatch) => {
		dispatch({
			type: 'ACTIVATE_AASDI_MODAL_LOADING'
		});
	};
};

export const toggleActivateAasdiErrorHidden = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_ACTIVATE_AASDI_ERROR',
			payload: {
				hidden: value
			}
		});
	};
};

export const activateAasdi = (id, rowIndex) => async (dispatch) => {
	try {
		dispatch(activateAasdiModalLoading());
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/${id}`;
		const body = {statusId: 1};
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(body)
		});
		const response = await call.json();
		console.log(response);

		if (call.status === 200) {
			dispatch({
				type: 'UPDATE_AASDI_STATUS',
				payload: {
					aasdiId: id,
					statusId: body.statusId
				}
			});
			dispatch(toggleActivateAasdiModal());
		} else {
			dispatch(toggleActivateAasdiErrorHidden(false));
		}
	} catch (err) {
		console.error(err);
	} finally {
		dispatch(activateAasdiModalLoading());
	}
};

export function closeError() {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_AASDI_MODAL_ERROR',
			payload: {
				value: false,
				message: 'There was a problem creating this AASDI'
			}
		});
	};
}

export function toggleCertModal(value) {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_CERT_MODAL_TOGGLE',
			payload: {value}
		});
	};
}

export function addSearchCert(id) {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_CERT_MODAL_SELECT',
			payload: {value: id}
		});
	};
}

export function removeSearchCert(id) {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_CERT_MODAL_DESELECT',
			payload: {value: id}
		});
	};
}

export function clearSearchCert(gridApi) {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_CERT_MODAL_CLEAR'
		});
		gridApi.setQuickFilter('');
	};
}

export function switchStateInputType(stepIndex, value) {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_CERT_MODAL_SWITCH_STATE_INPUT_TYPE',
			payload: {
				isUSA: value === 218,
				step: stepIndex
			}
		});
	};
}
