import update from 'react-addons-update';

export default (
	state = {
		contactConfirmationModal: {
			loading: false,
			toggleContactConfirmationModal: false,
			potentialContacts: [],
			selectedContact: {},
			newContact: {},
			clientId: null,
			workOrderType: 'workOrder',
			error: false
		}
	},
	action
) => {
	switch (action.type) {
		case 'CONTACT_CONFIRMATION_MODAL_LOADING':
			state = update(state, {
				contactConfirmationModal: {
					loading: {
						$set: action.payload
					}
				}
			});
			break;
		case 'CONTACT_CONFIRMATION_MODAL_TOGGLE':
			state = update(state, {
				contactConfirmationModal: {
					toggleContactConfirmationModal: {
						$set: action.payload
					}
				}
			});
			break;
		case 'CONTACT_CONFIRMATION_MODAL_ADD_CONTACT_OPTIONS':
			state = update(state, {
				contactConfirmationModal: {
					potentialContacts: {
						$set: action.payload
					}
				}
			});
			break;
		case 'CONTACT_CONFIRMATION_MODAL_ADD_CLIENT_ID':
			state = update(state, {
				contactConfirmationModal: {
					clientId: {
						$set: action.payload
					}
				}
			});
			break;
		case 'CONTACT_CONFIRMATION_MODAL_SET_WO_TYPE':
			state = update(state, {
				contactConfirmationModal: {
					workOrderType: {
						$set: action.payload
					}
				}
			});
			break;
		case 'CONTACT_CONFIRMATION_MODAL_ERROR':
			state = update(state, {
				contactConfirmationModal: {
					error: {
						$set: action.payload
					}
				}
			});
			break;
		case 'CONTACT_CONFIRMATION_MODAL_SET_NEW_CONTACT':
			state = update(state, {
				contactConfirmationModal: {
					newContact: {
						$set: action.payload
					}
				}
			});
			break;
		case 'CONTACT_CONFIRMATION_MODAL_SET_SELECTED_CONTACT':
			state = update(state, {
				contactConfirmationModal: {
					selectedContact: {
						$set: action.payload
					}
				}
			});
			break;
	}

	return state;
};

export const contactConfirmationColDefs = [
	{
		headerName: 'Name',
		field: 'fullName',
		width: 275
	},
	{
		headerName: 'Title',
		field: 'title',
		width: 175
	},
	{
		headerName: 'Email',
		field: 'email',
		width: 275
	},
	{
		headerName: 'Phone',
		field: 'phone',
		width: 150
	},
	{
		headerName: 'Extension',
		field: 'extension',
		width: 150
	}
];
