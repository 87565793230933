import React from 'react';
import {Modal, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';

// Use to display a basic message to the user
function MessageModal(props) {
	return (
		<Modal open={props.modalToggle} size="small">
			<Modal.Content>
				<h3>{props.message}</h3>
				<p>{props.details}</p>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={props.onClick} basic>
					OK
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

MessageModal.propTypes = {
	details: PropTypes.string,
	message: PropTypes.string,
	modalToggle: PropTypes.bool,
	onClick: PropTypes.func
};

export default MessageModal;
