import React from 'react';
import {Label} from 'semantic-ui-react';
import {connect} from 'react-redux';

import PropTypes from 'prop-types';

// import * as actions from '../../../../../../../components/contactModal/services/contactModalActions.js';
// import { toggleCreateWorkOrderAasdiContactModal } from '../../../services/createWorkOrderActions.js';
import * as actions from '../../../services/createWorkOrderActions.js';

import AasdiContactModal from './workOrderAASDIModal/components/aasdiContactSearchModal.js';

const AASDIActionContactRenderer = (props) => (
	<div>
		<Label
			onClick={() => {
				props.toggleCreateWorkOrderAasdiContactModal(true);
			}}
			style={{
				width: '100%',
				backgroundColor: 'transparent'
			}}
		>
			{props.aasdiItems[props.rowIndex].contactObject
				? Number.isInteger(props.aasdiItems[props.rowIndex].contactObject.id)
					? props.aasdiItems[props.rowIndex].contactObject.name
					: 'Add Contact'
				: 'Add Contact'}
		</Label>
		<AasdiContactModal aasdiId={props.aasdiItems[props.rowIndex].aasdiId} rowIndex={props.rowIndex} />
	</div>
);

AASDIActionContactRenderer.propTypes = {
	aasdiItems: PropTypes.array,
	rowIndex: PropTypes.number,
	toggleCreateWorkOrderAasdiContactModal: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		clientId: state.work.createWorkOrder.createWorkOrderModal.sections[1].fields[0].value,
		aasdiItems: state.work.createWorkOrder.createWorkOrderModal.sections[6].AASDI
	};
};
export default connect(mapStateToProps, actions)(AASDIActionContactRenderer);
