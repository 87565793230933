import { nothing } from 'immer'
import { createContext, useContext, createElement, PropsWithChildren, FC } from 'react'
import { useImmer } from 'use-immer'
import { JsonPointer } from "json-ptr"

import type { ImmerHook } from "use-immer"

//This is a big utility type to let us path walk a type with a string
export type SplitPath<T extends string> = T extends `${infer Prop}/${infer Rest}` ? [Prop, Rest] : [unknown, T]

export type ExtractProp<Value, Prop extends string> = 
    //try to see if prop is a number
    Prop extends `${number}` ? 
        number extends keyof Value ? Value[number] :
        unknown :
    //check if prop is in value
	Prop extends keyof Value ? Value[Prop] : unknown

export type PathExtract<Value, Path extends string> =
    Extract<Value, SplitPath<Path>[0], SplitPath<Path>[1]>

export type Extract<Value, Prop, Rest extends string> = 
    Prop extends string ? PathExtract<ExtractProp<Value, Prop>, Rest> :
    ExtractProp<Value, Rest>

export function createAppState<T>(initialState: T) {
    const ctx = createContext<ImmerHook<T>>([
        initialState,
        () => {throw new Error("Cannot update app state because provider is missing")}
    ])
    ctx.displayName = "AppState"
  
    const AppStateProvider: FC<PropsWithChildren<{}>> = ({children}) => {
        const immerHook = useImmer(initialState)
        return createElement(ctx.Provider, {value: immerHook}, children)
    }

    function useAppState(): ImmerHook<T>
    function useAppState<Path extends string>(path: Path): ImmerHook<PathExtract<T, Path>>
    function useAppState(path?: string): any {
        const [appState, updateAppState] = useContext(ctx)
        if(path === undefined) {
            return [appState, updateAppState] as ImmerHook<T>
        }

        const ptr = new JsonPointer("/" + path)
        return [
            ptr.get(appState),
            (val: unknown) => {
                updateAppState(draft => {
                    if(typeof val === "function") {
                        const newVal = val(ptr.get(draft))
                        if(newVal === nothing) {
                            ptr.set(draft, undefined)
                        } else if(newVal !== undefined) {
                            ptr.set(draft, newVal)
                        }
                    } else {
                        //set val at the target path
                        ptr.set(draft, val, true)
                    }
                })
            }
        ]
        
    }
  
    return { AppStateProvider, useAppState }
}