import React from 'react';
import {Label, Input} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';

import {updateCreateWorkOrderModal} from '../../services/createWorkOrderActions.js';

const InputField = (props) => (
	<div>
		<div
			style={{
				paddingTop: '1.2em'
			}}
		>
			<Label
				style={{
					backgroundColor: 'transparent',
					fontSize: '1rem',
					color: 'rgba(0,0,0,.8)',
					paddingLeft: '0'
				}}
			>
				{props.field.name}
			</Label>
		</div>
		<DebounceInput
			element={Input}
			onChange={(e) => {
				props.updateCreateWorkOrderModal(
					'createWorkOrderModal',
					props.sectionIndex,
					props.fieldIndex,
					e.target.value,
					false
				);
			}}
			debounceTimeout={600}
			value={props.field.value}
			style={{
				width: '100%'
			}}
			error={props.field.required || props.field.checkForm ? Boolean(props.field.error) : false}
			disabled={props.field.disabled}
			placeholder={props.field.placeholder}
		/>
	</div>
);

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'documentsSection'
		),
		sectionFields: state.work.createWorkOrder.createWorkOrderModal.sections.filter(
			(section) => section.name === 'documentsSection'
		)[0].fields,
		clientId: state.work.createWorkOrder.createWorkOrderModal.sections[0].fields[0].value
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateWorkOrderModal: (modal, sectionIndex, fieldIndex, value, error, inputType, data) => {
			dispatch(updateCreateWorkOrderModal(modal, sectionIndex, fieldIndex, value, error, inputType, data));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InputField);
