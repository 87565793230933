import _ from 'lodash';
import {WORK_RECORD_DISPLAY_ERROR, WORK_RECORD_HIDE_ERROR} from './workRecordErrorActions.js';

// left very simple on purpose; add functionality to this as needed
// switch to immutability-helper if state becomes much more complex than it currently is
const initialState = {
	display: false,
	message: '',
	icon: ''
};

export default function (state = initialState, action) {
	switch (action.type) {
		case WORK_RECORD_DISPLAY_ERROR:
			return {
				display: true,
				...action.payload
			};

		case WORK_RECORD_HIDE_ERROR:
			return _.cloneDeep(initialState);

		default:
			return state;
	}
}
