import update from 'immutability-helper';

import {TASK_TEMPLATES_SET_SNACKBAR_VALUES, TASK_TEMPLATES_RESET_SNACKBAR_VALUES} from '../TaskTemplates.actions.js';

export const snackbarInitialState = {
	snackbar: {
		loading: false,
		open: false,
		message: '',
		color: '',
		icon: ''
	}
};

export default function (state, action) {
	switch (action.type) {
		case TASK_TEMPLATES_SET_SNACKBAR_VALUES:
			state = update(state, {
				snackbar: {
					$merge: action.payload.updatedValues
				}
			});
			break;

		case TASK_TEMPLATES_RESET_SNACKBAR_VALUES:
			state = update(state, {
				snackbar: {
					$set: {
						open: false,
						message: '',
						color: '',
						loading: false,
						icon: '',
						redirect: ''
					}
				}
			});
			break;
	}
	return state;
}
