import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Label, Menu, Tab, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import WorkNav from '../work/components/workNav.js';
import Users from './components/Tab_Users.js';
import Approvals from './components/Tab_Approvals.js';
import Message from '../../components/Global/Messages.js';

import * as actions from './services/techManagementActions.js';
import './TechManagement.css';

export class Tech_Management extends Component {
	componentDidMount() {
		this.props.getUsers();
		this.props.getApprovals();
	}

	render() {
		const panes = [
			{
				menuItem: (
					<Menu.Item key="messages" color="blue">
						<Icon name="checkmark" size="large" />
						Approvals
						<Label>{this.props.totalCount}</Label>
					</Menu.Item>
				),
				render: () => <Approvals />
			},
			{
				menuItem: (
					<Menu.Item key="messages2" color="blue">
						<Icon name="user" size="large" />
						Users
						<Label>{this.props.totalCount}</Label>
					</Menu.Item>
				),
				render: () => <Users />
			}
		];

		return (
			<div style={{marginTop: '10vh'}}>
				<WorkNav style={{width: '20%', float: 'left'}} />
				<div style={{width: '80%', float: 'right', marginRight: '2vw'}}>
					<Message />
					<Tab panes={panes} {...this.props} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		techManagement: state.global.techManagement,
		totalCount: state.global.techManagement.users.totalCount,
		global: state.global.global
	};
};

Tech_Management.propTypes = {
	techManagement: PropTypes.shape({
		users: PropTypes.shape({
			totalCount: PropTypes.number
		}),
		approvals: PropTypes.shape({
			totalCount: PropTypes.number
		})
	}),
	getUsers: PropTypes.func,
	getApprovals: PropTypes.func,
	hideMessage: PropTypes.func
};

export default connect(mapStateToProps, actions)(Tech_Management);
