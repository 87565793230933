import React from 'react';
import {Table} from 'semantic-ui-react';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

const jobEndedInCurrentYear = (dateFieldToUse) => (job) => new Date(job[dateFieldToUse]).getFullYear() === currentYear;
const jobEndedInCurrentMonth = (dateFieldToUse) => (job) => new Date(job[dateFieldToUse]).getMonth() === currentMonth;

const sumJobRevenue = (accumulator, job) => accumulator + job.revenue;

const USD_formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

function RevenueTotals(props) {
	// ASD requested that the projectedOrderDate be used for Pipeline data and projectCreatedOnDate for Job Log data
	let dateFieldToUse = 'projectedOrderDate';

	if (!props.isUsingPipelineData) {
		dateFieldToUse = 'projectCreatedOnDate';
	}

	// originally this component took rowData from the same source as AGGrid; but it was requested that grid data and RevenueTotals be synced
	// the gridApi doesn't offer a simple "returnAllFilteredData" method to my knowledge, hence this distasteful solution
	let rowData = [];
	if (props.gridApi) {
		props.gridApi.forEachNodeAfterFilter((node) => {
			rowData.push(node.data);
		});
	}

	const tableRows = [
		{
			name: 'Month to Date Revenue',
			jobs: rowData.filter(jobEndedInCurrentYear(dateFieldToUse)).filter(jobEndedInCurrentMonth(dateFieldToUse)),
			get revenue() {
				return this.jobs.reduce(sumJobRevenue, 0);
			},
			get formattedValue() {
				return USD_formatter.format(this.revenue);
			}
		},
		{
			name: 'Year to Date Revenue',
			jobs: rowData.filter(jobEndedInCurrentYear(dateFieldToUse)),
			get revenue() {
				return this.jobs.reduce(sumJobRevenue, 0);
			},
			get formattedValue() {
				return USD_formatter.format(this.revenue);
			}
		},
		{
			name: 'Pipeline Average Revenue',
			jobs: rowData,
			get revenue() {
				return this.jobs.reduce(sumJobRevenue, 0) / this.jobs.length;
			},
			get formattedValue() {
				return USD_formatter.format(this.revenue);
			}
		}
	];

	return (
		<Table celled striped style={{width: '50%'}}>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>Name</Table.HeaderCell>
					<Table.HeaderCell>Value</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{tableRows.map((row, index) => (
					<Table.Row key={index} >
						<Table.Cell>{row.name}</Table.Cell>
						<Table.Cell>{row.formattedValue}</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	);
}

export default RevenueTotals;
