import React from 'react';
import {View, Text, Link, Image} from '@react-pdf/renderer';
import {isString, isObject} from 'lodash';

//Note: this function must always return an element from createPDFElement('div'). An underlying library requires it
export const createPDFElement = (element, props = {}, children = []) => {
	sanitizeStyles(props.style);
	children = fixChildrenElements(children);
	switch (element) {
		case 'div': {
			return createView(props.key, props.style, children);
		}
		case 'span': {
			return createText(props.key, props.style, children);
		}
		case 'p': {
			return createText(props.key, props.style, children);
		}
		case 'blockquote': {
			return createView(
				props.key,
				{
					...props.style,
					borderLeft: '3pt solid #ccc',
					marginTop: '10pt',
					marginBottom: '10pt',
					marginLeft: '20pt',
					paddingLeft: '10pt'
				},
				children
			);
		}
		case 'strong':
		case 'b': {
			return createText(
				props.key,
				{...props.style, fontFamily: 'Helvetica-Bold'}, //fontWeight: 'bold' <-- does not work because we dont have fonts set up properly
				children
			);
		}
		case 'em':
		case 'i': {
			return createText(
				props.key,
				{...props.style, fontFamily: 'Helvetica-Oblique'}, //fontStyle: 'italic' <-- does not work because we dont have fonts set up properly
				children
			);
		}
		case 'u': {
			return createText(props.key, {...props.style, textDecoration: 'underline'}, children);
		}
		case 'sup': {
			return createText(props.key, {...props.style, verticalAlign: 'super'}, children);
		}
		case 'sub': {
			return createText(props.key, {...props.style, verticalAlign: 'sub'}, children);
		}
		case 'a': {
			return createLink(props.key, props.style, props.href, children);
		}
		case 'img': {
			return createImage(props.key, props.style, props.src);
		}
		case 'ul': {
			return createView(
				props.key,
				{
					...props.style,
					marginLeft: '15pt'
				},
				children
			);
		}
		case 'ol': {
			return createView(
				props.key,
				{
					...props.style,
					marginLeft: '15pt'
				},
				children
			);
		}
		case 'li': {
			return createText(props.key, props.style, ['• ', ...children, '\n']);
		}
		case 'table': {
			console.error('table support not yet implemented.');
			return null;
		}
		case 'br': {
			//We need to return an element for br tags, because if we dont, they will be ignored/removed
			//react-pdf doesnt support br tags, so we replace them with newlines in fixChildrenElements
			return <br />;
		}
		default: {
			console.error(`unable to convert tag ${element} while converting html to pdf`);
			return null;
		}
	}
};

//https://developer.mozilla.org/en-US/docs/Web/CSS/font-size
//These are pt font sizes
const simpleFontSizeMap = {
	'xx-small': 6,
	'x-small': 8,
	small: 10,
	medium: 12, //12 is default font size
	large: 14,
	'x-large': 16,
	'xx-large': 18,
	'xxx-large': 20
};

//This funciton is used to fix all the react-pdf bugs or unsupported styles
const sanitizeStyles = (style) => {
	if (!isObject(style)) {
		return;
	}
	//react-pdf does not know how to process transparent
	//https://github.com/diegomura/react-pdf/issues/838
	if (style.backgroundColor === 'transparent') {
		delete style.backgroundColor;
	}

	//react-pdf does not support absolute font-sizes, need to convert to pt. values
	//see [insert github issue here]
	if (Object.keys(simpleFontSizeMap).includes(style.fontSize)) {
		style.fontSize = simpleFontSizeMap[style.fontSize];
	} else if (style.fontSize === 'larger' || style.fontSize === 'smaller') {
		//not sure if this actually causes problems, but im not chancing it.
		delete style.fontSize;
	}
};

const fixChildrenElements = (children) => {
	//react pdf cant render newlines or null
	return children
		.filter((child) => child !== '\n' && child !== null)
		.map((child) => {
			//check br section of createPDFElement for why we need to do this
			if (typeof child === 'object' && child.type === 'br') {
				return '\n';
			}
			return child;
		});
};

const createView = (key, style, children) => {
	children = children.map((child, index) => {
		if (isString(child)) {
			return createText(index, {}, child);
		} else {
			return child;
		}
	});

	return (
		<View key={key} style={style}>
			{children}
		</View>
	);
};

const createText = (key, style, children) => {
	if (!children) {
		//Return empty view. React PDF blows up if you render empty text block
		return <View></View>;
	}
	return (
		<Text key={key} style={{...style, fontFamily: 'Helvetica'}}>
			{children}
		</Text>
	);
};

const createLink = (key, style, src, children) => (
	<Link key={key} style={style} src={src}>
		{children}
	</Link>
);

const createImage = (key, style, src) => <Image src={src} key={key} style={style} />;

export default createPDFElement;
