// Modules
import React from 'react';
import {Grid, Label, Segment, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import AASDIModal from './components/workOrderAASDIModal/components/AASDIModal.js';
import WorkOrderTextAreaField from '../components/workOrderTextAreaField.js';
import WorkOrderDatePicker from '../components/workOrderDatePicker.js';
import AASDIActionCellRenderer from './components/aasdiActionCellRenderer.js';
import AASDIDatePickerCellRenderer from './components/aasdiDatePickerCellRenderer.js';
import AASDIInstructionsCellRenderer from './components/aasdiInstructionsCellRenderer.js';
import AASDIActionContactRenderer from './components/aasdiActionContactRenderer.js';
import * as actions from '../../services/createWorkOrderActions.js';

let AASDI = (props) => (
	<Segment>
		<Label attached="top" color="blue">
			AASDI
		</Label>
		<Grid>
			<Grid.Row>
				<Grid.Column width={12}>
					<div>
						<Button
							color="blue"
							floated="left"
							content="Add AASDI"
							icon="plus"
							onClick={() => props.toggleAASDIModal(props.sectionIndex)}
						/>
					</div>
				</Grid.Column>
				<Grid.Column width={16}>
					<div
						className="ag-theme-balham"
						style={{
							margin: '2% 0 0 0',
							height: '15rem',
							width: '100%'
						}}
					>
						<AgGridReact
							defaultColDef={{
								sortable: true,
								filter: true,
								resizable: true
							}}
							columnDefs={[
								{
									headerName: 'AASDI Name',
									field: 'name',
									maxWidth: 300
								},
								{
									headerName: 'Requested Time',
									field: 'requestedTime',
									cellRendererFramework: AASDIDatePickerCellRenderer,
									maxWidth: 250
								},
								{
									headerName: 'Actual Time',
									field: 'actualTime',
									cellRendererFramework: AASDIDatePickerCellRenderer,
									maxWidth: 250
								},
								{
									headerName: 'Special Instructions',
									field: 'specialInstructions',
									minWidth: 200,
									cellRendererFramework: AASDIInstructionsCellRenderer
								},
								{
									headerName: 'Contact',
									cellRendererFramework: AASDIActionContactRenderer,
									maxWidth: 200
								},
								{
									headerName: 'Remove',
									cellRendererFramework: AASDIActionCellRenderer,
									maxWidth: 100
								}
							]}
							modules={AllModules}
							onGridReady={(params) => {
								params.api.sizeColumnsToFit();
							}}
							animateRows={true}
							rowData={props.AASDIData ? props.AASDIData : []}
							{...props}
						/>
					</div>
				</Grid.Column>
				<Grid.Column width={8}>
					{props.selectedAASDIIndex > -1 ? (
						<div>
							<Grid.Column
								width={props.AASDIData[props.selectedAASDIIndex].requestedTime.columnWidth}
								style={{
									paddingTop: '0.8em'
								}}
							>
								<WorkOrderDatePicker
									field={props.AASDIData[props.selectedAASDIIndex].requestedTime}
									sectionIndex={props.sectionIndex}
								/>
							</Grid.Column>
							<Grid.Column
								width={props.AASDIData[props.selectedAASDIIndex].actualTime.columnWidth}
								style={{
									paddingTop: '0.8em'
								}}
							>
								<WorkOrderDatePicker
									field={props.AASDIData[props.selectedAASDIIndex].actualTime}
									sectionIndex={props.sectionIndex}
								/>
							</Grid.Column>
							<Grid.Column
								width={props.AASDIData[props.selectedAASDIIndex].specialInstructions.columnWidth}
								style={{marginTop: '1.5rem'}}
							>
								<WorkOrderTextAreaField
									field={props.AASDIData[props.selectedAASDIIndex].specialInstructions}
									sectionIndex={props.sectionIndex}
								/>
							</Grid.Column>
						</div>
					) : null}
				</Grid.Column>
			</Grid.Row>
		</Grid>
		<AASDIModal />
	</Segment>
);

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'AASDISection'
		),
		AASDIData:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'AASDISection'
				)
			].AASDI,
		selectedAASDIIndex: state.work.createWorkOrder.createWorkOrderModal.sections[
			state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
				(section) => section.name === 'AASDISection'
			)
		].AASDI.findIndex((aasdii) => aasdii.active),
		toggled:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'AASDISection'
				)
			].modal.toggled
	};
};

export default connect(mapStateToProps, actions)(AASDI);
