import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {removeDocument} from '../../../../../../services/opportunityActions.js';

class DocumentSectionRemoveButton extends Component {
	render() {
		return (
			<div style={{paddingTop: '.15em'}}>
				{console.log(this.props)}
				<Icon
					name="cancel"
					color="red"
					size="large"
					style={{cursor: 'pointer'}}
					onClick={() => {
						this.props.removeDocument(
							this.props.rowIndex,
							this.props.data.id,
							this.props.data.opportunityId
						);
					}}
				/>
			</div>
		);
	}
}

DocumentSectionRemoveButton.propTypes = {
	agGridReact: PropTypes.object,
	data: PropTypes.object,
	rowIndex: PropTypes.number,
	removeDocument: PropTypes.func
};

const mapDispatchToProps = {
	removeDocument: (rowIndex, id, oppId) => removeDocument(rowIndex, id, oppId)
};

export default connect(null, mapDispatchToProps)(DocumentSectionRemoveButton);
