// Module
import React from 'react';
import {List, Divider, Segment, Label, Popup, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Item from './components/Item.js';

// Service
import {getTechnologies} from './services/technologiesActions.js';

class Technologies extends React.Component {
	componentDidMount() {
		this.props.getTechnologies(this.props.id);
	}
	render() {
		return (
			<Segment loading={this.props.loading}>
				<Label color={'blue'} ribbon>
					Technologies
				</Label>
				{this.props.disabled ? (
					<Popup
						trigger={<Icon disabled name="info circle" />}
						header="This section is disabled"
						content="Once an opportunity is committed this section can't be updated."
						hideOnScroll
					/>
				) : (
					''
				)}
				<Divider />
				<List divided relaxed="very" verticalAlign="middle">
					{this.props.error ? (
						<Segment textAlign="center" basic>
							Hmmm, it looks like something went wrong when I was trying to pull this data. Try again, or
							contact the Dev team if it keeps happening.
							<Segment basic style={{paddingBottom: '0'}}></Segment>
						</Segment>
					) : (
						this.props.technologies.map((item) => (
							<Item
								key={item.id}
								id={item.id}
								oppId={this.props.id}
								disabled={this.props.disabled}
								{...item}
							/>
						))
					)}
				</List>
			</Segment>
		);
	}
}

Technologies.propTypes = {
	id: PropTypes.string.isRequired,
	fetched: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	getTechnologies: PropTypes.func.isRequired,
	technologies: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
	return {
		technologies: state.crm.opportunity.technologies.fetched ? state.crm.opportunity.technologies.items : [],
		fetched: state.crm.opportunity.technologies.fetched,
		loading: state.crm.opportunity.technologies.loading,
		error: state.crm.opportunity.technologies.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getTechnologies: (id) => {
			dispatch(getTechnologies(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Technologies);
