import React from 'react';
import {Checkbox, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {updateCreateWorkOrderModal} from '../../services/createWorkOrderActions.js';

const DropdownField = (props) => (
	<div
		style={{
			paddingTop: '1.5em'
		}}
	>
		<Label
			style={{
				backgroundColor: 'transparent',
				fontSize: '1rem',
				color: 'rgba(0,0,0,.8)',
				paddingLeft: '0',
				textAlign: 'center',
				width: '100%'
			}}
		>
			{props.field.name}
		</Label>
		<Checkbox
			toggle
			top
			style={{
				display: 'block',
				padding: '.8em 0 0 0em',
				margin: '0 auto'
			}}
			onChange={(e, data) => {
				props.updateCreateWorkOrderModal(
					'createWorkOrderModal',
					props.sectionIndex,
					props.fieldIndex,
					data.checked,
					false
				);
			}}
		/>
	</div>
);

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateWorkOrderModal: (modal, sectionIndex, fieldIndex, value, error) => {
			dispatch(updateCreateWorkOrderModal(modal, sectionIndex, fieldIndex, value, error));
		}
	};
};

export default connect(null, mapDispatchToProps)(DropdownField);
