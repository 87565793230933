import React, {Component} from 'react';
import {Segment} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';

class AgGrid extends Component {
	render() {
		const {rowData, columnDefs, onGridReady, frameworkComponents, gridStyle} = this.props;
		const defaultColDef = {
			sortable: true,
			filter: true,
			resizable: true
		};
		const sideBar = {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true
					}
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel'
				}
			],
			position: 'right'
		};
		const style = {height: '60vh', width: '100%', textAlign: 'left', boxSizing: 'border-box'};
		Object.assign(style, gridStyle);
		return (
			<Segment>
				<div
					className="ag-theme-balham"
					style={{
						height:
							window.location.href.indexOf('/crm/dashboard/pipeline/grid-view') >= 0
								? '45vh'
								: style.height,
						width: style.width,
						textAlign: style.textAlign,
						boxSizing: style.boxSizing
					}}
				>
					<AgGridReact
						paginationAutoPageSize={true}
						pagination={true}
						rowData={rowData}
						getRowStyle={this.getRowStyle}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						sideBar={sideBar}
						animateRows={true}
						onGridReady={onGridReady}
						modules={AllModules}
						suppressRowClickSelection={false}
						suppressDragLeaveHidesColumns={true}
						suppressContextMenu={true}
						suppressMultiRangeSelection={true}
						rowHeight={40}
						frameworkComponents={frameworkComponents}
						components={this.components}
						{...this.props}
					/>
				</div>
			</Segment>
		);
	}
}

AgGrid.propTypes = {
	rowData: PropTypes.array,
	columnDefs: PropTypes.array.isRequired,
	onGridReady: PropTypes.func,
	frameworkComponents: PropTypes.object,
	gridStyle: PropTypes.object
};

export default AgGrid;
