import React from 'react';
import {Popup, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const IconWithTooltip = (props) => (
	<Popup
		basic
		content={props.content}
		disabled={!props.content}
		trigger={<Icon name={props.name} color={props.color} size="large" />}
	/>
);

IconWithTooltip.propTypes = {
	content: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	color: PropTypes.string
};

export default IconWithTooltip;
