import {Label, Popup, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';

const URLLabels = (props) => [
	<Popup
		size={'tiny'}
		key={`openUrl`}
		trigger={<Label color={`blue`} icon={`external`} className="pseudoDragHandler" content={` Open a URL`} />}
		content="Open a URL task."
		on="hover"
	/>,
	props.task.url ? (
		<Popup
			size={'tiny'}
			key={`openUrlLink`}
			trigger={
				<Label className="pseudoDragHandler">
					<Icon className="pseudoDragHandler" name="linkify" />
					<a className="pseudoDragHandler" href={props.task.url} target="_blank">
						{' '}
						{/* {`${props.task.url.substring(0, 15)}...`} */}
						Click to open URL
					</a>
				</Label>
			}
			content="Click to open the URL in a new window."
			on="hover"
		/>
	) : null
];
export default URLLabels;

URLLabels.propTypes = {
	task: PropTypes.object.isRequired
};
