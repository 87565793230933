// TODOS
// Remove inline styling
import React from 'react';
import {Icon, Card, Header, Item} from 'semantic-ui-react';

import Task from './Task.jsx';
import Section from './Section.jsx';

import {SortableContainer} from 'react-sortable-hoc';

import {v4 as uuidv4} from 'uuid';

const TaskList = SortableContainer((props) => {
	return (
		<div>
			<Card fluid>
				<Card.Content>
					<Header as="h4" style={{paddingLeft: '.5rem'}}>
						<Icon name="pin" />
						<Header.Content>
							Pinned Tasks
							<Header.Subheader>The first tasks the AASDI will see.</Header.Subheader>
						</Header.Content>
					</Header>
				</Card.Content>
				<Card.Content style={{borderTop: '1px solid rgba(34,36,38,.1)'}}>
					<Item.Group divided>
						{props.pinned.map((task, index) => (
							<Task key={`pinned-${task.id}`} task={task} key={uuidv4()} index={index} />
						))}
					</Item.Group>
				</Card.Content>
			</Card>

			<Card fluid>
				<Card.Content>
					<Header as="h4" style={{paddingLeft: '.5rem'}}>
						<Icon name="tasks" />
						<Header.Content>Task List</Header.Content>
					</Header>
				</Card.Content>
				<Card.Content style={{borderTop: '1px solid rgba(34,36,38,.1)'}}>
					<Item.Group divided>
						{props.general.map((task, index) =>
							task.isSection ? (
								<Section key={uuidv4()} mode="templateManager" section={task} index={index} />
							) : (
								<Task key={uuidv4()} task={task} index={index} disabled={task.pinned} />
							)
						)}
					</Item.Group>
				</Card.Content>
			</Card>
		</div>
	);
});

export default TaskList;
