import React from 'react';
import {Grid, Header, Icon, Label, Input} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {updateRegistrationForm} from '../services/registrationActions.js';
import InputField from './components/inputField.js';
import DropdownField from './components/dropdownField.js';
import {MomentDatePicker} from '../../../../components/inputs/DatePicker.jsx';

const License = (props) => (
	<Grid>
		<Grid.Row>
			<Grid.Column width={16}>
				<Header as="h3">
					<Icon name="address card" />
					<Header.Content>License</Header.Content>
				</Header>
			</Grid.Column>
		</Grid.Row>
		<Header as="h4" textAlign="left">
			Low Voltage License
		</Header>
		<Grid.Row
			style={{
				border: '1px solid rgba(34,36,38,.15)',
				borderRadius: '.28571429rem',
				padding: '1.5rem'
			}}
		>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<DropdownField
					fieldLabel="licenseLowVoltageRequired"
					field={props.licenseLowVoltageRequired}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
			{props.licenseLowVoltageRequired.value === 'Yes'
				? [
						<Grid.Column
							width={16}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
							key="licenseLowVoltageIssuingCountry"
						>
							<DropdownField
								fieldLabel="licenseLowVoltageIssuingCountry"
								field={props.licenseLowVoltageIssuingCountry}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>,
						<Grid.Column
							width={16}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
							key="licenseLowVoltageIssuingState"
						>
							{props.licenseLowVoltageIssuingCountry.value === 218 ? (
								<DropdownField
									fieldLabel="licenseLowVoltageIssuingState"
									field={props.licenseLowVoltageIssuingState}
									stepIndex={props.stepIndex}
								/>
							) : (
								<InputField
									maxLength={2}
									fieldLabel="licenseLowVoltageIssuingState"
									field={props.licenseLowVoltageIssuingState}
									stepIndex={props.stepIndex}
									nonUSAState={true}
								/>
							)}
						</Grid.Column>,
						<Grid.Column
							width={16}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
							key="licenseLowVoltageExpirationDate"
						>
							<Label
								style={{
									backgroundColor: 'transparent',
									fontSize: '1rem',
									color: 'rgba(0,0,0,.8)',
									paddingLeft: '0'
								}}
							>
								{props.licenseLowVoltageExpirationDate.name}
							</Label>
							<Input fluid className="hiddenInput">
								<MomentDatePicker
									fieldLabel="licenseLowVoltageExpirationDate"
									field={props.licenseLowVoltageExpirationDate}
									stepIndex={props.stepIndex}
									selected={props.licenseLowVoltageExpirationDate.value}
									placeholderText="Select a date"
									onChange={(date) =>
										props.updateRegistrationForm(
											'registrationForm',
											props.stepIndex,
											'licenseLowVoltageExpirationDate',
											date,
											false
										)
									}
								/>
							</Input>
						</Grid.Column>
				  ]
				: null}
		</Grid.Row>
		<Header as="h4" textAlign="left" style={{paddingTop: '2rem'}}>
			Electrical License
		</Header>
		<Grid.Row
			style={{
				border: '1px solid rgba(34,36,38,.15)',
				borderRadius: '.28571429rem',
				padding: '1.5rem'
			}}
		>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<DropdownField
					fieldLabel="licenseElectricalRequired"
					field={props.licenseElectricalRequired}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
			{props.licenseElectricalRequired.value === 'Yes'
				? [
						<Grid.Column
							width={16}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
							key="licenseElectricalIssuingCountry"
						>
							<DropdownField
								fieldLabel="licenseElectricalIssuingCountry"
								field={props.licenseElectricalIssuingCountry}
								stepIndex={props.stepIndex}
							/>
						</Grid.Column>,
						<Grid.Column
							width={16}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
							key="licenseElectricalIssuingState"
						>
							{props.licenseElectricalIssuingCountry.value === 218 ? (
								<DropdownField
									fieldLabel="licenseElectricalIssuingState"
									field={props.licenseElectricalIssuingState}
									stepIndex={props.stepIndex}
								/>
							) : (
								<InputField
									maxLength={2}
									fieldLabel="licenseElectricalIssuingState"
									field={props.licenseElectricalIssuingState}
									stepIndex={props.stepIndex}
									nonUSAState={true}
								/>
							)}
						</Grid.Column>,
						<Grid.Column
							width={16}
							style={{
								paddingTop: '1rem',
								paddingBottom: '1rem'
							}}
							key="licenseElectricalExpirationDate"
						>
							<Label
								style={{
									backgroundColor: 'transparent',
									fontSize: '1rem',
									color: 'rgba(0,0,0,.8)',
									paddingLeft: '0'
								}}
							>
								{props.licenseElectricalExpirationDate.name}
							</Label>
							<Input fluid className="hiddenInput">
								<MomentDatePicker
									fieldLabel="licenseElectricalExpirationDate"
									field={props.licenseElectricalExpirationDate}
									stepIndex={props.stepIndex}
									selected={props.licenseElectricalExpirationDate.value}
									placeholderText="Select a date"
									onChange={(date) =>
										props.updateRegistrationForm(
											'registrationForm',
											props.stepIndex,
											'licenseElectricalExpirationDate',
											date,
											false
										)
									}
								/>
							</Input>
						</Grid.Column>
				  ]
				: null}
		</Grid.Row>
	</Grid>
);

const mapStateToProps = (state) => {
	return {
		stepIndex: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'License')[0].index,
		licenseLowVoltageRequired: state.registrationForm.registrationForm.fields.licenseLowVoltageRequired,
		licenseLowVoltageIssuingCountry: state.registrationForm.registrationForm.fields.licenseLowVoltageIssuingCountry,
		licenseLowVoltageIssuingState: state.registrationForm.registrationForm.fields.licenseLowVoltageIssuingState,
		licenseLowVoltageExpirationDate: state.registrationForm.registrationForm.fields.licenseLowVoltageExpirationDate,
		licenseElectricalRequired: state.registrationForm.registrationForm.fields.licenseElectricalRequired,
		licenseElectricalIssuingCountry: state.registrationForm.registrationForm.fields.licenseElectricalIssuingCountry,
		licenseElectricalIssuingState: state.registrationForm.registrationForm.fields.licenseElectricalIssuingState,
		licenseElectricalExpirationDate: state.registrationForm.registrationForm.fields.licenseElectricalExpirationDate
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateRegistrationForm: (modal, step, column, value, error) => {
			dispatch(updateRegistrationForm(modal, step, column, value, error));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(License);
