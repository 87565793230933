import React from 'react';
import {Grid, Divider, Header, Icon, Input, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {updateRegistrationForm} from '../services/registrationActions.js';

import InputField from './components/inputField.js';
import {MomentDatePicker} from '../../../../components/inputs/DatePicker.jsx';

const Insurance = (props) => (
	<Grid>
		<Grid.Row>
			<Grid.Column width={16}>
				<Header as="h3">
					<Icon name="umbrella" />
					<Header.Content>Insurance</Header.Content>
				</Header>
			</Grid.Column>
		</Grid.Row>
		<Header as="h4" textAlign="left">
			Liability Insurance
		</Header>
		<Grid.Row
			style={{
				border: '1px solid rgba(34,36,38,.15)',
				borderRadius: '.28571429rem',
				padding: '1.5rem'
			}}
		>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<InputField
					fieldLabel="insuranceLiabilityCompanyName"
					field={props.insuranceLiabilityCompanyName}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<InputField
					fieldLabel="insuranceLiabilityPolicyNumber"
					field={props.insuranceLiabilityPolicyNumber}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<Label
					style={{
						backgroundColor: 'transparent',
						fontSize: '1rem',
						color: 'rgba(0,0,0,.8)',
						paddingLeft: '0'
					}}
				>
					{props.insuranceLiabilityExpirationDate.name}
				</Label>
				<Input fluid className="hiddenInput">
					<MomentDatePicker
						fieldLabel="insuranceLiabilityExpirationDate"
						field={props.insuranceLiabilityExpirationDate}
						stepIndex={props.stepIndex}
						selected={props.insuranceLiabilityExpirationDate.value}
						placeholderText="Select a date"
						onChange={(date) =>
							props.updateRegistrationForm(
								'registrationForm',
								props.stepIndex,
								'insuranceLiabilityExpirationDate',
								date,
								false
							)
						}
					/>
				</Input>
			</Grid.Column>
			<Divider horizontal style={{padding: '2em 0em 3em 0em'}}>
				Optional
			</Divider>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<InputField
					fieldLabel="insuranceLiabilityBestRating"
					field={props.insuranceLiabilityBestRating}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
		<Header as="h4" textAlign="left" style={{paddingTop: '2rem'}}>
			Workers Compensation Insurance
		</Header>
		<Grid.Row
			style={{
				border: '1px solid rgba(34,36,38,.15)',
				borderRadius: '.28571429rem',
				padding: '1.5rem'
			}}
		>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<InputField
					fieldLabel="insuranceWorkersCompensationName"
					field={props.insuranceWorkersCompensationName}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<InputField
					fieldLabel="insuranceWorkersCompensationPolicyNumber"
					field={props.insuranceWorkersCompensationPolicyNumber}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<Label
					style={{
						backgroundColor: 'transparent',
						fontSize: '1rem',
						color: 'rgba(0,0,0,.8)',
						paddingLeft: '0'
					}}
				>
					{props.insuranceWorkersCompensationExpirationDate.name}
				</Label>
				<Input fluid className="hiddenInput">
					<MomentDatePicker
						fieldLabel="insuranceWorkersCompensationExpirationDate"
						field={props.insuranceWorkersCompensationExpirationDate}
						stepIndex={props.stepIndex}
						selected={props.insuranceWorkersCompensationExpirationDate.value}
						placeholderText="Select a date"
						onChange={(date) =>
							props.updateRegistrationForm(
								'registrationForm',
								props.stepIndex,
								'insuranceWorkersCompensationExpirationDate',
								date,
								false
							)
						}
					/>
				</Input>
			</Grid.Column>
			<Divider horizontal style={{padding: '2em 0em 3em 0em'}}>
				Optional
			</Divider>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<InputField
					fieldLabel="insuranceWorkersCompensationBestRating"
					field={props.insuranceWorkersCompensationBestRating}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
			<Grid.Column
				width={16}
				style={{
					paddingTop: '1rem',
					paddingBottom: '1rem'
				}}
			>
				<InputField
					fieldLabel="insuranceWorkersCompensationModRating"
					field={props.insuranceWorkersCompensationModRating}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

const mapStateToProps = (state) => {
	return {
		stepIndex: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Insurance')[0].index,
		insuranceLiabilityCompanyName: state.registrationForm.registrationForm.fields.insuranceLiabilityCompanyName,
		insuranceLiabilityPolicyNumber: state.registrationForm.registrationForm.fields.insuranceLiabilityPolicyNumber,
		insuranceLiabilityExpirationDate:
			state.registrationForm.registrationForm.fields.insuranceLiabilityExpirationDate,
		insuranceLiabilityBestRating: state.registrationForm.registrationForm.fields.insuranceLiabilityBestRating,
		insuranceWorkersCompensationName:
			state.registrationForm.registrationForm.fields.insuranceWorkersCompensationName,
		insuranceWorkersCompensationPolicyNumber:
			state.registrationForm.registrationForm.fields.insuranceWorkersCompensationPolicyNumber,
		insuranceWorkersCompensationExpirationDate:
			state.registrationForm.registrationForm.fields.insuranceWorkersCompensationExpirationDate,
		insuranceWorkersCompensationBestRating:
			state.registrationForm.registrationForm.fields.insuranceWorkersCompensationBestRating,
		insuranceWorkersCompensationModRating:
			state.registrationForm.registrationForm.fields.insuranceWorkersCompensationModRating
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateRegistrationForm: (modal, step, column, value, error) => {
			dispatch(updateRegistrationForm(modal, step, column, value, error));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Insurance);
