import {Button, Icon, Modal, Header, Dropdown, TextArea, Input} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const completeTaskModal = (props) => (
	<Modal closeIcon open={props.tasks.ui.toggleCompleteTaskModal} onClose={() => props.toggleCompleteTaskModal()}>
		<Header icon="check" content="Complete Task" />
		<Modal.Content>
			{/* <Loader
				disabled={
					props.tasks.edit.toggleCompleteTaskLoading === true
						? false
						: true
				}
			/> */}
			{(() => {
				if (props.task !== undefined && props.task.id) {
					switch (props.task.taskTypeId) {
						case 1:
							return (
								<div>
									<h1 style={{color: 'red'}}>
										Be advised you are SKIPPING this task. Please complete from a mobile device if
										you need to add a photo to the task.
									</h1>
									<h3>Are you sure you would like to complete this task?</h3>
								</div>
							);
						case 3:
							return (
								<div>
									<h3>
										Please fill in the input with the required input type (i.e. phone number, email,
										etc.)
									</h3>
									<Input
										fluid
										className="editFocus"
										style={{margin: '30px'}}
										key={`${props.task.id}`}
										label={`Input Type: ${props.task.maskName}`}
										placeholder={
											props.task.maskExample ? props.task.maskExample : props.task.maskName
										}
										onChange={(e, {value}) => {
											props.updateFormUserInput(
												props.aasdiIndex,
												props.groupIndex,
												props.tasks.ui.panel.index,
												value,
												props.task
											);
										}}
										value={props.task.formUserInput ? props.task.formUserInput : null}
									/>
								</div>
							);
						case 4:
							return (
								<div>
									<h1 style={{color: 'red'}}>
										Be advised you are SKIPPING this task. Please complete from a mobile device if
										you need to add a signature to the task.
									</h1>
									<h3>Are you sure you would like to complete this task?</h3>
								</div>
							);
						case 6:
							return (
								<div>
									<h3>Please select a choice from the dropdown below.</h3>
									<Dropdown
										selection
										value={props.task.userChoice ? props.task.userChoice.choice : null}
										options={dropdownTasks(props.task.choices)}
										onChange={(e, {value}) => {
											props.updateUserDropdownChoice(
												props.aasdiIndex,
												props.groupIndex,
												props.tasks.ui.panel.index,
												value,
												props.task.choices.find((x) => x.choice === value).choiceId,
												props.task
											);
										}}
									/>
								</div>
							);
						case 7:
							return (
								<div>
									<h3>
										Please fill in the input type specified and any additional details in the text
										area below.
									</h3>
									<Input
										style={{margin: '30px'}}
										key={`${props.task.id}`}
										label={`Input Type: ${props.task.maskName}`}
										placeholder={
											props.task.maskExample ? props.task.maskExample : props.task.maskName
										}
										fluid
										onChange={(e, {value}) => {
											props.updateFreeFormUserInput(
												props.aasdiIndex,
												props.groupIndex,
												props.tasks.ui.panel.index,
												value,
												props.task
											);
										}}
										value={props.task.freeFormUserInput ? props.task.freeFormUserInput : null}
									/>
									<label>{'Extra Information: '}</label>
									<TextArea
										style={{width: '100%'}}
										key={`${props.task.id}-${props.tasks.ui.panel.index}`}
										fluid
										onChange={(e, {value}) => {
											props.updateTextAreaInput(
												props.aasdiIndex,
												props.groupIndex,
												props.tasks.ui.panel.index,
												value,
												props.task
											);
										}}
										value={props.task.textBoxInput ? props.task.textBoxInput : null}
									/>
								</div>
							);
						default:
							return <h3>Are you sure you would like to complete this task?</h3>;
					}
				}
			})()}
		</Modal.Content>
		<Modal.Actions>
			<Button color="red" onClick={() => props.toggleCompleteTaskModal()} inverted>
				<Icon name="remove" /> No
			</Button>
			<Button
				color="green"
				onClick={() => {
					props.completeTask(props.aasdiIndex, props.groupIndex, props.tasks.ui.panel.index, props.task);
				}}
				inverted
				loading={props.tasks.ui.loading}
			>
				<Icon name="checkmark" /> Yes
			</Button>
		</Modal.Actions>
	</Modal>
);

export default completeTaskModal;

let dropdownTasks = (dropdownTasks) => {
	if (dropdownTasks === undefined || dropdownTasks === null) {
		return;
	}
	return dropdownTasks.map((tasks) => {
		if (tasks.choiceId) {
			return {
				value: tasks.choice,
				text: tasks.choice
			};
		} else {
			return {
				value: 'OPTION NOT SAVED',
				text: 'OPTION NOT SAVED',
				disabled: true
			};
		}
	});
};
