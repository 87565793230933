import {Component} from 'react';
import _ from 'lodash';

// Style wrapper for pdfs to replace @react-pdf/styled-components
/**
 * @param {Component} Comp - component from react-pdf/render
 * @param {Object} styles - object containg styles for component
 * @returns {Component} component with styles
 */

export default function Styled(Comp, styles) {
	return (props) => {
		const computedStyles = _.mapValues(styles, (style) => {
			if (_.isFunction(style)) return style(props);
			return style;
		});
		const newProps = {
			...props,
			style: {
				...computedStyles,
				...(props.style || {})
			}
		};
		return <Comp {...newProps} />;
	};
}
