import React, {Component} from 'react';
import {Label, Grid, Icon, Button, TextArea, Dropdown, Input} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {
	toggleCreateContactModal,
	toggleFetchingOnTabsNavbar
} from '../../../../../../components/Global/services/globalActions.js';
import {
	getTabRequestItems,
	addWorkViewDocument,
	updateDocumentValue,
	updateDocumentError
} from '../services/profileAasdiGridActions.js';
import {validateGoogleDriveLink} from '../../../../../../lib/old/validation.js';
class DocumentsFieldsRenderer extends Component {
	constructor(props) {
		super(props);
	}

	isFormCompleted(sectionFields) {
		let answer = true;
		sectionFields.forEach((input) => {
			if (input.required && !input.value) {
				answer = false;
			}
		});
		return answer;
	}

	submitDocument() {
		if (validateGoogleDriveLink(this.props.sectionFields[1].value)) {
			this.props.addWorkViewDocument(
				this.props.sectionFields[0],
				this.props.sectionFields[1],
				this.props.sectionFields[2],
				this.props.sectionFields[3],
				this.props.id, // aasdi or client id
				this.props.type
			);
		} else {
			this.props.updateDocumentError(1, true);
		}
	}

	render() {
		return (
			<Grid>
				<Grid.Row>
					{this.props.sectionFields.map((field, fieldIndex) =>
						field.input ? (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<div>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											{field.name}
										</Label>
									</div>
									<DebounceInput
										element={Input}
										required
										onChange={(e) => {
											this.props.updateDocumentValue(fieldIndex, e.target.value);
											this.props.updateDocumentError(fieldIndex, false);
										}}
										debounceTimeout={600}
										value={field.value}
										style={{
											width: '100%'
										}}
										error={field.required || field.checkForm ? field.error : false}
										disabled={field.disabled}
										placeholder={field.placeholder}
									/>
									{field.error ? (
										<Label color="red" pointing="above">
											{`Invalid ${field.name}`}
										</Label>
									) : null}
								</div>
							</Grid.Column>
						) : field.dropdown ? (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<div
									style={{
										paddingTop: '.8em'
									}}
								>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{field.name}
									</Label>
									<Dropdown
										className="createWorkOrderClientDropdown"
										placeholder={field.placeholder}
										fluid
										search
										selection
										options={field.options}
										onChange={(e, {value}) => {
											this.props.updateDocumentValue(fieldIndex, value);
										}}
										icon={field.icon}
										value={field.text}
										error={field.required ? field.error : false}
									/>
								</div>
							</Grid.Column>
						) : field.textArea ? (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<div
									style={{
										paddingTop: '0.8em',
										zIndex: '10'
									}}
								>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{field.name}
									</Label>
									<DebounceInput
										className="scrollingTextArea"
										element={TextArea}
										onChange={(e) => {
											this.props.updateDocumentValue(fieldIndex, e.target.value);
										}}
										debounceTimeout={600}
										value={field.value}
										style={{
											width: '100%'
										}}
										error={field.required || field.checkForm ? field.error : false}
										disabled={field.disabled}
										placeholder={field.placeholder}
									/>
								</div>
							</Grid.Column>
						) : null
					)}
					<Grid.Column
						width={15}
						style={{
							paddingTop: '0.8em'
						}}
					>
						<Button
							style={{
								backgroundColor: '#0f4b90',
								color: 'white'
							}}
							disabled={!this.isFormCompleted(this.props.sectionFields) || this.props.loading}
							onClick={() => {
								this.submitDocument();
							}}
						>
							<Icon name="plus" />
							Add Document
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

DocumentsFieldsRenderer.propTypes = {
	sectionFields: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired,
	addWorkViewDocument: PropTypes.func,
	updateDocumentValue: PropTypes.func,
	type: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	updateDocumentError: PropTypes.func
};

const mapStateToProps = (state) => ({
	profileAasdiGridData: state.work.aasdiProfileGrid.rowData,
	profileAasdiGridHeader: state.work.aasdiProfileGrid.headerData,
	sectionFields: state.work.aasdiProfileGrid.documentsFields.fields,
	loading: state.work.aasdiProfileGrid.documentsFields.loading
});

const mapDispatchToProps = (dispatch) => ({
	getTabRequestItems: (aasdiId, item) => dispatch(getTabRequestItems(aasdiId, item)),
	toggleCreateContactModal: (status) => dispatch(toggleCreateContactModal(status)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	addWorkViewDocument: (fileName, googleDriveLink, customerApproval, comments, id, type) =>
		dispatch(addWorkViewDocument(fileName, googleDriveLink, customerApproval, comments, id, type)),
	updateDocumentValue: (index, value) => dispatch(updateDocumentValue(index, value)),
	updateDocumentError: (index, error) => dispatch(updateDocumentError(index, error))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsFieldsRenderer);
