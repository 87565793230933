import React from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {Grid, Segment, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import * as actions from '../../services/workRecordActions.js';
import ClientWorkTabCellRenderer from '../../../../../client/scenes/profile/components/ClientWorkTabCellRenderer.js';

const frameworkComponents = {
	clientWorkTabCellRenderer: ClientWorkTabCellRenderer
};
class Order extends React.Component {
	UNSAFE_componentWillMount() {
		this.props.getWorkViewOrders(this.props.quotedJobNumber);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	render() {
		const defaultColDef = {
			filter: true,
			resizable: true
		};
		return (
			<Grid>
				<Grid.Row>
					<Grid.Column>
						{!this.props.quotedJobNumber ? (
							<Label color="red">
								group billable projects must have a quoted job number that is the same as their work
								number
							</Label>
						) : null}
						<Segment
							style={{
								padding: '1rem',
								height: '80vh'
							}}
							loading={this.props.orders.loading}
						>
							<Label attached="top" color="blue">
								Orders
							</Label>
							<div
								className="ag-theme-balham"
								style={{
									height: '70vh',
									width: '100%',
									textAlign: 'left',
									boxSizing: 'border-box'
								}}
							>
								<AgGridReact
									onGridReady={this.onGridReady}
									columnDefs={this.props.orders.colDefs}
									defaultColDef={defaultColDef}
									modules={AllModules}
									rowData={this.props.orders.orders}
									enableStatusBar={true}
									alwaysShowStatusBar={false}
									enableRangeSelection={true}
									frameworkComponents={frameworkComponents}
								/>
							</div>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

Order.propTypes = {
	orders: PropTypes.object,
	getWorkViewOrders: PropTypes.func,
	workJobNumber: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		quotedJobNumber: state.work.workRecord.view.record.quotedJobNumber,
		orders: state.work.workRecord.view.orders
	};
};

export default connect(mapStateToProps, actions)(Order);
