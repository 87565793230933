import customFetch from '../../../../../lib/old/customFetch.js';

export const updateStartDate = (value) => (dispatch) => {
	dispatch({
		type: 'MONTHLY_COMMIT_UPDATE_START_DATE',
		payload: {
			value
		}
	});
};

export const updateEndDate = (value) => (dispatch) => {
	dispatch({
		type: 'MONTHLY_COMMIT_UPDATE_END_DATE',
		payload: {
			value
		}
	});
};

export const updateSelectedBranch = (value) => (dispatch) => {
	dispatch({
		type: 'MONTHLY_COMMIT_UPDATE_SELECTED_BRANCH',
		payload: {
			value
		}
	});
};

export const updateSelectedAccountExecutive = (value) => (dispatch) => {
	dispatch({
		type: 'MONTHLY_COMMIT_UPDATE_SELECTED_ACCOUNT_EXECUTIVE',
		payload: {
			value
		}
	});
};

export const updateSelectedServiceType = (value) => (dispatch) => {
	dispatch({
		type: 'MONTHLY_COMMIT_UPDATE_SELECTED_SERVICE_TYPE',
		payload: {
			value
		}
	});
};

export const updateSelectedTechnology = (value) => (dispatch) => {
	dispatch({
		type: 'MONTHLY_COMMIT_UPDATE_SELECTED_TECHNOLOGY',
		payload: {
			value
		}
	});
};

export const getEmployees = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'MONTHLY_COMMIT_GET_EMPLOYEE_DROPDOWN_LOADING',
			payload: true
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let employees = json.map((employee) => ({
					key: employee.id,
					text: employee.fullName,
					value: employee.id
				}));
				dispatch({
					type: 'MONTHLY_COMMIT_GET_EMPLOYEE_DROPDOWN_OPTIONS',
					payload: {
						employees
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'MONTHLY_COMMIT_GET_EMPLOYEE_DROPDOWN_LOADING',
					payload: false
				});
			});
	};
};
