import React from 'react';
import {Label, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {updateScopeOfWork} from '../../services/createWorkOrderActions.js';
import {FancyEditor} from '../../../../../../components/inputs/FancyEditor.jsx';

class Scope extends React.Component {
	render() {
		return (
			<Segment style={{paddingTop: '2em', paddingBottom: '2em'}}>
				<Label attached="top" color="blue">
					Scope of Work
				</Label>
				{/* dont know why but this is clipping into the label so i need margin */}
				<div style={{height: 300, marginTop: 8}}>
					<FancyEditor
						value={this.props.scopeOfWorkValue}
						onChange={(value) => this.props.updateScopeOfWork(this.props.sectionIndex, value)}
					/>
				</div>
			</Segment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'scopeOfWorkSection'
		),
		scopeOfWorkValue:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'scopeOfWorkSection'
				)
			].scopeOfWorkValue
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateScopeOfWork: (sectionIndex, value) => {
			dispatch(updateScopeOfWork(sectionIndex, value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Scope);
