import {Button, Icon} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const selectAllButton = (props) => (
	<Button
		{...props}
		type="button"
		icon
		size="medium"
		toggle
		onClick={() => props.toggleAll(props.approvals === true ? props.approvals : null)}
	>
		<Icon color="blue" name="selected radio" />
	</Button>
);

selectAllButton.propTypes = {
	approvals: PropTypes.bool,
	toggleAll: PropTypes.func
};

export default selectAllButton;
