import {Label, Popup, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const SignatureLabels = (props) => [
	<Popup
		key={'openSignature'}
		size={'tiny'}
		trigger={<Label color={`blue`} icon={`write`} content={` Signature`} className="pseudoDragHandler" />}
		content="A signature task."
		on="hover"
	/>,
	props.task.signatureDriveLinks
		? props.task.signatureDriveLinks.map((link) => (
				<Popup
					size={'tiny'}
					key={'openSignatureLink'}
					trigger={
						<Label className="pseudoDragHandler">
							<Icon className="pseudoDragHandler" name="linkify" />
							<a className="pseudoDragHandler" href={link} target="_blank">
								{' '}
								Open Signature
							</a>
						</Label>
					}
					content="Open the signature in a new window."
					on="hover"
				/>
		  ))
		: null
];
export default SignatureLabels;

SignatureLabels.propTypes = {
	task: PropTypes.object.isRequired
};
