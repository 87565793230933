import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const MomentDatePicker = (props) => {
	return (
		<DatePicker
			dateFormat="MM-dd-yyyy"
			{...props}
			//anything below here overwrites ...props
			selected={convertDate(props.selected)}
			startDate={convertDate(props.startDate)}
			endDate={convertDate(props.endDate)}
			minDate={convertDate(props.minDate)}
			maxDate={convertDate(props.maxDate)}
			onChange={props.disabled ? null : (date) => invoke(props.onChange, moment(date))}
			onSelect={props.disabled ? null : (date) => invoke(props.onSelect, moment(date))}
		/>
	);
};

const convertDate = (date) => {
	if (date instanceof moment && date.isValid()) {
		return date.toDate();
	}
	return null;
};

const invoke = (fn, ...args) => {
	if (fn) {
		fn(...args);
	}
};
