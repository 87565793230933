import React from 'react';
import {Dropdown, Header} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';
import {debounceEventHandler, debounce} from '../../../../../../../../../lib/old/debounce.js';

import {
	updateCreateWorkOrderModal,
	getWorkOrderLocations,
	getWorkOrderBillingContacts
} from '../../../../../services/createWorkOrderActions.js';

const createNewButton = (props) => (
	<Dropdown
		selection
		search
		onSearchChange={debounceEventHandler(
			props.field.searchLocations
				? debounce((e) => {
						props.getWorkOrderLocations(
							props.sectionIndex,
							props.fieldIndex,
							e.target.value,
							props.clientId
						);
				  }, 600)
				: props.field.searchBillingContacts
				? debounce((e) => {
						props.getWorkOrderBillingContacts(
							props.sectionIndex,
							props.fieldIndex,
							e.target.value,
							props.clientId
						);
				  }, 600)
				: null
		)}
		onChange={
			props.field.searchLocations
				? (e, {value}) => {
						props.updateCreateWorkOrderModal(
							'createWorkOrderModal',
							props.sectionIndex,
							props.fieldIndex,
							value,
							false,
							'Location',
							props.field.dataList.find((data) => data.id === value)
						);
				  }
				: props.field.searchBillingContacts
				? (e, {value}) => {
						props.updateCreateWorkOrderModal(
							'createWorkOrderModal',
							props.sectionIndex,
							props.fieldIndex,
							value,
							false,
							'BillingContact',
							props.field.dataList.find((data) => data.id === value)
						);
				  }
				: (e, {value}) => {
						props.updateCreateWorkOrderModal(
							'createWorkOrderModal',
							props.sectionIndex,
							props.fieldIndex,
							value,
							false
						);
				  }
		}
		value={props.field.value}
		style={{
			width: '85%'
		}}
		loading={props.field.loading}
		error={props.field.required ? Boolean(props.field.error) : false}
		disabled={!props.clientId}
		placeholder={props.clientId ? null : 'Please select a client'}
		dataList={props.field.dataList}
		options={props.field.dataList.map((option, index) =>
			props.field.searchBillingContacts
				? {key: option.id, value: option.id, text: option.fullName}
				: {
						key: option.id,
						value: option.id,
						text: `${option.name}, ${option.storeNumber}, ${option.city}, ${option.state}`,
						content: (
							<Header
								size="tiny"
								content={option.name}
								subheader={`Store number: ${option.storeNumber}, City: ${option.city}, State: ${option.state}`}
							/>
						)
				  }
		)}
	/>
);

const mapStateToProps = (state) => {
	return {
		clientId: state.work.createWorkOrder.createWorkOrderModal.sections[1].fields[0].value
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateWorkOrderModal: (modal, sectionIndex, fieldIndex, value, error, inputType, data) => {
			dispatch(updateCreateWorkOrderModal(modal, sectionIndex, fieldIndex, value, error, inputType, data));
		},
		getWorkOrderLocations: (sectionIndex, fieldIndex, value, clientId) => {
			dispatch(getWorkOrderLocations(sectionIndex, fieldIndex, value, clientId));
		},
		getWorkOrderBillingContacts: (sectionIndex, fieldIndex, value, clientId) => {
			dispatch(getWorkOrderBillingContacts(sectionIndex, fieldIndex, value, clientId));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(createNewButton);
