import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AcceptButton from './components/buttons/acceptButton.js';
import RejectButton from './components/buttons/rejectButton.js';
import DetailsButton from './components/buttons/detailsButton.js';
import RejectModal from '../modals/rejectModal.js';
import AcceptModal from '../modals/acceptModal.js';
import PDFButton from './components/buttons/pdfButton.js';
import ArchiveButton from './components/buttons/archiveButton.js';

const ActionButtons = (props) => {
	return (
		<div style={{marginTop: '.15rem'}}>
			{props.category !== 'Accepted' && props.category !== 'Rejections' && props.category !== 'Users' && (
				<div>
					<AcceptButton record={props.data} />
					<RejectButton record={props.data} />
					<DetailsButton record={props.data} category={props.category} />
				</div>
			)}
			{props.category === 'Rejections' && (
				<div>
					<DetailsButton record={props.data} category={props.category} />
					{props.data.isArchived ? null : <ArchiveButton record={props.data} category={props.category} />}
				</div>
			)}
			{props.category === 'Accepted' && <PDFButton record={props.data} category={props.category} />}
			{props.category !== 'Users' && (
				<div>
					<RejectModal />
					<AcceptModal />
				</div>
			)}
		</div>
	);
};

ActionButtons.propTypes = {
	category: PropTypes.string,
	rowIndex: PropTypes.number,
	data: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		category: state.accountingConsoleReducer.accountingConsole.selectedCategory.name
	};
};

export default connect(mapStateToProps, null)(ActionButtons);
