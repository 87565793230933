// Modules
import React from 'react';
import {Button, Modal, Icon, Header, Grid, Segment, Label, Input, Dimmer, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';
import * as actions from '../../../../../services/accountingConsoleActions.js';

const aasdiCodeRegex = /^[a-zA-Z0-9]+$/;

class AcceptModal extends React.Component {
	constructor(props) {
		super(props);
	}

	handleModalAccept = (spectrumIdNeeded) => {
		let data;

		if (this.props.buttonId === 'single') {
			data = this.props.selectedRecord;
		} else if (this.props.buttonId === 'bulk') {
			data = this.props.selectedItemsForApproval;
		}
		if (spectrumIdNeeded === true) {
			if (!this.props.acceptModal.symbolError && this.props.acceptModal.id.length > 0) {
				this.props.acceptReject(this.props.selectedCategory, data, true, null, this.props.acceptModal.id);
			} else {
				alert('ID must be less than 10 characters and contain no symbols.');
			}
		} else {
			this.props.acceptReject(this.props.selectedCategory, data, true, null, this.props.acceptModal.id);
		}
	};

	render() {
		const aasdiName = this.props.selectedRecord.name;
		const spectrumIdNeeded = ['Client', 'AASDI'].indexOf(this.props.selectedCategory) !== -1;
		return (
			<Modal open={this.props.acceptModalToggle}>
				<Header>
					<Grid>
						<Grid.Row>
							<Icon name="plus circle" color="green" style={{margin: '0 .5vw'}} />
							{spectrumIdNeeded === true ? (
								<div>
									Add Spectrum ID{' '}
									<Label style={{marginLeft: '6vw'}}>
										{`ID ${
											this.props.selectedCategory === 'AASDI' ? `for AASDI ${aasdiName} ` : ''
										}must be less than 10 characters and contain no symbols`}
									</Label>
								</div>
							) : (
								<div>Confirm Approval</div>
							)}
						</Grid.Row>
					</Grid>
				</Header>
				{this.props.modalLoading === true ? (
					<Dimmer inverted active>
						<Loader size="big" />
					</Dimmer>
				) : null}
				<Modal.Content scrolling>
					<Segment basic textAlign="center">
						{spectrumIdNeeded === true ? (
							<Grid textAlign="center">
								{this.props.acceptModal.existsError ? (
									<Grid.Row style={{padding: '0'}}>
										<Label color="red" pointing="below">
											This ID already exists, please choose another.
										</Label>
									</Grid.Row>
								) : null}
								<Grid.Row style={{padding: '0'}}>
									<DebounceInput
										element={Input}
										className="big"
										onChange={(e) => {
											const value = e.target.value;
											if (value.length <= 10) {
												this.props.updateSpectrumId(value);
											}

											if (aasdiCodeRegex.test(value)) {
												this.props.toggleSymbolError(false);
											} else {
												this.props.toggleSymbolError(true);
											}
										}}
										debounceTimeout={600}
									/>
								</Grid.Row>
								{this.props.acceptModal.symbolError ? (
									<Grid.Row style={{padding: '0'}}>
										<Label color="red" pointing="above">
											ID must not contain symbols
										</Label>
									</Grid.Row>
								) : null}
							</Grid>
						) : (
							<div>
								{this.props.selectedItemsForApproval.length > 0 ? (
									<div>
										<div>
											Are you sure you want to approve{' '}
											{this.props.selectedItemsForApproval.length}{' '}
											{this.props.selectedCategory.toLowerCase()}s ?
										</div>
										{this.props.invalidSelectedItemsForApproval &&
										this.props.invalidSelectedItemsForApproval.length > 0 ? (
											<div>
												<div>
													{this.props.invalidSelectedItemsForApproval.length} invoice
													{this.props.invalidSelectedItemsForApproval.length > 1
														? 's are '
														: ' is '}
													desynced and will not be invoiced.
												</div>
												<div>
													Invalid work numbers (
													{this.props.invalidSelectedItemsForApproval.map((x, index) => {
														return `${x.workNumber}${
															this.props.invalidSelectedItemsForApproval.length === 1 ||
															index ===
																this.props.invalidSelectedItemsForApproval.length - 1
																? ''
																: ', '
														}`;
													})}
													)
												</div>
											</div>
										) : null}
									</div>
								) : (
									<div>
										Are you sure you want to approve this{' '}
										{this.props.selectedCategory.toLowerCase()}?
									</div>
								)}
							</div>
						)}
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group floated="left">
						<Button
							basic
							color="red"
							onClick={() => {
								this.props.toggleAcceptModal(false);
							}}
						>
							<Icon name="remove" /> Cancel
						</Button>
					</Button.Group>
					<Button.Group>
						<Button color="green" onClick={this.handleModalAccept}>
							<Icon name="plus circle" />
							{spectrumIdNeeded === true ? 'Add ID and Accept' : 'Accept'}
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

AcceptModal.propTypes = {
	acceptModal: PropTypes.object,
	acceptModalToggle: PropTypes.bool,
	modalLoading: PropTypes.bool,
	selectedRecord: PropTypes.object,
	selectedCategory: PropTypes.string,
	acceptReject: PropTypes.func,
	toggleExistsError: PropTypes.func,
	toggleSymbolError: PropTypes.func,
	toggleAcceptModal: PropTypes.func,
	updateSpectrumId: PropTypes.func,
	toggleLengthError: PropTypes.func,
	aasdiName: PropTypes.string,
	isAASDI: PropTypes.bool,
	buttonId: PropTypes.string,
	invalidSelectedItemsForApproval: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		buttonId: state.accountingConsoleReducer.accountingConsole.buttonId,
		selectedItemsForApproval: state.accountingConsoleReducer.accountingConsole.selectedItemsForApproval,
		invalidSelectedItemsForApproval:
			state.accountingConsoleReducer.accountingConsole.invalidSelectedItemsForApproval,
		acceptModalToggle: state.accountingConsoleReducer.accountingConsole.acceptModalToggle,
		acceptModal: state.accountingConsoleReducer.accountingConsole.acceptModal,
		selectedRecord: state.accountingConsoleReducer.accountingConsole.selectedRecord,
		selectedCategory: state.accountingConsoleReducer.accountingConsole.selectedCategory.name,
		modalLoading: state.accountingConsoleReducer.accountingConsole.modalLoading
	};
};

export default connect(mapStateToProps, actions)(AcceptModal);
