import {Button, Icon, Modal, Header, Form, Message} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const editUser = (props) => (
	<div>
		<Button
			{...props}
			onClick={() => props.toggleEditUserModal(props.techManagement.editUser.toggleEditUserModal)}
			icon
			size="medium"
			type="Button"
		>
			<Icon color="blue" name="edit" />
		</Button>
		<Modal
			closeIcon
			size="tiny"
			open={props.techManagement.editUser.toggleEditUserModal}
			onClose={() => props.toggleEditUserModal(props.techManagement.editUser.toggleEditUserModal)}
		>
			<Header icon="edit" content="Edit User" />
			<Modal.Content>
				<Form className="attached fluid segment userAddForm" loading={props.techManagement.editUser.loading}>
					<Form.Group widths="equal">
						<Form.Input
							label="Email"
							disabled
							type="text"
							value={props.techManagement.editUser.user.Email}
							onChange={(event) => {
								let value = event.target.value;
								let label = 'email';
								props.updateEditUserInfo({value, label});
							}}
						/>
						<Form.Input
							label="AASDI Code"
							type="text"
							value={props.techManagement.editUser.user.AASDI}
							onChange={(event) => {
								let value = event.target.value;
								let label = 'aasdi';
								props.updateEditUserInfo({value, label});
							}}
						/>
					</Form.Group>
				</Form>
				<Message attached="bottom" error hidden={props.techManagement.editUser.message.hideEditUserMessage}>
					<Icon name="help" />
					<span>{props.techManagement.editUser.message.text}</span>
				</Message>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="red"
					onClick={() => props.toggleEditUserModal(props.techManagement.editUser.toggleEditUserModal)}
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button color="green" onClick={() => props.editUser()}>
					<Icon name="checkmark" /> Save
				</Button>
			</Modal.Actions>
		</Modal>
	</div>
);

editUser.propTypes = {
	techManagement: PropTypes.shape({
		editUser: PropTypes.shape({
			toggleEditUserModal: PropTypes.bool,
			loading: PropTypes.bool,
			user: PropTypes.shape({
				Email: PropTypes.string,
				AASDI: PropTypes.string
			}),
			message: PropTypes.shape({
				hideEditUserMessage: PropTypes.bool,
				text: PropTypes.string
			})
		})
	}),
	toggleEditUserModal: PropTypes.func,
	updateEditUserInfo: PropTypes.func,
	editUser: PropTypes.func
};

export default editUser;
