import React from 'react';
import {Button, Modal, Label, Icon, Header, Dropdown, Form, TextArea, Loader, Dimmer} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';

import {toggleLostJobModal, submitLostJob, updateModal, closeDimmer} from './services/lostJobOpportunityModalActions.js';

const LostJobOpportunityModal = (props) => (
	<Modal open={props.lostJobOpportunityModal.lostJobOpportunityModal.toggleLostJobModal}>
		<Header>
			<Icon name="warning" color="orange" />
			<div className="content">Lost Job - Pipeline Record</div>
		</Header>
		<Modal.Content>
			{props.lostJobOpportunityModal.lostJobOpportunityModal.loading ? (
				<Dimmer inverted active className="centerDimmer">
					{props.lostJobOpportunityModal.lostJobOpportunityModal.loading === 'start' ? (
						<Loader size="large">Setting to Lost Job</Loader>
					) : (
						// Error handling
						<Header as="h4" icon>
							<Icon name="cancel" color="red" />
							<div style={{paddingBottom: '.5em', color: 'black'}}>Setting to Lost Job Failed</div>
							<div style={{color: 'black'}}>Please try again.</div>
							<Button
								onClick={() => {
									props.closeDimmer(null);
								}}
								style={{marginTop: '1.5em'}}
							>
								Close Message
							</Button>
						</Header>
					)}
				</Dimmer>
			) : null}
			<div>
				<Label
					style={{
						backgroundColor: 'transparent',
						fontSize: '1rem',
						color: 'rgba(0,0,0,.8)',
						paddingLeft: '0'
					}}
				>
					{'Why was this job lost?'}
				</Label>
			</div>
			<div style={{paddingTop: '0.3em'}}>
				<Dropdown
					placeholder="Choose reason"
					search
					selection
					options={props.lostJobOpportunityModal.lostJobOpportunityModal.options}
					onChange={(e, {value}) => {
						props.updateModal('reason', value, false);
					}}
					value={props.lostJobOpportunityModal.lostJobOpportunityModal.reason.value}
					error={props.lostJobOpportunityModal.lostJobOpportunityModal.reason.error}
				/>
			</div>

			{props.lostJobOpportunityModal.error ? (
				<div
					style={{
						color: '#ca1010',
						paddingTop: '0.5em',
						fontSize: '0.9em'
					}}
				>
					{'This field is required before submitting.'}
				</div>
			) : null}

			<div style={{paddingTop: '1em'}}>
				<Label
					style={{
						backgroundColor: 'transparent',
						fontSize: '1rem',
						color: 'rgba(0,0,0,.8)',
						paddingLeft: '0'
					}}
				>
					{'Comments'}
				</Label>
			</div>
			<Form>
				<DebounceInput
					element={TextArea}
					style={{minHeight: 100}}
					onChange={(e) => {
						props.updateModal('comments', e.target.value, false);
					}}
					debounceTimeout={600}
					value={props.lostJobOpportunityModal.lostJobOpportunityModal.comments.value}
				/>
			</Form>
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleLostJobModal(null, null, null, '');
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					onClick={() => {
						props.submitLostJob(
							props.lostJobOpportunityModal.lostJobOpportunityModal.id,
							props.lostJobOpportunityModal.lostJobOpportunityModal.place,
							props.lostJobOpportunityModal.lostJobOpportunityModal.reason.value,
							props.lostJobOpportunityModal.lostJobOpportunityModal.comments.value
						);
					}}
					color="green"
				>
					<Icon name="checkmark" /> Save
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

const mapStateToProps = (state) => {
	return {
		lostJobOpportunityModal: state.global.lostJobOpportunityModal
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLostJobModal: (id, place) => {
			dispatch(toggleLostJobModal(id, place));
		},
		updateModal: (column, value, error) => {
			dispatch(updateModal(column, value, error));
		},
		submitLostJob: (id, place, reason, comments) => {
			dispatch(submitLostJob(id, place, reason, comments));
		},
		closeDimmer: (value) => {
			dispatch(closeDimmer(value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LostJobOpportunityModal);
