import {Button, Icon} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const refresh = (props) => (
	<Button
		{...props}
		type="button"
		icon
		size="medium"
		loading={props.techManagement.refreshLoading.loading}
		onClick={() => (!props.approvals ? props.getUsers(null, null, true) : props.getApprovals(null, null, true))}
	>
		<Icon color="blue" name={props.techManagement.refreshLoading.icon} />
	</Button>
);

refresh.propTypes = {
	techManagement: PropTypes.shape({
		refreshLoading: PropTypes.shape({
			loading: PropTypes.bool,
			icon: PropTypes.string
		})
	}),
	approvals: PropTypes.bool,
	getUsers: PropTypes.func,
	getApprovals: PropTypes.func
};

export default refresh;
