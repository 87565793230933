import update from 'react-addons-update';

/**
 * The Pipeline state object
 * @namespace
 * @memberof App.Store.Reducers
 * @since 3.0.0
 */
export default (
	state = {
		fetched: false,
		loading: true,
		estimatorOptions: null,
		loadingEstimatorOptions: false
	},
	action
) => {
	switch (action.type) {
		case 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES':
			state = update(state, {
				[action.payload.column]: {
					$set: action.payload.value
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES_ITEM':
			state = update(state, {
				items: {
					[state.items.findIndex((item) => item.id === action.id)]: {
						[action.payload.column]: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY_ESTIMATOR_COLUMN_OPTIONS':
			state = update(state, {
				estimatorOptions: {
					$set: action.payload.value
				}
			});
			break;

		case 'CRM_LOAD_OPPORTUNITY_TECHNOLOGIES_ESTIMATOR':
			state = update(state, {
				items: {
					[state.items.findIndex((item) => item.id === action.payload.id)]: {
						loadingEstimator: {
							$set: action.payload.value
						}
					}
				}
			});
			break;
	}

	return state;
};
