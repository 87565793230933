import {Label, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';

const DropDownLabels = (props) => [
	<Popup
		key={'dropdownTaskType'}
		size={'tiny'}
		trigger={<Label color={`blue`} icon={`dropdown`} content={` Dropdown`} />}
		content="A dropdown task."
		on="hover"
	/>,
	props.task.userChoice ? (
		props.task.userChoice.choice ? (
			<Popup
				size={'tiny'}
				key={'choice'}
				trigger={<Label content={` ${props.task.userChoice.choice}`} />}
				content="The techs choice."
				on="hover"
			/>
		) : null
	) : null
];
export default DropDownLabels;

DropDownLabels.propTypes = {
	task: PropTypes.object.isRequired
};
