import React from 'react';
import {connect} from 'react-redux';
import {Icon, Label, Input, Dropdown, Button} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';

import {getAASDIByZip, setZipErrorToggle} from '../services/WorkAasdiGridActions.js';

function SearchZipCode(props) {
	return (
		<div className="aasdi-zip-code-search">
			<span className="input-with-label">
				<Label className="label">Zip</Label>
				<DebounceInput
					element={Input}
					debounceTimeout={600}
					value={props.selectedZip}
					onChange={(e) => {
						props.setInput(e.target.value.trim());
					}}
					type="text"
					onKeyPress={(e) => {
						if (e.charCode === 13) {
							if (e.target.value.length >= 3) {
								props.getAASDIByZip(
									props.selectedNameSearch,
									props.selectedZip.trim(),
									props.selectedRadius,
									props.gridApi
								);
							} else {
								props.setZipErrorToggle(true);
							}
						}
					}}
				/>
				{props.modal.zipError ? (
					<Label color="red" pointing="above">
						Invalid zip code
					</Label>
				) : null}
			</span>

			<span className="input-with-label">
				<Label className="label">Radius</Label>

				<Dropdown
					search
					selection
					scrolling
					compact
					options={props.modal.radiusOptions}
					text={props.selectedRadius}
					onChange={(e, {value}) => {
						props.setDropdown(value);
					}}
				/>
			</span>

			<Button
				basic
				color="blue"
				className="search-button"
				onClick={() => {
					if (props.selectedZip.length >= 3) {
						props.getAASDIByZip(
							props.selectedNameSearch,
							props.selectedZip,
							props.selectedRadius,
							props.gridApi
						);
					} else {
						props.setZipErrorToggle(true);
					}
				}}
			>
				<Icon name="search" className="search-button-icon" />
				Search
			</Button>
		</div>
	);
}

const mapStateToProps = (state) => ({
	modal: state.work.aasdiGrid.searchByZip
});

const mapDispatchToProps = (dispatch) => ({
	getAASDIByZip: (selectedNameSearch, selectedZip, selectedRadius, gridApi) =>
		dispatch(getAASDIByZip(selectedNameSearch, selectedZip, selectedRadius, gridApi)),
	setZipErrorToggle: (status) => dispatch(setZipErrorToggle(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchZipCode);
