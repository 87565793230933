import {Tab, Grid, Menu, Sticky, Label, Popup, Visibility} from 'semantic-ui-react';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TaskList from './TaskList.js';
import RightPanel from './Panel_Right.js';
import CreateTaskButton from './Button_CreateTask.js';
import CreateSectionButton from './Button_CreateSection.js';
import AddTemplateButton from './Button_AddTemplate.js';
import ToggleSequentialButton from './Button_ToggleSequential.js';
import To_Top from '../../../../../../../../../components/Global/Button_ToTop.js';
import optionFactory from './optionFactory.js';
import DeleteModal from './Modal_Delete.js';
import AddTemplateTasksModal from '../../../../../../../../../scenes/TaskTemplates/components/AddTemplateTasksModal.jsx';

import {connect} from 'react-redux';

class groups extends Component {
	// constructor(props) {
	// 	super(props);
	// 	this.myRef = React.createRef();
	// }
	render() {
		let groupTabPanes = this.props.groups.map((group, groupIndex) => ({
			menuItem: (
				<Menu.Item
					key={`${group.name}-${groupIndex}`}
					onClick={() => {
						if (this.props.tasks.ui.panel.toggle)
							this.props.togglePanel('close', this.props.mode, 'task', optionFactory(this.props));
						this.props.setSelectedTaskGroup(this.props.aasdiIndex, groupIndex);
					}}
					color="blue"
				>
					<Popup
						trigger={
							<div>
								{group.name.length > 8 ? group.name.substring(0, 8) + '...' : group.name}
								<Label style={{marginLeft: '15px'}} color="blue">{`${
									group.tasks.pinned.length || group.tasks.general.length
										? group.tasks.pinned.length + group.tasks.general.length
										: 0
								} ${group.tasks === 1 ? 'task' : 'tasks'}`}</Label>
							</div>
						}
						content={group.name}
						size={'tiny'}
					/>
				</Menu.Item>
			),
			render: () => (
				// <Transition.Group unmountOnHide animation="scale" duration={500}>
				<Tab.Pane
					attached={true}
					className="removeTopBorder"
					// ref={this.myRef}
					style={{border: 'none'}}
				>
					<Visibility
						onTopVisible={() => this.props.toggleToTop(false, this.props.tasks.taskList)}
						onTopPassed={() => this.props.toggleToTop(true, this.props.tasks.taskList)}
						once={false}
					>
						<Grid>
							<Grid.Column width={this.props.tasks.ui.grid.left} basic="true">
								<div key={`taskList-ref`} ref={this.props.handleContextRef}>
									<TaskList
										{...this.props}
										group={group}
										groupIndex={groupIndex}
										isSection={false}
										key={`taskList-${group.id}`}
									/>
								</div>
							</Grid.Column>
							<Grid.Column
								width={this.props.tasks.ui.grid.right}
								floated={'right'}
								padded="true"
								container="true"
							>
								<Sticky
									offset={150}
									context={document.getElementById('work-dashboard')}
									scrollContext={document.getElementById('work-dashboard')}
									key={`sticky-${group.id}`}
								>
									<RightPanel {...this.props} key={`panel-${group.id}`} />
								</Sticky>
							</Grid.Column>
						</Grid>
					</Visibility>
					<Popup
						size="mini"
						trigger={<To_Top key={`ToTop`} topTopLocation="tasklist" />}
						content="Scroll to top of page."
						on="hover"
					/>
				</Tab.Pane>
				// </Transition.Group>
			)
		}));

		groupTabPanes.push({
			menuItem: (
				<ToggleSequentialButton
					{...this.props}
					position="right"
					key={`sequential-tab`}
					groupIndex={this.props.tasks.selectedTaskGroupIndex}
				/>
			)
		});

		groupTabPanes.push({
			menuItem: (
				<CreateSectionButton
					{...this.props}
					position="right"
					key={`createSection-tab`}
					groupIndex={this.props.tasks.selectedTaskGroupIndex}
				/>
			)
		});

		groupTabPanes.push({
			menuItem: (
				<CreateTaskButton
					{...this.props}
					position="right"
					key={`createTask-tab}`}
					groupIndex={this.props.tasks.selectedTaskGroupIndex}
				/>
			)
		});

		groupTabPanes.push({
			menuItem: (
				<AddTemplateTasksModal
					group={this.props.groups[this.props.tasks.selectedTaskGroupIndex]}
					aasdiIndex={this.props.aasdiIndex}
					groupIndex={this.props.tasks.selectedTaskGroupIndex}
				/>
				// <AddTemplateButton
				// 	{...this.props}
				// 	position="right"
				// 	key={`template-tab`}
				// 	groupIndex={this.props.tasks.selectedTaskGroupIndex}
				// />
			)
		});

		return [
			<Tab
				key={`tab`}
				activeIndex={this.props.tasks.selectedTaskGroupIndex}
				menu={{
					secondary: true,
					pointing: true,
					icon: 'labeled',
					size: 'mini'
				}}
				panes={groupTabPanes}
			/>,

			<DeleteModal key={'deleteModal'} {...this.props} />
		];
	}
}

const mapStateToProps = (state) => {
	return {
		tasks: state.work.tasks
	};
};

export default connect(mapStateToProps, null)(groups);

groups.propTypes = {
	tasks: PropTypes.shape({
		taskList: PropTypes.array,
		selectedTaskGroupIndex: PropTypes.number,
		ui: PropTypes.shape({
			grid: PropTypes.shape({
				left: PropTypes.number,
				right: PropTypes.number
			}),
			orderedLoading: PropTypes.bool,
			loading: PropTypes.bool,
			toggleEdit: PropTypes.bool
		}),
		contextRef: PropTypes.any
	}),
	groups: PropTypes.array,
	handleContextRef: PropTypes.func,
	aasdiIndex: PropTypes.number,
	setSelectedTaskGroup: PropTypes.func,
	toggleAddTemplate: PropTypes.func,
	toggleOrdered: PropTypes.func,
	toggleToTop: PropTypes.func
};
