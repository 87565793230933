import React from 'react';
import {Grid, Header, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';

import InputField from './components/inputField.js';

const Bank = (props) => (
	<Grid>
		<Grid.Row>
			<Grid.Column width={16}>
				<Header as="h3">
					<Icon name="dollar" />
					<Header.Content>Bank</Header.Content>
				</Header>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="bankName" field={props.bankName} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="bankContactName" field={props.bankContactName} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="bankContactPhone" field={props.bankContactPhone} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

const mapStateToProps = (state) => {
	return {
		stepIndex: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Bank')[0].index,
		bankName: state.registrationForm.registrationForm.fields.bankName,
		bankContactName: state.registrationForm.registrationForm.fields.bankContactName,
		bankContactPhone: state.registrationForm.registrationForm.fields.bankContactPhone
	};
};

export default connect(mapStateToProps, null)(Bank);
