import React, { Component } from 'react';
import { Popup, Label, Icon } from 'semantic-ui-react';

export default class TechnologiesCellRenderer extends Component {
	render() {
		return this.props.value ? (
			<Popup
				trigger={
					<div
						style={{
							width: '100%',
							textAlign: 'left',
							paddingLeft: '.8em'
						}}
					>
						<Icon name="plug" style={{ paddingRight: '1.5em' }} />
						{this.props.value.length}
					</div>
				}
				on={['hover', 'click']}
				flowing
				hoverable
				position="left center"
			>
				{(() => {
					let technologies = this.props.value;
					if (technologies) {
						let list = technologies.map((value, index) => {
							return <Label key={`${value}-${index}`}>{value}</Label>;
						});
						return <div>{list}</div>;
					}
				})()}
			</Popup>
		) : (
				<div style={{ width: '100%', textAlign: 'left', paddingLeft: '.8em' }}>
					<Icon name="plug" style={{ paddingRight: '1.5em' }} />
					{0}
				</div>
			);
	}
}
