import {getApiToken} from '~/lib/api';
import store from '../../store.js';

/**
 * @deprecated use ~/lib/api instead
 */
export default async function customFetch(endpoint, inputObj = {}, extraHeaders = {}) {
	const state = store.getState();
	const idToken = await getApiToken();

	if (!idToken) throw new Error('Cannot make api request without authenticating first');

	let headers = new Headers({
		Authorization: 'Bearer ' + idToken,
		'Content-Type': 'application/json',
		...extraHeaders
	});

	if ((inputObj.method === 'PUT' || inputObj.method === 'DELETE') && !inputObj.body) {
		inputObj.body = JSON.stringify({});
	}

	// still need to pass userId, roleType, and token in the sendObj
	return fetch(endpoint, {
		method: inputObj.method ?? 'GET',
		body: inputObj.body ?? null,
		headers: headers
	});
}
