// TODO -B: convert to ts and move to components/ag-grid/renderers
import React from 'react';
import {Checkbox} from 'semantic-ui-react';

const ActiveToggleCellRenderer = (props) => {
	//feels kinda hacky, but i need to get this done right now
	if (props.node.rowPinned) {
		return null;
	}
	return (
		<div style={{paddingTop: '.2em'}}>
			<Checkbox
				toggle
				checked={props.data.toggled}
				// checked={true}
				disabled={props.data.noEdit}
				onClick={(event, {checked}) => {
					if (!checked) {
						props.reset(props.data.id);
					} else {
						props.update(props.data.id, 'toggled', checked);
					}
				}}
			/>
		</div>
	);
};

export default ActiveToggleCellRenderer;
