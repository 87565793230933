import React, {Component} from 'react';
import {Popup, Icon, Segment, Container, Header, Table} from 'semantic-ui-react';

export default class aasdiCellRenderer extends Component {
	render() {
		let aasdis = [];
		if (this.props.value) {
			try {
				aasdis = JSON.parse(this.props.value.replace(/\\|\\*amp;/g, ''));
			} catch (err) {
				console.error('error getting aasdi');
				console.error(this.props.value);
			}
		}
		return aasdis && aasdis.length > 0 ? (
			<Popup
				trigger={
					<div
						style={{
							textAlign: 'left',
							paddingLeft: '.8em'
						}}
					>
						<Icon name="align justify" size="large" color="black" style={{paddingRight: '1.5em'}} />
					</div>
				}
				on={['hover', 'click']}
				flowing
				hoverable
				basic
				position="left center"
			>
				<Segment basic style={{padding: '0'}}>
					<Segment.Group>
						<Segment.Group basic horizontal style={{width: '50vw'}}>
							<Segment basic style={{width: '30%', backgroundColor: '#F5F7F7'}}>
								<Header as="h4">AASDI</Header>
							</Segment>
							<Segment basic style={{width: '70%', backgroundColor: '#F5F7F7'}}>
								<Header as="h4">Trips</Header>
							</Segment>
						</Segment.Group>
						{aasdis && aasdis.length > 0
							? aasdis.map((aasdi, index) => {
									return (
										<Segment.Group horizontal key={index} style={{width: '50vw'}}>
											<Segment basic style={{width: '30%'}}>
												<Container fluid>
													<Header as="h5">{aasdi.name}</Header>
													<p>
														{`
									    phone: ${
											aasdi.phone && aasdi.phone.length === 10
												? `${aasdi.phone.substring(0, 3)}-${aasdi.phone.substring(
														3,
														6
												  )}-${aasdi.phone.substring(6, 10)}`
												: aasdi.phone
										}`}
													</p>
												</Container>
											</Segment>
											<Segment basic style={{width: '70%'}}>
												{aasdi.trips && aasdi.trips.length > 0 ? (
													<Table celled striped size="small">
														<Table.Header>
															<Table.Row>
																<Table.HeaderCell>Name</Table.HeaderCell>
																<Table.HeaderCell>Requested Date</Table.HeaderCell>
																<Table.HeaderCell>Actual Date</Table.HeaderCell>
															</Table.Row>
														</Table.Header>
														<Table.Body>
															{aasdi.trips && aasdi.trips.length > 0
																? aasdi.trips.map((trip, tripIndex) => {
																		return (
																			<Table.Row key={tripIndex}>
																				<Table.Cell>{trip.name}</Table.Cell>
																				<Table.Cell>
																					{trip.requestedDate}
																				</Table.Cell>
																				<Table.Cell>
																					{trip.actualDate}
																				</Table.Cell>
																			</Table.Row>
																		);
																  })
																: null}
														</Table.Body>
													</Table>
												) : null}
											</Segment>
										</Segment.Group>
									);
							  })
							: null}
					</Segment.Group>
				</Segment>
			</Popup>
		) : null;
	}
}
