import {Component} from 'react';

import {AllModules} from '@ag-grid-enterprise/all-modules';
import {AgGridReact} from '@ag-grid-community/react';

import {Form} from 'semantic-ui-react';
import {Redirect} from 'react-router-dom';

import ConfirmationModal from '../modals/ConfirmationModal.jsx';
import EditDesyncedModal from '../modals/EditDesyncedModal.jsx';
import GenericMessage, {MESSAGE_STATE} from '../GenericMessage.jsx';

import customFetch from '../../lib/old/customFetch.js';
import * as desyncedJobsService from './DesyncedJobs.service.js';
import {connect} from 'react-redux';

import './DesyncedJobs.css';

class DesyncedJobs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirect: false,
			desyncedRecords: [],
			isDeleteModalOpen: false,
			deletionRecord: null,

			isEditModalOpen: false,
			editRecord: null,

			messageType: '',
			messageContent: '',
			filter: ''
		};

		this.setDeleteModalOpen = this.setDeleteModalOpen.bind(this);
		this.setMessageType = this.setMessageType.bind(this);
	}

	async componentDidMount() {
		if (this.props.userEmail !== 'omni@asd-usa.com') {
			return this.setState({redirect: true});
		}

		this.setMessageType(MESSAGE_STATE.loading, 'Retrieving records...');

		try {
			const response = await desyncedJobsService.getDesyncedJobs();
			console.log(response);
			this.setMessageType(MESSAGE_STATE.none, '');

			this.setState({desyncedRecords: response});
		} catch (e) {
			console.error(e);
			this.setMessageType(MESSAGE_STATE.error, `${e.name}: ${e.message}`);
		}
	}

	async confirmDeletionOfRecord() {
		this.setMessageType(MESSAGE_STATE.loading, 'Deleting record...');

		try {
			await desyncedJobsService.deleteDesyncedFailureRecord(this.state.deletionRecord);
			this.setMessageType(MESSAGE_STATE.none, '');

			this.setState({
				desyncedRecords: this.state.desyncedRecords.filter((record) => record.id !== this.state.deletionRecord),
				deletionRecord: null
			});
		} catch (e) {
			console.error(e);
			this.setMessageType(MESSAGE_STATE.error, `${e.name}: ${e.message}`);
		}
	}

	async confirmUpdateOfRecord(updatedRecord) {
		this.setMessageType(MESSAGE_STATE.loading, 'Updating record...');

		try {
			await desyncedJobsService.updateDesyncedFailureRecord(updatedRecord);

			const updatedRecords = this.state.desyncedRecords.map((record) => {
				if (record.id === updatedRecord.id) {
					return updatedRecord;
				}
				return record;
			});

			this.setState({
				desyncedRecords: updatedRecords,
				editRecord: null
			});
			this.setMessageType(MESSAGE_STATE.none, '');
		} catch (e) {
			console.error(e);
			this.setMessageType(MESSAGE_STATE.error, `${e.name}: ${e.message}`);
		}
	}

	async invokeSpectrumManualRetry(recordId) {
		this.setMessageType(MESSAGE_STATE.loading, 'Attempting resync...');

		const request = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/work?mode=desync-failed-retry`, {
			method: 'POST',
			body: JSON.stringify({recordId})
		});
		const response = await request.json();
		console.log(response);
		// Invoking lambdas manually changes the request dynamic a little bit - success is based on whether or not the function is invoked, not whether or not the function succeeeds
		if (response.errorMessage) {
			this.setMessageType(MESSAGE_STATE.error, response.errorMessage);
		} else if (response.error) {
			this.setMessageType(MESSAGE_STATE.error, response.error);
		} else {
			this.setState({
				desyncedRecords: this.state.desyncedRecords.filter((record) => record.id !== recordId),
				deletionRecord: null
			});
			this.setMessageType(MESSAGE_STATE.none, '');
		}
	}

	async setPendingOpsToZero(entityPath) {
		this.setMessageType(MESSAGE_STATE.loading, 'Setting pending ops to zero...');
		try {
			await desyncedJobsService.setPendingOpsToZero(entityPath);
			this.setMessageType(
				MESSAGE_STATE.success,
				`Pending ops set to zero for this record. Record ${entityPath} is ready to be deleted.`
			);
		} catch (e) {
			console.error(e);
			this.setMessageType(MESSAGE_STATE.error, `${e.name}: ${e.message}`);
		}
	}

	setMessageType(messageType, messageContent) {
		this.setState({
			messageType,
			messageContent
		});
	}

	onQuickFilterChanged(e) {
		this.gridApi.setQuickFilter(e.target.value);
	}

	setDeletionRecord(id) {
		this.setState({deletionRecord: id});
	}

	setDeleteModalOpen(value) {
		this.setState({isDeleteModalOpen: value});
	}

	render() {
		const {redirect, messageType, messageContent, desyncedRecords, isDeleteModalOpen, isEditModalOpen, editRecord} =
			this.state;
		if (redirect) {
			return <Redirect to="/" />;
		}
		return (
			<div className="ag-theme-balham page-wrapper">
				<div className="message-wrapper">
					<GenericMessage type={messageType} content={messageContent} />
				</div>

				<Form className="form-wrapper">
					<Form.Field>
						<label>Filter Grid</label>
						<input
							type="text"
							onChange={(e) => this.setState({filter: e.currentTarget.value})}
							placeholder="Work Number, Work Id, etc"
							icon="search"
						/>
					</Form.Field>
				</Form>

				<AgGridReact
					onGridReady={(ev) => {
						ev.api.sizeColumnsToFit();
					}}
					getRowNodeId={(node) => node.id}
					immutableData
					quickFilterText={this.state.filter}
					rowData={desyncedRecords}
					modules={AllModules}
					sideBar={desyncedJobsService.sidebar}
					defaultColDef={desyncedJobsService.defaultColDef}
					columnDefs={this.columns}
				/>

				<ConfirmationModal
					message="Delete record from DesyncFailed?"
					isOpen={isDeleteModalOpen}
					toggleIsOpen={() => this.setDeleteModalOpen(false)}
					confirmationAction={() => this.confirmDeletionOfRecord()}
				/>
				<EditDesyncedModal
					isOpen={isEditModalOpen}
					record={editRecord}
					toggleIsOpen={() => this.setState({isEditModalOpen: false})}
					confirmEdit={(updatedRecord) => this.confirmUpdateOfRecord(updatedRecord)}
				/>
			</div>
		);
	}

	columns = [
		{
			headerName: 'Actions',
			width: 150,
			cellRendererFramework: (params) => {
				return desyncedJobsService.renderGridActionButtons(
					params,
					() => this.setState({isEditModalOpen: true, editRecord: params.data}),
					() => this.invokeSpectrumManualRetry(params.data.id),
					() => {
						this.setDeleteModalOpen(true);
						this.setDeletionRecord(params.data.id);
					},
					() => this.setPendingOpsToZero(params.data.entityPath)
				);
			}
		},
		{
			headerName: 'Desync Id',
			field: 'id',
			width: 100
		},
		{
			headerName: 'Entity Path',
			field: 'entityPath',
			width: 100
		},
		{
			headerName: 'Type',
			valueGetter: (params) => params.data.attributes.type.stringValue,
			width: 100
		},
		{
			headerName: 'Body',
			valueGetter: (params) => JSON.stringify(params.data.body),
			width: 400
		}
	];
}

const storeConnector = connect((state) => ({
	userEmail: state.auth.account.email
}));

export default storeConnector(DesyncedJobs);
