import update from 'immutability-helper';

export default (
	state = {
		loading: false,
		activeTabIndex: 0,
		toggled: false,
		rowData: null,
		rowMenu: [
			{
				name: 'My Work',
				active: true,
				status: true
			},
			{
				name: 'Today',
				active: false
			},
			{
				name: 'Tomorrow',
				active: false
			},
			{
				name: 'In Progress',
				active: false,
				status: true,
				statusId: 2
			},
			{
				name: 'On Hold',
				active: false,
				status: true,
				statusId: 9
			},
			{
				name: 'In Queue',
				active: false,
				status: true,
				statusId: 8
			},
			{
				name: 'Unscheduled',
				active: false,
				status: true,
				statusId: 1
			},
			{
				name: 'Support',
				active: false
			},
			{
				name: 'Reports',
				active: false,
				status: true
			},
			{
				name: 'History',
				active: false,
				status: true
			}
		]
	},
	action
) => {
	switch (action.type) {
		case 'WORK_GRID_LOADER':
			state = update(state, {
				loading: {
					$set: action.payload.value
				}
			});
			break;

		case 'WORK_FETCH_WORK_GRID':
			state = update(state, {
				rowData: {
					$set: action.payload
				}
			});
			break;

		case 'WORK_FETCH_WORK_GRID_REPORTS':
			state = update(state, {
				rowData: {
					$push: [...action.payload]
				}
			});
			break;

		case 'WORK_GRID_UPDATE_ITEM':
			state = update(state, {
				rowData: {
					[action.payload.index]: {
						[action.payload.field]: {
							$set: action.payload.data
						}
					}
				}
			});
			break;

		case 'WORK_GRID_ADD_WORK_ITEM':
			state =
				state.rowData && state.rowData.length > 0
					? update(state, {
							rowData: {
								$unshift: action.payload
							}
					  })
					: update(state, {
							rowData: {
								$set: action.payload
							}
					  });
			break;

		case 'WORK_MENU_ITEM_ACTIVE_WORK_GRID':
			state = update(state, {
				rowMenu: {
					[action.payload.previousActiveItemIndex]: {
						active: {
							$set: false
						}
					},
					[action.payload.newActiveItemIndex]: {
						active: {
							$set: true
						}
					}
				}
			});
			break;

		case 'STORE_ACTIVE_TAB_INDEX':
			state = update(state, {
				activeTabIndex: {
					$set: action.payload
				}
			});
			break;

		case 'UPDATE_WORK_GRID_TOGGLE':
			state = update(state, {
				toggled: {
					$set: !state.toggled
				}
			});
			break;

		default:
	}
	return state;
};
