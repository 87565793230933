import customFetch from '../../../../../../../lib/old/customFetch.js';
import _ from 'lodash';
import {
	createContact,
	addSearchContact
} from '../../../../../../../components/contactModal/services/contactModalActions.js';
import {validateGoogleDriveLink} from '../../../../../../../lib/old/validation.js';
import {roundFloat} from '~/util/numbers.ts';
import branchOptions from '../../../../../../../constants/branches.js';

export const toggleCreateProjectModal = (
	id = null,
	place = null,
	oppName = null,
	clientName = null,
	clientId = null,
	accountExecId = null,
	accountExecName = null,
	poAmount = null,
	serviceType = null,
	branchName = null
) => {
	return (dispatch, getState) => {
		dispatch({type: 'CREATE_PROJECT_CLEAR_TEMP_CONTACTS'});
		dispatch({
			type: 'CREATE_PROJECT_MODAL_TOGGLE',
			payload: {
				id,
				place
			}
		});
		let options = [
			{
				key: accountExecId,
				text: accountExecName,
				value: accountExecId
			}
		];
		const clientOption = [
			{
				key: clientId,
				text: clientName,
				value: clientId
			}
		];
		dispatch({
			type: 'CREATE_PROJECT_MODAL_AUTOFILL',
			payload: {
				oppName,
				clientName,
				clientId,
				accountExecId,
				options,
				poAmount,
				serviceType,
				clientOption,
				branchName
			}
		});

		if (accountExecId) {
			dispatch(getSalesRepresentativeData(accountExecId));
		}

		let checkToggle = getState().crm.createProject.createProjectModal.toggleCreateProjectModal;
		let agGridSectionIndex = getState().crm.createProject.createProjectModal.sections.findIndex(
			(section) => section.name === 'agGridSection'
		);
		if (checkToggle) {
			// Get technologies for Billing Details on Toggle Open
			dispatch(getTechnolgies(id, agGridSectionIndex));
		}
	};
};

export const getSalesRepresentativeData = (accountExecutiveId) => (dispatch) => {
	dispatch({
		type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
		payload: {
			sectionIndex: 0,
			fieldIndex: 11,
			loading: true
		}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?employeeId=${accountExecutiveId}`;

	customFetch(endpoint)
		.then((response) => {
			if (response.status !== 200) {
				return Promise.reject(response);
			}

			return response.json();
		})
		.then((json) => {
			dispatch({
				type: 'CREATE_PROJECT_MODAL_UPDATE',
				payload: {
					sectionIndex: 0,
					fieldIndex: 11,
					loading: false,
					value: json.branchId
				}
			});

			dispatch({
				type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
				payload: {
					sectionIndex: 0,
					fieldIndex: 11,
					loading: false
				}
			});
		})
		.catch((err) => {
			dispatch({
				type: 'ERROR',
				payload: err.stack
			});
			dispatch({
				type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
				payload: {
					sectionIndex: 0,
					fieldIndex: 11,
					loading: false
				}
			});
		});
};

//this shouldnt be a thunk action... no async going on here.
export const updateCreateProjectModal = (modal, sectionIndex, fieldIndex, value, error) => {
	return (dispatch, getState) => {
		const fieldName =
			getState().crm.createProject.createProjectModal.sections[sectionIndex].fields[fieldIndex].name;
		dispatch({
			type: 'CREATE_PROJECT_MODAL_UPDATE',
			payload: {
				modal,
				sectionIndex,
				fieldIndex,
				value,
				error
			}
		});
		if (fieldName === 'Sales Representative') {
			dispatch(getSalesRepresentativeData(value));
		}
	};
};

export const updateGroupBilling = (data) => {
	return (dispatch, getState) => {
		const state = getState().crm.createProject.createProjectModal;
		let modal = 'createProjectModal';
		let clientId = state.clientId;
		let sectionIndex = state.sections.findIndex((section) => section.name === 'billingDetailsSection');
		let fieldIndex = state.sections[sectionIndex].fields.findIndex((field) => field.name === 'Location');
		let value = data === true ? 'Various Locations' : '';
		let error = false;
		dispatch({
			type: 'CREATE_PROJECT_MODAL_UPDATE',
			payload: {
				modal,
				sectionIndex,
				fieldIndex,
				value,
				error
			}
		});
		if (value === 'Various Locations') {
			dispatch(getLocations(sectionIndex, fieldIndex, clientId, 'Various Locations', error));
		}
	};
};

export const getClients = (sectionIndex, fieldIndex, value) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				loading: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/client?clientSearch=${encodeURIComponent(value)}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((client) => ({
					key: client.id,
					text: `${client.name}, ${client.spectrumCode}`,
					value: client.id
				}));
				dispatch({
					type: 'CREATE_PROJECT_MODAL_GET_DROPDOWN_OPTIONS',
					payload: {
						sectionIndex,
						fieldIndex,
						options
					}
				});
				dispatch({
					type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const getEmployees = (sectionIndex, fieldIndex, value) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
			payload: {
				sectionIndex,
				fieldIndex,
				loading: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((accountExec) => ({
					key: accountExec.id,
					text: accountExec.fullName,
					value: accountExec.id
				}));
				dispatch({
					type: 'CREATE_PROJECT_MODAL_GET_DROPDOWN_OPTIONS',
					payload: {
						sectionIndex,
						fieldIndex,
						options
					}
				});
				dispatch({
					type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: false
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const getTechnolgies = (id, sectionIndex) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_PROJECT_MODAL_LOADING',
			payload: {
				state: 'start'
			}
		});
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}?mode=technology`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json = json.map((tech) => ({
					revenueLabor: 0,
					revenueMaterial: 0,
					estimatedLaborCost: 0,
					estimatedMaterialCost: 0,
					...tech
				}));
				dispatch({
					type: 'CREATE_PROJECT_MODAL_GET_TECHNOLOGIES',
					payload: {
						sectionIndex,
						json
					}
				});
				dispatch({
					type: 'CREATE_PROJECT_MODAL_LOADING',
					payload: {
						state: null
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

// sets billing values to 0 when technology is set to inactive
export const resetBillingDetailsRowOnToggle = (id) => {
	return (dispatch, getState) => {
		const state = getState().crm.createProject.createProjectModal;
		const rowIndex = state.sections[1].rows.findIndex((row) => row.id === id);
		const updateArray = [
			{field: 'toggled', value: false},
			{field: 'revenueLabor', value: 0},
			{field: 'revenueMaterial', value: 0},
			{field: 'estimatedLaborCost', value: 0},
			{field: 'estimatedMaterialCost', value: 0}
		];
		updateArray.map((record) => {
			dispatch({
				type: 'CREATE_PROJECT_MODAL_GRID_UPDATE',
				payload: {
					sectionIndex: 1,
					rowIndex,
					field: record.field,
					value: record.value
				}
			});
		});
	};
};

export const updateCreateProjectModalGrid = (id, field, value) => {
	return (dispatch, getState) => {
		const state = getState().crm.createProject.createProjectModal;
		const rowIndex = state.sections[1].rows.findIndex((row) => row.id === id);
		dispatch({
			type: 'CREATE_PROJECT_MODAL_GRID_UPDATE',
			payload: {
				sectionIndex: 1,
				rowIndex,
				field,
				value
			}
		});
	};
};

export const addDocument = (fileName, googleDrive, customerApproval, comments, sectionIndex) => {
	return (dispatch, getState) => {
		const state = getState().crm.createProject.createProjectModal;
		const validLink = validateGoogleDriveLink(googleDrive.value);
		if (!fileName.value || !googleDrive.value || !validLink) {
			const emptyFields = state.sections[sectionIndex].fields
				.map((field, fieldIndex) => ({
					field: field,
					fieldIndex: fieldIndex
				}))
				.filter(
					(field) =>
						field.field.checkForm &&
						(!field.field.value || (field.field.name === 'Google Drive Link' && !validLink))
				);
			emptyFields.map((field) =>
				dispatch(
					updateCreateProjectModal(
						'createProjectModal',
						sectionIndex,
						field.fieldIndex,
						field.field.value,
						true
					)
				)
			);
		} else {
			dispatch({
				type: 'CREATE_PROJECT_MODAL_ADD_DOCUMENT',
				payload: {
					sectionIndex,
					object: {
						fileName: fileName.value,
						googleDrive: googleDrive.value,
						customerApproval: customerApproval.value,
						comments: comments.value
					}
				}
			});
		}
	};
};

export const updateCreateProjectModalDocumentGrid = (rowIndex, sectionIndex, value) => {
	return (dispatch) =>
		dispatch({
			type: 'CREATE_PROJECT_MODAL_UPDATE_DOC_GRID',
			payload: {
				sectionIndex,
				rowIndex,
				value
			}
		});
};

export const removeCreateProjectModalDocumentGrid = (rowIndex, sectionIndex) => {
	return (dispatch) =>
		dispatch({
			type: 'CREATE_PROJECT_MODAL_REMOVE_DOC_GRID',
			payload: {
				sectionIndex,
				rowIndex
			}
		});
};

export const getLocations = (sectionIndex, fieldIndex, clientId, value, error) => {
	console.log('hit inside getLocations');
	return (dispatch, getState) => {
		const state = getState().crm.createProject.createProjectModal;
		let locationField = state.sections
			.filter((section) => section.name === 'billingDetailsSection')[0]
			.fields.filter((field) => field.name === 'Location')[0];
		let locationValue = locationField.value;
		let locationSelectedLocation = locationField.dataList.filter((location) => location.name === locationValue)[0];
		if (value !== 'Various Locations') {
			// Check if selected location is value to stop getLocation from firing
			if (locationSelectedLocation) {
				// Stops script
				return;
			} else {
				// Dispatch Loader
				dispatch({
					type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
					payload: {
						sectionIndex,
						fieldIndex,
						loading: true
					}
				});

				let endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations?limit=8&clientId=${clientId}&search=${value}`;

				customFetch(endpoint)
					.then((response) => {
						if (response.status !== 200) {
							return Promise.reject(response);
						}
						return response.json();
					})
					.then((json) => {
						dispatch({
							type: 'CREATE_PROJECT_MODAL_GET_DATALIST_OPTIONS',
							payload: {
								value,
								sectionIndex,
								fieldIndex,
								json
							}
						});
						dispatch({
							type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
							payload: {
								sectionIndex,
								fieldIndex,
								loading: false
							}
						});
					})
					.catch((err) => {
						dispatch({
							type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
							payload: {
								sectionIndex,
								fieldIndex,
								loading: false
							}
						});
						dispatch({
							type: 'ERROR',
							payload: err.stack
						});
					});
			}
		} else {
			dispatch(
				updateCreateProjectModal('createProjectModal', sectionIndex, fieldIndex, 'Various Locations', false)
			);
			return;
		}
	};
};

export const getBillingContacts = (sectionIndex, fieldIndex, clientId, value, error, newContact) => {
	return (dispatch, getState) => {
		console.log('getBillingContacts called');
		const state = getState().crm.createProject.createProjectModal;
		let billingContactField = state.sections
			.filter((section) => section.name === 'billingDetailsSection')[0]
			.fields.filter((field) => field.name === 'Billing Contact')[0];
		let billingContactValue = billingContactField.value;
		let billingContactSelected = billingContactField.dataList.filter(
			(contact) => contact.fullName === billingContactValue
		)[0];
		if (billingContactSelected) {
			// Should stop script, bugged currently so turned off
			console.log('script stopping');
		} else {
			// Dispatch Loader
			dispatch({
				type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
				payload: {
					sectionIndex,
					fieldIndex,
					loading: true
				}
			});

			let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}&name=${value}&type=1`;

			customFetch(endpoint)
				.then((response) => {
					if (response.status !== 200) {
						return Promise.reject(response);
					}
					return response.json();
				})
				.then((json) => {
					let billingContacts = json.filter((contact) => contact.isBillingContact);
					dispatch({
						type: 'CREATE_PROJECT_MODAL_GET_DATALIST_OPTIONS',
						payload: {
							value: newContact === 'newContact' ? value : null,
							sectionIndex,
							fieldIndex,
							error,
							json: billingContacts
						}
					});
					dispatch({
						type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
						payload: {
							sectionIndex,
							fieldIndex,
							loading: false
						}
					});
				})
				.catch((err) => {
					dispatch({
						type: 'CREATE_PROJECT_MODAL_LOADING_FIELD',
						payload: {
							sectionIndex,
							fieldIndex,
							loading: false
						}
					});
					dispatch({
						type: 'ERROR',
						payload: err.stack
					});
				});
		}
	};
};

export const createProject = () => {
	return (dispatch, getState) => {
		const state = getState().crm.createProject.createProjectModal;
		const agGridSection = state.sections[1];

		//used to return early if errors
		let hasError = false;
		const errors = [];

		//// VALIDATION ////
		let missingFields = state.sections
			.map((section, sectionIndex) => ({
				fields: section.fields,
				sectionIndex: sectionIndex
			}))
			.map((object) =>
				object.fields
					.map((field, fieldIndex) => ({
						field: field,
						fieldIndex: fieldIndex,
						sectionIndex: object.sectionIndex
					}))
					.filter(
						(field) =>
							field.field.required &&
							(!field.field.value ||
								(field.field.name === 'Google Drive Link' &&
									!validateGoogleDriveLink(field.field.value)))
					)
			)
			.filter((object) => object.length > 0)
			.reduce((a, b) => a.concat(b), []);

		if (missingFields.length > 0) {
			hasError = true;
			dispatch({
				type: 'CREATE_PROJECT_MODAL_CHECK_REQUIRED_FIELDS',
				payload: missingFields
			});
		}

		//utility function for getting values
		const fieldValueOrNull = (section, fieldName) => {
			const field = state.sections[section].fields.find((f) => f.name === fieldName);
			//i dont know why this would ever be the case, when we have more time we should get rid of this check and fix any errors where the field does not exist.
			if (!field) {
				return null;
			}
			return field.value;
		};

		// Check PO Amount === Total Revenue && PO Document
		const poAmount = Number(
			//should be parsing on field input instead of here...
			fieldValueOrNull(0, 'Purchase Order Amount')
				.toString()
				.replace(/[^0-9.-]+/g, '')
		);
		let totalRevenue = agGridSection.rows.reduce(
			(total, data) => total + data.revenueLabor + data.revenueMaterial,
			0
		);
		totalRevenue = roundFloat(totalRevenue, 2); //round to avoid any float math errors
		if (totalRevenue !== poAmount) {
			hasError = true;
			errors.push('poAmount');
		}

		const purchaseOrderDocValid = state.sections[3].documents.find((doc) => doc.customerApproval);
		if (!purchaseOrderDocValid) {
			hasError = true;
			errors.push('noApprovalDoc');
		}

		const firstToggled = state.sections[1].rows.find((tech) => tech.toggled);
		if (!firstToggled) {
			hasError = true;
			errors.push('noActiveTechnologies');
		}

		if (hasError) {
			if (errors) {
				dispatch({
					type: 'CREATE_PROJECT_MODAL_LOADING',
					payload: {state: errors}
				});
			}
			return;
		}

		/// READY TO POST ///
		dispatch({
			type: 'CREATE_PROJECT_MODAL_LOADING',
			payload: {state: 'progress'}
		});
		//this jankery is because we are storing value as a string instead of id
		//Bug: duplicate named billing contacts will always send the first one, even if they select the second
		//to fix this we need to store the id in value.
		let billingContact = state.sections[2].fields
			.find((f) => f.name === 'Billing Contact')
			.dataList.find((c) => c.fullName === fieldValueOrNull(2, 'Billing Contact'));
		const serviceType = fieldValueOrNull(0, 'Service Type');

		let workPayload = {
			opportunityName: fieldValueOrNull(0, 'Opportunity Name'),
			opportunityId: state.id,
			clientId: state.clientId,
			purchaseOrderNumber: fieldValueOrNull(0, 'Purchase Order #'),
			projectManagerId: fieldValueOrNull(0, 'Project Manager'),
			accountExecutiveId: fieldValueOrNull(0, 'Sales Representative'),
			purchaseOrderAmount: poAmount,
			isGroupBillable: fieldValueOrNull(0, 'Group Billing'),
			serviceType: serviceType,
			// service type options ? look in reducer
			accountManagerId: fieldValueOrNull(0, 'Account Manager'),
			ndsId: fieldValueOrNull(0, 'Project Administrator'),
			branch:
				serviceType === 2 ? 'NTI' : branchOptions.find((op) => op.text === fieldValueOrNull(0, 'Branch')).code,
			//this logic probably shouldnt be here, but for now im leaving it
			//location should just be null if groupBilling is yes via the action.
			locationId: fieldValueOrNull(0, 'Group Billing') ? null : fieldValueOrNull(2, 'Location'),
			billingContactId: billingContact.id,
			googleDriveLink: fieldValueOrNull(2, 'Google Drive Link'),
			//should be stoed as bool
			isNewConstruction: fieldValueOrNull(2, 'New Construction') === 'True',
			isProject: true,
			isDraft: false,
			//workCategory is just used for spectrum, to determine cost center.
			//This is our way of narrowing it down to only one. It's jank but st least its consistent
			//Maybe at some point we can hide away this logic into spectrumFunctions and have it select the first tech there.
			workCategory: firstToggled.name,
			masterClientId: fieldValueOrNull(0, 'Master Client')
		};

		if (workPayload.serviceType === 4) {
			workPayload.serviceSubType = fieldValueOrNull(0, 'Service Type Options');
		}
		console.log(workPayload);
		let workEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work`;

		customFetch(workEndpoint, {
			method: 'POST',
			body: JSON.stringify(workPayload)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let projectId = json[0].id;
				// SET PROJECT ID TO USE NEXT POSTS
				dispatch({
					type: 'CREATE_PROJECT_UPDATE_PROJECT_ID',
					payload: projectId
				});
			})
			.then(() => {
				let projectId = getState().crm.createProject.createProjectModal.projectId;
				console.log(projectId);

				// POST CONTACT SECTION
				let contacts = getState().crm.createProject.createProjectModal.tempContacts;
				contacts.forEach((contact) => {
					contact.type = 'work';
					contact.typeId = projectId;
					if (contact.contactId) {
						dispatch(
							addSearchContact(contact.type, contact.typeId, contact.contactId, contact.modalFields)
						);
					} else {
						dispatch(createContact(contact.type, contact.typeId, contact.modalFields));
					}
				});

				// POST BILLING DETAILS
				let projectBillingArray = state.sections[1].rows;
				let mappedArrayed = projectBillingArray
					.map((tech) => ({
						active: tech.toggled,
						technologyId: tech.id,
						revenueLabor: tech.revenueLabor,
						revenueMaterial: tech.revenueMaterial,
						estimatedLaborCost: tech.estimatedLaborCost,
						estimatedMaterialCost: tech.estimatedMaterialCost,
						totalRevenue: tech.revenueLabor + tech.revenueMaterial,
						totalCost: tech.estimatedMaterialCost + tech.estimatedLaborCost
					}))
					.filter(
						(item) =>
							item.revenueLabor ||
							item.revenueMaterial ||
							item.estimatedLaborCost ||
							item.estimatedMaterialCost
					);
				console.log(mappedArrayed);

				let billingEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${projectId}/projectBilling`;

				customFetch(billingEndpoint, {
					method: 'POST',
					body: JSON.stringify(mappedArrayed)
				})
					.then((response) => {
						if (response.status !== 200) {
							return Promise.reject(response);
						}
						return;
					})
					.then(() => {
						/// DOCUMENTS POST
						let projectId = getState().crm.createProject.createProjectModal.projectId;
						let clientId = getState().crm.createProject.createProjectModal.clientId;
						let oppId = getState().crm.createProject.createProjectModal.id;
						console.log(projectId);

						// POST BILLING DETAILS
						let documentArray = state.sections[3].documents;
						console.log(documentArray);
						let docMapped = documentArray.map((document) => ({
							comments: document.comments,
							opportunityId: oppId,
							workId: projectId,
							clientId: clientId,
							googleDriveLink: document.googleDrive,
							fileName: document.fileName,
							s3Url: null
						}));
						console.log(docMapped);

						let documentEndpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

						customFetch(documentEndpoint, {
							method: 'POST',
							body: JSON.stringify(docMapped)
						})
							.then((response) => {
								if (response.status !== 200) {
									return Promise.reject(response);
								}
								return response.json();
							})
							.then(() => {
								dispatch(toggleCreateProjectModal());
								dispatch({
									type: 'CREATE_PROJECT_MODAL_LOADING',
									payload: {state: null}
								});
							})
							.catch((err) => {
								dispatch({
									type: 'ERROR',
									payload: err.stack
								});
								dispatch({
									type: 'CREATE_PROJECT_MODAL_LOADING',
									payload: {state: 'error'}
								});
							});
					})
					.catch((err) => {
						dispatch({
							type: 'ERROR',
							payload: err.stack
						});
						dispatch({
							type: 'CREATE_PROJECT_MODAL_LOADING',
							payload: {state: 'error'}
						});
					});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'CREATE_PROJECT_MODAL_LOADING',
					payload: {state: 'error'}
				});
			});
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_PROJECT_MODAL_LOADING',
			payload: {
				state: value
			}
		});
	};
};

export const addToTempCreateProjectContacts = (contact) => {
	return (dispatch) => {
		let displayContact;
		if (contact.contactId) {
			let contactObj = contact.modalFields[0].value;
			displayContact = {
				firstName: contactObj.firstName,
				lastName: contactObj.lastName,
				title: contactObj.title,
				email: contactObj.email,
				phone: contactObj.phone,
				extension: contactObj.extension,
				isPrimary: contactObj.isPrimary,
				fullName: `${contactObj.firstName} ${contactObj.lastName}`,
				existing: true
			};
		} else {
			displayContact = {
				firstName: contact.modalFields[1].value,
				lastName: contact.modalFields[2].value,
				title: contact.modalFields[3].value,
				email: contact.modalFields[4].value,
				phone: contact.modalFields[5].value,
				extension: contact.modalFields[6].value,
				isPrimary: contact.modalFields[7].value,
				fullName: `${contact.modalFields[1].value} ${contact.modalFields[2].value}`
			};
		}
		dispatch({
			type: 'CREATE_PROJECT_ADD_TEMP_CONTACT',
			payload: {
				contact,
				displayContact
			}
		});
	};
};

export const removeFromTempCreateProjectContacts = (index) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_PROJECT_REMOVE_TEMP_CONTACT',
			payload: {
				index
			}
		});
	};
};

export const updateTempCreateProjectContacts = (index, contact) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_PROJECT_UPDATE_TEMP_CONTACT',
			payload: {
				index,
				contact
			}
		});
	};
};
