import React from 'react';
import {Input} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';

import * as actions from '../../../../../services/workRecordActions.js';
import {Column} from '../../../../../../../../../../../components/layout/Flex.jsx';

const CreateBillModalInputField = (props) => (
	<Column>
		<label>{props.field.name}</label>
		<DebounceInput
			element={Input}
			onChange={(e) => {
				props.createModalUpdateColumn(props.fieldIndex, e.target.value, false);
			}}
			icon={props.fieldIcon}
			iconPosition={props.fieldIconPosition}
			type={props.fieldType}
			debounceTimeout={600}
			value={props.field.value}
			error={Boolean(props.field.error)}
			disabled={props.field.disabled}
			placeholder={props.field.placeholder}
		/>
	</Column>
);

export default connect(null, actions)(CreateBillModalInputField);
