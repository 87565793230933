import React from 'react';
import {Label, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../../services/createProjectActions.js';

import {debounce, debounceEventHandler} from '../../../../../../../../lib/old/debounce.js';

const SearchField = (props) => (
	<div
		style={{
			paddingTop: '1.2em'
		}}
	>
		<Label
			style={{
				backgroundColor: 'transparent',
				fontSize: '1rem',
				color: 'rgba(0,0,0,.8)',
				paddingLeft: '0'
			}}
		>
			{props.field.name}
		</Label>
		<Dropdown
			disabled={props.field.disabled}
			style={
				props.templateSection
					? {
							display: 'inline-block',
							width: '60%',
							borderRadius: 0,
							fontWeight: 'normal'
					  }
					: null
			}
			className="createWorkOrderClientDropdown"
			placeholder={props.field.placeholder}
			fluid
			search
			options={props.field.options}
			selection
			onChange={(e, {value}) => {
				props.updateCreateProjectModal(
					'createProjectModal',
					props.sectionIndex,
					props.fieldIndex,
					value,
					false
				);
			}}
			onSearchChange={debounceEventHandler(
				debounce((e) => {
					if (e.target.value) {
						if (props.field.employeeSearch) {
							console.log('employee search');
							console.log(e.target.value);
							props.getEmployees(props.sectionIndex, props.fieldIndex, e.target.value);
						}
						if (props.field.searchClient) {
							props.getClients(props.sectionIndex, props.fieldIndex, e.target.value);
						}
					}
				}, 600)
			)}
			value={props.field.value}
			error={props.field.required ? Boolean(props.field.error) : false}
			upward={props.field.upward}
			loading={props.field.loading}
		/>
	</div>
);

SearchField.propTypes = {
	getWorkOrderClients: PropTypes.func
};

export default connect(null, actions)(SearchField);
