import React from 'react';
import {Button, Modal} from 'semantic-ui-react';

function ConfirmationModal(props) {
	const {isOpen, toggleIsOpen, message, confirmationAction} = props;

	return (
		<Modal size="mini" open={isOpen}>
			<Modal.Header>Confirm action</Modal.Header>
			<Modal.Content>
				<Modal.Description>{message}</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => toggleIsOpen()}>Cancel</Button>
				<Button
					primary
					onClick={() => {
						confirmationAction();
						toggleIsOpen();
					}}
				>
					Confirm
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

export default ConfirmationModal;
