import {Form, Dropdown} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import ErrorLabel from './Label_Error.js';
import inputMaskFactory from './inputMaskFactory.js';
import optionFactory from './optionFactory.js';

const InputMaskTaskType = (props) => (
	<Form.Field key={`${props.column}-formField`}>
		<label>Input Mask</label>
		<Dropdown
			disabled={props.task.completed}
			error={props.tasks.ui.errors.inputType}
			key={`${props.column}-input`}
			placeholder={props.task.maskName ? props.task.maskName : 'Select an Input Type'}
			fluid
			search
			selection
			options={inputMaskFactory(props.tasks.inputMask)}
			onChange={(e, {value}) => {
				let options = optionFactory(props);
				options = {...options, maskId: value};
				props.updateTask(props.mode, 'maskId', props.task.taskTypeId, options);
			}}
			defaultValue={props.task.maskId}
		/>
		<ErrorLabel {...props} key={`${props.column}-errorLabel`} message={'Please select an input mask.'} />
	</Form.Field>
);

export default InputMaskTaskType;
