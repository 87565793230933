import {Header, Icon, Card} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const EditPanelHeader = (props) => (
	<Card.Content>
		<Header as="h3">
			<Icon name="edit" />
			{props.id ? (
				<Header.Content>{`Edit ${props.isSection ? 'Section' : 'Task'}`}</Header.Content>
			) : (
				<Header.Content>{`Create ${props.isSection ? 'Section' : 'Task'}`}</Header.Content>
			)}
		</Header>
	</Card.Content>
);

export default EditPanelHeader;

EditPanelHeader.propTypes = {
	id: PropTypes.number.isRequired
};
