import React from 'react';
import {Grid, Header, Icon, Label, Input} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';
import InputField from './components/inputField.js';
import {updateRegistrationForm, loginPasswordCheckAction, stepComplete} from '../services/registrationActions.js';

const LoginInfo = (props) => (
	<Grid>
		<Grid.Row>
			<Grid.Column width={16}>
				<Header as="h3">
					<Icon name="signup" />
					<Header.Content>Create Account</Header.Content>
				</Header>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="loginUsername" field={props.loginUsername} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="loginPassword"
					field={props.loginPassword}
					stepIndex={props.stepIndex}
					type="password"
				/>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<div>
					<div
						style={{
							paddingTop: '0.5em'
						}}
					>
						<Label
							style={{
								backgroundColor: 'transparent',
								fontSize: '1rem',
								color: 'rgba(0,0,0,.8)',
								paddingLeft: '0'
							}}
						>
							{props.loginPasswordCheck.name}
						</Label>
					</div>
					<DebounceInput
						element={Input}
						onChange={(e) => {
							props.updateRegistrationForm(
								'registrationForm',
								props.stepIndex,
								'loginPasswordCheck',
								e.target.value,
								false
							);
							props.loginPasswordCheckAction(
								'registrationForm',
								props.stepIndex,
								'loginPasswordCheck',
								e.target.value
							);
						}}
						type={'password'}
						debounceTimeout={600}
						value={props.loginPasswordCheck.value}
						style={{
							width: '100%'
						}}
						error={props.loginPasswordCheck.required ? Boolean(props.loginPasswordCheck.error) : false}
						disabled={props.loginPasswordCheck.disabled}
						placeholder={props.loginPasswordCheck.placeholder}
					/>
				</div>
			</Grid.Column>
			<Grid.Column width={16} style={{paddingTop: '.7em'}}>
				{props.loginPasswordCheckErrorMessage.error ? props.loginPasswordCheckErrorMessage.value : null}
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

const mapStateToProps = (state) => {
	return {
		stepIndex: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Create Account')[0].index,
		loginUsername: state.registrationForm.registrationForm.fields.loginUsername,
		loginPassword: state.registrationForm.registrationForm.fields.loginPassword,
		loginPasswordCheck: state.registrationForm.registrationForm.fields.loginPasswordCheck,
		loginPasswordCheckErrorMessage: state.registrationForm.registrationForm.fields.loginPasswordCheckErrorMessage
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateRegistrationForm: (modal, step, column, value, error) => {
			dispatch(updateRegistrationForm(modal, step, column, value, error));
		},
		loginPasswordCheckAction: (modal, step, column, value) => {
			dispatch(loginPasswordCheckAction(modal, step, column, value));
		},
		stepComplete: (stepIndex) => {
			dispatch(stepComplete(stepIndex));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginInfo);
