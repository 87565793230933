import {Header, Icon, Card} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const CompletedTaskHeader = (props) => (
	<Card.Content>
		<Header as="h3">
			<Icon name="info circle" />
			<Header.Content>{`Completed ${props.isSection ? 'Section' : 'Task'} Information`}</Header.Content>
		</Header>
	</Card.Content>
);

export default CompletedTaskHeader;
