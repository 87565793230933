import update from 'react-addons-update';

export default (
	state = {
		billingContactModal: {
			id: null,
			clientId: null,
			error: false,
			toggleBillingContactModal: false,
			modalFields: [
				{
					name: 'First Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: true
				},
				{
					name: 'Last Name',
					input: true,
					value: '',
					error: false,
					disabled: false,
					required: true
				},
				{
					name: 'Title',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false
				},
				{
					name: 'Email',
					input: true,
					value: null,
					error: false,
					disabled: false,
					required: true
				},
				{
					name: 'Phone',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: true
				},
				{
					name: 'Ext',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: false
				}
			]
		}
	},
	action
) => {
	switch (action.type) {
		case 'BILLING_CONTACT_MODAL_LOADING':
			state = update(state, {
				billingContactModal: {
					loading: {
						$set: action.payload.state
					}
				}
			});
			break;

		case 'BILLING_CONTACT_MODAL_UPDATE':
			state = update(state, {
				[action.payload.modal]: {
					error: {
						$set: false
					},
					modalFields: {
						[action.payload.column]: {
							value: {
								$set: action.payload.value
							},
							error: {
								$set: false
							}
						}
					}
				}
			});
			break;

		case 'BILLING_CONTACT_MODAL_TOGGLE':
			state = update(state, {
				billingContactModal: {
					toggleBillingContactModal: {
						$set: !state.billingContactModal.toggleBillingContactModal
					},
					id: {
						$set: action.payload.id
					},
					clientId: {
						$set: action.payload.clientId
					},
					modalFields: {
						$set: baseModalFields
					}
				}
			});
			break;

		case 'BILLING_CONTACT_MODAL_CHECK_REQUIRED_FIELDS':
			action.payload.emptyRequiredFields.forEach((field) => {
				state = update(state, {
					billingContactModal: {
						error: {
							$set: true
						},
						modalFields: {
							[field.FieldIndex]: {
								error: {
									$set: true
								}
							}
						}
					}
				});
			});
			break;
	}

	return state;
};

const baseModalFields = [
	{
		name: 'First Name',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: true
	},
	{
		name: 'Last Name',
		input: true,
		value: '',
		error: false,
		disabled: false,
		required: true
	},
	{
		name: 'Title',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false
	},
	{
		name: 'Email',
		input: true,
		value: null,
		error: false,
		disabled: false,
		required: true
	},
	{
		name: 'Phone',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: true
	},
	{
		name: 'Ext',
		value: '',
		input: true,
		error: false,
		disabled: false,
		required: false
	}
];
