// Modules
import React from 'react';
import {Button, Modal, Icon, Header, Grid, Segment, Label, Input, Dimmer, Loader} from 'semantic-ui-react';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import moment from 'moment';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
// fix this vvv
import * as actions from '../../../../../services/accountingConsoleActions.js';

class PrevApprovedModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			columnDefs: [],
			rowData: false,
			category: ''
		};
	}

	handleClick = () => {
		this.props.closePreviouslyApprovedModal();
	};

	componentDidUpdate() {
		const category = this.props.data.category;
		if (category !== this.state.category) {
			let defs = [];
			switch (category) {
				case 'Project': {
					defs = [
						{
							headerName: 'Client',
							field: 'clientName',
							width: 200
						},
						{
							headerName: 'Branch',
							field: 'branchName',
							width: 125
						},
						{
							headerName: 'Created Date',
							field: 'workCreatedOn',
							valueFormatter: (data) => moment(data.value ? data.value : null).format('M/D/YYYY h:mm A'),
							width: 175
						},
						{
							headerName: 'Sales Person',
							field: 'accountExecutiveName',
							width: 200
						},
						{
							headerName: 'Name',
							field: 'name',
							width: 200
						}
					];
					break;
				}
				default: {
					defs = [
						{
							headerName: 'ID',
							field: 'id',
							width: 200
						}
					];
				}
			}
			this.setState({category, columnDefs: defs});
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
	};

	render() {
		const records = this.props.data.records;
		return (
			<Modal open={this.props.prevApprovedModalToggle}>
				<Header>
					<Grid>
						<Grid.Row>
							<div>Previously Approved</div>
						</Grid.Row>
					</Grid>
				</Header>
				<Modal.Content scrolling>
					<Segment basic textAlign="center">
						<h4>{`One or more of the records that you are trying to approve/reject have already been approved or rejected.`}</h4>
					</Segment>
					<Segment
						basic
						loading={this.props.loading}
						className="ag-theme-balham"
						style={{
							margin: '0 2%',
							height: '28rem',
							width: '96%'
						}}
					>
						<AgGridReact
							rowData={records}
							columnDefs={this.state.columnDefs}
							onGridReady={this.onGridReady}
							defaultColDef={{resizable: true, sortable: true}}
							modules={AllModules}
						/>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button color="green" onClick={this.handleClick}>
							OK
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

PrevApprovedModal.propTypes = {
	prevApprovedModalToggle: PropTypes.bool,
	data: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		prevApprovedModalToggle: state.accountingConsoleReducer.accountingConsole.previouslyApprovedModalToggle,
		data: state.accountingConsoleReducer.accountingConsole.previouslyApprovedModal
	};
};

export default connect(mapStateToProps, actions)(PrevApprovedModal);
