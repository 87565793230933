import React from 'react';
import {connect} from 'react-redux';
import {Popup, Button, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

// Services
import * as actions from '../../../../../../../services/accountingConsoleActions.js';

const ArchiveButton = (props) => {
	return (
		<Popup
			trigger={
				<Button
					style={{
						backgroundColor: '#e8e8e8',
						padding: '.5em .5em .5em'
					}}
					onClick={() => {
						props.archiveRejection(props.record);
					}}
				>
					<Icon name="archive" color="olive" style={{cursor: 'pointer', margin: 0}} />
				</Button>
			}
			content={`Archive`}
		/>
	);
};

ArchiveButton.propTypes = {
	selectedCategory: PropTypes.string,
	ACAcceptOrReject: PropTypes.func,
	selectedRow: PropTypes.number,
	selectedArray: PropTypes.array,
	toggleAcceptModal: PropTypes.func,
	acceptModal: PropTypes.bool,
	toggleDesyncedMessage: PropTypes.func,
	record: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		selectedCategory: state.accountingConsoleReducer.accountingConsole.selectedCategory.name,
		selectedArray:
			state.accountingConsoleReducer.accountingConsole.selectedCategory.data[
				state.accountingConsoleReducer.accountingConsole.selectedCategory.data.findIndex(
					(category) =>
						category.name === state.accountingConsoleReducer.accountingConsole.selectedCategory.name
				)
			].rowData
	};
};

export default connect(mapStateToProps, actions)(ArchiveButton);
