import React from 'react';
import {Label, Input} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';

import {updateCreateProjectModal} from '../../services/createProjectActions.js';

const InputField = (props) => (
	<div>
		<div
			style={{
				paddingTop: '0.8em'
			}}
		>
			<Label
				style={{
					backgroundColor: 'transparent',
					fontSize: '1rem',
					color: 'rgba(0,0,0,.8)',
					paddingLeft: '0'
				}}
			>
				{props.field.name}
			</Label>
		</div>
		<DebounceInput
			element={Input}
			onChange={(e) => {
				props.updateCreateProjectModal(
					'createProjectModal',
					props.sectionIndex,
					props.fieldIndex,
					e.target.value,
					false
				);
			}}
			debounceTimeout={600}
			value={props.field.value}
			style={{
				width: '100%'
			}}
			error={props.field.required || props.field.checkForm ? Boolean(props.field.error) : false}
			disabled={props.field.disabled}
			placeholder={props.field.placeholder}
		/>
	</div>
);

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateProjectModal: (modal, sectionIndex, fieldIndex, value, error) => {
			dispatch(updateCreateProjectModal(modal, sectionIndex, fieldIndex, value, error));
		}
	};
};

export default connect(null, mapDispatchToProps)(InputField);
