import React from 'react';
import PropTypes from 'prop-types';
import {Editor} from '@tinymce/tinymce-react';
import {camelCase} from 'lodash';

//This is just a starting point, need to make this more robust as we use it.
export const FancyEditor = (props) => {
	return (
		<Editor
			init={{
				menubar: '',
				license_key: 'gpl',
				promotion: false,
				plugins: 'link table image code save',
				toolbar: `${props.onSave ? 'save |' : ''}
				blocks fontsize | forecolor backcolor | bold italic underline link | alignleft aligncenter alignright | outdent indent`, //add ' | code' to this to inspect source for debugging
				height: props.height ?? '100%',
				save_onsavecallback: () => {}, //this is required to override default behavior. will raise an error if removed.
				paste_preprocess: (unused, v) => {
					v.content = removeHtmlComments(v.content);
				},
				paste_postprocess: (unused, {node}) => {
					sanitizeNodeTree(node);
				}
			}}
			outputFormat="html"
			onEditorChange={(value) => {
				if (value !== props.value) {
					props.onChange(value);
				}
			}}
			onSaveContent={(ev) => {
				if (props.onSave) {
					props.onSave(ev.content);
				}
			}}
			value={props.value}
			disabled={props.disabled}
		/>
	);
};

FancyEditor.propTypes = {
	value: PropTypes.string.isRequired,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onSave: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

const acceptedStylesHashMap = {
	color: {},
	'background-color': {},
	'text-align': {
		validator: (value) => {
			const valuesToReplace = {
				start: 'left',
				end: 'right'
			};
			const newValue = valuesToReplace[value];
			if (newValue) {
				return newValue;
			}
			return value;
		}
	},
	'text-decoration': {},
	'font-size': {}
};

const sanitizeNodeTree = (node) => {
	//remove all classes
	node.removeAttribute('class');
	//remove styles
	for (let i = node.style.length; i--; ) {
		const name = node.style[i];
		const acceptedStyle = acceptedStylesHashMap[name];

		// apply acceptedStyle validator if it exists, if acceptedStyle doesn't exist remove it from element
		if (acceptedStyle && acceptedStyle.validator) {
			const camelCaseName = camelCase(name);
			node.style[camelCaseName] = acceptedStyle.validator(node.style[camelCaseName]);
		} else if (!acceptedStyle) {
			node.style.removeProperty(name);
		}
	}

	//recurse on children
	for (const child of node.children) {
		sanitizeNodeTree(child);
	}
};

const removeHtmlComments = (html) => {
	html = html.replace(/<!\[if .*?\]>/g, '');
	html = html.replace(/<!\[endif]>/g, '');
	return html;
};
