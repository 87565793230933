// TODOS
// arrayMove is getting removed from react-sortable-hoc next major version -- find/build a replacement
import React from 'react';
import {Menu, Popup, Icon, Item} from 'semantic-ui-react';
import TaskList from './TaskList.jsx';
import {addTaskToTemplate, editTask, updateTaskTemplateOrder} from '../services/TaskTemplates.actions.js';
import {connect} from 'react-redux';
import {SortableElement, arrayMove} from 'react-sortable-hoc';

const Section = SortableElement((props) => {
	const {section} = props;

	let sectionBorderColor;
	if (section.pendingChanges) {
		sectionBorderColor = 'yellow';
	} else if (section.completed) {
		sectionBorderColor = 'green';
	} else {
		sectionBorderColor = 'red';
	}

	return (
		<Item id="Task-Section" className={`Task-Section ${sectionBorderColor}`}>
			<Item.Content>
				<Menu compact floated="right" size="mini">
					<Menu.Item
						className="pseudoDragHandler"
						onClick={() =>
							props.addTaskToTemplate({
								sectionId: section.id,
								tasksLength: section.tasks.general.length
							})
						}
					>
						<Icon name="plus" className="pseudoDragHandler" />
					</Menu.Item>

					<Menu.Item onClick={() => props.editTask(section)}>
						<Icon name="edit" />
					</Menu.Item>

					<Popup
						size="mini"
						trigger={
							<Menu.Item className="taskCardRightButton" icon style={{cursor: 'move'}}>
								<Icon name="sort" />
							</Menu.Item>
						}
						content="Click and drag to re-order task."
					/>
				</Menu>
				<Item.Header className="section-header">
					<Icon name="object group" />
					{section.title}
				</Item.Header>

				<TaskList
					pinned={section.tasks.pinned}
					general={section.tasks.general}
					pressDelay={100}
					onSortEnd={(e) => {
						props.updateTaskTemplateOrder(arrayMove(section.tasks.general, e.oldIndex, e.newIndex));
					}}
					lockAxis="y"
				/>
			</Item.Content>
		</Item>
	);
});

const mapDispatchToProps = (dispatch) => ({
	addTaskToTemplate: (options) => dispatch(addTaskToTemplate(options)),
	editTask: (task) => dispatch(editTask(task)),
	updateTaskTemplateOrder: (updatedTaskList) => dispatch(updateTaskTemplateOrder(updatedTaskList))
});

export default connect(null, mapDispatchToProps)(Section);
