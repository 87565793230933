import React from 'react';
import {connect} from 'react-redux';
import {Menu, Dropdown, Label, Popup, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import * as actions from '../../../services/workRecordActions.js';

class TripsDropdown extends React.Component {
	render() {
		if (this.props.manageAASDI.aasdis.length > 0) {
			return (
				<Menu compact style={{margin: '1.5vh 1vh 1.5vh 0', width: '20vw'}}>
					<Label size="large" horizontal content="Selected Trip" />
					<Dropdown
						text={
							this.props.noTrip ? (
								'No Trips'
							) : this.props.manageAASDI.infoSection[0].sections[0].loading ? (
								'Loading...'
							) : this.props.manageAASDI.infoSection[0].sections[0].fields[1].value &&
							  this.props.manageAASDI.infoSection[0].sections[0].fields[1].value.length < 30 ? (
								this.props.manageAASDI.infoSection[0].sections[0].fields[1].value
							) : (
								<Popup
									trigger={
										<div>
											{this.props.manageAASDI.infoSection[0].sections[0].fields[1].value &&
											this.props.manageAASDI.infoSection[0].sections[0].fields[1].value.length
												? this.props.manageAASDI.infoSection[0].sections[0].fields[1].value.substr(
														0,
														27
												  )
												: null}
											...
										</div>
									}
									content={this.props.manageAASDI.infoSection[0].sections[0].fields[1].value}
								/>
							)
						}
						className="hiddenInput"
						fluid
						style={{padding: '1.25vh 1vw 1vh 1vw'}}
					>
						<Dropdown.Menu style={{width: '15vw'}}>
							<Button
								icon="plus"
								content="Add Trip"
								color="blue"
								basic
								fluid
								onClick={() => {
									this.props.toggleCreateTripModal(true);
								}}
							/>
							{this.props.trips.map((trip, index) => {
								return (
									<Dropdown.Item
										key={index}
										value={trip.id}
										onClick={() => {
											this.props.getWorkViewTrip(trip.id);
										}}
									>
										{trip.name.length < 30 ? trip.name : `${trip.name.substr(0, 27)}...`}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</Menu>
			);
		} else {
			return null;
		}
	}
}

TripsDropdown.propTypes = {
	manageAASDI: PropTypes.object,
	noTrip: PropTypes.bool,
	getWorkViewTrip: PropTypes.func,
	trips: PropTypes.array,
	toggleCreateTripModal: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		manageAASDI: state.work.workRecord.view.manageAASDI,
		id: state.work.workRecord.view.id,
		noTrip: state.work.workRecord.view.manageAASDI.noTrip,
		trips: state.work.workRecord.view.manageAASDI.tripOptions
	};
};

export default connect(mapStateToProps, actions)(TripsDropdown);
