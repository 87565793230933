import {Button, Icon, Popup} from 'semantic-ui-react';
//import PropTypes from 'prop-types';
import React from 'react';

const CompleteTaskButton = (props) => (
	<Popup
		size="mini"
		trigger={
			<Button
				{...props}
				icon
				// loading={props.tasks.edit.loading}
				type="button"
				size="small"
				onClick={() => props.toggleCompleteTaskModal()}
			>
				<Icon name={'checkmark'} color={'green'} />
			</Button>
		}
		content={'Mark this task as completed.'}
	/>
);

export default CompleteTaskButton;
