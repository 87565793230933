import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {Icon, Popup} from 'semantic-ui-react';

// Services
import * as actions from '../../../../../../../services/accountingConsoleActions.js';

const PDFButton = (props) => {
	return (
		<div>
			{props.record.type === 'Invoice' ? (
				<div>
					<Popup
						trigger={
							<Icon
								color="blue"
								name="file pdf outline"
								style={{
									backgroundColor: 'transparent',
									fontSize: '1.6em',
									cursor: 'pointer'
								}}
								onClick={async () => {
									await props.getInvoiceInfo(props.record);
								}}
							/>
						}
						position="right center"
						content="Open Invoice PDF"
					/>
				</div>
			) : null}
		</div>
	);
};

PDFButton.propTypes = {
	record: PropTypes.object,
	getInvoiceInfo: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		workOverview: state.accountingConsoleReducer.accountingConsole.selectedCategory.name
	};
};

export default connect(mapStateToProps, actions)(PDFButton);
