import React from 'react';
import {connect} from 'react-redux';
import {Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {removeAasdiViewDocument} from '../services/profileAasdiGridActions.js';

const AasdiDocumentSectionRemoveButton = (props) => (
	<div style={{paddingTop: '.15em'}}>
		<Icon
			name="cancel"
			color="red"
			size="large"
			style={{cursor: 'pointer'}}
			onClick={() => {
				props.removeAasdiViewDocument(
					props.rowIndex,
					props.data.documentId,
					props.data.aasdiId,
					props.data.clientId
				);
			}}
		/>
	</div>
);

AasdiDocumentSectionRemoveButton.propTypes = {
	rowIndex: PropTypes.number,
	removeAasdiViewDocument: PropTypes.func,
	data: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeAasdiViewDocument: (index, documentId, aasdiId, clientId) => {
			dispatch(removeAasdiViewDocument(index, documentId, aasdiId, clientId));
		}
	};
};

export default connect(null, mapDispatchToProps)(AasdiDocumentSectionRemoveButton);
