import {Button, Icon, Modal, Header, Popup, Menu, Dropdown} from 'semantic-ui-react';
import {
	selectTemplatesForDropdown,
	selectTemplatesBeingRetrieved,
	selectTemplatesLoading
} from '../services/TaskTemplates.selectors.js';
import {retrieveTemplates, addTasksFromTemplate} from '../services/TaskTemplates.actions.js';
import React, {useState} from 'react';
import {connect} from 'react-redux';

import * as taskTemplateService from '../services/TaskTemplates.service.js';

const AddTemplateTasksModal = (props) => {
	taskTemplateService.retrieveTemplatesIfNeeded(props.templatesBeingRetrieved, props.retrieveTemplates);

	const [templateId, setTemplateId] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);

	let modalContent;
	if (props.templatesLoading) {
		modalContent = (
			<span>
				<Icon name="circle notched" size="large" loading />
				Loading templates...
			</span>
		);
	} else {
		modalContent = (
			<Dropdown
				placeholder="Select template"
				fluid
				search
				selection
				options={props.templates}
				onChange={(e, {value}) => setTemplateId(value)}
			/>
		);
	}

	return [
		<Popup
			size="mini"
			trigger={
				<Menu.Item onClick={() => setModalOpen(true)}>
					<Icon name="list layout" />
					Add Template
				</Menu.Item>
			}
			content="Add template"
		/>,
		<Modal size="small" open={modalOpen}>
			<Header>Add tasks from template</Header>
			<Modal.Content style={{height: '45vh'}} scrolling>
				{modalContent}
			</Modal.Content>
			,
			<Modal.Actions>
				<Button onClick={() => setModalOpen(false)}>Cancel</Button>
				<Button
					onClick={() => {
						props.addTasksFromTemplate(templateId, props.group, {
							aasdiIndex: props.aasdiIndex,
							groupIndex: props.groupIndex
						});
						setModalOpen(false);
					}}
					color="blue"
				>
					Add Tasks
				</Button>
			</Modal.Actions>
		</Modal>
	];
};

const mapStateToProps = (state) => ({
	templates: selectTemplatesForDropdown(state),
	templatesBeingRetrieved: selectTemplatesBeingRetrieved(state),
	templatesLoading: selectTemplatesLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
	retrieveTemplates: () => dispatch(retrieveTemplates()),
	addTasksFromTemplate: (templateId, group, options) => dispatch(addTasksFromTemplate(templateId, group, options))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplateTasksModal);
