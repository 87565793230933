let taskTypeFactory = (taskList) => {
	return taskList
		.filter((type) => type.active === true)
		.map((taskType) => {
			return {
				key: taskType.id,
				value: taskType.taskName,
				text: taskType.taskName
			};
		});
};
export default taskTypeFactory;
