import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';

class ProjectCostWOAASDIGrid extends React.Component {
	defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true
	};

	columnDefs = [
		{
			headerName: 'AASDI',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'PO #',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Invoice #',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Amount',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Notes',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'File',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Status',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Submitted',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Approved',
			field: '',
			filter: 'agTextColumnFilter'
		}
	];

	sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true,
					suppressPivots: true,
					suppressPivotMode: true
				}
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		],
		position: 'right'
	};

	onQuickFilterChanged(value) {
		this.gridApi.setQuickFilter(value);
	}

	exportHandler(value) {
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				return params.value;
			}
		};
		this.gridApi.exportDataAsExcel(params);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	render() {
		return (
			<div className="fixGridMargin">
				<div
					className="ag-theme-balham"
					style={{
						height: '20vh',
						width: '100%'
					}}
				>
					<AgGridReact
						animateRows={true}
						modules={AllModules}
						suppressRowClickSelection={false}
						suppressDragLeaveHidesColumns={true}
						suppressContextMenu={true}
						suppressMultiRangeSelection={true}
						onGridReady={this.onGridReady}
						columnDefs={this.columnDefs}
						defaultColDef={this.defaultColDef}
						sideBar={this.sideBar}
						rowData={this.props.AASDIIRowData}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		AASDIIRowData: state.work.workRecord.view.ProjectCosting.projectCostingWO.AASDIInvoicesGrid.rowData
	};
};
export default connect(mapStateToProps, null)(ProjectCostWOAASDIGrid);
