import React from 'react';
import moment from 'moment';
import {MomentDatePicker} from '../../../../../components/inputs/DatePicker.jsx';
import {Label, Popup} from 'semantic-ui-react';
import {updateWorkField} from '../scenes/workRecordView/services/workRecordActions.js';
import {connect} from 'react-redux';

const dateInputRenderer = (props) => {
	return (
		<div>
			<Popup
				trigger={
					<Label
						style={{
							width: '100%',
							backgroundColor: 'transparent'
						}}
					>
						{props.value ? moment(props.value, 'MM-DD-YYYY').format('MM-DD-YYYY') : 'Pick Date'}
					</Label>
				}
				on={['click']}
				flowing
			>
				<MomentDatePicker
					inline
					dateFormat="MM-dd-yyyy"
					selected={props.value ? moment(props.value, 'MM-DD-YYYY') : null}
					onChange={(date) => {
						const rowNode = props.api.getRowNode(props.node.id);
						const rowData = rowNode.data;
						const formattedDate = moment(date, 'MM-DD-YYYY', true);
						// this can be condensed by a lot, but not now
						if (props.colDef.field === 'startDate') {
							const endDate = moment(rowData.endDate, 'MM-DD-YYYY');

							if (moment(formattedDate, 'MM-DD-YYYY').isSameOrBefore(endDate)) {
								props.updateWorkField(
									rowData.opportunityId ? rowData.opportunityId : rowData.id,
									'startDate',
									formattedDate,
									null,
									Boolean(rowData.opportunityId),
									props.node.id
								);
								rowNode.setDataValue('startDate', formattedDate);
							}
						}

						if (props.colDef.field === 'endDate') {
							const startDate = moment(rowData.startDate, 'MM-DD-YYYY');

							if (moment(formattedDate, 'MM-DD-YYYY').isSameOrAfter(startDate)) {
								props.updateWorkField(
									rowData.opportunityId ? rowData.opportunityId : rowData.id,
									'endDate',
									formattedDate,
									null,
									Boolean(rowData.opportunityId),
									props.node.id
								);
								rowNode.setDataValue('endDate', formattedDate);
							}
						}
						return;
					}}
				/>
			</Popup>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateWorkField: (id, name, value, quotedJobNumber, isOpp, nodeIndex) => {
			dispatch(updateWorkField(id, name, value, quotedJobNumber, isOpp, nodeIndex));
		}
	};
};

export default connect(null, mapDispatchToProps)(dateInputRenderer);
