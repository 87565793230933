import React, {Component} from 'react';
import {Header, Icon, Input, Grid, Dropdown, Label, Popup, Button} from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import industryOptions from '../../../../../../../constants/industries.js';
import leadSourceOptions from '../../../../../../../constants/leadTypes.js';
import {updateOpportunityColumn, clientUpdated} from '../services/opportunityActions.js';
import {
	toggleCommitModal,
	setOpportunityData
} from '../../../../../../../components/commitModal/services/commitModalActions.js';
import {MomentDatePicker} from '../../../../../../../components/inputs/DatePicker.jsx';

const typeOptions = [
	{
		key: 1,
		text: 'New',
		value: 'New'
	},
	{
		key: 2,
		text: 'Existing',
		value: 'Existing'
	}
];
class OppInfo extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			id,
			projectedOrderDate,
			bidDueDate,
			serviceType,
			revenue,
			updateOpportunityColumn,
			validation,
			industryId,
			leadSourceId,
			type,
			isCommit,
			creation
		} = this.props;
		const typeValue = typeOptions.find((option) => option.text === type);
		const canDecommit = this.props.role === 1 || this.props.role === 2;

		return (
			<Grid columns={'equal'} key="opp-options">
				<Grid.Column>
					<Header size="tiny">
						Bid Due Date
						<Input fluid className="hiddenInput">
							<MomentDatePicker
								selected={bidDueDate ? moment(bidDueDate).utc() : null}
								placeholderText="Select a date"
								onChange={(date) =>
									updateOpportunityColumn(
										id,
										'bidDueDate',
										date ? date.format('MM-DD-YYYY').toString() : null
									)
								}
							/>
						</Input>
					</Header>
				</Grid.Column>
				<Grid.Column>
					<Header size="tiny">
						Award Date
						<Input fluid className={`hiddenInput ${this.props.isCommit && !creation ? 'disabled' : ''}`}>
							<MomentDatePicker
								selected={projectedOrderDate ? moment(projectedOrderDate).utc() : null}
								placeholderText="Select a date"
								disabled={this.props.isCommit && !creation}
								onChange={(date) =>
									updateOpportunityColumn(
										id,
										'projectedOrderDate',
										date ? date.format('MM-DD-YYYY').toString() : null
									)
								}
								minDate={bidDueDate ? moment(bidDueDate).utc() : null}
							/>
						</Input>
						{validation.projectedOrderDate ? (
							<Label basic color="red" pointing className="crm">
								Please insert award date greater than bid due date!
							</Label>
						) : null}
					</Header>
				</Grid.Column>
				<Grid.Column>
					<Header size="tiny">
						Service Type
						<Dropdown
							fluid
							className="hiddenInput"
							placeholder="Service Type"
							options={[
								{
									key: 1,
									text: 'Deploy',
									value: 1
								},
								{
									key: 2,
									text: 'Design',
									value: 2
								},
								{
									key: 3,
									text: 'Build',
									value: 3
								},
								{
									key: 4,
									text: 'Support',
									value: 4
								}
							]}
							value={serviceType}
							selectOnBlur={false}
							onChange={(e, {value}) => {
								if (e.type === 'blur') {
									return;
								}
								updateOpportunityColumn(id, 'serviceType', value);
							}}
						/>
						{validation.serviceType ? (
							<Label basic color="red" pointing className="crm">
								Select a service type!
							</Label>
						) : null}
					</Header>
				</Grid.Column>
				<Grid.Column>
					<Header size="tiny">
						Revenue
						<Header.Subheader>
							<div className={`ui fluid input hiddenInput disabled`}>
								<Input
									className="fixInputOpacity"
									style={{opacity: '1'}}
									value={revenue === null ? 0 : `$ ${revenue}`}
									disabled
								/>
							</div>
						</Header.Subheader>
					</Header>
				</Grid.Column>
				<Grid.Column>
					<Header size="tiny">
						Industry
						<Dropdown
							fluid
							className="hiddenInput"
							placeholder="Industry"
							search
							options={industryOptions}
							value={industryId}
							onChange={(e, {value}) => {
								if (e.type === 'blur') {
									return;
								}
								updateOpportunityColumn(id, 'industryId', value);
							}}
						/>
					</Header>
				</Grid.Column>
				<Grid.Column>
					<Header size="tiny">
						Lead Source
						<Dropdown
							fluid
							className="hiddenInput"
							placeholder="Lead Source"
							search
							options={leadSourceOptions}
							value={leadSourceId}
							onChange={(e, {value}) => {
								if (e.type === 'blur') {
									return;
								}
								updateOpportunityColumn(id, 'leadSourceId', value);
							}}
						/>
					</Header>
				</Grid.Column>
				<Grid.Column>
					<Header size="tiny">
						Type
						<Dropdown
							fluid
							className="hiddenInput"
							placeholder="Type"
							options={typeOptions}
							value={typeValue ? typeValue.value : null}
							onChange={(e, {value}) => updateOpportunityColumn(id, 'type', value)}
						/>
					</Header>
				</Grid.Column>
				{!creation ? ( // this removes the commit button from the opportunity creation page
					<Grid.Column>
						<Header size="tiny">
							<Header.Content>
								{isCommit ? (
									<Popup
										trigger={
											<div>
												<Button
													onClick={() => {
														this.props.toggleCommitModal();
														this.props.setOpportunityData(this.props.oppData);
													}}
													style={{
														textAlign: 'center',
														fontSize: '1em',
														marginTop: '.5em'
													}}
													basic
													color="blue"
													disabled={!canDecommit}
												>
													<Icon
														color="red"
														name="history"
														// style={{ marginLeft: '0.5em' }}
													/>
													Decommit
												</Button>
											</div>
										}
										on={['hover', 'click']}
										hoverable
										position="top center"
										content={
											!canDecommit
												? 'Only accounting and executive users can decommit an opportunity'
												: 'Decommit'
										}
									/>
								) : (
									<Popup
										trigger={
											<Button
												onClick={() => {
													this.props.toggleCommitModal();
													this.props.setOpportunityData(this.props.oppData);
												}}
												style={{
													textAlign: 'center',
													fontSize: '1em',
													marginTop: '.5em'
												}}
												basic
												color="blue"
											>
												<Icon color="purple" name="history" />
												Commit
											</Button>
										}
										on={['hover', 'click']}
										flowing
										hoverable
										position="top center"
										content="Commit"
									/>
								)}
							</Header.Content>
						</Header>
					</Grid.Column>
				) : (
					<></>
				)}
			</Grid>
		);
	}
}

OppInfo.propTypes = {
	id: PropTypes.string,
	projectedOrderDate: PropTypes.string,
	bidDueDate: PropTypes.string,
	serviceType: PropTypes.string,
	serviceTypeName: PropTypes.string,
	revenue: PropTypes.string,
	updateOpportunityColumn: PropTypes.func,
	validation: PropTypes.object,
	clientSearch: PropTypes.string,
	clientUpdated: PropTypes.func,
	createClientModalUpdate: PropTypes.func,
	industryId: PropTypes.number,
	leadSourceId: PropTypes.number,
	type: PropTypes.string,
	isCommit: PropTypes.bool,
	creation: PropTypes.bool,
	oppData: PropTypes.object,
	toggleCommitModal: PropTypes.func,
	setOpportunityData: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		id: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.id : null,
		projectedOrderDate: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.projectedOrderDate : null,
		bidDueDate: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.bidDueDate : null,
		serviceType: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.serviceType : null,
		industryId: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.industryId : null,
		leadSourceId: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.leadSourceId : null,
		revenue: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.revenue : null,
		type: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.type : null,
		fetched: state.crm.opportunity.data.fetched,
		validation: state.crm.opportunity.data.validation,
		createClientModalUpdate: state.work.clientGrid.createClientModalUpdate,
		isCommit: state.crm.opportunity.data.isCommit,
		newOpp: state.crm.opportunity.data.newOpp,
		oppData: state.crm.opportunity.data,
		role: state.auth.account.roleType
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateOpportunityColumn: (id, column, value) => {
			dispatch(updateOpportunityColumn(id, column, value));
		},

		clientUpdated: (clientInfo, operationId) => {
			dispatch(clientUpdated(clientInfo, operationId));
		},
		toggleCommitModal: () => {
			dispatch(toggleCommitModal());
		},
		setOpportunityData: (oppData, value) => {
			dispatch(setOpportunityData(oppData, value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OppInfo);
