// Modules
import React, {Component} from 'react';
import {Button, Modal, Icon, Header, Input, Label, Dropdown, Grid, Segment, Message} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import PropTypes from 'prop-types';
import aasdiSearchExpiredRenderer from '../../../../../../CreateWorkOrder/components/workOrderAASDI/components/workOrderAASDIModal/components/aasdiSearchExpiredRenderer.js';
import * as actions from '../../../services/workRecordActions.js';

class AASDIModal extends Component {
	constructor(props) {
		super(props);
		this.state = {expiredSelected: false};
	}
	render() {
		return (
			<Modal
				size="fullscreen"
				open={this.props.modal.toggle}
				onMount={() => {
					if (this.props.zip) {
						this.props.getWorkViewAASDI(
							this.props.zip,
							this.props.modal.search.radius,
							this.props.modal.search.name,
							this.props.aasdiArray
						);
						this.props.aasdiZipSearch(this.props.zip);
					}
				}}
			>
				<Header>
					<Icon name="plus" color="green" />
					<div className="content">Add AASDI</div>
				</Header>
				<Modal.Content
					style={{
						marginTop: '0'
					}}
				>
					<div>
						<Grid>
							<Grid.Row>
								<Grid.Column width={4}>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Zip
										</Label>
									</div>
									<DebounceInput
										element={Input}
										debounceTimeout={600}
										value={this.props.modal.search.zip}
										onChange={(e) => {
											this.props.aasdiZipSearch(e.target.value.trim());
										}}
										onKeyPress={(e) => {
											if (e.charCode === 13) {
												if (e.target.value.length >= 3) {
													this.props.aasdiZipSearch(e.target.value.trim());
													this.props.getWorkViewAASDI(
														e.target.value,
														this.props.modal.search.radius,
														this.props.modal.search.name,
														this.props.aasdiArray
													);
												} else {
													this.props.toggleWorkViewZipError(true);
												}
											}
										}}
										style={{width: '100%'}}
									/>
									{this.props.modal.search.zipError ? (
										<Label color="red" pointing="above">
											Invalid zip code
										</Label>
									) : null}
								</Grid.Column>
								<Grid.Column width={4}>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Radius
										</Label>
									</div>
									<Dropdown
										search
										selection
										scrolling
										compact
										options={this.props.modal.radiusOptions}
										text={this.props.modal.search.radius}
										onChange={(e, {value}) => {
											this.props.aasdiRadiusSearch(value);
										}}
										style={{width: '100%'}}
									/>
								</Grid.Column>
								<Grid.Column width={4}>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Name
										</Label>
									</div>
									<DebounceInput
										element={Input}
										debounceTimeout={600}
										onChange={(e) => {
											this.props.aasdiNameSearch(e.target.value);
										}}
										onKeyPress={(e) => {
											if (e.charCode === 13) {
												this.props.aasdiNameSearch(e.target.value);
												this.props.getWorkViewAASDI(
													this.props.modal.search.zip,
													this.props.modal.search.radius,
													this.props.modal.search.name,
													this.props.aasdiArray
												);
											}
										}}
										style={{width: '100%'}}
									/>
								</Grid.Column>
								<Grid.Column width={4}>
									<Button
										fluid
										color="blue"
										style={{
											marginTop: '3rem'
										}}
										onClick={() => {
											if (this.props.modal.search.zip) {
												this.props.getWorkViewAASDI(
													this.props.modal.search.zip,
													this.props.modal.search.radius,
													this.props.modal.search.name,
													this.props.aasdiArray
												);
											} else {
												this.props.toggleWorkViewZipError(true);
											}
										}}
									>
										<Icon
											name="search"
											style={{
												marginRight: '1rem'
											}}
										/>
										Search
									</Button>
								</Grid.Column>
							</Grid.Row>
						</Grid>

						<Segment basic loading={this.props.modal.search.loading} style={{width: '100%', padding: 0}}>
							<div
								className="ag-theme-balham"
								style={{
									height: '40vh',
									width: '100%'
								}}
							>
								<AgGridReact
									defaultColDef={{
										sortable: true,
										filter: true,
										resizable: true
									}}
									columnDefs={[
										{
											headerName: 'Distance',
											field: 'distance',
											sort: 'asc'
										},

										{
											headerName: 'AASDI ID',
											field: 'aasdiId'
										},
										{
											headerName: 'Name',
											field: 'name',
											cellRendererFramework: aasdiSearchExpiredRenderer,
											onCellClicked: (params) => {
												const profileLink = window.open(
													`/work/dashboard/aasdi/profile/${params.data.aasdiId}`
												);
												return profileLink;
											}
										},
										{
											headerName: 'Address',
											field: 'address'
										},
										{
											headerName: 'Address2',
											field: 'address2'
										},
										{headerName: 'City', field: 'city'},
										{headerName: 'State', field: 'state'},
										{headerName: 'Zip', field: 'zip'},
										{
											headerName: 'Country',
											field: 'countryName'
										}
									]}
									modules={AllModules}
									onGridReady={(params) => {
										this.gridApi = params.api;
										this.columnApi = params.columnApi;
										this.gridApi.sizeColumnsToFit();
									}}
									rowSelection="single"
									rowData={this.props.modal.search.results}
								/>
							</div>
						</Segment>
					</div>
				</Modal.Content>
				<Modal.Actions>
					{this.state.expiredSelected ? (
						<Message negative compact style={{marginRight: '2vw'}}>
							AASDIs with expired insurance can't be selected
						</Message>
					) : null}
					<Button.Group>
						<Button
							color="red"
							onClick={() => {
								this.props.toggleAASDIModal(false);
							}}
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							color="green"
							onClick={() => {
								let record = this.gridApi.getSelectedRows();

								if (!record[0].expired) {
									this.setState({expiredSelected: false});
									this.props.addAASDI(this.gridApi.getSelectedRows(), this.props.workId);
								} else {
									this.setState({expiredSelected: true});
								}
							}}
						>
							<Icon name="checkmark" /> Select
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

AASDIModal.propTypes = {
	workId: PropTypes.number,
	modal: PropTypes.object,
	addAASDI: PropTypes.func,
	aasdiNameSearch: PropTypes.func,
	toggleAASDIModal: PropTypes.func,
	aasdiRadiusSearch: PropTypes.func,
	getWorkViewAASDI: PropTypes.func,
	aasdiZipSearch: PropTypes.func,
	aasdiArray: PropTypes.array,
	zip: PropTypes.string,
	toggleWorkViewZipError: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		modal: state.work.workRecord.view.manageAASDI.modal,
		workId: state.work.workRecord.view.id,
		aasdiArray: state.work.workRecord.view.manageAASDI.aasdis
	};
};

export default connect(mapStateToProps, actions)(AASDIModal);
