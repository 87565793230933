import React from 'react';
import {connect} from 'react-redux';
import {Grid, Button, Icon, Label, Input, Dropdown, TextArea, Segment, Card} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import DocumentSectionCheckbox from './component/gridButtons/DocumentSectionCheckBox.js';
import DocumentSectionRemoveButton from './component/gridButtons/DocumentSectionRemoveButton.js';
import {updateDocumentValue, addDocument} from '../../../../services/opportunityActions.js';

class Documents extends React.Component {
	render() {
		const defaultColDef = {
			sortable: true,
			filter: true,
			resizable: true
		};
		return (
			<Card fluid>
				<Card.Content>
					<Label color="blue" ribbon>
						Documents
					</Label>
				</Card.Content>

				<Card.Content>
					<Grid>
						<Grid.Row>
							{this.props.sectionFields.map((field, fieldIndex) =>
								field.input ? (
									<Grid.Column width={field.columnWidth} key={fieldIndex}>
										<div>
											<div
												style={{
													paddingTop: '0.8em'
												}}
											>
												<Label
													style={{
														backgroundColor: 'transparent',
														fontSize: '1rem',
														color: 'rgba(0,0,0,.8)',
														paddingLeft: '0'
													}}
												>
													{field.name}
												</Label>
											</div>
											<DebounceInput
												element={Input}
												onChange={(e) => {
													this.props.updateDocumentValue(fieldIndex, e.target.value);
												}}
												debounceTimeout={600}
												value={field.value}
												style={{
													width: '100%'
												}}
												error={field.required || field.checkForm ? Boolean(field.error) : false}
												disabled={field.disabled}
												placeholder={field.placeholder}
											/>
										</div>
									</Grid.Column>
								) : field.dropdown ? (
									<Grid.Column width={field.columnWidth} key={fieldIndex}>
										<div
											style={{
												paddingTop: '.8em'
											}}
										>
											<Label
												style={{
													backgroundColor: 'transparent',
													fontSize: '1rem',
													color: 'rgba(0,0,0,.8)',
													paddingLeft: '0'
												}}
											>
												{field.name}
											</Label>
											<Dropdown
												className="createWorkOrderClientDropdown"
												placeholder={field.placeholder}
												fluid
												search
												selection
												options={field.options}
												onChange={(e, {value}) => {
													this.props.updateDocumentValue(fieldIndex, value);
												}}
												icon={field.icon}
												value={field.value}
												error={field.required ? field.error : false}
											/>
										</div>
									</Grid.Column>
								) : field.textArea ? (
									<Grid.Column width={field.columnWidth} key={fieldIndex}>
										<div
											style={{
												paddingTop: '0.8em',
												zIndex: '10'
											}}
										>
											<Label
												style={{
													backgroundColor: 'transparent',
													fontSize: '1rem',
													color: 'rgba(0,0,0,.8)',
													paddingLeft: '0'
												}}
											>
												{field.name}
											</Label>
											<DebounceInput
												className="scrollingTextArea"
												element={TextArea}
												onChange={(e) => {
													this.props.updateDocumentValue(fieldIndex, e.target.value);
												}}
												debounceTimeout={600}
												value={field.value}
												style={{
													width: '100%'
												}}
												error={field.required || field.checkForm ? Boolean(field.error) : false}
												disabled={field.disabled}
												placeholder={field.placeholder}
											/>
										</div>
									</Grid.Column>
								) : null
							)}
							<Grid.Column
								width={15}
								style={{
									paddingTop: '0.8em'
								}}
							>
								<Button
									primary
									onClick={() => {
										this.props.addDocument(
											this.props.sectionFields[0],
											this.props.sectionFields[1],
											this.props.sectionFields[2],
											this.props.sectionFields[3],
											this.props.oppId
										);
									}}
								>
									<Icon name="plus" />
									Add Document
								</Button>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={15}>
								<Segment loading={this.props.loadingDocs}>
									<div
										className="ag-theme-balham"
										style={{
											height: '20vh',
											width: '100%',
											textAlign: 'left',
											boxSizing: 'border-box'
										}}
									>
										<AgGridReact
											onGridReady={(params) => {
												this.gridApi = params.api;
												this.columnApi = params.columnApi;
												this.gridApi.sizeColumnsToFit();
											}}
											suppressHorizontalScroll={true}
											columnDefs={columnDefs}
											defaultColDef={defaultColDef}
											rowHeight={40}
											id="documentGrid"
											rowData={this.props.documentsData ? this.props.documentsData : null}
											enableStatusBar={true}
											alwaysShowStatusBar={false}
											enableRangeSelection={true}
											modules={AllModules}
											{...this.props}
										/>
									</div>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Card.Content>
			</Card>
		);
	}
}

const columnDefs = [
	{
		headerName: 'File Name',
		field: 'fileName',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Google Drive',
		field: 'googleDriveLink',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function (params) {
			return `<a href="${params.value}" target="_blank">${params.value}</a>`;
		}
	},
	{
		headerName: 'Comments',
		field: 'comments',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Customer Approval',
		field: 'customerApproval',
		cellStyle: {paddingTop: '.4em'},
		cellRendererFramework: DocumentSectionCheckbox
	},
	{
		headerName: 'Remove',
		cellStyle: {paddingTop: '.4em'},
		cellRendererFramework: DocumentSectionRemoveButton
	}
];

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.crm.opportunity.data.documents,
		sectionFields: state.crm.opportunity.data.documents.fields,
		documentsData: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.documents.documents : null,
		oppId: state.crm.opportunity.data.fetched ? state.crm.opportunity.data.id : null,
		loadingDocs: state.crm.opportunity.data.documents.loading
	};
};

const mapDispatchToProps = {
	updateDocumentValue: (index, value) => updateDocumentValue(index, value),
	addDocument: (fileName, googleDriveLink, customerApproval, comments, id) =>
		addDocument(fileName, googleDriveLink, customerApproval, comments, id)
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
