import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {AgGridReact} from '@ag-grid-community/react';
import '../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {Grid, Label, Input, Button, Icon, Menu, Segment, Popup, Checkbox} from 'semantic-ui-react';
import OrderTypeRenderer from './orderTypeRenderer.js';
import IconWithTooltip from '../../../../../components/icons/IconWithTooltip.jsx';
import closureCommentsCellRenderer from './closureCommentsCellRenderer.js';
import tagCellRenderer from '~/components/ag-grid/renderers/tagCellRenderer';
import aasdiCellRenderer from './aasdiCellRenderer.js';
import daysToCellRenderer from './daysToCellRenderer.js';
import {
	getWorkGrid,
	updateActiveMenuItem,
	updateWorkGridToggle,
	updateProjectionData
} from '../services/workGridActions.js';
import tripInfoRenderer from './tripInfoRenderer.js';
import dateInputRenderer from './dateInputRenderer.js';
import {updateWorkField} from '../scenes/workRecordView/services/workRecordActions.js';
import {calcRemainingToBill} from '../../../../../util/moneymath.ts';
import {fmtMoney} from '../../../../../util/formatting.js';

class WorkGrid extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeMenu: '',
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true
			},
			columnDefs: this.colDefsObj.mywork
		};
	}

	componentDidMount() {
		this.props.getWorkGrid(this.props.workGridMenu[this.props.activeTabIndex]);
	}

	activeMenu = this.props.workGridMenu[this.props.workGridMenu.findIndex((menu) => menu.active === true)].name;

	colDefsObj = {
		mywork: [
			{
				headerName: 'Type',
				field: 'action',
				cellStyle: {},
				cellRendererFramework: OrderTypeRenderer,
				width: 75,
				filter: false,
				getQuickFilterText: (params) => {
					// Filter based on project or work order
					return params.data.isProject ? 'Project' : 'Work Order';
				}
			},
			{
				headerName: 'Work Number',
				field: 'workNumber',
				filter: 'agTextColumnFilter',
				width: 120
			},
			{
				headerName: 'Status',
				field: 'status_Name',
				width: 150
			},
			{
				headerName: 'Update',
				field: 'activityStreamUpdate',
				filter: 'agTextColumnFilter',
				width: 85,
				cellRendererFramework: (params) => {
					return (
						params.valueFormatted && (
							<IconWithTooltip content={params.valueFormatted} color="black" name="bell outline" />
						)
					);
				},
				valueFormatter: (params) => {
					let update = params.value ? params.value.replace(/<(?:.|\n)*?>/gm, '').substring(0, 60) : '';
					if (update.length === 60) {
						update = `${update}...`;
					}
					return update;
				}
			},
			{
				headerName: 'Client',
				field: 'client_Name',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Service Type',
				field: 'serviceName',
				filter: 'agTextColumnFilter',
				width: 115,
				hide: true
			},
			{
				headerName: 'Store Number',
				field: 'location_StoreNumber',
				filter: 'agTextColumnFilter',
				width: 120
			},
			{
				headerName: 'City',
				field: 'location_City',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'State',
				field: 'location_State',
				filter: 'agTextColumnFilter',
				width: 75
			},
			{
				headerName: 'Created On',
				field: 'createdOn',
				width: 100,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				},
				hide: true
			},
			{
				headerName: 'Complete',
				field: 'workCompletedOn',
				width: 100,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				},
				hide: true
			},
			{
				headerName: 'AASDI',
				field: 'aasdiObj',
				cellRenderer: 'aasdiCellRenderer',
				width: 100
			},
			{
				headerName: 'Tags',
				field: 'tags',
				cellRenderer: 'TagCellRenderer',
				width: 100
			},
			{
				headerName: 'Project Manager',
				field: 'projectManager_Name',
				width: 150
			},
			{
				headerName: 'NDS',
				field: 'workManager_Name',
				width: 150
			},
			{
				headerName: 'Spectrum Code',
				field: 'spectrumCode',
				filter: 'agTextColumnFilter',
				width: 120
			},
			{
				headerName: 'Open Commitment',
				field: 'spectrumOpenCommit',
				width: 170,
				valueFormatter: ({value}) => fmtMoney(value)
			},
			{
				headerName: 'Estimated Revenue',
				field: 'spectrumEstimatedRevenue',
				filter: 'agNumberColumnFilter',
				valueFormatter: (params) => {
					return params.value ? fmtMoney(params.value) : `$0.00`;
				},
				width: 150
			},
			{
				headerName: 'Actual Revenue',
				field: 'spectrumActualRevenue',
				filter: 'agNumberColumnFilter',
				valueFormatter: (params) => {
					console.log('params: ', params);
					return params.value ? fmtMoney(params.value) : `$0.00`;
				},
				width: 150,
				hide: true
			},
			{
				headerName: 'Remaining to Bill',
				field: 'remainingToBill',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueGetter: (params) =>
					calcRemainingToBill(params.data.spectrumEstimatedRevenue, params.data.spectrumActualRevenue),
				valueFormatter: ({value}) => fmtMoney(value)
			},
			{
				headerName: 'Start Date',
				field: 'startDate',
				width: 150,
				filter: 'agDateColumnFilter',
				cellRenderer: 'dateInputRenderer',
				cellEditorParams: {
					cellRenderer: 'dateInputRenderer',
					column: 'startDate'
				},
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'End Date',
				field: 'endDate',
				filter: 'agDateColumnFilter',
				width: 150,
				cellRenderer: 'dateInputRenderer',
				cellEditorParams: {
					cellRenderer: 'dateInputRenderer',
					column: 'endDate'
				},
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Purchase Order Number',
				field: 'purchaseOrderNumber',
				filter: 'agTextColumnFilter',
				width: 200
			},
			{
				headerName: 'Expected Cost',
				field: 'estimatedTotalCost',
				filter: 'agNumberColumnFilter',
				valueFormatter: (params) => {
					return params.value ? `$${params.value}` : '$0';
				},
				width: 150,
				hide: true
			},
			{
				headerName: 'Expected GP%',
				field: 'estimatedGrossProfit',
				filter: 'agNumberColumnFilter',
				valueFormatter: (params) => {
					return params.value ? `%${params.value}` : '%0';
				},
				width: 150,
				hide: true
			},
			{
				headerName: 'Projection',
				field: 'projectedBilling',
				filter: 'agNumberColumnFilter',
				valueFormatter: (params) => {
					return params.value ? `$${params.value}` : '$0';
				},
				width: 150,
				editable: (params) => params.data.commitBillingId > 0
			},
			{
				headerName: 'Quoted Job Number',
				field: 'quotedJobNumber',
				width: 120
			}
		],
		support: [
			{
				headerName: 'Type',
				field: 'action',
				cellStyle: {},
				cellRendererFramework: OrderTypeRenderer,
				width: 75,
				filter: false,
				getQuickFilterText: (params) => {
					// Filter based on project or work order
					return params.data.isProject ? 'Project' : 'Work Order';
				}
			},
			{
				headerName: 'Work Number',
				field: 'workNumber',
				filter: 'agTextColumnFilter',
				width: 150,
				aggFunc: 'count'
			},
			{
				headerName: 'Status',
				field: 'status_Name',
				filter: 'agTextColumnFilter',
				width: 150,
				cellStyle: (params) => {
					let today = new Date().toISOString();

					let color = 'whitesmoke';

					if (params.data.requestedToCloseDate) {
						color = params.data.daysToClose?.color;
					} else if (params.data.invoicedDate) {
						color = params.data.daysToInvoice?.color;
					} else if (params.data.completedOn) {
						color = params.data.daysToComplete?.color;
					} else if (params.data.actualDate) {
						color = params.data.daysToSchedule?.color;
					} else {
						if (moment(today).diff(moment(params.data.dateReceived || params.data.createdOn), 'days') > 1) {
							color = 'gold';
						}
					}

					return {backgroundColor: color};
				},
				pivot: true,
				enablePivot: true
			},
			{
				headerName: 'NDS',
				field: 'workManager_Name',
				filter: 'agTextColumnFilter',
				width: 150,
				rowGroup: false,
				enableRowGroup: true
			},
			{
				headerName: 'Support Type',
				field: 'serviceSubName',
				filter: 'agTextColumnFilter',
				width: 200,
				rowGroup: false,
				enableRowGroup: true
			},
			{
				headerName: 'Client',
				field: 'client_Name',
				filter: 'agTextColumnFilter',
				width: 150,
				rowGroup: false,
				enableRowGroup: true
			},
			{
				headerName: 'Tags',
				field: 'tags',
				cellRenderer: 'TagCellRenderer',
				width: 100
			},
			{
				headerName: 'Location',
				field: 'location_Name',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'City',
				field: 'location_City',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'State',
				field: 'location_State',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'AASDI',
				field: 'aasdiObj',
				cellRenderer: 'aasdiCellRenderer',
				width: 100
			},
			{
				headerName: 'Update',
				field: 'activityStreamUpdate',
				filter: 'agTextColumnFilter',
				width: 100,
				cellRendererFramework: (params) => {
					return (
						params.valueFormatted && (
							<IconWithTooltip content={params.valueFormatted} color="black" name="bell outline" />
						)
					);
				},
				valueFormatter: (params) => {
					let update = params.value ? params.value.replace(/<(?:.|\n)*?>/gm, '').substring(0, 60) : '';
					if (update.length === 60) {
						update = `${update}...`;
					}
					return update;
				}
			},
			{
				headerName: 'Date Received',
				field: 'dateReceived',
				valueFormatter: (data) => {
					return data.value
						? moment(data.value).format('MM-DD-YYYY HH:mm')
						: moment(data.data.createdOn).format('MM-DD-YYYY HH:mm');
				},
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Scheduled Date',
				field: 'actualDate',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY h:mm a') : null;
				},
				cellStyle: (params) => {
					if (params.data.daysToSchedule?.color === 'red') {
						return {color: 'red'};
					}
				},
				filter: 'agTextColumnFilter',
				width: 200
			},
			{
				headerName: 'Schedule Time',
				field: 'daysToSchedule',
				valueFormatter: (params) => {
					let days = params.data.daysToSchedule?.number;
					if (isNaN(days)) {
						return 'Not Scheduled';
					}
					return days.toString() + ' ' + (days !== 1 ? 'days' : 'day');
				},
				cellStyle: (params) => {
					if (!isNaN(params.data.daysToSchedule?.number)) {
						return {backgroundColor: params.data.daysToSchedule?.color};
					}
					return {backgroundColor: 'whitesmoke', color: 'lightgray'};
				}
			},
			{
				headerName: 'Completion Date',
				field: 'workCompletedOn',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY h:mm a') : null;
				},
				cellStyle: (params) => {
					if (params.data.daysToComplete?.color === 'red') {
						return {color: 'red'};
					}
				},
				hide: false,
				width: 200
			},
			{
				headerName: 'Completion Time',
				field: 'daysToComplete',
				valueFormatter: (params) => {
					let days = params.data.daysToComplete?.number;
					if (isNaN(days)) {
						return 'Not Completed';
					}
					return days.toString() + ' ' + (days !== 1 ? 'days' : 'day');
				},
				cellStyle: (params) => {
					if (!isNaN(params.data.daysToComplete?.number)) {
						return {backgroundColor: params.data.daysToComplete?.color};
					}
					return {backgroundColor: 'whitesmoke', color: 'lightgray'};
				}
			},
			{
				headerName: 'Invoice Date',
				field: 'invoiceDate',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY h:mm a') : null;
				},
				cellStyle: (params) => {
					if (params.data.daysToInvoice?.color === 'red') {
						return {color: 'red'};
					}
				},
				width: 200
			},
			{
				headerName: 'Invoicing Time',
				field: 'daysToInvoice',
				valueFormatter: (params) => {
					let days = params.data.daysToInvoice?.number;
					if (isNaN(days)) {
						return 'Not Invoiced';
					}
					return days.toString() + ' ' + (days !== 1 ? 'days' : 'day');
				},
				cellStyle: (params) => {
					if (!isNaN(params.data.daysToInvoice?.number)) {
						return {backgroundColor: params.data.daysToInvoice?.color};
					}
					return {backgroundColor: 'whitesmoke', color: 'lightgray'};
				}
			},
			{
				headerName: 'Close Date',
				field: 'requestedToCloseDate',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY h:mm a') : null;
				},
				cellStyle: (params) => {
					if (params.data.daysToClose?.color === 'red') {
						return {color: 'red'};
					}
				},
				width: 200
			},
			{
				headerName: 'Closing Time',
				field: 'daysToClose',
				valueFormatter: (params) => {
					let days = params.data.daysToClose?.number;
					if (isNaN(days)) {
						return 'Not Closed';
					}
					return days.toString() + ' ' + (days !== 1 ? 'days' : 'day');
				},
				cellStyle: (params) => {
					if (!isNaN(params.data.daysToClose?.number)) {
						return {backgroundColor: params.data.daysToClose?.color};
					}
					return {backgroundColor: 'whitesmoke', color: 'lightgray'};
				}
			},
			{
				headerName: 'Revisit Schedule Date',
				field: 'revisitScheduleDate',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY h:mm a') : null;
				}
			}
		],
		history: [
			{
				headerName: 'Type',
				field: 'action',
				cellStyle: {},
				cellRendererFramework: OrderTypeRenderer,
				maxWidth: 75,
				filter: false,
				getQuickFilterText: (params) => {
					// Filter based on project or work order
					return params.data.isProject ? 'Project' : 'Work Order';
				}
			},
			{
				headerName: 'Work Number',
				field: 'workNumber',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Purchase Order Number',
				field: 'purchaseOrderNumber',
				filter: 'agTextColumnFilter',
				width: 200
			},
			{
				headerName: 'Client',
				field: 'client_Name',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Tags',
				field: 'tags',
				cellRenderer: 'TagCellRenderer',
				width: 100
			},
			{
				headerName: 'Spectrum Code',
				field: 'spectrumCode',
				filter: 'agTextColumnFilter',
				width: 120
			},
			{
				headerName: 'Next Scheduled Trip',
				field: 'aasdiObj',
				filter: 'agTextColumnFilter',
				cellRenderer: 'tripInfoRenderer',
				hide: this.activeMenu !== 'Reports',
				width: 175
			},
			{
				headerName: 'Status',
				field: 'status_Name',
				width: 150
			},
			{
				headerName: 'Update',
				field: 'activityStreamUpdate',
				filter: 'agTextColumnFilter',
				width: 85,
				cellRendererFramework: (params) => {
					return (
						params.valueFormatted && (
							<IconWithTooltip content={params.valueFormatted} color="black" name="bell outline" />
						)
					);
				},
				valueFormatter: (params) => {
					let update = params.value ? params.value.replace(/<(?:.|\n)*?>/gm, '').substring(0, 60) : '';
					if (update.length === 60) {
						update = `${update}...`;
					}
					return update;
				}
			},
			{
				headerName: 'Time',
				field: 'tripDate',
				filter: 'agTextColumnFilter',
				width: 150,
				valueFormatter: (data) => (data.value ? moment(data.value).format('hh:mm a') : null)
			},
			{
				headerName: 'City',
				field: 'location_City',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'State',
				field: 'location_State',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Store Number',
				field: 'location_StoreNumber',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'AASDI',
				field: 'aasdiObj',
				cellRenderer: 'aasdiCellRenderer',
				width: 100
			},
			{
				headerName: 'Service Type',
				field: 'serviceName',
				filter: 'agTextColumnFilter',
				width: 150,
				hide: true
			},
			{
				headerName: 'Quoted Job Number',
				field: 'quotedJobNumber',
				filter: 'agTextColumnFilter',
				width: 175
			},
			{
				headerName: 'Closure Comments',
				field: 'closureComments',
				cellRenderer: 'closureCommentsCellRenderer',
				width: 110
			},
			{
				headerName: 'NDS',
				field: 'workManager_Name',
				width: 150
			},
			{
				headerName: 'Project Manager',
				field: 'projectManager_Name',
				width: 150
			},
			{
				headerName: 'Program Manager',
				field: 'accountManager_Name',
				width: 150
			},
			{
				headerName: 'Estimated Revenue',
				field: 'spectrumEstimatedRevenue',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueFormatter: (params) => {
					return params.value ? fmtMoney(params.value) : `$0.00`;
				}
			},
			{
				headerName: 'Remaining to Bill',
				field: 'remainingToBill',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueGetter: (params) =>
					calcRemainingToBill(params.data.spectrumEstimatedRevenue, params.data.spectrumActualRevenue),
				valueFormatter: ({value}) => fmtMoney(value)
			},
			{
				headerName: 'Start Date',
				field: 'startDate',
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'End Date',
				field: 'endDate',
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Expected Cost',
				field: 'estimatedTotalCost',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueFormatter: (params) => {
					return params.value ? `$${params.value}` : '$0';
				},
				hide: true
			},
			{
				headerName: 'Expected GP%',
				field: 'estimatedGrossProfit',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueFormatter: (params) => {
					return params.value ? `$${params.value}` : '$0';
				},
				hide: true
			},
			{
				headerName: 'Actual Revenue',
				field: 'spectrumActualRevenue',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueFormatter: (params) => {
					return params.value ? fmtMoney(params.value) : `$0.00`;
				}
			},
			{
				headerName: 'Actual Cost',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueGetter: (params) => {
					const actualCost = params.data.spectrumActualCost ? params.data.spectrumActualCost : 0;
					const actualChangeOrderCost = params.data.spectrumActualChangeOrderCost
						? params.data.spectrumActualChangeOrderCost
						: 0;
					return actualCost + actualChangeOrderCost;
				},
				valueFormatter: ({value}) => fmtMoney(value)
			},
			{
				headerName: 'Open Commitment',
				field: 'spectrumOpenCommit',
				width: 170,
				valueFormatter: ({value}) => fmtMoney(value)
			},
			{
				headerName: 'Actual GP%',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueGetter: (params) => {
					const actualRevenue = params.data.spectrumActualRevenue ? params.data.spectrumActualRevenue : 0;
					const actualCost = params.data.spectrumActualCost ? params.data.spectrumActualCost : 0;
					const actualChangeOrderCost = params.data.spectrumActualChangeOrderCost
						? params.data.spectrumActualChangeOrderCost
						: 0;
					const actualTotalCost = actualCost + actualChangeOrderCost;
					const actualGP = ((actualRevenue - actualTotalCost) / actualRevenue) * 100;
					return actualGP && isFinite(actualGP) ? actualGP : 0;
				},
				valueFormatter: (params) => {
					return `${params.value.toFixed(2)} %`;
				}
			},
			{
				headerName: 'Group Work Number',
				field: 'groupWorkNumber',
				filter: 'agTextColumnFilter',
				width: 175
			},
			{
				headerName: 'Invoice Number',
				field: 'invoiceNumber',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Issue Date',
				field: 'createdOn',
				width: 150,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Completed Date',
				field: 'workCompletedOn',
				width: 150,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Ready to Invoice Date',
				field: 'readyToInvoiceDate',
				width: 150,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Invoiced Date',
				field: 'invoiceDate',
				width: 150,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Closed Date',
				field: 'closedDate',
				width: 150,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Start',
				field: 'createdOn',
				width: 150,
				hide: true,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Complete',
				field: 'workCompletedOn',
				width: 150,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				},
				hide: true
			},
			{
				headerName: 'Zip',
				field: 'location_Zip',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Site Contact',
				field: 'siteContactName',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Site Contact Phone',
				field: 'siteContactPhone',
				filter: 'agTextColumnFilter',
				width: 150
			}
		],
		other: [
			{
				headerName: 'Type',
				field: 'action',
				cellStyle: {},
				cellRendererFramework: OrderTypeRenderer,
				maxWidth: 75,
				filter: false,
				getQuickFilterText: (params) => {
					// Filter based on project or work order
					return params.data.isProject ? 'Project' : 'Work Order';
				},
				width: 150
			},
			{
				headerName: 'Work Number',
				field: 'workNumber',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Purchase Order Number',
				field: 'purchaseOrderNumber',
				filter: 'agTextColumnFilter',
				width: 200
			},
			{
				headerName: 'Next Scheduled Trip',
				field: 'aasdiObj',
				filter: 'agTextColumnFilter',
				cellRenderer: 'tripInfoRenderer',
				hide: this.activeMenu !== 'In Progress',
				width: 175
			},
			{
				headerName: 'SOW',
				field: 'scopeOfWork',
				filter: 'agTextColumnFilter',
				width: 75,
				cellRendererFramework: (params) => {
					return (
						params.valueFormatted && (
							<IconWithTooltip content={params.valueFormatted} color="black" name="align justify" />
						)
					);
				},
				valueFormatter: (params) => {
					let sow = params.value
						? params.value
								.replace(/<(?:.|\n)*?>/gm, '')
								.replace(/&nbsp;/gi, ' ')
								.substring(0, 60)
						: '';
					if (sow.length === 60) {
						sow = `${sow}...`;
					}
					return sow;
				}
			},
			{
				headerName: 'Purchase Order Amount',
				field: 'purchaseOrderAmount',
				filter: 'agTextColumnFilter',
				width: 150,
				valueFormatter: (params) => {
					return params.value ? fmtMoney(params.value) : `$0.00`;
				}
			},
			{
				headerName: 'Client',
				field: 'client_Name',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Tags',
				field: 'tags',
				cellRenderer: 'TagCellRenderer',
				width: 100
			},
			{
				headerName: 'Spectrum Code',
				field: 'spectrumCode',
				filter: 'agTextColumnFilter',
				width: 120
			},
			{
				headerName: 'Status',
				field: 'status_Name',
				width: 150
			},
			{
				headerName: 'Update',
				field: 'activityStreamUpdate',
				filter: 'agTextColumnFilter',
				width: 85,
				cellRendererFramework: (params) => {
					return (
						params.valueFormatted && (
							<IconWithTooltip content={params.valueFormatted} color="black" name="bell outline" />
						)
					);
				},
				valueFormatter: (params) => {
					let update = params.value ? params.value.replace(/<(?:.|\n)*?>/gm, '').substring(0, 60) : '';
					if (update.length === 60) {
						update = `${update}...`;
					}
					return update;
				}
			},
			{
				headerName: 'Store Number',
				field: 'location_StoreNumber',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'City',
				field: 'location_City',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'State',
				field: 'location_State',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Site Contact',
				field: 'siteContactName',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Site Contact Phone',
				field: 'siteContactPhone',
				filter: 'agTextColumnFilter',
				width: 150
			},
			{
				headerName: 'Trip',
				field: 'aasdiObj',
				filter: 'agTextColumnFilter',
				cellRenderer: 'tripInfoRenderer',
				width: 150
			},
			{
				headerName: 'AASDI',
				field: 'aasdiObj',
				cellRenderer: 'aasdiCellRenderer',
				width: 100
			},
			{
				headerName: 'Project Manager',
				field: 'projectManager_Name',
				width: 150
			},
			{
				headerName: 'NDS',
				field: 'workManager_Name',
				width: 150
			},
			{
				headerName: 'Issue Date',
				field: 'createdOn',
				width: 150,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Scheduled Date',
				field: 'actualDate',
				width: 150,
				filter: 'agDateColumnFilter',
				valueFormatter: (data) => {
					return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
				},
				filterParams: {
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						let dateAsString = moment(cellValue).format('DD/MM/YYYY');
						let dateParts = dateAsString.split('/');
						let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

						if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
							return 0;
						}

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						}

						if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
					}
				}
			},
			{
				headerName: 'Open Commitment',
				field: 'spectrumOpenCommit',
				width: 170,
				valueFormatter: ({value}) => fmtMoney(value)
			}
		]
	};

	sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true
				}
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		],
		position: 'right'
	};

	componentDidUpdate() {
		let activeMenu =
			this.props.workGridMenu[this.props.workGridMenu.findIndex((menu) => menu.active === true)].name;
		if (this.state.activeMenu !== activeMenu) {
			// first argument in set state clears out previous column
			// second argument sets new columns defs
			// this is to prevent column misordering
			this.setState({columnDefs: []}, () => {
				let columnDefs;
				switch (activeMenu) {
					case 'My Work':
						columnDefs = this.colDefsObj.mywork;
						break;
					case 'Support':
						columnDefs = this.colDefsObj.support;
						break;
					case 'Reports' || 'History':
						columnDefs = this.colDefsObj.history;
						break;
					default:
						columnDefs = this.colDefsObj.other;
				}

				this.setState({activeMenu, columnDefs});
			});
		}
	}

	frameworkComponents = {
		closureCommentsCellRenderer: closureCommentsCellRenderer,
		aasdiCellRenderer: aasdiCellRenderer,
		daysToCellRenderer: daysToCellRenderer,
		tripInfoRenderer: tripInfoRenderer,
		dateInputRenderer: dateInputRenderer,
		TagCellRenderer: tagCellRenderer
	};

	onQuickFilterChanged(value) {
		this.gridApi.setQuickFilter(value);
	}

	exportHandler(value) {
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				if (
					params.value &&
					params.column.colDef.filter &&
					params.column.colDef.filter === 'agDateColumnFilter'
				) {
					return moment(params.value).format('MM-DD-YYYY').toString();
				}

				if (
					params.value &&
					params.column.colDef.field &&
					params.column.colDef.field === 'location_StoreNumber'
				) {
					// remove special characters
					return params.value.replace(/[^a-zA-Z0-9 ]/g, '');
				}

				if (
					params.value &&
					params.column.colDef.field &&
					params.column.colDef.field === 'activityStreamUpdate'
				) {
					// replaces all special characters except '/'
					return params.value.replace(/[^a-zA-Z0-9/ ]/g, '');
				}

				if (params.value && params.column.colDef.field && params.column.colDef.field === 'scopeOfWork') {
					return params.value.replace(/<[^>]*>/g, '');
				}

				if (params.value && params.column.colDef.field && params.column.colDef.field === 'aasdiObj') {
					// formats aasdi/trip info for excel export

					if (params.value) {
						let aasdiObj = JSON.parse(
							params.value
								.replace(/\t/g, ' ')
								.replace(/\s\s+/g, ' ')
								.replace(/\\|\\*amp;/g, '')
						);
						let formattedVal = ``;
						aasdiObj.forEach((aasdi, index) => {
							formattedVal += `${index + 1}. ${aasdi.name}: ${aasdi.trips.length > 0 ? `(` : 'No Trips'}`;
							aasdi.trips.forEach((trip, tripIndex) => {
								formattedVal += `${tripIndex === 0 ? '' : ', '}${trip.name}${
									tripIndex === aasdi.trips.length - 1 ? ') ' : ''
								}`;
							});
						});
						return formattedVal;
					}
				}

				return params.value;
			}
		};
		this.gridApi.exportDataAsExcel(params);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		// this.gridApi.sizeColumnsToFit();
	};

	editValue = (params) => {
		if (params.data.commitBillingId) {
			const updatedData = params.data;
			this.props.updateProjectionData(updatedData, params.rowIndex);
		}
	};

	render() {
		return (
			<div className="fixGridMargin" style={{border: '1px solid rgba(34,36,38,.15)'}}>
				<Grid
					style={{
						padding: '2em 0',
						margin: '-1rem 0'
					}}
				>
					<Grid.Column width={6}>
						<Label
							style={{
								float: 'left',
								backgroundColor: 'transparent',
								fontSize: '.92rem',
								paddingTop: '1em'
							}}
						>
							Filter Grid
						</Label>
						<Input
							type="text"
							onChange={(e, {value}) => this.onQuickFilterChanged(value)}
							id="quickFilterCreateProjectModal"
							icon="search"
						/>
					</Grid.Column>
					<Grid.Column width={4}>
						{this.props.userRole === 'Standard' ? (
							<Checkbox
								label="Show All Work"
								toggle
								checked={this.props.workGridToggled}
								onChange={() => {
									this.props.updateWorkGridToggle();
									this.props.getWorkGrid(this.props.workGridMenu[this.props.activeTabIndex]);
								}}
							/>
						) : null}
					</Grid.Column>
					<Grid.Column width={6}>
						<Input
							style={{float: 'right'}}
							type="text"
							id="fileNameCreateProjectModal"
							placeholder="Name file for export"
							action
						>
							<input />
							<Button
								type="submit"
								onClick={() => {
									this.exportHandler(document.getElementById('fileNameCreateProjectModal').value);
								}}
								style={{fontSize: '.95rem'}}
							>
								Export
								<Icon name="arrow right" />
							</Button>
						</Input>
					</Grid.Column>
				</Grid>
				<Menu tabular style={{border: 'none', margin: 0}}>
					{this.props.workGridMenu.map((item, index) => (
						<Menu.Item
							name={item.name}
							active={item.active}
							key={item.name}
							onClick={() => {
								this.props.updateActiveMenuItem(index);
								this.props.getWorkGrid(item);
							}}
						/>
					))}
				</Menu>
				<Segment
					className="ag-theme-balham gridPagniationFix"
					style={{
						height: '60vh',
						width: '100%',
						marginTop: '0'
					}}
					loading={this.props.workGridLoading}
				>
					<AgGridReact
						rowSelection="single"
						animateRows={true}
						modules={AllModules}
						suppressRowClickSelection={false}
						sideBar={this.sideBar}
						suppressDragLeaveHidesColumns={true}
						suppressContextMenu={true}
						suppressMultiRangeSelection={true}
						onGridReady={this.onGridReady}
						defaultColDef={this.state.defaultColDef}
						columnDefs={this.state.columnDefs}
						rowData={this.props.workGridData}
						paginationAutoPageSize={true}
						pagination={true}
						onCellValueChanged={this.editValue}
						frameworkComponents={this.frameworkComponents}
						pivotMode={false}
						aggFuncs={this.aggFuncs}
						suppressAggFuncInHeader={true}
					/>
				</Segment>
			</div>
		);
	}
}

WorkGrid.propTypes = {
	activeTabIndex: PropTypes.number,
	getWorkGrid: PropTypes.func,
	updateActiveMenuItem: PropTypes.func,
	updateWorkGridToggle: PropTypes.func,
	userRole: PropTypes.string,
	workGridData: PropTypes.array,
	workGridMenu: PropTypes.array,
	workGridLoading: PropTypes.bool,
	workGridToggled: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		activeTabIndex: state.work.workGrid.activeTabIndex,
		userRole: state.auth.account.roleTypeName,
		workGridData: state.work.workGrid.rowData,
		workGridMenu: state.work.workGrid.rowMenu,
		workGridLoading: state.work.workGrid.loading,
		workGridToggled: state.work.workGrid.toggled
	};
};

const mapDispatchToProps = {
	getWorkGrid,
	updateWorkGridToggle,
	updateActiveMenuItem: (index) => updateActiveMenuItem(index),
	updateWorkField: (arg1, arg2, arg3, arg4, arg5, arg6) => updateWorkField(arg1, arg2, arg3, arg4, arg5, arg6),
	updateProjectionData: (arg1, arg2) => updateProjectionData(arg1, arg2)
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkGrid);
