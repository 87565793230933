// TODOS
// remove inline styling

import {Card, Form, Header, Icon, Popup, Input, Button} from 'semantic-ui-react';
import React from 'react';
import {connect} from 'react-redux';
import {selectTaskList, selectSidePanelTask, selectInputMaskList} from '../services/TaskTemplates.selectors.js';
import {changeTask, createTask, updateTask, deleteTask} from '../services/TaskTemplates.actions.js';
import * as taskTemplateService from '../services/TaskTemplates.service.js';
import {DebounceInput} from 'react-debounce-input';
import {useParams} from 'react-router-dom';
import {columnTypeHashmap} from './ColumnTypes.jsx';

const TaskInformation = (props) => {
	// id of the template
	const {id} = useParams();

	let taskType;
	let saveAction;
	let deleteButton;
	if (props.sidePanelTask.id) {
		taskType = (
			<Form.Input label="Task type" key={`-Header`}>
				<Header.Subheader key={`$-SubHeader`}>{props.sidePanelTask.name}</Header.Subheader>
			</Form.Input>
		);
		saveAction = (sidePanelTask) => props.updateTask(sidePanelTask, id);
		deleteButton = (
			<Button type="button" color="red" onClick={() => props.deleteTask(props.sidePanelTask)}>
				Delete
			</Button>
		);
	} else {
		taskType = (
			<Form.Field key={`-formField`}>
				<label>
					Task type
					<Popup
						size="small"
						trigger={<Icon style={{paddingLeft: '1rem'}} name="info circle" />}
						content="Select the type of task the AASDI needs to perform."
					/>
				</label>
				<Form.Dropdown
					key={`-input`}
					placeholder="Select task type"
					fluid
					search
					selection
					onChange={(e, changeObj) => {
						const taskType = changeObj.options.find((option) => option.value === changeObj.value);
						props.changeTask({
							taskTypeId: taskType.key,
							name: taskType.value
						});
					}}
					options={taskTemplateService.createTaskListDropdownActions(props.taskList)}
				/>
			</Form.Field>
		);
		saveAction = (sidePanelTask, id) => props.createTask(sidePanelTask, id);
	}

	let taskColumns;
	if (props.sidePanelTask.taskTypeId) {
		// select taskList from state, rightPanelTask
		// find entry in taskList with an id corresponding to rightPanelTask.taskTypeId
		// split entry.columnList by ','
		// loop over columnList, returning the proper Component for each entry in ColumnList

		taskColumns = props.taskList
			.find((type) => type.id === props.sidePanelTask.taskTypeId)
			.columnList.split(',')
			.map((column) => columnTypeHashmap(props)[column]);
	}

	return [
		<Card>
			<Card.Header>
				<Header style={{margin: 10}} as="h3">
					<Icon name="edit" />
					<Header.Content>Edit Task</Header.Content>
				</Header>
			</Card.Header>
			<Card.Content key={'inputFields'}>
				<Form>
					<Form.Field>
						<label>Task Title</label>
						<DebounceInput
							element={Input}
							placeholder="Task Title"
							debounceTimeout={600}
							key={`-input`}
							onChange={(e) => props.changeTask({title: e.target.value})}
							value={props.sidePanelTask.title}
						/>
					</Form.Field>

					{taskType}
					{taskColumns}
				</Form>
			</Card.Content>
			<Card.Content key={'PanelButtonGroup'} style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
				{deleteButton}
				<Button type="button" color="green" onClick={() => saveAction(props.sidePanelTask, id)}>
					Save
				</Button>
			</Card.Content>
		</Card>
	];
};

const mapStateToProps = (state) => ({
	taskList: selectTaskList(state),
	sidePanelTask: selectSidePanelTask(state),
	inputMaskList: selectInputMaskList(state)
});

const mapDispatchToProps = (dispatch, props) => ({
	changeTask: (updatedValues) => dispatch(changeTask(updatedValues)),
	createTask: (task, templateId) => dispatch(createTask(task, templateId)),
	updateTask: (task, templateId) => dispatch(updateTask(task, templateId)),
	deleteTask: (task) => dispatch(deleteTask(task))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskInformation);
