// Modules
import React from 'react';
import {Grid, Segment, Button, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Module
import Statistics from '../../../components/statistics/Statistics.js';

// Services
import '../style.css';
import {getStageAnalytics} from '../../../services/pipelineActions.js';

const CardStatistics = ({stages, loading, error, getStageAnalytics}) => {
	const errorElement = (
		<Segment basic>
			Hmmm, it looks like something went wrong when I was trying to pull this data. Try again, or contact the Dev
			team if it keeps happening
			<Segment basic style={{paddingBottom: '0'}}>
				<Button icon onClick={() => getStageAnalytics()}>
					<Icon name="refresh" />
				</Button>
			</Segment>
		</Segment>
	);
	return (
		<Grid.Row>
			{error ? (
				errorElement
			) : (
				<Grid columns={'equal'} divided>
					<Grid.Column className="borderBottom">
						<Statistics.IdentifiedNeed loading={loading} revenue={stages.identifiedNeed.revenue} />
					</Grid.Column>
					<Grid.Column className="borderBottom">
						<Statistics.Estimating loading={loading} revenue={stages.estimating.revenue} />
					</Grid.Column>
					<Grid.Column className="borderBottom">
						<Statistics.SubmittedQuote loading={loading} revenue={stages.submittedQuote.revenue} />
					</Grid.Column>
					<Grid.Column className="borderBottom">
						<Statistics.HighProbability loading={loading} revenue={stages.highProbability.revenue} />
					</Grid.Column>
					<Grid.Column className="borderBottom">
						<Statistics.VerbalAward loading={loading} revenue={stages.verbalAward.revenue} />
					</Grid.Column>
				</Grid>
			)}
		</Grid.Row>
	);
};

CardStatistics.propTypes = {
	stages: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	getStageAnalytics: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		stages: state.crm.pipeline.analytics.stages.data,
		loading: state.crm.pipeline.analytics.stages.loading,
		error: state.crm.pipeline.analytics.stages.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getStageAnalytics: () => {
			dispatch(getStageAnalytics());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CardStatistics);
