// Modules
import React from 'react';
import {Segment, Icon, Message, Progress} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Services
import * as actions from '../../../../../services/accountingConsoleActions.js';

// const Log = props => {
class DesyncedMessage extends React.Component {
	constructor(props) {
		super(props);
		// this.state = {
		// 	timer: 0,
		// 	mouseOn: false,
		// 	opacity: 100
		// };
	}

	// All that is commented out here will work as a toast notification but didn't have time to fix

	// showMessage() {
	// 	if (this.state.opacity < 1) {
	// 		this.setState({
	// 			opacity: Math.round((this.state.opacity + 0.1) * 10) / 10
	// 		});
	// 	}

	// 	if (this.state.timer < 100 && !this.state.mouseOn) {
	// 		this.setState({ timer: this.state.timer + 1 });
	// 	}

	// 	if (this.state.timer > 99 && this.state.opacity !== 0) {
	// 		this.setState({
	// 			opacity: Math.round((this.state.opacity - 0.5) * 10) / 10
	// 		});
	// 		this.props.toggleDesyncedMessage(false);
	// 	}
	// }

	// componentDidMount() {
	// 	setInterval(() => this.showMessage(), 50);
	// }

	render() {
		return (
			<Segment
				inverted
				color="red"
				style={{
					position: 'fixed',
					width: '12.6vw',
					height: '43vh',
					marginTop: '20vh',
					left: '.5vw',
					top: '6vh'
					// opacity: this.state.opacity
					// paddingLeft: '.1vw'
				}}
				// onMouseOver={() => {
				// 	console.log(`MOUSE IN`);
				// 	this.setState({ mouseOn: true });
				// }}
				// onMouseOut={() => {
				// 	console.log(`MOUSE OUT`);
				// 	this.setState({ mouseOn: false });
				// }}
			>
				<Message style={{margin: '.5vw'}}>
					<Message.Header> Record is Desynced</Message.Header>
					<p>
						This record is not synced with Spectrum. You can not approve or reject it until Spectrum has
						been updated with the most current information.
					</p>
					<br />
					{/* opacity= {this.state.opacity} <br />
					timer= {this.state.timer} */}
				</Message>
				<Icon name="unlink" size="massive" style={{margin: '2vw 2vw 2vw 2vw'}} />
				{/* <Progress
					percent={this.state.timer}
					size="small"
					inverted
					color="grey"
				/> */}
			</Segment>
		);
	}
}

DesyncedMessage.propTypes = {
	changedArray: PropTypes.array,
	toggleDesyncedMessage: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		changedArray: state.accountingConsoleReducer.accountingConsole.changedArray
	};
};

export default connect(mapStateToProps, actions)(DesyncedMessage);
