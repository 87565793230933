import { createReduxHistoryContext} from "redux-first-history";
import {createBrowserHistory} from 'history';

//having this file helps with circular import issues

//React Router setup
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
	history: createBrowserHistory()
});

export {routerReducer, createReduxHistory, routerMiddleware}