import React from 'react';
import {
	Button,
	Modal,
	Icon,
	Header,
	Grid,
	Label,
	Dropdown,
	Input,
	Form,
	Step,
	TextArea,
	Segment
} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from '../services/clientGridActions.js';
import {createClientModalFields, createClientDocumentsHeader} from '../services/clientGridReducer.js';
import {debounceEventHandler, debounce} from '../../../../../lib/old/debounce.js';
import api from '../../../../../lib/api.ts';
import {DebounceInput} from 'react-debounce-input';
import DocumentSectionRemoveButton from './documentSectionRemoveButton.js';
import AgGrid from '../../../../../components/Global/AgGrid.js';
import {clientDocTypeOptions} from '../../../../../constants/docTypes.js';
import {MomentDatePicker} from '../../../../../components/inputs/DatePicker.jsx';

const frameworkComponents = {
	documentSectionRemoveButton: DocumentSectionRemoveButton
};

const labelStyle = {
	backgroundColor: 'transparent',
	fontSize: '1rem',
	color: 'rgba(0,0,0,.8)',
	paddingLeft: '0'
};

class CreateClientModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetching: false,
			searchOptions: [],
			searchResults: [],
			targetClient: null,
			clientInfos: {}
		};
		this.searchClients = this.searchClients.bind(this);
		this.setOptions = this.setOptions.bind(this);
		this.clientSelected = this.clientSelected.bind(this);
		this.fieldUpdated = this.fieldUpdated.bind(this);
		this.submitUpdate = this.submitUpdate.bind(this);
		this.checkRequiredFields = this.checkRequiredFields.bind(this);
	}

	searchClients(searchInput) {
		this.setState({...this.state, isFetching: true});
		api(`legacy/client?name=${encodeURIComponent(searchInput)}&status=Prospect`)
			.then((response) => {
				this.setOptions(response);
				this.setState({...this.state, isFetching: false});
			})
			.catch((error) => {
				this.setState({...this.state, isFetching: false});
				alert('Something went wrong...');
				console.log(error);
			});
	}

	setOptions(results) {
		const searchOptions = results.map((result) => {
			const option = {};
			option.value = result.id;
			option.text = result.name;
			return option;
		});
		this.setState({...this.state, searchOptions, searchResults: results});
	}

	clientSelected(value) {
		const {searchResults} = this.state;
		const clientInfos = searchResults.find((info) => info.id === value) || {};
		this.setState({...this.state, targetClient: value, clientInfos});
		this.props.toggleHasFetchedDocuments(false);
	}

	fieldUpdated(value, targetFieldKey) {
		const {clientInfos} = this.state;
		clientInfos[targetFieldKey] = value;
		this.setState({...this.state, clientInfos});
	}

	availableClientInfos(clientInfos) {
		if (Object.keys(clientInfos).length === 0 && clientInfos.constructor === Object) {
			return false;
		}
		return true;
	}

	renderClientInfo(clientInfos) {
		if (!this.availableClientInfos(clientInfos)) {
			return null;
		}
		const fields = createClientModalFields(clientInfos);
		return fields.map((field) => (
			<Form.Field key={`form_field_${field.key}`} required={field.required}>
				<label key={`label_${field.key}`} style={labelStyle}>
					{field.label}
				</label>
				{field.isDropdown ? (
					<Dropdown
						className="createModalDropdown"
						fluid
						search
						selection
						options={field.options}
						onChange={(e, {value}) => this.fieldUpdated(value, field.key)}
						value={field.value}
					/>
				) : field.dropDownSearch ? (
					<>
						<Dropdown
							className="createModalDropdown"
							placeholder={field.employee ? `Search for ${field.label}` : 'Search for Contact'}
							fluid
							search
							selection
							onSearchChange={debounceEventHandler(
								debounce((e) => {
									if (e.target.value) {
										field.employee
											? this.props.getAccountExecs(e.target.value, field.type)
											: this.props.getContacts(e.target.value, clientInfos.id);
										// this.searchClients(e.target.value);
									}
								}, 600)
							)}
							value={field.value}
							onChange={(e, {value}) => {
								console.log(JSON.stringify(value));
								this.fieldUpdated(value, field.key);
								console.log(this.state.clientInfos);
							}}
							options={
								field.employee
									? this.props[`${field.type}DropDownSearchOptions`]
									: this.props.ContactsDropDownSearchOptions
							}
							loading={
								field.employee
									? this.props[`${field.type}DropDownSearchLoader`]
									: this.props.ContactsDropDownSearchLoader
							}
						/>
					</>
				) : (
					<Input
						key={`input_${field.key}`}
						value={field.value}
						onChange={(e) =>
							this.fieldUpdated(
								field.key === 'state' ? e.target.value.toUpperCase() : e.target.value,
								field.key
							)
						}
						disabled={field.disabled}
						maxLength={field.key === 'state' ? 2 : null}
					/>
				)}
			</Form.Field>
		));
	}

	checkRequiredFields(clientInfos) {
		const {documentsData} = this.props;
		let answer = true;
		const requiredTypes = clientDocTypeOptions.map((opt) => opt.key);

		const fields = createClientModalFields(clientInfos);
		fields.forEach((field) => {
			if (field.required && !field.value) {
				answer = false;
			}
		});
		const typesInDocuments = documentsData.map((doc) => Number(doc.docType));
		if (documentsData.length > 0) {
			answer = requiredTypes.every((type) => typesInDocuments.includes(type));
		} else {
			answer = false;
		}
		return answer;
	}

	async submitUpdate() {
		const {clientInfos} = this.state;
		const {updateCustomer} = this.props;
		clientInfos.transition = true;
		this.props.setIsSubmitting(true);
		await api(`legacy/client/${clientInfos.id}`, 'put', clientInfos)
			.then((response) => {
				updateCustomer(response);
				this.setState({
					...this.state,
					clientInfos: {},
					targetClient: null,
					searchOptions: [],
					searchResults: []
				});
			})
			.catch((error) => {
				this.closeModal();
				console.log(error);
				alert('Something went wrong');
			});
		this.props.createCustomerDocuments(clientInfos.id);
	}

	closeModal() {
		this.props.toggleCustomerCreationModal(false);
	}

	render() {
		const {toggleCustomerCreationModal, isOpen, documentsData, loading, hasFetchedDocuments, isSubmitting} =
			this.props;
		const {searchOptions, isFetching, targetClient, clientInfos} = this.state;
		return (
			<Modal size="small" open={isOpen} onUnmount={() => this.closeModal()}>
				<Header>
					<Icon name="plus" color="green" />
					<div className="content">Create Client</div>
					{targetClient ? (
						<Step.Group widths={4} size="small">
							{this.props.stepsCustomer.modalSteps.map((modalStep, index) => (
								<Step
									key={modalStep.name}
									icon={modalStep.icon}
									// description: modal.description,
									title={modalStep.name}
									active={modalStep.active}
									link={true}
									onClick={() => {
										this.props.setActiveStep('stepsCustomer', this.props.activeStep, index);
										if (index === 1) {
											if (!hasFetchedDocuments) {
												this.props.getClientDocuments(1, 'stepsCustomer', clientInfos);
											}
										}
									}}
								/>
							))}
						</Step.Group>
					) : null}
				</Header>
				<Modal.Content>
					<Segment basic loading={loading}>
						{this.props.stepsCustomer.modalSteps[0].active ? (
							<Grid>
								<Grid.Row>
									<Grid.Column width={16}>
										<Label style={labelStyle}>Search Prospects</Label>
										<Dropdown
											className="createModalDropdown"
											placeholder="Search for a prospect"
											fluid
											search
											selection
											onSearchChange={debounceEventHandler(
												debounce((e) => {
													if (e.target.value) {
														this.searchClients(e.target.value);
													}
												}, 600)
											)}
											value={targetClient}
											onChange={(e, {value}) => {
												this.clientSelected(value);
											}}
											options={searchOptions}
											loading={isFetching}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<Form>{this.renderClientInfo(clientInfos)}</Form>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						) : (
							this.props.stepsCustomer.modalSteps.map((modal, stepIndex) =>
								modal.active ? (
									<Grid key={stepIndex}>
										<Grid.Row>
											{modal.modalFields.map((field, fieldIndex) =>
												field.input ? (
													<Grid.Column key={fieldIndex} width={5}>
														<div
															style={{
																paddingTop: '0.5em'
															}}
														>
															<Label
																style={{
																	backgroundColor: 'transparent',
																	fontSize: '1rem',
																	color: 'rgba(0,0,0,.8)',
																	paddingLeft: '0'
																}}
															>
																{field.name}
															</Label>
														</div>
														<DebounceInput
															element={Input}
															onChange={(e) => {
																this.props.updateCreateModal(
																	'stepsCustomer',
																	stepIndex,
																	fieldIndex,
																	e.target.value,
																	false
																);
															}}
															debounceTimeout={600}
															value={field.value}
															style={{
																width: '100%'
															}}
															error={field.required ? field.error : false}
															disabled={field.disabled}
															placeholder={field.placeholder}
														/>
													</Grid.Column>
												) : field.datePicker ? (
													<Grid.Column
														key={fieldIndex}
														width={5}
														// style={{
														// 	marginTop:
														// 		'.1vh'
														// }}
													>
														<Grid.Row>
															<Label
																style={{
																	backgroundColor: 'transparent',
																	fontSize: '1rem',
																	color: 'rgba(0,0,0,.8)',
																	paddingLeft: '0'
																}}
															>
																{field.name}
															</Label>
														</Grid.Row>
														<Grid.Row>
															<MomentDatePicker
																className={
																	field.error
																		? 'createAasdiDatePicker sendToModalDatePickerError'
																		: 'createAasdiDatePicker'
																}
																showTimeSelect
																timeFormat="HH:mm"
																timeIntervals={5}
																dateFormat="LLL"
																timeCaption="time"
																selected={field.value}
																placeholder={field.placeholder}
																fluid
																search
																selection
																disabled={field.disabled}
															/>
														</Grid.Row>
													</Grid.Column>
												) : field.dropdown ? (
													// <Grid.Row key={fieldIndex}>
													<Grid.Column key={fieldIndex} width={5}>
														<div
															style={{
																paddingTop: '0.5em'
															}}
														>
															<Label
																style={{
																	backgroundColor: 'transparent',
																	fontSize: '1rem',
																	color: 'rgba(0,0,0,.8)',
																	paddingLeft: '0'
																}}
															>
																{field.name}
															</Label>
															<Dropdown
																className="createModalDropdown"
																placeholder={field.placeholder}
																fluid
																search
																selection
																options={field.options}
																onChange={(e, {value}) => {
																	console.log(`value ==/= ${value}`);
																	this.props.updateCreateModal(
																		'stepsCustomer',
																		stepIndex,
																		fieldIndex,
																		value,
																		false
																	);
																}}
																onSearchChange={debounceEventHandler(
																	debounce((e) => {
																		if (e.target.value) {
																			if (field.searchAE) {
																				this.props.getAccountExecs(
																					'stepsCustomer',
																					stepIndex,
																					fieldIndex,
																					e.target.value
																				);
																			} else if (field.searchMC) {
																				this.props.getMainContacts(
																					'stepsCustomer',
																					stepIndex,
																					fieldIndex,
																					e.target.value
																				);
																			}
																		}
																	}, 600)
																)}
																icon={field.icon}
																value={field.value}
																error={field.required ? Boolean(field.error) : false}
																disabled={field.disabled}
																upward={field.upward}
																loading={field.loading}
																defaultSearchQuery={field.searchQuery}
															/>
														</div>
													</Grid.Column>
												) : field.textArea ? (
													<Grid.Column width={field.columnWidth}>
														<div>
															<div
																style={{
																	paddingTop: '0.8em'
																}}
															>
																<Label
																	style={{
																		backgroundColor: 'transparent',
																		fontSize: '1rem',
																		color: 'rgba(0,0,0,.8)',
																		paddingLeft: '0'
																	}}
																>
																	{field.name}
																</Label>
															</div>
															<DebounceInput
																element={TextArea}
																onChange={(e) => {
																	this.props.updateCreateModal(
																		'stepsCustomer',
																		stepIndex,
																		fieldIndex,
																		e.target.value,
																		false
																	);
																}}
																debounceTimeout={600}
																value={field.value}
																style={{
																	width: '100%',
																	border: '1px solid rgba(34,36,38,.15)',
																	color: 'rgba(0,0,0,.87)',
																	borderRadius: '.28571429rem'
																}}
																error={field.required ? Boolean(field.error) : false}
																disabled={field.disabled}
																placeholder={field.placeholder}
															/>
														</div>
													</Grid.Column>
												) : null
											)}
										</Grid.Row>
										{modal.name === 'Documents' ? (
											<div style={{width: '95%'}}>
												<Grid.Row>
													<Grid.Column
														width={15}
														style={{
															paddingTop: '0.8em'
														}}
													>
														<Button
															primary
															onClick={() =>
																this.props.addDocument(
																	modal.modalFields[0],
																	modal.modalFields[1],
																	modal.modalFields[2],
																	modal.modalFields[3],
																	stepIndex,
																	'stepsCustomer',
																	clientInfos
																)
															}
														>
															<Icon name="plus" />
															Add Document
														</Button>
													</Grid.Column>
												</Grid.Row>
												<Grid.Row style={{margin: '1em'}}>
													<span style={{color: 'IndianRed'}}>
														* A document of both "Master Terms & Conditions" and "New
														Costomer Information" are required to create a client.
													</span>
												</Grid.Row>
												<Grid.Row
													style={{
														paddingTop: '0.8em'
													}}
												>
													<Grid.Column width={15}>
														<AgGrid
															columnDefs={createClientDocumentsHeader}
															rowData={documentsData}
															onGridReady={this.onGridReady}
															frameworkComponents={frameworkComponents}
															gridStyle={{
																height: '30vh'
															}}
															modalName={'stepsCustomer'}
															{...this.props}
														/>
													</Grid.Column>
												</Grid.Row>
												{modal.modalFields.map((field, fieldIndex) =>
													field.checkbox ? (
														<Grid.Row
															style={{
																paddingTop: '0.8em'
															}}
														>
															<Grid.Column>
																<Form>
																	<Form.Checkbox
																		required
																		checked={field.value}
																		label={field.name}
																		onChange={(e, {checked}) => {
																			this.props.updateCreateModal(
																				'stepsCustomer',
																				stepIndex,
																				fieldIndex,
																				checked,
																				false
																			);
																		}}
																		error={
																			field.required
																				? Boolean(field.error)
																				: false
																		}
																	/>
																</Form>
															</Grid.Column>
														</Grid.Row>
													) : null
												)}
											</div>
										) : null}
									</Grid>
								) : null
							)
						)}
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							onClick={() => {
								toggleCustomerCreationModal(false);
								//  vv this resets the creation modal on cancel vv
								this.setState(() => {
									return {
										isFetching: false,
										searchOptions: [],
										searchResults: [],
										targetClient: null,
										clientInfos: {}
									};
								});
							}}
							color="red"
						>
							<Icon name="remove" />
							Cancel
						</Button>
						<Button
							onClick={() => this.submitUpdate()}
							color="green"
							disabled={
								!this.availableClientInfos(clientInfos) ||
								isSubmitting ||
								!this.checkRequiredFields(clientInfos)
							}
							loading={isSubmitting}
						>
							<Icon name="add" />
							Create
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

CreateClientModal.propTypes = {
	isOpen: PropTypes.bool,
	toggleCustomerCreationModal: PropTypes.func,
	updateCustomer: PropTypes.func
};

const mapStateToProps = (state) => ({
	isOpen: state.work.clientGrid.showCustomerCreationModal,
	AccExecDropDownSearchOptions: state.work.clientGrid.AccExecDropDownSearchOptions,
	AccExecDropDownSearchLoader: state.work.clientGrid.AccExecDropDownSearchLoader,
	ContactsDropDownSearchOptions: state.work.clientGrid.ContactsDropDownSearchOptions,
	ContactsDropDownSearchLoader: state.work.clientGrid.ContactsDropDownSearchLoader,
	AccManDropDownSearchOptions: state.work.clientGrid.AccManDropDownSearchOptions,
	AccManDropDownSearchLoader: state.work.clientGrid.AccManDropDownSearchLoader,
	stepsCustomer: state.work.clientGrid.stepsCustomer,
	activeStep: state.work.clientGrid.stepsCustomer.modalSteps
		.map((modalStep, index) => ({modalStep: modalStep, index: index}))
		.filter((modalStep) => modalStep.modalStep.active)[0].index,
	documentsData: state.work.clientGrid.stepsCustomer.modalSteps[1].documents,
	loading: state.work.clientGrid.stepsCustomer.loading,
	hasFetchedDocuments: state.work.clientGrid.stepsCustomer.hasFetchedDocuments,
	isSubmitting: state.work.clientGrid.stepsCustomer.isSubmitting
});

// const mapDispatchToProps = dispatch => ({
// 	toggleCustomerCreationModal: status =>
// 		dispatch(toggleCustomerCreationModal(status)),
// 	updateCustomer: customer => dispatch(updateCustomer(customer)),
// 	getAccountExecs: value => dispatch(getAccountExecs(value))
// });

export default connect(mapStateToProps, actions)(CreateClientModal);
