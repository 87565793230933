import React from 'react';
import {Segment, Step, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ClosureModal from './closureModal.js';
import CanceledModal from './canceledModal.js';
import RequestToCBWModal from './requestToCBWModal.js';
import AasdiRatingModal from './aasdiRatingConfirmationModal.js';
import MessageModal from '../../../../../../../../../components/Global/MessageModal.js';
import {
	setProgressActiveStep,
	toggleClosureModal,
	toggleRatingConfirmationModal,
	toggleCanceledModal,
	toggleCBWModal
} from '../../../services/workRecordActions.js';
import CreateRatingModal from '../../../../../../../../../components/Global/CreateRatingModal.js';
import {toggleCreateRatingModal} from '../../../../../../../../../components/Global/services/globalActions.js';

class WorkOverviewProgress extends React.Component {
	constructor(props) {
		super(props);
		this.handleStageClick = this.handleStageClick.bind(this);
		this.handleModalToggle = this.handleModalToggle.bind(this);
		this.handleUpdateModalMessage = this.handleUpdateModalMessage.bind(this);
		this.cycleUnratedAasdis = this.cycleUnratedAasdis.bind(this);
		this.resetAasdiRatingModalOpen = this.resetAasdiRatingModalOpen.bind(this);
		this.state = {
			modalToggle: false,
			modalMessage: '',
			modalDetails: '',
			unratedAasdis: {
				numberOfAasdis: 0,
				aasdis: [],
				ratingCompleted: false,
				modalOpen: false
			}
		};
	}

	handleStageClick(stage) {
		if (this.props.activeStep !== stage) {
			if (
				stage !== 'Request to Close' &&
				stage !== 'Completed By Warranty' &&
				stage !== 'Canceled' &&
				stage !== 'Request to CBW'
			) {
				this.props.setProgressActiveStep(stage);
			} else if (stage === 'Canceled') {
				if (Math.round(this.props.remainingToBill) > 0.0) {
					this.handleUpdateModalMessage(
						'The remaining to bill amount must be $0.00.',
						`Current Remaining to Bill: ${this.props.remainingToBill}`
					);
					this.handleModalToggle();
				} else if (Math.round(this.props.actualCost) > 0.0) {
					this.handleUpdateModalMessage(
						'The actual cost amount must be $0.00.',
						`Current actual cost: ${this.props.actualCost}`
					);
					this.handleModalToggle();
				} else {
					if (stage === 'Canceled') {
						this.props.toggleCanceledModal();
					} else {
						this.props.setProgressActiveStep(stage);
					}
				}
			} else if (stage === 'Request to Close') {
				if (!this.props.isProject && this.props.hasPendingInvoices) {
					this.handleUpdateModalMessage(
						'All invoices must be closed.',
						'Try again after closing all invoices.'
					);
					this.handleModalToggle();
				} else if (Math.round(this.props.remainingToBill) > 0.0) {
					this.handleUpdateModalMessage(
						'The remaining to bill amount must be $0.00.',
						`Current Remaining to Bill: ${this.props.remainingToBill}`
					);
					this.handleModalToggle();
				} else if (this.props.hasOpenPOs) {
					this.handleUpdateModalMessage(
						'All purchase orders must be closed.',
						'Try again after all purchase orders have been closed.'
					);
					this.handleModalToggle();
				} else if (
					this.props.unratedAasdis &&
					this.props.unratedAasdis.length > 0 &&
					!this.state.unratedAasdis.modalOpen
				) {
					this.props.toggleRatingConfirmationModal();
				} else {
					this.props.toggleClosureModal();
				}
			} else if (stage === 'Request to CBW') {
				if (Math.round(this.props.remainingToBill) > 0.0) {
					// change back to > later
					this.handleUpdateModalMessage(
						'The remaining to bill amount must be $0.00.',
						`Current Remaining to Bill: ${this.props.remainingToBill}`
					);
					this.handleModalToggle();
				} else {
					this.props.toggleClosureModal(stage);
				}
			} else if (this.props.isDesynced) {
				this.props.toggleClosureModal();
			}
		}
	}

	resetAasdiRatingModalOpen() {
		this.setState({
			unratedAasdis: {
				...this.state.unratedAasdis,
				modalOpen: false
			}
		});
	}

	cycleUnratedAasdis() {
		if (!this.state.unratedAasdis.modalOpen) {
			// runs first
			this.setState({
				unratedAasdis: {
					...this.state.unratedAasdis,
					modalOpen: true,
					numberOfAasdis: this.props.unratedAasdis.length,
					aasdis: this.props.unratedAasdis
				}
			});
			this.props.toggleCreateRatingModal(true);
		} else {
			let ratingCompleted = false;
			if (this.state.unratedAasdis.aasdis.length === 1) {
				// runs on last aasdi
				ratingCompleted = true;
				this.props.toggleClosureModal();
			}
			// runs the rest of the time
			let newAasdiArray = this.state.unratedAasdis.aasdis;
			newAasdiArray.shift();
			this.setState({
				unratedAasdis: {
					...this.state.unratedAasdis,
					aasdis: newAasdiArray,
					ratingCompleted
				}
			});
		}
	}

	handleUpdateModalMessage(modalMessage, modalDetails) {
		this.setState({
			modalMessage: modalMessage,
			modalDetails: modalDetails
		});
	}

	handleModalToggle() {
		this.setState((prevState) => ({modalToggle: !prevState.modalToggle}));
	}

	render() {
		let stageNames = [
			'In Queue',
			'On Hold',
			'To Be Scheduled',
			'In Progress',
			'Work Completed',
			'Ready For Invoicing',
			'Invoiced',
			'Request to CBW',
			'Completed By Warranty',
			'Canceled',
			'Request to Close',
			'Closed'
		];
		let selectedStepIndex = stageNames.findIndex((stage) => stage === this.props.activeStep);
		return (
			<Segment
				loading={Boolean(
					this.props.projectCostingLoading || this.props.invoicesLoading || this.props.purchaseOrdersLoading
				)}
				style={{overflowY: 'auto', whiteSpace: 'nowrap'}}
			>
				<Step.Group
					fluid
					style={{
						padding: '0em',
						marginTop: '20px'
					}}
					size="mini"
				>
					{stageNames.map((stage, index) => {
						return (
							<Step
								disabled={
									stage === 'Closed' ||
									stage === 'Completed By Warranty' ||
									this.props.activeStep === 'Closed' ||
									this.props.activeStep === 'Canceled' ||
									this.props.activeStep === 'Completed By Warranty' ||
									this.props.activeStep === 'Request to Close' ||
									this.props.activeStep === 'Request to CBW'
								}
								key={index}
								className={['workView', selectedStepIndex < index ? 'toBeCompleted' : 'closed']}
								link={selectedStepIndex <= index}
								active={this.props.activeStep === stage}
								completed={selectedStepIndex > index}
								color={'blue'}
								onClick={() => this.handleStageClick(stage)}
							>
								<Step.Content>
									<MessageModal
										message={this.state.modalMessage}
										details={this.state.modalDetails}
										modalToggle={this.state.modalToggle}
										onClick={this.handleModalToggle}
									/>
									<ClosureModal />
									<AasdiRatingModal cycleUnratedAasdis={this.cycleUnratedAasdis} />
									<CreateRatingModal
										fromWorkOrder={true}
										workOrder={this.props.workOrder}
										unratedAasdis={this.state.unratedAasdis}
										cycleUnratedAasdis={this.cycleUnratedAasdis}
										resetAasdiRatingModalOpen={this.resetAasdiRatingModalOpen}
									/>
									<CanceledModal />
									<RequestToCBWModal />
									<Step.Title
										style={
											stage === 'Request to Close' && this.props.isDesynced
												? {
														textAlign: 'center',
														color: 'yellow'
												  }
												: {textAlign: 'center'}
										}
									>
										{stageNames.indexOf(this.props.activeStep) >= index ? (
											<Icon
												name={selectedStepIndex > index ? 'check' : 'map marker'}
												className={selectedStepIndex === index ? 'currentIcon' : null}
												color={selectedStepIndex <= index ? 'blue' : 'green'}
											/>
										) : null}
										{stage}
									</Step.Title>
								</Step.Content>
							</Step>
						);
					})}
				</Step.Group>
			</Segment>
		);
	}
}

WorkOverviewProgress.propTypes = {
	activeStep: PropTypes.string,
	hasOpenPOs: PropTypes.bool,
	hasPendingInvoices: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	invoices: PropTypes.array,
	invoicesLoading: PropTypes.bool,
	isDesynced: PropTypes.bool,
	isProject: PropTypes.bool,
	projectCostingLoading: PropTypes.bool,
	purchaseOrdersLoading: PropTypes.bool,
	remainingToBill: PropTypes.number,
	setProgressActiveStep: PropTypes.func,
	toggleClosureModal: PropTypes.func,
	toggleRatingConfirmationModal: PropTypes.func
};

const mapStateToProps = (state) => {
	const view = state.work.workRecord.view;
	return {
		activeStep: view.overview.progressStep.activeStep,
		hasPendingInvoices: view.BillingCenter.hasPendingInvoices,
		hasOpenPOs: view.ProjectCosting.projectCostingWO.purchaseOrdersGrid.hasOpenPOs,
		id: view.id,
		invoices: view.BillingCenter.invoiceRowData,
		isDesynced: view.record.isDesynced,
		isProject: view.record.isProject,
		projectCostingLoading: view.ProjectCosting.projectCostingPJ.loading,
		invoicesLoading: view.BillingCenter.invoiceLoading,
		purchaseOrdersLoading: view.ProjectCosting.projectCostingWO.purchaseOrdersLoading,
		remainingToBill: view.ProjectCosting.projectCostingPJ.summaryFields.marginGP[4].value,
		actualCost: view.ProjectCosting.projectCostingPJ.summaryFields.cost[4].value,
		workOrder: view.record,
		unratedAasdis: view.record.unratedAasdis
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setProgressActiveStep: (stage) => {
			dispatch(setProgressActiveStep(stage));
		},
		toggleClosureModal: (stage) => {
			dispatch(toggleClosureModal(stage));
		},
		toggleRatingConfirmationModal: () => {
			dispatch(toggleRatingConfirmationModal());
		},
		toggleCanceledModal: () => {
			dispatch(toggleCanceledModal());
		},
		toggleCBWModal: () => {
			dispatch(toggleCBWModal());
		},
		toggleCreateRatingModal: (status) => {
			dispatch(toggleCreateRatingModal(status));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOverviewProgress);
