import PropTypes from 'prop-types';
import './Flex.css';

export const Row = (props) => {
	return (
		<div {...getProps('row', props)}>
			{props.children}
		</div>
	);
};

export const Column = (props) => {
	return (
		<div {...getProps('column', props)}>
			{props.children}
		</div>
	);
};

function getProps(direction, props) {
	const {style, className, wrap, align, reverse, justify, alignItems, ...rest} = props;
	const newStyle = {...style}

	if (!props.wrap && props.align) {
		throw new Error('Cannot specify align unless wrap is set. It will have no affect.');
	}

	newStyle.display = 'flex';
	newStyle.flexDirection = direction + (props.reverse ? '-reverse' : '');
	newStyle.flexWrap = props.wrap ? 'wrap' : 'nowrap';
	newStyle.justifyContent = prefixStyle(props.justify);
	newStyle.alignItems = prefixStyle(props.alignItems);
	newStyle.alignContent = prefixStyle(props.align);

	const classes = [className];
	if (props.even) classes.push('omni-flex-even');
	if (props.grow) classes.push('omni-flex-grow');

	return {style: newStyle, className: classes.join(' '), ...rest};
}

function prefixStyle(val) {
	if (val === 'start' || val === 'end') {
		return 'flex-' + val;
	}
	return val;
}

Row.propTypes = Column.propTypes = {
	even: PropTypes.bool,
	grow: PropTypes.bool,
	wrap: PropTypes.bool,
	reverse: PropTypes.bool,
	justify: PropTypes.oneOf(['start', 'center', 'end', 'space-between', 'space-around', 'space-evenly']),
	align: PropTypes.oneOf(['start', 'center', 'end', 'stretch', 'space-between', 'space-around']),
	alignItems: PropTypes.oneOf(['start', 'center', 'end', 'stretch', 'baseline']),
	style: PropTypes.object,
	children: PropTypes.any
};
