import React from 'react';

const AutofillData = (props) => (
	<div>
		{props.field.selectedAASDI.name ? (
			<ul
				style={{
					listStyle: 'none',
					paddingLeft: '0em',
					marginTop: '1em',
					marginBottom: '0'
				}}
			>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Name:
					</span>
					{props.field.selectedAASDI.name}
				</li>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Address:
					</span>
					{`${props.field.selectedAASDI.address}, ${props.field.selectedAASDI.city}, ${props.field.selectedAASDI.state}, ${props.field.selectedAASDI.zip}`}
				</li>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Website:
					</span>
					{props.field.selectedAASDI.website}
				</li>
			</ul>
		) : null}
	</div>
);

export default AutofillData;
