import React from 'react';
import {Popup, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const OrderLinkRenderer = (props) => (
	<div style={{textAlign: 'center'}}>
		<Popup
			trigger={
				<Link to={`/work/view/${props.data.id}`} target="_blank">
					<Button
						icon="linkify"
						style={{
							backgroundColor: 'transparent',
							padding: '.5em .5em .5em',
							marginLeft: '.5em'
						}}
					/>
				</Link>
			}
			content={'View Work Details'}
			position="top center"
		/>
	</div>
);

export default OrderLinkRenderer;
