import React from 'react';
import {Grid, Divider} from 'semantic-ui-react';
import Statistics from '../../components/statistics/Statistics.js';
import Filters from '../../../../components/filters/Filters.js';
import AGGrid from '../../../../components/aggrid/AGGrid.js';

let GridView = () => [
	<Filters key={'page-filters'} />,
	<Divider key={'page-filters-divider1'} />,
	<Grid.Row key={'pipeline-statistics-grid'}>
		<Statistics.Quick type={'grid'} />
	</Grid.Row>,
	<Divider key={'page-filters-divider2'} />,
	<Grid.Row key={'pipeline-ag-grid1'}>
		<AGGrid key={'pipeline-ag-grid2'} />
	</Grid.Row>
];

export default GridView;
