import React from 'react';
import {connect} from 'react-redux';
import {Icon, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import * as actions from '../../../../../services/workRecordActions.js';

let SubmitBillButton = (props) => {
	const locked = props.isDesynced || props.blockBilling;
	return props.data.status === 'Awaiting Submission' ? (
		<Popup
			trigger={
				<Icon
					color={locked ? 'black' : 'green'}
					disabled={Boolean(locked)}
					name="angle double right"
					style={{
						backgroundColor: 'transparent',
						fontSize: '1.6em',
						cursor: 'pointer'
					}}
					onClick={() => {
						if (!locked) {
							props.actionBillModalToggle(props.data, 'submitBill', props.rowIndex);
						}
					}}
				/>
			}
			position="left center"
			content={locked ? 'Desynced or Locked' : 'Submit Bill'}
		/>
	) : null;
};

SubmitBillButton.propTypes = {
	actionBillModalToggle: PropTypes.object,
	isDesynced: PropTypes.bool,
	blockBilling: PropTypes.bool,
	data: PropTypes.object,
	rowIndex: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		isDesynced: state.work.workRecord.view.record.desynced,
		blockBilling: state.work.workRecord.view.record.isBillingBlocked
	};
};

export default connect(mapStateToProps, actions)(SubmitBillButton);
