import React from 'react';
import {Segment, Grid, Header, Label, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const Sales = (props) => (
	<Segment loading={false}>
		<Label color="blue" ribbon>
			Sales
		</Label>
		<Divider />
		<Grid>
			<Grid.Row>
				<Grid.Column style={{margin: '1vh'}} width={6}>
					<Header as="h3">
						<Header.Content>YTD Sales</Header.Content>
					</Header>
					<span>{props.ytd}</span>
				</Grid.Column>
				<Grid.Column style={{margin: '1vh'}} width={6}>
					<Header as="h3">
						<Header.Content>Previous Year Sales</Header.Content>
					</Header>
					<span>{props.lastYear}</span>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</Segment>
);

Sales.propTypes = {
	isLoading: PropTypes.bool,
	details: PropTypes.object.isRequired,
	salesData: PropTypes.object,
	lastYear: PropTypes.number,
	ytd: PropTypes.number
};

Sales.defaultProps = {
	isLoading: true,
	details: {}
};

const mapStateToProps = (state) => ({
	lastYear:
		state.work.aasdiProfileGrid.aasdiData[0] &&
		state.work.aasdiProfileGrid.aasdiData[0].previousYearSales !== null &&
		state.work.aasdiProfileGrid.aasdiData[0].previousYearSales !== undefined
			? `$${state.work.aasdiProfileGrid.aasdiData[0].previousYearSales}`
			: 'No Data Available From Spectrum',
	ytd:
		state.work.aasdiProfileGrid.aasdiData[0] &&
		state.work.aasdiProfileGrid.aasdiData[0].ytdSales !== null &&
		state.work.aasdiProfileGrid.aasdiData[0].ytdSales !== undefined
			? `$${state.work.aasdiProfileGrid.aasdiData[0].ytdSales}`
			: 'No Data Available From Spectrum'
});

export default connect(mapStateToProps, null)(Sales);
