import React from 'react';
import {Label, TextArea} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {updateCreateWorkOrderModal} from '../../services/createWorkOrderActions.js';

const InputField = (props) => (
	<div
		style={{
			paddingTop: '0.8em',
			zIndex: '10'
		}}
	>
		<Label
			style={{
				backgroundColor: 'transparent',
				fontSize: '1rem',
				color: 'rgba(0,0,0,.8)',
				paddingLeft: '0'
			}}
		>
			{props.field.name}
		</Label>
		<TextArea
			className="scrollingTextArea"
			autoHeight
			onChange={(e) => {
				props.updateCreateWorkOrderModal(
					'createWorkOrderModal',
					props.sectionIndex,
					props.fieldIndex,
					e.target.value,
					false
				);
			}}
			value={props.field.value}
			error={props.field.required ? Boolean(props.field.error) : false}
			disabled={props.field.disabled}
			placeholder={props.field.placeholder}
		/>
	</div>
);

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateWorkOrderModal: (modal, sectionIndex, fieldIndex, value, error, inputType, data) => {
			dispatch(updateCreateWorkOrderModal(modal, sectionIndex, fieldIndex, value, error, inputType, data));
		}
	};
};

export default connect(null, mapDispatchToProps)(InputField);
