// Modules
import React from 'react';
import {Segment, Divider, Input, Label, Dropdown, Feed, Popup} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Components
import ActivityStreamEvent from './components/Event.js';
import FilterLabel from './components/FilterLabel.js';

// Service
import * as actions from './services/activityStreamActions.js';

class ActivityStream extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			comment: '',
			logType: 'Note'
		};
	}

	componentDidMount() {
		this.props.getActivityStreamEvents(this.props.id, this.props.type);
	}

	render() {
		let events = this.props.activityStream;
		events.forEach((event) => {
			event.header = event.logType === 'Update' ? ` logged an ${event.logType}` : ` logged a ${event.logType}`;
		});
		if (this.props.filter !== 'Total') {
			console.log(`if no total: ${JSON.stringify(events, null, 2)}`);
			events = events.filter((event) => event.logType === this.props.filter);
		}

		return (
			<Segment>
				<Label color="blue" ribbon>
					Activity Stream
				</Label>
				<Divider />
				<Popup
					trigger={
						<Input
							fluid
							id={'stream-input'}
							action={
								<Popup
									trigger={
										<Dropdown
											button
											basic
											floating
											options={this.props.options}
											defaultValue="Note"
											onChange={(event, {value}) => {
												this.setState(() => {
													return {
														logType: value
													};
												});
											}}
										/>
									}
									header="Add an event to the stream."
									content="Select an event type to log."
								/>
							}
							icon="file"
							iconPosition="left"
							placeholder="Info..."
							onChange={(e, {value}) => {
								this.setState(() => {
									return {
										comment: value
									};
								});
							}}
							onKeyPress={(event) => {
								if (event.key === 'Enter' && this.props.loading === false) {
									this.props.postActivityStreamEvent(
										this.props.id,
										this.props.type,
										this.state.comment,
										this.state.logType
									);
								}
							}}
						/>
					}
					header="Add an event to the stream."
					content="After selecting your event type, type a message and hit enter."
				/>

				<Divider />
				{this.props.error ? (
					<Segment textAlign="center" basic>
						Hmmm, it looks like something went wrong when I was trying to pull this data. Try again, or
						contact the Dev team if it keeps happening
					</Segment>
				) : this.props.activityStream.length > 0 ? (
					[
						<Label.Group key={'activityStreamEventFilter'} color="blue">
							{this.props.activityStreamMetrics.map((metric) => (
								<FilterLabel key={`metric-${metric.logType}`} {...metric} filter={this.props.filter} />
							))}
						</Label.Group>,
						<Divider key={`activityStreamEvent-divider`} />,
						<Feed
							key={`activityStreamEvent-feed`}
							style={{
								maxHeight: '70vh',
								overflowY: 'auto'
							}}
						>
							{events.map((activityStreamEvent) => (
								<ActivityStreamEvent
									type={this.props.type}
									key={`event-${activityStreamEvent.id}}`}
									{...activityStreamEvent}
									oppId={this.props.id}
								/>
							))}
						</Feed>
					]
				) : (
					<Segment textAlign="center" basic loading={this.props.loading}>
						There are currently no events to display.
					</Segment>
				)}
			</Segment>
		);
	}
}

ActivityStream.propTypes = {
	activityStream: PropTypes.array,
	filter: PropTypes.string,
	fetched: PropTypes.bool,
	error: PropTypes.object,
	loading: PropTypes.bool,
	id: PropTypes.string,
	getActivityStreamEvents: PropTypes.func,
	postActivityStreamEvent: PropTypes.func,
	contentRef: PropTypes.object,
	activityStreamMetrics: PropTypes.array,
	type: PropTypes.string,
	options: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		activityStream: state.global.activityStream.fetched ? state.global.activityStream.events : [],
		filter: state.global.activityStream.fetched ? state.global.activityStream.filter : 'Total',
		fetched: state.global.activityStream.fetched,
		loading: state.global.activityStream.loading,
		error: state.global.activityStream.error,
		activityStreamMetrics: state.global.activityStream.fetched ? state.global.activityStream.metrics : []
	};
};

export default connect(mapStateToProps, actions)(ActivityStream);
