import React from 'react';
import {Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const DocumentSectionRemoveButton = (props) => (
	// isMobileDriveLink check is used to not show the remove option for mobile drive link
	<div style={{paddingTop: '.15em'}}>
		{!props.data.isMobileDriveLink && (
			<Icon
				name="cancel"
				color="red"
				size="large"
				style={{cursor: 'pointer'}}
				onClick={() => {
					props.agGridReact.props.removeWorkViewDocument(props.rowIndex, props.data.id, props.data.workId);
				}}
			/>
		)}
	</div>
);

DocumentSectionRemoveButton.propTypes = {
	agGridReact: PropTypes.object,
	data: PropTypes.object,
	rowIndex: PropTypes.number
};

export default DocumentSectionRemoveButton;
