import React from 'react';
import {Tab, Table, Checkbox, Input, Icon, Container, Popup, Visibility, Loader} from 'semantic-ui-react';

import {connect} from 'react-redux';

import Footer_Message from '../../../components/Global/Messages.js';
import Select_All_Button from './Button_SelectAll.js';
import Approve_User from './Button_ApproveUser.js';
import Refresh_Users from './Button_RefreshUsers.js';
import To_Top from '../../../components/Global/Button_ToTop.js';

import * as actions from '../services/techManagementActions.js';

const Approvals = (props) => (
	<Tab.Pane loading={props.loading}>
		<Container fluid className={'techManagementUserButtonGroupPadding TechManagement-ButtonRow Parent-Container'}>
			<span className="TechManagement-ButtonRow">
				<Popup trigger={<Select_All_Button approvals={true} {...props} />} content="Select all" on="hover" />
				<Popup trigger={<Refresh_Users approvals={true} {...props} />} content="Refresh table" on="hover" />
			</span>

			<Approve_User {...props} />
		</Container>
		<Visibility
			onBottomVisible={() => props.getApprovals(props.token)}
			onTopVisible={() => props.toggleToTop(false, props.approvals)}
			onTopPassed={() => props.toggleToTop(true, props.approvals)}
			once={false}
		>
			<Table sortable selectable striped>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						<Table.HeaderCell
							sorted={props.columnsApprovals === 'Email' ? props.directionApprovals : null}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'Email',
									type: 'approvals'
								})
							}
						>
							Email
						</Table.HeaderCell>
						<Table.HeaderCell>Approval Status</Table.HeaderCell>
						<Table.HeaderCell
							sorted={props.columnsApprovals === 'AASDI' ? props.directionApprovals : null}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'AASDI',
									type: 'approvals'
								})
							}
						>
							AASDI
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={props.columnsApprovals === 'UserCreateDateHidden' ? props.directionApprovals : null}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'UserCreateDateHidden',
									type: 'approvals'
								})
							}
						>
							User Create Date
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Table.Row className={props.filtering.toggle === true ? 'toggleFilterOn' : 'toggleFilterOff'}>
						<Table.Cell>
							<Popup
								trigger={
									<Icon
										name="delete"
										color="red"
										fitted
										className="clearFilter"
										onClick={() => props.clearFilter()}
									/>
								}
								content="Clear the filter."
								on="hover"
							/>
						</Table.Cell>
						<Table.Cell>
							<Input
								fluid
								placeholder="Search..."
								value={props.filtering.users.Username}
								onChange={(event) => {
									let value = event.target.value;
									let label = 'username';
									props.updateUserFilter({
										value,
										label,
										type: 'approvals'
									});
								}}
							/>
						</Table.Cell>
						<Table.Cell>
							<Input
								fluid
								placeholder="Search..."
								value={props.filtering.users.Approved}
								onChange={(event) => {
									let value = event.target.value;
									let label = 'Approved';
									props.updateUserFilter({
										value,
										label,
										type: 'approvals'
									});
								}}
							/>
						</Table.Cell>
					</Table.Row>
					{props.approvals.approvals.map(({Approved, Checked, AASDI, Email, UserCreateDate}) => (
						<Table.Row key={Email}>
							<Table.Cell collapsing>
								<Checkbox toggle checked={Checked} onChange={() => props.toggleUser(Email, true)} />
							</Table.Cell>
							<Table.Cell>{Email}</Table.Cell>
							<Table.Cell>{Approved}</Table.Cell>
							<Table.Cell>{AASDI}</Table.Cell>
							<Table.Cell>{UserCreateDate}</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			<Footer_Message {...props} />
			<Loader active={props.usersLoading} inline="centered" />
		</Visibility>
		<Popup trigger={<To_Top {...props} />} content="Scroll to top of page." on="hover" />
	</Tab.Pane>
);

const mapStateToProps = (state) => {
	return {
		loading: state.global.techManagement.loading,
		usersLoading: state.global.techManagement.users.loading,
		token: state.global.techManagement.approvals.token,
		approvals: state.global.techManagement.approvals,
		directionApprovals: state.global.techManagement.sorting.directions.approvals,
		columnsApprovals: state.global.techManagement.sorting.columns.approvals,
		filtering: state.global.techManagement.filtering,
		techManagement: state.global.techManagement,
		global: state.global.global
	};
};

export default connect(mapStateToProps, actions)(Approvals);
