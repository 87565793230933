const conversionTypeOptions = [
	{
		key: 1,
		text: 'Design',
		value: 1
	},
	{
		key: 2,
		text: 'Build',
		value: 2
	},
	{
		key: 3,
		text: 'None',
		value: 3
	}
];

export default conversionTypeOptions;
