import React, {useState, useEffect} from 'react';
import {Segment, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../../../../services/workRecordActions.js';
import {FancyEditor} from '../../../../../../../../../../components/inputs/FancyEditor.jsx';

const SowSection = (props) => {
	const [dirty, setDirty] = useState(false)
	const [editedValue, setEditedValue] = useState(null)

		return (
			<Segment loading={props.sow.loading}>
				<Label color="blue" ribbon style={{marginBottom: '1vh'}}>
					SOW
				</Label>
				{dirty ? (
					<Label attached="top right" color="red" size="small">
						Unsaved
					</Label>
				) : null}
				<div style={{height: 500}}>
					<FancyEditor
						value={dirty ? editedValue : props.sow.value}
						onChange={(value) => {
							setEditedValue(value)
							setDirty(true)
						}}
						onSave={() => {
							props.updateWorkSOW(props.id, editedValue);
							setDirty(false)
						}}
					/>
				</div>
			</Segment>
		);
	}

SowSection.propTypes = {
	updateWorkSOW: PropTypes.func,
	sow: PropTypes.object,
	getWorkSOW: PropTypes.func,
	id: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		sow: state.work.workRecord.view.overview.sow
	};
};

export default connect(mapStateToProps, actions)(SowSection);
