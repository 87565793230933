import React from 'react';
import {connect} from 'react-redux';
import {Popup, Button, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

// Services
import {
	toggleRejectModal,
	selectRecord,
	toggleDesyncedMessage
} from '../../../../../../../services/accountingConsoleActions.js';

const RejectButton = (props) => {
	return (
		<Popup
			trigger={
				<Button
					style={{
						backgroundColor: '#e8e8e8',
						padding: '.5em .5em .5em',
						marginLeft: '.25em'
					}}
					onClick={() => {
						if (props.record.lastSyncError) {
							// is desynced
							props.toggleDesyncedMessage(true);
							setTimeout(() => {
								props.toggleDesyncedMessage(false);
							}, 5000);
						} else {
							// is not desynced
							props.selectRecord(props.record);
							props.toggleRejectModal(props.rejectModal !== true);
						}
					}}
				>
					<Icon name="minus circle" color="red" style={{cursor: 'pointer', margin: 0}} />
				</Button>
			}
			content={props.record.lastSyncError ? 'Desynced' : 'Reject'}
		/>
	);
};

RejectButton.propTypes = {
	toggleRejectModal: PropTypes.func,
	rejectModal: PropTypes.bool,
	selectedRow: PropTypes.number,
	selectRecord: PropTypes.func,
	selectedArray: PropTypes.array,
	toggleDesyncedMessage: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		rejectModal: state.accountingConsoleReducer.accountingConsole.rejectModalToggle,
		selectedArray:
			state.accountingConsoleReducer.accountingConsole.selectedCategory.data[
				state.accountingConsoleReducer.accountingConsole.selectedCategory.data.findIndex(
					(category) =>
						category.name === state.accountingConsoleReducer.accountingConsole.selectedCategory.name
				)
			].rowData
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleRejectModal: (value) => {
			dispatch(toggleRejectModal(value));
		},
		selectRecord: (record) => {
			dispatch(selectRecord(record));
		},
		toggleDesyncedMessage: (value) => {
			dispatch(toggleDesyncedMessage(value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectButton);
