import React from 'react';
import {Segment, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';

export default function ClosureComments(props) {
	return (
		<>
			<Segment>
				<div>
					<Label color={'lightBlue'} ribbon style={{marginBottom: '2vh'}}>
						{props.stage === 'Closed' ? 'Closing Comments' : 'Closing by Warranty Comments'}
					</Label>
				</div>
				{props.comment}
			</Segment>
		</>
	);
}
