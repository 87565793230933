import React, {Component} from 'react';
import {Label, Segment, Grid, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../../../../../components/Global/AgGrid.js';
import FilterGrid from '../../../../../../../components/Global/FilterGrid.js';
import ExportGrid from '../../../../../../../components/Global/ExportGrid.js';
import {toggleFetchingOnTabsNavbar} from '../../../../../../../components/Global/services/globalActions.js';
import {getTabRequestItems, clearGrid} from '../services/profileGridActions.js';
import ClientWorkTabCellRenderer from './ClientWorkTabCellRenderer.js';
import {checkIdenticalsArrays} from '../../../../../../../lib/old/validation.js';

const frameworkComponents = {
	clientWorkTabCellRenderer: ClientWorkTabCellRenderer
};

class WorkTab extends Component {
	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
		const {clientId} = this.props;
		this.props.clearGrid('Work');
		this.gridApi.showLoadingOverlay();
		this.props.getTabRequestItems(clientId, 'Work');
		this.props.toggleFetchingOnTabsNavbar(true);
	};

	componentDidUpdate(prevProps) {
		const {profileGridData, profileGridHeader, toggleFetchingOnTabsNavbar} = this.props;
		if (prevProps.profileGridData === null && profileGridData) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.profileGridHeader, profileGridHeader)) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.rowData, profileGridData)) {
			toggleFetchingOnTabsNavbar(false);
		}
	}

	render() {
		const {profileGridData, profileGridHeader} = this.props;
		return (
			<Segment basic style={{marginTop: '1.5rem', border: '1px solid #DEDEDE'}}>
				<Label color="blue" ribbon>
					Work
				</Label>
				<Divider />
				<Grid style={{padding: '2em 0'}}>
					<FilterGrid gridApi={() => this.gridApi} />
					<ExportGrid gridApi={() => this.gridApi} />
				</Grid>
				<AgGrid
					columnDefs={profileGridHeader}
					rowData={profileGridData}
					onGridReady={this.onGridReady}
					frameworkComponents={frameworkComponents}
				/>
			</Segment>
		);
	}
}

WorkTab.propTypes = {
	profileGridData: PropTypes.array,
	clientProfileInfo: PropTypes.object,
	getTabRequestItems: PropTypes.func,
	clientId: PropTypes.string,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	profileGridHeader: PropTypes.array,
	clearGrid: PropTypes.func
};

const mapStateToProps = (state) => ({
	profileGridData: state.work.clientProfileGrid.rowData,
	clientProfileInfo: state.work.clientProfileGrid.profileInfos,
	stageNames: state.work.clientProfileGrid.stageNames,
	profileGridHeader: state.work.clientProfileGrid.headerData
});

const mapDispatchToProps = (dispatch) => ({
	getTabRequestItems: (clientId, item, mock) => dispatch(getTabRequestItems(clientId, item, mock)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	clearGrid: (item) => dispatch(clearGrid(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkTab);
