import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {AgGridReact} from '@ag-grid-community/react';

import {IconButton} from '../iconButtons/IconButton.jsx';
import {Switch} from '../iconButtons/Switch.jsx';
import {ActiveSwitch} from '../iconButtons/ActiveSwitch.jsx';
import { roundFloat } from '~/util/numbers.ts';
import {calcMargin, calcRevenueFromMargin} from '../../util/moneymath.ts';
import {fmtPercent, fmtMoney} from '../../util/formatting.js';

/*
TRUTH TABLE FOR EDITING BEHAVIOR: (please keep updated)
Cost never updates, this is because they have contracts where cost is set to a specific amount and never changes.
if fixedRevenue:
	editing cost 	updates margin
	editing margin 	updates revenue
	editing revenue updates margin
else: (fixedMargin)
	editing cost 	updates revenue
	editing margin 	updates revenue
	editing revenue updates margins
*/

const sharedPinnedColProps = {
	editable: false,
	resizable: false,
	sortable: false,
	suppressMenu: true,
	suppressSizeToFit: true,
	suppressContextMenu: true,
	suppressColumnsToolPanel: true,
	suppressFiltersToolPanel: true
};

//TODO: SKUGrid should not give row index to callbacks because its unreliable if the grid is sorted
export class SKUGrid extends React.Component {
	static propTypes = {
		data: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.any.isRequired,
				description: PropTypes.string.isRequired,
				manufacturer: PropTypes.string.isRequired,
				partNumber: PropTypes.string.isRequired,
				unit: PropTypes.oneOf(['FT', 'EA']).isRequired,
				quantity: PropTypes.number.isRequired,
				unitMaterialCost: PropTypes.number.isRequired,
				materialResell: PropTypes.number.isRequired,
				unitLaborCost: PropTypes.number.isRequired,
				isFixedRevenue: PropTypes.bool.isRequired,
				isActive: PropTypes.bool, //not required, only there for skus
				laborResell: PropTypes.number.isRequired
			})
		).isRequired,
		onRowRemove: PropTypes.func, //can only happen if canRemove = true
		onDataChange: PropTypes.func, //(index: integer, changes: object)
		loading: PropTypes.bool,

		//controls what actions get rendered
		canRemove: PropTypes.bool,
		canDeactivate: PropTypes.bool,
		canChangeFixed: PropTypes.bool,

		selectable: PropTypes.bool,
		onSelectionChange: PropTypes.func
	};

	constructor() {
		super(...arguments);
		this.gridRef = React.createRef();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.loading && this.props.loading && this.gridRef.current) {
			this.gridRef.current.api.showLoadingOverlay();
		}
	}

	emitChange = (index, field, value) => {
		this.props.onDataChange(index, _.set({}, field, value));
		return false;
	};

	updateFloatField = (params) => {
		const newNumber = roundFloat(toNumber(params.newValue), 2);
		return this.emitChange(params.node.rowIndex, params.colDef.field, newNumber);
	};

	updateRawField = (params) => {
		return this.emitChange(params.node.rowIndex, params.colDef.field, params.newValue);
	};

	getColumns = () => {
		const columns = [
			{
				headerName: 'Description',
				field: 'description',
				editable: !this.props.selectable,
				valueSetter: this.updateRawField
			},
			{
				headerName: 'Manufacturer',
				field: 'manufacturer',
				editable: !this.props.selectable,
				valueSetter: this.updateRawField
			},
			{
				headerName: 'Part Number',
				field: 'partNumber',
				filter: 'agTextColumnFilter',
				editable: !this.props.selectable,
				valueSetter: this.updateRawField
			},
			{
				headerName: 'Unit',
				field: 'unit',
				cellEditor: 'agRichSelectCellEditor',
				cellEditorParams: {
					values: ['FT', 'EA']
				},
				editable: !this.props.selectable,
				valueSetter: this.updateRawField
			},
			{
				headerName: 'Quantity',
				field: 'quantity',
				filter: 'agNumberColumnFilter',
				editable: !this.props.selectable,
				valueSetter: ({node, newValue, data}) => {
					//I feel like this value should be restricted to a whole number if unit === 'EA'
					//just keeping it how it is for now
					newValue = roundFloat(toNumber(newValue), 2);
					if (newValue <= 0) {
						return false;
					}
					const originalMaterialMargin = calcMargin(
						data.unitMaterialCost * data.quantity,
						data.materialResell
					);
					const originalLaborMargin = calcMargin(data.unitLaborCost * data.quantity, data.laborResell);
					this.props.onDataChange(node.rowIndex, {
						quantity: newValue,
						materialResell: calcRevenueFromMargin(newValue * data.unitMaterialCost, originalMaterialMargin),
						laborResell: calcRevenueFromMargin(newValue * data.unitLaborCost, originalLaborMargin)
					});
				}
			},
			{
				headerName: 'Material Unit Cost',
				field: 'unitMaterialCost',
				filter: 'agNumberColumnFilter',
				editable: !this.props.selectable,
				valueFormatter: ({value}) => fmtMoney(value),
				valueSetter: ({newValue, data, node}) => {
					newValue = roundFloat(toNumber(newValue), 2);
					const changes = {unitMaterialCost: newValue};
					if (!data.isFixedRevenue) {
						//need to recalculate old margin because we dont store it.
						const originalMargin = calcMargin(data.unitMaterialCost * data.quantity, data.materialResell);
						//impossible to match 100% margin
						if (originalMargin !== 1) {
							//calculate resell at original margin
							changes.materialResell = calcRevenueFromMargin(newValue * data.quantity, originalMargin);
						}
					}
					this.props.onDataChange(node.rowIndex, changes);
				}
			},
			{
				headerName: 'Material Margin',
				colId: 'markupMaterial',
				filter: 'agNumberColumnFilter',
				editable: ({node}) => !this.props.selectable && node.data.unitMaterialCost !== 0,
				valueFormatter: ({value}) => fmtPercent(value),
				valueGetter: (params) => {
					const cost = params.getValue('unitMaterialCost') * params.getValue('quantity');
					const revenue = params.getValue('materialResell');
					return roundFloat(calcMargin(cost, revenue) * 100, 2);
				},
				valueSetter: ({newValue, data, node}) => {
					const margin = toNumber(newValue) / 100;
					if (margin >= 1) {
						return false;
					}
					//some of this logic is duplicated in the applyMaterialMarkup action.
					//In hindsight I should have created a SKU class to contain a lot of this logic.
					//I want to do that sometime in the future but dont want to make this task take longer
					let newRevenue = calcRevenueFromMargin(data.unitMaterialCost * data.quantity, margin);
					return this.emitChange(node.rowIndex, 'materialResell', roundFloat(newRevenue, 2));
				}
			},
			{
				headerName: 'Material Revenue',
				field: 'materialResell',
				filter: 'agNumberColumnFilter',
				editable: !this.props.selectable,
				valueFormatter: ({value}) => fmtMoney(value),
				valueSetter: this.updateFloatField
			},
			{
				headerName: 'Labor Unit Cost',
				field: 'unitLaborCost',
				filter: 'agNumberColumnFilter',
				editable: !this.props.selectable,
				valueFormatter: ({value}) => fmtMoney(value),
				valueSetter: ({newValue, data, node}) => {
					newValue = roundFloat(toNumber(newValue), 2);
					const changes = {unitLaborCost: newValue};
					if (!data.isFixedRevenue) {
						//need to recalculate old margin because we dont store it.
						const originalMargin = calcMargin(data.unitMaterialCost * data.quantity, data.laborResell);
						//calculate resell at original margin
						if (originalMargin !== 1) {
							changes.laborResell = calcRevenueFromMargin(newValue * data.quantity, originalMargin);
						}
					}
					this.props.onDataChange(node.rowIndex, changes);
				}
			},
			{
				headerName: 'Labor Margin',
				field: 'markupLabor',
				filter: 'agNumberColumnFilter',
				editable: ({node}) => !this.props.selectable && node.data.unitMaterialCost !== 0,
				valueFormatter: ({value}) => fmtPercent(value),
				valueGetter: (params) => {
					const cost = params.getValue('unitLaborCost') * params.getValue('quantity');
					const revenue = params.getValue('laborResell');
					return roundFloat(calcMargin(cost, revenue) * 100, 2);
				},
				valueSetter: ({newValue, data, node}) => {
					const margin = toNumber(newValue) / 100;
					if (margin >= 1) {
						return false;
					}
					let newRevenue = calcRevenueFromMargin(data.unitLaborCost * data.quantity, margin);
					return this.emitChange(node.rowIndex, 'laborResell', roundFloat(newRevenue, 2));
				}
			},
			{
				headerName: 'Labor Revenue',
				field: 'laborResell',
				filter: 'agNumberColumnFilter',
				editable: !this.props.selectable,
				valueFormatter: ({value}) => fmtMoney(value),
				valueSetter: this.updateFloatField
			},
			{
				headerName: 'Total Revenue',
				filter: 'agNumberColumnFilter',
				valueFormatter: ({value}) => fmtMoney(value),
				editable: !this.props.selectable,
				valueGetter: ({getValue}) => getValue('laborResell') + getValue('materialResell')
			}
		];
		if (this.props.canRemove) {
			columns.push({
				cellStyle: {padding: '0px 3px'},
				cellRendererFramework: (params) => {
					return <IconButton name="cancel" color="red" onClick={this.props.onRowRemove} node={params.node} />;
				},
				width: 30,
				pinned: 'right',
				...sharedPinnedColProps
			});
		}
		if (this.props.canDeactivate) {
			columns.push({
				cellStyle: {padding: '0px 3px'},
				cellRendererFramework: (props) => (
					<ActiveSwitch
						toggled={props.data.isActive}
						onClick={() => this.emitChange(props.node.rowIndex, 'isActive', !props.data.isActive)}
					/>
				),
				headerTooltip: 'Active',
				tooltip: () => 'Active',
				width: 30,
				pinned: 'left',
				...sharedPinnedColProps
			});
		}
		if (this.props.canChangeFixed) {
			columns.push({
				cellStyle: {padding: '0px 3px'},
				cellRendererFramework: (props) => (
					<Switch
						toggled={props.data.isFixedRevenue}
						onText="Fixed Revenue"
						offText="Fixed Margin"
						onClick={() =>
							this.emitChange(props.node.rowIndex, 'isFixedRevenue', !props.data.isFixedRevenue)
						}
					/>
				),
				width: 120,
				pinned: 'left',
				...sharedPinnedColProps
			});
		}

		return columns;
	};

	onGridReady = (params) => {
		const colIds = params.columnApi
			.getAllColumns()
			.filter((column) => !column.colDef.suppressSizeToFit)
			.map((column) => column.colId);
		params.columnApi.autoSizeColumns(colIds);
	};

	render() {
		return (
			<div className="ag-theme-balham" style={{height: '100%'}}>
				<AgGridReact
					ref={this.gridRef}
					deltaRowDataMode
					animateRows
					rowSelection={this.props.selectable ? 'multiple' : undefined}
					onSelectionChanged={(event) => {
						const selected = event.api.getSelectedNodes().map((row) => row.data);
						this.props.onSelectionChange(selected);
					}}
					rowMultiSelectWithClick
					suppressCopyRowsToClipboard
					suppressContextMenu
					enableCellChangeFlash
					autoSizePadding={0}
					getRowNodeId={(data) => data.id}
					onGridReady={this.onGridReady}
					rowData={this.props.loading ? null : this.props.data}
					enableBrowserTooltips
					defaultColDef={{
						sortable: true,
						filter: true,
						resizable: true
					}}
					columnDefs={this.getColumns()}
				/>
			</div>
		);
	}
}

function toNumber(numstr) {
	const num = Number.parseFloat(numstr);
	if (Number.isNaN(num)) {
		return 0;
	}
	return num;
}

export default SKUGrid;
