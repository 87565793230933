import { createAppState } from "../lib/AppState";

//Add new properties here
interface AppState {
    reportBugModalOpen: boolean
}

//Add default state here
const appInitialState = {
    reportBugModalOpen: false
}

export const {AppStateProvider, useAppState} = createAppState<AppState>(appInitialState)