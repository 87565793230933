import React, {Component} from 'react';
import moment from 'moment';

export default class tripInfoRenderer extends Component {
	render() {
		let aasdis = [];
		if (this.props.value) {
			aasdis = JSON.parse(this.props.value.replace(/\\|\\*amp;/g, ''));
		}

		// puts all the trips from all of the aasdis into an array
		let trips = [];
		aasdis.forEach((aasdi) => {
			trips = trips.concat(aasdi.trips);
		});

		// loops through all trips to find the closest trip actual date to today that hasn't already passed
		// default is used in nearestTrip to determine if the var has been updated
		let nearestTrip = {default: 1, actualDate: moment('Jan 1 2222', 'll').format()};
		let today = moment().format();
		trips.forEach((trip) => {
			if (trip.actualDate) {
				let tripDate = moment(trip.actualDate, 'LLL').format();
				console.log('tripDate', tripDate);
				if (tripDate > today && tripDate < nearestTrip.actualDate) {
					nearestTrip = trip;
				}
			}
		});

		// creates and returns values based on headerName from columnDefs
		if (this.props.colDef.headerName === 'Next Scheduled Trip') {
			if (nearestTrip.default) {
				return 'No Date';
			} else {
				return <p>{moment(nearestTrip.actualDate, 'LLL').format('M/D/YYYY h:mm A')}</p>;
			}
		} else if ('Trip') {
			if (nearestTrip.default) {
				return null;
			} else {
				return <p>{nearestTrip.name}</p>;
			}
		}
	}
}
