import React, {Component} from 'react';
import {Segment, Grid, Header, Input, Dropdown, Label, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';
import PropTypes from 'prop-types';
import api from '../../lib/api.ts';
import {
	defineAasdiProfileFields,
	defineClientProfileFields
} from '../../scenes/work/scenes/aasdi/profile/services/profileAasdiGridReducer.js';
import {debounce, debounceEventHandler} from '../../lib/old/debounce.js';
import {setAasdiData} from '../../scenes/work/scenes/aasdi/profile/services/profileAasdiGridActions.js';

class ProfileDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			detailsFields: null
		};
		this.setUpdatedInfo = this.setUpdatedInfo.bind(this);
		this.defineInput = this.defineInput.bind(this);
		this.searchEmployee = this.searchEmployee.bind(this);
	}

	componentDidMount() {
		const {type, id, updateName, setAasdiData} = this.props;
		// /client/${id}
		// /aasdi/${id}
		api(`legacy/${type}/${id}`)
			.then((response) => {
				// TODO: track down why the /client/${id} response is nested and fix it everywhere
				const res = type === 'client' ? response[0][0] : response;
				const detailsFields =
					type === 'client' ? defineClientProfileFields(res) : defineAasdiProfileFields(res);
				this.setState({
					...this.state,
					detailsFields
				});
				const nameIndex = detailsFields.findIndex((field) => field.label === 'Name');
				const name = nameIndex !== -1 ? detailsFields[nameIndex].value : null;

				// TODO: Whether it's a client profile or AASDI profile, we're using functions,
				// actions, and reducers with misleading names (all use the word AASDI). This should
				// probably be refactored for clarity.
				updateName(name);

				setAasdiData(res);
			})
			.catch((error) => {
				console.error('Error while searching profile info: ', error);
			});
	}

	updateProfileInfo(body) {
		const {type, id} = this.props;
		api(`legacy/${type}/${id}`, 'put', body).catch((error) => {
			console.error('Error while updating profile info: ', error);
		});
	}

	setUpdatedInfo(value, inputIndex) {
		const {detailsFields} = this.state;
		detailsFields[inputIndex].value = value;
		this.setState({...this.state, detailsFields});
		let key;
		if (detailsFields[inputIndex].updateKey) {
			key = detailsFields[inputIndex].updateKey;
		} else {
			key = detailsFields[inputIndex].key;
		}

		const body = {};
		body[key] = value;
		this.updateProfileInfo(body);
		// check if profile name has changed
		if (key === 'name') {
			this.props.updateName(value);
		}
	}

	searchEmployee(value, key) {
		const fieldIndex = this.state.detailsFields.findIndex((f) => f.key === key);
		const field = this.state.detailsFields[fieldIndex];
		field.loading = true;
		this.setState((prevState) => ({...prevState, field}));

		api(`legacy/employee?searchName=${value}`, 'get')
			.then((response) => {
				const options = response.map((employee) => ({
					text: employee.fullName,
					value: employee.id
				}));
				field.loading = false;
				field.options = options;
				this.setState((prevState) => ({...prevState, field}));
			})
			.catch((error) => {
				console.error(error);
				field.loading = false;
				this.setState((prevState) => ({...prevState, field}));
			});
	}

	defineInput(item, index) {
		if (item.employee) {
			return (
				<Dropdown
					fluid
					className="hiddenInput"
					placeholder="Search for a sales representative"
					search
					scrolling
					loading={item.loading}
					options={item.options}
					value={item.value}
					onChange={(e, data) => {
						this.setUpdatedInfo(data.value, index);
					}}
					onSearchChange={debounceEventHandler(
						debounce((e) => {
							this.searchEmployee(e.target.value, item.key);
						}, 600)
					)}
				/>
			);
		}
		const {detailsFields} = this.state;
		const country = detailsFields.find((input) => input.key === 'countryId');
		const countryFlag = country && country.value === 218;
		if (item.disabled) {
			if (item.expired) {
				return (
					<p style={{color: '#DF4726'}}>
						{item.value !== null && item.value !== undefined ? item.value : ''}
					</p>
				);
			} else {
				return (
					<p style={{color: '#646564'}}>
						{item.value !== null && item.value !== undefined ? item.value : ''}
					</p>
				);
			}
		}

		if (item.isDropdown || (item.key === 'state' && countryFlag)) {
			return (
				<Dropdown
					fluid
					scrolling
					className="hiddenInput"
					onChange={(e, data) => {
						this.setUpdatedInfo(data.value, index);
					}}
					value={item.value}
					options={item.options}
				/>
			);
		} else if (item.key === 'state') {
			return (
				<DebounceInput
					element={Input}
					fluid
					transparent
					className="hiddenInput"
					maxLength={2}
					onChange={(e) => {
						this.setUpdatedInfo(e.target.value.toUpperCase(), index);
					}}
					debounceTimeout={600}
					value={item.value}
				/>
			);
		} else {
			return (
				<DebounceInput
					element={Input}
					fluid
					transparent
					className="hiddenInput"
					onChange={(e) => {
						this.setUpdatedInfo(e.target.value, index);
					}}
					debounceTimeout={600}
					value={item.value}
				/>
			);
		}
	}

	render() {
		const {detailsFields} = this.state;
		return (
			<Segment loading={!detailsFields} style={{minHeight: '200px'}}>
				<Label color="blue" ribbon>
					Profile Details
				</Label>
				<Divider />
				{detailsFields && detailsFields.length && (
					<Grid style={{paddingLeft: '1rem'}}>
						<Grid.Row>
							{detailsFields.map((item, index) => {
								return (
									<Grid.Column
										key={index}
										style={{
											paddingTop: '1rem',
											paddingBottom: '1rem'
										}}
										width={item.width}
									>
										<Header as="h5">
											<Header.Content>{item.label}</Header.Content>
										</Header>
										{this.defineInput(item, index)}
									</Grid.Column>
								);
							})}
						</Grid.Row>
					</Grid>
				)}
			</Segment>
		);
	}
}

ProfileDetails.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	updateName: PropTypes.func,
	setAasdiData: PropTypes.func
};

ProfileDetails.defaultProps = {
	type: 'aasdi'
};

const mapDispatchToProps = (dispatch) => ({
	setAasdiData: (data) => dispatch(setAasdiData(data))
});

export default connect(null, mapDispatchToProps)(ProfileDetails);
