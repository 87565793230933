import React from 'react';
import {Grid, Divider, Header, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';

import InputField from './components/inputField.js';

const Employee = (props) => (
	<Grid>
		<Header as="h3">
			<Icon name="user" />
			<Header.Content>Employee</Header.Content>
		</Header>
		<Grid.Row>
			<Grid.Column width={8}>
				<InputField
					fieldLabel="employeeFirstName"
					field={props.employeeFirstName}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
			<Grid.Column width={8}>
				<InputField fieldLabel="employeeLastName" field={props.employeeLastName} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="employeeEmail" field={props.employeeEmail} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Divider horizontal style={{padding: '2em 0em 3em 0em'}}>
			Optional
		</Divider>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="employeeTitle" field={props.employeeTitle} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="employeePhone" field={props.employeePhone} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

const mapStateToProps = (state) => {
	return {
		stepIndex: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Employee')[0].index,
		employeeFirstName: state.registrationForm.registrationForm.fields.employeeFirstName,
		employeeLastName: state.registrationForm.registrationForm.fields.employeeLastName,
		employeeEmail: state.registrationForm.registrationForm.fields.employeeEmail,
		employeeTitle: state.registrationForm.registrationForm.fields.employeeTitle,
		employeePhone: state.registrationForm.registrationForm.fields.employeePhone
	};
};

export default connect(mapStateToProps, null)(Employee);
