import React from 'react';
import {Button, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const AddButton = (props) => (
	<div style={{margin: 0}}>
		<Popup
			content={props.content}
			position={props.position}
			trigger={
				<Button
					as={Link}
					to={
						window.location.pathname.indexOf('/crm/dashboard/pipeline/grid-view') >= 0
							? '/crm/dashboard/opportunity/newOpp'
							: '/crm/dashboard/opportunity/newLead'
					}
					style={props.style}
					color={props.color}
					icon={props.icon}
					size={props.size}
				/>
			}
		/>
	</div>
);

AddButton.propTypes = {
	content: PropTypes.string.isRequired,
	position: PropTypes.string,
	onClick: PropTypes.func,
	icon: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.object,
	url: PropTypes.string
};

AddButton.defaultProps = {
	position: 'left center',
	icon: 'plus',
	color: 'green',
	size: 'large',
	style: {
		position: 'fixed',
		right: '1.5rem',
		top: '6.4rem',
		borderRadius: 0,
		zIndex: 10,
		margin: 0
	}
};

export default AddButton;
