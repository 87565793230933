import React, {Component} from 'react';
import {Label, Segment, Grid, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../../../../../components/Global/AgGrid.js';
import FilterGrid from '../../../../../../../components/Global/FilterGrid.js';
import ExportGrid from '../../../../../../../components/Global/ExportGrid.js';
import {toggleFetchingOnTabsNavbar} from '../../../../../../../components/Global/services/globalActions.js';
import {getTabRequestItems, clearGrid} from '../services/profileGridActions.js';
import {checkIdenticalsArrays} from '../../../../../../../lib/old/validation.js';
import DocumentsFieldsRenderer from '../../../../aasdi/profile/components/DocumentsFieldsRenderer.js';
import DocumentSectionCheckbox from '../../../../aasdi/profile/components/DocumentSectionCheckBox.js';
import DocumentSectionRemoveButton from '../../../../aasdi/profile/components/DocumentSectionRemoveButton.js';

const frameworkComponents = {
	clientDocumentSectionCheckbox: DocumentSectionCheckbox,
	clientDocumentSectionRemoveButton: DocumentSectionRemoveButton
};

class DocumentsTab extends Component {
	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
		const {clientId} = this.props;
		this.props.clearGrid('Documents');
		this.gridApi.showLoadingOverlay();
		this.props.getTabRequestItems(clientId, 'Documents');
		this.props.toggleFetchingOnTabsNavbar(true);
	};

	componentDidUpdate(prevProps) {
		const {profileGridData, profileGridHeader, toggleFetchingOnTabsNavbar, loading} = this.props;
		if (prevProps.loading === false && loading === true) {
			toggleFetchingOnTabsNavbar(true);
		} else if (prevProps.profileAasdiGridData === null && profileGridData) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.profileGridHeader, profileGridHeader)) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.rowData, profileGridData)) {
			toggleFetchingOnTabsNavbar(false);
		}
	}

	render() {
		const {clientId, profileGridHeader, profileGridData, loading} = this.props;
		return (
			<div>
				<Segment loading={false}>
					<Label color="blue" ribbon>
						Add document
					</Label>
					<DocumentsFieldsRenderer id={clientId} type="client" />
				</Segment>
				<Segment basic style={{marginTop: '1.5rem', border: '1px solid #DEDEDE'}} loading={loading}>
					<Label color="blue" ribbon>
						Documents
					</Label>
					<Divider />
					<Grid style={{padding: '2em 0'}}>
						<FilterGrid gridApi={() => this.gridApi} />
						<ExportGrid gridApi={() => this.gridApi} />
					</Grid>
					<AgGrid
						columnDefs={profileGridHeader}
						rowData={profileGridData}
						onGridReady={this.onGridReady}
						frameworkComponents={frameworkComponents}
					/>
				</Segment>
			</div>
		);
	}
}

DocumentsTab.propTypes = {
	profileGridData: PropTypes.array,
	getTabRequestItems: PropTypes.func,
	clientId: PropTypes.string,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	profileGridHeader: PropTypes.array,
	loading: PropTypes.bool.isRequired,
	clearGrid: PropTypes.func
};

const mapStateToProps = (state) => ({
	profileGridData: state.work.clientProfileGrid.rowData,
	stageNames: state.work.clientProfileGrid.stageNames,
	profileGridHeader: state.work.clientProfileGrid.headerData,
	loading: state.work.aasdiProfileGrid.documentsFields.loading
});

const mapDispatchToProps = (dispatch) => ({
	getTabRequestItems: (clientId, item, mock) => dispatch(getTabRequestItems(clientId, item, mock)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	clearGrid: (item) => dispatch(clearGrid(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTab);
