import React from 'react';
import {Button, Modal, Label, Icon, Header, Grid, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
// import { DebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';

import * as actions from '../../../../../services/createWorkOrderActions.js';

import {debounce, debounceEventHandler} from '../../../../../../../../../lib/old/debounce.js';

const AasdiContactSearchModal = (props) => (
	<Modal open={props.toggle} size={'small'}>
		<Header>
			<Icon name="plus" color="blue" />
			<div className="content">Add Contact</div>
		</Header>
		<Modal.Content scrolling={true} style={{paddingBottom: '3rem', height: '40vh'}}>
			<Grid>
				<Grid.Row>
					<Grid.Column
						width={10}
						style={{
							paddingTop: '0.5em'
						}}
					>
						<Label
							style={{
								backgroundColor: 'transparent',
								fontSize: '1rem',
								color: 'rgba(0,0,0,.8)',
								paddingLeft: '0'
							}}
						>
							Search for Contact
						</Label>
						<Dropdown
							className="createModalDropdown"
							fluid
							search
							selection
							options={props.contactOptions}
							onChange={(e, {value}) => {
								props.setTempContact(value, props.rowIndex);
							}}
							onSearchChange={debounceEventHandler(
								debounce((e) => {
									if (e.target.value) {
										props.getAasdiContacts(e.target.value, props.aasdiId);
									}
								}, 600)
							)}
							icon="search"
							error={props.error}
							loading={props.loading}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column
						width={10}
						style={{
							paddingTop: '0.5em'
						}}
					>
						<Label
							style={{
								backgroundColor: 'transparent',
								fontSize: '1rem',
								color: 'rgba(0,0,0,.8)',
								paddingLeft: '0'
							}}
						>
							Primary Contact
						</Label>
						<Dropdown
							className="createModalDropdown"
							placeholder={props.isPrimary ? 'Yes' : 'No'}
							fluid
							search
							selection
							options={[
								{key: 1, text: 'Yes', value: 1},
								{key: 0, text: 'No', value: 0}
							]}
							onChange={(e, {value}) => {
								props.setIsPrimary(value);
							}}
							value={props.isPrimary}
							upward={false}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleCreateWorkOrderAasdiContactModal(false);
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					onClick={() => {
						if (props.contactData.id !== '') {
							console.log('Add add add');
							props.setSelectedAasdiContacts(props.contactData, props.rowIndex);
						} else {
							props.aasdiContactsValidationError();
						}
					}}
					color="green"
				>
					<Icon name="checkmark" />
					Add
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

AasdiContactSearchModal.propTypes = {
	toggle: PropTypes.bool,
	rowIndex: PropTypes.number,
	contactOptions: PropTypes.array,
	setTempContact: PropTypes.func,
	getAasdiContacts: PropTypes.func,
	aasdiId: PropTypes.number,
	error: PropTypes.bool,
	loading: PropTypes.bool,
	isPrimary: PropTypes.number,
	setIsPrimary: PropTypes.func,
	toggleCreateWorkOrderAasdiContactModal: PropTypes.func,
	contactData: PropTypes.object,
	setSelectedAasdiContacts: PropTypes.func,
	aasdiContactsValidationError: PropTypes.func
};

const mapStateToProps = (state) => {
	let addContactModal =
		state.work.createWorkOrder.createWorkOrderModal.sections[
			state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
				(section) => section.name === 'AASDISection'
			)
		].addContactModal;
	return {
		toggle: addContactModal.open,
		loading: addContactModal.searchLoading,
		isPrimary: addContactModal.contact.isPrimary,
		contactOptions: addContactModal.options,
		contactData: addContactModal.contact,
		error: addContactModal.error
	};
};

export default connect(mapStateToProps, actions)(AasdiContactSearchModal);
