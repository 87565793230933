/*
This file implements oauth2 PKCE flow.
Call the login function to begin auth, once the user authenticates, they will be redirected back to `${origin}/auth`
At this point, in the query params will be an authCode, pass that to the getToken to get a JWT that will be used to authenticate with the api.
After JWT expires, call refreshToken with the refresh token returned from getToken, to get a new one.

To learn more, watch the relevant videos on this udemy course: https://hexagon.udemy.com/course/oauth-2-simplified
Auth Endpoints: https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-userpools-server-contract-reference.html
*/

//Try not to include any cognito specific logic into this file. This in intended for generic oauth flow.

//https://asd-usa.auth.us-east-1.amazoncognito.com/oauth2/idpresponse

const authServer = new URL('https://asd-usa.auth.us-east-1.amazoncognito.com')
const tokenURL = new URL('/oauth2/token', authServer)
const authURL = new URL('/oauth2/authorize', authServer)
const clientID = '26720bqpbeodbnq3hqeh0r8ss0'
const redirectURI = window.location.origin + '/auth/login'

const challengeSSKey = 'omni-oauth2-challenge-code'

const challengeLength = 64
const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

type LoginOptions = {
	scopes?: string[],
	extraSearchParams?: Record<string, string>
}

export async function login(opts: LoginOptions) {
	const [code, challenge] = await generateCodeChallenge(challengeLength)

	//session storage isnt really an amazing place to store the code cause session storage can be read by browser extensions..
	//I'm sure a better alternative will come along one day, there just isn't a standard for this right now.
	sessionStorage.setItem(challengeSSKey, code)

	authURL.searchParams.set('client_id', clientID)
	authURL.searchParams.set('redirect_uri', redirectURI)
	authURL.searchParams.set('response_type', 'code')
	authURL.searchParams.set('code_challenge_method', 'S256')
	authURL.searchParams.set('code_challenge', challenge)

	if(opts.scopes) {
		authURL.searchParams.set('scope', opts.scopes.join(' '))
	}
	if(opts.extraSearchParams) {
		for(const key in opts.extraSearchParams) {
			authURL.searchParams.set(key, opts.extraSearchParams[key])
		}
	}

	window.location.assign(authURL)
}

export async function getToken(authCode: string) {
	const code = sessionStorage.getItem(challengeSSKey)
	if(!code) throw new Error("no authorization code present")
	sessionStorage.removeItem(challengeSSKey)

	const tokenURL = new URL('/oauth2/token', authServer)

	const response = await fetch(tokenURL, {
		method: 'POST',
		headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
		body: new URLSearchParams({
			grant_type: 'authorization_code',
			client_id: clientID,
			redirect_uri: redirectURI,
			code: authCode,
			code_verifier: code
		})
	})

	const body = await response.json()
	if(!response.ok) {
		throw new Error(body.error)
	}

	return body
}

export async function refreshToken(token: string) {
	const response = await fetch(tokenURL, {
		method: 'POST',
		headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
		body: new URLSearchParams({
			grant_type: 'refresh_token',
			client_id: clientID,
			refresh_token: token
		})
	})

	const body = await response.json()
	return body
}

async function generateCodeChallenge(length: number): Promise<[code: string, challenge: string]> {
	if(length < 43 || length > 128) throw new Error('Invalid code challenge length. must be between 43 and 128')
	
	const rValues = crypto
		.getRandomValues(new Uint8Array(length))
		.map(v => validChars.charCodeAt(v % validChars.length))
	
	const code = new TextDecoder('utf-8').decode(rValues)
	const hValues = await crypto.subtle.digest('SHA-256', rValues)
	const challenge = base64UrlEncode(hValues)

	return [code, challenge]
}

export function decodeJWT(jwt: string) {
	const [header, body] = jwt.split('.')

	return {
		header: JSON.parse(base64UrlDecode(header)),
		body: JSON.parse(base64UrlDecode(body))
	}
}


function base64UrlEncode(buf: ArrayBuffer) {
	const hash = String.fromCharCode(...new Uint8Array(buf))
	return btoa(hash)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '')
}

function base64UrlDecode(hash: string) {	
	hash = hash
		.replace(/-/g, '+')
		.replace(/_/g, '/')
	
	switch (hash.length % 4) {
		case 0: break;
		case 1: throw new Error("Invalid base64 encoding.");
		case 2: hash += '=='; break;
		case 3: hash += '='; break;
	}

	return atob(hash)
}
