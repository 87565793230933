import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Bookings from './components/Bookings.js';
import Billings from './components/Billings.js';
import Filters from './components/filters/Filters.js';
import {getSalesRepresentative} from './services/commitsActions.js';

class MonthlyCommits extends React.Component {
	componentDidMount() {
		this.props.getSalesRepresentative();
	}

	render() {
		return (
			<div>
				<Filters key={'page-filters'} />
				<Bookings key="grid" />
				<Billings key="Grid" />
			</div>
		);
	}
}

MonthlyCommits.propTypes = {
	getSalesRepresentative: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getSalesRepresentative: () => dispatch(getSalesRepresentative())
});

export default connect(null, mapDispatchToProps)(MonthlyCommits);
