// Modules
import React from 'react';
import {Grid} from 'semantic-ui-react';

// Components
import PipelineOverview from './components/PipelineOverview.js';
import StageOverview from './components/StageOverview.js';
import Charts from './components/ChartWrapper.js';
import Filters from '../../../../components/filters/Filters.js';

let Analytics = () => (
	<Grid.Row>
		<Filters key={'page-filters'} />
		<Grid container columns="equal" textAlign="center">
			<Grid.Row>
				<Grid.Column>
					<PipelineOverview />
				</Grid.Column>
				<Grid.Column>
					<StageOverview />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row maxWidth={1724}>
				<Grid.Column>
					<Charts name="Technologies in the Pipeline" chartType="technologies" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Service Type in the Pipeline" chartType="serviceType" />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row maxWidth={1724}>
				<Grid.Column>
					<Charts name="Top 10 Largest Deals" chartType="topDeals" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Top 10 Largest Clients" chartType="topClients" />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row minWidth={1725}>
				<Grid.Column>
					<Charts name="Technologies in the Pipeline" chartType="technologies" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Service Type in the Pipeline" chartType="serviceType" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Top 10 Largest Deals" chartType="topDeals" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Top 10 Largest Clients" chartType="topClients" />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row maxWidth={1724}>
				<Grid.Column>
					<Charts name="Clients by Client Type" chartType="clientType" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Clients by Industry" chartType="industry" />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row maxWidth={1724}>
				<Grid.Column>
					<Charts name="Clients, New or Existing" chartType="clientCustomerType" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Estimates Due this Week" chartType="estimates" />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row minWidth={1725}>
				<Grid.Column>
					<Charts name="Clients by Client Type" chartType="clientType" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Clients by Industry" chartType="industry" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Clients, New or Existing" chartType="clientCustomerType" />
				</Grid.Column>
				<Grid.Column>
					<Charts name="Estimates Due this Week" chartType="estimates" />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</Grid.Row>
);

export default Analytics;
