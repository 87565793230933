import {Label, Popup, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const PictureLabels = (props) => [
	<Popup
		key={'openPicture'}
		size={'tiny'}
		trigger={<Label color={`blue`} icon={`camera`} content={` Take a picture`} />}
		content="Take a picture task."
		on="hover"
	/>,
	props.task.pictureDriveLinks
		? props.task.pictureDriveLinks.map((link) => (
				<Popup
					key={'openPictureLink'}
					size={'tiny'}
					trigger={
						<Label className="pseudoDragHandler" style={{maxWidth: '130px'}}>
							<Icon className="pseudoDragHandler" name="linkify" />
							<a className="pseudoDragHandler" href={link} target="_blank">
								{' '}
								Open image
							</a>
						</Label>
					}
					content="Click to open the picture in a new tab."
					on="hover"
				/>
		  ))
		: null
];
export default PictureLabels;

PictureLabels.propTypes = {
	task: PropTypes.object.isRequired
};
