import React, {Component} from 'react';
import {Button, Popup, Label, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

class CommitCellRenderer extends Component {
	commitGreenIcon = () => (
		<Popup
			trigger={
				<Icon
					name="calendar check outline"
					color={'green'}
					size="big"
					style={{
						// padding: '.5em .5em .5em',
						margin: '0 0 .5vh 0'
					}}
				/>
			}
			on={['hover', 'click']}
			flowing
			hoverable
			position="right center"
			content="Committed"
		/>
	);

	commitRedIcon = () => (
		<Popup
			trigger={
				<Icon
					name="calendar times outline"
					color={'red'}
					size="big"
					style={{
						// padding: '.5em .5em .5em',
						margin: '0 0 .5vh 0'
					}}
				/>
			}
			on={['hover', 'click']}
			flowing
			hoverable
			position="right center"
			content="Committed"
		/>
	);

	isConvertedForMonth = () => {
		const {data} = this.props;
		const projectedMonthIndex = new Date(data.projectedOrderDate).getMonth();
		const createdMonthIndex = new Date(data.workCreatedOn).getMonth();
		const currentMonthIndex = new Date().getMonth();
		if (!data.workId) {
			if (currentMonthIndex > projectedMonthIndex) return false;
			return true;
		}

		return projectedMonthIndex >= createdMonthIndex;
	};

	render() {
		return this.props.data.isCommit &&
			this.props.agGridReact.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0
			? this.isConvertedForMonth()
				? this.commitGreenIcon()
				: this.commitRedIcon()
			: null;
	}
}

export default CommitCellRenderer;
