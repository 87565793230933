import React from 'react';
import {Grid, Header, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';

import InputField from './components/inputField.js';

const Staffing = (props) => (
	<Grid>
		<Grid.Row>
			<Grid.Column width={16}>
				<Header as="h3">
					<Icon name="users" />
					<Header.Content>Staffing</Header.Content>
				</Header>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="staffingTechnicians"
					field={props.staffingTechnicians}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="staffingMFRCertTechnicians"
					field={props.staffingMFRCertTechnicians}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField fieldLabel="staffingRCDD" field={props.staffingRCDD} stepIndex={props.stepIndex} />
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="staffingLeadTechs"
					field={props.staffingLeadTechs}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row>
			<Grid.Column width={16}>
				<InputField
					fieldLabel="staffingProjectManagers"
					field={props.staffingProjectManagers}
					stepIndex={props.stepIndex}
				/>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

const mapStateToProps = (state) => {
	return {
		stepIndex: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.name === 'Staffing')[0].index,
		staffingTechnicians: state.registrationForm.registrationForm.fields.staffingTechnicians,
		staffingMFRCertTechnicians: state.registrationForm.registrationForm.fields.staffingMFRCertTechnicians,
		staffingRCDD: state.registrationForm.registrationForm.fields.staffingRCDD,
		staffingLeadTechs: state.registrationForm.registrationForm.fields.staffingLeadTechs,
		staffingProjectManagers: state.registrationForm.registrationForm.fields.staffingProjectManagers
	};
};

export default connect(mapStateToProps, null)(Staffing);
