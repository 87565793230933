import React from 'react';
import {Button, Modal, Icon, Header, Grid, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import {toggleAfterCreateModal, toggleCreateWorkOrderModal} from '../../services/createWorkOrderActions.js';
import WorkOrderPDFButton from './WorkOrderPDFButton.js';

class workOrderAfterCreateModal extends React.Component {
	closeModal() {
		this.props.toggleAfterCreateModal(false);
	}

	render() {
		const {isOpen, workId, workNumber, aasdiTrip, record} = this.props;
		const tripInfo = {
			specInst: aasdiTrip[0] ? aasdiTrip[0].specialInstructions : '',
			requestDate: aasdiTrip[0] ? aasdiTrip[0].requestedTime : '',
			aasdiSow: record.scopeOfWork
		};
		return (
			<div>
				<Modal size="small" open={isOpen} onUnmount={() => this.closeModal()}>
					<Header>
						<Icon name="plus" color="green" />
						<div className="content">{`Create work options (${workNumber})`}</div>
					</Header>
					<Modal.Content>
						<Grid centered>
							<Grid.Row>
								<Button
									basic
									size="huge"
									color="blue"
									content="Create new work order"
									icon="add"
									onClick={() => {
										this.closeModal();
										this.props.toggleCreateWorkOrderModal();
									}}
								/>
							</Grid.Row>
							<Divider />
							<Grid.Row>
								<Button
									basic
									size="huge"
									color="green"
									content="Go to detail page"
									icon="check"
									as={NavLink}
									to={`/work/view/${workId}`}
									onClick={() => {
										this.closeModal();
									}}
								/>
							</Grid.Row>
						</Grid>
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() => {
								this.closeModal();
							}}
							color="red"
						>
							<Icon name="remove" /> Cancel
						</Button>
						{isOpen && Boolean(aasdiTrip.length) && (
							<WorkOrderPDFButton workOverview={record} tripInfo={tripInfo} loading={!isOpen} />
						)}
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}

workOrderAfterCreateModal.propTypes = {
	isOpen: PropTypes.bool,
	workId: PropTypes.number,
	toggleAfterCreateModal: PropTypes.func,
	toggleCreateWorkOrderModal: PropTypes.func,
	workNumber: PropTypes.number,
	aasdiTrip: PropTypes.array,
	record: PropTypes.array
};

const mapStateToProps = (state) => ({
	isOpen: state.work.createWorkOrder.afterCreateModal.toggleAfterCreateModalStatus,
	workId: state.work.createWorkOrder.afterCreateModal.redirectWorkId,
	workNumber: state.work.createWorkOrder.afterCreateModal.redirectWorkNumber,
	aasdiTrip: state.work.createWorkOrder.createWorkOrderModal.tempAasdiTrip,
	record: state.work.createWorkOrder.createWorkOrderModal.tempWorkOrder
});

const mapDispatchToProps = (dispatch) => ({
	toggleAfterCreateModal: (status) => dispatch(toggleAfterCreateModal(status)),
	toggleCreateWorkOrderModal: () => dispatch(toggleCreateWorkOrderModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(workOrderAfterCreateModal);
