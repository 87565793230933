import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Popup} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/Global/ConfirmationModal.js';
import {
	activateClient,
	toggleActivateClientModal,
	toggleActivateClientErrorHidden,
	deactivateClient,
	toggleDeactivateClientModal,
	toggleDeactivateClientErrorHidden
} from '../services/clientGridActions.js';

class ClientActionCellRenderer extends Component {
	render() {
		const {data} = this.props;
		return (
			<div>
				{this.props.data.statusId === 3 ? (
					<Modal
						isModalOpen={this.props.isActivateModalOpen}
						isLoading={this.props.isActivateModalLoading}
						isErrorMessageHidden={this.props.isActivateClientErrorHidden}
						iconName="toggle on"
						iconColor="green"
						headerText="Activate Client"
						question={'Are you sure you would like to activate this client?'}
						errorMessage={this.props.activateClientErrorMessage}
						handleClickCancel={() => {
							this.props.toggleActivateClientModal();
							this.props.toggleActivateClientErrorHidden(true);
						}}
						handleClickConfirm={() => {
							this.props.activateClient(
								this.props.api.selectionController.lastSelectedNode.data.id,
								this.props.api.selectionController.lastSelectedNode.rowIndex
							);
						}}
					/>
				) : (
					<Modal
						isModalOpen={this.props.isDeactivateModalOpen}
						isLoading={this.props.isDeactivateModalLoading}
						isErrorMessageHidden={this.props.isDeactivateClientErrorHidden}
						iconName="toggle off"
						iconColor="red"
						headerText="Deactivate Client"
						question={`Are you sure you would like to deactivate this client?`}
						errorMessage={this.props.deactivateClientErrorMessage}
						handleClickCancel={() => {
							this.props.toggleDeactivateClientModal();
							this.props.toggleDeactivateClientErrorHidden(true);
						}}
						handleClickConfirm={() =>
							this.props.deactivateClient(
								this.props.api.selectionController.lastSelectedNode.data.id,
								this.props.api.selectionController.lastSelectedNode.rowIndex
							)
						}
					/>
				)}
				{this.props.data.statusId === 3 ? (
					<Popup
						trigger={
							<Button
								icon={'toggle off'}
								style={{
									color: 'red',
									backgroundColor: '#e8e8e8',
									padding: '.5em .5em .5em'
								}}
								onClick={() => this.props.toggleActivateClientModal()}
							/>
						}
						content={'Activate'}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						style={{zIndex: 1}}
					/>
				) : (
					<Popup
						trigger={
							<Button
								icon={'toggle on'}
								style={{
									color: 'green',
									backgroundColor: '#e8e8e8',
									padding: '.5em .5em .5em'
								}}
								onClick={() => this.props.toggleDeactivateClientModal()}
							/>
						}
						content={'Deactivate'}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						style={{zIndex: 1}}
					/>
				)}
				<Popup
					trigger={
						<Link to={`/work/dashboard/client/profile/${data.id}`}>
							<Button
								icon={'linkify'}
								style={{
									backgroundColor: '#e8e8e8',
									padding: '.5em .5em .5em'
								}}
							/>
						</Link>
					}
					content={'View Details'}
					on={['hover', 'click']}
					flowing
					hoverable
					position="right center"
					style={{zIndex: 1}}
				/>
			</div>
		);
	}
}

ClientActionCellRenderer.propTypes = {
	activateClient: PropTypes.func,
	activateClientErrorMessage: PropTypes.string,
	api: PropTypes.object,
	data: PropTypes.object,
	deactivateClient: PropTypes.func,
	deactivateClientErrorMessage: PropTypes.string,
	isActivateClientErrorHidden: PropTypes.bool,
	isActivateModalLoading: PropTypes.bool,
	isActivateModalOpen: PropTypes.bool,
	isDeactivateClientErrorHidden: PropTypes.bool,
	isDeactivateModalLoading: PropTypes.bool,
	isDeactivateModalOpen: PropTypes.bool,
	toggleActivateClientModal: PropTypes.func,
	toggleActivateClientErrorHidden: PropTypes.func,
	toggleDeactivateClientModal: PropTypes.func,
	toggleDeactivateClientErrorHidden: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		activateClientErrorMessage: state.work.clientGrid.activateClientErrorMessage,
		deactivateClientErrorMessage: state.work.clientGrid.deactivateClientErrorMessage,
		isActivateClientErrorHidden: state.work.clientGrid.isActivateClientErrorHidden,
		isActivateModalLoading: state.work.clientGrid.isActivateModalLoading,
		isActivateModalOpen: state.work.clientGrid.isActivateModalOpen,
		isDeactivateClientErrorHidden: state.work.clientGrid.isDeactivateClientErrorHidden,
		isDeactivateModalLoading: state.work.clientGrid.isDeactivateModalLoading,
		isDeactivateModalOpen: state.work.clientGrid.isDeactivateModalOpen
	};
};

const mapDispatchToProps = {
	activateClient: (id, rowIndex) => activateClient(id, rowIndex),
	deactivateClient: (id, rowIndex) => deactivateClient(id, rowIndex),
	toggleActivateClientModal,
	toggleActivateClientErrorHidden: (bool) => toggleActivateClientErrorHidden(bool),
	toggleDeactivateClientModal,
	toggleDeactivateClientErrorHidden: (bool) => toggleDeactivateClientErrorHidden(bool)
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientActionCellRenderer);
