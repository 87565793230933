// TODO
// This really should be two seperate components; an employee creation modal, and an AASDI/Client creation modal.
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Modal, Icon, Header, Grid, Dropdown, Form} from 'semantic-ui-react';
import {
	toggleUserCreationModal,
	updateUserForm,
	switchModalRequestType,
	setSearchOptions,
	insertUsersGrid,
	updateAccountingConsoleUsers
} from '../../../../../services/accountingConsoleActions.js';
import {debounceEventHandler, debounce} from '../../../../../../../lib/old/debounce.js';
import {fieldsForClientAndAASDI} from '../../../../../services/formFields/createClientOrAasdi.js';
import {createUserFields as fieldsForEmployee} from '../../../../../services/formFields/createUser.js';
import api from '../../../../../../../lib/api.ts';
import customFetch from '../../../../../../../lib/old/customFetch.js';
import {selectAccountingConsoleState} from '../../../../../services/accountingConsole.selectors.js';

import UserCreationDefaultField from '../formFields/UserCreationDefaultField.jsx';
import UserCreationOptionsField from '../formFields/UserCreationOptionsField.jsx';
import UserCreationErrorMessage from '../formFields/UserCreationErrorMessage.jsx';

const labelStyle = {
	backgroundColor: 'transparent',
	fontSize: '1rem',
	color: 'rgba(0,0,0,.8)',
	paddingLeft: '0'
};

const MODAL = {
	Employee: 'Employee',
	AASDI: 'AASDI',
	Client: 'Client'
};
const ROLE = {
	Standard: 0,
	Executive: 1,
	Accounting: 2,
	Inactive: 3,
	Client: 4,
	AASDI: 5
};
const HTTP = {
	get: 'get',
	post: 'post',
	put: 'put',
	delete: 'delete'
};
const USA_COUNTRY_CODE = 218;

class UserCreationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchOptions: [],
			isFetching: false,
			clientOrAASDIId: null,
			isSubmitting: false,
			displayErrorMessage: false,
			errorMessage: ''
		};
		this.fieldUpdated = this.fieldUpdated.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.searchAASDIorClient = this.searchAASDIorClient.bind(this);
		this.checkRequiredFields = this.checkRequiredFields.bind(this);
		this.submitUser = this.submitUser.bind(this);
		this.defineFields = this.defineFields.bind(this);
		this.defineSearchFieldValue = this.defineSearchFieldValue.bind(this);
		this.createCognitoUser = this.createCognitoUser.bind(this);
		this.fieldMap = this.fieldMap.bind(this);
		this.renderFormFields = this.renderFormFields.bind(this);
		this.determineSubmitEndpoint = this.determineSubmitEndpoint.bind(this);
		this.determineSubmitRequestBody = this.determineSubmitRequestBody.bind(this);
		this.handlePutSuccess = this.handlePutSuccess.bind(this);
		this.handlePostSuccess = this.handlePostSuccess.bind(this);
		this.determineSearchEndpoint = this.determineSearchEndpoint.bind(this);
	}

	closeModal() {
		const {toggleUserCreationModal, updateUserForm, switchModalRequestType, setSearchOptions} = this.props;
		this.setState((prevState) => ({
			...prevState,
			clientOrAASDIId: null,
			isSubmitting: false,
			isFetching: false,
			searchOptions: [],
			displayErrorMessage: false,
			errorMessage: ''
		}));
		setSearchOptions([]);
		toggleUserCreationModal(false);
		switchModalRequestType(HTTP.post);
		updateUserForm({});
	}

	defineFields() {
		const {modalType} = this.props;
		if (modalType === MODAL.Employee) {
			return fieldsForEmployee;
		}
		return fieldsForClientAndAASDI;
	}

	defineSearchFieldValue() {
		const {clientOrAASDIId} = this.state;
		const {initialSearchOptions} = this.props;
		if (clientOrAASDIId) {
			return clientOrAASDIId;
		}
		if (initialSearchOptions.length && initialSearchOptions[0].value) {
			return initialSearchOptions[0].value;
		}
		return null;
	}

	fieldUpdated(value, targetFieldKey) {
		const {fields, updateUserForm} = this.props;
		fields[targetFieldKey] = value;
		this.setState({...this.state, fields});
		updateUserForm(fields);
	}

	checkRequiredFields() {
		const {clientOrAASDIId} = this.state;
		const {modalType, fields, initialSearchOptions} = this.props;
		const initialSearchValue = initialSearchOptions.length ? initialSearchOptions[0].value : null;

		if (!clientOrAASDIId && !initialSearchValue && modalType !== MODAL.Employee) {
			return false;
		}

		let answer = true;
		this.defineFields().forEach((field) => {
			// cant do a simple falsy check here => a fields value may be a boolean false which would be a valid value
			// e.g. is a sales representative? true/false. if user chooses 'no'(false) then they wouldn't be able to create the user with a simple falsy check
			const value = fields[field.key];
			const isFieldEmpty = value === undefined || value === null || value === '';

			if (field.isRequired && isFieldEmpty) {
				answer = false;
			}
		});
		return answer;
	}

	determineSearchEndpoint(value, modalType) {
		if (modalType === MODAL.AASDI) {
			return `legacy/aasdi?name=${value}`;
		}
		return `legacy/client?name=${encodeURIComponent(value)}`;
	}

	async searchAASDIorClient(value) {
		const {modalType} = this.props;
		const endpoint = this.determineSearchEndpoint(value, modalType);

		this.setState((prevState) => ({...prevState, isFetching: true}));

		const results = await api(endpoint);

		if (!results) {
			this.setState((prevState) => ({...prevState, isFetching: false}));
			return;
		}

		const searchOptions = results.map((result) => ({value: result.id, text: result.name}));

		this.setState((prevState) => ({
			...prevState,
			searchOptions,
			isFetching: false
		}));
	}

	determineSubmitEndpoint(modalType) {
		switch (modalType) {
			case MODAL.AASDI:
			case MODAL.Client:
			case MODAL.Employee:
				return 'legacy/employee?type=' + modalType.toLowerCase();

			default:
				throw new Error('Invalid modal type.');
		}
	}

	determineSubmitRequestBody(modalType, formFields, modalRequestType) {
		switch (modalType) {
			case MODAL.AASDI:
				return {
					...formFields,
					roleId: ROLE.AASDI,
					aasdiId: this.defineSearchFieldValue()
				};

			case MODAL.Client:
				return {
					...formFields,
					roleId: ROLE.Client,
					clientId: this.defineSearchFieldValue()
				};

			case MODAL.Employee:
				if (modalRequestType === HTTP.post) {
					formFields.isActive = true;
				}
				return {
					...formFields,
					companyId: 1
				};

			default:
				throw new Error('Invalid modal type.');
		}
	}

	handlePutSuccess(body) {
		const {updateAccountingConsoleUsers} = this.props;

		this.setState((prevState) => ({...prevState, isSubmitting: false}));
		updateAccountingConsoleUsers(body);

		// grid rows must be manually refreshed after accounting console information is updated - probably an issue with AG-GRID
		// as the acCategory component re-renders with the updated information but still requires the refreshCells() call for the changes
		// to show up
		this.props.gridApi.refreshCells();
	}

	handlePostSuccess(body, response, modalType) {
		const {searchOptions} = this.state;
		const {insertUsersGrid} = this.props;

		let newBody = {...body};
		switch (modalType) {
			case MODAL.AASDI:
				const aasdi = searchOptions.find((option) => option.value === newBody.aasdiId);
				newBody.aasdiName = aasdi.text;
				newBody.aasdiUserId = response[0].id;
				break;

			case MODAL.Client:
				const client = searchOptions.find((option) => option.value === newBody.clientId);
				newBody.clientName = client.text;
				newBody.clientUserId = response[0].id;
				break;

			case MODAL.Employee:
				newBody.employeeId = response[0].id;
				break;

			default:
				throw new Error('Invalid modal type.');
		}

		insertUsersGrid(newBody);
	}

	async submitUser() {
		const {modalRequestType, modalType, fields} = this.props;

		let endpoint = this.determineSubmitEndpoint(modalType);
		const body = this.determineSubmitRequestBody(modalType, fields, modalRequestType);

		this.setState((prevState) => ({...prevState, isSubmitting: true}));

		try {
			const response = await api(endpoint, modalRequestType, body);

			if (response.error) {
				throw new Error(response.error);
			}

			if (modalRequestType === HTTP.put) {
				this.handlePutSuccess(body);
			} else {
				this.handlePostSuccess(body, response, modalType);
				this.closeModal();
			}
		} catch (error) {
			console.error(error);
			// error.message.toString() => defensive programming; prevents an error if error.message is an object for whatever reason
			this.setState((prevState) => ({
				...prevState,
				displayErrorMessage: true,
				errorMessage: error.message.toString(),
				isSubmitting: false
			}));
		}
	}

	// TODO: when this function starts being used
	// make sure it's only called when the user is
	// an AASDI user or client user
	createCognitoUser = () => {
		let payload = {
			username: this.state.fields.username,
			email: this.state.fields.email
		};

		console.log('payload: ', payload);

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/employee?type=cognito`, {
			method: 'POST',
			body: JSON.stringify({payload})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				console.log(json);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	fieldMap(field) {
		const {fields} = this.props;

		const fieldProps = {
			key: `form_field_${field.key}`,
			field,
			fields,
			labelStyle,
			fieldUpdated: (value, key) => this.fieldUpdated(value, key)
		};

		// from what I can gather, the state field is an options field if the country is USA, and a text field for everything else
		const isNotOptionsField = !field.options || (field.key === 'state' && fields.country !== USA_COUNTRY_CODE);

		if (isNotOptionsField) {
			return <UserCreationDefaultField {...fieldProps} />;
		}

		return <UserCreationOptionsField {...fieldProps} />;
	}
	renderFormFields() {
		return this.defineFields().map(this.fieldMap);
	}
	renderSearchForField() {
		const {modalType, initialSearchOptions} = this.props;
		const {searchOptions, isFetching} = this.state;

		if (modalType !== MODAL.Employee) {
			return (
				<Form.Field required>
					<label style={labelStyle}>Search {modalType}</label>
					<Dropdown
						className="createModalDropdown"
						placeholder={`Search for ${modalType}`}
						fluid
						search
						selection
						onSearchChange={debounceEventHandler(
							debounce((e) => {
								if (e.target.value) {
									this.searchAASDIorClient(e.target.value);
								}
							}, 600)
						)}
						value={this.defineSearchFieldValue()}
						onChange={(e, {value}) => {
							this.setState((prevState) => ({
								...prevState,
								clientOrAASDIId: value
							}));
						}}
						options={searchOptions.length ? searchOptions : initialSearchOptions}
						loading={isFetching}
					/>
				</Form.Field>
			);
		}
		return null;
	}

	render() {
		const {isSubmitting, displayErrorMessage, errorMessage} = this.state;
		const {isOpen, modalType, modalRequestType} = this.props;

		const isModalPost = modalRequestType === HTTP.post;
		const createOrUpdate = isModalPost ? 'Create' : 'Update';
		const modalTitle = `${createOrUpdate} ${modalType} User`;
		const submitIcon = isModalPost ? 'add' : 'redo alternate';

		return (
			<Modal size="small" open={isOpen}>
				<UserCreationErrorMessage display={displayErrorMessage} message={errorMessage} />
				<Header>
					<Icon name="plus" color="green" />
					<div className="content">{modalTitle}</div>
				</Header>
				<Modal.Content scrolling>
					<Grid>
						<Grid.Row>
							<Grid.Column width={16}>
								<Form>
									{this.renderSearchForField()}
									{this.renderFormFields()}
								</Form>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button onClick={() => this.closeModal()} color="red" disabled={isSubmitting}>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							color="green"
							disabled={!this.checkRequiredFields() || isSubmitting}
							loading={isSubmitting}
							onClick={() => this.submitUser()}
						>
							<Icon name={submitIcon} />
							{createOrUpdate}
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

UserCreationModal.propTypes = {
	toggleUserCreationModal: PropTypes.func,
	updateUserForm: PropTypes.func,
	switchModalRequestType: PropTypes.func,
	isOpen: PropTypes.bool,
	modalType: PropTypes.string,
	modalRequestType: PropTypes.string,
	fields: PropTypes.object,
	initialSearchOptions: PropTypes.array,
	setSearchOptions: PropTypes.func,
	insertUsersGrid: PropTypes.func
};

const mapStateToProps = (state) => ({
	isOpen: selectAccountingConsoleState(state).isUserCreationModalOpen,
	modalType: selectAccountingConsoleState(state).modalType,
	modalRequestType: selectAccountingConsoleState(state).modalRequestType,
	initialSearchOptions: selectAccountingConsoleState(state).initialSearchOptions,
	fields: selectAccountingConsoleState(state).userForm
});

const mapDispatchToProps = (dispatch) => ({
	toggleUserCreationModal: (status) => dispatch(toggleUserCreationModal(status)),
	updateUserForm: (form) => dispatch(updateUserForm(form)),
	switchModalRequestType: (type) => dispatch(switchModalRequestType(type)),
	setSearchOptions: (options) => dispatch(setSearchOptions(options)),
	insertUsersGrid: (user) => dispatch(insertUsersGrid(user)),
	updateAccountingConsoleUsers: (user) => dispatch(updateAccountingConsoleUsers(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCreationModal);
