import {Tab, Menu, Icon, Popup} from 'semantic-ui-react';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Groups from './Groups.js';

import * as actions from '../services/tasksActions.js';
import * as globalActions from '../../../../../../../../../components/Global/services/globalActions.js';
class AASDIs extends Component {
	render() {
		let aasdiPanes = this.props.aasdis.map((aasdi, index) => ({
			menuItem: (
				<Menu.Item
					key={aasdi.name}
					color={'blue'}
					onClick={() => {
						this.props.setSelectedTaskGroup(index, 0);
					}}
				>
					<Popup
						trigger={
							<div>
								<Icon name="user" size="large" />

								{aasdi.name.length > 8 ? aasdi.name.substring(0, 8) + '...' : aasdi.name}
							</div>
						}
						content={aasdi.name}
					/>
				</Menu.Item>
			),
			render: () => {
				return <Groups groups={aasdi.taskGroups} aasdiIndex={index} {...this.props} />;
			}
		}));
		return <Tab panes={aasdiPanes} />;
	}
}

AASDIs.propTypes = {
	aasdis: PropTypes.array,
	setSelectedTaskGroup: PropTypes.func
};

export default connect(null, {...actions, ...globalActions})(AASDIs);
