import React from 'react';
import {Statistic, Segment, Label, Button, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getStageAnalytics} from '../../../services/pipelineActions.js';

let QuickMetrics = ({stages, loading, error, getStageAnalytics}) => (
	<Segment key="quick-statistics" loading={loading}>
		<Label attached="top" color="blue">
			Quick Metrics
		</Label>
		{error ? (
			<Segment basic>
				Hmmm, it looks like something went wrong when I was trying to pull this data. Try again, or contact the
				Dev team if it keeps happening
				<Segment basic style={{paddingBottom: '0'}}>
					<Button icon onClick={() => getStageAnalytics()}>
						<Icon name="refresh" />
					</Button>
				</Segment>
			</Segment>
		) : (
			<Statistic.Group size="mini" widths={6}>
				<Statistic
					label="Identified Need"
					value={stages.identifiedNeed ? stages.identifiedNeed.revenue : '$0.00'}
				/>
				<Statistic label="Estimating" value={stages.estimating ? stages.estimating.revenue : '$0.00'} />
				<Statistic
					label="Submitted Quote"
					value={stages.submittedQuote ? stages.submittedQuote.revenue : '$0.00'}
				/>
				<Statistic
					label="High Probability"
					value={stages.highProbability ? stages.highProbability.revenue : '$0.00'}
				/>
				<Statistic label="Verbal Award" value={stages.verbalAward ? stages.verbalAward.revenue : '$0.00'} />
				<Statistic
					label="Closed Business"
					value={stages.closedBusiness ? stages.closedBusiness.revenue : '$0.00'}
				/>
			</Statistic.Group>
		)}
	</Segment>
);

QuickMetrics.propTypes = {
	error: PropTypes.bool,
	loading: PropTypes.bool,
	getStageAnalytics: PropTypes.func,
	stages: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		stages: state.crm.pipeline.analytics.stages.data,
		loading: state.crm.pipeline.analytics.stages.loading,
		error: state.crm.pipeline.analytics.stages.error
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getStageAnalytics: () => {
			dispatch(getStageAnalytics());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickMetrics);
