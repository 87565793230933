import React from 'react';
import {connect} from 'react-redux';
import {Label, Input, Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {searchAasdiCertifications} from './services/globalActions.js';

class FilterGrid extends React.Component {
	onQuickFilterChanged = (value) => {
		const gridApi = this.props.gridApi();
		gridApi.setQuickFilter(value);
	};

	render() {
		return (
			<Grid.Column width={8}>
				<Label
					style={{
						float: 'left',
						backgroundColor: 'transparent',
						fontSize: '.92rem',
						paddingTop: '1em'
					}}
				>
					{'Filter Grid'}
				</Label>
				<Input
					type="text"
					onChange={(e, {value}) => {
						if (this.props.cert) {
							if (this.props.certs.length >= 1) {
								const gridApi = this.props.gridApi();
								this.props.searchAasdiCertifications(this.props.certs, value, gridApi);
							} else {
								this.onQuickFilterChanged(value);
							}
						} else {
							this.onQuickFilterChanged(value);
						}
					}}
					icon="search"
				/>
			</Grid.Column>
		);
	}
}

FilterGrid.propTypes = {
	gridApi: PropTypes.func.isRequired,
	cert: PropTypes.bool,
	certs: PropTypes.array,
	searchAasdiCertifications: PropTypes.func
};

const mapStateToProps = (state) => ({
	certs: state.work.aasdiGrid.certModal.selectedCert
});

const mapDispatchToProps = (dispatch) => ({
	searchAasdiCertifications: (certs, value, apiGrid) => {
		dispatch(searchAasdiCertifications(certs, value, apiGrid));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterGrid);
