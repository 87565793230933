import React from 'react';
import AddGridItem from '../../../components/AddGridItem.jsx';
import {connect} from 'react-redux';

import {toggleCreateWorkOrderModal} from '../../CreateWorkOrder/services/createWorkOrderActions.js';

const CreateWorkOrderButton = (props) => (
	<AddGridItem content="Create New Work Order" onClick={() => props.toggleCreateWorkOrderModal()} />
);

const mapDispatchToProps = (dispatch) => {
	return {
		toggleCreateWorkOrderModal: () => {
			dispatch(toggleCreateWorkOrderModal());
		}
	};
};

export default connect(null, mapDispatchToProps)(CreateWorkOrderButton);
