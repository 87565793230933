import {Label, Form, Header, TextArea} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import optionFactory from './optionFactory.js';
import ErrorLabel from './Label_Error.js';

import DebounceInput from 'react-debounce-input';

const DefaultTaskType = (props) => (
	<Form.Field key={`${props.column}-formField`}>
		<label> Directions </label>
		{props.task.completed ? (
			<Header.Content>{props.task.directions}</Header.Content>
		) : (
			<DebounceInput
				element={TextArea}
				debounceTimeout={600}
				key={`${props.column}-input`}
				error={props.tasks.ui.errors.directions ? props.tasks.ui.errors.directions : false}
				onChange={(e) => {
					props.updateTask(props.mode, 'directions', e.target.value, optionFactory(props));
				}}
				value={props.task.directions}
			/>
		)}

		<ErrorLabel
			{...props}
			key={`${props.column}-errorLabel`}
			message={'Please enter directions for the tech.'}
			style={{color: 'black'}}
		/>
	</Form.Field>
);

export default DefaultTaskType;
