import moment from 'moment';
import customFetch from '../../../../../../../lib/old/customFetch.js';
import {updateGridItem} from '../../../services/workGridActions.js';
import {v4 as uuid} from 'uuid';
import {calcMargin, calcRemainingToBill, isDollarAmount} from '../../../../../../../util/moneymath.ts';

export const clearWork = () => ({type: 'CLEAR_WORK'});

export const getWork = (id) => {
	return async (dispatch) => {
		dispatch({
			type: 'WORK_DETAILS_NAVBAR_LOADING',
			payload: {
				loading: true
			}
		});

		dispatch({
			type: 'WORK_OVERVIEW_ORDER_DETAILS_LOADING_FIELD',
			payload: {
				loading: true
			}
		});

		dispatch({
			type: 'WORK_VIEW_AASDI_LOADING_FIELD',
			payload: {
				loading: true
			}
		});

		try {
			let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${id}`;

			// customFetch for work by id
			let response = await customFetch(endpoint);

			if (response.status !== 200) {
				throw new Error(response);
			}

			let json = await response.json();

			if (json.length) {
				json = json[0];
			}

			// check if it has an opportunity
			if (json.opportunityId) {
				const oppEndpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${json.opportunityId}`;

				const oppResponse = await customFetch(oppEndpoint);
				const oppData = await oppResponse.json();
				oppData.startDate = moment.utc(oppData.startDate).format('MM-DD-YYYY');
				oppData.endDate = moment.utc(oppData.endDate).format('MM-DD-YYYY');
				json.opportunityData = oppData;
			}

			if (json.startDate === null) {
				json.startDate = moment(new Date()).format('MM-DD-YYYY');
			} else {
				json.startDate = moment.utc(json.startDate).format('MM-DD-YYYY');
			}

			if (json.endDate === null) {
				json.endDate = moment(new Date()).format('MM-DD-YYYY');
			} else {
				json.endDate = moment.utc(json.endDate).format('MM-DD-YYYY');
			}

			// contact section
			let billingContactValue = {
				name: `${json.billingContact_Name}`,
				phone: 'No Phone Number',
				email: 'No Email'
			};

			if (json.billingContact_Title) {
				billingContactValue.name += `, ${json.billingContact_Title}`;
			}

			if (json.billingContact_Phone) {
				billingContactValue.phone = `${json.billingContact_Phone}`;
			}

			if (json.billingContact_Email) {
				billingContactValue.email = `${json.billingContact_Email}`;
			}

			dispatch({
				type: 'UPDATE_WORK_OVERVIEW_BILLING_CONTACT',
				payload: {
					value: billingContactValue
				}
			});

			// location section
			let locationValue = {
				name: `${json.location_Name}`,
				address: '',
				city: ''
			};

			if (json.location_Address) {
				locationValue.address += ` ${json.location_Address}`;
			}

			if (json.location_Address2) {
				locationValue.address += `, ${json.location_Address2}`;
			}

			if (json.location_City) {
				locationValue.city += ` ${json.location_City}`;
			}

			if (json.location_State) {
				locationValue.city += `, ${json.location_State}`;
			}

			if (json.location_Zip) {
				locationValue.city += `, ${json.location_Zip}`;
			}

			dispatch({
				type: 'UPDATE_WORK_OVERVIEW_LOCATION',
				payload: {
					value: locationValue
				}
			});

			dispatch({
				type: 'GET_WORK_OVERVIEW',
				payload: {
					json
				}
			});
			dispatch({
				type: 'WORK_DETAILS_NAVBAR_LOADING',
				payload: {
					loading: false
				}
			});

			dispatch({
				type: 'SET_WORK_SOW',
				payload: {
					json
				}
			});

			dispatch({
				type: 'WORK_OVERVIEW_ORDER_DETAILS_LOADING_FIELD',
				payload: {
					loading: false
				}
			});
			dispatch({
				type: 'WORK_SOW_LOADING_TOGGLE',
				payload: {
					value: false
				}
			});
		} catch (err) {
			dispatch({
				type: 'WORK_OVERVIEW_ORDER_DETAILS_LOADING_FIELD',
				payload: {
					loading: false
				}
			});

			dispatch({
				type: 'WORK_DETAILS_NAVBAR_LOADING',
				payload: {
					loading: false
				}
			});

			dispatch({
				type: 'WORK_SOW_LOADING_TOGGLE',
				payload: {
					value: false
				}
			});

			dispatch({
				type: 'ERROR',
				payload: err.stack
			});
		}

		try {
			let aasdiEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${id}/aasdi`;

			const response = await customFetch(aasdiEndpoint);

			if (response.status !== 200) {
				throw Promise.reject(response);
			}
			const json = await response.json();

			json.forEach((item, index) => {
				if (index === 0) {
					json[index].selected = true;
				} else {
					json[index].selected = false;
				}
			});

			dispatch({
				type: 'GET_WORK_VIEW_AASDI',
				payload: {
					json
				}
			});

			dispatch({
				type: 'WORK_VIEW_AASDI_LOADING_FIELD',
				payload: {
					loading: false
				}
			});
		} catch (err) {
			dispatch({
				type: 'ERROR',
				payload: err.stack
			});
		}
	};
};

export const toggleClosureModal = (stage) => {
	return (dispatch) => {
		return dispatch({
			type: 'CLOSURE_MODAL_TOGGLE',
			payload: {stage: stage ? stage : 'Request to Close'}
		});
	};
};

export const toggleRatingConfirmationModal = () => {
	return (dispatch) => {
		return dispatch({
			type: 'AASDI_RATING_MODAL_TOGGLE'
		});
	};
};

export const toggleCanceledModal = () => {
	return (dispatch) => {
		return dispatch({
			type: 'CANCELED_MODAL_TOGGLE'
		});
	};
};

export const toggleCBWModal = () => {
	return (dispatch) => {
		return dispatch({
			type: 'CBW_MODAL_TOGGLE'
		});
	};
};

export const changeViewStep = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_CHANGE_VIEW_STEP',
			payload: {
				value
			}
		});
	};
};

export const setWorkViewId = (workId) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_VIEW_WORK_ID',
			payload: {
				workId
			}
		});
	};
};

export const WORK_VIEW_CLEAR_INSERTED_BILLABLE_ITEM_DATA = 'WORK_VIEW_CLEAR_INSERTED_BILLABLE_ITEM_DATA';
export const clearInsertedBillableItemData = () => ({
	type: WORK_VIEW_CLEAR_INSERTED_BILLABLE_ITEM_DATA
});

export const getWorkViewDocuments = (id) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_VIEW_DOCUMENTS_LOADING',
			payload: true
		});
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents?workId=${id}`;

		// customFetch for work by id
		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'GET_WORK_VIEW_DOCUMENTS',
					payload: {
						json
					}
				});
				dispatch({
					type: 'WORK_VIEW_DOCUMENTS_LOADING',
					payload: false
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const updateDocumentValue = (index, value) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_WORK_VIEW_DOCUMENTS_VALUE',
			payload: {
				index,
				value
			}
		});
	};
};

export const addWorkViewDocument = (fileName, googleDriveLink, customerApproval, comments, id) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_VIEW_DOCUMENTS_LOADING',
			payload: true
		});
		let payloadObj = {
			comments: comments.value,
			workId: id,
			isClientVisible: false,
			isAasdiVisible: false,
			googleDriveLink: googleDriveLink.value,
			fileName: fileName.value,
			customerApproval: customerApproval.value
		};

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

		customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify([payloadObj])
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((results) => {
				payloadObj.id = results[0].id;
				dispatch({
					type: 'WORK_VIEW_DOCUMENTS_LOADING',
					payload: false
				});
				dispatch({
					type: 'WORK_VIEW_ADD_DOCUMENT',
					payload: {
						object: payloadObj
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'WORK_VIEW_DOCUMENTS_LOADING',
					payload: false
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const removeWorkViewDocument = (index, documentId, workId) => async (dispatch) => {
	// Loader
	dispatch({
		type: 'WORK_VIEW_DOCUMENTS_LOADING',
		payload: true
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;
	const call = await customFetch(endpoint, {
		method: 'DELETE',
		body: JSON.stringify({documentId: documentId, workId: workId})
	});
	// const response = await call.json();

	// API Call Worked, Remove Doc on Frontend
	if (call.status === 200) {
		dispatch({
			type: 'WORK_VIEW_REMOVE_DOCUMENT',
			payload: {
				index
			}
		});
	}

	// Hide Loader
	dispatch({
		type: 'WORK_VIEW_DOCUMENTS_LOADING',
		payload: false
	});
};

export const updateWorkViewDocument = (index, value) => async (dispatch, getState) => {
	value.customerApproval === true ? (value.customerApproval = false) : (value.customerApproval = true);

	// let toggle = value.customerApproval;
	// toggle needs to be changed to something, the customer approval doesn't change when the box is clicked

	let putBody = {
		documentId: value.id,
		workId: value.workId,
		customerApproval: value.customerApproval
	};

	dispatch({
		type: 'WORK_VIEW_UPDATE_DOCUMENT',
		payload: {
			index,
			value: value.customerApproval
		}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

	const call = await customFetch(endpoint, {
		method: 'PUT',
		body: JSON.stringify(putBody)
	});

	if (call.status !== 200) {
		console.log(`ERROR - not 200`);
	}

	let response = await call.json();
};

export const GET_WORK_VIEW_AASDI_STATUS_SUCCESS = 'GET_WORK_VIEW_AASDI_STATUS_SUCCESS';
export const getWorkViewAasdiStatusSuccess = (aasdiStatusRecords) => ({
	type: GET_WORK_VIEW_AASDI_STATUS_SUCCESS,
	payload: {
		aasdiStatusRecords
	}
});

export const GET_WORK_VIEW_AASDI_STATUS_FAILURE = 'GET_WORK_VIEW_AASDI_STATUS_FAILURE';
export const getWorkViewAasdiStatusFailure = () => ({
	type: GET_WORK_VIEW_AASDI_STATUS_FAILURE
});

export const getWorkViewAasdiStatus = (workRecordId) => {
	return async (dispatch) => {
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?aasdiStatusWorkId=${workRecordId}`;
		try {
			const response = await customFetch(endpoint);
			const parsedResponse = await response.json();

			if (response.status !== 200) {
				throw new Error(parsedResponse);
			}

			dispatch(getWorkViewAasdiStatusSuccess(parsedResponse));
		} catch (error) {
			console.error(error);
			dispatch(getWorkViewAasdiStatusFailure());
		}
	};
};
export const getWorkViewEventLog = (id) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_VIEW_EVENTLOG_GRID_LOADING_FIELD',
			payload: {
				loading: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/event-log?recordId=${id}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json.forEach((eventLog, index) => {
					eventLog.createdOn = moment(json[index].createdOn).format('MMMM D YYYY, hh:mm a');
				});
				dispatch({
					type: 'GET_WORK_VIEW_EVENTLOG',
					payload: {
						json
					}
				});
			})
			.then(() => {
				dispatch({
					type: 'WORK_VIEW_EVENTLOG_GRID_LOADING_FIELD',
					payload: {
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

/* BILLING CENTER ACTIONS */
export const getBills = (workId) => async (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_BILLING_CENTER_INVOICE_LOADING',
		loading: true
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing`;
	const call = await customFetch(endpoint);
	const invoices = await call.json();

	let hasPendingInvoices = false;
	for (const invoice of invoices) {
		if (invoice.status !== 'Invoiced') {
			hasPendingInvoices = true;
			break;
		}
	}

	if (call.status === 200) {
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_SET_BILLS',
			payload: {
				invoiceRowData: invoices,
				hasPendingInvoices: hasPendingInvoices
			}
		});

		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_INVOICE_LOADING',
			loading: false
		});
	}
};

export const getBillableItems = (workNumber, workId) => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing?projectInvoices=${workNumber}`;
	const call = await customFetch(endpoint);
	const response = await call.json();

	if (call.status === 200) {
		dispatch({
			type: 'WORK_VIEW_INVOICING_GRID_DATA',
			payload: {
				rowData: response
			}
		});
	}
};

export const getVendorPOs = (workId, workNumber) => async (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_PROJECT_COST_VENDOR_PURCHASE_ORDERS_LOADING',
		payload: {
			value: true
		}
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing?vendorPOs=${workNumber}`;
	const call = await customFetch(endpoint);
	const purchaseOrders = await call.json();

	// Check for POs in status of OPEN
	let hasOpenPOs = false;
	for (const purchaseOrder of purchaseOrders) {
		if (purchaseOrder.poStatus === 'OPEN') {
			hasOpenPOs = true;
			break;
		}
	}

	if (call.status === 200) {
		dispatch({
			type: 'WORK_VIEW_PROJECT_COST_SET_VENDOR_PURCHASE_ORDERS',
			payload: {
				rowData: purchaseOrders,
				hasOpenPOs
			}
		});

		dispatch({
			type: 'WORK_VIEW_PROJECT_COST_VENDOR_PURCHASE_ORDERS_LOADING',
			payload: {
				value: false
			}
		});
	}
};

export const toggleInvoiceSpreadsheet =
	(billIndex = null, spreadSheetData = []) =>
	(dispatch) => {
		dispatch(clearInsertedBillableItemData());
		return dispatch({
			type: 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_TOGGLE',
			payload: {
				billIndex,
				spreadSheetData
			}
		});
	};

export const getOriginalEstimates = () => async (dispatch, getState) => {
	dispatch({
		type: 'WORK_VIEW_BILL_ORIGINAL_ESTIMATES_SET_LOADED',
		payload: false
	});
	const workId = getState().work.workRecord.view.id;

	try {
		const response = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing?checkNull=true`
		);
		if (response.status !== 200) {
			throw response.body;
		}

		let estimates = await response.json();

		estimates = estimates.map((est) => {
			est.originalBillId = est.id;
			est.id = uuid();
			delete est.createdOn;
			delete est.workId;
			delete est.createdBy;
			return est;
		});

		dispatch({
			type: 'WORK_VIEW_BILL_SET_ORIGINAL_ESTIMATES',
			payload: estimates
		});
	} catch (err) {
		console.log(`Error: ${err}`);
	} finally {
		dispatch({
			type: 'WORK_VIEW_BILL_ORIGINAL_ESTIMATES_SET_LOADED',
			payload: true
		});
	}
};

export const getSKUs =
	(clientId = 0) =>
	async (dispatch, getState) => {
		// dispatch loading
		dispatch({
			type: 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_UPDATE_LOADING',
			payload: 'gettingSkus'
		});

		///////////// used to get existing items created with work order
		const workId = getState().work.workRecord.view.id;

		// get GlobalSKUs
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/sku?clientId=${clientId}&includeGlobal=true&isActive=true`;
		const callEndPoint = await customFetch(endpoint);
		const response = await callEndPoint.json();

		dispatch({
			type: 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_GRID_GET_SKUS',
			payload: {
				skuArray: response,
				skuLoaded: true
			}
		});
		dispatch({
			type: 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_UPDATE_LOADING',
			payload: null
		});
	};

export const setMaterialMargin = (value) => ({
	type: 'WORK_VIEW_BILL_MODAL_UPDATE_MATERIAL_MARGIN',
	payload: {
		value
	}
});

export const setLaborMargin = (value) => ({
	type: 'WORK_VIEW_BILL_MODAL_UPDATE_LABOR_MARGIN',
	payload: {
		value
	}
});

export const applyMaterialMargin = () => ({
	type: 'WORK_VIEW_BILL_MODAL_APPLY_MATERIAL_MARGIN'
});

export const applyLaborMargin = () => ({
	type: 'WORK_VIEW_BILL_MODAL_APPLY_LABOR_MARGIN'
});

export const saveBill = () => async (dispatch, getState) => {
	const modalData = getState().work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal;
	const billIndex = modalData.billIndex;
	const billId = getState().work.workRecord.view.BillingCenter.invoiceRowData[billIndex].id;
	const workId = getState().work.workRecord.view.record.id;
	const billableItems = prepareBillItemsForPost(modalData.spreadSheetData);

	// insertedItems
	/*TODO: remove _insertedItem flag on skus
	this should happen on the backend if it is absolutely neccesary, but we should just be able to push the whole array instead
	*/
	const insertedItems = prepareBillItemsForPost(
		modalData.updatedBillableItems
			.filter((item) => item._isInsertedItem)
			.filter((item) => !modalData.deletedBillableItems.find((deletedItem) => deletedItem.id === item.id))
	);
	const updatedItems = prepareBillItemsForPost(
		modalData.updatedBillableItems.filter((item) => !item._isInsertedItem)
	);
	const deletedItems = modalData.deletedBillableItems.filter((item) => !item._isInsertedItem);

	const freightValue = modalData.fields[2].value;
	if (!isDollarAmount(freightValue)) {
		return dispatch({
			type: 'WORK_VIEW_CREATE_BILL_FREIGHT_DOLLAR_AMOUNT_ERROR',
			payload: {
				value: true
			}
		});
	}

	if (billableItems.length < 1) {
		return dispatch({
			type: 'WORK_VIEW_BILLING_MODAL_SET_REQUIRED_ITEMS_ERROR',
			payload: {
				value: true
			}
		});
	}

	const emptyRequiredFields = getRequiredBillFields(modalData.fields);
	if (emptyRequiredFields.length > 0) {
		return dispatch({
			type: 'WORK_VIEW_CREATE_BILL_EMPTY_REQUIRED',
			payload: emptyRequiredFields
		});
	}

	dispatch({
		type: 'WORK_VIEW_BILLING_CENTER_MODAL_LOADING',
		payload: 'start'
	});

	const fields = modalData.fields;
	const payload = {
		billId,
		//billName: fields[0].value,
		purchaseOrderNumber: fields[3].value,
		freight: fields[2].value ? Number(fields[2].value) : 0,
		billingContactId: fields[1].value ? fields[1].value : null,
		billingSow: fields[6].value ? fields[6].value : null,
		insertedItems,
		updatedItems,
		deletedItems
	};

	try {
		const call = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing`, {
			method: 'PUT',
			body: JSON.stringify(payload)
		});
		if (call.status !== 200) {
			throw response;
		}
		const response = await call.json();
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_SET_BILL',
			payload: {response, billIndex}
		});

		//TODO: we dont need to refetch data here. just update our state. Dont have time to change it right now
		await dispatch(getBills(workId));

		// Hide Loader
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_MODAL_LOADING',
			payload: null
		});
		dispatch(toggleInvoiceSpreadsheet());
	} catch (err) {
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_MODAL_LOADING',
			payload: 'failed'
		});
	}
};
export const WORK_VIEW_PUSH_DELETED_BILLABLE_ITEM = 'WORK_VIEW_PUSH_DELETED_BILLABLE_ITEM';
export const pushDeletedBillableItem = (rowIndex) => ({
	type: WORK_VIEW_PUSH_DELETED_BILLABLE_ITEM,
	payload: {rowIndex}
});
export const removeRowSpreadsheet = (rowIndex) => {
	return (dispatch) => {
		dispatch(pushDeletedBillableItem(rowIndex));
		dispatch({
			type: 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_REMOVE_ROW',
			payload: {
				rowIndex
			}
		});
	};
};

export const removeInvoiceRowSpreadsheet = (billData, rowIndex) => async (dispatch, getState) => {
	const workId = getState().work.workRecord.view.record.id;
	await dispatch(invoiceSelected([], null));

	dispatch({
		type: 'WORK_VIEW_ACTION_BILL_MODAL_LOADING',
		loading: 'deleteBill'
	});
	let payload = billData;
	payload.deleteBill = true;
	payload.billId = billData.id;
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing`;
	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(payload)
		});
		const response = await call.json();
		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'WORK_VIEW_INVOICE_REMOVE_ROW',
			payload: {
				rowIndex
			}
		});

		await dispatch(getBills(workId));

		// Hide Loader
		dispatch({
			type: 'WORK_VIEW_ACTION_BILL_MODAL_LOADING',
			loading: null
		});
		dispatch(actionBillModalToggle(null, null, null));
	} catch (err) {
		dispatch({
			type: 'WORK_VIEW_ACTION_BILL_MODAL_LOADING',
			loading: 'failed'
		});
	}
};

export const WORK_VIEW_PUSH_INSERTED_BILLABLE_ITEM = 'WORK_VIEW_PUSH_INSERTED_BILLABLE_ITEM';
export const pushInsertedBillableItem = (billableItem) => ({
	type: WORK_VIEW_PUSH_INSERTED_BILLABLE_ITEM,
	payload: {billableItem}
});
export const addRowSpreadsheet = () => (dispatch) => {
	const billableItem = {
		id: uuid(),
		isFixedRevenue: true,
		description: '',
		manufacturer: '',
		partNumber: '',
		quantity: 1,
		unit: 'EA',
		unitMaterialCost: 0,
		materialResell: 0,
		unitLaborCost: 0,
		laborResell: 0,

		_isInsertedItem: true
	};
	dispatch(pushInsertedBillableItem(billableItem));
	return dispatch({
		type: 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_ADD_ROW',
		payload: {
			object: billableItem
		}
	});
};

export const addItemSpreadsheet = (item) => ({
	type: 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_ADD_ROW',
	payload: {
		object: item
	}
});

export const selectSKUModalToggle = (SKUList) => {
	return (dispatch, getState) => {
		const toggle =
			!getState().work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.selectSKUModal.toggle;
		// Removes None value in SKUList
		let newSKULIST = SKUList.filter((SKU) => SKU.name !== 'None');
		dispatch({
			type: 'WORK_VIEW_SELECT_SKU_MODAL_TOGGLE',
			payload: {
				toggle,
				SKUList: newSKULIST
			}
		});
	};
};

export const getRowNodeData = (rowIndex) => {
	return (dispatch, getState) => {
		const rowData =
			getState().work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.spreadSheetData[rowIndex];
		return rowData;
	};
};

export const WORK_VIEW_PUSH_UPDATED_BILLABLE_ITEM = 'WORK_VIEW_PUSH_UPDATED_BILLABLE_ITEM';
export const pushUpdatedBillableItem = (index, billData) => ({
	type: WORK_VIEW_PUSH_UPDATED_BILLABLE_ITEM,
	payload: {index, billData}
});

export const updateBillableItem = (index, changedSku) => (dispatch) => {
	dispatch(pushUpdatedBillableItem(index, changedSku));
	dispatch({
		type: 'WORK_VIEW_PROJECT_BILLABLEITEM_UPDATE',
		payload: {
			index,
			value: changedSku
		}
	});
};

export const selectSKUModalAddItems = (selectedRows) => {
	return (dispatch, getState) => {
		selectedRows.forEach((row) => {
			//this way of inserting sucks hard...
			//dont have time to change it right now because im afraid it will break the edit modal
			const spreadSheetDataEmptyIndex =
				getState().work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.spreadSheetData.findIndex(
					(item) => Object.keys(item).length === 0
				);

			const object = {
				id: uuid(),
				skuId: row.id,
				isFixedRevenue: row.isFixedRevenue,
				description: row.description ? row.description : '',
				manufacturer: row.manufacturer ? row.manufacturer : '',
				partNumber: row.partNumber ? row.partNumber : '',
				unit: 'EA',
				quantity: row.quantity ? row.quantity : 1,
				unitMaterialCost: row.unitMaterialCost ? row.unitMaterialCost : 0,
				materialResell: row.materialResell ? row.materialResell : 0,
				unitLaborCost: row.unitLaborCost ? row.unitLaborCost : 0,
				laborResell: row.laborResell ? row.laborResell : 0,

				_isInsertedItem: true
			};
			//this jankery is because we are inconsistent with if these are stored as indexes or strings
			//Eventually this should be removed when the sku.unit is actually consistent...
			if (row.unit) {
				if (row.unit === 'EA' || row.unit === 'FT') {
					object.unit = row.unit;
				} else if (row.unit == 1) {
					//double equals because row.unit is a string for some reason....
					object.unit = 'FT';
				} else if (row.unit == 2) {
					object.unit = 'EA';
				} else {
					//Note to developer: please investigate how the data got into this state.
					//This error is to force bug fixes and refactoring, please do not remove.
					throw new Error('Unknown unit type');
				}
			}

			dispatch(pushInsertedBillableItem(object));

			if (spreadSheetDataEmptyIndex > -1) {
				dispatch({
					type: 'WORK_VIEW_SELECT_SKU_MODAL_ADD_ITEMS',
					payload: {
						spreadSheetDataEmptyIndex,
						object
					}
				});
			} else {
				const nextIndex =
					getState().work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.spreadSheetData.length;
				dispatch({
					type: 'WORK_VIEW_SELECT_SKU_MODAL_ADD_ITEMS',
					payload: {
						spreadSheetDataEmptyIndex: nextIndex,
						object
					}
				});
			}
		});
	};
};

const prepareBillItemsForPost = (bills) => {
	return bills.map((item) => {
		const i = {
			...item,
			totalResell: item.materialResell + item.laborResell
		};
		if (item._isInsertedItem) delete i.id;
		return i;
	});
};

const getRequiredBillFields = (fields) => {
	if (fields[3].radio === 'existing') {
		return fields
			.map((field, fieldIndex) => ({
				field: field,
				fieldIndex: fieldIndex
			}))
			.filter((field) => !field.field.value && (field.field.required || field.field.existingType));
	}
	if (fields[3].radio === 'new') {
		return fields
			.map((field, fieldIndex) => ({
				field: field,
				fieldIndex: fieldIndex
			}))
			.filter((field) => !field.field.value && (field.field.required || field.field.newType));
	}
	throw new Error('unknown radio state');
};

export const createBill = (fields) => async (dispatch, getState) => {
	// Get project costing information, return items with a required value (using quantity here).
	// Also sets description based on if SKU or not
	let billableItems =
		getState().work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.spreadSheetData.filter(
			(item) => item.quantity
		);

	const freightValue = fields[2].value;
	if (!isDollarAmount(freightValue)) {
		return dispatch({
			type: 'WORK_VIEW_CREATE_BILL_FREIGHT_DOLLAR_AMOUNT_ERROR',
			payload: {
				value: true
			}
		});
	}

	const emptyRequiredFields = getRequiredBillFields(fields);
	if (emptyRequiredFields.length > 0) {
		return dispatch({
			type: 'WORK_VIEW_CREATE_BILL_EMPTY_REQUIRED',
			payload: emptyRequiredFields
		});
	}

	//What is this for? group billing?
	const removeItems = billableItems.filter((i) => i.originalBillId !== undefined).map((i) => i.id);

	billableItems = prepareBillItemsForPost(billableItems);

	const workId = getState().work.workRecord.view.record.id;

	if (billableItems.length < 1) {
		return dispatch({
			type: 'WORK_VIEW_BILLING_MODAL_SET_REQUIRED_ITEMS_ERROR',
			payload: {
				value: true
			}
		});
	}

	dispatch({
		type: 'WORK_VIEW_BILLING_CENTER_MODAL_LOADING',
		payload: 'start'
	});

	const payload = {
		billName: fields[0].value,
		purchaseOrderNumber: fields[3].value,
		freight: fields[2].value ? Number(fields[2].value) : 0,
		billingContactId: fields[1].value,
		billingSow: fields[6].value,
		items: billableItems,
		fileName: fields[4].value,
		googleDriveLink: fields[5].value
	};

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing`;

	try {
		const call = await customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(payload)
		});
		const response = await call.json();
		if (call.status !== 200) {
			throw response;
		}
		if (removeItems.length > 0) {
			dispatch({
				type: 'WORK_VIEW_BILL_ORIGINAL_ESTIMATES_REMOVE',
				payload: removeItems
			});
		}
		dispatch({
			type: 'WORK_VIEW_CREATE_BILL',
			payload: response
		});
		// Hide Loader
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_MODAL_LOADING',
			payload: null
		});
		dispatch({
			type: 'WORK_VIEW_BILLING_MODAL_SET_REQUIRED_ITEMS_ERROR',
			payload: {
				value: false
			}
		});
		dispatch(toggleInvoiceSpreadsheet());
	} catch (err) {
		return dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_MODAL_LOADING',
			payload: 'failed'
		});
	}
};

export const billingInvoiceGridUpdate = (rowIndex, column, value) => async (dispatch, getState) => {
	// Get bill info, return items with a required value (using quantity here).
	// Also sets description based on if SKU or not
	console.log(rowIndex);
	const bill = getState().work.workRecord.view.BillingCenter.invoiceRowData[rowIndex];
	const billableItems = bill.items.filter((item) => item.quantity);

	const payload = bill;
	if (column) {
		/// Load field on updating
		if (column === 'billingContactId') {
			dispatch({
				type: 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_INVOICE_GRID_CONTACT_LOADING',
				payload: {
					value: true
				}
			});
		}
		// Add column being updated for non bill item updates
		payload[column] = value;
	}
	payload.items = billableItems;
	payload.billId = bill.id;

	const workId = getState().work.workRecord.view.record.id;
	/// Fix for contact
	dispatch({
		type: 'WORK_VIEW_BILLING_CENTER_INVOICE_LOADING',
		loading: true
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(payload)
		});
		const response = await call.json();
		if (call.status !== 200) {
			throw response;
		}
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_SET_BILL',
			payload: {response, rowIndex}
		});
		// Hide Loader
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_INVOICE_LOADING',
			loading: false
		});
		if (column === 'billingContactId') {
			dispatch({
				type: 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_INVOICE_GRID_CONTACT_LOADING',
				payload: {
					value: false
				}
			});
		}
	} catch (err) {
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_INVOICE_LOADING',
			loading: false
		});
		if (column === 'billingContactId') {
			dispatch({
				type: 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_INVOICE_GRID_CONTACT_LOADING',
				payload: {
					value: true
				}
			});
		}
	}
};

export const invoiceSelected = (data, rowIndex) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_INVOICE_SELECTED',
		payload: {
			rowData: data.items,
			rowIndex,
			contactOption: {
				key: data.billingContactId,
				value: data.billingContactId,
				text: data.billingContactName
			}
		}
	});
};

export const createModalUpdateRadio = (fieldIndex, value) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_BILLING_CREATE_MODAL_RADIO',
		payload: {fieldIndex, value}
	});
};

export const createModalUpdateColumn = (fieldIndex, value, error) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_BILLING_CREATE_MODAL_UPDATE',
		payload: {fieldIndex, value, error}
	});
};

export const employeeUpdateValue = (value, name) => (dispatch) => {
	dispatch({
		type: 'UPDATE_EMPLOYEE_VALUE',
		payload: {value, name}
	});
};

export const employeeSearch = (value, name) => async (dispatch) => {
	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}`;

	dispatch({
		type: 'UPDATE_EMPLOYEE_LOADING',
		payload: {
			value: true,
			name
		}
	});

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			return Promise.reject(response);
		}

		let employees = response.map((employee) => ({
			key: employee.id,
			text: employee.fullName,
			value: employee.id
		}));
		dispatch({
			type: 'UPDATE_EMPLOYEE_OPTIONS',
			payload: {
				value: employees,
				name
			}
		});
		dispatch({
			type: 'UPDATE_EMPLOYEE_LOADING',
			payload: {
				value: false,
				name
			}
		});
	} catch (err) {
		dispatch({
			type: 'ERROR',
			payload: err.stack
		});
		dispatch({
			type: 'UPDATE_EMPLOYEE_LOADING',
			payload: {
				value: false,
				name
			}
		});
	}
};

export const updateWorkField = (id, name, value, quotedJobNumber, isOpp = false, nodeIndex = null) => {
	// quotedJobNumber is used to set all child jobs to invoiced when parent is invoiced
	return (dispatch) => {
		if (isOpp) {
			customFetch(`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}`, {
				method: 'PUT',
				body: JSON.stringify({
					[name]: moment(value, 'MM-DD-YYYY').format('MM-DD-YYYY').toString()
				})
			})
				.then((response) => {
					if (response.status !== 200) {
						return Promise.reject(response);
					}

					return response.json();
				})
				.then((data) => {
					if (nodeIndex) {
						dispatch(updateGridItem(nodeIndex, moment(value, 'MM-DD-YYYY'), name));
					}

					return data;
				})
				.catch((err) => {
					dispatch({
						type: 'ERROR',
						payload: err.stack
					});

					return Promise.reject(err);
				});
			return;
		}

		let body;
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${id}`;
		let field = '';

		if (name === 'Project Manager') {
			body = {
				projectManagerId: value
			};
		} else if (name === 'Project Administrator') {
			body = {
				ndsId: value
			};
		} else if (name === 'Sales Representative') {
			body = {
				accountExecutiveId: value
			};
		} else if (name === 'Purchase Order') {
			body = {
				purchaseOrderNumber: value
			};
		} else if (name === 'ASD Job Number') {
			body = {
				workNumber: value
			};
		} else if (name === 'Service Type') {
			if (value === 4) {
				body = {
					serviceType: value,
					serviceSubType: 1
				};
			} else {
				body = {
					serviceType: value,
					serviceSubType: null
				};
			}
		} else if (name === 'Work Category') {
			body = {
				workCategory: value
			};
		} else if (name === 'AASDI') {
			body = {
				client_Name: value
			};
		} else if (name === 'statusName') {
			if (quotedJobNumber && value === 'Invoiced') {
				endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${id}?mode=qjnparent`;
				body = {
					qjn: quotedJobNumber
				};
			} else {
				body = {
					status: value
				};
			}
		} else if (name === 'subType') {
			body = {
				serviceSubType: value
			};
		} else if (name === 'Quoted Job Number') {
			body = {
				quotedJobNumber: value
			};
		} else if (name === 'Master Client') {
			body = {
				masterClientId: value
			};
		} else if (name === 'Google Drive Link') {
			body = {
				googleDriveLink: value
			};
		} else if (name === 'startDate') {
			name = 'Start Date';
			field = 'startDate';
			body = {
				startDate: moment(value, 'MM-DD-YYYY').format('MM-DD-YYYY').toString()
			};
		} else if (name === 'endDate') {
			name = 'End Date';
			field = 'endDate';
			body = {
				endDate: moment(value, 'MM-DD-YYYY').format('MM-DD-YYYY').toString()
			};
		}

		if (
			name !== 'statusName' &&
			name !== 'subType' &&
			name !== 'Project Administrator' &&
			name !== 'Sales Representative' &&
			name !== 'Project Manager' &&
			name !== 'Master Client' &&
			name !== 'startDate' &&
			name !== 'endDate'
		) {
			dispatch({
				type: 'WORK_VIEW_UPDATE_FIELD',
				payload: {
					name,
					value,
					error: null
				}
			});
		} else if (name === 'subType') {
			dispatch({
				type: 'UPDATE_SERVICE_SUBTYPE_FIELD',
				payload: {
					value
				}
			});
		}

		if (name === 'Google Drive Link') {
			dispatch({
				type: 'UPDATE_GOOGLE_DRIVE_LINK',
				payload: {
					value
				}
			});
		}

		customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(body)
		})
			.then((response) => {
				if (response.status !== 200) {
					return response.json().then((err) => {
						throw new Error(err.error);
					});
				}
				return response.json();
			})
			.catch((err) => {
				dispatch({
					type: 'WORK_VIEW_UPDATE_FIELD',
					payload: {
						name,
						value,
						error: err
					}
				});
			});
	};
};

export const getDropdownOptions = (name, clientId, value) => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_LOADING_FIELD',
			payload: {
				name,
				value: true
			}
		});

		let endpoint;
		if (name === 'Contact') {
			endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}&name=${value}&type=0`;
		} else if (name === 'Address') {
			endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations?limit=8&clientId=${clientId}&search=${value}`;
		} else if (name === 'Master Client') {
			endpoint = `${process.env.REACT_APP_API_URL}/legacy/client?clientSearch=${encodeURIComponent(value)}`;
		}

		// customFetch for work by id
		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json.forEach((item, index) => {
					if (name === 'Contact') {
						json[index].key = item.id;
						json[index].text = item.fullName;
						json[index].value = item.id;
					} else if (name === 'Address') {
						let fullAddress = `
						${item.name}
						${item.storeNumber ? ', #:' + item.storeNumber : ''}
						${item.city ? ' , City:' + item.city : ''}
						${item.state ? ', State:' + item.state : ''}
						`;

						json[index].key = item.id;
						json[index].text = fullAddress;
						json[index].value = item.id;
					} else if (name === 'Master Client') {
						json[index].key = item.id;
						json[index].text = item.name;
						json[index].value = item.id;
					}
				});
				dispatch({
					type: 'SET_OPTIONS',
					payload: {
						name,
						json
					}
				});
				dispatch({
					type: 'TOGGLE_LOADING_FIELD',
					payload: {
						name,
						value: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const setOption = (name, record, options, sectionId) => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_LOADING_FIELD',
			payload: {
				name,
				value: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${record.id}`;
		let body;
		if (name === 'Address') {
			body = {
				locationId: sectionId
			};
		} else if (name === 'Contact') {
			body = {
				billingContactId: sectionId
			};
		}

		customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(body)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				let selectedRecord = options[options.findIndex((option) => option.id === sectionId)];

				let newSelected;

				if (name === 'Address') {
					newSelected = {
						name: `${selectedRecord.name}`,
						address: '',
						city: ''
					};

					if (selectedRecord.address) {
						newSelected.address += ` ${selectedRecord.address}`;
					}
					if (selectedRecord.address2) {
						newSelected.address += `, ${selectedRecord.address2}`;
					}
					if (selectedRecord.city) {
						newSelected.city += ` ${selectedRecord.city}`;
					}
					if (selectedRecord.state) {
						newSelected.city += `, ${selectedRecord.state}`;
					}
					if (selectedRecord.zip) {
						newSelected.city += `, ${selectedRecord.zip}`;
					}
					if (selectedRecord.countryName) {
						newSelected.city += `, ${selectedRecord.countryName}`;
					}
				} else if (name === 'Contact') {
					newSelected = {
						name: `${selectedRecord.fullName}`,
						phone: 'No Phone Number',
						email: 'No Email'
					};

					if (selectedRecord.title) {
						newSelected.name += `, ${selectedRecord.title}`;
					}
					if (selectedRecord.phone) {
						newSelected.phone = `${selectedRecord.phone}`;
					}
					if (selectedRecord.email) {
						newSelected.email = `${selectedRecord.email}`;
					}
				}

				dispatch({
					type: 'UPDATE_SELECTED_INFO',
					payload: {
						name,
						value: newSelected
					}
				});
			})
			.then(() => {
				dispatch({
					type: 'TOGGLE_LOADING_FIELD',
					payload: {
						name,
						value: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const getWorkSOW = (id) => async (dispatch) => {
	dispatch({
		type: 'WORK_SOW_LOADING_TOGGLE',
		payload: {
			value: true
		}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${id}/sow`;

	const getSOW = await customFetch(endpoint);
	const getSOWjson = await getSOW.json();

	if (getSOW.status !== 200) {
		return Promise.reject(getSOW.response);
	}

	if (getSOWjson.length > 0) {
		dispatch({
			type: 'SET_WORK_SOW',
			payload: {
				json: getSOWjson[0]
			}
		});
	}

	dispatch({
		type: 'WORK_SOW_LOADING_TOGGLE',
		payload: {
			value: false
		}
	});
};

export const updateWorkSOW = (workId, value) => async (dispatch) => {
	dispatch({
		type: 'WORK_SOW_LOADING_TOGGLE',
		payload: {
			value: true
		}
	});

	if (workId) {
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}`;
		const updateSOW = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify({
				scopeOfWork: value
			})
		});
		const updateSOWjson = await updateSOW.json();

		if (updateSOW.status !== 200) {
			return Promise.reject(updateSOW.response);
		}

		dispatch({
			type: 'SET_WORK_SOW',
			payload: {
				json: updateSOWjson
			}
		});
		dispatch({
			type: 'WORK_SOW_LOADING_TOGGLE',
			payload: {
				value: false
			}
		});
	} else {
		dispatch({
			type: 'ERROR',
			payload: 'No workId provided'
		});
	}
};

export const getWorkViewOrders = (quotedJobNumber, location) => async (dispatch) => {
	console.log('hit inside getWorkViewOrders');
	if (location === 'selectWO') {
		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
			payload: {
				loading: 'loading'
			}
		});
	} else {
		dispatch({
			type: 'WORK_ORDERS_LOADING_TOGGLE',
			payload: {
				value: true
			}
		});
	}

	if (!quotedJobNumber) {
		dispatch({
			type: 'SET_WORK_ORDERS',
			payload: {
				json: []
			}
		});
		dispatch({
			type: 'WORK_ORDERS_LOADING_TOGGLE',
			payload: {
				value: false
			}
		});
		return;
	}

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?quotedJobNumber=${quotedJobNumber}`;

	const getOrders = await customFetch(endpoint);
	const getOrdersjson = await getOrders.json();

	getOrdersjson.forEach((item, index) => {
		item.createdOn = moment(getOrdersjson[index].createdOn).format('MMMM D YYYY, hh:mm a');
	});

	if (getOrders.status !== 200) {
		return Promise.reject(getOrders.response);
	}

	if (getOrdersjson.length > 0) {
		/// Using same GET WOs for Add Order Group Select WO Modal
		if (location === 'selectWO') {
			dispatch({
				type: 'WORK_VIEW_ADD_ORDER_GROUP_SELECT_WO_MODAL_DATA',
				payload: {
					json: getOrdersjson.filter((order) => !order.isProject)
				}
			});
		}
		// Logic for normal orders tab
		else {
			dispatch({
				type: 'SET_WORK_ORDERS',
				payload: {
					json: getOrdersjson.filter((order) => !order.isProject)
				}
			});
		}
	}

	if (location === 'selectWO') {
		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
			payload: {
				loading: false
			}
		});
	} else {
		dispatch({
			type: 'WORK_ORDERS_LOADING_TOGGLE',
			payload: {
				value: false
			}
		});
	}
};

export const selectAASDI = (index, array) => (dispatch) => {
	array.forEach((item, mapIndex) => {
		if (index === mapIndex) {
			dispatch({
				type: 'WORK_VIEW_SELECT_AASDI',
				payload: {
					index: mapIndex,
					selected: true,
					id: array[index].id
				}
			});
		} else {
			dispatch({
				type: 'WORK_VIEW_SELECT_AASDI',
				payload: {
					index: mapIndex,
					selected: false,
					id: array[index].id
				}
			});
		}
	});
};

export const setWorkAasdiIdCheck = (id) => (dispatch) => {
	dispatch({
		type: 'SET_WORK_VIEW_AASDI_ID_CHECK',
		payload: {id}
	});
};

export const toggleAASDIModal = (value) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_TOGGLE_AASDI_MODAL',
		payload: {value}
	});
};

export const toggleDetailAASDIModal = (value) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_TOGGLE_AASDI_DETAIL_MODAL',
		payload: {value}
	});
};

export const getWorkViewAASDI = (zip, radius, name, aasdiArray) => async (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_AASDI_LOADING',
		payload: {
			value: true
		}
	});

	if (radius === '>200') {
		radius = 'ANY';
	}

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/locations/${zip}?radius=${radius}`;
	if (name) {
		endpoint += `&name=${name}`;
	}

	const searchAASDI = await customFetch(endpoint);
	let searchAASDIjson = await searchAASDI.json();

	//removes locations with aasdiIds that are already associated with this workId
	let ids = [];
	aasdiArray.forEach((item) => {
		ids.push(item.id);
	});
	if (searchAASDIjson > 0) {
		searchAASDIjson = searchAASDIjson.filter((e) => !ids.includes(e.aasdiId));
	}
	if (searchAASDI.status !== 200) {
		if (searchAASDIjson.error === 'No results') {
			dispatch({
				type: 'SET_WORK_VIEW_SEARCH_AASDI_ZIP_ERROR',
				payload: {
					value: false
				}
			});
			dispatch({
				type: 'SET_WORK_VIEW_SEARCH_AASDI',
				payload: {
					json: []
				}
			});
			dispatch({
				type: 'WORK_VIEW_AASDI_LOADING',
				payload: {
					value: false
				}
			});
		} else {
			dispatch({
				type: 'SET_WORK_VIEW_SEARCH_AASDI_ZIP_ERROR',
				payload: {
					value: true
				}
			});
			dispatch({
				type: 'SET_WORK_VIEW_SEARCH_AASDI',
				payload: {
					json: []
				}
			});
			dispatch({
				type: 'WORK_VIEW_AASDI_LOADING',
				payload: {
					value: false
				}
			});
		}

		return Promise.reject(searchAASDI.response);
	}

	if (searchAASDIjson.length > 0) {
		dispatch({
			type: 'SET_WORK_VIEW_SEARCH_AASDI',
			payload: {
				json: searchAASDIjson
			}
		});
	}

	dispatch({
		type: 'WORK_VIEW_AASDI_LOADING',
		payload: {
			value: false
		}
	});
	dispatch({
		type: 'SET_WORK_VIEW_SEARCH_AASDI_ZIP_ERROR',
		payload: {
			value: false
		}
	});
};

export const toggleWorkViewZipError = (value) => (dispatch) => {
	dispatch({
		type: 'SET_WORK_VIEW_SEARCH_AASDI_ZIP_ERROR',
		payload: {
			value
		}
	});
};

export const aasdiNameSearch = (value) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_AASDI_NAME_SEARCH',
		payload: {value}
	});
};

export const aasdiRadiusSearch = (value) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_AASDI_RADIUS',
		payload: {value}
	});
};

export const aasdiZipSearch = (value) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_AASDI_ZIP',
		payload: {value}
	});
};

export const addAASDI = (value, id) => async (dispatch, getState) => {
	dispatch({
		type: 'WORK_VIEW_AASDI_LOADING_FIELD',
		payload: {
			loading: true
		}
	});
	dispatch({
		type: 'WORK_VIEW_TOGGLE_AASDI_MODAL',
		payload: {value: false}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${id}/aasdi`;

	const addAASDI = await customFetch(endpoint, {
		method: 'POST',
		body: JSON.stringify({
			aasdiId: value[0].aasdiId,
			locationId: value[0].id
		})
	});
	const addAASDIjson = await addAASDI.json();

	if (addAASDI.status !== 200) {
		return Promise.reject(addAASDI.status);
	}

	if (addAASDIjson.length > 0) {
		const workAasdiId = addAASDIjson[0].id;
		const workSOW = getState().work.workRecord.view.overview.sow.value;

		let createTripEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work?workId=${workAasdiId}&mode=aasdi-trip`;

		const postTrip = await customFetch(createTripEndpoint, {
			method: 'POST',
			body: JSON.stringify({
				name: 'Trip 1',
				contactId: null,
				requestedDate: null,
				actualDate: null,
				sowText: workSOW,
				specialText: null,
				aasdiId: value[0].aasdiId
			})
		});

		let postTripJSON = null;
		if (postTrip.status === 200) {
			postTripJSON = await postTrip.json();
		}

		let aasdiEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${id}/aasdi`;

		customFetch(aasdiEndpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let newAasdi =
					json[
						json.findIndex((aasdis) => {
							return aasdis.id === addAASDIjson[0].aasdiId;
						})
					];
				let newUnratedAasdi = {aasdiId: newAasdi.id, name: newAasdi.name};
				dispatch({
					type: 'ADD_UNRATED_AASDI',
					payload: {
						newUnratedAasdi
					}
				});

				if (json.length === 1) {
					if (postTripJSON) dispatch(getWorkViewTrip(postTripJSON[0].id, null));
				}

				json.forEach((item, index) => {
					if (index === 0) {
						json[index].selected = true;
					} else {
						json[index].selected = false;
					}
				});

				dispatch({
					type: 'GET_WORK_VIEW_AASDI',
					payload: {
						json
					}
				});
			})
			.then(() => {
				dispatch({
					type: 'WORK_VIEW_AASDI_LOADING_FIELD',
					payload: {
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
		dispatch({
			type: 'SET_WORK_VIEW_ADD_AASDI',
			payload: {
				json: addAASDIjson
			}
		});
	}
};

export const removeAASDI = (workId, aasdiId) => async (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_AASDI_LOADING_FIELD',
		payload: {
			loading: true
		}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/aasdi/${aasdiId}`;

	const removeAASDI = await customFetch(endpoint, {
		method: 'DELETE'
	});
	const removeAASDIjson = await removeAASDI.json();

	if (removeAASDI.status !== 200) {
		return Promise.reject(removeAASDI);
	}

	if (removeAASDIjson.id > 0) {
		dispatch({
			type: 'REMOVE_UNRATED_AASDI',
			payload: {
				aasdiId
			}
		});

		let aasdiEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/aasdi`;

		customFetch(aasdiEndpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json.forEach((item, index) => {
					if (index === 0) {
						json[index].selected = true;
					} else {
						json[index].selected = false;
					}
				});

				dispatch({
					type: 'GET_WORK_VIEW_AASDI',
					payload: {
						json
					}
				});
			})
			.then(() => {
				dispatch({
					type: 'WORK_VIEW_AASDI_LOADING_FIELD',
					payload: {
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
		dispatch({
			type: 'SET_WORK_VIEW_ADD_AASDI',
			payload: {
				json: removeAASDIjson
			}
		});
	}
};

export const createBillUpdateColumnDropdown = (name, clientId, fieldIndex, value) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_VIEW_BILLING_CREATE_MODAL_UPDATE_LOADER',
			payload: {
				fieldIndex,
				loading: true
			}
		});

		let endpoint;
		if (name === 'billingContact') {
			endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}&name=${value}&type=1`;
		} else if (name === 'purchaseOrder') {
			return;
			// endpoint = `${
			// 	process.env.REACT_APP_API_URL
			// }/locations?clientId=${clientId}&name=${value}`;
		}

		// customFetch for work by id
		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json.forEach((item, index) => {
					if (name === 'billingContact') {
						json[index].key = item.id;
						json[index].text = item.fullName;
						json[index].value = item.id;
					} else if (name === 'purchaseOrder') {
						// json[index].key = item.id;
						// json[index].text = item.name;
						// json[index].value = item.id;
					}
				});
				dispatch({
					type: 'WORK_VIEW_BILLING_CREATE_MODAL_UPDATE_OPTIONS',
					payload: {
						fieldIndex,
						json
					}
				});
				dispatch({
					type: 'WORK_VIEW_BILLING_CREATE_MODAL_UPDATE_LOADER',
					payload: {
						fieldIndex,
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'WORK_VIEW_BILLING_CREATE_MODAL_UPDATE_LOADER',
					payload: {
						fieldIndex,
						loading: false
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const updateAasdiInfoSection = (value, index, sectionIndex, fieldIndex) => (dispatch) => {
	dispatch({
		type: 'UPDATE_AASDI_INFO_SECTION',
		payload: {value, index, sectionIndex, fieldIndex}
	});
};

export const cancelProjectCostingSpreadsheet = (billIndex) => (dispatch, getState) => {
	const useSavedRowData = getState().work.workRecord.view.BillingCenter.invoiceRowData;
	dispatch(toggleInvoiceSpreadsheet(billIndex, useSavedRowData));
};

export const billingContactUpdateDropdown = (clientId, value, currentSelectedInvoiceIndex) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_INVOICE_GRID_CONTACT_LOADING',
			payload: {
				value: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}&name=${value}&type=1`;

		// customFetch for work by id
		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json.forEach((item, index) => {
					json[index].key = item.id;
					json[index].text = item.fullName;
					json[index].value = item.id;
				});
				dispatch({
					type: 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_CONTACT_OPTIONS',
					payload: {json, currentSelectedInvoiceIndex}
				});
				dispatch({
					type: 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_INVOICE_GRID_CONTACT_LOADING',
					payload: {
						value: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'WORK_VIEW_BILLING_CENTER_UPDATE_BILLING_INVOICE_GRID_LOADING',
					payload: {
						loading: true,
						column: 'billContactOptions',
						currentSelectedInvoiceIndex
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const actionBillModalToggle = (billItem, place, rowIndex) => (dispatch, getState) => {
	const actionBillToggled = getState().work.workRecord.view.BillingCenter.actionBillModal.actionBillModalToggle;
	dispatch({
		type: 'WORK_VIEW_ACTION_BILL_MODAL_TOGGLE',
		payload: {toggle: !actionBillToggled, data: billItem, place, rowIndex}
	});
};

export const submitWOBill = (billData, rowIndex) => async (dispatch, getState) => {
	// dispatch loader
	const workId = getState().work.workRecord.view.record.id;
	await dispatch(invoiceSelected([], null));

	dispatch({
		type: 'WORK_VIEW_ACTION_BILL_MODAL_LOADING',
		loading: 'submitBill'
	});
	let payload = billData;
	payload.submitBill = true;
	payload.billId = billData.id;
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/billing`;
	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(payload)
		});
		const response = await call.json();
		if (call.status !== 200) {
			throw response;
		}
		dispatch({
			type: 'WORK_VIEW_BILLING_CENTER_SET_BILL',
			payload: {
				rowIndex,
				response
			}
		});
		// Hide Loader
		dispatch({
			type: 'WORK_VIEW_ACTION_BILL_MODAL_LOADING',
			loading: null
		});
		dispatch(actionBillModalToggle(null, null, null));
	} catch (err) {
		dispatch({
			type: 'WORK_VIEW_ACTION_BILL_MODAL_LOADING',
			loading: 'failed'
		});
	}
};

export const getProjectCostingSummary = (workId) => async (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_PROJECT_COSTING_SUMMARY_LOADING',
		payload: {value: true}
	});

	let projectCosting = {
		revenue: {
			estimatedRevenue: 0,
			estimatedCORevenue: 0,
			estimatedTotalRevenue: 0,
			actualRevenue: 0,
			actualCORevenue: 0,
			revisedContract: 0
		},
		cost: {
			estimatedCost: 0,
			estimatedCOCost: 0,
			estimatedTotalCost: 0,
			actualCost: 0,
			actualCOCost: 0,
			overUnderBudget: 0
		},
		marginGP: {
			estimatedMargin: 0,
			estimatedGrossProfit: 0,
			actualMargin: 0,
			actualGrossProfit: 0,
			remainingToBill: 0
		}
	};

	dispatch({
		type: 'WORK_VIEW_PROJECT_COSTING_SUMMARY',
		payload: projectCosting
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}?changes=true&projectbilling=true`;

	const call = await customFetch(endpoint);
	const response = await call.json();

	if (response.status === 200) {
		dispatch({
			type: 'WORK_VIEW_SET_BILLABLE_ITEMS',
			payload: response
		});
	}

	dispatch({
		type: 'WORK_VIEW_SET_CHANGE_ORDERS_GRID',
		payload: response.changeOrders
	});

	if (response.billing && response.billing.length > 0) {
		response.billing.forEach((bill, index) => {
			// REVENUE
			projectCosting.revenue.estimatedRevenue = bill.Revised_Contract; //this takes into account change orders from the spectrum side already. original_contract does not.
			projectCosting.revenue.actualRevenue = bill.Billed; //this *should* include the change order values (need confirmation)

			// COST
			projectCosting.cost.estimatedCost += bill.Est_Cost;
			projectCosting.cost.actualCost += bill.JTD_Cost;
		});
	}

	if (response.changeOrders && response.changeOrders.length > 0) {
		response.changeOrders.forEach((co) => {
			if (co.Status_Code === 'E ') {
				//change order is estimate if it has not been posted yet
				//these calculations need to be the same
				if (co.Post_Flag !== 'Y') {
					projectCosting.revenue.estimatedCORevenue += co.Change_Order_Revenue_Amount;
					projectCosting.cost.estimatedCOCost += projectCosting.cost.Total_Cost;
				} else {
					projectCosting.revenue.actualCORevenue += co.Change_Order_Revenue_Amount;
					projectCosting.cost.actualCOCost += projectCosting.cost.Total_Cost;
				}
			}
		});
	}

	// REVENUE
	projectCosting.revenue.estimatedTotalRevenue = projectCosting.revenue.estimatedRevenue;

	// COST
	projectCosting.cost.estimatedTotalCost = projectCosting.cost.estimatedCost + projectCosting.cost.estimatedCOCost;
	projectCosting.cost.actualTotalCost = projectCosting.cost.actualCost + projectCosting.cost.actualCOCost;
	projectCosting.cost.overUnderBudget = projectCosting.cost.estimatedTotalCost - projectCosting.cost.actualCost;

	// MARGIN
	projectCosting.marginGP.estimatedGrossProfit =
		projectCosting.revenue.estimatedTotalRevenue - projectCosting.cost.estimatedTotalCost;

	projectCosting.marginGP.estimatedMargin =
		calcMargin(projectCosting.cost.estimatedTotalCost, projectCosting.revenue.estimatedTotalRevenue) * 100;
	projectCosting.marginGP.actualGrossProfit = projectCosting.revenue.actualRevenue - projectCosting.cost.actualCost;

	projectCosting.marginGP.actualMargin =
		calcMargin(projectCosting.cost.actualTotalCost, projectCosting.revenue.actualRevenue) * 100;
	projectCosting.marginGP.remainingToBill = calcRemainingToBill(
		projectCosting.revenue.estimatedRevenue,
		projectCosting.revenue.actualRevenue
	);

	dispatch({
		type: 'WORK_VIEW_PROJECT_COSTING_SUMMARY',
		payload: projectCosting
	});

	dispatch({
		type: 'WORK_VIEW_PROJECT_COSTING_SUMMARY_LOADING',
		payload: {value: false}
	});
};

/* ORDER GROUP LOGIC */

export const toggleAddOrderGroupModal = (type, rowData, rowIndex) => (dispatch, getState) => {
	const addOrderGroupModalToggled =
		getState().work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.toggle;
	dispatch({
		type: 'WORK_VIEW_ADD_ORDER_GROUP_MODAL_TOGGLE',
		payload: {toggle: !addOrderGroupModalToggled, type}
	});
	// rowData = rowData.members;
	if (rowData) {
		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_MODAL_AUTOFILL',
			payload: {rowData, rowIndex}
		});
	}
};

export const selectWOModalToggle = (rowData) => (dispatch, getState) => {
	// Check to remove any work orders already on order group so they cant be selected again
	let currentSelectedWOPool =
		getState().work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.selectWOModal.rowData;
	if (rowData.length) {
		// Stack overflow for the win
		let newArray = currentSelectedWOPool.filter((o) => !rowData.find((o2) => o.id === o2.id));

		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_SELECT_WO_MODAL_DATA',
			payload: {
				json: newArray
			}
		});
	}

	// Toggle logic
	const selectWoModalToggled =
		getState().work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.selectWOModal.toggle;
	dispatch({
		type: 'WORK_VIEW_ADD_ORDER_GROUP_SELECT_WO_MODAL_TOGGLE',
		payload: {toggle: !selectWoModalToggled}
	});
};

export const addOrderGroupModalUpdateColumn = (fieldIndex, value, error) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE',
		payload: {fieldIndex, value, error}
	});
};

export const addOrderGroupModalUpdateColumnDropdown = (name, clientId, fieldIndex, value) => {
	return (dispatch) => {
		dispatch({
			type: 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE_LOADER',
			payload: {
				fieldIndex,
				loading: true
			}
		});

		let endpoint;
		if (name === 'billingContact') {
			endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}&name=${value}&type=1`;
		}
		// customFetch for work by id
		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json.forEach((item, index) => {
					if (name === 'billingContact') {
						json[index].key = item.id;
						json[index].text = item.fullName;
						json[index].value = item.id;
					}
				});
				dispatch({
					type: 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE_OPTIONS',
					payload: {
						fieldIndex,
						json
					}
				});
				dispatch({
					type: 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE_LOADER',
					payload: {
						fieldIndex,
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'WORK_VIEW_BILLING_ADD_ORDER_GROUP_MODAL_UPDATE_LOADER',
					payload: {
						fieldIndex,
						loading: false
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const selectWOModalAddItems = (selectedItems) => (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_ADD_ORDER_GROUP_ADD_SELECTED_ITEMS',
		payload: selectedItems
	});
};

export const removeWO = (rowIndex, rowData) => (dispatch, getState) => {
	let currentSelectedWOPool =
		getState().work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.selectWOModal.rowData;

	let checkWO = currentSelectedWOPool.filter((o) => rowData.id === o.id);

	// Don't add into selected pool as it is already there
	if (checkWO.length) {
		return dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_REMOVE_ITEM',
			payload: {
				rowIndex
			}
		});
	}

	dispatch({
		type: 'WORK_VIEW_ADD_ORDER_GROUP_REMOVE_AND_ADD_SELECT_ITEM',
		payload: {
			rowIndex,
			rowData
		}
	});

	const parentID = getState().work.workRecord.view.id;
	dispatch(getGroups(parentID));
};

export const removeOrderGroup = (rowIndex, comment, groupData) => async (dispatch) => {
	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${groupData.id}?mode=group`;

	let workIdsArray = [];
	groupData.members.forEach((member) => {
		workIdsArray.push(member.id);
	});

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify({
				remove: workIdsArray,
				status: 'Canceled'
			})
		});

		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch({
			type: 'WORK_VIEW_CANCEL_ORDER_GROUP',
			payload: {
				rowIndex
			}
		});

		dispatch({
			type: 'WORK_VIEW_SUBMIT_REMOVE_GROUP_MODAL_TOGGLE',
			payload: {
				toggle: false
			}
		});
	}
};

export const orderGroupAddSave = (fields, workId, workOrders, type, rowIndex) => async (dispatch, getState) => {
	// type variable checks to add new order group or save existing one
	// VALIDATION ON ADD ORDER GROUP MODAL //
	let emptyRequiredFields = fields
		.map((field, fieldIndex) => ({
			field: field,
			fieldIndex: fieldIndex
		}))
		.filter((field) => field.field.error || (!field.field.value && field.field.required));
	if (emptyRequiredFields.length > 0) {
		return dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_EMPTY_REQUIRED',
			payload: emptyRequiredFields
		});
	}
	// END VALIDATION

	let workOrderIds = workOrders.map((workOrder) => {
		return workOrder.id;
	});

	let callBody;

	if (type === 'create') {
		callBody = {
			workOrderArray: workOrderIds,
			projectId: workId,
			description: fields[0].value,
			purchaseOrderNumber: fields[1].value,
			quotedJobNumber: fields[2].value,
			billingContactId: fields[3].options.filter((option) => option.value === fields[3].value)[0].id,
			isGroup: true
		};
	} else {
		const oldGroup = getState().work.workRecord.view.BillingCenter.orderGroup.oldGroup;

		let remove = [];
		oldGroup.rowData.forEach((oldWO) => {
			let index = workOrderIds.findIndex((id) => id === oldWO.id);
			if (index === -1) {
				remove.push(oldWO.id);
			}
		});

		let add = [];
		workOrderIds.forEach((id) => {
			let index = oldGroup.rowData.findIndex((oldWO) => oldWO.id === id);
			if (index === -1) {
				add.push(id);
			}
		});

		let noChanges = true;

		callBody = {parentId: workId};

		if (oldGroup.fields[0].value !== fields[0].value) {
			noChanges = false;
			callBody.description = fields[0].value;
		}

		if (remove.length > 0) {
			noChanges = false;
			callBody.remove = remove;
		}
		if (add.length > 0) {
			noChanges = false;
			callBody.add = add;
		}

		if (noChanges === true) {
			dispatch(toggleAddOrderGroupModal(null, null));
			return;
		}
	}

	// Start loader
	dispatch({
		type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
		payload: {
			loading: type === 'create' ? 'adding' : 'saving'
		}
	});

	///// USING DATA FROM getState() ONLY TO EMULATE RESPONSE UNTIL END-POINT READY /////////
	// const response = await orderGroupAddSave.json();
	// const response = getState().work.workRecord.view.BillingCenter.orderGroup
	// 	.addOrderGroupModal;

	// Endpoint mockup

	const groupId = getState().work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.groupId;

	const endpoint =
		type === 'create'
			? `${process.env.REACT_APP_API_URL}/legacy/work`
			: `${process.env.REACT_APP_API_URL}/legacy/work/${groupId}?mode=group`;
	try {
		const call = await customFetch(endpoint, {
			method: type === 'create' ? 'POST' : 'PUT',
			body: JSON.stringify(callBody)
		});

		let response = {};

		if (type === 'create') {
			response = await call.json();
		}

		if (call.status !== 200) {
			throw response;
		} else {
			if (type === 'create') {
				await dispatch(getProjectCostingSummary(workId));
			} else {
				dispatch({
					type: 'WORK_VIEW_UPDATE_ORDER_GROUP',
					payload: {
						data: {
							workOrders: workOrders,
							description: fields[0].value,
							poNumber: fields[1].value,
							quotedJobNumber: fields[2].value,
							billingContact: fields[3].options.filter((option) => option.value === fields[3].value)[0]
						},
						rowIndex: rowIndex
					}
				});
			}

			const parentId = getState().work.workRecord.view.id;
			await dispatch(getGroups(parentId));

			dispatch(toggleAddOrderGroupModal(null, null, null));
			// Hide Loader
			dispatch({
				type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
				payload: false
			});
		}
	} catch (err) {
		// Show error on loader
		console.log(err);
		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
			payload: {loading: 'error'}
		});
	}
};

// Used for submitting order groups, not removing them
export const updateOrderGroupStatus = (orderGroup, status) => async (dispatch, getState) => {
	// add loading and removing group from order group grid
	dispatch({
		type: 'WORK_VIEW_SUBMIT_ORDER_GROUP_MODAL_LOADER',
		payload: {value: true}
	});

	let callBody = {status};

	// Endpoint mockup
	const groupId = orderGroup.id;

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${groupId}?mode=group`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(callBody)
		});

		let response = {};

		if (call.status !== 200) {
			throw response;
		}

		orderGroup.members.map(async (member) => {
			// fix this so you can send an array of workIds and it will add them all at once (workOrderBillingFunctions.js, postBill)
			const memberEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${member.id}`;

			const memberCall = await customFetch(memberEndpoint, {
				method: 'PUT',
				body: JSON.stringify(callBody)
			});

			let billCallBody = {
				billName: orderGroup.groupDescription,
				purchaseOrderNumber: orderGroup.purchaseOrderNumber,
				groupNumber: orderGroup.id,
				parentId: orderGroup.parentGroup,
				status: 'Pending in OMNI',
				billingContactId: orderGroup.billingContactId
			};

			console.log(`billCallBody --- ${JSON.stringify(billCallBody)}`);

			const workBillsEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${member.id}/billing?mode=group`;

			const workBillsCall = await customFetch(workBillsEndpoint, {
				method: 'POST',
				body: JSON.stringify(billCallBody)
			});
			if (workBillsCall.status === 200) {
				const parentID = getState().work.workRecord.view.id;
				await dispatch(getGroups(parentID));
			} else {
				console.log(workBillsCall.status);
				console.log('it DID NOT work');
			}
		});

		dispatch({
			type: 'WORK_VIEW_SUBMIT_ORDER_GROUP_MODAL_LOADER',
			payload: {value: false}
		});

		dispatch({
			type: 'WORK_VIEW_SUBMIT_ORDER_GROUP_MODAL_TOGGLE',
			payload: {toggle: false}
		});
	} catch (err) {
		// Show error on loader
		console.log(err);
	}
};

export const saveOldGroup = () => (dispatch, getState) => {
	const oldGroup = getState().work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal;
	dispatch({
		type: 'WORK_VIEW_ORDER_GROUP_MODAL_SAVE_OLD_GROUP',
		payload: {oldGroup}
	});
};

export const toggleSubmitOrderGroupModal = (data, rowIndex) => (dispatch, getState) => {
	const submitOrderGroupModalToggled =
		getState().work.workRecord.view.BillingCenter.orderGroup.submitOrderGroupModal.toggle;
	dispatch({
		type: 'WORK_VIEW_SUBMIT_ORDER_GROUP_MODAL_TOGGLE',
		payload: {toggle: !submitOrderGroupModalToggled, data, rowIndex}
	});
};

export const toggleRemoveGroupConfirmationModel = (data, rowIndex) => (dispatch, getState) => {
	const removeGroupConfirmationModelToggled =
		getState().work.workRecord.view.BillingCenter.orderGroup.removeGroupConfirmationModel.toggle;
	dispatch({
		type: 'WORK_VIEW_SUBMIT_REMOVE_GROUP_MODAL_TOGGLE',
		payload: {
			toggle: !removeGroupConfirmationModelToggled,
			data,
			rowIndex
		}
	});
};

export const updateRemoveOrderGroupCommentValue = (value) => (dispatch) => {
	dispatch({
		type: 'UPDATE_WORK_VIEW_SUBMIT_REMOVE_GROUP_MODAL_COMMENT',
		payload: {
			value
		}
	});
};

/* End Order Group Logic */

export const closeDimmer = (location) => (dispatch) => {
	switch (location) {
		case 'submitModal':
			dispatch({
				type: 'WORK_VIEW_BILLING_CENTER_SUBMITBILL_LOADING',
				loading: null
			});
			break;

		case 'createModal':
			dispatch({
				type: 'WORK_VIEW_BILLING_CENTER_MODAL_LOADING',
				payload: null
			});
			break;

		case 'addOrderGroupModal':
			dispatch({
				type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
				payload: {loading: null}
			});
			break;

		case 'projectCostingSpreadsheet':
			dispatch({
				type: 'WORK_VIEW_PROJECT_SPREADSHEET_MODAL_UPDATE_LOADING',
				payload: null
			});
			break;
	}
};

export const toggleCreateTripModal = (value) => (dispatch) => {
	dispatch({
		type: 'CREATE_TRIP_MODAL_TOGGLE',
		payload: {value}
	});
};

export const createTrip =
	(workAasdiId, name, contactId, requestedDate, actualDate, sowText, specialText, aasdiId) =>
	async (dispatch, getState) => {
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?workId=${workAasdiId}&mode=aasdi-trip`;

		dispatch({
			type: 'CREATE_TRIP_MODAL_LOADING',
			payload: {
				value: true
			}
		});

		const postTrip = await customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify({
				name,
				contactId,
				requestedDate,
				actualDate,
				sowText,
				specialText,
				aasdiId
			})
		});
		const postTripJSON = await postTrip.json();

		if (postTrip.status === 200) {
			dispatch(getWorkViewTrip(postTripJSON[0].id, 'createTrip'));

			dispatch({
				type: 'ADD_TRIP_CONTACTS_OPTION',
				payload: {
					object: {
						name: postTripJSON[0].name,
						id: postTripJSON[0].id
					}
				}
			});

			let infoSection = getState().work.workRecord.view.manageAASDI.infoSection;

			infoSection.forEach((side, sideIndex) => {
				side.sections.forEach((section, sectionIndex) => {
					dispatch({
						type: 'SET_TRIP_LOADING_TOGGLE',
						payload: {
							value: true,
							index: sideIndex,
							sectionIndex
						}
					});
				});
			});

			dispatch({
				type: 'CREATE_TRIP_MODAL_LOADING',
				payload: {
					value: false
				}
			});

			dispatch({
				type: 'CREATE_TRIP_MODAL_TOGGLE',
				payload: {value: false}
			});
		}
	};

export const updateTripContact =
	(data, workAasdiTripId, index, sectionIndex, fieldIndex) => async (dispatch, getState) => {
		dispatch({
			type: 'SET_TRIP_FIELD_LOADING_TOGGLE',
			payload: {
				value: true,
				index,
				sectionIndex,
				fieldIndex
			}
		});

		let workId = getState().work.workRecord.view.id;

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/aasdi/${workAasdiTripId}?mode=trip-update`;

		const updateContacts = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify({
				contactId: data.id
			})
		});
		const updateContactsJSON = await updateContacts.json();

		let contactEndpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?id=${updateContactsJSON.contactId}`;

		const getContactInfo = await customFetch(contactEndpoint);

		const getContactInfoJSON = await getContactInfo.json();

		if (getContactInfoJSON[0]) {
			dispatch({
				type: 'SET_TRIP_CONTACT',
				payload: {json: getContactInfoJSON[0]}
			});
		}

		dispatch({
			type: 'SET_TRIP_FIELD_LOADING_TOGGLE',
			payload: {
				value: false,
				index,
				sectionIndex,
				fieldIndex
			}
		});
	};

export const updateCreateTrip = (value, index) => (dispatch) => {
	dispatch({
		type: 'UPDATE_CREATE_TRIP',
		payload: {value, index}
	});
};

export const setCreateTripSaved = (value, index) => (dispatch) => {
	dispatch({
		type: 'CREATE_TRIP_TEXTAREA_SAVE',
		payload: {value, index}
	});
};

export const getWorkViewTrip = (tripId, type) => async (dispatch, getState) => {
	// sets loading on all sections to true
	let infoSection = getState().work.workRecord.view.manageAASDI.infoSection;

	if (!tripId) {
		if (getState().work.workRecord.view.manageAASDI.tripOptions > 0) {
			tripId = getState().work.workRecord.view.manageAASDI.tripOptions[0].id;
		} else {
			dispatch({
				type: 'TOGGLE_NO_TRIP',
				payload: {
					value: true
				}
			});
			tripId = '';
		}
	}
	infoSection.forEach((side, sideIndex) => {
		side.sections.forEach((section, sectionIndex) => {
			dispatch({
				type: 'SET_TRIP_LOADING_TOGGLE',
				payload: {
					value: true,
					index: sideIndex,
					sectionIndex
				}
			});
		});
	});

	if (!tripId) {
		dispatch({
			type: 'TOGGLE_NO_TRIP',
			payload: {
				value: true
			}
		});
		return;
	}

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?workId=${tripId}&search=specific&mode=aasdi-trip`;

	const getTrip = await customFetch(endpoint);
	const getTripjson = await getTrip.json();

	if (getTrip.status !== 200) {
		return Promise.reject(getTrip.response);
	}

	if (getTripjson.length > 0) {
		dispatch({
			type: 'TOGGLE_NO_TRIP',
			payload: {
				value: false
			}
		});

		if (getTripjson[0]) {
			dispatch({
				type: 'SET_TRIP_CONTACT',
				payload: {
					json: getTripjson[0]
				}
			});

			dispatch({
				type: 'SET_WORK_VIEW_AASDI_SOW',
				payload: {
					json: getTripjson[0]
				}
			});
		}

		dispatch({
			type: 'UPDATE_WORK_VIEW_AASDI_VALUE',
			payload: {
				value: getTripjson[0].requestedDate,
				index: 0,
				sectionIndex: 0,
				fieldIndex: 2
			}
		});
		dispatch({
			type: 'UPDATE_WORK_VIEW_AASDI_VALUE',
			payload: {
				value: getTripjson[0].actualDate,
				index: 0,
				sectionIndex: 0,
				fieldIndex: 3
			}
		});
		let setSelectedId;
		if (type === 'createTrip') {
			if (getTripjson.length > 0) {
				setSelectedId = getTripjson[getTripjson.length - 1].id[0];
			} else {
				setSelectedId = getTripjson[0].id[0];
			}
		} else {
			setSelectedId = getTripjson[0].id[0];
		}
		dispatch({
			type: 'SET_SELECTED_TRIP_ID',
			payload: {
				value: setSelectedId
			}
		});

		dispatch({
			type: 'UPDATE_WORK_VIEW_AASDI_VALUE',
			payload: {
				value: getTripjson[0].name,
				index: 0,
				sectionIndex: 0,
				fieldIndex: 1
			}
		});

		// sets loading on all sections to false
		infoSection.forEach((side, sideIndex) => {
			side.sections.forEach((section, sectionIndex) => {
				dispatch({
					type: 'SET_TRIP_LOADING_TOGGLE',
					payload: {
						value: false,
						index: sideIndex,
						sectionIndex
					}
				});
			});
		});
	}
};

export const updateWorkViewTrip =
	(workAasdiId, type, value, index, sectionIndex, fieldIndex) => async (dispatch, getState) => {
		let state = getState().work.workRecord.view;
		let workId = state.id;
		let tripId = state.manageAASDI.selectedTripId;
		let status = state.record.status_Name;

		dispatch({
			type: 'SET_TRIP_LOADING_TOGGLE',
			payload: {
				value: true,
				index,
				sectionIndex
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/aasdi/${tripId}?mode=trip-update`;

		let body;
		if (type === 'name') {
			body = {
				name: value
			};
		}
		if (type === 'sow') {
			body = {
				sowText: value
			};
		}
		if (type === 'special') {
			body = {
				specialText: value
			};
		}
		if (type === 'requested') {
			body = {
				requestedDate: value
			};
		}
		if (type === 'actual') {
			body = {
				actualDate: value
			};

			if (
				status !== 'In Progress' &&
				status !== 'Request to Close' &&
				status !== 'Closed' &&
				status !== 'Canceled' &&
				status !== 'Completed By Warranty'
			) {
				const workEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}`;

				const updateWorkStatus = await customFetch(workEndpoint, {
					method: 'PUT',
					body: JSON.stringify({
						status: 'In Progress'
					})
				});

				if (updateWorkStatus.status !== 200) {
					return Promise.reject(updateWorkStatus.response);
				}

				dispatch({
					type: 'WORK_RECORD_VIEW_SET_PROGRESS',
					payload: {
						value: 'In Progress'
					}
				});
			}
		}

		const updateTrip = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(body)
		});
		const updateTripjson = await updateTrip.json();
		// why are we not doing anything with this ^^^^^
		dispatch({
			type: 'UPDATE_WORK_VIEW_AASDI_VALUE',
			payload: {
				value,
				index,
				sectionIndex,
				fieldIndex
			}
		});

		dispatch({
			type: 'SET_TRIP_LOADING_TOGGLE',
			payload: {
				value: false,
				index,
				sectionIndex
			}
		});
	};

export const setTripTextAreaSaved = (value, index, sectionIndex, fieldIndex) => (dispatch) => {
	dispatch({
		type: 'SET_MANAGE_AASDI_UNSAVED',
		payload: {value, index, sectionIndex, fieldIndex}
	});
};

export const getContactsOptions = (value, id, index, sectionIndex, fieldIndex) => async (dispatch) => {
	if (Number.isInteger(fieldIndex)) {
		dispatch({
			type: 'SET_TRIP_FIELD_LOADING_TOGGLE',
			payload: {
				value: true,
				index,
				sectionIndex,
				fieldIndex
			}
		});
	} else {
		dispatch({
			type: 'TOGGLE_CREATE_TRIP_LOADING',
			payload: {
				value: true,
				index
			}
		});
	}

	dispatch({
		type: 'UPDATE_CREATE_TRIP',
		payload: {value, index}
	});

	dispatch({
		type: 'TOGGLE_CREATE_TRIP_LOADING',
		payload: {value: true, index}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?aasdiId=${id}&name=${value}`;

	const searchContacts = await customFetch(endpoint);
	const searchContactsJSON = await searchContacts.json();

	let contactArray = [];

	searchContactsJSON.forEach((contact, index) => {
		contactArray.push({
			key: index,
			text: contact.fullName,
			value: contact
		});
	});

	if (searchContacts.status !== 200) {
		return Promise.reject(searchContacts.status);
	} else {
		dispatch({
			type: 'ADD_CREATE_TRIP_CONTACTS_OPTIONS',
			payload: {value: contactArray, index}
		});
		dispatch({
			type: 'TOGGLE_CREATE_TRIP_LOADING',
			payload: {value: false, index}
		});

		if (Number.isInteger(fieldIndex)) {
			dispatch({
				type: 'SET_TRIP_FIELD_LOADING_TOGGLE',
				payload: {
					value: false,
					index,
					sectionIndex,
					fieldIndex
				}
			});
		} else {
			dispatch({
				type: 'TOGGLE_CREATE_TRIP_LOADING',
				payload: {
					value: false,
					index
				}
			});
		}
	}
};

export const clearContactOptions = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_WORK_VIEW_CONTACT_OPTIONS'
	});
};

export const toggleNoTrips = () => (dispatch) => {
	dispatch({
		type: 'TOGGLE_NO_TRIP',
		payload: {
			value: false
		}
	});
};

export const createTripNoName = (value) => (dispatch) => {
	dispatch({
		type: 'CREATE_TRIP_NO_NAME_CHECK',
		payload: {
			value
		}
	});
};

export const markTripAsComplete = (workId, workAasdiTripId) => async (dispatch, getState) => {
	const state = getState().work.workRecord.view.manageAASDI;
	const tripOptionsIndex = state.tripOptions.findIndex((trip) => trip.id === workAasdiTripId);

	dispatch({
		type: 'WORK_VIEW_AASDI_LOADING_FIELD',
		payload: {
			loading: true
		}
	});

	try {
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/aasdi/${workAasdiTripId}?mode=trip-update`;
		await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify({
				isComplete: true
			})
		});

		dispatch({
			type: 'SET_TRIP_ISCOMPLETED',
			payload: {
				index: tripOptionsIndex,
				isCompleted: true
			}
		});

		const workEndpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}?mode=tripcomplete`;
		const updateWorkOrderStatus = await customFetch(workEndpoint, {
			method: 'PUT',
			body: JSON.stringify({
				status: 'Work Completed'
			})
		});
		const results = await updateWorkOrderStatus.json();

		if (results.statusId === 3) {
			dispatch({
				type: 'WORK_RECORD_VIEW_SET_PROGRESS',
				payload: {
					value: 'Work Completed'
				}
			});
		}
	} catch (e) {
		console.error(e);
	} finally {
		dispatch({
			type: 'WORK_VIEW_AASDI_LOADING_FIELD',
			payload: {
				loading: false
			}
		});
	}
};

export const deletedSelectedTrip = () => async (dispatch, getState) => {
	let state = getState().work.workRecord.view;
	let infoSection = state.manageAASDI.infoSection;
	let workId = state.id;
	let id = state.manageAASDI.selectedTripId;

	infoSection.forEach((side, sideIndex) => {
		side.sections.forEach((section, sectionIndex) => {
			dispatch({
				type: 'SET_TRIP_LOADING_TOGGLE',
				payload: {
					value: true,
					index: sideIndex,
					sectionIndex
				}
			});
		});
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${workId}/aasdi/${id}?mode=trip-delete`;

	const call = await customFetch(endpoint, {
		method: 'DELETE'
	});

	let oldTripsState = state.manageAASDI.tripOptions;

	if (call.status === 200) {
		const tripIndex = oldTripsState.findIndex((trip) => trip.id === id);
		const trips = oldTripsState.splice(tripIndex, 1);

		dispatch({
			type: 'REMOVE_TRIP_CONTACTS_OPTION',
			payload: {
				trips
			}
		});

		let newTripsState = state.manageAASDI.tripOptions;

		if (newTripsState.length > 0) {
			dispatch(getWorkViewTrip(newTripsState[0].id === id ? newTripsState[1].id : newTripsState[0].id));
		} else {
			dispatch({
				type: 'SET_SELECTED_TRIP_ID',
				payload: {
					value: ''
				}
			});
			dispatch({
				type: 'TOGGLE_NO_TRIP',
				payload: {
					value: true
				}
			});
			infoSection.forEach((side, sideIndex) => {
				side.sections.forEach((section, sectionIndex) => {
					dispatch({
						type: 'SET_TRIP_LOADING_TOGGLE',
						payload: {
							value: false,
							index: sideIndex,
							sectionIndex
						}
					});
				});
			});
		}
	} else {
		console.log('Failed to DELETE trip');
	}
};

export const getTripOptions = (workAasdiId, type) => async (dispatch, getState) => {
	console.log('getTripOptions()');
	let infoSection = getState().work.workRecord.view.manageAASDI.infoSection;
	infoSection.forEach((side, sideIndex) => {
		side.sections.forEach((section, sectionIndex) => {
			dispatch({
				type: 'SET_TRIP_LOADING_TOGGLE',
				payload: {
					value: true,
					index: sideIndex,
					sectionIndex
				}
			});
		});
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?workId=${workAasdiId}&search=options&mode=aasdi-trip`;
	const getTrips = await customFetch(endpoint);
	const getTripsJSON = await getTrips.json();

	if (getTrips.status !== 200) {
		return Promise.reject(getTrips.response);
	}

	if (getTripsJSON.options && getTripsJSON.options.length > 0) {
		dispatch({
			type: 'TOGGLE_NO_TRIP',
			payload: {
				value: false
			}
		});
		let tripsArray = [];
		// add isCompleted to state
		getTripsJSON.options.forEach((trip, index) => {
			console.log('trip: ', trip);
			tripsArray.push({
				key: index,
				text: `${trip.name}`,
				value: trip
			});
		});

		dispatch({
			type: 'SET_TRIP_OPTIONS',
			payload: {
				trips: getTripsJSON.options
			}
		});

		dispatch({
			type: 'SET_SELECTED_TRIP_ID',
			payload: {
				value: getTripsJSON.trip[0].id[0]
			}
		});

		dispatch({
			type: 'SET_TRIP_CONTACT',
			payload: {
				json: getTripsJSON.trip[0]
			}
		});

		dispatch({
			type: 'SET_WORK_VIEW_AASDI_SOW',
			payload: {
				json: getTripsJSON.trip[0]
			}
		});

		dispatch({
			type: 'SET_TRIPS_DROPDOWN_SELECTED',
			payload: {
				value: getTripsJSON.trip[0].name
			}
		});
	} else {
		dispatch(clearTripOptions());
		dispatch({
			type: 'SET_SELECTED_TRIP_ID',
			payload: {
				value: ''
			}
		});

		dispatch({
			type: 'TOGGLE_NO_TRIP',
			payload: {
				value: true
			}
		});
	}

	infoSection.forEach((side, sideIndex) => {
		side.sections.forEach((section, sectionIndex) => {
			dispatch({
				type: 'SET_TRIP_LOADING_TOGGLE',
				payload: {
					value: false,
					index: sideIndex,
					sectionIndex
				}
			});
		});
	});
};

export const clearTripOptions = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_TRIP_OPTIONS'
	});
};

export const setTripIdCheck = (id) => (dispatch) => {
	dispatch({
		type: 'SET_TRIP_ID_CHECK',
		payload: {
			id
		}
	});
};

export const setProgressActiveStep = (value) => (dispatch, getState) => {
	const record = getState().work.workRecord.view;
	const id = record.record.id;
	const isProject = record.record.isProject;

	if (isProject) {
		const workNumber = record.record.workNumber;
		dispatch(updateWorkField(id, 'statusName', value, workNumber));
	} else {
		dispatch(updateWorkField(id, 'statusName', value));
	}

	dispatch({
		type: 'WORK_RECORD_VIEW_SET_PROGRESS',
		payload: {
			value
		}
	});
};

export const updateProgressBar = (value) => (dispatch) => {
	dispatch({
		type: 'WORK_RECORD_VIEW_SET_PROGRESS',
		payload: {
			value
		}
	});
};

export const getAddOrderGroupWorkOrders = (quotedJobNumber) => async (dispatch) => {
	dispatch({
		type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
		payload: {
			loading: 'loading'
		}
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?checkGroup=true&quotedJobNumber=${quotedJobNumber}`;

	const getOrders = await customFetch(endpoint);
	const getOrdersjson = await getOrders.json();

	if (getOrders.status !== 200) {
		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
			payload: {loading: 'error'}
		});
		return Promise.reject(getOrders.response);
	}

	if (getOrdersjson.length > 0) {
		// dispatch({
		// 	type: 'ADD_ORDER_GROUP_MODAL_SET_WORK_ORDERS',
		// 	payload: {
		// 		json: getOrdersjson
		// 	}
		// });
		if (getOrdersjson.filter((order) => !order.isProject).length < 1) {
			dispatch({
				type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
				payload: {loading: 'no WO'}
			});
			return;
		}

		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_SELECT_WO_MODAL_DATA',
			payload: {
				json: getOrdersjson.filter((order) => !order.isProject)
			}
		});

		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
			payload: {
				loading: false
			}
		});
	} else {
		dispatch({
			type: 'WORK_VIEW_ADD_ORDER_GROUP_LOADING',
			payload: {loading: 'no WO'}
		});
	}
};

export const getGroups = (parentId) => async (dispatch) => {
	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${parentId}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		} else {
			dispatch({
				type: 'WORK_RECORD_FINANCIALS_GROUPS',
				payload: {response}
			});
		}
	} catch (err) {
		console.log(`ERROR ERROR ERROR = ${JSON.stringify(err)}`);
	}
};
