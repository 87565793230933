import React from 'react';
import {Segment, Divider} from 'semantic-ui-react';
import '../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AgGrid from '../../../../components/Global/AgGrid.js';
import AasdiActionCellRenderer from './components/AasdiActionCellRenderer.js';
import AasdiStatusCellRenderer from './components/AasdiStatusCellRenderer.js';
import {getAasdiGrid} from './services/WorkAasdiGridActions.js';
import CreateAasdiModal from './components/createAasdiModal.js';
import CertModal from './components/certSearchModal.js';
import {toggleCreateAasdiModal} from './services/WorkAasdiGridActions.js';
import AddGridItem from '../../components/AddGridItem.jsx';
import GridHeader from '../../components/GridHeader.jsx';
import ManageCerts from './components/ManageCerts.jsx';
import SearchZipCodes from './components/SearchZipCodes.jsx';
import FilterGridRow from './components/FilterGridRow.jsx';
import './AasdiGridView.css';
import {fmtMoney} from '../../../../util/formatting.js';

const cellStylePaddingTop = {paddingTop: '.4em'};

class AasdiGridView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedZip: '',
			selectedNameSearch: '',
			selectedRadius: 25,
			defaultGrid: true
		};
	}

	columnDefs = [
		{
			headerName: 'Actions',
			field: 'action',
			cellStyle: cellStylePaddingTop,
			cellRendererFramework: AasdiActionCellRenderer,
			colId: 'actionColumn',
			width: 200
		},
		{
			headerName: 'Status',
			field: 'statusId',
			cellStyle: cellStylePaddingTop,
			cellRendererFramework: AasdiStatusCellRenderer,
			valueGetter: (params) => {
				let status;
				switch (params.data.statusId) {
					case 1:
						status = 'Active';
						break;
					case 4:
						status = 'Inactive';
						break;
					case 5:
						status = 'Not Approved';
						break;
					default:
						status = 'Unknown Status';
						break;
				}
				return status;
			},
			width: 200
		},
		{
			headerName: 'Name',
			field: 'name',
			filter: 'agTextColumnFilter',
			editable: true,
			cellStyle: cellStylePaddingTop,
			width: 350,
			minWidth: 310
		},
		{
			headerName: 'Distance(Miles)',
			field: 'distance',
			cellStyle: cellStylePaddingTop,
			width: 200
		},
		{
			headerName: 'City',
			field: 'city',
			cellStyle: cellStylePaddingTop,
			width: 150
		},
		{
			headerName: 'State',
			field: 'state',
			cellStyle: cellStylePaddingTop,
			width: 100
		},
		{
			headerName: 'Website',
			field: 'website',
			// filter: 'agTextColumnFilter',
			cellStyle: cellStylePaddingTop,
			width: 310,
			minWidth: 210,
			cellRenderer: function (params) {
				let url = params.value ? params.value.trim() : '';
				if (url && !url.includes('https://') && !url.includes('http://')) {
					url = 'http://' + url;
				}
				return `<a href="${url}" target="_blank">${url}</a>`;
			}
		},
		{
			headerName: 'Jobs Completed',
			field: 'jobsCompleted',
			filter: 'agNumberColumnFilter',
			editable: false,
			cellStyle: cellStylePaddingTop,
			width: 150
		},
		{
			headerName: 'Last Two Years',
			field: 'lastTwoYears',
			filter: 'agNumberColumnFilter',
			editable: false,
			cellStyle: {paddingTop: '.4em', textAlign: 'right'},
			cellRenderer: 'currencyCellRenderer',
			valueFormatter: (data) => {
				return data.value ? fmtMoney(data.value) : fmtMoney(0);
			}
		},
		{
			headerName: 'Spectrum Code',
			field: 'spectrumCode',
			filter: 'agTextColumnFilter',
			cellEditor: 'descriptionCellRenderer',
			cellEditorParams: {
				column: 'description'
			},
			cellStyle: cellStylePaddingTop,
			editable: true,
			width: 150
		},
		{
			headerName: '',
			field: 'certList',
			hide: true
		}
	];

	componentDidMount() {
		this.props.getAasdiGrid();
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	switchGridMode() {
		const {defaultGrid} = this.state;
		this.setState({defaultGrid: !defaultGrid});
	}

	render() {
		const {selectedZip, selectedNameSearch, selectedRadius} = this.state;

		let rowData = this.props.aasdiGridData;

		if (rowData) {
			rowData = this.state.defaultGrid ? rowData.filter((row) => row.statusId === 1) : rowData;
		}

		return (
			<Segment basic style={{marginTop: '1.5rem'}}>
				<GridHeader
					message="AASDIs Grid"
					addButton={
						<AddGridItem
							onClick={() => {
								this.props.toggleCreateAasdiModal(true);
							}}
							content="Create New Aasdi"
						/>
					}
				/>

				<div className="fixGridMargin">
					<div className="aasdi-grid-filters">
						<ManageCerts gridApi={this.gridApi} />

						<Divider />

						<SearchZipCodes
							gridApi={this.gridApi}
							selectedZip={selectedZip}
							selectedNameSearch={selectedNameSearch}
							selectedRadius={selectedRadius}
							setInput={(val) =>
								this.setState({
									selectedZip: val
								})
							}
							setDropdown={(val) =>
								this.setState({
									selectedRadius: val
								})
							}
						/>

						<Divider />

						<FilterGridRow switchGridMode={() => this.switchGridMode()} gridApi={this.gridApi} />
					</div>

					<AgGrid
						rowData={rowData}
						columnDefs={this.columnDefs}
						modules={AllModules}
						pagination={true}
						onGridReady={this.onGridReady}
						rowSelection="single"
					/>
					<CreateAasdiModal stepsAasdi={this.props.stepsAasdi} />
					<CertModal gridApi={() => this.gridApi} />
				</div>
			</Segment>
		);
	}
}

AasdiGridView.propTypes = {
	aasdiGridData: PropTypes.array,
	getAasdiGrid: PropTypes.func.isRequired,
	toggleCreateAasdiModal: PropTypes.func,
	stepsAasdi: PropTypes.object
};

const mapStateToProps = (state) => ({
	aasdiGridData: state.work.aasdiGrid.rowData,
	stepsAasdi: state.work.aasdiGrid.stepsAasdi
});

const mapDispatchToProps = (dispatch) => ({
	toggleCreateAasdiModal: (status) => {
		dispatch(toggleCreateAasdiModal(status));
	},
	getAasdiGrid: () => dispatch(getAasdiGrid())
});

export default connect(mapStateToProps, mapDispatchToProps)(AasdiGridView);
