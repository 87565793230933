// TODOS
// Remove all the .then() chaining and replace it with async/await syntax
// clean up actions that need cleaning up

import customFetch from '../../../../lib/old/customFetch.js';
import {selectTemplateTasks} from '../TaskTemplates.selectors.js';
import {savingChanges, savingChangesSuccess, savingChangesFailure, hideSnackbarAfterDelay} from './snackbar.actions.js';
import * as taskTemplateService from '../TaskTemplates.service.js';

// ### GET OPERATIONS ###
export const TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS = 'TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS';
export function retrieveTemplateTasks(templateId) {
	return async (dispatch) => {
		dispatch({type: TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS});
		try {
			const request = await customFetch(
				`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${templateId}&mode=template-management`
			);
			const response = await request.json();
			dispatch(retrieveTemplateTasksSuccess(response.tasks));
			hideSnackbarAfterDelay(dispatch, 3000);
		} catch (e) {
			console.log(e);
			dispatch(retrieveTemplateTasksFailure());
		}
	};
}

export const TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_SUCCESS = 'TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_SUCCESS';
export const retrieveTemplateTasksSuccess = (tasks) => ({
	type: TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_SUCCESS,
	payload: {tasks}
});

export const TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_FAILURE = 'TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_FAILURE';
export const retrieveTemplateTasksFailure = () => ({type: TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_FAILURE});

// ### POST OPERATIONS ###
export const TASK_TEMPLATES_ADD_TASK = 'TASK_TEMPLATES_ADD_TASK';
export const addTaskToTemplate = (options) => {
	return (dispatch, getState) => {
		let taskObjectOptions = {
			...options,
			workId: getState().work.workRecord.view.id
		};

		if (options && options.sectionId) {
			taskObjectOptions.tasksLength = options.tasksLength;
			taskObjectOptions.isSectionTask = true;
			taskObjectOptions.sectionId = options.sectionId;
		}

		dispatch({
			type: TASK_TEMPLATES_ADD_TASK,
			payload: {
				sidePanelTask: taskTemplateService.createTaskObject(taskObjectOptions)
			}
		});
	};
};

export const TASK_TEMPLATE_CREATE_TASK = 'TASK_TEMPLATE_CREATE_TASK';
export let createTask = (task, templateId) => {
	return (dispatch) => {
		dispatch(savingChanges);
		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${templateId}&mode=template-management`, {
			method: 'POST',
			body: JSON.stringify(task)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				const changes = {
					pendingChanges: false
				};

				// local mutation is an acceptable sin IMO
				if (json.id) {
					changes.id = json.id;
				} else if (json.taskId) {
					changes.id = json.taskId;
				} else if (json.templateGroupingId) {
					changes.id = json.templateGroupingId;
				}

				dispatch(changeTask(changes));
				dispatch({type: TASK_TEMPLATE_CREATE_TASK, payload: {task: {...task, ...changes}}});
				dispatch(savingChangesSuccess);
				hideSnackbarAfterDelay(dispatch, 3000);
			})
			.catch((err) => dispatch(savingChangesFailure));
	};
};

// ### PUT OPERATIONS ###
export const TASK_TEMPLATES_EDIT_TASK = 'TASK_TEMPLATES_EDIT_TASK';
export const editTask = (sidePanelTask) => ({
	type: TASK_TEMPLATES_EDIT_TASK,
	payload: {
		sidePanelTask
	}
});

export const TASK_TEMPLATES_CHANGE_TASK = 'TASK_TEMPLATES_CHANGE_TASK';
export const changeTask = (updatedValues) => ({
	type: TASK_TEMPLATES_CHANGE_TASK,
	payload: {
		...updatedValues
	}
});

export const TASK_TEMPLATE_UPDATE_TASK = 'TASK_TEMPLATE_UPDATE_TASK';
export const updateTask = (task) => {
	return async (dispatch) => {
		dispatch({type: TASK_TEMPLATE_UPDATE_TASK});
		dispatch(savingChanges);

		const request = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task?mode=template-management&taskId=${task.id}`,
			{
				method: 'PUT',
				body: JSON.stringify(task)
			}
		);
		if (request.status !== 200) {
			dispatch(savingChangesFailure);
			return dispatch(updateTaskFailure());
		}
		const response = await request.json();
		dispatch(savingChangesSuccess);
		hideSnackbarAfterDelay(dispatch, 3000);
		dispatch(updateTaskSuccess(task));
	};
};

export const TASK_TEMPLATE_UPDATE_TASK_SUCCESS = 'TASK_TEMPLATE_UPDATE_TASK_SUCCESS';
export const updateTaskSuccess = (updatedTask) => ({
	type: TASK_TEMPLATE_UPDATE_TASK_SUCCESS,
	payload: {updatedTask}
});

export const TASK_TEMPLATE_UPDATE_TASK_FAILURE = 'TASK_TEMPLATE_UPDATE_TASK_FAILURE';
export const updateTaskFailure = () => ({type: TASK_TEMPLATE_UPDATE_TASK_FAILURE});

export const TASK_TEMPLATE_SET_TASK_PINNED_VALUE = 'TASK_TEMPLATE_SET_TASK_PINNED_VALUE';
export const setTaskPinnedValue = (task, pinnedValue) => {
	return async (dispatch) => {
		dispatch(savingChanges);
		const updatedTask = {
			...task,
			pinned: pinnedValue
		};
		const request = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task?taskId=${task.id}&mode=template-management`,
			{
				method: 'PUT',
				body: JSON.stringify(updatedTask)
			}
		);
		if (request.status !== 200) {
			dispatch(savingChangesFailure);
			return dispatch(pinnedValue ? pinTaskFailure() : unpinTaskFailure());
		}
		const response = await request.json();
		dispatch(savingChangesSuccess);
		hideSnackbarAfterDelay(dispatch, 3000);
		dispatch(pinnedValue ? pinTaskSuccess(updatedTask) : unpinTaskSuccess(updatedTask));
	};
};

export const TASK_TEMPLATE_PIN_TASK_SUCCESS = 'TASK_TEMPLATE_PIN_TASK_SUCCESS';
export const pinTaskSuccess = (pinnedTask) => ({
	type: TASK_TEMPLATE_PIN_TASK_SUCCESS,
	payload: {pinnedTask}
});

export const TASK_TEMPLATE_PIN_TASK_FAILURE = 'TASK_TEMPLATE_PIN_TASK_FAILURE';
export const pinTaskFailure = () => ({type: TASK_TEMPLATE_PIN_TASK_FAILURE});

export const TASK_TEMPLATE_UNPIN_TASK_SUCCESS = 'TASK_TEMPLATE_UNPIN_TASK_SUCCESS';
export const unpinTaskSuccess = (unpinnedTask) => ({
	type: TASK_TEMPLATE_UNPIN_TASK_SUCCESS,
	payload: {unpinnedTask}
});

export const TASK_TEMPLATE_UNPIN_TASK_FAILURE = 'TASK_TEMPLATE_UNPIN_TASK_FAILURE';
export const unpinTaskFailure = () => ({type: TASK_TEMPLATE_UNPIN_TASK_FAILURE});

export const TASK_TEMPLATES_UPDATE_TASK_TEMPLATE_ORDER = 'TASK_TEMPLATES_UPDATE_TASK_TEMPLATE_ORDER';
export function updateTaskTemplateOrder(reorderedTasks) {
	return async (dispatch) => {
		let updatedTaskList = reorderedTasks.map((task, order = 0) => {
			task.taskOrder = order++;
			return task;
		});
		console.log('UPDATED TASK LIST ', updatedTaskList);
		dispatch({type: TASK_TEMPLATES_UPDATE_TASK_TEMPLATE_ORDER, payload: {updatedTaskList}});
		const request = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?mode=template-task-order`, {
			method: 'PUT',
			body: JSON.stringify(updatedTaskList)
		});
	};
}

// ### DELETE OPERATIONS ###
export const TASK_TEMPLATE_DELETE_TASK = 'TASK_TEMPLATE_DELETE_TASK';
export const deleteTask = (task) => {
	return async (dispatch, getState) => {
		dispatch({type: TASK_TEMPLATE_DELETE_TASK});
		dispatch(savingChanges);

		const request = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task/${task.id}?mode=template-management&taskId=${task.id}`,
			{
				method: 'DELETE'
			}
		);

		if (request.status !== 200) {
			dispatch(savingChangesFailure);
			dispatch(deleteTaskFailure());
		}

		const response = await request.json();

		const tasks = selectTemplateTasks(getState());

		dispatch(deleteTaskSuccess(task));
		dispatch(savingChangesSuccess);
		hideSnackbarAfterDelay(dispatch, 3000);

		// if the task was deleted from a section and isnt pinned, reorder that sections tasks
		if (task.sectionId && !task.pinned) {
			// what i would give for a pipe() function
			dispatch(
				updateTaskTemplateOrder(
					selectTemplateTasks(getState()).general.find((generalTask) => generalTask.id === task.sectionId)
						.tasks.general
				)
			);
		} else if (!task.pinned) {
			// what i would give for a pipe() function
			dispatch(updateTaskTemplateOrder(selectTemplateTasks(getState()).general));
		}
	};
};

export const TASK_TEMPLATE_DELETE_TASK_SUCCESS = 'TASK_TEMPLATE_DELETE_TASK_SUCCESS';
export const deleteTaskSuccess = (task) => ({type: TASK_TEMPLATE_DELETE_TASK_SUCCESS, payload: {task}});

export const TASK_TEMPLATE_DELETE_TASK_FAILURE = 'TASK_TEMPLATE_DELETE_TASK_FAILURE';
export const deleteTaskFailure = () => ({type: TASK_TEMPLATE_DELETE_TASK_FAILURE});
