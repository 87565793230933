import React from 'react';
import {connect} from 'react-redux';
import {Segment, Header, Grid, Dropdown, Label, Input} from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import {debounce, debounceEventHandler} from '../../../../../../lib/old/debounce.js';
import {
	getChartsData,
	getPipelineAnalytics,
	getPipelineOpportunities,
	getStageAnalytics
} from '../../scenes/pipeline/services/pipelineActions.js';
import {
	updateStartDate,
	updateEndDate,
	updateSelectedBranch,
	updateSelectedAccountExecutive,
	updateSelectedServiceType,
	updateSelectedTechnology,
	getEmployees
} from './services/filterActions.js';
import {MomentDatePicker} from '../../../../../../components/inputs/DatePicker.jsx';

class Filters extends React.Component {
	render() {
		return (
			<Segment>
				<Label attached="top" color="blue">
					Filters
				</Label>
				<Grid columns={5} padded>
					<Grid.Column>
						<Header size="tiny">Date Range</Header>
						<Input className="hiddenInput">
							<MomentDatePicker
								selected={moment(this.props.startDate)}
								selectsStart
								startDate={moment(this.props.startDate)}
								endDate={moment(this.props.endDate)}
								onSelect={(value) => {
									this.props.updateStartDate(value);
									this.props.getPipelineOpportunities();
									this.props.getStageAnalytics();
									this.props.getChartsData();
									this.props.getPipelineAnalytics();
								}}
							/>
						</Input>
						<Input className="hiddenInput">
							<MomentDatePicker
								selected={moment(this.props.endDate)}
								selectsEnd
								startDate={moment(this.props.startDate)}
								endDate={moment(this.props.endDate)}
								onSelect={(value) => {
									this.props.updateEndDate(value);
									this.props.getPipelineOpportunities();
									this.props.getStageAnalytics();
									this.props.getChartsData();
									this.props.getPipelineAnalytics();
								}}
							/>
						</Input>
					</Grid.Column>
					<Grid.Column>
						<Header size="tiny">Branch</Header>
						<Dropdown
							className="hiddenInput"
							placeholder="Select Branch"
							search
							options={[
								{
									key: 0,
									text: 'All Branches',
									value: null
								},
								{
									key: 1,
									text: 'Georgia',
									value: 1
								},
								{
									key: 2,
									text: 'North Carolina',
									value: 2
								},
								{
									key: 3,
									text: 'NTI',
									value: 3
								},
								{
									key: 6,
									text: 'Huntsville',
									value: 6
								}
							]}
							onChange={(e, {value}) => {
								this.props.updateSelectedBranch(value);
								this.props.getPipelineOpportunities();
								this.props.getStageAnalytics();
								this.props.getChartsData();
								this.props.getPipelineAnalytics();
							}}
						/>
					</Grid.Column>
					<Grid.Column>
						<Header size="tiny">Account Executive</Header>
						<Dropdown
							className="hiddenInput"
							placeholder={
								this.props.roleType === 'Standard'
									? `${this.props.firstName} ${this.props.lastName}`
									: 'Select Account Executive'
							}
							noResultsMessage={'No results found.'}
							search
							fluid
							loading={this.props.getAccountExecLoading}
							options={this.props.employees}
							onChange={(e, {value}) => {
								this.props.updateSelectedAccountExecutive(value);
								this.props.getPipelineOpportunities();
								this.props.getStageAnalytics();
								this.props.getChartsData();
								this.props.getPipelineAnalytics();
							}}
							onSearchChange={(event) =>
								debounceEventHandler(debounce(this.props.getEmployees(event.target.value), 600))
							}
						/>
					</Grid.Column>
					<Grid.Column>
						<Header size="tiny">Service Type</Header>
						<Dropdown
							className="hiddenInput"
							placeholder="Select Service Type"
							search
							options={[
								{
									key: 0,
									text: 'All Service Types',
									value: null
								},
								{
									key: 1,
									text: 'Deploy',
									value: 'Deploy'
								},
								{
									key: 2,
									text: 'Design',
									value: 'Design'
								},
								{
									key: 3,
									text: 'Build',
									value: 'Build'
								},
								{
									key: 4,
									text: 'Support',
									value: 'Support'
								}
							]}
							onChange={(e, {value}) => {
								this.props.updateSelectedServiceType(value);
								this.props.getPipelineOpportunities();
								this.props.getStageAnalytics();
								this.props.getChartsData();
								this.props.getPipelineAnalytics();
							}}
						/>
					</Grid.Column>
					<Grid.Column>
						<Header size="tiny">Technology</Header>
						<Dropdown
							className="hiddenInput"
							placeholder="Select Technology"
							search
							options={[
								{
									key: 0,
									text: 'All Technologies',
									value: null
								},
								{
									key: 1,
									text: 'ACC-SUR',
									value: 'ACC-SUR'
								},
								{
									key: 2,
									text: 'AUD-VIS',
									value: 'AUD-VIS'
								},
								{
									key: 3,
									text: 'NET-TEL',
									value: 'NET-TEL'
								},
								{
									key: 4,
									text: 'POS-SRV',
									value: 'POS-SRV'
								},
								{
									key: 5,
									text: 'POW-ELE',
									value: 'POW-ELE'
								},
								{
									key: 6,
									text: 'SOU-PAG',
									value: 'SOU-PAG'
								},
								{
									key: 7,
									text: 'STR-CAB',
									value: 'STR-CAB'
								},
								// {
								// 	key: 8,
								// 	text: 'LED-LIG',
								// 	value: 'LED-LIG'
								// },
								{
									key: 9,
									text: 'FIR-ALM',
									value: 'FIR-ALM'
								}
							]}
							onChange={(e, {value}) => {
								this.props.updateSelectedTechnology(value);
								this.props.getPipelineOpportunities();
								this.props.getStageAnalytics();
								this.props.getChartsData();
								this.props.getPipelineAnalytics();
							}}
						/>
					</Grid.Column>
				</Grid>
			</Segment>
		);
	}
}

Filters.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	employees: PropTypes.array,
	roleType: PropTypes.string,
	branchName: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	updateStartDate: PropTypes.func,
	updateEndDate: PropTypes.func,
	updateSelectedBranch: PropTypes.func,
	getEmployees: PropTypes.func,
	updateSelectedAccountExecutive: PropTypes.func,
	updateSelectedServiceType: PropTypes.func,
	updateSelectedTechnology: PropTypes.func,
	getChartsData: PropTypes.func,
	getPipelineAnalytics: PropTypes.func,
	getPipelineOpportunities: PropTypes.func,
	getStageAnalytics: PropTypes.func,
	getAccountExecLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		roleType: state.auth.account.roleTypeName,
		firstName: state.auth.account.firstName,
		lastName: state.auth.account.lastName,
		employees: state.crm.pipeline.selectedFilters.employees,
		branchName: state.auth.account.branch,
		startDate: state.crm.pipeline.selectedFilters.startDate,
		endDate: state.crm.pipeline.selectedFilters.endDate,
		getAccountExecLoading: state.crm.pipeline.selectedFilters.getAccountExecLoading
	};
};

const mapDispatchToProps = {
	getChartsData,
	getPipelineAnalytics,
	getPipelineOpportunities,
	getStageAnalytics,
	updateStartDate,
	updateEndDate,
	updateSelectedBranch,
	updateSelectedAccountExecutive,
	updateSelectedServiceType,
	updateSelectedTechnology,
	getEmployees
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
