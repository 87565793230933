import React, {Component} from 'react';
import {Label} from 'semantic-ui-react';
export default class ClientCellRenderer extends Component {
	render() {
		return this.props.value ? (
			<Label basic style={{width: '100%', border: 'none', fontWeight: 'normal'}}>
				{this.props.value}
			</Label>
		) : (
			<p>{'No Client Selected'}</p>
		);
	}
}
