import { Label, Icon, Segment, Dropdown, Popup, Grid, Loader, Button } from 'semantic-ui-react';
import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { api } from '~/lib/api';


type CompProps = {
    tags?: Array<{ id: number, name: string }> | undefined,
    parentUpdateTags?: (type: string, tag: {id: number, name: string}) => void,
    workId?: number | undefined
    opportunityId?: number | undefined
}

type Tag = {
    id: number, name: string
}

type Message = {
    id: number, message: string
}

type Option = {
    key: number, value: number, text: string
}


export default function Tags(props: CompProps) {
    // either "work" or "opportunity"
    const [type, setType] = useState<string>('');
    // either workId or opportunityId
    const [typeId, setTypeId] = useState<number>(0);
    // used to determine if component is used in existing work/opp record or if it's used in the work/opp create section
    // true if component is in the work/opp creation modal, false if in profile
    const [creatingRecord, setCreatingRecord] = useState<boolean>(false);
    //appear above dropdown in red
    const [errorMessages, setErrorMessages] = useState<Message[]>([]);
    const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);
    const [tagList, setTagList] = useState<Tag[]>([]);
    const [searchValue, setSearchValue] = useState<string>('')
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [tagLoading, setTagLoading] = useState<boolean>(false);

    // updates local tags when parent tags are updated
    useEffect(() => {
        // only gets used in work/opp creation
        if (props.tags) {
            setTagList(props.tags)
        }
      }, [props.tags]);

    // gets tags for opp/work on initial load
    useEffect(() => {
        if (typeof props.parentUpdateTags === 'function') {
            setCreatingRecord(true)
        } else {
            setType( props.workId ? 'work' : 'opportunity')
            // @ts-ignore if no updateTags function is supplied either workId or opportunityId will be supplied
            setTypeId( props.workId ? props.workId : props.opportunityId)

            // not sure why they want to use async like this in useEffects, but that's what I found online
            try {
                const getTags = async () => {
                    setTagLoading(true);
                    // wanted to use "type" and "typeId" but they aren't always set by the time this function fires
                    const endpoint = `legacy/tags?${props.workId ? 'work' : 'opportunity'}Id=${props.workId ? props.workId : props.opportunityId}`;
                    const response = await api.get(endpoint);
                    setTagList(response)
                    setTagLoading(false);
                }
                getTags()
            } catch (e) {
                console.log('error = ', e)
                triggerError("Something went wrong and we couldn't get attached tags");
            }
        }
    }, []);

    const removeError = (id: number) => {
        let newMessages = errorMessages.filter((e: {id: number, message: string}) => e.id !== id)
        setErrorMessages(newMessages);
    }

    const triggerError = async (message: string) => {
        let id = errorMessages.length ? errorMessages[errorMessages.length - 1].id + 1 : 1;
        setErrorMessages([...errorMessages, {id, message}]);
    }

    // checks if the tag has already tag is one of the dropdown options
    // or if the tag is already associated to the record before allowing users to create it
    const checkIfExists = (tag: string, options: Option[] | null, tags: Tag[]) => {
        let optionIndex = -1
        if (options !== null) {
            optionIndex = options.findIndex((o: Option)=> o.text === tag) // what? name should be text?
        }
        if (optionIndex !== -1) triggerError(`The tag "${tag}" already exists and is in the dropdown`);

        let tagIndex = tags.findIndex((t: Tag)=> t.name === tag)
        if (tagIndex !== -1) triggerError(`The tag "${tag}" is already added to this record`);

        return optionIndex !== -1 || tagIndex !== -1
    }

    // adds to removes tags
    const updateTags = (type: string, tag: Tag) => {
        if (creatingRecord) {
            // updates parent tags for opp/work creation
            // @ts-ignore creatingRecord will only be true if parentUpdateTags is a function
            props.parentUpdateTags(type, tag)
        } else {
            // updates local tags for opp/work record view
            let tags = tagList
            if (type === 'add') {
                tags.push(tag);
            } else {
                tags = tags.filter((t: Tag) => {return t.id !== tag.id})
            }
            setTagList(tags);
        }
    }

    const dropdownSearch = async (searchValue: string) => {
        setSearchLoading(true);
        let endpoint = `legacy/tags?search=${searchValue.trim()}`;
        if (!creatingRecord) endpoint += `&${type}Id=${typeId}`
        if (creatingRecord && tagList.length > 0) {
            const existingIds = tagList.map((t:Tag) => {return t.id})
            endpoint += `&excludeIds=${JSON.stringify(existingIds)}`
        }
        const response = await api.get(endpoint);
        if (response.error) {
                console.log('error = ', response.error);
                triggerError('Something went wrong with your search');
        } else {
            let options = response.map((tag: Tag) => {
                return {key: tag.id, value: tag.id, text: tag.name}
            })
            setDropdownOptions(options)
        }
        setSearchLoading(false);
    }

    // adds tag to record when clicked in dropdown options
    const dropdownSelect = async (eventType: string, data: any) => {
        if (eventType === 'click' && data.options) {
            let tag = data.options[data.options?.findIndex((o: Option) => o.value === data.value)]
            let checkPassed:boolean = true;
            setTagLoading(true);
            let exists = checkIfExists(tag.text, null, tagList)
            if (!creatingRecord && !exists) {
                const endpoint = `legacy/tags`;
                const body: {workId: number | null, opportunityId: number | null, existingTags: {id: number}[] } = {
                    workId: type === 'work' ? typeId : null,
                    opportunityId: type === 'opportunity' ? typeId : null,
                    existingTags: [{id: data.value}]
                }
                const response = await api.post(endpoint, body)
                if (response.error) {
                    checkPassed = false
                    if (response.error.includes('Unique_combo_tag')) {
                        triggerError(`The tag "${tag.text}" has already been added to this record`);
                    } else {
                        triggerError('Something went wrong while trying to attach the tag to this record');
                    }
                }
            }

            if (checkPassed && tag.value && !exists) {
                updateTags('add', {id: tag.value, name: tag.text});
                let options = dropdownOptions.filter((o: Option) => {return o.value !== tag.value})
                setDropdownOptions(options);
            }
            setTagLoading(false);
        }
    }

    const createNewTag = async () => {
        setTagLoading(true);
        let exists = checkIfExists(searchValue, dropdownOptions, tagList)
        if (!exists) {
            const endpoint = `legacy/tags`;
            const response = await api.post(endpoint, {
                workId: type === 'work' ? typeId : null,
                opportunityId: type === 'opportunity' ? typeId : null,
                newTags: [{name: searchValue.trim()}]
            })
            if (response.error) {
                console.log('hit inside error');
                if (response.error.includes('Unique_name')) {
                    console.log('hit inside name error');
                    triggerError(`The tag "${searchValue}" already exists in the database`);
                } else {
                    triggerError('Something went wrong while trying to create this tag');
                }
            } else {
                updateTags('add', response[0]);
            }
            setSearchValue('');
            setDropdownOptions([]);
        }
        setTagLoading(false);
    }

    const removeTag = async(tag: Tag) => {
        let checkPassed = true;
        setTagLoading(true);
        if (!creatingRecord) {
            const endpoint = `legacy/tags?${type}Id=${typeId}&tagId=${tag.id}`;
            const response = await api.delete(endpoint)
            console.log('response = ', response);
            if (response.rowsAffected.length < 1) {
                checkPassed = false
                triggerError(`The "${tag.name}" tag could not be removed at this time`)
            }
            checkPassed && updateTags('remove', tag);
        } else {
            updateTags('remove', tag);
        }
        setTagLoading(false);
    }

    const debounceDropdownSearch = useCallback( _.debounce(async (e, data) => {
        const search = data.searchQuery.trim();
        if (!searchLoading && search.length) {
            if (search.length > 25) {
                triggerError('The search value too long. There is a 25 character limit on tags');
                const cutSearch = search.slice(0, 25 - data.searchQuery.length)
                setSearchValue(cutSearch);
                dropdownSearch(cutSearch);
            } else if (!search.length) {
                setDropdownOptions([]);
            } else {
                dropdownSearch(search);
            }
        }
    }, 750) , [])

    return (
        <>
            <Segment>
                <div>
                    <Label color={'blue'} ribbon style={{marginBottom: '2vh'}}>
                        Tags
                    </Label>
                    <Popup
                        trigger={
                            <Icon
                                disabled
                                name="info circle"
                            />
                        }
                        header="Add a Tag"
                        content="Search for tags and click them in the dropdown to add them to the record. If the tag you are looking for isn't in the dropdown then clicking the green button will create a tag for what you searched for."
                        wide
                        hideOnScroll
                    />
                </div>
                <Grid relaxed='very'>
                    <Grid.Column style={{width: "100%", minWidth: '255px', maxWidth:'625px'}} >
                        <div>
                            <Button as='div' labelPosition='left' style={{width: '100%'}}>
                                <Dropdown
                                    fluid
                                    selection
                                    search
                                    upward
                                    loading={searchLoading}
                                    text={searchValue}
                                    searchQuery={searchValue}
                                    // set to -1 so none of the options are bold
                                    value={-1}
                                    onSearchChange={
                                            async (e, data) => {
                                                setSearchValue(data.searchQuery);
                                                await debounceDropdownSearch(e, data);
                                            }
                                        }
                                    onChange={
                                        async (e, data) => {
                                            if (!searchLoading) {
                                                dropdownSelect(e.nativeEvent.type, data)
                                            }
                                        }
                                    }
                                    placeholder={'Search for tags...'}
                                    noResultsMessage={'No tags were found'}
                                    options={!searchLoading ? dropdownOptions : []}
                                />
                                <Button
                                    color="green"
                                    icon="plus"
                                    size='tiny'
                                    disabled={searchValue.trim().length < 1 || searchLoading || tagLoading}
                                    onClick={async () => {createNewTag()}}
                                />
                            </ Button>
                        </div>
                    </Grid.Column>
                    <Grid.Column style={{width: "100%", minWidth: '255px', maxWidth:'800px'}} >
                        <div style={{marginBottom: '5px'}}>
                            {errorMessages?.length ? errorMessages.map((message: Message) => {
                                return (
                                    <Label key={message.id} color="red" style={{ padding: '2px 0 2px 6px', marginBottom: '3px'}} size='medium'>
                                            {message.message}
                                            <Icon name="x" size="small" inverted style={{marginLeft: "10px", float:"right"}} onClick={()=> {removeError(message.id)}} />
                                    </Label>
                                )
                            }) : <></>}
                        </div>
                        <div>
                            {tagList?.length ? tagList.map((tag: Tag, index: number) => {
                                return (
                                    <Label key={index} style={{marginTop: '5px'}}>
                                        {tag?.name ? tag.name : ''}
                                        <Icon disabled={tagLoading} onClick={async () => { removeTag(tag); }} name='delete' color={tagLoading ? "grey" : "red"} />
                                    </Label>
                                )}
                            ) : <></>}
                            {tagLoading && <Loader className='workaround' size='small' active={true} inline/>}
                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>
        </>
        )
}