import React from 'react';
import {Segment} from 'semantic-ui-react';
import {Route} from 'react-router-dom';

import WorkNav from './components/workNav.js';
import WorkGridView from './scenes/WorkOrder-Project/workGridView.js';
import AasdiGridView from './scenes/aasdi/aasdiGridView.js';
import ClientGridView from './scenes/client/clientGridView.js';
import WorkRecordView from './scenes/WorkOrder-Project/scenes/workRecordView/workRecordView.js';
import ProfileGridView from './scenes/client/scenes/profile/profileGridView.js';
import ProfileAasdiGridView from './scenes/aasdi/profile/profileAasdiGridView.js';
import MonthlyCommits from '../../components/monthlyCommits/MonthlyCommits.js';

export default () => [
	<WorkNav key={'work-nav'} />,
	<Segment
		key={'work-dashboard'}
		id="work-dashboard"
		style={{
			left: '15rem',
			right: '0',
			top: '2rem',
			overflowY: 'auto',
			height: '94.5vh',
			position: 'fixed'
		}}
		basic
	>
		<Route path={`/work/dashboard/work`} component={WorkGridView} />
		<Route path={`/work/view`} component={WorkRecordView} />
		<Route path={`/work/dashboard/client/grid`} exact component={ClientGridView} />
		<Route path={`/work/dashboard/client/profile`} component={ProfileGridView} />
		<Route path={`/work/dashboard/aasdi`} exact component={AasdiGridView} />
		<Route path={`/work/dashboard/aasdi/profile`} component={ProfileAasdiGridView} />
		<Route path={`/work/dashboard/commits`} component={MonthlyCommits} />
	</Segment>
];
