import React from 'react';
import {Icon, Feed, Popup} from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Service
import {deleteActivityStreamEvent} from '../services/activityStreamActions.js';

let ActivityStreamEvent = ({
	id,
	oppId,
	img,
	fullName,
	createdOn,
	logType,
	comment,
	deleteActivityStreamEvent,
	type,
	header
}) => (
	<Feed.Event>
		<Feed.Label>{img ? <img src="http://i.pravatar.cc/35?img=53" /> : <Icon name="user circle" />}</Feed.Label>
		<Feed.Content>
			<Feed.Summary>
				<Feed.User>{fullName}</Feed.User>
				{header}.<Feed.Date>{moment(createdOn).format('MM/DD/YY, h:mm a')}</Feed.Date>
			</Feed.Summary>
			<Feed.Extra text>{comment}</Feed.Extra>
			<Feed.Meta>
				<Popup
					trigger={
						<Feed.Like
							onClick={() => {
								deleteActivityStreamEvent(oppId, id, type);
							}}
						>
							<Icon name="trash" />
							Delete
						</Feed.Like>
					}
					header="Delete Event"
					content={`Click here to delete this event. WARNING: This is a permanent action`}
				/>
			</Feed.Meta>
		</Feed.Content>
	</Feed.Event>
);

ActivityStreamEvent.propTypes = {
	img: PropTypes.string,
	fullName: PropTypes.string,
	createdOn: PropTypes.string,
	logType: PropTypes.string,
	comment: PropTypes.string,
	id: PropTypes.string,
	oppId: PropTypes.string,
	deleteActivityStreamEvent: PropTypes.string,
	type: PropTypes.string
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteActivityStreamEvent: (oppid, id, type) => {
			dispatch(deleteActivityStreamEvent(oppid, id, type));
		}
	};
};

export default connect(null, mapDispatchToProps)(ActivityStreamEvent);
