import React, {Fragment, Component} from 'react';
import {Button, Icon, Modal, Input} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {KEY_RETURN} from 'keycode-js';

import '../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import * as actions from '../../../services/createWorkOrderActions.js';

import { roundFloat } from '~/util/numbers';
import {SKUGrid} from '../../../../../../../components/sku/SKUGrid.jsx';
import {SKUTotals} from '../../../../../../../components/sku/SKUTotals.jsx';
import {SelectSKUsModal} from '../../../../../../../components/modals/SelectSKUsModal.jsx';

/*TODO: CreateBillProjectCostingGrid needs to support decimal places for markup inputs
this is due to us storing the input value as a number, so when we parse "n." it removes the decimal when we parse
*/
//TODO: Merge create work and create bill project costing grids and reuse reducer/action logic.
class CreateBillProjectCostingGrid extends Component {
	render() {
		return (
			<Fragment>
				<Modal
					open={this.props.projectCostingSpreadSheetModalToggle}
					size="fullscreen"
					//Don't know why I need this.. seems like a semantic-ui-react bug causing it to be a really weird number
					style={{marginTop: 0}}
				>
					<Modal.Header>
						<div style={{display: 'flex', flexFlow: 'row nowrap'}}>
							<Icon name="plus" color="green" />
							<div className="content">Work Order Costing</div>
						</div>
					</Modal.Header>
					<Modal.Content>
						<div
							className="ag-theme-balham"
							style={{
								height: '25vh',
								width: '100%',
								textAlign: 'left',
								boxSizing: 'border-box'
							}}
						>
							<SKUGrid
								data={this.props.spreadSheetData}
								onRowRemove={this.props.removeRowSpreadsheet}
								canRemove
								onDataChange={this.props.updateProjectCostingModalGrid}
							/>
						</div>
						<div
							style={{
								marginTop: 15,
								display: 'flex',
								flexFlow: 'row nowrap',
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%'
							}}
						>
							<Button.Group>
								<Button
									color="green"
									onClick={() => this.props.addRowSpreadsheet(this.props.sectionIndex)}
								>
									<Icon name="plus" /> Add Row
								</Button>
								<Button
									color="grey"
									onClick={() => {
										this.props.getSKUs(this.props.clientId, this.props.sectionIndex);
										this.props.selectSKUModalToggle(this.props.sectionIndex);
									}}
								>
									<Icon name="edit" /> Select SKUs
								</Button>
							</Button.Group>
							<div
								style={{
									display: 'flex',
									flexFlow: 'row nowrap',
									alignItems: 'center'
								}}
							>
								<div style={{margin: '1em'}}>
									<label>Material Margin</label>
								</div>
								<div style={{width: 200}}>
									<Input
										fluid
										icon={{
											name: 'angle double right',
											circular: true,
											color: 'blue',
											link: true,
											onClick: () => this.props.applyMaterialMargin()
										}}
										value={
											this.props.marginAllMaterial !== null //prettier 😠
												? this.props.marginAllMaterial * 100
												: ''
										}
										onKeyDown={(ev) => {
											if (ev.keyCode === KEY_RETURN) {
												this.props.applyMaterialMargin();
											}
										}}
										onChange={(e) => {
											const valStr = e.target.value.trim();
											console.log('value string:' + valStr);
											if (valStr === '') {
												this.props.setMaterialMargin(null);
												return;
											}
											const val = Number.parseFloat(valStr);
											if (Number.isNaN(val) || val >= 100) {
												return;
											}
											this.props.setMaterialMargin(roundFloat(val / 100, 4));
										}}
									/>
								</div>
								<div style={{margin: '1em'}}>
									<label>Labor Margin</label>
								</div>
								<div style={{width: 200}}>
									<Input
										fluid
										icon={{
											name: 'angle double right',
											circular: true,
											color: 'blue',
											link: true,
											onClick: () => this.props.applyLaborMargin()
										}}
										onKeyDown={(ev) => {
											if (ev.keyCode === KEY_RETURN) {
												this.props.applyLaborMargin();
											}
										}}
										value={
											this.props.marginAllLabor !== null ? this.props.marginAllLabor * 100 : ''
										}
										onChange={(e) => {
											const valStr = e.target.value.trim();
											if (valStr === '') {
												this.props.setLaborMargin(null);
												return;
											}
											const val = Number.parseFloat(valStr);
											if (Number.isNaN(val) || val >= 100) {
												return;
											}
											this.props.setLaborMargin(roundFloat(val / 100, 4));
										}}
									/>
								</div>
							</div>
						</div>
						<div style={{marginTop: 15}}>
							<SKUTotals data={this.props.spreadSheetData} />
						</div>
					</Modal.Content>
					<Modal.Actions>
						<div
							style={{
								flex: '1 0 auto',
								display: 'flex',
								flexFlow: 'row-reverse nowrap'
							}}
						>
							<Button.Group>
								<Button
									color="red"
									onClick={() =>
										this.props.toggleProjectCostingSpreadsheetModal(this.props.sectionIndex, [])
									}
								>
									Cancel
								</Button>
								<Button
									color="blue"
									onClick={() => {
										this.props.completeProjectCostingSpreadsheet(
											this.props.sectionIndex,
											this.props.spreadSheetData
										);
									}}
								>
									Save &amp; Close
								</Button>
							</Button.Group>
						</div>
					</Modal.Actions>
				</Modal>
				<SelectSKUsModal
					open={this.props.selectSKUsOpen}
					skus={this.props.SKUList.filter((sku) => sku.isActive)}
					loading={!this.props.SKULoaded}
					onClose={(skus) => {
						if (skus) {
							this.props.selectSKUModalAddItems(skus);
						}
						this.props.selectSKUModalToggle(this.props.sectionIndex);
					}}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const modalData = state.work.createWorkOrder.createWorkOrderModal.sections.find(
		(section) => section.name === 'workOrderProjectCostingSection'
	).projectCostingSpreadsheetModal;
	const clientId = state.work.createWorkOrder.createWorkOrderModal.sections
		.find((section) => section.name === 'workOrderOverviewSection')
		.fields.find((section) => section.name === 'Client').value;
	return {
		projectCostingSpreadSheetModalToggle: modalData.toggle,
		spreadSheetData: modalData.spreadSheetData,
		marginAllLabor: modalData.applyLM,
		marginAllMaterial: modalData.applyMM,
		SKUList: modalData.SKUs,
		SKULoaded: modalData.skuLoaded,
		selectSKUsOpen: modalData.selectSKUModal.toggle,
		clientId,
		//its f$@#ing insane that i need this here...
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'workOrderProjectCostingSection'
		)
	};
};

export default connect(mapStateToProps, actions)(CreateBillProjectCostingGrid);
