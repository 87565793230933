import unified from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';
import createPDFElement from './createPDFElement.js';

export const generatePDFFromHTML = (content) =>
	unified()
		.use(rehypeParse, {fragment: true, emitParseErrors: true})
		.use(rehypeReact, {createElement: createPDFElement})
		.processSync(content).result;
