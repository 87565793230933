import {Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import CompleteButton from './Button_CompleteTask.js';
import DeleteButton from './Button_DeleteTask.js';
import SaveButton from './Button_Save.js';

const PanelButtonGroup = (props) => (
	<Button.Group floated="right">
		{(() => {
			let {aasdiIndex, groupIndex, index, sectionIndex} = props.tasks.ui.panel;

			if (props.task !== undefined) {
				if (
					//never show completed for these cases
					!props.task.id ||
					props.task.completed ||
					props.mode === 'templateManager' ||
					props.task.isSection
				) {
					return null;
				} else if (props.task.sectionId) {
					//handling sequential for tasks that are within a section
					if (
						//if it is a section and either pinned or not sequential
						props.task.pinned ||
						!props.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general[sectionIndex].isSequential
					) {
						return <CompleteButton {...props} />;
					} else {
						//if it is a section and it is sequential/not pinned
						let previousTaskCompleted = props.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general[
							sectionIndex
						].tasks.general[index - 1]
							? props.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general[sectionIndex].tasks
									.general[index - 1].completed
							: true;
						if (
							//only show complete button if the one before it is complete or it
							//is the first task in the general list
							previousTaskCompleted === null ||
							previousTaskCompleted === undefined
						) {
							return null;
						} else {
							return <CompleteButton {...props} />;
						}
					}
				} else {
					//not part of a section
					if (
						props.task.pinned || props.groups[groupIndex] !== undefined
							? !props.groups[groupIndex].sequential
							: false
					) {
						//alwasy show if it is pinned or not sequential
						return <CompleteButton {...props} />;
					} else {
						//if sequential list, check previous task before allowing complete
						let previousTaskCompleted = props.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general[
							index - 1
						]
							? props.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general[index - 1].completed
							: true;
						if (
							//only show complete button if the one before it is complete or it
							//is the first task in the general list
							previousTaskCompleted === null ||
							previousTaskCompleted === undefined
						) {
							return null;
						} else {
							return <CompleteButton {...props} />;
						}
					}
				}
			}
		})()}
		{props.task !== undefined ? props.task.completed ? null : <DeleteButton {...props} /> : null}

		{props.task !== undefined ? props.task.completed ? null : <SaveButton {...props} /> : null}
	</Button.Group>
);

export default PanelButtonGroup;

PanelButtonGroup.propTypes = {
	task: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired
};
