// TODO
// Investigate creating some sort of route guard mechanism that way retrieveTemplatesIfNeeded() doesn't need to be repeated across components
// Add unique keys to all components that need them
// break the components folder down into more organized subdirectories

import React from 'react';
import Templates from './Templates.jsx';
import Manage from './Manage.jsx';
import {Route} from 'react-router-dom';
import Snackbar from './components/Snackbar.jsx';

import './TaskTemplates.css';

function TaskTemplates(props) {
	return (
		<div>
			<Route path="/task-templates/list" component={Templates} />
			<Route path="/task-templates/edit/:id" component={Manage} />
			<Snackbar />
		</div>
	);
}

export default TaskTemplates;
