import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'semantic-ui-react';

export const Switch = (props) => (
	<Fragment>
		<Icon
			onClick={props.onClick}
			name={'toggle on'}
			style={{cursor: 'pointer'}}
			flipped={props.toggled ? undefined : 'horizontally'} //use flip instead of icon='toggle off' because it inverts the colors in a way that makes it look like its 'off'
			size="large"
			color="blue"
		/>
		{props.toggled ? props.onText : props.offText}
	</Fragment>
);

Switch.propTypes = {
	toggled: PropTypes.bool.isRequired, //(rowIndex: number): boolean
	onText: PropTypes.string,
	offText: PropTypes.string,
	onClick: PropTypes.func.isRequired
};

export default Switch;
