import {Label, Form, Header, Input} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import optionFactory from './optionFactory.js';
import ErrorLabel from './Label_Error.js';

import DebounceInput from 'react-debounce-input';

const DefaultTaskType = (props) => (
	<Form.Field key={`${props.column}-formField`}>
		<label>
			{props.column.charAt(0).toUpperCase() + props.column.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')}
		</label>
		{props.task.completed ? (
			<Header.Content>{props.task[props.column]}</Header.Content>
		) : (
			<DebounceInput
				element={Input}
				debounceTimeout={600}
				className="editFocus"
				disabled={props.task.completed}
				key={`${props.column}-input`}
				error={props.tasks.ui.errors[props.column] ? props.tasks.ui.errors[props.column] : false}
				label={
					props.column.charAt(0).toUpperCase() + props.column.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2') ===
					'Url'
						? 'http://'
						: null
				}
				placeholder={
					props.column.charAt(0).toUpperCase() + props.column.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2') ===
					'Url'
						? 'www.address.com'
						: null
				}
				onChange={(e) => {
					props.updateTask(props.mode, props.column, e.target.value, optionFactory(props), true);
				}}
				value={props.task[props.column]}
			/>
		)}

		<ErrorLabel {...props} message={'Fill out before saving.'} style={{color: 'black'}} />
	</Form.Field>
);

export default DefaultTaskType;
