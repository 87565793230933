import React from 'react';
import {Menu} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';

const CRMNav = () => (
	<div>
		<Menu secondary vertical fixed="left" className="omni-sidebar">
			<Menu.Item className="omni-sidebar-menu-item">
				<Menu.Header>
					<h2>CRM</h2>
				</Menu.Header>
				<Menu.Menu>
					<Menu.Item as={NavLink} to={`/crm/dashboard/pipeline/grid-view`} className="omni-sidebar-item">
						<h4>Pipeline</h4>
					</Menu.Item>
					<Menu.Menu>
						<Menu.Menu style={{marginLeft: '1vw'}}>
							<Menu.Item
								as={NavLink}
								to={`/crm/dashboard/pipeline/card-view`}
								name="card-view"
								className="omni-sidebar-item-small"
							>
								<h5>Card View</h5>
							</Menu.Item>
							<Menu.Item
								as={NavLink}
								to={`/crm/dashboard/pipeline/analytics`}
								name="Sales Analytics"
								className="omni-sidebar-item-small"
							>
								<h5>Sales Analytics</h5>
							</Menu.Item>
						</Menu.Menu>
					</Menu.Menu>
				</Menu.Menu>
			</Menu.Item>
			<Menu.Item as={NavLink} to={`/crm/dashboard/commits`} name="commits" className="omni-sidebar-item">
				<h4>Commits</h4>
			</Menu.Item>
			<Menu.Item as={NavLink} to={`/crm/dashboard/leads`} name="leads" className="omni-sidebar-item">
				<h4>Leads</h4>
			</Menu.Item>
			<Menu.Item as={NavLink} to={`/crm/dashboard/clients`} name="clients" className="omni-sidebar-item">
				<h4>Clients</h4>
			</Menu.Item>
		</Menu>
	</div>
);

export default CRMNav;
