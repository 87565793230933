import React from 'react';
import PropTypes from 'prop-types';
import {Grid, List, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import * as actions from '../../../../services/workRecordActions.js';

class ProjectCostingPJSummary extends React.Component {
	render() {
		return (
			<Grid columns={3} divided>
				<Grid.Row>
					<Grid.Column>
						<div
							style={{
								margin: '2vh 0px 2vh .8vw',
								fontWeight: 'bold'
							}}
						>
							Cost
						</div>
						<Segment basic loading={this.props.loading}>
							<List
								divided
								verticalAlign="middle"
								style={{padding: '0em 1em 1em 1em'}}
								relaxed="very"
								loading
							>
								{this.props.costItems.map((item, itemIndex) => (
									<List.Item key={itemIndex}>
										<div style={{display: 'inline-block'}}>{item.name}</div>
										<div
											style={{
												display: 'inline-block',
												marginLeft: '.5rem'
											}}
										>
											{item.value !== null
												? item.percent
													? `${item.value} %`
													: `$ ${item.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
												: null}
										</div>
									</List.Item>
								))}
							</List>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<div
							style={{
								margin: '2vh 0px 2vh .8vw',
								fontWeight: 'bold'
							}}
						>
							Revenue
						</div>
						<Segment basic loading={this.props.loading}>
							<List divided verticalAlign="middle" style={{padding: '0em 1em 1em 1em'}} relaxed="very">
								{this.props.revenueItems.map((item, itemIndex) => (
									<List.Item key={itemIndex}>
										<div
											style={{
												display: 'inline-block'
											}}
										>
											{item.name}
										</div>
										<div
											style={{
												display: 'inline-block',
												marginLeft: '.5rem'
											}}
										>
											{item.value !== null
												? item.percent
													? `${item.value} %`
													: `$ ${item.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
												: null}
										</div>
									</List.Item>
								))}
							</List>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<div
							style={{
								margin: '2vh 0px 2vh .8vw',
								fontWeight: 'bold'
							}}
						>
							Margin & GP
						</div>
						<Segment basic loading={this.props.loading}>
							<List
								divided
								verticalAlign="middle"
								style={{padding: '0em 1em 1em 1em'}}
								relaxed="very"
								loading
							>
								{this.props.marginGpItems.map((item, itemIndex) => (
									<List.Item key={itemIndex}>
										<div
											style={{
												display: 'inline-block'
											}}
										>
											{item.name}
										</div>
										<div
											style={{
												display: 'inline-block',
												marginLeft: '.5rem'
											}}
										>
											{item.value !== null
												? item.percent
													? `${Math.round(item.value * 100) / 100} %`
													: `$ ${item.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
												: null}
										</div>
									</List.Item>
								))}
							</List>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

ProjectCostingPJSummary.propTypes = {
	loading: PropTypes.bool,
	costItems: PropTypes.array,
	revenueItems: PropTypes.array,
	marginGpItems: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		revenueItems: state.work.workRecord.view.ProjectCosting.projectCostingPJ.summaryFields.revenue,
		costItems: state.work.workRecord.view.ProjectCosting.projectCostingPJ.summaryFields.cost,
		marginGpItems: state.work.workRecord.view.ProjectCosting.projectCostingPJ.summaryFields.marginGP,
		workId: state.work.workRecord.view.id,
		loading: state.work.workRecord.view.ProjectCosting.projectCostingPJ.loading
	};
};

export default connect(mapStateToProps, actions)(ProjectCostingPJSummary);
