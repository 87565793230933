import React from 'react';
import {Button, Popup} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const AasdiWorkTabCellRenderer = (props) => {
	return (
		<div>
			<Popup
				trigger={
					<Link to={`/work/view/${props.data.id}`}>
						<Button
							icon="linkify"
							style={{
								backgroundColor: 'transparent',
								padding: '.5em .5em .5em'
							}}
						/>
					</Link>
				}
				content={'View Work Details'}
				position="top center"
			/>
		</div>
	);
};

AasdiWorkTabCellRenderer.propTypes = {
	data: PropTypes.object
};

export default AasdiWorkTabCellRenderer;
