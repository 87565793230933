import React from 'react';
import {Button, Modal, Label, Icon, Header, Grid, Dropdown, Input, Dimmer, Divider, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';
import PropTypes from 'prop-types';

import * as actions from './services/contactModalActions.js';

import {debounce, debounceEventHandler} from '../../lib/old/debounce.js';

const ContactModal = (props) => (
	<Modal open={props.toggle} size={'small'}>
		<Header>
			<Icon name={props.option === 'edit' ? 'edit' : 'plus'} color={props.modalError ? 'red' : 'blue'} />
			<div className="content">{props.option === 'edit' ? 'Edit Contact' : 'Add Contact'}</div>
		</Header>
		<Modal.Content
			scrolling={true}
			style={props.option === 'search' ? {paddingBottom: '3rem', height: '40vh'} : {paddingBottom: '3rem'}}
			className="createModalContent"
		>
			{!props.option ? (
				<Grid centered>
					<Grid.Row>
						<Button
							basic
							size="huge"
							color="blue"
							content="Search for Contact"
							icon="search"
							onClick={() => {
								props.contactToggleOption('search');
							}}
						/>
					</Grid.Row>
					<Divider />
					<Grid.Row>
						<Button
							basic
							size="huge"
							color="green"
							content="Create New Contact"
							icon="add"
							onClick={() => {
								props.contactToggleOption('create');
							}}
						/>
					</Grid.Row>
				</Grid>
			) : null}
			{props.loading ? (
				<Dimmer inverted active className="centerDimmer">
					{props.loading === 'start' ? (
						<Loader size="big">Fetching Contacts for Client</Loader>
					) : props.loading === 'progress' ? (
						<Loader size="big">{props.option === 'edit' ? 'Updating Contact' : 'Adding Contact'}</Loader>
					) : (
						// Error handling
						<Header as="h3" icon>
							<Icon name="cancel" color="red" />
							<div style={{paddingBottom: '.5em', color: 'black'}}>Contact Adding Failed</div>
							<div style={{color: 'black'}}>Please try again.</div>
							<Button
								onClick={() => {
									props.closeDimmer(null);
								}}
								style={{marginTop: '1.5em'}}
							>
								Close Message
							</Button>
						</Header>
					)}
				</Dimmer>
			) : null}
			{props.option === 'create' || props.option === 'edit' ? (
				<Grid>
					<Grid.Row>
						{props.modalFields.map((field, fieldIndex) =>
							field.skip ? null : field.input ? (
								<Grid.Column
									width={field.split ? 8 : 16}
									key={fieldIndex}
									style={{
										paddingTop: '1rem',
										paddingBottom: '1rem'
									}}
								>
									<div>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											{field.name}
										</Label>
									</div>
									<DebounceInput
										element={Input}
										onChange={(e) => {
											props.updateContactModal(fieldIndex, e.target.value, false);
										}}
										debounceTimeout={600}
										value={field.value}
										style={{
											width: '100%'
										}}
										error={field.required ? field.error : false}
										disabled={field.disabled}
										placeholder={field.placeholder}
									/>
								</Grid.Column>
							) : (
								<Grid.Column
									width={16}
									key={fieldIndex}
									style={{
										paddingTop: '1rem',
										paddingBottom: '1rem'
									}}
								>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{field.name}
									</Label>
									<Dropdown
										className="createModalDropdown"
										placeholder={field.placeholder}
										fluid
										search
										selection
										options={field.options}
										onChange={(e, {value}) => {
											props.updateContactModal(fieldIndex, value, false);
										}}
										icon={field.icon}
										value={field.value}
										error={field.required ? Boolean(field.error) : false}
										disabled={field.disabled}
										upward={field.upward}
										loading={field.loading}
									/>
								</Grid.Column>
							)
						)}
					</Grid.Row>
				</Grid>
			) : props.option === 'search' ? (
				<Grid>
					{props.modalFields.map((field, fieldIndex) =>
						field.name === 'Search for Contact' ? (
							<Grid.Row key={fieldIndex}>
								<Grid.Column
									width={10}
									style={{
										paddingTop: '0.5em'
									}}
								>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{field.name}
									</Label>
									<Dropdown
										className="createModalDropdown"
										placeholder={field.placeholder}
										fluid
										search
										selection
										options={field.options}
										onChange={(e, {value}) => {
											props.updateContactModal(fieldIndex, value, false);
											console.log(value.id);
										}}
										onSearchChange={debounceEventHandler(
											debounce((e) => {
												if (e.target.value) {
													if (props.type !== 'newWork' && props.type !== 'newProject') {
														props.getContacts(props.type, props.typeId, e.target.value);
													} else {
														props.getContacts(
															props.type,
															props.typeId,
															e.target.value,
															props.clientId
														);
													}
												}
											}, 600)
										)}
										icon={field.icon}
										value={field.value}
										error={field.required ? Boolean(field.error) : false}
										disabled={field.disabled}
										upward={field.upward}
										loading={field.loading}
									/>
								</Grid.Column>
							</Grid.Row>
						) : null
					)}
					{props.modalFields.map((field, fieldIndex) =>
						field.name === 'Primary Contact' ? (
							<Grid.Row key={fieldIndex}>
								<Grid.Column
									width={10}
									style={{
										paddingTop: '0.5em'
									}}
								>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{field.name}
									</Label>
									<Dropdown
										className="createModalDropdown"
										placeholder={field.placeholder}
										fluid
										search
										selection
										options={field.options}
										onChange={(e, {value}) => {
											props.updateContactModal(fieldIndex, value, false);
										}}
										icon={field.icon}
										value={field.value}
										error={field.required ? Boolean(field.error) : false}
										disabled={field.disabled}
										upward={false}
										loading={field.loading}
									/>
								</Grid.Column>
							</Grid.Row>
						) : null
					)}
				</Grid>
			) : null}
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleContactModal(null, null, null, null, null, null);
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				{props.option ? (
					props.option === 'edit' ? (
						<Button
							onClick={() => {
								props.updateContact(
									props.type,
									props.typeId,
									props.contactId,
									props.modalFields,
									props.rowIndex
								);
							}}
							color={props.modalError ? 'red' : 'green'}
						>
							<Icon name="checkmark" />
							Save
						</Button>
					) : (
						<Button
							onClick={() => {
								if (props.option === 'search') {
									let contactToAdd = props.modalFields[0].value;
									props.addSearchContact(
										props.type,
										props.typeId,
										contactToAdd.id,
										props.modalFields
									);
								} else {
									props.createContact(props.type, props.typeId, props.modalFields);
								}
							}}
							color="green"
						>
							<Icon name="checkmark" />
							{props.option === 'create' ? 'Create' : 'Add'}
						</Button>
					)
				) : null}
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

ContactModal.propTypes = {
	toggle: PropTypes.bool,
	option: PropTypes.string,
	modalError: PropTypes.bool,
	contactToggleOption: PropTypes.func,
	loading: PropTypes.func,
	closeDimmer: PropTypes.func,
	toggleContactModal: PropTypes.func,
	updateContact: PropTypes.func,
	type: PropTypes.string,
	typeId: PropTypes.number,
	contactId: PropTypes.number,
	rowIndex: PropTypes.number,
	modalFields: PropTypes.array,
	addSearchContact: PropTypes.func,
	createContact: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		toggle: state.global.contactModal.contactModal.toggleContactModal,
		loading: state.global.contactModal.contactModal.loading,
		modalFields: state.global.contactModal.contactModal.modalFields,
		id: state.global.contactModal.contactModal.id,
		type: state.global.contactModal.contactModal.type,
		place: state.global.contactModal.contactModal.place,
		option: state.global.contactModal.contactModal.option,
		modalError: state.global.contactModal.contactModal.error,
		pipelineId: state.global.contactModal.contactModal.contactData
			? state.global.contactModal.contactModal.contactData.id
			: null,
		typeId: state.global.contactModal.contactModal.typeId,
		searchContactId: state.global.contactModal.contactModal.modalFields[
			state.global.contactModal.contactModal.place
		]
			? state.global.contactModal.contactModal.modalFields[state.global.contactModal.contactModal.place].filter(
					(field) => field.name === 'Search for Contact'
			  )[0].value
				? state.global.contactModal.contactModal.modalFields[
						state.global.contactModal.contactModal.place
				  ].filter((field) => field.name === 'Search for Contact')[0].value.id
				: null
			: null,
		contactId: state.global.contactModal.contactModal.contactData
			? state.global.contactModal.contactModal.place === 'workOrder'
				? state.global.contactModal.contactModal.contactData.id
				: state.global.contactModal.contactModal.contactData.contactId
			: null,
		contactData: state.global.contactModal.contactModal.contactData,
		rowIndex: state.global.contactModal.contactModal.rowIndex
	};
};

export default connect(mapStateToProps, actions)(ContactModal);
