// TODOS
// Remove inline styling
// A lot of these components need cleanup BAD e.g. unique keys, etc

import React from 'react';
import {DebounceInput} from 'react-debounce-input';
import {Form, Icon, Popup, Input, Button, Dropdown, Menu, TextArea} from 'semantic-ui-react';
import * as taskTemplateService from '../services/TaskTemplates.service.js';

export function columnTypeHashmap(props) {
	return {
		dropdown: (
			<div key={`-div`} className="dropdownTaskBoxDiv">
				<Dropdown
					style={{width: '50%'}}
					key={`-input`}
					className="dropdownFloat"
					placeholder="Dropdown Tasks"
					search
					selection
					options={taskTemplateService.createDropdownChoice(props.sidePanelTask.choices)}
					onChange={(e, {value}) => {
						props.changeTask({
							userChoice: {choice: value}
						});
					}}
					value={props.sidePanelTask.dropdown}
				/>
				{props.sidePanelTask && props.sidePanelTask.userChoice && props.sidePanelTask.userChoice.choice ? (
					<Popup
						key={`${props.column}-popup`}
						trigger={
							<Button
								className="squareButtonRight alignBottom"
								icon
								color="red"
								type="button"
								onClick={() =>
									props.changeTask({
										choices: props.sidePanelTask.choices.filter((choice) => {
											const userChoice = props.sidePanelTask.userChoice.choice;
											if (typeof choice === 'object' && typeof userChoice === 'object') {
												return choice.choiceId !== userChoice.choiceId;
											}
											return choice !== userChoice;
										})
									})
								}
							>
								<Icon name="trash" />
							</Button>
						}
						content={JSON.stringify(props.sidePanelTask)}
					/>
				) : null}
			</div>
		),
		choiceName: (
			<Form.Field key={`${props.column}-formField`}>
				<label> Choice Name: </label>

				<DebounceInput
					style={{width: '50%', padding: '.5em 0em .5em 0'}}
					element={Input}
					debounceTimeout={600}
					key={`-input`}
					className={'editFocus'}
					onChange={(e) => {
						props.changeTask({choiceName: e.target.value});
					}}
					value={props.sidePanelTask.choiceName}
				/>
				<Popup
					trigger={
						<Button
							className="squareButton"
							icon
							color={'green'}
							type="button"
							onClick={() => {
								props.changeTask({
									choices: [...props.sidePanelTask.choices, props.sidePanelTask.choiceName],
									choiceName: ''
								});
							}}
						>
							<Icon name="plus" />
						</Button>
					}
					content={'Add Dropdown Task.'}
				/>
			</Form.Field>
		),
		inputMask: (
			<Form.Field key={`${props.column}-formField`}>
				<label>Input Mask</label>
				<Dropdown
					key={`-input`}
					placeholder={props.sidePanelTask.maskName ? props.sidePanelTask.maskName : 'Select an Input Type'}
					fluid
					search
					selection
					options={taskTemplateService.createInputMaskDropdownActions(props.inputMaskList)}
					onChange={(e, {value}) => props.changeTask({maskId: value})}
					defaultValue={props.sidePanelTask.maskId}
				/>
			</Form.Field>
		),

		asdVerification: (
			<Menu fluid widths={16} icon="labeled" secondary style={{display: 'flex', flexDirection: 'column'}}>
				<Menu.Item
					disabled={props.sidePanelTask.completed}
					as="a"
					onClick={() => props.changeTask({asdVerification: !props.sidePanelTask.asdVerification})}
				>
					<Icon color="blue" name={props.sidePanelTask.asdVerification ? 'toggle on' : 'toggle off'} />
					Requires ASD Verification
				</Menu.Item>

				<Menu.Item
					disabled={props.sidePanelTask.completed}
					as="a"
					onClick={() => props.changeTask({requiresOmniApproval: !props.sidePanelTask.requiresOmniApproval})}
				>
					<Icon color="blue" name={props.sidePanelTask.requiresOmniApproval ? 'toggle on' : 'toggle off'} />
					Requires ASD Approval
				</Menu.Item>

				<Menu.Item
					disabled={props.sidePanelTask.completed}
					as="a"
					onClick={() => props.changeTask({customerVerification: !props.sidePanelTask.customerVerification})}
				>
					<Icon color="blue" name={props.sidePanelTask.customerVerification ? 'toggle on' : 'toggle off'} />
					Requires Customer Verification
				</Menu.Item>
			</Menu>
		),

		url: (
			<Form.Field>
				<label>URL</label>
				<DebounceInput
					element={Input}
					debounceTimeout={600}
					key={`-input`}
					onChange={(e) => {
						props.changeTask({url: e.target.value});
					}}
					value={props.sidePanelTask.url}
				/>
			</Form.Field>
		),

		directions: (
			<Form.Field>
				<label>Directions</label>
				<DebounceInput
					element={TextArea}
					debounceTimeout={600}
					key={`-input`}
					onChange={(e) => {
						props.changeTask({directions: e.target.value});
					}}
					value={props.sidePanelTask.directions}
				/>
			</Form.Field>
		)
	};
}
