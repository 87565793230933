import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import OpenSheetIcon from '../components/openSheetIcon.js';
import RemoveRowInvoiceButton from '../components/removeRowInvoiceButton.js';
import * as actions from '../../../../../services/workRecordActions.js';
import SubmitBillButton from '../components/submitBillButton.js';

class InvoicesGrid extends React.Component {
	componentDidUpdate() {
		//// When updating SOW or Billing Contact on bill, it would unselect the row visually.
		//// This small fix should keep that from happening
		if (this.props.invoiceGridData) {
			let selectedNodeIndex = this.gridApi.getSelectedNodes().length
				? this.gridApi.getSelectedNodes()[0].rowIndex
				: null;
			if (
				this.props.currentSelectedInvoiceIndex > -1 &&
				selectedNodeIndex !== this.props.currentSelectedInvoiceIndex
			) {
				this.gridApi.forEachNode((node) =>
					node.rowIndex === this.props.currentSelectedInvoiceIndex ? node.setSelected(true) : null
				);
			}
		}
	}

	defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true
	};

	columnDefs = [
		{
			cellRendererFramework: OpenSheetIcon,
			suppressSizeToFit: true,
			pinned: 'left',
			resizable: false,
			suppressMenu: true,
			width: 45
		},
		{
			headerName: 'Billing Name',
			field: 'billName',
			filter: 'agTextColumnFilter',
			editable: false
		},
		{
			headerName: 'Amount',
			field: 'amount',
			filter: 'agNumberColumnFilter',
			valueFormatter: (params) => {
				return params.value ? `$${params.value}` : '';
			}
		},
		{
			headerName: 'Freight',
			field: 'freight',
			filter: 'agNumberColumnFilter',
			editable: (params) => params.data.status === 'Awaiting Submission',
			onCellValueChanged: (params) => {
				this.props.billingInvoiceGridUpdate(params.node.rowIndex, params.colDef.field, params.newValue);
			},
			valueFormatter: (params) => {
				return params.value ? `$${params.value}` : '';
			}
		},
		{
			headerName: 'PO#',
			field: 'purchaseOrderNumber',
			filter: 'agTextColumnFilter',
			editable: false
		},
		{
			headerName: 'Sales Tax',
			field: 'salesTax',
			filter: 'agTextColumnFilter',
			valueFormatter: (params) => {
				return params.value ? `$${params.value.toFixed(2)}` : '';
			}
		},
		{
			headerName: 'Invoice Date',
			field: 'invoiceDate',
			filter: 'agTextColumnFilter',
			valueFormatter: (params) => {
				return params.value ? moment.parseZone(params.value).format('L') : '';
			}
		},
		{
			headerName: 'Invoice Number',
			filter: 'agTextColumnFilter',
			valueGetter: (params) => {
				return params.data.invoiceNumber || params.data.spectrumInvoiceNumber;
			},
			valueFormatter: (params) => {
				return params.value ? params.value.trim() : '';
			}
		},
		{
			headerName: 'Group Number',
			field: 'groupWorkNumber',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Date Paid',
			field: 'datePaid',
			filter: 'agTextColumnFilter',
			valueFormatter: (params) => {
				return params.data.status === 'Invoiced' && params.value
					? moment.parseZone(params.value).format('L')
					: params.data.status === 'Invoiced' && !params.value
					? 'Awaiting Payment'
					: '';
			}
		},
		{
			headerName: 'Status',
			field: 'status'
		},
		{
			pinned: 'right',
			resizable: false,
			suppressMenu: true,
			cellRendererFramework: SubmitBillButton,
			suppressSizeToFit: true,
			width: 45
		},
		{
			pinned: 'right',
			resizable: false,
			suppressMenu: true,
			cellRendererFramework: RemoveRowInvoiceButton,
			suppressSizeToFit: true,
			width: 45
		}
	];

	sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true,
					suppressPivots: true,
					suppressPivotMode: true
				}
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		],
		position: 'right'
	};

	onQuickFilterChanged(value) {
		this.gridApi.setQuickFilter(value);
	}

	exportHandler(value) {
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				return params.value;
			}
		};
		this.gridApi.exportDataAsExcel(params);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	getRowStyle = (params) => {
		return params.data.status !== 'Awaiting Submission' ? {color: '#a7a7a5'} : null;
	};

	render() {
		return (
			<Grid className="fixGridMargin">
				<Grid.Row style={{padding: '0'}}>
					<Grid.Column width={16}>
						<div
							className="ag-theme-balham"
							style={{
								height: '20vh',
								width: '100%'
							}}
						>
							<AgGridReact
								getRowStyle={this.getRowStyle}
								animateRows={true}
								modules={AllModules}
								suppressRowClickSelection={false}
								suppressDragLeaveHidesColumns={true}
								suppressContextMenu={true}
								suppressMultiRangeSelection={true}
								onGridReady={this.onGridReady}
								columnDefs={this.columnDefs}
								defaultColDef={this.defaultColDef}
								sideBar={this.sideBar}
								rowData={this.props.invoiceGridData}
								rowSelection="single"
								onRowSelected={(params) => {
									if (params.node.selected) {
										this.props.invoiceSelected(params.data, params.rowIndex);
									}
								}}
								{...this.props}
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

InvoicesGrid.propTypes = {
	invoiceSelected: PropTypes.func,
	invoiceGridData: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		invoiceGridData: state.work.workRecord.view.BillingCenter.invoiceRowData,
		billIndex: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.billIndex,
		currentSelectedInvoiceIndex: state.work.workRecord.view.BillingCenter.currentSelectedInvoiceIndex,
		isDesynced: state.work.workRecord.view.record.desynced
	};
};

export default connect(mapStateToProps, actions)(InvoicesGrid);
