import customFetch from '../../../../../../../../../../../lib/old/customFetch.js';

export const getTechnologies = (id) => {
	console.log('getTechnology fired XXXXXXXXXXXXXXXX');
	return (dispatch) => {
		dispatch({
			type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
			payload: {
				value: true,
				column: 'loading'
			}
		});

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}?mode=technology`)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json.map((item) => (item.loadingEstimator = false));
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
					payload: {
						column: 'items',
						value: json
					}
				});

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
					payload: {
						value: false,
						column: 'loading'
					}
				});

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
					payload: {
						value: true,
						column: 'fetched'
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
					payload: {
						value: err,
						column: 'error'
					}
				});
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
					payload: {
						value: true,
						column: 'fetched'
					}
				});
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
					payload: {
						value: false,
						column: 'loading'
					}
				});
			});
	};
};

export const updateTechnologies = (oppId, payload) => {
	return (dispatch, getState) => {
		let state = getState();

		dispatch({
			type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
			payload: {
				value: true,
				column: 'loading'
			}
		});

		let oldValue =
			state.crm.opportunity.technologies.items[
				state.crm.opportunity.technologies.items.findIndex((item) => item.id === payload.id)
			].estimation;

		if (payload.column === 'estimation') {
			payload.value = payload.value.replace(',', '');
		}

		let body = {
			[payload.column]: payload.value,
			id: payload.id
		};

		if (payload.column === 'estimator') {
			const option = state.crm.opportunity.technologies.estimatorOptions.filter(
				(option) => option.value === payload.value
			);
			payload.value = option[0].text;
		}

		dispatch({
			type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES_ITEM',
			id: payload.id,
			payload: payload
		});
		customFetch(`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${oppId}?mode=technology`, {
			method: 'PUT',
			body: JSON.stringify(body)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				let updatedState = getState();
				let total = 0;

				updatedState.crm.opportunity.technologies.items.forEach((item) => {
					if (item.toggled === true) {
						total = total + Number(item.estimation);
					}
				});

				return total;
			})
			.then((total) => {
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						value: total,
						column: 'revenue'
					}
				});

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
					payload: {
						value: false,
						column: 'loading'
					}
				});
			})
			.catch((err) => {
				payload.value = oldValue;

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES_ITEM',
					id: payload.id,
					payload: payload
				});

				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_TECHNOLOGIES',
					payload: {
						value: false,
						column: 'loading'
					}
				});
				console.log(err);
			});
	};
};

export const getAccountExecs = (value, id) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CRM_LOAD_OPPORTUNITY_TECHNOLOGIES_ESTIMATOR',
			payload: {
				value: true,
				id
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((accountExec) => ({
					key: accountExec.id,
					text: accountExec.fullName,
					value: accountExec.id
				}));
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_ESTIMATOR_COLUMN_OPTIONS',
					payload: {
						value: options
					}
				});
				dispatch({
					type: 'CRM_LOAD_OPPORTUNITY_TECHNOLOGIES_ESTIMATOR',
					payload: {
						value: false,
						id
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CRM_LOAD_OPPORTUNITY_TECHNOLOGIES_ESTIMATOR',
					payload: {
						value: false,
						id
					}
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};
