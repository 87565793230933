import stateOptions from '../../../../constants/states.js';
import countryOptions from '../../../../constants/countries.js';
import branchOptions from '../../../../constants/branches.js';
import roleOptions from '../../../../constants/userRoles.js';
import salesRepOptions from '../../../../constants/salesRepOptions.js';

export const createUserFields = [
	{
		label: 'First Name',
		key: 'firstName',
		type: 'text',
		value: '',
		isRequired: true,
		width: 8,
		maxLength: 50
	},
	{
		label: 'Last Name',
		key: 'lastName',
		type: 'text',
		value: '',
		isRequired: true,
		width: 8,
		maxLength: 50
	},
	{
		label: 'Email',
		key: 'email',
		type: 'text',
		value: '',
		isRequired: true,
		width: 8,
		maxLength: 50
	},
	{
		label: 'Middle Initial',
		key: 'middleInitial',
		type: 'text',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: 3
	},
	{
		label: 'Title',
		key: 'title',
		type: 'text',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: 50
	},
	{
		label: 'Branch',
		key: 'branchId',
		type: 'dropdown',
		options: branchOptions,
		value: '',
		isRequired: true,
		width: 8,
		maxLength: null
	},
	{
		label: 'Role',
		key: 'roleId',
		type: 'dropdown',
		options: roleOptions,
		value: '',
		isRequired: true,
		width: 8,
		maxLength: null
	},
	{
		label: 'Sales Representative',
		key: 'isSalesRep',
		type: 'dropdown',
		options: salesRepOptions,
		value: '',
		isRequired: true,
		width: 8,
		maxLength: null
	},
	{
		label: 'Spectrum Sales Code',
		key: 'spectrumSalespersonCode',
		type: 'text',
		value: '',
		width: 8,
		maxLength: 3
	},
	{
		label: 'Spectrum Code',
		key: 'spectrumCode',
		type: 'text',
		value: '',
		width: 8,
		maxLength: 8
	},
	{
		label: 'Address',
		key: 'address',
		type: 'text',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: 50
	},
	{
		label: 'Address 2',
		key: 'address2',
		type: 'text',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: 50
	},
	{
		label: 'City',
		key: 'city',
		type: 'text',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: 30
	},
	{
		label: 'Country',
		key: 'country',
		type: 'dropdown',
		options: countryOptions,
		value: '',
		isRequired: false,
		width: 8,
		maxLength: null
	},
	{
		label: 'State',
		key: 'state',
		type: 'dropdown',
		options: stateOptions,
		value: '',
		isRequired: false,
		width: 8,
		maxLength: 10
	},
	{
		label: 'ZIP Code',
		key: 'zip',
		type: 'text',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: 10
	},
	{
		label: 'Phone',
		key: 'phone',
		type: 'number',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: null
	},
	{
		label: 'Phone Ext',
		key: 'phoneExt',
		type: 'number',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: null
	},
	{
		label: 'Fax',
		key: 'fax',
		type: 'number',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: null
	},
	{
		label: 'Date of Hire',
		key: 'dateOfHire',
		type: 'date',
		value: '',
		isRequired: false,
		width: 8,
		maxLength: null
	}
];
