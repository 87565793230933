import React from 'react';
import PropTypes from 'prop-types';
// import {Auth} from 'aws-amplify';
import {Header, Icon, Step, Dimmer, Loader, Button, Grid} from 'semantic-ui-react';
import {connect} from 'react-redux';
import Employee from './components/Employee.js';
import Company from './components/Company.js';
import Contacts from './components/Contacts.js';
import Insurance from './components/Insurance.js';
import License from './components/License.js';
import Staffing from './components/Staffing.js';
import LoginInfo from './components/LoginInfo.js';
import Bank from './components/Bank.js';
import {closeDimmer, setActiveStep, register} from './services/registrationActions.js';

import RegisterModal from './components/registerModal.js';

class Registration extends React.Component {
	constructor(props) {
		super(props);
		// this.signUp = this.signUp.bind(this);
	}

	// removing this for security purposes
	// signUp = () => {
	// 	let username = this.props.loginUsername;
	// 	let password = this.props.loginPassword;
	// 	let email = this.props.employeeEmail;

	// 	Auth.signUp({
	// 		username,
	// 		password,
	// 		attributes: {
	// 			email
	// 		}
	// 	})
	// 		.then((data) => console.log(data))
	// 		.catch((err) => console.log(err));
	// };

	render() {
		return (
			<div style={{padding: '3em 23em 1em 23em'}}>
				{this.props.loading ? (
					<Dimmer inverted active className="centerDimmer">
						{this.props.loading === 'start' ? (
							<Loader size="big">Loading</Loader>
						) : this.props.loading === 'progress' ? (
							<Loader size="big">Registering</Loader>
						) : (
							// Error handling
							<Header as="h3" icon>
								<Icon name="cancel" color="red" />
								<div style={{paddingBottom: '.5em', color: 'black'}}>Registration Failed</div>
								<div style={{color: 'black'}}>Please try again.</div>
								<Button
									onClick={() => {
										this.props.closeDimmer();
									}}
									style={{marginTop: '1.5em'}}
								>
									Close Message
								</Button>
							</Header>
						)}
					</Dimmer>
				) : null}
				<Header>
					<Icon name="file alternate outline" color="blue" />
					<div className="content">Registration</div>
				</Header>
				<Header style={{paddingBottom: '2.5em'}}>
					<Step.Group
						as={Grid}
						columns={'equal'}
						size="small"
						style={{margin: '0'}}
						className="registrationStepsFix"
					>
						{this.props.steps.map((step, index) => (
							<Step
								style={{textAlign: 'center'}}
								as={Grid.Column}
								key={step.name}
								icon={step.icon}
								title={step.name}
								active={step.active}
								link={true}
								onClick={() => this.props.setActiveStep(this.props.activeStep, index)}
							/>
						))}
					</Step.Group>
				</Header>

				{/* Step Fields */}
				{this.props.activeStep === 0 ? <Employee /> : null}
				{this.props.activeStep === 1 ? <Company /> : null}
				{this.props.activeStep === 2 ? <Contacts /> : null}
				{this.props.activeStep === 3 ? <Insurance /> : null}
				{this.props.activeStep === 4 ? <License /> : null}
				{/* {props.activeStep === 5 ? <RecentWork /> : null} */}
				{this.props.activeStep === 5 ? <Staffing /> : null}
				{this.props.activeStep === 6 ? <Bank /> : null}
				{this.props.activeStep === 7 ? <LoginInfo /> : null}
				<Grid.Row>
					<Grid.Column>
						<div style={{padding: '3.5em 0'}}>
							{this.props.activeStep + 1 === this.props.steps.length ? (
								<Button
									onClick={() => {
										this.props.register();
										// this.signUp();
									}}
									color="green"
									style={{float: 'right'}}
									size="large"
								>
									<Icon
										name="check"
										style={{
											margin: '0 .42857143em 0 -.21428571em'
										}}
									/>
									Register
								</Button>
							) : (
								<Button
									onClick={() => {
										this.props.setActiveStep(this.props.activeStep, this.props.activeStep + 1);
									}}
									color="green"
									style={{float: 'right'}}
									size="large"
								>
									<Icon
										name="arrow right"
										style={{
											margin: '0 .42857143em 0 -.21428571em'
										}}
									/>
									Next
								</Button>
							)}
						</div>
					</Grid.Column>
				</Grid.Row>
				<RegisterModal />
			</div>
		);
	}
}

Registration.propTypes = {
	loginUsername: PropTypes.string,
	loginPassword: PropTypes.string,
	employeeEmail: PropTypes.string,
	register: PropTypes.func,
	activeStep: PropTypes.number,
	loading: PropTypes.bool,
	closeDimmer: PropTypes.func,
	setActiveStep: PropTypes.func,
	steps: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		steps: state.registrationForm.registrationForm.steps,
		loading: state.registrationForm.registrationForm.loading,
		activeStep: state.registrationForm.registrationForm.steps
			.map((step, index) => ({step: step, index: index}))
			.filter((step) => step.step.active)[0].index,
		loginUsername: state.registrationForm.registrationForm.fields.loginUsername.value,
		loginPassword: state.registrationForm.registrationForm.fields.loginPassword.value,
		employeeEmail: state.registrationForm.registrationForm.fields.employeeEmail.value
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveStep: (currentStep, targetStep) => {
			dispatch(setActiveStep(currentStep, targetStep));
		},
		register: () => {
			dispatch(register());
		},
		closeDimmer: () => {
			dispatch(closeDimmer());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
