import { rawApiFetch } from '~/lib/api';
import { decodeJWT, refreshToken } from '~/lib/oauth2';
import { history } from '~/store.js';

type Dispatch = (action: any) => void

export function signIn(idToken: string, refreshToken: string) {
	return async (dispatch: Dispatch, getState: any) => {
		const {body} = decodeJWT(idToken)
	
		//try to use persisted account info. load from server if not valid
		let account = getState().auth.account;
		if (account === null || account.email !== body.email) {
			account = await getOmniAccount(body.email, idToken);
		}
	
		dispatch({
			type: 'AUTH_SET_USER',
			payload: {
				idToken,
				refreshToken,
				expires: body.exp,
				account,
				profile: {
					email: body.email,
					firstName: body.given_name,
					lastName: body.family_name,
					picture: body.picture
				}
			}
		})
	}
}

export function refreshSession() {
	return async (dispatch: Dispatch, getState: any) => {
		console.log("refreshing oauth session")

		const rToken = getState().auth.refreshToken as string | null
		if(!rToken) throw new Error("No refresh token")

		try {
			const {id_token} = await refreshToken(rToken)
			const {body} = decodeJWT(id_token)
		
			dispatch({
				type: 'AUTH_REFRESH_TOKEN',
				payload: {
					idToken: id_token,
					expires: body.exp
				}
			})

			return id_token
		} catch {
			dispatch(signOut())
		}
	}
}

export function signOut() {
	return async (dispatch: Dispatch, getState: any) => {
		//TODO: send a request to sign out endpoint
		dispatch({type: 'AUTH_CLEAR_USER'});
	
		history.push('/auth/login');
	}
}

//we have to use rawApiFetch because our credentials are not in the store yet
async function getOmniAccount(email: string, token: string) {
	// impersonate
	// email = 'kwood@asd-usa.com';
	return await rawApiFetch(`/legacy/employee?getUser=${email}`, {
		headers: new Headers({
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		})
	});
}
