import React from 'react';
import {Grid, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import AgGrid from '~/components/Global/AgGrid.js';
import FilterGrid from '~/components/Global/FilterGrid.js';
import ExportGrid from '~/components/Global/ExportGrid.js';
import PropTypes from 'prop-types';
import {getClientGrid} from './services/clientGridActions.js';
import ClientTypeCellRenderer from './components/ClientTypeCellRenderer.js';
import ClientActionCellRenderer from './components/ClientActionCellRenderer.js';
import CreateClientModal from './components/CreateClientModal.js';
import {toggleCreateClientModal} from './services/clientGridActions.js';

import GridHeader from '../../components/GridHeader.jsx';
import AddGridItem from '../../components/AddGridItem.jsx';

const frameworkComponents = {
	clientActionCellRenderer: ClientActionCellRenderer,
	clientTypeCellRenderer: ClientTypeCellRenderer
};

class ClientGridView extends React.Component {
	componentDidMount() {
		this.props.getClientGrid();
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	render() {
		return (
			<Segment basic style={{marginTop: '1.5rem'}}>
				<GridHeader
					message="Clients Grid"
					addButton={
						<AddGridItem
							onClick={() => {
								this.props.toggleCreateClientModal(true);
							}}
							content="Add a New Client"
						/>
					}
				/>
				<Grid>
					<FilterGrid gridApi={() => this.gridApi} />
					<ExportGrid gridApi={() => this.gridApi} />
				</Grid>
				<AgGrid
					columnDefs={this.props.clientGridColumns}
					rowData={this.props.workGridData}
					onGridReady={this.onGridReady}
					frameworkComponents={frameworkComponents}
					rowSelection="single"
				/>
				<CreateClientModal />
			</Segment>
		);
	}
}

ClientGridView.propTypes = {
	clientGridColumns: PropTypes.array.isRequired,
	workGridData: PropTypes.array,
	getClientGrid: PropTypes.func.isRequired,
	toggleCreateClientModal: PropTypes.func
};

const mapStateToProps = (state) => ({
	workGridData: state.work.clientGrid.rowData,
	clientGridColumns: state.work.clientGrid.headerClient
});

const mapDispatchToProps = (dispatch) => ({
	getClientGrid: () => {
		dispatch(getClientGrid());
	},
	toggleCreateClientModal: (status) => {
		dispatch(toggleCreateClientModal(status));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientGridView);
