import React from 'react';
import {Grid, Step, Icon, Popup} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {updateOpportunityColumn} from '../../scenes/opportunity/services/opportunityActions.js';
import {toggleSendToModal} from '../../../../../../../src/components/sendToModal/services/sendToModalActions.js';
import SendToModal from '../../../../../../../src/components/sendToModal/sendToModal.js';

let Steps = (props) => {
	const {
		isOpportunityView,
		isOpportunity,
		opportunity,
		cardView,
		stages,
		updateOpportunityColumn,
		toggleSendToModal
	} = props;
	let stageName = isOpportunityView && opportunity.hasOwnProperty('stageName') ? opportunity.stageName : false;

	let stageNames = [
		'Identified Need',
		'Estimating',
		'Submitted Quote',
		'High Probability',
		'Verbal Award',
		'Closed Business'
	];

	console.log(stages);
	console.log(cardView);

	return (
		<Grid.Row key={'pipeline-card-header'}>
			<Grid as={Step.Group} columns={'equal'} size="mini">
				<Popup
					trigger={
						<Grid.Column
							as={Step}
							className={[
								cardView ? 'cardView' : null,
								isOpportunityView ? 'opportunity' : null,
								!stageName ? 'toBeCompleted' : null
							]}
							active={Boolean(stageName && stageNames.indexOf(stageName) === 0)}
							link
							color={'blue'}
							onClick={() => {
								if (isOpportunityView) {
									updateOpportunityColumn(opportunity.id, 'stageName', 'Identified Need');
								}

								return;
							}}
						>
							<Step.Content>
								<Step.Title style={{textAlign: 'center'}}>
									{stageNames.indexOf(stageName) >= 0 ? (
										<Icon
											name={stageNames.indexOf(stageName) === 0 ? 'map marker' : 'check'}
											className={stageNames.indexOf(stageName) === 0 ? 'currentIcon' : null}
											color={stageNames.indexOf(stageName) === 0 ? null : 'green'}
										/>
									) : null}
									Identified Need
									{cardView ? (
										<span className="cardCount">{`(${
											stages ? stages.identifiedNeed.count : 0
										})`}</span>
									) : null}
								</Step.Title>
							</Step.Content>
						</Grid.Column>
					}
					header="Change the stage of the opportunity"
					content="Click here to change this opportunity to the Identified Need stage."
				/>
				<Popup
					trigger={
						<Grid.Column
							as={Step}
							className={[
								cardView ? 'cardView' : null,
								isOpportunityView ? 'opportunity' : null,
								stageNames.indexOf(stageName) < 1 ? 'toBeCompleted' : null
							]}
							active={stageNames.indexOf(stageName) === 1}
							link
							completed
							color={'blue'}
							onClick={() => {
								if (isOpportunityView) {
									updateOpportunityColumn(opportunity.id, 'stageName', 'Estimating');
								}
								if (!isOpportunity) {
									toggleSendToModal(
										opportunity.id,
										'leads',
										opportunity.accountExecutiveId,
										opportunity.projectedOrderDate
									);
								}
								return;
							}}
						>
							<Step.Content>
								<Step.Title style={{textAlign: 'center'}}>
									{stageNames.indexOf(stageName) >= 1 ? (
										<Icon
											name={stageNames.indexOf(stageName) === 1 ? 'map marker' : 'check'}
											className={stageNames.indexOf(stageName) === 1 ? 'currentIcon' : null}
											color={stageNames.indexOf(stageName) === 1 ? null : 'green'}
										/>
									) : null}
									Estimating
									{cardView ? (
										<span className="cardCount">{`(${stages ? stages.estimating.count : 0})`}</span>
									) : null}
								</Step.Title>
							</Step.Content>
						</Grid.Column>
					}
					header="Change the stage of the opportunity"
					content="Click here to change this opportunity to the Estimating stage."
				/>

				<Popup
					trigger={
						<Grid.Column
							as={Step}
							className={[
								cardView ? 'cardView' : null,
								isOpportunityView ? 'opportunity' : null,
								stageNames.indexOf(stageName) < 2 ? 'toBeCompleted' : null
							]}
							active={stageNames.indexOf(stageName) === 2}
							link
							color={'blue'}
							onClick={() => {
								if (isOpportunityView) {
									updateOpportunityColumn(opportunity.id, 'stageName', 'Submitted Quote');
								}

								if (!isOpportunity) {
									toggleSendToModal(
										opportunity.id,
										'leads',
										opportunity.accountExecutiveId,
										opportunity.projectedOrderDate
									);
								}
								return;
							}}
						>
							<Step.Content>
								<Step.Title style={{textAlign: 'center'}}>
									{stageNames.indexOf(stageName) >= 2 ? (
										<Icon
											name={stageNames.indexOf(stageName) === 2 ? 'map marker' : 'check'}
											className={stageNames.indexOf(stageName) === 2 ? 'currentIcon' : null}
											color={stageNames.indexOf(stageName) === 2 ? null : 'green'}
										/>
									) : null}
									Submitted Quote
									{cardView ? (
										<span className="cardCount">{`(${
											stages ? stages.submittedQuote.count : 0
										})`}</span>
									) : null}
								</Step.Title>
							</Step.Content>
						</Grid.Column>
					}
					header="Change the stage of the opportunity"
					content="Click here to change this opportunity to the Submitted Quote stage."
				/>

				<Popup
					trigger={
						<Grid.Column
							as={Step}
							className={[
								cardView ? 'cardView' : null,
								isOpportunityView ? 'opportunity' : null,
								stageNames.indexOf(stageName) < 3 ? 'toBeCompleted' : null
							]}
							active={stageNames.indexOf(stageName) === 3}
							link
							color={'blue'}
							onClick={() => {
								if (isOpportunityView) {
									updateOpportunityColumn(opportunity.id, 'stageName', 'High Probability');
								}

								if (!isOpportunity) {
									toggleSendToModal(
										opportunity.id,
										'leads',
										opportunity.accountExecutiveId,
										opportunity.projectedOrderDate
									);
								}
								return;
							}}
						>
							<Step.Content>
								<Step.Title style={{textAlign: 'center'}}>
									{stageNames.indexOf(stageName) >= 3 ? (
										<Icon
											name={stageNames.indexOf(stageName) === 3 ? 'map marker' : 'check'}
											className={stageNames.indexOf(stageName) === 3 ? 'currentIcon' : null}
											color={stageNames.indexOf(stageName) === 3 ? null : 'green'}
										/>
									) : null}
									High Probability
									{cardView ? (
										<span className="cardCount">{`(${
											stages ? stages.highProbability.count : 0
										})`}</span>
									) : null}
								</Step.Title>
							</Step.Content>
						</Grid.Column>
					}
					header="Change the stage of the opportunity"
					content="Click here to change this opportunity to the High Probability stage."
				/>

				<Popup
					trigger={
						<Grid.Column
							as={Step}
							className={[
								cardView ? 'cardView' : null,
								isOpportunityView ? 'opportunity' : null,
								stageNames.indexOf(stageName) < 4 ? 'toBeCompleted' : null
							]}
							active={stageNames.indexOf(stageName) === 4}
							link
							color={'blue'}
							onClick={() => {
								if (isOpportunityView) {
									updateOpportunityColumn(opportunity.id, 'stageName', 'Verbal Award');
								}

								if (!isOpportunity) {
									toggleSendToModal(
										opportunity.id,
										'leads',
										opportunity.accountExecutiveId,
										opportunity.projectedOrderDate
									);
								}
								return;
							}}
						>
							<Step.Content>
								<Step.Title style={{textAlign: 'center'}}>
									{stageNames.indexOf(stageName) >= 4 ? (
										<Icon
											name={stageNames.indexOf(stageName) === 4 ? 'map marker' : 'check'}
											className={stageNames.indexOf(stageName) === 4 ? 'currentIcon' : null}
											color={stageNames.indexOf(stageName) === 4 ? null : 'green'}
										/>
									) : null}
									Verbal Award
									{cardView ? (
										<span className="cardCount">{`(${
											stages ? stages.verbalAward.count : 0
										})`}</span>
									) : null}
								</Step.Title>
							</Step.Content>
						</Grid.Column>
					}
					header="Change the stage of the opportunity"
					content="Click here to change this opportunity to the Verbal Award stage."
				/>

				{cardView ? null : (
					<Grid.Column
						as={Step}
						className={[
							cardView ? 'cardView' : null,
							isOpportunityView ? 'opportunity' : null,
							stageNames.indexOf(stageName) < 5 ? 'toBeCompleted' : null
						]}
						active={stageNames.indexOf(stageName) === 5}
						link
						color={'blue'}
						style={{cursor: 'auto'}}
						// onClick={() => {
						// 	if (isOpportunityView) {
						// 		return updateOpportunityColumn(
						// 			opportunity.id,
						// 			'stageName',
						// 			'Closed Business'
						// 		);
						// 	}
						// 	return;
						// }}
					>
						<Step.Content>
							<Step.Title style={{textAlign: 'center'}}>
								{stageNames.indexOf(stageName) >= 5 ? (
									<Icon
										name={stageNames.indexOf(stageName) === 5 ? 'map marker' : 'check'}
										className={stageNames.indexOf(stageName) === 5 ? 'currentIcon' : null}
										color={stageNames.indexOf(stageName) === 5 ? null : 'green'}
									/>
								) : null}
								Closed Business
								{cardView ? (
									<span className="cardCount">{`(${stages ? stages.closedBusiness.count : 0})`}</span>
								) : null}
							</Step.Title>
						</Step.Content>
					</Grid.Column>
				)}
			</Grid>
			<SendToModal {...props} />
		</Grid.Row>
	);
};

Steps.propTypes = {
	opportunity: PropTypes.shape({
		stageName: PropTypes.string.isRequired
	}),
	cardView: PropTypes.bool,
	stages: PropTypes.object.isRequired,
	isOpportunityView: PropTypes.bool.isRequired,
	updateOpportunityColumn: PropTypes.func.isRequired,
	toggleSendToModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		stages: state.crm.pipeline.analytics.stages.data,
		opportunity: state.crm.opportunity.data
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateOpportunityColumn: (id, column, value) => {
			dispatch(updateOpportunityColumn(id, column, value));
		},
		toggleSendToModal: (id, place, acctExecId, projOrderDate) =>
			dispatch(toggleSendToModal(id, place, acctExecId, projOrderDate))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
