import update from 'immutability-helper';
import branchOptions from '../../../../../../../constants/branches.js';

const defaultState = {
	createProjectModal: {
		id: null,
		projectId: null,
		iconError: false,
		loading: null,
		place: null,
		toggleCreateProjectModal: false,
		clientId: null,
		sections: [
			{
				name: 'opportunitySection',
				fields: [
					{
						name: 'Opportunity Name',
						value: '',
						input: true,
						error: false,
						disabled: true,
						required: true,
						columnWidth: 5
					},
					{
						name: 'Client Name',
						value: '',
						input: true,
						error: false,
						disabled: true,
						required: true,
						columnWidth: 5
					},
					{
						name: 'Purchase Order #',
						value: '',
						input: true,
						error: false,
						disabled: false,
						required: true,
						columnWidth: 5
					},
					{
						name: 'Project Manager',
						value: '',
						error: false,
						disabled: false,
						options: null,
						loading: false,
						required: true,
						columnWidth: 5,
						employeeSearch: true
					},
					{
						//Account Exec
						name: 'Sales Representative',
						value: null,
						error: false,
						disabled: false,
						options: null,
						loading: false,
						required: true,
						columnWidth: 5,
						employeeSearch: true
					},
					{
						name: 'Purchase Order Amount',
						value: '',
						input: true,
						error: false,
						disabled: false,
						required: true,
						columnWidth: 5
					},
					{
						name: 'Group Billing',
						value: false,
						error: false,
						options: [
							{
								key: 'No',
								text: 'No',
								value: false
							},
							{
								key: 'Yes',
								text: 'Yes',
								value: true
							}
						],
						groupBilling: true,
						disabled: false,
						required: false,
						columnWidth: 5
					},
					{
						name: 'Service Type',
						value: 3,
						error: false,
						serviceTypeField: true,
						options: [
							{
								key: 1,
								text: 'Deploy',
								value: 1
							},
							{
								key: 2,
								text: 'Design',
								value: 2
							},
							{
								key: 3,
								text: 'Build',
								value: 3
							},
							{
								key: 4,
								text: 'Support',
								value: 4
							}
						],
						disabled: false,
						required: true,
						columnWidth: 5
					},
					{
						name: 'Account Manager',
						value: '',
						error: false,
						disabled: false,
						required: true,
						columnWidth: 5,
						employeeSearch: true
					},
					{
						//NDS
						name: 'Project Administrator',
						value: '',
						error: false,
						disabled: false,
						required: false,
						columnWidth: 5,
						employeeSearch: true
					},
					{
						name: 'Master Client',
						value: '',
						input: false,
						error: false,
						required: true,
						loading: false,
						columnWidth: 5,
						searchClient: true
					},
					{
						name: 'Service Type Options',
						value: 1,
						skipFieldMap: true,
						input: false,
						error: false,
						disabled: false,
						required: false,
						columnWidth: 4,
						options: [
							{
								key: 1,
								text: 'Move, Add, Change',
								value: 1
							},
							{
								key: 2,
								text: 'Staging / Configuration',
								value: 2
							},
							{
								key: 3,
								text: 'Survey',
								value: 3
							},
							{
								key: 4,
								text: 'Repair',
								value: 4
							},
							{
								key: 6,
								text: 'Small Project',
								value: 6
							}
						]
					},
					{
						name: 'Branch',
						value: '',
						skipFieldMap: true
					}
				]
			},
			{
				name: 'agGridSection',
				rows: [],
				fields: [] //this is only used because validation code requires fields to be present on every section.
			},
			{
				name: 'billingDetailsSection',
				fields: [
					{
						name: 'Google Drive Link',
						value: '',
						input: true,
						error: false,
						disabled: false,
						required: true,
						columnWidth: 7
					},
					{
						name: 'Location',
						value: '',
						input: true,
						error: false,
						disabled: false,
						required: true,
						columnWidth: 7,
						upward: false,
						dataList: [],
						searchLocations: true
					},
					{
						name: 'Billing Contact',
						value: '',
						input: true,
						error: false,
						disabled: false,
						required: true,
						columnWidth: 7,
						placeholder: 'Search contacts',
						dataList: []
					},
					{
						name: 'New Construction',
						value: null,
						toggle: false,
						error: false,
						disabled: false,
						required: true,
						columnWidth: 7,
						placeholder: 'Choose option',
						options: [
							{
								key: 'No',
								text: 'No',
								value: 'False'
							},
							{
								key: 'Yes',
								text: 'Yes',
								value: 'True'
							}
						]
					}
				]
			},
			{
				name: 'documentsSection',
				fields: [
					{
						name: 'File Name',
						value: '',
						input: true,
						error: false,
						disabled: false,
						required: false,
						checkForm: true,
						columnWidth: 5
					},
					{
						name: 'Google Drive Link',
						value: '',
						input: true,
						error: false,
						disabled: false,
						checkForm: true,
						required: false,
						columnWidth: 5
					},
					{
						name: 'Customer Approval (Purchase Order)',
						value: false,
						input: false,
						dropdown: true,
						error: false,
						disabled: false,
						required: false,
						columnWidth: 5,
						options: [
							{
								key: 'No',
								text: 'No',
								value: false
							},
							{
								key: 'Yes',
								text: 'Yes',
								value: true
							}
						]
					},
					{
						name: 'Comments',
						value: '',
						input: false,
						textArea: true,
						error: false,
						disabled: false,
						required: false,
						columnWidth: 15
					}
				],
				documents: []
			},
			{
				name: 'contactsSection',
				fields: []
			},
			{
				name: 'activityStreamSection',
				fields: []
			}
		],
		tempContacts: [],
		displayContacts: []
	}
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case 'CREATE_PROJECT_MODAL_TOGGLE':
			state = update(state, {
				createProjectModal: {
					toggleCreateProjectModal: {
						$set: !state.createProjectModal.toggleCreateProjectModal
					},
					id: {
						$set: action.payload.id
					},
					place: {
						$set: action.payload.place
					},
					sections: {
						$set: defaultState.createProjectModal.sections
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_LOADING':
			state = update(state, {
				createProjectModal: {
					loading: {
						$set: action.payload.state
					}
				}
			});
			break;

		case 'CREATE_PROJECT_UPDATE_PROJECT_ID':
			state = update(state, {
				createProjectModal: {
					projectId: {
						$set: action.payload
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_UPDATE':
			state = update(state, {
				createProjectModal: {
					iconError: {$set: false},
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_GET_DROPDOWN_OPTIONS':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {
										$set: action.payload.options
									},
									loading: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_GET_DATALIST_OPTIONS':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									dataList: {
										$set: action.payload.json
									},
									loading: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_GET_TECHNOLOGIES':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							rows: {
								$set: action.payload.json
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_GRID_UPDATE':
			state = update(state, {
				createProjectModal: {
					iconError: {
						$set: false
					},
					sections: {
						[action.payload.sectionIndex]: {
							rows: {
								[action.payload.rowIndex]: {
									[action.payload.field]: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_GRID_TOTAL_UPDATE':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							totalRow: {
								0: {
									[action.payload.field]: {
										$set: action.payload.newValue
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_ADD_DOCUMENT':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							documents: {
								$push: [action.payload.object]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_UPDATE_DOC_GRID':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							documents: {
								[action.payload.rowIndex]: {
									customerApproval: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_REMOVE_DOC_GRID':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							documents: {
								$splice: [[action.payload.rowIndex, 1]]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_LOADING_FIELD':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									loading: {
										$set: action.payload.loading
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_MODAL_CHECK_REQUIRED_FIELDS':
			action.payload.map(
				(object) =>
					(state = update(state, {
						createProjectModal: {
							iconError: {$set: true},
							sections: {
								[object.sectionIndex]: {
									fields: {
										[object.fieldIndex]: {
											value: {
												$set: object.field.value
											},
											error: {
												$set: true
											}
										}
									}
								}
							}
						}
					}))
			);
			break;

		case 'CREATE_PROJECT_MODAL_AUTOFILL':
			state = update(state, {
				createProjectModal: {
					loading: {
						$set: null
					},
					clientId: {
						$set: action.payload.clientId
					},
					sections: {
						// Opportunity Section
						0: {
							fields: {
								// Opportunity Name
								0: {
									value: {
										$set: action.payload.oppName
									}
								},
								// Client Name
								1: {
									value: {
										$set: action.payload.clientName
									}
								},
								// Account Exec
								4: {
									value: {
										$set: action.payload.accountExecId
									},
									options: {
										$set: action.payload.options
									}
								},
								// Project Order Amount
								5: {
									value: {
										$set: action.payload.poAmount
									}
								},
								// Service Type
								7: {
									value: {
										$set: action.payload.serviceType
									}
								},
								// Master Client
								10: {
									value: {
										$set: action.payload.clientId
									},
									options: {
										$set: action.payload.clientOption
									}
								},
								12: {
									value: {
										$set: action.payload.branchName
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_ADD_TEMP_CONTACT':
			state = update(state, {
				createProjectModal: {
					tempContacts: {
						$push: [action.payload.contact]
					},
					displayContacts: {
						$push: [action.payload.displayContact]
					}
				}
			});
			break;

		case 'CREATE_PROJECT_UPDATE_TEMP_CONTACT':
			state = update(state, {
				createProjectModal: {
					tempContacts: {
						[action.payload.index]: {
							$set: action.payload.contact
						}
					},
					displayContacts: {
						[action.payload.index]: {
							$set: action.payload.contact
						}
					}
				}
			});
			break;

		case 'CREATE_PROJECT_REMOVE_TEMP_CONTACT':
			state = update(state, {
				createProjectModal: {
					tempContacts: {
						$splice: [[action.payload.index, 1]]
					},
					displayContacts: {
						$splice: [[action.payload.index, 1]]
					}
				}
			});
			break;

		case 'CREATE_PROJECT_CLEAR_TEMP_CONTACTS':
			state = update(state, {
				createProjectModal: {
					tempContacts: {
						$set: []
					},
					displayContacts: {
						$set: []
					}
				}
			});
			break;
	}

	return state;
};
