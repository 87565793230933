let inputMaskFactory = (inputMasks) => {
	return inputMasks.map((inputMask) => {
		return {
			key: inputMask.maskId,
			value: inputMask.maskId,
			text: inputMask.maskName
		};
	});
};

export default inputMaskFactory;
