import React from 'react';
import {Checkbox} from 'semantic-ui-react';

const DocumentSectionCheckbox = (props) => (
	<div style={{paddingTop: '.2em'}}>
		<Checkbox
			label={props.value ? 'Yes' : 'No'}
			checked={props.value}
			onClick={(event, {checked}) => {
				props.agGridReact.props.updateCreateWorkOrderModalDocumentGrid(
					props.rowIndex,
					props.agGridReact.props.sectionIndex,
					checked
				);
			}}
		/>
	</div>
);

export default DocumentSectionCheckbox;
