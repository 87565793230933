import React from 'react';
import {
	Button,
	Modal,
	Icon,
	Header,
	Dropdown,
	Input,
	Form,
	Step,
	Loader,
	Dimmer,
	Grid,
	Label,
	TextArea
} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';

import api from '../../../../../lib/api.ts';
import * as actions from '../services/clientGridActions.js';
import DocumentSectionRemoveButton from './documentSectionRemoveButton.js';
import {createClientDocumentsHeader} from '../services/clientGridReducer.js';
import AgGrid from '../../../../../components/Global/AgGrid.js';

const frameworkComponents = {
	documentSectionRemoveButton: DocumentSectionRemoveButton
};

class ProspectCreationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: [],
			loading: false
		};
		this.defineFieldType = this.defineFieldType.bind(this);
	}

	defineFieldValue(fieldKey, stepIndex) {
		const {modalSteps} = this.props.stepsClient;
		const targetField = modalSteps[stepIndex].modalFields.find((field) => field.key === fieldKey);
		return targetField.value;
	}

	submitForm() {
		const body = {};
		const {modalSteps} = this.props.stepsClient;
		const {addNewProspect} = this.props;
		modalSteps.forEach((step) => step.forEach((field) => (body[field.key] = field.value)));
		this.setState({...this.state, loading: true});
		api('legacy/client', 'post', body)
			.then((response) => {
				response.clientStatusName = response.clientStatusName ? response.clientStatusName : 'Prospect';
				addNewProspect(response);
				const resetedFields = this.setFields();
				this.setState({
					...this.state,
					loading: false,
					fields: resetedFields
				});
				this.closeModal();
			})
			.catch((error) => {
				console.log(error);
				this.setState({...this.state, loading: false});
				this.closeModal();
				alert('Something went wrong');
			});
	}

	closeModal() {
		this.props.toggleProspectCreationModal(false);
	}

	fieldUpdated(value, fieldKey, step) {
		const {fields} = this.state;
		const targetFieldIndex = fields[step].findIndex((field) => field.key === fieldKey);
		fields[step][targetFieldIndex].value = value;
		this.setState({...this.state, fields});
	}

	defineFieldType(inputFlag, key) {
		if (key !== 'state') {
			return inputFlag;
		}
		const countryValue = this.defineFieldValue('countryId', 0);
		if (countryValue !== 218) {
			return true;
		}
		return false;
	}

	render() {
		const {toggleProspectCreationModal, isOpen, documentsData} = this.props;
		const {loading} = this.state;

		return (
			<Modal size="large" open={isOpen} onUnmount={() => this.closeModal()}>
				<Header>
					<Icon name="plus" color="green" />
					<div className="content">Create prospect</div>
					<Step.Group widths={4} size="small">
						{this.props.stepsClient.modalSteps.map((modalStep, index) => (
							<Step
								key={modalStep.name}
								icon={modalStep.icon}
								// description: modal.description,
								title={modalStep.name}
								active={modalStep.active}
								link={true}
								onClick={() => this.props.setActiveStep('stepsClient', this.props.activeStep, index)}
							/>
						))}
					</Step.Group>
				</Header>
				{this.props.modalError ? (
					<Modal.Content style={{height: '35vh'}}>
						<Dimmer active inverted>
							<Grid textAlign="center" style={{marginTop: '10vh'}}>
								<Grid.Row>
									<Icon name="x" color="red" size="massive" />
								</Grid.Row>
								<Grid.Row>
									<Label basic size="big" color="red">
										{this.props.modalErrorMessage}
									</Label>
								</Grid.Row>
								<Grid.Row>
									<Button
										onClick={() => {
											this.props.closeError();
										}}
									>
										Close
									</Button>
								</Grid.Row>
							</Grid>
						</Dimmer>
					</Modal.Content>
				) : this.props.modalLoading ? (
					<Modal.Content style={{height: '35vh'}}>
						<Dimmer active inverted>
							<Loader inverted>Creating Prospect</Loader>
						</Dimmer>
					</Modal.Content>
				) : (
					<Modal.Content>
						{this.props.stepsClient.modalSteps.map((modal, stepIndex) =>
							modal.active ? (
								<Grid key={stepIndex}>
									<Grid.Row>
										{modal.modalFields.map((field, fieldIndex) =>
											this.defineFieldType(field.input, field.key) ? (
												<Grid.Column key={fieldIndex} width={5}>
													<div
														style={{
															paddingTop: '0.5em'
														}}
													>
														<Label
															style={{
																backgroundColor: 'transparent',
																fontSize: '1rem',
																color: 'rgba(0,0,0,.8)',
																paddingLeft: '0'
															}}
														>
															{field.name}
														</Label>
													</div>
													<DebounceInput
														element={Input}
														onChange={(e) =>
															this.props.updateCreateModal(
																'stepsClient',
																stepIndex,
																fieldIndex,
																field.key === 'state'
																	? e.target.value.toUpperCase()
																	: e.target.value,
																false
															)
														}
														debounceTimeout={600}
														maxLength={field.key === 'state' ? 2 : false}
														value={field.value}
														style={{
															width: '100%'
														}}
														error={field.required ? field.error : false}
														disabled={field.disabled}
														placeholder={field.placeholder}
													/>
												</Grid.Column>
											) : field.dropdown ? (
												<Grid.Column key={fieldIndex} width={5}>
													<div
														style={{
															paddingTop: '0.5em'
														}}
													>
														<Label
															style={{
																backgroundColor: 'transparent',
																fontSize: '1rem',
																color: 'rgba(0,0,0,.8)',
																paddingLeft: '0'
															}}
														>
															{field.name}
														</Label>
													</div>
													<Dropdown
														className="createModalDropdown"
														placeholder={field.placeholder}
														fluid
														search
														selection
														options={field.options}
														onChange={(e, {value}) =>
															this.props.updateCreateModal(
																'stepsClient',
																stepIndex,
																fieldIndex,
																value,
																false
															)
														}
														icon={field.icon}
														value={field.value}
														error={field.required ? field.error : false}
														disabled={field.disabled}
														upward={field.upward}
														loading={field.loading}
													/>
												</Grid.Column>
											) : field.textArea ? (
												<Grid.Column width={15}>
													<div>
														<div
															style={{
																paddingTop: '0.8em'
															}}
														>
															<Label
																style={{
																	backgroundColor: 'transparent',
																	fontSize: '1rem',
																	color: 'rgba(0,0,0,.8)',
																	paddingLeft: '0'
																}}
															>
																{field.name}
															</Label>
														</div>
														<DebounceInput
															element={TextArea}
															onChange={(e) =>
																this.props.updateCreateModal(
																	'stepsClient',
																	stepIndex,
																	fieldIndex,
																	e.target.value,
																	false
																)
															}
															debounceTimeout={600}
															value={field.value}
															style={{
																width: '100%',
																border: '1px solid rgba(34,36,38,.15)',
																color: 'rgba(0,0,0,.87)',
																borderRadius: '.28571429rem'
															}}
															error={field.required ? field.error : false}
															disabled={field.disabled}
															placeholder={field.placeholder}
														/>
													</div>
												</Grid.Column>
											) : null
										)}
									</Grid.Row>
									{modal.name === 'Documents' ? (
										<div style={{width: '95%'}}>
											<Grid.Row>
												<Grid.Column
													width={15}
													style={{
														paddingTop: '0.8em'
													}}
												>
													<Button
														primary
														onClick={() =>
															this.props.addDocument(
																modal.modalFields[0],
																modal.modalFields[1],
																modal.modalFields[2],
																modal.modalFields[3],
																stepIndex,
																'stepsClient'
															)
														}
													>
														<Icon name="plus" />
														Add Document
													</Button>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row
												style={{
													paddingTop: '0.8em'
												}}
											>
												<Grid.Column width={15}>
													<AgGrid
														columnDefs={createClientDocumentsHeader}
														rowData={documentsData}
														onGridReady={this.onGridReady}
														frameworkComponents={frameworkComponents}
														gridStyle={{
															height: '30vh'
														}}
														modalName={'stepsClient'}
														{...this.props}
													/>
												</Grid.Column>
											</Grid.Row>
											{modal.modalFields.map((field, fieldIndex) =>
												field.checkbox ? (
													<Grid.Row
														style={{
															paddingTop: '0.8em'
														}}
													>
														<Grid.Column width={5}>
															<Form>
																<Form.Checkbox
																	checked={field.value}
																	label={field.name}
																	onChange={(e, {checked}) =>
																		this.props.updateCreateModal(
																			'stepsClient',
																			stepIndex,
																			fieldIndex,
																			checked,
																			false
																		)
																	}
																	error={
																		field.required ? Boolean(field.error) : false
																	}
																/>
															</Form>
														</Grid.Column>
													</Grid.Row>
												) : null
											)}
										</div>
									) : null}
								</Grid>
							) : null
						)}
					</Modal.Content>
				)}
				<Modal.Actions>
					<Button.Group>
						<Button
							onClick={() => {
								toggleProspectCreationModal(false);
							}}
							color="red"
							disabled={loading}
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							onClick={() => this.props.createNewProspect()}
							color="green"
							loading={loading}
							disabled={loading}
						>
							<Icon name="add" /> Create
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

ProspectCreationModal.propTypes = {
	isOpen: PropTypes.bool,
	toggleCreateClientModal: PropTypes.func,
	toggleCustomerCreationModal: PropTypes.func,
	toggleProspectCreationModal: PropTypes.func,
	addNewProspect: PropTypes.func,
	prospect: PropTypes.func,
	documentsData: PropTypes.array,
	createNewProspect: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	isOpen: state.work.clientGrid.showProspectCreationModal,
	prospectFields: state.work.clientGrid.prospectFields,
	countryField: state.work.clientGrid.prospectFields[8].value,
	stepsClient: state.work.clientGrid.stepsClient,
	activeStep: state.work.clientGrid.stepsClient.modalSteps
		.map((modalStep, index) => ({modalStep: modalStep, index: index}))
		.filter((modalStep) => modalStep.modalStep.active)[0].index,
	documentsData: state.work.clientGrid.stepsClient.modalSteps[1].documents,
	modalLoading: state.work.clientGrid.stepsClient.loading,
	modalError: state.work.clientGrid.stepsClient.error,
	modalErrorMessage: state.work.clientGrid.stepsClient.errorMessage
});

export default connect(mapStateToProps, actions)(ProspectCreationModal);
