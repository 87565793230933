import {Icon, Menu, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import optionFactory from './optionFactory.js';

const CreateSectionButton = (props) => (
	<Popup
		size={'mini'}
		trigger={
			<Menu.Item
				onClick={() => {
					props.addTask(props.mode, {
						...optionFactory(props, true),
						isSection: true
					});
				}}
			>
				<Icon name="object group" />
				Create Section
			</Menu.Item>
		}
		content={`Create a new section.`}
	/>
);

CreateSectionButton.propTypes = {
	aasdis: PropTypes.array,
	aasdiIndex: PropTypes.number,
	groupIndex: PropTypes.number,
	isSection: PropTypes.bool,
	mode: PropTypes.string,
	addSection: PropTypes.func,
	toggleEditTaskPanel: PropTypes.func
};

export default CreateSectionButton;
