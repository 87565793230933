import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Icon, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {toggleRatingConfirmationModal} from '../../../services/workRecordActions.js';

class AasdiRatingModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	render() {
		return (
			<Modal open={this.props.open}>
				<Header>
					<h3>Rate AASDIs</h3>
				</Header>
				<Modal.Content>All AASDIs must be rated before you can close this job.</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							disabled={this.state.loading}
							onClick={() => {
								this.props.toggleRatingConfirmationModal();
							}}
							color="red"
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							loading={this.state.loading}
							disabled={this.state.loading}
							onClick={() => {
								this.props.cycleUnratedAasdis();
								this.props.toggleRatingConfirmationModal();
							}}
							color="green"
						>
							<Icon name="checkmark" />
							Start Rating AASDIs
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

AasdiRatingModal.propTypes = {
	id: PropTypes.string,
	open: PropTypes.bool,
	toggleClosureModal: PropTypes.func,
	updateProgressBar: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sendToClosure: state.global.sendToModal,
		open: state.work.workRecord.aasdiRatingModal.toggleRatingConfirmationModal,
		id: state.work.workRecord.view.id,
		workOrder: state.work.workRecord.view.record,
		unratedAasdis: state.work.workRecord.view.record.unratedAasdis
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleRatingConfirmationModal: () => {
			dispatch(toggleRatingConfirmationModal());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AasdiRatingModal);
