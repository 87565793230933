import customFetch from '../../../../../../../lib/old/customFetch.js';

export const getPipelineOpportunities = () => async (dispatch, getState) => {
	dispatch({
		type: 'CRM_PIPELINE_UPDATE_OPPORTUNITIES_FETCHED',
		payload: false
	});

	const selected = getState().crm.pipeline.selectedFilters;
	const userData = getState().auth.account;
	const gridType = getState().crm.pipeline.data.gridType;

	let accountExecutiveId = null;
	if (userData.roleTypeName === 'Standard') {
		accountExecutiveId = selected.accountExecutiveId ?? userData.id;
	} else {
		accountExecutiveId = selected.accountExecutiveId ?? null;
	}

	let mode = gridType === 'pipeline' ? 'dashboard' : 'jobLog';

	try {
		const call = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline?mode=${mode}&isLead=false&selectedAccountExecId=${accountExecutiveId}&branch=${selected.branch}&startDate=${selected.startDate}&endDate=${selected.endDate}&serviceTypeName=${selected.serviceType}&technologies=${selected.technology}`
		);

		const response = await call.json();
		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: gridType === 'pipeline' ? 'CRM_PIPELINE_UPDATE_OPPORTUNITIES' : 'CRM_PIPELINE_UPDATE_JOB_LOG',
			payload: response
		});

		dispatch({
			type: 'CRM_PIPELINE_UPDATE_OPPORTUNITIES_FETCHED',
			payload: true
		});
	} catch (err) {
		dispatch({
			type: 'ERROR',
			payload: err.stack
		});
		return Promise.reject(err);
	}
};

export const getStageAnalytics = () => {
	return (dispatch, getState) => {
		dispatch({
			type: 'CRM_PIPELINE_UPDATE_STAGE_ANALYTICS_BY_KEY',
			key: 'loading',
			payload: true
		});

		const selected = getState().crm.pipeline.selectedFilters;
		const userData = getState().auth.account;

		let accountExecutiveId = null;
		if (userData.roleTypeName === 'Standard') {
			accountExecutiveId = selected.accountExecutiveId
				? selected.accountExecutiveId
				: userData.accountExecutiveId;
		} else {
			accountExecutiveId = selected.accountExecutiveId ? selected.accountExecutiveId : null;
		}

		customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline?mode=analytics&isLead=false&selectedAccountExecId=${accountExecutiveId}&branch=${selected.branch}&startDate=${selected.startDate}&endDate=${selected.endDate}&serviceTypeName=${selected.serviceType}&technologies=${selected.technology}`
		)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_STAGE_ANALYTICS_BY_KEY',
					key: 'currentStage',
					payload: 'identifiedNeed'
				});

				dispatch({
					type: 'CRM_PIPELINE_UPDATE_STAGE_ANALYTICS_BY_KEY',
					key: 'data',
					payload: json
				});

				dispatch({
					type: 'CRM_PIPELINE_UPDATE_STAGE_ANALYTICS_BY_KEY',
					key: 'loading',
					payload: false
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_STAGE_ANALYTICS_BY_KEY',
					key: 'loading',
					payload: false
				});

				dispatch({
					type: 'CRM_PIPELINE_UPDATE_STAGE_ANALYTICS_BY_KEY',
					key: 'error',
					payload: err
				});
			});
	};
};

export const getPipelineAnalytics = () => {
	return (dispatch, getState) => {
		dispatch({
			type: 'CRM_PIPELINE_UPDATE_PIPELINE_OVERVIEW_ANALYTICS',
			payload: {
				data: [],
				fetched: false
			}
		});

		const selected = getState().crm.pipeline.selectedFilters;
		const userData = getState().auth.account;

		let accountExecutiveId = null;
		if (userData.roleTypeName === 'Standard') {
			accountExecutiveId = selected.accountExecutiveId
				? selected.accountExecutiveId
				: userData.accountExecutiveId;
		} else {
			accountExecutiveId = selected.accountExecutiveId ? selected.accountExecutiveId : null;
		}

		customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline?mode=analyticsOverview&selectedAccountExecId=${accountExecutiveId}&branch=${selected.branch}&startDate=${selected.startDate}&endDate=${selected.endDate}&serviceTypeName=${selected.serviceType}&technologies=${selected.technology}`
		)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_OVERVIEW_ANALYTICS',
					payload: {
						data: json,
						fetched: true
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_OVERVIEW_ANALYTICS',
					payload: {
						fetched: true,
						error: err
					}
				});
			});
	};
};

export const getChartsData = () => {
	return (dispatch, getState) => {
		let chartNames = [
			'technologies',
			'serviceType',
			'topDeals',
			'topClients',
			'clientCustomerType',
			'industry',
			'estimates',
			'clientType'
		];

		chartNames.forEach((name) => {
			dispatch({
				type: 'CRM_PIPELINE_UPDATE_PIPELINE_CHART_ANALYTICS',
				name,
				payload: {
					data: [],
					fetched: false,
					type: ''
				}
			});
		});

		const selected = getState().crm.pipeline.selectedFilters;
		const userData = getState().auth.account;

		let accountExecutiveId = null;
		if (userData.roleTypeName === 'Standard') {
			accountExecutiveId = selected.accountExecutiveId
				? selected.accountExecutiveId
				: userData.accountExecutiveId;
		} else {
			accountExecutiveId = selected.accountExecutiveId ? selected.accountExecutiveId : null;
		}

		chartNames.forEach((name) => {
			customFetch(
				`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline?mode=charts&chartType=${name}&selectedAccountExecId=${accountExecutiveId}&branch=${selected.branch}&startDate=${selected.startDate}&endDate=${selected.endDate}&serviceTypeName=${selected.serviceType}&technologies=${selected.technology}`
			)
				.then((response) => {
					if (response.status !== 200) {
						return Promise.reject(response);
					}
					return response.json();
				})
				.then((json) => {
					dispatch({
						type: 'CRM_PIPELINE_UPDATE_PIPELINE_CHART_ANALYTICS',
						name,
						payload: {
							data: json,
							fetched: true,
							type: 'bar'
						}
					});
				})
				.catch((err) => {
					dispatch({
						type: 'CRM_PIPELINE_UPDATE_PIPELINE_CHART_ANALYTICS',
						name,
						payload: {
							fetched: true,
							error: true,
							errorMessage: err
						}
					});
				});
		});
	};
};

export const updatePipelineOpportunityColumn = (value, oppId, place, column) => {
	return (dispatch, getState) => {
		let state = getState();
		let updateIndex = dispatch(rowIndex(oppId, place));
		let oldValue = state.crm[place].data.opportunities[updateIndex][column];

		// Update date column labels before PUT otherwise there is delay
		if (column === 'projectedOrderDate' || column === 'bidDueDate') {
			dispatch({
				type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_UPDATE_COLUMN',
				payload: {
					value: value,
					updateIndex,
					column
				}
			});
		}

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${oppId}`;

		customFetch(endpoint, {
			method: 'Put',
			body: JSON.stringify({
				[column]: value
			})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_UPDATE_COLUMN',
					payload: {
						value,
						updateIndex,
						column
					}
				});
				// Update pastDue
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_UPDATE_COLUMN',
					payload: {
						value: json.pastDue,
						updateIndex,
						column: 'pastDue'
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_UPDATE_COLUMN',
					payload: {
						value: oldValue,
						updateIndex,
						column
					}
				});
			});
	};
};

export const deactivatePipelineOpportunity = (id, place) => {
	return (dispatch) => {
		let deactivateIndex = dispatch(rowIndex(id, place));

		// dispatch loader
		dispatch({
			type: 'DEACTIVATE_OPPORTUNITY_MODAL_LOADING',
			payload: 'start'
		});
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}`;

		customFetch(endpoint, {
			method: 'Put',
			body: JSON.stringify({
				deactivate: true
			})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				dispatch({
					type: 'DEACTIVATE_OPPORTUNITY_MODAL_LOADING',
					payload: null
				});
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_DEACTIVATE',
					payload: deactivateIndex
				});
				return dispatch({
					type: 'DEACTIVATE_OPPORTUNITY_MODAL_TOGGLE'
				});
			})
			.catch((err) => {
				dispatch({
					type: 'DEACTIVATE_OPPORTUNITY_MODAL_LOADING',
					payload: 'error'
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export function sendPipelineBackToLeads(id, place) {
	return (dispatch) => {
		let oppId = dispatch(rowIndex(id, place));

		// dispatch loader
		dispatch({
			type: 'SEND_TO_MODAL_LOADING',
			payload: 'start'
		});
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/leads/opportunity/${id}/move-opportunity`;

		customFetch(endpoint, {
			method: 'Put',
			body: JSON.stringify({})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				dispatch({
					type: 'SEND_TO_MODAL_LOADING',
					payload: null
				});
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_DEACTIVATE',
					payload: oppId
				});
				dispatch({
					type: 'SEND_TO_MODAL_TOGGLE'
				});
			})
			.catch((err) => {
				dispatch({
					type: 'SEND_TO_MODAL_LOADING',
					payload: 'error'
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
}

export const submitLostJobPipeline = (id, place, reason, comments) => {
	return (dispatch) => {
		let deactivateIndex = dispatch(rowIndex(id, place));

		if (reason) {
			// dispatch loader
			dispatch({
				type: 'LOSTJOB_OPPORTUNITY_MODAL_LOADING',
				payload: 'start'
			});
			let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}`;

			customFetch(endpoint, {
				method: 'Put',
				body: JSON.stringify({
					stageName: 'Lost Job',
					lossReason: reason,
					lossComments: comments
				})
			})
				.then((response) => {
					if (response.status !== 200) {
						return Promise.reject(response);
					}
					return response.json();
				})
				.then(() => {
					dispatch({
						type: 'LOSTJOB_OPPORTUNITY_MODAL_LOADING',
						payload: null
					});
					dispatch({
						type: 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_DEACTIVATE',
						payload: deactivateIndex
					});
					return dispatch({
						type: 'LOSTJOB_OPPORTUNITY_MODAL_TOGGLE'
					});
				})
				.catch((err) => {
					dispatch({
						type: 'LOSTJOB_OPPORTUNITY_MODAL_LOADING',
						payload: 'error'
					});
					dispatch({
						type: 'ERROR',
						payload: err.stack
					});
				});
		} else {
			dispatch({
				type: 'LOSTJOB_OPPORTUNITY_MODAL_UPDATE',
				payload: {
					column: 'reason',
					value: null,
					error: true
				}
			});
		}
	};
};

export function rowIndex(id, place) {
	return (dispatch, getState) => {
		const state = getState();

		let index = state.crm[place].data.opportunities.findIndex((row) => {
			return row.id === id;
		});
		return index;
	};
}

export function updateGridType(type) {
	return (dispatch) => {
		dispatch({
			type: 'CRM_PIPELINE_GRID',
			payload: type
		});
		return;
	};
}
