import React from 'react';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {Icon} from 'semantic-ui-react';
import BillPDF from './BillPDF.js';

class BillPDFButton extends React.Component {
	isEmpty = (str) => {
		return !str || 0 === str.length;
	};

	render() {
		const {loading, invoiceRowData} = this.props;
		const invoiceNumber = invoiceRowData.spectrumInvoiceNumber
			? invoiceRowData.spectrumInvoiceNumber.trim()
			: invoiceRowData.id;
		const workNumber = invoiceRowData.workNumber;

		if (!loading && invoiceRowData && invoiceRowData.items) {
			return invoiceRowData.billClient ? (
				<PDFDownloadLink
					document={<BillPDF {...invoiceRowData} />}
					fileName={`Bill_${invoiceNumber}_${workNumber}.pdf`}
				>
					<Icon name="file pdf" size="large" />
				</PDFDownloadLink>
			) : (
				<></>
			);
		} else {
			return <Icon name="file pdf" size="large" />;
		}
	}
}

export default BillPDFButton;
