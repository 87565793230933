import {combineReducers} from 'redux';
import registrationForm from './scenes/auth/legacy/services/registrationReducer.js';
import global from './components/Global/services/globalReducer.js';
import createProject from './scenes/crm/scenes/dashboard/scenes/CreateProject/services/createProjectReducer.js';
import contactModal from './components/contactModal/services/contactModalReducer.js';
import sendToModal from './components/sendToModal/services/sendToModalReducer.js';
import commitModal from './components/commitModal/services/commitModalReducer.js';
import deactivateOpportunityModal from './components/deactivateOpportunityModal/services/deactivateOpportunityModalReducer.js';
import lostJobOpportunityModal from './components/lostJobOpportunityModal/services/lostJobOpportunityModalReducer.js';
import createLocationModal from './components/createLocationModal/services/createLocationModalReducer.js';
import billingContactModal from './components/billingContactModal/services/billingContactModalReducer.js';
import contactConfirmationModal from './components/contactConfirmationModal/services/contactConfirmationModalReducer.js';
import activityStream from './components/activityStream/services/activityStreamReducer.js';
import contactsSection from './components/contactsSection/services/contactsSectionReducer.js';
import techManagement from './scenes/TechManagement/services/techManagementReducer.js';
import pipelineReducer from './scenes/crm/scenes/dashboard/scenes/pipeline/services/pipelineReducers.js';
import analyticsReducer from './scenes/crm/scenes/dashboard/scenes/pipeline/scenes/analytics/services/analyticsReducers.js';
import opportunityReducer from './scenes/crm/scenes/dashboard/scenes/opportunity/services/opportunityReducer.js';
import leadsReducer from './scenes/crm/scenes/dashboard/scenes/leads/services/leadsReducer.js';
import technologiesReducer from './scenes/crm/scenes/dashboard/scenes/opportunity/components/oppContent/components/technologies/services/technologiesReducer.js';
import contactsReducer from './scenes/crm/scenes/dashboard/scenes/opportunity/components/oppContent/components/contacts/services/contactsReducer.js';
import filterReducers from './scenes/crm/scenes/dashboard/components/filters/services/filterReducers.js';
import commitsReducer from './components/monthlyCommits/services/commitsReducer.js';
import commitsFilterReducer from './components/monthlyCommits/components/filters/services/filterReducers.js';
import WorkRecordReducer from './scenes/work/scenes/WorkOrder-Project/scenes/workRecordView/services/workRecordReducer.js';
import WorkGridReducer from './scenes/work/scenes/WorkOrder-Project/services/workGridReducer.js';
import createWorkOrder from './scenes/work/scenes/CreateWorkOrder/services/createWorkOrderReducer.js';
import createCustomerModal from './components/createCustomerModal/services/createCustomerModalReducer.js';
import ClientGridReducer from './scenes/work/scenes/client/services/clientGridReducer.js';
import ClientProfileGridReducer from './scenes/work/scenes/client/scenes/profile/services/profileGridReducer.js';
import AasdiProfileGridReducer from './scenes/work/scenes/aasdi/profile/services/profileAasdiGridReducer.js';
import WorkAasdiGridReducer from './scenes/work/scenes/aasdi/services/WorkAasdiGridReducer.js';
import tasks from './scenes/work/scenes/WorkOrder-Project/scenes/workRecordView/scenes/tasks/services/tasksReducer.js';
import taskTemplates from './scenes/TaskTemplates/services/TaskTemplates.reducer.js';
import accountingConsoleReducer from './scenes/accountingConsole/services/accountingConsoleReducers.js';
import auth from './scenes/auth/services/authReducer.ts';
import workRecordErrorReducer from './scenes/work/scenes/WorkOrder-Project/scenes/workRecordView/services/workRecordErrorReducer.js';
import {routerReducer} from './router.js';

export default combineReducers({
	global: combineReducers({
		global,
		techManagement,
		createCustomerModal,
		contactModal,
		sendToModal,
		deactivateOpportunityModal,
		lostJobOpportunityModal,
		createLocationModal,
		billingContactModal,
		contactConfirmationModal,
		activityStream,
		contactsSection,
		commitModal
	}),

	error: combineReducers({
		workRecord: workRecordErrorReducer
	}),

	registrationForm: registrationForm,

	accountingConsoleReducer: accountingConsoleReducer,

	work: combineReducers({
		createWorkOrder: createWorkOrder,
		workGrid: WorkGridReducer,
		workRecord: WorkRecordReducer,
		clientProfileGrid: ClientProfileGridReducer,
		aasdiProfileGrid: AasdiProfileGridReducer,
		aasdiGrid: WorkAasdiGridReducer,
		clientGrid: ClientGridReducer,
		tasks: tasks,
		taskTemplates
	}),

	crm: combineReducers({
		pipeline: combineReducers({
			analytics: analyticsReducer,
			data: pipelineReducer,
			selectedFilters: filterReducers
		}),
		opportunity: combineReducers({
			data: opportunityReducer,
			technologies: technologiesReducer,
			contacts: contactsReducer
		}),
		leads: combineReducers({
			data: leadsReducer
		}),
		createProject: createProject,
		commits: combineReducers({
			data: commitsReducer,
			selectedFilters: commitsFilterReducer
		})
	}),

	router: routerReducer,
	auth
});
