import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Segment, Label} from 'semantic-ui-react';
import ProjectCostWOVendorGrid from './components/ProjectCostWOVendorGrid.js';
import ProjectCostWOAASDIGrid from './components/ProjectCostWOAASDIGrid.js';

class ProjectCostWO extends React.Component {
	render() {
		return (
			<Segment basic style={{marginTop: '0', paddingTop: 0}}>
				<Segment style={{padding: 0}}>
					<Label attached="top" icon="search" color="blue" style={{position: 'relative', padding: 0}}>
						<div
							style={{
								display: 'inline-block',
								padding: '1.2em'
							}}
						>
							{'Purchase Orders'}
						</div>
					</Label>
					<ProjectCostWOVendorGrid />
				</Segment>
				{!this.props.workRecord.isProject ? (
					<Segment style={{padding: 0}}>
						<Label attached="top" icon="search" color="blue" style={{position: 'relative', padding: 0}}>
							<div
								style={{
									display: 'inline-block',
									padding: '1.2em'
								}}
							>
								{'AASDI Invoices'}
							</div>
						</Label>
						<ProjectCostWOAASDIGrid />
					</Segment>
				) : null}
			</Segment>
		);
	}
}

ProjectCostWO.propTypes = {
	workRecord: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		workRecord: state.work.workRecord.view.record
	};
};

export default connect(mapStateToProps, null)(ProjectCostWO);
