import React from 'react';
import {Button, Modal, Icon, Header, Segment, Label, Radio, Dimmer, Loader, Input} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../../services/workRecordActions.js';
import BillModalInputField from './billModalInputField.js';
import BillModalDropdownField from './billModalDropdownField.js';
import BillProjectCostingGrid from './billProjectCostingGrid.js';
import {FancyEditor} from '../../../../../../../../../../../components/inputs/FancyEditor.jsx';
import {Row, Column} from '../../../../../../../../../../../components/layout/Flex.jsx';

class BillModal extends React.Component {
	render() {
		const existingPO = this.props.modalFields[3].radio === 'existing';

		return (
			<Modal open={this.props.open} size="fullscreen">
				<Header>
					<Icon name={this.props.edit ? 'edit' : 'plus'} color={this.props.edit ? 'grey' : 'green'} />
					<div className="content">{this.props.edit ? 'Edit Bill' : 'Create Bill'}</div>
				</Header>
				<Modal.Content scrolling={true}>
					{this.props.billModalLoading ? (
						<Dimmer inverted active className="centerDimmer">
							{this.props.billModalLoading === 'start' ? (
								<Loader size="large">Saving Bill</Loader>
							) : (
								// Error handling
								<Header as="h4" icon>
									<Icon name="cancel" color="red" />
									<div style={{paddingBottom: '.5em', color: 'black'}}>Saving Bill Failed</div>
									<div style={{color: 'black'}}>Please try again.</div>
									<Button
										onClick={() => {
											this.props.closeDimmer('createModal');
										}}
										style={{marginTop: '1.5em'}}
									>
										Close Message
									</Button>
								</Header>
							)}
						</Dimmer>
					) : null}
					<Segment basic>
						<Row even grow>
							{/* These styles arent great. Dont have enough time to make it right */}
							<div>
								<Row even style={{marginBottom: '1rem'}}>
									<div style={{flexGrow: 2, margin: '0 1rem 0 0'}}>
										{/* Fix left side padding */}
										<BillModalInputField field={this.props.modalFields[0]} fieldIndex={0} />
									</div>
									<div style={{flexGrow: 2, margin: '0 1rem'}}>
										<BillModalDropdownField field={this.props.modalFields[1]} fieldIndex={1} />
									</div>
									<div style={{flexGrow: 2, margin: '0 1rem'}}>
										<BillModalInputField
											field={this.props.modalFields[2]}
											fieldIndex={2}
											fieldType="number"
											fieldIcon="dollar sign"
											fieldIconPosition="left"
										/>
									</div>
								</Row>
								{this.props.edit ? undefined : (
									<div>
										<Radio
											label="Existing PO #"
											name="existingPurchaseOrderGroup"
											value="existing"
											style={{padding: '1rem'}}
											checked={this.props.modalFields[3].radio === 'existing'}
											onChange={(e, {value}) => {
												this.props.createModalUpdateRadio(3, value);
												this.props.createModalUpdateColumn(3, this.props.poNumber);
												this.props.createModalUpdateColumn(4, '');
												this.props.createModalUpdateColumn(5, '');
											}}
										/>
										<Radio
											label="New PO #"
											name="existingPurchaseOrderGroup"
											value="new"
											style={{padding: '1rem'}}
											checked={this.props.modalFields[3].radio === 'new'}
											onChange={(e, {value}) => {
												this.props.createModalUpdateRadio(3, value);
												this.props.createModalUpdateColumn(3, '');
											}}
										/>
									</div>
								)}
								<Row even grow>
									{existingPO || this.props.edit ? (
										<Column style={{marginRight: '1rem'}}>
											<label>Purchase Order #</label>
											<Input
												value={this.props.poNumber}
												error={this.props.modalFields[3].error}
												disabled
											/>
										</Column>
									) : (
										<>
											<div style={{margin: '0 1rem 0 0'}}>
												<BillModalInputField field={this.props.modalFields[4]} fieldIndex={4} />
											</div>
											<div style={{margin: '0 0 0 1rem'}}>
												<BillModalInputField field={this.props.modalFields[5]} fieldIndex={5} />
											</div>
										</>
									)}
								</Row>
							</div>
							<div style={{margin: '0 0 0 1rem'}}>
								<label>Billing SOW</label>
								<FancyEditor
									value={this.props.modalFields[6].value}
									height={300}
									onChange={(value) => this.props.createModalUpdateColumn(6, value, false)}
								/>
							</div>
						</Row>
					</Segment>
					<Segment basic>
						<span style={this.props.billItemRequiredError ? {color: 'red'} : {}}>Billable Items</span>
						<BillProjectCostingGrid edit={this.props.edit} />
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					{this.props.modalFields[2].error ? (
						<div
							style={{
								display: 'inline-block',
								marginRight: '5%'
							}}
						>
							{'Freight must be a dollar amount.'}
						</div>
					) : this.props.modalFields.find((f) => f.error) ? (
						<div
							style={{
								display: 'inline-block',
								marginRight: '5%'
							}}
						>
							{'Please fill in the required fields at top of modal.'}
						</div>
					) : this.props.billItemRequiredError ? (
						<Label basic color="red" style={{marginRight: '5%'}}>
							{'Billable items are required to create a bill.'}
						</Label>
					) : null}
					<Button.Group>
						<Button
							color="red"
							onClick={() => {
								this.props.toggleInvoiceSpreadsheet();
							}}
						>
							<Icon name="remove" />
							Cancel
						</Button>
						<Button
							color="green"
							onClick={() => {
								if (this.props.edit) {
									this.props.saveBill();
								} else {
									this.props.createBill(this.props.modalFields);
								}
							}}
						>
							<Icon name="checkmark" />
							{this.props.edit ? 'Save Bill' : 'Create Bill'}
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

BillModal.propTypes = {
	open: PropTypes.bool,
	edit: PropTypes.bool,
	billItemRequiredError: PropTypes.bool,
	closeDimmer: PropTypes.func,
	createBill: PropTypes.func,
	createBillModal: PropTypes.func,
	billModalLoading: PropTypes.string,
	billModalToggle: PropTypes.bool,
	createModalUpdateColumn: PropTypes.func,
	createModalUpdateRadio: PropTypes.func,
	getBills: PropTypes.func,
	modalFields: PropTypes.object,
	workId: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		open: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.toggle,
		edit: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.billIndex !== null,
		billModalToggle: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.toggle,
		modalFields: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.fields,
		billModalLoading: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.loading,
		billItemRequiredError:
			state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.billableItemsRequiredError,
		workId: state.work.workRecord.view.id,
		poNumber: state.work.workRecord.view.record.purchaseOrderNumber
	};
};

export default connect(mapStateToProps, actions)(BillModal);
