import React from 'react';
import {Button, Modal, Icon, Header, Segment, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import * as actions from '../../../../../services/workRecordActions.js';
import OrderLinkRenderer from '../addOrderGroupModal/components/addOrderGroupGrid/components/orderLinkRenderer.js';

class SubmitOrderGroupModal extends React.Component {
	defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true
	};

	columnDefs = [
		{
			headerName: 'Link',
			cellRendererFramework: OrderLinkRenderer,
			maxWidth: 75,
			filter: false
		},
		{
			headerName: 'Work #',
			field: 'workNumber',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Client',
			field: 'client_Name',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Issue Date',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Time',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Store #',
			field: 'location_StoreNumber',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'City',
			field: 'location_City',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'State',
			field: 'location_State',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Site Contact',
			field: '',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Site Contact Phone',
			field: '',
			filter: 'agTextColumnFilter'
		}
	];

	sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true,
					suppressPivots: true,
					suppressPivotMode: true
				}
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		],
		position: 'right'
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};
	render() {
		return (
			<Modal open={this.props.submitOrderGroupModalToggle} size="large">
				<Header>
					<Icon name="check" color="green" />
					<div className="content">{'Submit Order Group'}</div>
				</Header>
				<Modal.Content scrolling={true}>
					<p>Are you sure you would like to submit this order group?</p>
					<Segment style={{marginTop: '2rem'}} loading={this.props.loading}>
						<Label attached="top" icon="search" color="blue">
							{'Work Orders'}
						</Label>
						<div
							className="ag-theme-balham"
							style={{
								height: '25vh',
								width: '100%'
							}}
						>
							<AgGridReact
								animateRows={true}
								modules={AllModules}
								suppressRowClickSelection={false}
								suppressDragLeaveHidesColumns={true}
								suppressContextMenu={true}
								suppressMultiRangeSelection={true}
								onGridReady={this.onGridReady}
								columnDefs={this.columnDefs}
								defaultColDef={this.defaultColDef}
								sideBar={this.sideBar}
								rowData={
									this.props.orderGroupData
										? this.props.orderGroupData.members
											? this.props.orderGroupData.members
											: []
										: []
								}
								{...this.props}
							/>
						</div>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button
						color="red"
						onClick={() => {
							this.props.toggleSubmitOrderGroupModal(null, null);
						}}
					>
						<Icon name="remove" /> No
					</Button>
					<Button
						color="green"
						onClick={() => {
							this.props.updateOrderGroupStatus(this.props.orderGroupData, 'Pending in OMNI');
						}}
					>
						<Icon name="checkmark" /> Yes
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

SubmitOrderGroupModal.propTypes = {
	loading: PropTypes.bool,
	orderGroupData: PropTypes.object,
	submitOrderGroupModalToggle: PropTypes.bool,
	toggleSubmitOrderGroupModal: PropTypes.func,
	updateOrderGroupStatus: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		submitOrderGroupModalToggle: state.work.workRecord.view.BillingCenter.orderGroup.submitOrderGroupModal.toggle,
		orderGroupData: state.work.workRecord.view.BillingCenter.orderGroup.submitOrderGroupModal.data,
		orderGroupRowIndex: state.work.workRecord.view.BillingCenter.orderGroup.submitOrderGroupModal.rowIndex,
		loading: state.work.workRecord.view.BillingCenter.orderGroup.submitOrderGroupModal.loading
	};
};

export default connect(mapStateToProps, actions)(SubmitOrderGroupModal);
