import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../services/accountingConsoleActions.js';
import Category from './acCategory/acCategory.js';
import {selectSelectedCategory} from '../../services/accountingConsole.selectors.js';

class AccountingConsoleCategories extends React.Component {
	componentDidMount() {
		let urlArray = window.location.pathname.split('/');
		let selectedCategory = urlArray[urlArray.length - 1];
		this.props.setSelectedCategory(selectedCategory);
	}

	render() {
		return <div>{this.props.categoryName !== '' ? <Category /> : null}</div>;
	}
}

AccountingConsoleCategories.propTypes = {
	categoryName: PropTypes.string,
	setSelectedCategory: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		categoryName: selectSelectedCategory(state).name
	};
};

export default connect(mapStateToProps, actions)(AccountingConsoleCategories);
