import {Header, Icon, Form, Popup, Label} from 'semantic-ui-react';
import React from 'react';
// import PropTypes from 'prop-types';
import optionFactory from './optionFactory.js';
import taskTypeFactory from './taskTypeFactory.js';

const TaskPanel = (props) => [
	props.task.id ? (
		<Form.Input label="Task type" key={`${props.column}-Header`}>
			<Header.Subheader key={`${props.column}-SubHeader`}>{props.task.name}</Header.Subheader>
		</Form.Input>
	) : (
		<Form.Field key={`${props.column}-formField`}>
			<label>
				Task type
				<Popup
					size="small"
					trigger={<Icon style={{'padding-left': '1rem'}} name="info circle" />}
					content="Select the type of task the AASDI needs to perform."
				/>
			</label>
			<Form.Dropdown
				key={`${props.column}-input`}
				placeholder="Select task type"
				fluid
				search
				selection
				error={props.tasks.ui.errors.taskTypeId}
				value={props.tasks.name}
				onChange={(e, {value}) => {
					props.updateTask(props.mode, 'name', value, optionFactory(props));
				}}
				options={taskTypeFactory(props.tasks.taskList)}
			/>
			{props.tasks.ui.errors.taskTypeId ? (
				<Label style={{margin: 0}} pointing basic color="red" size="mini">
					Please select a task type
				</Label>
			) : null}
		</Form.Field>
		// </div>
	)
];

export default TaskPanel;
