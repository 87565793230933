import {FieldValidator} from 'final-form'

export function combineValidators<T>(...validators: Array<FieldValidator<T>|undefined>): FieldValidator<T> {
	return (value, allValues, meta) => {
		for (const validator of validators) {
			if (validator === undefined) continue;
			const err = validator(value, allValues, meta);
			if (err !== undefined) return err;
		}
	};
}

export function required(value: unknown) {
	const message = 'Field is required';
	if (value === "" || value === false || value === undefined) {
		return message
	}
	if (Array.isArray(value) && value.length === 0) {
		return message
	}
	if (typeof value === "object" && value === null) {
		return message
	}
}

export function maxCharacters(max: number) {
	return (value: string) => {
		if (value.length > max) {
			return `${value.length} of ${max} maximum characters`
		}
	}
}

export function isGoogleDriveLink(value: string) {
	if (value === "") return

	let url: URL
	try {
		url = new URL(value)
	} catch (err) {
		return 'Not a valid URL'
	}

	const validHostnames = ['drive.google.com', 'docs.google.com']
	if(!validHostnames.includes(url.hostname)) {
		return 'host must be one of:\n' + validHostnames.join(', ')
	}
	
	//we assume there is no user or account specifier because that will be cleaned up in the format
	const validPaths = [
		'/drive/folders/',
		'/open',
		'/file/d/',
		'/spreadsheets/d/',
		'/document/d/',
	]
	const pathPrefix = validPaths.find(p => url.pathname.startsWith(p))
	if(pathPrefix === undefined) {
		return 'path must be one of:\n' + validPaths.join('\n')
	}
}
