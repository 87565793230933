// Modules
import React from 'react';
import {Button, Modal, Input, Label, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';

// Services
import * as actions from '../../services/createWorkOrderActions.js';

class WorkTemplateModal extends React.Component {
	render() {
		return (
			<Modal size="large" open={this.props.open}>
				<Label attached="top" color="blue" size="large">
					Save Template
				</Label>
				{/* <Modal.Header color="blue">Save Template</Modal.Header> */}
				<Segment basic loading={this.props.loading} style={{paddingTop: '4vh'}} textAlign="center">
					<Modal.Content>
						<h4>What name would you like to give this template?</h4>
						<Input id="templateNameInput" placeholder="Name" size="big" style={{width: '25vw'}} />
					</Modal.Content>
				</Segment>
				<Modal.Actions>
					<Button
						disabled={this.props.loading}
						negative
						onClick={() => this.props.openWorkTemplateModal(false)}
					>
						Cancel
					</Button>
					<Button
						disabled={this.props.loading}
						positive
						content="Save"
						onClick={() =>
							this.props.saveWorkTemplates(document.getElementById('templateNameInput').value, 1)
						}
					/>
				</Modal.Actions>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		open: state.work.createWorkOrder.createWorkOrderModal.workTemplateModalToggle,
		loading: state.work.createWorkOrder.createWorkOrderModal.workTemplateLoader
	};
};

export default connect(mapStateToProps, actions)(WorkTemplateModal);
