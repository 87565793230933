import customFetch from '~/lib/old/customFetch.js';
import { validatePayload } from '~/lib/old/validation.js';
import { catMap } from '~/constants/certifications.js';

export const setAasdiProfileName = (name) => (dispatch) => {
	dispatch({
		type: 'WORK_AASDI_PROFILE_NAME',
		payload: {data: name, item: 'Overview'}
	});
};

export const getTabRequestItems = (id, item, mock = false) => {
	switch (item) {
		case 'Overview':
			return getAasdiContactsGrid(id, item, mock);
		case 'Documents':
			return getDocumentsByAasdi(id, item, mock);
		case 'Work':
			return getWorkByAasdi(id, item, mock);
		case 'Ratings':
			return getRatingsByAasdi(id, item, mock);
		case 'Locations':
			return getAasdiLocationTabGrid(id, item, mock);
		default:
			console.error(new Error('Uknown item: ' + item));
	}
};

export const getAasdiProfileGrid = (id, item = 'Overview') => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/${id}`;
	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			dispatch({
				type: 'WORK_FETCH_AASDI_PROFILE_GRID',
				payload: {data: validatePayload(response), item}
			});
		} catch (err) {
			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: null, item}
			});
			alert('Something went wrong.');
			console.log(err);
		}
	};

export const getWorkByAasdi = (id, item = 'Work') => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?aasdiId=${id}`;
	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: validatePayload(response), item}
			});
		} catch (err) {
			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: [], item}
			});
			alert('Something went wrong');
			console.log(err);
		}
	};

export const getAasdiContactsGrid = (id, item = 'Overview') => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?aasdiId=${id}&fullList=1`;
	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: validatePayload(response), item}
			});
			dispatch({
				type: 'UPDATE_AASDI_CONTACT_LIST',
				payload: {
					data: validatePayload(response)
				}
			});
		} catch (err) {
			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: null, item}
			});
			alert('Something went wrong');
			console.log(err);
		}
	};

export const getAasdiLocation =
	(id, item = 'Overview') =>
	async (dispatch) => {
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations?aasdiId=${id}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			dispatch({
				type: 'UPDATE_AASDI_OVERVIEW_LOCATION',
				payload: {data: validatePayload(response), item}
			});
		} catch (err) {
			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: null, item}
			});
			alert('Something went wrong');
			console.log(err);
		}
	};

export const getDocumentsByAasdi =
	(id, item = 'Documents') =>
	async (dispatch) => {
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents?aasdiId=${id}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

			response.forEach((doc) => {
				doc.documentId = doc.id;
				delete doc.id;
			});

			if (call.status !== 200) {
				throw response;
			}

			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: validatePayload(response), item}
			});
		} catch (err) {
			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: null, item}
			});
			alert('Something went wrong');
			console.log(err);
		}
	};

export const getRatingsByAasdi = (id, item = 'Ratings') => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/${id}/rating`;
	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: validatePayload(response), item}
			});
		} catch (err) {
			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: null, item}
			});
			alert('Something went wrong');
			console.log(err);
		}
	};

export const updateDocumentValue = (index, value) => (dispatch) => {
	dispatch({
		type: 'UPDATE_AASDI_VIEW_DOCUMENTS_VALUE',
		payload: {
			index,
			value
		}
	});
};

export const updateDocumentError = (index, error) => (dispatch) => {
	dispatch({
		type: 'UPDATE_AASDI_VIEW_DOCUMENTS_ERROR',
		payload: {
			index,
			error
		}
	});
};

export const addWorkViewDocument =
	(fileName, googleDriveLink, customerApproval, comments, id, type) => async (dispatch) => {
		dispatch({
			type: 'AASDI_VIEW_DOCUMENTS_LOADING',
			payload: true
		});
		let payloadObj =
			type === 'aasdi'
				? {
						comments: comments.value,
						isClientVisible: false,
						isAasdiVisible: false,
						aasdiId: id,
						googleDriveLink: googleDriveLink.value,
						fileName: fileName.value,
						customerApproval: customerApproval.value
				  }
				: {
						comments: comments.value,
						isClientVisible: false,
						isAasdiVisible: false,
						clientId: id,
						googleDriveLink: googleDriveLink.value,
						fileName: fileName.value,
						customerApproval: customerApproval.value
				  };
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

	try {
		const call = await customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify([payloadObj])
		});
		const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			payloadObj.documentId = response[0].id;

			dispatch({
				type: 'AASDI_VIEW_DOCUMENTS_LOADING',
				payload: false
			});
			if (type === 'aasdi') {
				dispatch({
					type: 'AASDI_VIEW_ADD_DOCUMENT',
					payload: {
						object: payloadObj
					}
				});
			} else {
				dispatch({
					type: 'CLIENT_VIEW_ADD_DOCUMENT',
					payload: {
						object: payloadObj
					}
				});
			}
		} catch (err) {
			dispatch({
				type: 'AASDI_VIEW_DOCUMENTS_LOADING',
				payload: false
			});
			alert('Something went wrong');
			console.log(err);
		}
	};

export const updateAasdiViewDocument =
	(index, document, type = 'aasdi') =>
	async (dispatch) => {
		// start Loader
		dispatch({
			type: 'AASDI_VIEW_DOCUMENTS_LOADING',
			payload: true
		});

		let value = document.customerApproval;
		value === true ? (value = false) : (value = true);
		const {...documentBody} = document;
		documentBody.documentId = document.documentId;
		documentBody.customerApproval = !document.customerApproval;

		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(documentBody)
		});
		const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			// API Call Worked, Remove Doc on Frontend
			if (type === 'aasdi') {
				dispatch({
					type: 'AASDI_VIEW_UPDATE_DOCUMENT',
					payload: {
						index,
						value: documentBody.customerApproval
					}
				});
			} else if (type === 'client') {
				dispatch({
					type: 'CLIENT_VIEW_UPDATE_DOCUMENT',
					payload: {
						index,
						value: documentBody.customerApproval
					}
				});
			}

			dispatch({
				type: 'AASDI_VIEW_DOCUMENTS_LOADING',
				payload: false
			});
		} catch (err) {
			dispatch({
				type: 'AASDI_VIEW_DOCUMENTS_LOADING',
				payload: false
			});
			alert('Something went wrong');
			console.log(err);
		}
	};

export const removeAasdiViewDocument = (index, documentId, aasdiId, clientId) => async (dispatch) => {
	// Loader
	dispatch({
		type: 'AASDI_VIEW_DOCUMENTS_LOADING',
		payload: true
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

	try {
		const call = await customFetch(endpoint, {
			method: 'DELETE',
			body: JSON.stringify({documentId, aasdiId, clientId})
		});

		if (call.status !== 200) {
			const response = await call.json();
			throw response;
		}

		// API Call Worked, Remove Doc on Frontend
		if (aasdiId) {
			dispatch({
				type: 'AASDI_VIEW_REMOVE_DOCUMENT',
				payload: {
					index
				}
			});
		} else if (clientId) {
			dispatch({
				type: 'CLIENT_VIEW_REMOVE_DOCUMENT',
				payload: {
					index
				}
			});
		}

		// Hide Loader
		dispatch({
			type: 'AASDI_VIEW_DOCUMENTS_LOADING',
			payload: false
		});
	} catch (err) {
		// Hide Loader
		dispatch({
			type: 'AASDI_VIEW_DOCUMENTS_LOADING',
			payload: false
		});
		alert('Something went wrong');
		console.log(err);
	}
};

export const workPerformedOptions = () => (dispatch) => {
	dispatch({
		type: 'AASDI_VIEW_WORK_PERFORMED_OPTIONS',
		payload: [
			{
				text: 'Work Performed 1',
				value: 'workPerformed1'
			},
			{
				text: 'Work Performed 2',
				value: 'workPerformed2'
			},
			{
				text: 'Work Performed 3',
				value: 'workPerformed3'
			},
			{
				text: 'Work Performed 4',
				value: 'workPerformed4'
			}
		]
	});
};

export const equipmentOptions = () => (dispatch) => {
	dispatch({
		type: 'AASDI_VIEW_EQUIPMENT_OPTIONS',
		payload: [
			{
				text: 'Equipment 1',
				value: 'equipment1'
			},
			{
				text: 'Equipment 2',
				value: 'equipment2'
			},
			{
				text: 'Equipment 3',
				value: 'equipment3'
			},
			{
				text: 'Equipment 4',
				value: 'equipment4'
			}
		]
	});
};
export const clearGrid = (item) => (dispatch) => {
	dispatch({
		type: 'UPDATE_ROWDATA_GRID',
		payload: {data: null, item}
	});
};

export const setAasdiData = (data) => (dispatch) => {
	dispatch({
		type: 'SET_AASDI_DATA',
		payload: validatePayload(data)
	});
};

export const getAasdiLocationTabGrid =
	(aasdiId, item = 'Locations') =>
	async (dispatch) => {
		// start Loader
		dispatch({
			type: 'DEFAULT_PROFILE_AASDI_LOADING',
			payload: true
		});

		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations?aasdiId=${aasdiId}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			// API Call Worked, show locations on Frontend
			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: validatePayload(response), item}
			});

			dispatch({
				type: 'DEFAULT_PROFILE_AASDI_LOADING',
				payload: false
			});
		} catch (err) {
			// Hide loader
			dispatch({
				type: 'DEFAULT_PROFILE_AASDI_LOADING',
				payload: false
			});

			console.log(err);
			alert('Something went wrong');
		}
	};

export const removeAasdiLocation = (index, location) => async (dispatch) => {
	// Loader
	dispatch({
		type: 'DEFAULT_PROFILE_AASDI_LOADING',
		payload: true
	});

	location.deactivate = 1;

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations/${location.id}`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(location)
		});
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		// API Call Worked, Remove Location on Frontend
		dispatch({
			type: 'AASDI_VIEW_REMOVE_LOCATION',
			payload: {
				index
			}
		});

		// Hide Loader
		dispatch({
			type: 'DEFAULT_PROFILE_AASDI_LOADING',
			payload: false
		});
	} catch (err) {
		// Hide Loader
		dispatch({
			type: 'DEFAULT_PROFILE_AASDI_LOADING',
			payload: false
		});
		console.log(err);
		alert('Something went wrong');
	}
};

export const createLocation = (data) => async (dispatch) => {
	// start Loader
	dispatch({
		type: 'DEFAULT_PROFILE_AASDI_LOADING',
		payload: true
	});
	const locationBody = {
		clientId: data.clientId,
		aasdiId: data.aasdiId,
		name: data.locationName,
		storeNumber: data.storeNumber,
		address: data.address,
		address2: data.address2,
		city: data.city,
		countryId: data.countryId,
		state: data.state,
		zip: data.zip,
		siteContactId: data.siteContactId,
		billingContactId: data.billingContactId
	};
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations`;

	try {
		const call = await customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(locationBody)
		});
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		// API Call Worked, show Location on Frontend
		dispatch({
			type: 'ADD_AASDI_LOCATION_ROWDATA_GRID',
			payload: {
				data: validatePayload(response)
			}
		});

		// Hide Loader
		dispatch({
			type: 'DEFAULT_PROFILE_AASDI_LOADING',
			payload: false
		});
	} catch (err) {
		// Hide Loader
		dispatch({
			type: 'DEFAULT_PROFILE_AASDI_LOADING',
			payload: false
		});

		console.log(err);
		alert('Something went wrong');
	}
};

export const updateLocationPhone = (data, rowIndex) => (dispatch) => {
	// start Loader
	dispatch({
		type: 'DEFAULT_PROFILE_AASDI_LOADING',
		payload: true
	});

	dispatch({
		type: 'UPDATE_AASDI_LOCATION_PHONE',
		payload: {data, index: rowIndex}
	});

	// Hide Loader
	dispatch({
		type: 'DEFAULT_PROFILE_AASDI_LOADING',
		payload: false
	});
};

export const getLocationWorks = (locationId) => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?locationId=${locationId}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'SET_AASDI_LOCATION_WORK',
			payload: {data: validatePayload(response)}
		});
	} catch (err) {
		dispatch({
			type: 'CLEAR_AASDI_LOCATION_WORK',
			payload: {data: null}
		});

		console.log(err);
		alert('Something went wrong');
	}
};

export const clearLocationWorks = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_AASDI_LOCATION_WORK',
		payload: {data: null}
	});
};

export const toggleConfirmDeactivateLocationModal = (status) => {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_TOGGLE_DEACTIVE_LOCATION_MODAL',
			payload: status
		});
	};
};

export const clearLocationToDeactivate = () => {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_CLEAR_LOCATION_TO_DEACTIVATE',
			payload: null
		});
	};
};

export const setLocationToDeactivate = (rowIndex, data) => {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_SET_LOCATION_TO_DEACTIVATE',
			payload: {rowIndex, data}
		});
	};
};

export const contactAdded = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_VIEW_ADDED_CONTACT',
			payload: validatePayload(data)
		});
	};
};

export const submitRating = (aasdiId, payload) => async (dispatch) => {
	// start Loader
	dispatch({
		type: 'DEFAULT_PROFILE_AASDI_LOADING',
		payload: true
	});

	const ratingBody = {
		workId: payload.workNumber,
		aasdiId: aasdiId,
		poAmount: payload.purchaseOrderAmount,
		technicianFirstName: payload.technicianFirstName,
		comments: payload.comments,
		acceptBudget: payload.ratings[0],
		communication: payload.ratings[1],
		meetSchedule: payload.ratings[2],
		integrity: payload.ratings[3],
		customerSatisfaction: payload.ratings[4],
		attitude: payload.ratings[5],
		responsiveness: payload.ratings[6],
		documentation: payload.ratings[7]
	};

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/${aasdiId}/rating`;

	try {
		const call = await customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(ratingBody)
		});
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		// API Call Worked, show rating on Frontend
		dispatch({
			type: 'ADD_AASDI_RATING_ROWDATA_GRID',
			payload: {
				data: validatePayload(response)
			}
		});

		// Hide Loader
		dispatch({
			type: 'DEFAULT_PROFILE_AASDI_LOADING',
			payload: false
		});
	} catch (err) {
		// Hide Loader
		dispatch({
			type: 'DEFAULT_PROFILE_AASDI_LOADING',
			payload: false
		});

		console.log(err);
		alert('Something went wrong');
	}
};

export const getWorkListByAasdiForRating = (id) => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?aasdiId=${id}`;
	try {
		dispatch({
			type: 'LOADING_WORKS_RATING_MODAL',
			payload: true
		});

		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'UPDATE_AASDI_WORKS_OPTION_FOR_RATING',
			payload: {data: validatePayload(response)}
		});
	} catch (err) {
		alert('Something went wrong');
		console.log(err);
	}

	dispatch({
		type: 'LOADING_WORKS_RATING_MODAL',
		payload: false
	});
};

export const updateAasdiCertifications = (oldCerts, newCerts, aasdiId) => async (dispatch) => {
	let certBody = {
		addArray: [],
		removeArray: []
	};

	dispatch({
		type: 'AASDI_CERTIFICATIONS_LOADING',
		payload: {value: true}
	});

	// finds certs to add
	newCerts.forEach((newCertId) => {
		if (oldCerts.indexOf(newCertId) !== -1) {
			return;
		} else certBody.addArray.push(newCertId);
	});

	// finds certs to remove
	oldCerts.forEach((oldCertId) => {
		if (newCerts.indexOf(oldCertId) !== -1) {
			return;
		} else {
			certBody.removeArray.push(oldCertId);
		}
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/${aasdiId}?mode=certification`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(certBody)
		});

		if (call.status !== 200) {
			throw call.status;
		}

		dispatch({
			type: 'AASDI_CERTIFICATIONS',
			payload: {certs: newCerts, orgCerts: newCerts}
		});
		dispatch({
			type: 'AASDI_CERTIFICATIONS_LOADING',
			payload: {value: false}
		});
	} catch (err) {
		console.log(`error: ${err}`);
		dispatch({
			type: 'AASDI_CERTIFICATIONS_LOADING',
			payload: {value: false}
		});
		dispatch({
			type: 'AASDI_CERTIFICATIONS_ERROR',
			payload: {value: true}
		});
	}
};

export const updateAasdiWP = (oldWorkPerformed, workPerformed, aasdiId) => async (dispatch) => {
	let wpBody = {
		addArray: [],
		removeArray: []
	};

	dispatch({
		type: 'AASDI_WORK_PERFORMED_LOADING',
		payload: {value: true}
	});

	// finds certs to add
	workPerformed.forEach((newCertId) => {
		if (oldWorkPerformed.indexOf(newCertId) !== -1) {
			return;
		} else wpBody.addArray.push(newCertId);
	});

	// finds certs to remove
	oldWorkPerformed.forEach((oldCertId) => {
		if (workPerformed.indexOf(oldCertId) !== -1) {
			return;
		} else wpBody.removeArray.push(oldCertId);
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/${aasdiId}?mode=workperformed`;

	try {
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(wpBody)
		});

		if (call.status !== 200) {
			throw call.status;
		}

		dispatch({
			type: 'AASDI_WORK_PERFORMED',
			payload: {wp: workPerformed, orgWp: workPerformed}
		});

		dispatch({
			type: 'AASDI_WORK_PERFORMED_LOADING',
			payload: {value: false}
		});
	} catch (err) {
		console.log(`error: ${err}`);
		dispatch({
			type: 'AASDI_WORK_PERFORMED_LOADING',
			payload: {value: false}
		});
		dispatch({
			type: 'AASDI_WORK_PERFORMED_ERROR',
			payload: {value: true}
		});
	}
};

export const getCertifications = (aasdiId) => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi/${aasdiId}`;

	dispatch({
		type: 'AASDI_CLEAR_CERTIFICATIONS'
	});

	dispatch({
		type: 'AASDI_WORK_PERFORMED_LOADING',
		payload: {value: true}
	});

	dispatch({
		type: 'AASDI_CERTIFICATIONS_LOADING',
		payload: {value: true}
	});

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		// adds parentId to cert
		if (response[0].certs && response[0].certs.length >= 1) {
			response[0].certs.forEach((cert) => {
				cert.parent = catMap[catMap.findIndex((map) => cert.category == map.id)].parentId;
			});
		}

		let certs = [];
		if (response[0].certs && response[0].certs.length >= 1) {
			response[0].certs.forEach((cert) => {
				certs.push(parseInt(cert.id));
			});
		}

		let workPerformed = [];

		if (response[0].workPerformed && response[0].workPerformed.length >= 1) {
			response[0].workPerformed.forEach((wp) => {
				workPerformed.push(parseInt(wp.id));
			});
		}

		dispatch({
			type: 'AASDI_CERTIFICATIONS',
			payload: {certs: certs, orgCerts: certs}
		});

		dispatch({
			type: 'AASDI_WORK_PERFORMED',
			payload: {wp: workPerformed, orgWp: workPerformed}
		});

		dispatch({
			type: 'AASDI_WORK_PERFORMED_LOADING',
			payload: {value: false}
		});
		dispatch({
			type: 'AASDI_CERTIFICATIONS_LOADING',
			payload: {value: false}
		});
	} catch (err) {
		dispatch({
			type: 'AASDI_CERTIFICATIONS_LOADING',
			payload: {value: false}
		});
		dispatch({
			type: 'AASDI_WORK_PERFORMED_LOADING',
			payload: {value: false}
		});
		dispatch({
			type: 'AASDI_CERTIFICATIONS_ERROR',
			payload: {value: true}
		});
		dispatch({
			type: 'AASDI_WORK_PERFORMED_ERROR',
			payload: {value: true}
		});
		console.log(err);
	}
};

export const addCert = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'ADD_AASDI_CERTIFICATIONS',
			payload: {value}
		});
	};
};

export const addWP = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'ADD_AASDI_WP',
			payload: {value}
		});
	};
};

export const closeError = () => {
	return (dispatch) => {
		dispatch({
			type: 'AASDI_CERTIFICATIONS_ERROR',
			payload: {value: false}
		});
		dispatch({
			type: 'AASDI_WORK_PERFORMED_ERROR',
			payload: {value: false}
		});
	};
};
