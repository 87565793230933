import React from 'react';
import ReactChartkick, {PieChart, BarChart, LineChart, ColumnChart, AreaChart} from 'react-chartkick';
import Chart from 'chart.js';

ReactChartkick.addAdapter(Chart);

let Charts = ({type, width, height, data}) => {
	const libraryOptions = {
		animation: {
			animateRotate: false
		}
	};

	switch (type) {
		case 'bar':
			return <BarChart data={data} width={width} height={height} legend="bottom" library={libraryOptions} />;
		case 'pie':
			return <PieChart data={data} width={width} height={height} legend="bottom" library={libraryOptions} />;
		case 'line':
			return <LineChart data={data} width={width} height={height} legend="bottom" library={libraryOptions} />;
		case 'column':
			return <ColumnChart data={data} width={width} height={height} legend="bottom" library={libraryOptions} />;
		case 'area':
			return <AreaChart data={data} width={width} height={height} legend="bottom" library={libraryOptions} />;
		default:
			return <BarChart data={data} width={width} height={height} legend="bottom" library={libraryOptions} />;
	}
};

export default Charts;
