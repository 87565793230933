// Modules
import React from 'react';
import {Label, Segment, Grid, Dropdown, Input, TextArea, Icon, Button, Modal, Rating} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {toggleCreateRatingModal} from './services/globalActions.js';
import RatingStarsField from '../../scenes/work/scenes/aasdi/profile/components/RatingStarsField.js';
import {
	submitRating,
	getWorkListByAasdiForRating
} from '../../scenes/work/scenes/aasdi/profile/services/profileAasdiGridActions.js';

class CreateRatingModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ratings: [0, 0, 0, 0, 0, 0, 0, 0],
			ratingTitles: [
				'Willingness To Accept Budget',
				'Communication',
				'Meet Or Exceed Schedule',
				'Integrity',
				'Customer Satisfaction',
				'Attitude',
				'Responsiveness',
				'Timelines of Closeout Documents'
			],
			workNumber: null,
			purchaseOrderAmount: null,
			technicianFirstName: '',
			comments: '',
			clientInfo: {
				client_Name: '',
				location: ''
			}
		};
		this.updateRating = this.updateRating.bind(this);
		this.evaluateForm = this.evaluateForm.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}

	componentDidMount() {
		if (!this.props.fromWorkOrder) {
			const aasdiId = this.props.id;
			this.props.getWorkListByAasdiForRating(aasdiId);
		}
	}

	componentDidUpdate() {
		if (this.props.fromWorkOrder && this.state.workNumber !== this.props.workOrder.id) {
			this.selectWorkOrder();
		}
	}

	closeModal() {
		this.props.toggleCreateRatingModal(false);
	}

	updateRating(index, rating) {
		let {ratings} = this.state;
		ratings[index] = rating;
		this.setState({...this.state, ratings});
	}

	clearModal() {
		// resets state for new aasdi rating
		this.setState({
			ratings: [0, 0, 0, 0, 0, 0, 0, 0],
			purchaseOrderAmount: '',
			technicianFirstName: '',
			comments: ''
		});
	}

	submitForm() {
		let aasdiId = this.props.id;
		if (!this.props.fromWorkOrder) {
			// aasdi rating page
			aasdiId = this.props.id;
			this.props.submitRating(aasdiId, this.state);
			this.closeModal();
			this.clearModal();
		} else {
			// work order rating page
			aasdiId = this.props.unratedAasdis.aasdis[0].aasdiId;
			this.props.submitRating(aasdiId, this.state);
			this.clearModal();
			this.props.cycleUnratedAasdis();

			if (this.props.unratedAasdis.aasdis.length === 0) {
				// only runs on last aasdi
				this.closeModal();
			}
		}
	}

	evaluateForm() {
		const {workNumber, purchaseOrderAmount, technicianFirstName, comments} = this.state;
		if (!workNumber || !purchaseOrderAmount || !technicianFirstName || !comments) {
			return true;
		}
		return false;
	}

	generateClientInfo(selectedWO) {
		let clientInfo = {
			client_Name: selectedWO.client_Name,
			location: {
				name: selectedWO.location_Name,
				number: selectedWO.location_StoreNumber,
				address: selectedWO.location_Address,
				address2: selectedWO.location_Address2,
				city: selectedWO.location_City,
				state: selectedWO.location_State,
				zip: selectedWO.location_Zip
			}
		};

		let locationInfo = '';
		Object.keys(clientInfo.location).forEach(function (key) {
			if (clientInfo.location[key]) {
				if (key === 'number') {
					locationInfo += `#${clientInfo.location[key]}, `;
				} else {
					locationInfo += `${clientInfo.location[key]}, `;
				}
			}
		});
		clientInfo.location = locationInfo.slice(0, -2);

		return clientInfo;
	}

	selectWorkOrder(value) {
		let selectedWO = this.props.fromWorkOrder
			? // passed in from work order
			  this.props.workOrder
			: // select from work options from aasdi page
			  this.props.workOptions[this.props.workOptions.findIndex((WO) => WO.id === value)];

		let clientInfo = this.generateClientInfo(selectedWO);
		this.setState({
			...this.state,
			workNumber: selectedWO.id,
			clientInfo
		});
	}

	render() {
		const isLoading = this.props.submitLoading;
		const {workOptions, workOptionsLoading} = this.props;
		return (
			<Modal size="large" open={this.props.isOpen}>
				<Modal.Header>
					<Grid className="templateDraftSection">
						<Grid.Row>
							<Grid.Column width={16} style={{margin: 'auto 0', display: 'flex'}}>
								<Icon name="plus" color="green" />
								<div className="content">
									Add New Rating
									{this.props.fromWorkOrder &&
										`: ${
											this.props.unratedAasdis.aasdis[0] &&
											this.props.unratedAasdis.aasdis[0].name
										} `}
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Modal.Header>
				<Modal.Content>
					<Segment
						basic
						style={{
							margin: '1.5rem',
							border: '1px solid #DEDEDE'
						}}
					>
						<Label color="blue" ribbon>
							Info
						</Label>
						<Grid>
							<Grid.Row style={{paddingBottom: 0}}>
								<Grid.Column width={4}>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										Client
									</Label>
									<p style={{margin: 0}}>{this.state.clientInfo.client_Name}</p>
								</Grid.Column>
								<Grid.Column width={12}>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										Location
									</Label>
									<p style={{margin: 0}}>{this.state.clientInfo.location}</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row style={{padding: 0}}>
								<Grid.Column width={4}>
									<div
										style={{
											paddingTop: '1.5em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Work Number
										</Label>
										{!this.props.fromWorkOrder ? (
											<Dropdown
												placeholder="Work Number"
												loading={workOptionsLoading}
												fluid
												search
												selection
												options={
													workOptions
														? workOptions.map((work) => ({
																key: work.id,
																text: work.workNumber,
																value: work.id
														  }))
														: {
																key: 0,
																value: 0,
																text: 'No work found'
														  }
												}
												onChange={(e, {value}) => this.selectWorkOrder(value)}
											/>
										) : (
											<p>{this.props.workOrder.workNumber}</p>
										)}
									</div>
								</Grid.Column>
								<Grid.Column width={6}>
									<div
										style={{
											paddingTop: '1.5em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Purchase Order Amount
										</Label>
										<Input
											fluid
											value={this.state.purchaseOrderAmount}
											onChange={(e) =>
												this.setState({
													...this.state,
													purchaseOrderAmount: e.target.value
												})
											}
										>
											<input className="no-spinners" type="number" />
										</Input>
									</div>
								</Grid.Column>
								<Grid.Column width={6}>
									<div
										style={{
											paddingTop: '1.5em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Technician First Name
										</Label>
										<Input
											fluid
											type="text"
											value={this.state.technicianFirstName}
											onChange={(e) =>
												this.setState({
													...this.state,
													technicianFirstName: e.target.value
												})
											}
										/>
									</div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16}>
									<div
										style={{
											paddingTop: '0.4em',
											zIndex: '10'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											Comments
										</Label>
										<DebounceInput
											className="createRatingTextArea"
											element={TextArea}
											debounceTimeout={600}
											style={{
												width: '100%'
											}}
											value={this.state.comments}
											onChange={(e) =>
												this.setState({
													...this.state,
													comments: e.target.value
												})
											}
										/>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
					<Segment
						basic
						style={{
							margin: '1.5rem',
							border: '1px solid #DEDEDE'
						}}
					>
						<Label color="blue" ribbon>
							Performance Evaluation
						</Label>
						<Grid columns="equal">
							{this.state.ratingTitles.map((title, index) => {
								if (index === 0 || index % 2 === 0) {
									return (
										<Grid.Row style={index === 0 ? {paddingTop: '2.5em'} : {paddingTop: 0}}>
											<Grid.Column>
												<Label
													style={{
														backgroundColor: 'transparent',
														fontSize: '1rem',
														color: 'rgba(0,0,0,.8)',
														paddingLeft: '0'
													}}
												>
													{title}
												</Label>
												<br />
												<Rating
													icon="star"
													maxRating={5}
													clearable
													rating={this.state.ratings[index]}
													onRate={(e, data) => this.updateRating(index, data.rating)}
												/>
											</Grid.Column>
											<Grid.Column>
												<Label
													style={{
														backgroundColor: 'transparent',
														fontSize: '1rem',
														color: 'rgba(0,0,0,.8)',
														paddingLeft: '0'
													}}
												>
													{this.state.ratingTitles[index + 1]}
												</Label>
												<br />
												<Rating
													icon="star"
													maxRating={5}
													clearable
													rating={this.state.ratings[index + 1]}
													onRate={(e, data) => this.updateRating(index + 1, data.rating)}
												/>
											</Grid.Column>
										</Grid.Row>
									);
								} else {
									return '';
								}
							})}
						</Grid>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						{
							// shows number of remaining aasdis while cycling through from work order page
							this.props.fromWorkOrder && (
								<h3 style={{margin: 'auto', paddingRight: '1em'}}>
									AASDIs remaining {this.props.unratedAasdis.aasdis.length}
								</h3>
							)
						}
						<Button
							color="red"
							onClick={() => {
								this.closeModal(false);
								if (this.props.fromWorkOrder) {
									this.props.resetAasdiRatingModalOpen();
								}
							}}
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button color="green" disabled={this.evaluateForm()} onClick={() => this.submitForm()}>
							{isLoading ? <Icon name="spinner" loading={true} /> : <Icon name="checkmark" />}
							Add Ratings
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

CreateRatingModal.propTypes = {
	isOpen: PropTypes.bool,
	id: PropTypes.number,
	toggleCreateRatingModal: PropTypes.func,
	workOptionsLoading: PropTypes.bool.isRequired,
	submitLoading: PropTypes.bool.isRequired,
	workOptions: PropTypes.array,
	getWorkListByAasdiForRating: PropTypes.func.isRequired,
	submitRating: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	isOpen: state.global.global.showCreateRatingModal,
	submitLoading: state.work.aasdiProfileGrid.loading,
	workOptionsLoading: state.work.aasdiProfileGrid.worksForRating.loading,
	workOptions: state.work.aasdiProfileGrid.worksForRating.works
});

const mapDispatchToProps = (dispatch) => ({
	toggleCreateRatingModal: (status) => dispatch(toggleCreateRatingModal(status)),
	submitRating: (aasdiId, body) => dispatch(submitRating(aasdiId, body)),
	getWorkListByAasdiForRating: (aasdiId) => dispatch(getWorkListByAasdiForRating(aasdiId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRatingModal);
