import React from 'react';
import {connect} from 'react-redux';
import {Segment, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import InvoicesGrid from './components/invoiceGrid/invoicesGrid.js';
import BillingSOWContact from './components/billingSOW&Contact.js';
import InvoiceDetailsGrid from './components/invoicesDetailsGrid.js';
import AddBillButton from './components/addBillButton.js';
import BillModal from './components/billModal/billModal.js';
import ActionBillModal from './components/components/actionBillModal.js';
import * as actions from '../../../services/workRecordActions.js';

class BillingCenterWO extends React.Component {
	componentDidMount() {
		this.props.getSKUs(this.props.clientId);
		this.props.getOriginalEstimates();
	}

	render() {
		return (
			<Segment basic style={{marginTop: '0', paddingTop: 0}}>
				<Segment style={{padding: 0}} loading={this.props.invoiceLoading}>
					<Label
						attached="top"
						icon="search"
						color={this.props.isDesynced ? 'yellow' : 'blue'}
						style={{position: 'relative', padding: 0}}
					>
						<div
							style={{
								display: 'inline-block',
								padding: '1.2em'
							}}
						>
							{this.props.isDesynced
								? `Invoices: You can't create or update bills until Spectrum is updated`
								: 'Invoices'}
						</div>
						<AddBillButton />
					</Label>
					<InvoicesGrid />
				</Segment>
				<Segment style={{padding: 0, marginTop: '1.5rem'}}>
					<Label attached="top" icon="search" color="blue" style={{position: 'relative'}}>
						{'Invoice Details'}
					</Label>
					<InvoiceDetailsGrid />
				</Segment>
				<Segment style={{padding: 0, marginTop: '1.5rem'}}>
					<Label attached="top" icon="search" color="blue" style={{position: 'relative'}}>
						{'Billing SOW & Contact'}
					</Label>
					<BillingSOWContact />
				</Segment>
				<BillModal />
				<ActionBillModal />
			</Segment>
		);
	}
}

BillingCenterWO.propTypes = {
	workId: PropTypes.number,
	invoiceLoading: PropTypes.bool,
	isDesynced: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		workId: state.work.workRecord.view.record.id,
		invoiceLoading: state.work.workRecord.view.BillingCenter.invoiceLoading,
		isDesynced: state.work.workRecord.view.record.desynced,
		clientId: state.work.workRecord.view.record.clientId
	};
};

export default connect(mapStateToProps, actions)(BillingCenterWO);
