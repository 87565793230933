import React from 'react';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';
import {Button, Modal, Icon, Header, TextArea} from 'semantic-ui-react';
import {connect} from 'react-redux';
import api from '../../../../../../../../../lib/api.ts';
import * as actions from '../../../services/workRecordActions.js';

class ClosureModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			comments: '',
			commentLengthError: false
		};
	}

	handleSubmit = () => {
		const {id, toggleClosureModal, updateProgressBar, stage} = this.props;
		const closureComments = this.state.comments;

		const status = stage;
		const endpoint = `legacy/work/${id}`;

		this.setState({loading: true});
		api(endpoint, 'put', {status, closureComments})
			.then(() => {
				this.setState({loading: false});
				toggleClosureModal();
				updateProgressBar(status);
			})
			.catch((error) => {
				this.setState({loading: false});
				console.log(error);
				toggleClosureModal();
			});
	};

	commentLengthCounter() {
		let length = this.state.comments.length;
		let style = {
			float: 'right',
			marginRight: '1em'
		};
		if (length > 6000 && length <= 6500) {
			style.color = 'goldenrod';
		} else if (length > 6500) {
			style.color = 'red';
		} else {
			style.color = 'dimGrey';
		}
		return <p style={style}>{`${this.state.comments.length}/6500`}</p>;
	}

	render() {
		const {loading} = this.state;
		const {open, toggleClosureModal} = this.props;
		const warranty = this.props.stage === 'Request to CBW';
		return (
			<Modal open={open}>
				<Header>
					<h3>Close Job {warranty && 'by Warranty'}</h3>
				</Header>
				<Modal.Content>
					<div>
						<p>Are you sure you would like to close this job{warranty && ' by warranty'}?</p>
						<DebounceInput
							element={TextArea}
							className="scrollingTextArea"
							style={{marginTop: '1rem', minHeight: '10rem'}}
							onChange={(e) => {
								let lengthError = false;
								if (e.target.value.length > 6500) {
									lengthError = true;
								}
								this.setState({comments: e.target.value, commentLengthError: lengthError});
							}}
							debounceTimeout={600}
						/>
						{this.commentLengthCounter()}
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							disabled={loading}
							onClick={() => {
								toggleClosureModal();
							}}
							color="red"
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							loading={loading}
							disabled={loading || this.state.commentLengthError}
							onClick={this.handleSubmit}
							color="green"
						>
							<Icon name="checkmark" />
							Yes
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

ClosureModal.propTypes = {
	id: PropTypes.string,
	open: PropTypes.bool,
	toggleClosureModal: PropTypes.func,
	updateProgressBar: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sendToClosure: state.global.sendToModal,
		open: state.work.workRecord.closureModal.toggleClosureModal,
		id: state.work.workRecord.view.id,
		stage: state.work.workRecord.closureModal.stage
	};
};

export default connect(mapStateToProps, actions)(ClosureModal);
