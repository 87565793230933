import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {Card, Icon, Label, Dimmer, Loader} from 'semantic-ui-react';
import * as actions from '../../services/accountingConsoleActions.js';

class acHomeScreen extends React.Component {
	componentDidMount() {
		this.props.setCategories();
		this.props.getAccountingConsole();
	}

	render() {
		return (
			<div
				style={{
					marginLeft: '.25rem'
				}}
			>
				<Dimmer.Dimmable
					as={Card.Group}
					dimmed={true}
					style={{
						marginTop: '4rem',
						width: '100%',
						textAlign: 'center'
					}}
				>
					{this.props.category[0]
						? this.props.category.map((item, index) => {
								return (
									<Card
										key={index}
										style={{
											width: '26vw',
											height: '40vh',
											margin: '.5% .5%'
										}}
										as={NavLink}
										to={`/accountingConsole/category/${item.name}`}
										onClick={() => {
											this.props.setSelectedCategory(item.name);
										}}
									>
										<Icon
											className={item.icon}
											color={item.color}
											size="massive"
											style={{
												height: '100%',
												width: '100%',
												margin: '6vh 0 1vh 0',
												paddingTop: '1vh'
											}}
										/>
										<Card.Content>
											<Card.Header
												style={
													{
														// padding: '.5rem'
													}
												}
											>
												{item.name}
											</Card.Header>
										</Card.Content>
										<Card.Content
											extra
											style={{
												paddingTop: '1vh',
												paddingBottom: '5vh'
											}}
										>
											<Card.Description>
												<Label
													circular
													size="big"
													style={{
														marginBottom: '1vh'
													}}
												>
													{item.count}
												</Label>
												<br />
												{item.description}
											</Card.Description>
										</Card.Content>
									</Card>
								);
						  })
						: null}
					<Dimmer active={this.props.loading} inverted>
						<Loader>Loading</Loader>
					</Dimmer>
				</Dimmer.Dimmable>
			</div>
		);
	}
}

acHomeScreen.propTypes = {
	category: PropTypes.array,
	setSelectedCategory: PropTypes.func,
	getAccountingConsole: PropTypes.func,
	loading: PropTypes.bool,
	setCategories: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		category: state.accountingConsoleReducer.accountingConsole.category,
		loading: state.accountingConsoleReducer.accountingConsole.loading
	};
};

export default connect(mapStateToProps, actions)(acHomeScreen);
