// Modules
import React, {Component} from 'react';
import {Button, Modal, Icon, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import ProfileDetails from '../../../../../../../../../components/Global/ProfileDetails.js';

import * as actions from '../../../services/workRecordActions.js';

class DetailAASDIModal extends Component {
	updateName() {
		return null;
	}
	render() {
		return (
			<Modal size="fullscreen" open={this.props.modal}>
				<Header>
					<div className="content">
						<span style={{fontWeight: 100}}>Details for AASDI:</span> {this.props.name}
					</div>
				</Header>
				<Modal.Content
					style={{
						marginTop: '0'
					}}
				>
					<div>
						<ProfileDetails id={this.props.id} type="aasdi" updateName={this.updateName} />
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							color="red"
							onClick={() => {
								this.props.toggleDetailAASDIModal(false);
							}}
						>
							<Icon name="remove" /> Close
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

DetailAASDIModal.propTypes = {
	toggleDetailAASDIModal: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		modal: state.work.workRecord.toggleDetailModal
		// workId: state.work.workRecord.view.id,
		// aasdiArray: state.work.workRecord.view.manageAASDI.aasdis
	};
};

export default connect(mapStateToProps, actions)(DetailAASDIModal);
