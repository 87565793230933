import React from 'react';
import { Popup, Label, Icon } from 'semantic-ui-react';
import {ICellRendererParams} from "@ag-grid-community/core"
import BillingDetailsGrid from '../../billingDetailsGrid/billingDetailsGrid';

const BillingBreakdownCellRenderer = (props: ICellRendererParams) => {
    return (
        <>{props.data.billing && props.data.billing.length > 0 ?
            <Popup
                trigger={
                    <div
                        style={{
                            width: '35%',
                            textAlign: 'left',
                            paddingLeft: '.8em'
                        }}
                    >
                        <Icon name="money bill alternate outline" style={{ paddingRight: '1.5em' }} />
                    </div>
                }
                on={['hover', 'click']}
                flowing
                hoverable
                position="left center"
            >
                <BillingDetailsGrid
                    rowData={props.data.billing}
                    slim={true}
                />
            </Popup>
        : <></>}
        </>
    )
}


export default BillingBreakdownCellRenderer;