import update from 'react-addons-update';
import countryOptions from '../../../../constants/countries.js';
import stateOptions from '../../../../constants/states.js';

export default (
	state = {
		isRegistrationModalOpen: false,
		registrationForm: {
			loading: null,
			fields: {
				employeeFirstName: {
					name: 'First Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 0,
					id: 'employeeFirstName'
				},
				employeeLastName: {
					name: 'Last Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 0,
					id: 'employeeLastName'
				},
				employeeEmail: {
					name: 'Email',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 0,
					id: 'employeeEmail'
				},
				employeeTitle: {
					name: 'Title',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 0,
					id: 'employeeTitle'
				},
				employeePhone: {
					name: 'Phone',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 0,
					id: 'employeePhone'
				},
				companyName: {
					name: 'Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyName'
				},
				companyAddress: {
					name: 'Address',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyAddress'
				},
				companyAddress2: {
					name: 'Address 2',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 1,
					id: 'companyAddress2'
				},
				companyCity: {
					name: 'City',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyCity'
				},
				companyState: {
					name: 'State',
					value: null,
					placeholder: 'Choose state',
					options: stateOptions,
					error: false,
					disabled: false,
					upward: false,
					required: true,
					stepIndex: 1,
					id: 'companyState'
				},
				companyZipCode: {
					name: 'Zip Code',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyZipCode'
				},
				companyCountry: {
					name: 'Country',
					value: 218,
					placeholder: 'Choose country',
					options: countryOptions,
					error: false,
					disabled: false,
					upward: false,
					required: true,
					stepIndex: 1,
					id: 'companyCountry'
				},
				companyWebsite: {
					name: 'Website',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyWebsite'
				},
				companyFederal: {
					name: 'Federal Tax ID',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyFederal'
				},
				companyYearFounded: {
					name: 'Year Founded',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyYearFounded'
				},
				companyAnnualRevenue: {
					name: 'Annual Revenue',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyAnnualRevenue'
				},
				companyGeographicCoverage: {
					name: 'Geographic Coverage',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyGeographicCoverage'
				},
				companyLicensedMunicipalities: {
					name: 'Licensed Municipalities',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyLicensedMunicipalities'
				},
				companyWarranty: {
					name: 'Warranty',
					value: null,
					placeholder: 'Choose warranty type',
					options: [
						{
							key: 1,
							text: 'None',
							value: 'None'
						},
						{
							key: 2,
							text: '1 Yr',
							value: '1 Yr'
						},
						{
							key: 3,
							text: '2 Yr',
							value: '2 Yr'
						},
						{
							key: 4,
							text: '3 Yr',
							value: '3 Yr'
						},
						{
							key: 5,
							text: '4 Yr',
							value: '4 Yr'
						},
						{
							key: 6,
							text: 'Other',
							value: 'Other'
						}
					],
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyWarranty'
				},
				companyUnion: {
					name: 'Union',
					value: null,
					placeholder: 'Choose union',
					options: [
						{
							key: 1,
							text: 'None',
							value: 'None'
						},
						{
							key: 2,
							text: 'IBEW',
							value: 'IBEW'
						},
						{
							key: 3,
							text: 'CWA',
							value: 'CWA'
						},
						{
							key: 4,
							text: 'Other',
							value: 'Other'
						}
					],
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyUnion'
				},
				companyWorkNonUnionJobs: {
					name: 'Work Non Union Jobs',
					value: null,
					options: [
						{
							key: 1,
							text: 'No',
							value: 'No'
						},
						{
							key: 2,
							text: 'Yes',
							value: 'Yes'
						}
					],
					error: false,
					disabled: false,
					required: true,
					stepIndex: 1,
					id: 'companyWorkNonUnionJobs'
				},
				contactsAutoFillErrorMessage: {
					value: '',
					error: false,
					stepIndex: 2,
					id: 'contactsAutoFillErrorMessage'
				},
				contactsPresidentAutoFill: {
					name: 'I am the president',
					value: 0,
					options: [
						{
							key: 0,
							text: 'No',
							value: 0
						},
						{
							key: 1,
							text: 'Yes',
							value: 1
						}
					],
					error: false,
					disabled: false,
					required: false,
					stepIndex: 2,
					id: 'contactsPresidentAutoFill'
				},
				contactsPresidentFirstName: {
					name: 'First Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 2,
					id: 'contactsPresidentFirstName'
				},
				contactsPresidentLastName: {
					name: 'Last Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 2,
					id: 'contactsPresidentLastName'
				},
				contactsPresidentEmail: {
					name: 'Email',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 2,
					id: 'contactsPresidentEmail'
				},
				contactsPresidentPhone: {
					name: 'Phone',
					value: '',
					error: false,
					disabled: false,
					required: false,
					divider: true,
					stepIndex: 2,
					id: 'contactsPresidentPhone'
				},
				contactsOperationsManagerAutoFill: {
					name: 'I am the operations manager',
					value: 0,
					options: [
						{
							key: 0,
							text: 'No',
							value: 0
						},
						{
							key: 1,
							text: 'Yes',
							value: 1
						}
					],
					error: false,
					disabled: false,
					required: false,
					stepIndex: 2,
					id: 'contactsOperationsManagerAutoFill'
				},
				contactsOperationsManagerFirstName: {
					name: 'First Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 2,
					id: 'contactsOperationsManagerFirstName'
				},
				contactsOperationsManagerLastName: {
					name: 'Last Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 2,
					id: 'contactsOperationsManagerLastName'
				},
				contactsOperationsManagerEmail: {
					name: 'Email',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 2,
					id: 'contactsOperationsManagerEmail'
				},
				contactsOperationsManagerPhone: {
					name: 'Phone',
					value: '',
					error: false,
					disabled: false,
					required: false,
					divider: true,
					stepIndex: 2,
					id: 'contactsOperationsManagerPhone'
				},
				insuranceLiabilityCompanyName: {
					name: 'Company Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					groupLabel: 'Liability Insurance',
					stepIndex: 3,
					id: 'insuranceLiabilityCompanyName'
				},
				insuranceLiabilityPolicyNumber: {
					name: 'Policy Number',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 3,
					id: 'insuranceLiabilityPolicyNumber'
				},
				insuranceLiabilityExpirationDate: {
					name: 'Expiration Date',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 3,
					id: 'insuranceLiabilityExpirationDate'
				},
				insuranceLiabilityBestRating: {
					name: 'Best Rating',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 3,
					id: 'insuranceLiabilityBestRating'
				},
				insuranceWorkersCompensationName: {
					name: 'Company Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 3,
					id: 'insuranceWorkersCompensationName'
				},
				insuranceWorkersCompensationPolicyNumber: {
					name: 'Policy Number',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 3,
					id: 'insuranceWorkersCompensationPolicyNumber'
				},
				insuranceWorkersCompensationExpirationDate: {
					name: 'Expiration Date',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 3,
					id: 'insuranceWorkersCompensationExpirationDate'
				},
				insuranceWorkersCompensationBestRating: {
					name: 'Best Rating',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 3,
					id: 'insuranceWorkersCompensationBestRating'
				},
				insuranceWorkersCompensationModRating: {
					name: 'Mod Rating',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 3,
					id: 'insuranceWorkersCompensationModRating'
				},
				licenseLowVoltageRequired: {
					name: 'License Required in State',
					value: 'Yes',
					options: [
						{
							key: 1,
							text: 'No',
							value: 'No'
						},
						{
							key: 2,
							text: 'Yes',
							value: 'Yes'
						}
					],
					error: false,
					disabled: false,
					required: true,
					stepIndex: 4,
					id: 'licenseLowVoltageRequired'
				},
				licenseLowVoltageIssuingCountry: {
					name: 'Country',
					value: 218,
					placeholder: 'Choose country',
					options: countryOptions,
					error: false,
					disabled: false,
					upward: false,
					required: true,
					stepIndex: 4,
					id: 'licenseLowVoltageIssuingCountry'
				},
				licenseLowVoltageIssuingState: {
					name: 'State',
					value: null,
					placeholder: 'Choose state',
					options: stateOptions,
					error: false,
					disabled: false,
					upward: false,
					required: true,
					stepIndex: 4,
					id: 'licenseLowVoltageIssuingState'
				},
				licenseLowVoltageExpirationDate: {
					name: 'Expiration Date',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 4,
					id: 'licenseLowVoltageExpirationDate'
				},
				licenseElectricalRequired: {
					name: 'License Required in State',
					value: 'Yes',
					options: [
						{
							key: 1,
							text: 'No',
							value: 'No'
						},
						{
							key: 2,
							text: 'Yes',
							value: 'Yes'
						}
					],
					error: false,
					disabled: false,
					required: true,
					stepIndex: 4,
					id: 'licenseElectricalRequired'
				},
				licenseElectricalIssuingCountry: {
					name: 'Country',
					value: 218,
					placeholder: 'Choose country',
					options: countryOptions,
					error: false,
					disabled: false,
					upward: false,
					required: true,
					stepIndex: 4,
					id: 'licenseElectricalIssuingCountry'
				},
				licenseElectricalIssuingState: {
					name: 'State',
					value: null,
					placeholder: 'Choose state',
					options: stateOptions,
					error: false,
					disabled: false,
					upward: false,
					required: true,
					stepIndex: 4,
					id: 'licenseElectricalIssuingState'
				},
				licenseElectricalExpirationDate: {
					name: 'Expiration Date',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 4,
					id: 'licenseElectricalExpirationDate'
				},
				staffingTechnicians: {
					name: 'Technicians',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 5,
					id: 'staffingTechnicians'
				},
				staffingMFRCertTechnicians: {
					name: 'MFR Cert. Technicians',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 5,
					id: 'staffingMFRCertTechnicians'
				},
				staffingRCDD: {
					name: 'RCDDs',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 5,
					id: 'staffingRCDD'
				},
				staffingLeadTechs: {
					name: 'Lead Techs',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 5,
					id: 'staffingLeadTechs'
				},
				staffingProjectManagers: {
					name: 'Project Managers',
					value: '',
					error: false,
					disabled: false,
					required: false,
					stepIndex: 5,
					id: 'staffingProjectManagers'
				},
				bankName: {
					name: 'Bank Name',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 6,
					id: 'bankName'
				},
				bankContactName: {
					name: 'Contact',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 6,
					id: 'bankContactName'
				},
				bankContactPhone: {
					name: 'Phone',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 6,
					id: 'bankContactPhone'
				},
				loginUsername: {
					name: 'Username',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 7,
					id: 'loginUsername'
				},
				loginPassword: {
					name: 'Password',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 7,
					id: 'loginPassword'
				},
				loginPasswordCheck: {
					name: 'Re-enter Password',
					value: '',
					error: false,
					disabled: false,
					required: true,
					stepIndex: 7,
					id: 'loginPasswordCheck'
				},
				loginPasswordCheckErrorMessage: {
					value: '',
					error: false,
					stepIndex: 7,
					id: 'loginPasswordCheckErrorMessage'
				}
			},
			steps: [
				{
					name: 'Employee',
					active: true,
					icon: {name: 'user', color: 'black'}
				},
				{
					name: 'Company',
					active: false,
					icon: {
						name: 'building',
						color: 'black'
					},
					description: 'location description'
				},
				{
					name: 'Contacts',
					description: `Please provide information for your company's contact on atleast one of the options.`,
					active: false,
					activeSplitStep: 0,
					icon: {
						name: 'phone',
						color: 'black'
					}
				},
				{
					name: 'Insurance',
					description: `Please fill in both insurance types.`,
					active: false,
					icon: {
						name: 'umbrella',
						color: 'black'
					}
				},
				{
					name: 'License',
					description: `Please fill in both license types.`,
					active: false,
					icon: {
						name: 'address card',
						color: 'black'
					}
				},
				// {
				// 	name: 'Recent Work',
				// 	description: 'contact description',
				// 	active: false,
				// 	icon: {
				// 		name: 'briefcase',
				// 		color: 'black'
				// 	}
				// },
				{
					name: 'Staffing',
					active: false,
					icon: {
						name: 'users',
						color: 'black'
					}
				},
				{
					name: 'Bank',
					active: false,
					icon: {
						name: 'dollar',
						color: 'black'
					}
				},
				{
					name: 'Create Account',
					description: 'contact description',
					active: false,
					icon: {
						name: 'signup',
						color: 'black'
					}
				}
			]
		}
	},
	action
) => {
	switch (action.type) {
		case 'LOGIN_REGISTRATION_FORM_LOADING':
			state = update(state, {
				registrationForm: {
					loading: {
						$set: action.payload.state
					}
				}
			});
			break;

		case 'LOGIN_REGISTRATION_FORM_UPDATE':
			state = update(state, {
				[action.payload.modal]: {
					steps: {
						[action.payload.step]: {
							icon: {
								color: {
									$set: 'black'
								}
							}
						}
					},
					fields: {
						[action.payload.column]: {
							value: {
								$set: action.payload.value
							},
							error: {
								$set: action.payload.error
							}
						}
					}
				}
			});
			break;

		case 'LOGIN_REGISTRATION_FORM_AUTOFILL':
			action.payload.arrayOfColumns.map(
				(column) =>
					(state = update(state, {
						[action.payload.modal]: {
							fields: {
								[column.columnName]: {
									value: {
										$set: column.value
									},
									error: {
										$set: false
									}
								}
							}
						}
					}))
			);
			break;

		case 'LOGIN_REGISTRATION_FORM_SPLIT_UPDATE':
			state = update(state, {
				[action.payload.modal]: {
					steps: {
						[action.payload.step]: {
							icon: {
								color: {
									$set: 'black'
								}
							},
							fieldSections: {
								[action.payload.fieldSection]: {
									fields: {
										[action.payload.column]: {
											value: {
												$set: action.payload.value
											},
											error: {
												$set: false
											}
										}
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'REGISTER_MODAL_TOGGLE':
			state = update(state, {
				isRegistrationModalOpen: {
					$set: action.payload.isRegistrationModalOpen
				}
			});
			break;

		case 'LOGIN_REGISTRATION_FORM_SET_ACTIVE_STEP':
			state = update(state, {
				registrationForm: {
					steps: {
						[action.payload.currentStep]: {
							active: {
								$set: false
							}
						},
						[action.payload.targetStep]: {
							active: {
								$set: true
							}
						}
					}
				}
			});
			break;

		case 'LOGIN_REGISTRATION_FORM_SET_ACTIVE_SPLIT_STEP':
			state = update(state, {
				registrationForm: {
					steps: {
						[action.payload.stepIndex]: {
							activeSplitStep: {
								$set: action.payload.targetSplitStep
							}
						}
					}
				}
			});
			break;

		case 'LOGIN_REGISTRATION_FORM_FIELD_LOADING':
			state = update(state, {
				registrationForm: {
					steps: {
						[action.payload.step]: {
							fields: {
								[action.payload.column]: {
									loading: {
										$set: true
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'LOGIN_REGISTRATION_CHECK_REQUIRED_FIELDS':
			action.payload.emptyRequiredFields.forEach((field) => {
				state = update(state, {
					registrationForm: {
						steps: {
							[field.stepIndex]: {
								icon: {
									color: {
										$set: 'red'
									}
								}
							}
						},
						fields: {
							[field.id]: {
								error: {
									$set: true
								}
							}
						}
					}
				});
			});
			break;

		case 'LOGIN_REGISTRATION_STEP_COMPLETE':
			state = update(state, {
				registrationForm: {
					steps: {
						[action.payload.stepIndex]: {
							icon: {
								color: {
									$set: 'green'
								}
							}
						}
					}
				}
			});
			break;
	}

	return state;
};
