export function Link(props) {
    const additionalProps = {}
    if (props.newtab) {
        additionalProps.target="_blank"
        additionalProps.rel="noopener noreferrer"
    }
    return <a href={props.href} {...additionalProps}>
        {props.text}
    </a>
}

export default Link