// Modules
import React from 'react';
import {Segment, Grid, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DropDownField from '../components/workOrderDropDownField.js';
import DatePicker from '../components/workOrderDatePicker.js';
import InputField from './components/workOrderOverviewInputField/workOrderOverviewInputField.js';
import CheckboxField from '../components/workOrderCheckbox.js';

const Overview = (props) => (
	<Segment>
		<Label attached="top" color="blue">
			Order Overview
		</Label>
		<Grid>
			<Grid.Row style={{paddingTop: 0, paddingBottom: '2.5em'}}>
				{props.sectionFields.map((field, fieldIndex) => {
					if (field.skipFieldMap) {
						return null;
					} else if (field.date) {
						return (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<DatePicker
									field={field}
									sectionIndex={props.sectionIndex}
									fieldIndex={fieldIndex}
									time={field.time}
								/>
							</Grid.Column>
						);
					} else if (field.toggle) {
						return (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<CheckboxField
									field={field}
									sectionIndex={props.sectionIndex}
									fieldIndex={fieldIndex}
								/>
							</Grid.Column>
						);
					} else if (field.input) {
						return (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<InputField field={field} sectionIndex={props.sectionIndex} fieldIndex={fieldIndex} />
							</Grid.Column>
						);
					} else if (field.serviceTypeField) {
						//this is super jank
						//we need to be using a more specific component here instead of overloading the dropdownfield component
						const serviceTypeOptionsIndex = props.sectionFields.findIndex(
							(sections) => sections.name === 'Service Type Options'
						);
						return (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<DropDownField
									field={field}
									sectionIndex={props.sectionIndex}
									fieldIndex={fieldIndex}
									serviceTypeOptions={props.sectionFields[serviceTypeOptionsIndex]}
									serviceTypeIndex={serviceTypeOptionsIndex}
								/>
							</Grid.Column>
						);
					} else if (true) {
						//need to have a flag for dropdowns, but if its nothing else its a dropdown apparently
						return (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<DropDownField
									field={field}
									sectionIndex={props.sectionIndex}
									fieldIndex={fieldIndex}
								/>
							</Grid.Column>
						);
					} else {
						//placeholder for when above is fixed
						throw new Error('unknown field type');
					}
				})}
			</Grid.Row>
		</Grid>
	</Segment>
);

Overview.propTypes = {
	sectionFields: PropTypes.object,
	sectionIndex: PropTypes.number
};

const mapStateToProps = (state) => {
	const sections = state.work.createWorkOrder.createWorkOrderModal.sections;
	const sectionIndex = sections.findIndex((section) => section.name === 'workOrderOverviewSection');
	return {
		sectionIndex,
		sectionFields: sections[sectionIndex].fields
	};
};
export default connect(mapStateToProps)(Overview);
