// TODOS
// make every case in this reducer use immutability-helper
// make some helper functions to improve readability + make code more DRY => the last case needs it bad

import {cloneDeep} from 'lodash';
import {
	TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS,
	TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_SUCCESS,
	TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_FAILURE,
	TASK_TEMPLATE_UPDATE_TASK_SUCCESS,
	TASK_TEMPLATES_ADD_TASK,
	TASK_TEMPLATES_EDIT_TASK,
	TASK_TEMPLATES_CHANGE_TASK,
	TASK_TEMPLATE_CREATE_TASK,
	TASK_TEMPLATE_PIN_TASK_SUCCESS,
	TASK_TEMPLATE_UNPIN_TASK_SUCCESS,
	TASK_TEMPLATE_DELETE_TASK_SUCCESS,
	TASK_TEMPLATES_UPDATE_TASK_TEMPLATE_ORDER
} from '../TaskTemplates.actions.js';

export const templateTasksInitialState = {
	selectedTemplateTasks: {
		pinned: [],
		general: []
	},
	templateTasksLoading: true,
	errorLoadingTemplateTasks: false,

	// template, section, task
	sidePanelMode: 'template',
	sidePanelTask: null
};

export default function (state, action) {
	switch (action.type) {
		// Tasks for current template
		case TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS:
			return {
				...state,
				sidePanelMode: 'template',
				selectedTemplateTasks: {pinned: [], general: []},
				templateTasksBeingRetrieved: true,
				templateTasksLoading: true
			};

		case TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_SUCCESS:
			const {tasks} = action.payload;
			return {
				...state,
				selectedTemplateTasks: tasks,
				templateTasksBeingRetrieved: false,
				templateTasksLoading: false
			};

		case TASK_TEMPLATES_RETRIEVE_TEMPLATE_TASKS_FAILURE:
			return {
				...state,
				errorLoadingTemplateTasks: true,
				templateTasksBeingRetrieved: false,
				templateTasksLoading: false
			};

		case TASK_TEMPLATE_UPDATE_TASK_SUCCESS:
			const {updatedTask} = action.payload;
			if (updatedTask.pinned) {
				return {
					...state,
					selectedTemplateTasks: {
						...state.selectedTemplateTasks,
						pinned: state.selectedTemplateTasks.pinned.map((task) => {
							if (task.id === updatedTask.id) {
								return updatedTask;
							}
							return task;
						})
					}
				};
			}
			return {
				...state,
				selectedTemplateTasks: {
					...state.selectedTemplateTasks,
					general: state.selectedTemplateTasks.general.map((task) => {
						if (task.id === updatedTask.id) {
							return updatedTask;
						}
						return task;
					})
				}
			};

		// -------------

		// These ADD_TASK and EDIT_TASK are misnomers, as they only display task information in the sidePanel
		case TASK_TEMPLATES_ADD_TASK:
		case TASK_TEMPLATES_EDIT_TASK:
			const {sidePanelTask} = action.payload;
			return {
				...state,
				sidePanelMode: sidePanelTask.isSection ? 'section' : 'task',
				sidePanelTask: sidePanelTask
			};

		case TASK_TEMPLATES_CHANGE_TASK:
			return {
				...state,
				sidePanelTask: {
					...state.sidePanelTask,
					...action.payload
				}
			};

		case TASK_TEMPLATE_CREATE_TASK:
			const {task} = action.payload;
			// if task is a section task, simply appending it to the end of the general array wont work
			// find the section in general matching task.sectionId, and append task to the end of that sections general array
			if (task.sectionId) {
				return {
					...state,
					selectedTemplateTasks: {
						...state.selectedTemplateTasks,
						general: state.selectedTemplateTasks.general.map((generalTask) => {
							if (generalTask.id === task.sectionId) {
								return {
									...generalTask,
									tasks: {
										...generalTask.tasks,
										general: [...generalTask.tasks.general, task]
									}
								};
							}
							return generalTask;
						})
					}
				};
			}
			return {
				...state,
				selectedTemplateTasks: {
					...state.selectedTemplateTasks,
					general: [...state.selectedTemplateTasks.general, task]
				}
			};

		case TASK_TEMPLATE_PIN_TASK_SUCCESS:
			const {pinnedTask} = action.payload;
			if (pinnedTask.sectionId) {
				return {
					...state,
					selectedTemplateTasks: {
						...state.selectedTemplateTasks,
						general: state.selectedTemplateTasks.general.map((task) => {
							if (task.id === pinnedTask.sectionId) {
								return {
									...task,
									tasks: {
										pinned: [...task.tasks.pinned, pinnedTask],
										general: task.tasks.general
											.filter((task) => task.id !== pinnedTask.id)
											.map((task, index) => ({...task, taskOrder: index}))
									}
								};
							}
							return task;
						})
					}
				};
			}
			return {
				...state,
				selectedTemplateTasks: {
					pinned: [...state.selectedTemplateTasks.pinned, pinnedTask],
					general: state.selectedTemplateTasks.general
						.filter((task) => task.id !== pinnedTask.id)
						.map((task, index) => ({...task, taskOrder: index}))
				}
			};

		case TASK_TEMPLATE_UNPIN_TASK_SUCCESS:
			const {unpinnedTask} = action.payload;
			if (unpinnedTask.sectionId) {
				return {
					...state,
					selectedTemplateTasks: {
						...state.selectedTemplateTasks,
						general: state.selectedTemplateTasks.general.map((task) => {
							if (task.id === unpinnedTask.sectionId) {
								return {
									...task,
									tasks: {
										pinned: task.tasks.pinned.filter((task) => task.id !== unpinnedTask.id),
										general: [...task.tasks.general, unpinnedTask]
									}
								};
							}
							return task;
						})
					}
				};
			}
			return {
				...state,
				selectedTemplateTasks: {
					pinned: state.selectedTemplateTasks.pinned.filter((task) => task.id !== unpinnedTask.id),
					general: [
						...state.selectedTemplateTasks.general,
						{...unpinnedTask, taskOrder: state.selectedTemplateTasks.general.length}
					]
				}
			};

		case TASK_TEMPLATES_UPDATE_TASK_TEMPLATE_ORDER:
			if (action.payload.updatedTaskList[0].sectionId) {
				return {
					...state,
					selectedTemplateTasks: {
						...state.selectedTemplateTasks,
						general: state.selectedTemplateTasks.general.map((task) => {
							if (task.id === action.payload.updatedTaskList[0].sectionId) {
								return {
									...task,
									tasks: {
										...task.tasks,
										general: cloneDeep(action.payload.updatedTaskList)
									}
								};
							}
							return task;
						})
					}
				};
			}

			return {
				...state,
				selectedTemplateTasks: {
					...state.selectedTemplateTasks,
					general: cloneDeep(action.payload.updatedTaskList)
				}
			};

		// this code is bad and i should feel bad
		case TASK_TEMPLATE_DELETE_TASK_SUCCESS:
			if (action.payload.task.sectionId) {
				return cloneDeep({
					...state,
					sidePanelMode: 'template',
					selectedTemplateTasks: {
						...state.selectedTemplateTasks,
						general: state.selectedTemplateTasks.general.map((task) => {
							if (task.id === action.payload.task.sectionId) {
								if (action.payload.task.pinned) {
									return {
										...task,
										tasks: {
											...task.tasks,
											pinned: task.tasks.pinned.filter(
												(pinned) => pinned.id !== action.payload.task.id
											)
										}
									};
								} else {
									return {
										...task,
										tasks: {
											...task.tasks,
											general: task.tasks.general.filter(
												(general) => general.id !== action.payload.task.id
											)
										}
									};
								}
							}

							return task;
						})
					}
				});
			} else {
				if (action.payload.task.pinned) {
					return cloneDeep({
						...state,
						sidePanelMode: 'template',
						selectedTemplateTasks: {
							...state.selectedTemplateTasks,

							pinned: state.selectedTemplateTasks.pinned.filter(
								(task) => task.id !== action.payload.task.id
							)
						}
					});
				} else {
					return cloneDeep({
						...state,
						sidePanelMode: 'template',
						selectedTemplateTasks: {
							...state.selectedTemplateTasks,
							general: state.selectedTemplateTasks.general.filter(
								(task) => task.id !== action.payload.task.id
							)
						}
					});
				}
			}

		default:
			return state;
	}
}
