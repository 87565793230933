import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Icon, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';

import * as actions from './services/commitModalActions.js';

const extractMonthNameFromDate = (date) => {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const monthIndex = new Date(date).getMonth();
	return months[monthIndex];
};

const CommitModal = (props) => (
	<Modal open={props.isOpen} size={'small'}>
		<Header>
			<Icon name="history" />
			<div className="content">{props.opportunityData.isCommit ? 'Decommit' : 'Commit'}</div>
		</Header>
		<Modal.Content scrolling={true} style={{paddingBottom: '3rem'}}>
			{props.opportunityData.isCommit
				? `Are you sure you want to decommit this 
        from ${extractMonthNameFromDate(props.opportunityData.projectedOrderDate)}?`
				: `Are you sure you want to commit this 
        for ${extractMonthNameFromDate(props.opportunityData.projectedOrderDate)}?`}
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleCommitModal();
					}}
					color="red"
				>
					<Icon name="remove" /> No
				</Button>
				<Button
					onClick={() => {
						props.setOppCommit(props.opportunityData, !props.opportunityData.isCommit, props.isGrid);
					}}
					color="green"
					disabled={props.isLoading}
				>
					<Icon name={props.isLoading ? 'spinner' : 'checkmark'} loading={props.isLoading} /> Yes
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

CommitModal.propTypes = {
	isOpen: PropTypes.bool,
	opportunityData: PropTypes.object,
	opportunityDataRow: PropTypes.object,
	toggleCommitModal: PropTypes.func,
	setOppCommit: PropTypes.func,
	isLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		opportunityData: state.global.commitModal.opportunityData,
		opportunityDataRow: state.global.commitModal.opportunityDataRow,
		isOpen: state.global.commitModal.isOpen,
		isLoading: state.global.commitModal.isLoading
	};
};

export default connect(mapStateToProps, actions)(CommitModal);
