import {Card, Form} from 'semantic-ui-react';
import React from 'react';
// import PropTypes from 'prop-types';
import CompletedHeader from './PanelHeader_Completed.js';
import EditHeader from './PanelHeader_Edit.js';
import DefaultTaskType from './PanelContent_Default.js';
import ClosePanel from './Panel_Close.js';
import CompleteModal from './Modal_CompleteTask.js';
import PanelButtonGroup from './Panel_ButtonGroup.js';

const SectionPanel = (props) => {
	return [
		!props.tasks || props.tasks === undefined
			? //this has been edited vvvvvvvvvv
			  // ||
			  // props.tasks.aasdis[props.tasks.ui.panel.aasdiIndex].taskGroups[
			  // 	props.tasks.ui.panel.groupIndex
			  // ].tasks[props.tasks.ui.panel.category][props.tasks.ui.panel.index] ===
			  // 	undefined
			  null
			: props.task.completed
			? [
					<ClosePanel key={'closeCompletedPanel'} {...props} />,
					<CompletedHeader key={'completedHeader'} isSection={props.isSection} />
			  ]
			: [
					<ClosePanel key={'closeEditPanel'} {...props} />,
					<EditHeader key={'editHeader'} isSection={props.isSection} id={props.task.id} />
			  ],
		!props.tasks ? null : (
			<Card.Content key={'inputFields'}>
				<Form>
					{(() => {
						if (props.tasks.taskList.some((type) => type.taskName === props.task.name)) {
							return props.tasks.taskList
								.filter((type) => type.taskName === props.task.name)[0]
								.columnList.split(',')
								.map((column) => {
									switch (column) {
										case 'title':
											return <DefaultTaskType {...props} key={column} column={column} />;
										default:
											return null;
									}
								});
						}
					})()}
				</Form>
			</Card.Content>
		),
		!props.tasks ? null : (
			<Card.Content key={'PanelButtonGroup'}>
				<PanelButtonGroup {...props} isEditSection />
			</Card.Content>
		),
		!props.tasks ? null : <CompleteModal key={'PanelCompleteModal'} {...props} />
	];
};

export default SectionPanel;
