import React from 'react';
import PropTypes from 'prop-types';

const AasdiStatusCellRenderer = (props) => {
	const statusId = props.data.statusId;
	let status;
	let color;

	switch (statusId) {
		case 1:
			status = 'Active';
			color = 'black';
			break;
		case 4:
			status = 'Inactive';
			color = 'red';
			break;
		case 5:
			status = 'Not Approved';
			color = 'red';
			break;
		default:
			status = 'Unknown Status';
			color = 'orange';
			break;
	}

	return (
		<div style={{textAlign: 'left'}}>
			<span style={{color: color}}>{status}</span>
		</div>
	);
};

AasdiStatusCellRenderer.propTypes = {
	data: PropTypes.object.isRequired
};

export default AasdiStatusCellRenderer;
