let optionFactory = (props, getLength) => {
	let options = {};

	props = {
		...props,
		category: props.category || 'general',
		isSection: props.isSection || false,
		isSectionTask: props.isSectionTask || false,
		task: props.task ? props.task : {}
	};

	if (!getLength) getLength = null;
	switch (props.mode) {
		case 'templateManager':
			options = {
				aasdiIndex: props.aasdiIndex,
				groupIndex: props.groupIndex,
				category: props.category,
				length: getLength ? props.tasks.currentTemplate.tasks[props.category].length : null
			};
			if (props.isSectionTask) {
				options = {
					...options,
					sectionId: props.task.sectionId ? props.task.sectionId : props.sectionId || null,
					length: getLength
						? props.tasks.currentTemplate.tasks[props.category][props.sectionIndex].tasks[
								props.sectionCategory || 'general'
						  ].length
						: null
				};
			}
			break;

		case 'workOrder':
			options = {
				aasdiIndex: props.aasdiIndex,
				groupIndex: props.groupIndex,
				length: getLength
					? props.tasks.aasdis[props.aasdiIndex].taskGroups[props.groupIndex].tasks[props.category].length
					: null
			};

			if (props.isSectionTask) {
				options = {
					...options,
					sectionId: props.groupId ? props.groupId : props.task.sectionId || null,
					length: getLength
						? props.tasks.aasdis[props.aasdiIndex].taskGroups[props.groupIndex].tasks[props.category][
								props.sectionIndex
						  ].tasks[props.sectionCategory || 'general'].length
						: null
				};
			}
			break;
	}
	options = {
		...options,
		index: props.index,
		isSection: props.isSection || false,
		isSectionTask: props.isSectionTask || false,
		category: props.category || 'general'
	};

	if (props.isSection) {
		options = {
			...options,
			sectionCategory: props.sectionCategory || 'general',
			sectionIndex: typeof props.sectionIndex !== 'undefined' ? props.sectionIndex : null
		};
	}

	return options;
};

export default optionFactory;
