import React from 'react';
import {Menu} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class TabsNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {currentStage: ''};
		this.updateStage = this.updateStage.bind(this);
	}

	componentDidMount() {
		const currentStage = this.props.stageNames[0];
		this.setState({...this.state, currentStage});
	}

	updateStage(currentStage) {
		this.setState({...this.state, currentStage});
	}

	render() {
		const {stageNames, disabled, style, className} = this.props;
		const {currentStage} = this.state;
		return (
			<Menu pointing secondary size="huge" widths={stageNames.length} style={style}>
				{stageNames.map((stage, index) => {
					return (
						<Menu.Item
							disabled={disabled || stage === 'OrderGroups'}
							name={stage}
							key={index}
							className={className}
							active={currentStage === stage}
							color={'blue'}
							onClick={() => {
								this.updateStage(stage);
								this.props.onClick(stage);
							}}
						/>
					);
				})}
			</Menu>
		);
	}
}

TabsNavbar.propTypes = {
	stageNames: PropTypes.array.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	className: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

TabsNavbar.defaultProps = {
	disabled: false,
	style: {margin: '1.5em 1em 2em 1em'},
	className: ''
};

const mapStateToProps = (state) => ({
	disabled: state.global.global.tabsNavbarIsFetching
});

// const mapDispatchToProps = dispatch => ({
//     toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
// });

export default connect(mapStateToProps, null)(TabsNavbar);
