import update from 'react-addons-update';

/**
 * The Pipeline state object
 * @namespace Opportunity
 * @memberof App.Store.Reducers
 * @since 3.0.0
 */
export default (
	state = {
		documents: {
			loading: true,
			fields: [
				{
					name: 'File Name',
					value: '',
					input: true,
					error: false,
					disabled: false,
					required: true,
					checkForm: true,
					columnWidth: 5
				},
				{
					name: 'Google Drive Link',
					value: '',
					input: true,
					error: false,
					disabled: false,
					checkForm: true,
					required: true,
					columnWidth: 5
				},
				{
					name: 'Customer Approval (Purchase Order)',
					value: '',
					input: false,
					dropdown: true,
					error: false,
					disabled: false,
					required: false,
					columnWidth: 5,
					options: [
						{
							key: 'No',
							text: 'No',
							value: false
						},
						{
							key: 'Yes',
							text: 'Yes',
							value: true
						}
					]
				},
				{
					name: 'Comments',
					value: '',
					input: false,
					textArea: true,
					error: false,
					disabled: false,
					required: false,
					columnWidth: 15
				}
			],
			documents: []
		},
		fetched: false,
		error: false,
		loading: true,
		isCommit: false,
		accountExecutiveSearch: {
			options: [],
			loading: false
		},
		clientSearch: {
			options: [],
			loading: false
		},
		tags: [],
		validation: {
			name: false,
			description: false,
			clientId: false,
			accountExecutiveId: false,
			projectedOrderDate: false,
			endDate: false
		}
	},
	action
) => {
	switch (action.type) {
		case 'CRM_UPDATED_CLIENT_PROSPECT_MODAL':
			state = update(state, {
				clientSearch: {
					options: {
						$push: [action.payload.clientOption]
					},
					fetched: {
						$set: true
					},
					loading: {
						$set: false
					},
					clientId: {
						$set: action.payload.clientId
					}
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY':
			state = update(state, {
				$merge: action.payload
			});
			break;

		case 'TOGGLE_LEAD_STATUS':
			state = update(state, {
				isLead: {
					$set: action.payload
				}
			});
			break;

		case 'TOGGLE_DESIGN_CONVERSION':
			state = update(state, {
				isDesignConversion: {
					$set: action.payload
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY_COLUMN':
			state = update(state, {
				[action.payload.column]: {
					$set: action.payload.value
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY_COLUMN_LOADING':
			state = update(state, {
				loading: {
					[action.payload.column]: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CRM_UPDATE_OPPORTUNITY_COLUMN_OPTIONS':
			state = update(state, {
				[action.payload.column]: {
					options: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CRM_SEARCH_FIELD_LOADING':
			state = update(state, {
				[action.payload.column]: {
					loading: {$set: action.payload.value}
				}
			});
			break;

		case 'GET_OPP_DOCUMENTS':
			state = update(state, {
				documents: {
					documents: {
						$set: action.payload
					}
				}
			});
			break;

		case 'OPP_UPDATE_DOCUMENTS_VALUE':
			state = update(state, {
				documents: {
					fields: {
						[action.payload.index]: {
							value: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'OPP_DOCUMENTS_LOADING':
			state = update(state, {
				documents: {
					loading: {
						$set: action.payload
					}
				}
			});
			break;

		case 'OPP_ADD_DOCUMENT':
			state = update(state, {
				documents: {
					documents: {
						$push: [action.payload.object]
					}
				}
			});
			break;

		case 'OPP_REMOVE_DOCUMENT':
			state = update(state, {
				documents: {
					documents: {
						$splice: [[action.payload.index, 1]]
					}
				}
			});
			break;

		case 'OPP_UPDATE_DOCUMENT':
			state = update(state, {
				documents: {
					documents: {
						[action.payload.index]: {
							customerApproval: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'OPP_CLEAR_DOC_FIELDS':
			state = update(state, {
				documents: {
					fields: {
						0: {
							value: {$set: ''}
						},
						1: {
							value: {$set: ''}
						},
						2: {
							value: {$set: ''}
						},
						3: {
							value: {$set: ''}
						}
					}
				}
			});

		case 'CREATE_OPPORTUNITY_ADD_TAG':
			state = update(state, {
				tags: {
					$push: [action.payload.tag]
				}
			});
			break;

		case 'CREATE_OPPORTUNITY_REMOVE_TAG':
			state = update(state, {
				tags: {
					$splice: [[action.payload.removeIndex, 1]]
				}
			});
			break;
	}

	return state;
};
