import {Button, Icon, Modal, Header, Form, Message, Popup} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';

const createUser = (props) => (
	<div>
		<Button
			{...props}
			onClick={() => props.toggleCreateUserModal(props.techManagement.createUser.toggleCreateUserModal)}
			icon
			labelPosition="left"
			primary
			type="Button"
			size="medium"
		>
			<Icon name="add user" /> Add User
		</Button>
		<Modal
			closeIcon
			size="tiny"
			open={props.techManagement.createUser.toggleCreateUserModal}
			onClose={() => props.toggleCreateUserModal(props.techManagement.createUser.toggleCreateUserModal)}
		>
			<Header icon="user" content="Add User" />
			<Modal.Content>
				<Form className="attached fluid segment userAddForm" loading={props.techManagement.createUser.loading}>
					<Form.Group widths="equal">
						<DebounceInput
							element={Form.Input}
							label="Email"
							placeholder="Email"
							type="text"
							value={props.techManagement.createUser.user.Email}
							onChange={(event) => {
								let value = event.target.value.toLowerCase();
								let label = 'email';
								props.updateCreateUserInfo({
									value,
									label
								});
							}}
							debounceTimeout={600}
						/>
						<Popup
							content="Use CTRL+SHIFT+V when pasting"
							position="top left"
							trigger={
								<DebounceInput
									element={Form.Input}
									label="AASDI Code"
									placeholder="ASD"
									type="text"
									value={props.techManagement.createUser.user.AASDI}
									onChange={(event) => {
										let value = event.target.value;
										let label = 'aasdi';
										props.updateCreateUserInfo({
											value,
											label
										});
									}}
									debounceTimeout={600}
								/>
							}
						/>
					</Form.Group>
				</Form>
				<Message attached="bottom" error hidden={props.techManagement.createUser.message.hideCreateUserMessage}>
					<Icon name="help" />
					<span>{props.techManagement.createUser.message.text}</span>
				</Message>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="red"
					onClick={() => props.toggleCreateUserModal(props.techManagement.createUser.toggleCreateUserModal)}
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button color="green" onClick={() => props.createUser()}>
					<Icon name="checkmark" /> Create
				</Button>
			</Modal.Actions>
		</Modal>
	</div>
);

createUser.propTypes = {
	techManagement: PropTypes.shape({
		createUser: PropTypes.shape({
			toggleCreateUserModal: PropTypes.bool,
			loading: PropTypes.bool,
			user: PropTypes.shape({
				Username: PropTypes.string,
				Email: PropTypes.string,
				AASDI: PropTypes.string
			}),
			message: PropTypes.shape({
				hideCreateUserMessage: PropTypes.bool,
				text: PropTypes.string
			})
		})
	}),
	toggleCreateUserModal: PropTypes.func,
	updateCreateUserInfo: PropTypes.func,
	createUser: PropTypes.func
};

export default createUser;
