import React from 'react';
import {Modal, Dimmer, Loader, Message, Header, Icon, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';

// Use to confirm an action should happen, etc.
function ConfirmationModal(props) {
	return (
		<Modal open={props.isModalOpen} size="small">
			<Dimmer inverted active={props.isLoading} className="centerDimmer">
				<Loader active={props.isLoading} />
			</Dimmer>
			<Header>
				<Icon name={props.iconName} color={props.iconColor} />
				{props.headerText}
			</Header>
			<Modal.Content>
				<p>{props.question}</p>
				<Message error hidden={props.isErrorMessageHidden} style={{color: 'black'}}>
					<p>{props.errorMessage}</p>
				</Message>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={props.handleClickCancel} basic>
					Cancel
				</Button>
				<Button onClick={props.handleClickConfirm} basic>
					Yes
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

ConfirmationModal.propTypes = {
	details: PropTypes.string,
	errorMessage: PropTypes.string,
	handleClickCancel: PropTypes.func,
	handleClickConfirm: PropTypes.func,
	headerText: PropTypes.string,
	iconColor: PropTypes.string,
	iconName: PropTypes.string,
	isErrorMessageHidden: PropTypes.bool,
	isLoading: PropTypes.bool,
	isModalOpen: PropTypes.bool,
	question: PropTypes.string
};

export default ConfirmationModal;
