import {useHistory } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {PropsWithChildren, useEffect} from 'react';

type AuthGateProps = PropsWithChildren<{
	redirect: string
}>

export function AuthGate(props: AuthGateProps) {
	const auth = useSelector((state: any) => state.auth);
	const history = useHistory()

	useEffect(() => {
		if(!auth.idToken) {
			history.push('/auth/login')
		}
	}, [auth.idToken])

	if(!auth.idToken) return null

	return props.children
}
