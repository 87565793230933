import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import GridView from './scenes/gridView/GridView.js';
import CardView from './scenes/cardView/CardView.js';
import Analytics from './scenes/analytics/Analytics.js';
import {
	getPipelineOpportunities,
	getStageAnalytics,
	getPipelineAnalytics,
	getChartsData
} from './services/pipelineActions.js';
import './Pipeline.css';

class Pipeline extends React.Component {
	componentDidMount() {
		this.props.getPipelineOpportunities();
		this.props.getStageAnalytics();
		this.props.getPipelineAnalytics();
		this.props.getChartsData();
	}
	render() {
		return [
			<Route key={'GridView'} path={`/crm/dashboard/pipeline/grid-view`} render={() => <GridView />} />,
			<Route key={'CardView'} path={`/crm/dashboard/pipeline/card-view`} render={() => <CardView />} />,
			<Route key={'Analytics'} path={`/crm/dashboard/pipeline/analytics`} render={() => <Analytics />} />
		];
	}
}

Pipeline.propTypes = {
	getPipelineOpportunities: PropTypes.func.isRequired,
	getStageAnalytics: PropTypes.func.isRequired,
	getPipelineAnalytics: PropTypes.func.isRequired,
	getChartsData: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPipelineOpportunities: () => {
			dispatch(getPipelineOpportunities());
		},
		getStageAnalytics: () => {
			dispatch(getStageAnalytics());
		},
		getPipelineAnalytics: () => {
			dispatch(getPipelineAnalytics());
		},
		getChartsData: () => {
			dispatch(getChartsData());
		}
	};
};

export default connect(null, mapDispatchToProps)(Pipeline);
