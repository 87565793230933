export const AasdiDocTypeOptions = [
	{key: 1, value: 1, text: 'COI/WC'},
	{key: 2, value: 2, text: 'Signed Agreement'},
	{key: 3, value: 3, text: 'W9'}
];

export const clientDocTypeOptions = [
	{key: 4, value: 4, text: 'Master Terms & Conditions'},
	{key: 5, value: 5, text: 'New Customer Information'}
];
