/**
 * Global actions are used for managing global components.
 * @memberof App.Actions
 * @namespace tasks
 * @since 2.0.0
 */

import naturalSort from 'object-property-natural-sort';
import {push} from 'redux-first-history';
import {copy} from 'redux-clipboard-copy';
import queryString from 'query-string';
import {
	toggleDiscardChangesModal,
	startLoader,
	endLoader
} from '../../../../../../../../../components/Global/services/globalActions.js';
import {v4 as ipv4Regex} from 'ip-regex';
import tlds from 'tlds';
import { arrayMove } from 'react-sortable-hoc';
// import headers from '../../../../../../../../../services/headers.js';
import _ from 'lodash';
import customFetch from '../../../../../../../../../lib/old/customFetch.js';
import {displayWorkRecordError} from '../../../services/workRecordErrorActions.js';

/////////////
// CREATE //
///////////
export function addTemplateTasks(taskObj, groupName, groupId, options) {
	return (dispatch, getState) => {
		const state = getState();

		//taskObj is an object in state with arrays a level deep so a deep clone is needed to remove
		//reference and keep immutability.
		let taskObjClone = _.cloneDeep(taskObj);
		let newTaskArr = [...taskObjClone.general, ...taskObjClone.pinned];
		let nextIndex = state.work.tasks.aasdis[options.aasdiIndex].taskGroups[options.groupIndex].tasks.general.length;

		let sectionArray = [];
		let nonSectionArray = [];
		//pushing to the correct arrays to get ready for the network calls where sections
		//will be posted first, then all other tasks.
		newTaskArr.forEach((task) => {
			//non pinned tasks are added to the end of the already existing general array
			//pinned tasks don't need a task order.
			if (!task.pinned) {
				task.taskOrder = nextIndex;
				nextIndex++;
			}

			if (task.choices) {
				task.choice = task.choices;
			}

			if (task.isSection) {
				task.id = null;
				sectionArray.push(task);
			} else {
				task.id = null;
				nonSectionArray.push(task);
			}
		});

		if (
			groupId !== undefined &&
			groupName !== undefined &&
			(sectionArray.length > 0 || nonSectionArray.length > 0)
		) {
			//the network call for sections first, then all other tasks
			dispatch(templateTasksCall(groupName, groupId, options, sectionArray, nonSectionArray));
		}
	};
}

function templateTasksCallGeneratePayload(task, options) {
	let isSectionTask = !(task.sectionId === null || task.sectionId === undefined);

	let payload = {
		mode: 'workOrder',
		options: {
			isSection: task.isSection,
			isSectionTask: isSectionTask,
			aasdiIndex: options.aasdiIndex,
			groupIndex: options.groupIndex,
			category: task.isSection || isSectionTask ? 'general' : task.isPinned ? 'pinned' : 'general',
			sectionIndex: isSectionTask ? options.mapIndex : task.taskOrder > 0 ? task.taskOrder - 1 : 0,
			// sectionIndex: task.taskOrder - 1,
			sectionCategory: task.isPinned ? 'pinned' : 'general'
		},
		task: [task]
	};

	return payload;
}

export function templateTasksCall(groupName, groupId, options, sectionArray, nonSectionArray) {
	//the old version is commented out below this

	// this code is still very much of dubious quality; I'd love to refactor it more fully into something decent, but I have to hit a deadline

	return async (dispatch, getState) => {
		const state = getState();

		sectionArray.forEach((section) => {
			section.tasks.general.forEach((task, index) => {
				task.isSectionTask = true;
			});
		});

		sectionArray.forEach((section) => {
			section.tasks.pinned.forEach((task, index) => {
				task.isSectionTask = true;
			});
		});

		dispatch({
			type: 'TASKS_UPDATE_EDIT_LOADER',
			payload: {
				loading: 'addingTemplate',
				aasdiIndex: options.aasdiIndex,
				groupIndex: options.groupIndex
			}
		});

		console.log('SECTION ARRAY ', sectionArray);
		if (sectionArray.length > 0) {
			console.log(
				` section Array ------ ${process.env.REACT_APP_API_URL}/task?${
					state.work.tasks.aasdis.map((aasdi) => aasdi.name).filter((names) => names === groupName)[0]
						? 'aasdiId'
						: 'tripId'
				}=${groupId}&workId=${state.work.tasks.workId}&mode=add-template`
			);
			let postSectionArray = await customFetch(
				`${process.env.REACT_APP_API_URL}/legacy/task?${
					state.work.tasks.aasdis.map((aasdi) => aasdi.name).filter((names) => names === groupName)[0]
						? 'aasdiId'
						: 'tripId'
				}=${groupId}&workId=${state.work.tasks.workId}&mode=add-template`,
				{
					method: 'POST',
					body: JSON.stringify(sectionArray)
				}
			);
			if (postSectionArray.status !== 200) {
				dispatch({
					type: 'ERROR',
					payload: `THERE WAS AN ERROR DURING TEMPLATE POST: ${JSON.stringify(await postSectionArray.json())}`
				});
				return;
			}
			let postSectionJson = await postSectionArray.json();
			console.log(`section array response === ${JSON.stringify(postSectionJson)}`);
			if (postSectionJson.statusCode) {
				dispatch({
					type: 'GLOBAL_UPDATE_MESSAGE',
					payload: {
						text: postSectionJson.message,
						icon: 'exclamation circle',
						error: true
					}
				});
				return dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: false
				});
			} else {
				//no errors time to add non-section tasks if they exist
				postSectionJson.forEach((task, index) => {
					//pull the sections' tasks to be added to the nonSectionTask array
					//don't need to worry about cloning, this isn't state yet.

					let sectionTaskArr = [...task.tasks.general, ...task.tasks.pinned];

					if (task.taskId) {
						task.id = task.taskId;
					}

					if (sectionTaskArr.length > 0) {
						//this adds in the tasks within each section to the nonSectionTasks array
						//and assigns sectionId to the tasks
						sectionTaskArr.forEach((sectionTask) => {
							sectionTask.sectionId = task.id;
							nonSectionArray.push(sectionTask);
						});
					}

					task.tasks = {general: [], pinned: []};

					options.mapIndex = index > 0 ? postSectionJson[index - 1].taskOrder - 1 : 0;
				});
			}
		}

		console.log('NON SECTION ARRAY ', nonSectionArray);
		if (nonSectionArray.length > 0) {
			console.log(
				`non section array 2 fetch -----  ${process.env.REACT_APP_API_URL}/task?${
					state.work.tasks.aasdis.map((aasdi) => aasdi.name).filter((names) => names === groupName)[0]
						? 'aasdiId'
						: 'tripId'
				}=${groupId}&workId=${state.work.tasks.workId}&mode=add-template`
			);
			const nonSectionPost = await customFetch(
				`${process.env.REACT_APP_API_URL}/legacy/task?${
					state.work.tasks.aasdis.map((aasdi) => aasdi.name).filter((names) => names === groupName)[0]
						? 'aasdiId'
						: 'tripId'
				}=${groupId}&workId=${state.work.tasks.workId}&mode=add-template`,
				{
					method: 'POST',
					body: JSON.stringify(nonSectionArray)
				}
			);
			if (nonSectionPost.status !== 200) {
				dispatch({
					type: 'ERROR',
					payload: `THERE WAS AN ERROR DURING TEMPLATE POST: ${JSON.stringify(await nonSectionPost.json())}`
				});
				return;
			}
			const nonSectionPostJson = await nonSectionPost.json();

			console.log(`non section 2 response === ${JSON.stringify(nonSectionPostJson)}`);
			if (nonSectionPostJson.statusCode) {
				dispatch({
					type: 'GLOBAL_UPDATE_MESSAGE',
					payload: {
						text: nonSectionPostJson.message,
						icon: 'exclamation circle',
						error: true
					}
				});
				return dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: false
				});
			}
		}

		dispatch({
			type: 'TASKS_TOGGLE_ADD_TEMPLATE',
			payload: {
				aasdiIndex: options.aasdiIndex,
				groupIndex: options.groupIndex
			}
		});
		dispatch(getTasks(state.work.tasks.workId));
		dispatch(setSelectedTaskGroup(0, 0));
	};
}

export let addTask = (mode, options) => {
	return (dispatch, getState) => {
		console.log('addTask');
		const workId = getState().work.workRecord.view.id;
		dispatch(startLoader());

		let task = {
			name: 'New Task',
			taskTypeId: null,
			title: 'New Task',
			completed: null,
			directions: 'Describe what you would like the AASDI to do.',
			id: null,
			index: options.length,
			taskOrder: options.length,
			maskName: null,
			approved: false,
			asdVerification: null,
			customerVerification: null,
			requiresOmniApproval: null,
			choices: [],
			userChoice: {
				choice: null
			},
			maskId: null,
			url: null,
			pictureDriveLinks: [],
			signatureDriveLinks: [],
			pendingChanges: true,
			pinned: false,
			isSection: false,
			workId: workId
		};

		if (options.isSection && !options.isSectionTask) {
			task = {
				...task,
				name: 'Section',
				title: 'New Section',
				directions: null,
				isSection: true,
				isSequential: true,
				taskTypeId: 10,
				tasks: {
					pinned: [],
					general: []
				}
			};
		}

		switch (options.isSectionTask) {
			case true:
				task = {
					...task,
					sectionId: options.sectionId,
					isSectionTask: options.isSectionTask
				};

				options = {
					...options,
					sectionIndex: options.index,
					index: options.length
				};
				break;

			default:
				options = {
					...options,
					index: options.length
				};
				break;
		}

		dispatch({
			type: 'TASKS_ADD_TASK',
			payload: {
				mode,
				options,
				task: [task]
			}
		});

		dispatch(togglePanel('open', mode, options.isSection && !options.isSectionTask ? 'section' : 'task', options));

		setTimeout(() => {
			dispatch(endLoader(`success`, ` ${task.title} has been successfully added.`));
		}, 500);
	};
};

export let createTask = (mode, options, task, group) => {
	return (dispatch) => {
		dispatch(startLoader());

		if (options.isSectionTask && !task.sectionId) {
			return dispatch(
				endLoader(
					'error',
					'Please finish creating a new section by hitting the save button in the edit panel before saving any section tasks.'
				)
			);
		}

		if (mode !== 'templateManager') {
			if (task.choices) {
				task.choice = task.choices;
			}
		}

		console.log(
			`${process.env.REACT_APP_API_URL}/legacy${
				mode === 'templateManager'
					? `/task?templateId=${group.id}&mode=template-management`
					: `/task?${typeof group.isProject !== 'undefined' ? `aasdiId=${group.id}` : `tripId=${group.id}`}`
			}`
		);

		customFetch(
			`${process.env.REACT_APP_API_URL}/legacy${
				mode === 'templateManager'
					? `/task?templateId=${group.id}&mode=template-management`
					: `/task?${typeof group.isProject !== 'undefined' ? `aasdiId=${group.id}` : `tripId=${group.id}`}`
			}`,
			{
				method: 'POST',
				body: JSON.stringify(task)
			}
		)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'TASKS_UPDATE_TASK',
					payload: {
						mode,
						column: 'id',
						value: json.id ? json.id : json.taskId,
						options
					}
				});

				dispatch({
					type: 'TASKS_UPDATE_TASK',
					payload: {
						mode,
						column: 'pendingChanges',
						value: false,
						options
					}
				});

				return dispatch(endLoader(`success`, ` ${task.title} has been successfully created.`));
			})
			.catch((err) => {
				dispatch(endLoader('error', 'There was an error when trying to create this task, please try again.'));

				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export function addDropdownTask(value, mode, options, task) {
	console.log('addDropdownTask - tasksActions.js   ---->  ', value);
	return (dispatch, getState) => {
		if (value === undefined || null || !value.replace(/\s/g, '').length) {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'Please insert a value in the Choice Name field before adding.',
					icon: 'warning sign',
					warning: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			return setTimeout(
				() =>
					dispatch({
						type: 'GLOBAL_HIDE_MESSAGE',
						payload: true
					}),
				4000
			);
		}

		dispatch({
			type: 'TASKS_ADD_DROPDOWN_TASK',
			payload: {
				mode,
				options: {
					...options
				},
				value
			}
		});

		return dispatch(isValidTask('dropdown'));
	};
}

export function createTemplate() {
	return (dispatch, getState) => {
		const state = getState();

		let template = {...state.work.tasks.currentTemplate};
		template.tasks = [...template.tasks.general, ...template.tasks.pinned];

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?mode=template`, {
			method: 'POST',
			body: JSON.stringify(template)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject({
						text: 'An error occurred while trying to save your changes, please try to save again. If the issue persists please contact the dev team.',
						icon: 'exclamation circle',
						error: true
					});
				}
				return response.json();
			})
			.then((response) => {
				dispatch(handleLoaderVisible('The template has been created'));

				dispatch({
					type: 'TASKS_UPDATE_EDIT_LOADER',
					payload: true
				});

				dispatch({
					type: 'TASKS_TOGGLE_EDIT_PANEL',
					payload: true
				});

				template.id = response.results.id;

				const searchString = queryString.stringify({
					mode: 'manage',
					templateId: response.results.id
				});

				dispatch(
					push({
						search: searchString
					})
				);

				dispatch({
					type: 'TASK_TEMPLATES_UPDATE_TEMPLATE_TO_EDIT',
					payload: template
				});
			})
			.catch(() => {
				//set the loader to an error.
				dispatch({
					type: 'GLOBAL_LOADER_ANIMATION',
					payload: {
						percent: 100,
						error: true,
						success: false,
						message:
							'An error occurred while trying to save your changes, please try to save again. If the issue persists please contact the dev team.'
					}
				});
			});
	};
}

///////////
// READ ///
/////////

export const getTasks = (workId, message) => async (dispatch, getState) => {
	console.log('getTasks');

	dispatch({
		type: 'GLOBAL_HIDE_MESSAGE',
		payload: true
	});
	dispatch({
		type: 'GLOBAL_UPDATE_PAGE_LOADER',
		payload: {
			loadingError: false,
			loading: message ? message : true
		}
	});

	dispatch({
		type: 'TASKS_UPDATE_WORK_ID',
		payload: workId
	});

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/task?workId=${workId}&mode=task`;
	console.log(endpoint);
	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}
		console.log(response);
		if (response.aasdis && !(response.aasdis.length > 0)) {
			//TODO: this needs to be an actual error
			//eslint-disable-next-line
			dispatch(displayWorkRecordError('An AASDI must be assigned in order to add tasks.', 'warning circle'));
		}

		response.aasdis.forEach((aasdi, index) => {
			if (aasdi.isProject) {
				let newAasdi = {...aasdi};
				delete newAasdi.trips;
				aasdi.trips.unshift(newAasdi);
				aasdi['taskGroups'] = aasdi['trips'];
				delete aasdi.trips;
				delete aasdi.tasks;

				return;
			}

			aasdi.tasks.pinned = aasdi.tasks.pinned.filter((singleAasdi) => aasdi.id === singleAasdi.aasdiId);

			aasdi.tasks.general = aasdi.tasks.general.filter((singleAasdi) => aasdi.id === singleAasdi.aasdiId);

			let newAasdi = {...aasdi};
			delete newAasdi.trips;
			aasdi['taskGroups'] = aasdi['trips'];
			newAasdi.isAasdiTask = true;
			aasdi.trips.push(newAasdi);
			delete aasdi.trips;
			delete aasdi.tasks;
		});

		dispatch({
			type: 'TASKS_UPDATE_ON_LOAD',
			payload: {
				aasdis: response.aasdis,
				taskList: response.taskList,
				inputMask: response.inputMask
			}
		});

		return dispatch({
			type: 'GLOBAL_UPDATE_PAGE_LOADER',
			payload: {
				loading: false
			}
		});
	} catch (err) {
		console.log(err);
		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: err.text ? err.text : 'ERROR',
				icon: err.icon ? err.icon : null,
				error: err.error ? err.error : null,
				warning: err.warning ? err.warning : null
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		return dispatch({
			type: 'GLOBAL_UPDATE_PAGE_LOADER',
			payload: {
				// loading: false,
				loadingError: true
			}
		});
	}
};

export function getTemplates(id) {
	console.log('getTemplates');
	return (dispatch) => {
		dispatch({
			type: 'TASK_TEMPLATES_LOADER',
			payload: {
				value: true
			}
		});

		if (id === 'new') {
			dispatch({
				type: 'TASK_TEMPLATES_TOGGLE_EDIT_TEMPLATE'
			});
		}

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: true
		});
		dispatch({
			type: 'GLOBAL_UPDATE_PAGE_LOADER',
			payload: {
				loadingError: false,
				loading: true
			}
		});

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?mode=template`)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject({
						text: 'An error occurred while trying to talk to the database, please refresh the page. If the issue persists please contact the dev team.',
						icon: 'exclamation circle',
						error: true
					});
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'GLOBAL_UPDATE_PAGE_LOADER',
					payload: {
						loadingError: false,
						loading: true
					}
				});
				dispatch({
					type: 'TASK_TEMPLATES_UPDATE_TEMPLATES',
					payload: json.results.templates
				});

				dispatch({
					type: 'TASK_TEMPLATES_UPDATE_TASKLIST',
					payload: json.results.taskList
				});

				dispatch({
					type: 'TASK_TEMPLATES_UPDATE_INPUTMASKLIST',
					payload: json.results.inputMask
				});

				dispatch({
					type: 'TASK_TEMPLATES_LOADER',
					payload: {
						value: false
					}
				});

				if (id === 'new') {
					return dispatch({
						type: 'GLOBAL_UPDATE_PAGE_LOADER',
						payload: {
							loading: false
						}
					});
				}

				if (id) {
					console.log(
						`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${id}&mode=template-management`
					);
					customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${id}&mode=template-management`)
						.then((response) => {
							if (response.status !== 200) {
								return Promise.reject(response);
							}
							return response.json();
						})
						.then((response) => {
							let index = json.results.templates.findIndex(
								//not sure why, but this will only work with 2 equal signs, not 3
								(template) => template.id == id
							);

							let template = json.results.templates[index];

							template.tasks = response.results.tasks;
							template.tasks.general.sort((a, b) => {
								return a.taskOrder - b.taskOrder;
							});

							dispatch({
								type: 'GLOBAL_UPDATE_PAGE_LOADER',
								payload: {
									loading: false
								}
							});
							return dispatch({
								type: 'TASK_TEMPLATES_UPDATE_TEMPLATE_TO_EDIT',
								payload: template
							});
						})
						.catch((err) => {
							console.log(err);
							dispatch({
								type: 'GLOBAL_UPDATE_MESSAGE',
								payload: {
									text: 'Error loading template, check ID.',
									icon: err.icon,
									error: err.error ? err.error : null,
									warning: err.warning ? err.warning : null
								}
							});

							dispatch({
								type: 'GLOBAL_HIDE_MESSAGE',
								payload: false
							});

							return dispatch({
								type: 'GLOBAL_UPDATE_PAGE_LOADER',
								payload: {
									// loading: false,
									loadingError: true
								}
							});
						});
				}
				dispatch({
					type: 'TASK_TEMPLATES_LOADER',
					payload: {
						value: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'GLOBAL_UPDATE_MESSAGE',
					payload: {
						text: err.text,
						icon: err.icon,
						error: err.error ? err.error : null,
						warning: err.warning ? err.warning : null
					}
				});

				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: false
				});

				return dispatch({
					type: 'GLOBAL_UPDATE_PAGE_LOADER',
					payload: {
						// loading: false,
						loadingError: true
					}
				});
			});
	};
}

//////////////
// UPDATE ///
////////////
export let togglePanel = (panelState, mode, type, options) => {
	return (dispatch, getState) => {
		const state = getState();
		if (state.work.tasks.ui.panel.toggle) {
			dispatch({
				type: 'TASKS_TOGGLE_PANEL',
				payload: false
			});
			switch (panelState) {
				case 'open':
					if (state.work.tasks.ui.panel.isSection) {
						if (
							state.work.tasks.ui.panel.sectionIndex === options.sectionIndex &&
							state.work.tasks.ui.panel.sectionCategory === options.sectionCategory &&
							state.work.tasks.ui.panel.index === options.index
						) {
							return;
						}
					} else {
						if (
							state.work.tasks.ui.panel.index === options.index &&
							state.work.tasks.ui.panel.category === options.category
						) {
							return;
						}
					}

					dispatch({
						type: 'TASKS_UPDATE_PANEL_DATA',
						payload: {
							...options,
							type
						}
					});

					setTimeout(() => {
						dispatch({
							type: 'TASKS_TOGGLE_PANEL',
							payload: true
						});
					}, 400);
					return;

				case 'close':
					return;
			}
		} else {
			if (panelState === 'close') return;

			dispatch({
				type: 'TASKS_UPDATE_PANEL_DATA',
				payload: {
					...options,
					type
				}
			});

			dispatch({
				type: 'TASKS_TOGGLE_PANEL',
				payload: true
			});
		}
	};
};

export function toggleEditTaskPanel(mode, options) {
	console.log('toggleEditTaskPanel');
	return (dispatch, getState) => {
		const state = getState();
		let valid;
		let togglePanel = () => {
			if (state.work.tasks.ui.toggleTaskPanel) {
				valid = dispatch(isValidTask());
				if (!valid) {
					return;
				}
			}

			dispatch({
				type: 'TASKS_TOGGLE_EDIT_PANEL',
				payload: {
					index: options.index,
					pinned: options.pinned
				}
			});
		};

		switch (state.work.tasks.ui.toggleTemplatePanel) {
			case true:
				//validate template here
				valid = dispatch(isValidTemplate());
				if (valid) {
					dispatch({
						type: 'TASK_TEMPLATES_TOGGLE_EDIT_TEMPLATE'
					});

					setTimeout(() => {
						togglePanel();
						return;
					}, 260);
				}

				break;

			default:
				togglePanel();
				return;
		}
	};
}

export function toggleEditTemplatePanel(mode, options) {
	console.log('toggleEditTemplatePanel');
	return (dispatch, getState) => {
		const state = getState();
		let valid;
		switch (state.work.tasks.ui.toggleTaskPanel) {
			case true:
				valid = dispatch(isValidTask());
				if (valid) {
					dispatch({
						type: 'TASKS_TOGGLE_EDIT_PANEL',
						payload: state.work.tasks.edit.data.index
					});

					setTimeout(() => {
						dispatch({
							type: 'TASK_TEMPLATES_TOGGLE_EDIT_TEMPLATE'
						});
					}, 260);
				}

				break;

			default:
				//validate template here
				valid = dispatch(isValidTemplate());
				if (valid) {
					dispatch({
						type: 'TASK_TEMPLATES_TOGGLE_EDIT_TEMPLATE'
					});
				}
				break;
		}
	};
}

export let updateTask = (mode, column, value, options) => {
	console.log('updateTask');
	return (dispatch, getState) => {
		const state = getState();

		let findTaskID = () => {
			let taskID = state.work.tasks.taskList.filter((taskType) => taskType.taskName === value);
			if (taskID[0] === undefined) {
				return value;
			} else {
				return taskID[0].id;
			}
		};
		let taskID = findTaskID();

		if (taskID === 3 || taskID === 7) {
			if (column !== 'maskId') {
				dispatch({
					type: 'TASKS_UPDATE_TASK',
					payload: {
						mode,
						column: 'pendingChanges',
						value: true,
						options
					}
				});
			} else {
				let findMaskDetails = () => {
					let maskObj = {};

					let maskId = options.maskId;
					maskObj = state.work.tasks.inputMask.filter((maskOption) => maskOption.maskId === maskId);
					return maskObj[0];
				};
				let maskDetails = findMaskDetails();

				let updates = [
					{column: 'maskId', value: maskDetails.maskId},
					{column: 'maskName', value: maskDetails.maskName},
					{column: 'maskRegex', value: maskDetails.maskRegex},
					{column: 'maskType', value: maskDetails.maskType},
					{column: 'pendingChanges', value: true}
				];

				if (mode === 'workOrder' && column === 'maskId') {
					value = maskDetails.maskId;
				}

				updates.forEach((update) => {
					dispatch({
						type: 'TASKS_UPDATE_TASK',
						payload: {
							mode,
							column: update.column,
							value: update.value,
							options
						}
					});
				});
			}
		} else {
			dispatch({
				type: 'TASKS_UPDATE_TASK',
				payload: {
					mode,
					column: 'pendingChanges',
					value: true,
					options
				}
			});
		}

		dispatch({
			type: 'TASKS_UPDATE_TASK',
			payload: {
				mode,
				column,
				value,
				options
			}
		});

		if (column === 'name') {
			dispatch({
				type: 'TASKS_UPDATE_TASK',
				payload: {
					mode,
					column: 'taskTypeId',
					value: taskID,
					options
				}
			});
		}
	};
};

export let saveChanges = (mode, options, task) => {
	console.log('hit inside saveChanges - tasksActions.js');
	return (dispatch, getState) => {
		dispatch(startLoader());

		let templateGroupingId = task.directions ? task.id : null;
		let endpoint =
			mode === 'templateManager'
				? `${process.env.REACT_APP_API_URL}/legacy/task/${templateGroupingId}?mode=template-detail`
				: // check here vvv
				  `${process.env.REACT_APP_API_URL}/legacy/task/${task.id}`;

		console.log(endpoint);

		// task.choice = task.choices;
		// delete task.choices;

		customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(task)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				dispatch({
					type: 'TASKS_UPDATE_TASK',
					payload: {
						mode,
						column: 'pendingChanges',
						value: false,
						options
					}
				});

				dispatch({
					type: 'TASKS_UPDATE_TASK',
					payload: {
						mode,
						column: 'choices',
						value: task.choices,
						options
					}
				});

				return dispatch(endLoader(`success`, ` ${task.title} has been successfully updated.`));
			})
			.catch((err) => {
				console.log(err);
				dispatch(endLoader('error', 'There was an error when trying to create this task, please try again.'));

				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export function toggleOrdered(aasdiIndex, groupIndex, toggleValue, group) {
	console.log('toggleOrdered');
	return (dispatch, getState) => {
		const state = getState();
		let valid;
		//validate task if task panel is open.
		if (state.work.tasks.ui.toggleTaskPanel) {
			valid = dispatch(isValidTask());
			if (!valid) {
				return;
			}
		}
		//validate template if template panel is open
		if (state.work.tasks.ui.toggleTemplatePanel) {
			valid = dispatch(isValidTemplate());
			if (!valid) {
				return;
			}
		}
		dispatch({
			type: 'TASKS_UPDATE_ORDERED_LOADER',
			payload: {
				loading: true
			}
		});

		dispatch(startLoader());

		customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task?mode=task-list-toggle-sequential&${
				state.work.tasks.aasdis.map((aasdi) => aasdi.name).filter((names) => names === group.name)[0]
					? 'aasdiId'
					: 'tripId'
			}=${group.id}`,
			{
				method: 'PUT',
				body: JSON.stringify({isSequential: toggleValue})
			}
		)
			.then((response) => response.json())
			.then((json) => {
				if (json.statusCode) {
					dispatch({
						type: 'GLOBAL_UPDATE_MESSAGE',
						payload: {
							text: json.message,
							icon: 'exclamation circle',
							error: true
						}
					});

					dispatch(endLoader('error', 'There was a communication error with the server. Please try again.'));

					return dispatch({
						type: 'GLOBAL_HIDE_MESSAGE',
						payload: false
					});
				} else {
					dispatch(endLoader('success', 'Tasks updated.'));

					dispatch({
						type: 'TASKS_TOGGLE_ORDERED',
						payload: {
							aasdiIndex,
							groupIndex,
							toggleValue
						}
					});
					return setTimeout(
						() =>
							dispatch({
								type: 'TASKS_UPDATE_ORDERED_LOADER',
								payload: {
									loading: false
								}
							}),
						1000
					);
				}
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: `THERE WAS AN ERROR DURING ORDERED TOGGLE: ${err}`
				});
				dispatch(endLoader('error', 'There was a communication error with the server. Please try again.'));
			});
	};
}

export function toggleSectionSequential(toggleValue, taskId, options) {
	console.log('toggleSectionSequential');
	return (dispatch, getState) => {
		const state = getState();
		let valid;
		//validate task if task panel is open.
		if (state.work.tasks.ui.toggleTaskPanel) {
			valid = dispatch(isValidTask());
			if (!valid) {
				return;
			}
		}

		dispatch(startLoader());

		// `${process.env.REACT_APP_API_URL}/legacy/task-list?${
		// 	state.work.tasks.aasdis
		// 		.map(aasdi => aasdi.name)
		// 		.filter(names => names === group.name)[0]
		// 		? 'aasdiId'
		// 		: 'tripId'
		// }=${group.id}`,
		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task/${taskId}?mode=section-toggle-sequential`, {
			method: 'PUT',
			body: JSON.stringify({sequential: toggleValue})
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.statusCode) {
					dispatch({
						type: 'GLOBAL_UPDATE_MESSAGE',
						payload: {
							text: json.message,
							icon: 'exclamation circle',
							error: true
						}
					});

					return dispatch({
						type: 'GLOBAL_HIDE_MESSAGE',
						payload: false
					});
				} else {
					dispatch({
						type: 'TASKS_SECTION_TOGGLE_SEQUENTIAL',
						payload: {
							aasdiIndex: options.aasdiIndex,
							groupIndex: options.groupIndex,
							taskIndex: options.sectionIndex,
							toggleValue
						}
					});
					dispatch(endLoader('success', 'Section updated.'));
					return setTimeout(
						() =>
							dispatch({
								type: 'TASKS_UPDATE_ORDERED_LOADER',
								payload: {
									loading: false
								}
							}),
						1000
					);
				}
			})
			.catch((err) => {
				dispatch(
					endLoader('error', 'There was an error while trying to modify the section. Please try again.')
				);
				dispatch({
					type: 'ERROR',
					payload: `THERE WAS AN ERROR DURING SECTION SEQUENTIAL TOGGLE: ${err}`
				});
			});
	};
}

export function toggleEditDropdownTask(mode, options, task) {
	console.log('toggleEditDropdownTask');
	return (dispatch, getState) => {
		let choices = task.choices;
		let selectedIndex =
			choices.findIndex((element) => element === task.dropdown) === -1
				? choices.map((choice) => choice.choice).findIndex((element) => element === task.dropdown)
				: choices.findIndex((element) => element === task.dropdown);
		if (selectedIndex === -1) {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'Please select a dropdown item.',
					icon: 'warning sign',
					warning: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			return setTimeout(
				() =>
					dispatch({
						type: 'GLOBAL_HIDE_MESSAGE',
						payload: true
					}),
				4000
			);
		}
		dispatch({
			type: 'TASKS_TOGGLE_EDIT_DROPDOWN_TASK',
			payload: {
				mode,
				options: {
					...options
				},
				selectedIndex,
				dropdown: task.dropdown
			}
		});
	};
}

export function saveEditDropdownTask(value, mode, options, task) {
	console.log('saveEditDropdownTask');
	return (dispatch, getState) => {
		if (value === undefined || null || !value.replace(/\s/g, '').length) {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'Please enter a Choice Name.',
					icon: 'warning sign',
					warning: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			return setTimeout(() => {
				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: true
				});
			}, 4000);
		}
		let choices = task.choices;
		let selectedIndex =
			choices.findIndex((element) => element === task.dropdown) === -1
				? choices.map((choice) => choice.choice).findIndex((element) => element === task.dropdown)
				: choices.findIndex((element) => element === task.dropdown);

		dispatch({
			type: 'TASKS_SAVE_EDIT_DROPDOWN_TASK',
			payload: {
				mode,
				options: {
					...options
				},
				value,
				selectedIndex
			}
		});
		return dispatch(isValidTask('dropdown'));
	};
}

export function generateCheckoutCode(mode, options) {
	console.log(`mode --- ${mode}`);
	console.log(`options ---- ${JSON.stringify(options)}`);
	console.log('generateCheckoutCode');
	return (dispatch, getState) => {
		dispatch({
			type: 'TASKS_UPDATE_EDIT_LOADER',
			payload: {
				loading: true
			}
		});

		const tasks = getState().work.tasks;

		let selectedTask = tasks.aasdis[options.aasdiIndex].taskGroups[options.groupIndex].tasks;

		customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task/${
				options.category === 'general'
					? selectedTask.general[options.index].id
					: selectedTask.pinned[options.index].id
			}?mode=checkout-generate-code`,
			{
				method: 'Put',
				body: JSON.stringify({generateCode: true})
			}
		)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject({
						text: 'An error occurred while trying to generate the code, please refresh the page. If the issue persists please contact the dev team.',
						icon: 'exclamation circle',
						error: true
					});
				}
				return response.json();
			})
			.then((json) => {
				let newCode = json[0].checkoutCode;
				//call with options.
				// switch (mode) {
				// 	case 'templates':
				// 		dispatch({
				// 			type: 'TASKS_TEMPLATES_CHECKOUT_CODE',
				// 			payload: {
				// 				newCode,
				// 				taskIndex
				// 			}
				// 		});
				// 		break;

				// 	default:
				dispatch({
					type: 'TASKS_CHECKOUT_CODE',
					payload: {
						mode: 'workOrder',
						options: options,
						newCode
					}
				});
				// 		break;
				// }

				return dispatch({
					type: 'TASKS_UPDATE_EDIT_LOADER',
					payload: {
						loading: false
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'GLOBAL_UPDATE_MESSAGE',
					payload: {
						text: err.text || 'There was an error while generating the checkout code. Please try again.',
						icon: err.icon || 'warning circle',
						error: err.error ? err.error : true,
						warning: err.warning ? err.warning : null
					}
				});

				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: false
				});

				return dispatch({
					type: 'GLOBAL_UPDATE_PAGE_LOADER',
					payload: {
						loadingError: true
					}
				});
			});
	};
}

export function toggleOMNIApproval(mode, options) {
	console.log('toggleOMNIApproval');
	return (dispatch, getState) => {
		const tasks = getState().work.tasks;
		let selectedTrip = tasks.aasdis[options.aasdiIndex].taskGroups[options.groupIndex].tasks;

		dispatch({
			type: 'TASKS_UPDATE_EDIT_LOADER',
			payload: {
				loading: true
			}
		});

		let selectedTask =
			options.category === 'general' ? selectedTrip.general[options.index] : selectedTrip.pinned[options.index];

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task/${selectedTask.id}?mode=checkout-approval`, {
			method: 'Put',
			body: JSON.stringify({approval: true})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject({
						text: 'An error occurred while trying to generate the code, please refresh the page. If the issue persists please contact the dev team.',
						icon: 'exclamation circle',
						error: true
					});
				}

				dispatch({
					type: 'TASKS_SET_APPROVED',
					payload: {
						mode: 'workOrder',
						options: options,
						approved: !selectedTask.approved
					}
				});

				dispatch({
					type: 'TASKS_UPDATE_EDIT_LOADER',
					payload: {
						loading: false
					}
				});
			})
			.catch((err) => {
				// TODO: needs something here
				console.log(`err - ${err}`);
			});
	};
}

export function handleContextRef(ref) {
	return (dispatch) => {
		dispatch({
			type: 'TASKS_UPDATE_CONTEXTREF',
			payload: ref
		});
	};
}

export function setSelectedTaskGroup(aasdiIndex, groupIndex) {
	return (dispatch) => {
		dispatch({
			type: 'TASKS_TOGGLE_PANEL',
			payload: false
		});
		dispatch({
			type: 'TASK_SET_SELECTED_TASK_GROUP',
			payload: {
				aasdiIndex,
				groupIndex
			}
		});
	};
}

export let toggleDeleteModal = (modalState, header, message, mode, options, task) => {
	return (dispatch) => {
		dispatch({
			type: 'TASKS_TOGGLE_DELETE_MODAL',
			payload: {
				delete: {
					toggle: modalState === 'open',
					header: header ? header : null,
					message: message ? message : null,
					mode: mode ? mode : null,
					task: task ? task : null,
					options: options ? options : null
				}
			}
		});
		dispatch({
			type: 'TASKS_TOGGLE_PANEL',
			payload: false
		});
	};
};

export function toggleDeleteTemplateModal() {
	return (dispatch) => {
		dispatch({
			type: 'TASKS_TEMPLATES_TOGGLE_DELETE_MODAL'
		});
	};
}

export const updateTaskOrder = (event, mode, options, tasks, type) => {
	console.log('updateTaskOrder');
	return (dispatch) => {
		dispatch(startLoader());

		let reorderedTasks = arrayMove(
			type === 'task' ? tasks : tasks[options.index].tasks.general,
			event.oldIndex,
			event.newIndex
		);
		dispatch({
			type: 'TASKS_UPDATE_TASK_ORDER',
			payload: {
				options,
				mode,
				tasks: reorderedTasks.map((task, order = 0) => {
					task.taskOrder = order++;
					return task;
				})
			}
		});

		let endpoint =
			mode === 'templateManager'
				? `${process.env.REACT_APP_API_URL}/legacy/task?mode=template-task-order`
				: `${process.env.REACT_APP_API_URL}/legacy/task?mode=task-order`;
		customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(reorderedTasks)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				return dispatch(endLoader(`success`, `The tasks have been successfully updated.`));
			})
			.catch(() => {
				dispatch({
					type: 'TASKS_UPDATE_TASK_ORDER',
					payload: {
						options,
						mode,
						tasks: tasks.map((task, order = 0) => {
							task.taskOrder = order++;
							return task;
						})
					}
				});

				return dispatch(endLoader(`error`, `There was an error while update the tasks, please try again.`));
			});
	};
};

export function updateCreateTemplateData(column, value) {
	console.log('updateCreateTemplateData');
	return (dispatch) => {
		return dispatch({
			type: 'TASK_TEMPLATES_UPDATE_CREATE_TEMPLATE_DATA',
			payload: {
				column,
				value
			}
		});
	};
}

export function updateMode(mode) {
	console.log('updateMode');
	return (dispatch) => {
		return dispatch({
			type: 'TASK_TEMPLATES_UPDATE_MODE',
			payload: mode
		});
	};
}

export function handleSort(clickedColumn) {
	console.log('handleSort');
	return (dispatch, getState) => {
		const state = getState();
		const {column, direction} = state.work.tasks.ui.sorting;
		const data = state.work.tasks.templates;
		let sorted = [];
		if (column !== clickedColumn) {
			let sortObj = {
				values: [],
				noValues: []
			};
			data.forEach((templateRecord) => {
				if (templateRecord[clickedColumn] === null) {
					sortObj.noValues.push(templateRecord);
				} else {
					sortObj.values.push(templateRecord);
				}
			});
			sortObj.values.sort(naturalSort(clickedColumn));
			sortObj.values.forEach((templateRecord) => {
				sorted.push(templateRecord);
			});
			sortObj.noValues.forEach((templateRecord) => {
				sorted.push(templateRecord);
			});
			return dispatch({
				type: 'TASK_TEMPLATES_UPDATE_SORTING',
				payload: {
					column: clickedColumn,
					data: sorted,
					direction: 'ascending'
				}
			});
		}
		return dispatch({
			type: 'TASK_TEMPLATES_UPDATE_SORTING',
			payload: {
				column: column,
				data: data.reverse(),
				direction: direction === 'ascending' ? 'descending' : 'ascending'
			}
		});
	};
}

export function editTemplate(id) {
	console.log('editTemplate');
	return (dispatch, getState) => {
		const state = getState();
		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${id}$mode=template-management`)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((response) => {
				let index = state.work.tasks.templates.filter((template) => id === template.id);
				let template = {...state.work.tasks.templates[index]};
				template.tasks = response.results.tasks;
				template.tasks.general.sort((a, b) => {
					return a.taskOrder - b.taskOrder;
				});

				return dispatch({
					type: 'TASK_TEMPLATES_UPDATE_TEMPLATE_TO_EDIT',
					payload: template
				});
			})
			.catch(() => {
				dispatch({
					type: 'GLOBAL_UPDATE_MESSAGE',
					payload: {
						text: `An error occurred while trying grab the templates's tasks, please refresh the page and try again. If the issue persists please contact the dev team.`,
						icon: 'exclamation circle',
						error: true
					}
				});

				dispatch({
					type: 'GLOBAL_HIDE_MESSAGE',
					payload: false
				});

				return dispatch({
					type: 'GLOBAL_UPDATE_PAGE_LOADER',
					payload: {
						loadingError: true
					}
				});
			});
	};
}

export function saveTemplate() {
	console.log('saveTemplate');
	return (dispatch, getState) => {
		const state = getState();
		let valid;
		//validate task if task panel is open.
		if (state.work.tasks.ui.toggleTaskPanel) {
			valid = dispatch(isValidTask());
			if (!valid) {
				dispatch({
					type: 'GLOBAL_LOADER_ANIMATION',
					payload: {
						percent: 100,
						error: true,
						success: false,
						message: 'Please enter valid data into the required fields.'
					}
				});
				setTimeout(() => {
					dispatch({
						type: 'GLOBAL_LOADER_ANIMATION',
						payload: {
							percent: null,
							error: false,
							success: false,
							message: ''
						}
					});
				}, 4000);
				return;
			}
		}

		dispatch({
			type: 'TASKS_UPDATE_EDIT_LOADER',
			payload: true
		});
		let template = {...state.work.tasks.currentTemplate};
		template.tasks = template.tasks.general.concat(template.tasks.pinned);
		template.tasks.forEach((task) => {
			if (task.choices) {
				task.choice = task.choices;
			}
		});

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?type=template&mode=template`, {
			method: 'Put',
			body: JSON.stringify(template)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject({
						text: 'An error occurred while trying to save your changes, please try to save again. If the issue persists please contact the dev team.',
						icon: 'exclamation circle',
						error: true
					});
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'TASKS_UPDATE_EDIT_LOADER',
					payload: true
				});
				//sets loader to successfull and makes sure there is only one timeout for removing the loader
				dispatch(handleLoaderVisible('Successfully saved template'));
				//HERE
				return console.log(json.results);
			})
			.catch((err) => {
				//set the loader to an error.
				dispatch({
					type: 'GLOBAL_LOADER_ANIMATION',
					payload: {
						percent: 100,
						error: true,
						success: false,
						message:
							'An error occurred while trying to save your changes, please try to save again. If the issue persists please contact the dev team.'
					}
				});
			});
	};
}

export function updateTemplateInfo(column, value) {
	console.log('updateTemplateInfo');
	return (dispatch) => {
		dispatch({
			type: 'TASK_TEMPLATES_UPDATE_TEMPLATE_INFO',
			payload: {
				column,
				value
			}
		});
		dispatch(isValidTemplate(column));
	};
}

export function updateClientSearchResults(searchQuery) {
	console.log('updateClientSearchResults');
	return (dispatch, getState) => {
		let resultObj = [];
		let searchChoices = [
			{
				text: 'Make template global',
				value: {
					clientName: null,
					clientId: null
				}
			}
		];

		const state = getState();
		let timeout = state.work.tasks.ui.template.timeout;
		clearTimeout(timeout);

		dispatch({
			type: 'TASK_TEMPLATES_UPDATE_TIMEOUT',
			payload: timeout
		});

		timeout = setTimeout(function () {
			dispatch({
				type: 'TASK_TEMPLATES_UPDATE_DROPDOWN_LOADER',
				payload: true
			});

			customFetch(
				`${process.env.REACT_APP_API_URL}/legacy/task?clientSearch=${encodeURIComponent(
					searchQuery
				)}&mode=template-client-list`,
				{
					method: 'GET'
				}
			)
				.then((response) => response.json())
				.then((json) => {
					if (json.statusCode) {
						dispatch({
							type: 'GLOBAL_UPDATE_MESSAGE',
							payload: {
								text: json.message,
								icon: 'exclamation circle',
								error: true
							}
						});
						return dispatch({
							type: 'GLOBAL_HIDE_MESSAGE',
							payload: false
						});
					} else {
						resultObj = json.results;
						resultObj.forEach((client) => {
							let newChoice = {
								text: client.clientName,
								value: {
									clientName: client.clientName,
									clientId: client.clientId
								}
							};
							searchChoices.push(newChoice);
						});
						dispatch({
							type: 'TASK_TEMPLATES_UPDATE_DROPDOWN_LOADER',
							payload: false
						});
						return dispatch({
							type: 'TASK_TEMPLATES_UPDATE_CLIENT_SEARCH_RESULTS',
							payload: searchChoices
						});
					}
				})
				.catch((err) => {
					dispatch({
						type: 'ERROR',
						payload: `THERE WAS AN ERROR DURING CLIENT LIST RETRIEVAL: ${err}`
					});
				});
		}, 200);
		dispatch({
			type: 'TASK_TEMPLATES_UPDATE_TIMEOUT',
			payload: timeout
		});
	};
}

export function toggleCompleteTaskModal() {
	console.log('toggleCompleteTaskModal');
	return (dispatch) => {
		dispatch({
			type: 'TASKS_TOGGLE_COMPLETE_MODAL'
		});
	};
}

export function completeTask(aasdiIndex, groupIndex, taskIndex, task) {
	//allows skipping or selecting choice
	return (dispatch, getState) => {
		const state = getState();
		let taskId = task.id;

		let body = {
			completeFromOmni: 'true'
		};
		switch (task.taskTypeId) {
			case 3:
				body.formUserInput = task.formUserInput ? task.formUserInput : null;
				break;
			case 6:
				body.choiceId = task.userChoice.choiceId ? task.userChoice.choiceId : null;
				break;
			case 7:
				body.freeFormUserInput = task.freeFormUserInput ? task.freeFormUserInput : null;
				body.textBoxInput = task.textBoxInput ? task.textBoxInput : null;
				break;
			default:
				break;
		}

		// sectionIndex === -1 if no match
		let sectionIndex = state.work.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general.findIndex((x) => {
			return x.id === task.sectionId;
		});

		/*/task/{taskId}/complete-task*/
		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task/${taskId}?mode=complete-task`, {
			method: 'PUT',
			body: JSON.stringify(body)
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.statusCode) {
					dispatch({
						type: 'GLOBAL_UPDATE_MESSAGE',
						payload: {
							text: json.message,
							icon: 'exclamation circle',
							error: true
						}
					});
					return dispatch({
						type: 'GLOBAL_HIDE_MESSAGE',
						payload: false
					});
				} else {
					let resultObj;
					resultObj = json.results;
					dispatch({
						type: 'TASKS_COMPLETE_TASK',
						payload: {
							pinned: task.pinned,
							aasdiIndex,
							groupIndex,
							taskIndex,
							completed: 'placeholder',
							sectionId: task.sectionId,
							sectionIndex
						}
					});
					if (task.sectionId) {
						const state2 = getState();
						let sectionComplete = true;
						state2.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general[
							sectionIndex
						].tasks.general.forEach((task) => {
							if (task.completed === null || task.completed === undefined) {
								sectionComplete = false;
							}
						});
						state2.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general[
							sectionIndex
						].tasks.pinned.forEach((task) => {
							if (task.completed === null || task.completed === undefined) {
								sectionComplete = false;
							}
						});
						if (sectionComplete) {
							dispatch({
								type: 'TASKS_COMPLETE_TASK',
								payload: {
									pinned: false,
									aasdiIndex,
									groupIndex,
									taskIndex: sectionIndex,
									completed: 'placeholder',
									sectionId: task.sectionId,
									sectionIndex: -1
								}
							});
							let taskId = task.sectionId;
							let body = {
								completeFromOmni: 'true'
							};
							//eslint-disable-next-line
							customFetch(`${process.env.REACT_APP_API_URL}/legacy/task/${taskId}/complete-task`, {
								method: 'PUT',
								body: JSON.stringify(body)
							})
								.then((response) => {
									if (response.status !== 200) {
										return Promise.reject(response);
									}
									return response.json();
								})
								.then((/*The response*/) => {
									//dispatch loader
								})
								.catch((err) => {
									dispatch({
										type: 'ERROR',
										payload: err.stack
									});
								});
						}
					}
					dispatch({
						type: 'TASKS_TOGGLE_COMPLETE_MODAL'
					});
					return;
				}
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: `THERE WAS AN ERROR DURING COMPLETING TASK: ${err}`
				});
			});
	};
}

export function updateUserDropdownChoice(aasdiIndex, groupIndex, taskIndex, userChoice, choiceId, task) {
	return (dispatch, getState) => {
		console.log('updateUserDropdownChoice');
		const state = getState();
		let sectionIndex = state.work.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general.findIndex((x) => {
			return x.id === task.sectionId;
		});
		dispatch({
			type: 'TASKS_UPDATE_DROPDOWN_USER_CHOICE',
			payload: {
				pinned: task.pinned,
				aasdiIndex,
				groupIndex,
				taskIndex,
				userChoice,
				choiceId,
				sectionId: task.sectionId,
				sectionIndex
			}
		});
	};
}

export function updateFormUserInput(aasdiIndex, groupIndex, taskIndex, formUserInput, task) {
	console.log('updateFormUserInput');
	return (dispatch, getState) => {
		const state = getState();
		let sectionIndex = state.work.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general.findIndex((x) => {
			return x.id === task.sectionId;
		});
		dispatch({
			type: 'TASKS_UPDATE_FORM_USER_INPUT',
			payload: {
				pinned: task.pinned,
				aasdiIndex,
				groupIndex,
				taskIndex,
				formUserInput,
				sectionId: task.sectionId,
				sectionIndex
			}
		});
	};
}

export function updateFreeFormUserInput(aasdiIndex, groupIndex, taskIndex, freeFormUserInput, task) {
	console.log('updateFreeFormUserInput');
	return (dispatch, getState) => {
		const state = getState();
		let sectionIndex = state.work.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general.findIndex((x) => {
			return x.id === task.sectionId;
		});
		dispatch({
			type: 'TASKS_UPDATE_FREE_FORM_USER_INPUT',
			payload: {
				pinned: task.pinned,
				aasdiIndex,
				groupIndex,
				taskIndex,
				freeFormUserInput,
				sectionId: task.sectionId,
				sectionIndex
			}
		});
	};
}
export function updateTextAreaInput(aasdiIndex, groupIndex, taskIndex, textBoxInput, task) {
	console.log('updateTextAreaInput');
	return (dispatch, getState) => {
		const state = getState();
		let sectionIndex = state.work.tasks.aasdis[aasdiIndex].taskGroups[groupIndex].tasks.general.findIndex((x) => {
			return x.id === task.sectionId;
		});
		dispatch({
			type: 'TASKS_UPDATE_TEXT_AREA_INPUT',
			payload: {
				pinned: task.pinned,
				aasdiIndex,
				groupIndex,
				taskIndex,
				textBoxInput,
				sectionId: task.sectionId,
				sectionIndex
			}
		});
	};
}

/**
 * This method will toggle if the task is pinned or not.
 * @memberof App.Actions.tasks
 * @typedef {Method}
 * @param {object} task The task you are trying to pin.
 * @param {string} mode The mode in which you are working, this is either 'templates' or 'null' for task list.
 * @param {object} options use the optionsFactory to build this object.
 * @throws {catch} If the fetch to the database fails.
 * @since 2.2.6
 * @example <caption>Example usage of the togglePinnedTask() method.</caption>
 * togglePinnedTask(task mode, options);
 */
export let togglePinnedTask = (mode, options, task) => {
	console.log('togglePinnedTask');
	return (dispatch, getState) => {
		const state = getState();
		let pin = () => {
			if (state.work.tasks.ui.panel.toggle) {
				dispatch(togglePanel('close', mode, 'task', options));
			}

			dispatch({
				type: 'TASKS_DELETE_TASK',
				payload: {
					mode,
					options: {
						...options,
						sectionDeleteMode: false
					}
				}
			});

			dispatch({
				type: 'TASKS_ADD_TASK',
				payload: {
					mode,
					options: {
						...options,
						category: options.isSection
							? options.category
							: options.category === 'pinned'
							? 'general'
							: 'pinned',
						sectionCategory: options.sectionCategory === 'pinned' ? 'general' : 'pinned'
					},
					task: [{...task, pinned: !task.pinned}]
				}
			});

			dispatch(endLoader('success', 'The task has been successfully pinned.'));
		};

		dispatch(startLoader());

		if (!task.id) {
			return pin();
		}

		let body = {
			pinned: !task.pinned
		};

		let endpoint =
			mode === 'templateManager'
				? // top one was template
				  `${process.env.REACT_APP_API_URL}/legacy/task/${task.id}?mode=pin&template=true`
				: `${process.env.REACT_APP_API_URL}/legacy/task/${task.id}?mode=pin`;

		customFetch(endpoint, {
			method: 'Put',
			body: JSON.stringify(body)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((/*The response*/) => {
				pin();
			})
			.catch((err) => {
				dispatch(endLoader('error', 'There was an error when trying to pin this task, please try again.'));

				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

/////////////
// DELETE //
///////////

export function dropdownRemoveTask(mode, options, task) {
	return (dispatch, getState) => {
		const state = getState();
		let choices = task.choices;
		let selectedIndex =
			choices.findIndex((element) => element === task.dropdown) === -1
				? choices.map((choice) => choice.choice).findIndex((element) => element === task.dropdown)
				: choices.findIndex((element) => element === task.dropdown);
		if (selectedIndex === -1) {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'Please select a dropdown item.',
					icon: 'warning sign',
					warning: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});

			return setTimeout(
				() =>
					dispatch({
						type: 'GLOBAL_HIDE_MESSAGE',
						payload: true
					}),
				4000
			);
		}

		dispatch({
			type: 'TASKS_REMOVE_DROPDOWN_TASK',
			payload: {
				mode,
				options: {
					...options
				},
				selectedIndex
			}
		});

		dispatch(isValidTask('dropdown'));
	};
}

export let deleteTask = (mode, options, task) => {
	return (dispatch) => {
		let success = () => {
			dispatch(
				endLoader(
					`success`,
					`${options.isSection ? 'Section' : 'Task'} "${
						task.title || 'New Task'
					}" has been successfully deleted.`
				)
			);

			dispatch(toggleDeleteModal('close'));

			if (!options.isSection || (options.isSection && options.isSectionTask))
				dispatch(togglePanel('close', mode, 'task', options));

			dispatch({
				type: 'TASKS_DELETE_TASK',
				payload: {
					mode,
					options: {
						...options
					}
				}
			});
			dispatch({
				type: 'TASKS_TOGGLE_PANEL',
				payload: false
			});
		};

		dispatch(startLoader());

		if (!task.id) {
			return success();
		}


		customFetch(`${process.env.REACT_APP_API_URL}/legacy/${mode === 'templateManager' ? 'template' : 'task'}/${task.id}`, {
			method: 'DELETE'
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				return success();
			})
			.catch((err) => {
				dispatch(endLoader('error', 'There was an error when trying to delete this task, please try again.'));

				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export function deleteTemplate() {
	return (dispatch, getState) => {
		const state = getState();

		if (!state.work.tasks.currentTemplate.id) {
			dispatch({
				type: 'TASKS_UPDATE_EDIT_LOADER',
				payload: {
					loading: true
				}
			});

			return (window.location.href = `/task-templates/list`);
		}

		dispatch({
			type: 'TASKS_UPDATE_EDIT_LOADER',
			payload: {
				loading: true
			}
		});

		console.log(
			`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${state.work.tasks.currentTemplate.id}&mode=template-management`
		);

		customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${state.work.tasks.currentTemplate.id}&mode=template-management`,
			{
				method: 'DELETE'
			}
		)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				dispatch(handleLoaderVisible('The template has been deleted'));
				return setTimeout(() => {
					window.location.href = `/task-templates/list`;
				}, 1500);
			})
			.catch(() => {
				dispatch({
					type: 'GLOBAL_LOADER_ANIMATION',
					payload: {
						percent: 100,
						error: true,
						success: false,
						message:
							'An error occurred while trying to delete the template, please refresh the page and try again. If the issue persists please contact the dev team.'
					}
				});

				return dispatch({
					type: 'GLOBAL_UPDATE_PAGE_LOADER',
					payload: {
						loadingError: true
					}
				});
			});
	};
}

//////////////
// MISC /////
////////////

export function isValidTask(column) {
	return (dispatch, getState) => {
		const state = getState();
		function ValidURL(str) {
			let regex = isValidURL({strict: false}).test(str);
			if (!regex) {
				return false;
			}
		}
		let taskEditObject = {...state.work.tasks.edit.data};
		let messageValue = null;
		//TASK TYPE ID
		if (column === 'name' || !column) {
			if (!taskEditObject.taskTypeId) {
				messageValue = 'Please select a task type.';
				return dispatch({
					type: 'TASKS_UPDATE_VALIDATION',
					payload: {
						column: 'taskTypeId',
						error: true
					}
				});
			} else if (taskEditObject.taskTypeId !== null) {
				dispatch({
					type: 'TASKS_UPDATE_VALIDATION',
					payload: {
						column: 'taskTypeId',
						error: false
					}
				});
			}
		}

		//DIRECTIONS
		if (column === 'directions' || !column) {
			if (taskEditObject.directions.replace(/\s/g, '').length === (undefined || 0)) {
				//dispatch an error using taskEditObject details to change the actual task
				dispatch({
					type: 'TASKS_UPDATE_VALIDATION',
					payload: {
						column: 'directions',
						error: true
					}
				});
				messageValue = 'Please fill in the Directions field.';
			} else if (taskEditObject.directions.replace(/\s/g, '').length > 0) {
				dispatch({
					type: 'TASKS_UPDATE_VALIDATION',
					payload: {
						column: 'directions',
						error: false
					}
				});
			}
		}

		//URL
		if (column === 'url' || !column) {
			if (taskEditObject.taskTypeId === 5) {
				if (ValidURL(taskEditObject.url) === false) {
					dispatch({
						type: 'TASKS_UPDATE_VALIDATION',
						payload: {
							column: 'url',
							error: true
						}
					});
					messageValue = 'Please use a correct URL. Example: http://www.google.com';
				} else if (ValidURL(taskEditObject.url) !== false) {
					dispatch({
						type: 'TASKS_UPDATE_VALIDATION',
						payload: {
							column: 'url',
							error: false
						}
					});
				}
			}
		}

		//INPUT TYPE
		if (column === 'maskId' || !column) {
			if (taskEditObject.taskTypeId === 3 || taskEditObject.taskTypeId === 7) {
				if (taskEditObject.maskId === null) {
					dispatch({
						type: 'TASKS_UPDATE_VALIDATION',
						payload: {
							column: 'inputType',
							error: true
						}
					});
					messageValue = 'Please select an Input Type.';
				} else if (taskEditObject.maskId !== null) {
					dispatch({
						type: 'TASKS_UPDATE_VALIDATION',
						payload: {
							column: 'inputType',
							error: false
						}
					});
				}
			}
		}

		//DROPDOWN
		if (column === 'dropdown' || !column) {
			if (taskEditObject.taskTypeId === 6) {
				if (taskEditObject.choices.length === 0) {
					dispatch({
						type: 'TASKS_UPDATE_VALIDATION',
						payload: {
							column: 'choices',
							error: true
						}
					});
					messageValue = 'Please add Dropdown choices.';
				} else if (taskEditObject.choices.length > 0) {
					dispatch({
						type: 'TASKS_UPDATE_VALIDATION',
						payload: {
							column: 'choices',
							error: false
						}
					});
				}
			}
		}

		if (messageValue !== null) {
			return false;
		} else {
			return true;
		}
	};
}

export function isValidTemplate(column) {
	return (dispatch, getState) => {
		const state = getState();
		let templateEditObject = {...state.work.tasks.currentTemplate};
		let messageValue = null;
		if (column === 'name' || !column) {
			if (templateEditObject.name.replace(/\s/g, '').length === (undefined || 0)) {
				dispatch({
					type: 'TASKS_UPDATE_VALIDATION',
					payload: {
						column: 'templateName',
						error: true
					}
				});
				messageValue = 'Please fill in the name for the template.';
			} else if (templateEditObject.name.replace(/\s/g, '').length > 0) {
				dispatch({
					type: 'TASKS_UPDATE_VALIDATION',
					payload: {
						column: 'templateName',
						error: false
					}
				});
			}
		}
		if (column === 'description' || !column) {
			if (templateEditObject.description.replace(/\s/g, '').length === (undefined || 0)) {
				dispatch({
					type: 'TASKS_UPDATE_VALIDATION',
					payload: {
						column: 'templateDescription',
						error: true
					}
				});
				messageValue = 'Please fill in the description for the template.';
			} else if (templateEditObject.description.replace(/\s/g, '').length > 0) {
				dispatch({
					type: 'TASKS_UPDATE_VALIDATION',
					payload: {
						column: 'templateDescription',
						error: false
					}
				});
			}
		}

		if (messageValue !== null) {
			return false;
		} else {
			return true;
		}
	};
}

export let isValidURL = (opts) => {
	opts = Object.assign({strict: true}, opts);

	const protocol = `(?:(?:[a-z]+:)?//)${opts.strict ? '' : '?'}`;
	const auth = '(?:\\S+(?::\\S*)?@)?';
	const ip = ipv4Regex().source;
	const host = '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)';
	const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
	const tld = `(?:\\.${
		opts.strict ? '(?:[a-z\\u00a1-\\uffff]{2,})' : `(?:${tlds.sort((a, b) => b.length - a.length).join('|')})`
	})\\.?`;
	const port = '(?::\\d{2,5})?';
	const path = '(?:[/?#][^\\s"]*)?';
	const regex = `(?:${protocol}|www\\.)${auth}(?:localhost|${ip}|${host}${domain}${tld})${port}${path}`;

	return opts.exact ? new RegExp(`(?:^${regex}$)`, 'i') : new RegExp(regex, 'ig');
};

export function handleLoaderVisible(message) {
	return (dispatch, getState) => {
		dispatch({
			type: 'GLOBAL_LOADER_ANIMATION',
			payload: {
				percent: 100,
				error: false,
				success: true,
				message: message
			}
		});
		const timeoutState = getState();
		let timeout = timeoutState.global.global.ui.timeout;
		clearTimeout(timeout);
		dispatch({
			type: 'GLOBAL_UPDATE_TIMEOUT',
			payload: timeout
		});
		//set 4 second timeout for loader to disappear
		timeout = setTimeout(() => {
			dispatch({
				type: 'GLOBAL_LOADER_ANIMATION',
				payload: {
					percent: null,
					error: false,
					success: false,
					message: ''
				}
			});
		}, 4000);
		dispatch({
			type: 'GLOBAL_UPDATE_TIMEOUT',
			payload: timeout
		});
	};
}

export function findTasks(aasdiIndex, groupIndex, templateId) {
	return (dispatch, getState) => {
		const state = getState();
		let resultObj = [];
		let currentTemplateTasks = {general: [], pinned: []};
		if (!state.work.tasks.ui.loading) {
			dispatch({
				type: 'TASKS_UPDATE_EDIT_LOADER',
				payload: {
					loading: true,
					aasdiIndex,
					groupIndex
				}
			});
		}

		console.log(`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${templateId}&mode=template-management`);
		customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${templateId}&mode=template-management`, {
			method: 'GET'
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.statusCode) {
					dispatch({
						type: 'GLOBAL_UPDATE_MESSAGE',
						payload: {
							text: json.message,
							icon: 'exclamation circle',
							error: true
						}
					});
					return dispatch({
						type: 'GLOBAL_HIDE_MESSAGE',
						payload: false
					});
				} else {
					resultObj = json.results.tasks;

					resultObj.general.forEach((taskFromTemplate) => {
						currentTemplateTasks.general.push(taskFromTemplate);
					});
					resultObj.pinned.forEach((taskFromTemplate) => {
						currentTemplateTasks.pinned.push(taskFromTemplate);
					});
					currentTemplateTasks.general = currentTemplateTasks.general.sort(
						(a, b) => a.taskOrder - b.taskOrder
					);

					currentTemplateTasks.general.forEach((section) => {
						if (section.isSection === true) {
							section.tasks.pinned.forEach((task) => {
								task.isSectionTask = true;
							});
							section.tasks.general.forEach((task) => {
								task.isSectionTask = true;
							});
						}
					});

					currentTemplateTasks.pinned.forEach((section) => {
						if (section.isSection === true) {
							section.tasks.pinned.forEach((task) => {
								task.isSectionTask = true;
							});
							section.tasks.general.forEach((task) => {
								task.isSectionTask = true;
							});
						}
					});

					dispatch({
						type: 'TASKS_UPDATE_CURRENT_TEMPLATE',
						payload: {
							aasdiIndex,
							groupIndex,
							currentTemplateTasks
						}
					});
					return dispatch({
						type: 'TASKS_UPDATE_EDIT_LOADER',
						payload: {
							loading: false,
							aasdiIndex,
							groupIndex
						}
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: `THERE WAS AN ERROR DURING TEMPLATE GET: ${err}`
				});
			});
	};
}

export function toggleAddTemplate(aasdiIndex, groupIndex, addPressed) {
	return (dispatch, getState) => {
		const state = getState();
		let valid;
		//validate task if task panel is open.
		if (state.work.tasks.ui.toggleTaskPanel) {
			valid = dispatch(isValidTask());
			if (!valid) {
				return;
			}
		}
		//validate template if template panel is open
		if (state.work.tasks.ui.toggleTemplatePanel) {
			valid = dispatch(isValidTemplate());
			if (!valid) {
				return;
			}
		}
		if (addPressed === 1) {
			let templateArray = [];
			let optionList = [];
			dispatch({
				type: 'TASKS_UPDATE_EDIT_LOADER',
				payload: {
					loading: true,
					aasdiIndex,
					groupIndex
				}
			});
			dispatch({
				type: 'TASKS_TOGGLE_ADD_TEMPLATE',
				payload: {
					aasdiIndex,
					groupIndex
				}
			});

			let clientId = state.work.workRecord.view.record.clientId;

			customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?clientId=${clientId}&mode=template`, {
				method: 'GET'
			})
				.then((response) => response.json())
				.then((json) => {
					if (json.statusCode) {
						dispatch({
							type: 'GLOBAL_UPDATE_MESSAGE',
							payload: {
								text: json.message,
								icon: 'exclamation circle',
								error: true
							}
						});
						return dispatch({
							type: 'GLOBAL_HIDE_MESSAGE',
							payload: false
						});
					} else {
						templateArray = json.results;
						templateArray.forEach((templates) => {
							optionList.push({
								header: templates.name,
								value: templates.id,
								active: !(optionList.length >= 1)
							});
						});
						let activeIndex = optionList.findIndex((element) => element.active === true);

						dispatch(findTasks(aasdiIndex, groupIndex, optionList[activeIndex].value));

						dispatch({
							type: 'TASKS_UPDATE_TEMPLATE_ARRAY',
							payload: {
								aasdiIndex,
								groupIndex,
								templateArray
							}
						});

						dispatch({
							type: 'TASKS_UPDATE_TEMPLATE_OPTIONS',
							payload: {
								aasdiIndex,
								groupIndex,
								optionList
							}
						});
					}
				})
				.catch((err) => {
					console.log('ERROR');
					dispatch({
						type: 'ERROR',
						payload: `THERE WAS AN ERROR DURING TEMPLATE GET: ${err}`
					});
				});
		} else {
			return dispatch({
				type: 'TASKS_TOGGLE_ADD_TEMPLATE',
				payload: {
					aasdiIndex,
					groupIndex
				}
			});
		}
	};
}

export function updateSelectedTemplate(aasdiIndex, groupIndex, optionList, templateArray, selectedOption) {
	return (dispatch) => {
		/////////////////////////////////////////////////////////////////////////////////////
		let newOptionList = optionList.map((option) => {
			if (option.value === selectedOption) {
				option.active = true;
			} else {
				option.active = false;
			}
			return option;
		});
		let activeIndex = newOptionList.findIndex((element) => element.active === true);

		dispatch(findTasks(aasdiIndex, groupIndex, optionList[activeIndex].value));
		dispatch({
			type: 'TASKS_UPDATE_TEMPLATE_OPTIONS',
			payload: {
				aasdiIndex,
				groupIndex,
				optionList
			}
		});
	};
}

export function checkForChanges(event, tasks) {
	return (dispatch) => {
		if (tasks.find((task) => task.id === null)) {
			dispatch({
				type: 'GLOBAL_UPDATE_MESSAGE',
				payload: {
					text: 'An error occurred while trying to delete the tasks, please refresh the page and try again. If the issue persists please contact the dev team.',
					icon: 'exclamation circle',
					error: true
				}
			});

			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: false
			});
			return false;
		}
		return true;
	};
}

export function copyCheckoutCode(value) {
	return (dispatch) => {
		dispatch(
			copy({
				payload: {value}
			})
		);

		dispatch({
			type: 'GLOBAL_UPDATE_MESSAGE',
			payload: {
				text: 'The Verification Code has be copied to your clipboard.',
				icon: 'check',
				success: true
			}
		});

		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: false
		});

		setTimeout(() => {
			dispatch({
				type: 'GLOBAL_HIDE_MESSAGE',
				payload: true
			});
		}, 5000);
	};
}

export function discardChanges(mode, panel, options) {
	//not working check reducer
	return (dispatch, getState) => {
		const state = getState();

		let discard = new Promise((resolve) => {
			switch (mode) {
				case 'templateManager':
					if (state.work.tasks.edit.data.pendingChanges) {
						dispatch({
							type: 'TASKS_TEMPLATES_DISCARD_EDIT_PANEL_CHANGES',
							payload: state.work.tasks.edit.data.index
						});
					}
					break;

				default:
					if (state.work.tasks.edit.data.pendingChanges) {
						dispatch({
							type: 'TASKS_DISCARD_EDIT_PANEL_CHANGES',
							payload: {
								taskIndex: state.work.tasks.edit.data.index,
								aasdiIndex: options.aasdiIndex,
								groupIndex: options.groupIndex
							}
						});
					}
					break;
			}
			dispatch(toggleDiscardChangesModal());
			resolve();
		});

		// eslint-disable-next-line
		discard.then(() => {
			dispatch(toggleEditTaskPanel(mode, options));
		});

		return;
	};
}
