import {Button, Popup, Input, Select} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const search = (props) => (
	<Input {...props}>
		<Popup
			trigger={
				<input
					{...props}
					id="searchField"
					placeholder="Search..."
					value={props.techManagement.search.text}
					onChange={(event) => {
						let value = event.target.value;
						props.updateSearch(value, null);
					}}
					action
					size="small"
					type="text"
					onKeyPress={(event) => (event.key === 'Enter' ? props.getUsers(null, true) : null)}
				/>
			}
			content="Enter keywords to search for."
			on="hover"
		/>
		<Popup
			trigger={
				<Select
					{...props}
					button
					basic
					floating
					options={options}
					defaultValue="Email"
					value={props.techManagement.search.column}
					onChange={(e, {value}) => props.updateSearch(null, value)}
				/>
			}
			content="Select a column to search."
			on="hover"
		/>
		<Popup
			trigger={
				<Button
					{...props}
					type="button"
					primary
					loading={props.techManagement.search.searchLoading}
					onClick={() => props.getUsers(null, true)}
				>
					Search
				</Button>
			}
			content="Search all users"
			on="hover"
		/>
	</Input>
);

const options = [
	{key: 'Email', text: 'Email', value: 'Email'},
	{key: 'AASDI', text: 'AASDI', value: 'AASDI'},
	{key: 'Activated', text: 'Activated', value: 'Activated'},
	{key: 'UserStatus', text: 'User Status', value: 'UserStatus'}
];

search.propTypes = {
	techManagement: PropTypes.shape({
		search: PropTypes.shape({
			column: PropTypes.string,
			text: PropTypes.string,
			searchLoading: PropTypes.bool
		})
	}),
	updateSearch: PropTypes.func,
	getUsers: PropTypes.func
};

export default search;
