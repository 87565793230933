import update from 'react-addons-update';

/**
 * The Pipeline state object
 * @namespace pipeline
 * @memberof App.Store.Reducers
 * @since 3.0.0
 */
export default (
	state = {
		opportunities: [],
		jobLog: [],
		fetched: false,
		gridType: 'pipeline'
	},
	action
) => {
	switch (action.type) {
		case 'CRM_PIPELINE_UPDATE_OPPORTUNITIES':
			state = update(state, {
				opportunities: {
					$set: action.payload
				}
			});
			break;

		case 'CRM_PIPELINE_UPDATE_JOB_LOG':
			state = update(state, {
				jobLog: {
					$set: action.payload
				}
			});
			break;

		case 'CRM_PIPELINE_GRID':
			state = update(state, {
				gridType: {
					$set: action.payload
				}
			});
			break;

		case 'CRM_PIPELINE_UPDATE_OPPORTUNITIES_FETCHED':
			state = update(state, {
				fetched: {
					$set: action.payload
				}
			});
			break;

		case 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_UPDATE_COLUMN':
			state = update(state, {
				opportunities: {
					[action.payload.updateIndex]: {
						[action.payload.column]: {
							$set: action.payload.value
						}
					}
				}
			});
			break;

		case 'CRM_PIPELINE_UPDATE_PIPELINE_OPPORTUNITY_DEACTIVATE':
			state = update(state, {
				opportunities: {
					$splice: [[action.payload, 1]]
				}
			});
			break;
		case 'CRM_PIPELINE_UPDATE_OPPORTUNITY_COLUMN_BY_ID':
			state = update(state, {
				opportunities: {
					[state.opportunities.findIndex((elm) => elm.id === action.payload.id)]: {
						[action.payload.column]: {
							$set: action.payload.value
						}
					}
				}
			});
			break;
		default:
	}

	return state;
};
