import customFetch from '../../../lib/old/customFetch.js';
import {getLocations} from '../../../scenes/crm/scenes/dashboard/scenes/CreateProject/services/createProjectActions.js';

export const toggleCreateLocationModal = (id, clientId) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_LOCATION_MODAL_TOGGLE',
			payload: {
				id,
				clientId
			}
		});
	};
};

export const setActiveStep = (currentStep, targetStep) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_LOCATION_MODAL_SET_ACTIVE_STEP',
			payload: {
				currentStep,
				targetStep
			}
		});
	};
};

export const updateCreateLocationModal = (modal, step, column, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_LOCATION_MODAL_UPDATE',
			payload: {
				modal,
				step,
				column,
				value,
				error
			}
		});
	};
};

export const getContacts = (step, column, value, clientId) => {
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'CREATE_LOCATION_MODAL_LOADING_FIELD',
			payload: {
				step,
				column
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?clientId=${clientId}&name=${value}&type=0`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((contact) => ({
					key: contact.id,
					text: contact.fullName,
					value: contact.id,
					fullValue: contact
				}));
				dispatch({
					type: 'CREATE_LOCATION_MODAL_GET_DROPDOWN_OPTIONS',
					payload: {
						step: step,
						column: column,
						options: options
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const fillContactFields = (step, column, value) => {
	return (dispatch, getState) => {
		const fullValue = getState().global.createLocationModal.createLocationModal.modalSteps[step].modalFields[
			column
		].options.filter((option) => option.fullValue.id === value)[0].fullValue;
		const payload = {
			modal: 'createLocationModal',
			step: step,
			firstName: fullValue.firstName,
			lastName: fullValue.lastName,
			email: fullValue.email,
			extension: fullValue.extension,
			phone: fullValue.phone
		};
		dispatch({
			type: 'CREATE_LOCATION_MODAL_AUTO_FILL',
			payload: payload
		});
	};
};

export const updateRadio = (previous, target) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_LOCATION_MODAL_UPDATE_RADIO',
			payload: {
				previous,
				target
			}
		});
		// Clear Autofill on New Contact
		if (previous === 'newContact') {
			dispatch({
				type: 'CREATE_LOCATION_MODAL_UPDATE_RADIO_CLEAR'
			});
		}
	};
};

export const createLocation = (clientId, type) => {
	return (dispatch, getState) => {
		const state = getState();

		// start validation
		let locationFields = state.global.createLocationModal.createLocationModal.modalSteps[0].modalFields;
		let contactFields = state.global.createLocationModal.createLocationModal.modalSteps[1].modalFields;

		let valErrors = {locationErrors: [], contactErrors: []};

		locationFields.forEach((field, index) => {
			if (field.required === true) {
				if (!field.value || field.value.length < 1) {
					valErrors.locationErrors.push(index);
					dispatch({
						type: 'CREATE_LOCATION_MODAL_CHECK_REQUIRED_FIELDS',
						payload: {
							stepIndex: 0,
							fieldIndex: index
						}
					});
				}
			}
		});
		console.log('hit here');

		contactFields.forEach((field, index) => {
			if (field.required === true) {
				console.log(`hit here contact-${index}`);
				if (!field.value || field.value.length < 1) {
					valErrors.contactErrors.push(index);
					dispatch({
						type: 'CREATE_LOCATION_MODAL_CHECK_REQUIRED_FIELDS',
						payload: {
							stepIndex: 1,
							fieldIndex: index
						}
					});
				}
			}
		});

		if (valErrors.locationErrors.length > 0 || valErrors.contactErrors.length > 0) {
			if (valErrors.locationErrors.length === 0) {
				//switch to contact
				dispatch(setActiveStep(0, 1));
			} else if (valErrors.contactErrors.length === 0) {
				// switch to location
				dispatch(setActiveStep(1, 0));
			}
			return;
		}
		// END validation

		// POST variables
		let createLocationModalSteps = state.global.createLocationModal.createLocationModal.modalSteps;
		let payload = {
			clientId: clientId,
			name: createLocationModalSteps[0].modalFields[0].value,
			storeNumber: createLocationModalSteps[0].modalFields[1].value,
			address: createLocationModalSteps[0].modalFields[2].value,
			address2: createLocationModalSteps[0].modalFields[3].value,
			city: createLocationModalSteps[0].modalFields[4].value,
			zip: createLocationModalSteps[0].modalFields[5].value,
			state: createLocationModalSteps[0].modalFields[6].value,
			countryId: createLocationModalSteps[0].modalFields[7].value,
			siteContactId: createLocationModalSteps[1].modalFields[0].value
				? createLocationModalSteps[1].modalFields[0].value
				: null,
			siteContact: {
				clientId: clientId,
				firstName: createLocationModalSteps[1].modalFields[1].value,
				lastName: createLocationModalSteps[1].modalFields[2].value,
				email: createLocationModalSteps[1].modalFields[3].value,
				extension: createLocationModalSteps[1].modalFields[4].value,
				phone: createLocationModalSteps[1].modalFields[5].value
			}
		};
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations?clientId=${clientId}`;

		dispatch({
			type: 'CREATE_LOCATION_MODAL_LOADING',
			payload: {
				state: 'progress'
			}
		});
		customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(payload)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let modal = 'createProjectModal';
				let sectionIndex = getState().crm.createProject.createProjectModal.sections.findIndex(
					(section) => section.name === 'billingDetailsSection'
				);
				let fieldIndex = getState().crm.createProject.createProjectModal.sections[
					sectionIndex
				].fields.findIndex((field) => field.name === 'Location');
				let error = false;

				if (type === 'project') {
					dispatch({
						type: 'CREATE_PROJECT_MODAL_UPDATE',
						payload: {
							modal,
							sectionIndex,
							fieldIndex,
							value: json.id,
							error
						}
					});

					dispatch({
						type: 'CREATE_PROJECT_MODAL_GET_DATALIST_OPTIONS',
						payload: {
							value: json.id,
							sectionIndex,
							fieldIndex,
							json: [json]
						}
					});
				}
				if (type === 'workOrder') {
					dispatch(
						getLocations(
							sectionIndex,
							fieldIndex,
							clientId,
							createLocationModalSteps[0].modalFields[0].value,
							error
						)
					);

					let sectionIndexWorkOrder = getState().work.createWorkOrder.createWorkOrderModal.sections.findIndex(
						(section) => section.name === 'workOrderOverviewSection'
					);
					let fieldIndexWorkOrder = getState().work.createWorkOrder.createWorkOrderModal.sections[
						sectionIndexWorkOrder
					].fields.findIndex((field) => field.name === 'Location');
					dispatch({
						type: 'CREATE_WORK_ORDER_GET_BILLING_CONTACTS_DROPDOWN_OPTIONS',
						payload: {
							sectionIndex: sectionIndexWorkOrder,
							fieldIndex: fieldIndexWorkOrder,
							json: [json],
							value: json.id
						}
					});
					dispatch({
						type: 'CREATE_WORK_ORDER_MODAL_LOCATION_UPDATE',
						payload: {
							value: json.id,
							sectionIndex: sectionIndexWorkOrder,
							fieldIndex: fieldIndexWorkOrder,
							locationObject: json,
							error
						}
					});
				}
				dispatch({
					type: 'CREATE_LOCATION_MODAL_LOADING',
					payload: {
						state: null
					}
				});
				dispatch(toggleCreateLocationModal(null, null));
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'CREATE_LOCATION_MODAL_LOADING',
					payload: {
						state: 'error'
					}
				});
			});
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_LOCATION_MODAL_LOADING',
			payload: {
				state: value
			}
		});
	};
};
