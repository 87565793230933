import React from 'react';
import {Label, Input} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';

import {updateRegistrationForm, stepComplete} from '../../services/registrationActions.js';

const InputField = (props) => (
	<div>
		<div
			style={{
				paddingTop: '0.5em'
			}}
		>
			<Label
				style={{
					backgroundColor: 'transparent',
					fontSize: '1rem',
					color: 'rgba(0,0,0,.8)',
					paddingLeft: '0'
				}}
			>
				{props.field.name}
			</Label>
		</div>
		<DebounceInput
			element={Input}
			onChange={(e) => {
				if (props.nonUSAState) {
					props.updateRegistrationForm(
						'registrationForm',
						props.stepIndex,
						props.fieldLabel,
						e.target.value.toUpperCase(),
						false
					);
				} else {
					props.updateRegistrationForm(
						'registrationForm',
						props.stepIndex,
						props.fieldLabel,
						e.target.value,
						false
					);
				}
				props.stepComplete(props.stepIndex);
			}}
			maxLength={props.nonUSAState ? 2 : false}
			type={props.type ? props.type : 'text'}
			debounceTimeout={600}
			value={props.field.value}
			style={{
				width: '100%'
			}}
			error={props.field.required ? Boolean(props.field.error) : false}
			disabled={props.field.disabled}
			placeholder={props.field.placeholder}
		/>
	</div>
);

const mapDispatchToProps = (dispatch) => {
	return {
		updateRegistrationForm: (modal, step, column, value, error) => {
			dispatch(updateRegistrationForm(modal, step, column, value, error));
		},
		stepComplete: (stepIndex) => {
			dispatch(stepComplete(stepIndex));
		}
	};
};

export default connect(null, mapDispatchToProps)(InputField);
