import React, {Component} from 'react';
import {Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class StatusCellRenderer extends Component {
	render() {
		const {data} = this.props;
		return (
			<div style={{width: '100%', textAlign: 'left', paddingLeft: '.8em'}}>
				<Icon name={data.isLead ? 'user' : 'plug'} style={{paddingRight: '1.5em'}} />
				{data.isLead ? 'Lead' : 'Opportunity'}
			</div>
		);
	}
}

StatusCellRenderer.propTypes = {
	data: PropTypes.object
};
