import React, {Component} from 'react';
import {Popup, Label, Icon} from 'semantic-ui-react';

export default class closureCommentsCellRenderer extends Component {
	render() {
		return this.props.value ? (
			<Popup
				trigger={
					<div
						style={{
							width: '100%',
							textAlign: 'left',
							paddingLeft: '.8em'
						}}
					>
						<Icon name="align justify" size="large" style={{paddingRight: '1.5em'}} />
					</div>
				}
				on={['hover', 'click']}
				flowing
				hoverable
				position="left center"
			>
				<Label>{this.props.value}</Label>
			</Popup>
		) : null;
	}
}
