import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import {Button, Icon, Segment, Input, Grid, Radio} from 'semantic-ui-react';
import moment from 'moment';
import {toggleCreationModal} from '../../../../../components/Global/services/globalActions.js';
import OptionSelectionModal from './components/modals/OptionSelectionModal.js';
import UserCreationModal from './components/modals/UserCreationModal.js';
import DeactivateUserModal from './components/modals/DeactivateUserModal.js';
import userActionsButtons from './components/actionButtons/components/buttons/userActionsButtons.js';
import BillingBreakdownCellRenderer from '~/components/ag-grid/renderers/billingBreakdownRenderer';
import RejectModal from './components/modals/rejectModal.js';
import AcceptModal from './components/modals/acceptModal.js';
import PreviouslyApprovedModal from './components/modals/PreviouslyApprovedModal.js';
import customFetch from '../../../../../lib/old/customFetch.js';
import {
	getAccountingConsoleCategoryInfo,
	selectRecord,
	toggleOptionSelectionModal,
	selectItemForApproval,
	toggleAcceptModal
} from '../../../services/accountingConsoleActions.js';
import DesyncedMessage from './components/desyncedMessage/desyncedMessage.js';

import {
	selectAccountingConsoleState,
	selectSelectedCategory,
	selectSelectedCategoryData
} from '../../../services/accountingConsole.selectors.js';

import PDFModal from './components/actionButtons/components/buttons/pdfModal.js';

const frameworkComponents = {
	userActionsButtons,
	BillingBreakdownCellRenderer
};

const BackButton = (
	<Grid.Column width={3}>
		<Link to={'/accountingConsole/view'}>
			<Button floated="left" color="red">
				<Icon name="arrow left" />
				Back to Accounting Console
			</Button>
		</Link>
	</Grid.Column>
);

class AccountingConsoleCategories extends React.Component {
	constructor() {
		super();
		this.state = {
			bulkDownloadLoading: false,
			archiveVisible: false
		};
		this.handleDownloadAllClick = this.handleDownloadAllClick.bind(this);
	}

	componentDidMount() {
		this.props.getAccountingConsoleCategoryInfo(this.props.selectedCategoryName);
		this.props.selectItemForApproval([]);
	}

	componentDidUpdate() {
		if (this.props.selectedCategoryName === 'Invoice' && this.gridApi) {
			this.columnApi.applyColumnState({sort: [{colId: 'workNumber', sort: 'desc'}]});
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
	};

	// Only allows invoices with a billId to be selected
	isRowSelectable = (rowNode) => {
		return rowNode.data ? rowNode.data.type === 'Invoice' && rowNode.data.secondaryId : false;
	};

	exportHandler(value) {
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				return params.value;
			}
		};
		this.gridApi.exportDataAsExcel(params);
	}

	acceptSelectedItems() {
		const selectedRows = this.gridApi.getSelectedRows();
		if (selectedRows.length > 0) {
			let validSelected = [];
			let invalid = [];
			selectedRows.forEach((x) => {
				if (!x.lastSyncError) {
					validSelected.push(x);
				} else {
					invalid.push(x);
				}
			});
			this.props.selectItemForApproval(validSelected, invalid);
			this.props.toggleAcceptModal(true, 'bulk');
		}
	}

	renderCategoryButton() {
		const acceptBulkButton = (
			<Button
				style={{
					marginRight: '3%'
				}}
				color="green"
				onClick={() => this.acceptSelectedItems()}
			>
				<Icon name="add" />
				Accept selected items
			</Button>
		);

		const categoryButtons = {
			Users: (
				<Button
					style={{
						marginRight: '3%'
					}}
					color="green"
					onClick={() => this.props.toggleOptionSelectionModal(true)}
				>
					<Icon name="add" />
					Add User
				</Button>
			),
			Accepted: (
				<Button
					style={{
						marginRight: '3%'
					}}
					floated="right"
					color="green"
					loading={this.state.bulkDownloadLoading}
					onClick={this.handleDownloadAllClick}
				>
					<Icon name="add" />
					Download All Selected Invoices
				</Button>
			),
			Rejections: (
				<Grid.Column width={2} style={{paddingTop: '10px', paddingLeft: '25px'}}>
					<span
						style={{
							position: 'relative',
							top: '-5px',
							left: '-10px',
							fontWeight: !this.state.archiveVisible ? 800 : 300
						}}
					>
						Rejections
					</span>
					<Radio toggle onChange={() => this.switchGridMode()} />
					<span
						style={{
							position: 'relative',
							top: '-5px',
							left: '10px',
							fontWeight: this.state.archiveVisible ? 800 : 300
						}}
					>
						Archived
					</span>
				</Grid.Column>
			),
			Invoice: acceptBulkButton,
			Project: acceptBulkButton,
			Closure: acceptBulkButton
		};

		return categoryButtons[this.props.selectedCategoryName];
	}
	renderExportButton() {
		const exportButton = (
			<Input
				style={{float: 'right', marginRight: '2%'}}
				type="text"
				id="fileNameCreateProjectModal"
				placeholder="Name file for export"
				action
			>
				<input />
				<Button
					type="submit"
					onClick={() => {
						this.exportHandler(document.getElementById('fileNameCreateProjectModal').value);
					}}
					style={{fontSize: '.95rem'}}
				>
					Export
					<Icon name="arrow right" />
				</Button>
			</Input>
		);

		const exportButtonCategories = {
			Client: exportButton,
			Invoice: exportButton,
			Project: exportButton,
			Closure: exportButton
		};

		return exportButtonCategories[this.props.selectedCategoryName];
	}

	switchGridMode() {
		const {archiveVisible} = this.state;
		this.props.getAccountingConsoleCategoryInfo(this.props.selectedCategoryName, !archiveVisible);
		this.setState({archiveVisible: !archiveVisible});
	}

	getRowId(data) {
		// needed to add 0 check because admin user has id of 0 and it was breaking filtering
		if (data.id || data.id === 0) {
			return data.id;
		} else if (data.userId) {
			return data.userId;
		} else {
			return data.workId;
		}
	}

	getRowStyle = (params) => {
		return params.data.lastSyncError ? {color: '#D0212D'} : {color: '#000'};
	};

	async handleDownloadAllClick() {
		let billIds = [];
		if (this.gridApi.getSelectedRows().length > 0) {
			let allSelected = this.gridApi.getSelectedRows();
			billIds = allSelected.map((selectedRow) => selectedRow.secondaryId);
		}

		this.setState({bulkDownloadLoading: true});

		try {
			const billIdsJoined = billIds.join(',');
			const endpoint = `${process.env.REACT_APP_API_URL}/legacy/accounting-console?billIds=${billIdsJoined}`;

			/* The Accept header is necessary here. See following documentation for more information:
			https://docs.aws.amazon.com/apigateway/latest/developerguide/api-gateway-payload-encodings-workflow.html */
			const response = await customFetch(endpoint, undefined, {
				Accept: 'application/zip'
			});
			const blob = await response.blob();
			const objectURL = URL.createObjectURL(blob);
			window.location.assign(objectURL);
			window.URL.revokeObjectURL(objectURL);
		} catch (err) {
			console.error('Fetch failed: ', err);
		}
		this.setState({bulkDownloadLoading: false});
	}

	onQuickFilterChanged(value) {
		this.gridApi.setQuickFilter(value);
	}

	render() {
		const {
			selectedCategoryName,
			toggleOptionSelectionModal,
			usersGrid,
			usersGridColumns,
			categoryData,
			categoryKeys
		} = this.props;

		const defaultColDef = {
			sortable: true,
			filter: true,
			resizable: true
		};

		const columnDefs = selectedCategoryName === 'Users' ? usersGridColumns : categoryKeys;
		const rowData = selectedCategoryName === 'Users' ? usersGrid : categoryData;

		return (
			<div>
				<RejectModal />
				<AcceptModal />
				<PreviouslyApprovedModal />
				<OptionSelectionModal />
				<UserCreationModal gridApi={this.gridApi} />
				<DeactivateUserModal />

				<div>
					<Grid
						style={{
							marginTop: '4vh'
						}}
					>
						<Grid.Row>
							{BackButton}

							<Grid.Column width={2}>
								<h2 style={{marginRight: '0'}}>{this.props.selectedCategoryName}</h2>
							</Grid.Column>

							<Grid.Column width={3}>
								<Input
									type="text"
									onChange={(e, {value}) => this.onQuickFilterChanged(value)}
									id="quickFilterCreateProjectModal"
									icon="search"
									placeholder="Filter Grid"
								/>
							</Grid.Column>

							<Grid.Column width={4}>{this.renderExportButton()}</Grid.Column>

							<Grid.Column width={3}>{this.renderCategoryButton()}</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>

				{this.props.desynced ? <DesyncedMessage /> : null}

				<Segment
					basic
					loading={this.props.loading}
					className="ag-theme-balham"
					style={{
						margin: '0 2%',
						height: '50rem',
						width: '96%'
					}}
				>
					<AgGridReact
						getRowStyle={this.getRowStyle}
						rowSelection="multiple"
						modules={AllModules}
						defaultColDef={defaultColDef}
						columnDefs={columnDefs}
						rowData={rowData}
						getRowNodeId={(data) => this.getRowId(data)}
						gridOptions={{rowHeight: 35}}
						onRowClicked={(e) => {
							this.props.selectRecord(e.data);
						}}
						onGridReady={this.onGridReady}
						paginationAutoPageSize={true}
						pagination={true}
						frameworkComponents={frameworkComponents}
						isRowSelectable={selectedCategoryName === 'Accepted' ? this.isRowSelectable : null}
					/>
				</Segment>
				<PDFModal />
			</div>
		);
	}
}

AccountingConsoleCategories.propTypes = {
	selectedCategoryName: PropTypes.string,
	getAccountingConsoleCategoryInfo: PropTypes.func,
	selectRecord: PropTypes.func,
	categoryKeys: PropTypes.array,
	categoryData: PropTypes.array,
	usersGrid: PropTypes.array,
	usersGridColumns: PropTypes.array,
	loading: PropTypes.bool,
	selectItemForApproval: PropTypes.func,
	toggleAcceptModal: PropTypes.func,
	toggleOptionSelectionModal: PropTypes.func
};

const mapStateToProps = (state) => {
	const selectedCategory = selectSelectedCategory(state);
	const selectedCategoryData = selectSelectedCategoryData(state);

	return {
		usersGrid: selectAccountingConsoleState(state).usersGrid,
		usersGridColumns: selectAccountingConsoleState(state).usersGridColumns,

		categoryKeys: selectedCategoryData.colDefs,
		categoryData: selectedCategoryData.rowData,

		selectedCategoryName: selectedCategory.name,
		loading: selectedCategory.loading,
		desynced: Boolean(selectedCategory.isDesynced)
	};
};

AccountingConsoleCategories.propTypes = {
	toggleCreationModal: PropTypes.func,
	desynced: PropTypes.number
};

AccountingConsoleCategories.defaultProps = {
	toggleCreationModal: () => null
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleAcceptModal: (isOpen, buttonId) => dispatch(toggleAcceptModal(isOpen, buttonId)),
		getAccountingConsoleCategoryInfo: (lastUrlPath, archive) => {
			dispatch(getAccountingConsoleCategoryInfo(lastUrlPath, archive));
		},
		selectItemForApproval: (record, invalid) => dispatch(selectItemForApproval(record, invalid)),
		selectRecord: (record) => {
			dispatch(selectRecord(record));
		},
		toggleCreationModal: (status) => dispatch(toggleCreationModal(status)),
		toggleOptionSelectionModal: (status) => dispatch(toggleOptionSelectionModal(status))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingConsoleCategories);
