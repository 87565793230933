import React from 'react';
import {Input, Grid, Icon, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';

class ExportGrid extends React.Component {
	exportHandler = (value) => {
		console.log(this.props);
		const gridApi = this.props.gridApi();
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function(params) {
				return params.value;
			}
		};
		gridApi.exportDataAsExcel(params);
	};

	render() {
		return (
			<Grid.Column width={8}>
				<Input
					style={{float: 'right'}}
					type="text"
					id="fileNameCreateProjectModal"
					placeholder="Name file for export"
					action
				>
					<input />
					<Button
						type="submit"
						onClick={() => {
							this.exportHandler(document.getElementById('fileNameCreateProjectModal').value);
						}}
						style={{fontSize: '.95rem'}}
					>
						Export
						<Icon name="arrow right" />
					</Button>
				</Input>
			</Grid.Column>
		);
	}
}

ExportGrid.propTypes = {
	gridApi: PropTypes.func.isRequired
};

export default ExportGrid;
