import {Segment, Grid, Popup, Menu, Icon, Label, Header, Button} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import optionFactory from './optionFactory.js';

const SelectedChoiceTaskType = (props) => {
	return (
		<Segment
			basic
			style={{
				'padding-top': '0px'
			}}
		>
			<Grid columns={3}>
				<Grid.Column textAlign="center">
					<Segment basic>
						<Popup
							content={
								props.task.asdVerification
									? 'Click to not require ASD verification'
									: 'Click to require ASD verification'
							}
							trigger={
								<Menu fluid widths={16} icon="labeled" secondary>
									<Menu.Item
										key={uuidv4()}
										disabled={props.task.completed || !props.task.id}
										as="a"
										onClick={() => {
											props.updateTask(
												props.mode,
												'asdVerification',
												!props.task.asdVerification,
												optionFactory(props)
											);

											if (!props.task.checkoutCode && props.mode !== 'templateManager') {
												props.generateCheckoutCode(props.mode, optionFactory(props));
											}
										}}
									>
										<Icon
											color="blue"
											name={props.task.asdVerification ? 'toggle on' : 'toggle off'}
										/>
										Requires ASD Verification
									</Menu.Item>
								</Menu>
							}
						/>
						{props.task[props.column] ? (
							<Popup
								trigger={
									<Label as="a">
										<Icon name="key" />
										<Label.Detail>
											{props.task.checkoutCode
												? props.task.checkoutCode.toUpperCase()
												: props.mode !== 'templateManager'
												? 'Generating...'
												: 'Code Pending'}
										</Label.Detail>
									</Label>
								}
								on="click"
								position="top right"
							>
								<Grid centered columns="equal" divided>
									<Grid.Column textAlign="center">
										<Header size="tiny">Copy Verification Code</Header>
										<Button
											icon
											size={'tiny'}
											color="blue"
											onClick={() => {
												props.copyCheckoutCode(props.task.checkoutCode);
											}}
										>
											<Icon name="copy" />
										</Button>
									</Grid.Column>
									<Grid.Column textAlign="center">
										<Header size="tiny">Generate Verification Code</Header>
										<Button
											disabled={props.task.completed || !props.task.id}
											size={'tiny'}
											onClick={() => {
												props.generateCheckoutCode(props.mode, optionFactory(props));
											}}
											icon
											color="green"
										>
											<Icon name="settings" />
										</Button>
									</Grid.Column>
								</Grid>
							</Popup>
						) : null}
					</Segment>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Segment basic>
						<Menu fluid widths={16} icon="labeled" secondary>
							<Menu.Item
								disabled={props.task.completed || !props.task.id}
								as="a"
								onClick={() => {
									props.updateTask(
										props.mode,
										'requiresOmniApproval',
										!props.task.requiresOmniApproval,
										optionFactory(props)
									);
								}}
							>
								<Icon
									color="blue"
									name={props.task.requiresOmniApproval ? 'toggle on' : 'toggle off'}
								/>
								Requires ASD Approval
							</Menu.Item>
						</Menu>
						{props.task.requiresOmniApproval ? (
							<Label
								as={props.task.approved ? null : 'a'}
								color={props.task.approved ? 'green' : null}
								onClick={() => {
									if (props.task.completed) {
										return;
									}
									let toggleApproval = () => {
										props.toggleOMNIApproval(props.mode, optionFactory(props));
									};
									if (!props.task.approved) {
										toggleApproval();
									}
								}}
							>
								<Icon name="check" />
								<Label.Detail>{props.task.approved ? 'Approved' : 'Approve'}</Label.Detail>
							</Label>
						) : null}
					</Segment>
				</Grid.Column>
				<Grid.Column textAlign="center">
					<Segment basic>
						<Menu fluid widths={16} icon="labeled" secondary>
							<Menu.Item
								disabled={props.task.completed || !props.task.id}
								as="a"
								onClick={() => {
									props.updateTask(
										props.mode,
										'customerVerification',
										!props.task.customerVerification,
										optionFactory(props)
									);
								}}
							>
								<Icon
									color="blue"
									name={props.task.customerVerification ? 'toggle on' : 'toggle off'}
								/>
								Requires Customer Verification
							</Menu.Item>
						</Menu>
					</Segment>
				</Grid.Column>
				{!props.task.id ? (
					<Grid.Row centered textAlign="center">
						<Label basic color="red">
							* checkout tasks must be saved before toggles can be used
						</Label>
					</Grid.Row>
				) : null}
			</Grid>
		</Segment>
	);
};

SelectedChoiceTaskType.propTypes = {
	task: PropTypes.object,
	toggleOMNIApproval: PropTypes.func,
	copyCheckoutCode: PropTypes.func,
	updateTask: PropTypes.func,
	mode: PropTypes.string,
	generateCheckoutCode: PropTypes.func,
	column: PropTypes.number
};

export default SelectedChoiceTaskType;
