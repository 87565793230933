import React from 'react';
import {Button, Modal, Label, Icon, Header, Grid, Dropdown, Input, Dimmer, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';

import {
	toggleBillingContactModal,
	updateBillingContactModal,
	createBillingContact,
	closeDimmer
} from './services/billingContactModalActions.js';

const BillingContactModal = (props) => (
	<Modal open={props.toggle} size={'small'}>
		<Header>
			<Icon name={'plus'} color={props.modalError ? 'red' : 'blue'} />
			<div className="content">{'Create Billing Contact'}</div>
		</Header>
		<Modal.Content scrolling={true} style={{paddingBottom: '3rem'}} className="createModalContent">
			{props.loading ? (
				<Dimmer inverted active className="centerDimmer">
					{props.loading === 'start' ? (
						<Loader size="big">Loading</Loader>
					) : props.loading === 'progress' ? (
						<Loader size="big">{'Creating Contact'}</Loader>
					) : (
						// Error handling
						<Header as="h3" icon>
							<Icon name="cancel" color="red" />
							<div style={{paddingBottom: '.5em', color: 'black'}}>Contact Creation Failed</div>
							<div style={{color: 'black'}}>Please try again.</div>
							<Button
								onClick={() => {
									props.closeDimmer(null);
								}}
								style={{marginTop: '1.5em'}}
							>
								Close Message
							</Button>
						</Header>
					)}
				</Dimmer>
			) : null}
			<Grid>
				<Grid.Row>
					{props.modalFields.map((field, fieldIndex) =>
						field.skip ? null : field.input ? (
							<Grid.Column
								width={field.split ? 8 : 16}
								key={fieldIndex}
								style={{
									paddingTop: '1rem',
									paddingBottom: '1rem'
								}}
							>
								<div>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{field.name}
									</Label>
								</div>
								<DebounceInput
									element={Input}
									onChange={(e) => {
										props.updateBillingContactModal(
											'billingContactModal',
											fieldIndex,
											e.target.value,
											false
										);
									}}
									debounceTimeout={600}
									value={field.value}
									style={{
										width: '100%'
									}}
									error={field.required ? field.error : false}
									disabled={field.disabled}
									placeholder={field.placeholder}
								/>
							</Grid.Column>
						) : (
							<Grid.Column
								width={16}
								key={fieldIndex}
								style={{
									paddingTop: '1rem',
									paddingBottom: '1rem'
								}}
							>
								<Label
									style={{
										backgroundColor: 'transparent',
										fontSize: '1rem',
										color: 'rgba(0,0,0,.8)',
										paddingLeft: '0'
									}}
								>
									{field.name}
								</Label>
								<Dropdown
									className="createModalDropdown"
									placeholder={field.placeholder}
									fluid
									search
									selection
									options={field.options}
									onChange={(e, {value}) => {
										props.updateBillingContactModal(
											'billingContactModal',
											fieldIndex,
											value,
											false
										);
									}}
									icon={field.icon}
									value={field.value}
									error={field.required ? Boolean(field.error) : false}
									disabled={field.disabled}
									upward={field.upward}
									loading={field.loading}
								/>
							</Grid.Column>
						)
					)}
				</Grid.Row>
			</Grid>
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleBillingContactModal(null, null);
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					onClick={() => {
						props.createBillingContact(props.id, props.clientId, props.modalFields, props.type, true);
					}}
					color="green"
				>
					<Icon name="checkmark" />
					{'Create'}
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

const mapStateToProps = (state) => {
	return {
		toggle: state.global.billingContactModal.billingContactModal.toggleBillingContactModal,
		loading: state.global.billingContactModal.billingContactModal.loading,
		modalFields: state.global.billingContactModal.billingContactModal.modalFields,
		id: state.global.billingContactModal.billingContactModal.id,
		modalError: state.global.billingContactModal.billingContactModal.error,
		clientId: state.global.billingContactModal.billingContactModal.clientId
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleBillingContactModal: (id, clientId) => {
			dispatch(toggleBillingContactModal(id, clientId));
		},
		updateBillingContactModal: (modal, column, value, error) => {
			dispatch(updateBillingContactModal(modal, column, value, error));
		},
		createBillingContact: (id, clientId, modalFields, type, dupCheckRequired) => {
			dispatch(createBillingContact(id, clientId, modalFields, type, dupCheckRequired));
		},
		closeDimmer: (value) => {
			dispatch(closeDimmer(value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingContactModal);
