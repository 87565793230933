import React from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
function UserCreationOptionsField(props) {
	let {fields, field, labelStyle, fieldUpdated} = props;

	return (
		<Form.Field key={`form_field_${field.key}`} required={field.isRequired}>
			<label style={labelStyle} key={`label_${field.key}`}>
				{field.label}
			</label>
			<Dropdown
				className="createModalDropdown"
				fluid
				search
				selection
				value={fields[field.key]}
				key={`dropdown_${field.key}`}
				onChange={(e, {value}) => fieldUpdated(value, field.key)}
				options={field.options}
			/>
		</Form.Field>
	);
}

export default UserCreationOptionsField;
