import update from 'react-addons-update';

/**
 * The Pipeline state object
 * @namespace
 * @memberof App.Store.Reducers
 * @since 3.0.0
 */
export default (
	state = {
		fetched: false,
		loading: true,
		filter: 'Total'
	},
	action
) => {
	switch (action.type) {
		case 'UPDATE_ACTIVITY_STREAM':
			state = update(state, {
				[action.payload.column]: {
					$set: action.payload.value
				}
			});
			break;

		case 'ADD_ACTIVITY_STREAM_EVENT':
			state = update(state, {
				events: {
					$unshift: action.payload
				}
			});
			break;

		case 'REMOVE_ACTIVITY_STREAM_EVENT':
			state = update(state, {
				events: {
					$splice: [[state.events.findIndex((event) => event.id === action.payload), 1]]
				}
			});
			break;

		case 'CLEAR_ACTIVITY_STREAM':
			state = update(state, {
				events: {
					$set: []
				},
				loading: {
					$set: true
				}
			});
			break;
	}

	return state;
};
