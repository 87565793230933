import {updatePipelineOpportunityColumn} from '../../../scenes/pipeline/services/pipelineActions.js';
import {updateLeadsOpportunityColumn} from '../../../scenes/leads/services/leadsActions.js';

export const updateOpportunityColumn = (value, oppId, place, column) => {
	return (dispatch) => {
		switch (place) {
			case 'pipeline':
				dispatch(updatePipelineOpportunityColumn(value, oppId, place, column));
				break;
			case 'leads':
				dispatch(updateLeadsOpportunityColumn(value, oppId, place, column));
				break;
			default:
		}
	};
};
