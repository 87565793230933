import React from 'react';
import {Button, Icon, Segment, Input} from 'semantic-ui-react';
import {connect} from 'react-redux';
import '../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import * as actions from '../../../../../services/workRecordActions.js';
import SelectSKUModal from '../components/selectSKUModal.js';
import { roundFloat } from '~/util/numbers';
import {SKUGrid} from '../../../../../../../../../../../components/sku/SKUGrid.jsx';
import {SKUTotals} from '../../../../../../../../../../../components/sku/SKUTotals.jsx';
import {KEY_RETURN} from 'keycode-js';

class CreateBillProjectCostingGrid extends React.Component {
	constructor() {
		super(...arguments);
	}

	componentDidMount() {
		if (this.props.originalEstimatesLoaded && !this.props.edit) {
			this.addOriginalEstimates();
		}
	}

	componentDidUpdate(prevProps) {
		if (
			//if original estimates were loaded while component was mounted
			prevProps.originalEstimatesLoaded != this.props.originalEstimatesLoaded &&
			this.props.originalEstimatesLoaded &&
			!this.props.edit
		) {
			this.addOriginalEstimates();
		}
	}

	addOriginalEstimates = () => {
		for (const est of this.props.originalEstimates) {
			this.props.addItemSpreadsheet(est);
		}
	};

	render() {
		return (
			<Segment style={{paddingBottom: '2em'}}>
				<div
					className="ag-theme-balham"
					style={{
						height: '25vh',
						width: '100%',
						textAlign: 'left',
						boxSizing: 'border-box'
					}}
				>
					<SKUGrid
						data={this.props.spreadSheetData}
						canRemove
						onRowRemove={this.props.removeRowSpreadsheet}
						onDataChange={this.props.updateBillableItem}
						loading={!this.props.originalEstimatesLoaded}
					/>
				</div>
				<div
					style={{
						marginTop: 15,
						display: 'flex',
						flexFlow: 'row nowrap',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%'
					}}
				>
					<Button.Group>
						<Button
							color="green"
							disabled={!this.props.originalEstimatesLoaded}
							onClick={() => this.props.addRowSpreadsheet()}
						>
							<Icon name="plus" /> Add Row
						</Button>
						<Button
							color="grey"
							disabled={!this.props.originalEstimatesLoaded || !this.props.SKULoaded}
							onClick={() => this.props.selectSKUModalToggle(this.props.SKUList)}
						>
							<Icon name="edit" /> Select SKUs
						</Button>
					</Button.Group>
					<div
						style={{
							display: 'flex',
							flexFlow: 'row nowrap',
							alignItems: 'center'
						}}
					>
						<div style={{margin: '1em'}}>
							<label>Material Margin</label>
						</div>
						<div style={{width: 200}}>
							<Input
								fluid
								disabled={!this.props.originalEstimatesLoaded}
								icon={{
									name: 'angle double right',
									circular: true,
									link: this.props.originalEstimatesLoaded,
									color: 'blue',
									onClick: () => this.props.applyMaterialMargin()
								}}
								value={this.props.marginAllMaterial !== null ? this.props.marginAllMaterial * 100 : ''}
								onKeyDown={(ev) => {
									if (ev.keyCode === KEY_RETURN) {
										this.props.applyMaterialMargin();
									}
								}}
								onChange={(e) => {
									const valStr = e.target.value.trim();
									if (valStr === '') {
										this.props.setMaterialMargin(null);
										return;
									}
									const val = Number.parseFloat(valStr);
									if (Number.isNaN(val) || val >= 100) {
										return;
									}
									this.props.setMaterialMargin(roundFloat(val / 100, 4));
								}}
							/>
						</div>
						<div style={{margin: '1em'}}>
							<label>Labor Margin</label>
						</div>
						<div style={{width: 200}}>
							<Input
								fluid
								disabled={!this.props.originalEstimatesLoaded}
								icon={{
									name: 'angle double right',
									circular: true,
									link: this.props.originalEstimatesLoaded,
									color: 'blue',
									onClick: () => this.props.applyLaborMargin()
								}}
								value={this.props.marginAllLabor !== null ? this.props.marginAllLabor * 100 : ''}
								onKeyDown={(ev) => {
									if (ev.keyCode === KEY_RETURN) {
										this.props.applyLaborMargin();
									}
								}}
								onChange={(e) => {
									const valStr = e.target.value.trim();
									if (valStr === '') {
										this.props.setLaborMargin(null);
										return;
									}
									const val = Number.parseFloat(valStr);
									if (Number.isNaN(val) || val >= 100) {
										return;
									}
									this.props.setLaborMargin(roundFloat(val / 100, 4));
								}}
							/>
						</div>
					</div>
				</div>
				<div style={{marginTop: 15}}>
					<SKUTotals data={this.props.spreadSheetData} />
				</div>
				<SelectSKUModal />
			</Segment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		spreadSheetData: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.spreadSheetData,
		SKUList: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.SKUs,
		SKULoaded: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.skuLoaded,
		originalEstimatesLoaded: state.work.workRecord.view.BillingCenter.originalEstimatesLoaded,
		originalEstimates: state.work.workRecord.view.BillingCenter.originalEstimates,
		marginAllLabor: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.marginAllLabor,
		marginAllMaterial: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.marginAllMaterial
	};
};

export default connect(mapStateToProps, actions)(CreateBillProjectCostingGrid);
