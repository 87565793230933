import {Card, Icon, Header, Item, Transition} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {Task, SortableTask} from './Task.js';
import {SortableSection} from './Section.js';
import TaskList from './TaskList.js';
import {SortableContainer} from 'react-sortable-hoc';

export const General = (props) => {
	let tasks = props.isSection ? props.section.tasks : props.group.tasks;
	if (!tasks.general) {
		tasks.general = [];
	}
	return (
		<Card fluid>
			<Card.Content>
				<Header as="h4" style={{paddingLeft: '.5rem'}}>
					<Icon name="tasks" />
					<Header.Content>Task List</Header.Content>
				</Header>
			</Card.Content>
			<Card.Content style={{borderTop: '1px solid rgba(34,36,38,.1)'}}>
				{tasks.general.length === 0 || tasks.general === undefined ? (
					<div style={{textAlign: 'center', color: 'grey'}}>
						<h3>
							<p>There aren't any tasks here.</p>
							<p>
								Start adding some now by clicking <em>Create Task</em>!
							</p>
						</h3>
					</div>
				) : null}
				<Transition.Group as={Item.Group} divided duration={900}>
					{tasks.general.map((task, index) => {
						return task.isSection ? (
							<SortableSection
								props={{
									...props,
									section: task,
									index,
									isSection: true,
									sectionIndex: index
								}}
								key={`section-${index}`}
								index={index}
							/>
						) : (
							<SortableTask
								props={{
									...props,
									task,
									index
								}}
								key={`${props.isSectionTask ? 'sectionTask' : 'task'}-${index}`}
								index={index}
							/>
						);
					})}
				</Transition.Group>
			</Card.Content>
		</Card>
	);
};

export const SortableGeneral = SortableContainer(({props}) => {
	return <General {...props} />;
});
