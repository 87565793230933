import {Button, Icon, Modal, Header, Loader, Dimmer, Message} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const activateUser = (props) => (
	<div>
		<Button
			{...props}
			onClick={() => props.toggleActivateUsersModal(props.techManagement.activateUsers.toggleActivateUsersModal)}
			icon
			size="medium"
			type="Button"
		>
			<Icon color="blue" name="checkmark" />
		</Button>
		<Modal
			closeIcon
			size="tiny"
			basic
			open={props.techManagement.activateUsers.toggleActivateUsersModal}
			onClose={() => props.toggleActivateUsersModal(props.techManagement.activateUsers.toggleActivateUsersModal)}
		>
			<Dimmer active={props.techManagement.activateUsers.loading} />
			<Header icon="checkmark" content="Activate user(s)" />
			<Modal.Content>
				<Loader disabled={props.techManagement.activateUsers.loading !== true} />
				<h3>
					Are you sure you want to activate the {props.techManagement.activateUsers.users.length} selected
					users?
				</h3>
				<Message
					attached="bottom"
					error
					hidden={props.techManagement.activateUsers.message.hideActivateUsersMessage}
				>
					<Icon name="help" />
					<span>{props.techManagement.activateUsers.message.text}</span>
				</Message>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="red"
					onClick={() =>
						props.toggleActivateUsersModal(props.techManagement.activateUsers.toggleActivateUsersModal)
					}
					inverted
				>
					<Icon name="remove" /> No
				</Button>
				<Button color="green" onClick={() => props.activateUsers()} inverted>
					<Icon name="checkmark" /> Yes
				</Button>
			</Modal.Actions>{' '}
		</Modal>
	</div>
);

activateUser.propTypes = {
	techManagement: PropTypes.shape({
		activateUsers: PropTypes.shape({
			loading: PropTypes.bool,
			users: PropTypes.array,
			toggleActivateUsersModal: PropTypes.bool,
			message: PropTypes.shape({
				hideActivateUsersMessage: PropTypes.bool,
				text: PropTypes.string
			})
		})
	}),
	toggleActivateUsersModal: PropTypes.func,
	activateUsers: PropTypes.func
};

export default activateUser;
