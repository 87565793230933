const countryOptions = [
	{key: 218, value: 218, flag: 'us', text: 'United States'},
	{key: 36, value: 36, flag: 'ca', text: 'Canada'},
	{key: 1, value: 1, flag: 'af', text: 'Afghanistan'},
	{key: 2, value: 2, flag: 'ax', text: 'Aland Islands'},
	{key: 3, value: 3, flag: 'al', text: 'Albania'},
	{key: 4, value: 4, flag: 'dz', text: 'Algeria'},
	{key: 5, value: 5, flag: 'as', text: 'American Samoa'},
	{key: 6, value: 6, flag: 'ad', text: 'Andorra'},
	{key: 7, value: 7, flag: 'ao', text: 'Angola'},
	{key: 8, value: 8, flag: 'ag', text: 'Antigua'},
	{key: 9, value: 9, flag: 'ar', text: 'Argentina'},
	{key: 10, value: 10, flag: 'am', text: 'Armenia'},
	{key: 11, value: 11, flag: 'aw', text: 'Aruba'},
	{key: 12, value: 12, flag: 'au', text: 'Australia'},
	{key: 13, value: 13, flag: 'at', text: 'Austria'},
	{key: 14, value: 14, flag: 'az', text: 'Azerbaijan'},
	{key: 15, value: 15, flag: 'bs', text: 'Bahamas'},
	{key: 16, value: 16, flag: 'bh', text: 'Bahrain'},
	{key: 17, value: 17, flag: 'bd', text: 'Bangladesh'},
	{key: 18, value: 18, flag: 'bb', text: 'Barbados'},
	{key: 19, value: 19, flag: 'by', text: 'Belarus'},
	{key: 20, value: 20, flag: 'be', text: 'Belgium'},
	{key: 21, value: 21, flag: 'bz', text: 'Belize'},
	{key: 22, value: 22, flag: 'bj', text: 'Benin'},
	{key: 23, value: 23, flag: 'bm', text: 'Bermuda'},
	{key: 24, value: 24, flag: 'bt', text: 'Bhutan'},
	{key: 25, value: 25, flag: 'bo', text: 'Bolivia'},
	{key: 26, value: 26, flag: 'ba', text: 'Bosnia'},
	{key: 27, value: 27, flag: 'bw', text: 'Botswana'},
	{key: 28, value: 28, flag: 'br', text: 'Brazil'},
	{key: 29, value: 29, flag: 'vg', text: 'British Virgin Islands'},
	{key: 30, value: 30, flag: 'bn', text: 'Brunei'},
	{key: 31, value: 31, flag: 'bg', text: 'Bulgaria'},
	{key: 32, value: 32, flag: 'bf', text: 'Burkina Faso'},
	{key: 33, value: 33, flag: 'bi', text: 'Burundi'},
	{key: 34, value: 34, flag: 'kh', text: 'Cambodia'},
	{key: 35, value: 35, flag: 'cm', text: 'Cameroon'},
	{key: 37, value: 37, flag: 'cv', text: 'Cape Verde'},
	{key: 38, value: 38, flag: 'ky', text: 'Cayman Islands'},
	{key: 39, value: 39, flag: 'cf', text: 'Central African Republic'},
	{key: 40, value: 40, flag: 'td', text: 'Chad'},
	{key: 41, value: 41, flag: 'cl', text: 'Chile'},
	{key: 42, value: 42, flag: 'cn', text: 'China'},
	{key: 43, value: 43, flag: 'cc', text: 'Cocos Islands'},
	{key: 44, value: 44, flag: 'co', text: 'Colombia'},
	{key: 45, value: 45, flag: 'km', text: 'Comoros'},
	{key: 46, value: 46, flag: 'cg', text: 'Congo Brazzaville'},
	{key: 47, value: 47, flag: 'cd', text: 'Congo'},
	{key: 48, value: 48, flag: 'cr', text: 'Costa Rica'},
	{key: 49, value: 49, flag: 'ci', text: 'Cote Divoire'},
	{key: 50, value: 50, flag: 'hr', text: 'Croatia'},
	{key: 51, value: 51, flag: 'cu', text: 'Cuba'},
	{key: 52, value: 52, text: 'Curacao'},
	{key: 53, value: 53, flag: 'cy', text: 'Cyprus'},
	{key: 54, value: 54, flag: 'cz', text: 'Czech Republic'},
	{key: 55, value: 55, flag: 'dk', text: 'Denmark'},
	{key: 56, value: 56, flag: 'dj', text: 'Djibouti'},
	{key: 57, value: 57, flag: 'dm', text: 'Dominica'},
	{key: 58, value: 58, flag: 'do', text: 'Dominican Republic'},
	{key: 59, value: 59, text: 'East Timor'},
	{key: 60, value: 60, flag: 'ec', text: 'Ecuador'},
	{key: 61, value: 61, flag: 'eg', text: 'Egypt'},
	{key: 62, value: 62, flag: 'sv', text: 'El Salvador'},
	{key: 63, value: 63, flag: 'gq', text: 'Equatorial Guinea'},
	{key: 64, value: 64, flag: 'er', text: 'Eritrea'},
	{key: 65, value: 65, flag: 'ee', text: 'Estonia'},
	{key: 66, value: 66, flag: 'et', text: 'Ethiopia'},
	{key: 67, value: 67, flag: 'fk', text: 'Falkland Islands'},
	{key: 68, value: 68, flag: 'fo', text: 'Faroe Islands'},
	{key: 69, value: 69, flag: 'fj', text: 'Fiji'},
	{key: 70, value: 70, flag: 'fi', text: 'Finland'},
	{key: 71, value: 71, flag: 'fr', text: 'France'},
	{key: 72, value: 72, flag: 'gf', text: 'French Guiana'},
	{key: 73, value: 73, flag: 'pf', text: 'French Polynesia'},
	{key: 74, value: 74, flag: 'ga', text: 'Gabon'},
	{key: 75, value: 75, flag: 'gm', text: 'Gambia'},
	{key: 76, value: 76, flag: 'ge', text: 'Georgia'},
	{key: 77, value: 77, flag: 'de', text: 'Germany'},
	{key: 78, value: 78, flag: 'gh', text: 'Ghana'},
	{key: 79, value: 79, flag: 'gi', text: 'Gibraltar'},
	{key: 80, value: 80, flag: 'gr', text: 'Greece'},
	{key: 81, value: 81, flag: 'gl', text: 'Greenland'},
	{key: 82, value: 82, flag: 'gd', text: 'Grenada'},
	{key: 83, value: 83, flag: 'gp', text: 'Guadeloupe'},
	{key: 84, value: 84, flag: 'gu', text: 'Guam'},
	{key: 85, value: 85, flag: 'gt', text: 'Guatemala'},
	{key: 86, value: 86, text: 'Guernsey'},
	{key: 87, value: 88, flag: 'gn', text: 'Guinea'},
	{key: 88, value: 88, flag: 'gw', text: 'Guinea-Bissau'},
	{key: 89, value: 89, flag: 'gy', text: 'Guyana'},
	{key: 90, value: 90, flag: 'ht', text: 'Haiti'},
	{key: 91, value: 91, flag: 'hn', text: 'Honduras'},
	{key: 92, value: 92, flag: 'hk', text: 'Hong Kong'},
	{key: 93, value: 93, flag: 'hu', text: 'Hungary'},
	{key: 94, value: 94, flag: 'is', text: 'Iceland'},
	{key: 95, value: 95, flag: 'in', text: 'India'},
	{key: 96, value: 96, flag: 'id', text: 'Indonesia'},
	{key: 97, value: 97, flag: 'ir', text: 'Iran'},
	{key: 98, value: 98, flag: 'iq', text: 'Iraq'},
	{key: 99, value: 99, flag: 'ie', text: 'Ireland'},
	{key: 100, value: 100, text: 'Isle of Man'},
	{key: 101, value: 101, flag: 'il', text: 'Israel'},
	{key: 102, value: 102, flag: 'it', text: 'Italy'},
	{key: 103, value: 103, flag: 'jm', text: 'Jamaica'},
	{key: 104, value: 104, flag: 'jp', text: 'Japan'},
	{key: 105, value: 105, text: 'Jersey'},
	{key: 106, value: 106, flag: 'jo', text: 'Jordan'},
	{key: 107, value: 107, flag: 'kz', text: 'Kazakhstan'},
	{key: 108, value: 108, flag: 'ke', text: 'Kenya'},
	{key: 109, value: 109, flag: 'ki', text: 'Kiribati'},
	{key: 110, value: 110, flag: 'kw', text: 'Kuwait'},
	{key: 111, value: 111, flag: 'kg', text: 'Kyrgyzstan'},
	{key: 112, value: 112, flag: 'la', text: 'Laos'},
	{key: 113, value: 113, flag: 'lv', text: 'Latvia'},
	{key: 114, value: 115, flag: 'lb', text: 'Lebanon'},
	{key: 116, value: 116, flag: 'ls', text: 'Lesotho'},
	{key: 117, value: 117, flag: 'lr', text: 'Liberia'},
	{key: 118, value: 118, flag: 'ly', text: 'Libya'},
	{key: 119, value: 119, flag: 'li', text: 'Liechtenstein'},
	{key: 120, value: 120, flag: 'lt', text: 'Lithuania'},
	{key: 121, value: 121, flag: 'lu', text: 'Luxembourg'},
	{key: 122, value: 122, flag: 'mo', text: 'Macau'},
	{key: 123, value: 123, flag: 'mk', text: 'Macedonia'},
	{key: 124, value: 124, flag: 'mg', text: 'Madagascar'},
	{key: 125, value: 125, flag: 'mw', text: 'Malawi'},
	{key: 126, value: 126, flag: 'my', text: 'Malaysia'},
	{key: 127, value: 127, flag: 'mv', text: 'Maldives'},
	{key: 128, value: 128, flag: 'ml', text: 'Mali'},
	{key: 129, value: 129, flag: 'mt', text: 'Malta'},
	{key: 130, value: 130, flag: 'mh', text: 'Marshall Islands'},
	{key: 131, value: 131, flag: 'mq', text: 'Martinique'},
	{key: 132, value: 132, flag: 'mr', text: 'Mauritania'},
	{key: 133, value: 133, flag: 'mu', text: 'Mauritius'},
	{key: 134, value: 134, flag: 'yt', text: 'Mayotte'},
	{key: 135, value: 135, flag: 'mx', text: 'Mexico'},
	{key: 136, value: 136, flag: 'fm', text: 'Micronesia'},
	{key: 137, value: 137, flag: 'md', text: 'Moldova'},
	{key: 138, value: 138, flag: 'mc', text: 'Monaco'},
	{key: 139, value: 139, flag: 'mn', text: 'Mongolia'},
	{key: 140, value: 140, flag: 'me', text: 'Montenegro'},
	{key: 141, value: 141, flag: 'ma', text: 'Morocco'},
	{key: 142, value: 142, flag: 'mz', text: 'Mozambique'},
	{key: 143, value: 143, text: 'Myanmar'},
	{key: 144, value: 144, flag: 'na', text: 'Namibia'},
	{key: 145, value: 145, flag: 'nr', text: 'Nauru'},
	{key: 146, value: 146, flag: 'np', text: 'Nepal'},
	{key: 147, value: 147, flag: 'nl', text: 'Netherlands'},
	{key: 148, value: 148, flag: 'nc', text: 'New Caledonia'},
	{key: 149, value: 149, flag: 'nz', text: 'New Zealand'},
	{key: 150, value: 150, flag: 'ni', text: 'Nicaragua'},
	{key: 151, value: 151, flag: 'ne', text: 'Niger'},
	{key: 152, value: 152, flag: 'ng', text: 'Nigeria'},
	{key: 153, value: 153, flag: 'kp', text: 'North Korea'},
	{key: 154, value: 154, flag: 'mp', text: 'Northern Mariana Islands'},
	{key: 155, value: 155, flag: 'no', text: 'Norway'},
	{key: 156, value: 156, flag: 'om', text: 'Oman'},
	{key: 157, value: 157, flag: 'pk', text: 'Pakistan'},
	{key: 158, value: 158, flag: 'pw', text: 'Palau'},
	{key: 159, value: 159, flag: 'pa', text: 'Panama'},
	{key: 160, value: 160, flag: 'pa', text: 'Papua New Guinea'},
	{key: 161, value: 161, flag: 'py', text: 'Paraguay'},
	{key: 162, value: 162, flag: 'pe', text: 'Peru'},
	{key: 163, value: 163, flag: 'ph', text: 'Philippines'},
	{key: 164, value: 164, flag: 'pl', text: 'Poland'},
	{key: 165, value: 165, flag: 'pt', text: 'Portugal'},
	{key: 166, value: 166, flag: 'pr', text: 'Puerto Rico'},
	{key: 167, value: 167, flag: 'qa', text: 'Qatar'},
	{key: 168, value: 168, flag: 're', text: 'Reunion'},
	{key: 169, value: 169, flag: 'ro', text: 'Romania'},
	{key: 170, value: 170, flag: 'ru', text: 'Russia'},
	{key: 171, value: 171, flag: 'rw', text: 'Rwanda'},
	{key: 172, value: 172, flag: 'sh', text: 'Saint Barthélemy'},
	{key: 173, value: 173, flag: 'kn', text: 'Saint Kitts and Nevis'},
	{key: 174, value: 174, flag: 'lc', text: 'Saint Lucia'},
	{key: 175, value: 175, text: 'Saint Martin'},
	{key: 176, value: 176, flag: 'pm', text: 'Saint Pierre'},
	{key: 177, value: 177, flag: 'vc', text: 'Saint Vincent'},
	{key: 178, value: 178, flag: 'ws', text: 'Samoa'},
	{key: 179, value: 179, flag: 'sm', text: 'San Marino'},
	{key: 180, value: 180, flag: 'st', text: 'Sao Tome'},
	{key: 181, value: 181, flag: 'sa', text: 'Saudi Arabia'},
	{key: 182, value: 182, flag: 'sn', text: 'Senegal'},
	{key: 183, value: 183, flag: 'rs', text: 'Serbia'},
	{key: 184, value: 184, flag: 'sc', text: 'Seychelles'},
	{key: 185, value: 185, flag: 'sl', text: 'Sierra Leone'},
	{key: 186, value: 186, flag: 'sg', text: 'Singapore'},
	{key: 187, value: 187, flag: 'sk', text: 'Slovakia'},
	{key: 188, value: 188, flag: 'si', text: 'Slovenia'},
	{key: 189, value: 189, flag: 'sb', text: 'Solomon Islands'},
	{key: 190, value: 190, flag: 'so', text: 'Somalia'},
	{key: 191, value: 191, flag: 'za', text: 'South Africa'},
	{key: 192, value: 192, flag: 'kr', text: 'South Korea'},
	{key: 193, value: 193, flag: 'es', text: 'Spain'},
	{key: 194, value: 194, flag: 'lk', text: 'Sri Lanka'},
	{key: 195, value: 195, flag: 'sd', text: 'Sudan'},
	{key: 196, value: 196, flag: 'sr', text: 'Suriname'},
	{key: 197, value: 197, flag: 'sj', text: 'Svalbard'},
	{key: 198, value: 198, flag: 'sz', text: 'Swaziland'},
	{key: 199, value: 199, flag: 'se', text: 'Sweden'},
	{key: 200, value: 200, flag: 'ch', text: 'Switzerland'},
	{key: 201, value: 201, flag: 'sy', text: 'Syria'},
	{key: 202, value: 202, flag: 'tw', text: 'Taiwan'},
	{key: 203, value: 203, flag: 'tj', text: 'Tajikistan'},
	{key: 204, value: 204, flag: 'tz', text: 'Tanzania'},
	{key: 205, value: 205, flag: 'th', text: 'Thailand'},
	{key: 206, value: 206, flag: 'tg', text: 'Togo'},
	{key: 207, value: 207, flag: 'to', text: 'Tonga'},
	{key: 208, value: 208, flag: 'tt', text: 'Trinidad'},
	{key: 209, value: 209, flag: 'tn', text: 'Tunisia'},
	{key: 210, value: 210, flag: 'tr', text: 'Turkey'},
	{key: 211, value: 211, flag: 'tm', text: 'Turkmenistan'},
	{key: 212, value: 212, flag: 'tm', text: 'Turks and Caicos'},
	{key: 213, value: 213, flag: 'tv', text: 'Tuvalu'},
	{key: 214, value: 214, flag: 'ug', text: 'Uganda'},
	{key: 215, value: 215, flag: 'ua', text: 'Ukraine'},
	{key: 216, value: 216, flag: 'ae', text: 'United Arab Emirates'},
	{key: 217, value: 217, flag: 'ae', text: 'United Kingdom'},
	{key: 219, value: 219, flag: 'uy', text: 'Uruguay'},
	{key: 220, value: 220, flag: 'vi', text: 'Us Virgin Islands'},
	{key: 221, value: 221, flag: 'uz', text: 'Uzbekistan'},
	{key: 222, value: 222, flag: 'vu', text: 'Vanuatu'},
	{key: 223, value: 223, flag: 'va', text: 'Vatican City'},
	{key: 224, value: 224, flag: 've', text: 'Venezuela'},
	{key: 225, value: 225, flag: 'vn', text: 'Vietnam'},
	{key: 226, value: 226, flag: 'wf', text: 'Wallis and Futuna'},
	{key: 227, value: 227, flag: 'eh', text: 'Western Sahara'},
	{key: 228, value: 228, flag: 'ye', text: 'Yemen'},
	{key: 229, value: 229, flag: 'zm', text: 'Zambia'},
	{key: 230, value: 230, flag: 'zw', text: 'Zimbabwe'}
];

export default countryOptions;
