import React from 'react';

const AutofillData = (props) => (
	<div>
		{props.field.locationSelected ? (
			<ul
				style={{
					listStyle: 'none',
					paddingLeft: '0em',
					marginTop: '1em',
					marginBottom: '0'
				}}
			>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Name:
					</span>
					{` ${props.field.selectedLocation.name}`}
				</li>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Store Number:
					</span>
					{` ${props.field.selectedLocation.storeNumber}`}
				</li>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Address:
					</span>
					{` ${props.field.selectedLocation.address} ${
						props.field.selectedLocation.address2 ? `, ${props.field.selectedLocation.address2}` : ''
					}`}
				</li>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						City:
					</span>
					{` ${props.field.selectedLocation.city}, ${props.field.selectedLocation.state}, ${props.field.selectedLocation.zip}`}
				</li>
			</ul>
		) : props.field.billingContactSelected ? (
			<ul
				style={{
					listStyle: 'none',
					paddingLeft: '0em',
					marginTop: '1em',
					marginBottom: '0'
				}}
			>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Name:
					</span>
					{` ${props.field.selectedBillingContact.fullName}`}
				</li>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Title:
					</span>
					{` ${props.field.selectedBillingContact.title}`}
				</li>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Email:
					</span>
					{` ${props.field.selectedBillingContact.email}`}
				</li>
				<li style={{marginTop: '.3em'}}>
					<span
						style={{
							fontWeight: 'bold'
						}}
					>
						Phone:
					</span>
					{` ${props.field.selectedBillingContact.phone} ${
						props.field.selectedBillingContact.extension
							? ` ext. ${props.field.selectedBillingContact.extension}`
							: ''
					}`}
				</li>
			</ul>
		) : null}
	</div>
);

export default AutofillData;
