import React from 'react';
import {
	Button,
	Modal,
	Icon,
	Header,
	Grid,
	Step,
	Label,
	Dropdown,
	Input,
	TextArea,
	Dimmer,
	Loader,
	Form
} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {
	toggleCreateAasdiModal,
	setActiveStep,
	updateCreateModal,
	addDocument,
	removeCreateAASDIDocumentGrid,
	switchStateInputType
} from '../services/WorkAasdiGridActions.js';
import {debounce, debounceEventHandler} from '../../../../../lib/old/debounce.js';
import DatePicker from 'react-datepicker';
import * as actions from '../services/WorkAasdiGridActions.js';
import {DebounceInput} from 'react-debounce-input';
import DocumentSectionRemoveButton from './documentSectionRemoveButton.js';
import AgGrid from '../../../../../components/Global/AgGrid.js';
import {createAasdiDocumentsHeader} from '../services/WorkAasdiGridReducer.js';
import {MomentDatePicker} from '../../../../../components/inputs/DatePicker.jsx';

const frameworkComponents = {
	documentSectionRemoveButton: DocumentSectionRemoveButton
};

class CreateAasdiModal extends React.Component {
	constructor(props) {
		super(props);
	}

	closeModal() {
		this.props.toggleCreateAasdiModal(false);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	render() {
		const {documentsData} = this.props;
		return (
			<Modal size="large" open={this.props.isOpen}>
				<Header>
					<Icon name="plus" color="green" />
					<div className="content">{this.props.stepsAasdi.modalTitle}</div>
					<Step.Group widths={4} size="small">
						{this.props.stepsAasdi.modalSteps.map((modalStep, index) => (
							<Step
								key={modalStep.name}
								icon={modalStep.icon}
								// description: modal.description,
								title={modalStep.name}
								active={modalStep.active}
								link={true}
								onClick={() => this.props.setActiveStep(this.props.activeStep, index)}
							/>
						))}
					</Step.Group>
				</Header>
				{this.props.modalError ? (
					<Modal.Content scrolling style={{height: '35vh'}}>
						<Dimmer active inverted>
							<Grid textAlign="center" style={{marginTop: '10vh'}}>
								<Grid.Row>
									<Icon name="x" color="red" size="massive" />
								</Grid.Row>
								<Grid.Row>
									<Label basic size="big" color="red">
										{this.props.modalErrorMessage}
									</Label>
								</Grid.Row>
								<Grid.Row>
									<Button
										onClick={() => {
											this.props.closeError();
										}}
									>
										Close
									</Button>
								</Grid.Row>
							</Grid>
						</Dimmer>
					</Modal.Content>
				) : this.props.modalLoading ? (
					<Modal.Content style={{height: '35vh'}}>
						<Dimmer active inverted>
							<Loader inverted>Creating AASDI</Loader>
						</Dimmer>
					</Modal.Content>
				) : (
					<Modal.Content scrolling>
						{this.props.stepsAasdi.modalSteps.map((modal, stepIndex) =>
							modal.active ? (
								<Grid key={stepIndex}>
									<Grid.Row>
										{modal.modalFields.map((field, fieldIndex) =>
											field.input ? (
												<Grid.Column key={fieldIndex} width={5}>
													<div
														style={{
															paddingTop: '0.5em'
														}}
													>
														<Label
															style={{
																backgroundColor: 'transparent',
																fontSize: '1rem',
																color: 'rgba(0,0,0,.8)',
																paddingLeft: '0'
															}}
														>
															{field.name}
														</Label>
													</div>
													<DebounceInput
														element={Input}
														mask={field.mask}
														onChange={(e) => {
															if (field.name === 'State') {
																this.props.updateCreateModal(
																	'stepsAasdi',
																	stepIndex,
																	fieldIndex,
																	e.target.value.toUpperCase(),
																	false
																);
															} else {
																this.props.updateCreateModal(
																	'stepsAasdi',
																	stepIndex,
																	fieldIndex,
																	e.target.value,
																	false
																);
															}
														}}
														debounceTimeout={600}
														maxLength={field.name === 'State' ? 2 : false}
														value={field.value}
														style={{
															width: '100%'
														}}
														error={field.required ? field.error : false}
														disabled={field.disabled}
														placeholder={field.placeholder}
													/>
												</Grid.Column>
											) : field.datePicker ? (
												<Grid.Column key={fieldIndex} width={5}>
													<Grid.Row>
														<Label
															style={{
																backgroundColor: 'transparent',
																fontSize: '1rem',
																color: 'rgba(0,0,0,.8)',
																paddingLeft: '0'
															}}
														>
															{field.name}
														</Label>
													</Grid.Row>
													<Grid.Row>
														<MomentDatePicker
															className={
																field.error
																	? 'createAasdiDatePicker sendToModalDatePickerError'
																	: 'createAasdiDatePicker'
															}
															showTimeSelect
															timeFormat="HH:mm"
															timeIntervals={5}
															dateFormat="LLL"
															timeCaption="time"
															selected={field.value}
															placeholder={field.placeholder}
															fluid
															search
															selection
															disabled={field.disabled}
															loading={field.loading}
														/>
													</Grid.Row>
												</Grid.Column>
											) : field.dropdown ? (
												// <Grid.Row key={fieldIndex}>
												<Grid.Column key={fieldIndex} width={5}>
													<div
														style={{
															paddingTop: '0.5em'
														}}
													>
														<Label
															style={{
																backgroundColor: 'transparent',
																fontSize: '1rem',
																color: 'rgba(0,0,0,.8)',
																paddingLeft: '0'
															}}
														>
															{field.name}
														</Label>
														<Dropdown
															className="createModalDropdown"
															placeholder={field.placeholder}
															fluid
															search
															selection
															options={field.options}
															onChange={(e, {value}) => {
																console.log(`value ==/= ${value}`);
																this.props.updateCreateModal(
																	'stepsAasdi',
																	stepIndex,
																	fieldIndex,
																	value,
																	false
																);
																if (field.name === 'Country') {
																	this.props.switchStateInputType(stepIndex, value);
																}
															}}
															onSearchChange={debounceEventHandler(
																debounce((e) => {
																	if (e.target.value) {
																		if (field.searchAE) {
																			this.props.getAccountExecs(
																				'stepsAasdi',
																				stepIndex,
																				fieldIndex,
																				e.target.value
																			);
																		} else if (field.searchMC) {
																			this.props.getMainContacts(
																				'stepsAasdi',
																				stepIndex,
																				fieldIndex,
																				e.target.value
																			);
																		}
																	}
																}, 600)
															)}
															icon={field.icon}
															value={field.value}
															error={field.required ? field.error : false}
															disabled={field.disabled}
															upward={field.upward}
															loading={field.loading}
															defaultSearchQuery={field.searchQuery}
														/>
													</div>
												</Grid.Column>
											) : field.textArea ? (
												<Grid.Column width={field.columnWidth}>
													<div>
														<div
															style={{
																paddingTop: '0.8em'
															}}
														>
															<Label
																style={{
																	backgroundColor: 'transparent',
																	fontSize: '1rem',
																	color: 'rgba(0,0,0,.8)',
																	paddingLeft: '0'
																}}
															>
																{field.name}
															</Label>
														</div>
														<DebounceInput
															element={TextArea}
															onChange={(e) => {
																this.props.updateCreateModal(
																	'stepsAasdi',
																	stepIndex,
																	fieldIndex,
																	e.target.value,
																	false
																);
															}}
															debounceTimeout={600}
															value={field.value}
															style={{
																width: '100%',
																border: '1px solid rgba(34,36,38,.15)',
																color: 'rgba(0,0,0,.87)',
																borderRadius: '.28571429rem'
															}}
															error={field.required ? field.error : false}
															disabled={field.disabled}
															placeholder={field.placeholder}
														/>
													</div>
												</Grid.Column>
											) : null
										)}
									</Grid.Row>
									{modal.name === 'Documents' ? (
										<div style={{width: '95%'}}>
											<Grid.Row>
												<Grid.Column
													width={15}
													style={{
														paddingTop: '0.8em'
													}}
												>
													<Button
														primary
														onClick={() =>
															this.props.addDocument(
																modal.modalFields[0],
																modal.modalFields[1],
																modal.modalFields[2],
																modal.modalFields[3],
																stepIndex
															)
														}
													>
														<Icon name="plus" />
														Add Document
													</Button>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row
												style={{
													paddingTop: '0.8em'
												}}
											>
												<Grid.Column width={15}>
													<AgGrid
														columnDefs={createAasdiDocumentsHeader}
														rowData={documentsData}
														onGridReady={this.onGridReady}
														frameworkComponents={frameworkComponents}
														gridStyle={{
															height: '30vh'
														}}
														{...this.props}
													/>
												</Grid.Column>
											</Grid.Row>
											{modal.modalFields.map((field, fieldIndex) =>
												field.checkbox ? (
													<Grid.Row
														style={{
															paddingTop: '0.8em'
														}}
													>
														<Grid.Column>
															<Form>
																<Form.Checkbox
																	checked={field.value}
																	label={field.name}
																	onChange={(e, {checked}) => {
																		this.props.updateCreateModal(
																			'stepsAasdi',
																			stepIndex,
																			fieldIndex,
																			checked,
																			false
																		);
																	}}
																	error={
																		field.required ? Boolean(field.error) : false
																	}
																/>
															</Form>
														</Grid.Column>
													</Grid.Row>
												) : null
											)}
										</div>
									) : null}
								</Grid>
							) : null
						)}
					</Modal.Content>
				)}
				<Modal.Actions>
					<Button.Group>
						<Button
							onClick={() => {
								this.props.toggleCreateAasdiModal(false);
							}}
							color="red"
							disabled={this.props.modalLoading}
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							onClick={() => {
								this.props.createNewAasdi();
							}}
							color="green"
							disabled={this.props.modalLoading}
						>
							<Icon name="checkmark" /> Create
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

CreateAasdiModal.propTypes = {
	isOpen: PropTypes.bool,
	id: PropTypes.number,
	toggleCreateContactModal: PropTypes.func,
	modalTitle: PropTypes.string,
	toggleCreateAasdiModal: PropTypes.func,
	updateCreateModal: PropTypes.func,
	setActiveStep: PropTypes.func,
	modalError: PropTypes.bool,
	modalErrorMessage: PropTypes.string
};

const mapStateToProps = (state) => ({
	isOpen: state.work.aasdiGrid.showCreateAasdiModal,
	activeStep: state.work.aasdiGrid.stepsAasdi.modalSteps
		.map((modalStep, index) => ({modalStep: modalStep, index: index}))
		.filter((modalStep) => modalStep.modalStep.active)[0].index,
	documentsData: state.work.aasdiGrid.stepsAasdi.modalSteps[2].documents,
	modalLoading: state.work.aasdiGrid.stepsAasdi.loading,
	modalError: state.work.aasdiGrid.stepsAasdi.error,
	modalErrorMessage: state.work.aasdiGrid.stepsAasdi.errorMessage,
	countrySelected:
		state.work.aasdiGrid.stepsAasdi.modalSteps[0].modalFields[
			state.work.aasdiGrid.stepsAasdi.modalSteps[0].modalFields.findIndex((field) => field.name === 'Country')
		].value
	// aasdiData: state.work.aasdiGrid.stepsAasdi.modalStep
});

const mapDispatchToProps = (dispatch) => ({
	toggleCreateAasdiModal: (status) => dispatch(toggleCreateAasdiModal(status)),
	updateCreateModal: (modal, step, column, value, error) => {
		dispatch(updateCreateModal(modal, step, column, value, error));
	},
	switchStateInputType: (stepIndex, value) => {
		dispatch(switchStateInputType(stepIndex, value));
	},
	setActiveStep: (currentStep, targetStep) => dispatch(setActiveStep(currentStep, targetStep)),
	addDocument: (fileName, googleDrive, docType, comments, sectionIndex) =>
		dispatch(addDocument(fileName, googleDrive, docType, comments, sectionIndex)),
	removeCreateAASDIDocumentGrid: (rowIndex, sextionIndex) =>
		dispatch(removeCreateAASDIDocumentGrid(rowIndex, sextionIndex))
});

// const mapDispatchToProps = dispatch => ({
// 	toggleCreateAasdiModal: status => dispatch(toggleCreateAasdiModal(status)),
// 	updateCreateModal: (modal, step, column, value, error) => {
// 		dispatch(updateCreateModal(modal, step, column, value, error));
// 	},
// 	setActiveStep: (currentStep, targetStep) =>
// 		dispatch(setActiveStep(currentStep, targetStep))
// });

export default connect(mapStateToProps, actions)(CreateAasdiModal);
