import React from 'react';
import {connect} from 'react-redux';
import {Checkbox} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {updateAasdiViewDocument} from '../services/profileAasdiGridActions.js';

// const AasdiDocumentSectionCheckbox = (props) => {
class AasdiDocumentSectionCheckbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			documentsData:
				window.location.href.indexOf('/client/profile/') !== -1
					? this.props.clientDocumentsData
					: this.props.aasdiDocumentsData,
			type: window.location.href.indexOf('/client/profile/') !== -1 ? 'client' : 'aasdi'
		};
	}

	render() {
		return (
			<div style={{paddingTop: '.2em'}}>
				<Checkbox
					label={this.state.documentsData[this.props.rowIndex].customerApproval ? 'Yes' : 'No'}
					checked={this.state.documentsData[this.props.rowIndex].customerApproval}
					onClick={() => {
						this.props.updateAasdiViewDocument(
							this.props.rowIndex,
							this.state.documentsData[this.props.rowIndex],
							this.state.type
						);
					}}
				/>
			</div>
		);
	}
}

AasdiDocumentSectionCheckbox.propTypes = {
	aasdiDocumentsData: PropTypes.object,
	clientDocumentsData: PropTypes.object,
	rowIndex: PropTypes.number,
	updateAasdiViewDocument: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		aasdiDocumentsData: state.work.aasdiProfileGrid.rowData,
		clientDocumentsData: state.work.clientProfileGrid.rowData
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateAasdiViewDocument: (index, value, type) => {
			dispatch(updateAasdiViewDocument(index, value, type));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AasdiDocumentSectionCheckbox);
