import React from 'react';
import {Label, Dropdown, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as actions from '../../services/createWorkOrderActions.js';

class DropdownField extends React.Component {
	constructor(props) {
		super(...arguments);
		this.searchChange = _.debounce(this.searchChange, 600);
	}

	searchChange = (val) => {
		if (val) {
			if (this.props.field.employeeSearch) {
				this.props.getWorkOrderEmployees(this.props.sectionIndex, this.props.fieldIndex, val);
			} else if (this.props.field.searchClient) {
				this.props.getWorkOrderClients(this.props.sectionIndex, this.props.fieldIndex, val);
			} else if (this.props.field.searchTemplate) {
				this.props.getWorkOrderTemplates(this.props.sectionIndex, this.props.fieldIndex, val);
			} else if (this.props.field.searchDrafts) {
				this.props.getWorkOrderDrafts(this.props.sectionIndex, this.props.fieldIndex, val);
			} else if (this.props.field.searchQJN) {
				this.props.getQuotedJobNumber(this.props.sectionIndex, this.props.fieldIndex, val);
			}
		}
	};

	valueChange = (value, options) => {
		const {
			sectionIndex,
			fieldIndex,
			field,
			applyTemplateOrDraft,
			updateCreateWorkOrderModal,
			autopopulateWorkOrderBranch
		} = this.props;

		if (field.searchTemplate) {
			applyTemplateOrDraft(value);
		} else {
			updateCreateWorkOrderModal(
				'createWorkOrderModal',
				sectionIndex,
				fieldIndex,
				value,
				false,
				field.name === 'Client' ? 'Client' : null
			);

			if (field.name === 'Sales Representative') {
				const branchId = options.find((option) => option.value === value).branchId;
				autopopulateWorkOrderBranch(branchId);
			}
		}
	};

	componentDidUpdate() {
		const opts = this.props.field.options || [];
		if (opts.length === 1 && opts[0].text === this.props.field.search) {
			this.valueChange(opts[0].value);
		}
	}

	render() {
		let labelStyle = {
			backgroundColor: 'transparent',
			fontSize: '1rem',
			color: 'rgba(0,0,0,.8)',
			paddingLeft: '0'
		};

		return (
			<div style={{paddingTop: '1.2em'}}>
				<Label
					style={
						this.props.templateSection
							? {
									display: 'inline',
									paddingRight: '2em',
									...labelStyle
							  }
							: labelStyle
					}
				>
					{this.props.field.name}
				</Label>

				<Dropdown
					style={
						this.props.templateSection
							? {
									display: 'inline-block',
									width: '60%',
									borderRadius: 0,
									fontWeight: 'normal'
							  }
							: {}
					}
					className="createWorkOrderClientDropdown"
					placeholder={this.props.field.placeholder}
					fluid
					search
					selection
					closeOnChange
					options={this.props.field.options}
					onChange={(e, {value, options}) => {
						if (e.type === 'blur') {
							return;
						}
						this.valueChange(value, options);
					}}
					onSearchChange={(ev) => {
						const value = ev.target.value;
						this.searchChange(value);
					}}
					icon={this.props.field.icon}
					value={this.props.field.value}
					disabled={this.props.field.disabled}
					error={this.props.field.required ? this.props.field.error : false}
					upward={this.props.field.upward}
					loading={this.props.field.loading}
					defaultSearchQuery={this.props.field.searchQuery}
				/>

				{this.props.field.serviceTypeField && this.props.field.value === 4 ? (
					<Dropdown
						className="createWorkOrderClientDropdown"
						placeholder={this.props.serviceTypeOptions.placeholder}
						fluid
						search
						selection
						options={this.props.serviceTypeOptions.options}
						onChange={(e, {value}) => {
							this.props.updateCreateWorkOrderModal(
								'createWorkOrderModal',
								this.props.sectionIndex,
								this.props.serviceTypeIndex,
								value,
								false,
								null
							);
						}}
						icon={this.props.serviceTypeOptions.icon}
						value={this.props.serviceTypeOptions.value}
						error={Boolean(this.props.serviceTypeOptions.error)}
						disabled={this.props.serviceTypeOptions.disabled}
						upward={this.props.serviceTypeOptions.upward}
						loading={this.props.serviceTypeOptions.loading}
						defaultSearchQuery={this.props.serviceTypeOptions.searchQuery}
					/>
				) : null}

				{this.props.templateSection ? (
					<Button
						/// DISABLED WHILE TEMPLATES IS IN PROGRESS
						disabled={false}
						icon="angle right"
						color="blue"
						attached="right"
						style={{
							padding: '.87571429em .78571429em .87571429em'
						}}
						onClick={() =>
							this.props.field.value ? this.props.applyTemplateOrDraft(this.props.field.value) : null
						}
					/>
				) : null}
			</div>
		);
	}
}

DropdownField.propTypes = {
	getWorkOrderClients: PropTypes.func,
	field: PropTypes.object,
	applyTemplateOrDraft: PropTypes.func,
	serviceTypeOptions: PropTypes.object,
	updateCreateWorkOrderModal: PropTypes.object,
	sectionIndex: PropTypes.number,
	getWorkOrderDrafts: PropTypes.func,
	getQuotedJobNumber: PropTypes.func,
	getWorkOrderTemplates: PropTypes.func,
	getWorkOrderEmployees: PropTypes.func,
	fieldIndex: PropTypes.number,
	templateSection: PropTypes.bool,
	serviceTypeIndex: PropTypes.number
};

export default connect(null, actions)(DropdownField);
