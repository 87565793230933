import {Card, Icon, Header, Item} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {Task} from './Task.js';

const Pinned = (props) => {
	let tasks = props.isSection ? props.section.tasks : props.group.tasks;
	if (!tasks || typeof tasks === 'undefined' || tasks === null) {
		return <div />;
	}
	if (!tasks.pinned) {
		tasks.pinned = [];
	}
	return tasks.pinned.length > 0 ? (
		<Card fluid>
			<Card.Content>
				<Header as="h4" style={{paddingLeft: '.5rem'}}>
					<Icon name="pin" />
					<Header.Content>
						Pinned Tasks
						<Header.Subheader>The first tasks the AASDI will see.</Header.Subheader>
					</Header.Content>
				</Header>
			</Card.Content>
			<Card.Content style={{borderTop: '1px solid rgba(34,36,38,.1)'}}>
				<Item.Group divided>
					{tasks.pinned.map((task, index) => (
						<Task {...props} key={`task-${task.id}`} group={props.group} index={index} task={task} />
					))}
				</Item.Group>
			</Card.Content>
		</Card>
	) : null;
};

export default Pinned;

Pinned.propTypes = {
	group: PropTypes.object.isRequired
};
