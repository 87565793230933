import {deactivatePipelineOpportunity} from '../../../scenes/crm/scenes/dashboard/scenes/pipeline/services/pipelineActions.js';
import {deactivateLeadsOpportunity} from '../../../scenes/crm/scenes/dashboard/scenes/leads/services/leadsActions.js';

export const deactivateOpportunity = (id, place) => {
	return (dispatch) => {
		console.log(place);
		switch (place) {
			case 'pipeline':
				dispatch(deactivatePipelineOpportunity(id, place));
				break;
			case 'leads':
				dispatch(deactivateLeadsOpportunity(id, place));
				break;
		}
	};
};

export const toggleDeactivateModal = (id, place) => {
	return (dispatch) => {
		dispatch({
			type: 'DEACTIVATE_OPPORTUNITY_MODAL_UPDATE_MODAL_ID',
			payload: {
				id,
				place
			}
		});
		return dispatch({
			type: 'DEACTIVATE_OPPORTUNITY_MODAL_TOGGLE'
		});
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'DEACTIVATE_OPPORTUNITY_MODAL_LOADING',
			payload: value
		});
	};
};
