import React from 'react';
import {Grid, Label, Segment, Table, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import PropTypes from 'prop-types';
import {toggleProjectCostingSpreadsheetModal} from '../../services/createWorkOrderActions.js';
import ProjectCostingSpreadsheetModal from './components/projectCostingSpreadsheetModal.js';

const ProjectCosting = (props) => (
	<Segment>
		<Label attached="top" color="blue">
			Work Order Costing
		</Label>
		<Grid>
			<Grid.Row>
				<Grid.Column>
					<Button
						onClick={() => {
							props.toggleProjectCostingSpreadsheetModal(
								props.sectionIndex,
								props.rowData.length > 0 ? props.rowData : []
							);
						}}
					>
						Open Spreadsheet
					</Button>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={13}>
					<div
						className="ag-theme-balham"
						style={{
							height: '35vh',
							width: '100%',
							textAlign: 'left',
							boxSizing: 'border-box',
							backgroundColor: props.sectionError ? '#fff6f6' : null
						}}
					>
						<AgGridReact
							onGridReady={(params) => {
								params.api.sizeColumnsToFit();
							}}
							animateRows={true}
							suppressHorizontalScroll={true}
							modules={AllModules}
							rowHeight={40}
							suppressCopyRowsToClipboard={true}
							{...props}
							rowData={props.rowData}
							defaultColDef={{
								sortable: true,
								filter: true,
								resizable: true
							}}
							columnDefs={[
								{
									headerName: 'Product Description',
									field: 'SKU',
									cellRenderer: StatusCellRenderer,
									cellStyle: {paddingTop: '.5em'},
									filter: false
								},
								{
									headerName: 'Unit',
									field: 'unit',
									cellStyle: {paddingTop: '.5em'},
									filter: 'agNumberColumnFilter'
								},
								{
									headerName: 'Quantity',
									field: 'quantity',
									cellStyle: {paddingTop: '.5em'},
									filter: 'agNumberColumnFilter'
								},
								{
									headerName: 'Cost',
									cellStyle: {paddingTop: '.5em'},
									filter: 'agNumberColumnFilter',
									valueFormatter: (params) => (params.value ? `$${params.value}` : ''),
									valueGetter: (params) => {
										if (params.data.unitLaborCost || params.data.unitMaterialCost) {
											return (
												Number(params.data.unitLaborCost) *
													(params.data.quantity ? Number(params.data.quantity) : 1) +
												Number(params.data.unitMaterialCost) *
													(params.data.quantity ? Number(params.data.quantity) : 1)
											);
										}
										return undefined;
									}
								},
								{
									headerName: 'Revenue',
									filter: 'agNumberColumnFilter',
									valueFormatter: (params) => (params.value ? `$${params.value}` : ''),
									valueGetter: ({data}) => data.materialResell + data.laborResell,
									cellStyle: {paddingTop: '.5em'}
								}
							]}
						/>
					</div>
				</Grid.Column>
				<Grid.Column width={3}>
					<Table definition>
						<Table.Header>
							<Table.HeaderCell />
							<Table.HeaderCell style={{width: '100%'}}>Summary</Table.HeaderCell>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>Resell</Table.Cell>
								<Table.Cell>{props.summaryData.totalResell}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Cost</Table.Cell>
								<Table.Cell>{props.summaryData.totalCost}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Profit</Table.Cell>
								<Table.Cell>{props.summaryData.totalGrossProfit}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Grid.Column>
			</Grid.Row>
			{!props.skuExists ? (
				<Grid.Row>
					<Label color="red" size="big" style={{marginLeft: '5vw', position: 'relative'}}>
						{`At least one SKU must be added in order to create a work order.`}
					</Label>
				</Grid.Row>
			) : null}
			{props.sectionError ? (
				<Grid.Row>
					<Label color="red" size="big" style={{marginLeft: '5vw', position: 'relative'}}>
						{
							'Either labor resell or material resell must be greater than $0.00 in order to create a work order.'
						}
					</Label>
				</Grid.Row>
			) : null}
		</Grid>
		<ProjectCostingSpreadsheetModal />
	</Segment>
);

ProjectCosting.propTypes = {
	rowData: PropTypes.array,
	sectionError: PropTypes.bool,
	sectionIndex: PropTypes.number,
	summaryData: PropTypes.object,
	toggleProjectCostingSpreadsheetModal: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'workOrderProjectCostingSection'
		),
		rowData:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'workOrderProjectCostingSection'
				)
			].rowData,
		summaryData:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'workOrderProjectCostingSection'
				)
			].summary,
		sectionError:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'workOrderProjectCostingSection'
				)
			].error,
		skuExists:
			state.work.createWorkOrder.createWorkOrderModal.sections[
				state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
					(section) => section.name === 'workOrderProjectCostingSection'
				)
			].skuExists
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleProjectCostingSpreadsheetModal: (sectionIndex, spreadsheetData) => {
			dispatch(toggleProjectCostingSpreadsheetModal(sectionIndex, spreadsheetData));
		}
	};
};

function StatusCellRenderer(params) {
	if (params.data.description) {
		return params.data.description;
	}
	return params.value ? (params.value.description ? params.value.description : params.value) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCosting);
