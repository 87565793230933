import React from 'react';
import {Image, Card} from 'semantic-ui-react';

import placeHolderImg from './cardPlaceHolder.png';

let PlaceHolder = () => (
	<Card>
		<Card.Content>
			<Image src={placeHolderImg} />
		</Card.Content>
	</Card>
);

export default PlaceHolder;
