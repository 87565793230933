import React, {Component} from 'react';
import {Button, Label, Segment, Grid, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../../../../../components/Global/AgGrid.js';
import FilterGrid from '../../../../../../../components/Global/FilterGrid.js';
import ExportGrid from '../../../../../../../components/Global/ExportGrid.js';
import {
	toggleFetchingOnTabsNavbar,
	toggleCreateLocationModal
} from '../../../../../../../components/Global/services/globalActions.js';
import {getTabRequestItems, clearGrid, createLocation, updateLocation} from '../services/profileGridActions.js';
import {LocationsTabHeader} from '../services/profileGridReducer.js';
import {checkIdenticalsArrays} from '../../../../../../../lib/old/validation.js';
import CreateLocationModal from '../../../../../../../components/Global/CreateLocationModal.js';
import LocationOrderHistoryModal from '../../../../../../../components/Global/LocationOrderHistoryModal.js';
import api from '../../../../../../../lib/api.ts';
import countryOptions from '../../../../../../../constants/countries.js';
import stateOptions from '../../../../../../../constants/states.js';
import LocationGridActionsCellRenderer from './LocationGridActionsCellRenderer.js';
import ConfirmDeactivateLocationModal from './ConfirmDeactivateLocationModal.js';

const frameworkComponents = {
	locationGridActionsCellRenderer: LocationGridActionsCellRenderer
};
class LocationsTab extends Component {
	constructor(props) {
		super(props);
		this.submitLocation = this.submitLocation.bind(this);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
		const {clientId, contactList} = this.props;
		if (
			this.props.profileGridData &&
			this.props.profileGridData.length &&
			Number(clientId) === this.props.profileGridData[0].clientId &&
			checkIdenticalsArrays(this.props.profileGridHeader, LocationsTabHeader)
		) {
			// table will not reload if desired data is already available.
			// It is assumed that all the contact's clientId coming from backend are equal to
			// the client profile id
			return;
		}
		this.props.clearGrid('Locations');
		this.gridApi.showLoadingOverlay();
		this.props.getTabRequestItems(clientId, 'Locations');
		this.props.toggleFetchingOnTabsNavbar(true);
		LocationsTabHeader[10].cellEditorParams.values = contactList.map((contacts) => contacts.fullName);
		LocationsTabHeader[11].cellEditorParams.values = contactList.map((contacts) => contacts.fullName);
	};

	componentDidUpdate(prevProps) {
		const {profileGridData, profileGridHeader, toggleFetchingOnTabsNavbar, isLoading} = this.props;
		if (prevProps.isLoading === false && isLoading === true) {
			toggleFetchingOnTabsNavbar(true);
		} else if (prevProps.profileGridData === null && profileGridData) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.profileGridHeader, profileGridHeader)) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.rowData, profileGridData)) {
			toggleFetchingOnTabsNavbar(false);
		}
	}

	submitLocation(data) {
		this.props.toggleFetchingOnTabsNavbar(true);
		data.clientId = this.props.clientId;
		this.props.createLocation(data);
	}

	editLocation = (params) => {
		const {contactList} = this.props;
		const updatedData = params.data;
		const locationId = updatedData.id;
		let contactPhone = null;
		this.props.toggleFetchingOnTabsNavbar(true);

		//get CountryId
		const newCountryIdIndex = countryOptions.findIndex((country) => country.text === updatedData.countryName);
		if (newCountryIdIndex > -1) {
			updatedData.countryId = countryOptions[newCountryIdIndex].key;
		}

		//format state value
		if (updatedData.state) {
			updatedData.state = updatedData.state.substring(0, 2).toUpperCase();
		}

		//get contact values
		const newContactIndex = contactList.findIndex((contact) => contact.fullName === updatedData.siteContactName);
		if (newContactIndex > -1) {
			updatedData.siteContactId = contactList[newContactIndex].id;
			contactPhone = contactList[newContactIndex].phone;
		}

		//get billing contact values
		const newBillingContactIndex = contactList.findIndex(
			(contact) => contact.fullName === updatedData.billingContactName
		);
		if (newBillingContactIndex > -1) {
			updatedData.billingContactId = contactList[newBillingContactIndex].id;
		}

		this.props.updateLocation(contactPhone, params.rowIndex);

		console.log('updatedData', updatedData);
		console.log('locationId', locationId);

		this.gridApi.showLoadingOverlay();
		this.props.toggleFetchingOnTabsNavbar(true);

		api(`legacy/locations/${locationId}`, 'PUT', updatedData)
			.then(() => {
				this.gridApi.hideOverlay();
				this.props.toggleFetchingOnTabsNavbar(false);
			})
			.catch((error) => {
				this.gridApi.hideOverlay();
				this.props.toggleFetchingOnTabsNavbar(false);
				alert('Error while updating contact!');
				console.error(error);
			});
	};

	render() {
		const {profileGridData, profileGridHeader, isLoading, clientId, contactList, localOrderHist} = this.props;

		return (
			<Segment basic style={{marginTop: '1.5rem', border: '1px solid #DEDEDE'}}>
				<Label color="blue" ribbon>
					Locations
				</Label>
				<Button
					basic
					color="blue"
					content="Add"
					floated="right"
					icon="add"
					size="tiny"
					onClick={() => this.props.toggleCreateLocationModal(true)}
				/>
				<Divider />
				<Grid style={{padding: '2em 0'}}>
					<FilterGrid gridApi={() => this.gridApi} />
					<ExportGrid gridApi={() => this.gridApi} />
				</Grid>
				<Segment basic loading={isLoading}>
					<AgGrid
						columnDefs={profileGridHeader}
						rowData={profileGridData}
						onGridReady={this.onGridReady}
						frameworkComponents={frameworkComponents}
						onCellValueChanged={this.editLocation}
					/>
				</Segment>
				<CreateLocationModal
					id={clientId}
					type={'client'}
					contacts={contactList}
					submitLocation={this.submitLocation}
				/>
				<LocationOrderHistoryModal historyData={localOrderHist} type={'client'} />
				<ConfirmDeactivateLocationModal />
			</Segment>
		);
	}
}

LocationsTab.propTypes = {
	profileGridData: PropTypes.array,
	clientProfileInfo: PropTypes.object,
	getTabRequestItems: PropTypes.func,
	clientId: PropTypes.string,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	profileGridHeader: PropTypes.array,
	clearGrid: PropTypes.func,
	isLoading: PropTypes.bool,
	toggleCreateLocationModal: PropTypes.func,
	contactList: PropTypes.array,
	createLocation: PropTypes.func,
	updateLocation: PropTypes.func,
	localOrderHist: PropTypes.array
};

const mapStateToProps = (state) => ({
	profileGridData: state.work.clientProfileGrid.rowData,
	clientProfileInfo: state.work.clientProfileGrid.profileInfos,
	stageNames: state.work.clientProfileGrid.stageNames,
	profileGridHeader: state.work.clientProfileGrid.headerData,
	isLoading: state.work.clientProfileGrid.loading,
	contactList: state.work.clientProfileGrid.contactList,
	localOrderHist: state.work.clientProfileGrid.localOrderHist
});

const mapDispatchToProps = (dispatch) => ({
	getTabRequestItems: (clientId, item, mock) => dispatch(getTabRequestItems(clientId, item, mock)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	clearGrid: (item) => dispatch(clearGrid(item)),
	toggleCreateLocationModal: (status) => dispatch(toggleCreateLocationModal(status)),
	createLocation: (data) => dispatch(createLocation(data)),
	updateLocation: (data, rowIndex) => dispatch(updateLocation(data, rowIndex))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsTab);
