import {Label} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

const ErrorLabel = (props) => {
	return props.tasks.ui.errors[props.column] ? (
		<Label key={uuidv4()} pointing basic color="red" size="mini">
			{props.message}
		</Label>
	) : null;
};

export default ErrorLabel;
