import React from 'react';
import {Popup, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const ButtonLink = (type) => (props) => {
	return (
		<div style={{textAlign: 'center'}}>
			{type === 'CommitBooking' ? (
				props.data.pipelineId ? (
					<Popup
						trigger={
							<Link to={`/crm/dashboard/opportunity/${props.data.pipelineId}`}>
								<Button
									icon="linkify"
									style={{
										backgroundColor: 'transparent',
										padding: '.5em .5em .5em',
										marginLeft: '.5em'
									}}
								/>
							</Link>
						}
						content={'View Opportunity Details'}
						position="top center"
					/>
				) : null
			) : props.data.pipelineId ? (
				props.data.workId ? (
					<Popup
						trigger={
							<Link to={`/work/view/${props.data.workId}`}>
								<Button
									icon="linkify"
									style={{
										backgroundColor: 'transparent',
										padding: '.5em .5em .5em',
										marginLeft: '.5em'
									}}
								/>
							</Link>
						}
						content="View Work Details"
						position="top center"
					/>
				) : (
					<Button
						disabled
						icon="linkify"
						style={{
							backgroundColor: 'transparent',
							padding: '.5em .5em .5em',
							marginLeft: '.5em'
						}}
					/>
				)
			) : null}
		</div>
	);
};

export default ButtonLink;
