import React from 'react';
import {Modal, Icon, Header, Grid, Segment, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {PDFViewer, PDFDownloadLink} from '@react-pdf/renderer';
import BillPDF from '../../../../../../../../work/scenes/WorkOrder-Project/scenes/workRecordView/scenes/billingCenter/billingCenterWO/components/components/BillPDF.js';
import {closePDFModal} from '../../../../../../../services/accountingConsoleActions.js';

class PDFModal extends React.Component {
	render() {
		const invoiceNumber = this.props.pdfInfo.spectrumInvoiceNumber
			? this.props.pdfInfo.spectrumInvoiceNumber.trim()
			: this.props.pdfInfo.id;
		const workNumber = this.props.pdfInfo.workNumber;

		return (
			<Modal open={this.props.open} size="large">
				<Header>
					<Grid>
						<Grid.Row>
							<Icon name="file pdf" color="blue" />
							<Grid.Column width={3} style={{margin: 'auto 0'}}>
								<div className="content">{`Invoice: ${`Bill_${invoiceNumber}_${workNumber}.pdf`}`}</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Header>
				<Modal.Content>
					{this.props.pdfInfo.billName ? (
						<PDFViewer style={{width: '55vw', height: '65vh'}}>
							<BillPDF {...this.props.pdfInfo} fileName={`Bill_${invoiceNumber}_${workNumber}.pdf`} />
						</PDFViewer>
					) : (
						<Segment style={{width: '55vw', height: '65vh'}} loading={true} />
					)}
				</Modal.Content>
				<Modal.Actions style={{height: '7vh'}}>
					<Button.Group floated="left">
						<Button
							color="red"
							onClick={() => {
								this.props.closePDFModal();
							}}
						>
							<Icon name="remove" /> close
						</Button>
					</Button.Group>
					<Button.Group>
						{this.props.pdfInfo.billName ? (
							<PDFDownloadLink
								document={
									<BillPDF
										{...this.props.pdfInfo}
										fileName={`Bill_${invoiceNumber}_${workNumber}.pdf`}
									/>
								}
								fileName={`Bill_${invoiceNumber}_${workNumber}.pdf`}
							>
								<Button
									color="green"
									onClick={() => {
										this.props.closePDFModal();
									}}
								>
									<Icon name="remove" /> save and close
								</Button>
							</PDFDownloadLink>
						) : null}
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

PDFModal.propTypes = {
	open: PropTypes.bool,
	pdfInfo: PropTypes.object,
	closePDFModal: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		closePDFModal: () => {
			dispatch(closePDFModal());
		}
	};
};

const mapStateToProps = (state) => {
	return {
		open:
			state.accountingConsoleReducer.accountingConsole.selectedCategory.data[
				state.accountingConsoleReducer.accountingConsole.selectedCategory.data.findIndex(
					(cat) => cat.name === 'Accepted'
				)
			].pdfModalOpen,
		pdfInfo:
			state.accountingConsoleReducer.accountingConsole.selectedCategory.data[
				state.accountingConsoleReducer.accountingConsole.selectedCategory.data.findIndex(
					(cat) => cat.name === 'Accepted'
				)
			].pdfInfo
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFModal);
