//All percentage values are assumed to be 0.NN for NN%
export const calcMargin = (cost: number, revenue: number) => {
	if (cost === 0 && revenue === 0) {
		//need to manually handle this case because jacascript cant handle divison by 0
		//you could argue that this should be 1.. but other formulas dont play nice with 1 margin so 0% is easier
		return 0;
	}
	return (revenue - cost) / revenue;
};

export const countFractionalDigits = (value: number) => {
	const str = value.toString();
	if (str.includes('.')) {
		return str.split('.')[1].length;
	}
	return 0;
};

export const isDollarAmount = (value: number) => {
	if (!isFinite(value) || Boolean(!value)) {
		return false;
	}

	if (!([0, 1, 2].indexOf(countFractionalDigits(value)) > -1)) {
		return false;
	}
	return true;
};

export const calcRevenueFromMargin = (cost: number, margin: number) => cost / (1 - margin);

export const calcCostFromMargin = (revenue: number, margin: number) => revenue * (1 - margin);

export const calcMarkup = (cost: number, revenue: number) => (revenue - cost) / cost;

export const calcRevenueFromMarkup = (cost: number, markup: number) => cost * markup + cost;

export const calcCostFromMarkup = (revenue: number, margin: number) => revenue / (margin + 1);

export const calcRemainingToBill = (estimatedRev: number, actualRev: number) => estimatedRev - actualRev;
