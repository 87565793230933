import React from 'react';
import {
	Button,
	Modal,
	Icon,
	Header
	//	Dimmer,
	//	Loader,
	//	TextArea,
	//	Segment,
	//	Label
} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
//import { DebounceInput } from 'react-debounce-input';
import * as actions from '../../../../../services/workRecordActions.js';

class SubmitOrderGroupModal extends React.Component {
	render() {
		return (
			<Modal open={this.props.removeGroupConfirmationModelToggle} size="large">
				<Header>
					<Icon name="x" color="red" />
					<div className="content">{'Remove Order Group'}</div>
				</Header>
				<Modal.Actions>
					<Button
						color="red"
						onClick={() => {
							this.props.toggleRemoveGroupConfirmationModel(null, null);
						}}
					>
						<Icon name="x" /> Cancel
					</Button>
					<Button
						color="green"
						onClick={() => {
							this.props.removeOrderGroup(
								this.props.orderGroupRowIndex,
								this.props.orderGroupRejectionComment,
								this.props.orderGroupData
							);
						}}
					>
						<Icon name="remove" /> Remove
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

SubmitOrderGroupModal.propTypes = {
	removeGroupConfirmationModelToggle: PropTypes.bool,
	toggleRemoveGroupConfirmationModel: PropTypes.func,
	removeOrderGroup: PropTypes.func,
	orderGroupRowIndex: PropTypes.number,
	orderGroupRejectionComment: PropTypes.string,
	orderGroupData: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		removeGroupConfirmationModelToggle:
			state.work.workRecord.view.BillingCenter.orderGroup.removeGroupConfirmationModel.toggle,
		removeGroupConfirmationModelLoading:
			state.work.workRecord.view.BillingCenter.orderGroup.removeGroupConfirmationModel.loading,
		orderGroupData: state.work.workRecord.view.BillingCenter.orderGroup.removeGroupConfirmationModel.data,
		orderGroupRowIndex: state.work.workRecord.view.BillingCenter.orderGroup.removeGroupConfirmationModel.rowIndex,
		orderGroupRejectionComment:
			state.work.workRecord.view.BillingCenter.orderGroup.removeGroupConfirmationModel.comment
	};
};

export default connect(mapStateToProps, actions)(SubmitOrderGroupModal);
