import {Button, Icon, Popup} from 'semantic-ui-react';
//import PropTypes from 'prop-types';
import React from 'react';
import optionsFactory from './optionFactory.js';

const PinButton = (props) => (
	<Popup
		size="mini"
		trigger={
			<Button
				className="pseudoDragHandler"
				size="mini"
				floated="right"
				icon
				type="button"
				onClick={() => {
					props.togglePinnedTask(props.mode, optionsFactory(props), props.task);
				}}
			>
				<Icon name="pin" className="pseudoDragHandler" />
			</Button>
		}
		content={props.task.pinned ? 'Un-pin this task.' : 'Pin this task.'}
	/>
);
export default PinButton;
