import React from 'react';
import {Menu} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';

const WorkNav = () => (
	<div>
		<Menu secondary vertical fixed="left" className="omni-sidebar">
			<Menu.Item className="omni-sidebar-menu-item">
				<Menu.Header>
					<h2>Work</h2>
				</Menu.Header>
				<Menu.Menu>
					<Menu.Item
						as={NavLink}
						to={`/work/dashboard/work/grid`}
						name="work-view"
						className="omni-sidebar-item"
					>
						<h4>Work</h4>
					</Menu.Item>
					<Menu.Menu>
						<Menu.Menu style={{marginLeft: '1vw'}}>
							<Menu.Item
								as={NavLink}
								to={`/task-templates/list`}
								name="task-Template-view"
								className="omni-sidebar-item-small"
							>
								<h5>Task Templates</h5>
							</Menu.Item>
						</Menu.Menu>
					</Menu.Menu>
				</Menu.Menu>
			</Menu.Item>
			<Menu.Item as={NavLink} to={`/work/dashboard/client/grid`} name="client-view" className="omni-sidebar-item">
				<h4>Clients</h4>
			</Menu.Item>
			<Menu.Item as={NavLink} to={`/work/dashboard/aasdi`} name="aasdi-view" className="omni-sidebar-item">
				<h4>AASDIs</h4>
			</Menu.Item>
			<Menu.Menu>
				<Menu.Menu style={{marginLeft: '1vw'}}>
					<Menu.Item
						as={NavLink}
						to={`/techManagement`}
						name="tech-management-view"
						className="omni-sidebar-item-small"
					>
						<h5>Manage Mobile Users</h5>
					</Menu.Item>
				</Menu.Menu>
			</Menu.Menu>
			<Menu.Item as={NavLink} to={`/work/dashboard/commits`} name="commits" className="omni-sidebar-item">
				<h4>Commits</h4>
			</Menu.Item>
		</Menu>
	</div>
);

export default WorkNav;
