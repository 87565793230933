import React from 'react';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import {Popup, Label} from 'semantic-ui-react';
import {MomentDatePicker} from '../../../../../../../components/inputs/DatePicker.jsx';

class DatePickerButton extends React.Component {
	getValue() {
		//this is what sets the value in the grid (changes props) gets called
		//after stopEditing. Needed for if no changes are made to the cell.
		return this.props.value;
	}

	render() {
		return (
			<Popup
				trigger={
					<Label
						style={{
							width: '100%',
							backgroundColor: 'transparent'
						}}
					>
						{this.props.value ? moment(this.props.value).format('MM-DD-YYYY') : 'Pick Date'}
					</Label>
				}
				on={['hover', 'click']}
				flowing
				hoverable
				position="right center"
			>
				<MomentDatePicker
					inline
					onChange={(date) => {
						this.props.agGridReact.props.updateOpportunityColumn(
							date.format('MM-DD-YYYY').toString(),
							this.props.node.data.id,
							this.props.agGridReact.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0
								? 'pipeline'
								: 'leads',
							this.props.column
						);
						this.props.stopEditing();
					}}
					selected={this.props.value ? moment(this.props.value) : null}
				/>
			</Popup>
		);
	}
}

export default DatePickerButton;
