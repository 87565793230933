import customFetch from '../../../lib/old/customFetch.js';
import moment from 'moment';

export const getSalesRepresentative = () => {
	return (dispatch, getState) => {
		const state = getState();
		const {startDate, endDate, accountExecutiveId, branch, serviceType, technology, employees} =
			state.crm.commits.selectedFilters;
		const accountExecutive = employees.find((elm) => elm.value === accountExecutiveId);
		dispatch({
			type: 'CRM_COMMITS_LOADING',
			payload: true
		});
		// /crm/commits?rangeIni=2019-07-01&rangeEnd=2019-07-31&technologies=ACC-SUR&serviceTypeName=Deploy&accountExecutiveName=Abe

		let endpoint = `${
			process.env.REACT_APP_API_URL
		}/legacy/crm/pipeline/opportunity/xx?mode=commits&rangeIni=${moment(startDate).format(
			'MM-DD-YYYY'
		)}&rangeEnd=${moment(endDate).format('MM-DD-YYYY')}`;

		if (technology) {
			endpoint += `&technologies=${technology}`;
		}

		if (accountExecutive) {
			endpoint += `&accountExecutiveName=${accountExecutive.text}`;
		}

		if (serviceType) {
			endpoint += `&serviceTypeName=${serviceType}`;
		}

		if (branch) {
			endpoint += `&branchName=${branch}`;
		}

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				const {commitBooking, commitBilling} = json;
				// Group By Sales Representative.
				const salesRep = [];

				let totalsBooking = {
					totalProjected: 0,
					totalBooked: 0,
					totalTotalPipeline: 0,
					totalHighProbability: 0
				};

				let totalsBilling = {
					totalProjected: 0,
					totalBilled: 0
				};

				commitBooking.forEach((item) => {
					if (!salesRep.find((elm) => elm.id === item.employeeId)) {
						salesRep.push({
							id: item.employeeId,
							accountExecutiveName: item.accountExecutiveName,
							commitBooking: [],
							commitBilling: []
						});
					}
				});
				salesRep.forEach((saleRep) => {
					commitBooking.forEach((item) => {
						if (item.employeeId === saleRep.id) {
							// Work Order and Change Order Projections
							item.projected = item.projected ? item.projected : 0;
							item.booked = item.commitType === 'P' && item.actual ? item.actual : 0;

							// Opportunity Projections
							if (item.opportunityEstimatedRevenue) {
								// If accepted as a project, else not a project yet
								if (item.workId) {
									item.projected = 0;
									item.booked = item.opportunityEstimatedRevenue;
								} else {
									item.projected = item.opportunityEstimatedRevenue;
									item.booked = 0;
								}
							}

							item.totalPipeline = item.totalPipeline ? item.totalPipeline : 0;
							item.highProbability = item.highProbability ? item.highProbability : 0;
							saleRep.commitBooking.push(item);

							// sums totals for totals row at bottom of grid
							totalsBooking.totalProjected += item.projected;
							totalsBooking.totalBooked += item.booked;
							totalsBooking.totalTotalPipeline += item.totalPipeline;
							totalsBooking.totalHighProbability += item.highProbability;
						}
					});
					commitBilling.forEach((item) => {
						if (item.employeeId === saleRep.id && (item.pipelineId === null || item.workId !== null)) {
							item.projected = item.projected ? item.projected : 0;
							item.billed = item.actual && item.commitType === 'P' ? item.actual : 0;

							if (item.workNumber) {
								item.description =
									item.workNumber +
									' - ' +
									item.clientName +
									' - ' +
									item.workCity +
									', ' +
									item.workState;
							} else if (!item.workNumber && item.commitType === 'P' && item.name) {
								item.description = item.name;
							} else if (item.commitType === 'W') {
								item.description = 'Work Orders';
							} else if (item.commitType === 'C') {
								item.description = 'Change Orders';
							}

							saleRep.commitBilling.push(item);

							totalsBilling.totalProjected += item.projected;
							totalsBilling.totalBilled += item.billed;
						}
					});
				});
				dispatch({
					type: 'CRM_COMMITS_GET_SALES_REPRESENTATIVE',
					payload: salesRep
				});
				dispatch({
					type: 'CRM_COMMITS_SET_TOTALS',
					payload: {
						totalsBooking,
						totalsBilling
					}
				});
				dispatch({
					type: 'CRM_COMMITS_LOADING',
					payload: false
				});
			})
			.catch((err) => {
				dispatch({
					type: 'CRM_COMMITS_LOADING',
					payload: false
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const updateCommitsItem = (data, field, gridLevel, commitType, parentData, oldValue) => (dispatch, getState) => {
	// This is to add new row. No more required.
	// if (parentData) {
	// 	const sales = getState().crm.commits.data.salesRepresentative;
	// 	const index = sales.findIndex(elm => elm.id === parentData.id);
	// 	if(!data.id) {
	// 		const detailIndex = parentData.detail.findIndex(elm => !elm.id);
	// 		parentData.detail[detailIndex].id = 15;
	// 		const newRow = {
	// 			type: 'Work Orders / Other',
	// 			description: '',
	// 			detailTotalPipeline: '0.0',
	// 			detailHigh: '0.0',
	// 			detailProjected: '0.0',
	// 			detailBooked: '0.0',
	// 			detailBillProjected: '0.0',
	// 			detailBilled: '0.0'
	// 		}
	// 		parentData.detail = [...parentData.detail, newRow];
	// 	}
	// 	dispatch({
	// 		type:
	// 			'CRM_COMMITS_UPDATE_GRID',
	// 		payload: {
	// 			value: parentData,
	// 			index,
	// 		}
	// 	});
	// }

	const commitTypeField = commitType === 'CommitBooking' ? 'commitBooking' : 'commitBilling';
	const rowIndex = parentData[commitTypeField].findIndex((elm) => elm.id === data.id);

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${data.id}?mode=commits&commitType=${commitType}`;

	customFetch(endpoint, {
		method: 'PUT',
		body: JSON.stringify({
			[field]: data[field]
		})
	})
		.then((response) => {
			if (response.status !== 200) {
				return Promise.reject(response);
			}
			return response.json();
		})
		.then((json) => {
			dispatch({
				type: 'CRM_COMMITS_UPDATE_COLUMN',
				payload: {
					salesRepId: parentData.id,
					commitType: commitTypeField,
					rowIndex,
					value: data[field],
					column: field
				}
			});
		})
		.catch((err) => {
			dispatch({
				type: 'ERROR',
				payload: err.stack
			});
			dispatch({
				type: 'CRM_COMMITS_UPDATE_COLUMN',
				payload: {
					salesRepId: parentData.id,
					commitType: commitTypeField,
					rowIndex,
					value: oldValue,
					column: field
				}
			});
		});
};
