export const workPerformed = [
	{id: 1, name: 'Paging Systems'},
	{id: 2, name: 'Security & Access Cntrl'},
	{id: 3, name: 'Voice PBX'},
	{id: 4, name: 'MICROWAVE'},
	{id: 5, name: 'Sound Masking Systems'},
	{id: 6, name: 'CCTV'},
	{id: 7, name: 'Router & Switch Config'},
	{id: 8, name: "iDEN (Nextel's GSM)"},
	{id: 9, name: 'Central Office'},
	{id: 10, name: 'CATV'},
	{id: 11, name: 'Desktop Support'},
	{id: 12, name: 'Two Way Radio '},
	{id: 13, name: 'Outside Plant'},
	{id: 14, name: 'Wireless Installation'},
	{id: 15, name: 'Server Support'},
	{id: 16, name: 'Cellular Inbuilding Wireless Systems'},
	{id: 17, name: 'Directional Boring'},
	{id: 18, name: 'Wireless Site Surveys'},
	{id: 19, name: 'Network Electronics Rack & Stack'},
	{id: 20, name: 'Trenching'},
	{id: 21, name: 'Network Security'},
	{id: 22, name: 'Wireless Design'},
	{id: 23, name: 'TDMA'},
	{id: 24, name: 'Residential Data / Voice'},
	{id: 25, name: 'Raised Flooring Install'},
	{id: 26, name: 'Voice over IP (VoIP)'},
	{id: 27, name: 'CDMA'},
	{id: 28, name: 'Residential DSL Installs'},
	{id: 29, name: 'Electrical'},
	{id: 30, name: 'Voice Key Systems'},
	{id: 31, name: 'GSM'}
];

export default {workPerformed};
