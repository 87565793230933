import { refreshSession } from '~/scenes/auth/services/authActions';
import store from '../store.js';

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE"

export async function api(endpoint: string, method: Method="GET", data?: object) {
	const body = data ? JSON.stringify(data) : undefined

	const idToken = await getApiToken()

	const headers = new Headers({'Authorization': 'Bearer ' + idToken});
	if(body) {
		headers.append('Content-Type', 'application/json')
	}

	return await rawApiFetch(endpoint, {method, body, headers})
}

api.get = async (endpoint: string) => await api(endpoint, 'GET')
api.post = async (endpoint: string, data: object) => await api(endpoint, 'POST', data)
api.put = async (endpoint: string, data: object) => await api(endpoint, 'PUT', data)
api.patch = async (endpoint: string, data: object) => await api(endpoint, 'PATCH', data)
api.delete = async (endpoint: string) => await api(endpoint, 'DELETE')

//time buffer just to be safe
const EXPIRE_BUFFER = 2
export async function getApiToken() {
	let {auth} = store.getState();
	if (!auth.idToken) throw new Error("Missing ID Token to make request")

	const now = Date.now() / 1000
	if(auth.expires < now - EXPIRE_BUFFER) {
		return await store.dispatch(refreshSession() as any)
	}

	return auth.idToken
}

export async function rawApiFetch(endpoint: string, opts: RequestInit) {
	if(!endpoint.startsWith('/')) {
		endpoint = "/" + endpoint
	}

	const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
	const response = await fetch(url, opts);

	if(response.ok) {
		return await response.json();
	}

	let err: ApiError
	//try to parse the error as json
	try {
		const {message, fields} = await response.json()
		err = new ApiError(message, response.status, response.statusText, fields)
	} catch (unused) {
		//otherwise just use the text
		try {
			const message = await response.text()
			err = new ApiError(message, response.status, response.statusText)
		} catch (parseErr) {
			console.error("Failed to parse error from response:")
			console.error(parseErr)
			
			err = new ApiError("Failed to get error from api response.", response.status, response.statusText)
		}
	}

	throw err
}

type FieldErrors = {[field: string]: string | FieldErrors}

export class ApiError extends Error {
	code: number
	fields: FieldErrors

	constructor(message: string, code: number, statusText: string, fields: FieldErrors={}) {
		message = `Api Error (${statusText}): ${message}`
		super(message)
		this.name = "ApiError"
		this.code = code
		this.fields = fields
	}
}

export default api;
