import React from 'react';
import {Button, Modal, Header, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {
	toggleConfirmDeactivateLocationModal,
	clearLocationToDeactivate,
	removeClientLocation
} from '../services/profileGridActions.js';
import {toggleFetchingOnTabsNavbar} from '../../../../../../../components/Global/services/globalActions.js';

class ConfirmDeactivateLocationModal extends React.Component {
	constructor(props) {
		super(props);
		this.onUnmount = this.onUnmount.bind(this);
		this.deactiveLocation = this.deactiveLocation.bind(this);
	}

	onUnmount() {
		this.props.toggleConfirmDeactivateLocationModal(false);
		this.props.clearLocationToDeactivate();
	}

	deactiveLocation() {
		const {locationToDeactivate} = this.props;
		if (locationToDeactivate) {
			this.props.removeClientLocation(locationToDeactivate.rowIndex, locationToDeactivate.data);
			this.props.toggleFetchingOnTabsNavbar(true);
		}
		this.onUnmount();
	}

	render() {
		return (
			<Modal open={this.props.isOpen} size="small" onUnmount={this.onUnmount}>
				<Header>
					<div>Deactivate Location</div>
				</Header>
				<Modal.Content>
					<p>Are you sure you want to deactivate this Location?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button color="red" onClick={() => this.onUnmount()}>
							<Icon name="remove" /> {'No'}
						</Button>
						<Button color="green" onClick={() => this.deactiveLocation()}>
							<Icon name="checkmark" />
							{'Yes'}
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

ConfirmDeactivateLocationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggleConfirmDeactivateLocationModal: PropTypes.func.isRequired,
	locationToDeactivate: PropTypes.object.isRequired,
	clearLocationToDeactivate: PropTypes.func.isRequired,
	removeClientLocation: PropTypes.func.isRequired,
	toggleFetchingOnTabsNavbar: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isOpen: state.work.clientProfileGrid.showDeactiveLocationModal,
		locationToDeactivate: state.work.clientProfileGrid.LocationToDeactivate
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleConfirmDeactivateLocationModal: (status) => dispatch(toggleConfirmDeactivateLocationModal(status)),
		clearLocationToDeactivate: () => dispatch(clearLocationToDeactivate()),
		removeClientLocation: (rowIndex, location) => dispatch(removeClientLocation(rowIndex, location)),
		toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeactivateLocationModal);
