import {Icon, Item, Menu} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import Pinned from './Pinned.js';
import {General, SortableGeneral} from './General.js';
import CreateTaskButton from './Button_CreateTask.js';
import AddTemplateButton from './Button_AddTemplate.js';
import DragHandle from './Button_DragHandle.js';
import ToggleSequentialButton from './Button_ToggleSequential.js';
import EditPanelButton from './Button_EditPanel.js';
import {SortableElement} from 'react-sortable-hoc';
import optionFactory from './optionFactory.js';

export const Section = (props) => (
	<Item
		style={{
			borderLeft: `3rem ${
				props.section.pendingChanges ? '#fbbd08' : props.section.completed ? '#21ba45' : '#db2828'
			} solid`,
			paddingLeft: '1rem'
		}}
	>
		<Item.Content>
			<Menu compact floated="right" size="mini">
				{props.mode !== 'templateManager' ? <ToggleSequentialButton {...props} /> : null}
				<CreateTaskButton
					{...props}
					noText
					isSectionTask
					sectionId={props.section.id}
					groupId={props.section.id}
				/>
				{/* {props.mode === 'templateManager' ? null  : <AddTemplateButton {...props} noText />} */}
				<EditPanelButton {...props} noText />
				<DragHandle {...props} isSection />
			</Menu>
			<Item.Header
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					maxWidth: '70%'
				}}
			>
				{<Icon name="object group" />}
				{props.section.title ? props.section.title : props.section.name}
			</Item.Header>

			<Pinned
				{...props}
				isSectionTask
				key={`pinned-${props.sectionIndex}-${props.section.id}`}
				sectionCategory="pinned"
				sectionId={props.section.id}
			/>
			<SortableGeneral
				props={{...props, isSectionTask: true}}
				key={`general-${props.sectionIndex}-${props.section.id}`}
				onSortEnd={(event) => {
					if (event.oldIndex !== event.newIndex) {
						props.updateTaskOrder(
							event,
							props.mode,
							{
								...optionFactory(props),
								isSectionTask: true
							},
							props.group.tasks.general,
							'sectionTask'
						);
					}
				}}
				shouldCancelStart={(e) => {
					// Cancel sorting if the event target is an `input`, `textarea`, `select` or `option`
					const disabledTagElements = ['input', 'textarea', 'select', 'option'];

					if (
						disabledTagElements.indexOf(e.target.tagName.toLowerCase()) !== -1 ||
						e.target.className.indexOf('pseudoDragHandler') !== -1
					) {
						return true; // Return true to cancel sorting
					}

					return props.togglePanel('close', props.mode, 'task', optionFactory(props));
				}}
				axis={'y'}
				lockAxis={'y'}
			/>
		</Item.Content>
	</Item>
);

export const SortableSection = SortableElement(({props}) => <Section {...props} />);

Section.propTypes = {
	section: PropTypes.object.isRequired
};
