import {Button, Icon, Popup, Menu} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import optionFactory from './optionFactory.js';

const EditPanelButton = (props) => {
	// if(props.task === undefined || props.task === null) {
	// 	return (
	// 		<div />
	// 	);
	// }
	if (props.isSection && !props.isSectionTask) {
		return (
			<Popup
				size={'mini'}
				trigger={
					<Menu.Item
						className="pseudoDragHandler"
						onClick={() => {
							props.togglePanel('open', props.mode, 'section', optionFactory(props));
						}}
					>
						{props.section.completed ? (
							<Icon name="eye" className="pseudoDragHandler" />
						) : (
							<Icon name="edit" className="pseudoDragHandler" />
						)}
						{props.noText ? null : `Edit Section`}
					</Menu.Item>
				}
				content={
					props.mode === 'templateManager'
						? 'Click to edit this task.'
						: props.section.completed
						? 'Click to view completed task details'
						: 'Click to edit this task.'
				}
			/>
		);
	}

	return (
		<Popup
			size="mini"
			trigger={
				<Button
					className="pseudoDragHandler"
					floated="right"
					size="mini"
					icon
					color="blue"
					type="button"
					onClick={() => {
						props.togglePanel('open', props.mode, 'task', optionFactory(props));
					}}
				>
					{props.task.completed ? (
						<Icon name="eye" className="pseudoDragHandler" />
					) : (
						<Icon name="edit" className="pseudoDragHandler" />
					)}
				</Button>
			}
			content={
				props.mode === 'templateManager'
					? 'Click to edit this task.'
					: props.task.completed
					? 'Click to view completed task details'
					: 'Click to edit this task.'
			}
		/>
	);
};

export default EditPanelButton;

EditPanelButton.propTypes = {
	toggleEditTaskPanel: PropTypes.func.isRequired
};
