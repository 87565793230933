import React from 'react';
import {Popup, Form, TextArea} from 'semantic-ui-react';

class DescriptionCellRenderer extends React.Component {
	state = {
		value: this.props.value
	};

	getValue() {
		//this is what sets the value in the grid (changes props) gets called
		//after stopEditing. Needed for if no changes are made to the cell.
		return this.props.value;
	}

	render() {
		return (
			<Popup
				trigger={
					this.props.value ? (
						this.props.value.replace(/\s+/, '') ? (
							<p>{this.state.value}</p>
						) : (
							<div style={{width: '100%', height: '25px'}} />
						)
					) : (
						<div style={{width: '100%', height: '25px'}} />
					)
				}
				on={['hover', 'click']}
				flowing
				hoverable
				position="left center"
			>
				<Form>
					<TextArea
						style={{
							width: '350px',
							minHeight: '40px',
							maxHeight: '150px'
						}}
						autoHeight
						value={this.state.value}
						onChange={(event) => {
							this.setState({
								value: event.target.value
							});
						}}
						onBlur={() => {
							this.props.agGridReact.props.updateOpportunityColumn(
								this.state.value ? this.state.value : null,
								this.props.node.data.id,
								this.props.agGridReact.props.location.indexOf('/crm/dashboard/pipeline/grid-view') >= 0
									? 'pipeline'
									: 'leads',
								this.props.column
							);
							this.props.stopEditing();
						}}
						onKeyPress={(event) => {
							if (event.key === 'Enter') {
								this.props.agGridReact.props.updateOpportunityColumn(
									this.state.value ? this.state.value : null,
									this.props.node.data.id,
									this.props.agGridReact.props.location.indexOf(
										'/crm/dashboard/pipeline/grid-view'
									) >= 0
										? 'pipeline'
										: 'leads',
									this.props.column
								);
								this.props.stopEditing();
							}
						}}
					/>
				</Form>
			</Popup>
		);
	}
}

export default DescriptionCellRenderer;
