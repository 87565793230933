import React from 'react';
import {Popup, Table, Button, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

function TemplateRow(props) {
	const {template} = props;
	const {id, name, description, clientName} = template;
	return (
		<Table.Row key={id}>
			<Table.Cell collapsing>
				<Popup
					size="mini"
					trigger={
						<Link to={`/task-templates/edit/${id}`}>
							<Button size="mini" icon color="blue" type="button">
								<Icon name="edit" />
							</Button>
						</Link>
					}
					content="Edit this template."
				/>
			</Table.Cell>
			<Table.Cell>{name}</Table.Cell>
			<Table.Cell>{description}</Table.Cell>
			<Table.Cell>{clientName}</Table.Cell>
		</Table.Row>
	);
}

export default TemplateRow;
