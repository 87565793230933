// TODOS
// remove inline styling
// Breaking things down into smaller components would do this component some good

import React from 'react';
import {Card, Form, Header, Icon, Input, Button, Menu} from 'semantic-ui-react';
import {selectSidePanelTask} from '../services/TaskTemplates.selectors.js';
import {changeTask, createTask, updateTask, deleteTask} from '../services/TaskTemplates.actions.js';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';
import {useParams} from 'react-router-dom';

const SectionInformation = (props) => {
	// id of the template
	const {id} = useParams();

	let headerContent;
	let saveAction;
	let deleteButton;

	if (props.sidePanelTask.id) {
		headerContent = 'Edit Section';
		saveAction = (sidePanelTask, id) => props.updateTask(sidePanelTask, id);
		deleteButton = (
			<Button type="button" color="red" onClick={() => props.deleteTask(props.sidePanelTask)}>
				Delete
			</Button>
		);
	} else {
		headerContent = 'Create Section';
		saveAction = (sidePanelTask, id) => props.createTask(sidePanelTask, id);
	}

	return [
		<Card>
			<Card.Header>
				<Header as="h3" style={{margin: 10}}>
					<Icon name="edit" />
					<Header.Content>{headerContent}</Header.Content>
				</Header>
			</Card.Header>

			<Card.Content key={'inputFields'}>
				<Form>
					<Form.Field>
						<label>Section Title</label>
						<DebounceInput
							element={Input}
							placeholder="Section Title"
							debounceTimeout={600}
							key={`-input`}
							onChange={(e) => props.changeTask({title: e.target.value})}
							value={props.sidePanelTask.title}
						/>
					</Form.Field>
				</Form>

				<Menu fluid widths={16} icon="labeled" secondary style={{display: 'flex', flexDirection: 'column'}}>
					<Menu.Item
						disabled={props.sidePanelTask.completed}
						as="a"
						onClick={() => props.changeTask({isSequential: !props.sidePanelTask.isSequential})}
					>
						<Icon color="blue" name={props.sidePanelTask.isSequential ? 'toggle on' : 'toggle off'} />
						Tasks must be completed sequentially
					</Menu.Item>
				</Menu>
			</Card.Content>
			<Card.Content key={'PanelButtonGroup'} style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
				{deleteButton}
				<Button type="button" color="green" onClick={() => saveAction(props.sidePanelTask, id)}>
					Save
				</Button>
			</Card.Content>
		</Card>
	];
};

const mapStateToProps = (state) => ({
	sidePanelTask: selectSidePanelTask(state)
});

const mapDispatchToProps = (dispatch) => ({
	changeTask: (updatedValues) => dispatch(changeTask(updatedValues)),
	createTask: (task, templateId) => dispatch(createTask(task, templateId)),
	updateTask: (task, templateId) => dispatch(updateTask(task, templateId)),
	deleteTask: (task) => dispatch(deleteTask(task))
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionInformation);
