import React, {Component} from 'react';
import {Label, Segment, Grid} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '../../../../../../components/Global/AgGrid.js';
import FilterGrid from '../../../../../../components/Global/FilterGrid.js';
import ExportGrid from '../../../../../../components/Global/ExportGrid.js';

import {
	toggleCreateContactModal,
	toggleFetchingOnTabsNavbar
} from '../../../../../../components/Global/services/globalActions.js';
import {checkIdenticalsArrays} from '../../../../../../lib/old/validation.js';
import {DocumentsTabHeader} from '../services/profileAasdiGridReducer.js';
import {
	getTabRequestItems,
	addWorkViewDocument,
	updateDocumentValue,
	clearGrid
} from '../services/profileAasdiGridActions.js';

import AasdiDocumentSectionCheckbox from './DocumentSectionCheckBox.js';
import AasdiDocumentSectionRemoveButton from './DocumentSectionRemoveButton.js';
import DocumentsFieldsRenderer from './DocumentsFieldsRenderer.js';

const frameworkComponents = {
	aasdiDocumentSectionCheckbox: AasdiDocumentSectionCheckbox,
	aasdiDocumentSectionRemoveButton: AasdiDocumentSectionRemoveButton
};
class Documents extends Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps) {
		const {profileAasdiGridData, profileAasdiGridHeader, toggleFetchingOnTabsNavbar, loading} = this.props;

		if (prevProps.loading === false && loading === true) {
			toggleFetchingOnTabsNavbar(true);
		} else if (
			prevProps.profileAasdiGridData === null &&
			profileAasdiGridData
		) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.profileAasdiGridHeader, profileAasdiGridHeader)) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.rowData, profileAasdiGridData)) {
			toggleFetchingOnTabsNavbar(false);
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();

		const {aasdiId} = this.props;

		if (
			this.props.profileAasdiGridData &&
			this.props.profileAasdiGridData.length &&
			Number(aasdiId) === this.props.profileAasdiGridData[0].aasdiId &&
			checkIdenticalsArrays(this.props.profileAasdiGridHeader, DocumentsTabHeader)
		) {
			return;
		}

		this.props.clearGrid('Documents');
		this.gridApi.showLoadingOverlay();
		this.props.getTabRequestItems(aasdiId, 'Documents');
		this.props.toggleFetchingOnTabsNavbar(true);
	};

	render() {
		const {profileAasdiGridData, profileAasdiGridHeader, aasdiId} = this.props;
		return (
			<div>
				<Segment basic style={{marginTop: '1.5rem', border: '1px solid #DEDEDE'}}>
					<Label color="blue" ribbon>
						Add document
					</Label>
					<DocumentsFieldsRenderer id={aasdiId} type="aasdi" />
				</Segment>
				<Segment basic style={{marginTop: '1.5rem', border: '1px solid #DEDEDE'}} loading={this.props.loading}>
					<Label color="blue" ribbon>
						Documents
					</Label>
					<Grid style={{padding: '2em 0'}}>
						<FilterGrid gridApi={() => this.gridApi} />
						<ExportGrid gridApi={() => this.gridApi} />
					</Grid>
					<AgGrid
						columnDefs={profileAasdiGridHeader}
						rowData={profileAasdiGridData}
						onGridReady={this.onGridReady}
						frameworkComponents={frameworkComponents}
					/>
				</Segment>
			</div>
		);
	}
}

Documents.propTypes = {
	getTabRequestItems: PropTypes.func.isRequired,
	toggleCreateContactModal: PropTypes.func,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	profileAasdiGridData: PropTypes.array.isRequired,
	profileAasdiGridHeader: PropTypes.array.isRequired,
	aasdiId: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	clearGrid: PropTypes.func
};

const mapStateToProps = (state) => ({
	profileAasdiGridData: state.work.aasdiProfileGrid.rowData,
	profileAasdiGridHeader: state.work.aasdiProfileGrid.headerData,
	sectionFields: state.work.aasdiProfileGrid.documentsFields.fields,
	loading: state.work.aasdiProfileGrid.documentsFields.loading
});

const mapDispatchToProps = (dispatch) => ({
	getTabRequestItems: (aasdiId, item) => dispatch(getTabRequestItems(aasdiId, item)),
	toggleCreateContactModal: (status) => dispatch(toggleCreateContactModal(status)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	addWorkViewDocument: (fileName, googleDriveLink, customerApproval, comments, id) =>
		dispatch(addWorkViewDocument(fileName, googleDriveLink, customerApproval, comments, id)),
	updateDocumentValue: (index, value) => dispatch(updateDocumentValue(index, value)),
	clearGrid: (item) => dispatch(clearGrid(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
