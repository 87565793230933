// TODOS
// make every case in this reducer use immutability-helper

import update from 'immutability-helper';

import {
	TASK_TEMPLATES_RETRIEVE_TEMPLATES,
	TASK_TEMPLATES_RETRIEVE_TEMPLATES_SUCCESS,
	TASK_TEMPLATES_RETRIEVE_TEMPLATES_FAILURE,
	TASK_TEMPLATE_DELETE_TEMPLATE_SUCCESS,
	TASK_TEMPLATE_UPDATE_TEMPLATE_SUCCESS,
	TASK_TEMPLATE_CHANGE_TEMPLATE_VALUES,
	TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS,
	TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_FAILURE,
	TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_SUCCESS,
	TASK_TEMPLATES_EDIT_TEMPLATE,
	TASK_TEMPLATES_CREATE_TEMPLATE
} from '../TaskTemplates.actions.js';

export const templatesInitialState = {
	templates: [],
	templatesLoading: true,
	errorLoadingTemplates: false,
	templatesBeingRetrieved: false,

	// template, section, task
	sidePanelMode: 'template',
	sidePanelTask: null,

	// Misnomer; should really be called something along the lines of taskTypeList
	taskList: [],

	inputMaskList: [],

	clientListSearchResults: [],
	clientListSearchResultsLoading: false,
	errorLoadingClientListSearchResults: false
};

export default function (state, action) {
	switch (action.type) {
		case TASK_TEMPLATES_RETRIEVE_TEMPLATES:
			state = update(state, {
				templatesBeingRetrieved: {
					$set: true
				}
			});
			break;

		case TASK_TEMPLATES_RETRIEVE_TEMPLATES_SUCCESS:
			state = update(state, {
				templatesLoading: {
					$set: false
				},
				templates: {
					$set: action.payload.templates
				},
				taskList: {
					$set: action.payload.taskList
				},
				inputMaskList: {
					$set: action.payload.inputMaskList
				}
			});
			break;

		case TASK_TEMPLATES_RETRIEVE_TEMPLATES_FAILURE:
			state = update(state, {
				...state,
				templatesLoading: {
					$set: false
				},
				errorLoadingTemplates: {
					$set: true
				}
			});
			break;

		case TASK_TEMPLATE_CHANGE_TEMPLATE_VALUES:
			const {updatedValues} = action.payload;
			return {
				...state,
				// replacing every template after changing a single value in the selected template strikes me as not gucci practice -> i should probs fix this
				templates: state.templates.map((template) => {
					if (template.id === action.payload.templateId) {
						return {...template, ...updatedValues};
					}
					return template;
				})
			};

		case TASK_TEMPLATE_DELETE_TEMPLATE_SUCCESS:
			const {templateId} = action.payload;
			return {
				...state,
				templates: state.templates.filter((template) => template.id !== templateId),
				selectedTemplateTasks: {
					pinned: [],
					general: []
				}
			};

		case TASK_TEMPLATE_UPDATE_TEMPLATE_SUCCESS:
			const {updatedTemplate} = action.payload;
			return {
				...state,
				templates: state.templates.map((template) => {
					if (template.id === updatedTemplate.id) {
						return updatedTemplate;
					}
					return template;
				})
			};

		case TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS:
			state = update(state, {
				clientListSearchResultsLoading: {
					$set: true
				}
			});
			break;

		case TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_SUCCESS:
			const {searchResults} = action.payload;
			state = update(state, {
				clientListSearchResults: {
					$set: searchResults
				},
				clientListSearchResultsLoading: {
					$set: false
				}
			});
			break;

		case TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_FAILURE:
			state = update(state, {
				clientListSearchResultsLoading: {
					$set: false
				},
				errorLoadingClientListSearchResults: {
					$set: true
				}
			});
			break;

		case TASK_TEMPLATES_EDIT_TEMPLATE:
			state = update(state, {
				sidePanelMode: {
					$set: 'template'
				}
			});
			break;

		case TASK_TEMPLATES_CREATE_TEMPLATE:
			const {newTemplate} = action.payload;
			state = update(state, {
				templates: {
					$set: [...state.templates, newTemplate]
				}
			});
			break;
	}

	return state;
}
