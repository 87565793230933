import update from 'react-addons-update';

export default (
	state = {
		isOpen: false,
		opportunityData: {},
		opportunityDataRow: 0,
		isLoading: false
	},
	action
) => {
	switch (action.type) {
		case 'TOGGLE_COMMIT_MODAL':
			state = update(state, {
				isOpen: {
					$set: !state.isOpen
				}
			});
			break;
		case 'SET_COMMIT_MODAL_OPPORTUNITY_DATA':
			state = update(state, {
				opportunityData: {
					$set: action.payload
				}
			});
			break;
		case 'COMMIT_MODAL_LOADING':
			state = update(state, {
				isLoading: {
					$set: action.payload
				}
			});
	}
	return state;
};
