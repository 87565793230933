import React from 'react';
import {connect} from 'react-redux';
import {Checkbox, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {updateWorkViewDocument} from '../../../../services/workRecordActions.js';

class DocumentSectionCheckbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:
				this.props.documentsData[this.props.rowIndex] &&
				this.props.documentsData[this.props.rowIndex].customerApproval
					? this.props.documentsData[this.props.rowIndex].customerApproval
					: false
		};
	}
	render() {
		// isMobileDriveLink check is used to not show the edit option for mobile drive link
		return (
			<div style={{paddingTop: '.2em'}}>
				{this.props.documentsData[this.props.rowIndex] && !this.props.documentsData[this.props.rowIndex].isMobileDriveLink ? (
					<Checkbox
						label={this.state.checked ? <label>Yes</label> : <label>No</label>}
						defaultChecked={
							this.props.documentsData[this.props.rowIndex] &&
							this.props.documentsData[this.props.rowIndex].customerApproval
								? this.props.documentsData[this.props.rowIndex].customerApproval
								: false
						}
						onClick={() => {
							this.props.updateWorkViewDocument(
								this.props.rowIndex,
								this.props.documentsData[this.props.rowIndex]
							);
							this.setState({
								checked: !this.state.checked
							});
						}}
					/>
				) : <></>}
			</div>
		);
	}
}

DocumentSectionCheckbox.propTypes = {
	documentsData: PropTypes.object,
	rowIndex: PropTypes.number,
	updateWorkViewDocument: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		documentsData: state.work.workRecord.view.Documents.documents
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateWorkViewDocument: (index, value) => {
			dispatch(updateWorkViewDocument(index, value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSectionCheckbox);
