import update from 'immutability-helper';
import moment from 'moment';
import countryOptions from '../../../../../../../constants/countries.js';
import stateOptions from '../../../../../../../constants/states.js';

export default (
	state = {
		loading: false,
		rowData: null,
		globalSKUModalOpen: false,
		globalSKUsLoading: false,
		globalSKUs: [],
		headerData: OverviewTabHeader,
		tab: 'Overview',
		stageNames,
		createSKUVariables,
		SKUToDeactivate: null,
		contactList: [],
		localOrderHist: null,
		showDeactiveLocationModal: false,
		LocationToDeactivate: null,
		profileName: null,
		overview: {
			sales: {
				ytd: '1000.00',
				lastYear: '8567.00'
			}
		}
	},
	action
) => {
	switch (action.type) {
		case 'WORK_CLIENT_PROFILE_NAME':
			state = update(state, {
				profileName: {
					$set: action.payload.data || action.payload
				}
			});
			break;
		case 'UPDATE_ROWDATA_GRID':
			state = update(state, {
				headerData: {
					$set: headers[`${action.payload.item}TabHeader`] || OverviewTabHeader
				},
				rowData: {
					$set: action.payload.data
				},
				tab: {
					$set: action.payload.item
				}
			});
			break;
		case 'DEFAULT_LOADING':
			state = update(state, {
				loading: {
					$set: action.payload
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_MODAL_SELECT_SKU_MODAL_TOGGLE':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						selectSKUModal: {
							toggle: {
								$set: action.payload.toggle
							},
							SKUList: {
								$set: action.payload.SKUList
							}
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_COMPLETE_PROJECT_COSTING_SPREADSHEET':
			state = update(state, {
				rowData: {
					$push: action.payload.rowData
				},
				createSKUVariables: {
					summary: {
						$set: action.payload.summary
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_TOGGLE':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						toggle: {
							$set: action.payload.value
						},
						spreadSheetData: {
							$set: action.payload.spreadSheetData
						},
						actionType: {
							$set: action.payload.type
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_GRID_UPDATE_TOTAL':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						[action.payload.field]: {
							$set: action.payload.value
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_GRID_UPDATE':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						spreadSheetData: {
							[action.payload.rowIndex]: {
								[action.payload.field]: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_GRID_UPDATE_SKU':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						spreadSheetData: {
							[action.payload.rowIndex]: {
								$set: action.payload.object
							}
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_ADD_ROW':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						spreadSheetData: {
							$push: [action.payload.object]
						}
					}
				}
			});
			break;

		case 'CREATE_CLIENT_SKU_MODAL_SELECT_SKU_MODAL_ADD_ITEMS':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						spreadSheetData: {
							[action.payload.spreadSheetDataEmptyIndex]: {
								$set: action.payload.object
							}
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_GET_SKUS':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						SKUs: {
							$set: action.payload.skuArray
						},
						skuLoaded: {
							$set: action.payload.skuLoaded
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_GET_SKU_LOADED':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						skuLoaded: {
							$set: action.payload.skuLoaded
						}
					}
				}
			});
			break;
		case 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_REMOVE_ROW':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						spreadSheetData: {
							$splice: [[action.payload.rowIndex, 1]]
						}
					}
				}
			});
			break;
		case 'CLIENT_VIEW_REMOVE_LOCATION':
			state = update(state, {
				rowData: {
					$splice: [[action.payload.index, 1]]
				}
			});
			break;
		case 'UPDATE_CLIENT_CONTACT_LIST':
			state = update(state, {
				contactList: {
					$set: action.payload.data
				}
			});
			break;
		case 'ADD_LOCATION_ROWDATA_GRID':
			state = update(state, {
				rowData: {
					$push: action.payload.data
				}
			});
			break;
		case 'UPDATE_LOCATION':
			state = update(state, {
				rowData: {
					[action.payload.index]: {
						phone: {
							$set: action.payload.data
						}
					}
				}
			});
			break;
		case 'CLIENT_SKU_SET':
			state = update(state, {
				rowData: {
					[action.payload.index]: {$set: action.payload.value}
				}
			});
			break;
		case 'CLIENT_ADD_SKUS':
			state = update(state, {
				rowData: {
					$push: action.payload
				}
			});
			break;
		case 'CLIENT_PRICING_OPEN_GLOBAL_SKUS':
			state = update(state, {
				globalSKUModalOpen: {$set: true}
			});
			break;
		case 'CLIENT_PRICING_CLOSE_GLOBAL_SKUS':
			state = update(state, {
				globalSKUModalOpen: {$set: false}
			});
			break;
		case 'CLIENT_PRICING_GLOBAL_SKUS_LOADING':
			state = update(state, {
				globalSKUsLoading: {$set: true}
			});
			break;
		case 'CLIENT_PRICING_GLOBAL_SKUS_LOADED':
			state = update(state, {
				globalSKUsLoading: {$set: false},
				globalSKUs: {$set: action.payload}
			});
			break;
		case 'CLIENT_VIEW_ADD_DOCUMENT':
			state = update(state, {
				rowData: {
					$push: [action.payload.object]
				}
			});
			break;
		case 'CLIENT_VIEW_REMOVE_DOCUMENT':
			state = update(state, {
				rowData: {
					$splice: [[action.payload.index, 1]]
				}
			});
			break;
		case 'CLIENT_SKUS_LOADING':
			state = update(state, {
				loading: {
					$set: action.payload
				}
			});
			break;
		case 'CLIENT_SKU_SELECT_SKU_MODAL_ADD_ITEMS':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						spreadSheetData: {
							[action.payload.spreadSheetDataEmptyIndex]: {
								$set: action.payload.object
							}
						}
					}
				}
			});
			break;
		case 'CLIENT_CREATE_SKU_MODAL_LOADING':
			state = update(state, {
				createSKUVariables: {
					projectCostingSpreadsheetModal: {
						isCreatingSKU: {
							$set: action.payload
						}
					}
				}
			});
			break;
		case 'CLIENT_VIEW_UPDATE_DOCUMENT':
			state = update(state, {
				rowData: {
					[action.payload.index]: {
						customerApproval: {
							$set: action.payload.value
						}
					}
				}
			});
			break;
		case 'SET_CLIENT_LOCATION_WORK':
			state = update(state, {
				localOrderHist: {
					$set: action.payload.data
				}
			});
			break;
		case 'CLEAR_CLIENT_LOCATION_WORK':
			state = update(state, {
				localOrderHist: {
					$set: action.payload.data
				}
			});
			break;
		case 'CLIENT_TOGGLE_DEACTIVE_LOCATION_MODAL':
			state = update(state, {
				showDeactiveLocationModal: {
					$set: action.payload
				}
			});
			break;
		case 'CLIENT_CLEAR_LOCATION_TO_DEACTIVATE':
			state = update(state, {
				LocationToDeactivate: {
					$set: action.payload
				}
			});
			break;
		case 'CLIENT_SET_LOCATION_TO_DEACTIVATE':
			state = update(state, {
				LocationToDeactivate: {
					$set: action.payload
				}
			});
			break;
		case 'CLIENT_VIEW_ADDED_CONTACT':
			state = update(state, {
				rowData: {
					$push: action.payload
				}
			});
			break;
		default:
	}
	return state;
};

export const OverviewTabHeader = [
	{
		headerName: 'First Name',
		field: 'firstName',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Last Name',
		field: 'lastName',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Email',
		field: 'email',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Phone',
		field: 'phone',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Ext.',
		field: 'extension',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Cell',
		field: 'mobilePhone',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Address',
		field: 'address',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Address 2',
		field: 'address2',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'City',
		field: 'city',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'State',
		field: 'state',
		filter: 'agTextColumnFilter',
		cellEditor: 'agSelectCellEditor',
		valueFormatter: (params) => {
			return params.value;
		},
		cellEditorParams: {
            values: stateOptions.map((state) => state.value),
        },
		width: 100,
		editable: true
	},
	{
		headerName: 'Country',
		field: 'countryId',
		filter: 'agTextColumnFilter',
		editable: true,
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			cellRenderer: function (params) {
				const country = countryOptions.find((country) => params.value === country.value);
				return country ? country.text : params.value;
			},
			values: countryOptions.map((country) => country.text)
		},
		cellRenderer: function (params) {
			const country = countryOptions.find((country) => params.value === country.value);
			return country ? country.text : params.value;
		}
	},
	{
		headerName: 'ZIP',
		field: 'zip',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Title',
		field: 'title',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Billing Contact',
		field: 'isBillingContact',
		cellEditor: 'agSelectCellEditor',
		valueFormatter: (params) => {
			return params.value ? 'Yes' : 'No';
		},
		cellEditorParams: {
            values: [true, false],
        },
		width: 250,
		editable: true
	}
];

const WorkTabHeader = [
	{
		headerName: 'Action bar',
		field: '',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'clientWorkTabCellRenderer'
	},
	{
		headerName: 'Work Order',
		field: 'workNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Order Status',
		field: 'status_Name',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Issue Date',
		field: 'createdOn',
		filter: 'agTextColumnFilter',
		editable: false,
		valueFormatter: (params) => {
			return params.value ? moment.parseZone(params.value).format('L') : '';
		}
	},
	{
		headerName: 'NDS',
		field: 'workManager_Name',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Store Number/Location ID',
		field: 'location_StoreStoreNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Location Name',
		field: 'location_Name',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'AASDI',
		field: 'aasdiName',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Job Number',
		field: 'quotedJobNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		//MISSING
		headerName: 'Order Group',
		field: '',
		filter: 'agTextColumnFilter',
		editable: false
	}
];

const OpportunitiesTabHeader = [
	{
		headerName: 'Actions',
		field: 'action',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'clientOpportunitiesActionTabCellRenderer',
		colId: 'actionColumn'
	},
	{
		headerName: 'Status',
		field: 'status',
		cellRenderer: 'leadStatusCellRenderer',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Name',
		field: 'name',
		filter: 'agTextColumnFilter',
		editable: false,
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Projected Order Date',
		field: 'projectedOrderDate',
		filter: 'agDateColumnFilter',
		cellEditor: 'datePickerButton',
		cellEditorParams: {
			column: 'projectedOrderDate'
		},
		editable: false,
		cellStyle: {paddingTop: '.4em'},
		valueFormatter: (params) => {
			return params.value ? moment.parseZone(params.value).format('L') : '';
		}
	},
	{
		headerName: 'Bid Due Date',
		field: 'bidDueDate',
		filter: 'agDateColumnFilter',
		cellEditor: 'datePickerButton',
		cellEditorParams: {
			column: 'bidDueDate'
		},
		editable: false,
		cellStyle: {paddingTop: '.4em'},
		valueFormatter: (params) => {
			return params.value ? moment.parseZone(params.value).format('L') : '';
		}
	},
	{
		headerName: 'Stage',
		field: 'stageName',
		cellRenderer: 'statusCellRenderer',
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			cellRenderer: 'statusCellRenderer',
			values: [
				{name: 'Identified Need', id: '1'},
				{name: 'Estimating', id: '2'},
				{name: 'Submitted Quote', id: '3'},
				{name: 'High Probability', id: '4'},
				{name: 'Verbal Award', id: '5'}
			]
		},
		editable: false,
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Account Exec',
		field: 'accountExecutiveName',
		filter: 'agTextColumnFilter',
		editable: false,
		cellStyle: {paddingTop: '.4em'}
	}
];

export const PricingTabHeader = [
	{
		headerName: 'Actions',
		field: '',
		filter: 'agTextColumnFilter',
		cellRenderer: 'clientSKURemoveButton',
		editable: false,
		width: 120
	},
	{
		headerName: 'Description',
		field: 'description',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Total',
		colId: 'total',
		filter: 'agTextColumnFilter',
		editable: false,
		valueGetter: (params) => params.data.unitLaborCost + params.data.unitMaterialCost,
		valueFormatter: (params) => {
			return params.value ? `$${params.value}` : '$0';
		}
	},
	{
		headerName: 'Material',
		field: 'unitMaterialCost',
		filter: 'agTextColumnFilter',
		editable: false,
		valueFormatter: (params) => {
			return params.value ? `$${params.value}` : '$0';
		}
	},
	{
		headerName: 'Labor',
		field: 'unitLaborCost',
		filter: 'agTextColumnFilter',
		editable: false,
		valueFormatter: (params) => {
			return params.value ? `$${params.value}` : '$0';
		}
	},
	{
		headerName: 'Created By',
		field: 'createdByName',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Created On',
		field: 'createdOn',
		filter: 'agTextColumnFilter',
		editable: false,
		valueFormatter: (params) => {
			return moment(params.value).format('MM-DD-YYYY h:mm A');
		}
	}
];

export const LocationsTabHeader = [
	{
		headerName: 'Actions',
		field: '',
		filter: 'agTextColumnFilter',
		cellRenderer: 'locationGridActionsCellRenderer',
		editable: false,
		width: 120
	},
	{
		headerName: 'Store Number/Location ID',
		field: 'storeNumber',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Name',
		field: 'name',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Address',
		field: 'address',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Address 2',
		field: 'address2',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'City',
		field: 'city',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'State',
		field: 'state',
		filter: 'agTextColumnFilter',
		editable: true,
		valueFormatter: (params) => {
			return params.value ? params.value.substring(0, 2).toUpperCase() : '';
		}
	},
	{
		headerName: 'Country',
		field: 'countryName',
		filter: 'agTextColumnFilter',
		editable: true,
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			values: countryOptions.map((country) => country.text)
		}
	},
	{
		headerName: 'Zip',
		field: 'zip',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Phone',
		field: 'phone',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Site Contact',
		field: 'siteContactName',
		filter: 'agTextColumnFilter',
		editable: true,
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			values: []
		}
	},
	{
		headerName: 'Billing Contact',
		field: 'billingContactName',
		filter: 'agTextColumnFilter',
		editable: true,
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			values: []
		}
	}
];

const DocumentsTabHeader = [
	{
		headerName: 'File Name',
		field: 'fileName',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Google Drive',
		field: 'googleDriveLink',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function (params) {
			return `<a href="${params.value}" target="_blank">${params.value}</a>`;
		}
	},
	{
		headerName: 'Comments',
		field: 'comments',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Customer Approval',
		field: '',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'clientDocumentSectionCheckbox'
	},
	{
		headerName: 'Remove',
		field: '',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'clientDocumentSectionRemoveButton'
	}
];

const headers = {
	WorkTabHeader,
	OpportunitiesTabHeader,
	PricingTabHeader,
	LocationsTabHeader,
	DocumentsTabHeader
};

const stageNames = ['Overview', 'Opportunities', 'Work', 'Locations', 'OrderGroups', 'Documents', 'Pricing'];

const createSKUVariables = {
	name: 'createSKUVariables',
	rowData: [],
	summary: {},
	projectCostingSpreadsheetModal: {
		skuLoaded: false,
		toggle: false,
		applyMM: null,
		applyLM: null,
		SKUs: [],
		spreadSheetData: [],
		selectSKUModal: {
			toggle: false,
			SKUList: []
		},
		isCreatingSKU: false,
		actionType: ''
	}
};
