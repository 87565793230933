import React, {Component, Fragment} from 'react';
import {Label, Segment, Button, Icon, Modal} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {SKUGrid} from '../../../../../../../components/sku/SKUGrid.jsx';
import {toggleFetchingOnTabsNavbar} from '../../../../../../../components/Global/services/globalActions.js';
import {
	getTabRequestItems,
	clearGrid,
	updateSKU,
	addSKU,
	toggleGlobalSKUsModal,
	loadGlobalSKUs,
	addSKUs
} from '../services/profileGridActions.js';
import SelectSKUsModal from '../../../../../../../components/modals/SelectSKUsModal.jsx';

class PricingTab extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		//see notes in mapStateToProps
		this.props.clearGrid('Pricing');
		this.props.getTabRequestItems(this.props.clientId, 'Pricing');
	}

	componentWillUnmount() {
		//TODO: unload the global skus. dont want to keep them around
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	onRowToggle = (i) => {
		this.props.toggleSKU(i);
	};

	render() {
		return (
			<Fragment>
				<Segment
					basic
					style={{
						marginTop: '1.5rem',
						border: '1px solid #DEDEDE',
						display: 'flex',
						flexFlow: 'column nowrap',
						alignItems: 'flex-start'
					}}
				>
					<div
						style={{
							marginTop: 15,
							display: 'flex',
							flexFlow: 'row nowrap',
							justifyContent: 'space-between',
							width: '100%'
						}}
					>
						<div>
							<Label color="blue" ribbon="left">
								Pricing
							</Label>
						</div>
						<Button.Group>
							<Button
								color="green"
								disabled={this.props.loading}
								onClick={() => this.props.addSKU(this.props.clientId)}
							>
								<Icon name="plus" /> Add Row
							</Button>
							<Button
								color="grey"
								//disabled={!this.props.globalSKUsLoaded}
								onClick={() => {
									this.props.toggleGlobalSKUsModal(true);
									if (this.props.globalSKUs.length === 0) {
										this.props.loadGlobalSKUs();
									}
								}}
							>
								<Icon.Group>
									<Icon color="blue" name="globe" />
									<Icon corner color="green" name="plus" />
								</Icon.Group>{' '}
								Add SKUs From Global
							</Button>
						</Button.Group>
						{/* <ExportGrid gridApi={() => this.gridApi} /> */}
					</div>
					<div style={{width: '100%', height: '60vh', marginTop: 15}}>
						<SKUGrid
							data={this.props.profileGridData}
							loading={this.props.loading}
							canDeactivate
							canChangeFixed
							editable
							onDataChange={(i, data) => this.props.updateSKU(i, data)}
						/>
					</div>
				</Segment>
				<SelectSKUsModal
					open={this.props.globalSKUModalOpen}
					skus={this.props.globalSKUs}
					loading={this.props.globalSKUsLoading}
					onClose={(selected) => {
						this.props.toggleGlobalSKUsModal(false);
						if (selected) {
							this.props.addSKUs(this.props.clientId, selected);
						}
					}}
				/>
			</Fragment>
		);
	}
}

PricingTab.propTypes = {
	profileGridData: PropTypes.array,
	clientProfileInfo: PropTypes.object,
	getTabRequestItems: PropTypes.func,
	clientId: PropTypes.string,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	loading: PropTypes.bool,
	globalSKUModalOpen: PropTypes.bool
};

const mapStateToProps = (state) => ({
	profileGridData: state.work.clientProfileGrid.rowData,
	clientProfileInfo: state.work.clientProfileGrid.profileInfos,
	stageNames: state.work.clientProfileGrid.stageNames,
	loading:
		state.work.clientProfileGrid.loading ||
		//Have to put this check here because tab state isnt updated properly until this tab is rendered.
		//It should not be the responsibility of the tab to tell our state that it is active.. should be the other way around
		state.work.clientProfileGrid.tab !== 'Pricing',
	globalSKUs: state.work.clientProfileGrid.globalSKUs,
	globalSKUsLoading: state.work.clientProfileGrid.globalSKUsLoading,
	globalSKUModalOpen: state.work.clientProfileGrid.globalSKUModalOpen
});

const mapDispatchToProps = {
	getTabRequestItems,
	toggleFetchingOnTabsNavbar,
	toggleGlobalSKUsModal,
	clearGrid,
	updateSKU,
	addSKU,
	loadGlobalSKUs,
	addSKUs
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingTab);
