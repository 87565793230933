import React from 'react';
import {connect} from 'react-redux';
import {Checkbox} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {updateDocument} from '../../../../../../services/opportunityActions.js';

class DocumentSectionCheckbox extends React.Component {
	constructor(props) {
		super(props);
		console.log(props);
		this.state = {
			checked:
				this.props.documentsData[this.props.rowIndex] &&
				this.props.documentsData[this.props.rowIndex].customerApproval
					? this.props.documentsData[this.props.rowIndex].customerApproval
					: false
		};
	}
	render() {
		return (
			<div style={{paddingTop: '.2em'}}>
				<Checkbox
					label={this.state.checked ? <label>Yes</label> : <label>No</label>}
					defaultChecked={
						this.props.documentsData[this.props.rowIndex] &&
						this.props.documentsData[this.props.rowIndex].customerApproval
							? this.props.documentsData[this.props.rowIndex].customerApproval
							: false
					}
					onClick={() => {
						this.props.updateDocument(this.props.rowIndex, this.props.documentsData[this.props.rowIndex]);
						this.setState({
							checked: !this.state.checked
						});
					}}
				/>
			</div>
		);
	}
}

DocumentSectionCheckbox.propTypes = {
	documentsData: PropTypes.object,
	rowIndex: PropTypes.number,
	updateDocument: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		documentsData: state.crm.opportunity.data.documents.documents
	};
};

const mapDispatchToProps = {
	updateDocument: (index, value) => updateDocument(index, value)
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSectionCheckbox);
