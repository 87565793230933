import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Icon, Header, Input} from 'semantic-ui-react';
import {connect} from 'react-redux';
import api from '../../../../../../../../../lib/api.ts';
import * as actions from '../../../services/workRecordActions.js';

class CanceledModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	handleSubmit = () => {
		const {id, toggleCanceledModal, updateProgressBar} = this.props;

		const status = 'Canceled';
		const endpoint = `legacy/work/${id}`;

		this.setState({loading: true});
		api(endpoint, 'put', {status})
			.then(() => {
				this.setState({loading: false});
				toggleCanceledModal();
				updateProgressBar(status);
			})
			.catch((error) => {
				this.setState({loading: false});
				console.log(error);
				toggleCanceledModal();
			});
	};

	render() {
		const {loading} = this.state;
		const {open, toggleCanceledModal} = this.props;
		return (
			<Modal
				open={open}
				style={{
					marginTop: '0'
				}}
			>
				<Header>
					<h3>Cancel Job</h3>
				</Header>
				<Modal.Content>
					<p>Are you sure you would like to cancel this job?</p>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							disabled={loading}
							onClick={() => {
								toggleCanceledModal();
							}}
							color="red"
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button loading={loading} disabled={loading} onClick={this.handleSubmit} color="green">
							<Icon name="checkmark" />
							Yes
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

CanceledModal.propTypes = {
	id: PropTypes.number,
	open: PropTypes.bool,
	toggleCanceledModal: PropTypes.func,
	updateProgressBar: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sendToClosure: state.global.sendToModal,
		open: state.work.workRecord.canceledModal.toggleCanceledModal,
		id: state.work.workRecord.view.id
	};
};

export default connect(mapStateToProps, actions)(CanceledModal);
