import { isGoogleDriveLink } from "~/components/form/validators";

/**
 * @deprecated use _.isEqual instead
 */
export const checkIdenticalsArrays = (array1, array2) => {
	if (!Array.isArray(array1) || !Array.isArray(array2)) {
		return false;
	}
	if (array1.length !== array2.length) {
		return false;
	}
	let answer = true;
	array1.forEach((element, index) => {
		if (typeof element === 'object') {
			answer = checkIdenticalsObjects(array2[index], element);
			return;
		}
		if (array2[index] !== element) {
			answer = false;
		}
	});
	return answer;
};

/**
 * @deprecated use _.isEqual instead
 */
export const checkIdenticalsObjects = (obj1, obj2) => {
	if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
		return false;
	}
	const keysObj1 = Object.keys(obj1);
	const keysObj2 = Object.keys(obj2);
	if (keysObj1.length !== keysObj2.length) {
		return false;
	}
	let answer = true;
	keysObj1.forEach((element) => {
		if (obj1[element] !== obj2[element]) {
			answer = false;
		}
	});
	return answer;
};

/**
 * @deprecated if you have to use this function, we should make the return type from the api consistent instead. This is a bandaid not a solution.
 */
export const validatePayload = (payload) => {
	if (Array.isArray(payload) && payload.length) {
		return payload;
	} else if (Array.isArray(payload) && !payload.length) {
		return [];
	} else if (typeof payload === 'object') {
		return [payload];
	}
	return [];
};

/**
 * @deprecated use isGoogleDriveLink instead
 */
export function validateGoogleDriveLink(gDriveLink) {
	return isGoogleDriveLink(gDriveLink) === undefined;
}

export const hasName = (name) => (field) => field.name === name;