import {Form} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

const SelectedChoiceTaskType = (props) => {
	return props.task.id ? (
		<Form.Field key={`${props.column}-formField`}>
			<div>
				{'Selected Choice:  '}
				<label>{props.task.userChoice ? props.task.userChoice.choice : null}</label>
			</div>
		</Form.Field>
	) : null;
};

export default SelectedChoiceTaskType;
