import {produce} from 'immer';
import {persistReducer} from 'redux-persist';
import persistStorage from 'redux-persist/es/storage'; // defaults to localStorage for web

type AuthState = {
	idToken: string | null
	refreshToken: string | null
	expires: number | null
	profile: null | {
		firstName: string
		lastName: string
		email: string
		picture: string
	},
	//TODO: replace with actual account info
	account: null | Record<string, unknown>
}

const defaultState: AuthState = {
	idToken: null,
	refreshToken: null,
	expires: null,
	profile: null,
	account: null
};


export type AuthAction = ActionSetCredentials | ActionClearCredentials | ActionRefreshToken
type ActionSetCredentials = {
	type: 'AUTH_SET_USER'
	payload: AuthState
}
type ActionClearCredentials = {
	type: 'AUTH_CLEAR_USER'
}
type ActionRefreshToken = {
	type: 'AUTH_REFRESH_TOKEN',
	payload: {
		idToken: string
		expires: number
	}
}

const reducer = (state: AuthState = defaultState, action: AuthAction): AuthState => {
	switch (action.type) {
		case 'AUTH_SET_USER': {
			return action.payload
		}
		case 'AUTH_REFRESH_TOKEN': {
			return produce(state, draft => {
				draft.idToken = action.payload.idToken
				draft.expires = action.payload.expires
			})
		}
		case 'AUTH_CLEAR_USER': {
			return defaultState
		}
		default: {
			
		}
	}

	return state;
};

//we need to persist account because that is not stored with our credentials
export default persistReducer(
	{
		key: 'auth',
		storage: persistStorage
	},
	reducer
);
