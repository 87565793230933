import React from 'react';
import {Button, Modal, Icon, Header, Checkbox, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import * as actions from '../services/WorkAasdiGridActions.js';
import * as action2 from '../../../../../components/Global/services/globalActions.js';
import {certifications} from '../../../../../constants/certifications.js';

const certSearchModal = (props) => (
	<Modal open={props.isOpen} size="fullscreen">
		<Header>
			<Icon name="file alternate outline" color="blue" />
			<div className="content">Select Certificates</div>
		</Header>
		<Modal.Content scrolling>
			{certifications.map((parent, index) => {
				return (
					<Segment.Group style={{marginLeft: '1vw', marginRight: '1vw'}} key={index}>
						<Segment inverted color="blue">
							{parent.name}
						</Segment>
						{parent.cert.map((category, index) => {
							return (
								<Segment.Group
									style={{
										marginLeft: '1vw',
										marginRight: '1vw'
									}}
									key={index}
									color="grey"
								>
									<Segment inverted color="grey">
										{category.name}
									</Segment>
									<Segment.Group>
										{category.subCert.map((cert, index) => {
											return (
												<Segment key={index}>
													<Checkbox
														style={{
															marginRight: '1vw'
														}}
														checked={
															props.selectedCerts.findIndex((id) => id == cert.id) !== -1
														}
														onClick={(event, {checked}) => {
															if (checked === true) {
																props.addSearchCert(cert.id);
															} else {
																props.removeSearchCert(cert.id);
															}
														}}
													/>
													{cert.name}
												</Segment>
											);
										})}
									</Segment.Group>
								</Segment.Group>
							);
						})}
					</Segment.Group>
				);
			})}
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						const apiGrid = props.gridApi();
						props.clearSearchCert(apiGrid);
						props.toggleCertModal(false);
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					onClick={() => {
						const apiGrid = props.gridApi();
						props.toggleCertModal(false);
						props.searchAasdiCertifications(props.selectedCerts, '', apiGrid);
					}}
					color="blue"
				>
					<Icon name="search" /> Search
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

const mapStateToProps = (state) => {
	return {
		isOpen: state.work.aasdiGrid.certModal.open,
		isLoading: state.work.aasdiGrid.certModal.open,
		selectedCerts: state.work.aasdiGrid.certModal.selectedCert
	};
};

export default connect(mapStateToProps, {...actions, ...action2})(certSearchModal);
