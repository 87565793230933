import {Label, Popup, Icon} from 'semantic-ui-react';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const labelProperties = {
	1: {icon: 'camera', content: ' Take a picture'},
	2: {icon: 'resize horizontal', content: ' Swipe to complete'},
	3: {icon: 'text cursor', content: ' Form Input'},
	4: {icon: 'write', content: ' Signature'},
	5: {icon: 'external', content: ' Open a URL'},
	6: {icon: 'dropdown', content: ' Dropdown'},
	7: {icon: 'text cursor', content: ' Free Form Text Area'},
	8: {icon: 'check square', content: ' Checkout'},
	9: {icon: 'paragraph', content: ' Paragraph'}
};

const extraLabel = (props) => ({
	1: renderListIfExists(props.task.pictureDriveLinks, (link) => (
		<Label key={uuidv4()} className="pseudoDragHandler" style={{maxWidth: '130px'}}>
			<Icon className="pseudoDragHandler" name="linkify" />
			<a className="pseudoDragHandler" href={link} target="_blank">
				Open image
			</a>
		</Label>
	)),
	5: renderItemIfExists(props.task.url, (url) => (
		<Label key={uuidv4()} className="pseudoDragHandler">
			<Icon className="pseudoDragHandler" name="linkify" />
			<a className="pseudoDragHandler" href={url} target="_blank">
				Click to open URL
			</a>
		</Label>
	)),
	6:
		props.task.userChoice && props.task.userChoice.choice
			? renderItemIfExists(props.task.userChoice.choice, (choice) => (
					<Popup
						key={uuidv4()}
						size={'tiny'}
						key={'choice'}
						trigger={<Label content={` ${choice}`} />}
						content="The techs choice."
						on="hover"
					/>
			  ))
			: null
});

const TaskLabel = (props) => [
	<Label key={uuidv4()} color="blue" {...labelProperties[props.task.taskTypeId]} />,
	extraLabel(props)[props.task.taskTypeId]
];

export default TaskLabel;

// helper functions
function renderItemIfExists(item, callback) {
	if (item) {
		return callback(item);
	}
}

function renderListIfExists(list, callback) {
	if (list) {
		return list.map(callback);
	}
}
