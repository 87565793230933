import React, {Component} from 'react';
import {Button, Modal, Segment, Grid, Input, Label, Dropdown, Header, Popup, Icon} from 'semantic-ui-react';
import {connect} from 'react-redux';
import moment from 'moment';
import {MomentDatePicker} from '../../../../../../../../../components/inputs/DatePicker.jsx';

import PropTypes from 'prop-types';

import {debounce, debounceEventHandler} from '../../../../../../../../../lib/old/debounce.js';

import * as actions from '../../../services/workRecordActions.js';
import {FancyEditor} from '../../../../../../../../../components/inputs/FancyEditor.jsx';

class CreateTripModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sowValue: 'sow',
			specialValue: ''
		};
	}

	componentDidMount() {
		this.props.clearContactOptions();
	}

	render() {
		return (
			<Modal size="large" open={this.props.open}>
				<Modal.Header>Create New Trip</Modal.Header>
				<Modal.Content scrolling style={{height: '70vh'}}>
					<Segment basic loading={Boolean(this.props.modalLoading)}>
						<Grid as={Segment} style={{paddingTop: '1vh'}} textAlign="center">
							<Label attached="top" color="blue">
								Basic Info
							</Label>
							{this.props.fields.map((field, index) => {
								if (field.input) {
									return (
										<Grid.Column width={16}>
											<Grid.Column width={4} />
											<Grid.Column width={8}>
												<Header.Content>
													<h4>{field.name}</h4>
												</Header.Content>
												<Input
													// fluid
													error={field.error}
													placeholder="Enter Name..."
													className="hiddenInput"
													onChange={(e) => {
														this.props.updateCreateTrip(e.target.value, index);
													}}
												/>
												{field.error ? (
													<Label basic color="red" pointing>
														A name is required to create a trip
													</Label>
												) : null}
											</Grid.Column>
											<Grid.Column width={4} />
										</Grid.Column>
									);
								} else if (field.contact) {
									return (
										<Grid.Column
											as={Segment}
											key={index}
											width={7}
											style={{
												margin: '1vh',
												paddingTop: '2vh'
											}}
										>
											<Grid.Column width={3}>
												<Label
													attached="top left"
													style={{
														padding: '1vh .5vw'
													}}
												>
													<Icon name="user" />
													{field.name}
												</Label>
												<Dropdown
													fluid
													search
													loading={field.loading}
													className="hiddenInput"
													placeholder={'Search by name...'}
													style={{
														marginTop: '.75vh',
														paddingBottom: '0'
													}}
													options={this.props.options}
													onSearchChange={debounceEventHandler(
														debounce((e) => {
															if (e.target.value) {
																this.props.getContactsOptions(
																	e.target.value,
																	this.props.selectedAasdi.id,
																	index
																);
															}
														}, 600)
													)}
													onChange={(e, data) => {
														this.props.updateCreateTrip(data.value, index);
													}}
												/>
											</Grid.Column>
											{field.value.id ? (
												<Segment
													as={Grid}
													style={{marginTop: '2vh'}}
													textAlign="center"
													// width={8}
												>
													<Header.Content>
														<h3>Selected Contact</h3>
													</Header.Content>
													<Grid.Row>
														<Grid.Column width={6}>
															<Header.Content>
																<h5>Name</h5>
															</Header.Content>
															{field.value.fullName}
														</Grid.Column>
														<Grid.Column width={6}>
															<Header.Content>
																<h5>Title</h5>
															</Header.Content>
															{field.value.title ? field.value.title : 'No Title'}
														</Grid.Column>
													</Grid.Row>
													<Grid.Row>
														<Grid.Column width={6}>
															<Header.Content>
																<h5>Phone</h5>
															</Header.Content>
															{field.value.phone ? field.value.phone : 'No Phone'}
															{field.value.extension &&
															field.value.extension.replace(/[^0-9.]/g, '').length > 0
																? ` (${field.value.extension})`
																: null}
														</Grid.Column>
														{/* </Grid.Row>
												<Grid.Row> */}
														<Grid.Column width={6}>
															<Header.Content>
																<h5>Email</h5>
															</Header.Content>
															{field.value.email ? field.value.email : 'No Email'}
														</Grid.Column>
													</Grid.Row>
												</Segment>
											) : null}
										</Grid.Column>
									);
								} else if (field.time) {
									return (
										<Grid.Column width={4} textAlign="center">
											<Popup
												trigger={
													<div>
														<Header.Content>{field.name}</Header.Content>
														<Label
															style={{
																// width: '100%',
																backgroundColor: 'transparent',
																padding: '1vh 1vw 1vh 0'
															}}
															size="big"
														>
															{field.value === ''
																? 'Pick Date'
																: moment
																		.utc(field.value)
																		.local()
																		.format('ddd MM-DD-YYYY HH:mm')}
														</Label>
													</div>
												}
												on={['hover', 'click']}
												position="bottom center"
												flowing
												hoverable
											> 
												<MomentDatePicker
													inline
													className={'workOrderDatePicker'}
													showTimeSelect
													timeFormat="HH:mm"
													timeIntervals={15}
													dateFormat="LLL"
													timeCaption="time"
													selected={moment.utc().local()}
													onChange={(date) => {
														this.props.updateCreateTrip(
															date ? date.utc().format() : null,
															index
														);
													}}
												/>
											</Popup>
										</Grid.Column>
									);
								}
								return null;
							})}
						</Grid>
						{this.props.fields.map((field, index) => {
							if (field.textArea) {
								return (
									<Segment key={index}>
										<Label attached="top" color="blue">
											{field.name}
										</Label>
										<Segment basic>
											{field.saved ? null : (
												<Label attached="top right" color="red" size="small">
													Unsaved
												</Label>
											)}
											<div style={{height: 400}}>
												<FancyEditor
													value={field.name === 'AASDI SOW' ? this.state.sowValue : this.state.specialValue}
													onSave={() => {
														this.props.setCreateTripSaved(true, index);
														if (field.name === 'AASDI SOW') {
															this.props.updateCreateTrip(this.state.sowValue, index);
														} else {
															this.props.updateCreateTrip(this.state.specialValue, index);
														}
													}}
													onChange={(value) => {
														if (field.name === 'AASDI SOW') {
															this.setState(() => {
																return {
																	sowValue: value
																};
															});
														} else if (field.name === 'Special Instructions') {
															this.setState(() => {
																return {
																	specialValue: value
																};
															});
														}
														if (value === this.props.fields[index].value) {
															this.props.setCreateTripSaved(true, index);
														} else {
															this.props.setCreateTripSaved(false, index);
														}
													}}
												/>
											</div>
										</Segment>
									</Segment>
								);
							}
							return null;
						})}
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button
						negative
						icon="x"
						content="Cancel"
						disabled={Boolean(this.props.modalLoading)}
						onClick={() => {
							this.props.createTripNoName(false);
							this.props.toggleCreateTripModal(false);
						}}
					/>
					<Button
						positive
						icon="checkmark"
						content="Create Trip"
						disabled={Boolean(this.props.modalLoading)}
						onClick={async () => {
							if (
								this.props.fields[this.props.fields.findIndex((field) => field.name === 'Trip Name')]
									.value.length > 0
							) {
								this.props.createTripNoName(false);
								await this.props.createTrip(
									this.props.selectedAasdi.workAasdiId,
									this.props.fields[
										this.props.fields.findIndex((field) => field.name === 'Trip Name')
									].value,
									this.props.fields[this.props.fields.findIndex((field) => field.name === 'Contact')]
										.value.id,
									this.props.fields[
										this.props.fields.findIndex((field) => field.name === 'Requested Date')
									].value,
									this.props.fields[
										this.props.fields.findIndex((field) => field.name === 'Actual Date')
									].value,
									this.props.fields[
										this.props.fields.findIndex((field) => field.name === 'AASDI SOW')
									].value,
									this.props.fields[
										this.props.fields.findIndex((field) => field.name === 'Special Instructions')
									].value,
									this.props.selectedAasdi.id
								);
								await this.props.getWorkViewTrip(this.props.newTripId, 'createTrip');
								//Reset sow value after trip is created
								this.setState(() => {
									return {
										sowValue: 'sow',
										specialValue: ''
									}
								})
							} else {
								this.props.createTripNoName(true);
							}
						}}
					/>
				</Modal.Actions>
			</Modal>
		);
	}
}

CreateTripModal.propTypes = {
	clearContactOptions: PropTypes.func,
	open: PropTypes.bool,
	fields: PropTypes.array,
	updateCreateTrip: PropTypes.func,
	getContactsOptions: PropTypes.func,
	options: PropTypes.array,
	selectedAasdi: PropTypes.object,
	setCreateTripSaved: PropTypes.func,
	createTripNoName: PropTypes.func,
	toggleCreateTripModal: PropTypes.func,
	createTrip: PropTypes.func,
	getWorkViewTrip: PropTypes.func,
	newTripId: PropTypes.number,
	modalLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	let modal = state.work.workRecord.view.manageAASDI.createTrip.modal;
	return {
		open: modal.open,
		fields: modal.fields,
		options: modal.contactOptions,
		selectedAasdi:
			state.work.workRecord.view.manageAASDI.aasdis[
				state.work.workRecord.view.manageAASDI.aasdis.findIndex((aasdi) => aasdi.selected === true)
			],
		newTripId: state.work.workRecord.view.manageAASDI.selectedTripId,
		sections: state.work.workRecord.view.manageAASDI.infoSection,
		modalLoading: state.work.workRecord.view.manageAASDI.createTrip.modal.loading,
		sow: state.work.workRecord.view.record.scopeOfWork
	};
};

export default connect(mapStateToProps, actions)(CreateTripModal);
