// TODOS
// remove inline styling
import {Card, Form, Header, Icon, Popup, Input, Button} from 'semantic-ui-react';
import React from 'react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';
import {
	selectClientListSearchResultsLoading,
	selectErrorLoadingClientListSearchResults
} from '../services/TaskTemplates.selectors.js';
import {
	changeTemplateValues,
	updateTemplate,
	updateClientListSearchResults,
	deleteTemplate
} from '../services/TaskTemplates.actions.js';
import {selectClientListSearchResults} from '../services/TaskTemplates.selectors.js';
import {useParams} from 'react-router-dom';
import {debounceEventHandler, debounce} from '../../../lib/old/debounce.js';
import {Link} from 'react-router-dom';

const TemplateInformation = (props) => {
	const {id} = useParams();

	let headerContent;
	if (props.template.id) {
		headerContent = 'Edit Template Info';
	} else {
		headerContent = 'Add Template Info';
	}

	let clientName;
	if (props.template.clientName) {
		clientName = props.template.clientName;
	} else {
		clientName = 'This is currently a global template.';
	}

	return [
		<Card>
			<Card.Content key={'header-templateInfo'}>
				<Header as="h3" key={'header-templateInfo'}>
					<Icon name="edit" />
					<Header.Content>{headerContent}</Header.Content>
				</Header>
			</Card.Content>
			<Card.Content key={'form-templateInfo'}>
				<Form>
					<Form.Field>
						<label>Template Name</label>
						<DebounceInput
							value={props.template.name}
							element={Input}
							debounceTimeout={600}
							onChange={(e) => props.changeTemplateValues({name: e.target.value}, id)}
						/>
					</Form.Field>

					<Form.Field>
						<label>Description</label>
						<DebounceInput
							value={props.template.description}
							element={Input}
							debounceTimeout={600}
							onChange={(e) => props.changeTemplateValues({description: e.target.value}, id)}
						/>
					</Form.Field>

					<Form.Field>
						<label>
							Client Name{' '}
							<Popup
								trigger={<Icon name="question circle outline" />}
								content="Use the Client List search to assign this template to a specific customer."
							/>
						</label>
						<p>{clientName}</p>
					</Form.Field>

					<Form.Dropdown
						placeholder="Begin typing to search for clients"
						label="Client List"
						fluid
						search
						selection
						error={props.errorLoadingClientListSearchResults}
						loading={props.clientListSearchResultsLoading}
						onSearchChange={debounceEventHandler(
							debounce((e) => props.updateClientListSearchResults(e.target.value), 600)
						)}
						onChange={(e, {value}) => props.changeTemplateValues(value, id)}
						options={props.clientListSearchResults}
					/>
				</Form>
			</Card.Content>
			<Card.Content key={'PanelButtonGroup'} style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
				<Link to="/task-templates/list">
					<Button type="button" onClick={() => props.deleteTemplate(props.template.id)} color="red">
						Delete
					</Button>
				</Link>

				<Button type="button" onClick={() => props.updateTemplate(props.template)} color="green">
					Save
				</Button>
			</Card.Content>
		</Card>
	];
};

const mapStateToProps = (state) => ({
	clientListSearchResults: selectClientListSearchResults(state),
	clientListSearchResultsLoading: selectClientListSearchResultsLoading(state),
	errorLoadingClientListSearchResults: selectErrorLoadingClientListSearchResults(state)
});

const mapDispatchToProps = (dispatch) => ({
	changeTemplateValues: (updatedValues, id) => dispatch(changeTemplateValues(updatedValues, Number(id))),
	updateTemplate: (updatedTemplate) => dispatch(updateTemplate(updatedTemplate)),
	updateClientListSearchResults: (searchQuery) => dispatch(updateClientListSearchResults(searchQuery)),
	deleteTemplate: (templateId) => dispatch(deleteTemplate(templateId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateInformation);
