import React from 'react';
import {connect} from 'react-redux';
import {Segment, Label} from 'semantic-ui-react';

import ProjectCostingPJSummary from './components/projectCostingPJSummary.js';

let ProjectCostPJ = () => (
	<Segment basic style={{marginTop: '0', paddingTop: 0}}>
		<Segment style={{padding: 0}}>
			<Label attached="top" icon="search" color="blue" style={{position: 'relative', padding: 0}}>
				<div
					style={{
						display: 'inline-block',
						padding: '1.2em'
					}}
				>
					{'Project Costing'}
				</div>
			</Label>
			<div className="fixGridMargin">
				<ProjectCostingPJSummary />
			</div>
		</Segment>
	</Segment>
);

export default connect(null, null)(ProjectCostPJ);
