// TODOS
// Create enums for string constants

import snackbarReducer, {snackbarInitialState} from './reducers/snackbar.reducer.js';
import templateTasksReducer, {templateTasksInitialState} from './reducers/template-tasks.reducer.js';
import templatesReducer, {templatesInitialState} from './reducers/templates.reducer.js';

const initialState = {
	...snackbarInitialState,
	...templateTasksInitialState,
	...templatesInitialState
};

function TaskTemplateReducer(state = initialState, action) {
	let updatedState = snackbarReducer(state, action);
	updatedState = templateTasksReducer(updatedState, action);
	updatedState = templatesReducer(updatedState, action);

	return updatedState;
}

export default TaskTemplateReducer;
