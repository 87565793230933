import {validatePayload, validateGoogleDriveLink, hasName} from '../../../../../lib/old/validation.js';
import api from '../../../../../lib/api.ts';
import customFetch from '../../../../../lib/old/customFetch.js';

export const getClientGrid = () => {
	return async (dispatch) => {
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/client?grid=true`;
		const fetchData = await customFetch(endpoint);
		const jsonData = await fetchData.json();
		dispatch({
			type: 'WORK_FETCH_CLIENT_GRID',
			payload: validatePayload(jsonData)
		});
	};
};

export function toggleCreateClientModal(status) {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_CREATE_CLIENT_MODAL',
			payload: status
		});
	};
}

export const toggleActivateClientModal = () => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_ACTIVATE_CLIENT_MODAL'
		});
	};
};

export const toggleActivateClientErrorHidden = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_ACTIVATE_CLIENT_ERROR',
			payload: {
				hidden: value
			}
		});
	};
};

export const activateClientModalLoading = () => {
	return (dispatch) => {
		dispatch({
			type: 'ACTIVATE_CLIENT_MODAL_LOADING'
		});
	};
};

export const activateClient = (id, rowIndex) => async (dispatch) => {
	try {
		dispatch(activateClientModalLoading());
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/client/${id}`;
		const body = {statusId: 1};
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(body)
		});
		const response = await call.json();
		console.log(response);

		if (call.status === 200) {
			dispatch({
				type: 'UPDATE_CLIENT_STATUS',
				payload: {
					i: rowIndex,
					statusId: body.statusId
				}
			});
			dispatch(toggleActivateClientModal());
		} else {
			dispatch(toggleActivateClientErrorHidden(false));
		}
	} catch (err) {
		console.error(err);
	} finally {
		dispatch(activateClientModalLoading());
	}
};

export const toggleDeactivateClientModal = () => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_DEACTIVATE_CLIENT_MODAL'
		});
	};
};

export const deactivateClientModalLoading = () => {
	return (dispatch) => {
		dispatch({
			type: 'DEACTIVATE_CLIENT_MODAL_LOADING'
		});
	};
};

export const toggleDeactivateClientErrorHidden = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_DEACTIVATE_CLIENT_ERROR',
			payload: {
				hidden: value
			}
		});
	};
};

export const deactivateClient = (id, rowIndex) => async (dispatch) => {
	try {
		dispatch(deactivateClientModalLoading());
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/client/${id}`;
		const body = {statusId: 3};
		const call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify(body)
		});
		const response = await call.json();
		console.log(response);

		if (call.status === 200) {
			dispatch({
				type: 'UPDATE_CLIENT_STATUS',
				payload: {
					i: rowIndex,
					statusId: body.statusId
				}
			});
			dispatch(toggleDeactivateClientModal());
		} else {
			dispatch(toggleDeactivateClientErrorHidden(false));
		}
	} catch (err) {
		console.error(err);
	} finally {
		dispatch(deactivateClientModalLoading());
	}
};

export function toggleCustomerCreationModal(status) {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_CUSTOMER_CREATION_MODAL',
			payload: status
		});
		dispatch({
			type: 'CUSTOMER_CREATION_MODAL_RESET'
		});
	};
}

export function resetClientUpdate() {
	return (dispatch) => {
		dispatch({
			type: 'RESET_CLIENT_UPDATE'
		});
	};
}

export function toggleProspectCreationModal(status) {
	if (status === false) {
		return (dispatch) => {
			dispatch({
				type: 'TOGGLE_PROSPECT_CREATION_MODAL_RESET',
				payload: status
			});
		};
	} else {
		return (dispatch) => {
			dispatch({
				type: 'TOGGLE_PROSPECT_CREATION_MODAL',
				payload: status
			});
		};
	}
}

export function addNewProspect(prospect) {
	return (dispatch) => {
		dispatch({
			type: 'NEW_PROSPECT_ADDED',
			payload: validatePayload(prospect)
		});
	};
}

export function updateCustomer(customer) {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_CUSTOMER',
			payload: validatePayload(customer)
		});
	};
}

export const getAccountExecs = (value, type) => {
	return (dispatch) => {
		const actionLoadType =
			type === 'AccExec' ? 'UPDATE_DROP_DOWN_LOADER_ACC_EXECS' : 'UPDATE_DROP_DOWN_LOADER_ACC_MAN';
		const actionOptType =
			type === 'AccExec' ? 'UPDATE_DROP_DOWN_OPTIONS_ACC_EXECS' : 'UPDATE_DROP_DOWN_OPTIONS_ACC_MAN';

		// Dispatch Loader
		dispatch({
			type: actionLoadType,
			payload: {
				value: true
			}
		});

		let endpoint;
		if (type === 'AccExec') {
			endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}&requiredField=spectrumSalespersonCode`;
		} else {
			endpoint = `${process.env.REACT_APP_API_URL}/legacy/employee?searchName=${value}`;
		}

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((accountExec) => ({
					key: accountExec.id,
					text: accountExec.fullName,
					value: accountExec.id
				}));
				console.log(JSON.stringify(options));
				dispatch({
					type: actionOptType,
					payload: {
						json: options
					}
				});
				dispatch({
					type: actionLoadType,
					payload: {
						value: false
					}
				});
			})
			.catch((err) => {
				console.log(`ERROR == ${err}`);
			});
	};
};

export const getContacts = (value, clientId) => {
	console.log(clientId);
	// clientId = 1;
	return (dispatch) => {
		// Dispatch Loader
		dispatch({
			type: 'UPDATE_DROP_DOWN_LOADER_CONTACTS',
			payload: {
				value: true
			}
		});

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts?name=${value}&clientId=${clientId}`;

		customFetch(endpoint)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				let options = json.map((accountExec) => ({
					key: accountExec.id,
					text: accountExec.fullName,
					value: accountExec.id
				}));
				console.log(JSON.stringify(options));
				dispatch({
					type: 'UPDATE_DROP_DOWN_OPTIONS_CONTACTS',
					payload: {
						json: options
					}
				});
				dispatch({
					type: 'UPDATE_DROP_DOWN_LOADER_CONTACTS',
					payload: {
						value: false
					}
				});
			})
			.catch((err) => {
				console.log(`ERROR == ${err}`);
			});
	};
};

export const setActiveStep = (stepType, currentStep, targetStep) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_PROSPECT_MODAL_SET_ACTIVE_STEP',
			payload: {
				stepType,
				currentStep,
				targetStep
			}
		});
	};
};

export const removeCreateClientDocumentGrid = (rowIndex, sectionIndex, modalName) => (dispatch, getState) => {
	const fieldData = getState().work.clientGrid.stepsCustomer.modalSteps;
	const documents = fieldData.find(hasName('Documents')).documents;
	if (modalName === 'stepsCustomer') {
		dispatch({
			type: 'CREATE_CUSTOMER_SET_DOC_TO_DELETE',
			payload: {
				document: documents[rowIndex],
				sectionIndex,
				modalName
			}
		});
	}
	dispatch({
		type: 'CREATE_CLIENT_REMOVE_DOC_GRID',
		payload: {
			sectionIndex,
			rowIndex,
			modalName
		}
	});
};

export const addDocument = (fileName, googleDrive, docType, comments, sectionIndex, stepType, clientInfos) => {
	return async (dispatch, getState) => {
		console.log('adddocuments', fileName, googleDrive, docType, comments, sectionIndex);
		const state = getState().work.clientGrid;
		const isValidGoogleDriveLink = validateGoogleDriveLink(googleDrive.value);
		if (!fileName.value || !isValidGoogleDriveLink || !docType.value) {
			const emptyFields = state[stepType].modalSteps[sectionIndex].modalFields
				.map((field, fieldIndex) => ({
					field: field,
					fieldIndex: fieldIndex
				}))
				.filter(
					(field) =>
						(field.field.checkForm && !field.field.value) ||
						(field.field.name === 'Google Drive Link' && !isValidGoogleDriveLink)
				);
			emptyFields.forEach((field) => {
				console.log('erro:', field);
				dispatch(updateCreateModal(stepType, sectionIndex, field.fieldIndex, field.field.value, true));
			});
		} else {
			dispatch({
				type: 'CREATE_CLIENT_MODAL_ADD_DOCUMENT',
				payload: {
					sectionIndex,
					stepType,
					object: {
						fileName: fileName.value,
						googleDriveLink: googleDrive.value,
						docType: docType.value,
						comments: comments.value
					}
				}
			});
		}
	};
};

export const updateCreateModal = (modal, step, column, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_CLIENT_MODAL_UPDATE',
			payload: {
				modal,
				step,
				column,
				value,
				error
			}
		});
	};
};

export const createNewProspect = () => async (dispatch, getState) => {
	console.log(`hit inside createNewProspect`);
	let fieldData = getState().work.clientGrid.stepsClient.modalSteps;
	// START VALIDATION
	let emptyArray = [];
	fieldData.forEach((step, stepIndex) => {
		step.modalFields.forEach((field, fieldIndex) => {
			if (field.required && !field.checkForm) {
				if (!field.value || field.value < 1 || field.value.length < 1) {
					emptyArray.push({step: stepIndex, field: fieldIndex});
				}
			}
		});
	});

	if (emptyArray.length > 0) {
		emptyArray.forEach((object) => {
			dispatch({
				type: 'CREATE_CLIENT_MODAL_SHOW_REQUIRED',
				payload: {
					step: object.step,
					field: object.field
				}
			});
		});
		return;
	}
	// END VALIDATION

	let clientId = null;
	let error = false;

	// start loader
	dispatch({
		type: 'CREATE_CLIENT_MODAL_LOADING',
		payload: {
			value: true
		}
	});

	//Populate prospect body;
	const prospectBody = {};
	fieldData[0].modalFields.forEach((field) => (prospectBody[field.key] = field.value));

	await api('legacy/client', 'post', prospectBody)
		.then((response) => {
			response.clientStatusName = response.clientStatusName ? response.clientStatusName : 'Prospect';
			dispatch(addNewProspect(response));
			clientId = response.id;
		})
		.catch((error) => {
			dispatch({
				type: 'CREATE_CLIENT_MODAL_ERROR',
				payload: {
					value: true,
					message: 'There was a problem creating this Prospect'
				}
			});
			dispatch({
				type: 'CREATE_CLIENT_MODAL_LOADING',
				payload: {
					value: false
				}
			});
			error = true;
			console.log(`ERROR == ${JSON.stringify(error)}`);
		});
	//POST DOCUMENTS IF PROSPECT CREATED
	if (clientId) {
		let payloadObj = fieldData.find(hasName('Documents')).documents;
		if (payloadObj.length > 0) {
			payloadObj.forEach((doc) => (doc.clientId = clientId));
			const endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

			try {
				const call = await customFetch(endpoint, {
					method: 'POST',
					body: JSON.stringify(payloadObj)
				});
				const response = await call.json();

				if (call.status !== 200) {
					throw response;
				}
			} catch (err) {
				dispatch({
					type: 'CREATE_CLIENT_MODAL_ERROR',
					payload: {
						value: true,
						message: 'There was a problem when creating the documents for this Prospect'
					}
				});
				dispatch({
					type: 'CREATE_CLIENT_MODAL_LOADING',
					payload: {
						value: false
					}
				});
				error = true;
				console.log(err);
			}
		}
	}

	dispatch({
		type: 'CREATE_CLIENT_MODAL_LOADING',
		payload: {
			value: false
		}
	});

	if (error === false) {
		dispatch(toggleProspectCreationModal(false));
	}
};

export function closeError() {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_CLIENT_MODAL_ERROR',
			payload: {
				value: false,
				message: 'There was a problem creating this Prospect'
			}
		});
	};
}

export const getClientDocuments = (sectionIndex, stepType, clientInfos) => async (dispatch) => {
	dispatch({
		type: 'CREATE_CUSTOMER_MODAL_LOADING',
		payload: {
			value: true
		}
	});

	dispatch({
		type: 'CREATE_CLIENT_MODAL_SET_DOCUMENTS',
		payload: {
			sectionIndex,
			stepType,
			object: []
		}
	});

	dispatch({
		type: 'CREATE_CLIENT_MODAL_SET_DOCUMENTS_TO_DELETE',
		payload: {
			sectionIndex,
			stepType,
			object: []
		}
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents?clientId=${clientInfos.id}`;
	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'CREATE_CLIENT_MODAL_SET_DOCUMENTS',
			payload: {
				sectionIndex,
				stepType,
				object: response
			}
		});
	} catch (err) {
		dispatch({
			type: 'CREATE_CUSTOMER_MODAL_ERROR',
			payload: {
				value: true,
				message: 'There was a problem to get the prospect documents'
			}
		});
		console.log(err);
	}

	dispatch({
		type: 'CREATE_CUSTOMER_MODAL_LOADING',
		payload: {
			value: false
		}
	});

	dispatch(toggleHasFetchedDocuments(true));
};

export const createCustomerDocuments = (clientId) => async (dispatch, getState) => {
	let fieldData = getState().work.clientGrid.stepsCustomer.modalSteps;
	const allDocs = fieldData.find(hasName('Documents')).documents;
	let allDocsToDelete = fieldData.find(hasName('Documents')).documentsToDelete;

	const payloadObj = allDocs.filter((doc) => doc.id === null || doc.id === undefined);
	if (payloadObj) {
		payloadObj.forEach((doc) => (doc.clientId = clientId));
	}

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents`;

	console.log(allDocs);
	console.log(payloadObj);
	// if there are new documents to fetch
	if (payloadObj.length > 0) {
		payloadObj.forEach((doc) => (doc.clientId = clientId));
		try {
			const call = await customFetch(endpoint, {
				method: 'POST',
				body: JSON.stringify(payloadObj)
			});
			const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}
		} catch (err) {
			dispatch({
				type: 'CREATE_CUSTOMER_MODAL_ERROR',
				payload: {
					value: true,
					message: 'There was a problem when creating the documents for this Prospect'
				}
			});
			dispatch({
				type: 'CREATE_CUSTOMER_MODAL_LOADING',
				payload: {
					value: false
				}
			});
			console.log(err);
		}

		//Delete selected documents for delete.
		if (allDocsToDelete.length > 0) {
			const payloadObjToDelete = allDocsToDelete.filter((doc) => doc.id);
			try {
				payloadObjToDelete.forEach(async (doc) => {
					const call = await customFetch(endpoint, {
						method: 'DELETE',
						body: JSON.stringify({
							documentId: doc.id,
							clientId: doc.clientId
						})
					});

					if (call.status !== 200) {
						const response = await call.json();
						throw response;
					}
				});
			} catch (err) {
				// Hide Loader
				dispatch({
					type: 'CREATE_CUSTOMER_MODAL_LOADING',
					payload: false
				});
				alert('Something went wrong');
				console.log(err);
			}
		}

		dispatch({
			type: 'CREATE_CUSTOMER_MODAL_LOADING',
			payload: {
				value: false
			}
		});

		dispatch(closeCustomerCreationModal());
	} else {
		dispatch(closeCustomerCreationModal());
	}
};

export const closeCustomerCreationModal = () => (dispatch) => {
	dispatch(setIsSubmitting(false));
	dispatch(toggleCustomerCreationModal(false));
	dispatch(setActiveStep('stepsCustomer', 1, 0));
};

export const toggleHasFetchedDocuments = (value) => (dispatch) => {
	dispatch({
		type: 'SET_CUSTOMER_HAS_FETCHED_DOCUMENTS',
		payload: {
			value
		}
	});
};

export const setIsSubmitting = (value) => (dispatch) => {
	dispatch({
		type: 'SET_CUSTOMER_IS_SUBMITTING',
		payload: {
			value
		}
	});
};
