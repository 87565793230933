import React from 'react';
import {Route} from 'react-router-dom';
import {Segment} from 'semantic-ui-react';
import Pipeline from './scenes/pipeline/Pipeline.js';
import Leads from './scenes/leads/Leads.js';
import Nav from './components/nav/nav.js';
import ClientGridView from '../../../work/scenes/client/clientGridView.js';
import Opportunity from './scenes/opportunity/Opportunity.js';
import MonthlyCommits from '../../../../components/monthlyCommits/MonthlyCommits.js';

export default function Dashboard() {
	return (
		<div>
			<Nav />,
			<Segment
				style={{
					left: '15rem',
					right: '0',
					overflowY: 'auto',
					height: '94.5vh',
					position: 'fixed',
					marginTop: '5vh'
				}}
				basic
			>
				<Route path={`/crm/dashboard/pipeline`} component={Pipeline} />
				<Route path={`/crm/dashboard/leads`} component={Leads} />
				<Route path={`/crm/dashboard/clients`} component={ClientGridView} />
				<Route path={`/crm/dashboard/opportunity/:id`} component={Opportunity} />
				<Route path={`/crm/dashboard/commits`} component={MonthlyCommits} />
			</Segment>
		</div>
	);
}
