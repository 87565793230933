import {Label, Popup} from 'semantic-ui-react';
import React from 'react';
import {v4 as uuidv4} from 'uuid';

const ParagraphLabels = () => (
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={<Label key={uuidv4()} color={`blue`} icon={`paragraph`} content={` Paragraph`} />}
		content="A paragraph task."
		on="hover"
	/>
);
export default ParagraphLabels;
