import React from 'react';
import {connect} from 'react-redux';
import {Grid, Label, Dropdown} from 'semantic-ui-react';
import {isNil} from 'lodash';

import {debounce, debounceEventHandler} from '../../../../../../../../../../lib/old/debounce.js';
import {FancyEditor} from '../../../../../../../../../../components/inputs/FancyEditor.jsx';

import * as actions from '../../../../services/workRecordActions.js';

class BillingSOWContact extends React.Component {
	render() {
		return this.props.currentSelectedInvoiceIndex !== null && this.props.invoiceRowData.length ? (
			<Grid className="fixGridMargin">
				<Grid.Column
					width={11}
					style={
						this.props.invoiceRowData[this.props.currentSelectedInvoiceIndex].status !==
						'Awaiting Submission'
							? {
									paddingTop: 0,
									paddingBottom: 0,
									opacity: 0.5
							  }
							: {paddingTop: 0, paddingBottom: 0}
					}
				>
					<div style={{height: 300}}>
						<FancyEditor
							key={this.props.currentSelectedInvoiceIndex}
							onChange={(value) =>
								this.props.billingInvoiceGridUpdate(
									this.props.currentSelectedInvoiceIndex,
									'billingSow',
									value
								)
							}
							value={
								isNil(this.props.currentSelectedInvoiceIndex)
									? ''
									: this.props.invoiceRowData[this.props.currentSelectedInvoiceIndex].billingSow
							}
							disabled={
								this.props.invoiceRowData[this.props.currentSelectedInvoiceIndex].status !==
								'Awaiting Submission'
							}
						/>
					</div>
				</Grid.Column>
				<Grid.Column width={5}>
					<div
						style={{
							paddingTop: '0',
							flexGrow: 1,
							margin: '0 1rem'
						}}
					>
						<Label
							style={{
								backgroundColor: 'transparent',
								fontSize: '1rem',
								color: 'rgba(0,0,0,.8)',
								paddingLeft: '0'
							}}
						>
							Billing Contact
						</Label>
						<Dropdown
							disabled={
								this.props.invoiceRowData[this.props.currentSelectedInvoiceIndex].status !==
								'Awaiting Submission'
							}
							className="createWorkOrderClientDropdown"
							fluid
							search
							selection
							options={this.props.currentBillingContactOptions.options}
							onChange={(e, {value}) => {
								// Fix update firing when same billing contact id seleted
								if (
									this.props.invoiceRowData[this.props.currentSelectedInvoiceIndex]
										.billingContactId === value
								) {
									return;
								}
								this.props.billingInvoiceGridUpdate(
									this.props.currentSelectedInvoiceIndex,
									'billingContactId',
									value
								);
							}}
							onSearchChange={debounceEventHandler(
								debounce((e) => {
									if (e.target.value) {
										this.props.billingContactUpdateDropdown(
											this.props.clientId,
											e.target.value,
											this.props.currentSelectedInvoiceIndex
										);
									}
								}, 600)
							)}
							value={this.props.invoiceRowData[this.props.currentSelectedInvoiceIndex].billingContactId}
							loading={this.props.currentBillingContactOptions.loading}
						/>
					</div>
				</Grid.Column>
			</Grid>
		) : (
			<div style={{padding: '3rem'}}>{'Please select a bill to see billing SOW & Contact.'}</div>
		);
		// </div>
	}
}

const mapStateToProps = (state) => {
	return {
		clientId: state.work.workRecord.view.record.clientId,
		currentSelectedInvoiceIndex: state.work.workRecord.view.BillingCenter.currentSelectedInvoiceIndex,
		invoiceRowData: state.work.workRecord.view.BillingCenter.invoiceRowData,
		currentBillingContactOptions: state.work.workRecord.view.BillingCenter.currentBillingContactOptions
	};
};

export default connect(mapStateToProps, actions)(BillingSOWContact);
