// Modules
import React from 'react';
import {Button, Modal, Icon, Header, Grid, Form, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {toggleCreateLocationModal} from './services/globalActions.js';
import countryOptions from '../../constants/countries.js';
import stateOptions from '../../constants/states.js';

class CreateLocationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			locationName: '',
			storeNumber: '',
			address: '',
			address2: '',
			countryList: countryOptions,
			countryId: '',
			stateList: stateOptions,
			state: '',
			city: '',
			zip: '',
			unionLaborSiteCheck: false,
			contactList: [],
			siteContactId: '',
			billingContactList: [],
			billingContactId: '',
			inputErrors: {
				locationNameInputError: false,
				storeNumberInputError: false,
				addressInputError: false,
				cityInputError: false,
				countryInputError: false,
				zipInputError: false
			},
			isCreatingLocation: false
		};

		this.onChange = this.onChange.bind(this);
		this.submitLocation = this.submitLocation.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {
		this.setState({
			contactList: this.props.contacts.map((contact) => {
				return {value: contact.id, text: contact.fullName};
			})
		});
		this.setState({
			billingContactList: this.props.contacts.map((contact) => {
				return {value: contact.id, text: contact.fullName};
			})
		});
	}

	submitLocation() {
		const {inputErrors, locationName, storeNumber, address, city, countryId, zip, state} = this.state;
		inputErrors.locationNameInputError = !locationName;
		inputErrors.storeNumberInputError = !storeNumber;
		inputErrors.addressInputError = !address;
		inputErrors.cityInputError = !city;
		inputErrors.countryInputError = !countryId;
		inputErrors.zipInputError = !zip;
		inputErrors.stateInputError = !state;
		this.setState({inputErrors});
		if (
			inputErrors.locationNameInputError ||
			inputErrors.storeNumberInputError ||
			inputErrors.addressInputError ||
			inputErrors.cityInputError ||
			inputErrors.countryInputError ||
			inputErrors.zipInputError ||
			inputErrors.stateInputError
		) {
			return;
		}
		this.setState({isCreatingLocation: true});
		this.props.submitLocation(this.state);
		setTimeout(() => {
			this.closeModal();
		}, 2000);
	}

	onChange(e) {
		const {inputErrors} = this.state;
		inputErrors[`${e.target.name}InputError`] = false;
		let value = e.target.value;
		if (e.target.name === 'state') {
			value = e.target.value.toUpperCase();
		}
		this.setState({inputErrors, [e.target.name]: value});
	}

	closeModal() {
		this.setState({
			locationName: '',
			locationId: '',
			address: '',
			address2: '',
			countryId: '',
			state: '',
			city: '',
			zip: '',
			unionLaborSiteCheck: false,
			siteContactId: '',
			billingContactId: '',
			inputErrors: {
				locationNameInputError: false,
				locationIdInputError: false,
				addressInputError: false,
				cityInputError: false,
				countryInputError: false,
				zipInputError: false,
				stateInputError: false
			},
			isCreatingLocation: false
		});
		this.props.toggleCreateLocationModal(false);
	}

	render() {
		const {isCreatingLocation, countryList, stateList, contactList, billingContactList} = this.state;
		const {
			locationNameInputError,
			storeNumberInputError,
			addressInputError,
			cityInputError,
			countryInputError,
			zipInputError,
			stateInputError
		} = this.state.inputErrors;
		const EUAOption = countryOptions.filter((country) => country.text === 'United States');
		return (
			<Modal size="large" open={this.props.isOpen}>
				<Header>
					<Grid className="templateDraftSection">
						<Grid.Row>
							<Grid.Column width={16} style={{margin: 'auto 0'}}>
								<Icon name="plus" color="green" />
								<div className="content">Add New Location</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Header>
				<Modal.Content>
					<Form>
						<Grid>
							<Grid.Row>
								<Grid.Column width={4}>
									<Form.Field required>
										<label>Name</label>
										<Form.Input
											name={'locationName'}
											style={{width: '100%'}}
											error={locationNameInputError}
											onChange={this.onChange}
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={4}>
									<Form.Field required>
										<label>Store Number</label>
										<Form.Input
											name={'storeNumber'}
											style={{width: '100%'}}
											error={storeNumberInputError}
											onChange={this.onChange}
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={4}>
									<Form.Field required>
										<label>Address</label>
										<Form.Input
											name={'address'}
											style={{width: '100%'}}
											error={addressInputError}
											onChange={this.onChange}
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={4}>
									<Form.Field>
										<label>Address 2</label>
										<Form.Input
											name={'address2'}
											style={{width: '100%'}}
											onChange={this.onChange}
										/>
									</Form.Field>
								</Grid.Column>
							</Grid.Row>

							<Grid.Row>
								<Grid.Column width={4}>
									<Form.Field required>
										<label>Country</label>
										<Dropdown
											style={{width: '100%'}}
											search
											selection
											error={countryInputError}
											options={countryList}
											defaultValue={'218'}
											onChange={(event, {value}) => {
												this.setState({
													countryId: value,
													state: ''
												});
												const {inputErrors} = this.state;
												inputErrors[`countryInputError`] = false;
											}}
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={4}>
									<Form.Field required>
										<label>State</label>
										{this.state.countryId === EUAOption[0].value ? (
											<Dropdown
												style={{width: '100%'}}
												search
												selection
												error={stateInputError}
												options={stateList}
												defaultValue={'1'}
												onChange={(event, {value}) => {
													this.setState({
														state: value
													});
													const {inputErrors} = this.state;
													inputErrors[`stateInputError`] = false;
												}}
											/>
										) : (
											<Form.Input
												name={'state'}
												style={{width: '100%'}}
												maxLength={2}
												value={this.state.state}
												error={stateInputError}
												onChange={this.onChange}
											/>
										)}
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={4}>
									<Form.Field required>
										<label>City</label>
										<Form.Input
											name={'city'}
											style={{width: '100%'}}
											error={cityInputError}
											onChange={this.onChange}
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={4}>
									<Form.Field required>
										<label>Zip Code</label>
										<Form.Input
											name={'zip'}
											style={{width: '100%'}}
											error={zipInputError}
											onChange={this.onChange}
										/>
									</Form.Field>
								</Grid.Column>
							</Grid.Row>

							<Grid.Row>
								<Grid.Column width={4}>
									<Form.Field>
										<label>Union Labor Site</label>
										<Dropdown
											style={{width: '100%'}}
											search
											selection
											options={[
												{value: 'true', text: 'Yes'},
												{value: 'false', text: 'No'}
											]}
											defaultValue={'false'}
											onChange={(event, {value}) => {
												this.setState({
													unionLaborSiteCheck: value
												});
											}}
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={4}>
									<Form.Field>
										<label>Contact</label>
										<Dropdown
											style={{width: '100%'}}
											selection
											options={contactList}
											onChange={(event, {value}) => {
												this.setState({
													siteContactId: value
												});
											}}
										/>
									</Form.Field>
								</Grid.Column>

								<Grid.Column width={4}>
									<Form.Field>
										<label>Default Billing Contact</label>
										<Dropdown
											style={{width: '100%'}}
											selection
											options={billingContactList}
											onChange={(event, {value}) => {
												this.setState({
													billingContactId: value
												});
											}}
										/>
									</Form.Field>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							color="red"
							disabled={isCreatingLocation}
							onClick={() => {
								this.closeModal(false);
							}}
						>
							<Icon name="remove" /> Cancel
						</Button>
						<Button color="green" disabled={isCreatingLocation} onClick={this.submitLocation}>
							{isCreatingLocation ? <Icon name="spinner" loading={true} /> : <Icon name="checkmark" />}
							{isCreatingLocation ? 'Creating' : 'Create'}
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}

CreateLocationModal.propTypes = {
	isOpen: PropTypes.bool,
	id: PropTypes.string,
	toggleCreateLocationModal: PropTypes.func,
	contacts: PropTypes.array,
	submitLocation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	isOpen: state.global.global.showCreateLocationModal
});

const mapDispatchToProps = (dispatch) => ({
	toggleCreateLocationModal: (status) => dispatch(toggleCreateLocationModal(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocationModal);
