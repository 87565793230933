// Modules
import React from 'react';
import {List, Checkbox, Segment, Input, Dropdown} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';

// Service
import {updateTechnologies, getAccountExecs} from '../services/technologiesActions.js';

import {debounce, debounceEventHandler} from '../../../../../../../../../../../lib/old/debounce.js';

let Item = ({
	id,
	oppId,
	toggled,
	name,
	value,
	estimator,
	estimation,
	index,
	updateTechnologies,
	estimatorOptions,
	loadingEstimator,
	getAccountExecs,
	disabled
}) => {
	return (
		<List.Item key={index}>
			<List.Content floated="right">
				<Checkbox
					toggle
					checked={toggled}
					onClick={(event, {checked}) => {
						if (!disabled) {
							updateTechnologies(oppId, {
								column: 'toggled',
								value: checked,
								id
							});
						}
					}}
					disabled={disabled}
				/>
			</List.Content>
			<List.Content>
				<List.Header style={disabled ? {color: 'grey'} : {}}>{name}</List.Header>
				<List.Description style={{fontSize: 'smaller', marginTop: '5px', color: 'grey'}}>
					{value}
				</List.Description>
			</List.Content>
			{toggled && (
				<Segment basic>
					<List.Content key="estimator" floated="right" style={{width: '50%'}}>
						<List.Header style={disabled ? {color: 'grey'} : {}}>Estimator</List.Header>
						<List.Description style={{fontSize: 'smaller', marginTop: '5px'}}>
							<div className={`ui fluid input hiddenInput ${disabled ? 'disabled' : ''}`}>
								<Dropdown
									placeholder={estimator ? null : 'Search for an Estimator'}
									search
									fluid
									loading={loadingEstimator}
									options={estimatorOptions}
									text={estimator}
									disabled={disabled}
									onChange={(e, {value}) => {
										updateTechnologies(oppId, {
											column: 'estimator',
											value,
											id
										});
									}}
									onSearchChange={debounceEventHandler(
										debounce((e) => {
											if (e.target.value) {
												getAccountExecs(e.target.value, id);
											}
										}, 600)
									)}
									style={{
										width: '100%'
									}}
								/>
							</div>
						</List.Description>
					</List.Content>
					<List.Content key="estimation">
						<List.Header style={disabled ? {color: 'grey'} : {}}>Estimation</List.Header>
						<List.Description style={{fontSize: 'smaller', marginTop: '5px'}}>
							<div className={`ui fluid input hiddenInput ${disabled ? 'disabled' : ''}`}>
								<div style={{width: 'auto', paddingRight: '.4em', paddingTop: '.2em'}}>$</div>
								<DebounceInput
									element={Input}
									onChange={(e) => {
										updateTechnologies(oppId, {
											column: 'estimation',
											value: e.target.value,
											id
										});
									}}
									debounceTimeout={800}
									value={estimation}
									disabled={disabled}
									style={{width: '50%'}}
								/>
							</div>
						</List.Description>
					</List.Content>
				</Segment>
			)}
		</List.Item>
	);
};

Item.propTypes = {
	id: PropTypes.string.isRequired,
	oppId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	estimation: PropTypes.string,
	estimator: PropTypes.string,
	toggled: PropTypes.bool.isRequired,
	index: PropTypes.string.isRequired,
	updateTechnologies: PropTypes.func.isRequired,
	loadingEstimator: PropTypes.bool.isRequired,
	estimatorOptions: PropTypes.array,
	getAccountExecs: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	validation: state.crm.opportunity.data.validation,
	estimatorOptions: state.crm.opportunity.technologies.estimatorOptions
});

const mapDispatchToProps = (dispatch) => ({
	updateTechnologies: (id, body) => dispatch(updateTechnologies(id, body)),
	getAccountExecs: (value, id) => dispatch(getAccountExecs(value, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Item);
