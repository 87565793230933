import update from 'immutability-helper';
import countryOptions from '../../../../../constants/countries.js';
import stateOptions from '../../../../../constants/states.js';

const docTypeOptions = [
	{
		key: 1,
		text: 'COI/WC',
		value: 1
	},
	{
		key: 2,
		text: 'Signed Agreement',
		value: 2
	},
	{
		key: 3,
		text: 'W9',
		value: 3
	}
];

let createAasdiModalSteps = [
	{
		name: 'AASDI',
		active: true,
		description: 'client description',
		icon: {name: 'user', color: 'black'},
		modalFields: [
			{
				name: 'Company Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Website',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Address',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Address 2',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false
			},
			{
				name: 'City',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Zip Code',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'State',
				value: null,
				dropdown: true,
				input: false,
				placeholder: 'Choose state',
				options: stateOptions,
				error: false,
				upward: true,
				disabled: false,
				required: true
			},
			{
				name: 'Country',
				value: 218,
				placeholder: 'Choose country',
				options: countryOptions,
				dropdown: true,
				error: false,
				disabled: false,
				upward: true,
				required: true
			}
		]
	},
	{
		name: 'Contact',
		description: 'contact description',
		active: false,
		icon: {
			name: 'phone',
			color: 'black'
		},
		modalFields: [
			{
				name: 'First Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Last Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Title',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false
			},
			{
				name: 'Email',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false
			},
			{
				name: 'Phone',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
				placeholder: '555-555-5555'
			},
			{
				name: 'Extension',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false
			}
		]
	},
	{
		name: 'Documents',
		active: false,
		description: 'AASDI Documents',
		icon: {name: 'file alternate', color: 'black'},
		documents: [],
		modalFields: [
			{
				name: 'File Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true,
				checkForm: true,
				columnWidth: 5
			},
			{
				name: 'Google Drive Link',
				value: '',
				input: true,
				error: false,
				disabled: false,
				checkForm: true,
				required: true,
				columnWidth: 5
			},
			{
				name: 'Document Type',
				value: false,
				input: false,
				dropdown: true,
				error: false,
				checkForm: true,
				disabled: false,
				required: true,
				columnWidth: 5,
				options: docTypeOptions
			},
			{
				name: 'Comments',
				value: '',
				input: false,
				textArea: true,
				error: false,
				disabled: false,
				required: false,
				columnWidth: 15
			},
			{
				name: 'ACH Document has been sent to nsapkalova@asd-usa.com.',
				value: false,
				input: false,
				textArea: false,
				checkbox: true,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 15
			}
		]
	}
];

export default (
	state = {
		loading: false,
		rowData: null,
		activateAasdiErrorHidden: true,
		activateAasdiErrorMessage:
			'There was a problem activating the AASDI. Please try again. If the problem persists, please contact the dev team.',
		isActivateModalLoading: false,
		isActivateModalOpen: false,
		deactivateAasdiErrorHidden: true,
		deactivateAasdiErrorMessage:
			'There was a problem deactivating the AASDI. Please try again. If the problem persists, please contact the dev team.',
		isDeactivateModalLoading: false,
		isDeactivateModalOpen: false,
		showCreateAasdiModal: false,
		certModal: {
			open: false,
			loading: false,
			selectedCert: []
		},
		stepsAasdi: {
			loading: false,
			error: false,
			errorMessage: 'There was a problem creating this AASDI',
			modalTitle: 'Create Aasdi',
			modalSteps: createAasdiModalSteps
		},
		searchByZip: {
			name: 'Modal',
			value: '',
			input: true,
			error: false,
			loading: false,
			columnWidth: 4,
			radiusOptions: [
				{key: '10', text: 10, value: 10},
				{key: '25', text: 25, value: 25},
				{key: '50', text: 50, value: 50},
				{key: '75', text: 75, value: 75},
				{key: '100', text: 100, value: 100},
				{key: '150', text: 150, value: 150},
				{key: '200', text: 200, value: 200},
				{key: '>200', text: '>200', value: '>200'}
			],
			selectedRadius: 25,
			selectedZip: '',
			zipError: false,
			selectedNameSearch: '',
			dataList: [],
			listName: 'ModalList',
			AASDISelected: false,
			selectedAASDI: {},
			searchAASDIModal: true,
			toggled: false
		},
		searchByCertification: {
			certificationOptions: [
				{
					key: 'certification1',
					text: 'Certification 1',
					value: 'certification1'
				},
				{
					key: 'certification2',
					text: 'Certification 2',
					value: 'certification2'
				},
				{
					key: 'certification3',
					text: 'Certification 3',
					value: 'certification3'
				},
				{
					key: 'certification4',
					text: 'Certification 4',
					value: 'certification4'
				}
			]
		}
	},
	action
) => {
	switch (action.type) {
		case 'WORK_FETCH_AASDI_GRID':
			state = update(state, {
				rowData: {
					$set: action.payload
				},
				loading: {
					$set: true
				}
			});
			break;
		case 'TOGGLE_CREATE_AASDI_MODAL':
			state = update(state, {
				showCreateAasdiModal: {
					$set: action.payload
				}
			});
			break;
		case 'CREATE_AASDI_MODAL_SET_ACTIVE_STEP':
			state = update(state, {
				stepsAasdi: {
					modalSteps: {
						[action.payload.currentStep]: {
							active: {
								$set: false
							}
						},
						[action.payload.targetStep]: {
							active: {
								$set: true
							}
						}
					}
				}
			});
			break;

		case 'CREATE_AASDI_MODAL_UPDATE':
			state = update(state, {
				[action.payload.modal]: {
					modalSteps: {
						[action.payload.step]: {
							icon: {
								color: {
									$set: 'black'
								}
							},
							modalFields: {
								[action.payload.column]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									}
								}
							}
						}
					}
				}
			});
			break;
		case 'AASDI_ZIP_ERROR_TOGGLE':
			state = update(state, {
				searchByZip: {
					zipError: {
						$set: action.payload
					}
				}
			});
			break;
		case 'AASDI_ZIP_SEARCH_DATA':
			state = update(state, {
				rowData: {
					$set: action.payload
				}
			});
			break;
		case 'SET_LOADING':
			state = update(state, {
				loading: {
					$set: action.payload.loading
				}
			});
			break;

		case 'CREATE_AASDI_MODAL_ADD_DOCUMENT':
			state = update(state, {
				stepsAasdi: {
					modalSteps: {
						[action.payload.sectionIndex]: {
							documents: {
								$push: [action.payload.object]
							}
						}
					}
				}
			});
			break;
		case 'CREATE_AASDI_MODAL_DROPDOWN_LOADER':
			state = update(state, {
				[action.payload.modal]: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								[action.payload.column]: {
									loading: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_AASDI_REMOVE_DOC_GRID':
			state = update(state, {
				stepsAasdi: {
					modalSteps: {
						[action.payload.sectionIndex]: {
							documents: {
								$splice: [[action.payload.rowIndex, 1]]
							}
						}
					}
				}
			});
			break;
		case 'CREATE_AASDI_MODAL_DROPDOWN_OPTIONS':
			state = update(state, {
				[action.payload.modal]: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								[action.payload.column]: {
									options: {
										$set: action.payload.options
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_AASDI_MODAL_LOADING':
			state = update(state, {
				stepsAasdi: {
					loading: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CREATE_AASDI_MODAL_ERROR':
			state = update(state, {
				stepsAasdi: {
					error: {
						$set: action.payload.value
					},
					errorMessage: {
						$set: action.payload.message
					}
				}
			});
			break;

		case 'CLEAR_AASDI_MODAL':
			state = update(state, {
				stepsAasdi: {
					modalSteps: {
						$set: createAasdiModalSteps
					}
				}
			});
			break;

		case 'CREATE_AASDI_MODAL_SHOW_REQUIRED':
			state = update(state, {
				stepsAasdi: {
					modalSteps: {
						[action.payload.step]: {
							icon: {
								color: {
									$set: 'red'
								}
							},
							modalFields: {
								[action.payload.field]: {
									error: {
										$set: true
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_AASDI_MODAL_ADD_AASDI_TO_GRID':
			state = update(state, {
				rowData: {
					$unshift: [action.payload.response]
				}
			});
			break;

		case 'DEACTIVATE_AASDI_MODAL_TOGGLE':
			state = update(state, {
				isDeactivateModalOpen: {
					$set: !state.isDeactivateModalOpen
				}
			});
			break;

		case 'DEACTIVATE_AASDI_MODAL_LOADING':
			state = update(state, {
				isDeactivateModalLoading: {
					$set: !state.isDeactivateModalLoading
				}
			});
			break;

		case 'TOGGLE_DEACTIVATE_AASDI_ERROR':
			state = update(state, {
				deactivateAasdiErrorHidden: {
					$set: action.payload.hidden
				}
			});
			break;

		case 'ACTIVATE_AASDI_MODAL_TOGGLE':
			state = update(state, {
				isActivateModalOpen: {
					$set: !state.isActivateModalOpen
				}
			});
			break;

		case 'ACTIVATE_AASDI_MODAL_LOADING':
			state = update(state, {
				isActivateModalLoading: {
					$set: !state.isActivateModalLoading
				}
			});
			break;

		case 'TOGGLE_ACTIVATE_AASDI_ERROR':
			state = update(state, {
				activateAasdiErrorHidden: {
					$set: action.payload.hidden
				}
			});
			break;

		case 'UPDATE_AASDI_STATUS':
			state = update(state, {
				rowData: {
					[state.rowData.findIndex((aasdi) => aasdi.id === action.payload.aasdiId)]: {
						statusId: {
							$set: action.payload.statusId
						}
					}
				}
			});
			break;

		case 'AASDI_CERT_MODAL_TOGGLE':
			state = update(state, {
				certModal: {
					open: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'AASDI_CERT_MODAL_SELECT':
			state = update(state, {
				certModal: {
					selectedCert: {
						$push: [action.payload.value]
					}
				}
			});
			break;

		case 'AASDI_CERT_MODAL_DESELECT':
			state = update(state, {
				certModal: {
					selectedCert: {
						$splice: [
							[state.certModal.selectedCert.findIndex((certId) => certId === action.payload.value), 1]
						]
					}
				}
			});
			break;

		case 'AASDI_CERT_MODAL_CLEAR':
			state = update(state, {
				certModal: {
					selectedCert: {
						$set: []
					}
				}
			});
			break;

		case 'AASDI_CERT_MODAL_SWITCH_STATE_INPUT_TYPE':
			const stateFieldIndex = state.stepsAasdi.modalSteps[action.payload.step].modalFields.findIndex(
				(field) => field.name === 'State'
			);
			state = update(state, {
				stepsAasdi: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								[stateFieldIndex]: {
									dropdown: {
										$set: action.payload.isUSA
									},
									input: {
										$set: !action.payload.isUSA
									}
								}
							}
						}
					}
				}
			});
			break;

		default:
	}
	return state;
};

export const createAasdiDocumentsHeader = [
	{
		headerName: 'File Name',
		field: 'fileName',
		cellStyle: {paddingTop: '.4em'}
		// cellRendererFramework: ActiveToggleCellRenderer
	},
	{
		headerName: 'Google Drive',
		field: 'googleDriveLink',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function (params) {
			return `<a href="${params.value}" target="_blank">${params.value}</a>`;
		}
	},
	{
		headerName: 'Comments',
		field: 'comments',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Document Type',
		field: 'docType',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function (params) {
			if (!(params.value - 1 in docTypeOptions.map((value) => value.key))) {
				return 'Invalid Type';
			}
			return docTypeOptions[params.value - 1].text;
		}
	},
	{
		headerName: 'Remove',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'documentSectionRemoveButton'
	}
];
