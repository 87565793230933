import React from 'react';
import {connect} from 'react-redux';
import {Grid, Button, Icon, Label, Input, Dropdown, TextArea, Segment} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import DocumentSectionCheckbox from './component/gridButtons/DocumentSectionCheckBox.js';
import DocumentSectionRemoveButton from './component/gridButtons/DocumentSectionRemoveButton.js';
import * as actions from '../../services/workRecordActions.js';

class Documents extends React.Component {
	render() {
		return (
			<Grid>
				<Grid.Row>
					{this.props.sectionFields.map((field, fieldIndex) =>
						field.input ? (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<div>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											{field.name}
										</Label>
									</div>
									<DebounceInput
										element={Input}
										onChange={(e) => {
											this.props.updateDocumentValue(fieldIndex, e.target.value);
										}}
										debounceTimeout={600}
										value={field.value}
										style={{
											width: '100%'
										}}
										error={field.required || field.checkForm ? field.error : false}
										disabled={field.disabled}
										placeholder={field.placeholder}
									/>
								</div>
							</Grid.Column>
						) : field.dropdown ? (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<div
									style={{
										paddingTop: '.8em'
									}}
								>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{field.name}
									</Label>
									<Dropdown
										className="createWorkOrderClientDropdown"
										placeholder={field.placeholder}
										fluid
										search
										selection
										options={field.options}
										onChange={(e, {value}) => {
											this.props.updateDocumentValue(fieldIndex, value);
										}}
										icon={field.icon}
										value={field.value}
										error={field.required ? field.error : false}
									/>
								</div>
							</Grid.Column>
						) : field.textArea ? (
							<Grid.Column width={field.columnWidth} key={fieldIndex}>
								<div
									style={{
										paddingTop: '0.8em',
										zIndex: '10'
									}}
								>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{field.name}
									</Label>
									<DebounceInput
										className="scrollingTextArea"
										element={TextArea}
										onChange={(e) => {
											this.props.updateDocumentValue(fieldIndex, e.target.value);
										}}
										debounceTimeout={600}
										value={field.value}
										style={{
											width: '100%'
										}}
										error={field.required || field.checkForm ? field.error : false}
										disabled={field.disabled}
										placeholder={field.placeholder}
									/>
								</div>
							</Grid.Column>
						) : null
					)}
					<Grid.Column
						width={15}
						style={{
							paddingTop: '0.8em'
						}}
					>
						<Button
							primary
							onClick={() => {
								this.props.addWorkViewDocument(
									this.props.sectionFields[0],
									this.props.sectionFields[1],
									this.props.sectionFields[2],
									this.props.sectionFields[3],
									this.props.WorkId
								);
							}}
						>
							<Icon name="plus" />
							Add Document
						</Button>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={15}>
						<Segment loading={this.props.loadingDocs}>
							<div
								className="ag-theme-balham"
								style={{
									height: '20vh',
									width: '100%',
									textAlign: 'left',
									boxSizing: 'border-box'
								}}
							>
								<AgGridReact
									onGridReady={(params) => {
										this.gridApi = params.api;
										this.columnApi = params.columnApi;
										this.gridApi.sizeColumnsToFit();
									}}
									suppressHorizontalScroll={true}
									columnDefs={columnDefs}
									defaultColDef={defaultColDef}
									modules={AllModules}
									rowHeight={40}
									id="documentGrid"
									rowData={this.props.documentsData ? this.props.documentsData : null}
									enableStatusBar={true}
									alwaysShowStatusBar={false}
									enableRangeSelection={true}
									{...this.props}
								/>
							</div>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.workRecord.view.Documents,
		sectionFields: state.work.workRecord.view.Documents.fields,
		documentsData: state.work.workRecord.view.Documents.documents,
		WorkId: state.work.workRecord.view.id,
		loadingDocs: state.work.workRecord.view.Documents.loading
	};
};

const defaultColDef = {
	sortable: true,
	filter: true,
	resizable: true
};

const columnDefs = [
	{
		headerName: 'File Name',
		field: 'fileName',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Google Drive',
		field: 'googleDriveLink',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function (params) {
			return params.value
				? `<a href="${params.value}" target="_blank">${params.value}</a>`
				: 'No mobile drive folder exists';
		}
	},
	{
		headerName: 'Comments',
		field: 'comments',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Customer Approval',
		field: 'customerApproval',
		cellStyle: {paddingTop: '.4em'},
		cellRendererFramework: DocumentSectionCheckbox
	},
	{
		headerName: 'Remove',
		cellStyle: {paddingTop: '.4em'},
		cellRendererFramework: DocumentSectionRemoveButton
	}
];

export default connect(mapStateToProps, actions)(Documents);
