import { roundFloat } from "~/util/numbers";

export const fmtPercent = (val) => {
	if (val === Infinity) {
		return '∞%';
	} else if (val === -Infinity) {
		return '-∞%';
	}
	return roundFloat(val, 2) + '%';
};

export const fmtMoney = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format;
