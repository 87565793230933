import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import reduxClipboardCopy from 'redux-clipboard-copy';
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from './reducer.js';
import { createReduxHistory, routerMiddleware } from './router.js';
import { persistStore } from "redux-persist"

const composeEnhancers = composeWithDevTools({
	//options
})

const store = createStore(
	rootReducer, //reducer
	{}, //initial state
	composeEnhancers(
		applyMiddleware( //enhancers (middleware)
			thunk,
			routerMiddleware,
			reduxClipboardCopy
		)
	)
);

export const persistor = persistStore(store)
export const history = createReduxHistory(store);
export default store;

const hot = import.meta.webpackHot
if (hot) {
	hot.accept('./reducer.js', () => {
		import('./reducer.js').then((newReducer) => {
			store.replaceReducer(newReducer.default);
		});
	});
}
