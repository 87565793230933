import React, {Component} from 'react';
import {Popup} from 'semantic-ui-react';
class aasdiSearchExpiredRenderer extends Component {
	render() {
		const {data} = this.props;
		return data.expired ? (
			<Popup
				trigger={
					<div
						style={{
							width: '100%',
							backgroundColor: 'transparent',
							color: 'red'
						}}
					>
						{data.name}
					</div>
				}
				on={['hover']}
				flowing
				hoverable
			>
				<p>{`AASDI Insurance has expired`}</p>
			</Popup>
		) : (
			<div>{data.name}</div>
		);
	}
}

export default aasdiSearchExpiredRenderer;
