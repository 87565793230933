export const fieldsForClientAndAASDI = [
	{
		label: 'First Name',
		key: 'firstName',
		type: 'text',
		isRequired: true,
		maxLength: null
	},
	{
		label: 'Last Name',
		key: 'lastName',
		type: 'text',
		isRequired: true,
		maxLength: null
	},
	{
		label: 'Email',
		key: 'email',
		type: 'email',
		isRequired: true,
		maxLength: null
	},
	{
		label: 'Phone',
		key: 'phone',
		type: 'number',
		isRequired: true,
		mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
		placeholder: '555-555-5555',
		maxLength: null
	},
	{
		label: 'Phone Ext',
		key: 'phoneExt',
		type: 'number',
		isRequired: false,
		mask: [/\d/, /\d/, /\d/, /\d/],
		placeholder: '5555',
		maxLength: null
	},
	{
		label: 'Username',
		key: 'username',
		type: 'username',
		isRequired: true,
		maxLength: null
	},
	{
		label: 'Password',
		key: 'password',
		type: 'password',
		isRequired: false,
		maxLength: null
	}
];
