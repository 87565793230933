import React, {Component} from 'react';
import {Label, Segment, Grid, Button, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import AgGrid from '../../../../../../components/Global/AgGrid.js';
import FilterGrid from '../../../../../../components/Global/FilterGrid.js';
import ExportGrid from '../../../../../../components/Global/ExportGrid.js';
import ActivityStream from '../../../../../../components/activityStream/ActivityStream.js';
import api from '../../../../../../lib/api.ts';
import countryOptions from '../../../../../../constants/countries.js';
import CreateContactModal from '../../../../../../components/Global/CreateContactModal.js';
import ProfileDetails from '../../../../../../components/Global/ProfileDetails.js';
import ExpiredMessage from './expiredInsuranceMessage.js';
import {
	toggleCreateContactModal,
	toggleFetchingOnTabsNavbar
} from '../../../../../../components/Global/services/globalActions.js';
import {checkIdenticalsArrays} from '../../../../../../lib/old/validation.js';
import {OverviewTabHeader} from '../services/profileAasdiGridReducer.js';
import {getTabRequestItems, setAasdiProfileName, clearGrid} from '../services/profileAasdiGridActions.js';

class Overview extends Component {
	constructor(props) {
		super(props);
		this.editContact = this.editContact.bind(this);
		this.updateName = this.updateName.bind(this);
	}

	componentDidUpdate(prevProps) {
		const {profileAasdiGridData, profileAasdiGridHeader, toggleFetchingOnTabsNavbar} = this.props;
		if (prevProps.profileAasdiGridData === null && profileAasdiGridData) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.profileAasdiGridHeader, profileAasdiGridHeader)) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.rowData, profileAasdiGridData)) {
			toggleFetchingOnTabsNavbar(false);
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();

		const {aasdiId} = this.props;

		if (
			this.props.profileAasdiGridData &&
			this.props.profileAasdiGridData.length &&
			Number(aasdiId) === this.props.profileAasdiGridData[0].aasdiId &&
			checkIdenticalsArrays(this.props.profileAasdiGridHeader, OverviewTabHeader)
		) {
			return;
		}
		this.props.clearGrid('Overview');
		this.gridApi.showLoadingOverlay();
		this.props.getTabRequestItems(aasdiId, 'Overview');
		this.props.toggleFetchingOnTabsNavbar(true);
	};

	editContact(params) {
		const updatedData = params.data;
		const aasdiId = updatedData.id;
		this.gridApi.showLoadingOverlay();
		this.setState({...this.state, isLoading: true});

		if (updatedData.state) {
			updatedData.state = updatedData.state.substring(0, 2).toUpperCase();
		}

		if (updatedData.countryId) {
			let country = countryOptions.find((country) => updatedData.countryId === country.text);
			if (country) {
				updatedData.countryId = country.value;
			}
		}

		api(`legacy/contacts/${aasdiId}`, 'PUT', updatedData)
			.then(() => {
				this.setState({...this.state, isLoading: false});
				this.gridApi.hideOverlay();
			})
			.catch((error) => {
				this.setState({...this.state, isLoading: false});
				this.gridApi.hideOverlay();
				alert('Error while updating contact!');
				console.error(error);
			});
	}

	updateName(name) {
		this.props.setAasdiProfileName(name);
	}

	render() {
		const {profileAasdiGridData, profileAasdiGridHeader, aasdiId} = this.props;

		return (
			<Segment>
				<Grid columns={2} style={{margin: '1em'}}>
					<Grid.Row>
						{this.props.expired ? <ExpiredMessage /> : null}
						<Grid.Column width={6}>
							<ActivityStream
								type="aasdi"
								id={aasdiId}
								options={[
									{
										key: 'note',
										text: 'Note',
										value: 'Note'
									},
									{
										key: 'phoneCall',
										text: 'Phone Call',
										value: 'Phone Call'
									},
									{
										key: 'quote',
										text: 'Quote',
										value: 'Quote'
									},
									{
										key: 'voicemail',
										text: 'Voicemail',
										value: 'Voicemail'
									}
								]}
							/>
						</Grid.Column>
						<Grid.Column width={10}>
							<ProfileDetails id={aasdiId} type="aasdi" updateName={this.updateName} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Segment basic style={{margin: '2.5rem', border: '1px solid #DEDEDE'}}>
					<Label color="blue" ribbon style={{marginBottom: '2vh'}}>
						Contacts
					</Label>
					<Button
						basic
						color="blue"
						content="Add"
						floated="right"
						icon="add"
						size="tiny"
						onClick={() => this.props.toggleCreateContactModal(true)}
					/>
					<Divider />
					<Grid style={{padding: '2em 0'}}>
						<FilterGrid gridApi={() => this.gridApi} />
						<ExportGrid gridApi={() => this.gridApi} />
					</Grid>
					<AgGrid
						columnDefs={profileAasdiGridHeader}
						rowData={profileAasdiGridData}
						onGridReady={this.onGridReady}
						onCellValueChanged={this.editContact}
					/>
				</Segment>
				<CreateContactModal id={aasdiId} typeOfId={'aasdiId'} />
			</Segment>
		);
	}
}

Overview.propTypes = {
	aasdiId: PropTypes.string,
	aasdiProfileInfo: PropTypes.array,
	clearGrid: PropTypes.func,
	getTabRequestItems: PropTypes.func,
	location: PropTypes.array,
	profileAasdiGridData: PropTypes.array,
	profileAasdiGridHeader: PropTypes.array,
	setAasdiProfileName: PropTypes.func,
	toggleCreateContactModal: PropTypes.func,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	expired: PropTypes.bool
};

const mapStateToProps = (state) => ({
	profileAasdiGridData: state.work.aasdiProfileGrid.rowData,
	aasdiProfileInfo: state.work.aasdiProfileGrid.profileInfos,
	profileAasdiGridHeader: state.work.aasdiProfileGrid.headerData,
	expired:
		state.work.aasdiProfileGrid.aasdiData[0] && state.work.aasdiProfileGrid.aasdiData[0].expired
			? state.work.aasdiProfileGrid.aasdiData[0].expired
			: false
});

const mapDispatchToProps = (dispatch) => ({
	getTabRequestItems: (aasdiId, item) => dispatch(getTabRequestItems(aasdiId, item)),
	toggleCreateContactModal: (status) => dispatch(toggleCreateContactModal(status)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	clearGrid: (item) => dispatch(clearGrid(item)),
	setAasdiProfileName: (name) => dispatch(setAasdiProfileName(name))
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
