import React, { useEffect, useState } from 'react';
import {Button, Icon, Divider} from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {refreshSession, signIn} from "./services/authActions"
import { Row } from '~/components/layout/Flex.jsx';
import { getToken, refreshToken, login } from '~/lib/oauth2';

export function Login() {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string>()
	const history = useHistory()
	const dispatch = useDispatch()
	const {search} = useLocation()
	
	const authCode = new URLSearchParams(search).get('code')

	useEffect(() => {
		(async () => {
			if(!authCode) return
	
			setLoading(true)
			try {
				const response = await getToken(authCode)

				await dispatch(signIn(response.id_token, response.refresh_token) as any)
				history.push('/')
			} catch (err: any) {
				console.error(err)
				setError("An unexpected error occured, please try again.")
			} finally {
				setLoading(false)
			}
		})()
	}, [authCode])
	
	//we dont update store because we have a listener that does that for us in reducer file
	const authenticate = async (provider?: string) => {
		const extraParams = provider ? {identity_provider: provider} : undefined
		await login({extraSearchParams: extraParams})
	}

	return <>
		<h3 style={{color: 'red'}}>{error}</h3>
		<Button fluid disabled={loading} loading={loading} color="google plus" onClick={() => authenticate('Google')}>
			<Icon name='google'/> Sign In With Google
		</Button>
		<Divider horizontal>OR</Divider>
		<Button fluid disabled={loading} loading={loading} onClick={() => authenticate()} color="blue">
			OMNI Sign In
		</Button>
		<Row justify='end' style={{marginTop: 20, marginRight: 10, marginLeft: 10}}>
			<Link to="/auth/register">Register</Link>
		</Row>
	</>
}