import customFetch from '../../../lib/old/customFetch.js';
import moment from 'moment';
import {updateOpportunityColumn} from '../../crm/scenes/dashboard/scenes/opportunity/services/opportunityActions.js';

export const getAccountingConsole = () => async (dispatch) => {
	dispatch({
		type: 'ACCOUNTING_CONSOLE_LOADER',
		payload: {
			value: true
		}
	});

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/accounting-console`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();
		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'GET_ACCOUNTING_CONSOLE',
			payload: {
				json: response
			}
		});

		dispatch({
			type: 'ACCOUNTING_CONSOLE_LOADER',
			payload: {
				value: false
			}
		});
	} catch (err) {
		dispatch({
			type: 'ERROR',
			payload: err.stack
		});
	}
};

export const getAccountingConsoleCategoryInfo = (name, archive) => async (dispatch) => {
	dispatch({
		type: 'ACCOUNTING_CONSOLE_CATEGORY_LOADER',
		payload: {
			value: true
		}
	});

	const endpoint =
		name !== 'Users'
			? `${process.env.REACT_APP_API_URL}/legacy/accounting-console?group=${name}${archive ? '&archive=1' : ''}`
			: `${process.env.REACT_APP_API_URL}/legacy/employee?type=allUsers`;
	try {
		const call = await customFetch(endpoint);
		let response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		response.forEach((item) => {
			item.spectrumEstimatedRevenue = item.spectrumEstimatedRevenue ? item.spectrumEstimatedRevenue : 0;
			item.spectrumActualRevenue = item.spectrumActualRevenue ? item.spectrumActualRevenue : 0;
			item.spectrumActualChangeOrderRevenue = item.spectrumActualChangeOrderRevenue
				? item.spectrumActualChangeOrderRevenue
				: 0;
			item.spectrumActualCost = item.spectrumActualCost ? item.spectrumActualCost : 0;
			item.spectrumActualChangeOrderCost = item.spectrumActualChangeOrderCost
				? item.spectrumActualChangeOrderCost
				: 0;
		});

		switch (name) {
			case 'Users':
				break;
			case 'Client':
			case 'AASDI': {
				response.forEach((item) => {
					item.fullAddress = `${item.address ? ` ${item.address},` : ''}${
						item.address2 ? ` ${item.address2},` : ''
					}${item.city ? ` ${item.city},` : ''}${item.state ? ` ${item.state} ` : ''}${
						item.zip ? ` ${item.zip} ` : ''
					}${item.countryName ? ` ${item.countryName}` : ''}`;
				});
				break;
			}

			case 'Project': {
				response.forEach((item) => {
					item.bidDueDate = moment(item.bidDueDate).format('MM-DD-YYYY');
					item.projectedOrderDate = moment(item.projectedOrderDate).format('MM-DD-YYYY');
				});
				break;
			}

			case 'Rejections': {
				response.forEach((item) => {
					item.rejectionType = item.billId
						? 'Invoice'
						: item.clientId
						? 'Client'
						: item.aasdiId
						? 'AASDI'
						: item.workId
						? 'Work'
						: item.opportunityId
						? 'Pipeline'
						: '';
					item.createdOn = moment(item.createdOn).format('MM-DD-YYYY h:mm A');
				});
				break;
			}

			case 'Invoice': {
				let arrayToReturn = [];
				let groupMemberIds = [];

				response.forEach((item) => {
					if (item.groupNumber === null) {
						// if the invoice is not a group it is added to array
						arrayToReturn.push(item);
					} else {
						if (
							// checks if the groupNumber hasn't been used before it
							groupMemberIds.findIndex((memberId) => memberId === item.groupNumber) === -1
						) {
							// adds new groupNumbers to the array used for the above check
							groupMemberIds.push(item.groupNumber);
							// this returns arrays of all the id's for members in each group
							let groupMembers = response
								.filter((filterItem) => filterItem.groupNumber === item.groupNumber)
								.map((groupMember) => {
									return {
										amount: groupMember.amount,
										workId: groupMember.workId,
										workBillsId: groupMember.id
									};
								});
							// adds the main groupId to the array of member data (used when changing status after approval or denial)
							groupMembers.push({
								workId: item.groupNumber,
								workBillsId: null
							});

							item.amount = item.groupBillAmount;
							item.groupMembers = groupMembers;
							item.workId = item.groupNumber; // work id associated with 19I work number
							item.isGroup = true;
							item.workNumber = item.quotedJobNumber;
							arrayToReturn.push(item);
						}
					}
				});
				response = arrayToReturn;

				break;
			}

			case 'Accepted': {
				response.forEach((item) => {
					item.createdOn = moment(item.createdOn[0] ? item.createdOn[0] : item.createdOn).format('lll');
					item.fullName = `${item.firstName} ${item.lastName}`;
				});
				break;
			}

			default:
				break;
		}

		dispatch({
			type: name !== 'Users' ? 'GET_ACCOUNTING_CONSOLE_CATEGORY_INFO' : 'GET_ACCOUNTING_CONSOLE_USERS',
			payload: {
				json: response,
				name
			}
		});

		dispatch({
			type: 'ACCOUNTING_CONSOLE_CATEGORY_LOADER',
			payload: {
				value: false
			}
		});
	} catch (err) {
		dispatch({
			type: 'ERROR',
			payload: err.stack
		});
	}
};

export const setUserForm = (form) => (dispatch) => {
	dispatch({
		type: 'SET_SELECTED_ACCOUNTING_CONSOLE',
		payload: {
			form
		}
	});
};

export const setSelectedCategory = (name) => (dispatch) => {
	dispatch({
		type: 'SET_SELECTED_ACCOUNTING_CONSOLE',
		payload: {
			name
		}
	});
};

export const toggleDesyncedMessage = (value) => (dispatch) => {
	dispatch({
		type: 'ACCOUNTING_CONSOLE_CATEGORY_DESYNCED',
		payload: {
			value
		}
	});
};

export const selectRecord = (record) => ({
	type: 'SET_SELECTED_RECORD',
	payload: {
		record
	}
});

export const toggleRejectModal = (value) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_REJECT_MODAL',
		payload: {
			value
		}
	});
};

export const toggleOptionSelectionModal = (value) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_OPTIONS_MODAL',
		payload: {
			value
		}
	});
};

export const updateUserForm = (form) => (dispatch) => {
	dispatch({
		type: 'UPDATE_USER_FORM',
		payload: {
			form
		}
	});
};

export const insertUsersGrid = (user) => (dispatch) => {
	dispatch({
		type: 'INSERT_ACCOUNTING_CONSOLE_USERS',
		payload: {
			user
		}
	});
};

export const UPDATE_ACCOUNTING_CONSOLE_USERS = 'UPDATE_ACCOUNTING_CONSOLE_USERS';
export const updateAccountingConsoleUsers = (user) => ({
	type: UPDATE_ACCOUNTING_CONSOLE_USERS,
	payload: {
		user
	}
});

export const setSearchOptions = (options) => (dispatch) => {
	dispatch({
		type: 'SET_SEARCH_OPTIONS_USER_MODAL',
		payload: {
			options
		}
	});
};

export const switchModalRequestType = (type) => (dispatch) => {
	dispatch({
		type: 'SWITCH_MODAL_REQUEST_TYPE',
		payload: {
			type
		}
	});
};

export const toggleUserCreationModal = (value) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_USER_CREATION_MODAL',
		payload: {
			value
		}
	});
};

export const toggleDeactivateUserModal = (value) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_DEACTIVATE_USER_MODAL',
		payload: {
			value
		}
	});
};

export const setDeactivateUserData = (user) => (dispatch) => {
	dispatch({
		type: 'SET_DEACTIVATE_USER_DATA',
		payload: {
			user
		}
	});
};

export const toggleUserCreationModalType = (value) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_USER_CREATION_MODAL_TYPE',
		payload: {
			value
		}
	});
};

export const toggleAcceptModal = (value, buttonId) => (dispatch, getState) => {
	const state = getState();
	if (state.accountingConsoleReducer.accountingConsole.acceptModal.id) {
		dispatch({
			type: 'UPDATE_ACCEPT_MODAL_ID',
			payload: {
				value: ''
			}
		});
	}

	if (state.accountingConsoleReducer.accountingConsole.acceptModal.existsError === true) {
		dispatch({
			type: 'TOGGLE_EXISTS_ERROR',
			payload: {
				value: false
			}
		});
	}

	if (
		buttonId === 'bulk' &&
		!state.accountingConsoleReducer.accountingConsole.selectedItemsForApproval[0]
		// this check is here to make sure that there is at least 1 valid job number selected. keeps user from approving desynced jobs
	) {
		// if only invalid jobs are selected then do nothing
		return;
	} else {
		// else open accept modal
		dispatch({
			type: 'TOGGLE_ACCEPT_MODAL',
			payload: {
				value,
				buttonId
			}
		});
	}
};

export const selectItemForApproval = (selectedNodes, invalid) => (dispatch) => {
	dispatch({
		type: 'SELECT_ITEM_FOR_APPROVAL',
		payload: selectedNodes
	});
	if (invalid) {
		dispatch({
			type: 'SET_INVALID',
			payload: invalid
		});
	}
};

// This should probably be broken up into a few different functions; I know this is horrible rn. Please forgive me.
export const acceptReject = (category, record, approve, comment, code) => async (dispatch, getState) => {
	console.log(`hit inside acceptReject`);
	const acEndpoint = `${process.env.REACT_APP_API_URL}/legacy/accounting-console`;

	dispatch({
		type: 'ACCOUNTING_CONSOLE_MODAL_LOADER',
		payload: {
			value: true
		}
	});

	try {
		const state = getState();
		const selectedItems = state.accountingConsoleReducer.accountingConsole.selectedItemsForApproval;
		const groupSelectedItems = selectedItems.filter((item) => item.hasOwnProperty('groupMembers'));
		const notGroupSelectedItems = selectedItems.filter((item) => !item.hasOwnProperty('groupMembers'));
		var opportunityId = state.accountingConsoleReducer.accountingConsole.selectedRecord.id;
		// Invoices
		if (category === 'Invoice') {
			let dataToSend = [];

			if (groupSelectedItems.length > 0) {
				// approve multiple group invoices
				dataToSend = groupSelectedItems;
			} else if (record.groupNumber) {
				// approve single group invoice and return
				dataToSend.push(record);
			}

			if (dataToSend.length > 0) {
				await dispatch(acceptRejectInvoiceGroup(dataToSend, approve, comment));
				if (notGroupSelectedItems.length === 0) {
					dataToSend.map((item) =>
						dispatch({
							type: 'REMOVE_FROM_GRID',
							payload: {
								id: item.workId,
								category: 'Invoice',
								record: item
							}
						})
					);
					dispatch({
						type: 'ACCOUNTING_CONSOLE_MODAL_LOADER',
						payload: {
							value: false
						}
					});

					if (approve === true) {
						dispatch({
							type: 'TOGGLE_ACCEPT_MODAL',
							payload: {
								value: false
							}
						});
					} else {
						dispatch({
							type: 'TOGGLE_REJECT_MODAL',
							payload: {
								value: false
							}
						});
					}
					dispatch(selectItemForApproval([]));
					updateOpportunityColumn(opportunityId, 'stageName', 'Closed Business');
					return;
				}
			}

			if (notGroupSelectedItems.length > 0) {
				// approve multiple not group invoices
				let ids = '';
				let billIds = '';
				notGroupSelectedItems.forEach((item) => {
					ids += `${item.workId},`;
					billIds += `${item.id},`;
				});
				billIds = billIds.slice(0, -1);
				ids = ids.slice(0, -1);

				await dispatch(acceptRejectInvoiceNotGroup(ids, billIds, approve));
			} else if (record.groupNumber === null) {
				// approve single not group invoices
				await dispatch(acceptRejectInvoiceNotGroup(record.workId, record.id, approve));
			}
		}

		if (selectedItems.length > 0) {
			// send array of items
			const putBody = selectedItems.map((item) => ({
				group: category,
				id: item.id,
				approve,
				code,
				workId: item.workId,
				statusId: item.statusId
			}));

			const endpoint = `${acEndpoint}?bulk=true`;

			const putAccountingConsole = await customFetch(endpoint, {
				method: 'PUT',
				body: JSON.stringify(putBody)
			});

			const response = await putAccountingConsole.json();

			if (putAccountingConsole.status !== 200) {
				throw response;
			}

			let prevApproved = [];
			let toApprove = [];
			// divides returned records into previously approved and not approved
			response.forEach((item) => {
				let index = selectedItems.findIndex((selected) => selected.id === item.id);
				if (item.prevApproved) {
					prevApproved.push(selectedItems[index]);
				} else {
					toApprove.push(selectedItems[index]);
				}
			});

			if (prevApproved.length > 0) {
				dispatch({
					type: 'TOGGLE_PREVIOUSLY_APPROVED_RECORDS',
					payload: {
						records: prevApproved,
						category
					}
				});
			}

			if (toApprove.length > 0) {
				const postBody = toApprove.map((item) => ({
					category,
					approve,
					id: item.id
				}));

				const postAccountingConsole = await customFetch(endpoint, {
					method: 'POST',
					body: JSON.stringify(postBody)
				});

				const response2 = await postAccountingConsole.json();

				if (postAccountingConsole.status !== 200) {
					throw response2;
				}
			}

			selectedItems.forEach((item) =>
				dispatch({
					type: 'REMOVE_FROM_GRID',
					payload: {
						id: item.workId ? item.workId : item.id,
						category,
						record: item
					}
				})
			);
		} else {
			const putAccountingConsole = await customFetch(acEndpoint, {
				method: 'PUT',
				body: JSON.stringify({
					group: category,
					id: record.id,
					approve,
					code,
					workId: record.workId,
					statusId: record.statusId
				})
			});

			const response = await putAccountingConsole.json();

			if (putAccountingConsole.status !== 200) {
				throw response;
			}

			if (category !== 'Closure' && response[0].prevApproved) {
				dispatch({
					type: 'TOGGLE_PREVIOUSLY_APPROVED_RECORDS',
					payload: {
						records: [record],
						category
					}
				});
			} else {
				const postAccountingConsole = await customFetch(acEndpoint, {
					method: 'POST',
					body: JSON.stringify({
						category,
						approve,
						id: record.id,
						comment,
						workId: record.workId,
						statusId: record.statusId
					})
				});

				const response2 = await postAccountingConsole.json();

				if (postAccountingConsole.status !== 200) {
					throw response2;
				}
			}
			dispatch({
				type: 'REMOVE_FROM_GRID',
				payload: {
					id: record.workId ? record.workId : record.id,
					category,
					record
				}
			});
		}

		dispatch({
			type: 'ACCOUNTING_CONSOLE_MODAL_LOADER',
			payload: {
				value: false
			}
		});

		if (approve === true) {
			dispatch({
				type: 'TOGGLE_ACCEPT_MODAL',
				payload: {
					value: false
				}
			});
		} else {
			dispatch({
				type: 'TOGGLE_REJECT_MODAL',
				payload: {
					value: false
				}
			});
		}
		dispatch(selectItemForApproval([]));
		updateOpportunityColumn(opportunityId, 'stageName', 'Closed Business');
		return;
	} catch (err) {
		dispatch({
			type: 'ACCOUNTING_CONSOLE_MODAL_LOADER',
			payload: {
				value: false
			}
		});

		if ((approve === true && category === 'Client') || category === 'AASDI' || approve === false) {
			dispatch({
				type: 'TOGGLE_EXISTS_ERROR',
				payload: {
					value: true,
					error: err
				}
			});
		} else if (err.failedAt) {
			alert(
				err.failedAt === 'work put' ? `failed at the put for work` : `failed at the put for accounting console`
			);
		} else {
			dispatch({
				type: 'ERROR',
				payload: err.stack
			});
		}
	}
};

export const closePreviouslyApprovedModal = () => async (dispatch) => {
	dispatch({
		type: 'TOGGLE_PREVIOUSLY_APPROVED_RECORDS',
		payload: {
			records: [],
			category: ''
		}
	});
};

export const acceptRejectInvoiceGroup = (groupData, approve, comment) => async (dispatch) => {
	console.log(`hit inside acceptRejectInvoiceGroup`);
	const acEndpoint = `${process.env.REACT_APP_API_URL}/legacy/accounting-console`;

	if (groupData.length > 0) {
		let ids = '';
		groupData.map((item) => (ids += `${item.workId},`));
		ids = ids.slice(0, -1);

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${ids}?mode=group&billing=group`;

		let call = await customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify({
				approve
			})
		});

		let response = await call.json();

		if (call.status !== 200) {
			throw response;
		}
	}

	groupData.forEach((record) => {
		record.groupMembers.forEach(async (member) => {
			if (member.workId !== record.workId) {
				let call = await customFetch(acEndpoint, {
					method: 'POST',
					body: JSON.stringify({
						category: 'Invoice',
						approve,
						id: member.workBillsId,
						comment,
						workId: member.workId
					})
				});

				let response = await call.json();

				if (call.status !== 200) {
					throw response;
				}
			}
		});
	});

	return;
};

export const acceptRejectInvoiceNotGroup = (ids, billIds, approve) => async (dispatch) => {
	console.log(`hit inside acceptRejectNotInvoiceGroup`);
	if (approve) {
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${ids}?mode=group&billing=single&billId=${billIds}`;

		const call = await customFetch(endpoint, {
			method: 'PUT'
		});

		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}
	}
};

export const updateRejectCommentValue = (value) => (dispatch) => {
	dispatch({
		type: 'UPDATE_REJECT_COMMENT_VALUE',
		payload: {
			value
		}
	});
};

export const updateSpectrumId = (value) => (dispatch) => {
	dispatch({
		type: 'UPDATE_ACCEPT_MODAL_ID',
		payload: {
			value
		}
	});
};

export const toggleSymbolError = (value) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_SYMBOL_ERROR',
		payload: {
			value
		}
	});
};

export const toggleExistsError = (value) => (dispatch) => {
	dispatch({
		type: 'TOGGLE_EXISTS_ERROR',
		payload: {
			value
		}
	});
};

// Determines which widgets in the accounting console are displayed based on user's role type
export const setCategories = () => (dispatch, getState) => {
	const state = getState();
	const roleType = state.auth.account.roleTypeName;

	const allCategories = [
		{
			color: 'purple',
			count: '',
			description: 'Show and Create New Users',
			icon: 'users',
			name: 'Users'
		},
		{
			color: 'yellow',
			count: '',
			description: 'Approve Clients',
			icon: 'thumbtack',
			name: 'Client'
		},
		{
			color: 'teal',
			count: '',
			description: 'Approve AASDI',
			icon: 'user',
			name: 'AASDI'
		},
		{
			color: 'olive',
			count: '',
			description: 'Approve Project',
			icon: 'money bill alternate',
			name: 'Project'
		},
		{
			color: 'blue',
			count: '',
			description: 'Approve Invoices',
			icon: 'envelope open',
			name: 'Invoice'
		},
		{
			color: 'black',
			count: '',
			description: 'Close Orders and Projects',
			icon: 'check square',
			name: 'Closure'
		},
		{
			color: 'red',
			count: '',
			description: 'View Rejections',
			icon: 'thumbs down',
			name: 'Rejections'
		},
		{
			color: 'green',
			count: '',
			description: 'View Accepted',
			icon: 'thumbs up',
			name: 'Accepted'
		}
	];

	const categories = [
		{
			color: 'red',
			count: '',
			description: 'View Rejections',
			icon: 'thumbs down',
			name: 'Rejections'
		},
		{
			color: 'green',
			count: '',
			description: 'View Accepted',
			icon: 'thumbs up',
			name: 'Accepted'
		}
	];

	if (roleType === 'Accounting') {
		dispatch({
			type: 'SET_CATEGORIES',
			payload: {
				value: allCategories
			}
		});
	} else if (roleType === 'Standard' || roleType === 'Executive') {
		dispatch({
			type: 'SET_CATEGORIES',
			payload: {
				value: categories
			}
		});
	}
};

export const getInvoiceInfo = (record) => async (dispatch) => {
	console.log('hit inside getInvoiceInfo');

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work/${record.recordId}/billing?billIds=${record.secondaryId}`;

	dispatch({
		type: 'TOGGLE_ACCEPTED_PDF_MODAL',
		payload: {
			value: true
		}
	});

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();
		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'SET_INFO_PDF_MODAL',
			payload: {
				value: response[0]
			}
		});
	} catch (err) {
		alert('Something went wrong');
		dispatch({
			type: 'TOGGLE_ACCEPTED_PDF_MODAL',
			payload: {
				value: false
			}
		});
		dispatch({
			type: 'CLEAR_INFO_PDF_MODAL'
		});
		console.log(err);
	}
};

export const closePDFModal = () => (dispatch) => {
	dispatch({
		type: 'TOGGLE_ACCEPTED_PDF_MODAL',
		payload: {
			value: false
		}
	});

	dispatch({
		type: 'CLEAR_INFO_PDF_MODAL'
	});
};

export const archiveRejection = (record) => async (dispatch) => {
	console.log(`hit inside archiveRejection`);

	let endpoint = `${process.env.REACT_APP_API_URL}/legacy/accounting-console`;

	let call = await customFetch(endpoint, {
		method: 'PUT',
		body: JSON.stringify({
			id: record.id,
			archive: true
		})
	});

	let response = await call.json();

	if (call.status !== 200) {
		throw response;
	}

	// deleting workId because REMOVE_FROM_GRID breaks if it's in place because it's used in other grids
	record.workId && delete record.workId;

	dispatch({
		type: 'REMOVE_FROM_GRID',
		payload: {
			id: record.id,
			category: 'Rejections',
			record: record
		}
	});

	return;
};
