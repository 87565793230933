// TODOS
// Remove inline styling
// Breaking things down into smaller components would do this component some good
import {Grid, Menu, Breadcrumb, Icon, Loader, Item, Message} from 'semantic-ui-react';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Link} from 'react-router-dom';

import * as taskTemplateService from './services/TaskTemplates.service.js';
import {retrieveTemplateTasks, editTemplate, updateTaskTemplateOrder} from './services/TaskTemplates.actions.js';

import {
	selectTemplateById,
	selectTemplates,
	selectTemplatesLoading,
	selectTemplatesBeingRetrieved,
	selectErrorLoadingTemplates,
	selectSidePanelMode,
	selectTemplateTasks,
	selectTemplateTasksLoading,
	selectErrorLoadingTemplateTasks
} from './services/TaskTemplates.selectors.js';
import {retrieveTemplates, addTaskToTemplate, deleteTemplate} from './services/TaskTemplates.actions.js';
import LoadingSpinner from './components/LoadingSpinner.jsx';

import TaskList from './components/TaskList.jsx';
import TemplateInformation from './components/TemplateInformation.jsx';
import TaskInformation from './components/TaskInformation.jsx';
import SectionInformation from './components/SectionInformation.jsx';

import WorkNav from '../work/components/workNav.js';

import {arrayMove} from 'react-sortable-hoc';

class Manage extends Component {
	componentDidMount() {
		const {templatesBeingRetrieved, retrieveTemplateTasks} = this.props;
		taskTemplateService.retrieveTemplatesIfNeeded(templatesBeingRetrieved, this.props.retrieveTemplates);
		retrieveTemplateTasks(this.props.match.params.id);
	}

	render() {
		const {
			template,
			templatesLoading,
			errorLoadingTemplates,
			templateTasksLoading,
			errorLoadingTemplateTasks
		} = this.props;

		if (templatesLoading) {
			return (
				<div>
					<WorkNav />
					<LoadingSpinner loadingMessage="Retrieving template..." />
				</div>
			);
		} else if (errorLoadingTemplates) {
			return (
				<div style={{display: 'flex'}}>
					{/* this div creates a position:static html element to wrap the position:fixed worknavbar
					helps it to fit with the UI better */}
					<div style={{width: '15rem'}}>
						<WorkNav />
					</div>
					<div style={{maxWidth: 600}}>
						<Message icon error size="small" style={{marginTop: 100}}>
							<Icon name="x" />
							<Message.Content>
								An error occured while loading this template. Try refreshing the page or report a bug if
								the problem persists.
							</Message.Content>
						</Message>
					</div>
				</div>
			);
		}

		let taskContent;
		if (templateTasksLoading) {
			taskContent = (
				<Grid.Column width={16}>
					<Loader active inline="centered" size="large" content="Retrieving tasks..." />
				</Grid.Column>
			);
		} else if (errorLoadingTemplateTasks) {
			taskContent = (
				<Grid.Column width={16}>
					<Message icon error size="large">
						<Icon name="x" />
						<Message.Content>
							An error occured while loading tasks for this template. Try refreshing the page or report a
							bug if the problem persists.
						</Message.Content>
					</Message>
				</Grid.Column>
			);
		} else {
			taskContent = [
				<Grid.Column width={12} key="grid-body">
					<TaskList
						onSortEnd={(e) => {
							this.props.updateTaskTemplateOrder(
								arrayMove(this.props.selectedTemplateTasks.general, e.oldIndex, e.newIndex)
							);
						}}
						pressDelay={100}
						pinned={this.props.selectedTemplateTasks.pinned}
						general={this.props.selectedTemplateTasks.general}
						lockAxis="y"
					/>
				</Grid.Column>,
				<Grid.Column width={4} key="grid-side-panel">
					<div style={{position: 'sticky', top: 75}}>
						{this.props.sidePanelMode === 'template' ? (
							<TemplateInformation template={this.props.template} />
						) : null}
						{this.props.sidePanelMode === 'task' ? <TaskInformation /> : null}
						{this.props.sidePanelMode === 'section' ? <SectionInformation /> : null}
					</div>
				</Grid.Column>
			];
		}

		return (
			<div style={{display: 'flex'}}>
				{/* this div creates a position:static html element to wrap the position:fixed worknavbar
					helps it to fit with the UI better */}
				<div style={{width: '15rem'}}>
					<WorkNav />
				</div>
				<div style={{width: '80%', marginTop: '4.5rem'}}>
					<Menu style={{margin: '1em 0'}} borderless icon="labeled" size="mini">
						<Menu.Item>
							<Breadcrumb
								size="large"
								style={{
									paddingTop: '0.53rem',
									fontWeight: 'normal',
									width: '100%'
								}}
							>
								<Link to="/task-templates/list">
									<Breadcrumb.Section>Templates</Breadcrumb.Section>
								</Link>
								<Breadcrumb.Divider icon="right chevron" className="fixFloat" />
								<Breadcrumb.Section active>
									{template && !template.id ? 'New Template' : template.name}
								</Breadcrumb.Section>
							</Breadcrumb>
						</Menu.Item>
						<Menu.Item position="right" />

						<Menu.Item
							onClick={() =>
								this.props.addSectionToTemplate({
									tasksLength: this.props.selectedTemplateTasks.general.length
								})
							}
						>
							<Icon name="object group" />
							Create Section
						</Menu.Item>

						<Menu.Item
							className="pseudoDragHandler"
							onClick={() =>
								this.props.addTaskToTemplate({
									tasksLength: this.props.selectedTemplateTasks.general.length
								})
							}
						>
							<Icon name="plus" className="pseudoDragHandler" />
							Create Task
						</Menu.Item>

						<Menu.Item onClick={() => this.props.editTemplate()}>
							<Icon name="edit" />
							Edit
						</Menu.Item>
					</Menu>
					<Grid>{taskContent}</Grid>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	templates: selectTemplates(state),
	templatesLoading: selectTemplatesLoading(state),
	templatesBeingRetrieved: selectTemplatesBeingRetrieved(state),
	errorLoadingTemplates: selectErrorLoadingTemplates(state),

	selectedTemplateTasks: selectTemplateTasks(state),
	templateTasksLoading: selectTemplateTasksLoading(state),
	errorLoadingTemplateTasks: selectErrorLoadingTemplateTasks(state),

	sidePanelMode: selectSidePanelMode(state),

	template: selectTemplateById(state, props.match.params.id)
});

const mapDispatchToProps = (dispatch, props) => ({
	retrieveTemplates: () => dispatch(retrieveTemplates()),
	addTaskToTemplate: (options) => dispatch(addTaskToTemplate(options)),
	addSectionToTemplate: (options) => dispatch(addTaskToTemplate({...options, isSection: true})),
	retrieveTemplateTasks: (templateId) => dispatch(retrieveTemplateTasks(templateId)),
	deleteTemplate: (templateId) => dispatch(deleteTemplate(templateId)),
	editTemplate: () => dispatch(editTemplate()),
	updateTaskTemplateOrder: (updatedTaskList) => dispatch(updateTaskTemplateOrder(updatedTaskList))
});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
