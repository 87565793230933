// TODOS
// create an action that groups savingChangesSuccess and hideSnackbarAfterDelay functionality

export const TASK_TEMPLATES_SET_SNACKBAR_VALUES = 'TASK_TEMPLATES_SET_SNACKBAR_VALUES';
export const setSnackbarValues = (updatedValues) => ({
	type: TASK_TEMPLATES_SET_SNACKBAR_VALUES,
	payload: {updatedValues}
});

// generic snackbar messages; can be made more specific as needed
export const savingChanges = setSnackbarValues({
	loading: true,
	icon: 'circle notched',
	message: 'Saving changes to database',
	color: 'grey',
	open: true
});
export const savingChangesSuccess = setSnackbarValues({
	loading: false,
	icon: 'check',
	message: 'Changes saved successfully',
	color: 'green',
	open: true
});
export const savingChangesFailure = setSnackbarValues({
	loading: false,
	message: 'An error occured while saving template changes',
	color: 'red',
	open: true,
	icon: 'x'
});

export const TASK_TEMPLATES_RESET_SNACKBAR_VALUES = 'TASK_TEMPLATES_RESET_SNACKBAR_VALUES';
export const resetSnackbarValues = () => ({type: TASK_TEMPLATES_RESET_SNACKBAR_VALUES});

export const hideSnackbarAfterDelay = (dispatch, delay) =>
	setTimeout(() => {
		dispatch(resetSnackbarValues());
	}, delay);
