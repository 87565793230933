import React from 'react';
import {Button, Modal, Icon, Header, Grid, Loader, Dimmer} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import WorkOrderTemplateDrafts from './components/workOrderTemplate&Drafts/workOrderTemplate&Drafts.js';
import WorkOrderOverview from './components/workOrderOverview/WorkOrderOverview.js';
import WorkOrderProjectCosting from './components/workOrderProjectCosting/workOrderProjectCosting.js';
import WorkOrderScope from './components/workOrderScope/workOrderScope.js';
import WorkOrderAASDI from './components/workOrderAASDI/workOrderAASDI.js';
import WorkOrderAttachments from './components/workOrderDocuments/workOrderDocuments.js';
import WorkTemplateModal from './components/workOrderTemplateModal/templateModal.js';
import WorkOrderAfterCreateModal from './components/components/workOrderAfterCreateModal.js';
import Tags from '~/components/tags/tags.tsx';
import * as actions from './services/createWorkOrderActions.js';
//import WorkOrderContact from './components/workOrderContact/workOrderContact.js';
import ContactSection from '../../../../components/contactsSection/contactsSection.js';
import customFetch from '../../../../lib/old/customFetch.js';

class Order extends React.Component {
	async componentDidUpdate() {
		if (this.props.desynced && this.props.workNumber) {
			const endpoint = `${process.env.REACT_APP_API_URL}/legacy/activityStream/notifications/notifications`;

			await customFetch(endpoint, {
				method: 'POST',
				body: JSON.stringify({
					notificationDetails: [
						{
							description: `The work order you created (${this.props.workNumber}) is desynced. The record exists in our database. However, it is still processing in spectrum.`,
							link: 'notifications',
							icon: 'sync red',
							userId: this.props.userId
						}
					]
				})
			});
			this.props.clearDesynced();
		}
	}

	updateTags = (type, tag) => {
		if (type === 'add') {
			this.props.addTag(tag);
		} else {
			const removeIndex = this.props.tags.findIndex((t) => t.id === tag.id);
			this.props.removeTag(removeIndex);
		}
	}

	render() {
		return (
			<div>
				<Modal size="fullscreen" open={this.props.createWorkOrderModalToggle}>
					{this.props.loading ? (
						<Dimmer inverted active className="centerDimmer">
							{this.props.loading === 'progress' ? (
								<Loader size="big">Creating Work Order</Loader>
							) : this.props.loading === 'totalRevenue' ? (
								<Header as="h3" icon>
									<Icon name="cancel" color="red" />
									<div style={{paddingBottom: '.5em'}}>
										Cannot create work order without positive total revenue.
									</div>
									<Button
										onClick={() => {
											this.props.closeDimmerMain(null);
										}}
										style={{marginTop: '1.5em'}}
									>
										Close Message
									</Button>
								</Header>
							) : this.props.spectrumError ? (
								<Header as="h3" icon>
									<Icon name="cancel" color="red" />
									<div style={{paddingBottom: '.5em', color: 'black'}}>
										Work Order Creation Failed at Spectrum.
									</div>
									<div style={{color: 'black'}}>{`reason : ${this.props.spectrumMessage}.`}</div>
									<div />
									<div />
									<div style={{color: 'black'}}>
										This project will appear in OMNI, but it will not be tracked in Spectrum.
									</div>
									<Button
										onClick={() => {
											this.props.closeDimmerMain('spectrum');
										}}
										style={{marginTop: '1.5em'}}
									>
										Close Message
									</Button>
								</Header>
							) : (
								// Error handling
								<Header as="h3" icon>
									<Icon name="cancel" color="red" />
									<div style={{paddingBottom: '.5em', color: 'black'}}>
										Work Order Creation Failed
									</div>
									<div style={{color: 'black'}}>Please try again.</div>
									<Button
										onClick={() => {
											this.props.closeDimmerMain(null);
										}}
										style={{marginTop: '1.5em'}}
									>
										Close Message
									</Button>
								</Header>
							)}
						</Dimmer>
					) : null}
					<Header>
						<Grid className="templateDraftSection">
							<Grid.Row>
								<Grid.Column width={3} style={{margin: 'auto 0'}}>
									<Icon name="plus" color={this.props.iconError ? 'red' : 'green'} />
									<div className="content">Create Work Order</div>
								</Grid.Column>
								<Grid.Column width={13}>
									<WorkOrderTemplateDrafts />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Header>
					<Modal.Content scrolling>
						<WorkOrderOverview />
						<WorkOrderProjectCosting />
						<WorkOrderScope />
						<WorkOrderAASDI />
						<Grid>
							<Grid.Row>
								<Grid.Column width={11}>
									<WorkOrderAttachments />
								</Grid.Column>
								<Grid.Column width={5}>
									<ContactSection type="newWork" typeId="none" clientId={this.props.clientId} />
									<Tags tags={this.props.tags} parentUpdateTags={this.updateTags}/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() => {
								console.log('save as template');
								this.props.openWorkTemplateModal(true);
							}}
							color="blue"
							style={{marginRight: '1em'}}
							floated="left"
							// disabled
						>
							<Icon name="save" /> Save as Template
						</Button>
						<Button onClick={() => {}} color="blue" floated="left" disabled>
							<Icon name="save" /> Save as Draft
						</Button>
						{this.props.iconError ? (
							<div
								style={{
									display: 'inline-block',
									paddingRight: '8em',
									color: '#db2828'
								}}
							>
								{'Field errors present. Please check the form.'}
							</div>
						) : null}
						<Button.Group>
							<Button
								onClick={() => {
									this.props.toggleCreateWorkOrderModal();
								}}
								color="red"
							>
								<Icon name="remove" /> Cancel
							</Button>
							<Button
								onClick={() => {
									this.props.createWorkOrder();
								}}
								color="green"
							>
								<Icon name="checkmark" /> Create
							</Button>
						</Button.Group>
					</Modal.Actions>
				</Modal>
				<WorkOrderAfterCreateModal />
				<WorkTemplateModal />
			</div>
		);
	}
}

Order.propTypes = {
	clientId: PropTypes.string,
	closeDimmerMain: PropTypes.func,
	createWorkOrder: PropTypes.func,
	createWorkOrderModalToggle: PropTypes.bool,
	iconError: PropTypes.bool,
	loading: PropTypes.string,
	openWorkTemplateModal: PropTypes.func,
	spectrumError: PropTypes.bool,
	spectrumMessage: PropTypes.string,
	toggleCreateWorkOrderModal: PropTypes.func,
	userId: PropTypes.number,
	workNumber: PropTypes.number,
	clearDesynced: PropTypes.func,
	desynced: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		clientId: state.work.createWorkOrder.createWorkOrderModal.sections[1].fields[0].value,
		createWorkOrderModalToggle: state.work.createWorkOrder.createWorkOrderModal.toggleCreateWorkOrderModal,
		loading: state.work.createWorkOrder.createWorkOrderModal.loading,
		spectrumError: state.work.createWorkOrder.createWorkOrderModal.spectrumError,
		spectrumMessage: state.work.createWorkOrder.createWorkOrderModal.spectrumMessage,
		iconError: state.work.createWorkOrder.createWorkOrderModal.iconError,
		userId: state.auth.account.userId,
		workNumber: state.work.createWorkOrder.afterCreateModal.redirectWorkNumber,
		desynced: state.work.createWorkOrder.createWorkOrderModal.desynced,
		tags: state.work.createWorkOrder.createWorkOrderModal.sections[7].tags
	};
};

export default connect(mapStateToProps, actions)(Order);
