import update from 'react-addons-update';
import countryOptions from '../../../constants/countries.js';
import stateOptions from '../../../constants/states.js';
import industryOptions from '../../../constants/industries.js';
import leadSourceOptions from '../../../constants/leadTypes.js';

export default (
	state = {
		createModal: {
			id: null,
			loading: null,
			place: null,
			toggleCreateModal: false,
			modalSteps: [
				{
					name: 'Client',
					active: true,
					description: 'client description',
					icon: {name: 'user', color: 'black'},
					modalFields: [
						{
							name: 'Name',
							value: '',
							input: true,
							error: false,
							disabled: true,
							required: true
						},
						{
							name: 'Type',
							value: null,
							placeholder: 'Choose client type',
							options: [
								{
									key: 'End User',
									text: 'End User',
									value: 'End User'
								},
								{
									key: 'Re-Seller',
									text: 'Re-Seller',
									value: 'Re-Seller'
								}
							],
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Industry',
							value: null,
							placeholder: 'Choose client industry',
							options: industryOptions,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Website',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						}
					]
				},
				{
					name: 'Location',
					active: false,
					icon: {
						name: 'map marker alternate',
						color: 'black'
					},
					description: 'location description',
					modalFields: [
						{
							name: 'Address',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Address 2',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false
						},
						{
							name: 'City',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'Zip Code',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true
						},
						{
							name: 'State',
							value: null,
							placeholder: 'Choose state',
							options: stateOptions,
							error: false,
							disabled: false,
							upward: true,
							required: true
						},
						{
							name: 'Country',
							value: 218,
							placeholder: 'Choose country',
							options: countryOptions,
							error: false,
							disabled: false,
							upward: true,
							required: true
						}
					]
				},
				{
					name: 'Contact',
					description: 'contact description',
					active: false,
					icon: {
						name: 'phone',
						color: 'black'
					},
					modalFields: [
						{
							name: 'Main Contact',
							value: '',
							input: false,
							searchMC: true,
							options: null,
							error: false,
							disabled: false
						},
						{
							name: 'Account Executive',
							value: null,
							input: false,
							error: false,
							disabled: false,
							searchAE: true,
							options: null,
							loading: false,
							required: true
						},
						{
							name: 'Lead Source',
							value: null,
							input: false,
							error: false,
							disabled: false,
							options: leadSourceOptions,
							upward: true,
							required: true
						}
					]
				}
			]
		}
	},
	action
) => {
	switch (action.type) {
		case 'CREATE_MODAL_LOADING':
			state = update(state, {
				createModal: {
					loading: {
						$set: action.payload.state
					}
				}
			});
			break;

		case 'CREATE_MODAL_UPDATE':
			state = update(state, {
				[action.payload.modal]: {
					modalSteps: {
						[action.payload.step]: {
							icon: {
								color: {
									$set: 'black'
								}
							},
							modalFields: {
								[action.payload.column]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_MODAL_UPDATE_PLACEHOLDER':
			state = update(state, {
				[action.payload.modal]: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								[action.payload.column]: {
									placeholder: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_MODAL_TOGGLE':
			state = update(state, {
				createModal: {
					toggleCreateModal: {
						$set: !state.createModal.toggleCreateModal
					},
					id: {
						$set: action.payload.id
					},
					place: {
						$set: action.payload.place
					},
					modalSteps: {
						$set: baseModalSteps
					}
				}
			});
			break;

		case 'CREATE_MODAL_SET_ACTIVE_STEP':
			state = update(state, {
				createModal: {
					modalSteps: {
						[action.payload.currentStep]: {
							active: {
								$set: false
							}
						},
						[action.payload.targetStep]: {
							active: {
								$set: true
							}
						}
					}
				}
			});
			break;

		case 'CREATE_MODAL_GET_DROPDOWN_OPTIONS':
			state = update(state, {
				createModal: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								[action.payload.column]: {
									options: {
										$set: action.payload.options
									},
									loading: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_MODAL_LOADING_FIELD':
			state = update(state, {
				createModal: {
					modalSteps: {
						[action.payload.step]: {
							modalFields: {
								[action.payload.column]: {
									loading: {
										$set: true
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_MODAL_CHECK_REQUIRED_FIELDS':
			action.payload.emptyRequiredFields.forEach((step) => {
				step.forEach((field) => {
					state = update(state, {
						createModal: {
							modalSteps: {
								[field.StepIndex]: {
									icon: {
										color: {
											$set: 'red'
										}
									},
									modalFields: {
										[field.FieldIndex]: {
											error: {
												$set: true
											}
										}
									}
								}
							}
						}
					});
				});
			});
			break;

		case 'CREATE_MODAL_AUTOFILL':
			state = update(state, {
				createModal: {
					loading: {
						$set: null
					},
					modalSteps: {
						// Client Step
						0: {
							modalFields: {
								// Client Name
								0: {
									value: {
										$set: action.payload.json.name
									}
								},
								1: {
									value: {
										$set: action.payload.json.clientType
									}
								},
								2: {
									value: {
										$set: action.payload.json.industryId
									}
								},
								3: {
									value: {
										$set: action.payload.json.website
									}
								}
							}
						},
						// Location Step
						1: {
							modalFields: {
								// Client Name
								0: {
									value: {
										$set: action.payload.json.address
									}
								},
								1: {
									value: {
										$set: action.payload.json.address2
									}
								},
								2: {
									value: {
										$set: action.payload.json.city
									}
								},
								3: {
									value: {
										$set: action.payload.json.zip
									}
								},
								4: {
									value: {
										$set: action.payload.json.state
									}
								},
								5: {
									value: {
										$set: action.payload.json.countryId ? action.payload.json.countryId : 218
									}
								}
							}
						},
						// Contact Step
						2: {
							modalFields: {
								// Main Contact
								0: {
									value: {
										$set: action.payload.json.mainContactId
									},
									options: {
										$set: [
											{
												key: action.payload.json.mainContactId,
												text: action.payload.json.mainContactName,
												value: action.payload.json.mainContactId
											}
										]
									}
								},
								// Account Manager
								1: {
									value: {
										$set: action.payload.json.accountExecutiveId
									},
									options: {
										$set: [
											{
												key: action.payload.json.accountExecutiveId,
												text: action.payload.json.accountExecutiveName,
												value: action.payload.json.accountExecutiveId
											}
										]
									}
								},
								// Lead Source
								2: {
									value: {
										$set: action.payload.json.leadSourceId
									}
								}
							}
						}
					}
				}
			});
			break;
	}

	return state;
};

const baseModalSteps = [
	{
		name: 'Client',
		active: true,
		description: 'client description',
		icon: {name: 'user', color: 'black'},
		modalFields: [
			{
				name: 'Name',
				value: '',
				input: true,
				error: false,
				disabled: true,
				required: true
			},
			{
				name: 'Type',
				value: null,
				placeholder: 'Choose client type',
				options: [
					{
						key: 'End User',
						text: 'End User',
						value: 'End User'
					},
					{
						key: 'Re-Seller',
						text: 'Re-Seller',
						value: 'Re-Seller'
					}
				],
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Industry',
				value: null,
				placeholder: 'Choose client industry',
				options: industryOptions,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Website',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			}
		]
	},
	{
		name: 'Location',
		active: false,
		icon: {
			name: 'map marker alternate',
			color: 'black'
		},
		description: 'location description',
		modalFields: [
			{
				name: 'Address',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Address 2',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false
			},
			{
				name: 'City',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'Zip Code',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true
			},
			{
				name: 'State',
				value: null,
				placeholder: 'Choose state',
				options: stateOptions,
				error: false,
				disabled: false,
				upward: true,
				required: true
			},
			{
				name: 'Country',
				value: 218,
				placeholder: 'Choose country',
				options: countryOptions,
				error: false,
				disabled: false,
				upward: true,
				required: true
			}
		]
	},
	{
		name: 'Contact',
		description: 'contact description',
		active: false,
		icon: {
			name: 'phone',
			color: 'black'
		},
		modalFields: [
			{
				name: 'Main Contact',
				value: '',
				input: false,
				searchMC: true,
				options: null,
				error: false,
				disabled: false
			},
			{
				name: 'Account Executive',
				value: null,
				input: false,
				error: false,
				disabled: false,
				searchAE: true,
				options: null,
				loading: false,
				required: true
			},
			{
				name: 'Lead Source',
				value: null,
				input: false,
				error: false,
				disabled: false,
				options: leadSourceOptions,
				upward: true,
				required: true
			}
		]
	}
];
