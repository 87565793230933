import React from 'react';
import {connect} from 'react-redux';
import {Button, Popup} from 'semantic-ui-react';

import * as actions from '../../../../services/workRecordActions.js';

let AddOrderGroupButton = (props) => (
	<Popup
		trigger={
			<Button
				color="green"
				floated="right"
				icon="plus"
				size="medium"
				style={{
					margin: 0,
					borderRadius: '.21428571rem .21428571rem 0 0',
					padding: '1em'
				}}
				// Toggles add order group modal with flag for creation
				onClick={() => {
					props.toggleAddOrderGroupModal('create');
				}}
			/>
		}
		position="left center"
		content="Add Order Group"
	/>
);

export default connect(null, actions)(AddOrderGroupButton);
