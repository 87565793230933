/**
 * Good for making sure floats dont add wrong
 * @param {number} val value to round
 * @param {int} decimals decimals to round to
 * @returns {float} rounded value
 */
export function roundFloat(val: number, decimals: number) {
	const shift = Math.pow(10, decimals);
	val *= shift;
	val = Math.round(val);
	val /= shift;
	return val;
}

export function safeParseNumber(num: string) {
	const parsed = parseInt(num, 10);
	if (isNaN(parsed)) {
		return 0;
	}
	return parsed;
}
