import {Button, Popup} from 'semantic-ui-react';
//import PropTypes from 'prop-types';
import React from 'react';
import optionFactory from './optionFactory.js';

const SaveButton = (props) => (
	<Popup
		size={'mini'}
		trigger={
			<Button
				disabled={props.task.completed}
				type="button"
				icon
				color={'green'}
				onClick={() => {
					switch (props.task.id) {
						case null:
							props.createTask(
								props.mode,
								optionFactory(props),
								props.task,
								props.mode === 'templateManager'
									? props.tasks.currentTemplate
									: props.tasks.aasdis[props.aasdiIndex].taskGroups[props.groupIndex]
							);
							break;

						default:
							//console.log('PROPS', props);
							props.saveChanges(props.mode, optionFactory(props), props.task);
							break;
					}
				}}
			>
				Save
			</Button>
		}
		content={props.task.isSection ? 'Save changes to this section' : 'Save changes to this task'}
	/>
);

export default SaveButton;
