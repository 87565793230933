import {List, Form} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

const SelectedChoiceTaskType = (props) => {
	return props.task.id
		? [
				`${props.column.charAt(0).toUpperCase()}${props.column.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')}: `,
				<List key={`${props.column}-list`} horizontal>
					{props.task[props.column]
						? props.task[props.column].map((links, index) => (
								<List.Item
									as="a"
									target="_blank"
									href={links}
									key={`${props.column}-listItem`}
									content={`${links.substring(0, 30)}...`}
								/>
						  ))
						: null}
				</List>
		  ]
		: null;
};

export default SelectedChoiceTaskType;
