import React from 'react';
import {Label, Input} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {updateCreateWorkOrderModal} from '../../../../services/createWorkOrderActions.js';

import CreateNewLocationModal from '../../../../../../../../components/createLocationModal/createLocationModal.js';
import CreateNewBillingModal from '../../../../../../../../components/billingContactModal/billingContactModal.js';
// import ContactConfirmationModal from '../../../../../../../../components/contactConfirmationModal/contactConfirmationModal.js';

// components
import AutofillData from './component/autofillData.js';
import CreateNewButton from './component/createNewButton.js';
import CreateNewPopup from './component/createNewPopup.js';
import Datalist from './component/dataList.js';

const InputField = (props) => (
	<div>
		<div
			style={{
				paddingTop: '1.2em'
			}}
		>
			<Label
				style={{
					backgroundColor: 'transparent',
					fontSize: '1rem',
					color: 'rgba(0,0,0,.8)',
					paddingLeft: '0'
				}}
			>
				{props.field.name}
			</Label>
		</div>
		{props.field.newButton ? (
			<div>
				<div>
					<CreateNewButton
						field={props.field}
						sectionIndex={props.sectionIndex}
						fieldIndex={props.fieldIndex}
						clientId={props.clientId}
					/>
					<CreateNewPopup field={props.field} clientId={props.clientId} />
					<AutofillData field={props.field} />
				</div>
			</div>
		) : (
			<div>
				<DebounceInput
					element={Input}
					onChange={(e) => {
						props.field.name === 'Client'
							? props.updateCreateWorkOrderModal(
									'createWorkOrderModal',
									props.sectionIndex,
									props.fieldIndex,
									e.target.value,
									false,
									'Client'
							  )
							: props.updateCreateWorkOrderModal(
									'createWorkOrderModal',
									props.sectionIndex,
									props.fieldIndex,
									e.target.value,
									false
							  );
					}}
					debounceTimeout={600}
					value={props.field.value}
					style={{
						width: '100%'
					}}
					error={props.field.required || props.field.checkForm ? Boolean(props.field.error) : false}
					disabled={props.field.disabled}
					placeholder={props.field.placeholder}
					loading={props.field.loading}
				/>
				{props.field.name === 'Purchase Order Number' ? (
					props.field.error ? (
						<Label color="red" style={{position: 'relative'}} pointing="above">
							{'Purchase Order Number should be unique'}
						</Label>
					) : null
				) : null}
			</div>
		)}
		{/* <ContactConfirmationModal /> */}
		<CreateNewLocationModal type={'workOrder'} />
		<CreateNewBillingModal type={'workOrder'} />
	</div>
);

InputField.propTypes = {
	field: PropTypes.object,
	sectionIndex: PropTypes.number,
	fieldIndex: PropTypes.number,
	clientId: PropTypes.number,
	updateCreateWorkOrderModal: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'workOrderOverviewSection'
		),
		sectionFields: state.work.createWorkOrder.createWorkOrderModal.sections.filter(
			(section) => section.name === 'workOrderOverviewSection'
		)[0].fields,
		clientId: state.work.createWorkOrder.createWorkOrderModal.sections[1].fields[0].value
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCreateWorkOrderModal: (modal, sectionIndex, fieldIndex, value, error, inputType, data) => {
			dispatch(updateCreateWorkOrderModal(modal, sectionIndex, fieldIndex, value, error, inputType, data));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InputField);
