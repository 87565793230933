import './navBar.scss';

import {Menu, Icon, Dropdown, Image, Popup} from 'semantic-ui-react';
import {NavLink} from 'react-router-dom';
import Logo from '../../img/logo-white.png';

import {signOut} from '~/scenes/auth/services/authActions.ts';
import Link from '~/components/Link.jsx';

import {useSelector, useDispatch} from 'react-redux';
import {useAppState} from '~/hooks/useAppState';

export function NavBar(props) {
	const {notifications} = props;
	const {firstName, lastName} = useSelector((state) => state.auth.account ?? {});
	const profileImage = useSelector((state) => state.auth.profileImage);
	const dispatch = useDispatch();

	const [appState, updateAppState] = useAppState();
	// const openBugReportModal = () =>
	// 	updateAppState((draft) => {
	// 		draft.reportBugModalOpen = true;
	// 	});

	return (
		<Menu className="omni-navbar" size="large" fixed="top">
			<Menu.Item>
				<Image size="mini" circular src={Logo} />
			</Menu.Item>
			<Menu.Item as={NavLink} to="/crm/dashboard/pipeline/grid-view">
				<Icon name="folder open outline" size="large" style={{marginRight: 5}} /> CRM
			</Menu.Item>
			<Menu.Item as={NavLink} to="/work/dashboard/work/grid">
				<Icon name="handshake outline" size="large" style={{marginRight: 10}} /> Work
			</Menu.Item>
			<Menu.Item as={NavLink} to="/accountingConsole/view">
				<Icon name="dollar sign" size="large" /> Approvals
			</Menu.Item>
			<Dropdown
				item
				trigger={
					<>
						<Icon name="file alternate" size="large" style={{marginRight: 5}} /> Quick Links{' '}
					</>
				}
			>
				<Dropdown.Menu>
					<Dropdown
						item
						// using link to nothing for consistent styling in dropdown
						trigger={<Link text="Employee" />}
						className="navSubDropdown"
					>
						<Dropdown.Menu className="subDropdownPopOutSpacing">
							<Dropdown.Item
								text="Employee Center"
								as="a"
								href="https://hexagonsi.service-now.com/ec"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="ASD Directory"
								as="a"
								href="https://asd-usa-company.monday.com/boards/4155304716"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Pursue Knowledge"
								as="a"
								href="https://asd-usa-company.monday.com/boards/3693085772/"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="System Bug & Improvement Request"
								as="a"
								href="https://hexagonsi.service-now.com/ec?id=esc_sc_category&sys_id=487c49411b993550a74e766e034bcbee&catalog_id=-1&spa=1"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Lessonly (LMS)"
								as="a"
								href="https://asd.lessonly.com/"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Monday.com"
								as="a"
								href="https://asd-usa-company.monday.com/"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown
						item
						// using link to nothing for consistent styling in dropdown
						trigger={<Link text="Sales" />}
						className="navSubDropdown"
					>
						<Dropdown.Menu className="subDropdownPopOutSpacing">
							<Dropdown.Item
								text="Request a Project Manager"
								as="a"
								href="https://app.hubspot.com/login"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Set up a New Client"
								as="a"
								href="https://wkf.ms/40Esv9N"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Engineering Request Form"
								as="a"
								href="https://www.hubspot.com"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Vendor Subscriptions"
								as="a"
								href="https://wkf.ms/3oNCOdu"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Marketing Request"
								as="a"
								href="https://forms.monday.com/forms/c0170bd68b5690d446e7da021d93d6f9?r=use1"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown
						item
						// using link to nothing for consistent styling in dropdown
						trigger={<Link text="Operations" />}
						className="navSubDropdown"
					>
						<Dropdown.Menu className="subDropdownPopOutSpacing">
							<Dropdown.Item
								text="Request Post Sales Help"
								as="a"
								href="https://www.hubspot.com"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Certificate of Insurance"
								as="a"
								href="https://docs.google.com/forms/d/e/1FAIpQLSfzVdfEiuG9krDFCHjQOXD-z-DtCrBdz2lcSKDV_tfh_Co8TA/viewform"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Technical Request (Coming Soon!)"
								as="a"
								// href="https://wkf.ms/3nFnr6b"
								// target="_blank"
								// rel="noreferrer noopener"
								disabled={true}
								// className="docsLink"
							/>
							<Dropdown.Item
								text="PO/Invoice Issue Log"
								as="a"
								href="https://asd-usa-company.monday.com/boards/4242072718/"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown
						item
						// using link to nothing for consistent styling in dropdown
						trigger={<Link text="Vendor" />}
						className="navSubDropdown"
					>
						<Dropdown.Menu className="subDropdownPopOutSpacing">
							<Dropdown.Item
								text="Vendors Listing"
								as="a"
								href="https://asd-usa-company.monday.com/boards/2179379013"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="New AASDI Request"
								as="a"
								href="https://wkf.ms/3XsdTt7"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
							<Dropdown.Item
								text="Special Payment Request"
								as="a"
								href="https://wkf.ms/3zzUNpT"
								target="_blank"
								rel="noreferrer noopener"
								className="docsLink"
							/>
						</Dropdown.Menu>
					</Dropdown>
				</Dropdown.Menu>
			</Dropdown>
			<Menu.Menu position="right">
				{/* <Popup
					content="Report a bug"
					trigger={
						<Menu.Item onClick={openBugReportModal} active={appState.reportBugModalOpen}>
							<Icon name="bug" size="large" />
						</Menu.Item>
					}
				/> */}

				{/* <NotificationFeed /> */}

				<Dropdown
					item
					icon={null}
					trigger={
						<>
							<span>
								{firstName} {lastName}
							</span>
							{profileImage ? (
								<Image style={{marginLeft: 10}} size="mini" circular src={profileImage} />
							) : undefined}
						</>
					}
				>
					<Dropdown.Menu>
						<Dropdown.Item disabled as={NavLink} to="/user/settings">
							Settings
						</Dropdown.Item>
						<Dropdown.Item onClick={() => dispatch(signOut())}>Logout</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Menu>
		</Menu>
	);
}
