import customFetch from '../../../lib/old/customFetch.js';

export const getContacts = (type, typeId) => async (dispatch) => {
	dispatch({
		type: 'CLEAR_CONTACTS'
	});
	dispatch({
		type: 'UPDATE_CONTACTS',
		payload: {
			value: true,
			column: 'loading'
		}
	});

	if (type !== 'newWork' && type !== 'newProject') {
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts/section/${type}/${typeId}`;

		console.log(endpoint);

		const contactsFetch = await customFetch(endpoint);
		let contactsFetchjson = await contactsFetch.json();

		if (contactsFetch.status !== 200) {
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					value: contactsFetchjson,
					column: 'error'
				}
			});
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					value: false,
					column: 'loading'
				}
			});

			return Promise.reject(contactsFetchjson);
		} else {
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					column: 'contacts',
					value: contactsFetchjson
				}
			});
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					value: false,
					column: 'loading'
				}
			});
		}
	} else {
		if (type === 'newWork' || type === 'newProject') {
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					column: 'contacts',
					value: []
				}
			});
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					value: false,
					column: 'loading'
				}
			});
		}
	}
};

export const deleteContact = (type, typeId, id, name, index) => async (dispatch) => {
	if (type !== 'newWork' && type !== 'newProject') {
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/contacts/section/${type}/${id}?delete=byId`;

		console.log(endpoint);

		const contactsDelete = await customFetch(endpoint, {
			method: 'DELETE',
			body: JSON.stringify({
				id,
				name,
				typeId
			})
		});
		let contactsDeletejson = await contactsDelete.json();
		dispatch({
			type: 'UPDATE_CONTACTS',
			payload: {
				value: true,
				column: 'loading'
			}
		});
		if (contactsDelete.status !== 200) {
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					value: contactsDeletejson,
					column: 'error'
				}
			});
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					value: false,
					column: 'loading'
				}
			});
		} else {
			dispatch({
				type: 'UPDATE_CONTACTS_DELETE_ITEM',
				payload: {
					index
				}
			});
			dispatch({
				type: 'UPDATE_CONTACTS',
				payload: {
					value: false,
					column: 'loading'
				}
			});
		}
	}
};

export const addContact = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_CONTACTS_ADD_ITEM',
			payload: {
				data
			}
		});
	};
};

export const updateContactItem = (payload) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_CONTACTS_ITEM',
			payload
		});
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_CONTACTS',
			payload: {
				value: value,
				column: 'editError'
			}
		});
	};
};
