// TODOS
// Remove inline styling
import {Table, Icon, Menu, Breadcrumb, Message} from 'semantic-ui-react';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {retrieveTemplates, createTemplate} from './services/TaskTemplates.actions.js';
import {
	selectTemplates,
	selectTemplatesLoading,
	selectTemplatesBeingRetrieved,
	selectErrorLoadingTemplates
} from './services/TaskTemplates.selectors.js';
import * as taskTemplateService from './services/TaskTemplates.service.js';
import TemplateRow from './components/TemplateRow.jsx';
import LoadingSpinner from './components/LoadingSpinner.jsx';

import WorkNav from '../work/components/workNav.js';

class Templates extends Component {
	constructor() {
		super();
	}

	componentDidMount() {
		const {templatesBeingRetrieved} = this.props;
		taskTemplateService.retrieveTemplatesIfNeeded(templatesBeingRetrieved, this.props.retrieveTemplates);
	}

	render() {
		const {templates, templatesLoading, errorLoadingTemplates} = this.props;

		if (templatesLoading) {
			return (
				<div>
					<WorkNav />
					<LoadingSpinner loadingMessage="Retrieving templates..." />
				</div>
			);
		} else if (errorLoadingTemplates) {
			return (
				<div style={{display: 'flex'}}>
					{/* this div creates a position:static html element to wrap the position:fixed worknavbar
					helps it to fit with the UI better */}
					<div style={{width: '15rem'}}>
						<WorkNav />
					</div>
					<div style={{maxWidth: 600}}>
						<Message icon error size="small" style={{marginTop: 100}}>
							<Icon name="x" />
							<Message.Content>
								An error occured while loading task templates. Try refreshing the page or report a bug
								if the problem persists.
							</Message.Content>
						</Message>
					</div>
				</div>
			);
		}

		return (
			<div style={{display: 'flex'}}>
				{/* this div creates a position:static html element to wrap the position:fixed worknavbar
					helps it to fit with the UI better */}
				<div style={{width: '15rem'}}>
					<WorkNav />
				</div>
				<div id="task-templates-table" style={{width: '80%', marginTop: '4.5rem'}}>
					<Menu style={{margin: '1em 0'}} borderless icon="labeled" size="mini">
						<Menu.Item>
							<Breadcrumb size="large" style={{paddingTop: '0.53rem'}}>
								<Breadcrumb.Section active>Templates</Breadcrumb.Section>
							</Breadcrumb>
						</Menu.Item>

						<Menu.Item position="right" />

						<Menu.Item onClick={() => this.props.createTemplate()}>
							<Icon name="list layout" />
							Create Template
						</Menu.Item>
					</Menu>

					<Table sortable selectable striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell />
								<Table.HeaderCell>Template Name</Table.HeaderCell>
								<Table.HeaderCell>Description</Table.HeaderCell>
								<Table.HeaderCell>Customer</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{templates.map((template, index) => (
								<TemplateRow index={index} template={template} />
							))}
						</Table.Body>
					</Table>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	templates: selectTemplates(state),
	templatesLoading: selectTemplatesLoading(state),
	templatesBeingRetrieved: selectTemplatesBeingRetrieved(state),
	errorLoadingTemplates: selectErrorLoadingTemplates(state)
});

const mapDispatchToProps = {
	retrieveTemplates,
	createTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
