import React from 'react';
import {connect} from 'react-redux';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {Label, Icon} from 'semantic-ui-react';
import WorkOrderPDF from './WorkOrderPDF.js';

class WorkOrderPDFLabel extends React.Component {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps) {
		const {tripInfo, loading} = this.props;
		if (
			loading === nextProps.loading &&
			this.isEmpty(tripInfo.specInst) === this.isEmpty(nextProps.tripInfo.specInst) &&
			this.isEmpty(tripInfo.requestDate) === this.isEmpty(nextProps.tripInfo.requestDate) &&
			this.isEmpty(tripInfo.aasdiSow) === this.isEmpty(nextProps.tripInfo.aasdiSow)
		) {
			return false;
		}
		return true;
	}

	isEmpty = (str) => {
		return !str || 0 === str.length;
	};

	render() {
		const {loading, workOverview} = this.props;
		if (!loading) {
			return (
				<PDFDownloadLink
					document={<WorkOrderPDF {...this.props} />}
					fileName={`${workOverview.workNumber}.pdf`}
				>
					<Label color="blue" attached="top right" style={{marginBottom: '2vh'}}>
						<Icon name="file pdf outline" />
						{'Download'}
					</Label>
				</PDFDownloadLink>
			);
		} else {
			return (
				<Label color="blue" attached="top right" style={{marginBottom: '2vh'}}>
					<Icon name="file pdf outline" />
					{'Loading...'}
				</Label>
			);
		}
	}
}

export default connect(null, null)(WorkOrderPDFLabel);
