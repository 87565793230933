import React from 'react';
import {
	Segment,
	Divider,
	Label,
	Message,
	List,
	Button,
	Icon,
	Popup,
	Dimmer,
	Header,
	Container
} from 'semantic-ui-react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getContacts, closeDimmer, deleteContact} from './services/contactsSectionActions.js';

import {removeFromTempWorkOrderContacts} from '../../scenes/work/scenes/CreateWorkOrder/services/createWorkOrderActions.js';
import {removeFromTempCreateProjectContacts} from '../../scenes/crm/scenes/dashboard/scenes/CreateProject/services/createProjectActions.js';

import ContactModal from '../contactModal/contactModal.js';
import {toggleContactModal} from '../contactModal/services/contactModalActions.js';

// requires type and id
// Example
//
//		<ContactsSection
//			type={'work'}
//			typeId={workId}
//		/>
//
//	possible type: work, opportunity, trip, newWork, newProject
//  type/ID pairs: (work = workId), (opportunity = pipelineId or OpportunityId), (trip = workAasdiTripId), (newWork or newProject = 'none')

let type;
class Contacts extends React.Component {
	componentDidMount() {
		type = this.props.type;
		if (this.props.typeId) {
			this.props.getContacts(this.props.type, this.props.typeId);
		}
	}
	render() {
		return (
			<div>
				<Segment loading={this.props.loading}>
					{this.props.editError ? (
						<Dimmer inverted active className="centerDimmer">
							<Header as="h4">
								<div style={{paddingBottom: '.5em', color: 'black'}}>Contact Edit Failed</div>
								<div style={{color: 'black'}}>Please try again.</div>
								<Button
									onClick={() => {
										this.props.closeDimmer(null);
									}}
									style={{marginTop: '1.5em'}}
								>
									Close Message
								</Button>
							</Header>
						</Dimmer>
					) : null}

					{(this.props.type === 'newWork' || this.props.type === 'newProject') &&
					!Number.isInteger(this.props.clientId) ? (
						<Container>
							<Label color="blue" ribbon>
								Contacts
							</Label>
							<Divider />
							<Message color="blue" style={{textAlign: 'center'}}>
								Please Select a Client.
							</Message>
						</Container>
					) : (
						<Container>
							<Label color="blue" ribbon>
								Contacts
							</Label>
							<Button
								basic
								color="blue"
								content="Add"
								icon="add"
								size="tiny"
								floated="right"
								onClick={() => {
									this.props.toggleContactModal(
										null,
										this.props.type,
										this.props.typeId,
										null,
										null,
										null,
										null
									);
								}}
							/>
							<Divider />

							{/* Give Conditional Logic some spacing so it's easier to read as blocks */}
							{!this.props.contacts.length && (
								<Message color="blue" style={{textAlign: 'center'}}>
									No Contacts added yet.
								</Message>
							)}

							{
								<List>
									{this.props.error ? (
										<Segment textAlign="center" basic>
											Hmmm, it looks like something went wrong when I was trying to pull this
											data. Try again, or contact the Dev team if it keeps happening.
											<Segment basic style={{paddingBottom: '0'}} />
										</Segment>
									) : (
										this.props.contacts.map((contact, index) => (
											<List.Item key={index}>
												<List.Content
													style={{
														width: '100%'
													}}
												>
													<Avatar
														name={contact.fullName}
														size="34"
														color="blue"
														round={true}
														style={{
															marginRight: '.8em',
															verticalAlign: 'top'
														}}
													/>
													<div
														style={{
															display: 'inline-block'
														}}
													>
														<List.Header>{contact.fullName}</List.Header>
														{contact.title ? contact.title : null}
														{contact.isPrimary ? (
															<Popup
																trigger={
																	<Icon
																		name="star"
																		color="yellow"
																		style={{
																			marginLeft: '1vw'
																		}}
																	/>
																}
																content="Primary Contact"
															/>
														) : null}
													</div>
													<List.Description>
														<List relaxed>
															{contact.company && (
																<List.Item icon="users" content={contact.company} />
															)}
															{/* {contact.location && (
														<List.Item
															icon="marker"
															content="Atlanta, GA"
														/>
													)} */}
															{contact.phone && (
																<List.Item
																	icon="phone"
																	content={
																		contact.extension
																			? `${contact.phone} (${contact.extension})`
																			: contact.phone
																	}
																/>
															)}
															{contact.email && (
																<List.Item
																	icon="mail"
																	content={
																		<a href={`mailto:${contact.email}`}>
																			{contact.email}
																		</a>
																	}
																/>
															)}
															<List.Item>
																<List.Content>
																	<div>
																		{this.props.type === 'newWork' ||
																		this.props.type === 'newProject' ? (
																			contact.existing ? (
																				<a>
																					<Icon color="red" name="edit" />
																					uneditable until
																					{this.props.type === 'newWork'
																						? ' work order '
																						: ' project '}
																					is created
																				</a>
																			) : (
																				<a
																					onClick={() => {
																						this.props.toggleContactModal(
																							null,
																							this.props.type,
																							this.props.typeId,
																							null,
																							'edit',
																							contact,
																							index
																						);
																					}}
																				>
																					<Icon name="edit" />
																					Edit Contact
																				</a>
																			)
																		) : (
																			<a
																				onClick={() => {
																					this.props.toggleContactModal(
																						null,
																						this.props.type,
																						this.props.typeId,
																						null,
																						'edit',
																						contact,
																						index
																					);
																				}}
																			>
																				<Icon name="edit" />
																				Edit Contact
																			</a>
																		)}
																		<Popup
																			trigger={
																				<Icon
																					name="trash"
																					style={{
																						float: 'right',
																						cursor: 'pointer'
																					}}
																					onClick={() => {
																						if (
																							this.props.type !==
																								'newWork' &&
																							this.props.type !==
																								'newProject'
																						) {
																							this.props.deleteContact(
																								this.props.type,
																								this.props.typeId,
																								contact.recordContactsId,
																								contact.fullName,
																								index
																							);
																						} else if (
																							this.props.type ===
																							'newWork'
																						) {
																							this.props.removeFromTempWorkOrderContacts(
																								index
																							);
																						} else if (
																							this.props.type ===
																							'newProject'
																						) {
																							this.props.removeFromTempCreateProjectContacts(
																								index
																							);
																						}
																					}}
																				/>
																			}
																			content={'Remove Contact'}
																			position="left center"
																		/>
																	</div>
																</List.Content>
															</List.Item>
														</List>
													</List.Description>
												</List.Content>
												{/* Don't show a Divider after the last contact */}
												{this.props.contacts[this.props.contacts.length - 1] !== contact && (
													<Divider />
												)}
											</List.Item>
										))
									)}
								</List>
							}
						</Container>
					)}
				</Segment>
				<ContactModal clientId={this.props.clientId} />
			</div>
		);
	}
}

Contacts.propTypes = {
	type: PropTypes.string,
	loading: PropTypes.bool,
	typeId: PropTypes.string,
	getContacts: PropTypes.func,
	editError: PropTypes.bool,
	closeDimmer: PropTypes.func,
	toggleContactModal: PropTypes.func,
	error: PropTypes.bool,
	contacts: PropTypes.array,
	deleteContact: PropTypes.func,
	removeFromTempWorkOrderContacts: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		contacts:
			type !== 'newWork' && type !== 'newProject'
				? state.global.contactsSection.contacts
					? state.global.contactsSection.contacts
					: []
				: type === 'newWork'
				? state.work.createWorkOrder.createWorkOrderModal.displayContacts
				: state.crm.createProject.createProjectModal.displayContacts,
		loading: state.global.contactsSection.loading,
		editError: state.global.contactsSection.editError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleContactModal: (id, type, typeId, place, option, contactData, rowIndex) => {
			dispatch(toggleContactModal(id, type, typeId, place, option, contactData, rowIndex));
		},
		getContacts: (type, id) => {
			dispatch(getContacts(type, id));
		},
		closeDimmer: (value) => {
			dispatch(closeDimmer(value));
		},
		deleteContact: (type, typeId, id, name, index) => {
			dispatch(deleteContact(type, typeId, id, name, index));
		},
		removeFromTempWorkOrderContacts: (index) => {
			dispatch(removeFromTempWorkOrderContacts(index));
		},
		removeFromTempCreateProjectContacts: (index) => {
			dispatch(removeFromTempCreateProjectContacts(index));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
