import React from 'react';
import {Button, Modal, Icon, Header, Dimmer, Loader, Segment, Label, Grid, List} from 'semantic-ui-react';
import _ from 'lodash';
import {connect} from 'react-redux';

import {toggleCreateProjectModal, createProject, closeDimmer} from './services/createProjectActions.js';

import OpportunitySection from './components/opportunitySection/opportunitySection.js';
import AGGridSection from './components/agGridSection/agGridSection.js';
import BillingDetailsSection from './components/billingDetailsSection/billingDetailsSection.js';
import DocumentsSection from './components/documentsSection/documentsSection.js';
import ActivityStream from '../../../../../../components/activityStream/ActivityStream.js';
import ContactsSection from '../../../../../../components/contactsSection/contactsSection.js';

const CreateProjectModal = (props) => (
	<Modal open={props.createProjectModalOpen} size="fullscreen">
		{props.loading ? <CreateProjectModalLoading reason={props.loading} closeDimmer={props.closeDimmer} /> : null}
		<Header>
			<Icon name="plus" color={props.iconError ? 'red' : 'green'} />
			<div className="content">Create Project</div>
		</Header>
		<Modal.Content scrolling={true}>
			<Segment style={{paddingBottom: '2em'}}>
				<Label attached="top" color="blue">
					{'Project Details'}
				</Label>
				<OpportunitySection />
				<AGGridSection />
				<BillingDetailsSection />
			</Segment>
			<DocumentsSection />
			<Grid>
				<Grid.Row>
					<Grid.Column width={10}>
						<ActivityStream
							type="opportunity"
							id={props.id}
							options={[
								{
									key: 'note',
									text: 'Note',
									value: 'Note'
								},
								{
									key: 'phoneCall',
									text: 'Phone Call',
									value: 'Phone Call'
								},
								{
									key: 'quote',
									text: 'Quote',
									value: 'Quote'
								},
								{
									key: 'voicemail',
									text: 'Voicemail',
									value: 'Voicemail'
								}
							]}
						/>
					</Grid.Column>
					<Grid.Column width={6}>
						<ContactsSection type="newProject" typeId="none" clientId={props.clientId} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleCreateProjectModal();
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					onClick={() => {
						props.createProject();
					}}
					color="green"
				>
					<Icon name="checkmark" /> Create
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

function CreateProjectModalLoading(props) {
	const reason = props.reason;

	return (
		<Dimmer inverted active className="centerDimmer">
			{reason === 'start' ? (
				<Loader size="big">Fetching Project Info</Loader>
			) : reason === 'progress' ? (
				<Loader size="big">Creating Project</Loader>
			) : reason === 'poAmount' ? (
				<CreateProjectModalError></CreateProjectModalError>
			) : _.isArray(reason) ? (
				<CreateProjectModalError closeDimmer={props.closeDimmer}>
					{reason.map((r) => {
						switch (r) {
							case 'poAmount':
								return 'Project order amount must equal total project revenue in billing details grid.';
							case 'noApprovalDoc':
								return 'Cannot create project without purchase order document.';
							case 'noActiveTechnologies':
								return 'Must have at least one active technology';
							default:
								throw new Error('Uknown error code: ' + r);
						}
					})}
				</CreateProjectModalError>
			) : (
				// Error handling
				<Header as="h3" icon>
					<Icon name="cancel" color="red" />
					<div style={{paddingBottom: '.5em', color: 'black'}}>Project Creation Failed</div>
					<div style={{color: 'black'}}>Please try again.</div>
					<Button
						onClick={() => {
							props.closeDimmer(null);
						}}
						style={{marginTop: '1.5em'}}
					>
						Close Message
					</Button>
				</Header>
			)}
		</Dimmer>
	);
}

function CreateProjectModalError(props) {
	const error = props.error;
	return (
		<Header as="h3" icon>
			<Icon name="cancel" color="red" />
			<div style={{paddingBottom: '.5em'}}>
				<List>
					{props.children.map((msg, i) => (
						<List.Item key={i} style={{color: 'black'}}>
							{msg}
						</List.Item>
					))}
				</List>
			</div>
			<Button
				onClick={() => {
					props.closeDimmer(null);
				}}
				style={{marginTop: '1.5em'}}
			>
				Close Message
			</Button>
		</Header>
	);
}

const mapStateToProps = (state) => {
	return {
		id: state.crm.createProject.createProjectModal.id,
		createProjectModalOpen: state.crm.createProject.createProjectModal.toggleCreateProjectModal,
		loading: state.crm.createProject.createProjectModal.loading,
		clientId: state.crm.createProject.createProjectModal.clientId,
		iconError: state.crm.createProject.createProjectModal.iconError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleCreateProjectModal: (
			id,
			place,
			oppName,
			clientName,
			accountExecId,
			accountExecName,
			poAmount,
			serviceType,
			branchName
		) => {
			dispatch(
				toggleCreateProjectModal(
					id,
					place,
					oppName,
					clientName,
					accountExecId,
					accountExecName,
					poAmount,
					serviceType,
					branchName
				)
			);
		},
		createProject: () => {
			dispatch(createProject());
		},
		closeDimmer: (state) => {
			dispatch(closeDimmer(state));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectModal);
