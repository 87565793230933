import api from "~/lib/api"

// endpoint is the path you are trying to hit. Do not include process.env.REACT_APP_API_URL
// start is either "first"(when you are selecting from oldest to newest), "last"(when you are selecting from newest to oldest), or a number (the number of the id that you want to start selecting at, normally only used after the first loop of this function)
// limit is the number of records you want to be returned
// addRows is a callback function that adds the new records returned by the function to where ever you're displaying it on the front end
// passthroughObj the arguments that are passed to the callback function
// the backend will need to be updated for any endpoint you are trying to use this one
export const pagingGet = (endpoint: string, start: string | number, limit: number, addRows:Function, passThroughObj: {}) => {
	let lastId:number;
	if (typeof start === 'number') {
		lastId = start;
	} else {
		lastId = start === 'first' ? 0 : 999999999;
	}
	return async (dispatch: any ) => {
		const data = await api.get(`${endpoint}&lastId=${lastId}&limit=${limit}`);
		if (data.done) {
			dispatch(addRows(data.rows, passThroughObj));
			return;
		} else {
			const again = dispatch(addRows(data.rows, passThroughObj));
			if (again) {
				await dispatch(pagingGet(endpoint, data.rows[data.rows.length - 1].id, limit, addRows, passThroughObj));
			}
		}
	}
}