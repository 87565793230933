import React from 'react';
import {Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const IconButton = (props) => (
	<Icon
		name={props.name}
		color={props.color}
		size="large"
		style={{cursor: 'pointer'}}
		onClick={() => props.onClick(props.node.rowIndex)}
	/>
);

IconButton.propTypes = {
	// can't just use props.rowIndex here, ag-grid doesn't update it properly
	node: PropTypes.shape({
		rowIndex: PropTypes.number.isRequired
	}).isRequired,
	onClick: PropTypes.func.isRequired,
	icon: PropTypes.string.isRequired,
	color: PropTypes.string
};

export default IconButton;
