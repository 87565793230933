import React from 'react';
import {Label, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';

import * as actions from '../../../../../../services/workRecordActions.js';

import {debounce, debounceEventHandler} from '../../../../../../../../../../../../lib/old/debounce.js';

const AddOrderGroupDropdownField = (props) => (
	<div style={{paddingTop: '0', flexGrow: 1, margin: '0 1rem'}}>
		<Label
			style={{
				backgroundColor: 'transparent',
				fontSize: '1rem',
				color: 'rgba(0,0,0,.8)',
				paddingLeft: '0'
			}}
		>
			{props.field.name}
		</Label>
		<Dropdown
			className="createWorkOrderClientDropdown"
			placeholder={props.field.placeholder}
			fluid
			search
			selection
			disabled={props.type !== 'create'}
			options={props.field.options}
			onChange={(e, {value}) => {
				props.addOrderGroupModalUpdateColumn(props.fieldIndex, value, false);
			}}
			onSearchChange={debounceEventHandler(
				debounce((e) => {
					if (e.target.value) {
						if (props.field.searchBillingContact) {
							props.addOrderGroupModalUpdateColumnDropdown(
								'billingContact',
								props.clientId,
								props.fieldIndex,
								e.target.value
							);
						}
					}
				}, 600)
			)}
			icon={props.field.icon}
			value={props.field.value}
			error={Boolean(props.field.error)}
			// disabled={props.field.disabled}
			upward={props.field.upward}
			loading={props.field.loading}
			defaultSearchQuery={props.field.searchQuery}
		/>
	</div>
);

const mapStateToProps = (state) => {
	return {
		clientId: state.work.workRecord.view.record.clientId
	};
};

export default connect(mapStateToProps, actions)(AddOrderGroupDropdownField);
