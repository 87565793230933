import {Button, Popup, Menu, Icon} from 'semantic-ui-react';
//import PropTypes from 'prop-types';
import React from 'react';

const SaveTemplate = (props) =>
	props.isPanel ? (
		<Popup
			size={'mini'}
			trigger={
				<Button
					floated={'right'}
					type="button"
					color={'green'}
					onClick={() => {
						props.startLoader();
						!props.tasks.currentTemplate.id ? props.createTemplate() : props.saveTemplate();
					}}
				>
					<Icon name={'save'} />
					Save
				</Button>
			}
			content={'Save this template.'}
		/>
	) : (
		<Popup
			size={'mini'}
			trigger={
				<Menu.Item
					onClick={() => {
						props.startLoader();
						!props.tasks.currentTemplate.id ? props.createTemplate() : props.saveTemplate();
					}}
				>
					<Icon name={'save'} />
					Save
				</Menu.Item>
			}
			content={'Save this template.'}
		/>
	);
export default SaveTemplate;
