import React from 'react';
import {Segment} from 'semantic-ui-react';

import CreateWorkOrderButton from './components/createWorkOrderButton.js';
import WorkGrid from './components/workGrid.js';

import CreateWorkOrder from '../../scenes/CreateWorkOrder/createWorkOrder.js';
import GridHeader from '../../components/GridHeader.jsx';

import './workGridView.css';

const WorkGridView = () => (
	<Segment basic style={{marginTop: '1.5rem', padding: 0, paddingTop: '3vh'}}>
		<GridHeader message="Work Orders & Projects Grid" addButton={<CreateWorkOrderButton />} />
		<WorkGrid />
		<CreateWorkOrder />
	</Segment>
);

export default WorkGridView;
