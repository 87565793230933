import {Icon} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import optionFactory from './optionFactory.js';

const ClosePanel = (props) => (
	<Icon
		name="close"
		size="small"
		style={{float: 'right', cursor: 'pointer', paddingTop: '1rem', paddingRight: '1rem'}}
		onClick={() => {
			props.togglePanel('close', props.mode, 'task', optionFactory(props));
		}}
	/>
);

export default ClosePanel;
