import React from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const iconByclientStatusName = {
	Customer: {
		icon: 'address card',
		content: 'Customer',
		color: 'blue'
	},
	Prospect: {
		icon: 'file powerpoint',
		content: 'Prospect',
		color: 'green'
	}
};

const getIconByClientStatsName = (statusName) =>
	iconByclientStatusName[statusName] ? iconByclientStatusName[statusName] : null;

const ClientTypeCellRenderer = (props) => {
	const client = getIconByClientStatsName(props.data.clientStatusName);

	return client ? (
		<div style={{textAlign: 'center'}}>
			<Popup
				trigger={
					<Icon
						name={client.icon}
						style={{
							backgroundColor: 'transparent',
							position: 'center',
							color: client.color
						}}
						size={'large'}
					/>
				}
				content={client.content}
				on={['hover', 'click']}
				flowing
				hoverable
				position="right center"
				style={{zIndex: 1}}
			/>
		</div>
	) : null;
};

ClientTypeCellRenderer.propTypes = {
	data: PropTypes.object.isRequired
};

export default ClientTypeCellRenderer;
