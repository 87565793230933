import React from 'react';
import {connect} from 'react-redux';
import {hideWorkRecordError} from '../services/workRecordErrorActions.js';
import {selectWorkRecordErrorState} from '../services/workRecordSelectors.js';
import {Message, Icon} from 'semantic-ui-react';

function WorkRecordErrorMessage(props) {
	const {display, message, icon} = props.error;

	if (!display) {
		return null;
	}

	return (
		<Message
			className="WorkRecord-ErrorMessage"
			onDismiss={() => props.hideWorkRecordError()}
			floating
			error
			icon={Boolean(icon)}
		>
			{icon ? <Icon name={icon} /> : null}
			{message}
		</Message>
	);
}

const mapStateToProps = (state) => ({
	error: selectWorkRecordErrorState(state)
});

const mapDispatchToProps = (dispatch) => ({
	hideWorkRecordError: () => dispatch(hideWorkRecordError())
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkRecordErrorMessage);
