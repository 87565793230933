import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import * as actions from '../../../../services/workRecordActions.js';

import OrderGroupGridButtons from './orderGroupGridButtons.js';

class GroupBillableGrid extends React.Component {
	defaultColDef = {
		sortable: true,
		filter: true,
		resizable: true
	};

	columnDefs = [
		{
			headerName: 'Actions',
			cellRendererFramework: OrderGroupGridButtons,
			maxWidth: 150,
			minWidth: 150,
			width: 150
		},
		{
			headerName: 'Description',
			filter: 'agTextColumnFilter',
			field: 'groupDescription'
		},
		{
			headerName: 'Group Number',
			filter: 'agTextColumnFilter',
			field: 'workNumber'
		},
		{
			headerName: 'Status',
			filter: 'agTextColumnFilter',
			field: 'status_Name'
		},
		{
			headerName: 'Labor',
			filter: 'agTextColumnFilter',
			field: 'members',
			valueFormatter: (params) => {
				let total = 0;
				const members = params.value;
				if (members && members.length > 0) {
					members.map((member) =>
						member.bills.map((bill) => (total += parseFloat(bill.laborResell ? bill.laborResell : 0)))
					);
				}
				return '$ ' + total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
			}
		},
		{
			headerName: 'Parts',
			filter: 'agTextColumnFilter',
			field: 'members',
			valueFormatter: (params) => {
				let total = 0;
				const members = params.value;
				if (members && members.length > 0) {
					members.forEach((member) =>
						member.bills.forEach((bill) => {
							total += parseFloat(bill.materialResell ? bill.materialResell : 0);
						})
					);
				}
				return '$ ' + total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
			}
		},
		{
			headerName: 'Freight',
			filter: 'agTextColumnFilter',
			field: 'members',
			valueFormatter: (params) => {
				let total = 0;
				const members = params.value;
				if (members && members.length > 0) {
					members.map((member) => (total += parseFloat(member.freight ? member.freight : 0)));
				}
				return '$ ' + total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
			}
		},
		{
			headerName: 'Total',
			filter: 'agTextColumnFilter',
			field: 'members',
			valueFormatter: (params) => {
				let total = 0;
				const members = params.value;

				if (members && members.length > 0) {
					members.forEach((member) =>
						member.bills.forEach((bill) => {
							total += parseFloat(bill.laborResell ? bill.laborResell : 0);
							total += parseFloat(bill.freight ? bill.freight : 0);
							total += parseFloat(bill.materialResell ? bill.materialResell : 0);
						})
					);
				}

				return '$ ' + total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
			}
		},
		{
			headerName: 'GP%',
			filter: 'agTextColumnFilter'
		},
		{
			headerName: 'Open Date',
			filter: 'agNumberColumnFilter',
			field: 'createdOn',
			valueFormatter: (data) => (data.value ? moment(data.value).format('MM-DD-YYYY') : null)
		},
		{
			headerName: 'Close Date',
			filter: 'agNumberColumnFilter',
			field: 'closedDate',
			valueFormatter: (data) => (data.value ? moment(data.value).format('MM-DD-YYYY') : null)
		},
		{
			headerName: 'Quoted Job Number',
			field: 'quotedJobNumber',
			filter: 'agNumberColumnFilter'
		},
		{
			headerName: 'Purchase Order Number',
			field: 'purchaseOrderNumber',
			filter: 'agNumberColumnFilter'
		},
		{
			headerName: 'Invoice Number',
			field: 'workNumber',
			filter: 'agNumberColumnFilter'
		}
	];

	sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressRowGroups: true,
					suppressValues: true,
					suppressPivots: true,
					suppressPivotMode: true
				}
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		],
		position: 'right'
	};

	componentDidMount() {
		this.props.getGroups(this.props.workId);
	}

	onQuickFilterChanged(value) {
		this.gridApi.setQuickFilter(value);
	}

	exportHandler(value) {
		let fileName = value;
		let params = {
			fileName: `${fileName ? fileName : 'agGrid'}-${moment().format('MM-DD-YYYY')}`,
			processCellCallback: function (params) {
				return params.value;
			}
		};
		this.gridApi.exportDataAsExcel(params);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	render() {
		return (
			<div className="fixGridMargin">
				<div
					className="ag-theme-balham"
					style={{
						height: '20vh',
						width: '100%'
					}}
				>
					<AgGridReact
						animateRows={true}
						modules={AllModules}
						suppressRowClickSelection={false}
						suppressDragLeaveHidesColumns={true}
						suppressContextMenu={true}
						suppressMultiRangeSelection={true}
						onGridReady={this.onGridReady}
						columnDefs={this.columnDefs}
						defaultColDef={this.defaultColDef}
						sideBar={this.sideBar}
						rowData={this.props.rowData}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		// rowData: state.work.workRecord.view.BillingCenter.orderGroup.rowData
		rowData: state.work.workRecord.view.BillingCenter.orderGroup.projectGroups.groups,
		workId: state.work.workRecord.view.id
	};
};
export default connect(mapStateToProps, actions)(GroupBillableGrid);
