import React from 'react';
import {Button, Icon, Segment, Label} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {certIds} from '../../../../../constants/certifications.js';
import {searchAasdiCertifications} from '../../../../../components/Global/services/globalActions.js';
import {toggleCertModal, clearSearchCert} from '../services/WorkAasdiGridActions.js';

function ManageCerts(props) {
	return (
		<div className="aasdi-manage-certs">
			<span>
				<Button basic color="blue" type="text" onClick={() => props.toggleCertModal(true)}>
					<Icon name="file alternate" className="aasdi-button-icon" />
					Certifications
				</Button>

				{props.selectedCerts.length > 0 ? (
					<Button
						basic
						color="red"
						onClick={() => {
							// I'm not sure why the 3 lines below are required but it won't reset the grid without it
							const apiGrid = () => props.gridApi;
							let gridApi = apiGrid();

							props.searchAasdiCertifications('', '', gridApi);
							props.clearSearchCert(gridApi);
						}}
					>
						<Icon name="x" className="aasdi-button-icon" />
						Clear Certs
					</Button>
				) : null}
			</span>

			{props.selectedCerts.length > 0 ? (
				<Segment>
					<Label inverted color="grey" attached="top">
						Selected Certifications
					</Label>
					{props.selectedCerts.map((cert, index) => {
						return (
							<Button
								active
								key={index}
								className="aasdi-selected-certification"
								content={certIds[certIds.findIndex((x) => x.id == cert)].name}
							/>
						);
					})}
				</Segment>
			) : null}
		</div>
	);
}

const mapStateToProps = (state) => ({
	modal: state.work.aasdiGrid.searchByZip,
	selectedCerts: state.work.aasdiGrid.certModal.selectedCert
});

const mapDispatchToProps = (dispatch) => ({
	toggleCertModal: (value) => dispatch(toggleCertModal(value)),
	clearSearchCert: (gridApi) => dispatch(clearSearchCert(gridApi)),
	searchAasdiCertifications: (certs, value, gridApi) => dispatch(searchAasdiCertifications(certs, value, gridApi))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCerts);
