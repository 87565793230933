import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'semantic-ui-react';

export const ActiveSwitch = (props) => (
	<Icon
		onClick={props.onClick}
		name={'toggle on'}
		style={{cursor: 'pointer'}}
		flipped={props.toggled ? undefined : 'horizontally'} //flip instead of toggle off because it looks better
		size="large"
		color={props.toggled ? 'green' : 'red'}
	/>
);

ActiveSwitch.propTypes = {
	toggled: PropTypes.bool.isRequired, //(rowIndex: number): boolean
	onClick: PropTypes.func.isRequired
};

export default ActiveSwitch;
