import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Table, Checkbox, Container, Popup, Visibility, Loader, Message} from 'semantic-ui-react';

import FooterMessage from '../../../components/Global/Messages.js';
import SelectAllButton from './Button_SelectAll.js';
import CreateUser from './Button_CreateUser.js';
import Edit_User from './Button_EditUser.js';
import Activate_User from './Button_ActivateUser.js';
import Deactivate_User from './Button_DeactivateUser.js';
import Reset_User_Password from './Button_ResetUserPassword.js';
import Refresh_Users from './Button_RefreshUsers.js';
import To_Top from '../../../components/Global/Button_ToTop.js';
import Search from './Search.js';

import * as actions from '../services/techManagementActions.js';
import {connect} from 'react-redux';

const Users = (props) => (
	<Tab.Pane loading={props.techManagement.loading}>
		<Container fluid className={'techManagementUserButtonGroupPadding TechManagement-ButtonRow Parent-Container'}>
			<span className="TechManagement-ButtonRow">
				<Popup trigger={<SelectAllButton {...props} />} content="Select all" on="hover" />
				<Popup trigger={<Refresh_Users {...props} />} content="Refresh table" on="hover" />
				<Search {...props} />
			</span>

			<span className="TechManagement-ButtonRow">
				<Popup trigger={<Reset_User_Password {...props} />} content="Reset user(s) password" on="hover" />
				<Popup trigger={<Deactivate_User {...props} />} content="Deactivate user(s)" on="hover" />
				<Popup trigger={<Activate_User {...props} />} content="Activate user(s)" on="hover" />
				<Popup trigger={<Edit_User {...props} />} content="Edit user" on="hover" />
				<CreateUser {...props} />
			</span>
		</Container>
		<Visibility
			onBottomVisible={() => props.getUsers(props.techManagement.users.token)}
			onTopVisible={() => props.toggleToTop(false, props.techManagement.users)}
			onTopPassed={() => props.toggleToTop(true, props.techManagement.users)}
			once={false}
		>
			<Table sortable selectable striped>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						<Table.HeaderCell
							sorted={
								props.techManagement.sorting.columns.users === 'Email'
									? props.techManagement.sorting.directions.users
									: null
							}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'Email',
									type: 'users'
								})
							}
						>
							Email
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={
								props.techManagement.sorting.columns.users === 'AASDI'
									? props.techManagement.sorting.directions.users
									: null
							}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'AASDI',
									type: 'users'
								})
							}
						>
							AASDI
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={
								props.techManagement.sorting.columns.users === 'UserCreateDateHidden'
									? props.techManagement.sorting.directions.users
									: null
							}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'UserCreateDateHidden',
									type: 'users'
								})
							}
						>
							User Create Date
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={
								props.techManagement.sorting.columns.users === 'UserLastModifiedDateHidden'
									? props.techManagement.sorting.directions.users
									: null
							}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'UserLastModifiedDateHidden',
									type: 'users'
								})
							}
						>
							User Last Modified Date
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={
								props.techManagement.sorting.columns.users === 'Enabled'
									? props.techManagement.sorting.directions.users
									: null
							}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'Enabled',
									type: 'users'
								})
							}
						>
							Activated
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={
								props.techManagement.sorting.columns.users === 'UserStatus'
									? props.techManagement.sorting.directions.users
									: null
							}
							onClick={() =>
								props.sortUsers({
									clickedColumn: 'UserStatus',
									type: 'users'
								})
							}
						>
							User Status
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Message className="placeHolder" hidden={props.techManagement.loading !== true} />
					{props.techManagement.users.users.map(
						({UserCreateDate, UserLastModifiedDate, Enabled, UserStatus, AASDI, Email, Checked}) => (
							<Table.Row key={Email}>
								<Table.Cell collapsing>
									<Checkbox toggle checked={Checked} onChange={() => props.toggleUser(Email)} />
								</Table.Cell>
								<Table.Cell>{Email}</Table.Cell>
								<Table.Cell>{AASDI}</Table.Cell>
								<Table.Cell>{UserCreateDate}</Table.Cell>
								<Table.Cell>{UserLastModifiedDate}</Table.Cell>
								<Table.Cell>{Enabled}</Table.Cell>
								<Table.Cell>{UserStatus}</Table.Cell>
							</Table.Row>
						)
					)}
				</Table.Body>
			</Table>
			<FooterMessage {...props} />
			<Loader active={props.techManagement.users.loading} inline="centered" />
		</Visibility>
		<Popup trigger={<To_Top {...props} />} content="Scroll to top of page." on="hover" />
	</Tab.Pane>
);

Users.propTypes = {
	techManagement: PropTypes.shape({
		loading: PropTypes.bool,
		users: PropTypes.shape({
			token: PropTypes.string,
			loading: PropTypes.bool,
			users: PropTypes.array
		}),
		sorting: PropTypes.shape({
			columns: PropTypes.shape({
				users: PropTypes.string
			}),
			directions: PropTypes.shape({
				users: PropTypes.string
			})
		})
	}),
	getUsers: PropTypes.func,
	updateSearch: PropTypes.func,
	toggleToTop: PropTypes.func,
	sortUsers: PropTypes.func,
	toggleUser: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		techManagement: state.global.techManagement,
		global: state.global.global
	};
};

export default connect(mapStateToProps, actions)(Users);
