import moment from 'moment';
import customFetch from '../../../lib/old/customFetch.js';

export const getActivityStreamEvents = (id, type) => {
	return (dispatch) => {
		dispatch({
			type: 'CLEAR_ACTIVITY_STREAM'
		});

		customFetch(`${process.env.REACT_APP_API_URL}/legacy/activityStream/${type}/${id}`)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				json.stream.map((event) => (event.createdOn = moment(event.createdOn)));

				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: json.stream,
						column: 'events'
					}
				});

				const reducer = (accumulator, currentValue) => accumulator + currentValue;

				if (json.metrics.length) {
					json.metrics.unshift({
						logType: 'Total',
						count: json.metrics.map((metric) => metric.count).reduce(reducer)
					});
				}

				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: json.metrics,
						column: 'metrics'
					}
				});
				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: false,
						column: 'loading'
					}
				});
				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: true,
						column: 'fetched'
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: err,
						column: 'error'
					}
				});
				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: true,
						column: 'fetched'
					}
				});
				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: false,
						column: 'loading'
					}
				});
			});
	};
};

export const postActivityStreamEvent = (id, type, comment, logType) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_ACTIVITY_STREAM',
			payload: {
				value: true,
				column: 'loading'
			}
		});

		document.getElementById('stream-input').value = '';

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/activityStream/${type}/${id}`;

		customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify({comment, logType})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'ADD_ACTIVITY_STREAM_EVENT',
					payload: [json]
				});

				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: false,
						column: 'loading'
					}
				});
			})
			.catch((err) => {
				// document.getElementById('stream-input').value = body.comment;

				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: false,
						column: 'loading'
					}
				});
				console.log(err);
			});
	};
};

export const deleteActivityStreamEvent = (id, eventId, type) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_ACTIVITY_STREAM',
			payload: {
				value: true,
				column: 'loading'
			}
		});
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/activityStream/${type}/${id}?mode=deleteStreamEvent`;

		customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify({id: eventId})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				dispatch({
					type: 'REMOVE_ACTIVITY_STREAM_EVENT',
					payload: eventId
				});

				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: false,
						column: 'loading'
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'UPDATE_ACTIVITY_STREAM',
					payload: {
						value: false,
						column: 'loading'
					}
				});
				console.log(err);
			});
	};
};

export const updateFilter = (type) => {
	return (dispatch) => {
		dispatch({
			type: 'UPDATE_ACTIVITY_STREAM',
			payload: {
				value: type,
				column: 'filter'
			}
		});
	};
};
