import React from 'react';
import {Modal, Button, Form, TextArea} from 'semantic-ui-react';

function EditDesyncedModal(props) {
	const {isOpen, record, confirmEdit, toggleIsOpen} = props;
	if (!record) {
		return null;
	}

	let fieldValue = record.body;
	if (typeof fieldValue === 'object') {
		fieldValue = JSON.stringify(fieldValue);
	}

	function submitChanges(e) {
		let formIterator = new FormData(e.target).entries();

		const formValues = Object.fromEntries(formIterator);
		let newBody = formValues.body;
		if (newBody[0] === '{') {
			newBody = JSON.parse(newBody);
		}
		confirmEdit({...record, body: newBody});
		toggleIsOpen();
	}

	return (
		<Modal open={isOpen} size="fullscreen">
			<Modal.Header>Edit Desynced Record</Modal.Header>
			<Modal.Content>
				<Form id="submit" onSubmit={(e) => submitChanges(e)}>
					<TextArea rows="6" name="body" style={{height: '70vh'}} defaultValue={fieldValue}></TextArea>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => toggleIsOpen()}>Cancel</Button>
				<Button type="submit" form="submit" primary>
					Edit
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

export default EditDesyncedModal;
