import React from 'react';
import {connect} from 'react-redux';
import {Checkbox} from 'semantic-ui-react';

class DocumentSectionCheckbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: this.props.value
		};
	}
	render() {
		return (
			<div style={{paddingTop: '.2em'}}>
				<Checkbox
					label={this.state.checked ? <label>Yes</label> : <label>No</label>}
					defaultChecked={this.props.value}
					onClick={(checked) => {
						this.props.agGridReact.props.updateCreateProjectModalDocumentGrid(
							this.props.rowIndex,
							this.props.agGridReact.props.sectionIndex,
							checked
						);
					}}
				/>
			</div>
		);
	}
}

export default connect(null, null)(DocumentSectionCheckbox);
