import update from 'immutability-helper';
import countryOptions from '../../../../../../constants/countries.js';
import stateOptions from '../../../../../../constants/states.js';
import leadSourceOptions from '../../../../../../constants/leadTypes.js';
import clientTypeOptions from '../../../../../../constants/clientTypes.js';
import industryOptions from '../../../../../../constants/industries.js';
import moment from 'moment';
import {fmtMoney} from '../../../../../../util/formatting.js';

export default (
	state = {
		loading: false,
		rowData: [],
		headerData: OverviewTabHeader,
		tab: 'Overview',
		stageNames,
		aasdiData: [],
		documentsFields,
		contactList: [],
		localOrderHist: null,
		profileName: null,
		workPerformedOptions: [],
		equipmentOptions: [],
		showDeactiveSKUModal: false,
		LocationToDeactivate: null,
		worksForRating: {
			loading: false,
			works: null
		},
		certificationsLoading: true,
		certificationsError: false,
		certifications: [],
		orgCertifications: [],
		wpLoading: true,
		wpError: false,
		wpArray: [],
		orgWpArray: []
	},
	action
) => {
	switch (action.type) {
		case 'WORK_AASDI_PROFILE_NAME':
			state = update(state, {
				profileName: {
					$set: action.payload.data || action.payload
				}
			});
			break;
		case 'UPDATE_ROWDATA_GRID':
			state = update(state, {
				headerData: {
					$set: headers[`${action.payload.item}TabHeader`] || OverviewTabHeader
				},
				rowData: {
					$set: action.payload.data
				},
				tab: {
					$set: action.payload.item
				}
			});
			break;
		case 'UPDATE_AASDI_OVERVIEW_LOCATION':
			state = update(state, {
				location: {
					$set: action.payload.data || action.payload
				}
			});
			break;
		case 'UPDATE_AASDI_VIEW_DOCUMENTS_VALUE':
			state = update(state, {
				documentsFields: {
					fields: {
						[action.payload.index]: {
							value: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;
		case 'UPDATE_AASDI_VIEW_DOCUMENTS_ERROR':
			state = update(state, {
				documentsFields: {
					fields: {
						[action.payload.index]: {
							error: {
								$set: action.payload.error
							}
						}
					}
				}
			});
			break;
		case 'AASDI_VIEW_DOCUMENTS_LOADING':
			state = update(state, {
				documentsFields: {
					loading: {
						$set: action.payload
					}
				}
			});
			break;
		case 'AASDI_VIEW_ADD_DOCUMENT':
			state = update(state, {
				rowData: {
					$push: [action.payload.object]
				}
			});
			break;
		case 'AASDI_VIEW_UPDATE_DOCUMENT':
			state = update(state, {
				rowData: {
					[action.payload.index]: {
						customerApproval: {
							$set: action.payload.value
						}
					}
				}
			});
			break;
		case 'AASDI_VIEW_REMOVE_DOCUMENT':
			state = update(state, {
				rowData: {
					$splice: [[action.payload.index, 1]]
				}
			});
			break;
		case 'DEFAULT_PROFILE_AASDI_LOADING':
			state = update(state, {
				loading: {
					$set: action.payload
				}
			});
			break;
		case 'AASDI_VIEW_WORK_PERFORMED_OPTIONS':
			state = update(state, {
				workPerformedOptions: {
					$set: action.payload
				}
			});
			break;
		case 'AASDI_VIEW_REMOVE_LOCATION':
			state = update(state, {
				rowData: {
					$splice: [[action.payload.index, 1]]
				}
			});
			break;
		case 'UPDATE_AASDI_CONTACT_LIST':
			state = update(state, {
				contactList: {
					$set: action.payload.data
				}
			});
			break;
		case 'ADD_AASDI_LOCATION_ROWDATA_GRID':
			state = update(state, {
				rowData: {
					$push: action.payload.data
				}
			});
			break;
		case 'UPDATE_AASDI_LOCATION_PHONE':
			state = update(state, {
				rowData: {
					[action.payload.index]: {
						siteContactPhone: {
							$set: action.payload.data
						}
					}
				}
			});
			break;
		case 'SET_AASDI_LOCATION_WORK':
			state = update(state, {
				localOrderHist: {
					$set: action.payload.data
				}
			});
			break;
		case 'SET_AASDI_DATA':
			state = update(state, {
				aasdiData: {
					$set: action.payload
				}
			});
			break;
		case 'CLEAR_AASDI_LOCATION_WORK':
			state = update(state, {
				localOrderHist: {
					$set: action.payload.data
				}
			});
			break;
		case 'AASDI_VIEW_EQUIPMENT_OPTIONS':
			state = update(state, {
				equipmentOptions: {
					$set: action.payload
				}
			});
			break;
		case 'AASDI_TOGGLE_DEACTIVE_LOCATION_MODAL':
			state = update(state, {
				showDeactiveLocationModal: {
					$set: action.payload
				}
			});
			break;
		case 'AASDI_CLEAR_LOCATION_TO_DEACTIVATE':
			state = update(state, {
				LocationToDeactivate: {
					$set: action.payload
				}
			});
			break;
		case 'AASDI_SET_LOCATION_TO_DEACTIVATE':
			state = update(state, {
				LocationToDeactivate: {
					$set: action.payload
				}
			});
			break;
		case 'ADD_AASDI_RATING_ROWDATA_GRID':
			state = update(state, {
				rowData: {
					$push: action.payload.data
				}
			});
			break;
		case 'LOADING_WORKS_RATING_MODAL':
			state = update(state, {
				worksForRating: {
					loading: {
						$set: action.payload
					}
				}
			});
			break;
		case 'UPDATE_AASDI_WORKS_OPTION_FOR_RATING':
			state = update(state, {
				worksForRating: {
					works: {
						$set: action.payload.data
					}
				}
			});
			break;
		case 'AASDI_VIEW_ADDED_CONTACT':
			state = update(state, {
				rowData: {
					$push: action.payload
				}
			});
			break;
		case 'AASDI_CERTIFICATIONS_LOADING':
			state = update(state, {
				certificationsLoading: {
					$set: action.payload.value
				}
			});
			break;
		case 'AASDI_WORK_PERFORMED_LOADING':
			state = update(state, {
				wpLoading: {
					$set: action.payload.value
				}
			});
			break;
		case 'AASDI_CERTIFICATIONS_ERROR':
			state = update(state, {
				certificationsError: {
					$set: action.payload.value
				}
			});
			break;
		case 'AASDI_WORK_PERFORMED_ERROR':
			state = update(state, {
				wpError: {
					$set: action.payload.value
				}
			});
			break;
		case 'AASDI_CERTIFICATIONS':
			state = update(state, {
				orgCertifications: {
					$set: action.payload.orgCerts
				},
				certifications: {
					$set: action.payload.certs
				}
			});
			break;
		case 'AASDI_WORK_PERFORMED':
			state = update(state, {
				orgWpArray: {
					$set: action.payload.orgWp
				},
				wpArray: {
					$set: action.payload.wp
				}
			});
			break;
		case 'ADD_AASDI_CERTIFICATIONS':
			state = update(state, {
				certifications: {
					$set: action.payload.value
				}
			});
			break;
		case 'ADD_AASDI_WP':
			state = update(state, {
				wpArray: {
					$set: action.payload.value
				}
			});
			break;

		case 'AASDI_CLEAR_CERTIFICATIONS':
			state = update(state, {
				certifications: {$set: []},
				orgCertifications: {$set: []},
				orgWpArray: {
					$set: []
				},
				wpArray: {
					$set: []
				}
			});
			break;
		default:
	}
	return state;
};

export const OverviewTabHeader = [
	{
		headerName: 'First Name',
		field: 'firstName',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Last Name',
		field: 'lastName',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Email Address',
		field: 'email',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Phone',
		field: 'phone',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Ext',
		field: 'extension',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Cell',
		field: 'mobilePhone',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Address',
		field: 'address',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Address 2',
		field: 'address2',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'City',
		field: 'city',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'State',
		field: 'state',
		filter: 'agTextColumnFilter',
		valueFormatter: (params) => {
			return params.value ? params.value.substring(0, 2).toUpperCase() : '';
		},
		editable: true
	},
	{
		headerName: 'Country',
		field: 'countryId',
		filter: 'agTextColumnFilter',
		editable: true,
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			cellRenderer: function (params) {
				const country = countryOptions.find((country) => params.value === country.value);
				return country ? country.text : params.value;
			},
			values: countryOptions.map((country) => country.text)
		},
		cellRenderer: function (params) {
			const country = countryOptions.find((country) => params.value === country.value);
			return country ? country.text : params.value;
		}
	},
	{
		headerName: 'ZIP',
		field: 'zip',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Title',
		field: 'title',
		filter: 'agTextColumnFilter',
		editable: true
	}
];

export const DocumentsTabHeader = [
	{
		headerName: 'File Name',
		field: 'fileName',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Google Drive',
		field: 'googleDriveLink',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: function (params) {
			return `<a href="${params.value}" target="_blank">${params.value}</a>`;
		}
	},
	{
		headerName: 'Comments',
		field: 'comments',
		cellStyle: {paddingTop: '.4em'}
	},
	{
		headerName: 'Customer Approval',
		field: '',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'aasdiDocumentSectionCheckbox'
	},
	{
		headerName: 'Remove',
		field: '',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'aasdiDocumentSectionRemoveButton'
	}
];

export const WorkTabHeader = [
	{
		headerName: 'Action bar',
		field: '',
		cellStyle: {paddingTop: '.4em'},
		cellRenderer: 'aasdiWorkTabCellRenderer'
	},
	{
		headerName: 'Work Order',
		field: 'workNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Order Status',
		field: 'status_Name',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Issue Date',
		field: 'createdOn',
		filter: 'agTextColumnFilter',
		editable: false,
		valueFormatter: (data) => {
			return data.value ? moment(data.value).format('MM-DD-YYYY') : null;
		}
	},
	{
		headerName: 'NDS',
		field: 'workManager_Name',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Store Number',
		field: 'location_StoreNumber',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Location Name',
		field: 'location_Name',
		filter: 'agTextColumnFilter',
		editable: false
	},

	{
		headerName: 'Job Number',
		field: 'quotedJobNumber',
		filter: 'agTextColumnFilter',
		editable: false
	}
];

export const RatingsTabHeader = [
	{
		headerName: 'Work Number',
		field: 'workNumber',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 150
	},
	{
		headerName: 'Project Date',
		field: 'createdOn',
		filter: 'agTextColumnFilter',
		editable: false,
		valueFormatter: (params) => moment(params.value).format('MM-DD-YYYY'),
		width: 150
	},
	{
		headerName: 'Customer',
		field: 'client_Name',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 100
	},
	{
		headerName: 'Project Name',
		field: 'opportunityName',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 150
	},
	{
		headerName: 'PO Amount',
		field: 'poAmount',
		filter: 'agTextColumnFilter',
		editable: false,
		valueFormatter: ({value}) => fmtMoney(value),
		width: 120
	},
	{
		headerName: 'Project Manager',
		field: 'projectManager_Name',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 140
	},
	{
		headerName: 'Tech. First Name',
		field: 'technicianFirstName',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 150
	},
	{
		headerName: 'Accept Budget',
		field: 'acceptBudget',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 130
	},
	{
		headerName: 'Meet Schedule',
		field: 'meetSchedule',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 130
	},
	{
		headerName: 'Customer Satisfaction',
		field: 'customerSatisfaction',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 130
	},
	{
		headerName: 'Responsiveness',
		field: 'responsiveness',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 130
	},
	{
		headerName: 'Communication',
		field: 'communication',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 130
	},
	{
		headerName: 'Integrity',
		field: 'integrity',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 130
	},
	{
		headerName: 'Attitude',
		field: 'attitude',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 130
	},
	{
		headerName: 'Documentation',
		field: 'documentation',
		filter: 'agTextColumnFilter',
		editable: false,
		width: 130
	},
	{
		headerName: 'Comments',
		field: 'comments',
		filter: 'agTextColumnFilter',
		editable: false
	}
];

const documentsFields = {
	loading: false,
	fields: [
		{
			name: 'File Name',
			value: '',
			input: true,
			error: false,
			disabled: false,
			required: true,
			checkForm: true,
			columnWidth: 5
		},
		{
			name: 'Google Drive Link',
			value: '',
			input: true,
			error: false,
			disabled: false,
			checkForm: true,
			required: true,
			columnWidth: 5
		},
		{
			name: 'Customer Approval (Purchase Order)',
			value: false,
			input: false,
			dropdown: true,
			error: false,
			disabled: false,
			required: false,
			columnWidth: 5,
			options: [
				{
					key: 'No',
					text: 'No',
					value: false
				},
				{
					key: 'Yes',
					text: 'Yes',
					value: true
				}
			]
		},
		{
			name: 'Comments',
			value: '',
			input: false,
			textArea: true,
			error: false,
			disabled: false,
			required: false,
			columnWidth: 15
		}
	]
};

export const LocationsTabHeader = [
	{
		headerName: 'Actions',
		field: '',
		filter: 'agTextColumnFilter',
		cellRenderer: 'locationGridActionsCellRenderer',
		editable: false,
		width: 120
	},
	{
		headerName: 'Store Number',
		field: 'storeNumber',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Name',
		field: 'name',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Address',
		field: 'address',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Address 2',
		field: 'address2',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'City',
		field: 'city',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'State',
		field: 'state',
		filter: 'agTextColumnFilter',
		valueFormatter: (params) => {
			return params.value ? params.value.substring(0, 2).toUpperCase() : '';
		},
		editable: true
	},
	{
		headerName: 'Country',
		field: 'countryName',
		filter: 'agTextColumnFilter',
		editable: true,
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			cellRenderer: function (params) {
				const country = countryOptions.find((country) => params.value === country.value);
				return country ? country.text : params.value;
			},
			values: countryOptions.map((country) => country.text)
		},
		cellRenderer: function (params) {
			const country = countryOptions.find((country) => params.value === country.value);
			return country ? country.text : params.value;
		}
	},
	{
		headerName: 'Zip',
		field: 'zip',
		filter: 'agTextColumnFilter',
		editable: true
	},
	{
		headerName: 'Site Contact Phone',
		field: 'siteContactPhone',
		filter: 'agTextColumnFilter',
		editable: false
	},
	{
		headerName: 'Site Contact',
		field: 'siteContactName',
		filter: 'agTextColumnFilter',
		editable: true,
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			values: []
		}
	},
	{
		headerName: 'Billing Contact',
		field: 'billingContactName',
		filter: 'agTextColumnFilter',
		editable: true,
		cellEditor: 'agRichSelectCellEditor',
		cellEditorParams: {
			values: []
		}
	}
];

export const defineAasdiProfileFields = (data) => {
	const info = Array.isArray(data) ? data[0] : data;
	console.log(`AASDI data ----- ${JSON.stringify(data)}`);
	const fields = [];
	fields.push({
		label: 'Name',
		isDropdown: false,
		value: info.name,
		key: 'name', // corresponds to the payload's object key of the field
		options: [],
		width: 4
	});
	fields.push({
		label: 'Spectrum Code',
		isDropdown: false,
		value: info.spectrumCode,
		key: 'spectrumCode',
		options: [],
		width: 4,
		disabled: true
	});
	fields.push({
		label: 'Address',
		isDropdown: false,
		value: info.address,
		key: 'address',
		options: [],
		width: 5
	});
	fields.push({
		label: 'City',
		isDropdown: false,
		value: info.city,
		key: 'city',
		options: [],
		width: 4
	});
	fields.push({
		label: 'State',
		isDropdown: false,
		value: info.state,
		key: 'state',
		width: 4
	});
	fields.push({
		label: 'Zip',
		isDropdown: false,
		value: info.zip,
		key: 'zip',
		width: 5
	});
	fields.push({
		label: 'Website',
		isDropdown: false,
		value: info.website,
		key: 'website',
		options: [],
		width: 4
	});
	fields.push({
		label: 'Status',
		isDropdown: false,
		value: info.aasdiStatusName,
		key: 'aasdiStatusName',
		options: [],
		width: 4,
		disabled: true
	});

	fields.push({
		label: 'Liability Exp. Date',
		isDropdown: false,
		value: moment(info.liabilityExpirationDate).add(1, 'days').format('L'),
		key: 'liabilityExpirationDate',
		options: [],
		expired: info.expired,
		disabled: true,
		width: 5
	});
	fields.push({
		label: `Workers' Comp Exp. Date`,
		isDropdown: false,
		value: moment(info.workersExpirationDate).add(1, 'days').format('L'),
		key: 'workersExpirationDate',
		options: [],
		expired: info.expired,
		disabled: true,
		width: 4
	});
	return fields;
};

export const defineClientProfileFields = (data) => {
	const info = Array.isArray(data) ? data[0] : data;
	const fields = [];
	fields.push({
		label: 'Name',
		isDropdown: false,
		value: info.name,
		key: 'name', // corresponds to the payload's object key of the field
		options: [],
		width: 3
	});
	fields.push({
		label: 'Address',
		isDropdown: false,
		value: info.address,
		key: 'address',
		options: [],
		width: 3
	});
	fields.push({
		label: 'Address 2',
		isDropdown: false,
		value: info.address2,
		key: 'address2',
		options: [],
		width: 3
	});
	fields.push({
		label: 'ZIP',
		isDropdown: false,
		value: info.zip,
		key: 'zip',
		options: [],
		width: 3
	});
	fields.push({
		label: 'Country',
		isDropdown: true,
		value: info.countryId,
		key: 'countryId',
		options: countryOptions,
		width: 3
	});
	fields.push({
		label: 'State',
		isDropdown: info.countryId === 218,
		value: info.state,
		key: 'state',
		options: stateOptions,
		width: 3
	});
	fields.push({
		label: 'City',
		isDropdown: false,
		value: info.city,
		key: 'city',
		options: [],
		width: 3
	});
	fields.push({
		label: 'Website',
		isDropdown: false,
		value: info.website,
		key: 'website',
		options: [],
		width: 3
	});
	fields.push({
		label: 'Status',
		isDropdown: false,
		value: info.clientStatusName,
		key: 'clientStatusName',
		options: [],
		width: 3,
		disabled: true
	});
	fields.push({
		label: 'Client Type',
		isDropdown: true,
		value: info.clientType,
		key: 'clientType',
		options: clientTypeOptions,
		width: 3
	});
	fields.push({
		label: 'Lead Source',
		isDropdown: true,
		value: info.leadSourceId,
		key: 'leadSourceId',
		options: leadSourceOptions,
		width: 3
	});
	fields.push({
		label: 'Industry',
		isDropdown: true,
		value: info.industryId,
		key: 'industryId',
		options: industryOptions,
		width: 3
	});
	fields.push({
		label: 'Sales Representative',
		isDropdown: false,
		value: info.accountExecutiveId,
		key: 'accountExecutiveName',
		updateKey: 'accountExecutiveId',
		employee: true,
		options: [
			{
				text: info.accountExecutiveName,
				value: info.accountExecutiveId
			}
		],
		width: 3,
		loading: false,
		search: true
	});
	fields.push({
		label: 'Account Manager',
		isDropdown: false,
		value: info.accountManagerId,
		key: 'accountManagerName',
		updateKey: 'accountManagerId',
		employee: true,
		options: [
			{
				text: info.accountManagerName,
				value: info.accountManagerId
			}
		],
		width: 3,
		loading: false,
		search: true
	});
	return fields;
};

const headers = {
	OverviewTabHeader,
	DocumentsTabHeader,
	WorkTabHeader,
	RatingsTabHeader,
	LocationsTabHeader
};

const stageNames = ['Overview', 'Work', 'Documents', 'Certifications', 'Ratings', 'Locations', 'Company Profile'];
