import React, {Component} from 'react';
import {Label, Segment, Grid, Button, Divider} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import AgGrid from '~/components/Global/AgGrid.js';
import FilterGrid from '~/components/Global/FilterGrid.js';
import ExportGrid from '~/components/Global/ExportGrid.js';
import ProfileDetails from '~/components/Global/ProfileDetails.js';
import CreateContactModal from '~/components/Global/CreateContactModal.js';
import {checkIdenticalsArrays} from '~/lib/old/validation.js';
import {toggleCreateContactModal, toggleFetchingOnTabsNavbar} from '~/components/Global/services/globalActions.js';
import ActivityStream from '~/components/activityStream/ActivityStream.js';
import api from '~/lib/api.ts';
import {getTabRequestItems, setClientProfileName, clearGrid} from '../services/profileGridActions.js';
import {OverviewTabHeader} from '../services/profileGridReducer.js';
import ClientWorkTabCellRenderer from './ClientWorkTabCellRenderer.js';
import countryOptions from '~/constants/countries.js';

import Sales from './Sales.js';

const frameworkComponents = {
	clientWorkTabCellRenderer: ClientWorkTabCellRenderer
};

class OverviewTab extends Component {
	constructor(props) {
		super(props);
		this.updateName = this.updateName.bind(this);
	}

	componentDidUpdate(prevProps) {
		const {profileGridData, profileGridHeader, toggleFetchingOnTabsNavbar} = this.props;
		if (prevProps.profileGridData === null && profileGridData) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.profileGridHeader, profileGridHeader)) {
			toggleFetchingOnTabsNavbar(false);
		} else if (!checkIdenticalsArrays(prevProps.rowData, profileGridData)) {
			toggleFetchingOnTabsNavbar(false);
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
		const {clientId} = this.props;

		if (
			this.props.profileGridData &&
			this.props.profileGridData.length &&
			Number(clientId) === this.props.profileGridData[0].clientId &&
			checkIdenticalsArrays(this.props.profileGridHeader, OverviewTabHeader)
		) {
			// table will not reload if desired data is already available.
			// It is assumed that all the contact's clientId coming from backend are equal to
			// the client profile id
			return;
		}
		this.props.clearGrid('Overview');
		this.gridApi.showLoadingOverlay();
		this.props.getTabRequestItems(clientId, 'Overview');
		this.props.toggleFetchingOnTabsNavbar(true);
	};

	editContact = (params) => {
		const updatedData = params.data;
		const contactId = updatedData.id;
		this.gridApi.showLoadingOverlay();
		this.props.toggleFetchingOnTabsNavbar(true);
		if (updatedData.state) {
			updatedData.state = updatedData.state.substring(0, 2).toUpperCase();
		}
		if (updatedData.countryId) {
			let country = countryOptions.find((country) => updatedData.countryId === country.text);
			if (country) {
				updatedData.countryId = country.value;
			}
		}
		api(`legacy/contacts/${contactId}`, 'PUT', updatedData)
			.then(() => {
				this.gridApi.hideOverlay();
				this.props.toggleFetchingOnTabsNavbar(false);
			})
			.catch((error) => {
				this.gridApi.hideOverlay();
				this.props.toggleFetchingOnTabsNavbar(false);
				alert('Error while updating contact!');
				console.error(error);
			});
	};

	updateName(name) {
		if (name) {
			this.props.setClientProfileName(name);
		}
	}

	render() {
		const {profileGridData, clientId, profileGridHeader} = this.props;
		return (
			<Segment>
				<Grid columns={2} style={{margin: '2.5em'}}>
					<Grid.Row>
						<Grid.Column width={16}>
							<ProfileDetails id={clientId} type="client" updateName={this.updateName} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={8}>
							{clientId && (
								<ActivityStream
									type="client"
									id={clientId}
									options={[
										{
											key: 'note',
											text: 'Note',
											value: 'Note'
										},
										{
											key: 'phoneCall',
											text: 'Phone Call',
											value: 'Phone Call'
										},
										{
											key: 'quote',
											text: 'Quote',
											value: 'Quote'
										},
										{
											key: 'voicemail',
											text: 'Voicemail',
											value: 'Voicemail'
										}
									]}
								/>
							)}
						</Grid.Column>
						<Grid.Column width={8}>
							<Sales />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Segment basic style={{margin: '2.5rem', border: '1px solid #DEDEDE'}}>
					<Label color="blue">Contacts</Label>
					<Button
						basic
						color="blue"
						content="Add"
						floated="right"
						icon="add"
						size="tiny"
						onClick={() => this.props.toggleCreateContactModal(true)}
					/>
					<Divider />
					<Grid style={{padding: '2em 0'}}>
						<FilterGrid gridApi={() => this.gridApi} />
						<ExportGrid gridApi={() => this.gridApi} />
					</Grid>
					<AgGrid
						columnDefs={profileGridHeader}
						rowData={profileGridData}
						onGridReady={this.onGridReady}
						onCellValueChanged={this.editContact}
						frameworkComponents={frameworkComponents}
					/>
				</Segment>
				<CreateContactModal id={clientId} typeOfId={'clientId'} />
			</Segment>
		);
	}
}

OverviewTab.propTypes = {
	profileGridData: PropTypes.array,
	getTabRequestItems: PropTypes.func,
	toggleCreateContactModal: PropTypes.func,
	toggleFetchingOnTabsNavbar: PropTypes.func,
	clientId: PropTypes.string,
	profileGridHeader: PropTypes.array,
	clearGrid: PropTypes.func,
	setClientProfileName: PropTypes.func,
	salesData: PropTypes.object
};

const mapStateToProps = (state) => ({
	profileGridData: state.work.clientProfileGrid.rowData,
	profileGridHeader: state.work.clientProfileGrid.headerData
});

const mapDispatchToProps = (dispatch) => ({
	toggleCreateContactModal: (status) => dispatch(toggleCreateContactModal(status)),
	getTabRequestItems: (clientId, item) => dispatch(getTabRequestItems(clientId, item)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	setClientProfileName: (name) => dispatch(setClientProfileName(name)),
	clearGrid: (item) => dispatch(clearGrid(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewTab);
