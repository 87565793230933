import React from 'react';
import {Button, Modal, Icon, Header, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {AgGridReact} from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '../../../../../../../../../../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import * as actions from '../../../../../services/workRecordActions.js';

class SelectSKUModal extends React.Component {
	render = () => {
		const {props} = this;
		return (
			<Modal open={props.selectSKUModalToggleValue} size="fullscreen" style={{marginTop: '0'}}>
				<Header>
					<Icon name="plus" color={props.iconError ? 'red' : 'green'} />
					<div className="content">Select SKUs</div>
				</Header>
				<Modal.Content scrolling={true}>
					<div style={{padding: '1em 0'}}>
						Select a row by clicking on it. Selecting a row will remove previous selection unless you hold
						down
						<span style={{color: 'blue'}}> ctrl / cmd</span> while clicking. Selecting a row and then
						holding down
						<span style={{color: 'blue'}}> shift</span> while clicking a second row will select the range.
					</div>
					<Segment style={{paddingBottom: '2em'}}>
						<div
							className="ag-theme-balham"
							style={{
								height: '40vh',
								width: '100%',
								textAlign: 'left',
								boxSizing: 'border-box'
							}}
						>
							<AgGridReact
								onGridReady={(params) => {
									this.gridApi = params.api;
									this.columnApi = params.columnApi;
									this.gridApi.sizeColumnsToFit();
								}}
								suppressHorizontalScroll={true}
								modules={AllModules}
								rowSelection="multiple"
								suppressCopyRowsToClipboard={true}
								// enable delta updates
								deltaRowDataMode={true}
								// return id required for delta updates
								getRowNodeId={(data) => data.id}
								{...props}
								suppressContextMenu={true}
								rowHeight={40}
								rowData={props.SKUList}
								defaultColDef={{
									sortable: true,
									filter: true,
									resizable: true
								}}
								columnDefs={[
									{
										headerName: 'SKU',
										field: 'description',
										editable: false,
										cellStyle: {paddingTop: '.5em'}
									},
									{
										headerName: 'Material Resell',
										field: 'materialResell',
										cellStyle: {paddingTop: '.5em'},
										valueFormatter: (params) => {
											return params.value ? `$${params.value}` : '';
										}
									},
									{
										headerName: 'Labor Resell',
										field: 'laborResell',
										cellStyle: {paddingTop: '.5em'},
										valueFormatter: (params) => {
											return params.value ? `$${params.value}` : '';
										}
									},
									{
										headerName: 'Total Resell',
										field: 'totalResell',
										cellStyle: {paddingTop: '.5em'},
										valueFormatter: (params) => {
											return params.value ? `$${params.value}` : '';
										}
									}
								]}
							/>
						</div>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" onClick={() => props.selectSKUModalToggle([])}>
						<Icon name="remove" />
						Cancel
					</Button>
					<Button
						color="blue"
						onClick={() => {
							props.selectSKUModalAddItems(this.gridApi.getSelectedRows());
							props.selectSKUModalToggle([]);
						}}
					>
						<Icon name="checkmark" />
						Add Selected SKUs
					</Button>
				</Modal.Actions>
			</Modal>
		);
	};
}

const mapStateToProps = (state) => {
	return {
		selectSKUModalToggleValue:
			state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.selectSKUModal.toggle,
		SKUList: state.work.workRecord.view.BillingCenter.projectCostingSpreadsheetModal.selectSKUModal.SKUList
	};
};

export default connect(mapStateToProps, actions)(SelectSKUModal);
