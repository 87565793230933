import React from 'react';
import {connect} from 'react-redux';
import {Segment, Grid, Input, Label, Dropdown, Header, Popup, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';
import moment from 'moment';

import ContactsSection from '../../../../../../../../../components/contactsSection/contactsSection.js';
import ActivityStream from '../../../../../../../../../components/activityStream/ActivityStream.js';
import {debounce, debounceEventHandler} from '../../../../../../../../../lib/old/debounce.js';

import * as actions from './../../../services/workRecordActions.js';
import WorkOrderPDFLabel from '../../overview/components/WorkOrderPDFLabel.js';
import {FancyEditor} from '../../../../../../../../../components/inputs/FancyEditor.jsx';
import {MomentDatePicker} from '../../../../../../../../../components/inputs/DatePicker.jsx';

class AasdiInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sowValue: '',
			specialValue: ''
		};
	}

	async componentDidUpdate() {
		if (!this.state.specialValue) {
			this.state.specialValue = this.props.sections[1].sections[1].fields[0].value
		}
		if (!this.state.sowValue) {
			this.state.sowValue = this.props.sections[1].sections[0].fields[0].value
		}
		
		if (
			this.props.tripIdCheck !== this.props.selectedTripId &&
			this.props.workAasdiIdCheck === this.props.selectedWorkAasdiId
			
		) {
			this.state.sowValue = this.props.sections[1].sections[0].fields[0].value
			this.state.specialValue = this.props.sections[1].sections[1].fields[0].value

			if (this.props.selectedTripId !== '' && this.props.manageAASDI.noTrip === false) {
				this.props.clearContactOptions();
				this.props.setTripIdCheck(this.props.selectedTripId);
			} else {
				this.props.setTripIdCheck('');
			}
		}
	}

	render() {
		const specInst = this.props.manageAASDI.infoSection[1].sections[1].fields[0].value;
		const specInstLoading = this.props.manageAASDI.infoSection[1].sections[1].loading;
		const aasdiSow = this.props.manageAASDI.infoSection[1].sections[0].fields[0].value;
		const aasdiSowLoading = this.props.manageAASDI.infoSection[1].sections[0].loading;
		const requestDate = this.props.manageAASDI.infoSection[0].sections[0].fields[3].value;
		const requestDateLoading = this.props.manageAASDI.infoSection[0].sections[0].loading;
		const tripInfo = {
			specInst,
			aasdiSow,
			requestDate
		};

		return (
			<div key="this">
				<Segment style={{padding: '3vh'}}>
					<div style={{marginBottom: '2vh'}}>
						<WorkOrderPDFLabel
							workOverview={this.props.record}
							tripInfo={tripInfo}
							loading={specInstLoading || requestDateLoading || aasdiSowLoading}
						/>
					</div>
					<Grid
						style={{
							padding: 0
						}}
					>
						{this.props.sections.map((side, index) => {
							return (
								<Grid.Column key={index} width={side.columnWidth}>
									{side.sections.map((section, sectionIndex) => {
										if (section.activityStream) {
											return (
												<Segment basic loading={section.loading}>
													{!section.loading &&
													this.props.manageAASDI.selectedTripId &&
													this.props.manageAASDI.selectedTripId === this.props.tripIdCheck ? (
														<ActivityStream
															id={this.props.manageAASDI.selectedTripId}
															type={'trip'}
															options={[
																{
																	key: 'note',
																	text: 'Note',
																	value: 'Note'
																},
																{
																	key: 'phoneCall',
																	text: 'Phone Call',
																	value: 'Phone Call'
																},
																{
																	key: 'quote',
																	text: 'Quote',
																	value: 'Quote'
																},
																{
																	key: 'voicemail',
																	text: 'Voicemail',
																	value: 'Voicemail'
                                                                }
															]}
														/>
													) : (
														<Segment
															loading={true}
															style={{
																height: '10vh'
															}}
														/>
													)}
												</Segment>
											);
										} else if (section.contactsSection) {
											return (
												<Segment basic>
													{!section.loading &&
													this.props.manageAASDI.selectedTripId &&
													this.props.manageAASDI.selectedTripId === this.props.tripIdCheck ? (
														<ContactsSection
															type={'trip'}
															typeId={this.props.manageAASDI.selectedTripId}
														/>
													) : (
														<Segment
															loading={true}
															style={{
																height: '10vh'
															}}
														/>
													)}
												</Segment>
											);
										} else {
											return (
												<Grid.Row
													key={sectionIndex}
													as={Segment}
													loading={section.loading}
													style={{
														margin: '0 0 2vh 0'
													}}
												>
													<Label color="blue" ribbon>
														{section.name}
													</Label>
													<Grid>
														{section.fields.map((field, fieldIndex) => {
															return (
																<Grid.Column
																	key={fieldIndex}
																	width={field.columnWidth}
																	style={{
																		marginBottom: '2vh'
																	}}
																>
																	<Header
																		as="h5"
																		style={{
																			marginBottom: '.5vh'
																		}}
																	/>
																	{field.nameDisplay ? (
																		<div>
																			<Header.Content>
																				<h4>{field.name}</h4>
																			</Header.Content>

																			<DebounceInput
																				element={Input}
																				size="large"
																				fluid
																				transparent
																				value={field.value}
																				className={'hiddenInput'}
																				debounceTimeout={1500}
																				onChange={(e) => {
																					this.props.updateWorkViewTrip(
																						this.props.manageAASDI
																							.selectedTripId,
																						'name',
																						e.target.value,
																						index,
																						sectionIndex,
																						fieldIndex
																					);
																				}}
																				style={{
																					marginTop: '.5vh'
																				}}
																			/>
																		</div>
																	) : field.contact ? (
																		<Segment>
																			<Label
																				attached="top left"
																				style={{
																					padding: '1vh .5vw'
																				}}
																			>
																				<Icon name="user" />
																				Contact
																			</Label>
																			<Dropdown
																				fluid
																				search
																				loading={field.loading}
																				className="hiddenInput"
																				placeholder={'Search by name...'}
																				style={{
																					marginTop: '.75vh',
																					paddingBottom: '0'
																				}}
																				options={
																					this.props.options &&
																					this.props.options.length > 0
																						? this.props.options
																						: null
																				}
																				onSearchChange={debounceEventHandler(
																					debounce((e) => {
																						if (e.target.value) {
																							this.props.getContactsOptions(
																								e.target.value,
																								this.props.selectedAASDI
																									.id,
																								index,
																								sectionIndex,
																								fieldIndex
																							);
																						}
																					}, 600)
																				)}
																				onChange={(e, data) => {
																					this.props.updateTripContact(
																						data.value,
																						this.props.manageAASDI
																							.selectedTripId,
																						index,
																						sectionIndex,
																						fieldIndex
																					);
																				}}
																			/>
																			{this.props.contact.name ===
																			'No Contact' ? (
																				<p>
																					{this.props.contact.name
																						? `${this.props.contact.name}`
																						: null}
																				</p>
																			) : (
																				<div>
																					<p>
																						{this.props.contact.name
																							? `${this.props.contact.name}`
																							: null}
																						{this.props.contact.title
																							? `,  ${this.props.contact.title}`
																							: ''}
																					</p>
																					<p>
																						{this.props.contact.phone
																							? `phone: ${this.props.contact.phone}`
																							: 'No Phone'}
																					</p>
																					<p>
																						{this.props.contact.email
																							? `email: ${this.props.contact.email}`
																							: 'No Email'}
																					</p>
																				</div>
																			)}
																		</Segment>
																	) : field.time ? (
																		<Popup
																			trigger={
																				<div>
																					<Header.Content>
																						<h5>{field.name}</h5>
																					</Header.Content>
																					<Label
																						style={{
																							width: '100%',
																							backgroundColor:
																								'transparent',
																							padding: '1vh 1vw 1vh 0'
																						}}
																						// size="big"
																					>
																						{field.value !== '' &&
																						field.value
																							? moment
																									.utc(field.value)
																									.local()
																									.format(
																										'MM-DD-YYYY HH:mm'
																									)
																							: 'Pick Date'}
																					</Label>
																				</div>
																			}
																			on={['hover', 'click']}
																			flowing
																			hoverable
																		>
																			<MomentDatePicker
																				inline
																				className={'workOrderDatePicker'}
																				showTimeSelect
																				timeFormat="HH:mm"
																				timeIntervals={15}
																				dateFormat="LLL"
																				timeCaption="time"
																				selected={
																					field.value
																						? moment
																								.utc(field.value)
																								.local()
																						: moment.utc().local()
																				}
																				onChange={(date) => {
																					this.props.updateWorkViewTrip(
																						this.props.manageAASDI
																							.selectedTripId,
																						field.name === 'Requested Date'
																							? 'requested'
																							: 'actual',
																						date.utc().format(),
																						index,
																						sectionIndex,
																						fieldIndex
																					);
																				}}
																			/>
																		</Popup>
																	) : field.input ? (
																		<DebounceInput
																			element={Input}
																			fluid
																			transparent
																			value={field.value}
																			className={'hiddenInput'}
																			debounceTimeout={600}
																			onChange={(e) => {
																				this.props.updateAasdiInfoSection(
																					e.target.value,
																					index,
																					sectionIndex,
																					fieldIndex
																				);
																			}}
																		/>
																	) : field.dropdown ? (
																		<Dropdown
																			fluid
																			value={field.value}
																			options={field.options}
																			className={'hiddenInput'}
																			onChange={(e, {value}) => {
																				this.props.updateAasdiInfoSection(
																					value,
																					index,
																					sectionIndex,
																					fieldIndex
																				);
																			}}
																		/>
																	) : field.textArea ? (
																		<Segment basic>
																			{field.saved ? null : (
																				<Label
																					attached="top right"
																					color="red"
																					size="small"
																				>
																					Unsaved
																				</Label>
																			)}
																			<div
																				style={{
																					height: 400
																				}}
																			>
																				<FancyEditor
																					key={`${this.props.manageAASDI.selectedTripId}${field.name}`}
																					onSave={() => {
																						this.props.updateAasdiInfoSection(
																							field.name === 'AASDI SOW'
																								? this.state.sowValue
																								: this.state.specialValue,
																							index,
																							sectionIndex,
																							fieldIndex
																						);
																						this.props.updateWorkViewTrip(
																							this.props.manageAASDI
																								.selectedTripId,
																							field.name === 'AASDI SOW'
																								? 'sow'
																								: 'special',
																							field.name === 'AASDI SOW'
																								? this.state.sowValue
																								: this.state.specialValue,
																							index,
																							sectionIndex,
																							fieldIndex
																						);
																						this.props.setTripTextAreaSaved(
																							true,
																							index,
																							sectionIndex,
																							fieldIndex
																						);
																					}}
																					value={field.name === 'AASDI SOW' ? this.state.sowValue : this.state.specialValue}
																					onChange={(value) => {
																						if (
																							field.name === 'AASDI SOW'
																						) {
																							this.setState(() => {
																								return {
																									sowValue: value
																								};
																							});
																						} else if (
																							field.name ===
																							'Special Instructions'
																						) {
																							this.setState(() => {
																								return {
																									specialValue: value
																								};
																							});
																						}
																						if (
																							value ===
																							this.props.manageAASDI
																								.infoSection[index]
																								.sections[sectionIndex]
																								.fields[fieldIndex]
																								.value
																						) {
																							this.props.setTripTextAreaSaved(
																								true,
																								index,
																								sectionIndex,
																								fieldIndex
																							);
																						} else {
																							this.props.setTripTextAreaSaved(
																								false,
																								index,
																								sectionIndex,
																								fieldIndex
																							);
																						}
																					}}
																				/>
																			</div>
																		</Segment>
																	) : null}
																</Grid.Column>
															);
														})}
													</Grid>
												</Grid.Row>
											);
										}
									})}
								</Grid.Column>
							);
						})}
					</Grid>
				</Segment>
			</div>
		);
	}
}

AasdiInfo.propTypes = {
	selectedAASDI: PropTypes.object,
	sections: PropTypes.object,
	contact: PropTypes.object,
	updateAasdiInfoSection: PropTypes.func,
	updateWorkViewTrip: PropTypes.func,
	manageAASDI: PropTypes.object,
	setTripTextAreaSaved: PropTypes.func,
	selectedTripId: PropTypes.number,
	workAasdiIdCheck: PropTypes.number,
	selectedWorkAasdiId: PropTypes.number,
	updateTripContact: PropTypes.func,
	getContactsOptions: PropTypes.func,
	options: PropTypes.array,
	tripIdCheck: PropTypes.number,
	clearContactOptions: PropTypes.func,
	setTripIdCheck: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		options: state.work.workRecord.view.manageAASDI.createTrip.modal.contactOptions,
		selectedAASDI:
			state.work.workRecord.view.manageAASDI.aasdis[
				state.work.workRecord.view.manageAASDI.aasdis.findIndex((aasdi) => aasdi.selected === true)
			],
		manageAASDI: state.work.workRecord.view.manageAASDI,
		sections: state.work.workRecord.view.manageAASDI.infoSection,
		contact: state.work.workRecord.view.manageAASDI.contactObject,
		selectedTripId: state.work.workRecord.view.manageAASDI.selectedTripId,
		tripIdCheck: state.work.workRecord.view.manageAASDI.tripIdCheck,
		workAasdiIdCheck: state.work.workRecord.view.manageAASDI.workAasdiIdCheck,
		selectedWorkAasdiId:
			state.work.workRecord.view.manageAASDI.aasdis[
				state.work.workRecord.view.manageAASDI.aasdis.findIndex((aasdi) => aasdi.selected === true)
			].workAasdiId,
		record: state.work.workRecord.view.record
	};
};

export default connect(mapStateToProps, actions)(AasdiInfo);
