import React from 'react';
import PropTypes from 'prop-types';
import {Segment, Grid, Label, Input, Button, Popup, Dropdown, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import InputField from '../components/inputField.js';
import DropdownField from '../components/dropdownField.js';
import {DebounceInput} from 'react-debounce-input';
import {getLocations, getBillingContacts, updateCreateProjectModal} from '../../services/createProjectActions.js';
import {toggleBillingContactModal} from '../../../../../../../../components/billingContactModal/services/billingContactModalActions.js';
import BillingContactModal from '../../../../../../../../components/billingContactModal/billingContactModal.js';
import {toggleCreateLocationModal} from '../../../../../../../../components/createLocationModal/services/createLocationModalActions.js';
import CreateLocationModal from '../../../../../../../../components/createLocationModal/createLocationModal.js';
//import { debounceEventHandler } from '../../../../../../../../services/debounce.js';

class BillingDetailsSection extends React.Component {
	render() {
		let selectedLocation = this.props.location.dataList.filter(
			(location) => location.id === this.props.location.value
		)[0];

		let selectedBillingContact = this.props.billingContact.dataList.filter(
			(contact) => contact.fullName === this.props.billingContact.value
		)[0];

		return (
			<div>
				<Segment basic>
					<Grid>
						<Grid.Row>
							<Grid.Column width={this.props.location.columnWidth}>
								<div>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											{this.props.location.name}
										</Label>
									</div>
									<div>
										<Dropdown
											selection
											search
											onSearchChange={(e) => {
												this.props.updateCreateProjectModal(
													'createProjectModal',
													this.props.sectionIndex, // 2
													this.props.sectionFields.findIndex(
														(field) => field.name === this.props.location.name
													), // 1
													e.target.value,
													false
												);
												this.props.getLocations(
													this.props.sectionIndex,
													this.props.sectionFields.findIndex(
														(field) => field.name === this.props.location.name
													),
													this.props.clientId,
													e.target.value,
													false
												);
											}}
											onChange={(e, {value}) => {
												this.props.updateCreateProjectModal(
													'createProjectModal',
													this.props.sectionIndex, // 2
													this.props.sectionFields.findIndex(
														(field) => field.name === this.props.location.name
													), // 1
													value,
													false
												);
											}}
											value={this.props.location.value}
											text={
												this.props.location.value === 'Various Locations'
													? 'Various Locations'
													: null
											}
											error={
												this.props.location.required
													? Boolean(this.props.location.error)
													: false
											}
											loading={this.props.location.loading}
											disabled={this.props.location.value === 'Various Locations'}
											style={{
												width: '90%'
											}}
											dataList={this.props.location.dataList}
											options={this.props.location.dataList.map((location) =>
												this.props.location.searchLocations
													? {
															key: location.id,
															value: location.id,
															text: `${location.name}, ${
																location.storeNumber ? location.storeNumber : ''
															}, ${location.city ? location.city : ''}, ${
																location.state ? location.state : ''
															}`,
															content: (
																<Header
																	size="tiny"
																	content={location.name}
																	subheader={`Store number: ${location.storeNumber}, City: ${location.city}, State: ${location.state}`}
																/>
															)
													  }
													: {
															key: location.id,
															value: location.id,
															text: location.name,
															content: (
																<Header
																	size="tiny"
																	content={location.name}
																	subheader={`Store number: ${location.storeNumber}, City: ${location.city}, State: ${location.state}`}
																/>
															)
													  }
											)}
										/>
										<Popup
											content="Create Location"
											trigger={
												<Button
													style={{
														display: 'inline-block',
														margin: '0',
														width: '10%',
														textAlign: 'center',
														lineHeight: '1.1'
													}}
													onClick={() =>
														this.props.toggleCreateLocationModal(
															this.props.oppId,
															this.props.clientId
														)
													}
													attached="right"
													color="green"
													icon="plus"
												/>
											}
										/>
									</div>
									{// Appears below dropdown when a location has been selected
									selectedLocation ? (
										// Various Sites check
										selectedLocation.name === 'Various Locations' ? null : (
											<ul
												style={{
													listStyle: 'none',
													paddingLeft: '0em',
													marginTop: '1em',
													marginBottom: '0'
												}}
											>
												<li
													style={{
														marginTop: '.3em'
													}}
												>
													<span
														style={{
															fontWeight: 'bold'
														}}
													>
														Name:
													</span>
													{` ${selectedLocation.name}`}
												</li>
												<li
													style={{
														marginTop: '.3em'
													}}
												>
													<span
														style={{
															fontWeight: 'bold'
														}}
													>
														Site:
													</span>
													{` ${selectedLocation.city}, ${selectedLocation.state}`}
												</li>
												<li
													style={{
														marginTop: '.3em'
													}}
												>
													<span
														style={{
															fontWeight: 'bold'
														}}
													>
														Address:
													</span>
													{` ${selectedLocation.address} ${
														selectedLocation.address2 ? selectedLocation.address2 : ''
													}, ${selectedLocation.city}, ${selectedLocation.state}, ${
														selectedLocation.zip
													}, ${selectedLocation.countryName}`}
												</li>
												<li
													style={{
														marginTop: '.3em'
													}}
												>
													<span
														style={{
															fontWeight: 'bold'
														}}
													>
														Site Contact:
													</span>
													{` ${
														selectedLocation.siteContactName
															? selectedLocation.siteContactName
															: ''
													}`}
												</li>
											</ul>
										)
									) : null}
								</div>
							</Grid.Column>
							<Grid.Column width={1} />
							<Grid.Column width={this.props.billingContact.columnWidth}>
								<div>
									<div
										style={{
											paddingTop: '0.8em'
										}}
									>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											{this.props.billingContact.name}
										</Label>
									</div>
									<DebounceInput
										icon="search"
										iconPosition="left"
										element={Input}
										list="billingContactList"
										onChange={(e) => {
											this.props.updateCreateProjectModal(
												'createProjectModal',
												this.props.sectionIndex,
												this.props.sectionFields.findIndex(
													(field) => field.name === this.props.billingContact.name
												),
												e.target.value,
												false
											);
											this.props.getBillingContacts(
												this.props.sectionIndex,
												this.props.sectionFields.findIndex(
													(field) => field.name === this.props.billingContact.name
												),
												this.props.clientId,
												e.target.value,
												false
											);
										}}
										loading={this.props.billingContact.loading}
										debounceTimeout={600}
										value={this.props.billingContact.value}
										style={{
											width: '90%'
										}}
										error={
											this.props.billingContact.required
												? Boolean(this.props.billingContact.error)
												: false
										}
										disabled={this.props.billingContact.disabled}
										placeholder={this.props.billingContact.placeholder}
									/>
									<Popup
										content="Create Contact"
										trigger={
											<Button
												style={{
													display: 'inline-block',
													margin: '0',
													width: '10%',
													textAlign: 'center',
													lineHeight: '1.1'
												}}
												attached="right"
												color="green"
												icon="plus"
												onClick={() =>
													this.props.toggleBillingContactModal(
														this.props.oppId,
														this.props.clientId
													)
												}
											/>
										}
									/>
									<datalist id="billingContactList">
										{this.props.billingContact.dataList.map((option, index) => (
											<option key={index} value={option.fullName} />
										))}
									</datalist>
									{selectedBillingContact ? (
										<ul
											style={{
												listStyle: 'none',
												paddingLeft: '0em',
												marginTop: '1em',
												marginBottom: '0'
											}}
										>
											<li style={{marginTop: '.3em'}}>
												<span
													style={{
														fontWeight: 'bold'
													}}
												>
													Name:
												</span>
												{` ${selectedBillingContact.fullName}`}
											</li>
											<li style={{marginTop: '.3em'}}>
												<span
													style={{
														fontWeight: 'bold'
													}}
												>
													Title:
												</span>
												{` ${selectedBillingContact.title}`}
											</li>
											<li style={{marginTop: '.3em'}}>
												<span
													style={{
														fontWeight: 'bold'
													}}
												>
													Email:
												</span>
												{` ${selectedBillingContact.email}`}
											</li>
											<li style={{marginTop: '.3em'}}>
												<span
													style={{
														fontWeight: 'bold'
													}}
												>
													Phone:
												</span>
												{` ${selectedBillingContact.phone}`}
											</li>
										</ul>
									) : null}
								</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={this.props.googleDrive.columnWidth}>
								<InputField
									field={this.props.googleDrive}
									sectionIndex={this.props.sectionIndex}
									fieldIndex={this.props.sectionFields.findIndex(
										(field) => field.name === this.props.googleDrive.name
									)}
								/>
							</Grid.Column>
							<Grid.Column width={1} />
							<Grid.Column width={this.props.newConstruction.columnWidth}>
								<DropdownField
									field={this.props.newConstruction}
									sectionIndex={this.props.sectionIndex}
									fieldIndex={this.props.sectionFields.findIndex(
										(field) => field.name === this.props.newConstruction.name
									)}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
				<BillingContactModal type={'project'} />
				<CreateLocationModal type={'project'} />
			</div>
		);
	}
}

BillingDetailsSection.propTypes = {
	location: PropTypes.object,
	clientId: PropTypes.number,
	sectionIndex: PropTypes.number,
	sectionFields: PropTypes.array,
	getLocations: PropTypes.func,
	updateCreateProjectModal: PropTypes.func,
	oppId: PropTypes.number,
	toggleCreateLocationModal: PropTypes.func,
	getBillingContacts: PropTypes.func,
	billingContact: PropTypes.object,
	toggleBillingContactModal: PropTypes.func,
	googleDrive: PropTypes.object,
	newConstruction: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.crm.createProject.createProjectModal.sections.findIndex(
			(section) => section.name === 'billingDetailsSection'
		),
		sectionFields: state.crm.createProject.createProjectModal.sections.filter(
			(section) => section.name === 'billingDetailsSection'
		)[0].fields,
		oppId: state.crm.createProject.createProjectModal.id,
		clientId: state.crm.createProject.createProjectModal.clientId,
		location: state.crm.createProject.createProjectModal.sections
			.filter((section) => section.name === 'billingDetailsSection')[0]
			.fields.filter((field) => field.name === 'Location')[0],
		billingContact: state.crm.createProject.createProjectModal.sections
			.filter((section) => section.name === 'billingDetailsSection')[0]
			.fields.filter((field) => field.name === 'Billing Contact')[0],
		googleDrive: state.crm.createProject.createProjectModal.sections
			.filter((section) => section.name === 'billingDetailsSection')[0]
			.fields.filter((field) => field.name === 'Google Drive Link')[0],
		newConstruction: state.crm.createProject.createProjectModal.sections
			.filter((section) => section.name === 'billingDetailsSection')[0]
			.fields.filter((field) => field.name === 'New Construction')[0]
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getLocations: (sectionIndex, fieldIndex, clientId, value, error) => {
			dispatch(getLocations(sectionIndex, fieldIndex, clientId, value, error));
		},
		getBillingContacts: (sectionIndex, fieldIndex, clientId, value, error) => {
			dispatch(getBillingContacts(sectionIndex, fieldIndex, clientId, value, error));
		},
		updateCreateProjectModal: (modal, sectionIndex, fieldIndex, value, error) => {
			dispatch(updateCreateProjectModal(modal, sectionIndex, fieldIndex, value, error));
		},
		toggleCreateLocationModal: (id, clientId) => {
			dispatch(toggleCreateLocationModal(id, clientId));
		},
		toggleBillingContactModal: (id, clientId) => {
			dispatch(toggleBillingContactModal(id, clientId));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetailsSection);
