import React from 'react';
import {Grid, Card} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import BuildGroup from './BuildGroup.js';

let VerbalAward = ({opportunities, fetched}) => (
	<Grid.Column key={'VerbalAward'} className="gridColumn">
		<Card.Group itemsPerRow={1}>{BuildGroup(opportunities, fetched)}</Card.Group>
	</Grid.Column>
);

VerbalAward.propTypes = {
	opportunities: PropTypes.string.isRequired,
	fetched: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	return {
		opportunities: state.crm.pipeline.data.opportunities
			? state.crm.pipeline.data.opportunities.filter((row) => row.stageName === 'Verbal Award')
			: [],
		fetched: state.crm.pipeline.data.fetched
	};
};

export default connect(mapStateToProps)(VerbalAward);
