/* eslint-disable */
/**
 * @deprecated use lodash debounce
 */
export const debounce = (a, b, c) => {
	let d, e;
	return function() {
		function h() {
			(d = null), c || (e = a.apply(f, g));
		}
		let f = this,
			g = arguments;
		return clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e;
	};
};

/**
 * @deprecated use lodash debounce
 * events dont need to be presisted anymore. React removed the mutability of react events because of confusion.
 */
export const debounceEventHandler = (debouncedFunction) => {
	const debounced = debounce(debouncedFunction);
	return function(e) {
		e.persist();
		return debounced(e);
	};
};
