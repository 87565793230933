import {Transition, Card} from 'semantic-ui-react';
import React from 'react';
// import PropTypes from 'prop-types';
import TaskPanel from './Panel_Task.js';
import SectionPanel from './Panel_Section.js';
import TemplateInfoPanel from './Panel_TemplateInfo.js';

const RightPanel = (props) => {
	let task;

	switch (props.mode) {
		case 'templateManager':
			if (!props.tasks.currentTemplate.id) {
				task = {
					completed: false
				};
				break;
			}
			switch (props.tasks.ui.panel.toggle) {
				case true:
					switch (props.tasks.ui.panel.isSectionTask) {
						case true:
							task =
								props.tasks.currentTemplate.tasks[props.tasks.ui.panel.category][
									props.tasks.ui.panel.sectionIndex
								].tasks[props.tasks.ui.panel.sectionCategory][props.tasks.ui.panel.index];
							break;

						case false:
							task =
								props.tasks.currentTemplate.tasks[props.tasks.ui.panel.category][
									props.tasks.ui.panel.index
								];
							break;
					}
					break;

				case false:
					task = {
						completed: false
					};
					break;
			}
			break;

		case 'workOrder':
			switch (props.tasks.ui.panel.toggle) {
				case true:
					switch (props.tasks.ui.panel.isSectionTask) {
						case true:
							task =
								props.tasks.aasdis[props.tasks.ui.panel.aasdiIndex].taskGroups[
									props.tasks.ui.panel.groupIndex
								].tasks[props.tasks.ui.panel.category][props.tasks.ui.panel.sectionIndex].tasks[
									props.tasks.ui.panel.sectionCategory
								][props.tasks.ui.panel.index];
							break;

						case false:
							task =
								props.tasks.aasdis[props.tasks.ui.panel.aasdiIndex].taskGroups[
									props.tasks.ui.panel.groupIndex
								].tasks[props.tasks.ui.panel.category][props.tasks.ui.panel.index];
							break;
					}
					break;

				case false:
					task = {
						completed: false
					};
					break;
			}
			break;
	}

	props = {
		...props,
		...props.tasks.ui.panel,
		task: task
	};

	return (
		<Transition.Group animation="fade left" duration={500}>
			{props.tasks.ui.panel.toggle && (
				<Card fluid>
					{(() => {
						switch (props.tasks.ui.panel.type) {
							case 'template':
								return <TemplateInfoPanel {...props} />;

							case 'task':
								return <TaskPanel {...props} task={task} />;

							case 'section':
								return <SectionPanel {...props} task={task} isSection />;
						}
					})()}
				</Card>
			)}
		</Transition.Group>
	);
};
export default RightPanel;
