import React from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';

function LoadingSpinner(props) {
	const {loadingMessage} = props;

	return (
		<Dimmer
			inverted
			active
			style={{
				position: 'static'
			}}
		>
			<Loader size="huge">{loadingMessage}</Loader>
		</Dimmer>
	);
}

export default LoadingSpinner;
