import {Route, Switch, Redirect} from 'react-router-dom'
import {Login} from './Login';
import Registration from './legacy/Registration.jsx';
import {Form, Segment} from 'semantic-ui-react';
import OmniLogo from '~/img/logo-blue.png';

export function AuthView() {
	return <div className="omni-login-page">
		<img src={OmniLogo} alt="Omni Logo" className="omni-login-page-logo" />
		<Form size="large" className="omni-login-page-form">
			<Segment>
				<Switch>
					<Route path="/auth/register">
						<Registration />
					</Route>
					<Route path="/auth/login">
						<Login />
					</Route>
					<Redirect to="/auth/login" />
				</Switch>
			</Segment>
		</Form>
	</div>
}