import React from 'react';
import {Message, Icon} from 'semantic-ui-react';

function UserCreationErrorMessage(props) {
	if (props.display) {
		return (
			<Message icon error style={{position: 'sticky', top: 0, zIndex: 10}}>
				<Icon name="x" />
				<Message.Content>An error occured while creating/updating user. {props.message}</Message.Content>
			</Message>
		);
	}

	return null;
}

export default UserCreationErrorMessage;
