import React, {Component} from 'react';
import {Label, Segment, Grid, Input, Divider, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {DebounceInput} from 'react-debounce-input';
import moment from 'moment';
import api from '../../../../../../lib/api.ts';
import {
	toggleCreateContactModal,
	toggleFetchingOnTabsNavbar
} from '../../../../../../components/Global/services/globalActions.js';
import {getTabRequestItems, setAasdiProfileName, clearGrid, setAasdiData} from '../services/profileAasdiGridActions.js';

import {MomentDatePicker} from '../../../../../../components/inputs/DatePicker.jsx';

const companyDetailsFields = [
	{
		key: 'yearFounded',
		label: 'Year Founded',
		placeholder: 'Year',
		inputType: 'number'
	},
	{
		key: 'federalTaxId',
		label: 'Tax ID',
		placeholder: 'Tax ID',
		inputType: 'text'
	},
	{
		key: 'unions',
		label: 'Union',
		placeholder: 'Union',
		inputType: 'text'
	},
	{
		key: 'annualRevenue',
		label: 'Annual Revenue ($)',
		placeholder: 'Annual Revenue',
		inputType: 'number'
	}
];

const licensesFields = [
	{
		key: 'licensedMunicipalities',
		label: 'Muncipality License',
		placeholder: 'Muncipality License',
		inputType: 'text'
	},
	{
		key: 'lowVoltageState',
		label: 'Low Voltage State',
		placeholder: 'Low Voltage State',
		inputType: 'text'
	},
	{
		key: 'lowVoltageRequired',
		label: 'Low Voltage Required',
		placeholder: 'Low Voltage Required',
		inputType: 'text'
	},
	{
		key: 'lowVoltageExpirationDate',
		label: 'Low Voltage Expiration Date',
		placeholder: 'Expiration Date',
		inputType: 'date'
	},
	{
		key: 'electricalState',
		label: 'Electrical State',
		placeholder: 'Electrical State',
		inputType: 'text'
	},
	{
		key: 'electricalRequired',
		label: 'Electrical Required',
		placeholder: 'Electrical Required',
		inputType: 'text'
	},
	{
		key: 'electricalExpirationDate',
		label: 'Electrical Expiration Date',
		placeholder: 'Expiration Date',
		inputType: 'date'
	}
];

const staffFields = [
	{
		key: 'projectManagers',
		label: 'Project Managers',
		placeholder: 'Project Managers',
		inputType: 'number'
	},
	{
		key: 'leadTechs',
		label: 'Lead Technicians',
		placeholder: 'Lead Technicians',
		inputType: 'number'
	},
	{
		key: 'technicians',
		label: 'Technicians',
		placeholder: 'Technicians',
		inputType: 'number'
	},
	{
		key: 'rcdds',
		label: `RCDD's`,
		placeholder: `RCDD's`,
		inputType: 'number'
	}
];

const liabilityInsurance = [
	{
		key: 'liabilityCompanyName',
		label: 'Company Name',
		placeholder: 'Company Name',
		inputType: 'text'
	},
	{
		key: 'liabilityPolicyNumber',
		label: 'Policy Number',
		placeholder: 'Policy Number',
		inputType: 'number'
	},
	{
		key: 'liabilityBestRating',
		label: 'Best Rating',
		placeholder: 'Best Rating',
		inputType: 'number'
	},
	{
		key: 'liabilityExpirationDate',
		label: 'Expiration Date',
		placeholder: 'Expiration Date',
		inputType: 'date'
	}
];

const workersCompInsuranceFields = [
	{
		key: 'workersCompanyName',
		label: 'Company Name',
		placeholder: 'Company Name',
		inputType: 'text'
	},
	{
		key: 'workersPolicyNumber',
		label: 'Policy Number',
		placeholder: 'Policy Number',
		inputType: 'number'
	},
	{
		key: 'workersBestRating',
		label: 'Best Rating',
		placeholder: 'Best Rating',
		inputType: 'number'
	},
	{
		key: 'workersExpirationDate',
		label: 'Expiration Date',
		placeholder: 'Expiration Date',
		inputType: 'date'
	}
];

class Overview extends Component {
	constructor(props) {
		super(props);
		this.updateAASDIInfo = this.updateAASDIInfo.bind(this);
	}

	componentDidMount() {
		// http://localhost:3000/work/dashboard/aasdi/profile/10046
		const {clearGrid, toggleFetchingOnTabsNavbar} = this.props;
		toggleFetchingOnTabsNavbar(false);
		clearGrid();
	}

	updateAASDIInfo(value, key) {
		const {aasdiData, setAasdiData, toggleFetchingOnTabsNavbar} = this.props;
		const id = aasdiData[0].id;
		const body = {};
		body[key] = value;
		toggleFetchingOnTabsNavbar(true);
		api(`legacy/aasdi/${id}`, 'put', body)
			.then((response) => {
				toggleFetchingOnTabsNavbar(false);
				setAasdiData([response]);
			})
			.catch((error) => {
				toggleFetchingOnTabsNavbar(false);
				alert('Error while updating info!');
				console.error(error);
			});
	}

	render() {
		const {aasdiData} = this.props;
		return (
			<Segment>
				<Grid columns={2} style={{margin: '2.5em'}}>
					<Grid.Row>
						<Grid.Column width={16}>
							<Segment style={{minHeight: '200px'}}>
								<Label color="blue" ribbon>
									Licenses
								</Label>
								<Divider />
								<Grid>
									<Grid.Row>
										{licensesFields.map((field, index) =>
											field.inputType !== 'date' ? (
												<Grid.Column
													style={{margin: '1.5vh'}}
													width={3}
													key={`licenses${index}`}
												>
													<Header as="h5">
														<Header.Content>{field.label}</Header.Content>
													</Header>
													<DebounceInput
														element={Input}
														fluid
														transparent
														className="hiddenInput"
														type={field.inputType}
														onChange={(e) => {
															this.updateAASDIInfo(e.target.value, field.key);
														}}
														debounceTimeout={600}
														placeholder={field.placeholder}
														value={aasdiData[0][field.key]}
													/>
												</Grid.Column>
											) : (
												<Grid.Column
													style={{margin: '1.5vh'}}
													width={3}
													key={`licenses${index}`}
												>
													<Header size="tiny">
														{field.label}
														<Input
															fluid
															className="hiddenInput"
															style={{
																position: 'relative',
																top: '12px'
															}}
														>
															<MomentDatePicker
																selected={moment(aasdiData[0][field.key])}
																placeholderText="Select a date"
																onChange={(date) => {
																	this.updateAASDIInfo(
																		date.format('MM-DD-YYYY').toString(),
																		field.key
																	);
																}}
															/>
														</Input>
													</Header>
												</Grid.Column>
											)
										)}
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={8}>
							<Segment style={{minHeight: '200px'}}>
								<Label color="blue" ribbon>
									Company Details
								</Label>
								<Divider />
								<Grid>
									<Grid.Row>
										{companyDetailsFields.map((field, index) =>
											field.inputType !== 'date' ? (
												<Grid.Column
													style={{
														margin: '1.5vh'
													}}
													width={6}
													key={`company_details${index}`}
												>
													<Header as="h5">
														<Header.Content>{field.label}</Header.Content>
													</Header>
													<DebounceInput
														element={Input}
														fluid
														transparent
														className="hiddenInput"
														type={field.inputType}
														onChange={(e) => {
															this.updateAASDIInfo(e.target.value, field.key);
														}}
														debounceTimeout={600}
														placeholder={field.placeholder}
														value={aasdiData[0][field.key]}
													/>
												</Grid.Column>
											) : (
												<Grid.Column
													style={{
														margin: '1.5vh'
													}}
													width={6}
													key={`company_details${index}`}
												>
													<Header size="tiny">
														{field.label}
														<Input
															fluid
															className="hiddenInput"
															style={{
																position: 'relative',
																top: '12px'
															}}
														>
															<MomentDatePicker
																selected={moment(aasdiData[0][field.key])}
																placeholderText="Select a date"
																onChange={(date) => {
																	this.updateAASDIInfo(
																		date.format('MM-DD-YYYY').toString(),
																		field.key
																	);
																}}
															/>
														</Input>
													</Header>
												</Grid.Column>
											)
										)}
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
						<Grid.Column width={8}>
							<Segment style={{minHeight: '200px'}}>
								<Label color="blue" ribbon>
									Staff
								</Label>
								<Divider />
								<Grid>
									<Grid.Row>
										{staffFields.map((field, index) =>
											field.inputType !== 'date' ? (
												<Grid.Column style={{margin: '1.5vh'}} width={6} key={`staff${index}`}>
													<Header as="h5">
														<Header.Content>{field.label}</Header.Content>
													</Header>
													<DebounceInput
														element={Input}
														fluid
														transparent
														className="hiddenInput"
														type={field.inputType}
														onChange={(e) => {
															this.updateAASDIInfo(e.target.value, field.key);
														}}
														debounceTimeout={600}
														placeholder={field.placeholder}
														value={aasdiData[0][field.key]}
													/>
												</Grid.Column>
											) : (
												<Grid.Column style={{margin: '1.5vh'}} width={6} key={`staff${index}`}>
													<Header size="tiny">
														{field.label}
														<Input
															fluid
															className="hiddenInput"
															style={{
																position: 'relative',
																top: '12px'
															}}
														>
															<MomentDatePicker
																selected={moment(aasdiData[0][field.key])}
																placeholderText="Select a date"
																onChange={(date) => {
																	this.updateAASDIInfo(
																		date.format('MM-DD-YYYY').toString(),
																		field.key
																	);
																}}
															/>
														</Input>
													</Header>
												</Grid.Column>
											)
										)}
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={8}>
							<Segment style={{minHeight: '200px'}}>
								<Label color="blue" ribbon>
									Liability Insurance
								</Label>
								<Divider />
								<Grid>
									<Grid.Row>
										{liabilityInsurance.map((field, index) =>
											field.inputType !== 'date' ? (
												<Grid.Column
													style={{
														margin: '1.5vh'
													}}
													width={6}
													key={`liability_insurance${index}`}
												>
													<Header as="h5">
														<Header.Content>{field.label}</Header.Content>
													</Header>

													<DebounceInput
														element={Input}
														fluid
														transparent
														className="hiddenInput"
														type={field.inputType}
														onChange={(e) => {
															this.updateAASDIInfo(e.target.value, field.key);
														}}
														debounceTimeout={600}
														placeholder={field.placeholder}
														value={aasdiData[0][field.key]}
													/>
												</Grid.Column>
											) : (
												<Grid.Column
													style={{
														margin: '1.5vh'
													}}
													width={6}
													key={`liability_insurance${index}`}
												>
													<Header size="tiny">
														{field.label} - Disabled
														{/* disabled because backend functionality is also disabled */}
														<Input
															disabled
															fluid
															className="hiddenInput"
															style={{
																position: 'relative',
																top: '12px'
															}}
															title="This field is currently disabled."
														>
															<MomentDatePicker
																disabled
																selected={moment(aasdiData[0][field.key])}
																placeholderText="Select a date"
																onChange={(date) => {
																	this.updateAASDIInfo(
																		date.format('MM-DD-YYYY').toString(),
																		field.key
																	);
																}}
															/>
														</Input>
													</Header>
												</Grid.Column>
											)
										)}
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
						<Grid.Column width={8}>
							<Segment style={{minHeight: '200px'}}>
								<Label color="blue" ribbon>
									Workers Comp Insurance
								</Label>
								<Divider />
								<Grid>
									<Grid.Row>
										{workersCompInsuranceFields.map((field, index) =>
											field.inputType !== 'date' ? (
												<Grid.Column
													style={{
														margin: '1.5vh'
													}}
													width={6}
													key={`workers_comp_insurance${index}`}
												>
													<Header as="h5">
														<Header.Content>{field.label}</Header.Content>
													</Header>
													<DebounceInput
														element={Input}
														fluid
														transparent
														className="hiddenInput"
														type={field.inputType}
														onChange={(e) => {
															this.updateAASDIInfo(e.target.value, field.key);
														}}
														debounceTimeout={600}
														placeholder={field.placeholder}
														value={aasdiData[0][field.key]}
													/>
												</Grid.Column>
											) : (
												<Grid.Column
													style={{
														margin: '1.5vh'
													}}
													width={6}
													key={`workers_comp_insurance${index}`}
												>
													<Header size="tiny">
														{field.label} - Disabled
														{/* disabled because backend functionality is also disabled */}
														<Input
															disabled
															fluid
															className="hiddenInput"
															style={{
																position: 'relative',
																top: '12px'
															}}
														>
															<MomentDatePicker
																disabled
																selected={moment(aasdiData[0][field.key])}
																placeholderText="Select a date"
																onChange={(date) => {
																	this.updateAASDIInfo(
																		date.format('MM-DD-YYYY').toString(),
																		field.key
																	);
																}}
															/>
														</Input>
													</Header>
												</Grid.Column>
											)
										)}
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		);
	}
}

Overview.propTypes = {
	profileAasdiGridHeader: PropTypes.array,
	aasdiData: PropTypes.array,
	aasdiId: PropTypes.string,
	aasdiProfileInfo: PropTypes.array,
	clearGrid: PropTypes.func,
	getTabRequestItems: PropTypes.func,
	location: PropTypes.array,
	profileAasdiGridData: PropTypes.array,
	setAasdiProfileName: PropTypes.func,
	setAasdiData: PropTypes.func,
	toggleCreateContactModal: PropTypes.func,
	toggleFetchingOnTabsNavbar: PropTypes.func
};

const mapStateToProps = (state) => ({
	profileAasdiGridData: state.work.aasdiProfileGrid.rowData,
	aasdiProfileInfo: state.work.aasdiProfileGrid.profileInfos,
	profileAasdiGridHeader: state.work.aasdiProfileGrid.headerData,
	aasdiData: state.work.aasdiProfileGrid.aasdiData
});

const mapDispatchToProps = (dispatch) => ({
	getTabRequestItems: (aasdiId, item) => dispatch(getTabRequestItems(aasdiId, item)),
	toggleCreateContactModal: (status) => dispatch(toggleCreateContactModal(status)),
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status)),
	clearGrid: (item) => dispatch(clearGrid(item)),
	setAasdiProfileName: (name) => dispatch(setAasdiProfileName(name)),
	setAasdiData: (data) => dispatch(setAasdiData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
