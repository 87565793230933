import {Label, Popup} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

const CompletedLabels = (props) => (
	<Popup
		key={uuidv4()}
		size={'tiny'}
		trigger={
			<Label
				color={props.task.pendingChanges ? 'yellow' : props.task.completed ? 'green' : 'red'}
				icon={props.task.pendingChanges ? 'warning sign' : props.task.completed ? 'check' : 'x'}
				content={
					props.task.pendingChanges
						? ' Pending Changes'
						: props.task.completed
						? ' Completed'
						: ' Not Completed'
				}
			/>
		}
		content="Displays if the task has been completed or not."
		on="hover"
	/>
);
export default CompletedLabels;

CompletedLabels.propTypes = {
	task: PropTypes.object.isRequired
};
