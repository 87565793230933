import React from 'react';
import {Route, Switch} from 'react-router-dom';
import TechManagement from './scenes/TechManagement/TechManagement.js';
import TaskTemplates from './scenes/TaskTemplates/TaskTemplates.jsx';
import CRM from './scenes/crm/crm.js';
import AccountingConsole from './scenes/accountingConsole/accountingConsole.js';
import Work from './scenes/work/work.js';
import {NavBar} from './scenes/navBar/NavBar.jsx';
import LandingPage from './components/landingPage.js';
import DesyncedJobs from './components/DesyncedJobs/DesyncedJobs.jsx';

import {BugReportModal} from './scenes/BugReportModal.tsx';

import {AuthGate} from '~/scenes/auth/AuthGate';
import {AuthView} from '~/scenes/auth/AuthView';

export function App() {
	return (
		<>
			<Route path="/auth">
				<AuthView />
			</Route>
			<AuthGate redirect="/auth/login">
				<NavBar />
				<Switch>
					<Route exact path="/">
						<LandingPage />
					</Route>
					<Route path="/techManagement">
						<TechManagement />
					</Route>
					<Route path="/task-templates">
						<TaskTemplates />
					</Route>
					<Route path="/crm">
						<CRM />
					</Route>
					{/* TODO: rename to approvals */}
					<Route path="/accountingConsole">
						<AccountingConsole />
					</Route>
					<Route path="/work">
						<Work />
					</Route>
					<Route path="/desynced">
						<DesyncedJobs />
					</Route>
				</Switch>

				<BugReportModal />
			</AuthGate>
		</>
	);
}

export default App;
