import {Message, Icon} from 'semantic-ui-react';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from './services/globalActions.js';

const Messages = (props) => (
	<Message
		hidden={props.hideMessageValue}
		success={props.success}
		error={props.error}
		warning={props.warning}
		className={'messageMargin'}
		onDismiss={() => props.hideMessage()}
	>
		<Icon name={props.icon} />
		{props.text}
	</Message>
);

Messages.propTypes = {
	global: PropTypes.shape({
		message: PropTypes.shape({
			hideMessage: PropTypes.bool,
			success: PropTypes.bool,
			error: PropTypes.bool,
			warning: PropTypes.bool,
			icon: PropTypes.string,
			text: PropTypes.string
		})
	}),
	hideMessage: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		hideMessageValue: state.global.global.message.hideMessage,
		success: state.global.global.message.success,
		error: state.global.global.message.error,
		warning: state.global.global.message.warning,
		icon: state.global.global.message.icon,
		text: state.global.global.message.text
	};
};

export default connect(mapStateToProps, actions)(Messages);
