/**
 * Global actions are used for managing global components.
 * @memberof App.Actions
 * @namespace global
 * @since 2.0.0
 */

export function hideMessage() {
	return (dispatch) => {
		dispatch({
			type: 'GLOBAL_HIDE_MESSAGE',
			payload: true
		});
	};
}

export function toggleToTop(toggle, reference) {
	return (dispatch) => {
		console.log('toggleTopTop run');
		return dispatch({
			type: 'GLOBAL_TOGGLE_TO_TOP',
			payload: {
				toggle: !reference ? false : toggle
			}
		});
	};
}

export function toTop(topTopLocation) {
	return () => {
		// Fix for work tasklists
		if (topTopLocation === 'tasklist') {
			const scrollingElement = document.getElementById('work-dashboard');
			scrollingElement.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
		// Fix for task templates
		if (topTopLocation === 'tasktemplates') {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	};
}

export function toggleCreateContactModal(status) {
	return (dispatch) => {
		dispatch({
			type: 'GLOBAL_TOGGLE_CREATE_CONTACT_MODAL',
			payload: status
		});
	};
}

export function toggleCreateRatingModal(status) {
	return (dispatch) => {
		dispatch({
			type: 'GLOBAL_TOGGLE_CREATE_RATING_MODAL',
			payload: status
		});
	};
}

export function toggleCreateLocationModal(status) {
	return (dispatch) => {
		dispatch({
			type: 'GLOBAL_TOGGLE_CREATE_LOCATION_MODAL',
			payload: status
		});
	};
}

export function toggleLocationOrderHistModal(status) {
	return (dispatch) => {
		dispatch({
			type: 'GLOBAL_TOGGLE_LOCATION_ORDER_HISTORY',
			payload: status
		});
	};
}

export function toggleFetchingOnTabsNavbar(status) {
	return {
		type: 'GLOBAL_FETCHING_TABS_NAVBAR',
		payload: status
	};
}

export function toggleCreationModal(status) {
	return (dispatch) => {
		dispatch({
			type: 'GLOBAL_CREATION_MODAL_STATUS',
			payload: status
		});
	};
}

/**
 * This method will start the progress loader.
 * @memberof App.Actions.global
 * @typedef {Method}
 * @since 2.2.6
 * @example <caption>Example usage of the startLoader() method.</caption>
 * startLoader()
 */
export function startLoader() {
	return (dispatch, getState) => {
		dispatch({
			type: 'GLOBAL_LOADER_ANIMATION',
			payload: {
				percent: null,
				error: false,
				success: false,
				message: ''
			}
		});
		let percent = 0;

		let intervalID = setInterval(() => {
			const state = getState();

			// Your logic here
			if (state.global.global.ui.progressLoader.percent < 80) {
				percent += 20;
				dispatch({
					type: 'GLOBAL_LOADER_ANIMATION',
					payload: {
						percent: percent,
						error: false,
						success: false,
						message: ''
					}
				});
			} else {
				window.clearInterval(intervalID);
			}
		}, 500);
	};
}

/**
 * This method will end the progress loader with either error or success.
 * @memberof App.Actions.global
 * @typedef {Method}
 * @param {string} state State can have either 'error' or 'success' passed into it.
 * @param {string} message The message you would like to display to the user.
 * @throws {console.log} If the state is not 'error' or 'success'.
 * @since 2.2.6
 * @example <caption>Example usage of the endLoader() method on error.</caption>
 * endLoader('error', 'There was an error when trying to pin the task, please try again.');
 * @example <caption>Example usage of the endLoader() method on success.</caption>
 * endLoader('success', 'The task was successfully pinned.);
 */
export let endLoader = (type, message) => {
	return (dispatch) => {
		console.log(message);
		console.log(type);
		if (type.toLowerCase() !== 'error' && type.toLowerCase() !== 'success')
			return console.log(`State must have either 'error' or 'success' passed in. You passed in: ${type}`);

		dispatch({
			type: 'GLOBAL_LOADER_ANIMATION',
			payload: {
				percent: 100,
				error: type === 'error',
				success: type === 'success',
				message: message
			}
		});
		//Close the progress bar 4 secs after it has finished.
		setTimeout(
			() => {
				dispatch({
					type: 'GLOBAL_LOADER_ANIMATION',
					payload: {
						percent: null,
						error: false,
						success: false,
						message: ''
					}
				});
			},
			type === 'success' ? 4000 : 12000
		);
	};
};

export const toggleDiscardChangesModal = (onConfirm, params) => {
	return (dispatch) => {
		dispatch({
			type: 'GLOBAL_TOGGLE_DISCARD_CHANGES_MODAL',
			payload: {
				onConfirm: onConfirm,
				params: params
			}
		});
	};
};

export const searchAasdiCertifications = (certs, value, gridApi) => {
	return () => {
		let certList = '';
		if (certs && certs.length >= 1) {
			certs.forEach((cert) => {
				certList += `CertID${cert}- `;
			});
		}
		gridApi.setQuickFilter(`${certList} ${value}`);
	};
};
