import {Component} from 'react';
import {Label, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import OverviewTab from './components/OverviewTab.js';
import OpportunitiesTab from './components/OpportunitiesTab.js';
import DocumentsTab from './components/DocumentsTab.js';
import WorkTab from './components/WorkTab.js';
import PricingTab from './components/PricingTab.js';
import LocationsTab from './components/LocationsTab.js';

import TabsNavbar from '~/components/Global/TabsNavbar.js';
import {toggleFetchingOnTabsNavbar} from '~/components/Global/services/globalActions.js';

class ProfileGridView extends Component {
	constructor(props) {
		super(props);
		this.state = {tab: 'Overview', clientId: this.getClientId()};
		this.tabClick = this.tabClick.bind(this);
	}

	getClientId = () => {
		//This is terrible... we need to be using profile id as a param from react router.
		//never parse like this. Dont have time to fix it right now.
		const url = window.location.href;
		return url.substring(url.indexOf('/profile') + 9, url.length);
	};

	tabClick(item) {
		//We should be updating redux state here. No reason to have this state be local.
		//Now redux will be out of date with what is actually being rendered (i.e. the tab prop in state)
		//Redux should be source of truth
		const {tab} = this.state;
		if (tab === item) {
			return;
		}
		this.setState({...this.state, tab: item});
		this.props.toggleFetchingOnTabsNavbar(true);
	}

	render() {
		const {clientProfileName, stageNames} = this.props;
		const {tab, clientId} = this.state;
		return (
			<Segment basic style={{marginTop: '1.5rem'}}>
				<Label attached="top" color="blue" style={{position: 'relative'}}>
					{clientProfileName}
				</Label>
				<TabsNavbar stageNames={stageNames} onClick={this.tabClick} />
				{tab === 'Overview' && <OverviewTab clientId={clientId} />}
				{tab === 'Work' && <WorkTab clientId={clientId} />}
				{tab === 'Pricing' && <PricingTab clientId={clientId} />}
				{tab === 'Opportunities' && <OpportunitiesTab clientId={clientId} />}
				{tab === 'Locations' && <LocationsTab clientId={clientId} />}
				{tab === 'Documents' && <DocumentsTab clientId={clientId} />}
			</Segment>
		);
	}
}

ProfileGridView.propTypes = {
	clientProfileName: PropTypes.string,
	currentTab: PropTypes.string,
	stageNames: PropTypes.array.isRequired,
	toggleFetchingOnTabsNavbar: PropTypes.func
};

const mapStateToProps = (state) => ({
	clientProfileName: state.work.clientProfileGrid.profileName,
	currentTab: state.work.clientProfileGrid.tab,
	stageNames: state.work.clientProfileGrid.stageNames
});

const mapDispatchToProps = (dispatch) => ({
	toggleFetchingOnTabsNavbar: (status) => dispatch(toggleFetchingOnTabsNavbar(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGridView);
