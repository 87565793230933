import React from 'react';
import {connect} from 'react-redux';
import {Popup, Form, TextArea} from 'semantic-ui-react';

import {updateAASDIGridColumn} from '../../../services/createWorkOrderActions.js';

class AASDIInstructionsCellRenderer extends React.Component {
	state = {
		value: this.props.value
	};

	getValue() {
		//this is what sets the value in the grid (changes props) gets called
		//after stopEditing. Needed for if no changes are made to the cell.
		return this.props.value;
	}

	render() {
		return (
			<Popup
				trigger={
					this.props.value ? (
						this.props.value.replace(/\s+/, '') ? (
							<p style={{marginTop: '.4em'}}>{this.state.value}</p>
						) : (
							<div style={{width: '100%', height: '25px'}} />
						)
					) : (
						<div style={{width: '100%', height: '25px'}} />
					)
				}
				on={['hover', 'click']}
				flowing
				hoverable
				position="bottom left"
			>
				<Form>
					<TextArea
						style={{
							width: '500px',
							minHeight: '40px',
							maxHeight: '150px'
						}}
						autoHeight
						value={this.state.value}
						onChange={(event) => {
							this.setState({
								value: event.target.value
							});
						}}
						onBlur={() => {
							this.props.updateAASDIGridColumn(
								this.props.sectionIndex,
								this.props.rowIndex,
								this.props.colDef.field,
								this.state.value ? this.state.value : null
							);
							// this.props.stopEditing();
						}}
						onKeyPress={(event) => {
							if (event.key === 'Enter') {
								this.props.updateAASDIGridColumn(
									this.props.sectionIndex,
									this.props.rowIndex,
									this.props.colDef.field,
									this.state.value ? this.state.value : null
								);
								// this.props.stopEditing();
							}
						}}
					/>
				</Form>
			</Popup>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateAASDIGridColumn: (sectionIndex, rowIndex, column, value) => {
			dispatch(updateAASDIGridColumn(sectionIndex, rowIndex, column, value));
		}
	};
};

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.work.createWorkOrder.createWorkOrderModal.sections.findIndex(
			(section) => section.name === 'AASDISection'
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AASDIInstructionsCellRenderer);
