import React from 'react';
import {Popup, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {toggleCreateLocationModal} from '../../../../../../../../../components/createLocationModal/services/createLocationModalActions.js';
import {toggleBillingContactModal} from '../../../../../../../../../components/billingContactModal/services/billingContactModalActions.js';

const createNewPopup = (props) =>
	props.field.searchLocations ? (
		<Popup
			content="Create Location"
			trigger={
				<Button
					style={{
						display: 'inline-block',
						margin: '0',
						width: '3rem',
						textAlign: 'center',
						lineHeight: '1.1'
					}}
					attached="right"
					color="green"
					icon="plus"
					onClick={() => props.toggleCreateLocationModal(1, props.clientId)}
				/>
			}
		/>
	) : (
		<Popup
			content="Create Billing Contact"
			trigger={
				<Button
					style={{
						display: 'inline-block',
						margin: '0',
						width: '3rem',
						textAlign: 'center',
						lineHeight: '1.1'
					}}
					attached="right"
					color="green"
					icon="plus"
					onClick={() => props.toggleBillingContactModal(1, props.clientId)}
				/>
			}
		/>
	);

const mapDispatchToProps = (dispatch) => {
	return {
		toggleCreateLocationModal: (id, clientId) => {
			dispatch(toggleCreateLocationModal(id, clientId));
		},
		toggleBillingContactModal: (id, clientId) => {
			dispatch(toggleBillingContactModal(id, clientId));
		}
	};
};

export default connect(null, mapDispatchToProps)(createNewPopup);
