import React from 'react';
import {Card, Icon, Label, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

let CardMain = ({opportunity}) => (
	<Card>
		<Card.Content>
			{opportunity.pastDue ? (
				<Popup
					trigger={
						<Label color="yellow" floating>
							<Icon name="warning sign" />
						</Label>
					}
					content="This opportunity is past due."
					size="small"
					on="hover"
					basic
				/>
			) : null}
			<Card.Header>{opportunity.name}</Card.Header>
			<Card.Meta>
				<span>{opportunity.revenue}</span>
			</Card.Meta>
			<Card.Description>{opportunity.clientName}</Card.Description>
			<Card.Description>{moment(opportunity.projectedOrderDate).format('MM-DD-YYYY')}</Card.Description>
		</Card.Content>
	</Card>
);

export default CardMain;

CardMain.propTypes = {
	opportunity: PropTypes.shape({
		pastDue: PropTypes.bool.isRequired,
		name: PropTypes.string.isRequired,
		revenue: PropTypes.string.isRequired,
		clientName: PropTypes.string.isRequired,
		projectedOrderDate: PropTypes.string.isRequired
	})
};
