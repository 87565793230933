import {Icon, Menu, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import optionFactory from './optionFactory.js';

const CreateTaskButton = (props) => {
	return (
		<Popup
			size={'mini'}
			trigger={
				<Menu.Item
					className="pseudoDragHandler"
					onClick={() => {
						props.addTask(props.mode, optionFactory(props, true));
					}}
				>
					<Icon name="plus" className="pseudoDragHandler" />
					{props.noText ? null : `Create Task`}
				</Menu.Item>
			}
			content={props.isSection ? `Create a new task for this section.` : `Create a new task.`}
		/>
	);
};

CreateTaskButton.propTypes = {
	aasdis: PropTypes.array,
	aasdiIndex: PropTypes.number,
	groupIndex: PropTypes.number,
	isSection: PropTypes.bool,
	mode: PropTypes.string,
	addTask: PropTypes.func,
	toggleEditTaskPanel: PropTypes.func
};

export default CreateTaskButton;
