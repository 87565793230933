import React from 'react';
import {Button, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

function AddGridItem(props) {
	const {content, onClick} = props;
	return (
		<div style={{float: 'right'}}>
			<Popup
				content={content}
				position="left center"
				trigger={
					<Button
						onClick={() => onClick()}
						style={{
							marginRight: 0,
							borderRadius: 0
						}}
						color="green"
						icon="plus"
						size="large"
					/>
				}
			/>
		</div>
	);
}

AddGridItem.propTypes = {
	content: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};

export default AddGridItem;
