import React from 'react';
import {Button, Modal, Label, Icon, Header, Grid, Dropdown, Input, Dimmer, Loader, Step} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {DebounceInput} from 'react-debounce-input';
import PropTypes from 'prop-types';

import {
	toggleCreateModal,
	updateCreateModal,
	// updateCreateModalPlaceholder,
	setActiveStep,
	getAccountExecs,
	getMainContacts,
	createCustomer,
	closeDimmer
} from './services/createCustomerModalActions.js';

import {debounceEventHandler, debounce} from '../../lib/old/debounce.js';

const CreateCustomerModal = (props) => (
	<Modal open={props.createCustomerModal.createModal.toggleCreateModal} size={'small'}>
		<Header>
			<Icon name="plus" color="green" />
			<div className="content">Create Customer</div>
			<Step.Group widths={3} size="small">
				{props.createCustomerModal.createModal.modalSteps.map((modalStep, index) => (
					<Step
						key={modalStep.name}
						icon={modalStep.icon}
						// description: modal.description,
						title={modalStep.name}
						active={modalStep.active}
						link={true}
						onClick={() => props.setActiveStep(props.activeStep, index)}
					/>
				))}
			</Step.Group>
		</Header>
		<Modal.Content scrolling={true} style={{paddingBottom: '3rem'}} className="createModalContent">
			{props.createCustomerModal.createModal.loading ? (
				<Dimmer inverted active className="centerDimmer">
					{props.createCustomerModal.createModal.loading === 'start' ? (
						<Loader size="big">Fetching Client Information</Loader>
					) : props.createCustomerModal.createModal.loading === 'progress' ? (
						<Loader size="big">Creating Customer</Loader>
					) : (
						// Error handling
						<Header as="h3" icon>
							<Icon name="cancel" color="red" />
							<div style={{paddingBottom: '.5em', color: 'black'}}>
								{props.createCustomerModal.createModal.loading === 'error'
									? 'Customer Creation Failed'
									: 'Could not fetch client info.'}
							</div>
							<div style={{color: 'black'}}>Please try again.</div>
							<Button
								onClick={() => {
									props.closeDimmer(null);
								}}
								style={{marginTop: '1.5em'}}
							>
								Close Message
							</Button>
						</Header>
					)}
				</Dimmer>
			) : null}
			{props.createCustomerModal.createModal.modalSteps.map((modal, stepIndex) =>
				modal.active ? (
					<Grid key={stepIndex}>
						{modal.modalFields.map((field, fieldIndex) =>
							field.input || (field.name === 'State' && props.countryField !== 218) ? (
								<Grid.Row key={fieldIndex}>
									<Grid.Column width={8}>
										<div
											style={{
												paddingTop: '0.5em'
											}}
										>
											<Label
												style={{
													backgroundColor: 'transparent',
													fontSize: '1rem',
													color: 'rgba(0,0,0,.8)',
													paddingLeft: '0'
												}}
											>
												{field.name}
											</Label>
										</div>
										<DebounceInput
											element={Input}
											onChange={(e) => {
												props.updateCreateModal(
													'createModal',
													stepIndex,
													fieldIndex,
													e.target.value,
													false
												);
											}}
											debounceTimeout={600}
											value={field.value}
											style={{
												width: '100%'
											}}
											error={field.required ? field.error : false}
											disabled={field.disabled}
											placeholder={field.placeholder}
										/>
									</Grid.Column>
								</Grid.Row>
							) : (
								<Grid.Row key={fieldIndex}>
									<Grid.Column width={8}>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											{field.name}
										</Label>
										<Dropdown
											className="createModalDropdown"
											placeholder={field.placeholder}
											fluid
											search
											selection
											options={field.options}
											onChange={(e, {value}) => {
												props.updateCreateModal(
													'createModal',
													stepIndex,
													fieldIndex,
													value,
													false
												);
											}}
											onSearchChange={debounceEventHandler(
												debounce((e) => {
													if (e.target.value) {
														if (field.searchAE) {
															props.getAccountExecs(
																stepIndex,
																fieldIndex,
																e.target.value
															);
														} else if (field.searchMC) {
															props.getMainContacts(
																stepIndex,
																fieldIndex,
																e.target.value,
																props.createCustomerModal.createModal.id,
																props.createCustomerModal.createModal.place
															);
														}
													}
												}, 600)
											)}
											icon={field.icon}
											value={field.value}
											error={field.required ? field.error : false}
											disabled={field.disabled}
											upward={field.upward}
											loading={field.loading}
											defaultSearchQuery={field.searchQuery}
										/>
									</Grid.Column>
								</Grid.Row>
							)
						)}
					</Grid>
				) : null
			)}
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleCreateModal(null, null);
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					onClick={() => {
						props.createCustomer(
							props.createCustomerModal.createModal.id,
							props.createCustomerModal.createModal.place
						);
					}}
					color="green"
				>
					<Icon name="checkmark" /> Create
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

CreateCustomerModal.propTypes = {
	createCustomer: PropTypes.func,
	createCustomerModal: PropTypes.object,
	toggleCreateModal: PropTypes.func,
	getMainContacts: PropTypes.func,
	updateCreateModal: PropTypes.func,
	getAccountExecs: PropTypes.func,
	countryField: PropTypes.number,
	closeDimmer: PropTypes.func,
	setActiveStep: PropTypes.func,
	activeStep: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		createCustomerModal: state.global.createCustomerModal,
		activeStep: state.global.createCustomerModal.createModal.modalSteps
			.map((modalStep, index) => ({modalStep: modalStep, index: index}))
			.filter((modalStep) => modalStep.modalStep.active)[0].index,
		countryField: state.global.createCustomerModal.createModal.modalSteps[1].modalFields[5].value
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleCreateModal: (id, place) => {
			dispatch(toggleCreateModal(id, place));
		},
		updateCreateModal: (modal, step, column, value, error) => {
			dispatch(updateCreateModal(modal, step, column, value, error));
		},
		setActiveStep: (currentStep, targetStep) => {
			dispatch(setActiveStep(currentStep, targetStep));
		},
		createCustomer: (id, place) => {
			dispatch(createCustomer(id, place));
		},
		getAccountExecs: (step, column, value) => {
			dispatch(getAccountExecs(step, column, value));
		},
		getMainContacts: (step, column, value, oppId, place) => {
			dispatch(getMainContacts(step, column, value, oppId, place));
		},
		closeDimmer: (value) => {
			dispatch(closeDimmer(value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomerModal);
