const leadSourceOptions = [
	{key: 1, value: 1, text: 'ASD Website'},
	{key: 2, value: 2, text: 'Bid Clerk'},
	{key: 3, value: 3, text: 'Business Journal'},
	{key: 4, value: 4, text: 'Business Publication'},
	{key: 5, value: 5, text: 'Call-In'},
	{key: 6, value: 6, text: 'Fortune 1000'},
	{key: 7, value: 7, text: 'GSA'},
	{key: 8, value: 8, text: 'Internet'},
	{key: 9, value: 9, text: 'LinkedIn - Social Networking'},
	{key: 10, value: 10, text: 'Management Assigned'},
	{key: 11, value: 11, text: 'Marketing'},
	{key: 12, value: 12, text: 'Networking Event'},
	{key: 13, value: 13, text: 'Phelan Lead'},
	{key: 14, value: 14, text: 'Referral'},
	{key: 15, value: 15, text: 'Trade Show'}
];

export default leadSourceOptions;
