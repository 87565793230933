import React from 'react';
import {Button, Modal, Icon, Header} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import * as actions from '../services/registrationActions.js';

class RegisterModal extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {open, toggleRegisterModal} = this.props;
		return (
			<Modal open={open}>
				<Header>
					<h1>Registration Successful!</h1>
				</Header>
				<Modal.Content>
					<h3>
						We have sent you a verification email. Please click the link in the email to verify your email.
					</h3>
				</Modal.Content>
				<Modal.Actions>
					<Link to="seu_path">
						<Button
							onClick={() => {
								toggleRegisterModal();
							}}
							color="green"
						>
							<Icon name="checkmark" />
							Go to Log in Page
						</Button>
					</Link>
				</Modal.Actions>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		open: state.registrationForm.isRegistrationModalOpen
	};
};

export default connect(mapStateToProps, actions)(RegisterModal);
