import {Button, Icon} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../Global/services/globalActions.js';

const toTop = (props) => (
	<Button
		type="button"
		icon
		floated="right"
		size="small"
		toggle
		color="blue"
		// Visibility component in Groups component busted
		// className={`${props.global.global.toggleToTop} toTop`}
		className="toTop showToTop"
		onClick={() => props.toTop(props.topTopLocation)}
	>
		<Icon name="arrow up" />
	</Button>
);

toTop.propTypes = {
	global: PropTypes.shape({
		toggleToTop: PropTypes.string
	}),
	approvals: PropTypes.object,
	toTop: PropTypes.func
};

export default connect(null, actions)(toTop);
