import update from 'immutability-helper';
import moment from 'moment';
import branchOptions from '../../../../../constants/branches.js';
import {calcCostFromMargin, calcRevenueFromMargin} from '../../../../../util/moneymath.ts';
import {roundFloat} from '~/util/numbers.ts';
import {v4 as uuid} from 'uuid';

export default (
	state = {
		createWorkOrderModal: {
			iconError: false,
			id: null,
			workId: null,
			loading: null,
			place: null,
			toggleCreateWorkOrderModal: false,
			clientId: null,
			spectrumError: false,
			spectrumMessage: '',
			tempContacts: [],
			tempAasdiTrip: [],
			tempWorkOrder: [],
			displayContacts: [],
			workTemplateModalToggle: false,
			workTemplateLoader: false,
			desynced: 0,
			sections: [
				{
					name: 'workOrderTemplateDraftSection',
					fields: [
						{
							name: 'Templates',
							value: null,
							input: false,
							error: false,
							// DISABLED FOR NOW
							disabled: false,
							searchTemplate: true,
							options: null,
							loading: false,
							required: false,
							columnWidth: 8,
							placeholder: 'Select a template'
						},
						{
							name: 'Drafts',
							value: null,
							input: false,
							error: false,
							// DISBALED FOR NOW
							disabled: true,
							searchDrafts: true,
							options: null,
							loading: false,
							required: false,
							columnWidth: 8,
							placeholder: 'Select a draft'
						}
					]
				},
				{
					name: 'workOrderOverviewSection',
					fields: [
						{
							name: 'Client',
							value: '',
							input: false,
							error: false,
							required: true,
							loading: false,
							columnWidth: 4,
							searchClient: true
						},
						{
							name: 'Purchase Order Number',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4,
							loading: false
						},
						{
							name: 'Quoted Job Number (Optional)',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							columnWidth: 4
						},
						{
							name: 'Service Type',
							value: 4,
							serviceTypeField: true,
							input: false,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4,
							options: [
								{
									key: 1,
									text: 'Deploy',
									value: 1
								},
								{
									key: 2,
									text: 'Design',
									value: 2
								},
								{
									key: 3,
									text: 'Build',
									value: 3
								},
								{
									key: 4,
									text: 'Support',
									value: 4
								}
							]
						},
						{
							name: 'Select Work Category',
							value: '',
							input: false,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4,
							options: [
								{
									key: 1,
									text: 'Access Control & Surveillance',
									value: 1
								},
								{
									key: 2,
									text: 'Audio Visual',
									value: 2
								},
								{
									key: 3,
									text: 'Network Integration & Telephony',
									value: 3
								},
								{
									key: 4,
									text: 'Point of Sale',
									value: 4
								},
								{
									key: 5,
									text: 'Power & Electrical',
									value: 5
								},
								{
									key: 6,
									text: 'Sound Masking & Paging',
									value: 6
								},
								{
									key: 7,
									text: 'Structured Cabling',
									value: 7
								},
								// {
								// 	key: 8,
								// 	text: 'LED Lighting',
								// 	value: 8
								// },
								{
									key: 9,
									text: 'Fire Alarm',
									value: 9
								}
							]
						},
						{
							name: 'Google Drive Path',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4
						},
						{
							name: 'New Construction',
							value: null,
							toggle: false,
							error: false,
							disabled: false,
							columnWidth: 4,
							options: [
								{
									key: 1,
									text: 'No',
									value: false
								},
								{
									key: 2,
									text: 'Yes',
									value: true
								}
							]
						},
						{
							name: 'Location',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4,
							upward: false,
							dataList: [],
							listName: 'LocationsList',
							locationSelected: false,
							selectedLocation: {},
							newButton: true,
							searchLocations: true
						},
						{
							name: 'Billing Contact',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: true,
							upward: false,
							columnWidth: 4,
							dataList: [],
							listName: 'billingContactsList',
							billingContactSelected: false,
							selectedBillingContact: {},
							newButton: true,
							searchBillingContacts: true
						},
						{
							name: 'Service Type Options',
							value: 1,
							skipFieldMap: true,
							input: false,
							error: false,
							disabled: false,
							required: false,
							columnWidth: 4,
							options: [
								{
									key: 1,
									text: 'Move, Add, Change',
									value: 1
								},
								{
									key: 2,
									text: 'Staging / Configuration',
									value: 2
								},
								{
									key: 3,
									text: 'Survey',
									value: 3
								},
								{
									key: 4,
									text: 'Repair',
									value: 4
								},
								{
									key: 5,
									text: 'Annuity',
									value: 5
								},
								{
									key: 6,
									text: 'Small Project',
									value: 6
								}
							]
						},
						{
							name: 'Project Manager',
							value: '',
							input: false,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4,
							employeeSearch: true
						},
						{
							name: 'Account Manager (Sales Rep)',
							value: '',
							input: false,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4,
							employeeSearch: true
						},
						{
							//Account Exec
							name: 'Sales Representative',
							value: '',
							input: false,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4,
							employeeSearch: true
						},
						{
							//NDS
							name: 'Project Administrator',
							value: '',
							input: false,
							error: false,
							disabled: false,
							required: false,
							columnWidth: 4,
							employeeSearch: true
						},
						{
							name: 'Branch',
							value: null,
							skipFieldMap: true,
							input: false,
							error: false,
							disabled: false,
							required: true,
							columnWidth: 4,
							options: branchOptions
						},
						{
							name: 'Master Client',
							value: '',
							input: false,
							error: false,
							required: true,
							loading: false,
							columnWidth: 4,
							searchClient: true
						},
						{
							name: 'Start Date',
							value: moment(new Date()).format('MM-DD-YYYY'),
							input: true,
							error: false,
							disabled: false,
							required: true,
							date: true,
							columnWidth: 4
						},
						{
							name: 'End Date',
							value: moment(new Date()).format('MM-DD-YYYY'),
							input: true,
							error: false,
							disabled: false,
							required: true,
							date: true,
							columnWidth: 4
						},
						{
							name: 'Date Received',
							value: moment(new Date()).format('MM-DD-YYYY'),
							input: true,
							error: false,
							disabled: false,
							required: true,
							date: true,
							time: true,
							columnWidth: 4
						}
					]
				},
				{
					name: 'workOrderProjectCostingSection',
					rowData: [],
					error: false,
					skuExists: true,
					summary: {},
					projectCostingSpreadsheetModal: {
						skuLoaded: false,
						toggle: false,
						applyMM: null,
						applyLM: null,
						SKUs: [],
						spreadSheetData: [],
						selectSKUModal: {
							toggle: false,
							SKUList: []
						}
					}
				},
				{
					name: 'scopeOfWorkSection',
					scopeOfWorkValue: ''
				},
				{
					name: 'documentsSection',
					fields: [
						{
							name: 'File Name',
							value: '',
							input: true,
							error: false,
							disabled: false,
							required: false,
							checkForm: true,
							columnWidth: 5
						},
						{
							name: 'Google Drive Link',
							value: '',
							input: true,
							error: false,
							disabled: false,
							checkForm: true,
							required: false,
							columnWidth: 5
						},
						{
							name: 'Customer Approval (PO)',
							value: false,
							input: false,
							dropdown: true,
							error: false,
							disabled: false,
							required: false,
							columnWidth: 5,
							options: [
								{
									key: 'No',
									text: 'No',
									value: false
								},
								{
									key: 'Yes',
									text: 'Yes',
									value: true
								}
							]
						},
						{
							name: 'Comments',
							value: '',
							input: false,
							textArea: true,
							error: false,
							disabled: false,
							required: false,
							columnWidth: 15
						}
					],
					documents: [],
					error: false
				},
				{
					name: 'contactSection',
					contacts: [],
					loading: false,
					editError: false
				},
				{
					name: 'AASDISection',
					AASDI: [],
					addContactModal: {
						open: false,
						searchLoading: false,
						error: false,
						options: [],
						contact: {
							isPrimary: 0,
							id: '',
							name: ''
						}
					},
					modal: {
						name: 'Modal',
						value: '',
						input: true,
						error: false,
						loading: false,
						columnWidth: 4,
						radiusOptions: [
							{key: '10', text: 10, value: 10},
							{key: '25', text: 25, value: 25},
							{key: '50', text: 50, value: 50},
							{key: '75', text: 75, value: 75},
							{key: '100', text: 100, value: 100},
							{key: '150', text: 150, value: 150},
							{key: '200', text: 200, value: 200},
							{key: '>200', text: '>200', value: '>200'}
						],
						selectedRadius: 25,
						selectedZip: '',
						zipError: false,
						selectedNameSearch: '',
						dataList: [],
						listName: 'ModalList',
						AASDISelected: false,
						selectedAASDI: {},
						searchAASDIModal: true,
						toggled: false
					}
				},
				{
					name: 'tagsSection',
					tags: []
				}
			]
		},
		afterCreateModal: {
			toggleAfterCreateModalStatus: false,
			redirectWorkId: null,
			redirectWorkNumber: null
		}
	},
	action
) => {
	let locationUpdateField = state.createWorkOrderModal.sections[1].fields.findIndex(
		(fields) => fields.name === 'Location'
	);
	let billingContactUpdateField = state.createWorkOrderModal.sections[1].fields.findIndex(
		(fields) => fields.name === 'Billing Contact'
	);
	let aasdiSectionIndex = state.createWorkOrderModal.sections.findIndex((section) => section.name === 'AASDISection');

	switch (action.type) {
		case 'CREATE_WORK_ORDER_MODAL_TOGGLE':
			state = update(state, {
				createWorkOrderModal: {
					toggleCreateWorkOrderModal: {
						$set: action.payload.value
					},
					sections: {
						$set: baseSections
					},
					desynced: {
						$set: 0
					}
				}
			});
			break;

		case 'AFTER_CREATE_WORK_MODAL_TOGGLE':
			state = update(state, {
				afterCreateModal: {
					toggleAfterCreateModalStatus: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_LOADING':
			state = update(state, {
				createWorkOrderModal: {
					loading: {
						$set: action.payload.state
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_UPDATE':
			state = update(state, {
				createWorkOrderModal: {
					iconError: {$set: false},
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_LOCATION_UPDATE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[locationUpdateField]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									},
									locationSelected: {
										$set: true
									},
									selectedLocation: {
										$set: action.payload.locationObject
									}
								}
							}
						}
					}
				}
			});
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[aasdiSectionIndex]: {
							modal: {
								selectedZip: {
									$set: action.payload.locationObject.zip
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_AASDI_UPDATE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									},
									AASDISelected: {
										$set: true
									},
									selectedAASDI: {
										$set: action.payload.locationObject
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_AASDI_GRID_UPDATE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							AASDI: {
								[action.payload.rowIndex]: {
									[action.payload.column]: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});

			break;

		case 'CREATE_WORK_ORDER_MODAL_AASDI_ADD_CONTACT':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							AASDI: {
								[action.payload.rowIndex]: {
									[action.payload.column]: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});

			break;

		case 'CREATE_WORK_ORDER_MODAL_BILLING_CONTACT_UPDATE':
			action.payload.contactObject.fullName = `${action.payload.contactObject.firstName} ${action.payload.contactObject.lastName}`;
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[billingContactUpdateField]: {
									value: {
										$set: action.payload.value
									},
									error: {
										$set: action.payload.error
									},
									billingContactSelected: {
										$set: true
									},
									selectedBillingContact: {
										$set: action.payload.contactObject
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_SEARCH_LOADING':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {$set: []},
									search: {$set: action.payload.search},
									loading: {$set: true}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_SEARCH_FINISH':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {$set: action.payload.options},
									loading: {$set: false}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_LOADING_FIELD':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									loading: {
										$set: action.payload.loading
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_EMPLOYEE_DROPDOWN_OPTIONS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {
										$set: action.payload.options
									},
									loading: {
										$set: false
									},
									value: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_CLIENT_DROPDOWN_OPTIONS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {
										$set: action.payload.options
									},
									loading: {
										$set: false
									},
									value: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'LOAD_ACCOUNT_MANAGER_FIELD':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {
										$set: action.payload.options
									},
									loading: {
										$set: false
									},
									value: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'LOAD_SALES_REPRESENTATIVE_FIELD':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {
										$set: action.payload.options
									},
									loading: {
										$set: false
									},
									value: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_LOCATIONS_DROPDOWN_OPTIONS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									dataList: {
										$set: action.payload.json
									},
									loading: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_BILLING_CONTACTS_DROPDOWN_OPTIONS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									value: {
										$set: action.payload.value
									},
									dataList: {
										$set: action.payload.json
									},
									loading: {
										$set: false
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_DATALIST_OPTIONS':
			state = update(state, {
				createProjectModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									dataList: {
										$set: action.payload.json
									},
									loading: {
										$set: false
									},
									error: {
										$set: action.payload.error
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_TOGGLE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							projectCostingSpreadsheetModal: {
								toggle: {
									$set: action.payload.value
								},
								spreadSheetData: {
									$set: action.payload.spreadSheetData
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_UPDATE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						2: {
							projectCostingSpreadsheetModal: {
								spreadSheetData: {
									[action.payload.rowIndex]: {
										$merge: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_SET_LABOR_MARGIN':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						2: {
							projectCostingSpreadsheetModal: {
								applyLM: {$set: action.payload}
							}
						}
					}
				}
			});
			break;
		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_SET_MATERIAL_MARGIN':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						2: {
							projectCostingSpreadsheetModal: {
								applyMM: {$set: action.payload}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_APPLY_MATERIAL_MARGIN':
			const maginAllMaterial = state.createWorkOrderModal.sections[2].projectCostingSpreadsheetModal.applyMM;
			const materialMapFn = (sku) => {
				if (sku.unitMaterialCost === 0) {
					return sku;
				}
				return update(sku, {
					materialResell: {
						$set: roundFloat(
							calcRevenueFromMargin(sku.unitMaterialCost * sku.quantity, maginAllMaterial),
							2
						)
					}
				});
			};

			state = update(state, {
				createWorkOrderModal: {
					sections: {
						2: {
							projectCostingSpreadsheetModal: {
								spreadSheetData: {
									$apply: (data) => data.map(materialMapFn)
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_APPLY_LABOR_MARGIN':
			const maginAllLabor = state.createWorkOrderModal.sections[2].projectCostingSpreadsheetModal.applyLM;
			const laborMapFn = (sku) => {
				if (sku.unitLaborCost === 0) {
					return sku;
				}
				return update(sku, {
					laborResell: {
						$set: roundFloat(calcRevenueFromMargin(sku.unitLaborCost * sku.quantity, maginAllLabor), 2)
					}
				});
			};

			state = update(state, {
				createWorkOrderModal: {
					sections: {
						2: {
							projectCostingSpreadsheetModal: {
								spreadSheetData: {
									$apply: (data) => data.map(laborMapFn)
								}
							}
						}
					}
				}
			});
			break;
		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_UPDATE_TOTAL':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							projectCostingSpreadsheetModal: {
								[action.payload.field]: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_ADD_ROW':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						2: {
							projectCostingSpreadsheetModal: {
								spreadSheetData: {
									$push: [action.payload.object]
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_REMOVE_ROW':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						2: {
							projectCostingSpreadsheetModal: {
								spreadSheetData: {
									$splice: [[action.payload, 1]]
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_PROJECT_SPREADSHEET_MODAL_GRID_UPDATE_ROWNODE_DATA':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							projectCostingSpreadsheetModal: {
								[action.payload.field]: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_SELECT_SKU_MODAL_TOGGLE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							projectCostingSpreadsheetModal: {
								selectSKUModal: {
									toggle: {
										$set: action.payload.toggle
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_SELECT_SKU_MODAL_ADD_ITEMS':
			//give uuid to prevent conflict of adding multiple of same sku
			//adding skuId property because this is changing into a billableitem
			const items = action.payload.map((sku) => ({...sku, skuId: sku.id, id: uuid()}));

			state = update(state, {
				createWorkOrderModal: {
					sections: {
						2: {
							projectCostingSpreadsheetModal: {
								spreadSheetData: {$push: items}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_ADD_DOCUMENT':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							documents: {
								$push: [action.payload.object]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_CHECK_REQUIRED_DOCUMENTS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							error: {
								$set: action.payload.hasError
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_UPDATE_DOC_GRID':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							documents: {
								[action.payload.rowIndex]: {
									customerApproval: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_REMOVE_DOC_GRID':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							documents: {
								$splice: [[action.payload.rowIndex, 1]]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_COMPLETE_PROJECT_COSTING_SPREADSHEET':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							rowData: {
								$set: action.payload.rowData
							},
							summary: {
								$set: action.payload.summary
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_UPDATE_SCOPE_OF_WORK':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							scopeOfWorkValue: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_UPDATE_CONTACTS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							[action.payload.column]: {
								$set: action.payload.value
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_CONTACTS_ADD_ITEM':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							contacts: {
								$push: [action.payload.data]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_CONTACTS_DELETE_ITEM':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							contacts: {
								$splice: [[action.payload, 1]]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_CONTACTS_ITEM_UPDATE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							contacts: {
								[action.payload.contactIndex]: {
									$set: action.payload.contactItem
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_DROPDOWN_OPTIONS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							fields: {
								[action.payload.fieldIndex]: {
									options: {
										$set: action.payload.options
									},
									loading: {
										$set: false
									},
									value: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_MODAL_APPLY_TEMPL_OR_DRAFT':
			action.payload.map(
				(item) =>
					(state = update(state, {
						createWorkOrderModal: {
							sections: {
								[item.sectionIndex]: {
									fields: {
										[state.createWorkOrderModal.sections[item.sectionIndex].fields.findIndex(
											(section) => section.name === item.name
										)]: {
											options: {
												$set: item.options
													? item.options
													: state.createWorkOrderModal.sections[item.sectionIndex].fields[
															state.createWorkOrderModal.sections[
																item.sectionIndex
															].fields.findIndex((section) => section.name === item.name)
													  ].options
											},
											dataList: {
												$set: item.dataList
											},
											value: {
												$set: item.value
											},
											error: {
												$set: false
											}
										}
									}
								}
							}
						}
					}))
			);
			break;

		case 'CREATE_WORK_MODAL_APPLY_TEMPL_OR_DRAFT_COST_SOW':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[state.createWorkOrderModal.sections.findIndex(
							(section) => section.name === 'scopeOfWorkSection'
						)]: {
							scopeOfWorkValue: {
								$set: action.payload.SOW
							}
						},
						[state.createWorkOrderModal.sections.findIndex(
							(section) => section.name === 'workOrderProjectCostingSection'
						)]: {
							$set: action.payload.projectCosting
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_CHECK_REQUIRED_FIELDS':
			action.payload.map(
				(object) =>
					(state = update(state, {
						createWorkOrderModal: {
							iconError: {$set: true},
							sections: {
								[object.sectionIndex]: {
									fields: {
										[object.fieldIndex]: {
											value: {
												$set: object.field.value
											},
											error: {
												$set: true
											}
										}
									}
								}
							}
						}
					}))
			);
			break;

		case 'CREATE_WORK_ORDER_MODAL_CHECK_RESELL':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							error: {
								$set: action.payload.error
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_CHECK_SKU':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							skuExists: {
								$set: action.payload.skuExists
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_AASDI_DROPDOWN_OPTIONS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							modal: {
								value: {
									$set: action.payload.value
								},
								dataList: {
									$set: action.payload.json
								},
								loading: {
									$set: false
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_SELECT_ZIP':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							modal: {
								selectedZip: {
									$set: action.payload.zip
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_ZIP_ERROR_TOGGLE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							modal: {
								zipError: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_SELECT_SEARCH':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							modal: {
								selectedNameSearch: {
									$set: action.payload.search
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_SELECT_RADIUS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							modal: {
								selectedRadius: {
									$set: action.payload.radius
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_TOGGLE_AASDI_MODAL':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							modal: {
								toggled: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_AASDI_MODAL_UPDATE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							modal: {
								selectedNameSearch: {
									$set: action.payload.value
								},
								error: {
									$set: action.payload.error
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_LOADING_AASDI_MODAL':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							modal: {
								loading: {
									$set: action.payload.loading
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_ADD_AASDI':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							AASDI: {
								$push: action.payload.selectedAASDIArray
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_REMOVE_AASDI':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							AASDI: {
								$splice: [[action.payload.index, 1]]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_MODAL_REMOVE_ALL_AASDI':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							AASDI: {
								$set: []
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_UPDATE_WORK_ID':
			state = update(state, {
				createWorkOrderModal: {
					workId: {
						$set: action.payload
					}
				}
			});
			break;

		case 'SET_REDIRECT_WORK_ID':
			state = update(state, {
				afterCreateModal: {
					redirectWorkId: {
						$set: action.payload.workId
					},
					redirectWorkNumber: {
						$set: action.payload.workNumber
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_SKUS':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							projectCostingSpreadsheetModal: {
								SKUs: {
									$set: action.payload.skuArray
								},
								skuLoaded: {
									$set: action.payload.skuLoaded
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_GET_SKU_LOADED':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						[action.payload.sectionIndex]: {
							projectCostingSpreadsheetModal: {
								skuLoaded: {
									$set: action.payload.skuLoaded
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ADD_TEMP_CONTACT':
			state = update(state, {
				createWorkOrderModal: {
					tempContacts: {
						$push: [action.payload.contact]
					},
					displayContacts: {
						$push: [action.payload.displayContact]
					}
				}
			});
			break;

		case 'CREATE_WORK_UPDATE_TEMP_CONTACT':
			state = update(state, {
				createWorkOrderModal: {
					tempContacts: {
						[action.payload.index]: {
							$set: action.payload.contact
						},
						displayContacts: {
							[action.payload.index]: {
								$set: action.payload.contact
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_REMOVE_TEMP_CONTACT':
			state = update(state, {
				createWorkOrderModal: {
					tempContacts: {
						$splice: [[action.payload.index, 1]]
					},
					displayContacts: {
						$splice: [[action.payload.index, 1]]
					}
				}
			});
			break;

		case 'CREATE_WORK_CLEAR_TEMP_CONTACT':
			state = update(state, {
				createWorkOrderModal: {
					tempContacts: {
						$set: []
					},
					displayContacts: {
						$set: []
					}
				}
			});
			break;

		case 'CREATE_WORK_UPDATE_TEMP_AASDI_TRIP':
			state = update(state, {
				createWorkOrderModal: {
					tempAasdiTrip: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CREATE_WORK_UPDATE_TEMP_WORK_ORDER':
			state = update(state, {
				createWorkOrderModal: {
					tempWorkOrder: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_TOGGLE':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						6: {
							addContactModal: {
								open: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_SEARCH_LOADING':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						6: {
							addContactModal: {
								searchLoading: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_IS_PRIMARY':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						6: {
							addContactModal: {
								contact: {
									isPrimary: {
										$set: action.payload.value
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_DROPDOWN_OPTIONS':
			action.payload.json.map(
				(contact) =>
					(state = update(state, {
						createWorkOrderModal: {
							sections: {
								6: {
									addContactModal: {
										options: {
											$push: [contact]
										}
									}
								}
							}
						}
					}))
			);
			break;

		case 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_SELECTED_CONTACT':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						6: {
							addContactModal: {
								contact: {
									id: {
										$set: action.payload.value.id
									},
									name: {
										$set: action.payload.value.fullName
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_ADD_CONTACT':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						6: {
							AASDI: {
								[action.payload.index]: {
									siteContactId: {
										$set: action.payload.contact.id
									},
									siteContactName: {
										$set: action.payload.contact.name
									},
									contactIsPrimary: {
										$set: action.payload.contact.isPrimary
									},
									contactObject: {
										$set: action.payload.contact
									}
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_CLEAR_CONTACT':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						6: {
							addContactModal: {
								contact: {
									id: {
										$set: ''
									},
									name: {
										$set: ''
									},
									isPrimary: {
										$set: 0
									}
								},
								options: {
									$set: []
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_AASDI_CONTACT_MODAL_ERROR':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						6: {
							addContactModal: {
								error: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_SPECTRUM_ERROR_TOGGLE':
			state = update(state, {
				createWorkOrderModal: {
					spectrumError: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_SPECTRUM_MESSAGE':
			state = update(state, {
				createWorkOrderModal: {
					spectrumMessage: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_TOGGLE_WORK_TEMPLATE_MODAL':
			state = update(state, {
				createWorkOrderModal: {
					workTemplateModalToggle: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_TEMPLATE_MODAL_LOADER':
			state = update(state, {
				createWorkOrderModal: {
					workTemplateLoader: {
						$set: action.payload.value
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_DESYNCED':
			state = update(state, {
				createWorkOrderModal: {
					desynced: {
						$set: action.payload.value
					}
				}
			});

		case 'CREATE_WORK_ORDER_ADD_TAG':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						7: {
							tags: {
								$push: [action.payload.tag]
							}
						}
					}
				}
			});
			break;

		case 'CREATE_WORK_ORDER_REMOVE_TAG':
			state = update(state, {
				createWorkOrderModal: {
					sections: {
						7: {
							tags: {
								$splice: [[action.payload.removeIndex, 1]]
							}
						}
					}
				}
			});
			break;

		default:
			break;
	}
	return state;
};

// BASE SECTIONS TO RESET FIELDS ON TOGGLE | Needs to match intial state
const baseSections = [
	{
		name: 'workOrderTemplateDraftSection',
		fields: [
			{
				name: 'Templates',
				value: null,
				input: false,
				error: false,
				disabled: false,
				searchTemplate: true,
				options: null,
				loading: false,
				required: false,
				columnWidth: 8,
				placeholder: 'Select a template'
			},
			{
				name: 'Drafts',
				value: null,
				input: false,
				error: false,
				// DISBALED FOR NOW
				disabled: true,
				searchDrafts: true,
				options: null,
				loading: false,
				required: false,
				columnWidth: 8,
				placeholder: 'Select a draft'
			}
		]
	},
	{
		name: 'workOrderOverviewSection',
		fields: [
			{
				name: 'Client',
				value: '',
				input: false,
				error: false,
				required: true,
				loading: false,
				columnWidth: 4,
				searchClient: true
			},
			{
				name: 'Purchase Order Number',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4,
				loading: false
			},
			{
				name: 'Quoted Job Number (Optional)',
				value: '',
				input: false,
				error: false,
				disabled: false,
				required: false,
				columnWidth: 4,
				loading: false,
				searchQJN: true
			},
			{
				name: 'Service Type',
				value: 4,
				serviceTypeField: true,
				input: false,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4,
				options: [
					{
						key: 1,
						text: 'Deploy',
						value: 1
					},
					{
						key: 2,
						text: 'Design',
						value: 2
					},
					{
						key: 3,
						text: 'Build',
						value: 3
					},
					{
						key: 4,
						text: 'Support',
						value: 4
					}
				]
			},
			{
				name: 'Select Work Category',
				value: '',
				input: false,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4,
				options: [
					{
						key: 1,
						text: 'Access Control & Surveillance',
						value: 1
					},
					{
						key: 2,
						text: 'Audio Visual',
						value: 2
					},
					{
						key: 3,
						text: 'Network Integration & Telephony',
						value: 3
					},
					{
						key: 4,
						text: 'Point of Sale',
						value: 4
					},
					{
						key: 5,
						text: 'Power & Electrical',
						value: 5
					},
					{
						key: 6,
						text: 'Sound Masking & Paging',
						value: 6
					},
					{
						key: 7,
						text: 'Structured Cabling',
						value: 7
					},
					// {
					// 	key: 8,
					// 	text: 'LED Lighting',
					// 	value: 8
					// },
					{
						key: 9,
						text: 'Fire Alarm',
						value: 9
					}
				]
			},
			{
				name: 'Google Drive Path',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4
			},
			{
				name: 'New Construction',
				value: null,
				toggle: false,
				error: false,
				disabled: false,
				columnWidth: 4,
				options: [
					{
						key: 1,
						text: 'No',
						value: false
					},
					{
						key: 2,
						text: 'Yes',
						value: true
					}
				]
			},
			{
				name: 'Branch',
				value: null,
				input: false,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4,
				options: branchOptions
			},
			{
				name: 'Location',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4,
				upward: false,
				dataList: [],
				listName: 'LocationsList',
				locationSelected: false,
				selectedLocation: {},
				newButton: true,
				searchLocations: true
			},
			{
				name: 'Billing Contact',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: true,
				upward: false,
				columnWidth: 4,
				dataList: [],
				listName: 'billingContactsList',
				billingContactSelected: false,
				selectedBillingContact: {},
				newButton: true,
				searchBillingContacts: true
			},
			{
				name: 'Service Type Options',
				value: 1,
				skipFieldMap: true,
				input: false,
				error: false,
				disabled: false,
				required: false,
				columnWidth: 4,
				options: [
					{
						key: 1,
						text: 'Move, Add, Change',
						value: 1
					},
					{
						key: 2,
						text: 'Staging / Configuration',
						value: 2
					},
					{
						key: 3,
						text: 'Survey',
						value: 3
					},
					{
						key: 4,
						text: 'Repair',
						value: 4
					},
					{
						key: 5,
						text: 'Annuity',
						value: 5
					},
					{
						key: 6,
						text: 'Small Project',
						value: 6
					}
				]
			},
			{
				name: 'Project Manager',
				value: '',
				input: false,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4,
				employeeSearch: true
			},
			{
				name: 'Account Manager (Sales Rep)',
				value: '',
				input: false,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4,
				employeeSearch: true
			},
			{
				//Account Exec
				name: 'Sales Representative',
				value: '',
				input: false,
				error: false,
				disabled: false,
				required: true,
				columnWidth: 4,
				employeeSearch: true
			},
			{
				//NDS
				name: 'Project Administrator',
				value: '',
				input: false,
				error: false,
				disabled: false,
				required: false,
				columnWidth: 4,
				employeeSearch: true
			},
			{
				name: 'Master Client',
				value: '',
				input: false,
				error: false,
				required: true,
				loading: false,
				columnWidth: 4,
				searchClient: true
			},
			{
				name: 'Start Date',
				value: moment(new Date()).format('MM-DD-YYYY'),
				input: true,
				error: false,
				disabled: false,
				required: true,
				date: true,
				columnWidth: 4
			},
			{
				name: 'End Date',
				value: moment(new Date()).format('MM-DD-YYYY'),
				input: true,
				error: false,
				disabled: false,
				required: true,
				date: true,
				columnWidth: 4
			},
			{
				name: 'Date Received',
				value: moment(new Date()).format('MM-DD-YYYY'),
				input: true,
				error: false,
				disabled: false,
				required: true,
				date: true,
				time: true,
				columnWidth: 4
			}
		]
	},
	{
		name: 'workOrderProjectCostingSection',
		rowData: [],
		error: false,
		skuExists: true,
		summary: {},
		projectCostingSpreadsheetModal: {
			skuLoaded: false,
			toggle: false,
			applyMM: null,
			applyLM: null,
			SKUs: [],
			spreadSheetData: [],
			selectSKUModal: {
				toggle: false,
				SKUList: []
			}
		}
	},
	{
		name: 'scopeOfWorkSection',
		scopeOfWorkValue: ''
	},
	{
		name: 'documentsSection',
		fields: [
			{
				name: 'File Name',
				value: '',
				input: true,
				error: false,
				disabled: false,
				required: false,
				checkForm: true,
				columnWidth: 5
			},
			{
				name: 'Google Drive Link',
				value: '',
				input: true,
				error: false,
				disabled: false,
				checkForm: true,
				required: false,
				columnWidth: 5
			},
			{
				name: 'Customer Approval (PO)',
				value: false,
				input: false,
				dropdown: true,
				error: false,
				disabled: false,
				required: false,
				columnWidth: 5,
				options: [
					{
						key: 'No',
						text: 'No',
						value: false
					},
					{
						key: 'Yes',
						text: 'Yes',
						value: true
					}
				]
			},
			{
				name: 'Comments',
				value: '',
				input: false,
				textArea: true,
				error: false,
				disabled: false,
				required: false,
				columnWidth: 15
			}
		],
		documents: []
	},
	{
		name: 'contactSection',
		contacts: [],
		loading: false,
		editError: false
	},
	{
		name: 'AASDISection',
		AASDI: [],
		addContactModal: {
			open: false,
			searchLoading: false,
			error: false,
			options: [],
			contact: {
				isPrimary: 0,
				id: '',
				name: ''
			}
		},
		modal: {
			name: 'Modal',
			value: '',
			input: true,
			error: false,
			loading: false,
			columnWidth: 4,
			radiusOptions: [
				{key: '10', text: 10, value: 10},
				{key: '25', text: 25, value: 25},
				{key: '50', text: 50, value: 50},
				{key: '75', text: 75, value: 75},
				{key: '100', text: 100, value: 100},
				{key: '150', text: 150, value: 150},
				{key: '200', text: 200, value: 200},
				{key: '>200', text: '>200', value: '>200'}
			],
			selectedRadius: 25,
			selectedZip: '',
			zipError: false,
			selectedNameSearch: '',
			dataList: [],
			listName: 'ModalList',
			AASDISelected: false,
			selectedAASDI: {},
			searchAASDIModal: true,
			toggled: false
		}
	},
	{
		name: 'tagsSection',
		tags: []
	}
];
