// TODOS
// clean up actions that need cleaning up
import customFetch from '../../../../lib/old/customFetch.js';
import {
	savingChanges,
	savingChangesSuccess,
	savingChangesFailure,
	setSnackbarValues,
	hideSnackbarAfterDelay
} from './snackbar.actions.js';

// ### GET OPERATIONS ###
export const TASK_TEMPLATES_RETRIEVE_TEMPLATES = 'TASK_TEMPLATES_RETRIEVE_TEMPLATES';
export function retrieveTemplates() {
	return async (dispatch) => {
		dispatch({type: TASK_TEMPLATES_RETRIEVE_TEMPLATES});
		try {
			const request = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?mode=template`);
			const response = await request.json();
			console.log(response, response.inputMask);
			dispatch(retrieveTemplatesSuccess(response.templates, response.taskList, response.inputMask));
		} catch (e) {
			console.log(e);
			dispatch(retrieveTemplatesFailure());
		}
	};
}

export const TASK_TEMPLATES_RETRIEVE_TEMPLATES_SUCCESS = 'TASK_TEMPLATES_RETRIEVE_TEMPLATES_SUCCESS';
export const retrieveTemplatesSuccess = (templates, taskList, inputMaskList) => ({
	type: TASK_TEMPLATES_RETRIEVE_TEMPLATES_SUCCESS,
	payload: {templates, taskList, inputMaskList}
});

export const TASK_TEMPLATES_RETRIEVE_TEMPLATES_FAILURE = 'TASK_TEMPLATES_RETRIEVE_TEMPLATES_FAILURE';
export const retrieveTemplatesFailure = () => ({
	type: TASK_TEMPLATES_RETRIEVE_TEMPLATES_FAILURE
});

// ### POST OPERATIONS ###
export const TASK_TEMPLATES_CREATE_TEMPLATE = 'TASK_TEMPLATES_CREATE_TEMPLATE';
export const createTemplate = () => {
	return async (dispatch) => {
		dispatch(savingChanges);
		const request = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/task?mode=template`, {
			method: 'POST',
			body: JSON.stringify({
				name: 'New Template',
				description: 'New Template'
			})
		});

		if (request.status !== 200) {
			dispatch(savingChangesFailure);
			return;
		}

		const response = await request.json();

		dispatch({
			type: TASK_TEMPLATES_CREATE_TEMPLATE,
			payload: {newTemplate: response}
		});
		dispatch(
			setSnackbarValues({
				loading: false,
				icon: 'check',
				message: 'Template created successfully',
				color: 'green',
				redirect: `/task-templates/edit/${response.id}`
			})
		);
		hideSnackbarAfterDelay(dispatch, 3000);
	};
};

// ### PUT OPERATIONS ###
export const TASK_TEMPLATES_EDIT_TEMPLATE = 'TASK_TEMPLATES_EDIT_TEMPLATE';
export const editTemplate = () => ({type: TASK_TEMPLATES_EDIT_TEMPLATE});

export const TASK_TEMPLATE_CHANGE_TEMPLATE_VALUES = 'TASK_TEMPLATE_CHANGE_TEMPLATE_VALUES';
export const changeTemplateValues = (updatedValues, templateId) => ({
	type: TASK_TEMPLATE_CHANGE_TEMPLATE_VALUES,
	payload: {
		updatedValues,
		templateId
	}
});

export const TASK_TEMPLATE_UPDATE_TEMPLATE = 'TASK_TEMPLATE_UPDATE_TEMPLATE';
export const updateTemplate = (template) => {
	return async (dispatch) => {
		dispatch(savingChanges);

		const request = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task?type=template&mode=template&templateId=${template.id}`,
			{
				method: 'PUT',
				body: JSON.stringify(template)
			}
		);
		if (request.status !== 200) {
			dispatch(savingChangesFailure);
			return;
		}

		dispatch(savingChangesSuccess);
		hideSnackbarAfterDelay(dispatch, 3000);

		dispatch(updateTemplateSuccess(template));
	};
};

export const TASK_TEMPLATE_UPDATE_TEMPLATE_SUCCESS = 'TASK_TEMPLATE_UPDATE_TEMPLATE_SUCCESS';
export const updateTemplateSuccess = (updatedTemplate) => ({
	type: TASK_TEMPLATE_UPDATE_TEMPLATE_SUCCESS,
	payload: {updatedTemplate}
});

export const TASK_TEMPLATE_UPDATE_TEMPLATE_FAILURE = 'TASK_TEMPLATE_UPDATE_TEMPLATE_FAILURE';

export const TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS = 'TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS';
export const updateClientListSearchResults = (searchQuery) => {
	return async (dispatch) => {
		dispatch({type: TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS});

		const request = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task?clientSearch=${encodeURIComponent(
				searchQuery
			)}&mode=template-client-list`
		);
		if (request.status !== 200) {
			dispatch(updateClientListSearchResultsFailure());
			return;
		}
		const response = await request.json();
		const formattedSearchResults = response.map((client) => ({
			text: client.clientName,
			value: {
				clientName: client.clientName,
				clientId: client.clientId
			}
		}));
		dispatch(updateClientListSearchResultsSuccess(formattedSearchResults));
	};
};

export const TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_SUCCESS =
	'TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_SUCCESS';
export const updateClientListSearchResultsSuccess = (searchResults) => ({
	type: TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_SUCCESS,
	payload: {searchResults}
});

export const TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_FAILURE =
	'TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_FAILURE';
export const updateClientListSearchResultsFailure = () => ({
	type: TASK_TEMPLATE_UPDATE_CLIENT_LIST_SEARCH_RESULTS_FAILURE
});

// ### DELETE OPERATIONS ###
export const TASK_TEMPLATE_DELETE_TEMPLATE = 'TASK_TEMPLATE_DELETE_TEMPLATE';
export const deleteTemplate = (templateId) => {
	return async (dispatch) => {
		dispatch(savingChanges);

		const request = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task/${templateId}?mode=template&templateId=${templateId}`,
			{
				method: 'DELETE'
			}
		);
		if (request.status !== 200) {
			dispatch(savingChangesFailure);
		}
		dispatch(savingChangesSuccess);
		hideSnackbarAfterDelay(dispatch, 3000);

		dispatch(deleteTemplateSuccess(templateId));
	};
};

export const TASK_TEMPLATE_DELETE_TEMPLATE_SUCCESS = 'TASK_TEMPLATE_DELETE_TEMPLATE_SUCCESS';
export const deleteTemplateSuccess = (templateId) => ({
	type: TASK_TEMPLATE_DELETE_TEMPLATE_SUCCESS,
	payload: {templateId}
});
