import update from 'react-addons-update';
import moment from 'moment';

export default (
	state = {
		sendToModal: {
			toggleSendToModal: false,
			place: null,
			id: null,
			loading: null,
			projectedOrderDate: {
				name: 'Projected Order Date',
				value: null,
				error: false,
				loading: false,
				required: true,
				filled: false
			},
			accountExec: {
				name: 'Account Executive',
				value: null,
				error: false,
				searchAE: true,
				options: null,
				loading: false,
				required: true,
				filled: false
			}
		}
	},
	action
) => {
	switch (action.type) {
		case 'SEND_TO_MODAL_TOGGLE':
			state = update(state, {
				sendToModal: {
					toggleSendToModal: {
						$set: !state.sendToModal.toggleSendToModal
					}
				}
			});
			break;

		case 'SEND_TO_MODAL_LOADING':
			state = update(state, {
				sendToModal: {
					loading: {
						$set: action.payload
					}
				}
			});
			break;

		case 'SEND_TO_MODAL_LOADING_FIELD':
			state = update(state, {
				sendToModal: {
					[action.payload.column]: {
						loading: {
							$set: true
						}
					}
				}
			});
			break;

		case 'SEND_TO_MODAL_GET_DROPDOWN_OPTIONS':
			state = update(state, {
				sendToModal: {
					[action.payload.column]: {
						options: {
							$set: action.payload.options
						},
						loading: {
							$set: false
						}
					}
				}
			});
			break;

		case 'SEND_TO_MODAL_UPDATE':
			state = update(state, {
				sendToModal: {
					[action.payload.column]: {
						value: {
							$set: action.payload.value
						},
						error: {
							$set: false
						}
					}
				}
			});
			break;

		case 'SEND_TO_MODAL_FIELD_ERROR':
			state = update(state, {
				sendToModal: {
					[action.payload.column]: {
						error: {
							$set: action.payload.error
						}
					}
				}
			});
			break;

		case 'SEND_TO_MODAL_UPDATE_MODAL_ID':
			state = update(state, {
				sendToModal: {
					id: {
						$set: action.payload.id
					},
					place: {
						$set: action.payload.place
					},
					accountExec: {
						value: {
							$set: action.payload.acctExecId ? action.payload.acctExecId : null
						},
						filled: {
							$set: Boolean(action.payload.acctExecId)
						}
					},
					projectedOrderDate: {
						value: {
							$set: action.payload.projOrderDate
								? moment(action.payload.projOrderDate)
										.format('MM-DD-YYYY')
										.toString()
								: null
						},
						filled: {
							$set: Boolean(action.payload.projOrderDate)
						}
					}
				}
			});
			break;
	}

	return state;
};
