const techManagementReducer = (
	state = {
		loading: true,
		refreshLoading: {
			loading: false,
			icon: 'refresh'
		},
		users: {
			token: null,
			history: [],
			users: [],
			totalCount: null,
			loading: false,
			new: true
		},
		approvals: {
			token: null,
			history: [],
			approvals: [],
			totalCount: null,
			loading: false,
			new: true
		},
		sorting: {
			directions: {
				users: null,
				approvals: null
			},
			columns: {
				users: null,
				approvals: null
			}
		},
		filtering: {
			toggle: false,
			users: {
				AASDI: null,
				Approved: null,
				Email: null,
				EmailVerified: null,
				Enabled: null,
				UserCreateDate: null,
				UserLastModifiedDate: null,
				UserStatus: null,
				Username: null
			},
			approvals: {
				Username: null,
				Approved: null
			}
		},
		search: {
			text: null,
			column: 'Email',
			searchLoading: false
		},
		createUser: {
			toggleCreateUserModal: false,
			loading: false,
			user: {
				Email: null,
				AASDI: null
			},
			message: {
				hideCreateUserMessage: true,
				text: ''
			}
		},
		editUser: {
			toggleEditUserModal: false,
			loading: false,
			user: {
				Email: null,
				AASDI: null
			},
			message: {
				hideEditUserMessage: true,
				text: null
			}
		},
		activateUsers: {
			toggleActivateUsersModal: false,
			loading: false,
			users: [],
			message: {
				hideActivateUsersMessage: true,
				text: null
			}
		},
		deactivateUsers: {
			toggleDeactivateUsersModal: false,
			loading: false,
			users: [],
			message: {
				hideDeactivateUsersMessage: true,
				text: null
			}
		},
		resetUsersPassword: {
			toggleResetUsersPasswordModal: false,
			loading: false,
			users: [],
			message: {
				hideResetUsersPasswordMessage: true,
				text: null
			}
		},
		approveUsers: {
			toggleApproveUsersModal: false,
			loading: false,
			users: [],
			message: {
				hideApproveUsersMessage: true,
				text: null
			}
		},
		disapproveUser: {
			toggleDisapproveUser: false,
			loading: false,
			user: {
				Email: null,
				AASDI: null
			},
			message: {
				hideDisapproveUserMessage: true,
				text: null
			}
		},
		toggleToTop: {
			users: 'hideToTop',
			approvals: 'hideToTop'
		}
	},
	action
) => {
	switch (action.type) {
		case 'TECH_MANAGEMENT_GET_USERS':
			state = {
				...state,
				users: {
					...state.users,
					token: action.payload.paginationToken,
					history: [...state.users.users, action.payload.users],
					users: action.payload.users,
					totalCount: action.payload.userCount
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_USER_NEW':
			state = {
				...state,
				users: {
					...state.users,
					new: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_GET_USER_LOADER':
			state = {
				...state,
				loading: action.payload
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_MORE_USER_LOADER':
			state = {
				...state,
				users: {
					...state.users,
					loading: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_GET_APPROVALS':
			state = {
				...state,
				approvals: {
					...state.approvals,
					token: action.payload.paginationToken,
					history: [...state.approvals.approvals, action.payload.users],
					approvals: action.payload.users,
					totalCount: action.payload.users.length
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_APPROVALS_NEW':
			state = {
				...state,
				approvals: {
					...state.approvals,
					new: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_MORE_APPROVALS_LOADER':
			state = {
				...state,
				approvals: {
					...state.approvals,
					loading: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_CREATE_USER':
			state = {
				...state,
				createUser: {
					...state.createUser,
					user: state.users.users.unshift(action.payload)
				}
			};
			break;

		case 'TECH_MANAGEMENT_CREATE_APPROVAL':
			state = {
				...state,
				createUser: {
					...state.createUser,
					user: state.approvals.approvals.unshift(action.payload)
				},
				approvals: {
					...state.approvals,
					totalCount: state.approvals.approvals.length
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_CREATE_USER_OBJECT':
			state = {
				...state,
				createUser: {
					...state.createUser,
					user: {
						...state.user,
						AASDI: action.payload.label === 'aasdi' ? action.payload.value : state.createUser.user.AASDI,
						Email: action.payload.label === 'email' ? action.payload.value : state.createUser.user.Email
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_CREATE_USER_MODAL':
			state = {
				...state,
				createUser: {
					...state.createUser,
					toggleCreateUserModal: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_CREATE_USER_LOADER':
			state = {
				...state,
				createUser: {
					...state.createUser,
					loading: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_HIDE_CREATE_USER_MESSAGE':
			state = {
				...state,
				createUser: {
					...state.createUser,
					message: {
						...state.message,
						hideCreateUserMessage: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_CREATE_USER_MESSAGE':
			state = {
				...state,
				createUser: {
					...state.createUser,
					message: {
						...state.message,
						text: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_EDIT_USER':
			state = {
				...state,
				users: {
					...state.users,
					users: [
						...state.users.users.slice(0, action.payload.index),
						action.payload.user,
						...state.users.users.slice(action.payload.index + 1)
					],
					history: [
						...state.users.history,
						[
							...state.users.users.slice(0, action.payload.index),
							action.payload.user,
							...state.users.users.slice(action.payload.index + 1)
						]
					]
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_EDIT_USER_OBJECT':
			state = {
				...state,
				editUser: {
					...state.editUser,
					user: {
						...state.editUser.user,
						AASDI: action.payload.label === 'aasdi' ? action.payload.value : state.editUser.user.AASDI,
						Email: action.payload.label === 'email' ? action.payload.value : state.editUser.user.Email
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_EDIT_USER_OBJECT':
			state = {
				...state,
				editUser: {
					...state.editUser,
					user: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_EDIT_USER_MODAL':
			state = {
				...state,
				editUser: {
					...state.editUser,
					toggleEditUserModal: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_EDIT_USER_LOADER':
			state = {
				...state,
				editUser: {
					...state.editUser,
					loading: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_HIDE_EDIT_USER_MESSAGE':
			state = {
				...state,
				editUser: {
					...state.editUser,
					message: {
						...state.editUser.message,
						hideEditUserMessage: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_EDIT_USER_MESSAGE':
			state = {
				...state,
				editUser: {
					...state.editUser,
					message: {
						...state.editUser.message,
						text: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_ACTIVATE_USERS_MODAL':
			state = {
				...state,
				activateUsers: {
					...state.activateUsers,
					toggleActivateUsersModal: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_ACTIVATE_USERS':
			state = {
				...state,
				users: {
					...state.users,
					users: action.payload,
					history: [...state.users.history, action.payload]
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_ACTIVATE_USERS':
			state = {
				...state,
				activateUsers: {
					...state.activateUsers,
					users: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_ACTIVATE_USERS_LOADER':
			state = {
				...state,
				activateUsers: {
					...state.activateUsers,
					loading: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_HIDE_ACTIVATE_USERS_MESSAGE':
			state = {
				...state,
				activateUsers: {
					...state.activateUsers,
					message: {
						...state.activateUsers.message,
						hideActivateUsersMessage: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_ACTIVATE_USERS_MESSAGE':
			state = {
				...state,
				activateUsers: {
					...state.activateUsers,
					message: {
						...state.activateUsers.message,
						text: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_DEACTIVATE_USERS_MODAL':
			state = {
				...state,
				deactivateUsers: {
					...state.deactivateUsers,
					toggleDeactivateUsersModal: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_DEACTIVATE_USERS':
			state = {
				...state,
				users: {
					...state.users,
					users: action.payload,
					history: [...state.users.history, action.payload]
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_DEACTIVATE_USERS':
			state = {
				...state,
				deactivateUsers: {
					...state.deactivateUsers,
					users: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_DEACTIVATE_USERS_LOADER':
			state = {
				...state,
				deactivateUsers: {
					...state.deactivateUsers,
					loading: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_HIDE_DEACTIVATE_USERS_MESSAGE':
			state = {
				...state,
				deactivateUsers: {
					...state.deactivateUsers,
					message: {
						...state.deactivateUsers.message,
						hideDeactivateUsersMessage: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_DEACTIVATE_USERS_MESSAGE':
			state = {
				...state,
				deactivateUsers: {
					...state.deactivateUsers,
					message: {
						...state.deactivateUsers.message,
						text: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_RESET_USERS_PASSWORD_MODAL':
			state = {
				...state,
				resetUsersPassword: {
					...state.resetUsersPassword,
					toggleResetUsersPasswordModal: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_RESET_USERS_PASSWORD':
			state = {
				...state,
				users: {
					...state.users,
					users: action.payload,
					history: [...state.users.history, action.payload]
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_RESET_USERS_PASSWORD':
			state = {
				...state,
				resetUsersPassword: {
					...state.resetUsersPassword,
					users: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_RESET_USERS_PASSWORD_LOADER':
			state = {
				...state,
				resetUsersPassword: {
					...state.resetUsersPassword,
					loading: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_HIDE_RESET_USERS_PASSWORD_MESSAGE':
			state = {
				...state,
				resetUsersPassword: {
					...state.resetUsersPassword,
					message: {
						...state.resetUsersPassword.message,
						hideResetUsersPasswordMessage: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_RESET_USERS_PASSWORD_MESSAGE':
			state = {
				...state,
				resetUsersPassword: {
					...state.resetUsersPassword,
					message: {
						...state.resetUsersPassword.message,
						text: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_USER':
			state = {
				...state,
				users: {
					...state.users,
					users: [
						...state.users.users.slice(0, action.payload.index),
						action.payload.user,
						...state.users.users.slice(action.payload.index + 1)
					],
					history: [
						...state.users.history,
						[
							...state.users.users.slice(0, action.payload.index),
							action.payload.user,
							...state.users.users.slice(action.payload.index + 1)
						]
					]
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_APPROVALS':
			state = {
				...state,
				approvals: {
					...state.approvals,
					approvals: [
						...state.approvals.approvals.slice(0, action.payload.index),
						action.payload.approvals,
						...state.approvals.approvals.slice(action.payload.index + 1)
					],
					history: [
						...state.approvals.history,
						[
							...state.approvals.approvals.slice(0, action.payload.index),
							action.payload.approvals,
							...state.approvals.approvals.slice(action.payload.index + 1)
						]
					]
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_ALL':
			state = {
				...state,
				users: {
					...state.users,
					users: !action.payload.users ? state.users.users : action.payload.users,
					history: [...state.users.history, action.payload.users]
				},
				approvals: {
					...state.approvals,
					approvals: !action.payload.approvals ? state.approvals.approvals : action.payload.approvals
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_FILTER':
			state = {
				...state,
				filtering: {
					...state.filtering,
					toggle: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_FILTER_USERS':
			state = {
				...state,
				users: {
					...state.users,
					users: action.payload,
					history: [...state.users.history, action.payload]
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_FILTER':
			state = {
				...state,
				filtering: {
					...state.filtering,
					users: {
						...state.filtering.users,
						Username:
							action.payload.label === 'username' ? action.payload.value : state.filtering.users.Username,
						AASDI: action.payload.label === 'aasdi' ? action.payload.value : state.filtering.users.AASDI,
						Email: action.payload.label === 'email' ? action.payload.value : state.filtering.users.Email,
						Approved:
							action.payload.label === 'approved' ? action.payload.value : state.filtering.users.Approved,
						EmailVerified:
							action.payload.label === 'emailVerified'
								? action.payload.value
								: state.filtering.users.EmailVerified,
						Enabled:
							action.payload.label === 'enabled' ? action.payload.value : state.filtering.users.Enabled,
						UserCreateDate:
							action.payload.label === 'userCreateDate'
								? action.payload.value
								: state.filtering.users.UserCreateDate,
						UserLastModifiedDate:
							action.payload.label === 'userLastModifiedDate'
								? action.payload.value
								: state.filtering.users.UserLastModifiedDate,
						UserStatus:
							action.payload.label === 'userStatus'
								? action.payload.value
								: state.filtering.users.UserStatus
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_SEARCH':
			state = {
				...state,
				search: {
					...state.search,
					text: action.payload.text
						? action.payload.text
						: action.payload.text === null
						? state.search.text
						: '',
					column: action.payload.column
						? action.payload.column
						: action.payload.column === null
						? state.search.column
						: 'Email'
				}
			};
			break;

		case 'TECH_MANAGEMENT_CLEAR_FILTER':
			state = {
				...state,
				filtering: {
					...state.filtering,
					users: {
						AASDI: '',
						Approved: '',
						Email: '',
						EmailVerified: '',
						Enabled: '',
						UserCreateDate: '',
						UserLastModifiedDate: '',
						UserStatus: '',
						Username: ''
					},
					approvals: {
						Username: '',
						Approved: ''
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_SORT_USERS':
			state = {
				...state,
				users: action.payload.users ? action.payload.users : state.users,
				approvals: action.payload.approvals ? action.payload.approvals : state.approvals,
				sorting: action.payload.sorting
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_APPROVALS_COUNT':
			state = {
				...state,
				approvals: {
					...state.approvals,
					totalCount: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_APPROVE_USERS_MODAL':
			state = {
				...state,
				approveUsers: {
					...state.approveUsers,
					toggleApproveUsersModal: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_APPROVE_USERS':
			state = {
				...state,
				approvals: {
					...state.approvals,
					approvals: action.payload,
					history: [...state.approvals.history, action.payload],
					totalCount: state.approvals.approvals.length
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_APPROVE_USERS':
			state = {
				...state,
				approveUsers: {
					...state.approveUsers,
					users: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_APPROVE_USERS_LOADER':
			state = {
				...state,
				approveUsers: {
					...state.approveUsers,
					loading: action.payload
				}
			};
			break;

		case 'TECH_MANAGEMENT_HIDE_APPROVE_USERS_MESSAGE':
			state = {
				...state,
				approveUsers: {
					...state.approveUsers,
					message: {
						...state.approveUsers.message,
						hideApproveUsersMessage: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_UPDATE_APPROVE_USERS_MESSAGE':
			state = {
				...state,
				approveUsers: {
					...state.approveUsers,
					message: {
						...state.approveUsers.message,
						text: action.payload
					}
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_TO_TOP':
			state = {
				...state,
				toggleToTop: {
					users: !action.payload.users
						? state.toggleToTop === 'hideToTop'
							? 'showToTop'
							: 'hideToTop'
						: action.payload.users === true
						? 'showToTop'
						: 'hideToTop',
					approvals: !action.payload.approvals
						? state.toggleToTop === 'hideToTop'
							? 'showToTop'
							: 'hideToTop'
						: action.payload.approvals === true
						? 'showToTop'
						: 'hideToTop'
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_REFRESH_LOADER':
			state = {
				...state,
				refreshLoading: {
					...state.refreshLoading,
					loading: action.payload.loading,
					icon: action.payload.icon
				}
			};
			break;

		case 'TECH_MANAGEMENT_TOGGLE_SEARCH_LOADER':
			state = {
				...state,
				search: {
					...state.search,
					searchLoading: action.payload
				}
			};
			break;

		default:
			break;
	}

	return state;
};

export default techManagementReducer;
