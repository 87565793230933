import React from 'react';
import {Segment, Icon, Message, Grid} from 'semantic-ui-react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// const Log = props => {
class ExpiredInsuranceMessage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Grid.Column width={16}>
				<Segment
					inverted
					color="red"
					style={{
						height: '15vh',
						paddingBottom: '1vh',
						marginBottom: '5vh'
					}}
				>
					<Message style={{margin: '.5vw'}}>
						<Message.Header> Insurance Expired</Message.Header>
						<p>
							This AASDI's Workers' Comp or Liability Insurance has expired. This must be resolved and
							updated in spectrum before this AASDI can selected to perform work.
						</p>
					</Message>
					{/* <Icon
						name="unlink"
						size="massive"
						style={{ margin: '2vw 2vw 2vw 2vw' }}
					/> */}
				</Segment>
			</Grid.Column>
		);
	}
}

ExpiredInsuranceMessage.propTypes = {
	changedArray: PropTypes.array
};

// const mapStateToProps = state => {
// 	return {
// 		changedArray:
// 			state.accountingConsoleReducer.accountingConsole.changedArray
// 	};
// };

export default connect(null, null)(ExpiredInsuranceMessage);
