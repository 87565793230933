import {Form} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';

const FormUserInputTaskType = (props) => {
	return props.task.id ? (
		<Form.Field key={`${props.column}-formField`}>
			<div>
				{'User Input:  '}
				<label>{props.task.formUserInput}</label>
			</div>
		</Form.Field>
	) : null;
};

export default FormUserInputTaskType;
