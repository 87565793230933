import React from 'react';
import {Grid, Divider} from 'semantic-ui-react';

import Statistics from './components/Statistics.js';
import Steps from '../../../../components/steps/Steps.js';
import Filters from '../../../../components/filters/Filters.js';
import Group from './components/CardGroup/CardGroup.js';

let CardView = () => [
	<Filters key={'page-filters'} />,
	<Divider key={'page-filters-divider'} />,
	<Steps key={'pipeline-card-header'} cardView />,
	<Statistics key={'pipeline-statistics-card'} />,
	<Grid.Row key={'pipeline-card-grid'}>
		<Grid columns={'equal'} divided>
			<Group.IdentifiedNeed />
			<Group.Estimating />
			<Group.SubmittedQuote />
			<Group.HighProbability />
			<Group.VerbalAward />
		</Grid>
	</Grid.Row>
];

export default CardView;
