import update from 'react-addons-update';
/**
 * The global state object
 * @namespace global
 * @memberof App.Store.Reducers
 */
const global = (
	state = {
		/**
		 * Holds the state of the messages component.
		 * @namespace message
		 * @memberof App.Store.Reducers.global
		 */
		message: {
			/**
			 * Toggles if the messages is hidden or shown.
			 * @memberof App.Store.Reducers.global.message#
			 */
			hideMessage: true,
			/**
			 * Toggles if the messages is success.
			 * @memberof App.Store.Reducers.global.message#
			 */
			success: false,
			/**
			 * Toggles if the messages is error.
			 * @memberof App.Store.Reducers.global.message#
			 */
			error: false,
			/**
			 * Toggles if the messages is warning.
			 * @memberof App.Store.Reducers.global.message#
			 */
			warning: false,
			/**
			 * The text that is shown in the message box.
			 * @memberof App.Store.Reducers.global.message#
			 */
			text: null,
			/**
			 * The icon that is shown left of the text in the message box.
			 * @memberof App.Store.Reducers.global.message#
			 */
			icon: null
		},
		/**
		 * Holds the state of the UI.
		 * @namespace ui
		 * @memberof App.Store.Reducers.global
		 */
		ui: {
			/**
			 * Holds the state of the progressLoader component.
			 * We are moving all UI related states under this object.
			 * @namespace progressLoader
			 * @memberof App.Store.Reducers.global.ui
			 */
			progressLoader: {
				percent: null,
				error: false,
				success: false,
				message: ''
			},
			timeout: null,
			discardChanges: {
				toggleModal: false,
				message: '',
				onConfirm: null,
				params: null
			}
		},
		/**
		 * Holds the state of the page loader.
		 * @namespace pageLoading
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.ui]{@link App.Store.Reducers.global.ui}.
		 * @typedef {boolean}
		 */
		pageLoading: false,
		/**
		 * Holds the boolean state of loading errors.
		 * @namespace pageLoadingError
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.ui]{@link App.Store.Reducers.global.ui}.
		 */
		pageLoadingError: false,
		/**
		 * Holds the boolean state of toTop for if the component is show or hidden.
		 * @namespace pageLoadingError
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.ui]{@link App.Store.Reducers.global.ui}.
		 */
		toggleToTop: 'hideToTop',
		/**
		 * Holds the boolean state of modal to create a new contact.
		 * @namespace showCreateContactModal
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.showCreateContactModal]{@link App.Store.Reducers.global.showCreateContactModal}.
		 */
		showCreateContactModal: false,
		/**
		 * Holds the boolean state of modal to create a new contact.
		 * @namespace showCreateAasdiModal
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.showCreateAasdiModal]{@link App.Store.Reducers.global.showCreateAasdiModal}.
		 */
		showCreateAasdiModal: false,
		/**
		 * Holds the boolean state of modal to create a new rating.
		 * @namespace showCreateRatingModal
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.showCreateRatingModal]{@link App.Store.Reducers.global.showCreateRatingModal}.
		 */
		showCreateRatingModal: false,
		/**
		 * Holds the boolean state to inform if tabs navbar is fetching data to ag-grid.
		 * @namespace tabsNavbarIsFetching
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.tabsNavbarIsFetching]{@link App.Store.Reducers.global.tabsNavbarIsFetching}.
		 */
		tabsNavbarIsFetching: false,
		/**
		 * Holds the boolean state of modal to create a new location.
		 * @namespace showCreateLocationModal
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.showCreateLocationModal]{@link App.Store.Reducers.global.showCreateLocationModal}.
		 */
		showCreateLocationModal: false,
		/**
		 * Holds the boolean state of modal to create a new location.
		 * @namespace showLocationOrderHistModal
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.showLocationOrderHistModal]{@link App.Store.Reducers.global.showLocationOrderHistModal}.
		 */
		showLocationOrderHistModal: false,
		/**
		 * Holds the boolean state of modal to create new information.
		 * @namespace showCreationModal
		 * @memberof App.Store.Reducers.global
		 * @todo Move under [App.Store.Reducers.global.showCreationModal]{@link App.Store.Reducers.global.showCreationModal}.
		 */
		showCreationModal: false
	},
	action
) => {
	switch (action.type) {
		case 'GLOBAL_HIDE_MESSAGE':
			state = {
				...state,
				message: {
					...state.message,
					hideMessage: action.payload
				}
			};
			break;

		case 'GLOBAL_UPDATE_MESSAGE':
			state = {
				...state,
				message: {
					...state.message,
					text: action.payload.text,
					icon: action.payload.icon,
					warning: action.payload.warning === undefined ? false : action.payload.warning,
					success: action.payload.success === undefined ? false : action.payload.success,
					error: action.payload.error === undefined ? false : action.payload.error
				}
			};
			break;

		case 'GLOBAL_UPDATE_PAGE_LOADER':
			if (action.payload.loadingError != null) {
				state = update(state, {
					pageLoadingError: {
						$set: action.payload.loadingError
					}
				});
			}
			if (action.payload.loading != null) {
				state = update(state, {
					pageLoading: {
						$set: action.payload.loading
					}
				});
			}
			break;

		default:
			break;

		case 'GLOBAL_TOGGLE_TO_TOP':
			state = update(state, {
				toggleToTop: {
					$set: action.payload.toggle ? 'showToTop' : 'hideToTop'
				}
			});
			break;
		case 'GLOBAL_LOADER_ANIMATION':
			state = update(state, {
				ui: {
					progressLoader: {
						$set: action.payload
					}
				}
			});
			break;
		case 'GLOBAL_UPDATE_TIMEOUT':
			state = update(state, {
				ui: {
					timeout: {
						$set: action.payload
					}
				}
			});
			break;
		case 'GLOBAL_TOGGLE_CREATE_CONTACT_MODAL':
			state = update(state, {
				showCreateContactModal: {
					$set: action.payload
				}
			});
			break;
		case 'GLOBAL_TOGGLE_CREATE_RATING_MODAL':
			state = update(state, {
				showCreateRatingModal: {
					$set: action.payload
				}
			});
			break;
		case 'GLOBAL_TOGGLE_CREATE_LOCATION_MODAL':
			state = update(state, {
				showCreateLocationModal: {
					$set: action.payload
				}
			});
			break;
		case 'GLOBAL_TOGGLE_LOCATION_ORDER_HISTORY':
			state = update(state, {
				showLocationOrderHistModal: {
					$set: action.payload
				}
			});
			break;
		case 'GLOBAL_FETCHING_TABS_NAVBAR':
			state = update(state, {
				tabsNavbarIsFetching: {
					$set: action.payload
				}
			});
			break;
		case 'GLOBAL_CREATION_MODAL_STATUS':
			state = update(state, {
				showCreationModal: {
					$set: action.payload
				}
			});
			break;
		// /**
		//  * ### GLOBAL_TOGGLE_DISCARD_CHANGES_MODAL
		//  * This reducer toggles the discard changes modal, updates the onConfirmation method and its params.
		//  * @type reducer
		//  * @global
		//  * @author Daniel Cornelison <daniel.cornelison@hexagonsi.com>
		//  */
		case 'GLOBAL_TOGGLE_DISCARD_CHANGES_MODAL':
			state = update(state, {
				ui: {
					discardChanges: {
						toggleModal: {
							$set: !state.ui.discardChanges.toggleModal
						},
						onConfirmation: {$set: action.payload.onConfirm},
						params: {$set: action.payload.params}
					}
				}
			});
			break;
	}

	return state;
};

export default global;
