// Modules
import React from 'react';
import {Segment, Statistic} from 'semantic-ui-react';

// Components
import Quick from './components/Quick.js';

// Services
import './Statistics.css';

let Statistics = () => {
	return console.error('Select a sub component');
};

export default Statistics;

Statistics.Quick = () => [<Quick key="quick" />];

Statistics.IdentifiedNeed = ({revenue, loading}) => [
	<Segment key={`IdentifiedNeed`} fluid basic textAlign="center" loading={loading}>
		<Statistic size="mini" value={revenue} />
	</Segment>
];

Statistics.Estimating = ({revenue, loading}) => [
	<Segment key={`Estimating`} fluid basic textAlign="center" loading={loading}>
		<Statistic size="mini" value={revenue} />
	</Segment>
];

Statistics.SubmittedQuote = ({revenue, loading}) => [
	<Segment key={`SubmittedQuote`} fluid basic textAlign="center" loading={loading}>
		<Statistic size="mini" value={revenue} />
	</Segment>
];

Statistics.HighProbability = ({revenue, loading}) => [
	<Segment key={`HighProbability`} fluid basic textAlign="center" loading={loading}>
		<Statistic size="mini" value={revenue} />
	</Segment>
];

Statistics.VerbalAward = ({revenue, loading}) => [
	<Segment key={`VerbalAward`} fluid basic textAlign="center" loading={loading}>
		<Statistic size="mini" value={revenue} />
	</Segment>
];

Statistics.ClosedBusiness = ({revenue, loading}) => [
	<Segment key={`ClosedBusiness`} fluid basic textAlign="center" loading={loading}>
		<Statistic size="mini" value={revenue} />
	</Segment>
];
