import React from 'react';
import {Input, Segment, Grid, Label} from 'semantic-ui-react';
import {DebounceInput} from 'react-debounce-input';
import {connect} from 'react-redux';

import * as actions from '../../services/createProjectActions.js';

import InputField from '../components/inputField.js';
import DropdownField from '../components/dropdownField.js';
import SearchField from '../components/searchField.js';

const OpportunitySection = (props) => (
	<Segment basic>
		<Grid>
			<Grid.Row>
				{props.sectionFields.map(
					(field, fieldIndex) => (
						// field.employeeSearch || field.searchClient ? (
						<Grid.Column
							style={{
								paddingTop: '.5em',
								paddingBottom: '.5em'
							}}
							width={field.columnWidth}
							key={fieldIndex}
						>
							{field.employeeSearch || field.searchClient ? (
								<SearchField field={field} sectionIndex={props.sectionIndex} fieldIndex={fieldIndex} />
							) : field.purchaseOrder ? (
								//TODO: client name
								<React.Fragment>
									{console.log(field)}
									<div style={{paddingTop: '0.8em'}}>
										<Label
											style={{
												backgroundColor: 'transparent',
												fontSize: '1rem',
												color: 'rgba(0,0,0,.8)',
												paddingLeft: '0'
											}}
										>
											{field.name}
										</Label>
									</div>
									<DebounceInput
										style={{width: '100%'}}
										element={Input}
										debounceTimeout={600}
										value={field.value}
										loading={field.loading}
										onChange={(e) => {
											props.validatePONumber(e.target.value);
										}}
									/>
									{field.error ? (
										<Label color="red" pointing="above">
											{field.error.toString()}
										</Label>
									) : null}
								</React.Fragment>
							) : field.input ? (
								<InputField field={field} sectionIndex={props.sectionIndex} fieldIndex={fieldIndex} />
							) : field.skipFieldMap ? null : (
								<DropdownField
									field={field}
									sectionIndex={props.sectionIndex}
									fieldIndex={fieldIndex}
									serviceTypeOptions={
										field.serviceTypeField
											? props.sectionFields[
													props.sectionFields.findIndex(
														(sections) => sections.name === 'Service Type Options'
													)
											  ]
											: null
									}
									serviceTypeIndex={
										field.serviceTypeField
											? props.sectionFields.findIndex(
													(sections) => sections.name === 'Service Type Options'
											  )
											: null
									}
								/>
							)}
						</Grid.Column>
					)
					// ) : null
				)}
			</Grid.Row>
		</Grid>
	</Segment>
);

const mapStateToProps = (state) => {
	return {
		sectionIndex: state.crm.createProject.createProjectModal.sections.findIndex(
			(section) => section.name === 'opportunitySection'
		),
		sectionFields: state.crm.createProject.createProjectModal.sections.filter(
			(section) => section.name === 'opportunitySection'
		)[0].fields
	};
};

export default connect(mapStateToProps, actions)(OpportunitySection);
