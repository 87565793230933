import React from 'react';
import PropTypes from 'prop-types';
import Pinned from './Pinned.js';
import {General, SortableGeneral} from './General.js';
import {Section, SortableSection} from './Section.js';
import optionFactory from './optionFactory.js';

/**
 * This component is used to generate tasks for the user to work with.
 * @memberof App.Components.Presentational.tasks#
 * @param {object} props The state object needs to include the global reducer.
 * @param {object} props.tasks The global object.
 * @param {function} props.updateTaskOrder
 * @param {function} props.toggleDiscardChangesModal
 * @typedef {Component}
 * @throws {Error} An error message.
 * @since 2.2.6
 */
export const TaskList = (props) => (
	<div>
		<Pinned {...props} category="pinned" key={`pinned-${props.group.id}`} />
		<SortableGeneral
			props={{...props}}
			useWindowAsScrollContainer
			key={`general-${props.group.id}`}
			onSortEnd={(event) => {
				if (event.oldIndex !== event.newIndex) {
					props.updateTaskOrder(
						event,
						props.mode,
						{
							...optionFactory(props)
						},
						props.group.tasks.general,
						'task'
					);
				}
			}}
			collection={props.group.id}
			shouldCancelStart={(e) => {
				// Cancel sorting if the event target is an `input`, `textarea`, `select` or `option`
				const disabledTagElements = ['input', 'textarea', 'select', 'option'];

				if (
					disabledTagElements.indexOf(e.target.tagName.toLowerCase()) !== -1 ||
					e.target.className.indexOf('pseudoDragHandler') !== -1
				) {
					return true; // Return true to cancel sorting
				}

				return props.togglePanel('close', props.mode, 'task', optionFactory(props));
			}}
			axis={'y'}
			lockAxis={'y'}
		/>
	</div>
);

export default TaskList;

TaskList.propTypes = {
	group: PropTypes.object.isRequired
};
