import customFetch from '../../../../../../../lib/old/customFetch.js';

export const getLeadsOpportunities = () => {
	return (dispatch) => {
		customFetch(`${process.env.REACT_APP_API_URL}/legacy/crm/pipeline?mode=dashboard&isLead=true`)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CRM_LEADS_UPDATE_OPPORTUNITIES',
					payload: json
				});

				dispatch({
					type: 'CRM_LEADS_UPDATE_OPPORTUNITIES_FETCHED',
					payload: true
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export const updateLeadsOpportunityColumn = (value, oppId, place, column) => {
	return (dispatch, getState) => {
		let state = getState();
		let updateIndex = dispatch(rowIndex(oppId, place));
		let oldValue = state.crm[place].data.opportunities[updateIndex][column];

		// Update date column labels before PUT otherwise there is delay
		if (column === 'projectedOrderDate' || column === 'bidDueDate' || column === 'description') {
			dispatch({
				type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_UPDATE_COLUMN',
				payload: {
					value,
					updateIndex,
					column
				}
			});
		}

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${oppId}`;

		customFetch(endpoint, {
			method: 'Put',
			body: JSON.stringify({
				[column]: value
			})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then((json) => {
				dispatch({
					type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_UPDATE_COLUMN',
					payload: {
						value,
						updateIndex,
						column
					}
				});
				// Update pastDue
				dispatch({
					type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_UPDATE_COLUMN',
					payload: {
						value: json.pastDue,
						updateIndex,
						column: 'pastDue'
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_UPDATE_COLUMN',
					payload: {
						value: oldValue,
						updateIndex,
						column
					}
				});
			});
	};
};

export const deactivateLeadsOpportunity = (id, place) => {
	return (dispatch) => {
		let deactivateIndex = dispatch(rowIndex(id, place));

		// dispatch loader
		dispatch({
			type: 'DEACTIVATE_OPPORTUNITY_MODAL_LOADING',
			payload: 'start'
		});
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}`;

		customFetch(endpoint, {
			method: 'Put',
			body: JSON.stringify({
				deactivate: true
			})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				dispatch({
					type: 'DEACTIVATE_OPPORTUNITY_MODAL_LOADING',
					payload: null
				});
				dispatch({
					type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_DEACTIVATE',
					payload: deactivateIndex
				});
				return dispatch({
					type: 'DEACTIVATE_OPPORTUNITY_MODAL_TOGGLE'
				});
			})
			.catch((err) => {
				dispatch({
					type: 'DEACTIVATE_OPPORTUNITY_MODAL_LOADING',
					payload: 'error'
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
};

export function sendToPipeline(id, place, acctExecId, projOrderDate) {
	return (dispatch) => {
		console.log(id, place, acctExecId, projOrderDate);

		let oppId = dispatch(rowIndex(id, place));

		// dispatch loader
		dispatch({
			type: 'SEND_TO_MODAL_LOADING',
			payload: 'start'
		});
		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}/move-opportunity`;

		customFetch(endpoint, {
			method: 'PUT',
			body: JSON.stringify({
				accountExecutiveId: acctExecId,
				projectedOrderDate: projOrderDate
			})
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}
				return response.json();
			})
			.then(() => {
				dispatch({
					type: 'SEND_TO_MODAL_LOADING',
					payload: null
				});
				dispatch({
					type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_DEACTIVATE',
					payload: oppId
				});
				dispatch({
					type: 'TOGGLE_LEAD_STATUS',
					payload: false
				});
				dispatch({
					type: 'SEND_TO_MODAL_TOGGLE'
				});
			})
			.catch((err) => {
				dispatch({
					type: 'SEND_TO_MODAL_LOADING',
					payload: 'error'
				});
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
			});
	};
}

export const submitLostJobLeads = (id, place, reason, comments) => {
	return (dispatch) => {
		let deactivateIndex = dispatch(rowIndex(id, place));

		if (reason) {
			// dispatch loader
			dispatch({
				type: 'LOSTJOB_OPPORTUNITY_MODAL_LOADING',
				payload: 'start'
			});
			let endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${id}`;

			customFetch(endpoint, {
				method: 'Put',
				body: JSON.stringify({
					stageName: 'Lost Job',
					lossReason: reason,
					lossComments: comments
				})
			})
				.then((response) => {
					if (response.status !== 200) {
						return Promise.reject(response);
					}
					return response.json();
				})
				.then(() => {
					dispatch({
						type: 'LOSTJOB_OPPORTUNITY_MODAL_LOADING',
						payload: null
					});
					dispatch({
						type: 'CRM_LEADS_UPDATE_LEADS_OPPORTUNITY_DEACTIVATE',
						payload: deactivateIndex
					});
					return dispatch({
						type: 'LOSTJOB_OPPORTUNITY_MODAL_TOGGLE'
					});
				})
				.catch((err) => {
					dispatch({
						type: 'LOSTJOB_OPPORTUNITY_MODAL_LOADING',
						payload: 'error'
					});
					dispatch({
						type: 'ERROR',
						payload: err.stack
					});
				});
		} else {
			dispatch({
				type: 'LOSTJOB_OPPORTUNITY_MODAL_UPDATE',
				payload: {
					column: 'reason',
					value: null,
					error: true
				}
			});
		}
	};
};

export function rowIndex(id, place) {
	return (dispatch, getState) => {
		const state = getState();

		const opportunities = state.crm[place].data.opportunities;

		let index = 0;
		if (opportunities) {
			index = state.crm[place].data.opportunities.findIndex((row) => {
				return row.id === id;
			});
		}
		return index;
	};
}
