import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Icon, Header, Segment, Grid, Dimmer, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import * as actions from '../../../../../services/workRecordActions.js';
import AddOrderGroupModalInputField from './components/addOrderGroupModalInputField.js';
import AddOrderGroupModalDropdownField from './components/addOrderGroupDropdownField.js';
import AddOrderGroupGrid from './components/addOrderGroupGrid/addOrderGroupGrid.js';
import SelectWOModal from './components/addOrderGroupGrid/components/selectWOModal.js';

class AddOrderGroupModal extends React.Component {
	componentDidMount() {
		this.props.addOrderGroupModalUpdateColumn(2, this.props.quotedJobNumber, false);
		if (this.props.type !== 'create') {
			this.props.saveOldGroup();
		}
	}

	componentDidUpdate() {
		if (!this.props.quotedJobNumber) {
			this.props.addOrderGroupModalUpdateColumn(2, this.props.quotedJobNumber, false);
		}
	}

	render() {
		return (
			<Modal open={this.props.addOrderGroupModalToggle} size="large">
				<Header>
					<Icon
						name={this.props.type === 'create' ? 'plus' : 'edit'}
						color={this.props.type === 'create' ? 'green' : 'blue'}
					/>
					<div className="content">
						{this.props.type === 'create' ? 'Add Order Group' : 'Edit Order Group'}
					</div>
				</Header>
				<Modal.Content scrolling={true}>
					{this.props.addOrderGroupModalLoading ? (
						<Dimmer inverted active className="centerDimmer">
							{this.props.addOrderGroupModalLoading === 'loading' ? (
								<Loader size="large">Loading WOs</Loader>
							) : this.props.addOrderGroupModalLoading === 'adding' ? (
								<Loader size="large">{'Adding Order Group'}</Loader>
							) : this.props.addOrderGroupModalLoading === 'saving' ? (
								<Loader size="large">{'Saving Order Group'}</Loader>
							) : this.props.addOrderGroupModalLoading === 'no WO' ? (
								<Header as="h4" icon>
									<Icon name="cancel" color="red" />
									<div style={{paddingBottom: '.5em', color: 'black'}}>
										No work orders are ready to be invoiced for this project.
									</div>
									<Button
										onClick={() => {
											this.props.closeDimmer('addOrderGroupModal');
											this.props.toggleAddOrderGroupModal(null, null);
										}}
										style={{marginTop: '1.5em'}}
									>
										Close Message
									</Button>
								</Header>
							) : (
								// Error handling
								<Header as="h4" icon>
									<Icon name="cancel" color="red" />
									<div style={{paddingBottom: '.5em', color: 'black'}}>
										Order Group add or save failed.
									</div>
									<div style={{color: 'black'}}>Please try again.</div>
									<Button
										onClick={() => {
											this.props.closeDimmer('addOrderGroupModal');
										}}
										style={{marginTop: '1.5em'}}
									>
										Close Message
									</Button>
								</Header>
							)}
						</Dimmer>
					) : null}
					<Segment basic>
						<Grid columns="equal">
							{this.props.modalFields.map((field, index) => (
								<Grid.Column key={index}>
									{field.input ? (
										<AddOrderGroupModalInputField
											field={field}
											fieldIndex={index}
											quotedJobNumber={field.quotedJobNumber ? this.props.quotedJobNumber : null}
										/>
									) : (
										<AddOrderGroupModalDropdownField
											field={field}
											fieldIndex={index}
											type={this.props.type}
										/>
									)}
								</Grid.Column>
							))}
						</Grid>
						<AddOrderGroupGrid />
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button.Group>
						<Button
							color="red"
							onClick={() => {
								this.props.toggleAddOrderGroupModal(null, null);
							}}
						>
							<Icon name="remove" />
							Cancel
						</Button>
						<Button
							color="green"
							onClick={() => {
								// Check if add order group is created or not
								// Add new order group
								if (this.props.type === 'create') {
									this.props.orderGroupAddSave(
										this.props.modalFields,
										this.props.workId,
										this.props.workOrders,
										this.props.type,
										null
									);
								}
								// Save order group
								else {
									this.props.orderGroupAddSave(
										this.props.modalFields,
										this.props.workId,
										this.props.workOrders,
										this.props.type,
										this.props.orderGroupIndex
									);
								}
							}}
						>
							<Icon name="checkmark" />
							{this.props.type === 'create' ? 'Add Order Group' : 'Save Order Group'}
						</Button>
					</Button.Group>
				</Modal.Actions>
				<SelectWOModal />
			</Modal>
		);
	}
}

AddOrderGroupModal.propTypes = {
	addOrderGroupModalLoading: PropTypes.bool,
	addOrderGroupModalToggle: PropTypes.bool,
	addOrderGroupModalUpdateColumn: PropTypes.func,
	addOrderGroupModalValidatePONumber: PropTypes.func,
	closeDimmer: PropTypes.func,
	modalFields: PropTypes.array,
	orderGroupAddSave: PropTypes.func,
	orderGroupIndex: PropTypes.number,
	quotedJobNumber: PropTypes.string,
	saveOldGroup: PropTypes.func,
	toggleAddOrderGroupModal: PropTypes.func,
	type: PropTypes.string,
	workId: PropTypes.number,
	workOrders: PropTypes.array
};

const mapStateToProps = (state) => {
	return {
		addOrderGroupModalToggle: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.toggle,
		modalFields: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.fields,
		addOrderGroupModalLoading: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.loading,
		quotedJobNumber: state.work.workRecord.view.record.quotedJobNumber,
		workOrders: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.rowData,
		workId: state.work.workRecord.view.record.id,
		type: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.type,
		orderGroupIndex: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal.orderGroupIndex,
		//used to see if any values have changed
		oldGroup: state.work.workRecord.view.BillingCenter.orderGroup.addOrderGroupModal
	};
};

export default connect(mapStateToProps, actions)(AddOrderGroupModal);
