import _ from 'lodash';
import {v4 as uuid} from 'uuid';
import customFetch from '~/lib/old/customFetch.js';
import {validatePayload} from '~/lib/old/validation.js';
import {toggleFetchingOnTabsNavbar} from '~/components/Global/services/globalActions.js';
import {calcMargin} from '~/util/moneymath.ts';
import api from '~/lib/api.ts';
import {roundFloat} from '~/util/numbers';
import {pagingGet} from '~/services/pagingGet';

export const getTabRequestItems = (clientId, item) => {
	switch (item) {
		case 'Work':
			return getClientWorkTabGrid(clientId, item);
		case 'Opportunities':
			return getClientOpportunitiesTabGrid(clientId, item);
		case 'Documents':
			return getClientDocumentsTabGrid(clientId, item);
		case 'Pricing':
			return getClientPricingTabGrid(clientId, item);
		case 'Locations':
			return getClientLocationTabGrid(clientId, item);
		case 'Overview':
			return getClientContactsGrid(clientId, item);
		default:
			throw new Error('Invalid item for profile grid tab request');
	}
};

export const setClientProfileName = (name) => (dispatch) => {
	dispatch({
		type: 'WORK_CLIENT_PROFILE_NAME',
		payload: {data: name, item: 'Overview'}
	});
};

export const getClientContactsGrid = (id, item) => async (dispatch) => {
	try {
		const data = await api(`legacy/contacts?clientId=${id}&fullList=1`);

		dispatch({
			type: 'UPDATE_ROWDATA_GRID',
			payload: {
				data: validatePayload(data),
				item
			}
		});
		dispatch({
			type: 'UPDATE_CLIENT_CONTACT_LIST',
			payload: {
				data: validatePayload(data)
			}
		});
	} catch (err) {
		dispatch({
			type: 'UPDATE_ROWDATA_GRID',
			payload: {data: null, item}
		});

		console.log(err);
		alert(err);
	}
};

export const getClientWorkTabGrid = (clientId, item) => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?clientId=${clientId}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'UPDATE_ROWDATA_GRID',
			payload: {data: validatePayload(response), item}
		});
	} catch (err) {
		dispatch({
			type: 'UPDATE_ROWDATA_GRID',
			payload: {data: [], item}
		});

		console.log(err);
		alert(err);
	}
};

const getClientDocumentsTabGrid =
	(clientId, item = 'Documents') =>
	async (dispatch) => {
		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/documents?clientId=${clientId}`;

		try {
			const call = await customFetch(endpoint);
			const response = await call.json();

			response.forEach((doc) => {
				doc.documentId = doc.id;
				delete doc.id;
			});

			if (call.status !== 200) {
				throw response;
			}

			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: validatePayload(response), item}
			});
		} catch (err) {
			dispatch({
				type: 'UPDATE_ROWDATA_GRID',
				payload: {data: null, item}
			});

			console.log(err);
			alert(err);
		}
	};

export const getClientPricingTabGrid = (clientId, item) => async (dispatch) => {
	dispatch(toggleFetchingOnTabsNavbar(true));
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/sku?clientId=${clientId}`;
	const fetchData = await customFetch(endpoint);
	const jsonData = await fetchData.json();
	dispatch(toggleFetchingOnTabsNavbar(false));

	dispatch({
		type: 'UPDATE_ROWDATA_GRID',
		payload: {data: jsonData, item}
	});
};

export const toggleGlobalSKUsModal = (open) => {
	if (open) {
		return {
			type: 'CLIENT_PRICING_OPEN_GLOBAL_SKUS'
		};
	} else {
		return {
			type: 'CLIENT_PRICING_CLOSE_GLOBAL_SKUS'
		};
	}
};

export const getSKUs =
	(clientId = 0) =>
	async (dispatch) => {
		// dispatch loading
		dispatch({
			type: 'CREATE_CLIENT_SKU_GET_SKU_LOADED',
			payload: {skuLoaded: false}
		});

		const callEndPoint = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/sku?includeGlobal=true?clientId=${clientId}&isActive=true`
		);
		const response = await callEndPoint.json();

		dispatch({
			type: 'CREATE_CLIENT_SKU_GET_SKUS',
			payload: {
				skuArray: response,
				skuLoaded: true
			}
		});
	};

export const loadGlobalSKUs = () => async (dispatch) => {
	// dispatch loading
	dispatch({
		type: 'CLIENT_PRICING_GLOBAL_SKUS_LOADING'
	});

	const response = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/sku?includeGlobal=true`);
	//TODO: ERROR HANDLING
	if (response.status !== 200) {
		console.error('could not get global skus');
	}
	const data = await response.json();

	dispatch({
		type: 'CLIENT_PRICING_GLOBAL_SKUS_LOADED',
		payload: data
	});
};

export const updateProjectCostingModalGrid = (rowIndex, field, value) => {
	return (dispatch) => {
		if (field === 'SKU') {
			let object;
			if (value.name === 'None') {
				object = {
					SKU: value
				};
			} else {
				object = {
					SKU: value,
					manufacturer: value.manufacturer ? value.manufacturer : null,
					partNumber: value.partNumber ? value.partNumber : null,
					unit: value.unit ? value.unit : null,
					quantity: value.quantity ? value.quantity : null,
					markupMaterial: value.markupMaterial ? value.markupMaterial : null,
					materialResell: value.materialResell ? value.materialResell : null,
					unitMaterialCost: value.unitMaterialCost ? value.unitMaterialCost : null,
					unitLaborCost: value.unitLaborCost ? value.unitLaborCost : null,
					markupLabor: value.markupLabor ? value.markupLabor : null,
					laborResell: value.laborResell ? value.laborResell : null,
					totalResell: value.totalResell ? value.totalResell : null,
					id: value.id ? value.id : null,
					description: value.description ? value.description : null,
					clientId: value.clientId ? value.clientId : null,
					createdBy: value.createdBy ? value.createdBy : null,
					type: value.type ? value.type : null
				};
			}
			dispatch({
				type: 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_GRID_UPDATE_SKU',
				payload: {
					rowIndex,
					object
				}
			});
		} else {
			dispatch({
				type: 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_GRID_UPDATE',
				payload: {
					rowIndex,
					field,
					value
				}
			});
		}
	};
};

export const getRowNodeData = (rowIndex) => {
	return (dispatch, getState) => {
		const rowData =
			getState().work.clientProfileGrid.createSKUVariables.projectCostingSpreadsheetModal.spreadSheetData[
				rowIndex
			];
		return rowData;
	};
};

export const addRowSpreadsheet = () => {
	return (dispatch, getState) => {
		const spreadSheetData =
			getState().work.clientProfileGrid.createSKUVariables.projectCostingSpreadsheetModal.spreadSheetData;
		let nextId;
		if (spreadSheetData.length > 0) {
			nextId =
				spreadSheetData[
					getState().work.clientProfileGrid.createSKUVariables.projectCostingSpreadsheetModal.spreadSheetData
						.length - 1
				].id + 1;
		} else {
			nextId = 1;
		}
		dispatch({
			type: 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_ADD_ROW',
			payload: {
				object: {
					id: nextId
				}
			}
		});
	};
};

export const selectSKUModalToggle = (SKUList) => {
	return (dispatch, getState) => {
		const toggle =
			!getState().work.clientProfileGrid.createSKUVariables.projectCostingSpreadsheetModal.selectSKUModal.toggle;
		// Removes None value in SKUList
		let newSKULIST = [];
		if (SKUList) {
			newSKULIST = SKUList.filter((SKU) => SKU.name !== 'None');
		}
		dispatch({
			type: 'CREATE_CLIENT_SKU_MODAL_SELECT_SKU_MODAL_TOGGLE',
			payload: {
				toggle,
				SKUList: newSKULIST
			}
		});
	};
};

export const getClientOpportunitiesTabGrid = (clientId, item) => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline?clientId=${clientId}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'UPDATE_ROWDATA_GRID',
			payload: {
				data: validatePayload(response),
				item
			}
		});
	} catch (err) {
		dispatch({
			type: 'UPDATE_ROWDATA_GRID',
			payload: {data: null, item}
		});

		console.log(err);
		alert(err);
	}
};

export const applyMarkup = (column, value) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_GRID_UPDATE_TOTAL',
			payload: {
				field: column,
				value
			}
		});
	};
};

export const toggleProjectCostingSpreadsheetModal = (spreadSheetData, value, type) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_TOGGLE',
			payload: {
				spreadSheetData,
				value,
				type
			}
		});
	};
};

export const cancelProjectCostingSpreadsheet = () => (dispatch, getState) => {
	const useSavedRowData = getState().work.clientProfileGrid.createSKUVariables.rowData;
	console.log(useSavedRowData);
	dispatch(toggleProjectCostingSpreadsheetModal(useSavedRowData, false));
};

export const removeRowSpreadsheet = (rowIndex) => {
	return (dispatch) => {
		dispatch({
			type: 'CREATE_CLIENT_SKU_SPREADSHEET_MODAL_REMOVE_ROW',
			payload: {
				rowIndex
			}
		});
	};
};
export const clearGrid = (item) => (dispatch) => {
	dispatch({
		type: 'UPDATE_ROWDATA_GRID',
		payload: {data: null, item}
	});
};

export const addSKU = (clientId, skuData) => {
	return async (dispatch, getState) => {
		dispatch({
			type: 'CLIENT_SKUS_LOADING',
			payload: true
		});

		const sku = {
			id: uuid(),
			description: '',
			manufacturer: '',
			partNumber: '',
			quantity: 1,
			unit: 'EA',
			unitMaterialCost: 0,
			markupMaterial: 0,
			materialResell: 0,
			unitLaborCost: 0,
			markupLabor: 0,
			laborResell: 0,
			isActive: true,
			isFixedRevenue: true,
			...skuData //this will overwrite any above props
		};

		const response = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/sku`, {
			method: 'POST',
			//NOTE: these will need to change if the view changes.... would cause problems. Dont have a clean way around it right now without major refactoring touching multiple pieces
			body: JSON.stringify({
				clientId,
				skus: [_.omit(sku, ['id', 'createdOn', 'createdByName', 'ClientName'])]
			})
		});
		if (response.status !== 200) {
			//TODO: we dont really have a singular way to report errors. just doing this for now
			console.error(response);
		} else {
			const data = await response.json();
			console.log(data);
			sku.clientId = clientId;
			sku.id = data[data.length - 1].id; //backend does not return data properly... dont have time to fix rn
			dispatch({
				type: 'CLIENT_ADD_SKUS',
				payload: [sku]
			});
		}

		dispatch({
			type: 'CLIENT_SKUS_LOADING',
			payload: false
		});
	};
};

export const addSKUs = (clientId, skus) => {
	return async (dispatch, getState) => {
		dispatch({
			type: 'CLIENT_SKUS_LOADING',
			payload: true
		});
		skus = skus.map((sku) => _.omit(sku, ['id', 'createdOn', 'createdByName', 'ClientName']));

		const response = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/sku`, {
			method: 'POST',
			//NOTE: these will need to change if the view changes.... would cause problems. Dont have a clean way around it right now without major refactoring touching multiple pieces
			body: JSON.stringify({
				clientId,
				skus
			})
		});
		if (response.status !== 200) {
			//TODO: we dont really have a singular way to report errors. just doing this for now
			console.error(response);
		} else {
			const data = await response.json();
			dispatch({
				type: 'CLIENT_ADD_SKUS',
				payload: data
			});
		}

		dispatch({
			type: 'CLIENT_SKUS_LOADING',
			payload: false
		});
	};
};

export const updateSKU = (index, skuChanges) => async (dispatch, getState) => {
	dispatch({
		type: 'CLIENT_SKUS_LOADING',
		payload: true
	});
	let sku = _.cloneDeep(getState().work.clientProfileGrid.rowData[index]); //make sure we dont modify state
	const skuid = sku.id;
	sku = {...sku, ...skuChanges};

	//calculate margin
	sku.markupLabor = roundFloat(calcMargin(sku.unitLaborCost * sku.quantity, sku.laborResell) * 100, 2);
	sku.markupMaterial = roundFloat(
		calcMargin(sku.unitMaterialCost * sku.quantity, sku.materialResell) * 100, //prettier 😠
		2
	);

	if (sku.markupLabor === -Infinity) {
		sku.markupLabor = null;
	}
	if (sku.markupMaterial === -Infinity) {
		sku.markupMaterial = null;
	}

	const response = await customFetch(`${process.env.REACT_APP_API_URL}/legacy/sku/${skuid}`, {
		method: 'PUT',
		//NOTE: these will need to change if the view changes.... would cause problems. Dont have a clean way around it right now without major refactoring touching multiple pieces
		body: JSON.stringify(_.omit(sku, ['id', 'createdOn', 'createdByName', 'ClientName']))
	});

	if (response.status !== 200) {
		//TODO: we dont really have a singular way to report errors. just doing this for now
		console.error(response);
	} else {
		dispatch({
			type: 'CLIENT_SKU_SET',
			payload: {index, value: sku}
		});
	}

	dispatch({
		type: 'CLIENT_SKUS_LOADING',
		payload: false
	});
};

const appendLocationRows = (newRows) => {
	return (dispatch, getState) => {
		const oldRows = getState().work.clientProfileGrid.rowData || [];
		const rows = oldRows.concat(newRows);
		dispatch({
			type: 'UPDATE_ROWDATA_GRID',
			payload: {data: rows, item: 'Locations'}
		});
		return true;
	};
};

export const getClientLocationTabGrid = (clientId) => {
	return async (dispatch) => {
		// start Loader
		dispatch({
			type: 'DEFAULT_LOADING',
			payload: true
		});

		try {
			const endpoint = `/legacy/locations?clientId=${clientId}`;
			await dispatch(pagingGet(endpoint, 'first', 10000, appendLocationRows, {}));

			// Hide Loader
			dispatch({
				type: 'DEFAULT_LOADING',
				payload: false
			});
		} catch (err) {
			dispatch({
				type: 'DEFAULT_LOADING',
				payload: false
			});

			console.log(err);
			alert('Something went wrong');
		}
	};
};

export const removeClientLocation = (index, location) => async (dispatch) => {
	// Loader
	dispatch({
		type: 'DEFAULT_LOADING',
		payload: true
	});

	location.deactivate = 1;

	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations/${location.id}`;
	const deactivateLocation = await customFetch(endpoint, {
		method: 'PUT',
		body: JSON.stringify(location)
	});

	// API Call Worked, Remove Location on Frontend
	if (deactivateLocation.status === 200) {
		dispatch({
			type: 'CLIENT_VIEW_REMOVE_LOCATION',
			payload: {
				index
			}
		});
	}

	// Hide Loader
	dispatch({
		type: 'DEFAULT_LOADING',
		payload: false
	});
};

export const selectSKUModalAddItems = (selectedRows) => {
	return (dispatch, getState) => {
		selectedRows.forEach((row) => {
			const spreadSheetDataEmptyIndex =
				getState().work.clientProfileGrid.createSKUVariables.projectCostingSpreadsheetModal.spreadSheetData.findIndex(
					(item) => Object.keys(item).length === 0
				);
			if (spreadSheetDataEmptyIndex > -1) {
				dispatch({
					type: 'CLIENT_SKU_SELECT_SKU_MODAL_ADD_ITEMS',
					payload: {
						spreadSheetDataEmptyIndex,
						object: {
							SKU: row,
							manufacturer: row.manufacturer ? row.manufacturer : null,
							partNumber: row.partNumber ? row.partNumber : null,
							unit: row.unit ? row.unit : null,
							quantity: row.quantity ? row.quantity : null,
							unitMaterialCost: row.unitMaterialCost ? row.unitMaterialCost : null,
							markupMaterial: row.markupMaterial ? row.markupMaterial : null,
							materialResell: row.materialResell ? row.materialResell : null,
							unitLaborCost: row.unitLaborCost ? row.unitLaborCost : null,
							markupLabor: row.markupLabor ? row.markupLabor : null,
							laborResell: row.laborResell ? row.laborResell : null,
							totalResell: row.totalResell ? row.totalResell : null,
							id: row.id ? row.id : null,
							description: row.description ? row.description : null,
							clientId: row.clientId ? row.clientId : null,
							createdBy: row.createdBy ? row.createdBy : null,
							type: row.type ? row.type : null
						}
					}
				});
			} else {
				const nextIndex =
					getState().work.clientProfileGrid.createSKUVariables.projectCostingSpreadsheetModal.spreadSheetData
						.length;
				dispatch({
					type: 'CLIENT_SKU_SELECT_SKU_MODAL_ADD_ITEMS',
					payload: {
						spreadSheetDataEmptyIndex: nextIndex,
						object: {
							SKU: row,
							manufacturer: row.manufacturer ? row.manufacturer : null,
							partNumber: row.partNumber ? row.partNumber : null,
							unit: row.unit ? row.unit : null,
							quantity: row.quantity ? row.quantity : null,
							unitMaterialCost: row.unitMaterialCost ? row.unitMaterialCost : null,
							markupMaterial: row.markupMaterial ? row.markupMaterial : null,
							materialResell: row.materialResell ? row.materialResell : null,
							unitLaborCost: row.unitLaborCost ? row.unitLaborCost : null,
							markupLabor: row.markupLabor ? row.markupLabor : null,
							laborResell: row.laborResell ? row.laborResell : null,
							totalResell: row.totalResell ? row.totalResell : null,
							id: row.id ? row.id : null,
							description: row.description ? row.description : null,
							clientId: row.clientId ? row.clientId : null,
							createdBy: row.createdBy ? row.createdBy : null,
							type: row.type ? row.type : null
						}
					}
				});
			}
		});
	};
};

export const createLocation = (data) => {
	return async (dispatch) => {
		// start Loader
		dispatch({
			type: 'DEFAULT_LOADING',
			payload: true
		});
		const locationBody = {
			clientId: data.clientId,
			aasdiId: data.aasdiId,
			name: data.locationName,
			storeNumber: data.storeNumber,
			address: data.address,
			address2: data.address2,
			city: data.city,
			countryId: data.countryId,
			state: data.state,
			zip: data.zip,
			siteContactId: data.siteContactId,
			billingContactId: data.billingContactId
		};

		const endpoint = `${process.env.REACT_APP_API_URL}/legacy/locations`;

		try {
			const call = await customFetch(endpoint, {
				method: 'POST',
				body: JSON.stringify(locationBody)
			});
			const response = await call.json();

			if (call.status !== 200) {
				throw response;
			}

			dispatch({
				type: 'ADD_LOCATION_ROWDATA_GRID',
				payload: {data: validatePayload(response)}
			});
		} catch (err) {
			console.log(err);
			alert('Something went wrong');
		}

		// Hide Loader
		dispatch({
			type: 'DEFAULT_LOADING',
			payload: false
		});
	};
};

export const updateLocation = (data, rowIndex) => (dispatch) => {
	// start Loader
	dispatch({
		type: 'DEFAULT_LOADING',
		payload: true
	});

	dispatch({
		type: 'UPDATE_LOCATION',
		payload: {data, index: rowIndex}
	});

	// Hide Loader
	dispatch({
		type: 'DEFAULT_LOADING',
		payload: false
	});
};

export const getLocationWorks = (locationId) => async (dispatch) => {
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/work?locationId=${locationId}`;

	try {
		const call = await customFetch(endpoint);
		const response = await call.json();

		if (call.status !== 200) {
			throw response;
		}

		dispatch({
			type: 'SET_CLIENT_LOCATION_WORK',
			payload: {data: validatePayload(response)}
		});
	} catch (err) {
		dispatch({
			type: 'CLEAR_CLIENT_LOCATION_WORK',
			payload: {data: null}
		});

		console.log(err);
		alert('Something went wrong');
	}
};

export const clearLocationWorks = () => {
	return async (dispatch) => {
		dispatch({
			type: 'CLEAR_CLIENT_LOCATION_WORK',
			payload: {data: null}
		});
	};
};

export const toggleConfirmDeactivateLocationModal = (status) => {
	return (dispatch) => {
		dispatch({
			type: 'CLIENT_TOGGLE_DEACTIVE_LOCATION_MODAL',
			payload: status
		});
	};
};

export const clearLocationToDeactivate = () => {
	return (dispatch) => {
		dispatch({
			type: 'CLIENT_CLEAR_LOCATION_TO_DEACTIVATE',
			payload: null
		});
	};
};

export const setLocationToDeactivate = (rowIndex, data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLIENT_SET_LOCATION_TO_DEACTIVATE',
			payload: {rowIndex, data}
		});
	};
};

export const contactAdded = (data) => {
	return (dispatch) => {
		dispatch({
			type: 'CLIENT_VIEW_ADDED_CONTACT',
			payload: validatePayload(data)
		});
	};
};
