export const selectTaskTemplatesState = (state) => state.work.taskTemplates;

export const selectTemplates = (state) => selectTaskTemplatesState(state).templates;
export const selectTemplateById = (state, templateId) =>
	selectTemplates(state).find((template) => {
		return template.id === Number(templateId);
	});

export const selectTemplatesLoading = (state) => selectTaskTemplatesState(state).templatesLoading;
export const selectTemplatesBeingRetrieved = (state) => selectTaskTemplatesState(state).templatesBeingRetrieved;
export const selectErrorLoadingTemplates = (state) => selectTaskTemplatesState(state).errorLoadingTemplates;

export const selectTemplateTasks = (state) => selectTaskTemplatesState(state).selectedTemplateTasks;
export const selectTemplateTasksLoading = (state) => selectTaskTemplatesState(state).templateTasksLoading;
export const selectErrorLoadingTemplateTasks = (state) => selectTaskTemplatesState(state).errorLoadingTemplateTasks;

export const selectSidePanelMode = (state) => selectTaskTemplatesState(state).sidePanelMode;
export const selectSidePanelTask = (state) => selectTaskTemplatesState(state).sidePanelTask;

export const selectTaskList = (state) => selectTaskTemplatesState(state).taskList;

export const selectInputMaskList = (state) => selectTaskTemplatesState(state).inputMaskList;

export const selectClientListSearchResults = (state) => selectTaskTemplatesState(state).clientListSearchResults;
export const selectClientListSearchResultsLoading = (state) =>
	selectTaskTemplatesState(state).clientListSearchResultsLoading;
export const selectErrorLoadingClientListSearchResults = (state) =>
	selectTaskTemplatesState(state).errorLoadingClientListSearchResults;

export const selectTemplatesForDropdown = (state) =>
	selectTemplates(state).map((template) => ({
		text: template.name,
		text: template.name,
		description: template.description,
		value: template.id,
		key: template.id
	}));

export const selectSnackbar = (state) => selectTaskTemplatesState(state).snackbar;
