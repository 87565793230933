import update from 'react-addons-update';
import ButtonLink from '../components/ButtonLink.js';
import {fmtMoney} from "../../../util/formatting.js";

/**
 * The Leads state object
 * @namespace commits
 * @memberof App.Store.Reducers
 * @since 3.0.0
 */
export default (
	state = {
		salesRepresentative: null,
		loading: true,
		bookings: {
			loading: false
		},
		bookingTotals: [
			{
				accountExecutiveName: 'Total',
				commitBooking: [
					{
						projected: 0,
						booked: 0,
						totalPipeline: 0,
						highProbability: 0
					}
				],
				commitBilling: [
					{
						projected: 0,
						billed: 0
					}
				]
			}
		],
		billings: {
			loading: false
		}
	},
	action
) => {
	switch (action.type) {
		case 'CRM_COMMITS_GET_SALES_REPRESENTATIVE':
			state = update(state, {
				salesRepresentative: {
					$set: action.payload
				}
			});
			break;
		case 'CRM_COMMITS_LOADING':
			state = update(state, {
				loading: {
					$set: action.payload
				}
			});
			break;
		case 'CRM_COMMITS_UPDATE_GRID':
			state = update(state, {
				salesRepresentative: {
					$splice: [[action.payload.index, 1, action.payload.value]]
				}
			});
			break;

		case 'CRM_COMMITS_SET_TOTALS':
			state = update(state, {
				bookingTotals: {
					$set: [
						{
							accountExecutiveName: 'Total',
							commitBooking: [
								{
									projected: action.payload.totalsBooking.totalProjected,
									booked: action.payload.totalsBooking.totalBooked,
									totalPipeline: action.payload.totalsBooking.totalTotalPipeline,
									highProbability: action.payload.totalsBooking.totalHighProbability
								}
							],
							commitBilling: [
								{
									projected: action.payload.totalsBilling.totalProjected,
									billed: action.payload.totalsBilling.totalBilled
								}
							]
						}
					]
				}
			});
			break;
		case 'CRM_COMMITS_UPDATE_COLUMN':
			state = update(state, {
				salesRepresentative: {
					[state.salesRepresentative.findIndex((elm) => elm.id === action.payload.salesRepId)]: {
						[action.payload.commitType]: {
							[action.payload.rowIndex]: {
								[action.payload.column]: {
									$set: action.payload.value
								}
							}
						}
					}
				}
			});
			break;
		default:
	}

	return state;
};

function numberParser(params) {
	console.log('params: ', params);
	return Number(params.newValue).toFixed(2);
}

function currencyFormatter(params) {
	return fmtMoney(params.value);
}

function pinnedCellRenderer(params) {
	if (!isNaN(Number(params.value))) params.value = `$ ${params.value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	return '<b>' + params.value + '</b>';
}

export const bookingColumnMasterDefs = [
	{
		headerName: 'Sales Rep',
		filter: 'agTextColumnFilter',
		field: 'accountExecutiveName',
		cellRenderer: 'agGroupCellRenderer',
		pinnedRowCellRenderer: pinnedCellRenderer
	},
	{
		headerName: 'Projected',
		filter: 'agNumberColumnFilter',
		field: 'projected',
		valueGetter: (params) => {
			let sum = 0;
			if (params.data.commitBooking) {
				params.data.commitBooking.forEach((item) => {
					sum += parseFloat(item.projected);
				});
			}
			return Number(sum).toFixed(2);
		},
		valueFormatter: currencyFormatter,
		editable: false,
		pinnedRowCellRenderer: pinnedCellRenderer
	},
	{
		headerName: 'Booked (Actual)',
		filter: 'agNumberColumnFilter',
		field: 'booked',
		valueGetter: (params) => {
			let sum = 0;
			if (params.data.commitBooking) {
				params.data.commitBooking.forEach((item) => {
					sum += parseFloat(item.booked);
				});
			}
			return Number(sum).toFixed(2);
		},
		valueFormatter: currencyFormatter,
		editable: false,
		pinnedRowCellRenderer: pinnedCellRenderer
	},
	{
		headerName: 'Total Pipeline',
		filter: 'agNumberColumnFilter',
		field: 'totalPipeline',
		valueGetter: (params) => {
			let sum = 0;
			if (params.data.commitBooking) {
				params.data.commitBooking.forEach((item) => {
					sum += parseFloat(item.totalPipeline);
				});
			}
			return Number(sum).toFixed(2);
		},
		valueFormatter: currencyFormatter,
		editable: false,
		hide: true,
		pinnedRowCellRenderer: pinnedCellRenderer
	},
	{
		headerName: 'High Probability / Next 90 Days',
		filter: 'agNumberColumnFilter',
		field: 'high',
		valueGetter: (params) => {
			let sum = 0;
			if (params.data.commitBooking) {
				params.data.commitBooking.forEach((item) => {
					sum += parseFloat(item.highProbability);
				});
			}
			return Number(sum).toFixed(2);
		},
		valueFormatter: currencyFormatter,
		editable: false,
		hide: true,
		pinnedRowCellRenderer: pinnedCellRenderer
	}
];

export const bookingColumnDetailDefs = [
	{
		headerName: 'Action',
		field: 'action',
		cellStyle: {},
		cellRendererFramework: ButtonLink('CommitBooking'),
		width: 80,
		filter: false
	},
	{
		headerName: 'Description',
		filter: 'agTextColumnFilter',
		field: 'name',
		editable: false,
		valueGetter: (params) =>
			params.data.commitType === 'P'
				? params.data.name
				: params.data.commitType === 'W'
				? 'Work Orders'
				: 'Change Orders'
	},
	{
		headerName: 'Projected',
		filter: 'agNumberColumnFilter',
		field: 'projected',
		valueParser: numberParser,
		valueFormatter: currencyFormatter,
		editable: (params) => !params.data.name
	},
	{
		headerName: 'Booked (Actual)',
		filter: 'agNumberColumnFilter',
		field: 'booked',
		valueParser: numberParser,
		valueFormatter: currencyFormatter,
		editable: false
	}
];

export const billingColumnMasterDefs = [
	{
		headerName: 'Sales Rep',
		filter: 'agTextColumnFilter',
		field: 'accountExecutiveName',
		cellRenderer: 'agGroupCellRenderer'
	},
	{
		headerName: 'Projected',
		filter: 'agNumberColumnFilter',
		field: 'billProjectedMaster',
		valueGetter: (params) => {
			let sum = 0;
			if (params.data.commitBilling) {
				params.data.commitBilling.forEach((item) => {
					sum += parseFloat(item.projected);
				});
			}
			return Number(sum).toFixed(2);
		},
		valueFormatter: currencyFormatter,
		editable: false,
		pinnedRowCellRenderer: pinnedCellRenderer
	},
	{
		headerName: 'Billed (Actual)',
		filter: 'agNumberColumnFilter',
		field: 'billedMaster',
		valueGetter: (params) => {
			let sum = 0;
			if (params.data.commitBilling) {
				params.data.commitBilling.forEach((item) => {
					sum += parseFloat(item.billed);
				});
			}
			return Number(sum).toFixed(2);
		},
		valueFormatter: currencyFormatter,
		editable: false,
		pinnedRowCellRenderer: pinnedCellRenderer
	},
	{
		headerName: 'Commit Remaining',
		filter: 'agNumberColumnFilter',
		field: 'commitRemaining',
		valueGetter: (params) => {
			const value = params.getValue('billProjectedMaster') - params.getValue('billedMaster');
			return Number(value).toFixed(2);
		},
		valueFormatter: currencyFormatter,
		editable: false,
		pinnedRowCellRenderer: pinnedCellRenderer
	}
];

export const billingColumnDetailDefs = [
	{
		headerName: 'Action',
		field: 'action',
		cellStyle: {},
		cellRendererFramework: ButtonLink('CommitBilling'),
		width: 80,
		filter: false
	},
	{
		headerName: 'Description',
		filter: 'agTextColumnFilter',
		field: 'description',
		editable: false
	},
	{
		headerName: 'Projected',
		filter: 'agNumberColumnFilter',
		field: 'projected',
		valueParser: numberParser,
		valueFormatter: currencyFormatter,
		editable: true
	},
	{
		headerName: 'Billed (Actual)',
		filter: 'agNumberColumnFilter',
		field: 'billed',
		valueParser: numberParser,
		valueFormatter: currencyFormatter,
		editable: false
	},
	{
		headerName: 'Commit Remaining',
		filter: 'agNumberColumnFilter',
		field: 'commitRemaining',
		valueGetter: (params) => {
			const value = params.getValue('projected') - params.getValue('billed');
			return value.toFixed(2);
		},
		valueFormatter: currencyFormatter,
		editable: false
	}
];
