// Modules
import React from 'react';
import {Grid, Divider} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Components
import ActivityStream from '../../../../../../../../components/activityStream/ActivityStream.js';
import ContactsSection from '../../../../../../../../components/contactsSection/contactsSection.js';
import Technologies from './components/technologies/Technologies.js';
import Documents from './components/documents/index.js';
import Tags from '../../../../../../../../components/tags/tags.tsx';

let OppContent = ({id, isCommit}) => (
	<Grid key="content">
		<Grid.Column width={'10'}>
			<ActivityStream
				type="opportunity"
				id={id}
				options={[
					{
						key: 'note',
						text: 'Note',
						value: 'Note'
					},
					{
						key: 'phoneCall',
						text: 'Phone Call',
						value: 'Phone Call'
					},
					{
						key: 'quote',
						text: 'Quote',
						value: 'Quote'
					},
					{
						key: 'voicemail',
						text: 'Voicemail',
						value: 'Voicemail'
					}
				]}
			/>
			<Documents />
			<Tags opportunityId={parseInt(id)} />
			<br />
		</Grid.Column>
		<Grid.Column width={'6'}>
			<Grid.Row>
				<Technologies id={id} disabled={isCommit} />
			</Grid.Row>
			<Divider />
			<Grid.Row>
				<ContactsSection type={'opportunity'} typeId={id} />
			</Grid.Row>
		</Grid.Column>
	</Grid>
);

OppContent.propTypes = {
	id: PropTypes.string.isRequired,
	isCommit: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		isCommit: state.crm.opportunity.data.isCommit
	};
};

export default connect(mapStateToProps, null)(OppContent);
