import React from 'react';
import { Popup, Label, Icon } from 'semantic-ui-react';
import {ICellRendererParams} from "@ag-grid-community/core"

const TagCellRenderer = (props: ICellRendererParams) => {
    const tags = props.value?.length > 0 ? JSON.parse(props.value) : false;
		return tags ? (
			<Popup
				trigger={
					<div
						style={{
							width: '100%',
							textAlign: 'left',
							paddingLeft: '.8em'
						}}
					>
						<Icon name="tag" style={{ paddingRight: '1.5em' }} />
						{tags.length}
					</div>
				}
				on={['hover', 'click']}
				flowing
				hoverable
				position="left center"
			>
				{(() => {
					if (tags.length > 0) {
						let list = tags.map((tag: any, index: any) => {
							return <Label key={`${tag.id}`}>{tag.name}</Label>;
						});
						return <div>{list}</div>;
					}
				})()}
			</Popup>
		) : (
            <></>
		);
}


export default TagCellRenderer;