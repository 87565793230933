import React from 'react';
import {Button, Modal, Icon, Header, Dimmer, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';

import {toggleDeactivateModal, deactivateOpportunity, closeDimmer} from './services/deactivateOpportunityModalActions.js';

const DeactivateOpportunityModal = (props) => (
	<Modal open={props.deactivateOpportunityModal.deactivateOpportunityModal.toggleDeactivateModal}>
		<Header>
			<Icon name="ban" color="red" />
			<div className="content">Deactivate Opportunity</div>
		</Header>
		<Modal.Content>
			{props.deactivateOpportunityModal.deactivateOpportunityModal.loading ? (
				<Dimmer inverted active className="centerDimmer">
					{props.deactivateOpportunityModal.deactivateOpportunityModal.loading === 'start' ? (
						<Loader size="large">Deactivating Opportunity</Loader>
					) : (
						// Error handling
						<Header as="h4" icon>
							<Icon name="cancel" color="red" />
							<div style={{paddingBottom: '.5em', color: 'black'}}>Deactivate Opportunity Failed</div>
							<div style={{color: 'black'}}>Please try again.</div>
							<Button
								onClick={() => {
									props.closeDimmer(null);
								}}
								style={{marginTop: '1.5em'}}
							>
								Close Message
							</Button>
						</Header>
					)}
				</Dimmer>
			) : null}
			<p>Are you sure you would like to set the status of this row to inactive?</p>
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleDeactivateModal(null, null);
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					onClick={() => {
						props.deactivateOpportunity(
							props.deactivateOpportunityModal.deactivateOpportunityModal.id,
							props.deactivateOpportunityModal.deactivateOpportunityModal.place
						);
					}}
					color="green"
				>
					<Icon name="checkmark" /> Deactivate
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

const mapStateToProps = (state) => {
	return {
		deactivateOpportunityModal: state.global.deactivateOpportunityModal
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleDeactivateModal: (id, place) => {
			dispatch(toggleDeactivateModal(id, place));
		},
		deactivateOpportunity: (id, place) => {
			dispatch(deactivateOpportunity(id, place));
		},
		closeDimmer: (value) => {
			dispatch(closeDimmer(value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateOpportunityModal);
