import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/Global/ConfirmationModal.js';
import {
	activateAasdi,
	toggleActivateAasdiModal,
	toggleActivateAasdiErrorHidden,
	deactivateAasdi,
	toggleDeactivateAasdiModal,
	toggleDeactivateAasdiErrorHidden
} from '../services/WorkAasdiGridActions.js';

class AasdiActionCellRenderer extends Component {
	render() {
		const {data} = this.props;
		return (
			<div>
				{this.props.data.statusId === 4 ? (
					<Modal
						isModalOpen={this.props.isActivateModalOpen}
						isLoading={this.props.isActivateModalLoading}
						isErrorMessageHidden={this.props.activateAasdiErrorHidden}
						iconName="toggle on"
						iconColor="green"
						headerText="Activate AASDI"
						question={'Are you sure you would like to activate this AASDI?'}
						errorMessage={this.props.activateAasdiErrorMessage}
						handleClickCancel={() => {
							this.props.toggleActivateAasdiModal();
							this.props.toggleActivateAasdiErrorHidden(true);
						}}
						handleClickConfirm={() => {
							this.props.activateAasdi(
								this.props.api.selectionController.lastSelectedNode.data.id,
								this.props.api.selectionController.lastSelectedNode.rowIndex
							);
						}}
					/>
				) : (
					<Modal
						isModalOpen={this.props.isDeactivateModalOpen}
						isLoading={this.props.isDeactivateModalLoading}
						isErrorMessageHidden={this.props.deactivateAasdiErrorHidden}
						iconName="toggle off"
						iconColor="red"
						headerText="Deactivate AASDI"
						question={`Are you sure you would like to deactivate this AASDI?`}
						errorMessage={this.props.deactivateAasdiErrorMessage}
						handleClickCancel={() => {
							this.props.toggleDeactivateAasdiModal();
							this.props.toggleDeactivateAasdiErrorHidden(true);
						}}
						handleClickConfirm={() =>
							this.props.deactivateAasdi(
								this.props.api.selectionController.lastSelectedNode.data.id,
								this.props.api.selectionController.lastSelectedNode.rowIndex
							)
						}
					/>
				)}
				{this.props.data.statusId === 4 ? (
					<Popup
						trigger={
							<Button
								icon={'toggle off'}
								style={{
									color: 'red',
									backgroundColor: '#e8e8e8',
									padding: '.5em .5em .5em'
								}}
								onClick={() => this.props.toggleActivateAasdiModal()}
							/>
						}
						content={'Activate'}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						style={{zIndex: 1}}
					/>
				) : (
					<Popup
						trigger={
							<Button
								icon={'toggle on'}
								style={{
									color: 'green',
									backgroundColor: '#e8e8e8',
									padding: '.5em .5em .5em'
								}}
								onClick={() => this.props.toggleDeactivateAasdiModal()}
							/>
						}
						content={'Deactivate'}
						on={['hover', 'click']}
						flowing
						hoverable
						position="right center"
						style={{zIndex: 1}}
					/>
				)}
				<Popup
					trigger={
						<Link to={`/work/dashboard/aasdi/profile/${data.aasdiId ? data.aasdiId : data.id}`}>
							<Button
								icon={'linkify'}
								style={{
									backgroundColor: '#e8e8e8',
									padding: '.5em .5em .5em'
								}}
							/>
						</Link>
					}
					content={'View Details'}
					on={['hover', 'click']}
					flowing
					hoverable
					position="right center"
					style={{zIndex: 1}}
				/>
				{data.expired ? (
					<Popup
						trigger={
							<Link to={`/work/dashboard/aasdi/profile/${data.aasdiId ? data.aasdiId : data.id}`}>
								<Button
									icon={'hourglass end'}
									color="grey"
									style={{
										backgroundColor: 'red',
										padding: '.5em .5em .5em'
									}}
								/>
							</Link>
						}
						content={'Insurance Expired'}
						flowing
						hoverable
						position="right center"
						style={{zIndex: 1}}
					/>
				) : null}
			</div>
		);
	}
}

AasdiActionCellRenderer.propTypes = {
	activateAasdi: PropTypes.func,
	activateAasdiErrorHidden: PropTypes.bool,
	activateAasdiErrorMessage: PropTypes.string,
	api: PropTypes.object,
	data: PropTypes.object,
	deactivateAasdi: PropTypes.func,
	deactivateAasdiErrorHidden: PropTypes.bool,
	deactivateAasdiErrorMessage: PropTypes.string,
	isActivateModalLoading: PropTypes.bool,
	isActivateModalOpen: PropTypes.bool,
	isDeactivateModalLoading: PropTypes.bool,
	isDeactivateModalOpen: PropTypes.bool,
	toggleActivateAasdiErrorHidden: PropTypes.func,
	toggleActivateAasdiModal: PropTypes.func,
	toggleDeactivateAasdiModal: PropTypes.func,
	toggleDeactivateAasdiErrorHidden: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		activateAasdiErrorHidden: state.work.aasdiGrid.activateAasdiErrorHidden,
		activateAasdiErrorMessage: state.work.aasdiGrid.activateAasdiErrorMessage,
		deactivateAasdiErrorHidden: state.work.aasdiGrid.deactivateAasdiErrorHidden,
		deactivateAasdiErrorMessage: state.work.aasdiGrid.deactivateAasdiErrorMessage,
		isActivateModalLoading: state.work.aasdiGrid.isActivateModalLoading,
		isActivateModalOpen: state.work.aasdiGrid.isActivateModalOpen,
		isDeactivateModalLoading: state.work.aasdiGrid.isDeactivateModalLoading,
		isDeactivateModalOpen: state.work.aasdiGrid.isDeactivateModalOpen
	};
};

const mapDispatchToProps = {
	activateAasdi: (id, rowIndex) => activateAasdi(id, rowIndex),
	deactivateAasdi: (id, rowIndex) => deactivateAasdi(id, rowIndex),
	toggleActivateAasdiModal,
	toggleActivateAasdiErrorHidden: (bool) => toggleActivateAasdiErrorHidden(bool),
	toggleDeactivateAasdiModal,
	toggleDeactivateAasdiErrorHidden: (bool) => toggleDeactivateAasdiErrorHidden(bool)
};

export default connect(mapStateToProps, mapDispatchToProps)(AasdiActionCellRenderer);
