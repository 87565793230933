import React from 'react';
import {Dimmer, Loader, Label, Icon, Segment} from 'semantic-ui-react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import WorkViewNavbar from './components/workViewNavbar.js';
import Step from './scenes/step.js';
import moment from 'moment';
import {
	setWorkViewId,
	getWork,
	clearWork,
	getBills,
	getProjectCostingSummary,
	getVendorPOs,
	getWorkViewDocuments,
	getWorkViewEventLog,
	getWorkViewAasdiStatus,
	getBillableItems
} from './services/workRecordActions.js';

class View extends React.Component {
	componentDidMount() {
		let url = window.location.href;
		let workId = url.substr(url.lastIndexOf('/') + 1);
		if (workId !== this.props.workId) {
			this.props.setWorkViewId(workId);
			this.props.getWork(workId);
		}
	}

	componentWillUnmount() {
		this.props.clearWork();
	}

	componentDidUpdate() {
		if (this.props.workId && this.props.workNumber) {
			this.props.getBills(this.props.workId);
			this.props.getVendorPOs(this.props.workId, this.props.workNumber);
			this.props.getWorkViewDocuments(this.props.workId);
			this.props.getWorkViewEventLog(this.props.workId);
			this.props.getWorkViewAasdiStatus(this.props.workId);
			this.props.getProjectCostingSummary(this.props.workId);
			if (!this.props.isGroupBillable) {
				this.props.getBillableItems(this.props.workNumber, this.props.workId);
			}
		}
	}

	render() {
		const {workRecord} = this.props;
		return (
			<div>
				<div
					style={{
						top: 0,
						position: 'sticky',
						height: 70,
						backgroundColor: 'white',
						zIndex: 99999
					}}
				>
					<Label
						color={this.props.isDesynced ? 'yellow' : 'blue'}
						attached="top"
						style={{position: 'relative', top: '25px'}}
					>
						<div
							style={{
								display: 'inline-block',
								position: 'relative',
								top: '3px'
							}}
						>
							{this.props.isDesynced
								? `${workRecord.workNumber} This record is not synced with Spectrum. 
								There are currently ${workRecord.desyncs[0].pendingOps} 
								operations processing. You will not be able to update some parts of this 
								work order until Spectrum has been updated. Desync occurred on 
								${moment(workRecord.desyncs[0].desyncedOn).format('YYYY-MM-DD HH:MM')}. This record was last
								proccessed on ${workRecord.desyncs[0].proccessedOn}`
								: workRecord.workNumber}
						</div>
						<div style={{display: 'inline-block', float: 'right'}}>
							<a href={workRecord.googleDriveLink} alt="Google drive Link" target="_blank">
								<Icon link size="large" name="google drive" />
							</a>
						</div>
					</Label>
				</div>
				<WorkViewNavbar />
				<Segment
					style={{
						marginTop: '1.5em',
						paddingTop: 0
					}}
					basic
				>
					<Route path={`/work/view/`} component={Step} />
				</Segment>
			</div>
		);
	}
}

View.propTypes = {
	desyncedDate: PropTypes.string,
	getBills: PropTypes.func,
	getBillableItems: PropTypes.func,
	getProjectCostingSummary: PropTypes.func,
	getVendorPOs: PropTypes.func,
	getWork: PropTypes.func,
	getWorkViewDocuments: PropTypes.func,
	getWorkViewEventLog: PropTypes.func,
	isDesynced: PropTypes.bool,
	isGroupBillable: PropTypes.bool,
	setWorkViewId: PropTypes.func,
	workId: PropTypes.number,
	workNumber: PropTypes.string,
	workRecord: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		workRecord: state.work.workRecord.view.record,
		workId: state.work.workRecord.view.record.id,
		workNumber: state.work.workRecord.view.record.workNumber,
		isDesynced: state.work.workRecord.view.record.desynced,
		isGroupBillable: state.work.workRecord.view.record.isGroupBillable,
		desyncedDate: state.work.workRecord.view.record.lastSyncError
	};
};

const mapDispatchToProps = {
	setWorkViewId,
	getWork,
	clearWork,
	getBills,
	getBillableItems,
	getProjectCostingSummary,
	getVendorPOs,
	getWorkViewDocuments,
	getWorkViewEventLog,
	getWorkViewAasdiStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
