import customFetch from '../../../lib/old/customFetch.js';

import {addTemplateTasks} from '../../work/scenes/WorkOrder-Project/scenes/workRecordView/scenes/tasks/services/tasksActions.js';

export * from './actions/templates.actions.js';
export * from './actions/template-tasks.actions.js';
export * from './actions/snackbar.actions.js';

// this function acts as an interface between the new task-templates and the the old work tasks
export const addTasksFromTemplate = (templateId, group, options) => {
	return async (dispatch) => {
		// retrieve tasks for template via endpoint
		const request = await customFetch(
			`${process.env.REACT_APP_API_URL}/legacy/task?templateId=${templateId}&mode=template-management`
		);
		const response = await request.json();
		const tasks = response.tasks;

		dispatch(addTemplateTasks(tasks, group.name, group.id, options));
	};
};
