import {Form} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const TextBoxInputTaskType = (props) => {
	return props.task.id ? (
		<Form.Field key={`${props.column}-formField`}>
			<div>
				{'Extra Information:  '}
				<label>{props.task.textBoxInput}</label>
			</div>
		</Form.Field>
	) : null;
};

export default TextBoxInputTaskType;
