import {Form, Popup, Button, Icon, Input} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import ErrorLabel from './Label_Error.js';
import optionFactory from './optionFactory.js';

import DebounceInput from 'react-debounce-input';

const ChoiceNameTaskType = (props) => (
	<Form.Field key={`${props.column}-formField`}>
		<label> Choice Name: </label>

		<DebounceInput
			style={{width: '50%', padding: '.5em 0em .5em 0'}}
			element={Input}
			debounceTimeout={600}
			key={`${props.column}-input`}
			className={'editFocus'}
			onChange={(e) => {
				props.updateTask(props.mode, 'choiceName', e.target.value, optionFactory(props));
			}}
			value={props.task.choiceName}
		/>
		<Popup
			trigger={
				<Button
					disabled={props.task.completed}
					className="squareButton"
					icon
					color={'green'}
					type="button"
					onClick={() => {
						props.addDropdownTask(props.task.choiceName, props.mode, optionFactory(props), props.task);
						props.updateTask(props.mode, 'choiceName', props.task.choiceName, optionFactory(props));
					}}
				>
					<Icon name="plus" />
				</Button>
			}
			content={'Add Dropdown Task.'}
		/>
		{props.task.choices !== null || props.task.choices !== undefined || props.task.choices.length === 0 ? null : (
			<Popup
				trigger={
					<Button
						disabled={props.task.completed}
						className="squareButton"
						icon
						color={'blue'}
						type="button"
						onClick={() => props.toggleEditDropdownTask(props.mode, optionFactory(props), props.task)}
					>
						<Icon name="edit" />
					</Button>
				}
				content={'Edit Dropdown Task.'}
			/>
		)}
		{props.task.editName ? (
			<Button
				disabled={props.task.completed}
				color="green"
				className="squareButtonRight"
				type="button"
				onClick={() =>
					props.saveEditDropdownTask(props.task.choiceName, props.mode, optionFactory(props), props.task)
				}
			>
				Save Name
			</Button>
		) : null}
	</Form.Field>
	// <Form.Input
	// 	disabled={props.task.completed}
	// 	// className="editFocus"
	// 	key={uuidv4()}
	// 	label={
	// 		`ChoiceName: `
	// 	}
	// 	onChange={(e, { value }) => {
	//         props.updateTask(
	//             props.mode,
	//             'choiceName',
	//             value,
	//             optionFactory(props)
	//         );
	// 	}}
	// 	value={props.task.choiceName}
	// >

	// </Form.Input>
);

export default ChoiceNameTaskType;
