import customFetch from '../../../../lib/old/customFetch.js';

export const setActiveStep = (currentStep, targetStep) => {
	return (dispatch) => {
		dispatch({
			type: 'LOGIN_REGISTRATION_FORM_SET_ACTIVE_STEP',
			payload: {
				currentStep,
				targetStep
			}
		});
	};
};

export const setActiveSplitStep = (stepIndex, targetSplitStep) => {
	return (dispatch) => {
		dispatch({
			type: 'LOGIN_REGISTRATION_FORM_SET_ACTIVE_SPLIT_STEP',
			payload: {
				stepIndex,
				targetSplitStep
			}
		});
	};
};

export const stepComplete = (stepIndex) => {
	return (dispatch, getState) => {
		const fields = getState().registrationForm.registrationForm.fields;
		let checkRequiredStepFields = Object.values(fields).filter(
			(field) => field.required && !field.value && field.stepIndex === stepIndex
		);
		// Logic for contact section
		if (stepIndex === 2) {
			if (
				fields.contactsPresidentFirstName.value &&
				fields.contactsPresidentLastName.value &&
				fields.contactsPresidentEmail.value
			) {
				// Remove requirement for operations if president filled
				checkRequiredStepFields = checkRequiredStepFields.filter(
					(field) =>
						field.id !== 'contactsOperationsManagerFirstName' &&
						field.id !== 'contactsOperationsManagerLastName' &&
						field.id !== 'contactsOperationsManagerEmail'
				);
			}
			if (
				fields.contactsOperationsManagerFirstName.value &&
				fields.contactsOperationsManagerLastName.value &&
				fields.contactsOperationsManagerEmail.value
			) {
				// Remove requirement for president if operations filled
				checkRequiredStepFields = checkRequiredStepFields.filter(
					(field) =>
						field.id !== 'contactsPresidentFirstName' &&
						field.id !== 'contactsPresidentLastName' &&
						field.id !== 'contactsPresidentEmail'
				);
			}
		}
		// Logic for license section
		if (stepIndex === 4) {
			if (fields.licenseLowVoltageRequired.value === 'No') {
				// Remove requirement for license fields
				checkRequiredStepFields = checkRequiredStepFields.filter(
					(field) =>
						field.id !== 'licenseLowVoltageIssuingState' && field.id !== 'licenseLowVoltageExpirationDate'
				);
			}
			if (fields.licenseElectricalRequired.value === 'No') {
				// Remove requirement for license fields
				checkRequiredStepFields = checkRequiredStepFields.filter(
					(field) =>
						field.id !== 'licenseElectricalIssuingState' && field.id !== 'licenseElectricalExpirationDate'
				);
			}
		}
		//console.log(checkRequiredStepFields);
		// All fields filled out
		if (checkRequiredStepFields.length === 0) {
			dispatch({
				type: 'LOGIN_REGISTRATION_STEP_COMPLETE',
				payload: {
					stepIndex
				}
			});
		}
	};
};

export const updateRegistrationForm = (modal, step, column, value, error) => {
	return (dispatch) => {
		dispatch({
			type: 'LOGIN_REGISTRATION_FORM_UPDATE',
			payload: {
				modal,
				step,
				column,
				value,
				error
			}
		});
	};
};

export const contactsAutoFillData = (modal, type, column, value) => {
	return (dispatch, getState) => {
		let fields = getState().registrationForm.registrationForm.fields;
		let arrayOfColumns;
		// Check if employee fields are filled out before allowing autofill
		if (
			value === 1 &&
			(!fields.employeeFirstName.value || !fields.employeeLastName.value || !fields.employeeEmail.value)
		) {
			let message = `Please finish filling out the required fields on Employee tab before setting to 'Yes'.`;
			dispatch({
				type: 'LOGIN_REGISTRATION_FORM_UPDATE',
				payload: {
					modal: modal,
					step: 2,
					column: column,
					value: 0,
					error: true
				}
			});
			dispatch({
				type: 'LOGIN_REGISTRATION_FORM_UPDATE',
				payload: {
					modal: modal,
					step: 2,
					column: 'contactsAutoFillErrorMessage',
					value: message,
					error: true
				}
			});
		} else {
			// Autofill for President from Employee step
			if (type === 'president') {
				// Yes is chosen
				if (value === 1) {
					arrayOfColumns = [
						{
							columnName: 'contactsPresidentFirstName',
							value: fields.employeeFirstName.value ? fields.employeeFirstName.value : null
						},
						{
							columnName: 'contactsPresidentLastName',
							value: fields.employeeLastName.value ? fields.employeeLastName.value : null
						},
						{
							columnName: 'contactsPresidentEmail',
							value: fields.employeeEmail.value ? fields.employeeEmail.value : null
						},
						{
							columnName: 'contactsPresidentPhone',
							value: fields.employeePhone.value ? fields.employeePhone.value : null
						}
					];
				}
				// No is chosen, clear fields
				if (value === 0) {
					arrayOfColumns = [
						{
							columnName: 'contactsPresidentFirstName',
							value: ''
						},
						{
							columnName: 'contactsPresidentLastName',
							value: ''
						},
						{
							columnName: 'contactsPresidentEmail',
							value: ''
						},
						{
							columnName: 'contactsPresidentPhone',
							value: ''
						}
					];
				}
			}
			if (type === 'operations manager') {
				if (value === 1) {
					arrayOfColumns = [
						{
							columnName: 'contactsOperationsManagerFirstName',
							value: fields.employeeFirstName.value ? fields.employeeFirstName.value : null
						},
						{
							columnName: 'contactsOperationsManagerLastName',
							value: fields.employeeLastName.value ? fields.employeeLastName.value : null
						},
						{
							columnName: 'contactsOperationsManagerEmail',
							value: fields.employeeEmail.value ? fields.employeeEmail.value : null
						},
						{
							columnName: 'contactsOperationsManagerPhone',
							value: fields.employeePhone.value ? fields.employeePhone.value : null
						}
					];
				}
				if (value === 0) {
					arrayOfColumns = [
						{
							columnName: 'contactsOperationsManagerFirstName',
							value: ''
						},
						{
							columnName: 'contactsOperationsManagerLastName',
							value: ''
						},
						{
							columnName: 'contactsOperationsManagerEmail',
							value: ''
						},
						{
							columnName: 'contactsOperationsManagerPhone',
							value: ''
						}
					];
				}
			}
			dispatch({
				type: 'LOGIN_REGISTRATION_FORM_AUTOFILL',
				payload: {
					modal,
					arrayOfColumns
				}
			});
			dispatch({
				type: 'LOGIN_REGISTRATION_FORM_UPDATE',
				payload: {
					modal: 'registrationForm',
					step: 2,
					column: 'contactsAutoFillErrorMessage',
					value: '',
					error: false
				}
			});
		}
	};
};

export const register = () => {
	return (dispatch, getState) => {
		let fields = getState().registrationForm.registrationForm.fields;
		// Variables for POST
		let payload = {
			name: fields.companyName.value,
			address: fields.companyAddress.value,
			address2: fields.companyAddress2.value,
			city: fields.companyCity.value,
			state: fields.companyState.value,
			zip: fields.companyZipCode.value,
			website: fields.companyWebsite.value,
			countryId: fields.companyCountry.value,
			federalTaxId: fields.companyFederal.value,
			yearFounded: fields.companyYearFounded.value,
			annualRevenue: fields.companyAnnualRevenue.value,
			warranty: fields.companyWarranty.value,
			unions: fields.companyUnion.value,
			workNonUnion: fields.companyWorkNonUnionJobs.value,
			geographicCoverage: fields.companyGeographicCoverage.value,
			licensedMunicipalities: fields.companyLicensedMunicipalities.value,

			liabilityCompanyName: fields.insuranceLiabilityCompanyName.value,
			liabilityPolicyNumber: fields.insuranceLiabilityPolicyNumber.value,
			liabilityExpirationDate: fields.insuranceLiabilityExpirationDate.value,
			liabilityBestRating: fields.insuranceLiabilityBestRating.value,
			workersCompanyName: fields.insuranceWorkersCompensationName.value,
			workersPolicyNumber: fields.insuranceWorkersCompensationPolicyNumber.value,
			workersExpirationDate: fields.insuranceWorkersCompensationExpirationDate.value,
			workersBestRating: fields.insuranceWorkersCompensationBestRating.value,
			workersModRating: fields.insuranceWorkersCompensationModRating.value,

			lowVoltageRequired: fields.licenseLowVoltageRequired.value,
			lowVoltageState: fields.licenseLowVoltageIssuingState.value,
			lowVoltageExpirationDate: fields.licenseLowVoltageExpirationDate.value,
			electricalRequired: fields.licenseElectricalRequired.value,
			electricalState: fields.licenseElectricalIssuingState.value,
			electricalExpirationDate: fields.licenseElectricalExpirationDate.value,

			technicians: fields.staffingTechnicians.value,
			mfrCertTechnicians: fields.staffingMFRCertTechnicians.value,
			rcdds: fields.staffingRCDD.value,
			leadTechs: fields.staffingLeadTechs.value,
			projectManagers: fields.staffingProjectManagers.value,

			bankName: fields.bankName.value,
			bankContact: fields.bankContactName.value,
			bankPhone: fields.bankContactPhone.value,

			//username: fields.loginUsername.value,
			//password: fields.loginPassword.value,

			firstName: fields.employeeFirstName.value,
			lastName: fields.employeeLastName.value,
			title: fields.employeeTitle.value,
			email: fields.employeeEmail.value,
			phone: fields.employeePhone.value,

			isPresident: fields.contactsPresidentAutoFill.value === 0 ? 'No' : 'Yes',
			presidentFirstName: fields.contactsPresidentFirstName.value,
			presidentLastName: fields.contactsPresidentLastName.value,
			presidentPhone: fields.contactsPresidentPhone.value,
			presidentEmail: fields.contactsPresidentEmail.value,
			isOperationsManager: fields.contactsOperationsManagerAutoFill.value === 0 ? 'No' : 'Yes',
			operationsFirstName: fields.contactsOperationsManagerFirstName.value,
			operationsLastName: fields.contactsOperationsManagerLastName.value,
			operationsPhone: fields.contactsOperationsManagerPhone.value,
			operationsEmail: fields.contactsOperationsManagerEmail.value
		};

		let endpoint = `${process.env.REACT_APP_API_URL}/legacy/aasdi`;

		// Check for required fields
		let emptyRequiredFields = Object.values(fields).filter((field) => field.required && !field.value);

		// SPLIT FIELD CHECKS
		//// CONTACTS
		if (
			fields.contactsPresidentFirstName.value &&
			fields.contactsPresidentLastName.value &&
			fields.contactsPresidentEmail.value
		) {
			// Remove requirement for operations if president filled
			emptyRequiredFields = emptyRequiredFields.filter(
				(field) =>
					field.id !== 'contactsOperationsManagerFirstName' &&
					field.id !== 'contactsOperationsManagerLastName' &&
					field.id !== 'contactsOperationsManagerEmail'
			);
		}
		if (
			fields.contactsOperationsManagerFirstName.value &&
			fields.contactsOperationsManagerLastName.value &&
			fields.contactsOperationsManagerEmail.value
		) {
			// Remove requirement for president if operations filled
			emptyRequiredFields = emptyRequiredFields.filter(
				(field) =>
					field.id !== 'contactsPresidentFirstName' &&
					field.id !== 'contactsPresidentLastName' &&
					field.id !== 'contactsPresidentEmail'
			);
		}
		//// LICESNE
		if (fields.licenseLowVoltageRequired.value === 'No') {
			// Remove requirement for license fields
			emptyRequiredFields = emptyRequiredFields.filter(
				(field) =>
					field.id !== 'licenseLowVoltageIssuingState' && field.id !== 'licenseLowVoltageExpirationDate'
			);
		}
		if (fields.licenseElectricalRequired.value === 'No') {
			// Remove requirement for license fields
			emptyRequiredFields = emptyRequiredFields.filter(
				(field) =>
					field.id !== 'licenseElectricalIssuingState' && field.id !== 'licenseElectricalExpirationDate'
			);
		}
		// END SPLIT FIELD CHECKS
		// Check Password Match
		if (fields.loginPassword.value !== fields.loginPasswordCheck.value) {
			emptyRequiredFields.push(fields.loginPasswordCheck);
			dispatch({
				type: 'LOGIN_REGISTRATION_FORM_UPDATE',
				payload: {
					modal: 'registrationForm',
					step: 7,
					column: 'loginPasswordCheckErrorMessage',
					value: `Passwords do not match.`,
					error: true
				}
			});
		}

		// Run check on required fields
		if (emptyRequiredFields.length > 0) {
			dispatch({
				type: 'LOGIN_REGISTRATION_CHECK_REQUIRED_FIELDS',
				payload: {
					emptyRequiredFields
				}
			});
			return;
		}
		dispatch({
			type: 'LOGIN_REGISTRATION_FORM_LOADING',
			payload: {
				state: 'progress'
			}
		});
		customFetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(payload)
		})
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject(response);
				}

				return response.json();
			})
			.then(() => {
				dispatch(toggleRegisterModal());

				dispatch({
					type: 'LOGIN_REGISTRATION_FORM_LOADING',
					payload: {
						state: null
					}
				});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR',
					payload: err.stack
				});
				dispatch({
					type: 'LOGIN_REGISTRATION_FORM_LOADING',
					payload: {
						state: 'error'
					}
				});
			});
	};
};

export const toggleRegisterModal = () => {
	return (dispatch, getState) => {
		const toggleModal = getState().registrationForm.isRegistrationModalOpen;
		return dispatch({
			type: 'REGISTER_MODAL_TOGGLE',
			payload: {
				isRegistrationModalOpen: !toggleModal
			}
		});
	};
};

export const loginPasswordCheckAction = (modal, step, column, value) => {
	return (dispatch, getState) => {
		let fields = getState().registrationForm.registrationForm.fields;
		// Check if passwords are correct
		if (value !== fields.loginPassword.value) {
			let message = `Passwords do not match.`;
			dispatch({
				type: 'LOGIN_REGISTRATION_FORM_UPDATE',
				payload: {
					modal: modal,
					step: step,
					column: column,
					value: value,
					error: true
				}
			});
			dispatch({
				type: 'LOGIN_REGISTRATION_FORM_UPDATE',
				payload: {
					modal: modal,
					step: step,
					column: 'loginPasswordCheckErrorMessage',
					value: message,
					error: true
				}
			});
		} else {
			// Passwords match
			dispatch({
				type: 'LOGIN_REGISTRATION_FORM_UPDATE',
				payload: {
					modal: 'registrationForm',
					step: step,
					column: 'loginPasswordCheckErrorMessage',
					value: '',
					error: false
				}
			});
			dispatch(stepComplete(step));
		}
	};
};

export const closeDimmer = (value) => {
	return (dispatch) => {
		dispatch({
			type: 'LOGIN_REGISTRATION_FORM_LOADING',
			payload: {
				state: value
			}
		});
	};
};
