import {Button, Icon, Popup} from 'semantic-ui-react';
import React from 'react';
//import PropTypes from 'prop-types';
import optionFactory from './optionFactory.js';

const deleteTaskButton = (props) => [
	props.isSection && !props.isSectionTask ? (
		<Popup
			size={'mini'}
			trigger={
				<Button
					onClick={() => {
						props.toggleDeleteModal(
							'open',
							'Delete Section',
							`Are you sure you want to delete section ${props.task.title}?`,
							props.mode,
							optionFactory(props),
							props.task
						);
					}}
					icon
					color={'red'}
					type="button"
				>
					<Icon name="trash" />
				</Button>
			}
			content={'Delete this section.'}
		/>
	) : (
		<Popup
			size={'mini'}
			trigger={
				<Button
					onClick={() => {
						props.toggleDeleteModal(
							'open',
							`Delete Task`,
							`Are you sure you want to delete "${props.task.title}"?`,
							props.mode,
							optionFactory(props),
							props.task
						);
					}}
					icon
					color={'red'}
					type="button"
				>
					<Icon inverted name="trash" />
				</Button>
			}
			content={'Delete this task.'}
		/>
	)
];

export default deleteTaskButton;
