import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Icon, Header, Dimmer, Loader, Grid, Label, List, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {
	toggleSendToModal,
	closeDimmer,
	sendTo,
	updateSendToModal,
	getAccountExecs
} from './services/sendToModalActions.js';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {debounce, debounceEventHandler} from '../../lib/old/debounce.js';
import {MomentDatePicker} from '../inputs/DatePicker.jsx';

const SendToModal = (props) => (
	<Modal open={props.sendToModal.sendToModal.toggleSendToModal}>
		<Header>
			<Icon name="share" color="blue" />
			<div className="content">
				{props.sendToModal.sendToModal.place === 'pipeline' ? 'Send Back To Leads' : 'Send to Pipeline'}
			</div>
		</Header>
		<Modal.Content
			style={
				props.sendToModal.sendToModal.place === 'leads'
					? !props.accountExec.filled || !props.projectedOrderDate.filled
						? {height: '50vh'}
						: null
					: null
			}
		>
			{props.sendToModal.sendToModal.loading ? (
				<Dimmer inverted active className="centerDimmer">
					{props.sendToModal.sendToModal.loading === 'start' ? (
						<Loader size="large">
							{props.sendToModal.sendToModal.place === 'pipeline'
								? 'Sending Back to Leads'
								: 'Sending to Pipeline'}
						</Loader>
					) : (
						// Error handling
						<Header as="h4" icon>
							<Icon name="cancel" color="red" />
							<div style={{paddingBottom: '.5em', color: 'black'}}>Sending Failed</div>
							<div style={{color: 'black'}}>Please try again.</div>
							<Button
								onClick={() => {
									props.closeDimmer(null);
								}}
								style={{marginTop: '1.5em'}}
							>
								Close Message
							</Button>
						</Header>
					)}
				</Dimmer>
			) : null}
			{props.sendToModal.sendToModal.place === 'pipeline' ? (
				'Are you sure you would like to send this record back to leads?'
			) : !props.accountExec.filled || !props.projectedOrderDate.filled ? (
				!props.accountExec.filled && !props.projectedOrderDate.filled ? (
					// Both values required
					<Grid centered>
						<Grid.Row>
							<Grid.Column width={12}>
								<Header
									as="h4"
									icon
									style={{
										marginBottom: '0',
										textAlign: 'left'
									}}
								>
									You must meet one of the requirements below to move this opportunity to the
									pipeline:
								</Header>
								<List bulleted style={{padding: '0  0 1.5em 0'}}>
									<List.Item>Prospect has invited you to respond to a RFP.</List.Item>
									<List.Item>Prospect has asked you to provide a quote.</List.Item>
									<List.Item>
										Prospect has communicated that you will be asked one of the above in the future.
									</List.Item>
								</List>
								{'These fields are required before sending a record to pipeline.'}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={12}>
								<Label
									style={{
										backgroundColor: 'transparent',
										fontSize: '1rem',
										color: 'rgba(0,0,0,.8)',
										paddingLeft: '0',
										paddingBottom: '.6em',
										display: 'block'
									}}
								>
									{props.projectedOrderDate.name}
								</Label>
								<MomentDatePicker
									className={
										props.projectedOrderDate.error
											? 'sendToModalDatePicker sendToModalDatePickerError'
											: 'sendToModalDatePicker'
									}
									onChange={(date) => {
										props.updateSendToModal(
											'projectedOrderDate',
											date.format('MM-DD-YYYY').toString(),
											false
										);
									}}
									selected={moment(
										props.projectedOrderDate.value ? props.projectedOrderDate.value : moment()
									)}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={12}>
								<Label
									style={{
										backgroundColor: 'transparent',
										fontSize: '1rem',
										color: 'rgba(0,0,0,.8)',
										paddingLeft: '0'
									}}
								>
									{props.accountExec.name}
								</Label>
								<Dropdown
									className="createModalDropdown"
									fluid
									search
									selection
									options={props.accountExec.options}
									onChange={(e, {value}) => {
										props.updateSendToModal('accountExec', value, false);
									}}
									onSearchChange={debounceEventHandler(
										debounce((e) => {
											if (e.target.value) {
												props.getAccountExecs('accountExec', e.target.value);
											}
										}, 600)
									)}
									value={props.accountExec.value}
									error={Boolean(props.accountExec.error)}
									upward={props.accountExec.upward}
									loading={props.accountExec.loading}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				) : (
					<Grid centered>
						<Grid.Row>
							<Grid.Column width={12}>
								<Header
									as="h4"
									icon
									style={{
										marginBottom: '0',
										textAlign: 'left'
									}}
								>
									You must meet one of the requirements below to move this opportunity to the
									pipeline:
								</Header>
								<List bulleted style={{padding: '0  0 1.5em 0'}}>
									<List.Item>Prospect has invited you to respond to a RFP.</List.Item>
									<List.Item>Prospect has asked you to provide a quote.</List.Item>
									<List.Item>
										Prospect has communicated that you will be asked one of the above in the future.
									</List.Item>
								</List>
								{'This field is required before sending a record to pipeline.'}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							{!props.accountExec.filled ? (
								<Grid.Column width={12}>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0'
										}}
									>
										{props.accountExec.name}
									</Label>
									<Dropdown
										className="createModalDropdown"
										fluid
										search
										selection
										options={props.accountExec.options}
										onChange={(e, {value}) => {
											props.updateSendToModal('accountExec', value, false);
										}}
										onSearchChange={(e) => {
											props.getAccountExecs('accountExec', e.target.value);
										}}
										value={props.accountExec.value}
										error={Boolean(props.accountExec.error)}
										upward={props.accountExec.upward}
										loading={props.accountExec.loading}
									/>
								</Grid.Column>
							) : !props.projectedOrderDate.filled ? (
								<Grid.Column width={12}>
									<Label
										style={{
											backgroundColor: 'transparent',
											fontSize: '1rem',
											color: 'rgba(0,0,0,.8)',
											paddingLeft: '0',
											paddingBottom: '.6em',
											display: 'block'
										}}
									>
										{props.projectedOrderDate.name}
									</Label>
									<MomentDatePicker
										className={
											props.projectedOrderDate.error
												? 'sendToModalDatePicker sendToModalDatePickerError'
												: 'sendToModalDatePicker'
										}
										onChange={(date) => {
											props.updateSendToModal(
												'projectedOrderDate',
												moment(date)
													.format('MM-DD-YYYY')
													.toString(),
												false
											);
										}}
										selected={
											props.projectedOrderDate.value
												? moment(props.projectedOrderDate.value)
												: null
										}
									/>
								</Grid.Column>
							) : null}
						</Grid.Row>
					</Grid>
				)
			) : (
				<Grid centered>
					<Grid.Row>
						<Grid.Column width={12}>
							<Header as="h4" icon style={{marginBottom: '0', textAlign: 'left'}}>
								You must meet one of the requirements below to move this opportunity to the pipeline:
							</Header>
							<List bulleted style={{padding: '0  0 1.5em 0'}}>
								<List.Item>Prospect has invited you to respond to a RFP.</List.Item>
								<List.Item>Prospect has asked you to provide a quote.</List.Item>
								<List.Item>
									Prospect has communicated that you will be asked one of the above in the future.
								</List.Item>
							</List>
							{`Are you sure you would like to send this record to
					pipeline?`}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</Modal.Content>
		<Modal.Actions>
			<Button.Group>
				<Button
					onClick={() => {
						props.toggleSendToModal(null, null, null, null);
					}}
					color="red"
				>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					onClick={() => {
						props.sendTo(
							props.sendToModal.sendToModal.id,
							props.sendToModal.sendToModal.place,
							props.accountExec.value,
							props.projectedOrderDate.value
						);
					}}
					color="green"
				>
					<Icon name="checkmark" />
					{props.sendToModal.sendToModal.place === 'pipeline' ? 'Send to Leads' : 'Send to Pipeline'}
				</Button>
			</Button.Group>
		</Modal.Actions>
	</Modal>
);

SendToModal.propTypes = {
	accountExec: PropTypes.object,
	closeDimmer: PropTypes.func,
	getAccountExecs: PropTypes.func,
	projectedOrderDate: PropTypes.object,
	sendTo: PropTypes.func,
	toggleSendToModal: PropTypes.func,
	updateSendToModal: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		sendToModal: state.global.sendToModal,
		accountExec: state.global.sendToModal.sendToModal.accountExec,
		projectedOrderDate: state.global.sendToModal.sendToModal.projectedOrderDate
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleSendToModal: (id, place, acctExecId, projOrderDate) => {
			dispatch(toggleSendToModal(id, place, acctExecId, projOrderDate));
		},
		sendTo: (id, place, acctExecId, projOrderDate) => {
			dispatch(sendTo(id, place, acctExecId, projOrderDate));
		},
		closeDimmer: (value) => {
			dispatch(closeDimmer(value));
		},
		updateSendToModal: (column, value, error) => {
			dispatch(updateSendToModal(column, value, error));
		},
		getAccountExecs: (column, value) => {
			dispatch(getAccountExecs(column, value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SendToModal);
