import React from 'react';
import {connect} from 'react-redux';
import {Button, Popup} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import * as actions from '../../../../services/workRecordActions.js';

let AddBillButton = (props) => (
	<Popup
		trigger={
			<Button
				color={props.isDesynced ? 'yellow' : 'green'}
				floated="right"
				icon="plus"
				size="medium"
				disabled={[6, 10, 14, 18].includes(props.workStatus) || props.isDesynced}
				style={{
					margin: 0,
					borderRadius: '.21428571rem .21428571rem 0 0',
					padding: '1em'
				}}
				onClick={() => {
					props.toggleInvoiceSpreadsheet();
				}}
			/>
		}
		position="left center"
		content="Create Bill"
	/>
);

AddBillButton.propTypes = {
	workStatus: PropTypes.number,
	createBillModal: PropTypes.func,
	isDesynced: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		isDesynced: state.work.workRecord.view.record.desynced,
		workStatus: state.work.workRecord.view.record.statusId
	};
};

export default connect(mapStateToProps, actions)(AddBillButton);
