import customFetch from '../../../lib/old/customFetch.js';

export const toggleCommitModal = () => {
	return (dispatch) => {
		dispatch({
			type: 'TOGGLE_COMMIT_MODAL'
		});
	};
};

export const setOpportunityData = (payload) => {
	return (dispatch) => {
		dispatch({
			type: 'SET_COMMIT_MODAL_OPPORTUNITY_DATA',
			payload
		});
	};
};

export const commitModalLoading = (isLoading) => {
	return (dispatch) => {
		dispatch({
			type: 'COMMIT_MODAL_LOADING',
			payload: isLoading
		});
	};
};

export const setOppCommit = (oppData, value, isGrid) => (dispatch) => {
	dispatch(commitModalLoading(true));
	console.log('oppData:', oppData);
	const endpoint = `${process.env.REACT_APP_API_URL}/legacy/crm/pipeline/opportunity/${oppData.id}`;
	customFetch(endpoint, {
		method: 'PUT',
		body: JSON.stringify({
			isCommit: value
		})
	})
		.then((response) => {
			if (response.status !== 200) {
				return Promise.reject(response);
			}
			return response.json();
		})
		.then((json) => {
			console.log('return json:', json);
			dispatch(commitModalLoading(false));
			dispatch(toggleCommitModal());

			if (isGrid) {
				dispatch({
					type: 'CRM_PIPELINE_UPDATE_OPPORTUNITY_COLUMN_BY_ID',
					payload: {
						id: oppData.id,
						column: 'isCommit',
						value: value
					}
				});
			} else {
				dispatch({
					type: 'CRM_UPDATE_OPPORTUNITY_COLUMN',
					payload: {
						column: 'isCommit',
						value: value
					}
				});
			}
		})
		.catch((err) => {
			console.log('err', err);
			dispatch(commitModalLoading(false));
			dispatch(toggleCommitModal());
		});
};
