import {Icon, Dropdown, Label, Popup, Button} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import dropdownFactory from './dropdownFactory.js';
import optionFactory from './optionFactory.js';
import ErrorLabel from './Label_Error.js';

const DropdownTaskType = (props) => {
	return (
		<div key={`${props.column}-div`} className="dropdownTaskBoxDiv">
			<Dropdown
				style={{width: '50%'}}
				key={`${props.column}-input`}
				disabled={props.task.completed}
				error={props.tasks.ui.errors.choices}
				className="dropdownFloat"
				placeholder="Dropdown Tasks"
				search
				selection
				options={dropdownFactory(props.task.choices)}
				onChange={(e, {value}) => {
					props.updateTask(props.mode, props.column, value, optionFactory(props));
					props.updateTask(props.mode, 'choiceName', value, optionFactory(props));
				}}
				value={props.task.dropdown}
			/>
			<ErrorLabel
				{...props}
				key={`${props.column}-errorLabel`}
				message={'Please add a choice to the choice list.'}
				style={{color: 'black'}}
			/>
			{props.task.choices && props.task.choices.length > 0 ? (
				<Popup
					key={`${props.column}-popup`}
					trigger={
						<Button
							disabled={props.task.completed}
							className="squareButtonRight alignBottom"
							icon
							color={'red'}
							type="button"
							onClick={() => props.dropdownRemoveTask(props.mode, optionFactory(props), props.task)}
						>
							<Icon name="trash" />
						</Button>
					}
					content={JSON.stringify(props.task)}
				/>
			) : null}
		</div>
	);
};

export default DropdownTaskType;
